import React from 'react';
import { ArrowForward } from 'react-ionicons';

const ModalSorting = (props) => {
  const { product, onClose } = props;

  return (
    <div
      className='fixed w-screen h-screen top-0 left-0 z-10 flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]'
      role='dialog'
      aria-modal='true'
      aria-labelledby='sort-modal-heading'
    >
      <div className='absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]' aria-hidden='true' />

      <div
        className='slide-in-bottom flex flex-col m-0 mt-auto md:mt-0 px-4 sm:px-6 lg:px-8 pb-8 pt-8 w-full bg-white md:rounded-2xl relative z-20 overflow-auto '
        style={{ maxWidth: '700px' }}
        aria-labelledby='sort-options'
      >
        <img
          className='absolute top-6 right-6 cursor-pointer'
          src='/assets/img/close.svg'
          onClick={() => onClose()}
          alt='Close'
          role='button'
          aria-label='Close Modal'
        />

        <h3 className='mt-4' id='sort-modal-heading'>
          SORT
        </h3>

        <div className='flex flex-col gap-4 mt-4 quicksand' aria-labelledby='sort-options'>
          <div className=''>
            <h4 className='font-bold'>Recommended Sort</h4>
            <div
              className='flex align-items-center gap-1 px-4 py-2 rounded-lg text-sm'
              style={{ background: '#F7F7F7' }}
            >
              <input type='radio' id='default' name='filter' value='default' aria-labelledby='recommended-sort-label' />
                <p>Recommended Sort</p>
            </div>
          </div>

          <div>
            <h4 className='font-bold'>Price</h4>
            <div
              className='flex align-items-center justify-content-between px-4 py-2 rounded-lg text-sm'
              style={{ background: '#F7F7F7' }}
            >
              <div className='flex align-items-center gap-1'>
                <input type='radio' id='price_asc' name='filter' value='price_asc' aria-labelledby='price-asc-label' /> 
                <p id='price-asc-label'>Low to High</p>
              </div>
              <div className='flex align-items-center gap-1'>
                <input
                  type='radio'
                  id='price_desc'
                  name='filter'
                  value='price_desc'
                  aria-labelledby='price-desc-label'
                />
                  <p id='price-desc-label'>High to Low</p>
              </div>
            </div>
          </div>

          <div>
            <h4 className='font-bold'>Rating</h4>
            <div
              className='flex align-items-center justify-content-between gap-4 px-4 py-2 rounded-lg text-sm'
              style={{ background: '#F7F7F7' }}
            >
              <div className='flex align-items-center gap-1'>
                <input
                  type='radio'
                  id='rating_asc'
                  name='filter'
                  value='rating_asc'
                  aria-labelledby='rating-asc-label'
                />
                  <p id='rating_desc'>High to Low </p>
              </div>
              <div className='flex align-items-center gap-1'>
                <input
                  type='radio'
                  id='rating_desc'
                  name='filter'
                  value='rating_desc'
                  aria-labelledby='rating-desc-label'
                />
                  <p id='rating_desc'>Low to High</p>
              </div>
            </div>
          </div>

          <div>
            <h4 className='font-bold'>Delivery Price</h4>
            <div
              className='flex align-items-center justify-content-between gap-4 px-4 py-2 rounded-lg text-sm'
              style={{ background: '#F7F7F7' }}
            >
              <div className='flex align-items-center gap-1'>
                <input
                  type='radio'
                  id='delivery_asc'
                  name='filter'
                  value='delivery_asc'
                  aria-labelledby='delivery-asc-label'
                />
                  <p id='delivery-asc-label'>Low to High</p>
              </div>
              <div className='flex align-items-center gap-1'>
                <input
                  type='radio'
                  id='delivery_desc'
                  name='filter'
                  value='delivery_desc'
                  aria-labelledby='delivery-desc-label'
                />
                  <p id='delivery-desc-label'>High to Low</p>
              </div>
            </div>
          </div>
        </div>

        <button
          type='button'
          className='flex justify-content-center gap-2 mt-4 py-2 w-full bg-black text-white rounded-lg '
          aria-label='Sort Button'
        >
          SORT <ArrowForward color={'#fff'} aria-hidden='true' />
        </button>
      </div>
    </div>
  );
};

export default ModalSorting;
