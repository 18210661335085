const Languages_Array = [
  {
    name: 'ITALIANO',
    code: 'it',
    img: '/assets/languages/it.png'
  },
  {
    name: 'ENGLISH',
    code: 'en',
    img: '/assets/languages/en.png'
  },
  {
    name: 'FRANÇAIS',
    code: 'fr',
    img: '/assets/languages/fr.png'
  },
  {
    name: 'ESPAÑOL',
    code: 'es',
    img: '/assets/languages/es.png'
  }
]

const Currencies_Array = [
  {
    nav: '€ EUR',
    name: 'EURO - €',
    code: 'eur',
    img: '/assets/languages/eu.png'
  },
  {
    nav: '£ GBP',
    name: 'GBP - £',
    code: 'gbp',
    img: '/assets/languages/en.png'
  },
  {
    nav: '$ USD',
    name: 'US DOLLAR - $',
    code: 'usd',
    img: '/assets/languages/us.png'
  }
]

const Cities_Array = [
  {
    id: 'D101F0VnJ9',
    en: 'Milan',
    it: 'Milano',
    fr: 'Milan',
    es: 'Milán',
    de: 'Mailand',
    ar: 'ميلان',
    ja: 'ミラノ',
    zh: '米蘭',

    en_url: '/en/search/milan/IT',
    it_url: '/it/search/milano/IT',
    fr_url: '/fr/search/milan/IT',
    es_url: '/es/search/milan/IT',

    de_url: '/de/search/milan/IT',
    ar_url: '/ar/search/milan/IT',
    ja_url: '/ja/search/milan/IT',
    zh_url: '/zh/search/milan/IT',


    country: 'IT',
    geocode: { latitude: 45.46427, longitude: 9.18951 },
  },
  {
    id: 'e59ZbFsjaL',
    en: 'Paris',
    it: 'Parigi',
    fr: 'Paris',
    es: 'París',
    de: 'Paris',
    ar: 'باريس',
    ja: 'パリ',
    zh: '巴黎',

    en_url: '/en/search/paris/FR',
    it_url: '/it/search/parigi/FR',
    fr_url: '/fr/search/paris/FR',
    es_url: '/es/search/paris/FR',

    de_url: '/de/search/paris/FR',
    ar_url: '/ar/search/paris/FR',
    ja_url: '/ja/search/paris/FR',
    zh_url: '/zh/search/paris/FR',

    country: 'FR',
    geocode: { latitude: 48.85341, longitude: 2.3488 }
  },
  {
    id: 'fcLPlQLMFH',
    en: 'Barcelona',
    it: 'Barcellona',
    fr: 'Barcelone',
    es: 'Barcelona',
    de: 'Barcelona',
    ar: 'برشلونة',
    ja: 'バルセロナ',
    zh: '巴塞羅那',

    en_url: '/en/search/barcelona/ES',
    it_url: '/it/search/barcellona/ES',
    fr_url: '/fr/search/barcelone/ES',
    es_url: '/es/search/barcelona/ES',

    de_url: '/de/search/barcelona/ES',
    ar_url: '/ar/search/barcelona/ES',
    ja_url: '/ja/search/barcelona/ES',
    zh_url: '/zh/search/barcelona/ES',

    country: 'ES',
    geocode: { latitude: 41.38879, longitude: 2.15899 },
  },
  {
    id: 'wD6sxjtq7n',
    en: 'London',
    it: 'Londra',
    fr: 'Londres',
    es: 'Londres',
    de: 'London',
    ar: 'لندن',
    ja: 'ロンドン',
    zh: '倫敦',

    en_url: '/en/search/london/GB',
    it_url: '/it/search/londra/GB',
    fr_url: '/fr/search/londres/GB',
    es_url: '/es/search/londres/GB',

    de_url: '/de/search/london/GB',
    ar_url: '/ar/search/london/GB',
    ja_url: '/ja/search/london/GB',
    zh_url: '/zh/search/london/GB',

    country: 'GB',
    geocode: { latitude: 51.50853, longitude: -0.12574 },
  },
  {
    id: 'REYWVO7FOe',
    en: 'Istanbul',
    it: 'Istanbul',
    fr: 'Istanbul',
    es: 'Estanbul',
    de: 'Istanbul',
    ar: 'اسطنبول',
    ja: 'イスタンブール',
    zh: '伊斯坦布爾',

    en_url: '/en/search/istanbul/TR',
    it_url: '/it/search/istanbul/TR',
    fr_url: '/fr/search/istanbul/TR',
    es_url: '/es/search/estanbul/TR',

    de_url: '/de/search/istanbul/TR',
    ar_url: '/ar/search/istanbul/TR',
    ja_url: '/ja/search/istanbul/TR',
    zh_url: '/zh/search/istanbul/TR',

    country: 'TR',
    geocode: { latitude: 41.01384, longitude: 28.94966 },
  },
  {
    id: 'jYfCHc0nFo',
    en: 'Rome',
    it: 'Roma',
    fr: 'Rome',
    es: 'Roma',
    de: 'Rom',
    ar: 'روما',
    ja: 'ローマ',
    zh: '羅馬',

    en_url: '/en/search/rome/IT',
    it_url: '/it/search/roma/IT',
    fr_url: '/fr/search/rome/IT',
    es_url: '/es/search/roma/IT',

    de_url: '/de/search/rome/IT',
    ar_url: '/ar/search/rome/IT',
    ja_url: '/ja/search/rome/IT',
    zh_url: '/zh/search/rome/IT',

    country: 'IT',
    geocode: { latitude: 41.89193, longitude: 12.51133 },
  },
  {
    id: 'tTrG6KXrUX',
    en: 'Berlin',
    it: 'Berlino',
    fr: 'Berlin',
    es: 'Berlina',
    de: 'Berlin',
    ar: 'برلين',
    ja: 'ベルリン',
    zh: '柏林',

    en_url: '/en/search/berlin/DE',
    it_url: '/it/search/berlino/DE',
    fr_url: '/fr/search/berlin/DE',
    es_url: '/es/search/berlina/DE',

    de_url: '/de/search/berlin/DE',
    ar_url: '/ar/search/berlin/DE',
    ja_url: '/ja/search/berlin/DE',
    zh_url: '/zh/search/berlin/DE',

    country: 'DE',
    geocode: { latitude: 52.52437, longitude: 13.41053 },
  },
  {
    id: 'OlUyLQYkCE',
    en: 'Florence',
    it: 'Firenze',
    fr: 'Florence',
    es: 'florencia',
    de: 'Florenz',
    ar: 'فلورنسا',
    ja: 'フィレンツェ',
    zh: '佛羅倫薩',

    en_url: '/en/search/florence/IT',
    it_url: '/it/search/firenze/IT',
    fr_url: '/fr/search/florence/IT',
    es_url: '/es/search/florencia/IT',

    de_url: '/de/search/florence/IT',
    ar_url: '/ar/search/florence/IT',
    ja_url: '/ja/search/florence/IT',
    zh_url: '/zh/search/florence/IT',

    country: 'IT',
    geocode: { latitude: 43.77925, longitude: 11.24626 },
  },
  {
    id: 'fthoQPrRfl',
    en: 'Amsterdam',
    it: 'Amsterdam',
    fr: 'Amsterdam',
    es: 'Amsterdam',
    de: 'Amsterdam',
    ar: 'أمستردام',
    ja: 'アムステルダム',
    zh: '阿姆斯特丹',

    en_url: '/en/search/amsterdam/NL',
    it_url: '/it/search/amsterdam/NL',
    fr_url: '/fr/search/amsterdam/NL',
    es_url: '/es/search/amsterdam/NL',

    de_url: '/de/search/amsterdam/NL',
    ar_url: '/ar/search/amsterdam/NL',
    ja_url: '/ja/search/amsterdam/NL',
    zh_url: '/zh/search/amsterdam/NL',

    country: 'IT',
    geocode: { latitude: 43.77925, longitude: 11.24626 },
  },
  {
    id: '3q7HgOxhIJ',
    en: 'Dubai',
    it: 'Dubai',
    fr: 'Dubai',
    es: 'Dubai',
    de: 'Dubai',
    ar: 'دبي',
    ja: 'ドバイ',
    zh: '迪拜',

    en_url: '/en/search/dubai/AE',
    it_url: '/it/search/dubai/AE',
    fr_url: '/fr/search/dubai/AE',
    es_url: '/es/search/dubai/AE',

    de_url: '/de/search/dubai/AE',
    ar_url: '/ar/search/dubai/AE',
    ja_url: '/ja/search/dubai/AE',
    zh_url: '/zh/search/dubai/AE',

    country: 'AE',
    geocode: { latitude: 43.77925, longitude: 11.24626 },
  }

]

const Categories_Array = [
  {
    it: 'Tutte le categorie',
    en: 'All categories',
    fr: 'Toutes les categories',
    es: 'Todas las categorias',
    tr: 'Hepsi',
    code: 'all',
    keywords: ['all']
  },
  {
    it: 'Passeggini',
    en: 'Strollers',
    fr: 'Poussettes',
    es: 'Cochecitos',
    tr: 'Bebek Arabaları',
    code: 'passeggini',
    keywords: [
      'passeggini',
      'passeggino', 'passeggio',
      'cochecitos', 'cochecito',
      'strollers', 'stroller',
      'poussettes', 'poussette',
      'bebek-arabalar'
    ]
  }, {
    it: 'Seggiolini Auto',
    en: 'Car Seats',
    fr: 'Sièges Auto',
    es: 'Asientos de Coche',
    tr: 'Oto Koltukları',
    code: 'seggiolini',
    keywords: [
      'seggiolini',
      'seggiolini-auto', 'seggiolino auto', 'seggiolino', 'ovetto', 'seggiolini-auto',
      'asientos-de-coche', 'asiento de coche',
      'car-seats', 'car-seat', 'car', 'auto seat', 'seggiolino-auto', 'pram-infant-car-seat',
      'sieges-auto', 'sieges auto', 'sieges-auto',
      'oto-koltuklar'
    ]
  }, {
    it: 'Culle e Lettini',
    en: 'Cribs & Cots',
    fr: 'Lits et Berceaux',
    es: 'Cunas y Camas',
    tr: 'Beşikler ve Park Yataklar',
    code: 'culle_lettini',
    keywords: [
      'culle_lettini',
      'culle-e-lettini', 'culle e lettini', 'culle', 'culle e lettini', 'lettino', 'lettini',
      'cunas-y-camas', 'cunas', 'camas',
      'cribs-and-cots', 'cribs', 'cots', 'co-sleeper-bassinet', 'light-portable-cot',
      'lits-et-berceaux',
      'besikler-ve-park-yataklar'

    ]
  }, {
    it: 'Sdraiette',
    en: 'Bouncers',
    fr: 'Videurs',
    es: 'Gorilas',
    tr: 'Ana Kucakları',
    code: 'sdraiette',
    keywords: [
      'sdraiette',
      'sdraietta',
      'gorilas',
      'bouncers',
      'videurs',
      'ana-kucaklar'
    ]
  }, {
    it: 'Sistemi Modulari',
    en: 'Modular Systems',
    fr: 'Systèmes Modulaires',
    es: 'Sistemas Modulares',
    tr: 'Modüler Sistem Bebek Arabaları',
    code: 'sistemi_modulari',
    keywords: [
      'sistemi_modulari',
      'sistemi-modulari', 'sistemi modulari', 'sistema modulare', 'trio',
      'sistemas-modulares', 'sistema modulare',
      'modular-systems', 'modular system',
      'systemes-modulaires', 'systemes-modulaires', 'systemes modulaires',
      'moduler-sistem-bebek-arabalar'
    ]
  }, {
    it: 'Marsupi e Zaini',
    en: 'Bum Bags & Backpacks',
    fr: 'Sacs banane et sacs à dos',
    es: 'Riñoneras y Mochilas',
    tr: 'Kanguru ve Sırt Çantaları',
    code: 'marsupi_zaini',
    keywords: [
      'marsupi_zaini',
      'marsupi-e-zaini', 'marsupi e zaini', 'marsupi', 'zaini', 'marsupio', 'zaino',
      'rinoneras-y-mochilas', 'riñoneras', 'mochilas', 'mochila', 'riñonera',
      'bum-bags-and-backpacks',
      'sacs banane et sacs à dos', 'sacs-banane-et-sacs-a-dos',
      'kanguru-ve-srt-cantalar'
    ]
  }, {
    it: 'Seggioloni',
    en: 'Highchairs',
    fr: 'Chaises hautes',
    es: 'Tronas',
    tr: 'Mama Sandalyeleri',
    code: 'seggioloni',
    keywords: [
      'seggioloni',
      'seggiolone',
      'tronas',
      'highchairs',
      'chaises hautes', 'chaises-hautes',
      'mama-sandalyeleri'
    ]
  }, {
    it: 'Box Gioco e Recinto',
    en: 'Play Box & Fence',
    fr: 'Boîte de jeu et Clôture',
    es: 'Cajas de Juego',
    tr: 'Oyun Alanı ve Oyun Çiti',
    code: 'box_recinto',
    keywords: [
      'box_recinto',
      'box-gioco-e-recinto', 'box gioco e recinto', 'box gioco', 'recinto',
      'cajas-de-juego',
      'play-box-and-fence',
      'boite-de-jeu-et-cloture', 'boîte de jeu et clôture', 'boîte-de-jeu-et-clôture',
      'oyun-alan-ve-oyun-citi'
    ]
  }, {
    it: 'Giocatoli e Libri',
    en: 'Toys & Books',
    fr: 'Jouets et livres',
    es: 'Juguetes y libros',
    tr: 'Oyuncaklar ve Kitaplar',
    code: 'giocatoli_libri',
    keywords: [
      'giocatoli_libri',
      'giocatoli-e-libri', 'giocatoli e libri', 'giocatoli', 'libri', 'giocatoli e libri',
      'juguetes-y-libros', 'juguetes', 'libros',
      'toys-and-books',
      'jouets et livres', 'jouets-et-livres',
      'oyuncaklar-ve-kitaplar'
    ]
  }, {
    it: 'Vestiti di Carnevale',
    en: 'Carnival Clothes',
    fr: 'Vêtements de carnaval',
    es: 'Ropa de Carnaval',
    tr: 'Festival Kıyafetleri',
    code: 'vestiti_carnevale',
    keywords: [
      'vestiti_carnevale',
      'vestiti-di-carnevale', 'vestiti di carnevale', 'vestito carnevale', 'vestito carnevale',
      'ropa-de-carnaval', 'carnevale',
      'carnival-clothes',
      'vetements-de-carnaval', 'vêtements-de-carnaval',
      'festival-kyafetleri'

    ]
  }, {
    it: 'Allattamento',
    en: 'Breastfeeding',
    fr: 'Allaiter',
    es: 'Lactancia',
    tr: 'Emzirme Ürünleri',
    code: 'allattamento',
    keywords: [
      'allattamento',
      'allattamenti',
      'lactancia',
      'breastfeeding',
      'allaiter',
      'emzirme-urunleri'
    ]
  }, {
    it: 'Benessere',
    en: 'Wellness',
    fr: 'Bien-être',
    es: 'Bienestar',
    tr: 'Bebek Sağlık Ürünleri',
    code: 'benessere',
    keywords: [
      'benessere',
      'benesseri',
      'bienestar',
      'wellness',
      'bien-etre', 'bien-être',
      'bebek-saglk-urunleri'
    ]
  }, {
    it: 'E-Bikes',
    en: 'E-Bikes',
    fr: 'E-Bikes',
    es: 'E-Bikes',
    tr: 'E-Bikes',
    code: 'ebikes',
    keywords: ['e-bikes', 'ebikes', 'bici elettronica']
  }, {
    it: 'Articoli Stagionali',
    en: 'Outdoor & Seasonal',
    fr: 'Articles Saisonniers',
    es: 'Artículos de Temporada',
    tr: 'Açık Hava ve Sezonluk Ürünler',
    code: 'outdoor_seasonal',
    keywords: [
      'outdoor_seasonal',
      'articoli-stagionali', 'articoli stagionali', 'articolo stagionale', 'stagionale',
      'articulos-de-temporada',
      'outdoor-and-seasonal',
      'articles-saisonniers',
      'ack-hava-ve-sezonluk-urunler'
    ]
  }, {
    it: 'Pacchetti',
    en: 'Packages',
    fr: 'Paquets',
    es: 'Paquetes',
    tr: 'Setler',
    code: 'packages',
    keywords: [
      'pacchetti',
      'pacchetto',
      'paquetes',
      'packages',
      'paquets',
      'setler'
    ]
  }, {
    it: 'Altro',
    en: 'Other',
    fr: 'Autre',
    es: 'Otro',
    tr: 'Diğer Ürünler',
    code: 'other',
    keywords: [
      'other',
      'altri prodotti', 'altri',
      'otro',
      'other',
      'diğer-ürünler'
    ]
  }
];

const Filter_Array = [
  {
    it: 'Passeggini',
    en: 'Strollers',
    fr: 'Poussettes',
    es: 'Cochecitos',
    tr: 'Bebek Arabaları',
    de: 'Kinderwagen',
    ar: 'عربات الأطفال',
    ja: 'ベビーカー',
    zh: '童車',
    code: 'passeggini',
    sub: 'strollers',
    icon: '/assets/img/icons/stroller.svg',
    keywords: [
      'passeggini',
      'passeggino', 'passeggio',
      'cochecitos', 'cochecito',
      'strollers', 'stroller',
      'poussettes', 'poussette',
      'bebek-arabalar'
    ]
  },
  {
    it: 'Culle e Lettini',
    en: 'Cribs & Cots',
    fr: 'Lits et Berceaux',
    es: 'Cunas y Camas',
    tr: 'Beşikler ve Park Yataklar',
    de: 'Krippen & Kinderbetten',
    ar: 'أسرّة وأسرّة أطفال',
    ja: 'ベビーベッド＆ベビーベッド',
    zh: '嬰兒床和帆布床',
    code: 'culle_lettini',
    sub: 'cribs_cots',
    icon: '/assets/img/icons/cribs.svg',
    keywords: [
      'culle_lettini',
      'culle-e-lettini', 'culle e lettini', 'culle', 'culle e lettini', 'lettino', 'lettini',
      'cunas-y-camas', 'cunas', 'camas',
      'cribs-and-cots', 'cribs', 'cots', 'co-sleeper-bassinet', 'light-portable-cot',
      'lits-et-berceaux', 'cribs_cots',
      'besikler-ve-park-yataklar',
      'box_recinto',
      'box-gioco-e-recinto', 'box gioco e recinto', 'box gioco', 'recinto',
      'cajas-de-juego',
      'play-box-and-fence',
      'boite-de-jeu-et-cloture', 'boîte de jeu et clôture', 'boîte-de-jeu-et-clôture',
      'oyun-alan-ve-oyun-citi'

    ]
  },
  {
    it: 'Seggiolini Auto',
    en: 'Car Seats',
    fr: 'Sièges Auto',
    es: 'Asientos de Coche',
    tr: 'Oto Koltukları',
    de: 'Autositze',
    ar: 'مقاعد السيارة',
    ja: 'カーシート',
    zh: '汽車座椅',
    code: 'seggiolini',
    sub: 'car_seats',
    icon: '/assets/img/icons/car-seats.svg',
    keywords: [
      'seggiolini',
      'seggiolini-auto', 'seggiolino auto', 'seggiolino', 'ovetto', 'seggiolini-auto',
      'asientos-de-coche', 'asiento de coche',
      'car-seats', 'car-seat', 'car', 'auto seat', 'seggiolino-auto', 'pram-infant-car-seat',
      'sieges-auto', 'sieges auto', 'sieges-auto',
      'oto-koltuklar', 'carseats', 'carseat', 'car_seats'
    ]
  },
  {
    it: 'Giocatoli e Libri',
    en: 'Toys & Books',
    fr: 'Jouets et livres',
    es: 'Juguetes y libros',
    tr: 'Oyuncaklar ve Kitaplar',
    de: 'Spielzeug & Bücher',
    ar: 'الألعاب والكتب',
    ja: 'おもちゃと本',
    zh: '玩具和書籍',
    code: 'giocatoli_libri',
    sub: 'toys',
    icon: '/assets/img/icons/toys.svg',
    keywords: [
      'giocatoli_libri',
      'giocatoli-e-libri', 'giocatoli e libri', 'giocatoli', 'libri', 'giocatoli e libri',
      'juguetes-y-libros', 'juguetes', 'libros',
      'toys-and-books',
      'jouets et livres', 'jouets-et-livres',
      'oyuncaklar-ve-kitaplar', 'toys'
    ]
  },
  {
    it: 'Vestiti',
    en: 'Outwear',
    fr: 'Vêtements',
    es: 'Ropa',
    tr: 'Kıyafetleri',
    de: 'Oberbekleidung',
    ar: 'أبلى',
    ja: '生き抜く',
    zh: '外套',
    code: 'outwear',
    sub: 'outwear',
    icon: '/assets/img/icons/outerwear.svg',
    keywords: [
      'vestiti_carnevale',
      'vestiti-di-carnevale', 'vestiti di carnevale', 'vestito carnevale', 'vestito carnevale',
      'ropa-de-carnaval', 'carnevale',
      'carnival-clothes',
      'vetements-de-carnaval', 'vêtements-de-carnaval',
      'festival-kyafetleri', 'outwear', 'ropa', 'vetements', 'vêtements', 'oberbekleidung',
      'vestiti'

    ]
  },
  {
    it: 'Sdraiette e Marsupi',
    en: 'Carriers & Bouncers',
    fr: 'Videurs et Sacs banane',
    es: 'Gorilas y Riñoneras',
    tr: 'Ana Kucakları',
    de: 'Träger',
    ar: 'الناقلون',
    ja: 'キャリア',
    zh: '運營商',
    code: 'sdraiette',
    sub: 'carriers',
    icon: '/assets/img/icons/carriers.svg',
    keywords: [
      'sdraiette',
      'sdraietta',
      'gorilas',
      'bouncers',
      'videurs',
      'ana-kucaklar',
      'carriers',
      'marsupi_zaini',
      'marsupi-e-zaini', 'marsupi e zaini', 'marsupi', 'zaini', 'marsupio', 'zaino',
      'rinoneras-y-mochilas', 'riñoneras', 'mochilas', 'mochila', 'riñonera',
      'bum-bags-and-backpacks',
      'sacs banane et sacs à dos', 'sacs-banane-et-sacs-a-dos',
      'kanguru-ve-srt-cantalar', 'carriers-and-bouncers', 'sdraiette-e-marsupi', 'videurs-et-sacs-banane',
      'gorilas-y-riñoneras', 'gorilas-y-rinoneras'
    ]
  },
  {
    it: 'Seggioloni',
    en: 'Highchairs',
    fr: 'Chaises hautes',
    es: 'Tronas',
    tr: 'Mama Sandalyeleri',
    de: 'Hohe Stühle',
    ar: 'الكراسي العالية',
    ja: 'ハイチェア',
    zh: '高腳椅',
    code: 'seggioloni',
    sub: 'highchairs',
    icon: '/assets/img/icons/highchairs.svg',
    keywords: [
      'seggioloni',
      'seggiolone',
      'tronas',
      'highchairs',
      'chaises hautes', 'chaises-hautes',
      'mama-sandalyeleri',
      'highchairs'
    ]
  },
  {
    it: 'Allattamento',
    en: 'Breastfeeding',
    fr: 'Allaiter',
    es: 'Lactancia',
    tr: 'Emzirme Ürünleri',
    de: 'Stillen',
    ar: 'الرضاعة الطبيعية',
    ja: '母乳育児',
    zh: '哺乳',
    code: 'allattamento',
    sub: 'breastfeeding',
    icon: '/assets/img/icons/baby-food.svg',
    keywords: [
      'allattamento',
      'allattamenti',
      'lactancia',
      'breastfeeding',
      'allaiter',
      'emzirme-urunleri'
    ]
  }, 
  {
    it: 'Benessere',
    en: 'Wellness',
    fr: 'Bien-être',
    es: 'Bienestar',
    tr: 'Bebek Sağlık Ürünleri',
    de: 'Wohlbefinden',
    ar: 'صحة',
    ja: 'ウェルネス',
    zh: '健康',
    code: 'benessere',
    sub: 'wellness',
    icon: '/assets/img/icons/wellness.svg',
    keywords: [
      'benessere',
      'benesseri',
      'bienestar',
      'wellness',
      'bien-etre', 'bien-être',
      'bebek-saglk-urunleri'
    ]
  },
  {
    it: 'Consumabile',
    en: 'Consumable',
    fr: 'Consommable',
    es: 'Consumible',
    tr: 'Oyun Alanı ve Oyun Çiti',
    de: 'Verbrauchsmaterial',
    ar: 'مستهلك',
    ja: '消耗品',
    zh: '耗材',
    code: 'consumable',
    sub: 'consumable',
    icon: '/assets/img/icons/diapers.svg',
    keywords: [
      'diapers',
      'baby-food', 'couches',
      'consumable', 'consumabile', 'consommable',
      'verbrauchsmaterial', 'consumible'

    ]
  }
];

const Press_Array = [
  {
    name: 'Forbes',
    icon: '/assets/press/babonbo-forbes-us.jpg',
    url: 'https://www.forbes.com/sites/garydrenik/2021/10/28/how-renting-baby-equipment-is-saving-time-money-and-the-environment/?sh=706cf56547a6'
  },
  {
    name: 'La Repubblica Milano',
    icon: '/assets/press/babonbo-repubblica-it.jpg',
    url: 'https://milano.repubblica.it/cronaca/2020/02/19/news/passeggini_e_seggioloni_per_i_baby_turisti_una_star_up_per_trovarli_in_citta_-248996615/'
  },
  {
    name: 'Il Sole 24 ore',
    icon: '/assets/press/babonbo-il-sole-24-ore-it.jpg',
    url: 'https://lab24.ilsole24ore.com/imprese-femminili-prima-dopo-durante-coronavirus/'
  },
  {
    name: 'Millionaire.it',
    icon: '/assets/press/babonbo-millionaire-it.jpg',
    url: 'https://www.millionaire.it/baby-ecco-tutto-quello-che-serve/'
  },
  {
    name: 'Corriere della Sera',
    icon: '/assets/press/babonbo-corriere-della-sera-it.jpg',
    url: ''
  },
  {
    name: 'Radio 105',
    icon: '/assets/press/babonbo-radio-105.jpg',
    url: 'https://www.105.net/audio/105-start-up/1329198/hai-perso-105-start-up-riascolta-la-storia-di-duygu-sefa-ceo-e-founder-di-babonbo.html'
  },
  {
    name: 'ParkingGo',
    icon: '/assets/press/babonbo-parkingo-it.jpg',
    url: ''
  },
  {
    name: 'Founders Institute',
    icon: '/assets/press/babonbo-founders-institute-it.jpg',
    url: ''
  },
  {
    name: 'Polihub',
    icon: '/assets/press/babonbo-polihub-it.jpg',
    url: ''
  },
  {
    name: 'Medium',
    icon: '/assets/press/babonbo-medium-uk.jpg',
    url: 'https://medium.com/authority-magazine/female-disruptors-duygu-sefa-of-babonbo-on-the-three-things-you-need-to-shake-up-your-industry-b410aaec75d1'
  },
  {
    name: 'Eluxe Magazine',
    icon: '/assets/press/babonbo-eluxe-magazine-us.jpg',
    url: 'https://eluxemagazine.com/culture/why-baby-gear-rental-sites-are-exploding/'
  },
  {
    name: 'Trendhunter',
    icon: '/assets/press/babonbo-trendhunter-us.jpg',
    url: 'https://www.trendhunter.com/trends/babonbo'
  },
  {
    name: 'Corriere Nazionale',
    icon: '/assets/press/babonbo-corriere-nazionale-it.jpg',
    url: 'https://www.corrierenazionale.it/2022/07/24/in-vacanza-con-i-bimbi-a-bordo-babonbo-riduce-gli-sprechi/amp/'
  },
  {
    name: 'Smart Life',
    icon: '/assets/press/babonbo-smart-life-it.jpg',
    url: 'https://www.01smartlife.it/babonbo-il-marketplace-che-facilita-le-vacanze-con-bambini/'
  },
  {
    name: 'Syrus Today',
    icon: '/assets/press/babonbo-syrus-us.jpg',
    url: 'https://syrus.today/interview-with-duygu-sefa-ceo-of-babonbo-25275.html'
  },
  {
    name: 'Italia Economy',
    icon: '/assets/press/babonbo-italia-economy-it.jpg',
    url: 'https://italiaeconomy.it/ecco-babonbo-la-startup-che-facilita-le-vacanze-con-bebe-a-bordo-e-riduce-gli-sprechi/'
  },
  {
    name: 'Re2Bit',
    icon: '/assets/press/babonbo-re2bit-it.jpg',
    url: 'https://re2bit.com/2022/06/28/al-via-babonbo-marketplace-che-facilita-le-vacanze-con-i-bebe/'
  },
  {
    name: 'Quantity Travel',
    icon: '/assets/press/babonbo-quality-travel-it.jpg',
    url: 'https://www.qualitytravel.it/babonbo-la-startup-che-facilita-le-vacanze-con-bebe-a-bordo-e-riduce-gli-sprechi/112066'
  },
  {
    name: 'Milano Biz',
    icon: '/assets/press/babonbo-milano-biz-it.jpg',
    url: 'https://www.milanobiz.it/vacanze-con-bambini-piccoli-comfort-sicurezza/'
  },
  {
    name: 'Green City',
    icon: '/assets/press/babonbo-green-city-it.jpg',
    url: 'https://www.greencity.it/green-life/14467/babonbo-il-marketplace-che-facilita-le-vacanze-con-bebe-a-bordo-e-riduce-gli-sprechi.html'
  },

]

const Ages_Array = [
  {
    it: 'Prematuro',
    en: 'Premature',
    fr: 'Premature',
    es: 'Prematuro',
    code: 'premature',
    min: 0,
    max: 45
  },
  {
    it: 'Nascita',
    en: 'Birth',
    fr: 'Naissance',
    es: 'Nacimiento',
    code: 'birth',
    min: 45,
    max: 55
  },
  {
    it: '1 mese',
    en: '1 month',
    fr: '1 mois',
    es: '1 mes',
    code: '1_m',
    min: 50,
    max: 55
  },
  {
    it: '3 mesi',
    en: '3 months',
    fr: '3 mois',
    es: '3 meses',
    code: '3_m',
    min: 54,
    max: 60
  },
  {
    it: '6 mesi',
    en: '6 months',
    fr: '6 mois',
    es: '6 meses',
    code: '6_m',
    min: 60,
    max: 66
  },
  {
    it: '9 mesi',
    en: '9 months',
    fr: '9 mois',
    es: '9 meses',
    code: '9_m',
    min: 67,
    max: 74
  },
  {
    it: '12 mesi',
    en: '12 months',
    fr: '12 mois',
    es: '12 meses',
    code: '12_m',
    min: 74,
    max: 80
  },
  {
    it: '18 mesi',
    en: '18 months',
    fr: '18 mois',
    es: '18 meses',
    code: '18_m',
    min: 80,
    max: 86
  },
  {
    it: '24 mesi',
    en: '24 months',
    fr: '24 mois',
    es: '24 meses',
    code: '24_m',
    min: 86,
    max: 94
  },
  {
    it: '3 anni',
    en: '3 years',
    fr: '3 ans',
    es: '3 años',
    code: '3_y',
    min: 94,
    max: 102
  },
  {
    it: '4 anni',
    en: '4 years',
    fr: '4 ans',
    es: '4 años',
    code: '4_y',
    min: 102,
    max: 108
  },
  {
    it: '5 anni',
    en: '5 years',
    fr: '5 ans',
    es: '5 años',
    code: '5_y',
    min: 108,
    max: 116
  },
  {
    it: '6 anni',
    en: '6 years',
    fr: '6 ans',
    es: '6 años',
    code: '6_y',
    min: 116,
    max: 123
  },
  {
    it: '8 anni',
    en: '8 years',
    fr: '8 ans',
    es: '8 años',
    code: '8_y',
    min: 123,
    max: 128
  },
  {
    it: '10 anni',
    en: '10 years',
    fr: '10 ans',
    es: '10 años',
    code: '10_y',
    min: 128,
    max: 140
  },
  {
    it: '12 anni',
    en: '12 years',
    fr: '12 ans',
    es: '12 años',
    code: '12_y',
    min: 140,
    max: 152
  },
  {
    it: '14 anni',
    en: '14 years',
    fr: '14 ans',
    es: '14 años',
    code: '14_y',
    min: 152,
    max: 164
  },
  {
    it: '16 anni',
    en: '16 years',
    fr: '16 ans',
    es: '16 años',
    code: '16_y',
    min: 164,
    max: 176
  }

];

const Times_Array = [
 
  {
    en: '8 - 10 AM',
    it: '8:00 - 10:00',
    es: '8:00 - 10:00',
    fr: '8:00 - 10:00',
    code: '8_10_AM',
  },
  {
    en: '10 - 12 PM',
    it: '10:00 - 12:00',
    es: '10:00 - 12:00',
    fr: '10:00 - 12:00',
    code: '10_12_PM',
  },
  {
    en: '12 - 2 PM',
    it: '12:00 - 14:00',
    es: '12:00 - 14:00',
    fr: '12:00 - 14:00',
    code: '12_2_PM',
  },
  {
    en: '2 - 4 PM',
    it: '14:00 - 16:00',
    es: '14:00 - 16:00',
    fr: '14:00 - 16:00',
    code: '2_4_PM',
  },
  {
    en: '4 - 6 PM',
    it: '16:00 - 18:00',
    es: '16:00 - 18:00',
    fr: '16:00 - 18:00',
    code: '4_6_PM',
  },
  {
    en: '6 - 8 PM',
    it: '18:00 - 20:00',
    es: '18:00 - 20:00',
    fr: '18:00 - 20:00',
    code: '6_8_PM',
  }

]

const onCheckCityRequest = (req_body) => {

  var loc_city = req_body.city || '';
  var loc_country = req_body.country || '';
  var loc_lang = req_body.lang || '';
  var loc_category = req_body.category || 'all';

  var it = '';
  var en = '';
  var fr = '';
  var es = '';
  var tr = '';

  //Category Check Middleware
  if (loc_country) {
    let CAT_INDEX = Categories_Array.findIndex((item) => item.keywords.includes(loc_country.toLowerCase()));

    if (CAT_INDEX != -1) {
      let CAT_FOUND = Categories_Array[CAT_INDEX] || {};

      it = CAT_FOUND.it;
      fr = CAT_FOUND.fr;
      en = CAT_FOUND.en;
      es = CAT_FOUND.es;
      tr = CAT_FOUND.tr;

      loc_category = CAT_FOUND.code;
      loc_country = null;


    }
    else {

      let CAT_INDEX = Categories_Array.findIndex((item) => item.keywords.includes(loc_category.toLowerCase()));

      if (CAT_INDEX == -1) {
        loc_category = 'all';
      }

      let CAT_FOUND = Categories_Array[CAT_INDEX] || {};

      it = CAT_FOUND.it;
      fr = CAT_FOUND.fr;
      en = CAT_FOUND.en;
      es = CAT_FOUND.es;
      tr = CAT_FOUND.tr;

      loc_category = CAT_FOUND.code;

    }
  }
  else {


    let CAT_INDEX = Categories_Array.findIndex((item) => item.keywords.includes(loc_category.toLowerCase()));

    if (CAT_INDEX == -1) {
      loc_category = 'all';
    }

    let CAT_FOUND = Categories_Array[CAT_INDEX] || {};

    it = CAT_FOUND.it;
    fr = CAT_FOUND.fr;
    en = CAT_FOUND.en;
    es = CAT_FOUND.es;
    tr = CAT_FOUND.tr;

    loc_category = CAT_FOUND.code;


  }


  return {
    city: loc_city,
    country: loc_country,
    category: loc_category,
    lang: loc_lang,
    it: it,
    en: en,
    es: es,
    fr: fr,
    tr: tr
  }

}

const onCheckRequest = (req_body) => {

  var loc_city = req_body.city || '';
  var loc_country = req_body.country || '';
  var loc_lang = req_body.lang || '';
  var loc_category = req_body.category || 'all';

  var it = '';
  var en = '';
  var fr = '';
  var es = '';
  var tr = '';

  //Category Check Middleware
  if (loc_country) {
    let CAT_INDEX = Filter_Array.findIndex((item) => item.keywords.includes(loc_country.toLowerCase()));

    if (CAT_INDEX != -1) {
      let CAT_FOUND = Filter_Array[CAT_INDEX] || {};

      it = CAT_FOUND.it;
      fr = CAT_FOUND.fr;
      en = CAT_FOUND.en;
      es = CAT_FOUND.es;
      tr = CAT_FOUND.tr;

      loc_category = CAT_FOUND.code;
      loc_country = null;


    }
    else {

      let CAT_INDEX = Filter_Array.findIndex((item) => item.keywords.includes(loc_category.toLowerCase()));

      if (CAT_INDEX == -1) {
        loc_category = 'all';
      }

      let CAT_FOUND = Filter_Array[CAT_INDEX] || {};

      it = CAT_FOUND.it;
      fr = CAT_FOUND.fr;
      en = CAT_FOUND.en;
      es = CAT_FOUND.es;
      tr = CAT_FOUND.tr;

      loc_category = CAT_FOUND.code;

    }
  }
  else {


    let CAT_INDEX = Categories_Array.findIndex((item) => item.keywords.includes(loc_category.toLowerCase()));

    if (CAT_INDEX == -1) {
      loc_category = 'all';
    }

    let CAT_FOUND = Categories_Array[CAT_INDEX] || {};

    it = CAT_FOUND.it;
    fr = CAT_FOUND.fr;
    en = CAT_FOUND.en;
    es = CAT_FOUND.es;
    tr = CAT_FOUND.tr;

    loc_category = CAT_FOUND.code;


  }


  return {
    city: loc_city,
    country: loc_country,
    category: loc_category,
    lang: loc_lang,
    it: it,
    en: en,
    es: es,
    fr: fr,
    tr: tr
  }

}

const onTrimArrayDic = (obj) => {

  if (!obj) {
    return [];
  }

  let OBX = obj || [];

  if (Array.isArray(obj)) {
    OBX = obj || [];
  }
  else {
    OBX = [obj];
  }

  return OBX
}

const onTrimCoordinates = (latitude, longitude) => {

  let LATITUDE = parseFloat(latitude) || 0;
  let LONGITUDE = parseFloat(longitude) || 0;

  let NS = LATITUDE >= 0 ? 'N' : 'S';
  let EW = LONGITUDE >= 0 ? 'E' : 'W';

  return Math.abs(LATITUDE).toFixed(3) + ' ' + NS + ' / ' + Math.abs(LONGITUDE).toFixed(3) + ' ' + EW;

}

const onTrimTimer = (totalSeconds) => {

  var sec_num = parseInt(totalSeconds, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }

  return hours + ':' + minutes + ':' + seconds;

}

const onTrimClock = (unixTimestamp) => {
  var date = new Date(unixTimestamp * 1000);
  return date.toLocaleTimeString("it-IT")
}

const onTrimMapCenter = (markers) => {
  return {
    lat: getMiddle('latitude', markers),
    lng: getMiddle('longitude', markers)
  }
}

const onTrimMapPoints = (markers, counter) => {

  if(counter == 0){
    return markers.map((item)=> {
      return {
        lat: item.latitude,
        lon: item.longitude
      }
    })
  }

  if(counter == markers.length-1){
    return markers.map((item)=> {
      return {
        lat: item.latitude,
        lon: item.longitude
      }
    })
  }
  return markers.slice(0, counter-1).map((item)=> {
    return {
      lat: item.latitude,
      lon: item.longitude
    }
  })
}

const onTrimNumberDate = (num) => {
  return num;
}

const onTrimDateDifference = (date1, date2) => {
  var diff = Math.abs(date1 - date2) / 1000; // Difference in seconds

  var days = Math.floor(diff / (24 * 60 * 60));
  diff -= days * 24 * 60 * 60;

  var hours = Math.floor(diff / (60 * 60));
  diff -= hours * 60 * 60;

  var minutes = Math.floor(diff / 60);
  diff -= minutes * 60;

  var seconds = Math.floor(diff);

  var string = onTrimNumberDate(days) + " days, " 
  + onTrimNumberDate(hours) + " hours, " 
  + onTrimNumberDate(minutes) + " minutes, " 
  + onTrimNumberDate(seconds) +" seconds"

  return {
    string: string,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

const getMiddle = (prop, markers) => {
  let values = markers.map(m => m[prop]);
  let min = Math.min(...values);
  let max = Math.max(...values);
  if (prop === 'latitude' && (max - min > 180)) {
    values = values.map(val => val < max - 180 ? val + 360 : val);
    min = Math.min(...values);
    max = Math.max(...values);
  }
  let result = (min + max) / 2;
  if (prop === 'longitude' && result > 180) {
    result -= 360
  }
  return result;
}


export {
  Currencies_Array,
  Languages_Array,
  Cities_Array,
  Categories_Array,
  Filter_Array,
  Press_Array,
  Ages_Array,
  Times_Array,
  onCheckCityRequest,
  onCheckRequest,
  onTrimArrayDic,
  onTrimClock,
  onTrimCoordinates,
  onTrimTimer,
  onTrimDateDifference,
  onTrimMapCenter,
  onTrimMapPoints,
  onTrimNumberDate
}