import React, { useEffect, useState, useRef } from 'react';

import LoaderMaster from '../Loaders/Loaders';

import {AddOutline, EyeOutline, BodyOutline, CalendarOutline, GlobeOutline, ShieldCheckmarkOutline, CashOutline, SchoolOutline, CopyOutline, MegaphoneOutline, ChevronUpOutline, ShirtOutline, ReaderOutline, ChatbubbleEllipsesOutline, StarOutline, LogOutOutline, LogInOutline, AirplaneOutline, BarcodeOutline, CardOutline, CheckmarkCircleSharp, ImageOutline, LocationOutline, LogoEuro, MailOutline, MapOutline, MedkitOutline, PhonePortraitOutline, StorefrontOutline, WarningSharp, HandLeftOutline, LogoApple, LogoAmazon, LocateOutline, TrendingUpOutline, PencilOutline, ArrowBackOutline, AnalyticsOutline, SendOutline} from 'react-ionicons'
import ReactCountryFlag from 'react-country-flag';

import { Link } from 'react-router-dom';

import swal from 'sweetalert';

const AdminRoot = (props) => {

    const {USER, TRANSLATE, history} = props;

    const [isLoading, setLoading] = useState(true);
  
    useEffect(()=>{ 
  
      console.log("USER ===> ", USER);

        setLoading(false)

        if(USER.admin){
            
        }
        else{
            setLoading(false)
            swal('You\'re not Authorized', 'You are not authorized to be here', 'error')
        
            history.push('/logout')
           
        }
      
    }, []);

  
    if(isLoading){
      return(
          <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
      );
    }

    return (
        <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10">
            
            {USER.admin ? (
                <>  
                    <div className='row px-xl-5 px-2 mx-2 mb-8 justify-content-center'>
                        <div style={{ fontSize: 60, fontWeight: 100 }}>Marketing <span style={{ fontWeight: 'bold' }}>Area</span></div>
                    </div>
                    <MenuAdmin {...props} />
                </>
            ) : (
                <>
                    {USER.partner ? (
                        <>
                            <div className='row px-xl-5 px-2 mx-2 mb-8 justify-content-center'>
                                <div style={{ fontSize: 60, fontWeight: 100 }}>Marketing <span style={{ fontWeight: 'bold' }}>Area</span></div>
                            </div>
                            <MenuPartner {...props} />
                        </>
                    ) : (
                        <>
                            {/* <h1>Not Authorized</h1> */}
                        </>
                    )}
                </>
            )}
                   
        </div>
    );
    
}



const MenuAdmin = (props) => {

    const {TRANSLATE, USER} = props;

    let MenuClass = 'col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6 mb-4';
    let MenuStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        fontSize: 18,
        fontWeight: 'bold',
        width: '100%',
        background: '#fff',
        paddingTop: 20,
        paddingBottom: 20
    }

    return (
        <div className='row m-0 p-0 px-5 justify-content-center' style={{width:'100%'}}>
    
                <div className={MenuClass}>
                    <Link to="/admin/push" className='bg-light' style={MenuStyle}>
                        <SendOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Push Sender
                    </Link>
                </div>

                <div className={MenuClass}>
                    <Link to="/admin/coupons" className='bg-light' style={MenuStyle}>
                        <BarcodeOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Coupons
                    </Link>
                </div>

                <div className={MenuClass}>
                    <Link to="/admin/sitemap" className='bg-light' style={MenuStyle}>
                        <LocateOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Sitemap
                    </Link>
                </div>

                <div className={MenuClass}>
                    <Link to="/admin/referrals" className='bg-light' style={MenuStyle}>
                        <TrendingUpOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Referrals
                    </Link>
                </div>

                <div className={MenuClass}>
                    <Link to="/admin/blog" className='bg-light' style={MenuStyle}>
                        <PencilOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Blog
                    </Link>
                </div>

                <div className={MenuClass}>
                    <Link to="/admin/resources" className='bg-light' style={MenuStyle}>
                        <SchoolOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                         Resources
                    </Link>
                </div>

               

                
                <div className={MenuClass}>
                    <Link to="/" className='bg-light' style={MenuStyle}>
                        <ArrowBackOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Go Back
                    </Link>
                </div>
                

        </div>
    )
}

const MenuPartner = (props) => {

    const {TRANSLATE, USER} = props;

    let MenuClass = 'col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
    let MenuStyle = {
        borderRadius: 20,
        fontSize: 24,
        fontWeight: 'bold'
    }
    
    let partner_id = USER.partner;

    return (
        <>
           {partner_id && (
                <div className='row px-xl-5 px-2 mb-5 justify-content-center'>
                
                        <Link to={'/partners/'+partner_id+'/stores'} className={MenuClass} style={MenuStyle}>
                            <StorefrontOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                            {TRANSLATE.menu_stores}
                        </Link>

                        <Link to={'/partners/'+partner_id+'/products'} className={MenuClass} style={MenuStyle}>
                            <ShirtOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                            {TRANSLATE.menu_products}
                        </Link>

                        <Link to={'/partners/'+partner_id+'/orders'} className={MenuClass} style={MenuStyle}>
                            <ReaderOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                            {TRANSLATE.menu_orders}
                        </Link>

                        <Link to={'/partners/'+partner_id+'/inbox'} className={MenuClass} style={MenuStyle}>
                            <ChatbubbleEllipsesOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                            {TRANSLATE.menu_messages}
                        </Link>

                        <Link to={'/partners/'+partner_id+'/reviews'} className={MenuClass} style={MenuStyle}>
                            <StarOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                            {TRANSLATE.reviews}
                        </Link>

                        {USER.name ? (
                            <Link to="/logout" className={MenuClass} style={MenuStyle}>
                                <LogOutOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                                {TRANSLATE.logout}
                            </Link>
                        ) : (
                            <Link to="/login" className={MenuClass} style={MenuStyle}>
                                <LogInOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                                {TRANSLATE.login}
                            </Link>
                        )}

                </div>

            )}

        </>
    )
}


const HeaderComponent = ({history, DASHBOARD = {}, TRANSLATE}) => {

  
    //Checklist  
    const CHECK_PERCENTAGE = DASHBOARD.percentage || 0;
    const CHECK_STATUS = DASHBOARD.status || false;
  
    const CHECK_LIST = DASHBOARD.checklist || {};
    const check_name = CHECK_LIST.name || false;
    const check_email = CHECK_LIST.email || false;
    const check_address = CHECK_LIST.address || false;
    const check_photo = CHECK_LIST.photo || false;
    const check_about = CHECK_LIST.about || false;
    const check_phone = CHECK_LIST.phone || false;
    const check_fiscal = CHECK_LIST.fiscal || false;
    const check_bank = CHECK_LIST.bank || false;
    const check_product = CHECK_LIST.product || false;
    const check_delivery = CHECK_LIST.delivery || false;
    const check_terms = CHECK_LIST.terms || false;
    const check_currency = CHECK_LIST.currency || false;
  
    const CAN_ACTIVATE = CHECK_PERCENTAGE == 100;
  
    const onSelectCheckItem = (index) => {
  
        if(index == 0){
           history.push('/dashboard/name');
        }
        else if (index == 1){
          console.log("EMAIL ===> ");
        }
        else if (index == 2){
            history.push('/dashboard/location');
        }
        else if (index == 3){
            history.push('/dashboard/photo');
        }
        else if (index == 4){
            history.push('/dashboard/description');
        }
        else if (index == 5){
            history.push('/dashboard/phone');
        }
        else if (index == 6){
            history.push('/dashboard/fiscal');
        }
        else if (index == 7){
            history.push('/dashboard/bank');
        }
        else if (index == 8){
            history.push('/dashboard/products/new');
        }
        else if (index == 9){
            history.push('/dashboard/delivery');
        }
        else if (index == 10){
            history.push('/dashboard/currency');
        }
        else if (index == 11){
            history.push('/dashboard/terms');
        }
        
  
    }
  
  
    return (
              
            <div style={{ marginTop: 10}}>

                {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30, marginTop: 20}}>
                    <h3>Provider Checklist</h3>
                    <h3>{CHECK_PERCENTAGE}%</h3>
                </div> */}
                
                <div className="top_wrapper">
                    <Check_Item title={TRANSLATE.settings_provider_name} Icon={StorefrontOutline} status={check_name} onSelectCheckItem={onSelectCheckItem} index={0} />
                    <Check_Item title={TRANSLATE.settings_provider_email} Icon={MailOutline} status={check_email} onSelectCheckItem={onSelectCheckItem} index={1}/>
                    <Check_Item title={TRANSLATE.settings_provider_location} Icon={LocationOutline} status={check_address} onSelectCheckItem={onSelectCheckItem} index={2}/>
                    <Check_Item title={TRANSLATE.settings_provider_photo} Icon={ImageOutline} status={check_photo} onSelectCheckItem={onSelectCheckItem} index={3}/>
                    <Check_Item title={TRANSLATE.settings_provider_about} Icon={CardOutline} status={check_about} onSelectCheckItem={onSelectCheckItem} index={4}/>
                    <Check_Item title={TRANSLATE.settings_provider_phone} Icon={PhonePortraitOutline} status={check_phone} onSelectCheckItem={onSelectCheckItem} index={5}/>
                    <Check_Item title={TRANSLATE.settings_provider_fiscal} Icon={BarcodeOutline} status={check_fiscal} onSelectCheckItem={onSelectCheckItem} index={6}/>
                    <Check_Item title={TRANSLATE.settings_provider_bank} Icon={MedkitOutline} status={check_bank} onSelectCheckItem={onSelectCheckItem} index={7}/>
                    <Check_Item title={TRANSLATE.settings_provider_add_product} Icon={AddOutline} status={check_product} onSelectCheckItem={onSelectCheckItem} index={8}/>
                    <Check_Item title={TRANSLATE.settings_provider_delivery_return} Icon={AirplaneOutline}status={check_delivery} onSelectCheckItem={onSelectCheckItem} index={9}/>
                    <Check_Item title={TRANSLATE.change_currency} Icon={LogoEuro} status={check_currency} onSelectCheckItem={onSelectCheckItem} index={10} currency={DASHBOARD.currency || 'eur'} />
                    {/* <Check_Item title={TRANSLATE.settings_provider_terms} Icon={FileTrayOutline} status={check_terms} onSelectCheckItem={onSelectCheckItem} index={11}/> */}
                </div>
                
            </div>
        
  
    )
}
  
const Check_Item = ({Icon, title, status, size, index, onSelectCheckItem, currency}) => {
  
    if(!size){
      size= 30;
    }

    return (
        <div className="product_container" onClick={()=> onSelectCheckItem(index)}>
            <div className="product_card" style={{height: 200}}>
                <div className="product_photo" style={{height: 80}}>
                    {index == 10 ? (
                        <>  
                            {currency === 'eur' && (
                                <h4>€ EUR <ReactCountryFlag countryCode={"eu"} /></h4>
                            )}

                            {currency === 'gbp' && (
                               <h4>£ GBP <ReactCountryFlag countryCode={"gb"} /></h4>
                            )}

                            {currency === 'usd' && (
                               <h4>$ USD <ReactCountryFlag countryCode={"us"} /></h4>
                            )}
                            
                            
                        </>
                    ) : (
                        <Icon color={'#26BFBF'} style={{height: 40, width: 40}} />
                    )}
                   
                   
                </div>

    
                <div className="product_info">
                    <h1>
                        {title}
                    </h1>

                    <div className="product_price">
                    
                        {status ? (
                            <CheckmarkCircleSharp size={26} color="#228c22" style={{marginLeft: "auto"}} />
                        ) : (
                            <WarningSharp size={26} color="#eed202" style={{marginLeft: "auto"}} />
                        )}
                        
                    </div>
    
                </div>
            </div>
        </div>
    )

    // return (
    //     <div style={Styles.listMenuItem} onClick={()=> onSelectCheckItem(index)}>
          
    //         <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
    //             <Icon color={'#26BFBF'}/>
    //             <h4 style={{marginLeft: 20, fontWeight: '400', fontSize: '15px', color: (status ? '#000' : '#ccc')}}>
    //               {title}
    //             </h4>
    //         </div>
    

    //         {status ? (
    //             <CheckmarkCircleSharp size={26} color="#228c22" style={{marginLeft: "auto"}} />
    //         ) : (
    //             <WarningSharp size={26} color="#eed202" style={{marginLeft: "auto"}} />
    //         )}
            
    //     </div>
    // )
  
  
}
  


const Styles = {
    listMenuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        backgroundColor: '#ffffff',
        borderBottomWidth: 1,
        borderBottomColor: "#F2F3F7",
        cursor: 'pointer',
        height: '52px'
    },
    button: {
        display: 'flex',
        height: '50px',
        width: '200px',
        backgroundColor: '#fd6270',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',  
        borderRadius: 10,
        width: '100%',
        cursor: 'pointer'
    },
    innerCard: {
        display: 'flex',
        flexDirection: 'row', 
        backgroundColor: '#f1f1f1', 
        padding: 10, 
        width: "100%", 
        borderRadius:6,
        marginTop: 10,
    },
    cta: {
        display: 'flex',
        height: 60,
        backgroundColor: '#000',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
        width: '60%'
    }
}



export default AdminRoot

