import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router';

import AppRoutes from './routes';

import {HelmetProvider} from 'react-helmet-async';

import './styles/css/style.css';

//console.log = () => {};

const helmetContext = {};

function App (props) {

  const {type} = props;

  if(type === 'server'){
    return (
      <Provider store={props.store}>
        {
          props.location
          ? (
            <StaticRouter location={props.location} context={{}}>
              <AppRoutes store={props.store}/>
            </StaticRouter>
          ) : (
            <BrowserRouter>
              <AppRoutes/>
            </BrowserRouter>  
          )
        }
      </Provider>
    );
  }
  else{
    return (
      <HelmetProvider context={helmetContext}>
        <Provider store={props.store}>
          {
            props.location
            ? (
              <StaticRouter location={props.location} context={{}}>
                <AppRoutes store={props.store}/>
              </StaticRouter>
            ) : (
              <BrowserRouter>
                <AppRoutes/>
              </BrowserRouter>  
            )
          }
        </Provider>
      </HelmetProvider>
    );
  }
  
}

export default App;
