import {
  AddOutlined,
  CameraAltOutlined,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  CircleSharp,
  KeyboardDoubleArrowRightOutlined,
  UnfoldMore,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { ArrowForward, CloseCircleOutline, InformationCircleOutline } from 'react-ionicons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Filter_Array } from '../../Constants/Constants';
import Countries_EN from '../../Search/countries_en.json';
import Dropdown from '../Dropdown/Dropdown';
import Header from '../Header';
import RangeSlider from '../RangeSlider/RangeSlider';

const Provider = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [PROVIDER, setProvider] = useState({
    name: '',
    surname: '',
    storeName: '',
    about: '',
    countryCode: '',
    phoneNum: '',
    email: '',
  });

  return (
    <div className='layout'>
      <Header {...props} animatedBackground={false} isProvider={true}>
        {currentStep === 0 && (
          <div
            className='hero relative flex flex-col md:flex-row mb-4 md:mb-12 gap-8 w-full mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 color-pr'
            style={{ zIndex: '40' }}
          >
            <div className='header-title'>
              <h1 className='w-full quicksand text-[16px] md:text-[18px] color-pr'>
                EARN MONEY UP TO 5000 EURO <br /> IN A YEAR
              </h1>

              <div className='flex flex-col md:flex-row gap-4 md:mt-4 gap-4'>
                <Link
                  to='/'
                  className='flex align-items-center justify-content-center px-2 py-1 text-xs md:text-sm bg-white rounded-full'
                >
                  <UnfoldMore color='' className='icon-link-passive color-pr' />
                  Learn More
                </Link>
                <button
                  type='button'
                  className='flex align-items-center justify-content-center text-xs md:text-sm px-2 py-1 bg-pr text-white rounded-full'
                  onClick={() => setCurrentStep(1)}
                >
                  <KeyboardDoubleArrowRightOutlined className='' />
                  BECOME A PROVIDER
                </button>
              </div>
            </div>

            <div className='hero-img-container provider ml-auto mr-0'>
              <h2 className='quicksand color-pr' style={{ lineHeight: '1.5rem', textTransform: 'uppercase' }}>
                Europe’s biggest leader baby equipment rental portal
              </h2>
              <img src='/assets/img/landing/hero.jpg' className='hero-img mt-4' />
            </div>
          </div>
        )}
      </Header>

      <div
        className='flex flex-col align-items-center justify-content-center mx-auto my-4 max-w-xl px-4 sm:px-6 lg:px-8'
        style={{ flexGrow: '1' }}
      >
        {currentStep === 1 && <Step1 setCurrentStep={setCurrentStep} provider={PROVIDER} setProvider={setProvider} />}
        {currentStep === 2 && <Step2 setCurrentStep={setCurrentStep} />}
        {currentStep === 3 && <Step3 setCurrentStep={setCurrentStep} />}
        {currentStep === 4 && <Step4 setCurrentStep={setCurrentStep} {...props} />}
        {currentStep === 5 && <Step5 setCurrentStep={setCurrentStep} />}
      </div>
    </div>
  );
};

export default Provider;

const Step1 = (props) => {
  const { provider, setProvider, setCurrentStep } = props;
  const { name, surname, storeName, about, countryCode, phoneNum, email } = provider;
  const [location, setLocation] = useState({});

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [isModalLocation, setModalLocation] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    console.log(e.target.name, e.target.value);
    // setProvider({ ...provider, [e.target.name]: e.target.value });
  };
  return (
    <>
      {isModalLocation && <ModalLocation setCurrentStep={setCurrentStep} onClose={() => setModalLocation(false)} />}

      <Progressbar value={(100 * 1) / 6} />

      <h1 className='text-[14px]'>START YOUR BABONBO STORE</h1>
      <h2 className='text-center' style={{ fontSize: '15px' }}>
        PERSONAL INFORMATION
      </h2>

      <div className='mt-4'>
        <h3 className='font-semibold mb-2' style={{ fontSize: '15px' }}>
          PROFILE PHOTO
        </h3>

        <button
          type='button'
          className='flex align-items-center pl-1 pr-2 py-1 gap-1 text-sm border-purple-gray rounded-lg'
        >
          <AddOutlined /> ADD PHOTO
        </button>

        <div className='flex flex-col gap-2 my-2'>
          <div className='w-full '>
            <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
              <label className='inline-label bg-light-purple'>Name</label>

              <input
                value={name}
                onChange={handleChange}
                type='text'
                placeholder='John'
                className='text-sm border-purple-gray '
              />
            </div>
          </div>
          <div className='w-full'>
            <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
              <label className='inline-label bg-light-purple'>Surname</label>

              <input
                value={surname}
                onChange={handleChange}
                type='text'
                placeholder='Doe'
                className='text-sm border-purple-gray '
              />
            </div>
          </div>
          <div className='w-3/4 h-1 bg-light-gray'></div>
          <div className='w-full'>
            <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
              <label className='inline-label bg-light-purple'>Store Name</label>

              <input
                value={storeName}
                onChange={handleChange}
                type='text'
                placeholder='Babonbo'
                className='text-sm border-purple-gray '
              />
            </div>
          </div>
          <div className='w-full'>
            <textarea
              value={about}
              onChange={handleChange}
              type='text'
              placeholder='ABOUT YOU'
              className='w-full text-sm bg-light-gray rounded-lg border-none'
            />
          </div>
        </div>

        <div className='mt-8'>
          <h2 className='text-center' style={{ fontSize: '15px' }}>
            YOUR CONTACT INFORMATION
          </h2>

          <div className='flex flex-col gap-2'>
            <div className='w-full'>
              <div className='flex gap-2 w-full'>
                <div className='relative inline-flex flex-col mt-5 ' style={{ flex: '0 0 auto' }}>
                  <label className='inline-label bg-light-purple'>Country Code</label>
                  <div className='flex' style={{ maxWidth: '120px' }}>
                    <input
                      value={countryCode}
                      onChange={handleChange}
                      className='border-purple-gray w-full'
                      type='text'
                      placeholder='+44'
                    />
                  </div>
                </div>
                <div className='relative inline-flex flex-col mt-5 w-full'>
                  <label className='inline-label bg-light-purple'>Phone Number</label>
                  <input
                    value={phoneNum}
                    onChange={handleChange}
                    type='text'
                    placeholder=''
                    className='border-purple-gray w-full'
                  />
                </div>
              </div>
            </div>

            <div className='w-full'>
              <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                <label className='inline-label bg-light-purple'>Email</label>

                <input
                  value={email}
                  onChange={handleChange}
                  type='text'
                  placeholder='example@example.com'
                  className='text-sm border-purple-gray '
                />
              </div>
            </div>
          </div>
        </div>

        <h3 className='font-semibold mt-8 mb-2' style={{ fontSize: '15px' }}>
          Whom will see your informations?
        </h3>

        <p className='text-sm'>
          Our providers are local parents who offer their unused, high-quality baby gear for rent. They understand the
          challenges of parenting while traveling and are eager to make life easier for you. You too can join our 500+
          providers and rent your baby gear to families traveling to your location.
        </p>

        <div className='flex align-items-center mt-4 text-sm'>
          <button type='button' onClick={() => setTermsAndConditions(!termsAndConditions)}>
            {termsAndConditions ? <CheckBoxOutlined style={{ color: '#26bfbf' }} /> : <CheckBoxOutlineBlankOutlined />}
          </button>
          <p>
            Accept
            {/*TODO terms and conditions link */}
            <a href='#' className='ml-1 underline'>
              terms and conditions
            </a>
          </p>
        </div>

        <button
          type='button'
          className='quicksand flex align-items-center justify-content-center mt-4 p-2 font-bold w-full bg-black text-white rounded-lg'
          onClick={() => setModalLocation(true)}
        >
          CONTINUE <ArrowForward color={'#fff'} />
        </button>
      </div>
    </>
  );
};

const mockLocations = [
  { title: 'London', value: 'London' },
  { title: 'Paris', value: 'Paris' },
  { title: 'Istanbul', value: 'Istanbul' },
];

const ModalLocation = (props) => {
  const { setCurrentStep, onClose } = props;

  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [searchText, setSearchText] = useState('');
  const [address, setAddress] = useState('');

  const countries = Countries_EN.map((item, index) => {
    const country_code = item.value.toLowerCase();
    return { title: item.label, value: country_code };
  });

  const CITIES = mockLocations;
  const DISTRICTS = mockLocations;

  return (
    <div className='fixed w-screen h-screen top-0 left-0 z-10 flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]'>
      <div className='absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]' />

      <div
        className='slide-in-bottom flex flex-col m-0 mt-auto md:mt-0 px-4 sm:px-6 lg:px-8 pb-8 pt-8 w-full bg-white md:rounded-2xl relative z-20 overflow-auto '
        style={{ maxWidth: '700px', minHeight: '600px' }}
      >
        <img className='absolute top-6 right-6 cursor-pointer' src='/assets/img/close.svg' onClick={() => onClose()} />

        <div className='flex flex-col mt-4 gap-2'>
          <Dropdown
            value={country}
            options={countries}
            onChange={(value) => setCountry(value)}
            isProvider={true}
            outlined
          />

          <Dropdown
            value={city}
            options={CITIES}
            onChange={(value) => setCity(value)}
            searchText={searchText}
            setSearchText={setSearchText}
            isProvider={true}
            outlined
          />

          {/*TODO Display district list after city selection */}
          {city &&
            (DISTRICTS.length > 0 ? (
              <Dropdown
                value={district}
                options={DISTRICTS}
                onChange={(value) => setDistrict(value)}
                searchText={searchText}
                setSearchText={setSearchText}
                isProvider={true}
                outlined
              />
            ) : (
              <div>There is no available district</div>
            ))}

          {district && (
            <>
              <div className='w-full'>
                <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                  <label className='inline-label bg-light-purple'>Your Address</label>

                  <input
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    type='text'
                    placeholder='Baker Street No:2'
                  />
                </div>
              </div>
              <div className='my-4'>
                <h3 className='flex gap-2 font-semibold mt-8 mb-2' style={{ fontSize: '15px' }}>
                  <InformationCircleOutline /> Why we need you your address?
                </h3>
                <p className='text-sm'>
                  Our providers are local parents who offer their unused, high-quality baby gear for rent. They
                  understand the challenges of parenting while traveling and are eager to make life easier for you. You
                  too can join our 500+ providers and rent your baby gear to families traveling to your location.
                </p>
              </div>
              <button
                type='button'
                className='quicksand flex align-items-center justify-content-center mt-4 p-2 font-bold w-full bg-black text-white rounded-lg'
                onClick={() => setCurrentStep(2)}
              >
                CONTINUE <ArrowForward />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Step2 = (props) => {
  const { setCurrentStep } = props;
  const [selectedDistrict, setSelectedDistrict] = useState({});
  const [selectedNearbyDistricts, setSelectedNearbyDistricts] = useState([]);
  const [selectedOtherDistricts, setSelectedOtherDistricts] = useState([]);

  const nearbyDistricts = mockLocations;
  const otherDistricts = mockLocations;

  return (
    <div
      className='flex flex-col'
      style={{ flexGrow: '1', minWidth: '100%', maxWidth: '600px', width: '90vw', margin: '0 auto' }}
    >
      <h3 className='ml-auto'>Suggested Delivery Price</h3>

      <div className='flex items-center justify-between mt-2 gap-4'>
        <p className='flex items-center gap-1'>
          {selectedDistrict.id ? <CheckBoxOutlined style={{ color: '#26bfbf' }} /> : <CheckBoxOutlineBlankOutlined />}
          Hackney
        </p>

        <div className='flex px-2 py-1 border rounded-lg bg-white' style={{ width: '75px' }}>
          <input
            value={selectedDistrict.cost_delivery}
            onChange={(e) => setSelectedDistrict({ ...selectedDistrict, cost_delivery: e.target.value })}
            type='text'
            placeholder=''
            className='flex w-full outline-none border-none m-0 p-0 shadow-none'
          />
          <span>$</span>
        </div>
      </div>

      <div className='flex items-center gap-1 text-sm mt-2'>
        <InformationCircleOutline /> Delivery Info
      </div>

      <div className='mt-8 w-full'>
        <h2 className='w-full text-center text-lg font-normal'>Nearby Districts</h2>
        <div className='flex items-center justify-between mt-2 gap-4'>
          <h3 className='flex items-center gap-1'>
            {selectedNearbyDistricts.length > 0 ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
            {selectedNearbyDistricts.length > 0 ? 'Deselect All' : 'Select All'}
          </h3>
          <h3>Suggested Delivery Price</h3>
        </div>
        {nearbyDistricts.map((item, index) => (
          <div
            className={`flex items-center justify-between mt-2 px-2 py-1 gap-4 rounded-lg ${
              index % 2 === 0 ? 'bg-light-gray' : ''
            }`}
            key={item.id}
          >
            <p className='flex items-center gap-1'>
              {item.id ? <CheckBoxOutlined style={{ color: '#26bfbf' }} /> : <CheckBoxOutlineBlankOutlined />}
              {item.title}
            </p>

            <div className='flex px-2 py-1 border rounded-lg bg-white' style={{ width: '75px' }}>
              <input
                value={selectedNearbyDistricts.cost_delivery}
                onChange={(e) =>
                  setSelectedNearbyDistricts({ ...selectedNearbyDistricts, cost_delivery: e.target.value })
                }
                type='text'
                placeholder=''
                className='flex w-full outline-none border-none m-0 p-0 shadow-none'
              />
              <span>$</span>
            </div>
          </div>
        ))}
      </div>

      <div className='mt-8 w-full'>
        <h2 className='w-full text-center text-lg font-normal'>Other Districts</h2>

        <div className='flex items-center justify-between mt-2 gap-4'>
          <h3 className='flex items-center gap-1'>
            {selectedOtherDistricts.length > 0 ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
            {selectedOtherDistricts.length > 0 ? 'Deselect All' : 'Select All'}
          </h3>
          <h3>Suggested Delivery Price</h3>
        </div>

        {otherDistricts.map((item, index) => (
          <div
            className={`flex items-center justify-between mt-2 px-2 py-1 gap-4 rounded-lg ${
              index % 2 === 0 ? 'bg-light-gray' : ''
            }`}
            key={item.id}
          >
            <p className='flex items-center gap-1'>
              {item.id ? <CheckBoxOutlined style={{ color: '#26bfbf' }} /> : <CheckBoxOutlineBlankOutlined />}
              {item.title}
            </p>

            <div className='flex px-2 py-1 border rounded-lg bg-white' style={{ width: '75px' }}>
              <input
                value={selectedOtherDistricts.cost_delivery}
                onChange={(e) =>
                  setSelectedOtherDistricts({ ...selectedOtherDistricts, cost_delivery: e.target.value })
                }
                type='text'
                placeholder=''
                className='flex w-full outline-none border-none m-0 p-0 shadow-none'
              />
              <span>$</span>
            </div>
          </div>
        ))}
      </div>

      <div className='flex flex-col w-full mt-auto mb-4'>
        <button
          type='button'
          className='flex items-center justify-center mt-4 p-2 w-full bg-gray text-white rounded-lg'
          onClick={() => setCurrentStep(1)}
        >
          SAVE AND ADD ANOTHER CITY <ArrowForward style={{ color: '#fff' }} />
        </button>

        <button
          type='button'
          className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
          onClick={() => setCurrentStep(3)}
        >
          CONTINUE <ArrowForward style={{ color: '#fff' }} />
        </button>
      </div>
    </div>
  );
};

const Step3 = (props) => {
  const { setCurrentStep } = props;
  const [selectedAirports, setSelectedAirports] = useState([]);

  const airports = mockLocations;
  return (
    <div
      className='flex flex-col'
      style={{ flexGrow: '1', minWidth: '100%', maxWidth: '600px', width: '90vw', margin: '0 auto' }}
    >
      <h2 className='w-full text-center font-normal'>Airports</h2>
      <div className='mt-8 w-full'>
        <div className='flex align-items-center justify-content-between mt-2 gap-4'>
          <h3 className='flex align-items-center gap-1'>
            {selectedAirports.length > 0 ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
            {selectedAirports.length > 0 ? 'Deselect All' : 'Select All'}
          </h3>

          <h3>Suggested Delivery Price</h3>
        </div>

        <div className='mt-8 w-full'>
          {airports.map((item, index) => (
            <div
              key={item.id}
              className={`flex items-center justify-between mt-2 px-2 py-1 gap-4 rounded-lg ${
                index % 2 === 0 ? 'bg-light-gray' : ''
              }`}
            >
              <p className='flex items-center gap-1'>
                {item.id ? <CheckBoxOutlined style={{ color: '#26bfbf' }} /> : <CheckBoxOutlineBlankOutlined />}
                {item.title}
              </p>

              <div className='flex px-2 py-1 border rounded-lg bg-white' style={{ width: '75px' }}>
                <input
                  value={selectedAirports.cost_delivery}
                  onChange={(e) => setSelectedAirports({ ...selectedAirports, cost_delivery: e.target.value })}
                  type='text'
                  placeholder=''
                  className='flex w-full outline-none border-none m-0 p-0 shadow-none'
                />
                <span>$</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='flex flex-col w-full mt-auto mb-4'>
        <button
          type='button'
          className='flex align-items-center justify-content-center mt-4 p-2 gap-2 w-full bg-black text-white rounded-lg'
          onClick={() => setCurrentStep(4)}
        >
          CONTINUE <ArrowForward />
        </button>
      </div>
    </div>
  );
};

const Step4 = (props) => {
  const { setCurrentStep, LANGUAGE } = props;
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleCategorySelection = (item) => {
    if (selectedCategories.includes(item)) {
      setSelectedCategories(selectedCategories.filter((category) => category !== item));
    } else {
      setSelectedCategories([...selectedCategories, item]);
    }

    setCurrentStep(5);
  };

  return (
    <div
      className='flex flex-col'
      style={{ flexGrow: '1', minWidth: '100%', maxWidth: '600px', width: '90vw', margin: '0 auto' }}
    >
      {' '}
      <h2 className='mt-4 mb-2 w-full font-normal'>WHAT IS YOUR PRODUCTS?</h2>
      <div className='flex flex-col w-full gap-2'>
        {Filter_Array.map((item, index) => (
          <button
            key={index}
            type='button'
            className={`flex align-items-center justify-content-between py-1 text-lg rounded-lg ${
              selectedCategories.includes(item) ? 'bg-light-gray' : ''
            }`}
            onClick={() => handleCategorySelection(item)}
          >
            {item[LANGUAGE]}
            {selectedCategories.includes(item) ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
          </button>
        ))}
      </div>
      <div className='flex flex-col w-full mt-auto mb-4'>
        <button
          type='button'
          className='quicksand flex align-items-center justify-content-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
          onClick={() => setCurrentStep(5)}
        >
          CONTINUE <ArrowForward />
        </button>
      </div>
    </div>
  );
};

const Step5 = (props) => {
  const { setCurrentStep } = props;
  const [brand, setBrand] = useState();
  const [model, setModal] = useState();
  const [priceDay, setPriceDay] = useState();
  const [language, setLanguage] = useState();
  const [title, setTitle] = useState();
  const [about, setAbout] = useState();
  const [ageRange, setAgeRange] = useState();
  const [searchText, setSearchText] = useState();
  const [quantity, setQuantity] = useState();

  const BRANDS = [];

  return (
    <div className='quicksand'>
      <h1 className=' mt-4 mb-2 w-full text-center font-normal'>BABY STROLLERS</h1>

      <div className='mb-4'>
        <h4 className='font-bold'>Tips</h4>
        <ul>
          <li className='flex gap-1'>
            <CircleSharp style={{ marginTop: '2px', width: '12px', color: '#26bfbf' }} /> More products more customers
          </li>
          <li className='flex  gap-1'>
            <CircleSharp style={{ marginTop: '2px', width: '12px', color: '#26bfbf' }} /> Our providers are local
            parents who offer their unused
          </li>
          <li className='flex gap-1'>
            <CircleSharp style={{ marginTop: '2px', width: '12px', color: '#26bfbf' }} /> They understand the challenges
            of parenting while traveling
          </li>
        </ul>
      </div>

      <Dropdown
        placeholder='Select Brand'
        value={brand}
        options={BRANDS}
        onChange={(value) => setBrand(value)}
        searchText={searchText}
        setSearchText={setSearchText}
        isProvider={true}
        outlined
      />

      <div className='w-full mt-4'>
        <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
          <label className='inline-label bg-light-purple'>Model Name/Number</label>

          <input value={model} onChange={(e) => setModal(e.target.value)} type='text' placeholder='' />
        </div>
      </div>

      <div>
        <h4 className='font-bold'>How many?</h4>
        <div className='flex flex-shrink-0 my-2'>
          {Array.from({ length: 6 }, (_, index) => index).map((item) => (
            <div
              key={item}
              className={`flex px-2 py-1 justify-content-center w-full border-light-gray ${
                quantity === item + 1 ? 'bg-pr color-white' : 'color-pr'
              }`}
            >
              {item + 1}
            </div>
          ))}
        </div>
      </div>

      <div className='w-full mt-4'>
        <h4 className='font-bold'>PRICE</h4>

        <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
          <label className='inline-label bg-light-purple'>Suggested Daily Price</label>

          <div className='flex px-2 py-1 border'>
            <input
              value={priceDay}
              onChange={(e) => setPriceDay(e.target.value)}
              type='text'
              placeholder=''
              className='flex w-full outline-none border-none shadow-none'
            />

            {/* Currency icon */}
            <span className='my-auto'>$</span>
          </div>
        </div>
      </div>

      <div className='flex gap-1 mt-4'>
        <InformationCircleOutline className='mt-1' />
        <p>
          To offer convenient prices to traveling families for rentals longer than 10 days, Babonbo applies a daily
          discount on this daily price.
        </p>
      </div>

      <div className='mt-4'>
        <h4 className='font-bold'>Product Info</h4>

        <div className='flex mt-2 gap-2'>
          <button
            type='button'
            className='flex align-items-center pl-1 pr-2 py-1 gap-1 text-sm border-purple-gray rounded-lg'
          >
            <AddOutlined /> ADD PHOTO
          </button>

          <button
            type='button'
            className='flex align-items-center pl-1 pr-2 py-1 gap-1 text-sm border-purple-gray rounded-lg'
          >
            <CameraAltOutlined /> TAKE A PHOTO
          </button>
        </div>

        <div className='flex my-4 gap-2'>
          <div className='relative border-light-gray rounded-lg' style={{ width: '96px', height: '88px' }}>
            <CloseCircleOutline
              className='absolute top-0 right-0'
              style={{ color: 'red', width: '20px', height: '20px' }}
            />
            <div className='flex items-center justify-center h-full'>
              <img src='/assets/cities/amsterdam.png' />
            </div>
          </div>
          <div className='relative border-light-gray rounded-lg' style={{ width: '96px', height: '88px' }}>
            <div>
              <CloseCircleOutline
                className='absolute top-0 right-0'
                style={{ color: 'red', width: '20px', height: '20px' }}
              />
            </div>
            <div className='flex items-center justify-center h-full'>
              {' '}
              <img src='/assets/cities/dubai.png' />
            </div>
          </div>
        </div>

        <h3>You can add multiple images</h3>
      </div>

      <div className='mt-4'>
        <h4 className='font-bold'>Select Language</h4>

        <div>
          <div className='flex gap-2'>
            <div className='flex bg-light-gray rounded-full p-2' onClick={() => setLanguage('en')}>
              <img src='/assets/languages/en.png' style={{ width: '24px', height: '24px' }} />
            </div>
            <div className='flex bg-light-gray rounded-full p-2' onClick={() => setLanguage('it')}>
              <img src='/assets/languages/it.png' style={{ width: '24px', height: '24px' }} />
            </div>
            <div className='flex bg-light-gray rounded-full p-2' onClick={() => setLanguage('fr')}>
              <img src='/assets/languages/fr.png' style={{ width: '24px', height: '24px' }} />
            </div>

            <div className='flex bg-light-gray rounded-full p-2' onClick={() => setLanguage('es')}>
              <img src='/assets/languages/es.png' style={{ width: '24px', height: '24px' }} />
            </div>
          </div>

          <div className='w-full mt-4'>
            <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
              <label className='inline-label bg-light-purple'>Title</label>

              <input value={title} onChange={(e) => setTitle(e.target.value)} type='text' placeholder='' />
            </div>
          </div>

          <div className='relative inline-flex flex-column mt-1 mb-4 w-full '>
            <textarea
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              type='text'
              placeholder='ABOUT'
              className='w-full text-sm bg-light-gray rounded-lg border-none'
            />
          </div>
        </div>
      </div>

      <div className='w-full'>
        <h4 className='font-bold'>Age Range</h4>

        <RangeSlider />
      </div>

      <div className='flex flex-col w-full mt-auto mb-4'>
        {/* TODO return country/city select step */}
        <button
          type='button'
          className='quicksand flex align-items-center justify-content-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
          onClick={() => setCurrentStep(1)}
        >
          SAVE AND ADD ANOTHER BABY STOLLER <ArrowForward color={'#fff'} />
        </button>

        <button
          type='button'
          className='quicksand flex align-items-center justify-content-center mt-4 p-2 gap-2 w-full font-bold rounded-lg'
          onClick={() => setCurrentStep(4)}
        >
          CONTINUE TO ADD OTHER PRODUCTS <ArrowForward color={''} />
        </button>
      </div>
    </div>
  );
};

const Progressbar = (props) => {
  const { value } = props;

  return (
    <div className='flex align-items-center justify-content-between gap-1 w-full '>
      <div className='w-full rounded-lg overflow-hidden' style={{ height: '10px', backgroundColor: '#ddd' }}>
        <div
          style={{
            height: '10px',
            backgroundColor: '#4CAF50',
            width: `${value}%`,
          }}
        ></div>
      </div>
      <div className='my-3 quicksand text-xs text-center font-bold'>{value.toFixed(0)}%</div>
    </div>
  );
};
