import React, { useState } from 'react';
import { ArrowForwardCircleOutline, InformationCircleOutline } from 'react-ionicons';
import { Calendar } from 'react-modern-calendar-datepicker';
import { FooterDashboard } from '../Footer';
import Header from '../Header';
import ToggleButton from '../ToggleButton/ToggleButton';

const ProductStoreSettings = (props) => {
  const [isModalCalendar, setModalCalendar] = useState(false);

  return (
    <div className='layout relative quicksand'>
      {isModalCalendar && <ModalCalendar onClose={() => setModalCalendar(false)} />}
      <Header {...props} animatedBackground={false} isProvider={true} />
      <div className='flex flex-col mx-auto my-4 w-full max-w-xl px-4 sm:px-6 lg:px-8' style={{ flexGrow: 1 }}>
        <h2 className=' mt-4 mb-2 w-full text-center font-normal'>STORE SETTINGS</h2>
        <div className='mb-4'>
          <ul>
            <li className='flex gap-1'>
              <ToggleButton onToggle={() => {}} description={false} /> Activate “Only Self-Pickup” Mode
            </li>
            <li className='flex  gap-1'>
              <ToggleButton onToggle={() => {}} description={false} /> Activate “Only Delivery”
            </li>
            <li className='flex gap-1'>
              <ToggleButton onToggle={() => {}} description={false} />
              Accept Last Minute Order
              <InformationCircleOutline />
            </li>
            <li className='flex  gap-1'>
              <ToggleButton onToggle={() => setModalCalendar(true)} description={false} /> Activate Vacation Mode
            </li>
          </ul>
        </div>
      </div>

      <div style={{ height: '50px' }}></div>
      <FooterDashboard {...props} />
    </div>
  );
};

export default ProductStoreSettings;

const ModalCalendar = (props) => {
  const { onClose } = props;

  return (
    <div className='fixed w-screen h-screen top-0 left-0 z-10 flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]'>
      <div className='absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]' />

      <div
        className='slide-in-bottom flex flex-col m-0 mt-auto md:mt-0 px-4 sm:px-6 lg:px-8 pb-8 pt-8 w-full bg-white md:rounded-2xl relative z-20 overflow-auto '
        style={{ maxWidth: '700px' }}
      >
        <img className='absolute top-6 right-6 cursor-pointer' src='/assets/img/close.svg' onClick={() => onClose()} />

        <h3 className='my-4'>Select Dates for Vacation</h3>

        <div className='mx-auto' style={{ minWidth: '320px' }}>
          <Calendar />
        </div>

        <button
          type='button'
          className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full bg-pr text-white rounded-lg'
          onClick={() => {}}
        >
          SAVE <ArrowForwardCircleOutline style={{ color: '#fff' }} />
        </button>
      </div>
    </div>
  );
};
