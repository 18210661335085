import React, { useEffect, useState } from "react";

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';

import moment from "moment";

const SearchDates = (props) => {

    const { onClose, USER, TRANSLATE, onUpdateDate } = props;

    
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
    });


    const onApplyDates = () => {
        
        if(!selectedDayRange){
            return 
        }

        const START_DATE = onFormatDateInput(selectedDayRange.from);
        const END_DATE = onFormatDateInput(selectedDayRange.to);

        onUpdateDate({
            from: START_DATE,
            to: END_DATE
        })

        onClose()
        
    }

    const onFormatDateInput = (date) => {
		if (!date) return '';

		const year = date.year.toString();
		const month = date.month.toString().padStart(2, 0);
		const day = date.day.toString().padStart(2, 0);
		return `${day}-${month}-${year}`;
	};

    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 flex-col " style={{zIndex: 99999}}>
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
                <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col fade-in md:slider-in">
                    <div className="flex justify-between p-8 border-b border-zinc-200 bg-white z-20">

                        <div onClick={() => onClose()}>
                            <img className='w-[150px] mr-4' src='/assets/img/babonbo_logo_provider.png' />
                        </div>
                        <img className="cursor-pointer" src="/assets/img/close.svg" onClick={() => onClose()} />
                    </div>

                    <div className="overflow-auto h-full pt-8 md:pb-0 pb-32">
                        <Calendar
                            value={selectedDayRange}
                            onChange={setSelectedDayRange}
                            colorPrimary="#27BFBF"
                            colorPrimaryLight="#E9F9F9"
                            calendarClassName="provider-calendar flex-shrink-0 max-w-[320px]"
                        />

                    </div>

                    <div className="flex justify-between md:p-8 p-4 border-t border-zinc-200 items-center mt-auto md:relative fixed bottom-0 left-0 w-full bg-white">
                        <div className="text-bagreen-100 cursor-pointer" onClick={() => onClose()}>
                            Back
                        </div>
                        <div className="btn-provider" onClick={() => onApplyDates()}>
                            Apply Dates
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SearchDates