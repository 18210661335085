const provider = (state = {}, action) => {

  switch (action.type) {
    case 'UPDATE_PROVIDER':
      return action.provider || {};
    default:
      return state;
  }
};
export default provider;
