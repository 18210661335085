import React, { useEffect, useRef } from "react"

import NavSide from './NavSide';
import SearchBar from './Searchbar';
import Cities_Cards from '../Docs/Cities';

import {RadioButtonOnSharp, RadioButtonOffSharp} from 'react-ionicons';
import { Categories_Array } from '../General/Constants';
import { onFormatUrl } from '../../helpers/GeneralHelper';
import { Link } from "react-router-dom";

const SearchSide = (props) => {

      const searchInputElement = useRef(null);

      const { match, LANGUAGE, history, isSearchbar, onChangeLanguage, USER, TRANSLATE, SEARCH} = props;

      const CURRENT_URL = match.url;

      useEffect(()=> {

            if(isSearchbar){
                 // alert('SearchBar ON');
                 setTimeout(()=> {
                       if(searchInputElement.current){
                           searchInputElement.current.focus();
                       }
                 }, 300)
                 
            }
            else{
                 // alert('SearchBar OFF');   
            }

      }, [isSearchbar]);

      const onSelectLanguage = (lang) => {

            //Setup URL
            const currentCity = match.params.city;
            const currentCountry = match.params.country;
            const currentCategory = match.params.category;

            const SearchURL = SEARCH ? SEARCH[lang+'_url'] : null;
            
            let ChangedURL = CURRENT_URL.replace('/'+LANGUAGE+'/', '/'+lang+'/');

            if(currentCity && currentCountry && SearchURL){
                 
                  if(currentCategory){
                        ChangedURL = SearchURL+'/'+currentCategory;
                  }
                  else{
                        
                        ChangedURL = SearchURL;
                  }
                    
            }

            console.log("LANGUAGE ===> ", LANGUAGE);
            console.log("LANG ===> ", lang);
            console.log("CURRENT ===> ", CURRENT_URL);
            console.log("CHANGED ===> ", ChangedURL);
            console.log("currentCity ===> ", currentCity);
            console.log("currentCountry ===> ", currentCountry);
            console.log("currentCategory ===> ", currentCategory);
            console.log("SearchURL ===> ", SearchURL);

            if(ChangedURL === CURRENT_URL){
                  onChangeLanguage(lang);
            }
            else{
                  history.push(ChangedURL);
            }
      }

      if(!isSearchbar){
            return null
      } 


      const isProducts = (CURRENT_URL === '/dashboard/products');
      const isDashboard = (CURRENT_URL === '/dashboard');
      const isOrders = (CURRENT_URL === '/orders');
      const isOrdersProvider = (CURRENT_URL === '/dashboard/orders');
    

      return (
            <nav className={"side-nav "+(isSearchbar ? 'active' : '')}  style={{overflow: 'auto', width: '100%', borderRadius: 0, padding: 20}}>
                  
                  <NavSide onSelectLanguage={onSelectLanguage} {...props} type="SearchBar" />
                  
                  <SearchBar {...props} 
                            style={{display: 'flex', width: '-webkit-fill-available', marginTop: 80, height: 60}} 
                            styleParent={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 30}}
                            styleChild={{marginTop: 10, width: '90%', position: 'inherit'}}      
                            styleIcon={{marginRight: 10}}
                            inputRef={searchInputElement}
                  />

                  <Cities_Cards {...props} className="row search"/>
                  
                  {/* Category Start */}
                  <h5 className="section-title position-relative text-uppercase mb-3" style={{margin: 0}}><span className="bg-secondary pr-3">{TRANSLATE.filter_by_category}</span></h5>
                  <div className="bg-light p-4 mb-30" style={{borderRadius: 20}}>
                        <form>
                        {Categories_Array.map((item, index) => {

                              const Cat_Name = item[LANGUAGE];
                              const Cat_Code = item.code;
      
                              const Cat_Code_Formatted = (Cat_Code !== 'all' && Cat_Code !== 'other') ? onFormatUrl(Cat_Name.toLowerCase()) : Cat_Code;

                              const isSearchRoot = match.url === '/'+LANGUAGE+'/search';
                              const currentCategory = null;

                              const SearchURL = SEARCH ? SEARCH[LANGUAGE+'_url'] : '/'+LANGUAGE+'/search/'+onFormatUrl(USER.city)+'/'+USER.country;
                              const FinalURL = Cat_Code_Formatted !== 'all' ? SearchURL+'/'+Cat_Code_Formatted : SearchURL;

                              if(Cat_Code === 'all'){

                                    const isActive = (isSearchRoot || (currentCategory === 'all'));

                                    return (
                                    <Link to={FinalURL} className="d-flex align-items-center justify-content-between mb-3" key={index}>
                                          
                                          <div className='' style={{marginLeft: -3}}>
                                                {isActive ? (
                                                <RadioButtonOnSharp color="#fd6270" style={{marginRight: 5}} />
                                                ) : (
                                                <RadioButtonOffSharp color="#fd6270" style={{marginRight: 5}} />
                                                )}

                                                <label className="" >{Cat_Name}</label>
                                          </div>
                                    
                                          {/* <span className="badge border font-weight-normal">{PRODUCTS.length}</span> */}
                                    </Link>
                                    )
                              }

                              const isActive = (currentCategory === Cat_Code);
                              
                              return (
                                    <Link to={FinalURL} className="d-flex align-items-center justify-content-between mb-3" key={index}>
                                          
                                          <div className='' style={{marginLeft: -3}}>
                                                {isActive ? (
                                                <RadioButtonOnSharp color="#fd6270" style={{marginRight: 5}} />
                                                ) : (
                                                <RadioButtonOffSharp color="#fd6270" style={{marginRight: 5}} />
                                                )}

                                                <label className="" >{Cat_Name}</label>
                                          </div>
                                    
                                          {/* <span className="badge border font-weight-normal">{}</span> */}
                                    </Link>
                              )

                        })}
                        
                        </form>
                  </div>
                  {/* Category End */}
                  
                  
                        
            </nav>
      )
}



export default SearchSide