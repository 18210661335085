import React, {useState, useEffect} from "react";

import LoaderMaster from "../Loaders/Loaders";

import {postApiPrivate} from "../../api";

import { AddCircleOutline, ArrowBack, CheckmarkCircleOutline, CheckmarkCircleSharp, CloseSharp, ImageSharp, RefreshOutline, Save, SaveSharp, TrashBinSharp } from "react-ionicons";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, InputAdornment, TextareaAutosize, TextField } from "@mui/material";

import {onGetCurrencySymbol } from "../../helpers/GeneralHelper";

import {Categories_Array, Ages_Array} from '../General/Constants';

import ReactFileReader from 'react-file-reader';

import { ImageCompressor, getImageSize } from "compressor-img";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";


import "../Profile/z_profile.css";

const AdminProductsDetails = (props) => {

  const {TRANSLATE, history, match, location, LANGUAGE} = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [isFocused, setFocused] = useState(false);
  const [BrandResults, setBrandResults] = useState([]);
  const [BrandInput, setBrandInput] = useState('');

  const [PUBLIC_PRODUCT, setProduct] = useState({});

  const [LangName, setLangName] = useState('en');

  const [isImageEditor, setImageEditor] = useState(false);
  const [CorrectedPhotos, setCorrectedPhotos] = useState([]);


  useEffect(()=> {

      var product_id = match.params.id || '';

      onFetchPublic(product_id);

  }, []);

  const onSaveAction = (id) => {
      swal({
          title: 'Confirm to Save',
          text: 'Please insert the CONFIRM to update this product',
          content: "input",
      })
      .then((value) => {

        if(value === 'CONFIRM'){

            onSaveProduct(id)
        }
        else{

            swal('Invalid Password', 'You are not authorized to update this product', 'error');
        }

      });
  }

  const onFetchPublic = async(product_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    
    setEdited(false);
    setLoading(true);

    try{

      const FORM = { id: product_id};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/listing/details', FORM, JWT);
        
      if(DATA_RESPONSE.error){

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      const ProductData = DATA_RESPONSE.p || {};
      const ProductMarca = ProductData.marca || '';
      const ProductImages = ProductData.images || [];

      if(ProductMarca){
        setBrandInput(ProductMarca);
      }

      const ProductImagesCorrected = ProductImages.map((item, index) => {
          return {
            src: item,
            index: index,
            uploaded: true
          }
      })

      setCorrectedPhotos(ProductImagesCorrected)
      setProduct(ProductData);
      setLoading(false);

    }
    catch(e){

        setLoading(false);
    }
      
  }
  
  const onUpdateProduct = async(key, value) => {

    setEdited(true);
    setProduct({
      ...PUBLIC_PRODUCT,
      [key]: value
    });

  }

  const onSaveProduct = async(product_id) => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        var FORM = { 
          id: product_id, 
          name: PUBLIC_PRODUCT.name,
          about: PUBLIC_PRODUCT.about,
          marca: PUBLIC_PRODUCT.marca,
          brand: PUBLIC_PRODUCT.brand,
          category: PUBLIC_PRODUCT.category,
          price: parseInt(PUBLIC_PRODUCT.price),
          price_day: parseInt(PUBLIC_PRODUCT.price_day),
          price_week: parseInt(PUBLIC_PRODUCT.price_week),
          price_month: parseInt(PUBLIC_PRODUCT.price_month),
          deposit: parseInt(PUBLIC_PRODUCT.deposit),
          ages: PUBLIC_PRODUCT.ages,
          en: PUBLIC_PRODUCT.en,
          it: PUBLIC_PRODUCT.it,
          es: PUBLIC_PRODUCT.es,
          fr: PUBLIC_PRODUCT.fr,
          status: PUBLIC_PRODUCT.status

        };

        const ProductImages = PUBLIC_PRODUCT.images || [];

        if(ProductImages.length > 0){
          FORM = {
            ...FORM,
            images: ProductImages
          }
        }

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/listing/edit', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Update This Product', 'error');
            onFetchPublic(product_id);

            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("SEARCH ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('Product Updated', 'This Product has been updated successfully !!!', 'success');
        onFetchPublic(product_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Update This Product', 'error');
          onFetchPublic(product_id);
      }

  }

  const onDeleteProduct = async(product_id) => {
    
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
      
    setSaving(true);

    try{

      const FORM = { id: product_id};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/listing/delete', FORM, JWT);
        
      if(DATA_RESPONSE.error){
          setSaving(false);
          swal('Error', 'Cannot Delete This Product', 'error');
          onFetchPublic(product_id);
          console.log("ERROR FROM API LOADING PRODUCT DELETE", DATA_RESPONSE.error);
          return 
      }

      setSaving(false);
      swal('Product Deleted', 'This Product has been deleted successfully !!!', 'success');
      history.replace('/admin/listing');

    }
    catch(e){

      setSaving(false);
      swal('Error', 'Cannot Delete This Brand', 'error');
      onFetchPublic(product_id);
    }
  }
  
  const onSelectBrand = async(brand) => {

      console.log("BRAND SELECTED ===> ", brand);

      setFocused(false);

      setEdited(true);
      setProduct({
        ...PUBLIC_PRODUCT,
        marca: brand.name,
        brand: brand.id
      });

      setBrandInput(brand.name);

      swal({
        title: 'Brand Updated',
        text: 'You selected '+brand.name,
        icon: 'success',
        timer: 2000
      })
      
  }

  const onSearchBrands = async(query) => {

      setBrandInput(query);

      if(!query){
          setFocused(false);
          setBrandResults([]);

          return 
      }

      //Fetch

      try{

          const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

          const DATA_RESPONSE = await postApiPrivate('/brands/search', {query: query, lang: LANGUAGE} , JWT);
          
          if(DATA_RESPONSE.error){
              console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
              return 
          }

          console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

          const filtredResults = DATA_RESPONSE.data || [];

          if(filtredResults.length > 0){
              setFocused(true);
              setBrandResults(filtredResults);
          }

          
      }
      catch(e){
          
          console.log("CREATING ===> ", e);

          setFocused(false);
          setBrandResults([]);
  
      }
    
          
  }

  const onSearchKeyUp = async(event) => {
      event.preventDefault();

      if (event.keyCode === 13) {
          event.target.blur();

          if(BrandResults.length>0){
              onSelectBrand(BrandResults[0]);
          }
      }
  }

  const onSelectAge = (age) => {

    const product_ages = PUBLIC_PRODUCT.ages || [];

    if(product_ages.includes(age)){

      const filtred_ages = product_ages.filter((item)=> item !== age);
      onUpdateProduct('ages', filtred_ages);

    }
    else{
      onUpdateProduct('ages', [...product_ages, age]);
    }

  }

  const onUpdateTranslations = (type, lang, value) => {

      if(lang === 'en'){
          onUpdateProduct(type, value);
      }
      else{

        const target_translation = PUBLIC_PRODUCT[lang] || {};
        const new_translation = {
          ...target_translation,
          [type]: value
        }

        onUpdateProduct(lang, new_translation);

      }

  }

  //Images

  const onAddImage = (data) => {

    const src = data.src || '';
    const isUploaded = data.uploaded || false; 

    if(isUploaded){
      const images = PUBLIC_PRODUCT.images || [];
      const newImages = [...images, src]
      onUpdateProduct('images', newImages);
    }

    setCorrectedPhotos((previous)=> {
        return [...previous, data]
    })

  }

  const onEditImage = (data, index) => {

    const src = data.src || '';
    const isUploaded = data.uploaded || false; 

    if(isUploaded){
      const images = PUBLIC_PRODUCT.images || [];
      const newImages  = [
          ...images.slice(0, index),
          src,
          ...images.slice(index+1),
      ]
      onUpdateProduct('images', newImages);
    }

    setCorrectedPhotos((previous)=> {
        return [
            ...previous.slice(0, index),
            data,
            ...previous.slice(index+1),
        ]
    })

  }

  const onDeleteImage = (index) => {

    const images = PUBLIC_PRODUCT.images || [];
    const filtred_images = images.filter((item, idx)=> index !== idx)
    const filtred_corrected = CorrectedPhotos.filter((item, idx) => index !== idx);

    setCorrectedPhotos(filtred_corrected);
    onUpdateProduct('images', filtred_images);


  }




  if(isLoading){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading}/>
    )

  }

  if(isSaving){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
    )

  }


  const {
    id,
    name,
    about,
    category,

    brand,
    marca,

    price,
    price_day,
    price_week,
    price_month,

    deposit,    

    updatedAt,
    createdAt,

    status,

    ages


  } = PUBLIC_PRODUCT;


  const CurrencyX = onGetCurrencySymbol('eur');
  const Categories_Final = Categories_Array.filter((item, index)=> index != 0);

  let CurrentProductName = '';
  let CurrentProductAbout = '';

  if(LangName === 'en'){
    CurrentProductName = name;
    CurrentProductAbout = about;
  }
  else{
    const CurrentLang = PUBLIC_PRODUCT[LangName] || {};
    CurrentProductName = CurrentLang.name || '';
    CurrentProductAbout = CurrentLang.about || '';
  }

  const managerObx = PUBLIC_PRODUCT.manager || {};
  const manager_id = managerObx.id || '';
  const manager_name = managerObx.name || '';
  const manager_img = managerObx.img || '';

 


  if(isImageEditor){
    return(
  
        <Container maxWidth="xl" style={{background: '#f0f5f9'}}>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={4} md={6}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                      <div className="top_store_card">
                          <div className="top_store_profile">

                              {CorrectedPhotos.length>0 ? (
                                <img className="profile_img" src={CorrectedPhotos[0].src} style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}} onClick={()=> setImageEditor(true)} />
                              ) : (
                                <div className="profile_img" style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}} onClick={()=> setImageEditor(true)} />
                              )}
                            
                              <h3 className="store_title">{name}</h3>
                              
                          </div>
                          <div className="top_store_stats">
                              <div>
                                <h3>{CurrencyX}{parseInt(price_day) || 0}</h3>
                                <p>{TRANSLATE.day}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price_week) || 0}</h3>
                                <p>{TRANSLATE.week}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price_month) || 0}</h3>
                                <p>{TRANSLATE.month}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price) || 0}</h3>
                                <p>New</p>
                              </div>
                          </div>
                      </div> 

                      
                      {isEdited && (
                        <> 

                          <div className="top_add_cart"  
                              style={{backgroundColor: '#59ad6a'}} 
                              onClick={()=> onSaveAction(id)}
                              
                          >
                            <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Update Product
                          </div>
                        
                        </>
                      )}
                      
                      <div className="top_add_cart"  style={{backgroundColor: '#3d3d3d'}} onClick={()=> setImageEditor(false)}>
                        <ArrowBack color={'#fff'} style={{marginRight: 10}} /> Go Back
                      </div>
                        
                      
                  </div>
              </Grid>
              <Grid item xs={12} lg={8} md={6}>
              
                <div className="container-fluid top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                    <div className="row">
                      
                      <div className="col-12 mb-5">
                          <h3>Product Images</h3>
                          <p>You can upload up to 4 photos of this product</p>
                      </div>
                      
                      {CorrectedPhotos.map((item, index)=> {

                          return (
                            <div className="col-sm-6 col-12" key={index}>
                                <ProductImageFull item={item} 
                                                  index={index} 
                                                  onDeleteImage={onDeleteImage}
                                                  onEditImage={onEditImage}
                                                  onSaveAction={onSaveAction}
                                                  product_id={id}
                                                  setImageEditor={setImageEditor}
                                               

                                />
                            </div>
                          )

                      })}

                      {Array.from({ length: (4-CorrectedPhotos.length) }).map((item, index)=> {

                          return (
                            <div className="col-sm-6 col-12" key={index}>
                                <ProductImageEmpty 
                                    TRANSLATE={TRANSLATE} 
                                    onAddImage={onAddImage}
                                />
                               
                            </div>
                          )

                      })}
                      


                    </div>
                
                </div>
                

              </Grid>
            
            </Grid>            
          
      </Container>

      
      )

  }
  

  return(
  
      <Container maxWidth="xl" style={{background: '#f0f5f9'}}>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={4} md={6}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                      <div className="top_store_card">
                          <div className="top_store_profile">

                              {CorrectedPhotos.length>0 ? (
                                <img className="profile_img" src={CorrectedPhotos[0].src} style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}} onClick={()=> setImageEditor(true)} />
                              ) : (
                                <div className="profile_img" style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}} onClick={()=> setImageEditor(true)} />
                              )}
                            
                              <h3 className="store_title">{name}</h3>
                              
                          </div>
                          <div className="top_store_stats">
                              <div>
                                <h3>{CurrencyX}{parseInt(price_day) || 0}</h3>
                                <p>{TRANSLATE.day}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price_week) || 0}</h3>
                                <p>{TRANSLATE.week}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price_month) || 0}</h3>
                                <p>{TRANSLATE.month}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price) || 0}</h3>
                                <p>New</p>
                              </div>
                          </div>
                      </div> 

                      {!isEdited && (
                          <>

                            <div className="top_add_cart"  style={{backgroundColor: 'var(--yellow)'}} onClick={()=> setImageEditor(true)}>
                              <ImageSharp color={'#fff'} style={{marginRight: 10}} /> Edit Images
                            </div>

                            <div className="top_add_cart" 
                                style={{backgroundColor: '#e64942'}} 
                                onClick={()=> {
                                    swal({
                                        title: 'Confirm to Delete',
                                        text: 'Please insert CONFIRM to delete this product',
                                        content: "input",
                                    })
                                    .then((value) => {

                                      if(value === 'CONFIRM'){

                                          onDeleteProduct(id)
                                      }
                                      else{

                                          swal('Invalid Password', 'You are not authorized to delete this product', 'error');
                                      }

                                    });
                                }}
                            >
                              <TrashBinSharp color={'#fff'} style={{marginRight: 10}} /> Delete Product 
                            </div>
                          </>
                      )}
                    
                      {isEdited && (
                        <> 

                          <div className="top_add_cart"  
                              style={{backgroundColor: '#59ad6a'}} 
                              onClick={()=> onSaveAction(id)}
                              
                          >
                            <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Update Product
                          </div>

                          <div className="top_add_cart"  style={{backgroundColor: '#ccc'}} onClick={()=> onFetchPublic(id)}>
                            <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Cancel Changes
                          </div>
                        
                        </>
                      )}
              
                      
                  </div>
              </Grid>
              <Grid item xs={12} lg={5} md={6}>
              
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                        <div className="top_box_magic" style={{marginBottom: 20}}>
                            <h1>
                              Status
                            </h1>

                            {status ? (
                              <Chip label="Completed" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateProduct('status', false)}/>
                            ) : (
                              <Chip label="Uncomplete" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning" onClick={(e)=> onUpdateProduct('status', true)} />
                            )}
                        </div>   

                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Name

                              <div>
                                <img src="/assets/languages/en.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5)}} onClick={()=> setLangName('en')}/>
                                <img src="/assets/languages/it.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5)}} onClick={()=> setLangName('it')}/>
                                <img src="/assets/languages/fr.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5)}} onClick={()=> setLangName('fr')}/>
                                <img src="/assets/languages/es.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5)}} onClick={()=> setLangName('es')}/>
                              </div>

                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={CurrentProductName} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateTranslations('name', LangName, e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  placeholder="Type here..."
                              
                              />
                            </div>
                        </div>


                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Description

                              
                            </h1>
                            <div style={{margin: '10px 0px'}}>

                              <TextareaAutosize
                                className={'gridyx-textarea'}
                                minRows={4}
                                maxRows={10}
                                placeholder="Type here..."
                                value={CurrentProductAbout}
                                onChange={(e)=> onUpdateTranslations('about', LangName, e.target.value)}
                                
                              />

                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Category
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <select 
                                  className={'gridyx-select'}
                                  value={category} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateProduct('category', e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  select
                              >
                                {Categories_Final.map((item, index)=> {
                                    const Cat_Name = item[LANGUAGE];
                                    const Cat_Code = item.code;
          
                                    let NewURL = match.url;
                                    if(index != 0){
                                      NewURL = NewURL+'?filter='+Cat_Code
                                    }
                                  return (
                                    <option value={Cat_Code} key={index}>
                                        {Cat_Name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Brand
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={BrandInput} 
                                  label={'.'} 
                                  // onChange={(e)=> onUpdateProduct('marca', e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  onFocus={()=> setFocused(true)}  
                                  onChange={(e)=> onSearchBrands(e.target.value)} 
                                  onKeyUp={(e)=> onSearchKeyUp(e)}
                                
                              
                              />

                              {(isFocused && BrandResults.length > 0) && (
                                    <div style={{...Styles.resultsContainer}}>
                                        {BrandResults.map((item, index)=> {

                                            return (
                                                <div style={Styles.resultsRow} 
                                                        key={index} 
                                                        onClick={()=> onSelectBrand(item)}
                                                >

                                                      {item.name}
                                                      
                                                      <CheckmarkCircleOutline size={28} color="#ccc" style={{marginRight: 10}} />
                                                      
                        
                                                </div>
                                            )
                                            
                                        })}
                                    </div>
                                )}
                            </div>

                          

                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Price New
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={price}
                                  onChange={(e)=> onUpdateProduct('price', e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                  }}
                                  type="number"
                                  helperText={price <= 0 ? "Invalid price day. Don't use decimals" : ''}
                                  error={price <= 0}
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Suggested Price per day
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={price_day}
                                  onChange={(e)=> onUpdateProduct('price_day', e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                  }}
                                  type="number"
                                  helperText={price_day <= 0 ? "Invalid price day. Don't use decimals" : ''}
                                  error={price_day <= 0}
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Suggested Price per week
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={price_week}
                                  onChange={(e)=> onUpdateProduct('price_week', e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                  }}
                                  type="number"
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Suggested Price per month
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={price_month}
                                  onChange={(e)=> onUpdateProduct('price_month', e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                  }}
                                  type="number"
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Suggested Deposit
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={deposit}
                                  onChange={(e)=> onUpdateProduct('deposit', e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                  }}
                                  type="number"
                                  helperText={deposit <= 0 ? "Invalid price day. Don't use decimals" : ''}
                                  error={deposit <= 0}
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Age
                            </h1>
                            <div className="d-flex flex-wrap" style={{margin: '10px 0px'}}>
                              
                            
                                {Ages_Array.map((item, index)=> {

                                  return (
                                    <div className="d-flex align-items-center justify-content-between" key={index} style={{borderBottom: '1px solid #00000045', padding: 20, cursor: 'pointer', width: '50%'}} onClick={()=> onSelectAge(item.code)}>
                                      
                                      <div className="d-flex flex-column" style={{fontWeight: 600}}>
                                        {item[LANGUAGE]}
                                        <div style={{fontWeight: 300}}>{item.min}-{item.max} cm</div>
                                      </div>
                                
                                      {(ages && ages.includes(item.code)) ? (
                                        <CheckmarkCircleSharp size={30} color="green" style={{marginRight: 10}}  />
                                      ) : (
                                        <CheckmarkCircleOutline size={30} color="#ccc" style={{marginRight: 10}} />
                                      )}
                                      
                                                      
                                    </div>
                                  )
                                })}
    
                            </div>
                        </div>

                
                </div>
                

              </Grid>
              <Grid item xs={12} lg={3} md={3}>
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                    
                    <div className="top_box_magic">
                        <h1>
                          Product Created
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <p>
                            {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                          </p> 
                        </div>
                    </div>   
                    <div className="top_box_magic">
                        <h1>
                          Product Updated
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <p>
                            {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                          </p> 
                        </div>
                    </div>  

                    {manager_id && (
                      <div className="top_box_magic">
                          <h1>
                            Manager
                          </h1>
                          <Chip
                              avatar={<Avatar alt={manager_name} src={manager_img} />}
                              label={manager_name.toLowerCase()}
                              variant="outlined"
                              size='medium'
                              style={{textTransform: 'capitalize', marginTop: 10, width: 'fit-content'}}
                              onClick={()=> history.push('/admin/users/profile/'+manager_id)}
                          />
                      </div>
                    )}

                

                </div>
              </Grid>
            </Grid>  
       
          
      </Container>

    
  )


}


const ProductImageFull = ({item, onDeleteImage, index, onEditImage, onSaveAction, product_id, setImageEditor}) => {

    const {src, size, compressed, uploaded} = item;

    const [CurrentUrl, setUrl] = useState('');

    const [isChanged, setChanged] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [isCropped, setCropped] = useState(false);

    const [cropper, setCropper] = useState();

   
    useEffect(()=> {

      setUrl(item.src);

    }, []);
  
    const onUploadImage = async(item) => {
      
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
          
        setUploading(true);

        try{

          var FORM = { 
            ...item,
            type: 'products'
          };

          console.log("SEARCH ===> ", FORM);
      
          const DATA_RESPONSE = await postApiPrivate('/admin/library/upload', FORM, JWT);
            
          if(DATA_RESPONSE.error){
            setUploading(false);
              swal('Error', 'Cannot Update This Product', 'error');
            
              console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
              return 
          }

          console.log("SEARCH ===> ", DATA_RESPONSE);

          setUploading(false);
          swal('Photo Updated', 'This photo has been uploaded successfully !!!', 'success');
         
          const new_src = DATA_RESPONSE.url;
          onEditImage({
            src: new_src,
            uploaded: true
          }, index)
  
          setUrl(new_src);
          setChanged(false);

          // setImageEditor(false)
          // setTimeout(()=> {
          //   onSaveAction(product_id)
          // }, 3000)
          

        }
        catch(e){

          setUploading(false);
            swal('Error', 'Cannot Upload This Photo', 'error');
           
        }
    }
    
    const onCropImage = (item, index) => {

        if (typeof cropper !== "undefined") {
       
          const newImage = cropper.getCroppedCanvas().toDataURL();
          const newSize = getImageSize(newImage);

          let imageCompressor = new ImageCompressor({
              onSuccess: response => {
                  
                  const compressedImg = response.compressed;
              
                  const originalSize = getImageSize(response.original);
                  const compressedSize = getImageSize(response.compressed);
                  
                  if(originalSize > 3000){
                      
                  }
                  else if(originalSize <= 3000 && originalSize > 250){
                      
                  }
                  else if(originalSize <= 250){
                      
                  }

                  console.log("COMPRESSED ===> ", compressedImg);
                  console.log("ORIGINAL SIZE ===> ", originalSize);
                  console.log("COMPRESSED SIZE ===> ", compressedSize);

                  onEditImage({
                    src: compressedImg,
                    uploaded: false,
                    size: originalSize,
                    compressed: compressedSize
                  }, index)
          
                  setChanged(true);
                  setCropped(true);


              },
              //scale: 100,
              quality: 50,
              originalImage: newImage
          });
        
        }
       
    }

    const onEditUrl = (url) => {
        setUrl(url);
        setChanged(true);
    }

    const onSaveUrl = () => {
        onEditImage({
          src: CurrentUrl,
          uploaded: true
        }, index)

        setChanged(false)
    }

 


    const CAN_SAVE = isChanged && CurrentUrl.length > 0;
   

    return (
      <>
      
          <div className="product-images-full">

              
              <div className="d-flex align-items-center justify-content-center" style={{background: 'var(--danger)', position: 'absolute', top: 10, right: 25, borderRadius: '50%', width: 30, height: 30, cursor: 'pointer', zIndex: 2}} onClick={()=> onDeleteImage(index)}>
                <CloseSharp color={'#ffffff'} style={{height: 16, width: 16}} />
              </div>
             

              {!uploaded ? (
                 <>
                      {isCropped ? (
                        <>
                          <img style={{objectFit: 'contain', width: '100%', height: 350, background: '#ccc'}} src={src} />
                          <div className="top_box_magic w-100 p-3">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Paste url of image

                                <div className="d-flex align-items-center justify-content-center" style={{background: '#000', borderRadius: 20, height: 25, color: '#fff', padding: '0px 10px', fontSize: 14}}>
                                  {size} kb to {compressed} kb
                                </div>
                              </h1>
                              <div className="d-flex align-items-center justify-content-between" style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    value={CurrentUrl} 
                                    label={'.'} 
                                    onChange={(e)=> onEditUrl(e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    placeholder="Type here..."
                                    disabled={true}
                                
                                />

                                {isUploading ? (
                                    <div className="gridyx-textbutton" style={{cursor: 'not-allowed', background: '#ccc'}}>
                                        <RefreshOutline color={'#fff'} rotate={true} style={{marginBottom: 5}}/>
                                      </div>
                                ) : (
                                    <>

                                      {CAN_SAVE ? (
                                        <div className="gridyx-textbutton" onClick={()=> {
                                            swal({
                                                title: 'Upload Photo',
                                                text: 'Please add a name to the photo to easily find it later',
                                                icon: 'info',
                                                content: "input",
                                            })
                                            .then((value) => {
        
                                              if(value && value.length > 0){
                                                onUploadImage({
                                                  ...item,
                                                  name: value
                                                })
                                              }
                                              else{
                                                swal('min 4 chars', 'The photo name should be at least 4 chars long.', 'warning');
                                              }
        
                                            });
                                        }}>
                                          Upload
                                        </div>
                                      ) : (
                                        <div className="gridyx-textbutton" style={{cursor: 'not-allowed', background: '#ccc'}}>
                                          Upload
                                        </div>
                                      )}

                                    </>
                                )}
                                
                                
                              </div>
                          </div>
                        </>
                      ) : (
                        <>

                          <Cropper
                            style={{ height: 350, width: "100%" }}
                            initialAspectRatio={1}
                            src={src}
                            viewMode={1}
                            cropBoxResizable={false}
                            dragMode={'move'}
                            background={true}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                            guides={true}
                          />
                          <div className="top_box_magic w-100 p-3">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Adjust Image

                                <div className="d-flex align-items-center justify-content-center" style={{background: '#000', borderRadius: 20, height: 25, color: '#fff', padding: '0px 10px', fontSize: 14}}>
                                  {size} kb
                                </div>
                              </h1>
                              <div className="d-flex align-items-center justify-content-between" style={{margin: '10px 0px'}}>
                               
                               <p style={{marginRight: 10}}>
                                 Make sure the product image is square with white background
                               </p>
                               
                               <div className="gridyx-textbutton" style={{width: 140}} onClick={()=> onCropImage(item, index)}>
                                  Crop Image
                                </div>
                                
                              </div>
                          </div>
                        </>
                      )}
                      
                    
                 </>
              ) : (
                <>
                    <img style={{objectFit: 'contain', width: '100%', height: 350, background: '#ccc'}} src={src} />
                    <div className="top_box_magic w-100 p-3">
                      <h1 className="d-flex align-items-center justify-content-between">
                        Paste url of image
                      </h1>
                      <div className="d-flex align-items-center justify-content-between" style={{margin: '10px 0px'}}>
                        <TextField 
                            className={'gridyx-textfield'}
                            value={CurrentUrl} 
                            label={'.'} 
                            onChange={(e)=> onEditUrl(e.target.value)}
                            InputLabelProps={{shrink: false}}
                            placeholder="Type here..."
                        
                        />

                        {CAN_SAVE ? (
                          <div className="gridyx-textbutton" onClick={()=> onSaveUrl()}>
                            Save
                          </div>
                        ) : (
                          <div className="gridyx-textbutton" style={{cursor: 'not-allowed', background: '#ccc'}}>
                            Save
                          </div>
                        )}
                        
                      </div>
                  </div>
                </>
                

              )}

             
          </div>

          
      </>
      
    )
}

const ProductImageEmpty = ({TRANSLATE, onAddImage}) => {

  const [CurrentUrl, setUrl] = useState('');

  const onSaveUrl = () => {
      onAddImage({
        src: CurrentUrl,
        uploaded: true
      })

      setUrl('');
  }

  const onHandleUpload = (files) => {
    
      console.log("Files ==> ", files);
      const OriginalFile = files.fileList[0];

      let reader = new FileReader();
      reader.onloadend = () => {

          const newSize = getImageSize(reader.result);

          onAddImage({
            src: reader.result,
            size: newSize
          })
             
      };

      reader.readAsDataURL(OriginalFile);
  }

 

  const CAN_SAVE = CurrentUrl.length > 0;

  return (

      <div className="product-images-full">

        <ReactFileReader fileTypes={[".jpg",".jpeg",".png",".gif"]} base64={true} multipleFiles={false} handleFiles={onHandleUpload} >
            <div className="d-flex flex-column align-items-center justify-content-center" style={{ padding: 60, height: 350}}>
                <AddCircleOutline color={'#ccc'} style={{width: 30, height: 30}} />
                <h1 style={{color: '#ccc', fontSize: 12}}>
                    {TRANSLATE.product_add_photo}
                </h1>
            </div>
        </ReactFileReader>

        <div className="top_box_magic w-100 p-3">
            <h1 className="d-flex align-items-center justify-content-between">
              Paste url of image
            </h1>
            <div className="d-flex align-items-center justify-content-between" style={{margin: '10px 0px'}}>
              <TextField 
                  className={'gridyx-textfield'}
                  value={CurrentUrl} 
                  label={'.'} 
                  onChange={(e)=> setUrl(e.target.value)}
                  InputLabelProps={{shrink: false}}
                  placeholder="Type here..."
              
              />

              {CAN_SAVE ? (
                <div className="gridyx-textbutton" onClick={()=> onSaveUrl()}>
                  Save
                </div>
              ) : (
                <div className="gridyx-textbutton" style={{cursor: 'not-allowed', background: '#ccc'}}>
                  Save
                </div>
              )}
              
            </div>
        </div>

      </div>
    
  )
}




const Styles = {
  resultsRow: {
      display: 'flex', 
      alignItems: 'center', 
      color: '#000', 
      height: 40, 
      minHeight: 40,
      paddingLeft: 20, 
      border: '1px solid #00000040', 
      borderRight: 'none', 
      borderTop: 'none', 
      borderLeft: 'none',
      cursor: 'pointer',
      textTransform: 'capitalize',
      justifyContent: 'space-between'
  },
  resultsContainer: {
      display: 'flex', 
      flexDirection: 'column', 
      background: '#fff', 
      maxHeight: 280,
      position: 'relative', 
      top: 20, 
      border: '1px solid #00000040', 
      borderRadius: 10,
      overflow: 'auto',
      marginBottom: 20
  },
  right: {
      display: 'flex',
      alignItems: 'center'
  },
  searchContainer: {
      width: 300,
      height: 42,
      backgroundColor: '#F1F1F1',
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 5
  },
  input: {
      fontSize: 16,
      display: 'flex',
      color: '#000',
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      marginLeft: 5,
      textTransform: 'capitalize'
  },
  searchOption: {
      display: 'flex', 
      width: 40, 
      height: 35, 
      alignItems: 'center', 
      borderRadius: '50%', 
      background: '#fd6270'
  },
  button: {
        display: 'flex',
        height: '50px',
        width: '200px',
        backgroundColor: '#26BFBF',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',  
        borderRadius: 10,
        width: '100%'
    },
    img_full: {
      display: 'flex',
      width: 200, 
      height: 200, 
      borderRadius: 6, 
      marginRight: 15, 
      marginLeft: 15
    },
    img_empty: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%', 
      height: '100%', 
      borderRadius: 6, 
      marginRight: 15, 
      borderColor: '#ccc', 
      borderWidth: 2, 
      borderStyle: "dashed", 
      justifyContent: 'center', 
      alignItems: 'center',
      marginBottom: 10,
      
    },
    clickCard: {
        backgroundColor: "#fff",
        padding: 20,
        marginTop: 10,
    },
    clickLabel: {
        color: "#697883",
        fontFamily: "SourceSansPro-Bold", 
        fontSize: 14, 
        marginBottom: 10,
    },
    wrapFlex: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    publishBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fd6270',
      padding: 10,
      width: 90,
      color: '#fff',
      borderRadius: 10,
      cursor: 'pointer'
    }

}


export default AdminProductsDetails

