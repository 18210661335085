import React from 'react';

import { Typography } from '@mui/material';

const CustomTableHeader = ({title}) => {

    return (
        <div>
            <Typography variant='span' fontWeight={'bold'}>
                {title}
            </Typography>
        </div>
    )
}

const GetRouteSearchParams = (query) => {
    const routeParams = getQueryStringParams(query) || {};
    return routeParams
}

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};
  

export {
    CustomTableHeader,
    GetRouteSearchParams
}