import { CheckBoxOutlineBlank, CheckBoxOutlined, SettingsOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { ArrowForward, ChevronDownOutline } from 'react-ionicons';
import { Calendar } from 'react-modern-calendar-datepicker';
import OutsideClickHandler from 'react-outside-click-handler';
import { FooterDashboard } from '../Footer';
import Header from '../Header';
import ToggleButton from '../ToggleButton/ToggleButton';

const mockPayouts = [
  { year: 2024, month: 'April', status: 'waiting', amount: '€2.457.85' },
  { year: 2024, month: 'March', status: 'waiting', amount: '€2.457.85' },
  { year: 2024, month: 'February', status: 'waiting', amount: '€2.457.85' },
  { year: 2024, month: 'January', status: 'waiting', amount: '€2.457.85' },
  { year: 2023, month: 'April', status: 'waiting', amount: '€2.457.85' },
];

const ProviderProducts = (props) => {
  const [activePage, setActivePage] = useState('status');

  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} isProvider={true} />
      <div className='flex flex-col mx-auto my-4 w-full max-w-xl px-4 sm:px-6 lg:px-8' style={{ flexGrow: 1 }}>
        <div className='flex align-items-center justify-content-between w-full'>
          <div
            className={`px-2 py-1 rounded-lg ${activePage === 'status' ? 'bg-pr text-white' : 'color-pr'}`}
            onClick={() => setActivePage('status')}
          >
            Active/Passive <SettingsOutlined />
          </div>
          <div
            className={`px-2 py-1 rounded-lg ${activePage === 'availability' ? 'bg-pr text-white' : 'color-pr'}`}
            onClick={() => setActivePage('availability')}
          >
            Availability <SettingsOutlined />
          </div>
          <div
            className={`px-2 py-1 rounded-lg ${activePage === 'price' ? 'bg-pr text-white' : 'color-pr'}`}
            onClick={() => setActivePage('price')}
          >
            Price <SettingsOutlined />
          </div>
        </div>
        {activePage === 'status' && (
          <>
            <h3 className='my-4 font-bold'>PRODUCTS</h3>
            <div className='flex flex-col gap-2'>
              <ProductCard />
              <ProductCard />
            </div>
          </>
        )}

        {activePage === 'availability' && (
          <>
            <h3 className='my-4 font-bold'>Availability</h3>
            <div className='mx-auto'>
              <Calendar />
            </div>
          </>
        )}

        {activePage === 'price' && (
          <>
            <h3 className='my-4 font-bold'>PRODUCTS</h3>
            <div className='flex flex-col gap-2'>
              <ProductCard type='price' {...props} />
              <ProductCard type='price' {...props} />
            </div>
          </>
        )}

        <div className='flex flex-col mt-auto gap-4'>
          <button
            type='button'
            className='flex align-items-center justify-content-center p-2 font-bold w-full bg-black text-white rounded-lg'
            onClick={() => {}}
          >
            SAVE <ArrowForward color={'#fff'} />
          </button>
          <button
            type='button'
            className='flex align-items-center justify-content-center p-2 font-bold w-full rounded-lg'
            onClick={() => {}}
          >
            CANCEL
          </button>
        </div>
      </div>

      <div style={{ height: '50px' }}></div>
      <FooterDashboard {...props} />
    </div>
  );
};

export default ProviderProducts;

const ProductCard = (props) => {
  const { type, history } = props;
  const [action, setAction] = useState('availability');
  const [visible, setVisible] = useState(false);
  const [price, setPrice] = useState(0);
  const [selected, setSelected] = useState([]);

  const actions = [
    { title: 'Edit', value: 'edit' },
    { title: 'Change Price', value: 'price' },
    { title: 'Change Availability', value: 'availability' },
    { title: 'Duplicate Product', value: 'duplicate' },
  ];

  const handleToggle = (isToggled) => {
    console.log('Toggled:', isToggled);
    // Perform any action based on toggle state
  };

  const handleAction = (value) => {
    setAction(value);
    setVisible(false);

    if (action === 'edit') {
      history.push('/newux/orders/edit');
    }
  };

  return (
    <div className='flex p-2 gap-2 w-full bg-light-gray rounded-lg'>
      {true ? <CheckBoxOutlined style={{ color: '#26bfbf' }} /> : <CheckBoxOutlineBlank />}

      <img src='/assets/cities/amsterdam.png' className='border-mid-gray' style={{ width: '60px', height: '56px' }} />
      <div className={`w-full ${type ? 'flex align-items-center justify-content-between' : ' '}`}>
        <div className='w-full'>
          <h2 className='text-sm truncate w-full whitespace-nowrap overflow-hidden'>Uppa Baby Stroller</h2>
          <h3 className='text-xs'>
            <strong>€10</strong>/day*
          </h3>
        </div>

        <div className='flex align-items-center justify-content-between'>
          {type !== 'price' && action === 'availability' && (
            <>
              <div className='relative'>
                <div
                  className='flex items-center gap-2 text-sm color-pr cursor-pointer'
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  Actions <ChevronDownOutline color={'#26bfbf'} style={{ width: '16px', height: '16px' }} />
                </div>

                {visible && (
                  <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
                    <div className='absolute top-8 left-0 flex flex-col bg-white rounded-lg shadow-lg p-1 z-10'>
                      {actions.map((item, index) => (
                        <div
                          key={index}
                          className='p-1 hover:bg-gray-200 cursor-pointer text-sm'
                          style={{ minWidth: 'max-content' }}
                          onClick={() => handleAction(item.value)}
                        >
                          {item.title}
                        </div>
                      ))}
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
              <div className='px-2 py-1 rounded-lg'>
                <ToggleButton onToggle={handleToggle} />
              </div>
            </>
          )}

          {(type === 'price' || action === 'price') && (
            <div className='relative inline-flex flex-column' style={{ width: '90px' }}>
              <label className='inline-label bg-light-purple'>Price</label>

              <div className='flex p-2 border'>
                <input
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  type='text'
                  placeholder=''
                  className='flex m-0 p-0 bg-transparent outline-none border-none shadow-none'
                  style={{ width: '70px' }}
                />

                {/* Currency icon */}
                <span className='my-auto'>$</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
