import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import moment from 'moment/min/moment-with-locales';

import { onGetCurrencySymbol } from '../../helpers/GeneralHelper';

import { Calendar, momentLocalizer } from 'react-big-calendar'

import "react-big-calendar/lib/css/react-big-calendar.css";

import { CircularProgress, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import { Select } from '@mui/material';

const localizer = momentLocalizer(moment)

const eventStyle = (event) => {

    const eventType = event.type || 'default';

    let eventColor = '#26BFBF';

    if (eventType === 'default') {
        eventColor = 'green'
    }
    else if (eventType === 'delivery') {
        eventColor = '#fd6270'
    }
    else if (eventType === 'pickup') {
        eventColor = '#26BFBF'
    }
    else if (eventType === 'order') {
        eventColor = 'green'
    }

    return {
        style: {
            backgroundColor: eventColor,
            color: '#ffffff',
            whiteSpace: 'pre-line'
        }
    }
}



const AdminCalendary = (props) => {

    const { TRANSLATE, history, match } = props;

    const [isLoading, setLoading] = useState(true);

    const [STATUS, setStatus] = useState('all');

    const [PAGE, setPage] = useState(0);

    const [ORDERS, setOrders] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 500;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchOrders({
                page: current_page
            });

        }

        window.scrollTo(0, 0);

    }, [match.params.page]);

    const onFetchOrders = async ({ page }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                l: LIMIT,
                c: true,
                partner: match.params.id
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/orders', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setOrders(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSelectOrder = ({ order_id }) => {
        history.push('/partners/' + match.params.id + '/orders/profile/' + order_id);
    }


    const delivery = ORDERS.map((item, index) => {

        moment.locale(TRANSLATE.lang);

        let currency = item.scurrency || 'eur';
        let symbol = onGetCurrencySymbol(currency);

        let store_obx = item.store || {};
        let customer_obx = item.customer || {};

        let total = item.total || 0;

        let new_order = {

            order_id: item.id,

            id: index,
            title: 'DELIVERY #' + item.id + '\n\nSTORE ==> ' + store_obx.name + '\nCUSTOMER ==> ' + customer_obx.name + '\nTOTAL ===> ' + symbol + '' + total.toFixed(2),
            allDay: true,
            start: new Date(item.checkin),
            end: new Date(item.checkin),
            type: 'delivery'

        };


        return new_order

    });

    const pickup = ORDERS.map((item, index) => {

        moment.locale(TRANSLATE.lang);

        let currency = item.scurrency || 'eur';
        let symbol = onGetCurrencySymbol(currency);

        let store_obx = item.store || {};
        let customer_obx = item.customer || {};

        let total = item.total || 0;

        let new_order = {

            order_id: item.id,

            id: index,
            title: 'PICKUP #' + item.id + '\n\nSTORE ==> ' + store_obx.name + '\nCUSTOMER ==> ' + customer_obx.name + '\nTOTAL ===> ' + symbol + '' + total.toFixed(2),
            allDay: true,
            start: new Date(item.checkout),
            end: new Date(item.checkout),
            type: 'pickup'

        };


        return new_order

    });

    const orders = ORDERS.map((item, index) => {

        moment.locale(TRANSLATE.lang);

        let currency = item.scurrency || 'eur';
        let symbol = onGetCurrencySymbol(currency);

        let store_obx = item.store || {};
        let customer_obx = item.customer || {};

        let total = item.total || 0;

        let new_order = {

            order_id: item.id,

            id: index,
            title: 'ORDER #' + item.id + '\n\nSTORE ==> ' + store_obx.name + '\nCUSTOMER ==> ' + customer_obx.name + '\nTOTAL ===> ' + symbol + '' + total.toFixed(2),
            allDay: true,
            start: new Date(item.created),
            end: new Date(item.created),
            type: 'order'

        };


        return new_order

    });


    let events = [...orders, ...delivery, ...pickup];

    if (STATUS === 'orders') {
        events = [...orders]
    }
    else if (STATUS === 'deliveries') {
        events = [...delivery]
    }
    else if (STATUS === 'pickups') {
        events = [...pickup]
    }
    else if (STATUS === 'logistic') {
        events = [...delivery, ...pickup]
    }

    return (
        <>

            <Typography variant="h2" style={{ margin: '50px 20px' }}>

                CALENDARY →

                {isLoading ? (

                    <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                ) : (

                    <>
                        {' '}<span style={{ color: '#26BFBF' }}>{events.length}</span> {(STATUS === 'all' ? 'events' : STATUS)}
                    </>

                )}



            </Typography>

            <FormControl style={{ margin: 50, marginTop: 0 }}>
                <InputLabel id="demo-simple-select-label">SHOW</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={STATUS}
                    label="Show"
                    onChange={(e) => setStatus(e.target.value)}
                    variant="outlined" size='medium' style={{ background: '#fff' }}
                >
                    <MenuItem value={'all'}>All Orders, Deliveries and Pickups</MenuItem>
                    <MenuItem value={'orders'}>Only Orders</MenuItem>
                    <MenuItem value={'deliveries'}>Only Deliveries</MenuItem>
                    <MenuItem value={'pickups'}>Only Pickups</MenuItem>
                    <MenuItem value={'logistic'}>Only Deliveries & Pickups</MenuItem>
                </Select>
            </FormControl>



            <Calendar
                localizer={localizer}
                events={events || []}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 2000, background: '#fff', padding: 20, borderRadius: 10 }}
                eventPropGetter={eventStyle}
                onSelectEvent={onSelectOrder}
                components={[() => (<h1>jsjsjsjsj</h1>)]}
            />


        </>
    );

}


export default AdminCalendary


