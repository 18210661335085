import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';
import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import { Add } from '@mui/icons-material';
import { Filter_Array } from '../Modals/Constants';

const AdminCoupons = (props) => {

    const { TRANSLATE, history, match, location, USER } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [COUPONS, setCoupons] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var current_page = parseInt(page_id);

            if (USER.admin) {
                onFetchCoupons({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else {
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

                history.push('/logout')
            }

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchCoupons = async ({ page, sort, direction }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/coupons', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setCoupons(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchCoupons = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchCoupons({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/coupons/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setCoupons(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setCoupons([]);
            setLoading(false);
        }


    }

    const onSortCoupons = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'search_name'
        }
        else if (sort === 'img') {
            return 'photo'
        }
        else if (sort === 'lang') {
            return 'language'
        }
        else if (sort === 'isCompleted') {
            return 'setup'
        }
        else {
            return sort
        }
    }

    const onChangePageCoupons = ({ page, sort, direction }) => {
        let new_url = '/admin/coupons/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        history.push(new_url);
    }

    const onSelectCoupon = (coupon_id) => {
        history.push('/admin/coupons/profile/' + coupon_id);
    }


    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectCoupon(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "code",
            label: "CODE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const couponObx = COUPONS[rowIndex] || {};

                    const coupon_id = couponObx.id;
                    const coupon_code = couponObx.code || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={coupon_code.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => onSelectCoupon(coupon_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "value",
            label: "VALUE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const couponObx = COUPONS[rowIndex] || {};

                    const coupon_value = couponObx.value || '';

                    return (

                        <Chip label={coupon_value} size='medium' />

                    )
                }
            },
        },
        {
            name: "type",
            label: "TYPE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const couponObx = COUPONS[rowIndex] || {};

                    const coupon_type = couponObx.type || '';

                    return (

                        <Chip label={coupon_type} size='small' />

                    )
                }
            },
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    if (label === 'ON') {
                        return (
                            <Chip label={'ON'} size='small' color='success' />
                        )
                    }

                    return (
                        <Chip label={'OFF'} size='small' color='error' />
                    )

                }
            },
        },
        {
            name: "from",
            label: "FROM",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const couponObx = COUPONS[rowIndex] || {};

                    const coupon_used = couponObx.from || '';

                    return (

                        <Chip label={coupon_used} size='small' />

                    )
                }
            },
        },
        {
            name: "to",
            label: "TO",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const couponObx = COUPONS[rowIndex] || {};

                    const coupon_used = couponObx.to || '';

                    return (

                        <Chip label={coupon_used} size='small' />

                    )
                }
            },
        },
        {
            name: "limit",
            label: "LIMIT",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const couponObx = COUPONS[rowIndex] || {};

                    const coupon_limit = couponObx.limit || '';

                    if (!coupon_limit) {
                        return (
                            <Chip label={coupon_limit} size='small' />
                        )
                    }

                    return (
                        <Chip label={coupon_limit} size='small' color='info' />
                    )

                }
            },
        },
        {
            name: "categories",
            label: "CATEGORIES",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const couponObx = COUPONS[rowIndex] || {};

                    const coupon_categories = couponObx.categories || [];

                    if (coupon_categories.length === 0) {
                        return (
                            <Chip label={'No'} size='small' />
                        )
                    }

                    return (
                        <div className='flex gap-2'>
                            {coupon_categories.map((item) => {
                                
                                const matchedCategory = Filter_Array.find((cat)=> cat.code === item);
                                if(matchedCategory){
                                    const Cat_Name = matchedCategory['en'];
                                    return (
                                        <Chip label={Cat_Name} size='small' avatar={<Avatar alt={Cat_Name} src={matchedCategory.icon} />} />
                                    )
                                }
                                return (
                                    <Chip label={item} size='small' />
                                )
                            })}

                        </div>
                    )

                }
            },
        },
        // {
        //     name: "used",
        //     label: "USED",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (src, dir) => {

        //             const rowIndex = dir.rowIndex;
        //             const couponObx = COUPONS[rowIndex] || {};

        //             const coupon_used = couponObx.used || 0;

        //             return (

        //                 <Chip label={coupon_used} size='small' />

        //             )
        //         }
        //     },
        // },
        {
            name: "manager",
            label: "MANAGER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const couponObx = COUPONS[rowIndex] || {};

                    const managerObx = couponObx.manager || {};
                    const manager_id = managerObx.id || '';
                    const manager_name = managerObx.name || '';
                    const manager_img = managerObx.img || '';


                    if (manager_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                                    label={manager_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => history.push('/admin/users/profile/' + manager_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: 'isDeleted',
            label: 'DELETED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const couponObx = COUPONS[rowIndex] || {};

                    const isDeleted = couponObx.isDeleted || false;

                    if (isDeleted) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="Deleted" color='error' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }
                }
            }

        },
        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = COUPONS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_coupon = {
            id: item.id,
            code: item.code,
            value: item.value,
            status: item.status ? 'ON' : 'OFF',
            used: item.used,
            from: item.from,
            to: item.to,
            limit: item.limit,
            categories: item.categories,
            isDeleted: item.isDeleted || false,
            manager: item.manager || {},
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY')
        };

        return new_coupon
    })

    const customToolbar = () => (
        <Tooltip disableFocusListener title="Add Coupon">
            <IconButton onClick={() => history.push('/admin/coupons/profile/new')}>
                <Add />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filter: false,
        print: false,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageCoupons({
                        page: tableState.page,
                        sort: onSortCoupons(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageCoupons({
                        page: tableState.page,
                        sort: onSortCoupons(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchCoupons(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchCoupons(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Coupon By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Coupons with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },



    };


    return (
        <>
            {/* <pre>
                {JSON.stringify(data, null, 2)}
            </pre> */}
            <div className="top_container small_padding" style={{ background: '#f0f5f9' }}>
                <MUIDataTable
                    title={
                        <div className='font-light md:text-5xl text-xl text-lg py-8'>

                            {'Coupons Found → '}

                            {isLoading ? (

                                <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                            ) : (

                                <>
                                    {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                                </>

                            )}

                        </div>
                    }
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        </>
    );

}



export default AdminCoupons

