import React, {useState, useEffect} from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { onUpdateCart } from '../../actions';

import { postApiPrivate } from '../../api';

import Countries_EN from '../../components/Search/countries_en.json';


const SearchDelivery = (props) => {

    const { onClose } = props; 

    const { LANGUAGE, TRANSLATE, onSelectDelivery, geocode} = props;


    const [isLoading, setLoading] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [SearchResults, setSearchResults] = useState([]);
    const [SearchInput, setSearchInput] = useState('');

    const [tab, setTab] = useState('points');

    useEffect(()=> {

        if(geocode && geocode.latitude){
            onSelectTab('points');
        }

    }, [])

    const onSelectTab = (tab) => {

        setSearchResults([]);

        if(tab === 'points'){
            onFetchPoints()
        }
        else if(tab === 'cities'){
            onFetchCities()
        }

        setTab(tab);
    }

    const onFetchCities = async() => {

        setLoading(true);
        
        try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/locations/search', {isNearby: true, geocode, lang: LANGUAGE} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if(filtredResults.length > 0){
                setFocused(true);
                setSearchResults(filtredResults);
            }

            setLoading(false);
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);

            setFocused(false);
            setSearchResults([]);
            setLoading(false);
        }
      
    }

    const onFetchPoints = async(type) => {

        //Fetch
        setLoading(true);
        
        try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/geo/search', {isNearby: true, geocode, lang: LANGUAGE} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if(filtredResults.length > 0){
                setFocused(true);
                setSearchResults(filtredResults);
            }

            setLoading(false);
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);

            setFocused(false);
            setSearchResults([]);
            setLoading(false);
        }
      
           
   }
 
    //CITIES
    const onUpdateSearch = async(search) => {
        
       const SelectedCity =  {
            city: search[LANGUAGE],
            name: search[LANGUAGE],
            geocode: search.geocode,
            type: 'city',
            cost: 0
        }

        onSelectDelivery(SelectedCity);

        onClose();
    
        
    }

    const onSearchCities = async(city) => {

        setSearchInput(city);

        if(!city || city.length <= 3){
            setFocused(false);
            setSearchResults([]);

            return 
        }

        //Fetch

        setLoading(true);
        
        try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/locations/search', {query: city, lang: LANGUAGE} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if(filtredResults.length > 0){
                setFocused(true);
                setSearchResults(filtredResults);
            }

            setLoading(false);
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);

            setFocused(false);
            setSearchResults([]);
            setLoading(false);
        }
      
           
    }

    const onSearchKeyUp = async(event) => {
        event.preventDefault();

        if (event.keyCode === 13) {
            event.target.blur();

            if(SearchResults.length>0){
                onUpdateSearch(SearchResults[0]);
            }
        }
    }


    //POINTS
    const onUpdateSearchPoints = async(geo) => {
        
        const SELECTED_GEO = geo;

        const SelectedCity =  {
            name: SELECTED_GEO.name,
            geocode: SELECTED_GEO.geocode,
            city: SELECTED_GEO.city,
            type: SELECTED_GEO.type,
            cost: 0
         }
 
         onSelectDelivery(SelectedCity);
 
         onClose();
     
         
    }
 
    const onSearchPoints = async(geo) => {
 
         setSearchInput(geo);
 
         if(!geo || geo.length <= 3){
             setFocused(false);
             setSearchResults([]);
 
             return 
         }
 
         //Fetch
 
         setLoading(true);
         
         try{
 
             const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
 
             const DATA_RESPONSE = await postApiPrivate('/geo/search', {query: geo, lang: LANGUAGE} , JWT);
             
             if(DATA_RESPONSE.error){
                 console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                 setLoading(false);
                 return 
             }
 
             console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);
 
             const filtredResults = DATA_RESPONSE.data || [];
 
             if(filtredResults.length > 0){
                 setFocused(true);
                 setSearchResults(filtredResults);
             }
 
             setLoading(false);
             
         }
         catch(e){
             
             console.log("CREATING ===> ", e);
 
             setFocused(false);
             setSearchResults([]);
             setLoading(false);
         }
       
            
    }
 
    const onSearchKeyUpPoints = async(event) => {
         event.preventDefault();
 
         if (event.keyCode === 13) {
             event.target.blur();
 
             if(SearchResults.length>0){
                 onUpdateSearch(SearchResults[0]);
             }
         }
    }


    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 z-[999] flex-col ">
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
                <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col slider-in">
                    <div className="flex justify-between p-8 border-b border-zinc-200 bg-white z-20">
                        {/* <h2>Search a City</h2> */}
                        <div className="font-bold text-lg">
                            New Delivery
                        </div>
                        <img className="cursor-pointer" src="/assets/img/close.svg" onClick={() => onClose()} />
                    </div>
                    
                    {(tab === 'points' || tab === 'airports' || tab === 'stations' || tab === 'ports')? (
                        <div className='p-3 flex flex-col h-full'>
                            <div className="input-group mt-3">
                    
                                <input className="py-3 ring-2 ring-bagreen-100" type="text" placeholder={TRANSLATE.wd_address_airport_station} 
                                        onFocus={()=> setFocused(true)}  
                                        onChange={(e)=> onSearchPoints(e.target.value)} 
                                        onKeyUp={(e)=> onSearchKeyUpPoints(e)}
                                        value={SearchInput}
                                        autoComplete="new-password"
                                      
                            
                                />
    
                                {isLoading ? (
                                    <div className="w-[54px] h-[54px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute top-[3px] right-[3px] z-30">
                                        <img className='animate-spin w-[20px] h-[20px]' src="/assets/img/loader-white.png" />
                                    </div>        
                                ) : (
                                    <div className="w-[54px] h-[54px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute top-[3px] right-[3px] z-30">
                                        <img src="/assets/img/search.svg" style={{filter: 'invert(100%)'}} />
                                    </div>
                                )}
                                
                                </div>
                                {/**AUTOFIL DROPDOWN CAN BE USED ON ANY INPUT GROUP */}
    
                                <div className="w-full bg-white rounded-lg text-black z-30 overflow-auto">
                                    
                                    {SearchResults.map((item, index)=> {
    
                                        const {name, type, geocode} = item;

                                        const final_name = name;
                                        const final_geo = geocode || {};
                                        const final_latitude = final_geo.latitude;
                                        const final_longitude = final_geo.longitude;
    
                                        return (
    
                                            <div className="w-full p-3 my-3 flex items-center cursor-pointer hover:bg-zinc-100" onClick={()=> onUpdateSearchPoints(item)} key={index}>
                                                {type === 'home' && (
                                                    <img src={'/assets/img/location.svg'} className="w-[20px] h-[20px] mr-3 mb-auto mt-1" /> 
                                                )}
                                                {type === 'airport' && (
                                                    <img src={'/assets/img/plane.svg'} className="w-[20px] h-[20px] mr-3 mb-auto mt-1"  />
                                                )}
                                                {type === 'station' && (
                                                    <img src={'/assets/img/train-station.svg'} className="w-[20px] h-[20px] mr-3 mb-auto mt-1"  />
                                                )}
                                                {type === 'port' && (
                                                    <img src={'/assets/img/port-icon.svg'} className="w-[20px] h-[20px] mr-3 mb-auto mt-1"  />
                                                )}

                                                {(type !== 'home' && type !== 'airport' && type !== 'station' && type !== 'port') && (
                                                     <img src={'/assets/img/city-icon.svg'} className="w-[20px] h-[20px] mr-3 mb-auto mt-1"  />
                                                )}
                                                {final_name}
                                            </div>
                                        )
                                        
                                    })}

                                    <div style={{height: 200}}></div>
                                    
                                </div>
                                
    
                        </div>
                    ) : (

                        <div className='p-3 flex flex-col h-full'>
                            <div className="input-group mt-3">
                    
                                <input className="py-3 ring-2 ring-bagreen-100" type="text" placeholder={TRANSLATE.tool_enter_city} 
                                        onFocus={()=> setFocused(true)}  
                                        onChange={(e)=> onSearchCities(e.target.value)} 
                                        onKeyUp={(e)=> onSearchKeyUp(e)}
                                        value={SearchInput}
                                        autoComplete="new-password"
                                      
                            
                                />

                                {isLoading ? (
                                    <div className="w-[54px] h-[54px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute top-[3px] right-[3px] z-30">
                                        <img className='animate-spin w-[20px] h-[20px]' src="/assets/img/loader-white.png" />
                                    </div>        
                                ) : (
                                    <div className="w-[54px] h-[54px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute top-[3px] right-[3px] z-30">
                                        <img src="/assets/img/search.svg" style={{filter: 'invert(100%)'}} />
                                    </div>
                                )}
                                
                                
                                {/**AUTOFIL DROPDOWN CAN BE USED ON ANY INPUT GROUP */}
                            </div>
                                <div className="w-full pb-20 bg-white rounded-lg text-black z-30 overflow-auto">
                                    
                                    {SearchResults.map((item, index)=> {

                                        const country_index = Countries_EN.findIndex((cty)=> cty.value === item.country);
                                        const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
                                        const country_name = country_obx ? country_obx.label : null

                                        return (

                                            <div className="w-full p-3 my-3 flex items-center cursor-pointer hover:bg-zinc-100" onClick={()=> onUpdateSearch(item)} key={index}>
                                                <img className='w-[20px] h-[20px] mr-3 mb-auto mt-1' src="/assets/img/location.svg" />
                                                    {item[LANGUAGE]} - ({country_name || item.country})
                                            </div>
                                        )
                                        
                                    })}

                                    <div style={{height: 200}}></div>
                                    
                                </div>
                                

                        </div>
                    )}
                    

                    <div className='bg-white w-full fixed bottom-0 flex items-center justify-center px-2 py-2 z-30'>

                        <div className='flex h-[48px] w-full md:w-auto rounded-full bg-zinc-100 p-1 gap-x-1 text-sm mt-3 md:mt-0 items-center justify-center' style={{boxShadow: '0 25px 25px rgb(0 0 0 / 0.15)'}}>
                            
                            <div className={'flex md:w-auto w-1/2 text-center items-center justify-center h-full px-6 rounded-full cursor-pointer hover:bg-bagreen-100 hover:text-white hover:opacity-100 '+(tab === 'cities' ? 'bg-bagreen-100 text-white hover:bg-bagreen-200 opacity-100' : '')} onClick={()=> onSelectTab('cities')}>
                                {TRANSLATE.wd_all_cities}
                            </div>
                            <div className={'flex md:w-auto w-1/2 text-center items-center justify-center h-full px-6 rounded-full cursor-pointer hover:bg-bagreen-100 hover:text-white hover:opacity-100 '+(tab === 'points' ? 'bg-bagreen-100 text-white hover:bg-bagreen-200 opacity-100' : '')} onClick={()=> onSelectTab('points')}>
                                {TRANSLATE.wd_points_of_interest}
                            </div>
                        
                        </div>

                    </div>
                    
                </div>    

            </div>
        </>
    )
}



const mapStateToProps = state => {
  return ({
    USER: state.user,
    PROVIDER: state.provider,
    CART: state.cart,
    PRODUCT: state.product,
    PRODUCTS: state.products
  });
}

const mapDispatchToProps = dispatch => ({
  onUpdateCart: (cart) => dispatch(onUpdateCart(cart))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDelivery);
