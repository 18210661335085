import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import ReactCountryFlag from 'react-country-flag';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import StarReview from '../General/Stars';


const AdminNotifications = (props) => {

    const { TRANSLATE, history, match, location, USER } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [NOTIFICATIONS, setNotifications] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var filter_status = routeParams.status || null;
            var filter_owner = routeParams.owner || null;
            var filter_customer = routeParams.customer || null;

            var current_page = parseInt(page_id);

            if (USER.admin) {
                onFetchNotifications({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id,
                    filter_status,
                    filter_owner,
                    filter_customer
                });
            }
            else {
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

                history.push('/logout')
            }

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchNotifications = async ({ page, sort, direction, filter_status, filter_owner, filter_customer }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                status: filter_status,
                owner: filter_owner,
                customer: filter_customer,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/notifications', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setNotifications(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchNotifications = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchNotifications({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/notifications/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setNotifications(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setNotifications([]);
            setLoading(false);
        }


    }

    const onSortNotifications = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'search_name'
        }
        else if (sort === 'img') {
            return 'photo'
        }
        else if (sort === 'lang') {
            return 'language'
        }
        else if (sort === 'isCompleted') {
            return 'setup'
        }
        else {
            return sort
        }
    }

    const onChangePageNotifications = ({ page, sort, direction, filter_status, filter_owner, filter_customer }) => {
        let new_url = '/admin/notifications/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        if (filter_status) {
            new_url = new_url + '&status=' + filter_status;
        }

        if (filter_owner) {
            new_url = new_url + '&owner=' + filter_owner;
        }

        if (filter_customer) {
            new_url = new_url + '&customer=' + filter_customer;
        }

        history.push(new_url);
    }

    //Select Actions
    const onSelectNotification = (notification_id) => {
        history.push('/admin/notifications/profile/' + notification_id);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/admin/users/profile/' + customer_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/admin/stores/profile/' + store_id);
    }

    const onSelectOrder = (order_id) => {
        history.push('/admin/orders/profile/' + order_id);
    }

    const onSelectProduct = (product_id) => {
        history.push('/admin/products/profile/' + product_id);
    }

    const onSelectReview = (review_id) => {
        history.push('/admin/reviews/profile/' + review_id);
    }

    const onSelectReferral = (referral_id) => {
        history.push('/admin/referrals/profile/' + referral_id);
    }

    //Logic
    var routeParams = GetRouteSearchParams(location.search);

    var currentSort = routeParams.sort || null;
    var currentDirection = routeParams.dir || null;

    var currentFilterStatus = routeParams.status || null;
    var currentFilterOwner = routeParams.owner || null;
    var currentFilterCustomer = routeParams.customer || null;

    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectNotification(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "owner",
            label: "OWNER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const notificationObx = NOTIFICATIONS[rowIndex] || {};

                    const ownerObx = notificationObx.owner || {};
                    const owner_id = ownerObx.id || '';
                    const owner_name = ownerObx.name || '';
                    const owner_img = ownerObx.img || '';


                    if (owner_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={owner_name} src={owner_img} />}
                                    label={owner_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => onSelectCustomer(owner_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: "read",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (

                        <>
                            {(label === 'unread') && (
                                <Chip label={label} size='small' />
                            )}

                            {(label === 'read') && (
                                <Chip label={label} size='small' style={{ backgroundColor: 'green', color: '#fff' }} />
                            )}
                        </>
                    )
                }
            },
        },
        {
            name: "type",
            label: "TYPE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (

                        <>
                            {(label === 'order_pending') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#FCE205', color: '#aa9908' }} />
                            )}

                            {(label === 'order_accepted') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#9AD6AB', color: '#fff' }} />
                            )}

                            {(label === 'order_rejected') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#ec1529', color: '#fff' }} />
                            )}

                            {(label === 'order_canceled') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#ec1529', color: '#fff' }} />
                            )}

                            {(label === 'order_delivering') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#88D1F1', color: '#fff' }} />
                            )}

                            {(label === 'order_delivered') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#ff8b3d', color: '#fff' }} />
                            )}

                            {(label === 'order_complete') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#9AD6AB', color: '#fff' }} />
                            )}


                            {(label === 'review_customer' || label === 'review_store') && (
                                <Chip label={label} size='small' style={{ backgroundColor: 'var(--yellow)', color: '#fff' }} />
                            )}

                            {(label === 'approval_product') && (
                                <Chip label={label} size='small' />
                            )}

                            {(label === 'approval_store') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#26BFBF', color: '#fff' }} />
                            )}

                            {(label === 'approval_customer') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#fd6270', color: '#fff' }} />
                            )}

                            {(label === 'followers') && (
                                <Chip label={label} size='small' style={{ backgroundColor: '#fd6270', color: '#fff' }} />
                            )}

                            {(label === 'referral_award') && (
                                <Chip label={label} size='small' style={{ backgroundColor: 'green', color: '#fff' }} />
                            )}

                        </>
                    )
                }
            },
        },

        {
            name: "about",
            label: "DESCRIPTION",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const notificationObx = NOTIFICATIONS[rowIndex] || {};

                    const notification_owner = notificationObx.owner || {};
                    const owner_id = notification_owner.id;

                    const notification_user = notificationObx.user || {};
                    const user_id = notification_user.id;
                    const user_name = notification_user.name || '';

                    const notification_order = notificationObx.order || {};
                    const order_id = notification_order.id;
                    const order_status = notification_order.status || '';
                    const order_total = notification_order.total || 0;
                    const order_currency = notification_order.currency || 'eur';

                    const notification_store = notificationObx.store || {};
                    const store_id = notification_store.id;
                    const store_name = notification_store.name || '';

                    const notification_product = notificationObx.product || {};
                    const product_id = notification_product.id;
                    const product_name = notification_product.name || '';

                    const notification_referral = notificationObx.referral || {};
                    const referral_id = notification_referral.id;
                    const referral_value = notification_referral.value || 0;

                    const notification_review = notificationObx.review || {};
                    const review_id = notification_review.id;
                    const review_rank = notification_review.rank || 0;
                    const review_type = notification_review.type || '';
                    const review_store = notification_review.store || '';
                    const review_customer = notification_review.customer || '';

                    const notification_type = notificationObx.type || '';
                    const notification_status = notificationObx.status || '';

                    let color = '#000';

                    if (order_status === 'pending') {
                        color = '#FCE205';
                    }
                    else if (order_status === 'accepted') {
                        color = 'green';
                    }
                    else if (order_status === 'rejected') {
                        color = '#ec1529';
                    }
                    else if (order_status === 'canceled') {
                        color = '#ec1529';
                    }
                    else if (order_status === 'delivering') {
                        color = '#88D1F1';
                    }
                    else if (order_status === 'delivered') {
                        color = '#ff8b3d';
                    }
                    else if (order_status === 'complete') {
                        color = 'green';
                    }

                    //Setup Notification
                    if (notification_type === 'order_pending') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectOrder(order_id)}>
                                [{store_name}]: Hai ricevuto un nuovo ordine !!! 🎉🎉🎉
                            </div>
                        )
                    }
                    else if (notification_type === 'order_accepted') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectOrder(order_id)}>
                                La tua richiesta di noleggio é stata accettata da {store_name} !!! ✅
                            </div>
                        )
                    }
                    else if (notification_type === 'order_rejected') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectOrder(order_id)}>
                                La tua richiesta di noleggio é stata rifiutata da {store_name} !!! 🚨
                            </div>
                        )
                    }
                    else if (notification_type === 'order_canceled') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectOrder(order_id)}>
                                [{store_name}]: La richiesta di noleggio é stata cancellata dal cliente !!! 🚨
                            </div>
                        )
                    }
                    else if (notification_type === 'order_delivering') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectOrder(order_id)}>
                                Il tuo noleggio é in fase di consegna da {store_name} !!! 🚚
                            </div>
                        )
                    }
                    else if (notification_type === 'order_delivered') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectOrder(order_id)}>
                                Il tuo noleggio é stato consegnato da {store_name} !!! 📦
                            </div>
                        )
                    }
                    else if (notification_type === 'order_complete') {

                        if (notification_status === 'customer') {
                            return (
                                <div style={{ fontWeight: 100 }} onClick={() => onSelectOrder(order_id)}>
                                    Ordine completato ! Fai una recensione a {store_name} ⭐️⭐️⭐️⭐️⭐️
                                </div>
                            )
                        }
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectOrder(order_id)}>
                                Ordine completato ! Fai una recensione a {user_name} ⭐️⭐️⭐️⭐️⭐️
                            </div>
                        )
                    }
                    else if (notification_type === 'review_customer') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectReview(review_id)}>
                                {store_name} ti ha lasciato una recenzione ! ⭐
                            </div>
                        )
                    }
                    else if (notification_type === 'review_store') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectReview(review_id)}>
                                {user_name} ti ha lasciato una recenzione ! ⭐
                            </div>
                        )
                    }
                    else if (notification_type === 'approval_product') {

                        if (notification_status === 'approved') {
                            return (
                                <div style={{ fontWeight: 100 }} onClick={() => onSelectProduct(product_id)}>
                                    [{store_name}]: your product "{product_name}" has been approved ✅
                                </div>
                            )
                        }
                        else if (notification_status === 'pending') {
                            return (
                                <div style={{ fontWeight: 100 }} onClick={() => onSelectProduct(product_id)}>
                                    [{store_name}]: your product "{product_name}" is in review ⚠️
                                </div>
                            )
                        }

                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectProduct(product_id)}>
                                [{store_name}]: your product "{product_name}" has been rejected. Check the reason 🚨
                            </div>
                        )
                    }
                    else if (notification_type === 'approval_store') {
                        if (notification_status === 'approved') {
                            return (
                                <div style={{ fontWeight: 100 }} onClick={() => onSelectStore(store_id)}>
                                    [{store_name}]: your store changes have been approved ✅
                                </div>
                            )
                        }
                        else if (notification_status === 'pending') {
                            return (
                                <div style={{ fontWeight: 100 }} onClick={() => onSelectStore(store_id)}>
                                    [{store_name}]: your store changes is in review ⚠️
                                </div>
                            )
                        }

                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectStore(store_id)}>
                                [{store_name}]: your store changes have been rejected. Check the reason 🚨
                            </div>
                        )
                    }
                    else if (notification_type === 'approval_customer') {
                        if (notification_status === 'approved') {
                            return (
                                <div style={{ fontWeight: 100 }} onClick={() => onSelectCustomer(user_id)}>
                                    Your profile changes have been approved ✅
                                </div>
                            )
                        }
                        else if (notification_status === 'pending') {
                            return (
                                <div style={{ fontWeight: 100 }} onClick={() => onSelectCustomer(user_id)}>
                                    Your profile changes are in review ⚠️
                                </div>
                            )
                        }

                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectCustomer(user_id)}>
                                Your profile changes have been rejected. Check the reason 🚨
                            </div>
                        )
                    }
                    else if (notification_type === 'followers') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectCustomer(user_id)}>
                                [{store_name}]: You have a new follower 🎉
                            </div>
                        )
                    }
                    else if (notification_type === 'referral_award') {
                        return (
                            <div style={{ fontWeight: 100 }} onClick={() => onSelectReferral(referral_id)}>
                                You have just received +{referral_value} coins 🤑
                            </div>
                        )
                    }

                    return (
                        <div>
                            NO
                        </div>

                    )

                }
            },

        },

        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = NOTIFICATIONS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_notification = {
            id: item.id,
            type: item.type,
            status: item.status,
            read: item.read,
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY'),
            user: item.user || {},
            owner: item.owner || {},
            product: item.product || {},
            store: item.store || {},
            review: item.review || {},
            order: item.order || {},
            about: 1000
        };

        return new_notification
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageNotifications({
                        page: tableState.page,
                        sort: onSortNotifications(sortFilter),
                        direction: sortDirection,
                        filter_status: currentFilterStatus,
                        filter_owner: currentFilterOwner,
                        filter_customer: currentFilterCustomer
                    });

                    break;
                case 'sort':

                    onChangePageNotifications({
                        page: tableState.page,
                        sort: onSortNotifications(sortFilter),
                        direction: sortDirection,
                        filter_status: currentFilterStatus,
                        filter_owner: currentFilterOwner,
                        filter_customer: currentFilterCustomer
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchNotifications(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchNotifications(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Notification By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Notifications with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },



    };


    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Notifications →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}

function numberWithCommas(x, CURRENCY) {
    if (isNaN(x)) return x;

    return (x).toLocaleString('en-US', {
        style: 'currency',
        currency: CURRENCY,
    });

}


export default AdminNotifications

