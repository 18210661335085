import { ChevronRight, WarningAmberOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { ArrowForward, ChevronForward } from 'react-ionicons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Dropdown from '../Dropdown/Dropdown';
import { FooterDashboard } from '../Footer';
import Header from '../Header';

const ProviderOrders = (props) => {
  const [filter, setFilter] = useState('all');

  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} isProvider={true} />
      <div className='flex flex-col mx-auto my-4 w-full max-w-xl px-4 sm:px-6 lg:px-8' style={{ flexGrow: 1 }}>
        <div className='flex mb-4 w-full align-items-center justify-content-between'>
          <h2>ORDERS</h2>

          <Dropdown
            value={filter}
            options={[{ title: 'All Orders', value: 'all' }]}
            onChange={(value) => setFilter(value)}
            searchDisabled={true}
            isProvider={true}
            outlined
          />
        </div>

        <OrderCard />
      </div>

      <div style={{ height: '50px' }}></div>
      <FooterDashboard {...props} />
    </div>
  );
};

export default ProviderOrders;

const OrderCard = () => {
  return (
    <div className='flex flex-col p-2 w-full bg-light-gray rounded-lg'>
      <div className='flex align-items-center justify-content-between w-full color-red'>
        <label className='mb-2 py-1 font-bold text-sm rounded-full'>
          <WarningAmberOutlined /> TODAY - 10:00
        </label>
        <div className='flex gap-2'>
          <label className='mb-2 ml-auto px-4 py-1 text-white rounded-full' style={{ background: '#8F00FF' }}>
            New
          </label>
          <Link
            className='mb-2 ml-auto pl-4 py-1 text-white rounded-full'
            style={{ background: '#FFA500' }}
            to='/newux/orders/request'
          >
            Request <ChevronRight />
          </Link>
        </div>
      </div>
      <div className='flex gap-2'>
        <img src='/assets/cities/amsterdam.png' className='border-mid-gray' style={{ width: '60px', height: '56px' }} />
        <div className='w-full'>
          <h2 className='text-sm text-ellipsis'>Uppa Baby Stroller</h2>
          <h3 className='text-sm' style={{ fontStyle: 'italic' }}>
            Aishwarya Rajamohan
          </h3>

          <div className='flex mt-2 w-full justify-content-between'>
            <p className='font-semibold'>1x</p>
            <p>16$</p>
          </div>

          <div className='flex gap-8 mt-2 w-full justify-content-between '>
            <p className='font-semibold'>4days</p>
            <p>27-03-2023 - 28-03-2023</p>
          </div>

          <div className='flex justify-content-between mt-2 w-full'>
            <p>ID: #5235266</p>
            <Link to='/newux/orders/details' type='button' className='flex gap-1 color-pr'>
              Details <ChevronForward color={'#26bfbf'} />
            </Link>
          </div>
        </div>
      </div>

      <button
        type='button'
        className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
        onClick={() => {}}
      >
        ORDER DELIVERED <ArrowForward style={{ color: '#fff' }} />
      </button>
    </div>
  );
};
