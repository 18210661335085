import React, { useState, useEffect } from "react";

import LoaderMaster from "../Loaders/Loaders";

import { postApiPrivate } from "../../api";

import { CloseSharp, LeafSharp, SaveSharp, TrashBinSharp } from "react-ionicons";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, TextField } from "@mui/material";
import DateSelector from "../General/DateSelector";

import "../Profile/z_profile.css";
import { Filter_Array } from "../Modals/Constants";


const AdminCouponsDetails = (props) => {

  const { TRANSLATE, history, match } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [isCalendar, setCalendar] = useState(false);

  const [PUBLIC_COUPON, setCoupon] = useState({});


  useEffect(() => {

    var coupon_id = match.params.id || '';

    onFetchPublic(coupon_id);

  }, []);

  const onSaveAction = (id) => {
    swal({
      title: 'Confirm to Save',
      text: 'Please insert the CONFIRM to update this coupon',
      content: "input",
    })
      .then((value) => {

        if (value === 'CONFIRM') {

          onSaveCoupon(id)
        }
        else {

          swal('Invalid Password', 'You are not authorized to update this coupon', 'error');
        }

      });
  }

  const onFetchPublic = async (coupon_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setEdited(false);
    setLoading(true);

    try {

      const FORM = { id: coupon_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/coupons/details', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));


      setCoupon(DATA_RESPONSE.data);
      setLoading(false);

    }
    catch (e) {

      setLoading(false);
    }

  }

  const onUpdateCoupon = async (key, value) => {

    setEdited(true);
    setCoupon({
      ...PUBLIC_COUPON,
      [key]: value
    });

  }

  const onSaveCoupon = async (coupon_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      var FORM = {
        id: coupon_id,
        type: PUBLIC_COUPON.type,
        status: PUBLIC_COUPON.status,
        code: PUBLIC_COUPON.code,
        value: PUBLIC_COUPON.value,
        from: PUBLIC_COUPON.from,
        to: PUBLIC_COUPON.to,
        limit: PUBLIC_COUPON.limit,
        categories: PUBLIC_COUPON.categories

      };


      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/coupons/edit', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update This Coupon', 'error');
        onFetchPublic(coupon_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Coupon Updated', 'This Coupon has been updated successfully !!!', 'success');
      onFetchPublic(coupon_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This Coupon', 'error');
      onFetchPublic(coupon_id);
    }

  }

  const onDeleteCoupon = async (coupon_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: coupon_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/coupons/delete', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Delete This Coupon', 'error');
        onFetchPublic(coupon_id);
        console.log("ERROR FROM API LOADING COUPON DELETE", DATA_RESPONSE.error);
        return
      }

      setSaving(false);
      swal('Coupon Deleted', 'This Coupon has been deleted successfully !!!', 'success');
      history.replace('/admin/coupons');

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Delete This Coupon', 'error');
      onFetchPublic(coupon_id);
    }
  }

  const onRecoverCoupon = async (coupon_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: coupon_id, recover: true };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/coupons/delete', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Recover This Coupon', 'error');
        onFetchPublic(coupon_id);
        console.log("ERROR FROM API LOADING COUPON DELETE", DATA_RESPONSE.error);
        return
      }

      setSaving(false);
      swal('Coupon Recovered', 'This Coupon has been recovered successfully !!!', 'success');

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Delete This Coupon', 'error');
      onFetchPublic(coupon_id);
    }
  }

  const onSelectCategory = (category) => {
    const oldCategories = PUBLIC_COUPON?.categories || [];

    let categoryIndex = oldCategories.findIndex((item) => item === category);
    if (categoryIndex === -1) {
      const newCategories = [
        ...oldCategories,
        category
      ]
      onUpdateCoupon('categories', newCategories);
    }
    else {
      const newCategories = oldCategories.filter((item) => item !== category);
      onUpdateCoupon('categories', newCategories);
    }

  }


  if (isLoading) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading} />
    )

  }

  if (isSaving) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving} />
    )

  }


  const {
    id,
    type,
    status,
    code,
    value,
    from,
    to,
    limit,
    isDeleted,
    createdAt,
    updatedAt

  } = PUBLIC_COUPON;


  const managerObx = PUBLIC_COUPON.manager || {};
  const manager_id = managerObx.id || '';
  const manager_name = managerObx.name || '';
  const manager_img = managerObx.img || '';

  const categories = PUBLIC_COUPON.categories || [];


  return (
    <>
      <Container maxWidth="xl" style={{ background: '#f0f5f9' }}>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} md={6}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>

              {!isEdited && (
                <>

                  {isDeleted ? (
                    <div className="top_add_cart"
                      style={{ backgroundColor: 'var(--success)' }}
                      onClick={() => {
                        swal({
                          title: 'Confirm to Recover',
                          text: 'Please insert CONFIRM to recover this coupon',
                          content: "input",
                        })
                          .then((value) => {

                            if (value === 'CONFIRM') {

                              onRecoverCoupon(id)
                            }
                            else {

                              swal('Invalid Password', 'You are not authorized to recover this coupon', 'error');
                            }

                          });
                      }}
                    >
                      <LeafSharp color={'#fff'} style={{ marginRight: 10 }} /> Recover Coupon
                    </div>
                  ) : (
                    <div className="top_add_cart"
                      style={{ backgroundColor: '#e64942' }}
                      onClick={() => {
                        swal({
                          title: 'Confirm to Delete',
                          text: 'Please insert CONFIRM to delete this coupon',
                          content: "input",
                        })
                          .then((value) => {

                            if (value === 'CONFIRM') {

                              onDeleteCoupon(id)
                            }
                            else {

                              swal('Invalid Password', 'You are not authorized to delete this coupon', 'error');
                            }

                          });
                      }}
                    >
                      <TrashBinSharp color={'#fff'} style={{ marginRight: 10 }} /> Delete Coupon
                    </div>
                  )}

                  <div className="top_add_cart" style={{ backgroundColor: '#ccc' }} onClick={() => history.goBack()}>
                    <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Go Back
                  </div>

                </>
              )}

              {isEdited && (
                <>

                  <div className="top_add_cart"
                    style={{ backgroundColor: '#59ad6a' }}
                    onClick={() => onSaveAction(id)}

                  >
                    <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Update Coupon
                  </div>

                  <div className="top_add_cart" style={{ backgroundColor: '#ccc' }} onClick={() => onFetchPublic(id)}>
                    <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Cancel Changes
                  </div>

                </>
              )}


            </div>
          </Grid>
          <Grid item xs={12} lg={5} md={6}>

            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

              <div className="top_box_magic" style={{ marginBottom: 20 }}>
                <h1>
                  Status
                </h1>

                {status ? (
                  <Chip label="Active" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="success" onClick={(e) => onUpdateCoupon('status', false)} />
                ) : (
                  <Chip label="Unactive" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="warning" onClick={(e) => onUpdateCoupon('status', true)} />
                )}
              </div>

              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Code
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    value={code}
                    label={'.'}
                    onChange={(e) => onUpdateCoupon('code', e.target.value.toUpperCase())}
                    InputLabelProps={{ shrink: false }}
                    placeholder="Type here..."

                  />
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Value
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    label={'.'}
                    InputLabelProps={{ shrink: false }}
                    value={value}
                    onChange={(e) => onUpdateCoupon('value', e.target.value)}
                    type="number"

                  />
                </div>
              </div>


              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Type
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <select
                    className={'gridyx-select'}
                    value={type}
                    label={'.'}
                    onChange={(e) => onUpdateCoupon('type', e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    select
                  >
                    <option value={null}>
                      ---- Select a coupon type ----
                    </option>
                    <option value="percentage">
                      Percentage
                    </option>
                    <option value="currency">
                      Currency
                    </option>

                  </select>
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Validity Date Range
                </h1>
                <p>
                  If Empty, coupon has no expiration or starting validity date
                </p>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    InputLabelProps={{ shrink: false }}
                    value={from && to ? `${from} - ${to}` : ``}
                    onClick={() => setCalendar(true)}

                  />
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Limit
                </h1>
                <p>
                  Cart need to be at least the limit value to accept the discount from this coupon
                </p>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    label={''}
                    InputLabelProps={{ shrink: false }}
                    value={limit}
                    onChange={(e) => onUpdateCoupon('limit', e.target.value)}
                    type="number"

                  />
                </div>
              </div>
              {/* <pre>
                {JSON.stringify(categories, null, 2)}
              </pre> */}
              <div className="top_box_magic">
                <h1>
                  Categories
                </h1>
                <p>
                  Coupon is valid if you have 1 product that match one of the categories
                </p>
                <div className="flex flex-wrap gap-3" style={{ margin: '10px 0px' }}>
                  {Filter_Array.map((item, index) => {

                    const Cat_Name = item['en'];
                    const Cat_Code = item.code;
                    const isActive = categories.includes(Cat_Code);

                    return (
                      <div className={'relative h-[72px] w-[72px] md:h-[90px] md:w-[90px] shrink-0 rounded-2xl flex-col flex justify-center align-center items-center cursor-pointer md:hover:bg-zinc-100 '+(isActive ? 'border-2 border-green-700' : '')} key={index} onClick={() => onSelectCategory(Cat_Code)}>
                        <img className='w-[26px] h-[26px] md:w-auto md:h-auto' src={item.icon} alt="" />
                        <span className='text-xs whitespace-nowrap'>{Cat_Name}</span>
                        {isActive && (
                          <div className='bg-green-700 rounded-full' style={{ position: 'absolute', bottom: 10, right: 10 }}>
                            <img src='/assets/img/check-white.svg' alt='' />
                          </div>
                        )}
                      </div>
                    )

                  })}
                </div>
              </div>

            </div>


          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>

              <div className="top_box_magic">
                <h1>
                  Coupon Created
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <p>
                    {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                  </p>
                </div>
              </div>
              <div className="top_box_magic">
                <h1>
                  Coupon Updated
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <p>
                    {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                  </p>
                </div>
              </div>

              {manager_id && (
                <div className="top_box_magic">
                  <h1>
                    Manager
                  </h1>
                  <Chip
                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                    label={manager_name.toLowerCase()}
                    variant="outlined"
                    size='medium'
                    style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                    onClick={() => history.push('/admin/users/profile/' + manager_id)}
                  />
                </div>
              )}



            </div>
          </Grid>
        </Grid>


      </Container>
      {isCalendar && (
        <DateSelector {...props}
          onClose={() => setCalendar(false)}
          from={from}
          to={to}
          isRange={true}
          title={'Select Coupon Range'}
          description={'Choose the coupon validity date range from the calendar'}
          extra={'This coupon will be valid for rentals between:'}
          onSave={(item) => {
            setEdited(true);
            setCoupon({
              ...PUBLIC_COUPON,
              from: item.from,
              to: item.to
            });

            setCalendar(false);
          }}
        />
      )}
    </>


  )


}




export default AdminCouponsDetails

