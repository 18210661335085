import React, { useEffect, useState, useRef } from 'react';

import LoaderMaster from '../Loaders/Loaders';

import {AddOutline, SchoolOutline, CopyOutline, ShirtOutline, ReaderOutline, ChatbubbleEllipsesOutline, StarOutline, LogOutOutline, LogInOutline, AirplaneOutline, BarcodeOutline, CardOutline, CheckmarkCircleSharp, ImageOutline, LocationOutline, LogoEuro, MailOutline, MapOutline, MedkitOutline, PhonePortraitOutline, StorefrontOutline, WarningSharp, HandLeftOutline, LogoApple, LogoAmazon, LocateOutline, TrendingUpOutline, PencilOutline, ArrowBackOutline, DesktopOutline, SearchOutline} from 'react-ionicons'
import ReactCountryFlag from 'react-country-flag';

import { Link } from 'react-router-dom';

import swal from 'sweetalert';

const AdminSitemap = (props) => {

    const {USER, TRANSLATE, history} = props;

    const [isLoading, setLoading] = useState(true);
  
    useEffect(()=>{
  
      console.log("USER ===> ", USER);

        setLoading(false)

        if(USER.admin){
            
        }
        else{
            setLoading(false)
            swal('You\'re not Authorized', 'You are not authorized to be here', 'error')
        
            history.push('/logout')
           
        }
      
    }, []);

  
    if(isLoading){
      return(
          <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
      );
    }

    return (
        <div className='container-fluid mb-5' style={{background: '#f0f5f9'}}>
            
            {USER.admin ? (
                <>  
                    <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>
                        <h1 style={{fontSize: 60, fontWeight: 100}}>Sitemap <span style={{fontWeight: 'bold'}}>Area</span></h1>
                    </div>
                    <MenuAdmin {...props} />
                </>
            ) : (
                <>
                   
                </>
            )}
                   
        </div>
    );
    
}



const MenuAdmin = (props) => {

    const {TRANSLATE, USER} = props;

    let MenuClass = 'col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6 mb-4';
    let MenuStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        fontSize: 18,
        fontWeight: 'bold',
        width: '100%',
        background: '#fff',
        paddingTop: 20,
        paddingBottom: 20
    }

    return (
        <div className='row m-0 p-0 px-5 justify-content-center' style={{width:'100%'}}>
            
                <div className={MenuClass}>
                    <a href="https://www.babonbo.com/sitemap_site.xml" target={'_blank'} className='bg-light' style={MenuStyle}>
                        <DesktopOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Website
                    </a>
                </div>

                <div className={MenuClass}>
                    <a href="https://www.babonbo.com/sitemap_search.xml" target={'_blank'} className='bg-light' style={MenuStyle}>
                        <SearchOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Search
                    </a>
                </div>

                <div className={MenuClass}>
                    <a href="https://www.babonbo.com/sitemap_stores.xml" target={'_blank'} className='bg-light' style={MenuStyle}>
                        <StorefrontOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Stores
                    </a>
                </div>

                <div className={MenuClass}>
                    <a href="https://www.babonbo.com/sitemap_products.xml" target={'_blank'} className='bg-light' style={MenuStyle}>
                        <ShirtOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Products
                    </a>
                </div>

                <div className={MenuClass}>
                    <a href="https://www.babonbo.com/sitemap_blog.xml" target={'_blank'} className='bg-light' style={MenuStyle}>
                        <PencilOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        Blog
                    </a>
                </div>

                <div className={MenuClass}>
                    <Link to="/admin/marketing" className='bg-light' style={MenuStyle}>
                        <ArrowBackOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                        {TRANSLATE.go_back}
                    </Link>
                </div>
                

        </div>
    )
}


export default AdminSitemap

