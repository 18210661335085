import React, { useEffect, useState } from 'react';

import { Route, Switch } from 'react-router-dom';

import Header from './Header';

import { connect } from 'react-redux';
import { onUpdateLanguage } from '../../actions';

import AdminRoot from './AdminRoot';

import AdminAnalytics from './AdminAnalytics';
import AdminApprovals from './AdminApprovals';
import AdminBank from './AdminBank';
import AdminBlog from './AdminBlog';
import AdminBrands from './AdminBrands';
import AdminCalendary from './AdminCalendary';
import AdminCoupons from './AdminCoupons';
import AdminCustomers from './AdminCustomers';
import AdminDashboard from './AdminDashboard';
import AdminDatabase from './AdminDatabase';
import AdminGeopoints from './AdminGeopoints';
import AdminGiftCards from './AdminGiftCards';
import AdminInbox from './AdminInbox';
import AdminLegal from './AdminLegal';
import AdminListing from './AdminListing';
import AdminLocations from './AdminLocations';
import AdminMap from './AdminMap';
import AdminMarketing from './AdminMarketing';
import AdminNotifications from './AdminNotifications';
import AdminOrders from './AdminOrders';
import AdminPages from './AdminPages';
import AdminPartners from './AdminPartners';
import AdminPayments from './AdminPayments';
import AdminPlayground from './AdminPlayground';
import AdminProducts from './AdminProducts';
import AdminPush from './AdminPush';
import AdminReferrals from './AdminReferrals';
import AdminReviews from './AdminReviews';
import AdminSitemap from './AdminSitemap';
import AdminStores from './AdminStores';

import AdminAnalyticsDetails from './AdminAnalyticsDetails';
import AdminApprovalsDetails from './AdminApprovalsDetails';
import AdminBankDetails from './AdminBankDetails';
import AdminBlogDetails from './AdminBlogDetails';
import AdminBrandsDetails from './AdminBrandsDetails';
import AdminCouponsDetails from './AdminCouponsDetails';
import AdminCustomersDetails from './AdminCustomersDetails';
import AdminGeopointsDetails from './AdminGeopointsDetails';
import AdminGiftCardsDetails from './AdminGiftCardsDetails';
import AdminInboxDetails from './AdminInboxDetails';
import AdminLegalDetails from './AdminLegalDetails';
import AdminListingDetails from './AdminListingDetails';
import AdminLocationsDetails from './AdminLocationsDetails';
import AdminNotificationsDetails from './AdminNotificationsDetails';
import AdminOrdersDetails from './AdminOrdersDetails';
import AdminPagesDetails from './AdminPagesDetails';
import AdminPartnersDetails from './AdminPartnersDetails';
import AdminProductsDetails from './AdminProductsDetails';
import AdminPushDetails from './AdminPushDetails';
import AdminReferralsDetails from './AdminReferralsDetails';
import AdminReviewsDetails from './AdminReviewsDetails';
import AdminStoresDetails from './AdminStoresDetails';

import AdminAnalyticsAdd from './AdminAnalyticsAdd';
import AdminApprovalsAdd from './AdminApprovalsAdd';
import AdminBankAdd from './AdminBankAdd';
import AdminBlogAdd from './AdminBlogAdd';
import AdminBrandsAdd from './AdminBrandsAdd';
import AdminCouponsAdd from './AdminCouponsAdd';
import AdminCustomersAdd from './AdminCustomersAdd';
import AdminGeopointsAdd from './AdminGeopointsAdd';
import AdminGiftCardsAdd from './AdminGiftCardsAdd';
import AdminListingAdd from './AdminListingAdd';
import AdminLocationsAdd from './AdminLocationsAdd';
import AdminNotificationsAdd from './AdminNotificationsAdd';
import AdminOrdersAdd from './AdminOrdersAdd';
import AdminPagesAdd from './AdminPagesAdd';
import AdminPartnersAdd from './AdminPartnersAdd';
import AdminProductsAdd from './AdminProductsAdd';
import AdminPushAdd from './AdminPushAdd';
import AdminReferralsAdd from './AdminReferralsAdd';
import AdminReviewsAdd from './AdminReviewsAdd';
import AdminStoresAdd from './AdminStoresAdd';

import AdminAnalyticsCity from './AdminAnalyticsCity';
import AdminAnalyticsDelivery from './AdminAnalyticsDelivery';
import AdminAnalyticsGeneral from './AdminAnalyticsGeneral';
import AdminAnalyticsExpress from './AdminAnalyticsExpress';
import AdminAnalyticsProduct from './AdminAnalyticsProduct';
import AdminAnalyticsStore from './AdminAnalyticsStore';

import AdminListingGame from './AdminListingGame';

import './z_admin.css';
import './z_pagination.css';


const AdminMaster = (props) => {
  const { USER, onChangeLanguage, LANGUAGE, TRANSLATE, history, match } = props;
  const [isSidebar, setSidebar] = useState(false);
  const [isActiveMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    const JWT = typeof global.window !== 'undefined' ? localStorage.getItem('jwt_token') : null;
    if (!JWT) {
      return history.replace('/login');
    } else {
      //alert("USER ===> "+JSON.stringify(USER, null, 2));

      if (!USER.admin) {
        // return history.replace('/'+LANGUAGE+'/403');
      }
    }
  }, [USER]);

  useEffect(() => {
    setSidebar(false);
    setActiveMenu(false);
  }, [props.match.url]);

  useEffect(() => {
    onChangeLanguage('en');
  }, []);

  const sharedProps = {
    ...props,
    isSidebar,
    setSidebar,
    isActiveMenu,
    setActiveMenu,
    isAdmin: true,
  };

  const sharedPage = {
    TRANSLATE,
    USER,
    LANGUAGE,
    setActiveMenu,
    onChangeLanguage,
  };

  // if(!USER.admin){
  //     return (
  //       <LoaderMaster type="secondary" />
  //     )
  // }

  let MasterStyles = {
    width: '100%',
    height: '100vh',
    padding: 0,
    paddingTop: 50,
    background: '#f0f5f9',
  };

  if (!USER.name) {
    return null;
  }

  const CURRENT_URL = match.url;
  const isMap = CURRENT_URL.includes('/map');
  const finalContainer = isMap ? 'mx-auto max-w-[100%]' : 'mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10';

  return (
    <>
      <Header {...sharedProps} />

      <div className={finalContainer} style={isMap ? { height: 'calc(100% - 85px)' } : {}}>
        <Switch>
          <Route exact path='/' render={(props) => <AdminRoot {...props} {...sharedPage} />} />

          <Route exact path='/admin' render={(props) => <AdminDashboard {...props} {...sharedPage} />} />
          <Route exact path='/admin/dashboard' render={(props) => <AdminDashboard {...props} {...sharedPage} />} />

          <Route exact path='/admin/partners' render={(props) => <AdminPartners {...props} {...sharedPage} />} />
          <Route exact path='/admin/partners/:page' render={(props) => <AdminPartners {...props} {...sharedPage} />} />
          <Route
            path='/admin/partners/profile/new'
            render={(props) => <AdminPartnersAdd {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/partners/profile/:id'
            render={(props) => <AdminPartnersDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/products' render={(props) => <AdminProducts {...props} {...sharedPage} />} />
          <Route exact path='/admin/products/:page' render={(props) => <AdminProducts {...props} {...sharedPage} />} />
          <Route
            path='/admin/products/profile/new'
            render={(props) => <AdminProductsAdd {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/products/profile/:id'
            render={(props) => <AdminProductsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/listing' render={(props) => <AdminListing {...props} {...sharedPage} />} />
          <Route exact path='/admin/listing/game' render={(props) => <AdminListingGame {...props} {...sharedPage} />} />
          <Route exact path='/admin/listing/:page' render={(props) => <AdminListing {...props} {...sharedPage} />} />
          <Route path='/admin/listing/profile/new' render={(props) => <AdminListingAdd {...props} {...sharedPage} />} />
          <Route
            path='/admin/listing/profile/:id'
            render={(props) => <AdminListingDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/stores' render={(props) => <AdminStores {...props} {...sharedPage} />} />
          <Route exact path='/admin/stores/:page' render={(props) => <AdminStores {...props} {...sharedPage} />} />
          <Route path='/admin/stores/profile/new' render={(props) => <AdminStoresAdd {...props} {...sharedPage} />} />
          <Route
            path='/admin/stores/profile/:id'
            render={(props) => <AdminStoresDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/users' render={(props) => <AdminCustomers {...props} {...sharedPage} />} />
          <Route exact path='/admin/users/:page' render={(props) => <AdminCustomers {...props} {...sharedPage} />} />
          <Route path='/admin/users/profile/new' render={(props) => <AdminCustomersAdd {...props} {...sharedPage} />} />
          <Route
            path='/admin/users/profile/:id'
            render={(props) => <AdminCustomersDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/reviews' render={(props) => <AdminReviews {...props} {...sharedPage} />} />
          <Route exact path='/admin/reviews/:page' render={(props) => <AdminReviews {...props} {...sharedPage} />} />
          <Route path='/admin/reviews/profile/new' render={(props) => <AdminReviewsAdd {...props} {...sharedPage} />} />
          <Route
            path='/admin/reviews/profile/:id'
            render={(props) => <AdminReviewsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/brands' render={(props) => <AdminBrands {...props} {...sharedPage} />} />
          <Route exact path='/admin/brands/:page' render={(props) => <AdminBrands {...props} {...sharedPage} />} />
          <Route path='/admin/brands/profile/new' render={(props) => <AdminBrandsAdd {...props} {...sharedPage} />} />
          <Route
            path='/admin/brands/profile/:id'
            render={(props) => <AdminBrandsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/orders' render={(props) => <AdminOrders {...props} {...sharedPage} />} />
          <Route exact path='/admin/orders/:page' render={(props) => <AdminOrders {...props} {...sharedPage} />} />
          <Route path='/admin/orders/profile/new' render={(props) => <AdminOrdersAdd {...props} {...sharedPage} />} />
          <Route
            path='/admin/orders/profile/:id'
            render={(props) => <AdminOrdersDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/inbox' render={(props) => <AdminInbox {...props} {...sharedPage} />} />
          <Route exact path='/admin/inbox/:page' render={(props) => <AdminInbox {...props} {...sharedPage} />} />
          <Route
            path='/admin/inbox/profile/:conversation'
            render={(props) => <AdminInboxDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/locations' render={(props) => <AdminLocations {...props} {...sharedPage} />} />
          <Route
            exact
            path='/admin/locations/:page'
            render={(props) => <AdminLocations {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/locations/profile/new'
            render={(props) => <AdminLocationsAdd {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/locations/profile/:id'
            render={(props) => <AdminLocationsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/geopoints' render={(props) => <AdminGeopoints {...props} {...sharedPage} />} />
          <Route
            exact
            path='/admin/geopoints/:page'
            render={(props) => <AdminGeopoints {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/geopoints/profile/new'
            render={(props) => <AdminGeopointsAdd {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/geopoints/profile/:id'
            render={(props) => <AdminGeopointsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/referrals' render={(props) => <AdminReferrals {...props} {...sharedPage} />} />
          <Route
            exact
            path='/admin/referrals/:page'
            render={(props) => <AdminReferrals {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/referrals/profile/new'
            render={(props) => <AdminReferralsAdd {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/referrals/profile/:id'
            render={(props) => <AdminReferralsDetails {...props} {...sharedPage} />}
          />

          <Route
            exact
            path='/admin/notifications'
            render={(props) => <AdminNotifications {...props} {...sharedPage} />}
          />
          <Route
            exact
            path='/admin/notifications/:page'
            render={(props) => <AdminNotifications {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/notifications/profile/new'
            render={(props) => <AdminNotificationsAdd {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/notifications/profile/:id'
            render={(props) => <AdminNotificationsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/blog' render={(props) => <AdminBlog {...props} {...sharedPage} />} />
          <Route exact path='/admin/blog/:page' render={(props) => <AdminBlog {...props} {...sharedPage} />} />
          <Route path='/admin/blog/profile/new' render={(props) => <AdminBlogAdd {...props} {...sharedPage} />} />
          <Route path='/admin/blog/profile/:id' render={(props) => <AdminBlogDetails {...props} {...sharedPage} />} />

          <Route exact path='/admin/resources' render={(props) => <AdminPages {...props} {...sharedPage} />} />
          <Route exact path='/admin/resources/:page' render={(props) => <AdminPages {...props} {...sharedPage} />} />
          <Route path='/admin/resources/profile/new' render={(props) => <AdminPagesAdd {...props} {...sharedPage} />} />
          <Route
            path='/admin/resources/profile/:id'
            render={(props) => <AdminPagesDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/coupons' render={(props) => <AdminCoupons {...props} {...sharedPage} />} />
          <Route exact path='/admin/coupons/:page' render={(props) => <AdminCoupons {...props} {...sharedPage} />} />
          <Route path='/admin/coupons/profile/new' render={(props) => <AdminCouponsAdd {...props} {...sharedPage} />} />
          <Route
            path='/admin/coupons/profile/:id'
            render={(props) => <AdminCouponsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/giftcards' render={(props) => <AdminGiftCards {...props} {...sharedPage} />} />
          <Route
            exact
            path='/admin/giftcards/:page'
            render={(props) => <AdminGiftCards {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/giftcards/profile/new'
            render={(props) => <AdminGiftCardsAdd {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/giftcards/profile/:id'
            render={(props) => <AdminGiftCardsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/bank' render={(props) => <AdminBank {...props} {...sharedPage} />} />
          <Route exact path='/admin/bank/:page' render={(props) => <AdminBank {...props} {...sharedPage} />} />
          <Route path='/admin/bank/profile/new' render={(props) => <AdminBankAdd {...props} {...sharedPage} />} />
          <Route path='/admin/bank/profile/:id' render={(props) => <AdminBankDetails {...props} {...sharedPage} />} />

          <Route exact path='/admin/approvals' render={(props) => <AdminApprovals {...props} {...sharedPage} />} />
          <Route
            exact
            path='/admin/approvals/:page'
            render={(props) => <AdminApprovals {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/approvals/profile/new'
            render={(props) => <AdminApprovalsAdd {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/approvals/profile/:id'
            render={(props) => <AdminApprovalsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/analytics' render={(props) => <AdminAnalytics {...props} {...sharedPage} />} />
          <Route
            exact
            path='/admin/analytics/general'
            render={(props) => <AdminAnalyticsGeneral {...props} {...sharedPage} />}
          />
          <Route
            exact
            path='/admin/analytics/express'
            render={(props) => <AdminAnalyticsExpress {...props} {...sharedPage} />}
          />
          <Route
            exact
            path='/admin/analytics/city'
            render={(props) => <AdminAnalyticsCity {...props} {...sharedPage} />}
          />
          <Route
            exact
            path='/admin/analytics/store'
            render={(props) => <AdminAnalyticsStore {...props} {...sharedPage} />}
          />
          <Route
            exact
            path='/admin/analytics/product'
            render={(props) => <AdminAnalyticsProduct {...props} {...sharedPage} />}
          />
          <Route
            exact
            path='/admin/analytics/delivery'
            render={(props) => <AdminAnalyticsDelivery {...props} {...sharedPage} />}
          />
          <Route
            exact
            path='/admin/analytics/:page'
            render={(props) => <AdminAnalytics {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/analytics/profile/new'
            render={(props) => <AdminAnalyticsAdd {...props} {...sharedPage} />}
          />
          <Route
            path='/admin/analytics/profile/:id'
            render={(props) => <AdminAnalyticsDetails {...props} {...sharedPage} />}
          />

          <Route exact path='/admin/legal' render={(props) => <AdminLegal {...props} {...sharedPage} />} />
          <Route path='/admin/legal/profile/:id' render={(props) => <AdminLegalDetails {...props} {...sharedPage} />} />

          <Route exact path='/admin/push' render={(props) => <AdminPush {...props} {...sharedPage} />} />
          <Route exact path='/admin/push/:page' render={(props) => <AdminPush {...props} {...sharedPage} />} />
          <Route path='/admin/push/profile/new' render={(props) => <AdminPushAdd {...props} {...sharedPage} />} />
          <Route path='/admin/push/profile/:id' render={(props) => <AdminPushDetails {...props} {...sharedPage} />} />

          <Route exact path='/admin/sitemap' render={(props) => <AdminSitemap {...props} {...sharedPage} />} />

          <Route exact path='/admin/payments' render={(props) => <AdminPayments {...props} {...sharedPage} />} />
          <Route exact path='/admin/marketing' render={(props) => <AdminMarketing {...props} {...sharedPage} />} />
          <Route exact path='/admin/calendary' render={(props) => <AdminCalendary {...props} {...sharedPage} />} />
          <Route exact path='/admin/map' render={(props) => <AdminMap {...props} {...sharedPage} />} />
          <Route exact path='/admin/database' render={(props) => <AdminDatabase {...props} {...sharedPage} />} />

          <Route exact path='/admin/playground' render={(props) => <AdminPlayground {...props} {...sharedPage} />} />
        </Switch>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.user,
    PROVIDER: state.provider,
    CART: state.cart,
    PRODUCT: state.product,
    PRODUCTS: state.products,
    LANGUAGE: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeLanguage: (lang) => dispatch(onUpdateLanguage(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminMaster);
