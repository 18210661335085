import React from 'react'
import ReactDOM from 'react-dom';

import App from './App';

import configureStore from './configure-store';

// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//     gtmId: 'GTM-K22RWGH'
// }

// TagManager.initialize(tagManagerArgs);


const initialState = global.window && global.window.INITIAL_STATE
const store = configureStore(initialState);

delete window.INITIAL_STATE

if(module.hot){
  ReactDOM.render(
    <App store={store}/>,
    document.getElementById('root')
  )
}
else{
  ReactDOM.hydrate(
    <App store={store}/>,
    document.getElementById('root')
  )
}


