import React, { useEffect, useState } from 'react';

import {postApiPrivate} from '../../api';

import { Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import ReactCountryFlag from 'react-country-flag';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import StarReview from '../General/Stars';
import { FilterList } from '@mui/icons-material';

import Countries_EN from '../Search/countries_en.json';

import StoreFilter from '../Modals/StoreFilter';
 

const AdminStores = (props) => {

    const {TRANSLATE, history, match, location} = props;

    const [isLoading, setLoading] = useState(true);
    
    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [STORES, setStores] = useState([]); 
    const [TOTAL, setTotal] = useState(null);

    const [isFilter, setFilter] = useState(false);

    const LIMIT = 20;
    let CURRENT = LIMIT*(PAGE+1);

    if(TOTAL < CURRENT){
        CURRENT = TOTAL;
    }

    useEffect(()=> {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if(!JWT){
            return history.replace('/login');
        }
        else{

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;
            var current_page = parseInt(page_id);

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            onFetchStores({
                ...routeParams,
                page: current_page,
                sort: sort_id,
                direction: direction_id
            });
            
        }

        window.scrollTo(0,0);

    }, [match.params.page, location.search]);

    const onFetchStores = async (params) => {

        const {page, sort, direction, status, approval, city, country, range, name, lat, lon, categories } = params;
        const {push, language, currency, orders, products, delivery, start, end, reviews} = params

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
        setLoading(true);

        try{

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                status,
                approval,
                country,
                city,
                name,
                range,
                lat, 
                lon,
                categories,
                push,
                language,
                currency,
                orders,
                products,
                delivery,
                reviews,
                start,
                end,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/stores', FORM , JWT);

            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setStores(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0,0)
            

        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchStores = async(query) => {

        setSearchText(query);

        if(!query || query.length == 0){

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchStores({
                page: current_page
            });

            return 
        }

        if(query.length <= 3){

            return 
        }


        try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/stores/search', {query: query} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setStores(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);

            setTotal(0);
            setStores([]);
            setLoading(false);
        }
      
           
    }

    const onSortStores = (sort) => {

        if(sort === 'id'){
            return 'objectId'
        }
        else if(sort === 'name'){
            return 'search_name'
        }
        else if(sort === 'img'){
            return 'photo'
        }
        else if(sort === 'lang'){
            return 'language'
        }
        else{
            return sort
        }
    }

    const onChangePageStores = ({page, sort, direction}) => {

        const routeParams = GetRouteSearchParams(location.search);
        const { status, approval, city, country, range, name, lat, lon, categories } = routeParams;
        const {push, language, currency, orders, products, delivery, start, end, reviews} = routeParams


        let newQuery = '/admin/stores/'+page;

        if(sort){
            newQuery = newQuery+'?sort='+sort+'&dir='+direction;
        }
        else{
            newQuery = newQuery+'?sort=updatedAt&dir=desc';
        }

        if(country) newQuery = newQuery + '&country='+country;

        if(city) newQuery = newQuery + '&city='+city+'&name='+name+'&lat='+lat+'&lon='+lon;

        if(range) newQuery = newQuery + '&range='+range;

        if(status) newQuery = newQuery + '&status='+status;

        if(approval) newQuery = newQuery + '&approval='+approval;

        if(categories) newQuery = newQuery + '&categories='+categories;

        if(push) newQuery = newQuery + '&push='+push;
        if(orders) newQuery = newQuery + '&orders='+orders;
        if(products) newQuery = newQuery + '&products='+products;
        if(delivery) newQuery = newQuery + '&delivery='+delivery;
        if(reviews) newQuery = newQuery + '&reviews='+reviews;

        if(language) newQuery = newQuery + '&language='+language;
        if(currency) newQuery = newQuery + '&currency='+currency;
        if(start) newQuery = newQuery + '&start='+start;
        if(end) newQuery = newQuery + '&end='+end;

        history.push(newQuery);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/admin/users/profile/'+customer_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/admin/stores/profile/'+store_id);
    }

    const onSelectLocation = (location_id) => {
        history.push('/admin/locations/profile/'+location_id);
    }
    
    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }
 
    //Table Data
    const columns = [ 
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={()=> onSelectStore(id)} style={{cursor: 'pointer'}}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "store_img",
            label: "STORE",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" }}),
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                    const store_name = storeObx.name || '';
                    const store_img = storeObx.img || '';

                    return (
                        <div>
                            <Chip
                                avatar={<Avatar alt={store_name} src={store_img} />}
                                label={store_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{textTransform: 'capitalize'}}
                                onClick={()=> onSelectStore(store_id)}
                            />
                        </div>
                    )
                }
            },
            
        },
        {
            name: "owner",
            label: "OWNER",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" }}),
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const ownerObx = storeObx.owner || {};
                    const customer_id = ownerObx.id || '';
                    const customer_name = ownerObx.name || '';
                    const customer_img = ownerObx.img || '';

                    return (
                        <div style={{maxWidth: 200}}>
                            <Chip
                                avatar={<Avatar alt={customer_name} src={customer_img} />}
                                label={customer_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{textTransform: 'capitalize'}}
                                onClick={()=> onSelectCustomer(customer_id)}
                            />
                        </div>
                        
                    )
                
                }
            },
            
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    let color = 'default';

                    if(label){
                        color = 'success';
                    }
                   
                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label ? 'ON' : 'OFF'} color={color} size='small'/>
                        </div>
                    )
                    
                }
            },
        },
        {
            name: "approval",
            label: "APPROVAL",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    let color = 'default';

                    if(label === 'true'){
                        color = 'warning';
                    }

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                   
                    return (
                        <div className='flex items-center justify-center cursor-pointer' onClick={()=> history.push('/admin/approvals/0?sort=updatedAt&dir=desc&store='+store_id)}>
                            <Chip label={label === 'true' ? 'NEED' : 'NO'} color={color} size='small'/>
                        </div>
                    )
                    
                }
            },
        },
        {
            name: 'score',
            label: 'SCORE',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                   

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' 
                                  onClick={()=> history.push('/admin/stores/profile/'+store_id)}
                            
                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'products_count',
            label: 'PRODUCTS',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                   
                    // const score = parseFloat(label);
                    // let scoreIndicator = '';

                    // if(score < 100){
                    //     scoreIndicator = 'bg-green-100 text-black';
                    // }
                    // else if(score >= 100 && score < 200){
                    //     scoreIndicator = 'bg-green-200 text-black';
                    // }
                    // else if(score >= 200 && score < 300){
                    //     scoreIndicator = 'bg-green-300 text-white';
                    // }
                    // else if(score >= 300 && score < 400){
                    //     scoreIndicator = 'bg-green-300 text-white';
                    // }
                    // else if(score >= 400 && score < 500){
                    //     scoreIndicator = 'bg-green-400 text-white';
                    // }
                    // else if(score >= 500 && score < 600){
                    //     scoreIndicator = 'bg-green-500 text-white';
                    // }
                    // else if(score >= 600 && score < 700){
                    //     scoreIndicator = 'bg-green-600 text-white';
                    // }
                    // else if(score >= 700 && score < 800){
                    //     scoreIndicator = 'bg-green-700 text-white';
                    // }
                    // else if(score >= 800 && score < 900){
                    //     scoreIndicator = 'bg-green-800 text-white';
                    // }
                    // else if(score >= 900 && score < 1000){
                    //     scoreIndicator = 'bg-green-900 text-white';
                    // }
                    // else if(score >= 1000){
                    //     scoreIndicator = 'bg-black text-white';
                    // }

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' 
                                  onClick={()=> history.push('/admin/products/0?sort=updatedAt&dir=desc&store='+store_id)}
                                  color="primary"
                            
                            />
                        </div>
                    )
                }
            }

        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <CopyToClipboard text={label}
                                            onCopy={() => onSelectCopy(label)}
                        >
                    
                            <Chip label={label} size='small' />

                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "phone",
            label: "Phone",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    if(!label){
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    return (
                        <CopyToClipboard text={label}
                                            onCopy={() => onSelectCopy(label)}
                        >
                    
                            <Chip label={label} size='small' />

                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "timezone",
            label: "Timezone",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >

                            <Chip label={label} size='small' />

                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "rating",
            label: "RATING",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }
                
            },
        },
        {
            name: 'reviews',
            label: 'REVIEWS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                   
                    const total = parseFloat(label);

                    if(total > 0){
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Chip label={label} size='small' color='warning'
                                      
                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' 
                                 
                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total_start',
            label: 'START',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                   
                    const total = parseFloat(label);

                    if(total > 0){
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Chip label={label} size='small' color='info'
                                      onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&store='+store_id)}
                                
                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' 
                                  onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&store='+store_id)}
                            
                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total_orders',
            label: 'ORDERS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                   
                    const total = parseFloat(label);

                    if(total > 0){
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Chip label={label} size='small' color='info'
                                      onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&store='+store_id)}
                                
                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' 
                                  onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&store='+store_id)}
                            
                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total_green',
            label: 'GREEN',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                   
                    const total = parseFloat(label);

                    if(total > 0){
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Chip label={label} size='small' color='success'
                                      onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=complete&store='+store_id)}
                                
                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' 
                                  onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=complete&store='+store_id)}
                            
                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total_red',
            label: 'RED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                   

                    const total = parseFloat(label);

                    if(total > 0){
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Chip label={label} size='small' color='error'
                                      onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=rejected&store='+store_id)}
                                
                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' 
                                  onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=rejected&store='+store_id)}
                            
                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total_yellow',
            label: 'YELLOW',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                   
                    const total = parseFloat(label);

                    if(total > 0){
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Chip label={label} size='small' color='warning'
                                      onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=pending&store='+store_id)}
                                
                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' 
                                  onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=pending&store='+store_id)}
                            
                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'address',
            label: 'Location',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};
                    
                    const countryCode = storeObx.country;
                    const storeAddress = storeObx.address;
                   
                    if(!storeAddress){
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    return (
                        <CopyToClipboard text={label}   
                                         onCopy={() => onSelectCopy(label)}
                        >
                            <Chip
                                avatar={<ReactCountryFlag countryCode={countryCode} style={{fontSize: 20, marginLeft: 10}} svg/>}
                                label={storeAddress.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{textTransform: 'capitalize'}}
                            />
                            
                        </CopyToClipboard>
                    )
                }
            }

        },
        {
            name: 'currency',
            label: 'CURRENCY',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            
                                {label === 'usd' && (
                                <>
                                    <ReactCountryFlag countryCode={'us'} style={{marginRight: 5, fontSize: 20}} /> USD
                                </>
                                )}
                                {label === 'eur' && (
                                <>
                                    <ReactCountryFlag  countryCode={'eu'} style={{marginRight: 5,  fontSize: 20}} /> EUR
                                </>
                                )}
                                {label === 'gbp' && (
                                <>
                                    <ReactCountryFlag  countryCode={'gb'} style={{marginRight: 5,  fontSize: 20}} /> GBP
                                </>
                                )}
                            
                        </div>
                    )
                }
            }

        },
       
        {
            name: "communication",
            label: "Communication",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }
                
            },
        },
        {
            name: "logistic",
            label: "Logistic",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }
                
            },
        },
        {
            name: "products",
            label: "Products",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }
                
            },
        },
        {
            name: 'updatedAt',
            label: 'UPDATED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            }

        },
        {
            name: 'createdAt',
            label: 'CREATED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            }

        },

        
    ];
  
    const data = STORES.map((item) => {

        moment.locale(TRANSLATE.lang);
 
        let new_store = {
            
            id: item.id,
            
            status: item.status,
            
            products_count: item.products_count || 0,

            name: item.name,
            city: item.city,
            img: item.img,
            rating: item.rating ? parseFloat(item.rating.toFixed(2)) : 0,
            reviews: item.reviews,
            phone: item.phone ? '+'+item.phone.replace('+', '') : '',
            currency: item.currency,
            email: item.email,
            country: item.country,
            address: item.address,
            score: item.score ? parseFloat(item.score.toFixed(2)) : 0,
            total_orders: item.total || 0,
            total_green: item.green || 0,
            total_red: item.red || 0,
            total_yellow: item.yellow || 0,
            total_start: item.start || 0,

            approval: item.approval ? 'true' : 'false',
            
            timezone: item.timezone,
            

            communication: item.communication  ? parseFloat(item.communication.toFixed(2)) : 0,
            logistic: item.logistic  ? parseFloat(item.logistic.toFixed(2)) : 0,
            products: item.products  ? parseFloat(item.products.toFixed(2)) : 0,

            updatedAt: moment(item.updatedAt).format('DD MMMM YYYY'),
            createdAt: moment(item.createdAt).format('DD MMMM YYYY')
        };
        
       

        return new_store
    }) 

    const customToolbar = () => (
        <Tooltip disableFocusListener title="Filter Stores">
            <IconButton onClick={() => setFilter(true)}>
                <FilterList />
            </IconButton>
        </Tooltip>
    );

    var routeParams = GetRouteSearchParams(location.search);

    const options = {
        filter: false,
        print: false,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,

        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageStores({
                        ...routeParams,
                        page: tableState.page,
                        sort: onSortStores(sortFilter),
                        direction: sortDirection 
                    });
                    
                    break;
                case 'sort':
                    
                    onChangePageStores({
                        ...routeParams,
                        page: tableState.page,
                        sort: onSortStores(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchStores(e.target.value);
            },
            onKeyUp:(e) => {
                if(e.keyCode === 13){
                    onSearchStores(e.target.value);
                }
            } 
        },
        searchPlaceholder: 'Search Store By Name',
        textLabels: {
            body: {
                noMatch: isLoading ? 
                    'Searching Stores with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
    
    };


    var filter_country = routeParams.country || null;
    var filter_status = routeParams.status || null;
    var filter_approval = routeParams.approval || null;

    
    let StoreTitle = 'All Stores';
    let StoreExtra = [];

    if(filter_status === 'true'){
        StoreTitle = 'Active Stores';
    }
    else if(filter_status === 'false'){
        StoreTitle = 'Inactive Stores';
    }

    if(filter_country){
        var matched_country = Countries_EN.find((item)=> item.value === filter_country)?.label;
        StoreTitle = StoreTitle + ' in '+matched_country;
    }

    if(filter_approval === 'true'){
        StoreExtra = [...StoreExtra, 'approved']
    }
    else if(filter_approval === 'false'){
        StoreExtra = [...StoreExtra, 'unapproved']
    }

    return (
        <>
            {isFilter && (
                <StoreFilter {...props}
                    onClose={() => setFilter(false)}
       
               />
            )}

            <MUIDataTable
                title={
                    <div className='font-light md:text-5xl text-xl text-lg py-8'>

                        {'Stores Found → '}
                        
                        {isLoading ? (

                            <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />
                        
                            ) : (

                            <>
                               {' '}<span style={{color: '#26BFBF'}}>{CURRENT}</span>{'/'+TOTAL}
                            </>

                        )} 
                         
                    </div>
                }
                data={data}
                columns={columns}
                options={options}
            />
        </>
    );
    
}  

export default AdminStores

