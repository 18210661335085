import React, { useState } from 'react';
import { ArrowForwardCircleOutline, ChevronDown, LocationSharp } from 'react-ionicons';
import './search_location.css';

const mockDistricts = [
  { district: 'City of London', total: 50, cost_delivery: 0, currency: 'eur' },
  { district: 'City of Westminister', total: 47, cost_delivery: 20, currency: 'eur' },
  { district: 'City of Westminister', total: 0, cost_delivery: null, currency: 'eur' },
];

const SearchLocation = (props) => {
  const { FoundCities, TRANSLATE, USER, outlined } = props;
  const { label, selectedLocation, setSelectedLocation } = props;

  const [searchText, setSearchText] = useState('');
  const [isFocus, setFocus] = useState(false);

  const onSelectLocation = (value) => {
    setSearchText('');
    setSelectedLocation(value);
    setFocus(false);
  };

  const onSearch = (e) => {
    //setSelectedLocation(null);
    setSearchText(e.target.value);
  };

  const results = mockDistricts;
  return (
    <>
      {(isFocus || !selectedLocation) && (
        <div className='relative inline-flex flex-column w-full' id='search-section' aria-labelledby='search-label'>
          {label && (
            <label className={isFocus || !selectedLocation ? 'inline-label' : ''} id='search-label'>
              {label}
            </label>
          )}

          <div className='search-box px-2 border-light-gray' aria-label='Search Box'>
            <input
              value={searchText}
              onChange={onSearch}
              type='text'
              placeholder='Search'
              onFocus={() => setFocus(true)}
              aria-label='Search Input'
            />
            <LocationSharp width='24px' height='24px' color='#fc6170' aria-hidden='true' />
          </div>
        </div>
      )}

      {!isFocus && selectedLocation && (
        <div className='quicksand font-bold' id='selected-location-section' aria-labelledby='selected-location-label'>
          {label && <label id='selected-location-label'>{label}</label>}
          <SelectedLocation {...props} result={selectedLocation || mockDistricts[0]} setFocus={setFocus} />
        </div>
      )}

      <div className='overflow-auto' id='search-results' aria-label='Search Results'>
        {FoundCities?.length > 0 &&
          FoundCities.map((item, index) => {
            const costs = item.costs || [];
            const costsFormatted = costs.sort((a, b) => a - b);

            return (
              <SearchResult
                {...props}
                result={{
                  district: item.name,
                  total: item.products,
                  cost_delivery: parseFloat(costsFormatted[0]),
                  currency: USER.currency || 'eur',
                }}
                key={`search-result-${index}`}
                setSelectedLocation={(value) => onSelectLocation(value)}
              />
            );
          })}
      </div>
    </>
  );
};

export default SearchLocation;

const SearchResult = (props) => {
  const { result, setSelectedLocation } = props;

  return (
    <button
      type='button'
      className={`search-result-container ${result.total < 1 ? 'passive' : ''}`}
      onClick={() => setSelectedLocation(result)}
      aria-label={`Select ${result.district}`}
    >
      {result.cost_delivery !== null && (
        <label
          className={`label-box-delivery ${result.cost_delivery > 0 ? '' : 'free-pickup'}`}
          aria-label='Delivery Cost'
        >
          {result.cost_delivery > 0 ? `Delivery ${result.cost_delivery} ${result.currency}` : 'Free Self-Pickup Option'}
        </label>
      )}
      <div className='search-result' aria-labelledby={`district-${result.district}`}>
        <div className='district-name' id={`district-${result.district}`}>
          {result.district}
        </div>
        <div className={'district-info'} aria-label='District Info'>
          {`${result.total} + Products`}
          <ArrowForwardCircleOutline width={'20px'} height={'20px'} color={'#fc6170'} aria-hidden='true' />
        </div>
      </div>
    </button>
  );
};

const SelectedLocation = (props) => {
  const { result, setFocus } = props;

  return (
    <button
      type='button'
      className={`search-result-container my-3 ${result.total < 1 ? 'passive' : ''}`}
      onClick={() => setFocus(true)}
      aria-label={`Select ${result.district}`}
    >
      {result.cost_delivery !== null && (
        <label className={`label-box-delivery ${result.cost_delivery > 0 ? '' : 'free-pickup'}`}>
          {result.cost_delivery > 0 ? `Delivery ${result.cost_delivery} ${result.currency}` : 'Free Self-Pickup Option'}
        </label>
      )}
      <div className='search-result' aria-labelledby={`district-${result.district}`}>
        <div className='district-name' id={`district-${result.district}`}>
          {result.district}
        </div>
        <div className={'district-info'} aria-label='District Info'>
          <ChevronDown width={'20px'} height={'20px'} color={'#fc6170'} />
        </div>
      </div>
    </button>
  );
};
