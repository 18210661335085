import React, { useEffect, useState } from "react";

import { postApiPrivate } from "../../api";

import moment from "moment";
import swal from "sweetalert";
import { CloseOutline } from "react-ionicons";
import LoaderMaster from "../Loaders/Loaders";

import { GetRouteSearchParams } from "../../helpers/GeneralHelper";

import {Times_Array} from './Constants';

const StoreSchedules = (props) => {


    const { onClose, USER, STORE_ID, history, location, match, TRANSLATE, LANGUAGE } = props;


    const [isLoading, setLoading] = useState(false);
    const [isChanged, setChanged] = useState(false);
    const [isAvailability, setAvailability] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const [MONDAY, setMonday] = useState([]);
    const [TUESDAY, setTuesday] = useState([]);
    const [WEDNESDAY, setWednesday] = useState([]);
    const [THURSDAY, setThursday] = useState([]);
    const [FRIDAY, setFriday] = useState([]);
    const [SATURDAY, setSaturday] = useState([]);
    const [SUNDAY, setSunday] = useState([]);

    const [MONDAY_DEL, setMonday_del] = useState([]);
    const [TUESDAY_DEL, setTuesday_del] = useState([]);
    const [WEDNESDAY_DEL, setWednesday_del] = useState([]);
    const [THURSDAY_DEL, setThursday_del] = useState([]);
    const [FRIDAY_DEL, setFriday_del] = useState([]);
    const [SATURDAY_DEL, setSaturday_del] = useState([]);
    const [SUNDAY_DEL, setSunday_del] = useState([]);

    const [isMonday, setIsMonday] = useState(false);
    const [isTuesday, setIsTuesday] = useState(false);
    const [isWednesday, setIsWednesday] = useState(false);
    const [isThursday, setIsThursday] = useState(false);
    const [isFriday, setIsFriday] = useState(false);
    const [isSaturday, setIsSaturday] = useState(false);
    const [isSunday, setIsSunday] = useState(false);



    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/' + LANGUAGE + '/login');
        }

        onCheckSchedules()
    }, [USER]);

    useEffect(() => {

        if (isAvailability) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }

    }, [isAvailability])

    const onCheckSchedules = async () => {

        setChanged(false);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const store_id = STORE_ID || '';

        if(!store_id){
            return alert('Error');
        }

        setLoading(true);

        try {

            const FORM = {
                id: store_id
            }

            const DATA_RESPONSE = await postApiPrivate('/admin/stores/forms/check', FORM, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API PLACE ORDER", DATA_RESPONSE.error);
                setLoading(false);

                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const SchedulesData = DATA_RESPONSE.data || {};

            const MondayHome = SchedulesData.monday_h || [];
            const MondayDelivery = SchedulesData.monday_d || [];

            const TuesdayHome = SchedulesData.tuesday_h || [];
            const TuesdayDelivery = SchedulesData.tuesday_d || [];

            const WednesdayHome = SchedulesData.wednesday_h || [];
            const WednesdayDelivery = SchedulesData.wednesday_d || [];

            const ThursdayHome = SchedulesData.thursday_h || [];
            const ThursdayDelivery = SchedulesData.thursday_d || [];

            const FridayHome = SchedulesData.friday_h || [];
            const FridayDelivery = SchedulesData.friday_d || [];

            const SaturdayHome = SchedulesData.saturday_h || [];
            const SaturdayDelivery = SchedulesData.saturday_d || [];

            const SundayHome = SchedulesData.sunday_h || [];
            const SundayDelivery = SchedulesData.sunday_d || [];

            const isMonday = MondayHome.length > 0 || MondayDelivery.length > 0;
            const isTuesday = TuesdayHome.length > 0 || TuesdayDelivery.length > 0;
            const isWednesday = WednesdayHome.length > 0 || WednesdayDelivery.length > 0;
            const isThursday = ThursdayHome.length > 0 || ThursdayDelivery.length > 0;
            const isFriday = FridayHome.length > 0 || FridayDelivery.length > 0;
            const isSaturday = SaturdayHome.length > 0 || SaturdayDelivery.length > 0;
            const isSunday = SundayHome.length > 0 || SundayDelivery.length > 0;

            setIsMonday(isMonday);
            setIsTuesday(isTuesday);
            setIsWednesday(isWednesday);
            setIsThursday(isThursday);
            setIsFriday(isFriday);
            setIsSaturday(isSaturday);
            setIsSunday(isSunday);


            setMonday(MondayHome);
            setTuesday(TuesdayHome);
            setWednesday(WednesdayHome);
            setThursday(ThursdayHome);
            setFriday(FridayHome);
            setSaturday(SaturdayHome);
            setSunday(SundayHome)

            setMonday_del(MondayDelivery);
            setTuesday_del(TuesdayDelivery);
            setWednesday_del(WednesdayDelivery);
            setThursday_del(ThursdayDelivery);
            setFriday_del(FridayDelivery);
            setSaturday_del(SaturdayDelivery);
            setSunday_del(SundayDelivery)


            setLoading(false)

        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }
    }

    const onSubmitSchedules = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const store_id = STORE_ID || '';

        if(!store_id){
            return alert('Error');
        }


        setSubmitting(true);

        try {

            const FORM = {
                id: store_id,
                monday: {
                    home: MONDAY || [],
                    delivery: MONDAY_DEL || []
                },
                tuesday: {
                    home: TUESDAY || [],
                    delivery: TUESDAY_DEL || []
                },
                wednesday: {
                    home: WEDNESDAY || [],
                    delivery: WEDNESDAY_DEL || []
                },
                thursday: {
                    home: THURSDAY || [],
                    delivery: THURSDAY_DEL || []
                },
                friday: {
                    home: FRIDAY || [],
                    delivery: FRIDAY_DEL || []
                },
                saturday: {
                    home: SATURDAY || [],
                    delivery: SATURDAY_DEL || []
                },
                sunday: {
                    home: SUNDAY || [],
                    delivery: SUNDAY_DEL || []
                },


            }

            const DATA_RESPONSE = await postApiPrivate('/admin/stores/forms/create', FORM, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API PLACE ORDER", DATA_RESPONSE.error);
                setSubmitting(false);

                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);


            setSubmitting(false)

            swal('Schedules Updated', 'Your pickup and dropoff schedules have been updated successfully', 'success');

            onCheckSchedules()

        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setSubmitting(false);
        }
    }

    const onSelectHome = (day, time, setFunction) => {


        if (day.includes(time)) {
            const filtred = day.filter((item) => item !== time);
            setFunction(filtred)

        }
        else {
            setFunction([...day, time])
        }

        setChanged(true);

    }

    const onSelectDelivery = (day, time, setFunction) => {

        if (day.includes(time)) {
            const filtred = day.filter((item) => item !== time);
            setFunction(filtred)

        }
        else {
            setFunction([...day, time])
        }

        setChanged(true);

    }


    const CAN_SAVE = isChanged;


    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                 <div className="slideout-content" style={{color: '#000000'}}>

                     <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Store Schedules
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        {isLoading || isSubmitting ? (
                            <LoaderMaster type={'secondary'} />
                        ) : (
                            <>
                               <div className="w-full mt-8 flex flex-col">

                                    <ScheduleMap title={TRANSLATE.wd_list_schedules.a} DAY={MONDAY || []} DAY_DEL={MONDAY_DEL || []} isAction={isMonday} setIsAction={setIsMonday}
                                        onClickHome={(code) => onSelectHome(MONDAY, code, setMonday)}
                                        onClickDelivery={(code) => onSelectDelivery(MONDAY_DEL, code, setMonday_del)}
                                        LANGUAGE={LANGUAGE}
                                        TRANSLATE={TRANSLATE}

                                    />

                                    <ScheduleMap title={TRANSLATE.wd_list_schedules.b} DAY={TUESDAY || []} DAY_DEL={TUESDAY_DEL || []} isAction={isTuesday} setIsAction={setIsTuesday}
                                        onClickHome={(code) => onSelectHome(TUESDAY, code, setTuesday)}
                                        onClickDelivery={(code) => onSelectDelivery(TUESDAY_DEL, code, setTuesday_del)}
                                        LANGUAGE={LANGUAGE}
                                        TRANSLATE={TRANSLATE}

                                    />

                                    <ScheduleMap title={TRANSLATE.wd_list_schedules.c} DAY={WEDNESDAY || []} DAY_DEL={WEDNESDAY_DEL || []} isAction={isWednesday} setIsAction={setIsWednesday}
                                        onClickHome={(code) => onSelectHome(WEDNESDAY, code, setWednesday)}
                                        onClickDelivery={(code) => onSelectDelivery(WEDNESDAY_DEL, code, setWednesday_del)}
                                        LANGUAGE={LANGUAGE}
                                        TRANSLATE={TRANSLATE}

                                    />

                                    <ScheduleMap title={TRANSLATE.wd_list_schedules.d} DAY={THURSDAY || []} DAY_DEL={THURSDAY_DEL || []} isAction={isThursday} setIsAction={setIsThursday}
                                        onClickHome={(code) => onSelectHome(THURSDAY, code, setThursday)}
                                        onClickDelivery={(code) => onSelectDelivery(THURSDAY_DEL, code, setThursday_del)}
                                        LANGUAGE={LANGUAGE}
                                        TRANSLATE={TRANSLATE}

                                    />

                                    <ScheduleMap title={TRANSLATE.wd_list_schedules.e} DAY={FRIDAY || []} DAY_DEL={FRIDAY_DEL || []} isAction={isFriday} setIsAction={setIsFriday}
                                        onClickHome={(code) => onSelectHome(FRIDAY, code, setFriday)}
                                        onClickDelivery={(code) => onSelectDelivery(FRIDAY_DEL, code, setFriday_del)}
                                        LANGUAGE={LANGUAGE}
                                        TRANSLATE={TRANSLATE}

                                    />

                                    <ScheduleMap title={TRANSLATE.wd_list_schedules.f} DAY={SATURDAY || []} DAY_DEL={SATURDAY_DEL || []} isAction={isSaturday} setIsAction={setIsSaturday}
                                        onClickHome={(code) => onSelectHome(SATURDAY, code, setSaturday)}
                                        onClickDelivery={(code) => onSelectDelivery(SATURDAY_DEL, code, setSaturday_del)}
                                        LANGUAGE={LANGUAGE}
                                        TRANSLATE={TRANSLATE}

                                    />

                                    <ScheduleMap title={TRANSLATE.wd_list_schedules.g} DAY={SUNDAY || []} DAY_DEL={SUNDAY_DEL || []} isAction={isSunday} setIsAction={setIsSunday}
                                        onClickHome={(code) => onSelectHome(SUNDAY, code, setSunday)}
                                        onClickDelivery={(code) => onSelectDelivery(SUNDAY_DEL, code, setSunday_del)}
                                        LANGUAGE={LANGUAGE}
                                        TRANSLATE={TRANSLATE}

                                    />

                                    <div style={{ height: 200 }}></div>
                                </div> 
                            </>
                        )}


                    </div>

                    {!isLoading && !isSubmitting &&  (
                        <div className="d-flex align-items-center justify-content-between" style={{backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>
                            <div className="top_add_cart" style={{width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5}} onClick={() => onClose()}>
                                Back
                            </div>

                            {CAN_SAVE ? (
                                <div className="top_add_cart" style={{width: '50%', backgroundColor: '#26BFBF', marginLeft: 5}} onClick={()=> onSubmitSchedules()}>
                                    Save Changes
                                </div>
                            ) : (
                                <div className="top_add_cart cursor-not-allowed" style={{width: '50%', backgroundColor: 'grey', marginLeft: 5}}>
                                    Save Changes
                                </div>
                            )}
                            
                        </div>
                    )}


                </div>

            </div>


        </>
    )
}


const ScheduleMap = (props) => {

    const {
        title,
        isAction,
        setIsAction,
        DAY,
        DAY_DEL,
        onClickHome,
        onClickDelivery,
        LANGUAGE,
        TRANSLATE
    } = props; 

    return (
        <div className="py-4 px-6 border border-zinc-200 rounded-lg relative my-3">
            <img className={"absolute top-4 right-4 z-20 cursor-pointer " + (isAction ? 'rotate-90' : '')} src="/assets/img/chevron-right.svg" onClick={()=> setIsAction(!isAction)}/>
            <div className="text-lg mb-0 font-bold uppercase">{title}</div>

            {isAction && (
                <div className='flex gap-1 w-full flex-wrap text-xs mt-8'>
                    <div className='w-full text-sm font-bold'>
                        {TRANSLATE.wd_list_schedules.home}
                    </div>
                    {Times_Array.map((item, index) => {

                        const isActive = DAY.includes(item.code);
                        const isStatus = isActive ? 'border-bagreen-100 bg-bagreen-50' : 'border-zinc-200';
                        const isCheck = isActive ? '' : 'grayscale group-hover:grayscale-0';

                        return (
                            <div className={'px-3 py-2 cursor-pointer flex items-center border rounded-2xl group hover:border-bagreen-100 '+isStatus} key={index} onClick={()=> onClickHome(item.code)}>
                                <img className={'w-5 h-5 mr-1 '+isCheck} src='/assets/img/check.svg' />
                                {item[LANGUAGE]} 
                            </div>
                        )
                    })}
                    
                    <div className='w-full text-sm font-bold mt-4'>
                        {TRANSLATE.wd_list_schedules.service}
                    </div>

                    {Times_Array.map((item, index) => {

                        const isActive = DAY_DEL.includes(item.code);
                        const isStatus = isActive ? 'border-bagreen-100 bg-bagreen-50' : 'border-zinc-200';
                        const isCheck = isActive ? '' : 'grayscale group-hover:grayscale-0';

                        return (
                            <div className={'px-3 py-2 cursor-pointer flex items-center border rounded-2xl group hover:border-bagreen-100 '+isStatus} key={index} onClick={()=> onClickDelivery(item.code)}>
                                <img className={'w-5 h-5 mr-1 '+isCheck} src='/assets/img/check.svg' />
                                {item[LANGUAGE]} 
                            </div>
                        )
                    })}
                    
                </div>
            )}
        </div>
    )
}
  

export default StoreSchedules