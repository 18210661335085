import React, { useEffect, useState } from 'react';
import { postApiPrivate } from '../../../api';
import { Filter_Array } from '../../Constants/Constants';
import CustomDatePicker from '../DatePicker/CustomDatePicker';
import Dropdown from '../Dropdown/Dropdown';
import Footer from '../Footer';
import Header from '../Header';
import SplashLoadingScreen from '../Listing/SplashScreen';
import ModalFilters from './ModalFilters/ModalFilters';
import ModalProductDetails from './ModalProductDetails/ModalProductDetails';
import ModalSorting from './ModalSorting/ModalSorting';
import ProductCard from './ProductCard/ProductCard';

import { ChildCareOutlined, SwapVert } from '@mui/icons-material';
import { CalendarOutline, GridOutline, Location, MenuOutline } from 'react-ionicons';

import '../new_ux.css';
import './listinggrid.css';

const mockLocations = [
  { title: 'London', value: 'London' },
  { title: 'Paris', value: 'Paris' },
  { title: 'Istanbul', value: 'Istanbul' },
];

const categories = Filter_Array.map((item) => {
  return { title: item['en'], value: item.code, icon: item.icon };
});

const ages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => {
  return { title: item > 0 ? `${item} years old` : 'Under 1 year old', value: item };
});

const FORM_DEFAULT = {
  checkin: '01-08-2024',
  checkout: '31-08-2024',
  curid: 'usd',
  device: 'mobile',
  dropadd: 'Kensington, London, UK',
  dropcity: 'Kensington',
  droplat: '51.5010095',
  droplon: '-0.1932794',
  id: 'wWzYEOWQLn',
  lang: 'en',
  pickadd: 'Kensington, London, UK',
  pickcity: 'Kensington',
  picklat: '51.5010095',
  picklon: '-0.1932794',
  request:
    'https://www.babonbo.com/en/flow/kensington-and-chelsea/GB/results?id=wWzYEOWQLn&checkin=01-08-2024&checkout=31-08-2024&picklat=51.5010095&picklon=-0.1932794&droplat=51.5010095&droplon=-0.1932794&pickadd=Y2l0eT1LZW5zaW5ndG9uJmlucHV0PUtlbnNpbmd0b24lMkMrTG9uZG9uJTJDK1VL&dropadd=Y2l0eT1LZW5zaW5ndG9uJmlucHV0PUtlbnNpbmd0b24lMkMrTG9uZG9uJTJDK1VL',
};

const ListingGrid = (props) => {
  const { history } = props;

  const [isFiltering, setFiltering] = useState(false);

  const [isModalProductDetails, setModalProductDetails] = useState(false);
  const [activeTab, setActiveTab] = useState('Product');
  const [isModalSorting, setModalSorting] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [isGrid, setGrid] = useState(true);

  const [isLoading, setLoading] = useState(true);
  const [CITY, setCity] = useState(null);
  const [RESULTS, setResults] = useState([]);
  const [PRODUCTS, setProducts] = useState([]);
  const [TOTAL, setTotal] = useState(0);

  useEffect(() => {
    onFetchProducts();
  }, []);

  const onFetchProducts = async () => {
    window.scrollTo(0, 0);

    const JWT = typeof global.window !== 'undefined' ? localStorage.getItem('jwt_token') : null;

    const FORM = FORM_DEFAULT;

    setLoading(true);

    console.log('FORM ===> Client ', FORM);

    const DATA_RESPONSE = await postApiPrivate('/flow/results', FORM, JWT);

    if (DATA_RESPONSE.error) {
      console.log('ERROR FROM API LOADING PRODUCTS', DATA_RESPONSE.error);

      // alert('here');
      setLoading(false);

      return;
    }

    const DATA_CITY = DATA_RESPONSE.city;
    const DATA_RESULTS = DATA_RESPONSE.results || [];
    const DATA_PRODUCTS = DATA_RESPONSE.products || [];
    const DATA_TOTAL = DATA_RESPONSE.total || 0;

    //alert('RESULTS ===> '+JSON.stringify(DATA_RESPONSE, null, 2));

    setCity(DATA_CITY);
    setResults(DATA_RESULTS);
    setProducts(DATA_PRODUCTS);
    setTotal(DATA_TOTAL);

    if (DATA_TOTAL === 0) {
      //setMissingProduct(true);
    }

    setLoading(false);

    console.log('FETCHING PRODUCTS NEARBY ===> ', DATA_RESPONSE);

    window.scrollTo(0, 0);
  };

  const toggleIsModalProductDetails = (value = null) => {
    if (value) {
      setSelectedProduct(value);
      setModalProductDetails(false);
    } else {
      setModalProductDetails(false);
    }
  };

  useEffect(() => {
    if (isModalProductDetails || isFiltering) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalProductDetails, isFiltering]);

  const onFilter = () => {};

  const sharedProps = {
    ...props,
  };

  return (
    <div className='layout'>
      {isModalProductDetails && (
        <ModalProductDetails
          PRODUCTS={PRODUCTS} //TODO PRODUCTS of current product providers products
          product={selectedProduct}
          onClose={toggleIsModalProductDetails}
          {...props}
          defaultTab={activeTab}
        />
      )}

      {isModalSorting && <ModalSorting onFilter={onFilter} onClose={() => setModalSorting(false)} {...props} />}

      <Header {...props} animatedBackground={false} background='bg-listing-grid'>
        <MainFilters />
        <MainFiltersMobile />
      </Header>

      <main
        className='flex-grow-1 w-full my-2 mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'
        id='main-content'
        role='main'
        aria-labelledby='products-heading'
      >
        <div>
          <div className='relative flex flex-col items-center justify-center mb:mt-8'>
            <div
              className='flex w-full md:hidden justify-between align-items-center mb-6'
              aria-label='Mobile View Controls'
            >
              <button
                type='button'
                className='flex align-items-center font-semibold gap-1'
                onClick={() => setGrid(false)}
                style={{ color: '#3F51B5' }}
                aria-label='Switch to List View'
              >
                <MenuOutline color={'#3F51B5'} aria-hidden='true' />
                LIST
              </button>
              <button
                type='button'
                className='flex align-items-center font-semibold gap-1'
                onClick={() => setGrid(true)}
                style={{ color: '#3F51B5' }}
                aria-label='Switch to Grid View'
              >
                <GridOutline color={'#3F51B5'} aria-hidden='true' />
                GRID
              </button>
              <button
                type='button'
                className='flex align-items-center font-semibold gap-1'
                onClick={() => setModalSorting(true)}
                style={{ color: '#3F51B5' }}
                aria-label='Open Sorting Modal'
              >
                <SwapVert color='#3F51B5' aria-hidden='true' />
                SORT
              </button>
            </div>
            <h2 className='mb-4 text-base md:text-2xl font-normal' id='listing-heading' aria-label='Strollers Listing'>
              STROLLERS
            </h2>
            <button
              type='button'
              className='hidden md:block absolute top-0 left-auto right-0 button-stroke color-ba rounded-full'
              onClick={() => setModalSorting(true)}
              aria-label='Sort By'
            >
              <SwapVert />
              Sort by
            </button>
          </div>

          {isLoading ? (
            <SplashLoadingScreen {...sharedProps} PickupObx={{}} />
          ) : (
            <div
              className={`flex flex-wrap gap-2 mb-8 justify-center md:justify-between ${
                isGrid ? 'grid list-grid' : ''
              }`}
              aria-label='Product Listings'
            >
              {PRODUCTS.map((product) => (
                <ProductCard
                  isGrid={isGrid}
                  key={`${product.name}-${product.id}`}
                  product={{
                    id: product.id,
                    cost_delivery: 0,
                    cost_day: 10,
                    currency: 'eur',
                    images: [product.first_img, product.second_img, product.third_img, product.fourth_img],
                    name: product.name,
                    total_rent: 10,
                    description: product.about,
                    store: {
                      img: product?.owner?.img,
                      name: product?.owner?.name,
                      rate: product?.owner?.rating,
                      reviews: product?.owner?.reviews,
                      description: product?.owner?.about,
                    },
                  }}
                  setSelectedProduct={(value) => {
                    setSelectedProduct(value);
                    setModalProductDetails(true);
                  }}
                  selectProvider={(value) => {
                    setSelectedProduct(value);
                    setActiveTab('Provider');
                    setModalProductDetails(true);
                  }}
                  onClick={() => history.push('/newux/checkout')}
                  aria-label={`Product Card for ${product.name}`}
                />
              ))}
            </div>
          )}
        </div>
      </main>

      <Footer {...props} />
    </div>
  );
};

export default ListingGrid;

const MainFilters = (props) => {
  const [location, setLocation] = useState('London');
  const [district, setDistrict] = useState('Paris');

  const [date, setDate] = useState({
    from: null,
    to: null,
  });
  const [category, setCategory] = useState('');
  const [selectedAges, setSelectedAges] = useState([]);
  const [searchText, setSearchText] = useState('');

  return (
    <div
      className={`mx-auto max-w-8xl hidden md:flex px-4 sm:px-6 lg:px-8 `}
      id='filters-section'
      role='region'
      aria-labelledby='filters-heading'
    >
      <div
        className={`flex flex-col md:flex-row mb-4 md:mb-8 px-2 sm:px-6 lg:px-8 justify-center md:justify-between w-full bg-white`}
        aria-label='Main Filters'
      >
        <div
          className='w-full'
          style={{ maxWidth: 'calc(20% - 36px)', flexGrow: 0, flexShrink: 0 }}
          aria-label='Location Filter'
        >
          <Dropdown
            label={'Location'}
            value={location}
            options={mockLocations}
            onChange={(value) => setLocation(value)}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </div>

        <div
          className='w-full'
          style={{ maxWidth: 'calc(20% - 36px)', flexGrow: 0, flexShrink: 0 }}
          aria-label='District Filter'
        >
          <Dropdown
            label={'District'}
            value={district}
            options={mockLocations}
            onChange={(value) => setDistrict(value)}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </div>

        <div
          className='w-full'
          style={{ maxWidth: 'calc(20% - 36px)', flexGrow: 0, flexShrink: 0 }}
          aria-label='Date Filter'
        >
          <CustomDatePicker label={'Date'} value={date} onChange={() => null} aria-label='Select Date' />
        </div>

        <div
          className='w-full'
          style={{ maxWidth: 'calc(20% - 36px)', flexGrow: 0, flexShrink: 0 }}
          aria-label='Product Filter'
        >
          <Dropdown
            label={'Products'}
            value={category}
            options={categories}
            placeholder={'Select product...'}
            onChange={(value) => setCategory(value)}
            searchDisabled={true}
          />
        </div>

        <div
          className='w-full'
          style={{ maxWidth: 'calc(20% - 36px)', flexGrow: 0, flexShrink: 0 }}
          aria-label='Ages Filter'
        >
          <Dropdown
            multiSelect={true}
            label={'Ages'}
            value={selectedAges}
            options={ages}
            placeholder={'Select age...'}
            onChange={(value) => {
              if (selectedAges.includes(value)) {
                let currentAges = selectedAges.filter((age) => age !== value);
                let sortedAges = currentAges.sort((a, b) => a - b);
                setSelectedAges(sortedAges);
              } else {
                let currentAges = [...selectedAges, value];
                let sortedAges = currentAges.sort((a, b) => a - b);
                setSelectedAges(sortedAges);
              }
            }}
            aria-label='Select Age'
          />
        </div>

        <button type='button' onClick={() => {}} className='button-apply-filters' aria-label='Apply Filters'>
          LIST ALL
        </button>
      </div>
    </div>
  );
};

const MainFiltersMobile = (props) => {
  const [category, setCategory] = useState('');

  const [modalFilters, setModalFilters] = useState(false);

  return (
    <div className='w-full md:hidden'>
      {modalFilters && <ModalFilters onClose={() => setModalFilters(false)} />}
      <div className='md:hidden px-4 sm:px-6 lg:px-8' onClick={() => setModalFilters(true)} aria-label='Filter Modal'>
        <div
          className={`flex justify-between w-full rounded-lg bg-white`}
          style={{ border: '1px solid #d8d8d8', boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.09) inset' }}
          aria-labelledby='filter-heading'
        >
          <div className='flex flex-col p-1 gap-1 justify-around w-full' aria-label='Location and District Filters'>
            <div className='flex align-items-center gap-1 text-sm' aria-label='Location Filter'>
              <Location color={'#8c8c8c'} aria-hidden='true' />
              <p>London</p>
            </div>

            <div className='flex align-items-center gap-1 text-sm' aria-label='District Filter'>
              <Location color={'#8c8c8c'} aria-hidden='true' />
              <p>Kensington and Chelsea</p>
            </div>
          </div>

          <div className='w-[180px]' aria-label='Date and Age Filters'>
            <div className='flex align-items-center gap-1 text-sm' aria-label='Date Filter'>
              <CalendarOutline color={'#8c8c8c'} aria-hidden='true' />
              <p>
                <span className='font-semibold text-black'>Start Date:</span> <br /> 10 January
              </p>
            </div>
            <div className='flex align-items-center gap-1 text-sm' aria-label='Age Filter'>
              <ChildCareOutlined color={'#8c8c8c'} aria-hidden='true' />
              <p>1 year old</p>
            </div>
          </div>
        </div>
        <div className='flex my-4 overflow-scroll' aria-label='Category Filters'>
          {categories.map((item) => (
            <div
              className={`category-item flex flex-col items-center justify-center p-2 h-[50px] text-xs rounded-lg flex-shrink-0 ${
                category === item ? 'bg-gray' : 'bg-white'
              }`}
              onClick={() => setCategory(item)}
              aria-label={`Select Category ${item.title}`}
            >
              {item.icon && <img src={item.icon} alt='' className='dropdown-option-img m-auto' aria-hidden='true' />}
              {item.title.split(' ')[0]}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
