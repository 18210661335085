import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import SunCalc from 'suncalc'

const CountdownToMidnight = (props) => {

    const { timezone, isTime, isDate, isRange } = props;
    const [timeRemaining, setTimeRemaining] = useState("");

    useEffect(() => {
        // Function to calculate time remaining until midnight
        const secondsToMidnightTimezone = (timezone) => {
            const now = moment().tz(timezone);
            const midnight = moment().tz(timezone).endOf('day');
            const diffSeconds = midnight.diff(now, 'seconds');
            const hours = Math.floor(diffSeconds / 3600);
            const minutes = Math.floor((diffSeconds % 3600) / 60);
            const seconds = diffSeconds % 60;
            const formattedHours = padZero(hours % 12 || 12);
            const period = hours < 12 ? 'AM' : 'PM';
            return `${formattedHours}:${padZero(minutes)}:${padZero(seconds)} ${period}`;
        };

        const secondsToMidnightUTC = () => {
            const now = moment.utc();
            const midnight = moment.utc().endOf('day');
            const diffSeconds = midnight.diff(now, 'seconds');
            const hours = Math.floor(diffSeconds / 3600);
            const minutes = Math.floor((diffSeconds % 3600) / 60);
            const seconds = diffSeconds % 60;
            return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
        };

        // Function to pad single digit numbers with leading zeros
        const padZero = (num) => {
            return num.toString().padStart(2, '0');
        };

        // Update time remaining every second
        const interval = setInterval(() => {

            if (isDate || isRange) {

            }
            else {
                if (timezone) {
                    setTimeRemaining(secondsToMidnightTimezone(timezone));
                }
                else {
                    setTimeRemaining(secondsToMidnightUTC());
                }
            }

        }, 1000);

        // Clean up interval on unmount
        return () => clearInterval(interval);
    }, [timezone]);

    if (isDate) {
        const newMoment = moment.utc(isDate);
        const moonPhase = SunCalc.getMoonIllumination(newMoment).phase;
        const { title, icon } = getMoonPhaseName(moonPhase);
        return (
            <img src={`/assets/moon/${icon}`} className='w-[25px] h-[25px] mr-2' alt={title} />
        )
    }

    if (isTime) {

        if (timezone) {
            return (
                <>
                    <div className='text-xl font-semibold'>
                        {moment().tz(timezone).format('hh:mm:ss')} TZ
                    </div>
                    <div className='text-red-500 text-xs font-semibold'>
                        <span className='font-normal text-black'>Midnight</span>-{timeRemaining}
                    </div>
                </>
            )
        }
        return (
            <>
                <div className='text-xl font-semibold'>
                    {moment.utc().format('hh:mm:ss')} UTC
                </div>
                <div className='text-red-500 text-xs font-semibold'>
                    <span className='font-normal text-black'>Midnight</span>-{timeRemaining}
                </div>
            </>
        )
    }

    if (timezone) {
        return (
            <>
                <div className='text-xl font-semibold'>{moment().tz(timezone).format('dddd DD MMMM YYYY')}</div>
                <div className='flex text-base items-center '>
                    <ReactCountryFlag countryCode='gb' svg className='mr-2' />
                    {timezone}
                </div>
            </>
        )
    }

    return (
        <>
            <div className='text-xl font-semibold'>{moment.utc().format('dddd DD MMMM YYYY')}</div>
            <div className='flex text-base items-center '>
                <ReactCountryFlag countryCode='gb' svg className='mr-2' />
                London, United Kingdom
            </div>
        </>
    );
};

const getMoonPhaseName = (phase) => {
    if (phase < 0.0625) return { title: 'New Moon', icon: 'new-moon.png' };
    if (phase < 0.1875) return { title: 'Waxing Crescent', icon: 'waxing-crescent.png' };
    if (phase < 0.3125) return { title: 'First Quarter', icon: 'first-quarter.png' };
    if (phase < 0.4375) return { title: 'Waxing Gibbous', icon: 'waxing-gibbous.png' };
    if (phase < 0.5625) return { title: 'Full Moon', icon: 'full-moon.png' };
    if (phase < 0.6875) return { title: 'Waning Gibbous', icon: 'waning-gibbous.png' };
    if (phase < 0.8125) return { title: 'Last Quarter', icon: 'last-quarter.png' };
    if (phase < 0.9375) return { title: 'Waning Crescent', icon: 'waning-crescent.png' };
    return { title: 'New Moon', icon: 'new-moon.png' }; // New Moon (repeating the New Moon phase for simplicity)
};


export default CountdownToMidnight;