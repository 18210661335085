import React, { useEffect, useState } from "react";

import { postApiPrivate } from "../../api";

import moment from "moment";
import swal from "sweetalert";
import { CloseOutline } from "react-ionicons";
import LoaderMaster from "../Loaders/Loaders";
import { GetRouteSearchParams, numberWithCommas } from "../../helpers/GeneralHelper";

const StoreAnalytics = (props) => {


    const { onClose, USER, STORE_ID, LANGUAGE, TRANSLATE, history, DASHBOARD, CURRENCY, PMC, location } = props;


    const [isLoading, setLoading] = useState(true);
    const [ANALYTICS, setAnalytics] = useState({});
    const [FILTER, setFilter] = useState(null);
    const [RANGE, setRange] = useState('all');



    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);
        const range = routeParams.range || 'all';

        setRange(range);

        if (range === 'all') {
            onFetchAnalytics('all');
        }
        else {
            onFetchAnalytics(range);
        }

    }, [location.search])


    const onFetchAnalytics = async (range_id) => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const store_id = STORE_ID;

        if (!store_id) {
            return alert('Error');
        }

        const TimesObx = onFetchTimes(range_id);

        const FORM = {
            id: store_id,
            type: range_id,
            start: TimesObx.start,
            end: TimesObx.end
        }

        setLoading(true);

        const DATA_RESPONSE = await postApiPrivate('/admin/stores/analytics', FORM, JWT);

        if (DATA_RESPONSE.error) {
            console.log("ERROR FROM API LOADING PRODUCTS", DATA_RESPONSE.error);

            setLoading(false);

            return
        }


        setAnalytics(DATA_RESPONSE)

        setLoading(false);
    }

    const onFetchTimes = (type) => {

        var REFERENCE = moment(); // fixed just for testing, use moment();
        var TODAY = REFERENCE.clone().startOf('day');
        var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
        var A_7DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_14DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_30DAYS_OLD = REFERENCE.clone().subtract(30, 'days').startOf('day');
        var A_60DAYS_OLD = REFERENCE.clone().subtract(60, 'days').startOf('day');
        var A_90DAYS_OLD = REFERENCE.clone().subtract(90, 'days').startOf('day');
        var A_180DAYS_OLD = REFERENCE.clone().subtract(180, 'days').startOf('day');
        var A_365DAYS_OLD = REFERENCE.clone().subtract(365, 'days').startOf('day');


        if (type === 'all') {
            return {
                start: '01-01-2021',
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }
        else if (type === 'today') {
            return {
                start: TODAY.format('DD-MM-YYYY'),
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }
        else if (type === 'yesterday') {
            return {
                start: YESTERDAY.format('DD-MM-YYYY'),
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }
        else if (type === 'last_7') {
            return {
                start: A_7DAYS_OLD.format('DD-MM-YYYY'),
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }
        else if (type === 'last_14') {
            return {
                start: A_14DAYS_OLD.format('DD-MM-YYYY'),
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }
        else if (type === 'last_30') {
            return {
                start: A_30DAYS_OLD.format('DD-MM-YYYY'),
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }
        else if (type === 'last_60') {
            return {
                start: A_60DAYS_OLD.format('DD-MM-YYYY'),
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }
        else if (type === 'last_90') {
            return {
                start: A_90DAYS_OLD.format('DD-MM-YYYY'),
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }
        else if (type === 'last_180') {
            return {
                start: A_180DAYS_OLD.format('DD-MM-YYYY'),
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }
        else if (type === 'last_365') {
            return {
                start: A_365DAYS_OLD.format('DD-MM-YYYY'),
                end: REFERENCE.format('DD-MM-YYYY')
            }
        }

    }

    const GreenStats = ANALYTICS.green_stats || {};

    const TotalOrders = ANALYTICS.total || 0;
    const TotalPending = ANALYTICS.pending || 0;
    const TotalAccepted = ANALYTICS.accepted || 0;
    const TotalDelivering = ANALYTICS.delivering || 0;
    const TotalDelivered = ANALYTICS.delivered || 0;
    const TotalComplete = ANALYTICS.complete || 0;
    const TotalRejected = ANALYTICS.rejected || 0;
    const TotalCanceled = ANALYTICS.canceled || 0;
    const TotalRefund = ANALYTICS.refund || 0;

    const TotalGreen = ANALYTICS.green || 0;
    const TotalRed = ANALYTICS.red || 0;
    const TotalYellow = ANALYTICS.yellow || 0;

    const RateGreen = (TotalGreen / TotalOrders * 100) || 0;
    const RateRed = (TotalRed / TotalOrders * 100) || 0;
    const RateYellow = (TotalYellow / TotalOrders * 100) || 0;

    const BabonboScore = ANALYTICS.score || 0;

    const TotalShown = GreenStats.amount_provider || 0;

    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Store Analytics
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        {isLoading ? (
                            <LoaderMaster type={'secondary'} />
                        ) : (
                            <>
                                <div className="d-flex flex-column align-items-center justify-content-center pb-32">

                                    <div className="flex gap-x-8 flex-wrap mt-8 mb-4 w-full">
                                        <div className="w-full">

                                            {isLoading ? (
                                                <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                                    <div className='w-full flex items-center justify-center'>
                                                        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="bg-bagreen-100 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                                    <h1 className="my-auto font-semibold pl-6">
                                                        {numberWithCommas(TotalShown, CURRENCY)}
                                                    </h1>
                                                    <div className="flex flex-col items-end justify-between text-xs">
                                                        <select value={RANGE} onChange={(e) => history.push('?range=' + e.target.value)} className="bg-transparent text-xs accent-white bg-blend-color-dodge p-0 pr-8 border-none text-white focus:outline-none focus:ring-0">
                                                            <option value={'all'}>{TRANSLATE.wd_all_time}</option>
                                                            <option value={'today'}>{TRANSLATE.wd_today}</option>
                                                            <option value={'yesterday'}>{TRANSLATE.wd_yesterday}</option>
                                                            <option value={'last_7'}>{TRANSLATE.wd_last_days.replace('{{TOTAL}}', 7)}</option>
                                                            <option value={'last_14'}>{TRANSLATE.wd_last_days.replace('{{TOTAL}}', 14)}</option>
                                                            <option value={'last_30'}>{TRANSLATE.wd_last_days.replace('{{TOTAL}}', 30)}</option>
                                                            <option value={'last_60'}>{TRANSLATE.wd_last_days.replace('{{TOTAL}}', 60)}</option>
                                                            <option value={'last_90'}>{TRANSLATE.wd_last_days.replace('{{TOTAL}}', 90)}</option>
                                                            <option value={'last_180'}>{TRANSLATE.wd_last_days.replace('{{TOTAL}}', 180)}</option>
                                                            <option value={'last_365'}>{TRANSLATE.wd_last_days.replace('{{TOTAL}}', 365)}</option>
                                                        </select>
                                                        <div className="flex items-center">
                                                            {BabonboScore.toFixed(2)} <img className="ml-2" src="/assets/img/chart.svg" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                    </div>

                                    {!isLoading && (
                                        <>
                                            {/****** Analytics ******/}
                                            <div className="flex gap-x-8 flex-wrap mt-8 mb-4 w-full">
                                                <div className="w-full">
                                                    <div className="font-semibold capitalize">
                                                        {TRANSLATE.wd_analytics}
                                                    </div>
                                                    <div className="min-h-[150px] mt-2 mb-4 grid  grid-cols-2 gap-3">
                                                        <div className={"w-full rounded-lg flex flex-col items-center justify-center cursor-pointer min-h-[150px] col-span-2  " + (FILTER === 'received' ? 'border-2 border-bagreen-100' : 'border border-zinc-200 hover:border-zinc-400')} >
                                                            <h2 className='mb-1'>
                                                                {TotalOrders}
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_received}
                                                            </span>
                                                        </div>
                                                        <div className={"w-full rounded-lg flex flex-col items-center justify-center cursor-pointer min-h-[150px] " + (FILTER === 'yellow' ? 'border-2 border-bagreen-100' : 'border border-zinc-200 hover:border-zinc-400')} >
                                                            <h2 className='mb-1 text-yellow-500'>
                                                                {RateYellow.toFixed(2)}%
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_pending_rate}
                                                            </span>
                                                        </div>
                                                        <div className={"w-full rounded-lg flex flex-col items-center justify-center cursor-pointer min-h-[150px] " + (FILTER === 'green' ? 'border-2 border-bagreen-100' : 'border border-zinc-200 hover:border-zinc-400')} >
                                                            <h2 className='mb-1 text-green-600'>
                                                                {RateGreen.toFixed(2)}%
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_acceptance_rate}
                                                            </span>
                                                        </div>
                                                        <div className={"w-full rounded-lg flex flex-col items-center justify-center cursor-pointer min-h-[150px] " + (FILTER === 'red' ? 'border-2 border-bagreen-100' : 'border border-zinc-200 hover:border-zinc-400')} >
                                                            <h2 className='mb-1 text-red-500'>
                                                                {RateRed.toFixed(2)}%
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_rejection_rate}
                                                            </span>
                                                        </div>

                                                        <div className="w-full border border-zinc-200 rounded-lg  min-h-[150px] flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                                            <h2 className='mb-1'>
                                                                {BabonboScore.toFixed(2)}
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_score}
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                           

                                            {/****** Orders ******/}
                                            <div className="flex gap-x-8 flex-wrap mt-8 mb-6 w-full">
                                                <div className="w-full">
                                                    <div className="font-semibold capitalize">
                                                        {TRANSLATE.wd_orders}
                                                    </div>
                                                    <div className="min-h-[150px] mt-2 mb-4 grid  grid-cols-3 gap-3">

                                                        <div className="col-span-2 min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=pending&store='+STORE_ID)}>
                                                            <h2 className='mb-1'>
                                                                {TotalPending}
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_pending}
                                                            </span>
                                                        </div>
                                                        <div className="col-span-1 min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=accepted&store='+STORE_ID)}>
                                                            <h2 className='mb-1'>
                                                                {TotalAccepted}
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_accepted}
                                                            </span>
                                                        </div>
                                                        <div className="min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=delivering&store='+STORE_ID)}>
                                                            <h2 className='mb-1'>
                                                                {TotalDelivering}
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_delivering}
                                                            </span>
                                                        </div>
                                                        <div className="min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=delivered&store='+STORE_ID)}>
                                                            <h2 className='mb-1'>
                                                                {TotalDelivered}
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_delivered}
                                                            </span>
                                                        </div>
                                                        <div className="min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=complete&store='+STORE_ID)}>
                                                            <h2 className='mb-1'>
                                                                {TotalComplete}
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_completed}
                                                            </span>
                                                        </div>
                                                        <div className="min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=rejected&store='+STORE_ID)}>
                                                            <h2 className='mb-1'>
                                                                {TotalRejected}
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_rejected}
                                                            </span>
                                                        </div>
                                                        <div className="min-h-[150px] col-span-2 w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=canceled&store='+STORE_ID)}>
                                                            <h2 className='mb-1'>
                                                                {TotalCanceled}
                                                            </h2>
                                                            <span className="text-xs capitalize">
                                                                {TRANSLATE.wd_canceled}
                                                            </span>
                                                        </div>
                                                        {/* <div className="w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={()=> history.push('/'+LANGUAGE+'/dashboard/orders?filter=refunded')}>
                                    <h2 className='mb-1'>
                                        {TotalRefund}
                                    </h2>
                                    <span className="text-xs">
                                        Refunded
                                    </span>
                                </div> */}

                                                    </div>
                                                </div>
                                            </div>


                                             {/****** Performance ******/}

                                             <StorePerformance  {...props} ANALYTICS={ANALYTICS} FILTER={'received'} />

                                             <StorePerformance  {...props} ANALYTICS={ANALYTICS} FILTER={'green'} />

                                             <StorePerformance  {...props} ANALYTICS={ANALYTICS} FILTER={'red'} />

                                             <StorePerformance  {...props} ANALYTICS={ANALYTICS} FILTER={'yellow'} />
                                        </>
                                    )}


                                    <div style={{ height: 200 }}></div>
                                </div>
                            </>
                        )}


                    </div>

                    {/* {!isLoading && (
                        <div className="d-flex align-items-center justify-content-between" style={{backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>
                            <div className="top_add_cart" style={{width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5}} onClick={() => onClose()}>
                                Back
                            </div>
                            <div className="top_add_cart" style={{width: '50%', backgroundColor: '#26BFBF', marginLeft: 5}}>
                                Save Changes
                            </div>
                        </div>
                    )} */}


                </div>

            </div>


        </>
    )
}

const StorePerformance = (props) => {

    const {FILTER, ANALYTICS, TRANSLATE, CURRENCY} = props;

    const TotalStats = ANALYTICS.total_stats || {};
    const GreenStats = ANALYTICS.green_stats || {};
    const RedStats = ANALYTICS.red_stats || {};
    const YellowStats = ANALYTICS.yellow_stats || {};

    //Calculate Performance
    let MainStats = TotalStats;
    let MainColor = 'blue';
    
    if (FILTER === 'yellow') {
        MainStats = YellowStats;
        MainColor = 'orange';
    }
    else if (FILTER === 'green') {
        MainStats = GreenStats;
        MainColor = 'green'
    }
    else if (FILTER === 'red') {
        MainStats = RedStats;
        MainColor = 'red'
    }

    const TotalProvider = MainStats.amount_provider || 0;

    const TotalCustomers = MainStats.total_customers || 0;
    const TotalProducts = MainStats.total_products || 0;
    const TotalDays = MainStats.total_rental_days || 0;

    const AverageOrderValue = MainStats.average_order_value || 0;

    return (
        <div className="flex gap-x-8 flex-wrap mt-8 mb-4 w-full">
            <div className="w-full">
                <div className="font-semibold capitalize">
                    {TRANSLATE.wd_performance} <span style={{color: MainColor, opacity: 0.8}}>({FILTER})</span>
                </div>
                <div className=" mt-2 mb-4  grid  grid-cols-2 gap-3">

                    <div className="min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                        <h2 className='mb-1'>
                            {TotalCustomers}
                        </h2>
                        <span className="text-xs capitalize">
                            {TRANSLATE.wd_total_customers}
                        </span>
                    </div>
                    <div className="min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                        <h2 className='mb-1'>
                            {TotalProducts}
                        </h2>
                        <span className="text-xs capitalize">
                            {TRANSLATE.wd_total_products}
                        </span>
                    </div>
                    <div className="min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                        <h2 className='mb-1'>
                            {TotalDays}
                        </h2>
                        <span className="text-xs capitalize">
                            {TRANSLATE.wd_total_days}
                        </span>
                    </div>
                    <div className="min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                        <h2 className='mb-1'>
                            {numberWithCommas(AverageOrderValue, CURRENCY)}
                        </h2>
                        <span className="text-xs text-center capitalize">
                            {TRANSLATE.wd_aov}
                        </span>
                    </div>
                    <div className="min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                        <h2 className='mb-1'>
                            {numberWithCommas(TotalProvider, CURRENCY)}
                        </h2>
                        {FILTER === 'received' && (
                            <span className="text-xs capitalize">
                                {TRANSLATE.wd_total_earnings}
                            </span>
                        )}

                        {FILTER === 'yellow' && (
                            <span className="text-xs capitalize">
                                {TRANSLATE.wd_total_to_earn}
                            </span>
                        )}

                        {FILTER === 'green' && (
                            <span className="text-xs capitalize">
                                {TRANSLATE.wd_total_earned}
                            </span>
                        )}

                        {FILTER === 'red' && (
                            <span className="text-xs capitalize">
                                {TRANSLATE.wd_total_lost}
                            </span>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default StoreAnalytics