import React from 'react'


const Tooltip = (props) => {
    const { desc } = props;
    return (
        <>
            <div className='group inline-flex items-center justify-center bg-black text-white w-[20px] h-[20px] rounded-full text-xs font-normal cursor-pointer relative ml-1 flex-shrink-0 hover:z-[9999]'>
                ?
                <div className='absolute w-[1px] h-[1px] -top-[2px] right-[50%] flex justify-center items-end bb-transition-100 invisible opacity-0 group-hover:visible group-hover:opacity-100'>
                    <div className='bg-black w-[200px] h-auto text-white text-xs rounded-lg p-2 flex-shrink-0 flex text-center items-center justify-center'>
                        {props.desc}
                    </div>
                </div>
            </div>
        </>
    )

}

export default Tooltip