import React, { useState, useEffect } from "react";

import LoaderMaster from "../Loaders/Loaders";
import StarRating from "../General/Stars";

import { postApiPrivate } from "../../api";

import { CloseSharp, EyeOutline, EyeSharp, LockOpenSharp, MailSharp, NotificationsSharp, ReaderOutline, SaveSharp, ShirtOutline, Star, StarOutline, TrashBinSharp } from "react-ionicons";

import ReactCountryFlag from "react-country-flag";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, TextareaAutosize } from "@mui/material";

import ReviewComponent from "../Reviews/ReviewComponent";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { WidgetCount } from './AdminWidgets';

import { onFormatUrl } from "../../helpers/GeneralHelper";

import "../Profile/z_profile.css";

const AdminStoresDetails = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [PUBLIC_STORE, setStore] = useState({});


  useEffect(() => {

    var store_id = match.params.id || '';

    onFetchPublic(store_id);

  }, []);

  const onFetchPublic = async (store_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setEdited(false);
    setLoading(true);

    try {

      const FORM = { id: store_id, partner: match.params.pid };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/stores/details', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setStore(DATA_RESPONSE || {});
      setLoading(false);

    }
    catch (e) {

      setLoading(false);
    }

  }

  const onUpdateStore = async (key, value) => {

    setEdited(true);
    setStore({
      ...PUBLIC_STORE,
      [key]: value
    });

  }

  const onSaveStore = async (store_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = {
        id: store_id,
        about: PUBLIC_STORE.about,
        status: PUBLIC_STORE.status ? 'published' : 'unpublished',
        partner: match.params.pid

      };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/stores/edit', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update This Store', 'error');
        onFetchPublic(store_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Store Updated', 'This Store has been updated successfully !!!', 'success');
      onFetchPublic(store_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This Store', 'error');
      onFetchPublic(store_id);
    }

  }



  const onSelectCopy = (copy) => {
    swal('Copied to Clipboard !!!', copy, 'success')
  }

  if (isLoading) {

    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
      </div>
    );

  }

  if (isSaving) {

    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
      </div>
    );

  }


  const {
    id,
    name,
    img,
    updatedAt,
    createdAt,
    city,
    rating,
    email,
    phone,
    about,
    currency,
    status,
    address,

    communication,
    logistic,
    products,

    products_count,

    reviews,

    country,

    followers,
    following,

    owner,

    delivery,
    geocode


  } = PUBLIC_STORE;


  const ownerObx = owner || {};
  const ownerName = ownerObx.name || '';
  const ownerImg = ownerObx.img || '';
  const ownerId = ownerObx.id || '';

  const score = PUBLIC_STORE.score ? parseFloat(PUBLIC_STORE.score.toFixed(2)) : 0;
  const total_orders = PUBLIC_STORE.total || 0;
  const total_green = PUBLIC_STORE.green || 0;
  const total_red = PUBLIC_STORE.red || 0;
  const total_yellow = PUBLIC_STORE.yellow || 0;


  return (

    <>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={4} md={6}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>
            <div className="top_store_card">
              <div className="top_store_profile">

                {img ? (
                  <img className="profile_img" src={img} />
                ) : (
                  <div className="profile_img" src={'/assets/img/avatar.svg'} />
                )}

                <div className="font-bold text-lg">{name}</div>
                <StarRating rate={rating} reviews={reviews} />

              </div>

              <div className="top_store_stats">
                <div>
                  <div className="font-bold text-lg">{total_orders || 0}</div>
                  <p>Orders</p>
                </div>
                <div>
                  <div className="font-bold text-lg">{products_count || 0}</div>
                  <p>Products</p>
                </div>
                <div>
                  <div className="font-bold text-lg">{score || 0}</div>
                  <p>Score</p>
                </div>
              </div>
            </div>

            {!isEdited && (
              <div className="row justify-content-center">
                <a className="top_add_cart simple col-sm-5 mr-sm-1" target="_blank" href={'https://www.babonbo.com/en/' + onFormatUrl(name) + '/bs/' + id}>
                  <EyeOutline color={'inherit'} style={{ marginRight: 10 }} />  Store
                </a>
                <div className="top_add_cart simple col-sm-5" onClick={() => history.push('/partners/' + match.params.pid + '/products/0?sort=updatedAt&dir=desc&status=ON&store=' + id)}>
                  <ShirtOutline color={'inherit'} style={{ marginRight: 10 }} />  Products
                </div>
                <div className="top_add_cart simple col-sm-5 mr-sm-1" onClick={() => history.push('/partners/' + match.params.pid + '/orders/0?sort=updatedAt&dir=desc&store=' + id)}>
                  <ReaderOutline color={'inherit'} style={{ marginRight: 10 }} />  Orders
                </div>
                <div className="top_add_cart simple col-sm-5" onClick={() => history.push('/partners/' + match.params.pid + '/reviews/0?sort=updatedAt&dir=desc&type=provider&store=' + id)}>
                  <StarOutline color={'inherit'} style={{ marginRight: 10 }} />  Reviews
                </div>
              </div>
            )}

            {isEdited && (
              <>

                <div className="top_add_cart"
                  style={{ backgroundColor: '#59ad6a' }}
                  onClick={() => {
                    swal({
                      title: 'Confirm to Save',
                      text: 'Please type "CONFIRM" to update this store',
                      content: "input",
                    })
                      .then((value) => {

                        if (value === 'CONFIRM') {

                          onSaveStore(id)
                        }
                        else {

                          swal('Invalid Password', 'You are not authorized to update this store', 'error');
                        }

                      });
                  }}

                >
                  <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Update Store
                </div>

                <div className="top_add_cart" style={{ backgroundColor: '#ccc' }} onClick={() => onFetchPublic(id)}>
                  <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Cancel Changes
                </div>

              </>
            )}


          </div>
        </Grid>
        <Grid item xs={12} lg={5} md={6}>

          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

            <div className="top_box_magic" style={{ marginBottom: 20 }}>
              <h1>
                Status
              </h1>

              {status ? (
                <Chip label="Published" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="success" onClick={(e) => onUpdateStore('status', false)} />
              ) : (
                <Chip label="Unpublished" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="warning" onClick={(e) => onUpdateStore('status', true)} />
              )}
            </div>

            <div className="top_box_magic">
              <h1>
                {TRANSLATE.email}
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {email}
                </p>
              </div>
            </div>

            {phone && (
              <div className="top_box_magic">
                <h1>
                  Phone
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <p>
                    +{phone.replace('+', '')}
                  </p>
                </div>
              </div>
            )}

            <div className="top_box_magic">
              <h1>
                Address
              </h1>
              <div style={{ margin: '10px 0px' }}>

                {!address ? (
                  <Chip size='medium' label="NO" />
                ) : (
                  <CopyToClipboard text={address}
                    onCopy={() => onSelectCopy(address)}
                  >
                    <Chip
                      avatar={<ReactCountryFlag countryCode={country} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                      label={address.toLowerCase()}
                      variant="outlined"
                      size='medium'
                      style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                    />

                  </CopyToClipboard>
                )}

              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                {TRANSLATE.about_us}
              </h1>
              <div style={{ margin: '10px 0px' }}>

                <TextareaAutosize
                  minRows={4}
                  maxRows={10}
                  placeholder="Please insert the store's description"
                  defaultValue={about}
                  style={{ width: '100%', border: 'solid 1px #00000019', borderRadius: 10, padding: 10 }}
                  onChange={(e) => onUpdateStore('about', e.target.value)}
                />

              </div>
            </div>


            <div className="top_box_magic">
              <h1>
                Currency
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {currency === 'usd' && (
                    <>
                      <ReactCountryFlag svg countryCode={'us'} style={{ marginRight: 5 }} /> $ - USD
                    </>
                  )}
                  {currency === 'eur' && (
                    <>
                      <ReactCountryFlag svg countryCode={'eu'} style={{ marginRight: 5 }} /> € - EUR
                    </>
                  )}
                  {currency === 'gbp' && (
                    <>
                      <ReactCountryFlag svg countryCode={'gb'} style={{ marginRight: 5 }} /> £ - GBP
                    </>
                  )}
                </p>
              </div>
            </div>



          </div>


        </Grid>
        <Grid item xs={12} lg={3} md={3}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>


            <div className="top_box_magic">
              <h1>
                Store Created
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                </p>
              </div>
            </div>
            <div className="top_box_magic">
              <h1>
                Store Updated
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                </p>
              </div>
            </div>

            {ownerId && (
              <div className="top_box_magic">
                <h1>
                  Store Owner
                </h1>

                <Chip
                  avatar={<Avatar alt={ownerName} src={ownerImg} />}
                  label={ownerName.toLowerCase()}
                  variant="outlined"
                  size='medium'
                  style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                  onClick={() => history.push('/partners/' + match.params.pid + '/customers/profile/' + ownerId)}
                />

              </div>
            )}

            <div className="top_box_magic">
              <h1>
                Active Products
              </h1>

              <Chip label={products_count + ' Products'}
                style={{ maxWidth: 100, marginTop: 10, width: 'fit-content' }}
                variant="filled"
                color="default"
                onClick={() => history.push('/partners/' + match.params.pid + '/products/0?sort=updatedAt&dir=desc&store=' + id)}
              />

            </div>

            {id && (
              <WidgetCount title="Rented Out Products"
                label="Products"
                type="orders"
                onClick={() => history.push('/partners/' + match.params.pid + '/products/0?sort=updatedAt&dir=desc&store=' + id)}
                filters={{
                  store: id,
                  query: 'products'
                }}
              />
            )}

            {id && (
              <WidgetCount title="Total Products"
                label="Products"
                type="products"
                onClick={() => history.push('/partners/' + match.params.pid + '/products/0?sort=updatedAt&dir=desc&store=' + id)}
                filters={{
                  store: id
                }}
              />
            )}


            {id && (
              <WidgetCount title="Total Orders"
                label="Orders"
                type="orders"
                onClick={() => history.push('/partners/' + match.params.pid + '/orders/0?sort=updatedAt&dir=desc&store=' + id)}
                filters={{
                  store: id
                }}
              />
            )}

            <div className="top_box_magic">
              <h1>
                Total Reviews
              </h1>

              <Chip label={reviews + ' Reviews'}
                style={{ maxWidth: 100, marginTop: 10, width: 'fit-content' }}
                variant="filled"
                color="default"
                onClick={() => history.push('/partners/' + match.params.pid + '/reviews/0?sort=updatedAt&dir=desc&store=' + id)}
              />

            </div>

            <div style={{ marginTop: 40 }} />

            <ReviewComponent title="Communication"
              value={communication || 0}
              ReadOnly={true}

            />
            <ReviewComponent title="Logistics"
              value={logistic || 0}
              ReadOnly={true}

            />
            <ReviewComponent title="Products"
              value={products || 0}
              ReadOnly={true}

            />

          </div>
        </Grid>
      </Grid>

    </>


  )


}




export default AdminStoresDetails
