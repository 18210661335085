import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import { onUpdateCart } from '../../actions';

import { postApiPrivate } from '../../api';
import StarReview from '../General/Stars';

const SearchCustomer = (props) => {

    const { onClose } = props;

    const { LANGUAGE, onUpdateCustomer, Geocode } = props;

    const inputRef = useRef();

    const [isLoading, setLoading] = useState(true);
    const [isFocused, setFocused] = useState(false);
    const [SearchResults, setSearchResults] = useState([]);
    const [SearchInput, setSearchInput] = useState('');

    useEffect(() => {

        inputRef.current.focus();

    }, [])

 
    const onUpdateSearch = async (store) => {

        setFocused(false);

        setSearchInput(store[LANGUAGE]);

        console.log("CITY ==> ", store);

        onClose();

        onUpdateCustomer(store)

    }

    const onSearchCustomers = async (query) => {

        setSearchInput(query);

        if (!query || query.length <= 3) {
            setFocused(false);
            setSearchResults([]);

            return
        }

        //Fetch

        setLoading(true);

        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/users/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if (filtredResults.length > 0) {
                setFocused(true);
                setSearchResults(filtredResults);
            }

            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setFocused(false);
            setSearchResults([]);
            setLoading(false);
        }


    }

    const onSearchKeyUp = async (event) => {
        event.preventDefault();

        if (event.keyCode === 13) {
            event.target.blur();

            if (SearchResults.length > 0) {
                onUpdateSearch(SearchResults[0]);
            }
        }
    }


    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 flex-col " style={{ zIndex: 1000 }}>
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
                <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col slider-in">
                    <div className="flex justify-between p-8 border-b border-zinc-200 bg-white z-20">
                        {/* <h2>Search a City</h2> */}
                        <div onClick={() => onClose()}>
                            <img className='w-[150px] mr-4' src='/assets/img/babonbo_logo_provider.png' alt='' />
                        </div>
                        <img className="cursor-pointer" src="/assets/img/close.svg" onClick={() => onClose()} alt='' />
                    </div>

                    <div className='p-3 flex flex-col'>
                        <div className="input-group mt-3">

                            <input className="bg-white py-3 ring-2 ring-bagreen-100" type="text" placeholder="Search by customer name..."
                                onFocus={() => setFocused(true)}
                                onChange={(e) => onSearchCustomers(e.target.value)}
                                onKeyUp={(e) => onSearchKeyUp(e)}
                                value={SearchInput}
                                autoComplete="new-password"
                                ref={inputRef}

                            />

                            {isLoading ? (
                                <div className="w-[54px] h-[54px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute top-[3px] right-[3px] z-30">
                                    <img className='animate-spin w-[20px] h-[20px]' src="/assets/img/loader-white.png" alt='' />
                                </div>
                            ) : (
                                <div className="w-[54px] h-[54px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute top-[3px] right-[3px] z-30">
                                    <img src="/assets/img/search.svg" style={{ filter: 'invert(100%)' }} alt='' />
                                </div>
                            )}

                        </div>

                        <div className="w-full bg-white rounded-lg text-black z-30 overflow-auto" style={{ height: '100vh' }}>

                            {/* <pre>
                                    {JSON.stringify(SearchResults, null, 2)}
                                </pre> */}

                            {SearchResults.map((item, index) => {

                                const store_name = item.name;
                                const store_img = item.img;
                                const store_score = item.score || 0;
                                const store_reviews = item.reviews || 0;
                                const store_rating = item.rating || 0;
                                const store_distance = item.distance || 0;
                                const store_status = item.status || false;
                                const store_orders = item.orders || 0;

                                return (

                                    <div className="w-full p-3 my-3 flex items-center justify-between cursor-pointer hover:bg-zinc-100" onClick={() => onUpdateSearch(item)} key={index}>
                                        <div className='flex'>
                                            <img className='w-[40px] h-[40px] rounded-full object-cover mr-3 mb-auto mt-1' src={store_img || '/assets/img/avatar.svg'} alt='' />
                                            <div className='flex flex-col'>
                                                <div className='font-bold'>{store_name}</div>
                                                <StarReview rate={store_rating} size={20} />
                                                <div className='text-xs'>{store_reviews} reviews</div>
                                                <div className='text-xs'>Score: {parseInt(store_score)}</div>
                                                <div className='text-xs'>Orders: {parseInt(store_orders)}</div>
                                                <div className='text-xs'>{store_distance.toFixed(2)} from center</div>
                                            </div>
                                        </div>


                                        {/* {store_status ? (
                                            <div className='bg-green-500 text-xs text-white rounded-md p-1'>Published</div>
                                        ) : (
                                            <div className='bg-orange-500 text-xs text-white rounded-md p-1'>Unpublished</div>
                                        )} */}

                                    </div>
                                )

                            })}

                            <div style={{ height: 200 }}></div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}



const mapStateToProps = state => {
    return ({
        USER: state.user,
        PROVIDER: state.provider,
        CART: state.cart,
        PRODUCT: state.product,
        PRODUCTS: state.products
    });
}

const mapDispatchToProps = dispatch => ({
    onUpdateCart: (cart) => dispatch(onUpdateCart(cart))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchCustomer);
