import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import {PersonOutline, CloseSharp} from 'react-ionicons';

import {Languages_Array} from '../General/Constants';

const NavSide = (props) => {

  const {history, match, setSidebar, setSearchbar, USER, LANGUAGE, onSelectLanguage, type} = props;

  const [isDrop, setDrop] = useState(false);

  const CURRENT_URL = match.url;

  const isProvider = (CURRENT_URL.includes('/onboarding/provider')) || CURRENT_URL.includes('/dashboard');

  return(
    
    <div className="top_navbar">
                
        <div className="top_navbar_left">
            
            {isProvider ? (
                <Link to="/">
                      <img src="/assets/img/babonbo_logo_provider.png" className="navlogo" style={{display: 'block'}}/>
                </Link>  
            ) : (
                <Link to="/">
                      <img src="/assets/img/babonbo_logo.png" className="navlogo" style={{display: 'block'}}/>
                </Link> 
            )}
        
        </div>

        <div className="top_navbar_right">
           
            {(type === 'SideBar') && (
                <>
                    <div className="navlanguage" onClick={()=> setDrop('language')} style={{display: 'flex', marginRight: 0}}>
                        <img src={'/assets/languages/'+LANGUAGE+'.png'} width="25" height="25" alt={'Babonbo | '+LANGUAGE.toUpperCase()} />
                    </div>
                    
                    {(isDrop && isDrop === 'language') && (
                    <div className='language_dropdown' style={{top: 50, marginRight: 30}}>
                            {Languages_Array.map((item, index) => {
                                
                                return (
                                    <div className={(LANGUAGE === item.code ? 'active' : null)} key={index} onClick={()=> onSelectLanguage(item.code)}>
                                        <img  src={'/assets/languages/'+item.code+'.png'} width="20" height="20" alt={'Babonbo | '+item.code.toUpperCase()} /> {item.name}
                                    </div>
                                )

                            })}
                    </div>
                    )}

                    <div className="navprofile" style={{display: 'block'}} onClick={()=> history.push('/profile')}>
                        {(USER.name && USER.picture) ? (
                            <img src={USER.picture} />
                        ) : (
                            <div>
                                <PersonOutline color={'#ffffff'}/>
                            </div>
                        )}
                    </div>

                </>
            )}  
            
            <div className="navburger" onClick={()=> (type === 'SearchBar') ? setSearchbar(false) :  setSidebar(false)}>
                <CloseSharp color={'#000000'} />
            </div>

        
        </div>

    </div>
      
  )


}


export default NavSide
