import React from 'react';
import { ArrowForwardCircleOutline, ChevronForwardCircleOutline } from 'react-ionicons';

const ListProducts = (props) => {
  const { products, onClick } = props;

  return (
    <div className='listing-item' onClick={onClick}>
      <div className='flex align-tems-center justify-content-between '>
        <h3 className='quicksand font-bold'>{products.title}</h3>
        <h4 className='px-2 py-1 text-xs border-black md:hidden quicksand font-bold'>{`${products.total} + ${products.type}`}</h4>
      </div>

      <div className='relative flex align-items-center '>
        <ul className='list-products '>
          {products.products.map((x) => (
            <li className='product-avatar'>
              <img src={x.img} />
            </li>
          ))}
        </ul>

        <div className='absolute left-0  md:hidden flex justify-end items-center mt-4 pl-auto pr-1 py-2 w-full h-[40px] gap-1 text-white text-sm bg-ba rounded-full'>
          List All <ArrowForwardCircleOutline color='' />
        </div>
      </div>

      <div className='product-info'>
        <h4>{`${products.total} + ${products.type}`}</h4>
        <div className='pointer'>
          List All <ChevronForwardCircleOutline color='' />
        </div>
      </div>
    </div>
  );
};

export default ListProducts;
