import React, {useState} from "react";

import LoaderMaster from "../Loaders/Loaders";

import {postApiPrivate} from "../../api";

import { CheckmarkCircleOutline, CloseSharp,  SaveSharp } from "react-ionicons";

import swal from "sweetalert";

import {Chip, Container, Grid, TextField } from "@mui/material";

import ReactCountryFlag from "react-country-flag";
import Countries_EN from '../Search/countries_en.json';

import "../Profile/z_profile.css";

import Iframe from "react-iframe";
import { Link } from "react-router-dom";

const AdminGeopointsAdd = (props) => {

  const {TRANSLATE, history, LANGUAGE} = props;

  const [isSaving, setSaving] = useState(false);

  const [isFocused, setFocused] = useState(false);
  const [CityResults, setCityResults] = useState([]);
  const [CityInput, setCityInput] = useState('');

  const [PUBLIC_GEOPOINT, setGeopoint] = useState({});

  const [LangName, setLangName] = useState('en');

  
  const onUpdateGeopoint = async(key, value) => {

    const geocode = PUBLIC_GEOPOINT.geocode || {};

    if(key === 'latitude'){
        setGeopoint({
          ...PUBLIC_GEOPOINT,
          geocode: {
            ...geocode,
            latitude: value
          }
        })

        return
    }

    if(key === 'longitude'){
        setGeopoint({
          ...PUBLIC_GEOPOINT,
          geocode: {
            ...geocode,
            longitude: value
          }
        })

        return
    }

    setGeopoint({
      ...PUBLIC_GEOPOINT,
      [key]: value
    });

  }

  const onSaveGeopoint = async() => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        var FORM = { 
          type: PUBLIC_GEOPOINT.type,
          en: PUBLIC_GEOPOINT.en,
          it: PUBLIC_GEOPOINT.it,
          fr: PUBLIC_GEOPOINT.fr,
          es: PUBLIC_GEOPOINT.es,
          city: PUBLIC_GEOPOINT.city,
          country: PUBLIC_GEOPOINT.country,
          points: PUBLIC_GEOPOINT.points,
          geocode: PUBLIC_GEOPOINT.geocode,
          status: PUBLIC_GEOPOINT.status,
          search: PUBLIC_GEOPOINT.search
        };

        const GeopointImages = PUBLIC_GEOPOINT.images || [];

        if(GeopointImages.length > 0){
          FORM = {
            ...FORM,
            images: GeopointImages
          }
        }

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/geopoints/create', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Create This Geopoint', 'error');
      
            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("SEARCH ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('Geopoint Created', 'This Geopoint has been created successfully !!!', 'success');


        const geopoint_id = DATA_RESPONSE.id;
        history.push('/admin/geopoints/profile/'+geopoint_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Created This Geopoint', 'error');
         
      }

  }

  const onSelectCity = async(city) => {

      console.log("CITY SELECTED ===> ", city);

      setFocused(false);

      const GeopointCountry = city.country; 

      const country_index = Countries_EN.findIndex((cty)=> cty.value === GeopointCountry);
      const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
      const country_name = country_obx ? country_obx.label : null;

      setGeopoint({
        ...PUBLIC_GEOPOINT,
        city: city[LANGUAGE],
        country: {
          name: country_name,
          code: GeopointCountry
        },
        search: city.id
      });

      

      setCityInput(city[LANGUAGE]);

      swal({
        title: 'City Updated',
        text: 'You selected '+city[LANGUAGE],
        icon: 'success',
        timer: 2000
      })
      
  }

  const onSearchCity = async(query) => {

      setCityInput(query);

      if(!query){
          setFocused(false);
          setCityResults([]);

          return 
      }

      //Fetch

      try{

          const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

          const DATA_RESPONSE = await postApiPrivate('/locations/search', {query: query, lang: LANGUAGE} , JWT);
          
          if(DATA_RESPONSE.error){
              console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
              return 
          }

          console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

          const filtredResults = DATA_RESPONSE.data || [];

          if(filtredResults.length > 0){
              setFocused(true);
              setCityResults(filtredResults);
          }

          
      }
      catch(e){
          
          console.log("CREATING ===> ", e);

          setFocused(false);
          setCityResults([]);
  
      }
    
          
  }

  const onSearchKeyUp = async(event) => {
      event.preventDefault();

      if (event.keyCode === 13) {
          event.target.blur();

          if(CityResults.length>0){
              onSelectCity(CityResults[0]);
          }
      }
  }



  if(isSaving){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
    )

  }


  const {
    type,
    country,
    points,
    status,
    city,
    search,
    en,
    it,
    fr,
    es

  } = PUBLIC_GEOPOINT;

  const geocode = PUBLIC_GEOPOINT.geocode || {};
  const geoLatitude = geocode.latitude || 0;
  const geoLongitude = geocode.longitude || 0;

  const CAN_SAVE = (
        en && en.length>0 &&
        it && it.length>0 &&
        fr && fr.length>0 &&
        es && es.length>0 &&
        geoLatitude !== 0 && geoLongitude !== 0 &&
        type && type.length>0 &&
        city && city.length>0 && 
        country && country.name &&
        search && search.length>0
      
  )

  const GeopointName = PUBLIC_GEOPOINT[LangName] || '';

  return(
  
      <Container maxWidth="lg" style={{background: '#f0f5f9'}}>

            <Grid container spacing={2}>
              
              <Grid item xs={12} lg={9} md={9}>
              
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                        <div className="top_box_magic" style={{marginBottom: 20}}>
                            <h1>
                              Status
                            </h1>

                            {status ? (
                              <Chip label="Active" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateGeopoint('status', false)}/>
                            ) : (
                              <Chip label="Unactive" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning" onClick={(e)=> onUpdateGeopoint('status', true)} />
                            )}
                        </div>   

                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Name

                              <div>
                                <img src="/assets/languages/en.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5)}} onClick={()=> setLangName('en')}/>
                                <img src="/assets/languages/it.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5)}} onClick={()=> setLangName('it')}/>
                                <img src="/assets/languages/fr.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5)}} onClick={()=> setLangName('fr')}/>
                                <img src="/assets/languages/es.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5)}} onClick={()=> setLangName('es')}/>
                              </div>

                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={GeopointName} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateGeopoint(LangName, e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  placeholder="Type here..."
                              
                              />
                            </div>
                        </div>

                        {/* <div className="top_box_magic">
                           <h1 className="d-flex align-items-center justify-content-between">
                              Url

                              <div>
                                <div style={{fontSize: 14, color: '#40a4e9', cursor: 'pointer'}} onClick={()=> onGenerateUrl(LangName, PUBLIC_GEOPOINT[LangName])}>
                                  Auto-generate
                                </div>
                              </div>

                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={PUBLIC_GEOPOINT[LangName+'_url']} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateGeopoint(LangName+'_url', e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  placeholder="Type here..."
                              
                              />
                            </div>
                        </div> */}

                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Type

                              {type && (
                                <div>
                                  <img src={'/assets/img/babonbo_marker_'+type+'.png'} style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5}} />
                                </div>
                              )}
                              
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <select 
                                  className={'gridyx-select'}
                                  value={type} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateGeopoint('type', e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  select
                              >
                                <option value={null}>
                                     ---- Select a geopoint type ----
                                </option>
                                <option value="airport">
                                    Airport
                                </option>
                                <option value="station">
                                    Train Station
                                </option>
                                <option value="port">
                                    Port
                                </option>
                                <option value="place">
                                    Place
                                </option>
                                <option value="hotel">
                                    Hotel
                                </option>
                                <option value="park">
                                    Park
                                </option>
                                
                              </select>
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              City
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={CityInput} 
                                  label={'.'} 
                                  // onChange={(e)=> onUpdateGeopoint('marca', e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  onFocus={()=> setFocused(true)}  
                                  onChange={(e)=> onSearchCity(e.target.value)} 
                                  onKeyUp={(e)=> onSearchKeyUp(e)}
                                
                              
                              />

                              {(isFocused && CityResults.length > 0) && (
                                    <div style={{...Styles.resultsContainer}}>
                                        {CityResults.map((item, index)=> {

                                            const country_index = Countries_EN.findIndex((cty)=> cty.value === item.country);
                                            const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
                                            const country_name = country_obx ? country_obx.label : null


                                            return (
                                                  <div style={Styles.resultsRow} 
                                                          key={index} 
                                                          onClick={()=> onSelectCity(item)}
                                                  >

                                                        <div>
                                                          <ReactCountryFlag countryCode={item.country} svg style={{marginRight: 5}}/> 
                                                          {item[LANGUAGE]} - ({country_name || item.country})
                                                        </div>
                                                      
                                                        <CheckmarkCircleOutline size={28} color="#ccc" style={{marginRight: 10}} />
                                                        
                          
                                                  </div>
                                            )
                                            
                                        })}
                                    </div>
                                )}
                            </div>

                        </div>

                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Country

                              <div>
                                <ReactCountryFlag countryCode={country?.code} svg style={{width: 20, height: 20, marginLeft: 5, cursor: 'pointer'}} /> 
                              </div>

                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={country?.name} 
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  placeholder="Type here..."
                                  disabled
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Latitude
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={geoLatitude}
                                  onChange={(e)=> onUpdateGeopoint('latitude', e.target.value)}
                                  type="number"
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Longitude
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={geoLongitude}
                                  onChange={(e)=> onUpdateGeopoint('longitude', e.target.value)}
                                  type="number"
                              
                              />
                            </div>
                        </div>
                        
                        <Iframe url={"https://maps.google.com/maps?q="+geocode.latitude+"%2C"+geocode.longitude+"&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                                width="100%"
                                height="300"
                                className="map_frame"
                                frameborder="0" 
                                scrolling="no" 
                                marginheight="0" 
                                marginwidth="0"
                        />
                </div>
                

              </Grid>
              <Grid item xs={12} lg={3} md={3}>
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                    

                    <div className="top_box_magic">
                        <h1>
                          Points
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <TextField 
                              className={'gridyx-textfield'}
                              label={'.'} 
                              InputLabelProps={{shrink: false}}
                              value={points}
                              onChange={(e)=> onUpdateGeopoint('points', e.target.value)}
                              type="number"
                          
                          />
                        </div>
                    </div>

                    <div className="top_add_cart"  
                        style={{backgroundColor: '#59ad6a'}} 
                        onClick={()=> {

                            if(!CAN_SAVE){

                                return swal("Incomplete", "Please complete all the fields in order to create this geopoint", 'warning')
                            }

                            swal({
                                title: 'Confirm to Save',
                                text: 'Please insert the CONFIRM to create this geopoint',
                                content: "input",
                            })
                            .then((value) => {

                            if(value === 'CONFIRM'){

                                onSaveGeopoint()
                            }
                            else{

                                swal('Invalid Password', 'You are not authorized to create this geopoint', 'error');
                            }

                            });
                        }}
                        
                    >
                        <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Create Geopoint
                    </div>

                    <Link className="top_add_cart"  style={{backgroundColor: 'var(--dark)'}} to="/admin/geopoints">
                        <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Go Back
                    </Link>
                          
                

                </div>
              </Grid>

            </Grid>  
       
          
      </Container>

    
  )


}



const Styles = {
  resultsRow: {
      display: 'flex', 
      alignItems: 'center', 
      color: '#000', 
      height: 40, 
      minHeight: 40,
      paddingLeft: 20, 
      border: '1px solid #00000040', 
      borderRight: 'none', 
      borderTop: 'none', 
      borderLeft: 'none',
      cursor: 'pointer',
      textTransform: 'capitalize',
      justifyContent: 'space-between'
  },
  resultsContainer: {
      display: 'flex', 
      flexDirection: 'column', 
      background: '#fff', 
      maxHeight: 280,
      position: 'relative', 
      top: 20, 
      border: '1px solid #00000040', 
      borderRadius: 10,
      overflow: 'auto',
      marginBottom: 20
  },
  right: {
      display: 'flex',
      alignItems: 'center'
  },
  searchContainer: {
      width: 300,
      height: 42,
      backgroundColor: '#F1F1F1',
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 5
  },
  input: {
      fontSize: 16,
      display: 'flex',
      color: '#000',
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      marginLeft: 5,
      textTransform: 'capitalize'
  },
  searchOption: {
      display: 'flex', 
      width: 40, 
      height: 35, 
      alignItems: 'center', 
      borderRadius: '50%', 
      background: '#fd6270'
  },
  button: {
        display: 'flex',
        height: '50px',
        width: '200px',
        backgroundColor: '#26BFBF',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',  
        borderRadius: 10,
        width: '100%'
    },
    img_full: {
      display: 'flex',
      width: 200, 
      height: 200, 
      borderRadius: 6, 
      marginRight: 15, 
      marginLeft: 15
    },
    img_empty: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%', 
      height: '100%', 
      borderRadius: 6, 
      marginRight: 15, 
      borderColor: '#ccc', 
      borderWidth: 2, 
      borderStyle: "dashed", 
      justifyContent: 'center', 
      alignItems: 'center',
      marginBottom: 10,
      
    },
    clickCard: {
        backgroundColor: "#fff",
        padding: 20,
        marginTop: 10,
    },
    clickLabel: {
        color: "#697883",
        fontFamily: "SourceSansPro-Bold", 
        fontSize: 14, 
        marginBottom: 10,
    },
    wrapFlex: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    publishBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fd6270',
      padding: 10,
      width: 90,
      color: '#fff',
      borderRadius: 10,
      cursor: 'pointer'
    }

}


export default AdminGeopointsAdd

