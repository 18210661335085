import React, { useState, useEffect } from "react";

import LoaderMaster from "../Loaders/Loaders";

import { postApiPrivate } from "../../api";

import { CloseSharp, Eye, LeafSharp, Pencil, SaveSharp, TrashBinSharp } from "react-ionicons";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, TextField } from "@mui/material";

import "../Profile/z_profile.css";
import DateSelector from "../General/DateSelector";

const AdminGiftCardsDetails = (props) => {

  const { TRANSLATE, history, match } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const [PUBLIC_GIFTCARD, setGiftCard] = useState({});

  const [isCalendar, setCalendar] = useState(false);


  useEffect(() => {

    var giftcard_id = match.params.id || '';

    onFetchPublic(giftcard_id);

  }, []);

  useEffect(() => {

    if (isCalendar) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isCalendar])

  const onSaveAction = (id) => {
    swal({
      title: 'Confirm to Save',
      text: 'Please insert the CONFIRM to update this giftcard',
      content: "input",
    })
      .then((value) => {

        if (value === 'CONFIRM') {

          onSaveGiftCard(id)
        }
        else {

          swal('Invalid Password', 'You are not authorized to update this giftcard', 'error');
        }

      });
  }

  const onFetchPublic = async (giftcard_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setEdited(false);
    setLoading(true);

    try {

      const FORM = { id: giftcard_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/giftcards/details', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      const GC = DATA_RESPONSE.data || {};

      setGiftCard({
        ...DATA_RESPONSE.data,
        date: GC.delivery
      });
      setLoading(false);

    }
    catch (e) {

      setLoading(false);
    }

  }

  const onUpdateGiftCard = async (key, value) => {

    setEdited(true);

    if (key === 'design') {
      let GiftFound = cardList.find((item) => item.type === value);
      if (GiftFound) {
        setGiftCard({
          ...PUBLIC_GIFTCARD,
          type: value,
          design: GiftFound.img
        });
        return
      }
    }
    setGiftCard({
      ...PUBLIC_GIFTCARD,
      [key]: value
    });

  }

  const onSaveGiftCard = async (giftcard_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      var FORM = {
        id: giftcard_id,
        ...PUBLIC_GIFTCARD
      };


      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/giftcards/edit', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update This GiftCard', 'error');
        onFetchPublic(giftcard_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('GiftCard Updated', 'This GiftCard has been updated successfully !!!', 'success');
      onFetchPublic(giftcard_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This GiftCard', 'error');
      onFetchPublic(giftcard_id);
    }

  }

  const onDeleteGiftCard = async (giftcard_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: giftcard_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/giftcards/delete', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Delete This GiftCard', 'error');
        onFetchPublic(giftcard_id);
        console.log("ERROR FROM API LOADING GIFTCARD DELETE", DATA_RESPONSE.error);
        return
      }

      setSaving(false);
      swal('GiftCard Deleted', 'This GiftCard has been deleted successfully !!!', 'success');
      history.replace('/admin/giftcards');

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Delete This GiftCard', 'error');
      onFetchPublic(giftcard_id);
    }
  }

  const onRecoverGiftCard = async (giftcard_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: giftcard_id, recover: true };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/giftcards/delete', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Recover This GiftCard', 'error');
        onFetchPublic(giftcard_id);
        console.log("ERROR FROM API LOADING GIFTCARD DELETE", DATA_RESPONSE.error);
        return
      }

      setSaving(false);
      swal('GiftCard Recovered', 'This GiftCard has been recovered successfully !!!', 'success');

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Delete This GiftCard', 'error');
      onFetchPublic(giftcard_id);
    }
  }


  if (isLoading) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading} />
    )

  }

  if (isSaving) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving} />
    )

  }


  const {
    id,
    fullname,
    email,
    recipient_fullname,
    recipient_email,
    amount,
    date,
    message,
    status,
    type
  } = PUBLIC_GIFTCARD

  const CAN_SAVE = (
    fullname && fullname.length > 0 &&
    email && email.length > 0 &&
    recipient_fullname && recipient_fullname.length > 0 &&
    recipient_email && recipient_email.length > 0 &&
    type && type.length > 0 &&
    amount && amount > 0 &&
    date && date.length > 0
  )

  const cardList = [
    {
      type: 'merry-christmas',
      img: 'https://beta.babonbo.com/assets/gift/1.png'
    },
    {
      type: 'happy-easter',
      img: 'https://beta.babonbo.com/assets/gift/2.png'
    },
    {
      type: 'happy-monthers-day',
      img: 'https://beta.babonbo.com/assets/gift/3.png'
    },
    {
      type: 'enjoy-your-travel',
      img: 'https://beta.babonbo.com/assets/gift/4.png'
    },
    {
      type: 'fathers-day',
      img: 'https://beta.babonbo.com/assets/gift/5.png'
    },
    {
      type: 'happy-birthday',
      img: 'https://beta.babonbo.com/assets/gift/6.png'
    },
    {
      type: 'happy-halloween',
      img: 'https://beta.babonbo.com/assets/gift/7.png'
    }

  ]

  const amountList = [25, 50, 75, 100, 150, 200, 250];

  const {
    isDeleted,
  } = PUBLIC_GIFTCARD;


  const managerObx = PUBLIC_GIFTCARD.manager || {};
  const manager_id = managerObx.id || '';
  const manager_name = managerObx.name || '';
  const manager_img = managerObx.img || '';

  const ownerObx = PUBLIC_GIFTCARD.owner || {};
  const owner_id = ownerObx.id || '';
  const owner_name = ownerObx.name || '';
  const owner_img = ownerObx.img || '';

  const updatedAt = PUBLIC_GIFTCARD.updated || '';
  const createdAt = PUBLIC_GIFTCARD.created || '';
  const redeemedAt = PUBLIC_GIFTCARD.redeemed || '';

  const onCalculateColor = (status) => {
    if (status === 'redeemed') {
      return 'success'
    }
    else if (status === 'paid') {
      return 'info'
    }
    else if (status === 'pending') {
      return 'warning'
    }
    else if (status === 'canceled') {
      return 'success'
    }
    else {
      return 'default'
    }
  }

  const handleShare = async () => {

    let CODE = `BCGC${PUBLIC_GIFTCARD.id}`;

    try {
      if (navigator.share) {
        // Use navigator.share for mobile devices
        await navigator.share({
          title: 'Check out this gift card code!',
          text: `Use this gift card code: ${CODE}`,
        });
        console.log('Referral code shared successfully');
      } else if (navigator.clipboard) {
        // Fallback for desktop devices
        await navigator.clipboard.writeText(CODE);
        console.log('GiftCard code copied to clipboard');
        swal(CODE, `GiftCard code copied to clipboard. You can manually paste it.`, 'success');
      } else {
        throw new Error('Share and Clipboard APIs not supported');
      }
    } catch (error) {
      console.error('Error sharing/referring code:', error.message);
      // Handle errors or provide additional fallbacks
    }
  };

  const status_color = onCalculateColor(status);

  let design = '';
  const foundCard = cardList.find((item) => item.type === type);
  if (foundCard) {
    design = foundCard.img
  }

  return (

    <>
      <Container maxWidth="xl" style={{ background: '#f0f5f9' }}>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} md={6}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>

              {!isEditMode ? (
                <div className="top_add_cart"
                  style={{ backgroundColor: 'var(--info)' }}
                  onClick={() => {
                    swal({
                      title: 'Confirm to Open Edit Mode',
                      text: 'Please insert CONFIRM to recover this giftcard',
                      content: "input",
                    })
                      .then((value) => {

                        if (value === 'CONFIRM') {

                          setEditMode(true)
                        }
                        else {

                          swal('Invalid Password', 'You are not authorized to edit this giftcard', 'error');
                        }

                      });
                  }}
                >
                  <Pencil color={'#fff'} style={{ marginRight: 10 }} /> Edit GiftCard
                </div>
              ) : (
                <div className="top_add_cart"
                  style={{ backgroundColor: 'var(--primary)' }}
                  onClick={() => setEditMode(false)}
                >
                  <Eye color={'#fff'} style={{ marginRight: 10 }} /> View GiftCard
                </div>
              )}

              {!isEdited && (
                <>

                  {isDeleted ? (
                    <div className="top_add_cart"
                      style={{ backgroundColor: 'var(--success)' }}
                      onClick={() => {
                        swal({
                          title: 'Confirm to Recover',
                          text: 'Please insert CONFIRM to recover this giftcard',
                          content: "input",
                        })
                          .then((value) => {

                            if (value === 'CONFIRM') {

                              onRecoverGiftCard(id)
                            }
                            else {

                              swal('Invalid Password', 'You are not authorized to recover this giftcard', 'error');
                            }

                          });
                      }}
                    >
                      <LeafSharp color={'#fff'} style={{ marginRight: 10 }} /> Recover GiftCard
                    </div>
                  ) : (
                    <div className="top_add_cart"
                      style={{ backgroundColor: '#e64942' }}
                      onClick={() => {
                        swal({
                          title: 'Confirm to Delete',
                          text: 'Please insert CONFIRM to delete this giftcard',
                          content: "input",
                        })
                          .then((value) => {

                            if (value === 'CONFIRM') {

                              onDeleteGiftCard(id)
                            }
                            else {

                              swal('Invalid Password', 'You are not authorized to delete this giftcard', 'error');
                            }

                          });
                      }}
                    >
                      <TrashBinSharp color={'#fff'} style={{ marginRight: 10 }} /> Delete GiftCard
                    </div>
                  )}

                  <div className="top_add_cart" style={{ backgroundColor: '#ccc' }} onClick={() => history.goBack()}>
                    <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Go Back
                  </div>

                </>
              )}

              {isEdited && (
                <>

                  <div className="top_add_cart"
                    style={{ backgroundColor: '#59ad6a' }}
                    onClick={() => onSaveAction(id)}

                  >
                    <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Update GiftCard
                  </div>

                  <div className="top_add_cart" style={{ backgroundColor: '#ccc' }} onClick={() => onFetchPublic(id)}>
                    <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Cancel Changes
                  </div>

                </>
              )}


            </div>
          </Grid>
          {isEditMode ? (
            <Grid item xs={12} lg={5} md={6}>

              <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

                <div className="top_box_magic" style={{ marginBottom: 20 }}>
                  <h1>
                    Status
                  </h1>

                  <Chip label={status} style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color={status_color} onClick={(e) => onUpdateGiftCard('status', false)} />

                </div>

                <div className="top_box_magic">
                  <h1 className="d-flex align-items-center justify-content-between">
                    Choose a Design
                  </h1>
                  <div className="w-full flex flex-wrap justify-center gap-2">
                    {cardList.map((item, index) => {
                      return (
                        <img src={item.img}
                          key={index}
                          onClick={() => onUpdateGiftCard('type', item.type)}
                          className={"cursor-pointer h-[100px] border-1 rounded-lg " + (type === item.type ? 'border-babonbo-100 border-2' : '')}
                          alt={item.type}
                        />
                      )
                    })}
                  </div>
                </div>

                <div className="top_box_magic">
                  <h1>
                    Choose an E-Gift Card Amount
                  </h1>
                  <div className='w-full flex flex-wrap justify-end gap-2'>
                    {amountList.map((item, index) => (
                      <button
                        key={index}
                        name='amount'
                        type='button'
                        className={`text-black rounded-lg max-w-56 min-w-fit py-2 px-4 bg-zinc-100 text-center ${item === amount ? 'border-2 border-babonbo-100 drop-shadow-sm' : 'hover:drop-shadow-lg cursor-pointer'
                          }`}
                        onClick={() => onUpdateGiftCard('amount', item)}
                      >
                        ${item}
                      </button>
                    ))}
                  </div>
                  <div className="flex items-center justify-between" style={{ margin: '10px 0px' }}>
                    <div className="mr-2">$</div>
                    <TextField
                      className={'gridyx-textfield'}

                      InputLabelProps={{ shrink: false }}
                      value={amount}
                      onChange={(e) => onUpdateGiftCard('amount', e.target.value)}
                      type="number"

                    />
                  </div>
                </div>

                <div className="top_box_magic">
                  <h1>
                    Who's the sender ?
                  </h1>

                  <div className="flex items-center justify-between gap-x-2" style={{ margin: '10px 0px' }}>
                    <div className="w-full">
                      <div className="mr-2">Name</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={fullname}
                        onChange={(e) => onUpdateGiftCard('fullname', e.target.value)}
                        type="text"

                      />
                    </div>
                    <div className="w-full">
                      <div className="mr-2">Email</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={email}
                        onChange={(e) => onUpdateGiftCard('email', e.target.value)}
                        type="email"

                      />
                    </div>
                  </div>
                </div>

                <div className="top_box_magic">
                  <h1>
                    Who's the recipient ?
                  </h1>

                  <div className="flex items-center justify-between gap-x-2" style={{ margin: '10px 0px' }}>
                    <div className="w-full">
                      <div className="mr-2">Name</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={recipient_fullname}
                        onChange={(e) => onUpdateGiftCard('recipient_fullname', e.target.value)}
                        type="text"

                      />
                    </div>
                    <div className="w-full">
                      <div className="mr-2">Email</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={recipient_email}
                        onChange={(e) => onUpdateGiftCard('recipient_email', e.target.value)}
                        type="email"

                      />
                    </div>
                  </div>
                </div>

                <div className="top_box_magic">
                  <h1>
                    Customize Delivery
                  </h1>

                  <div className="flex justify-between gap-x-2" style={{ margin: '10px 0px' }}>

                    <div className="w-full">
                      <div className="mr-2">Delivery Message</div>
                      <textarea
                        className={'gridyx-textfield rounded-md'}
                        InputLabelProps={{ shrink: false }}
                        value={message}
                        onChange={(e) => onUpdateGiftCard('message', e.target.value)}
                        style={{ minHeight: 300, border: 'solid 1px #00000040' }}

                      />
                    </div>

                    <div className="w-full">
                      <div className="mr-2">Delivery Date</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={date}
                        onClick={() => setCalendar(true)}
                        type="text"


                      />

                      <div className="flex flex-wrap gap-2 mt-4">
                        <button
                          name='amount'
                          type='button'
                          className={`text-black rounded-lg max-w-56 min-w-fit py-2 px-4 bg-zinc-100 text-center ${date === moment().format('DD/MM/YYYY') ? 'border-2 border-babonbo-100 drop-shadow-sm' : 'hover:drop-shadow-lg cursor-pointer'
                            }`}
                          onClick={() => onUpdateGiftCard('date', moment().format('DD/MM/YYYY'))}
                        >
                          Today
                        </button>
                        <button
                          name='amount'
                          type='button'
                          className={`text-black rounded-lg max-w-56 min-w-fit py-2 px-4 bg-zinc-100 text-center ${date === moment().add(1, 'days').format('DD/MM/YYYY') ? 'border-2 border-babonbo-100 drop-shadow-sm' : 'hover:drop-shadow-lg cursor-pointer'
                            }`}
                          onClick={() => onUpdateGiftCard('date', moment().add(1, 'days').format('DD/MM/YYYY'))}
                        >
                          Tomorrow
                        </button>
                        <button
                          name='amount'
                          type='button'
                          className={'text-black rounded-lg max-w-56 min-w-fit py-2 px-4 bg-zinc-100 text-center hover:drop-shadow-lg cursor-pointer'}
                          onClick={() => setCalendar(true)}
                        >
                          Custom
                        </button>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

            </Grid>
          ) : (
            <Grid item xs={12} lg={5} md={6}>

              <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

                <div className="top_box_magic" style={{ marginBottom: 20 }}>
                  <h1>
                    Status
                  </h1>

                  <Chip label={status} style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color={status_color} />

                </div>

                <div className="top_box_magic">
                  <h1>
                    Gift Card Code
                  </h1>
                  <div className='w-full flex flex-wrap justify-start gap-2'>
                    <button
                      onClick={() => handleShare()}
                      name='amount'
                      type='button'
                      className={`text-black text-5xl rounded-lg w-full py-2 px-10 bg-zinc-100 text-center border-2 border-babonbo-100 drop-shadow-sm`}
                    >
                      BCGC{PUBLIC_GIFTCARD.id}
                    </button>
                  </div>
                </div>

                <div className="top_box_magic">
                  <h1 className="d-flex align-items-center justify-content-between">
                    Choose a Design
                  </h1>
                  <div className="w-full flex flex-wrap justify-start gap-2">
                    <img src={design}
                      className={"cursor-pointer h-[200px] border-1 rounded-lg border-babonbo-100 border-2"}
                      alt={type}
                    />
                  </div>
                </div>

                <div className="top_box_magic">
                  <h1>
                    Choose an E-Gift Card Amount
                  </h1>
                  <div className='w-full flex flex-wrap justify-start gap-2'>
                    <button
                      name='amount'
                      type='button'
                      className={`text-black text-2xl rounded-lg w-[100px] min-w-fit py-2 px-4 bg-zinc-100 text-center border-2 border-babonbo-100 drop-shadow-sm`}
                    >
                      ${amount}
                    </button>
                  </div>

                </div>

                <div className="top_box_magic">
                  <h1>
                    Who's the sender ?
                  </h1>

                  <div className="flex items-center justify-between gap-x-2" style={{ margin: '10px 0px' }}>
                    <div className="w-full">
                      <div className="mr-2">Name</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={fullname}
                        type="text"
                        disabled

                      />
                    </div>
                    <div className="w-full">
                      <div className="mr-2">Email</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={email}
                        type="email"
                        disabled

                      />
                    </div>
                  </div>
                </div>

                <div className="top_box_magic">
                  <h1>
                    Who's the recipient ?
                  </h1>

                  <div className="flex items-center justify-between gap-x-2" style={{ margin: '10px 0px' }}>
                    <div className="w-full">
                      <div className="mr-2">Name</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={recipient_fullname}
                        type="text"
                        disabled

                      />
                    </div>
                    <div className="w-full">
                      <div className="mr-2">Email</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={recipient_email}
                        type="email"
                        disabled

                      />
                    </div>
                  </div>
                </div>

                <div className="top_box_magic">
                  <h1>
                    Customize Delivery
                  </h1>

                  <div className="flex justify-between gap-x-2" style={{ margin: '10px 0px' }}>

                    <div className="w-full">
                      <div className="mr-2">Delivery Message</div>
                      <textarea
                        className={'gridyx-textfield rounded-md'}
                        InputLabelProps={{ shrink: false }}
                        value={message}
                        style={{ minHeight: 300, border: 'solid 1px #00000040' }}
                        disabled

                      />
                    </div>

                    <div className="w-full">
                      <div className="mr-2">Delivery Date</div>
                      <TextField
                        className={'gridyx-textfield'}
                        InputLabelProps={{ shrink: false }}
                        value={date}
                        type="text"
                        disabled


                      />


                    </div>
                  </div>
                </div>

              </div>

            </Grid>
          )}
          <Grid item xs={12} lg={3} md={3}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>

              <div className="top_box_magic">
                <h1>
                  GiftCard Created
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <p>
                    {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                  </p>
                </div>
              </div>
              <div className="top_box_magic">
                <h1>
                  GiftCard Updated
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <p>
                    {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                  </p>
                </div>
              </div>

              {owner_id && (
                <>
                  <div className="top_box_magic">
                    <h1>
                      Redeemed By
                    </h1>
                    <Chip
                      avatar={<Avatar alt={owner_name} src={owner_img} />}
                      label={owner_name.toLowerCase()}
                      variant="outlined"
                      size='medium'
                      style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                      onClick={() => history.push('/admin/users/profile/' + owner_id)}
                    />
                  </div>
                  <div className="top_box_magic">
                    <h1>
                      GiftCard Redeemed
                    </h1>
                    <div style={{ margin: '10px 0px' }}>
                      <p>
                        {moment(redeemedAt).format('dddd DD MMMM YYYY - HH:mm')}
                      </p>
                    </div>
                  </div>
                </>
              )}


              {manager_id && (
                <div className="top_box_magic">
                  <h1>
                    Manager
                  </h1>
                  <Chip
                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                    label={manager_name.toLowerCase()}
                    variant="outlined"
                    size='medium'
                    style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                    onClick={() => history.push('/admin/users/profile/' + manager_id)}
                  />
                </div>
              )}


              {/* <div className="mt-10">
                <pre>
                  {JSON.stringify(PUBLIC_GIFTCARD, null, 2)}
                </pre>
              </div> */}



            </div>
          </Grid>
        </Grid>


      </Container>

      {isCalendar && (
        <DateSelector {...props}
          onClose={() => setCalendar(false)}
          date={date}
          title={'Select Delivery Date'}
          description={'Choose the delivery date for your gift card from the calendar'}
          extra={'Your Gift card will be delivered on:'}
          onSave={(item) => {
            onUpdateGiftCard('date', item)
            setCalendar(false);
          }}
        />
      )}

    </>


  )


}





export default AdminGiftCardsDetails

