import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Button, Checkbox, Chip, CircularProgress, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, Tooltip, Typography } from '@mui/material';

import { Add } from '@mui/icons-material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';
import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';


const AdminResources = (props) => {

    const { TRANSLATE, history, match, location, USER } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [BLOGS, setResources] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var current_page = parseInt(page_id);

            if (USER.admin) {
                onFetchResources({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else {
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

                history.push('/logout')
            }

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchResources = async ({ page, sort, direction }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/pages', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setResources(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchResources = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchResources({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/pages/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setResources(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setResources([]);
            setLoading(false);
        }


    }

    const onSortResources = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'search_name'
        }
        else if (sort === 'img') {
            return 'photo'
        }
        else if (sort === 'lang') {
            return 'language'
        }
        else if (sort === 'isCompleted') {
            return 'setup'
        }
        else {
            return sort
        }
    }

    const onChangePageResources = ({ page, sort, direction }) => {
        let new_url = '/admin/resources/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        history.push(new_url);
    }

    const onSelectResource = (resource_id) => {
        history.push('/admin/resources/profile/' + resource_id);
    }


    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectResource(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "banner",
            label: "BANNER",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src) => {

                    if (src) {
                        return (
                            <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={src || ''} />
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip size='small' label="NO" />
                        </div>

                    )

                }
            },
        },
        {
            name: "title",
            label: "TITLE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const resourceObx = BLOGS[rowIndex] || {};

                    const resource_id = resourceObx.id;
                    const resource_code = resourceObx.title || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={resource_code.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => onSelectResource(resource_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "category",
            label: "MODULE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const resourceObx = BLOGS[rowIndex] || {};

                    const resource_id = resourceObx.id;
                    const resource_category = resourceObx.category || '';

                    if (!resource_category) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={resource_category}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => onSelectResource(resource_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "tags",
            label: "TAGS",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const resourceObx = BLOGS[rowIndex] || {};

                    const resource_id = resourceObx.id;
                    const resource_tags = resourceObx.tags || '';


                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={resource_tags || 0}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => onSelectResource(resource_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "language",
            label: "LANGUAGE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const resourceObx = BLOGS[rowIndex] || {};

                    const resource_id = resourceObx.id;
                    const resource_language = resourceObx.language || '';

                    let final_text = '';

                    if (resource_language === 'en') {
                        final_text = 'English 🇬🇧'
                    }
                    else if (resource_language === 'it') {
                        final_text = 'Italian 🇮🇹'
                    }
                    else if (resource_language === 'es') {
                        final_text = 'Spanish 🇪🇸'
                    }
                    else if (resource_language === 'fr') {
                        final_text = 'French 🇫🇷'
                    }

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={final_text}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => onSelectResource(resource_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label === 'true') {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="Published" color='success' />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip size='small' label="Unpublished" color='warning' />
                        </div>
                    )
                }
            }
        },
        {
            name: "date",
            label: "DATE",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = BLOGS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_resource = {
            id: item.id,
            title: item.title,
            subtitle: item.subtitle,
            banner: item.banner,
            category: item.category,
            language: item.language,
            tags: item.tags,
            status: item.status ? 'true' : 'false',
            date: moment(item.date).format('DD MMMM YYYY'),
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY')
        };

        return new_resource
    })

    const customToolbar = () => (
        <Tooltip disableFocusListener title="Add Resource">
            <IconButton onClick={() => history.push('/admin/resources/profile/new')}>
                <Add />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageResources({
                        page: tableState.page,
                        sort: onSortResources(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageResources({
                        page: tableState.page,
                        sort: onSortResources(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchResources(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchResources(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Resource By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Resources with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },



    };


    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Resources →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}


export default AdminResources

