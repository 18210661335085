import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import ReactCountryFlag from 'react-country-flag';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';

import Countries_EN from '../Search/countries_en.json';
import { Add } from 'react-ionicons';

import LocationFilter from '../Modals/LocationFilter';
import { FilterList } from '@mui/icons-material';

const AdminLocations = (props) => {

    const { TRANSLATE, history, match, location } = props;

    const [isFilter, setFilter] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [LOCATIONS, setLocations] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var current_page = parseInt(page_id);

            onFetchLocations({
                page: current_page,
                ...routeParams
            });
        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchLocations = async (params) => {

        const {page, sort, direction, status, approval, city, country, range, name, lat, lon, categories } = params;

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                status,
                approval,
                country,
                city,
                name,
                range,
                lat, 
                lon,
                categories,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/locations', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setLocations(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchLocations = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchLocations({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/locations/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setLocations(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setLocations([]);
            setLoading(false);
        }


    }

    const onSortLocations = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else {
            return sort
        }
    }

    const onChangePageLocations = (params) => {

        const { status, city, country, name, lat, lon, page, sort, direction } = params;


        let new_url = '/admin/locations/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }
        else {
            new_url = new_url + '?sort=updatedAt&dir=desc';
        }

        if(country) new_url = new_url + '&country='+country;

        if(city) new_url = new_url + '&city='+city+'&name='+name+'&lat='+lat+'&lon='+lon;

        if(status) new_url = new_url + '&status='+status;
 
        history.push(new_url);
    }

    const onSelectLocation = (location_id) => {
        history.push('/admin/locations/profile/' + location_id);
    }


    //Logic
    var routeParams = GetRouteSearchParams(location.search);

    var currentFilterCountry = routeParams.country || null;
    var currentFilterStatus = routeParams.status || null;


    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectLocation(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "sponsored",
            label: "SPONSORED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const locationObx = LOCATIONS[rowIndex] || {};

                    const sponsoredObx = locationObx.sponsored || {};
                    const sponsored_id = sponsoredObx.id || '';
                    const sponsored_name = sponsoredObx.name || '';
                    const sponsored_img = sponsoredObx.img || '';


                    if (sponsored_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={sponsored_name} src={sponsored_img} />}
                                    label={sponsored_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => history.push('/admin/stores/profile/' + sponsored_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: "img",
            label: "THUMBNAIL",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src) => {

                    if (src) {
                        return (
                            <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={src || ''} />
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip size='small' label="NO" />
                        </div>

                    )

                }
            },
        },
        {
            name: "name",
            label: "City",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const pageObx = LOCATIONS[rowIndex] || {};
                    const page_id = pageObx.id;
                    const page_name = pageObx.en;

                    return (
                        <Chip label={page_name}
                            size='medium'
                            color='default'
                            onClick={() => history.push('/admin/page/profile/' + page_id + '?lang=it')}
                        />
                    )

                }
            },
        },
        {
            name: "state",
            label: "State",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const pageObx = LOCATIONS[rowIndex] || {};
                    const page_id = pageObx.id;
                    const page_state = pageObx.state;

                    return (
                        <Chip label={page_state}
                            size='medium'
                            color='default'
                            onClick={() => null}
                        />
                    )

                }
            },
        },
        {
            name: 'country',
            label: 'COUNTRY',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const locationObx = LOCATIONS[rowIndex] || {};
                    const countryCode = locationObx.country;

                    if (!countryCode || countryCode === 'BB') {
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip
                                avatar={<ReactCountryFlag countryCode={countryCode} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                                label={countryCode.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}

                            />
                        </div>
                    )
                },
                customFilterListOptions: {
                    render: (value) => {

                        return (
                            <>
                                <ReactCountryFlag countryCode={value} style={{ marginRight: 5 }} />
                                {value.toUpperCase()}
                            </>

                        )
                    }
                },
                filterOptions: {
                    names: Countries_EN.map((item) => {
                        return item.value
                    })
                }


            }

        },
        {
            name: 'status',
            label: 'STATUS',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const locationObx = LOCATIONS[rowIndex] || {};

                    const status = locationObx.status || false;

                    if (status) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="ACTIVE" color='success' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="OFF" />
                            </div>

                        )
                    }
                },
                filterOptions: {
                    names: ['ON', 'OFF']
                }

            }

        },
        {
            name: "points",
            label: "POINTS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {

                    let Color = '';

                    if (value >= 5000) {
                        Color = 'success'
                    }
                    else if (value < 5000 && value >= 1000) {
                        Color = 'info'
                    }
                    else if (value < 1000 && value >= 100) {
                        Color = 'warning'
                    }
                    else if (value < 100 && value >= 0) {
                        Color = 'error'
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={value} size='small' color={Color} />
                        </div>

                    )
                }
            },
        },
        {
            name: "distance",
            label: "DISTANCE",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {

                    let Color = 'info';

                    // if (value >= 5000) {
                    //     Color = 'success'
                    // }
                    // else if (value < 5000 && value >= 1000) {
                    //     Color = 'info'
                    // }
                    // else if (value < 1000 && value >= 100) {
                    //     Color = 'warning'
                    // }
                    // else if (value < 100 && value >= 0) {
                    //     Color = 'error'
                    // }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={value} size='small' color={Color} />
                        </div>

                    )
                }
            },
        },

        // {
        //     name: "en_url",
        //     label: "ENGLISH 🇬🇧",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         customBodyRender: (label, dir) => {

        //             const rowIndex = dir.rowIndex;
        //             const pageObx = LOCATIONS[rowIndex] || {};
        //             const page_id = pageObx.id;

        //             if (label) {
        //                 return (
        //                     <Tooltip title={label}>
        //                         <Chip label={'ON'}
        //                             size='medium'
        //                             color='success'
        //                             avatar={<EyeSharp color={'#fff'} />}
        //                             onClick={() => window.open('https://www.babonbo.com' + label, '_blank')}
        //                         />
        //                     </Tooltip>
        //                 )
        //             }

        //             return (
        //                 <Chip label={'OFF'} size='medium' color='error' />
        //             )

        //         }
        //     },
        // },
        // {
        //     name: "it_url",
        //     label: "ITALIANO 🇮🇹",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         customBodyRender: (label, dir) => {

        //             const rowIndex = dir.rowIndex;
        //             const pageObx = LOCATIONS[rowIndex] || {};
        //             const page_id = pageObx.id;

        //             if (label) {
        //                 return (
        //                     <Tooltip title={label}>
        //                         <Chip label={'ON'}
        //                             size='medium'
        //                             color='success'
        //                             avatar={<EyeSharp color={'#fff'} />}
        //                             onClick={() => window.open('https://www.babonbo.com' + label, '_blank')}
        //                         />
        //                     </Tooltip>
        //                 )
        //             }

        //             return (
        //                 <Chip label={'OFF'} size='medium' color='error' />
        //             )
        //         }
        //     },
        // },
        // {
        //     name: "fr_url",
        //     label: "FRANÇAIS 🇫🇷",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         customBodyRender: (label, dir) => {

        //             const rowIndex = dir.rowIndex;
        //             const pageObx = LOCATIONS[rowIndex] || {};
        //             const page_id = pageObx.id;

        //             if (label) {
        //                 return (
        //                     <Tooltip title={label}>
        //                         <Chip label={'ON'}
        //                             size='medium'
        //                             color='success'
        //                             avatar={<EyeSharp color={'#fff'} />}
        //                             onClick={() => window.open('https://www.babonbo.com' + label, '_blank')}
        //                         />
        //                     </Tooltip>
        //                 )
        //             }

        //             return (
        //                 <Chip label={'OFF'} size='medium' color='error' />
        //             )
        //         }
        //     },
        // },
        // {
        //     name: "es_url",
        //     label: "ESPANOL 🇪🇸",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         customBodyRender: (label, dir) => {

        //             const rowIndex = dir.rowIndex;
        //             const pageObx = LOCATIONS[rowIndex] || {};
        //             const page_id = pageObx.id;

        //             if (label) {
        //                 return (
        //                     <Tooltip title={label}>
        //                         <Chip label={'ON'}
        //                             size='medium'
        //                             color='success'
        //                             avatar={<EyeSharp color={'#fff'} />}
        //                             onClick={() => window.open('https://www.babonbo.com' + label, '_blank')}
        //                         />
        //                     </Tooltip>
        //                 )
        //             }

        //             return (
        //                 <Chip label={'OFF'} size='medium' color='error' />
        //             )
        //         }
        //     },
        // },
        {
            name: "manager",
            label: "MANAGER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const locationObx = LOCATIONS[rowIndex] || {};

                    const managerObx = locationObx.manager || {};
                    const manager_id = managerObx.id || '';
                    const manager_name = managerObx.name || '';
                    const manager_img = managerObx.img || '';


                    if (manager_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                                    label={manager_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => history.push('/admin/users/profile/' + manager_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: 'isDeleted',
            label: 'DELETED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const locationObx = LOCATIONS[rowIndex] || {};

                    const isDeleted = locationObx.isDeleted || false;

                    if (isDeleted) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="Deleted" color='error' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }
                }
            }

        },
        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = LOCATIONS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_location = {

            id: item.id,
            state: item.state,
            country: item.country,
            img: item.img,
            en: item.en,
            it: item.it,
            fr: item.fr,
            es: item.es,
            tr: item.tr,
            en_url: item.en_url,
            it_url: item.it_url,
            fr_url: item.fr_url,
            es_url: item.es_url,
            tr_url: item.tr_url,
            points: item.points,
            updatedAt: moment(item.updatedAt).fromNow(),
            createdAt: moment(item.createdAt).format('DD MMMM YYYY'),
            status: item.status ? 'ON' : 'OFF',
            isDeleted: item.isDeleted || false,
            manager: item.manager || {},
            distance: parseInt(item.distance) || 0

        };


        return new_location
    })

    const customToolbar = () => (
        <>
            <Tooltip disableFocusListener title="Filter Locations">
                <IconButton onClick={() => setFilter(true)}>
                    <FilterList />
                </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Add Location">
                <IconButton onClick={() => history.push('/admin/locations/profile/new')}>
                    <Add />
                </IconButton>
            </Tooltip>
        </>
    );

    const options = {
        filter: false,
        print: false,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';

            var routeParams = GetRouteSearchParams(location.search);
            var newParams = {
                ...routeParams,
                page: tableState.page,
                sort: onSortLocations(sortFilter),
                direction: sortDirection
            }

            switch (action) {

                case 'changePage':

                    onChangePageLocations({
                        ...newParams
                    });

                    break;
                case 'sort':

                    onChangePageLocations({
                        ...newParams,
                        page: 0
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchLocations(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchLocations(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Location By City Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Locations with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        }

    };




    return (
        <>
            {/* <pre>
                {JSON.stringify(data, null, 2)}
            </pre> */}
            <MUIDataTable
                title={
                    <div className='font-light md:text-5xl text-xl text-lg py-8'>

                        {'Locations Found → '}
                        
                        {isLoading ? (

                            <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />
                        
                            ) : (

                            <>
                               {' '}<span style={{color: '#26BFBF'}}>{CURRENT}</span>{'/'+TOTAL}
                            </>

                        )} 
                         
                    </div>
                } 
                data={data}
                columns={columns}
                options={options}
            />
            {isFilter && (
                <LocationFilter {...props}
                    onClose={() => setFilter(false)}

                />
            )}
        </>
    );

}


export default AdminLocations

