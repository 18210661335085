import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import moment from 'moment/min/moment-with-locales';

import { GetRouteSearchParams, onGetCurrencySymbol } from '../../helpers/GeneralHelper';

import { Calendar, momentLocalizer } from 'react-big-calendar'

import "react-big-calendar/lib/css/react-big-calendar.css";

import { CircularProgress, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import { Select } from '@mui/material';

import swal from 'sweetalert';

import SunCalc from 'suncalc'

const localizer = momentLocalizer(moment)

// const eventStyle = (event) => {

//     const eventType = event.type || 'default';

//     let eventColor = '#26BFBF';

//     if (eventType === 'default') {
//         eventColor = 'green'
//     }
//     else if (eventType === 'delivery') {
//         eventColor = '#fd6270'
//     }
//     else if (eventType === 'pickup') {
//         eventColor = '#26BFBF'
//     }
//     else if (eventType === 'order') {
//         eventColor = 'green'
//     }

//     return {
//         style: {
//             backgroundColor: eventColor,
//             color: '#ffffff',
//             whiteSpace: 'pre-line'
//         }
//     }
// }

// const CalendaryView = (props) => {

//     const { TRANSLATE, history, match, USER } = props;

//     const [isLoading, setLoading] = useState(true);

//     const [STATUS, setStatus] = useState('all');

//     const [PAGE, setPage] = useState(0);

//     const [ORDERS, setOrders] = useState([]);
//     const [TOTAL, setTotal] = useState(null);

//     const LIMIT = 500;
//     let CURRENT = LIMIT * (PAGE + 1);

//     if (TOTAL < CURRENT) {
//         CURRENT = TOTAL;
//     }

//     useEffect(() => {
//         const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
//         if (!JWT) {
//             return history.replace('/login');
//         }
//         else {

//             var page_number = match.params.page || 0;
//             var current_page = parseInt(page_number);

//             if (USER.admin) {
//                 onFetchOrders({
//                     page: current_page
//                 });
//             }
//             else {
//                 setLoading(false)
//                 swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

//                 history.push('/logout')
//             }

//         }

//         window.scrollTo(0, 0);

//     }, [match.params.page]);

//     const onFetchOrders = async ({ page }) => {

//         setPage(page);

//         const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

//         setLoading(true);

//         try {

//             var FORM = {
//                 p: page,
//                 l: LIMIT,
//                 c: true
//             }

//             var DATA_RESPONSE = await postApiPrivate('/admin/orders', FORM, JWT);


//             if (DATA_RESPONSE.error) {
//                 console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
//                 setLoading(false);
//                 return
//             }

//             console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

//             setOrders(DATA_RESPONSE.data);
//             setTotal(DATA_RESPONSE.count);
//             setLoading(false);

//             window.scrollTo(0, 0)


//         }
//         catch (e) {

//             console.log("CREATING ===> ", e);
//             setLoading(false);
//         }

//     }

//     const onSelectOrder = ({ order_id }) => {
//         history.push('/admin/orders/profile/' + order_id);
//     }


//     const delivery = ORDERS.map((item, index) => {

//         moment.locale(TRANSLATE.lang);

//         let currency = item.scurrency || 'eur';
//         let symbol = onGetCurrencySymbol(currency);

//         let store_obx = item.store || {};
//         let customer_obx = item.customer || {};

//         let total = item.total || 0;

//         let new_order = {

//             order_id: item.id,

//             id: index,
//             title: 'DELIVERY #' + item.id + '\n\nSTORE ==> ' + store_obx.name + '\nCUSTOMER ==> ' + customer_obx.name + '\nTOTAL ===> ' + symbol + '' + total.toFixed(2),
//             allDay: true,
//             start: new Date(item.checkin),
//             end: new Date(item.checkin),
//             type: 'delivery'

//         };


//         return new_order

//     });

//     const pickup = ORDERS.map((item, index) => {

//         moment.locale(TRANSLATE.lang);

//         let currency = item.scurrency || 'eur';
//         let symbol = onGetCurrencySymbol(currency);

//         let store_obx = item.store || {};
//         let customer_obx = item.customer || {};

//         let total = item.total || 0;

//         let new_order = {

//             order_id: item.id,

//             id: index,
//             title: 'PICKUP #' + item.id + '\n\nSTORE ==> ' + store_obx.name + '\nCUSTOMER ==> ' + customer_obx.name + '\nTOTAL ===> ' + symbol + '' + total.toFixed(2),
//             allDay: true,
//             start: new Date(item.checkout),
//             end: new Date(item.checkout),
//             type: 'pickup'

//         };


//         return new_order

//     });

//     const orders = ORDERS.map((item, index) => {

//         moment.locale(TRANSLATE.lang);

//         let currency = item.scurrency || 'eur';
//         let symbol = onGetCurrencySymbol(currency);

//         let store_obx = item.store || {};
//         let customer_obx = item.customer || {};

//         let total = item.total || 0;

//         let new_order = {

//             order_id: item.id,

//             id: index,
//             title: 'ORDER #' + item.id + '\n\nSTORE ==> ' + store_obx.name + '\nCUSTOMER ==> ' + customer_obx.name + '\nTOTAL ===> ' + symbol + '' + total.toFixed(2),
//             allDay: true,
//             start: new Date(item.created),
//             end: new Date(item.created),
//             type: 'order'

//         };


//         return new_order

//     });


//     let events = [...orders, ...delivery, ...pickup];

//     if (STATUS === 'orders') {
//         events = [...orders]
//     }
//     else if (STATUS === 'deliveries') {
//         events = [...delivery]
//     }
//     else if (STATUS === 'pickups') {
//         events = [...pickup]
//     }
//     else if (STATUS === 'logistic') {
//         events = [...delivery, ...pickup]
//     }

//     return (
//         <>

//             <Typography variant="h2" style={{ margin: '50px 20px' }}>

//                 CALENDARY →

//                 {isLoading ? (

//                     <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

//                 ) : (

//                     <>
//                         {' '}<span style={{ color: '#26BFBF' }}>{events.length}</span> {(STATUS === 'all' ? 'events' : STATUS)}
//                     </>

//                 )}



//             </Typography>

//             <FormControl style={{ margin: 50, marginTop: 0 }}>
//                 <InputLabel id="demo-simple-select-label">SHOW</InputLabel>
//                 <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={STATUS}
//                     label="Show"
//                     onChange={(e) => setStatus(e.target.value)}
//                     variant="outlined" size='medium' style={{ background: '#fff' }}
//                 >
//                     <MenuItem value={'all'}>All Orders, Deliveries and Pickups</MenuItem>
//                     <MenuItem value={'orders'}>Only Orders</MenuItem>
//                     <MenuItem value={'deliveries'}>Only Deliveries</MenuItem>
//                     <MenuItem value={'pickups'}>Only Pickups</MenuItem>
//                     <MenuItem value={'logistic'}>Only Deliveries & Pickups</MenuItem>
//                 </Select>
//             </FormControl>

//             <Calendar
//                 localizer={localizer}
//                 events={events || []}
//                 startAccessor="start"
//                 endAccessor="end"
//                 style={{ height: 2000, background: '#fff', padding: 20, borderRadius: 10 }}
//                 eventPropGetter={eventStyle}
//                 onSelectEvent={onSelectOrder}
//                 components={[() => (<h1>jsjsjsjsj</h1>)]}
//             />

//         </>
//     );

// }


const AdminCalendary = (props) => {
    const { location, history } = props;
    const [events, setEvents] = useState([]);
    const [orders, setOrders] = useState([]);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);
        const startString = routeParams.start || '';
        const endString = routeParams.end || '';

        if (!startString || !endString) {
            onNavigate(new Date())
        }
        else {
            onFetchOrders()
        }

    }, [location.search]); // Trigger effect when URL query parameters change

    const fetchMoonPhaseData = (ORDERS) => {

        const routeParams = GetRouteSearchParams(location.search);
        const startString = routeParams.start || '';
        const endString = routeParams.end || '';
        const startDate = moment(startString, 'DD-MM-YYYY');
        const endDate = moment(endString, 'DD-MM-YYYY');

        const dates = [];
        const current = startDate.clone();

        // Generate array of dates from start date to end date
        while (current <= endDate) {
            dates.push(current.clone());
            current.add(1, 'days');
        }

        // Calculate moon phase for each date
        const moonPhaseEvents = dates.map(date => {
            const moonTimes = SunCalc.getMoonTimes(date.toDate(), 33, 33); // Latitude and longitude of desired location
            const moonPhase = SunCalc.getMoonIllumination(date.toDate()).phase;
            const { title, icon } = getMoonPhaseName(moonPhase);

            // Filter orders created on the same day as the moon rise time
            const filteredOrders = ORDERS.filter(item => moment(item.created).isSame(date.toDate(), 'day'));

            return {
                title: title,
                start: moonTimes.rise,
                end: moonTimes.rise, // Assuming moon phase lasts only for the rise time
                icon: icon,
                allDay: true,
                orders: filteredOrders.length || [],
                total: ORDERS.length
            };
        });

        setEvents(moonPhaseEvents);
    };


    const onFetchOrders = async () => {

        const routeParams = GetRouteSearchParams(location.search);
        const startString = routeParams.start || '';
        const endString = routeParams.end || '';

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: 0,
                l: 1000,
                start: startString,
                end: endString
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/orders', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const ORDERS = DATA_RESPONSE.data || [];

            setOrders(ORDERS);
            setLoading(false);

            fetchMoonPhaseData(ORDERS);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const getMoonPhaseName = (phase) => {
        if (phase < 0.0625) return { title: 'New Moon', icon: 'new-moon.png' };
        if (phase < 0.1875) return { title: 'Waxing Crescent', icon: 'waxing-crescent.png' };
        if (phase < 0.3125) return { title: 'First Quarter', icon: 'first-quarter.png' };
        if (phase < 0.4375) return { title: 'Waxing Gibbous', icon: 'waxing-gibbous.png' };
        if (phase < 0.5625) return { title: 'Full Moon', icon: 'full-moon.png' };
        if (phase < 0.6875) return { title: 'Waning Gibbous', icon: 'waning-gibbous.png' };
        if (phase < 0.8125) return { title: 'Last Quarter', icon: 'last-quarter.png' };
        if (phase < 0.9375) return { title: 'Waning Crescent', icon: 'waning-crescent.png' };
        return { title: 'New Moon', icon: 'new-moon.png' }; // New Moon (repeating the New Moon phase for simplicity)
    };

    const onNavigate = (newDate) => {
        console.log("Navigation ===> ", newDate);
        // Assuming you want the start date to be the first day of the current month
        const startDate = moment(newDate).startOf('month').format('DD-MM-YYYY');
        // Assuming you want the end date to be the last day of the current month
        const endDate = moment(newDate).endOf('month').format('DD-MM-YYYY');
        history.push(`/admin/calendary?start=${startDate}&end=${endDate}`);
    };


    return (
        <>
            {isLoading && (
                <div className='flex w-full justify-between items-center mb-4'>
                    <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                    <div>
                        Loading...
                    </div>
                    <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                </div>
            )}
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 2000, background: '#fff', padding: 20, borderRadius: 10 }}
                eventPropGetter={eventStyle}
                onSelectEvent={(event) => history.push(`/admin/orders/0?sort=updatedAt&dir=desc&status=all&start=${moment(event.start).format('DD-MM-YYYY')}&end=${moment(event.end).format('DD-MM-YYYY')}`)}
                onNavigate={onNavigate}
                components={{
                    event: EventComponent
                }}

            />
            {/* <pre>
                {JSON.stringify(events, null, 2)}
            </pre> */}
        </>
    );
};



const eventStyle = (event, start, end, isSelected) => {
    const style = {
        backgroundColor: '#000', // Adjust background color as needed
        borderRadius: '5px',
        opacity: 0.8,
        color: '#fff',
        border: 'none',
        display: 'block'
    };

    return {
        style: style
    };
};

const EventComponent = ({ event }) => {

    const total_orders = parseInt(event.orders);

    return (
        <>
            <div style={{ backgroundColor: '#000', padding: '5px', borderRadius: '3px' }}>
                <img src={`/assets/moon/${event.icon}`} alt={event.title} style={{ width: '20px', height: '20px', marginRight: '5px' }} className='rounded-full' />
                {event.title}
            </div>

            {(total_orders === 0) ? (
                <div style={{ backgroundColor: 'red', padding: '5px', borderRadius: '3px' }}>
                    {total_orders} orders
                </div>
            ) : (total_orders > 0 && total_orders < 20) ? (
                <div style={{ backgroundColor: 'orange', padding: '5px', borderRadius: '3px' }}>
                    {total_orders} orders
                </div>
            ) : (total_orders >= 20 && total_orders < 30) ? (
                <div style={{ backgroundColor: 'green', padding: '5px', borderRadius: '3px' }}>
                    {total_orders} orders
                </div>
            ) : (total_orders >= 30) ? (
                <div style={{ backgroundColor: 'purple', padding: '5px', borderRadius: '3px' }}>
                    {total_orders} orders
                </div>
            ) : null}


        </>
    )
};

export default AdminCalendary


