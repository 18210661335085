import { combineReducers } from 'redux';

import user from './user';
import provider from './provider';
import providers from './providers';
import cart from './cart';
import product from './product';
import products from './products';
import language from './language';
import currency from './currency';
import search from './search';
import points from './points';
import count from './count';


export default combineReducers({
  user,
  provider,
  providers,
  cart,
  product,
  products,
  language,
  currency,
  search,
  points,
  count
})
