import React from 'react';

const StarReview = ({ rate, size, onClick, AlreadyReviewed, isHidden }) => {

    if (!size) {
        size = 20;
    }

    const ReviewImgStyle = {
        width: size,
        height: size,
        objectFit: 'cover',
        cursor: AlreadyReviewed ? 'not-allowed' : 'pointer'
    }

    if (!rate) {
        rate = 0;
    }

    //New Logic
    const EmptyStar = 'far fa-star text-warning mr-1';
    const FullStar = 'fa fa-star text-warning mr-1';
    const HalfStart = 'fa fa-star-half-alt text-warning mr-1';

    //New Stars
    let Start_1 = EmptyStar;
    let Start_2 = EmptyStar;
    let Start_3 = EmptyStar;
    let Start_4 = EmptyStar;
    let Start_5 = EmptyStar;

    if (rate < 0.5) {
        Start_1 = EmptyStar;
        Start_2 = EmptyStar;
        Start_3 = EmptyStar;
        Start_4 = EmptyStar;
        Start_5 = EmptyStar;
    }
    else if (rate >= 0.5 && rate < 1) {
        Start_1 = HalfStart;
        Start_2 = EmptyStar;
        Start_3 = EmptyStar;
        Start_4 = EmptyStar;
        Start_5 = EmptyStar;
    }
    else if (rate >= 1 && rate < 1.5) {
        Start_1 = FullStar;
        Start_2 = EmptyStar;
        Start_3 = EmptyStar;
        Start_4 = EmptyStar;
        Start_5 = EmptyStar;
    }
    else if (rate >= 1.5 && rate < 2) {
        Start_1 = FullStar;
        Start_2 = HalfStart;
        Start_3 = EmptyStar;
        Start_4 = EmptyStar;
        Start_5 = EmptyStar;
    }
    else if (rate >= 2 && rate < 2.5) {
        Start_1 = FullStar;
        Start_2 = FullStar;
        Start_3 = EmptyStar;
        Start_4 = EmptyStar;
        Start_5 = EmptyStar;
    }
    else if (rate >= 2.5 && rate < 3) {
        Start_1 = FullStar;
        Start_2 = FullStar;
        Start_3 = HalfStart;
        Start_4 = EmptyStar;
        Start_5 = EmptyStar;
    }
    else if (rate >= 3 && rate < 3.5) {
        Start_1 = FullStar;
        Start_2 = FullStar;
        Start_3 = FullStar;
        Start_4 = EmptyStar;
        Start_5 = EmptyStar;
    }
    else if (rate >= 3.5 && rate < 4) {
        Start_1 = FullStar;
        Start_2 = FullStar;
        Start_3 = FullStar;
        Start_4 = HalfStart;
        Start_5 = EmptyStar;
    }
    else if (rate >= 4 && rate < 4.5) {
        Start_1 = FullStar;
        Start_2 = FullStar;
        Start_3 = FullStar;
        Start_4 = FullStar;
        Start_5 = EmptyStar;
    }
    else if (rate >= 4.5 && rate < 5) {
        Start_1 = FullStar;
        Start_2 = FullStar;
        Start_3 = FullStar;
        Start_4 = FullStar;
        Start_5 = HalfStart;
    }
    else if (rate >= 5) {
        Start_1 = FullStar;
        Start_2 = FullStar;
        Start_3 = FullStar;
        Start_4 = FullStar;
        Start_5 = FullStar;
    }

    return (
        <div className="d-flex align-items-center justify-content-left mb-1" onClick={onClick}>
            <small className={Start_1}></small>
            <small className={Start_2}></small>
            <small className={Start_3}></small>
            <small className={Start_4}></small>
            <small className={Start_5}></small>
            {!isHidden && (
                <small>({rate.toFixed(1)})</small>
            )}

        </div>
    )

}


export default StarReview;