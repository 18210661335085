import React, { useState, useEffect, useRef } from 'react';

import { postApiPrivate } from '../../api';

import AdminMapCluster from './AdminMapCluster';
import { ArrowBackIos, DeliveryDining, ParkOutlined, ArrowRightRounded, MapOutlined, Google } from '@mui/icons-material';

import { LocationOutline, StorefrontOutline, GlobeOutline, TrainOutline, AirplaneOutline, BoatOutline, BusinessOutline, HourglassOutline, FilterOutline, ArrowForwardCircleOutline, Search } from 'react-ionicons'
import { GetRouteSearchParams } from './AdminHelper';

import StoreFilter from '../Modals/StoreFilter';
import LocationFilter from '../Modals/LocationFilter';
import GeopointFilter from '../Modals/GeopointFilter';

import ReactCountryFlag from 'react-country-flag';

import Countries_EN from '../Search/countries_en.json'
import StarReview from '../General/Stars';
import { Filter_Array } from '../Modals/Constants';
import { numberWithCommas, onFormatUrl } from '../../helpers/GeneralHelper';

import moment from "moment";
import swal from "sweetalert";

import DatePicker, { utils } from "react-modern-calendar-datepicker";


import "react-modern-calendar-datepicker/lib/DatePicker.css";

const AdminMap = (props) => {

  const { location } = props;

  const mapRef = useRef();

  const [isMapFiltering, setMapFiltering] = useState(false);
  const [isLocationsFiltering, setLocationsFiltering] = useState(false);
  const [isGeopointsFiltering, setGeopointsFiltering] = useState(false);

  const [isMapSorting, setMapSorting] = useState(false);
  const [isMapSearching, setMapSearching] = useState(false);
  const [isMapLoading, setMapLoading] = useState(false);

  const [PROVIDER, setProvider] = useState(null);
  const [POSITION, setPosition] = useState(null);

  const [STORES, setStores] = useState([]);
  const [CITIES, setCities] = useState([]);
  const [GEOPOINTS, setGeopoints] = useState([]);
  const [STATUS, setStatus] = useState('search');
  const [INPUT, setInput] = useState('');
  const [TOTAL, setTotal] = useState(0);

  useEffect(() => {

    const routeParams = GetRouteSearchParams(location.search);
    const routeSearch = routeParams.search || 'search';

    if (routeSearch) {

      if (routeSearch === 'search' || routeSearch === 'custom') {
        setProvider(null);
        setPosition(null);
      }

      setStatus(routeSearch);
    }


  }, [location.search]);

  const onFetchStores = async (params) => {

    const { sort, direction, status, approval, city, country, range, name, lat, lon, categories } = params;

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setMapLoading(true);

    try {

      var FORM = {
        s: sort,
        d: direction,
        status,
        approval,
        country,
        city,
        name,
        range,
        lat,
        lon,
        categories
      }

      var DATA_RESPONSE = await postApiPrivate('/admin/map', FORM, JWT);


      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
        setMapLoading(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      setStores(DATA_RESPONSE.data);
      setTotal(DATA_RESPONSE.count);
      setMapLoading(false);

      window.scrollTo(0, 0)


    }
    catch (e) {

      console.log("CREATING ===> ", e);
      setMapLoading(false);
    }

  }

  const onSearchStores = async (query) => {

    setInput(query);

    if (!query || query.length == 0) {
      var routeParams = GetRouteSearchParams(location.search);
      var routeSearch = routeParams.search || '';

      if (routeSearch) {
        onFetchStores({
          ...routeParams
        });
      }
      return
    }

    if (query.length <= 3) {

      return
    }

    setMapSearching(true);

    try {

      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

      const DATA_RESPONSE = await postApiPrivate('/admin/map/search', { query: query, type: STATUS }, JWT);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
        setMapSearching(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      setStores(DATA_RESPONSE.data);
      setTotal(DATA_RESPONSE.count);
      setMapSearching(false);

    }
    catch (e) {

      console.log("CREATING ===> ", e);

      setTotal(0);
      setStores([]);
      setMapSearching(false);
    }


  }

  const onFetchCities = async (params) => {

    const { sort, direction, status, approval, city, country, range, name, lat, lon, categories } = params;

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setMapLoading(true);

    try {

      var FORM = {
        s: sort,
        d: direction,
        status,
        approval,
        country,
        city,
        name,
        range,
        lat,
        lon,
        categories
      }

      var DATA_RESPONSE = await postApiPrivate('/admin/map/cities', FORM, JWT);


      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
        setMapLoading(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      setCities(DATA_RESPONSE.data);
      setTotal(DATA_RESPONSE.count);
      setMapLoading(false);

      window.scrollTo(0, 0)


    }
    catch (e) {

      console.log("CREATING ===> ", e);
      setMapLoading(false);
    }

  }

  const onSearchCities = async (query) => {

    setInput(query);

    if (!query || query.length == 0) {
      var routeParams = GetRouteSearchParams(location.search);
      var routeSearch = routeParams.search || '';

      if (routeSearch) {
        onFetchCities({
          ...routeParams
        });
      }
      return
    }

    if (query.length <= 3) {

      return
    }

    setMapSearching(true);

    try {

      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

      const DATA_RESPONSE = await postApiPrivate('/admin/map/search', { query: query, type: STATUS }, JWT);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
        setMapSearching(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      setCities(DATA_RESPONSE.data);
      setTotal(DATA_RESPONSE.count);
      setMapSearching(false);

    }
    catch (e) {

      console.log("CREATING ===> ", e);

      setTotal(0);
      setCities([]);
      setMapSearching(false);
    }


  }

  const onFetchGeopoints = async (params) => {

    const { sort, direction, status, approval, city, country, range, name, lat, lon, categories, type } = params;

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setMapLoading(true);

    try {

      var FORM = {
        s: sort,
        d: direction,
        status,
        approval,
        country,
        city,
        name,
        range,
        lat,
        lon,
        categories,
        type
      }

      var DATA_RESPONSE = await postApiPrivate('/admin/map/geostores', FORM, JWT);


      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
        setMapLoading(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      setGeopoints(DATA_RESPONSE.data);
      setTotal(DATA_RESPONSE.count);
      setMapLoading(false);

      window.scrollTo(0, 0)


    }
    catch (e) {

      console.log("CREATING ===> ", e);
      setMapLoading(false);
    }

  }

  const onSearchGeopoints = async (query) => {

    setInput(query);

    if (!query || query.length == 0) {
      var routeParams = GetRouteSearchParams(location.search);
      var routeSearch = routeParams.search || '';

      if (routeSearch) {
        onFetchGeopoints({
          ...routeParams
        });
      }
      return
    }

    if (query.length <= 3) {

      return
    }

    setMapSearching(true);

    try {

      let SUBTYPE = '';
      if (STATUS === 'airports') {
        SUBTYPE = 'airport';
      }
      else if (STATUS === 'stations') {
        SUBTYPE = 'station'
      }
      else if (STATUS === 'ports') {
        SUBTYPE = 'port'
      }
      else if (STATUS === 'places') {
        SUBTYPE = 'place'
      }
      else if (STATUS === 'parks') {
        SUBTYPE = 'park'
      }

      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

      const DATA_RESPONSE = await postApiPrivate('/admin/map/search', { query: query, type: 'geopoints', sub: SUBTYPE }, JWT);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
        setMapSearching(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      setGeopoints(DATA_RESPONSE.data);
      setTotal(DATA_RESPONSE.count);
      setMapSearching(false);

    }
    catch (e) {

      console.log("CREATING ===> ", e);

      setTotal(0);
      setGeopoints([]);
      setMapSearching(false);
    }


  }

  const onZoomToMarker = (latitude, longitude) => {

    setTimeout(() => {
      mapRef.current.flyTo([latitude, longitude], 15, {
        duration: 2
      });
    }, 300)

  };


  const STORES_POINTS = STORES.map((item) => {

    const GEOCODE = item.geocode;

    return {
      ...item,
      latitude: GEOCODE ? GEOCODE.latitude : 0,
      longitude: GEOCODE ? GEOCODE.longitude : 0,
      type: 'provider'
    }
  })

  const CITIES_POINTS = CITIES.map((item) => {

    const GEOCODE = item.geocode;

    return {
      ...item,
      latitude: GEOCODE ? GEOCODE.latitude : 0,
      longitude: GEOCODE ? GEOCODE.longitude : 0,
      type: 'city'
    }
  })

  const GEOPOINTS_POINTS = GEOPOINTS.map((item) => {

    const GEOCODE = item.geocode;

    return {
      ...item,
      latitude: GEOCODE ? GEOCODE.latitude : 0,
      longitude: GEOCODE ? GEOCODE.longitude : 0
    }
  })

  const sharedProps = {
    ...props,
    isMapLoading,
    setMapLoading,
    isMapSearching,
    setMapSearching,
    isMapFiltering,
    setMapFiltering,
    isLocationsFiltering,
    setLocationsFiltering,
    isGeopointsFiltering,
    setGeopointsFiltering,
    isMapSorting,
    setMapSorting,
    PROVIDER,
    INPUT,
    STATUS,
    STORES,
    CITIES,
    GEOPOINTS,
    POSITION,
    setStatus,
    setProvider,
    setPosition,
    setInput,
    setStores,
    setCities,
    setGeopoints,
    onFetchStores,
    onFetchCities,
    onFetchGeopoints,
    onSearchStores,
    onSearchCities,
    onSearchGeopoints,
    onZoomToMarker

  }


  return (
    <>
      <div className='w-full h-full flex'>

        {STATUS === 'search' && (
          <SearchController {...sharedProps} />
        )}

        {STATUS === 'stores' && (
          <StoresController {...sharedProps} />
        )}
        {STATUS === 'cities' && (
          <CitiesController {...sharedProps} />
        )}
        {STATUS === 'airports' && (
          <GeopointsController {...sharedProps} TYPX={'airport'} />
        )}
        {STATUS === 'stations' && (
          <GeopointsController {...sharedProps} TYPX={'station'} />
        )}
        {STATUS === 'ports' && (
          <GeopointsController {...sharedProps} TYPX={'port'} />
        )}
        {STATUS === 'places' && (
          <GeopointsController {...sharedProps} TYPX={'place'} />
        )}
        {STATUS === 'parks' && (
          <GeopointsController {...sharedProps} TYPX={'park'} />
        )}
        {STATUS === 'geopoints' && (
          <GeopointsController {...sharedProps} />
        )}
        {STATUS === 'countries' && (
          <CountriesController {...sharedProps} />
        )}
        {STATUS === 'custom' && (
          <CustomController {...sharedProps} />
        )}

        {STATUS === 'seo' && (
          <CitiesController {...sharedProps} />
        )}

        <div className='hidden md:block h-full' style={{ width: (PROVIDER || POSITION) ? '60%' : '100%' }}>
          <AdminMapCluster
            {...sharedProps}
            Providers={STORES_POINTS || []}
            Cities={CITIES_POINTS || []}
            Geopoints={GEOPOINTS_POINTS || []}
            mapRef={mapRef}

          />
        </div>

        {PROVIDER && (
          <ProviderController {...sharedProps} />
        )}

        {POSITION && (
          <LogisticController {...sharedProps} />
        )}

      </div>

      {isMapLoading && (
        <div className="fixed w-screen h-screen top-0 left-0 z-[500] flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]">
          <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
          <div className="mx-auto w-full max-w-[400px] px-4 sm:px-6 lg:px-8 pb-10 pt-10 bg-white rounded-2xl relative z-20">
            <div className='w-full h-[400px]] flex flex-col items-center justify-center'>
              <img className='animate-spin w-[40px] h-[40px] mb-2' src='/assets/img/loader-provider.png' alt='' />
              Loading Map...
            </div>
          </div>
        </div>
      )}

      {isMapFiltering && (
        <StoreFilter {...sharedProps}
          onClose={() => setMapFiltering(false)}
          isMap={true}

        />
      )}

      {isLocationsFiltering && (
        <LocationFilter {...sharedProps}
          onClose={() => setLocationsFiltering(false)}
          isMap={true}

        />
      )}

      {isGeopointsFiltering && (
        <GeopointFilter {...sharedProps}
          onClose={() => setGeopointsFiltering(false)}
          isMap={true}


        />
      )}
    </>

  )


}

//Search + Settings
const SearchController = (props) => {

  const { setStatus, history } = props;


  return (
    <div className='bg-white h-full' style={{ width: 450, overflow: 'auto', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px' }}>

      <div className='w-full bg-white flex justify-center items-center p-4 border-b'>
        <div className='font-bold text-lg'>Map Search</div>
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=stores&sort=updatedAt&dir=desc&country=all&range=100&status=true&approval=false');
      }}>
        <div className='flex'>
          <StorefrontOutline className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Stores
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=cities&sort=points&dir=desc&country=all&range=100&status=true');
      }}>
        <div className='flex'>
          <LocationOutline className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Cities
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=airports&sort=updatedAt&dir=desc&country=all&range=100&status=true');
      }}>
        <div className='flex'>
          <AirplaneOutline className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Airports
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=stations&sort=updatedAt&dir=desc&country=all&range=100&status=true');
      }}>
        <div className='flex'>
          <TrainOutline className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Train Stations
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=ports&sort=updatedAt&dir=desc&country=all&range=100&status=true');
      }}>
        <div className='flex'>
          <BoatOutline className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Ports
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=places&sort=updatedAt&dir=desc&country=all&range=100&status=true');
      }}>
        <div className='flex'>
          <BusinessOutline className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Places
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=parks&sort=updatedAt&dir=desc&country=all&range=100&status=true');
      }}>
        <div className='flex'>
          <ParkOutlined className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Parks
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=geopoints&sort=updatedAt&dir=desc&country=all&range=100&status=true');
      }}>
        <div className='flex'>
          <MapOutlined className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Geopoints
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>


      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=countries&sort=updatedAt&dir=desc&country=all&range=100&status=true');
      }}>
        <div className='flex'>
          <GlobeOutline className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Countries
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
        history.push('?search=custom');
      }}>
        <div className='flex'>
          <DeliveryDining className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              Custom Search
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>

      <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
         history.push('?search=seo&sort=points&dir=desc&country=all&range=100&status=true');
      }}>
        <div className='flex'>
          <Google className="shrink-0 mr-4 w-[25px] h-[25px]" />
          <div className="w-full">
            <div className="font-bold text-base mb-1 flex justify-between">
              SEO Manager
            </div>
          </div>
        </div>
        <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
      </div>




    </div>
  )
}


//Controllers
const StoresController = (props) => {

  const { TRANSLATE, history, match, location, onZoomToMarker } = props;

  const { LANGUAGE, onFetchStores, setProvider, onSearchStores, isMapSearching, setMapFiltering, setMapSorting, setInput } = props;

  const { STORES, setPosition, INPUT } = props;

  useEffect(() => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    if (!JWT) {
      return history.replace('/login');
    }
    else {

      var routeParams = GetRouteSearchParams(location.search);
      var routeSearch = routeParams.search || '';

      if (routeSearch) {
        onFetchStores({
          ...routeParams
        });
      }
    }

    window.scrollTo(0, 0);

  }, [match.params.page, location.search]);

  useEffect(() => {
    console.log("STORE CALLED")
  }, [STORES])

  var routeParams = GetRouteSearchParams(location.search);
  var routeCountry = routeParams.country || '';

  return (
    <div className='bg-white h-full' style={{ width: 450, overflow: 'auto', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px' }}>
      {/* <pre>
              {JSON.stringify(STORES, null, 2)}
            </pre> */}

      <div className='w-full bg-white flex justify-between items-center p-4 border-b'>
        <div className='flex w-full'>
          <ArrowBackIos className='cursor-pointer' onClick={() => history.push(`/admin/map`)} />
          <input placeholder='Search stores by name...' className='w-full'
            value={INPUT}
            onChange={(e) => {
              setInput(e.target.value)
              //onSearchStores(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                onSearchStores(e.target.value);
              }
            }}

          />
        </div>

        {isMapSearching ? (
          <div>
            <HourglassOutline className='cursor-pointer animate-spin' />
          </div>
        ) : (
          <div>
            <FilterOutline className='cursor-pointer' onClick={() => setMapFiltering(true)} />
          </div>
        )}
      </div>

      {STORES.map((marker, index) => (
        <StoreItem key={index}
          store={marker}
          index={index}
          TRANSLATE={TRANSLATE}
          LANGUAGE={LANGUAGE}
          onSelectProvider={() => {

            const geocode = marker.geocode || {};
            const latitude = geocode.latitude || 0;
            const longitude = geocode.longitude || 0;

            setPosition(null);
            setProvider(marker);
            onZoomToMarker(latitude, longitude)
          }}
        />
      ))}

      <div className='h-full'></div>

      <div className='w-full bg-white flex items-center p-4 border-t sticky bottom-0'>
        {(routeCountry && routeCountry !== 'all') ? (
          <ReactCountryFlag countryCode={routeCountry.toLowerCase()} className='mr-2 w-[25px] h-[25px]' svg />
        ) : (
          <GlobeOutline className="mr-2" />
        )}
        <div className='flex flex-col'>
          <div className='text-sm font-bold'>
            Babonbo Stores found {STORES.length}
          </div>
          <div className='text-xs font-bold text-zinc-500'>
            Sorting by updatedAt
          </div>
        </div>

      </div>

    </div>
  )
}
const CitiesController = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const { LANGUAGE, onFetchCities, setStatus, onSearchCities, isMapSearching, setLocationsFiltering, setMapSorting, setInput } = props;

  const { CITIES, TOTAL, INPUT } = props;

  useEffect(() => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    if (!JWT) {
      return history.replace('/login');
    }
    else {

      var routeParams = GetRouteSearchParams(location.search);
      var routeSearch = routeParams.search || '';

      if (routeSearch) {
        onFetchCities({
          ...routeParams
        });
      }

    }

    window.scrollTo(0, 0);

  }, [match.params.page, location.search]);

  useEffect(() => {
    console.log("CITY CALLED")
  }, [CITIES])

  var routeParams = GetRouteSearchParams(location.search);
  var routeCountry = routeParams.country || '';

  return (
    <div className='bg-white h-full' style={{ width: 450, overflow: 'auto', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px' }}>
      {/* <pre>
        {JSON.stringify(CITIES, null, 2)}
      </pre> */}

      <div className='w-full bg-white flex justify-between items-center p-4 border-b'>
        <div className='flex w-full'>
          <ArrowBackIos className='cursor-pointer' onClick={() => history.push(`/admin/map`)} />
          <input placeholder='Search city by name...' className='w-full'
            value={INPUT}
            onChange={(e) => {
              setInput(e.target.value)
              //onSearchStores(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                onSearchCities(e.target.value);
              }
            }}

          />
        </div>

        {isMapSearching ? (
          <div>
            <HourglassOutline className='cursor-pointer animate-spin' />
          </div>
        ) : (
          <div>
            <FilterOutline className='cursor-pointer' onClick={() => setLocationsFiltering(true)} />
          </div>
        )}
      </div>

      {CITIES.map((marker, index) => (
        <CityItem key={index}
          city={marker}
          index={index}
          {...props}
        />
      ))}

      <div className='h-full'></div>

      <div className='w-full bg-white flex items-center p-4 border-t sticky bottom-0'>
        {(routeCountry && routeCountry !== 'all') ? (
          <ReactCountryFlag countryCode={routeCountry.toLowerCase()} className='mr-2 w-[25px] h-[25px]' svg />
        ) : (
          <GlobeOutline className="mr-2" />
        )}
        <div className='flex flex-col'>
          <div className='text-sm font-bold'>
            Babonbo Cities found {CITIES.length}
          </div>
          <div className='text-xs font-bold text-zinc-500'>
            Sorting by updatedAt
          </div>
        </div>

      </div>

    </div>
  )
}
const GeopointsController = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const { STATUS, onFetchGeopoints, TYPX, onSearchGeopoints, isMapSearching, setGeopointsFiltering, setInput } = props;

  const { GEOPOINTS, setProvider, setPosition, onZoomToMarker, INPUT } = props;

  useEffect(() => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    if (!JWT) {
      return history.replace('/login');
    }
    else {

      var routeParams = GetRouteSearchParams(location.search);
      var routeSearch = routeParams.search || '';

      if (routeSearch) {
        onFetchGeopoints({
          ...routeParams,
          type: TYPX
        });
      }

    }

    window.scrollTo(0, 0);

  }, [match.params.page, location.search]);

  useEffect(() => {
    console.log("GEOPOINT CALLED")
  }, [GEOPOINTS])

  var routeParams = GetRouteSearchParams(location.search);
  var routeCountry = routeParams.country || '';

  return (
    <div className='bg-white h-full' style={{ width: 450, overflow: 'auto', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px' }}>
      {/* <pre>
        {JSON.stringify(CITIES, null, 2)}
      </pre> */}

      <div className='w-full bg-white flex justify-between items-center p-4 border-b'>
        <div className='flex w-full'>
          <ArrowBackIos className='cursor-pointer' onClick={() => history.push(`/admin/map`)} />
          <input placeholder={`Search ${STATUS} by name...`} className='w-full'
            value={INPUT}
            onChange={(e) => {
              setInput(e.target.value)
              //onSearchStores(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                onSearchGeopoints(e.target.value);
              }
            }}

          />
        </div>

        {isMapSearching ? (
          <div>
            <HourglassOutline className='cursor-pointer animate-spin' />
          </div>
        ) : (
          <div>
            <FilterOutline className='cursor-pointer' onClick={() => setGeopointsFiltering(true)} />
          </div>
        )}
      </div>

      {/* <pre>
        {JSON.stringify(GEOPOINTS, null, 2)}
      </pre> */}

      {GEOPOINTS.map((marker, index) => (
        <GeostoreItem key={index}
          geopoint={marker}
          index={index}
          {...props}
          onSelectPosition={() => {

            const geocode = marker.geocode || {};
            const latitude = geocode.latitude || 0;
            const longitude = geocode.longitude || 0;

            setProvider(null);
            setPosition(marker)
            onZoomToMarker(latitude, longitude)
          }}
        />
      ))}

      {GEOPOINTS.length < 10 ? (
        <div className='h-full'></div>
      ) : (
        <div style={{ height: 200 }}></div>
      )}

      <div className='w-full bg-white flex items-center p-4 border-t sticky bottom-0'>
        {(routeCountry && routeCountry !== 'all') ? (
          <ReactCountryFlag countryCode={routeCountry.toLowerCase()} className='mr-2 w-[25px] h-[25px]' svg />
        ) : (
          <GlobeOutline className="mr-2" />
        )}
        <div className='flex flex-col'>
          <div className='text-sm font-bold'>
            Babonbo {STATUS} found {GEOPOINTS.length}
          </div>
          <div className='text-xs font-bold text-zinc-500'>
            Sorting by updatedAt
          </div>
        </div>

      </div>

    </div>
  )
}
const CountriesController = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const { STATUS, onFetchGeopoints, TYPX, onSearchGeopoints, isMapSearching, setGeopointsFiltering, setInput } = props;

  const { INPUT } = props;

  const [COUNTRIES, setCountries] = useState([]);

  useEffect(() => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    if (!JWT) {
      return history.replace('/login');
    }
    else {
      setCountries(Countries_EN);
    }

    window.scrollTo(0, 0);

  }, [match.params.page, location.search]);


  const onSearchCountries = (query) => {
    query = query.toLowerCase();
    setCountries(Countries_EN.filter(country => country.label.toLowerCase().includes(query)));
  }

  return (
    <div className='bg-white h-full' style={{ width: 450, overflow: 'auto', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px' }}>
      {/* <pre>
        {JSON.stringify(CITIES, null, 2)}
      </pre> */}

      <div className='w-full bg-white flex justify-between items-center p-4 border-b'>
        <div className='flex w-full'>
          <ArrowBackIos className='cursor-pointer' onClick={() => history.push(`/admin/map`)} />
          <input placeholder={`Search Country by name...`} className='w-full'
            value={INPUT}
            onChange={(e) => {
              setInput(e.target.value)
              //onSearchStores(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                onSearchCountries(e.target.value);
              }
            }}

          />
        </div>

        {isMapSearching ? (
          <div>
            <HourglassOutline className='cursor-pointer animate-spin' />
          </div>
        ) : (
          <div>
            <FilterOutline className='cursor-pointer' />
          </div>
        )}
      </div>

      {/* <pre>
        {JSON.stringify(GEOPOINTS, null, 2)}
      </pre> */}

      {COUNTRIES.map((marker, index) => (
        <CountryItem key={index}
          country={marker}
          index={index}
          {...props}
        />
      ))}

      {COUNTRIES.length < 10 ? (
        <div className='h-full'></div>
      ) : (
        <div style={{ height: 200 }}></div>
      )}

      <div className='w-full bg-white flex items-center p-4 border-t sticky bottom-0'>
        <GlobeOutline className="mr-2" />
        <div className='flex flex-col'>
          <div className='text-sm font-bold'>
            Babonbo {STATUS} found {COUNTRIES.length}
          </div>
          <div className='text-xs font-bold text-zinc-500'>
            Sorting by updatedAt
          </div>
        </div>

      </div>

    </div>
  )
}
const CustomController = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const { STATUS, onFetchGeopoints, TYPX, onSearchGeopoints, isMapSearching, setGeopointsFiltering, setInput } = props;

  const { INPUT } = props;

  const [CHECKIN, setCheckin] = useState(moment().format('DD/MM/YYYY'));
  const [CHECKOUT, setCheckout] = useState(moment().add(3, 'days').format('DD/MM/YYYY'));
  const [CITY, setCity] = useState(null);

  const [PICKUP, setPickup] = useState(null);
  const [DROPOFF, setDropoff] = useState(null);

  const [START_DATE, setStartDate] = useState(null);
  const [END_DATE, setEndDate] = useState(null);

  const [CATEGORIES, setCategories] = useState([]);

  const [DATE_RANGE, setDateRange] = useState({
    from: null,
    to: null,
  });


  useEffect(() => {

    const newCheckin = moment(CHECKIN, "DD-MM-YYYY");
    const newCheckout = moment(CHECKOUT, "DD-MM-YYYY");

    const newStart = {
      day: parseInt(newCheckin.format("D")),
      month: parseInt(newCheckin.format("M")),
      year: parseInt(newCheckin.format("YYYY")),
    };

    const newEnd = {
      day: parseInt(newCheckout.format("D")),
      month: parseInt(newCheckout.format("M")),
      year: parseInt(newCheckout.format("YYYY")),
    };

    setStartDate(newStart);
    setEndDate(newEnd);
    setDateRange({
      from: newStart,
      to: newEnd,
    });

  }, []);

  const onSelectSearch = () => {

    if (!CITY) {
      return swal(
        "Missing City",
        "Where would you like to rent your gear ?",
        "warning"
      );
    }

    if (!START_DATE) {
      return swal("Missing Checkin", "Please insert a checkin date", "warning");
    }

    if (!END_DATE) {
      return swal(
        "Missing Checkout",
        "Please insert a checkout date",
        "warning"
      );
    }

    if (!PICKUP) {
      return swal(
        "Missing Delivery Address",
        "Please insert the address for the baby gear delivery",
        "warning"
      );
    }

    if (!DROPOFF) {
      return swal(
        "Missing Return Address",
        "Please insert the address for the baby gear return",
        "warning"
      );
    }

    //Format Dates
    const FORMATTED_START = formatDateInputStart(START_DATE);
    const FORMATTED_END = formatDateInputEnd(END_DATE);

    //Format Delivery
    const FOUND_PICKUP = { city: PICKUP.address.city || PICKUP.address.county, input: PICKUP.display_name, boundingBox: JSON.stringify(PICKUP.boundingbox) };
    const FOUND_DROPOFF = { city: DROPOFF.address.city || PICKUP.address.county, input: DROPOFF.display_name, boundingBox: JSON.stringify(DROPOFF.boundingbox) };

    console.log("FOUND_PICKUP ===> ", FOUND_PICKUP);
    console.log("FOUND_DROPOFF ===> ", FOUND_DROPOFF);

    //Encode Delivery
    const ENCODED_PICKUP = new URLSearchParams(FOUND_PICKUP).toString();
    const ENCRYPTED_PICKUP = window.btoa(ENCODED_PICKUP);

    const ENCODED_DROPOFF = new URLSearchParams(FOUND_DROPOFF).toString();
    const ENCRYPTED_DROPOFF = window.btoa(ENCODED_DROPOFF);

    //Generate URL
    let fixedPath = '?id=' + CITY.id;
    let new_url = fixedPath + "&checkin=" + FORMATTED_START + "&checkout=" + FORMATTED_END;

    new_url =
      new_url +
      "&picklat=" +
      PICKUP.latitude +
      "&picklon=" +
      PICKUP.longitude +
      "&droplat=" +
      DROPOFF.latitude +
      "&droplon=" +
      DROPOFF.longitude;

    if (ENCRYPTED_PICKUP) {
      new_url = new_url + "&pickadd=" + ENCRYPTED_PICKUP;
    }

    if (ENCRYPTED_DROPOFF) {
      new_url = new_url + "&dropadd=" + ENCRYPTED_DROPOFF;
    }

    history.push(new_url);

  };

  const onSelectCity = (city) => {
    setCity(city);
  };

  const onFromDateChange = (date) => {
    setStartDate(date);
    setEndDate(null);
    setDateRange((previous) => ({
      ...previous,
      from: date,
      to: null,
    }));
  };

  const onToDateChange = (date) => {
    if (!START_DATE) {
      alert("You need to select a valid checkin date");
      // alertify
      // 	.delay(4000)
      // 	.closeLogOnClick(true)
      // 	.error('لطفا ابتدا تاریخ ورود را انتخاب کنید');
      return false;
    }

    setStartDate(date.from);
    setEndDate(date.to);
    setDateRange(date);
  };

  const formatDateInputStart = () => {
    if (!START_DATE) return "";

    const year = START_DATE.year.toString();
    const month = START_DATE.month.toString().padStart(2, 0);
    const day = START_DATE.day.toString().padStart(2, 0);
    return `${day}/${month}/${year}`;
  };

  const formatDateInputEnd = () => {
    if (!DATE_RANGE.to) return "";
    const toDate = DATE_RANGE.to;
    const year = toDate.year.toString();
    const month = toDate.month.toString().padStart(2, 0);
    const day = toDate.day.toString().padStart(2, 0);
    return `${day}/${month}/${year}`;
  };

  const search_country = CITY ? CITY.country : "";


  return (
    <div className='bg-white h-full' style={{ width: 500, overflow: 'auto', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px' }}>
      {/* <pre>
        {JSON.stringify(CITIES, null, 2)}
      </pre> */}

      <div className='w-full bg-white flex justify-between items-center p-4 border-b'>
        <div className='flex w-full'>
          <ArrowBackIos className='cursor-pointer' onClick={() => history.push(`/admin/map`)} />
          <div className='font-bold text-lg'>Custom Search</div>

        </div>

      </div>

      {/* <pre>
        {JSON.stringify(GEOPOINTS, null, 2)}
      </pre> */}

      <div className="flex flex-col justify-between w-full p-4">

        <SearchPicker
          {...props}
          onSelectCity={onSelectCity}
          search_country={search_country}
          zIndex={99}
        />

        <div className="w-full flex flex-col mb-2">
          <div className='input-group m-0'>
            <label>
              {TRANSLATE.wd_rental_period}
            </label>
          </div>
          <div className='w-full flex items-center justify-between gap-x-2'>
            <div className="input-group w-1/2 with-icon m-0">
              <img
                className="input-icon z-[900]"
                src="/assets/img/calendar.svg"
                alt=""
              />
              <span className="inner-label z-50">
                {TRANSLATE.wd_start}
              </span>
              <DatePicker
                className={"pt-0"}
                minimumDate={utils().getToday()}
                value={START_DATE}
                onChange={onFromDateChange}
                formatInputText={formatDateInputStart}
                colorPrimary="#FC6170"
                colorPrimaryLight="#FFE9EB"
                calendarPopperPosition="bottom"
              />
            </div>
            <div className="input-group w-1/2 with-icon m-0">
              <img
                className="input-icon z-[900]"
                src="/assets/img/calendar.svg"
                alt=""
              />
              <span className="inner-label z-50">{TRANSLATE.wd_end}</span>
              <DatePicker
                className={"pt-0"}
                minimumDate={utils().getToday()}
                value={DATE_RANGE}
                onChange={onToDateChange}
                formatInputText={formatDateInputEnd}
                colorPrimary="#FC6170"
                colorPrimaryLight="#FFE9EB"
                calendarPopperPosition="bottom"
              />
            </div>
          </div>

        </div>

        <div className="w-full flex flex-col mt-4">
          <div className='input-group m-0'>
            <label>
              Product Categories
            </label>
          </div>

          {Filter_Array.map((cat, index) => (
            <CategoryItem key={index}
              category={cat}
              index={index}
              isActive={CATEGORIES.includes(cat.code)}
              onSelectCategory={() => {
                if (CATEGORIES.includes(cat.code)) {
                  setCategories(CATEGORIES.filter((item) => item.code !== cat.code))
                }
                else {
                  setCategories(prev => [...prev, cat.code]);
                }
              }}
              {...props}
            />
          ))}



        </div>



      </div>

      <div style={{ height: 200 }}></div>


      <div className='w-full bg-white flex items-center p-4 border-t sticky bottom-0'>
        <div className="bg-babonbo-100 text-white rounded-lg flex items-center justify-center p-2 m-4 mt-2 cursor-pointer">
          <Search color={'#fff'} className="mr-1" /> Search Query
        </div>
      </div>

    </div>
  )
}

//Details
const ProviderController = (props) => {

  const { PROVIDER, onZoomToMarker } = props;

  const [TAB, setTab] = useState('provider');

  return (
    <div className='bg-white h-full' style={{ width: 450, overflow: 'auto', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px' }}>

      <div className='w-full bg-white flex items-center justify-between border-t sticky top-0'>

        <div style={{ width: '33%', height: 40 }} className={`flex items-center justify-center text-xs cursor-pointer ${(TAB === 'provider' ? 'bg-bagreen-100 px-2 text-white' : 'bg-zinc-100 text-black')}`} onClick={() => setTab('provider')}>
          Provider
        </div>
        <div style={{ width: '33%', height: 40 }} className={`flex items-center justify-center text-xs cursor-pointer ${(TAB === 'locations' ? 'bg-bagreen-100 px-2 text-white' : 'bg-zinc-100 text-black')}`} onClick={() => setTab('locations')}>
          Pickup Locations
        </div>
        <div style={{ width: '33%', height: 40 }} className={`flex items-center justify-center text-xs cursor-pointer ${(TAB === 'products' ? 'bg-bagreen-100 px-2 text-white' : 'bg-zinc-100 text-black')}`} onClick={() => setTab('products')}>
          Products
        </div>

      </div>

      <div className='w-full flex items-center justify-between rounded-full bg-zinc-100 mt-4 p-4'>
        <div className='flex'>
          <img src={PROVIDER.img} alt='' className='w-[30px] h-[30px] rounded-full shrink-0 mr-2' />
          <div className='font-bold text-sm'>
            {PROVIDER.name}
          </div>
        </div>
        <div className='flex flex-col items-end'>
          <StarReview rate={PROVIDER.rating} size={20} isHidden />
          <div className='text-xs'>
            {parseFloat(PROVIDER.rating).toFixed(1)} / {parseInt(PROVIDER.reviews)} Reviews
          </div>
        </div>
      </div>

      {TAB === 'provider' && (
        <>
          <div className='w-full flex flex-col items-center justify-center p-4'>
            <img src={PROVIDER.img} alt='' className='w-full rounded-md shrink-0 mr-2' />
            <div className='font-bold text-lg mt-4 text-center'>
              {PROVIDER.name}
            </div>
            <div className='text-sm font-normal mt-2'>
              {PROVIDER.about}
            </div>
          </div>
          <div className="flex w-full gap-x-4 px-4">
            <div className="border border-zinc-200 rounded-lg p-4 flex items-center mb-4 w-1/2 justify-center font-medium text-sm">
              <img className="w-[45px] h-[45px] mr-4" src="/assets/img/quality-product.svg" alt='' />
              Quality Products
            </div>
            <div className="border border-zinc-200 rounded-lg p-4 flex items-center mb-4 w-1/2 justify-center font-medium text-sm">
              <img className="w-[45px] h-[45px] mr-4" src="/assets/img/clean.svg" alt='' />
              Cleanliness Standards
            </div>
          </div>
          <div className="flex w-full gap-x-4 px-4">
            <div className="border border-zinc-200 rounded-lg p-4 flex items-center mb-4 w-1/2 justify-center font-medium text-sm">
              <img className="w-[45px] h-[45px] mr-4" src="/assets/img/premium-support.svg" alt='' />
              Premium Support
            </div>
            <div className="border border-zinc-200 rounded-lg p-4 flex items-center mb-4 w-1/2 justify-center font-medium text-sm">
              <img className="w-[45px] h-[45px] mr-4" src="/assets/img/convenient-delivery.svg" alt='' />
              Convenient Delivery
            </div>
          </div>
        </>
      )}

      {TAB === 'locations' && (
        <DeliveryController {...props}
          PROVIDER={PROVIDER}
        />
      )}


      {TAB === 'products' && (
        <ProductsController {...props}
          provider_id={PROVIDER.id}
          currency={PROVIDER.currency || 'eur'}
        />
      )}


    </div >
  )
}
const DeliveryController = (props) => {

  const { PROVIDER, onZoomToMarker } = props;

  const DELIVERY = PROVIDER.delivery || [];

  const FREE = DELIVERY.filter((item) => parseFloat(item.cost) == 0);
  const CITIES = DELIVERY.filter((item) => item.type === 'city');
  const AIRPORTS = DELIVERY.filter((item) => item.type === 'airport');
  const STATIONS = DELIVERY.filter((item) => item.type === 'station');
  const PORTS = DELIVERY.filter((item) => item.type === 'port');
  const OTHER = DELIVERY.filter((item) => (item.type !== 'city') && (item.type !== 'airport') && (item.type !== 'station') && (item.type !== 'port'))

  return (
    <div className='flex flex-col w-full'>

      {FREE.length > 0 && (
        <div className='w-full mt-10 mb-2 px-4'>
          <div className='font-bold w-full'>
            Free Delivery
          </div>
        </div>
      )}
      {FREE.map((item, index) => {

        const geocode = item.geocode || {};
        const latitude = geocode.latitude || 0;
        const longitude = geocode.longitude || 0;

        let logo = 'babonbo_marker.png';
        let type = item.type;

        if (type === 'airport') {
          logo = 'babonbo_marker_airport.png';
        }
        else if (type === 'station') {
          logo = 'babonbo_marker_station.png';
        }
        else if (type === 'port') {
          logo = 'babonbo_marker_port.png';
        }

        return (
          <div className='flex items-center justify-between border-b p-4' key={index}>
            <div className='flex w-full'>

              <img src={`/assets/img/${logo}`} className='w-[30px] h-[30px] mr-1' />
              <div className='flex flex-col'>
                <div className='font-bold text-sm'>{item.name}</div>
                {parseFloat(item.cost) == 0 ? (
                  <div className='rounded-full bg-green-500 text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    Free delivery
                  </div>
                ) : (
                  <div className='rounded-full bg-black text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    {numberWithCommas(parseFloat(item.cost), PROVIDER.currency || 'eur')}
                  </div>
                )}
              </div>
            </div>
            <div className='flex text-xs cursor-pointer' onClick={() => onZoomToMarker(latitude, longitude)}>
              View Location <ArrowForwardCircleOutline className='ml-2' />
            </div>
          </div>
        )
      })}

      {AIRPORTS.length > 0 && (
        <div className='w-full mt-10 mb-2 px-4'>
          <div className='font-bold w-full'>
            Deliver to Airports
          </div>
        </div>
      )}
      {AIRPORTS.map((item, index) => {

        const geocode = item.geocode || {};
        const latitude = geocode.latitude || 0;
        const longitude = geocode.longitude || 0;

        return (
          <div className='flex items-center justify-between border-b p-4' key={index}>
            <div className='flex w-full'>
              <img src='/assets/img/babonbo_marker_airport.png' className='w-[30px] h-[30px] mr-1' />
              <div className='flex flex-col'>
                <div className='font-bold text-sm'>{item.name}</div>
                {parseFloat(item.cost) == 0 ? (
                  <div className='rounded-full bg-green-500 text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    Free delivery
                  </div>
                ) : (
                  <div className='rounded-full bg-black text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    {numberWithCommas(parseFloat(item.cost), PROVIDER.currency || 'eur')}
                  </div>
                )}
              </div>
            </div>
            <div className='flex text-xs cursor-pointer' onClick={() => onZoomToMarker(latitude, longitude)}>
              View Location <ArrowForwardCircleOutline className='ml-2' />
            </div>
          </div>
        )
      })}

      {STATIONS.length > 0 && (
        <div className='w-full mt-10 mb-2 px-4'>
          <div className='font-bold w-full'>
            Deliver to Train Stations
          </div>
        </div>
      )}
      {STATIONS.map((item, index) => {

        const geocode = item.geocode || {};
        const latitude = geocode.latitude || 0;
        const longitude = geocode.longitude || 0;

        return (
          <div className='flex items-center justify-between border-b p-4' key={index}>
            <div className='flex w-full'>
              <img src='/assets/img/babonbo_marker_station.png' className='w-[30px] h-[30px] mr-1' />
              <div className='flex flex-col'>
                <div className='font-bold text-sm'>{item.name}</div>
                {parseFloat(item.cost) == 0 ? (
                  <div className='rounded-full bg-green-500 text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    Free delivery
                  </div>
                ) : (
                  <div className='rounded-full bg-black text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    {numberWithCommas(parseFloat(item.cost), PROVIDER.currency || 'eur')}
                  </div>
                )}
              </div>
            </div>
            <div className='flex text-xs cursor-pointer' onClick={() => onZoomToMarker(latitude, longitude)}>
              View Location <ArrowForwardCircleOutline className='ml-2' />
            </div>
          </div>
        )
      })}

      {PORTS.length > 0 && (
        <div className='w-full mt-10 mb-2 px-4'>
          <div className='font-bold w-full'>
            Deliver to Ports
          </div>
        </div>
      )}
      {PORTS.map((item, index) => {

        const geocode = item.geocode || {};
        const latitude = geocode.latitude || 0;
        const longitude = geocode.longitude || 0;

        return (
          <div className='flex items-center justify-between border-b p-4' key={index}>
            <div className='flex w-full'>
              <img src='/assets/img/babonbo_marker_port.png' className='w-[30px] h-[30px] mr-1' />
              <div className='flex flex-col'>
                <div className='font-bold text-sm'>{item.name}</div>
                {parseFloat(item.cost) == 0 ? (
                  <div className='rounded-full bg-green-500 text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    Free delivery
                  </div>
                ) : (
                  <div className='rounded-full bg-black text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    {numberWithCommas(parseFloat(item.cost), PROVIDER.currency || 'eur')}
                  </div>
                )}
              </div>
            </div>
            <div className='flex text-xs cursor-pointer' onClick={() => onZoomToMarker(latitude, longitude)}>
              View Location <ArrowForwardCircleOutline className='ml-2' />
            </div>
          </div>
        )
      })}

      {CITIES.length > 0 && (
        <div className='w-full mt-10 mb-2 px-4'>
          <div className='font-bold w-full'>
            Deliver to Cities
          </div>
        </div>
      )}
      {CITIES.map((item, index) => {

        const geocode = item.geocode || {};
        const latitude = geocode.latitude || 0;
        const longitude = geocode.longitude || 0;

        return (
          <div className='flex items-center justify-between border-b p-4' key={index}>
            <div className='flex w-full'>
              <img src='/assets/img/babonbo_marker.png' className='w-[30px] h-[30px] mr-1' />
              <div className='flex flex-col'>
                <div className='font-bold text-sm'>{item.name}</div>
                {parseFloat(item.cost) == 0 ? (
                  <div className='rounded-full bg-green-500 text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    Free delivery
                  </div>
                ) : (
                  <div className='rounded-full bg-black text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    {numberWithCommas(parseFloat(item.cost), PROVIDER.currency || 'eur')}
                  </div>
                )}
              </div>
            </div>
            <div className='flex text-xs cursor-pointer' onClick={() => onZoomToMarker(latitude, longitude)}>
              View Location <ArrowForwardCircleOutline className='ml-2' />
            </div>
          </div>
        )
      })}

      {OTHER.length > 0 && (
        <div className='w-full mt-10 mb-2 px-4'>
          <div className='font-bold w-full'>
            Deliver to Other
          </div>
        </div>
      )}
      {OTHER.map((item, index) => {

        const geocode = item.geocode || {};
        const latitude = geocode.latitude || 0;
        const longitude = geocode.longitude || 0;

        return (
          <div className='flex items-center justify-between border-b p-4' key={index}>
            <div className='flex w-full'>
              <img src='/assets/img/babonbo_marker.png' className='w-[30px] h-[30px] mr-1' />
              <div className='flex flex-col'>
                <div className='font-bold text-sm'>{item.name}</div>
                {parseFloat(item.cost) == 0 ? (
                  <div className='rounded-full bg-green-500 text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    Free delivery
                  </div>
                ) : (
                  <div className='rounded-full bg-black text-xs text-white px-2' style={{ width: 'fit-content' }}>
                    {numberWithCommas(parseFloat(item.cost), PROVIDER.currency || 'eur')}
                  </div>
                )}
              </div>
            </div>
            <div className='flex text-xs cursor-pointer' onClick={() => onZoomToMarker(latitude, longitude)}>
              View Location <ArrowForwardCircleOutline className='ml-2' />
            </div>
          </div>
        )
      })}

    </div>
  )
}
const ProductsController = (props) => {

  const { provider_id, POSITION, currency, TRANSLATE, LANGUAGE } = props;

  const [isLoading, setLoading] = useState(true);
  const [PRODUCTS, setProducts] = useState([]);


  useEffect(() => {

    if (provider_id) {
      onFetchProducts(provider_id);
    }

    if (POSITION) {

      let Stores = POSITION.stores.map((item) => item.id);
      //alert('STORE ===> '+JSON.stringify(Stores, null, 2))
      onFetchProducts(null, Stores);
    }

  }, [provider_id, POSITION]);

  const onFetchProducts = async (provider_id, Stores) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setLoading(true);

    try {

      var FORM = {}

      if (provider_id) {
        FORM = { id: provider_id }
      }

      if (Stores) {
        FORM = { ids: Stores }
      }

      var DATA_RESPONSE = await postApiPrivate('/admin/map/products', FORM, JWT);


      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
        setLoading(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      setProducts(DATA_RESPONSE.data);
      setLoading(false);

      window.scrollTo(0, 0)


    }
    catch (e) {

      console.log("CREATING ===> ", e);
      setLoading(false);
    }

  }

  if (isLoading) {

    return (
      <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
        <div className='w-full flex items-center justify-center'>
          <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
        </div>
      </div>
    )
  }

  return (
    <div className='flex w-full flex-col'>

      <div className='w-full mt-10 mb-4 px-4'>
        <div className='font-bold w-full'>{PRODUCTS.length} Products Available</div>
      </div>

      {PRODUCTS.map((product, index) => (
        <ProductItem key={index}
          product={product}
          currency={currency}
          index={index}
          TRANSLATE={TRANSLATE}
          LANGUAGE={LANGUAGE}
          onSelectProduct={() => window.open(`https://www.babonbo.com/en/${onFormatUrl(product.name)}/bp/${product.id}`)}


        />
      ))}

    </div>
  )
}
const LogisticController = (props) => {

  const { POSITION, history } = props;

  const [TAB, setTab] = useState('info');

  let costs = POSITION.costs || [];
  let firstCost = costs[0] ? parseFloat(costs[0]) : 0;
  let secondCost = costs[costs.length - 1] ? parseFloat(costs[costs.length - 1]) : 0;

  return (
    <div className='bg-white h-full' style={{ width: 450, overflow: 'auto', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px' }}>

      <div className='w-full bg-white flex items-center justify-between border-t sticky top-0'>

        <div style={{ width: '33%', height: 40 }} className={`flex items-center justify-center text-xs cursor-pointer ${(TAB === 'info' ? 'bg-bagreen-100 px-2 text-white' : 'bg-zinc-100 text-black')}`} onClick={() => setTab('info')}>
          Point Info
        </div>
        <div style={{ width: '33%', height: 40 }} className={`flex items-center justify-center text-xs cursor-pointer ${(TAB === 'providers' ? 'bg-bagreen-100 px-2 text-white' : 'bg-zinc-100 text-black')}`} onClick={() => setTab('providers')}>
          Providers
        </div>
        <div style={{ width: '33%', height: 40 }} className={`flex items-center justify-center text-xs cursor-pointer ${(TAB === 'products' ? 'bg-bagreen-100 px-2 text-white' : 'bg-zinc-100 text-black')}`} onClick={() => setTab('products')}>
          Products
        </div>

      </div>

      <div className='w-full flex items-center justify-between rounded-full bg-zinc-100 mt-4 p-4'>
        <div className='flex'>
          <img src={POSITION.image} alt='' className='w-[30px] h-[30px] rounded-full shrink-0 mr-2' />
          <div>
            <div className='font-bold text-sm'>
              {POSITION.name}
            </div>
            <div className='font-semibold text-xs'>
              {POSITION.city}
            </div>
          </div>
        </div>
        {/* <div className='flex flex-col items-end'>
          <StarReview rate={PROVIDER.rating} size={20} isHidden />
          <div className='text-xs'>
            {parseFloat(PROVIDER.rating).toFixed(1)} / {parseInt(PROVIDER.reviews)} Reviews
          </div>
        </div> */}
      </div>

      {TAB === 'info' && (
        <>
          <div className='w-full flex flex-col items-center justify-center p-4'>
            <img src={POSITION.image} alt='' className='w-full rounded-md shrink-0 mr-2 w-[100px] h-[100px] object-contain' />
            <div className='font-bold text-lg mt-4 text-center'>
              {POSITION.name}
            </div>
            <div className='text-sm font-normal mt-2'>
              {POSITION.city}
            </div>
          </div>

          <div className="flex w-full justify-center p-4 items-center gap-x-2">
            <div className='flex flex-col items-center justify-center' style={{ width: '33%' }}>
              <div className='text-sm text-black font-bold'>{POSITION.stores.length}</div>
              <div className='text-xs text-black font-normal capitalize'>
                Stores
              </div>
            </div>
            <div className='flex flex-col items-center justify-center' style={{ width: '33%' }}>
              <div className='text-sm text-black font-bold'>
                {(parseFloat(firstCost) !== parseFloat(secondCost)) ? (
                  <>
                    {numberWithCommas(parseFloat(firstCost), 'eur')} - {numberWithCommas(parseFloat(secondCost), 'eur')}
                  </>
                ) : (
                  <>
                    {numberWithCommas(parseFloat(firstCost), 'eur')}
                  </>
                )}
              </div>
              <div className='text-xs text-black font-normal capitalize'>
                Delivery
              </div>
            </div>
            <div className='flex flex-col items-center justify-center' style={{ width: '33%' }}>
              <div className='text-sm text-black font-bold'>{parseInt(POSITION.products)}</div>
              <div className='text-xs text-black font-normal capitalize'>
                Products
              </div>
            </div>


          </div>

          <div className="bg-babonbo-100 text-white rounded-lg flex items-center justify-center p-2 m-4 cursor-pointer" onClick={() => {
            history.push(`?search=stores&sort=updatedAt&dir=desc&point=${POSITION.name}&status=true&approval=false`);
          }}>
            <Search color={'#fff'} className="mr-1" /> Search in Geopoint
          </div>

        </>
      )}

      {TAB === 'providers' && (
        <ProvidersController {...props}
          POSITION={POSITION}
        />
      )}


      {TAB === 'products' && (
        <ProductsController {...props}
          POSITION={POSITION}
          currency={'eur'}
        />
      )}


    </div >
  )
}
const ProvidersController = (props) => {

  const { POSITION, setPosition, setProvider, onZoomToMarker, TRANSLATE, LANGUAGE } = props;

  const [isLoading, setLoading] = useState(true);
  const [PROVIDERS, setProviders] = useState([]);


  useEffect(() => {

    if (POSITION) {

      let Stores = POSITION.stores.map((item) => item.id);
      //alert('STORE ===> '+JSON.stringify(Stores, null, 2))
      onFetchProviders(Stores);
    }

  }, [POSITION]);

  const onFetchProviders = async (Stores) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setLoading(true);

    try {

      var FORM = {
        ids: Stores
      }

      var DATA_RESPONSE = await postApiPrivate('/admin/map/stores', FORM, JWT);


      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
        setLoading(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      setProviders(DATA_RESPONSE.data);
      setLoading(false);

      window.scrollTo(0, 0)


    }
    catch (e) {

      console.log("CREATING ===> ", e);
      setLoading(false);
    }

  }

  if (isLoading) {

    return (
      <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
        <div className='w-full flex items-center justify-center'>
          <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
        </div>
      </div>
    )
  }

  return (
    <div className='flex w-full flex-col'>

      <div className='w-full mt-10 mb-4 px-4'>
        <div className='font-bold w-full'>{PROVIDERS.length} Providers Available</div>
      </div>

      {PROVIDERS.map((store, index) => (
        <StoreItem key={index}
          store={store}
          index={index}
          TRANSLATE={TRANSLATE}
          LANGUAGE={LANGUAGE}
          onSelectProvider={() => {

            const geocode = store.geocode || {};
            const latitude = geocode.latitude || 0;
            const longitude = geocode.longitude || 0;

            setPosition(null);
            setProvider(store);
            onZoomToMarker(latitude, longitude)
          }}
        />
      ))}

    </div>
  )
}


//List Items
const StoreItem = ({ store, onSelectProvider }) => {

  return (
    <div className="flex p-4 items-start relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => onSelectProvider()}>
      <img className="rounded-full shrink-0 mr-4 object-cover w-[50px] h-[50px] border border-zinc-200"
        src={store.img} alt='' />
      <div className="w-full">
        <div className="font-bold text-xs mb-1 flex justify-between">
          {store.name}
        </div>
        <div className="flex items-center gap-x-2">
          {parseFloat(store.rating) > 0 && (
            <div className="flex text-xs items-center">
              {parseFloat(store.rating).toFixed(1)}
              <img src={'/assets/img/star.svg'} className="ml-1 w-[10px] h-[10px]" alt="" />
            </div>
          )}
          <div className="w-1 h-1 rounded-full bg-gray-500" />
          {parseFloat(store.reviews) > 0 && (
            <div className="flex text-xs">
              {parseInt(store.reviews)} reviews
            </div>
          )}
        </div>
        <div className="flex items-center gap-x-2">
          <div className='text-xs text-bagreen-100 font-normal'>
            orders: {parseInt(store.total)}
          </div>
          <div className="w-1 h-1 rounded-full bg-gray-500" />
          <div className='text-xs text-gray-500 font-normal'>
            score: {parseInt(store.score)}
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <div className='text-xs text-blue-500 font-normal'>
            {store.products_count} products
          </div>
          <div className="w-1 h-1 rounded-full bg-gray-500" />
          <div className='text-xs text-gray-500 font-normal'>
            {store.delivery.length} delivery points
          </div>
        </div>
      </div>
      {store.status ? (
        <div className='bg-green-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
      ) : (
        <div className='bg-red-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
      )}

    </div>
  )

}
const ProductItem = ({ product, currency, onSelectProduct }) => {

  const price_day = product.price_day || 0;
  const category = product.category;

  const CategoryFound = Filter_Array.find((item) => item.code === category);
  const CategoryName = CategoryFound ? CategoryFound.en : category;

  return (
    <div className="flex p-4 items-start relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => onSelectProduct()}>
      <img className="rounded-full shrink-0 mr-4 object-cover w-[50px] h-[50px] border border-zinc-200"
        src={product.img} alt='' />
      <div className="w-full">
        <div className="font-bold text-xs mb-1 flex justify-between" style={{ width: '80%' }}>
          {product.name}
        </div>

        <div className="flex items-center gap-x-2">
          <div className='text-xs text-gray-500 font-normal'>
            {CategoryName}
          </div>

          <div className="w-1 h-1 rounded-full bg-gray-500" />

          <div className='text-xs text-babonbo-100 font-bold'>
            {numberWithCommas(parseFloat(price_day), currency)} /day
          </div>

        </div>

        <div className="flex items-center gap-x-2">
          {parseFloat(product.rating) > 0 && (
            <div className="flex text-xs items-center">
              {parseFloat(product.rating).toFixed(1)}
              <img src={'/assets/img/star.svg'} className="ml-1 w-[10px] h-[10px]" alt="" />
            </div>
          )}
          <div className="w-1 h-1 rounded-full bg-gray-500" />
          {parseFloat(product.reviews) > 0 && (
            <div className="flex text-xs">
              {parseInt(product.reviews)} reviews
            </div>
          )}
        </div>


      </div>
      {product.status ? (
        <div className='bg-green-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
      ) : (
        <div className='bg-red-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
      )}

    </div>
  )

}
const CityItem = ({ city, history, setStatus }) => {

  const CountryFound = Countries_EN.find((item) => item.value === city.country);
  const CountryName = CountryFound ? CountryFound.label : '';

  const CityGeocode = city.geocode || {};
  const CityLatitude = CityGeocode ? CityGeocode.latitude : 0;
  const CityLongitude = CityGeocode ? CityGeocode.longitude : 0;

  const StateName = city.state || '';

  return (
    <div className="flex p-4 items-start relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
      history.push(`?search=stores&sort=updatedAt&dir=desc&country=${city.country}&city=${city.id}&name=${city.en}&lat=${CityLatitude}&lon=${CityLongitude}&range=100&status=true&approval=false`);
    }}>
      <ReactCountryFlag className="rounded-full shrink-0 mr-4 object-cover w-[25px] h-[25px] border border-zinc-200"
        countryCode={city.country} svg />
      <div className="w-full">
        <div className="font-bold text-xs mb-1 flex justify-between uppercase">
          {city.en}
        </div>
        <div className="flex items-center gap-x-2">
          <div className='text-xs text-black font-normal'>
            {StateName ? `${StateName}, ${CountryName}` : CountryName}
          </div>
        </div>
      </div>
      {city.status ? (
        <div className='bg-green-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
      ) : (
        <div className='bg-red-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
      )}

    </div>
  )

}
const CountryItem = ({ country, history, setStatus }) => {

  return (
    <div className="flex p-4 items-center relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
      history.push(`?search=stores&sort=updatedAt&dir=desc&country=${country.value}&status=true&approval=false`);
    }}>
      <ReactCountryFlag className="rounded-full shrink-0 mr-4 object-cover w-[25px] h-[25px] border border-zinc-200"
        countryCode={country.value} svg />
      <div className="w-full">
        <div className="font-bold text-xs flex justify-between uppercase">
          {country.label}
        </div>
      </div>
      {/* {city.status ? (
        <div className='bg-green-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
      ) : (
        <div className='bg-red-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
      )} */}
      <ArrowBackIos className="shrink-0 mr-4 w-[15px] h-[15px]" style={{ transform: 'rotate(180deg)' }} />
    </div>
  )

}
const GeostoreItem = ({ geopoint, onSelectPosition }) => {

  const GeopointType = geopoint.type;

  let GeopointIcon = 'babonbo_marker.png';

  if (GeopointType === 'airport') {
    GeopointIcon = 'babonbo_marker_airport.png';
  }
  else if (GeopointType === 'station') {
    GeopointIcon = 'babonbo_marker_station.png';
  }
  else if (GeopointType === 'port') {
    GeopointIcon = 'babonbo_marker_port.png';
  }


  let costs = geopoint.costs || [];
  let firstCost = costs[0] ? parseFloat(costs[0]) : 0;
  let secondCost = costs[costs.length - 1] ? parseFloat(costs[costs.length - 1]) : 0;

  return (
    <div className="flex p-4 items-start relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => onSelectPosition()}>
      <img src={`/assets/img/${GeopointIcon}`} className="rounded-full shrink-0 mr-2 object-cover w-[25px] h-[25px] border border-zinc-200"
      />
      <div className="w-full">
        <div className="font-bold text-xs mb-1 flex justify-between uppercase">
          {geopoint.name}
        </div>
        <div className="flex items-center gap-x-2">
          <div className='text-xs text-black font-normal capitalize'>
            {geopoint.city}
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <div className='text-xs text-black font-normal capitalize'>
            Stores: {geopoint.stores.length}
          </div>
          <div className="w-1 h-1 rounded-full bg-gray-500" />
          <div className='text-xs text-black font-normal capitalize'>
            Products: {parseInt(geopoint.products)}
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <div className='text-xs text-black font-normal capitalize'>
            Delivery: {(parseFloat(firstCost) !== parseFloat(secondCost)) ? (
              <>
                {numberWithCommas(parseFloat(firstCost), 'eur')} - {numberWithCommas(parseFloat(secondCost), 'eur')}
              </>
            ) : (
              <>
                {numberWithCommas(parseFloat(firstCost), 'eur')}
              </>
            )}
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <div className='flex'>
            {geopoint.stores.slice(0, 2).map((item, index) => {
              return (
                <img key={index} src={item.image} className="rounded-full shrink-0 mt-2 object-cover w-[20px] h-[20px] border border-zinc-200" />
              );
            })}
          </div>

          {geopoint.stores.length > 2 && (
            <p className="mt-2 text-xs text-gray-600">
              +{geopoint.stores.length - 2} more
            </p>
          )}
        </div>
      </div>


    </div>
  )

}
const GeopointItem = ({ geopoint, history, STATUS }) => {

  const CountryFound = Countries_EN.find((item) => item?.label?.toLowerCase() === geopoint?.country?.toLowerCase());
  const CountryValue = CountryFound ? CountryFound.value : '';

  const CityGeocode = geopoint.geocode || {};
  const CityLatitude = CityGeocode ? CityGeocode.latitude : 0;
  const CityLongitude = CityGeocode ? CityGeocode.longitude : 0;

  const GeopointType = geopoint.type;

  let GeopointIcon = 'babonbo_marker.png';

  if (GeopointType === 'airport') {
    GeopointIcon = 'babonbo_marker_airport.png';
  }
  else if (GeopointType === 'station') {
    GeopointIcon = 'babonbo_marker_station.png';
  }
  else if (GeopointType === 'port') {
    GeopointIcon = 'babonbo_marker_port.png';
  }



  return (
    <div className="flex p-4 items-start relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => {
      history.push(`?search=stores&sort=updatedAt&dir=desc&country=${geopoint.country}&city=${geopoint.id}&name=${geopoint.en}&lat=${CityLatitude}&lon=${CityLongitude}&range=100&status=true&approval=false`);
    }}>
      <img src={`/assets/img/${GeopointIcon}`} className="rounded-full shrink-0 mr-2 object-cover w-[25px] h-[25px] border border-zinc-200"
      />
      <div className="w-full">
        <div className="font-bold text-xs mb-1 flex justify-between uppercase">
          {geopoint.name}
        </div>
        <div className="flex items-center gap-x-2">
          <div className='text-xs text-black font-normal capitalize'>
            {geopoint.city} - {geopoint.country}
          </div>
        </div>

      </div>
      <div className='flex flex-col items-end' style={{ width: 30 }}>
        {geopoint.status ? (
          <div className='bg-green-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
        ) : (
          <div className='bg-red-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
        )}
        <ReactCountryFlag className="rounded-full shrink-0 mt-2 object-cover w-[20px] h-[20px] border border-zinc-200"
          countryCode={CountryValue} svg />
      </div>


    </div>
  )

}
const CategoryItem = ({ category, isActive, onSelectCategory }) => {

  return (
    <div className="flex p-4 items-center justify-between relative bg-white border-b border-zinc-200 cursor-pointer" onClick={() => onSelectCategory()}>

      <div className='w-full flex'>
        <img className="rounded-full shrink-0 mr-4 object-cover w-[25px] h-[25px] border border-zinc-200"
          src={category.icon} />
        <div className="w-full">
          <div className="font-bold text-xs flex justify-between uppercase">
            {category.en}
          </div>
        </div>
      </div>

      {isActive ? (
        <div className='bg-green-700 rounded-full' style={{ bottom: 10, right: 10 }}>
          <img src='/assets/img/check-white.svg' alt='' />
        </div>
      ) : (
        <div className='rounded-full' style={{ bottom: 10, right: 10, background: 'gray' }}>
          <img src='/assets/img/check-white.svg' alt='' />
        </div>
      )}

    </div>
  )

}

//Helper
const SearchPicker = (props) => {
  const {
    TRANSLATE,
    LANGUAGE,
    search_country,
    zIndex,
    onSelectCity,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [SearchResults, setSearchResults] = useState([]);
  const [SearchInput, setSearchInput] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(null);


  const onUpdateSearch = async (search) => {
    console.log("CITY SELECTED ===> ", search);

    //Correct Path
    let searchPath = search[LANGUAGE + "_url"];
    let fixedPath =
      searchPath.replace("/search/", "/flow/") + "/info?id=" + search.id;

    setFocused(false);

    setSearchInput(search[LANGUAGE]);

    onSelectCity(search);
    return;

  };

  const onChangeLocation = async (city) => {
    setSearchInput(city);

    if (!city || city.length <= 3) {
      setFocused(false);
      setSearchResults([]);

      return;
    }


    clearTimeout(debouncedSearch); // Clear the previous debounce timeout
    const timeoutId = setTimeout(() => {
      // Call the Node.js server API to perform the search
      onFetchLocations(city);
    }, 500); // Adjust the delay time as needed

    setDebouncedSearch(timeoutId); // Store the new debounce timeout

  };

  // Function to fetch nearby places from OpenStreetMap API
  const onFetchLocations = async (query) => {

    //alert('calling');

    setLoading(true)

    try {

      const DATA_RESPONSE = await postApiPrivate("/locations/search", { query: query, lang: LANGUAGE });

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
        setLoading(false);
        return;
      }


      const filteredResults = DATA_RESPONSE.data || [];

      if (filteredResults.length > 0) {
        setFocused(true);
        setSearchResults(filteredResults);
      }

      setLoading(false);


    } catch (error) {
      console.error('Error fetching nearby places:', error);
      setFocused(false);
      setSearchResults([]);
      setLoading(false);

    }


  };

  const onSearchKeyUp = async (event) => {
    event.preventDefault();

    if (event.keyCode === 13) {
      event.target.blur();

      if (SearchResults.length > 0) {
        onUpdateSearch(SearchResults[0]);
      }
    }
  };

  return (
    <div className="input-group with-icon w-full" style={{ zIndex: zIndex }}>
      <label>
        {TRANSLATE.wd_destination}
      </label>
      <img className="input-icon" src="/assets/img/location.svg" alt="" />
      <span className="inner-label">City</span>
      <input
        type="text"
        placeholder={TRANSLATE.ask_which_city}
        onFocus={() => setFocused(true)}
        onChange={(e) => onChangeLocation(e.target.value)}
        onKeyUp={(e) => onSearchKeyUp(e)}
        value={SearchInput}
        autoComplete="new-password"
        className="pt-0"
      />

      {isLoading ? (
        <div className="w-[45px] h-[45px] rounded-full bg-babonbo-100 hover:bg-babonbo-200 cursor-pointer flex justify-center items-center absolute right-[5px] md:right-[25px] z-30" style={{ top: 40, zIndex: zIndex }}>
          <img
            className="animate-spin w-[20px] h-[20px]"
            src="/assets/img/loader-white.png"
            alt=""
          />
        </div>
      ) : (
        <>
          {search_country && (
            <div className="w-[45px] h-[45px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute right-[5px] md:right-[25px]" style={{ top: 40, zIndex: zIndex }}>
              <img
                className="w-[20px] h-[20px]"
                src="/assets/img/check.svg"
                style={{ filter: "brightness(0) invert(1)" }}
                alt=""
              />
            </div>
          )}
        </>
      )}

      {isFocused && SearchResults.length > 0 && (
        <div className="w-full bg-white rounded-lg drop-shadow-2xl text-black overflow-auto absolute top-[100%] max-h-[40vh]" style={{ zIndex: zIndex }}>
          {SearchResults.map((item, index) => {
            const country_index = Countries_EN.findIndex(
              (cty) => cty.value === item.country
            );
            const country_obx =
              country_index != -1 ? Countries_EN[country_index] : null;
            const country_name = country_obx ? country_obx.label : null;

            return (
              <div
                className="w-full p-3 flex items-center border-b border-zinc-200 cursor-pointer hover:bg-zinc-100"
                onClick={() => onUpdateSearch(item)}
                key={index}
              >
                <ReactCountryFlag svg countryCode={item.country} className="mr-2" />
                {item[LANGUAGE]} - ({country_name || item.country})
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};


export default AdminMap
