
import React from "react";
import { Link } from "react-router-dom";
import StarRating from "../General/Stars";

import moment from 'moment/min/moment-with-locales';

const ReviewsCard = ({name, subtitle, image, date, rank, destination, TRANSLATE}) => {

  moment.locale(TRANSLATE.lang);

  const endMoment = moment(date);

  return (
      <div class="testimonial-box">
          <Link class="box-top" to={destination}>
          
              <div class="profile">
                  
                  <div class="profile-img">
                      <img src={image} />
                  </div>
                  
                  <div class="name-user">
                      <strong>{name}</strong>
                      <span>{endMoment.fromNow()}</span>
                  </div>
              </div>
              
              <div class="reviews">
                  <StarRating reviews={30} rate={5} />
              </div>
          </Link>
          <div class="client-comment">
              <p>
                  {subtitle}
              </p>
          </div>
      </div>
  )

  return (
      <Link className="review_container" to={destination}>
        
          <img src={image} className="review_img" />
         
          <div className="review_info">
            <div className="review_user">
              <div className="review_name">
                {name}
              </div>
              <div className="review_date">
                {endMoment.fromNow()}
              </div>
            </div>

            <StarRating rate={rank} size={15} reviews={0} />
            
            <p className="review_text">
              {subtitle}
            </p>

          </div>	

      </Link>
  )
}

export default ReviewsCard