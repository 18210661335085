import React from 'react';

import {AddOutline, SearchOutline} from 'react-ionicons';
import SearchCitiesNavbar from '../Search/SearchCitiesNavbar';


const Searchbar = (props) => {

  const {TRANSLATE, history, match, LANGUAGE, style, inputRef} = props;

  const onAddProduct = () => {
    history.push('/dashboard/products/new');
  }

  const onSearchProduct = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
        event.target.blur();
        history.push('/dashboard/products?q='+event.target.value);
    }
    
  }

  const onSearchOrder = (event, type) => {
    event.preventDefault();
    if (event.keyCode === 13) {
        event.target.blur();

        if(type === 'provider'){
          history.push('/dashboard/orders?q='+event.target.value);
        }
        else{
          history.push('/orders?q='+event.target.value);
        }

    }
  }


  const CURRENT_URL = match.url;

  const isProducts = (CURRENT_URL === '/dashboard/products');
  const isDashboard = (CURRENT_URL === '/dashboard');
  const isOrders = (CURRENT_URL === '/orders');
  const isOrdersProvider = (CURRENT_URL === '/dashboard/orders');

  
  if(isProducts){
      return (
        <div className="search_container" style={{...style}}>
            <input placeholder={TRANSLATE.search_product} onKeyUp={(e)=> onSearchProduct(e)} ref={inputRef}/>
            <div style={{background: '#26BFBF'}} onClick={()=> onAddProduct()}>
                <AddOutline  color='#ffffff' />
            </div>
        </div>
      )
  }
  else if(isDashboard){
    return (
        <div className="search_container" style={{...style}}>
            <input placeholder={TRANSLATE.orders_search_rental} onKeyUp={(e)=> onSearchOrder(e, 'provider')} ref={inputRef}/>
            <div style={{background: '#26BFBF'}}>
                <SearchOutline  color='#ffffff' />
            </div>
        </div>
    )
  }
  else if(isOrdersProvider){
    return (
        <div className="search_container" style={{...style}}>
            <input placeholder={TRANSLATE.orders_search_rental} onKeyUp={(e)=> onSearchOrder(e, 'provider')} ref={inputRef}/>
            <div style={{background: '#26BFBF'}}>
                <SearchOutline  color='#ffffff' />
            </div>
        </div>
    )
  }
  else{
    return (
        <SearchCitiesNavbar {...props} type="nearby" placeholder={TRANSLATE.where_to_rent} LANGUAGE={LANGUAGE} />
    )
  }

}

export default Searchbar