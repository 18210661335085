import { Chip } from '@mui/material';
import React, {useEffect, useState} from 'react'
import { postApiPrivate } from '../../api';

const WidgetCount = (props) => {

    const {title, label, type, filters, onClick} = props;
  
    const [isLoading, setLoading] = useState(true);
    const [COUNT, setCount] = useState(0);
  
    useEffect(()=> {
  
        onFetchCount();
  
    }, []);
  
    const onFetchCount = async() => {
  
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
      
      setLoading(true);
  
      try{
  
        console.log("Count "+type+" ===> ", filters);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/'+type+'/count', filters, JWT);
          
        if(DATA_RESPONSE.error){
  
            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }
  
  
        setCount(DATA_RESPONSE || 0);
        setLoading(false);
  
      }
      catch(e){
  
          setLoading(false);
      }
    }
  
    if(isLoading){
      return <></>
    }
  
    return (
  
      <div className="top_box_magic">
          <h1>
            {title}
          </h1>
  
          <Chip label={COUNT+' '+label} 
                style={{marginTop: 10, width: 'fit-content'}} 
                variant="filled" 
                color="default" 
                onClick={()=> onClick()}
          />
          
      </div>
    )
  
}

  
export {
    WidgetCount
}