import React, {useEffect} from 'react';
import LoaderMaster from '../Loaders/Loaders';

import Cookies from 'universal-cookie';

import './z_auth.css';

const AuthLogout = ({history, TRANSLATE, onStartUser}) => {

    useEffect(()=> {

        setTimeout(()=> {

            localStorage.removeItem('jwt_token');
            localStorage.removeItem('cart');
            localStorage.removeItem('ano');

            const cookies = new Cookies();
            cookies.remove('jwt_token');
            
            onStartUser('reset');

            const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
            if(isNativeApp){
                const webData = {
                    type: 'LOGOUT',
                    data: null
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(webData));
            }
            
            history.push('/');
        
        }, 1000)
        
    },[])

    return(

        <div className="account-create-container">

            <LoaderMaster title={TRANSLATE.loader_logout} type="primary" />
            
        </div>
    )


}



export default AuthLogout
