const Metadata = (lang) => {
    
    if(lang === 'it'){
        return Landing_IT;
    }
    else if(lang === 'en'){
        return Landing_EN;
    }
    else if(lang === 'fr'){
        return Landing_FR;
    }
    else if(lang === 'es'){
        return Landing_ES;
    }
    else{
        return Landing_EN
    }
}

const Landing_EN = {
    title: 'Babonbo.com | Baby Gear Rental & Service',
    description: 'Baby equipment rental service. Delivery at your holiday destination or to your doorstep. Save money, time, and the environment.',
    img: 'https://www.babonbo.com/assets/img/preview-en.jpg',
    learn_more: 'Learn More',
    where: 'Where we are',
    support: 'Support',

    sec_1_title: 'Baby Equipment Rental & Service.',
    sec_1_sub: 'Babonbo is the first platform in Europe where you can rent the best baby equipment at home or on vacation.',
    
    sec_1_main: 'How to rent on Babonbo',
    sec_1_most: 'Rent baby equipment from our local providers.',
    sec_1_cta: '',
    sec_1_list: {
        one: 'Choose a Location',
        two: 'Decide what to rent',
        three: 'Check the availability of date',
        four: 'Complete your booking'
    },

    sec_2_title: 'Inspiration for your next trip',
    sec_2_sub: 'Discover our famous locations',
    sec_2_list: {
        one: 'London',
        two: 'Rome',
        three: 'Milan',
        four: 'Venice',
        five: 'Istanbul' 
    },

    sec_3_title: 'Become a provider! Start earning today.',
    sec_3_sub: 'Open your store for free anywhere in the world!\nUpload the photos of your baby equipment.\nSupport traveling families.\nStart earning by helping renting your baby gear.',
    sec_3_cta: 'Create your Store',

    sec_4_title: 'Become part of the community Babonbo!',
    sec_4_sub: 'Create your profile and join our community of moms all around the world !\n',
    sec_4_cta: 'Join Community',

    sec_5_title: 'Download our mobile app for the best user experience.',
    sec_5_sub: 'Babonbo is the first mobile app to rent baby gear from locals.\n',

    sec_faq: [
        {
            t: 'What can I do if the destination entered is not present on the platform?',
            s: 'Enter your details in the form, you will be contacted by our assistance center, we will find a solution for you.',
        },
        {
            t: 'What can I do if I am looking for a product that is not on the platform?',
            s: 'Contact us, we will try to find a solution.',
        },
        {
            t: 'How do I change my reservation?',
            s: 'Enter the section dedicated to your order and proceed with the modification.',
        },
        {
            t: 'Are the equipment sanitized?',
            s: 'Yes, our providers are expected to adhere to high standards of hygiene.',
        },
        {
            t: 'What happens if the equipment breaks or if I return it very dirty?',
            s: 'The terms and conditions are set out below.',
        },
        {
            t: 'Is the equipment installed by the provider?',
            s: 'Our providers will explain how to assemble the equipment',
        },
        {
            t: 'What is the cost of the Babonbo service?',
            s: 'Babonbo gets 15% of the rental value, excluding the cost of delivery and collection.',
        },
        {
            t: 'How does order cancellation work?',
            s: 'You can cancel your reservation 48 hours before the rental, for more information, read the terms and conditions.',
        },
        {
            t: 'What do I do if my credit card has been declined?',
            s: 'Contact us, we will understand your problem specifically. ',
        }

    ],
    sec_faq_t: 'Frequently Asked Questions',

    /** Provider **/
    pro_1_title: 'Launch Your Baby Equipments Rental Store',
    pro_1_sub: 'Open your store for free anywhere in the world! Support traveling families. Start earning by helping renting your baby gear.',
    


   
    
}

const Landing_IT = {

    title: 'Babonbo.com | Noleggio e servizio di abbigliamento per bambini',
    description: 'Servizio di noleggio attrezzature per bambini. Consegna a destinazione o a domicilio. Risparmia denaro, tempo e ambiente.',
    img: 'https://www.babonbo.com/assets/img/preview-it.jpg',
    learn_more: 'Informazioni',
    where: 'Dove siamo',
    support: 'Supporto',

    sec_1_title: 'Noleggio e servizio di attrezzature per bambini.',
    sec_1_sub: 'Babonbo è la prima piattaforma in Europa dove puoi noleggiare le migliori attrezzature per bambini a casa o in vacanza.',
    
    sec_1_main: 'Come noleggiare su Babonbo',
    sec_1_most: 'Noleggia l\'attrezzatura per bambini dai nostri provider locali.',
    sec_1_cta: '',
    sec_1_list: {
        one: 'Scegli una posizione',
        two: 'Decidi cosa affittare',
        three: 'Verifica la disponibilità della data',
        four: 'Completa la tua prenotazione'
    },

    sec_2_title: 'Ispirazione per il tuo prossimo viaggio',
    sec_2_sub: 'Scopri i nostri luoghi famosi',
    sec_2_list: {
        one: 'Londra',
        two: 'Roma',
        three: 'Milano',
        four: 'Venezia',
        five: 'Istanbul'
    },

    sec_3_title: 'Diventa un fornitore! Inizia a guadagnare oggi.',
    sec_3_sub: 'Apri il tuo negozio gratuitamente ovunque nel mondo!\nCarica le foto della tua attrezzatura per bambini.\nSostieni le famiglie in viaggio.\nInizia a guadagnare aiutando a noleggiare la tua attrezzatura per bambini.',
    sec_3_cta: 'Crea il tuo negozio',

    sec_4_title: 'Entra a far parte della community Babonbo!',
    sec_4_sub: 'Crea il tuo profilo e unisciti alla nostra community di mamme in tutto il mondo!\n',
    sec_4_cta: 'Unisciti alla community',

    sec_5_title: 'Scarica la nostra app mobile per la migliore esperienza utente.',
    sec_5_sub: 'Babonbo è la prima app per dispositivi mobili a noleggiare l\'attrezzatura per bambini dalla gente del posto.\n',

    sec_faq: [
        {
            t: 'Cosa posso fare se la destinazione inserita non è presente in piattaforma?',
            s: 'Inserisci i tuoi dati nel form,  sarai contattato dal nostro centro assistenza, troveremo una soluzione per te.',
        },
        {
            t: 'Cosa posso fare se cerco un prodotto assente in piattaforma?',
            s: 'Contattaci, proveremo a trovare una soluzione.',
        },
        {
            t: 'Come modifico la prenotazione?',
            s: 'Entra nella sezione dedicata al tuo ordine e procedi alla modifica.',
        },
        {
            t: 'Le attrezzature sono sanificate?',
            s: 'Sì, i nostri provider sono tenuti a rispettare alti standard igienici.',
        },
        {
            t: 'Cosa succede se si rompe l’attrezzatura o se la restituisco molto sporca?',
            s: 'I termini e le condizioni sono di seguito riportati.',
        },
        {
            t: 'Le attrezzature vengono installati dal provider?',
            s: 'I nostri provider ti spiegheranno come montare le attrezzature',
        },
        {
            t: 'Qual’è il costo del servizio Babonbo?',
            s: 'Babonbo ricava il 15% sul valore del noleggio, escluso il costo di consegna e di ritiro.',
        },
        {
            t: 'Come funziona l’annullamento dell’ordine?',
            s: 'Puoi annullare la prenotazione 48 ore prima del noleggio, per ulteriori informazioni, leggi termini e condizioni.',
        },
        {
            t: 'Cosa faccio se la mia carta di credito è stata rifiutata?',
            s: 'Contattaci, capiremo nello specifico il tuo problema. ',
        }

    ],
    sec_faq_t: 'Domande frequenti (FAQ)',

    /** Fornitore **/
    pro_1_title: 'Avvia il tuo negozio di noleggio attrezzature per bambini',
    pro_1_sub: 'Apri il tuo negozio gratuitamente ovunque nel mondo! Sostieni le famiglie in viaggio. Inizia a guadagnare aiutando a noleggiare la tua attrezzatura per bambini.',
    
    
}

const Landing_FR = {

    title : 'Babonbo.com | Location et service d\'équipement pour bébé',
    description : 'Service de location de matériel pour bébé. Livraison sur votre lieu de vacances ou à votre porte. Économisez de l\'argent, du temps et de l\'environnement.',
    img: 'https://www.babonbo.com/assets/img/preview-fr.jpg',
    learn_more: 'En savoir plus',
    where: 'Où sommes-nous',
    support: 'Soutien',

    sec_1_title: 'Location et service d\'équipement pour bébés.',
    sec_1_sub: 'Babonbo est la première plateforme en Europe où vous pouvez louer le meilleur équipement pour bébé à la maison ou en vacances.',
    
    sec_1_main: 'Comment louer sur Babonbo',
    sec_1_most: 'Louez du matériel pour bébé auprès de nos prestataires locaux.',
    sec_1_cta: '',
    sec_1_list: {
        one: 'Choisissez un emplacement',
        two: "Décidez quoi louer ",
        three: 'Vérifiez la disponibilité de la date',
        four: 'Terminez votre réservation'
    },

    sec_2_title: 'Inspiration pour votre prochain voyage',
    sec_2_sub: 'Découvrez nos lieux célèbres',
    sec_2_list: {
        one: 'Londres',
        two: 'Rome',
        three : 'Milan',
        four: 'Venise',
        five: 'Istanbul'
    },

    sec_3_title: 'Devenez fournisseur! Commencez à gagner aujourd\'hui.',
    sec_3_sub: 'Ouvrez votre magasin gratuitement partout dans le monde!\nTéléchargez les photos de votre équipement pour bébé.\nSoutenez les familles qui voyagent.\nCommencez à gagner de l\'argent en louant votre équipement pour bébé.',
    sec_3_cta: 'Créer votre Boutique',

    sec_4_title: 'Rejoins la communauté Babonbo!',
    sec_4_sub: 'Créez votre profil et rejoignez notre communauté de mamans du monde entier !\n',
    sec_4_cta: 'Rejoindre la communauté',

    sec_5_title: 'Téléchargez notre application mobile pour la meilleure expérience utilisateur.',
    sec_5_sub: 'Babonbo est la première application mobile permettant de louer du matériel pour bébé auprès des habitants.\n',

    sec_faq: [
        {
            t: 'Que puis-je faire si la destination saisie n\'est pas présente sur la plateforme ?',
            s: 'Entrez vos coordonnées dans le formulaire, vous serez contacté par notre centre d\'assistance, nous trouverons une solution pour vous.',
        },
        {
            t: 'Que puis-je faire si je recherche un produit qui n\'est pas sur la plateforme ?',
            s: 'Contactez-nous, nous essaierons de trouver une solution.',
        },
        {
            t: 'Comment modifier ma réservation ?',
            s: 'Entrez dans la section dédiée à votre commande et procédez à la modification.',
        },
        {
            t: 'Le matériel est-il désinfecté ?',
            s: 'Oui, nos prestataires sont tenus de respecter des normes d\'hygiène élevées.',
        },
        {
            t: 'Que se passe-t-il si le matériel casse ou si je le rends très sale ?',
            s: 'Les termes et conditions sont énoncés ci-dessous.',
        },
        {
            t: 'L\'équipement est-il installé par le fournisseur ?',
            s : 'Nos prestataires vous expliqueront comment monter le matériel',
        },
        {
            t: 'Quel est le coût du service Babonbo ?',
            s: 'Babonbo perçoit 15% de la valeur locative, hors frais de livraison et d\'enlèvement.',
        },
        {
            t: 'Comment fonctionne l\'annulation de commande ?',
            s: 'Vous pouvez annuler votre réservation 48h avant la location, pour plus d\'informations, lire les conditions générales.',
        },
        {
            t: 'Que dois-je faire si ma carte de crédit a été refusée ?',
            s: 'Contactez-nous, nous comprendrons précisément votre problème. ',
        }

    ],
    sec_faq_t: 'Questions Fréquemment Posées (FAQ)',

    /** Fournisseur **/
    pro_1_title: 'Lancez votre magasin de location d\'équipements pour bébé',
    pro_1_sub: 'Ouvrez votre boutique gratuitement partout dans le monde ! Soutenir les familles itinérantes. Commencez à gagner de l\'argent en aidant à louer votre équipement pour bébé.',
}

const Landing_ES = { 

    title: 'Babonbo.com | Servicio y alquiler de artículos para bebés',
    description: 'Servicio de alquiler de material para bebés. Entrega en su destino de vacaciones o en la puerta de su casa. Ahorra dinero, tiempo y protege el medio ambiente.',
    img: 'https://www.babonbo.com/assets/img/preview-es.jpg',
    learn_more: 'Más información',
    where: 'Dónde estamos',
    support: 'Soporte',

    sec_1_title: 'Servicio y alquiler de equipos para bebés.',
    sec_1_sub: 'Babonbo es la primera plataforma en Europa donde puedes alquilar el mejor material para bebés a domicilio o de vacaciones.',
   
    sec_1_main: 'Cómo alquilar en Babonbo',
    sec_1_most: 'Alquile equipo para bebés de nuestros proveedores locales.',
    sec_1_cta: '',
    sec_1_list: {
        one: 'Elegir una ubicación',
        two: 'Decide qué alquilar',
        three: 'Consultar la disponibilidad de fecha',
        four: 'Completa tu reserva'
    },

    sec_2_title: 'Inspiración para tu próximo viaje',
    sec_2_sub: 'Descubre nuestros lugares famosos',
    sec_2_list: {
        one: 'Londres',
        two: 'Roma',
        three: 'Milán',
        four: 'Venecia',
        five: 'Estambul'
    },

    sec_3_title: '¡Conviértase en proveedor! Comienza a ganar hoy.',
    sec_3_sub: '¡Abre tu tienda gratis en cualquier parte del mundo!\nSube las fotos de tu equipo para bebés.\nApoya a las familias que viajan.\nEmpieza a ganar ayudando a alquilar tu equipo para bebés.',
    sec_3_cta: 'Crea Tu Tienda',

    sec_4_title: '¡Forma parte de la comunidad Babonbo!',
    sec_4_sub: '¡Crea tu perfil y únete a nuestra comunidad de mamás de todo el mundo!\n',
    sec_4_cta: 'Unirse a la comunidad',

    sec_5_title: 'Descarga nuestra aplicación móvil para obtener la mejor experiencia de usuario.',
    sec_5_sub: 'Babonbo es la primera aplicación móvil para alquilar artículos para bebés de los lugareños.\n',

    sec_faq: [
        {
            t: '¿Qué puedo hacer si el destino ingresado no está presente en la plataforma?',
            s: 'Ingrese sus datos en el formulario, será contactado por nuestro centro de asistencia, encontraremos una solución para usted.',
        },
        {
            t: '¿Qué puedo hacer si estoy buscando un producto que no está en la plataforma?',
            s: 'Contáctenos, intentaremos encontrar una solución.',
        },
        {
            t: '¿Cómo cambio mi reserva?',
            s: 'Entre en la sección dedicada a su pedido y proceda con la modificación.',
        },
        {
            t: '¿Están desinfectados los equipos?',
            s: 'Sí, se espera que nuestros proveedores cumplan con altos estándares de higiene.',
        },
        {
            t: '¿Qué pasa si el equipo se rompe o si lo devuelvo muy sucio?',
            s: 'Los términos y condiciones se establecen a continuación.',
        },
        {
            t: '¿El equipo lo instala el proveedor?',
            s: 'Nuestros proveedores le explicarán cómo montar el equipo',
        },
        {
            t: '¿Cuál es el costo del servicio Babonbo?',
            s: 'Babonbo recibe el 15 % del valor del alquiler, sin incluir los gastos de envío y recogida',
        },
        {
            t: '¿Cómo funciona la cancelación de pedidos?',
            s: 'Puede cancelar su reserva 48 horas antes del alquiler, para más información, lea los términos y condiciones.',
        },
        {
            t: '¿Qué hago si mi tarjeta de crédito ha sido rechazada?',
            s: 'Contáctenos, entenderemos su problema específicamente. ',
        }

    ],
    sec_faq_t: 'Preguntas Frecuentes (FAQ)',

    /** Proveedor **/
    pro_1_title: 'Lanza tu tienda de alquiler de equipos para bebés',
    pro_1_sub: '¡Abre tu tienda gratis en cualquier parte del mundo! Apoyar a las familias viajeras. Comienza a ganar ayudando a alquilar tu equipo de bebé.',
    

   
}

const Landing_TR = {

    title: 'Babonbo.com | Bebek Takımı Kiralama ve Servis',
    description: 'Bebek malzemeleri kiralama hizmeti. Tatil yerinize veya kapınıza teslimat. Paradan, zamandan ve çevreden tasarruf edin.',
    img: 'https://www.babonbo.com/assets/img/preview-tr.jpg',
    learn_more: 'Bilgi',
    where: 'Neredeyiz',
    support: 'Destek',

    sec_1_title: 'Bebek Ekipman Kiralama ve Servis.',
    sec_1_sub: 'Babonbo, evde veya tatilde en iyi bebek ekipmanlarını kiralayabileceğiniz Avrupa\'daki ilk platformdur.',
    
    sec_1_main: 'Babonbo\'da nasıl kiralama yapılır',
    sec_1_most: 'Yerel tedarikçilerimizden bebek ekipmanı kiralayın.',
    sec_1_cta: '',
    sec_1_list: {
        one: 'Bir Yer Seçin',
        two: 'Ne kiralayacağınıza karar verin',
        three: 'Tarihin uygunluğunu kontrol edin',
        four: 'Rezervasyonunuzu tamamlayın'
    },

    sec_2_title: 'Bir sonraki seyahatiniz için ilham kaynağı',
    sec_2_sub: 'Ünlü lokasyonlarımızı keşfedin',
    sec_2_list: {
        one: 'Londra',
        two: 'Roma',
        three: 'Milan',
        four: 'Venedik',
        five: 'İstanbul'
    },

    sec_3_title: 'Sağlayıcı olun! Bugün kazanmaya başlayın.',
    sec_3_sub: 'Dünyanın her yerinde mağazanızı ücretsiz açın!\nBebek ekipmanlarınızın fotoğraflarını yükleyin.\nSeyahat eden aileleri destekleyin.\nBebek eşyalarınızı kiralayarak kazanmaya başlayın.',
    sec_3_cta: 'Bir Mağaza Oluştur',

    sec_4_title: 'Babonbo topluluğunun bir parçası olun!',
    sec_4_sub: 'Profilinizi oluşturun ve dünyanın her yerinden anneler topluluğumuza katılın!\n',
    sec_4_cta: 'Topluluğa Katıl',

    sec_5_title: 'En iyi kullanıcı deneyimi için mobil uygulamamızı indirin.',
    sec_5_sub: 'Babonbo, yerel halktan bebek malzemeleri kiralayan ilk mobil uygulamadır.\n',

    sec_faq: [
        {
            t: 'Girilen hedef platformda yoksa ne yapabilirim?',
            s: 'Forma bilgilerinizi girin, yardım merkezimiz sizinle iletişime geçecektir, sizin için bir çözüm bulalım.',
        },
        {
            t: 'Platformda olmayan bir ürün arıyorsam ne yapabilirim?',
            s: 'Bize ulaşın, bir çözüm bulmaya çalışacağız.',
        },
        {
            t: 'Rezervasyonumu nasıl değiştiririm?',
            s: 'Siparişinize ayrılmış bölüme girin ve değişiklik ile devam edin.',
        },
        {
            t: 'Ekipman dezenfekte edildi mi?',
            s: 'Evet, tedarikçilerimizden yüksek hijyen standartlarına uymaları beklenmektedir.',
        },
        {
            t: 'Ekipman bozulursa veya çok kirli iade edersem ne olur?',
            s: 'Şartlar ve koşullar aşağıda belirtilmiştir.',
        },
        {
            t: 'Ekipman sağlayıcı tarafından mı kuruldu?',
            s: 'Tedarikçilerimiz ekipmanın nasıl monte edileceğini açıklayacaktır',
        },
        {
            t: 'Babonbo hizmetinin maliyeti nedir?',
            s: 'Babonbo, teslimat ve toplama maliyeti hariç, kira bedelinin %15\'ini alır.',
        },
        {
            t: 'Sipariş iptali nasıl çalışır?',
            s: 'Rezervasyonunuzu kiralamadan 48 saat önce iptal edebilirsiniz, daha fazla bilgi için şartlar ve koşulları okuyun.',
        },
        {
            t: 'Kredi kartım reddedilirse ne yapmalıyım?',
            s: 'Bize ulaşın, sorununuzu özel olarak anlayacağız. ',
        }

    ],
    sec_faq_t: 'Sık Sorulan Sorular',

    /** Sağlayıcı **/
    pro_1_title: 'Bebek Ekipman Kiralama Mağazanızı Açın',
    pro_1_sub: 'Dünyanın her yerinde mağazanızı ücretsiz açın! Seyahat eden aileleri destekleyin. Bebek eşyalarınızı kiralamaya yardım ederek kazanmaya başlayın.',
}

export default Metadata