import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import { GetRouteSearchParams } from './AdminHelper';

import swal from 'sweetalert';

import StarReview from '../General/Stars';
import { Add, FilterList } from '@mui/icons-material';

import ReviewFilter from '../Modals/ReviewFilter';
 

const AdminReviews = (props) => {

    const { TRANSLATE, history, match, location } = props;

    const [isLoading, setLoading] = useState(true);

    const [isFilter, setFilter] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [REVIEWS, setReviews] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var filter_type = routeParams.type || null;
            var filter_store = routeParams.store || null;
            var filter_customer = routeParams.customer || null;

            var current_page = parseInt(page_id);

            onFetchReviews({
                page: current_page,
                sort: sort_id,
                direction: direction_id,
                filter_type,
                filter_store,
                filter_customer
            });

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchReviews = async ({ page, sort, direction, filter_type, filter_store, filter_customer }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                type: filter_type,
                store: filter_store,
                customer: filter_customer,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/reviews', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            // alert("DATA_RESPONSE ====> "+ JSON.stringify(DATA_RESPONSE, null, 2));


            setReviews(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchReviews = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchReviews({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/reviews/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setReviews(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setReviews([]);
            setLoading(false);
        }


    }

    const onSortReviews = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else {
            return sort
        }
    }

    const onChangePageReviews = ({ page, sort, direction, filter_type, filter_store, filter_customer }) => {

        let new_url = '/admin/reviews/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }
        else {
            new_url = new_url + '?sort=updatedAt&dir=desc';
        }

        if (filter_type) {
            new_url = new_url + '&type=' + filter_type;
        }

        if (filter_store) {
            new_url = new_url + '&store=' + filter_store;
        }

        if (filter_customer) {
            new_url = new_url + '&customer=' + filter_customer;
        }

        history.push(new_url);
    }

    const onSelectReview = (review_id) => {
        history.push('/admin/reviews/profile/' + review_id);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/admin/users/profile/' + customer_id);
    }

    const onSelectProduct = (product_id) => {
        history.push('/admin/products/profile/' + product_id);
    }

    const onSelectOrder = (order_id) => {
        history.push('/admin/orders/profile/' + order_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/admin/stores/profile/' + store_id);
    }

    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }


    //Logic
    var routeParams = GetRouteSearchParams(location.search);

    var currentSort = routeParams.sort || null;
    var currentDirection = routeParams.dir || null;

    var currentFilterType = routeParams.type || null;
    var currentFilterStore = routeParams.store || null;
    var currentFilterCustomer = routeParams.customer || null;

    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectReview(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "store",
            label: "STORE",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const reviewObx = REVIEWS[rowIndex] || {};
                    const storeObx = reviewObx.store || {};

                    const store_id = storeObx.id || '';
                    const store_name = storeObx.name || '';
                    const store_img = storeObx.img || '';

                    return (
                        <Chip
                            avatar={<Avatar alt={store_name} src={store_img} />}
                            label={store_name.toLowerCase()}
                            variant="outlined"
                            size='medium'
                            style={{ textTransform: 'capitalize' }}
                            onClick={() => onSelectStore(store_id)}

                        />
                    )
                }
            },

        },
        {
            name: "customer",
            label: "CUSTOMER",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const reviewObx = REVIEWS[rowIndex] || {};
                    const customerObx = reviewObx.customer || {};

                    const customer_id = customerObx.id || '';
                    const customer_name = customerObx.name || '';
                    const customer_img = customerObx.img || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                avatar={<Avatar alt={customer_name} src={customer_img} />}
                                label={customer_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectCustomer(customer_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "order",
            label: "ORDER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={'#' + label} size='small'
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => onSelectOrder(label)}
                            />
                        </div>
                    )
                }
            },
        },
        {
            name: "type",
            label: "TYPE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    let color = 'default';

                    if (label === 'provider') {
                        color = 'warning';
                    }
                    else if (label === 'customer') {
                        color = 'success';
                    }
                    else if (label === 'product') {
                        color = 'info';
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} color={color} size='small' />
                        </div>
                    )

                },
                filterOptions: {
                    names: ['customer', 'provider', 'product']
                }
            },
        },
        {
            name: "rank",
            label: "RANK",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "text",
            label: "TEXT",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: {} }),
                customBodyRender: (label) => {

                    if (!label) {
                        return null
                    }

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }

            },
        },
        {
            name: "communication",
            label: "Communication",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return null
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "logistic",
            label: "Logistic",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return null
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "products",
            label: "Products",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return null
                    }
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "punctuation",
            label: "Punctuation",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return null
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "use",
            label: "Use of Gear",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    if (!label) {
                        return null
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "product",
            label: "PRODUCT",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (product, dir) => {

                    const rowIndex = dir.rowIndex;
                    const reviewObx = REVIEWS[rowIndex] || {};
                    const productObx = reviewObx.product || {};

                    const product_id = productObx.id || '';
                    const product_name = productObx.name || '';
                    const product_img = productObx.img || '';

                    if (!product_id) {
                        return null
                    }

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                avatar={<Avatar alt={product_name} src={product_img} />}
                                label={product_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectProduct(product_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "cleanliness",
            label: "Cleanliness",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return null
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "accuracy",
            label: "Accuracy",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return null
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "condition",
            label: "Condition",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return null
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "updatedAt",
            label: "LAST UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "DATE CREATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },



    ];

    const data = REVIEWS.map((item) => {

        moment.locale(TRANSLATE.lang);


        let store_obx = item.store || {};
        let customer_obx = item.customer || {};
        let order_obx = item.order || '';
        let product_obx = item.product || {};

        let review_type = item.type || 0;
        let review_rank = item.rank || 0;
        let review_text = item.text || 0;

        let communication = item.communication || 0;
        let logistic = item.logistic || 0;
        let products = item.products || 0;
        let punctuation = item.punctuation || 0;
        let use = item.use || 0;

        let accuracy = item.accuracy || 0;
        let condition = item.condition || 0;
        let cleanliness = item.cleanliness || 0;


        let new_review = {

            id: item.id,

            store: store_obx,
            customer: customer_obx,
            order: order_obx,
            product: product_obx,

            type: review_type,
            rank: review_rank,
            text: review_text,

            communication,
            logistic,
            products,
            punctuation,
            use,

            accuracy,
            condition,
            cleanliness,

            updatedAt: moment(item.updated).format('DD MMMM YYYY'),
            createdAt: moment(item.created).format('DD MMMM YYYY'),

        };



        return new_review
    })

    const customToolbar = () => (
        <>
            <Tooltip disableFocusListener title="Add Review">
                <IconButton onClick={() => history.push('/admin/reviews/profile/new')}>
                    <Add />
                </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Filter Reviews">
                <IconButton onClick={() => setFilter(true)}>
                    <FilterList />
                </IconButton>
            </Tooltip>
        </>
    );

    const options = {
        filter: false,
        print: false,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,

        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageReviews({
                        page: tableState.page,
                        sort: onSortReviews(sortFilter),
                        direction: sortDirection,
                        filter_type: currentFilterType,
                        filter_store: currentFilterStore,
                        filter_customer: currentFilterCustomer
                    });

                    break;
                case 'sort':



                    onChangePageReviews({
                        page: tableState.page,
                        sort: onSortReviews(sortFilter),
                        direction: sortDirection,
                        filter_type: currentFilterType,
                        filter_store: currentFilterStore,
                        filter_customer: currentFilterCustomer
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchReviews(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchReviews(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Review By Text',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Reviews with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        onFilterChange: (column, filterList, type) => {

            //Check if Current Filter is Active
            if (column === 'type') {
                const filterValue = filterList[4];
                currentFilterType = filterValue;
            }

            onChangePageReviews({
                page: 0,
                sort: onSortReviews(currentSort),
                direction: currentDirection,
                filter_type: currentFilterType,
                filter_store: currentFilterStore,
                filter_customer: currentFilterCustomer
            });

        }

    };




    return (
        <>
            <MUIDataTable
                title={
                    <div className='font-light md:text-5xl text-xl text-lg py-8'>

                        {'Reviews Found → '}

                        {isLoading ? (

                            <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                        ) : (

                            <>
                                {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                            </>

                        )}

                    </div>
                }
                data={data}
                columns={columns}
                options={options}
            />

            {isFilter && (
                <ReviewFilter {...props}
                    onClose={() => setFilter(false)}

                />
            )}
        </>
    );

}



export default AdminReviews


