import React, { useState } from 'react';
import StarReview from '../../../General/Stars';

import { MarkChatUnread, ThumbUp } from '@mui/icons-material';
import { ArrowForwardCircleOutline } from 'react-ionicons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SearchLocation from '../../SearchLocation/SearchLocation';
import ProductCard from '../ProductCard/ProductCard';
import './product_details.css';

const tabs = ['Product', 'Pickup Locations', 'Provider'];

const features = [
  {
    name: 'Pre-loved',
    icon: '/assets/img/pre_loved.png',
  },
  {
    name: 'Delivery to your door',
    icon: '/assets/img/delivery_to_home.png',
  },
  {
    name: 'Clean & Sanitized',
    icon: '/assets/img/clean_and_sanitized.png',
  },
  {
    name: 'Cancel for Free 3 Days Before the Rental',
    icon: '/assets/img/cancel_free_3_days.png',
  },
];

const ModalProductDetails = (props) => {
  const { PRODUCTS, product, defaultTab = tabs[0], onClose } = props;

  const [activeTab, setActiveTab] = useState(defaultTab);

  return (
    <div className='fixed w-screen h-screen top-0 left-0 z-10 flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]'>
      <div className='absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]' />

      <div className='slide-in-bottom flex flex-col m-0 mt-auto md:mt-0 w-full md:w-2/3 md:max-w-[600px] px-4 sm:px-6 lg:px-8 pt-8 bg-white md:rounded-2xl relative z-20 overflow-auto '>
        <img className='absolute top-6 right-6 cursor-pointer' src='/assets/img/close.svg' onClick={() => onClose()} />

        <div className='product-details-tabs hidden md:flex'>
          {tabs.map((tab) => (
            <button
              type='button'
              key={tab}
              className={`product-details-tab ${activeTab === tab ? 'active-tab' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div>
          <div className='main-tab-header flex-row gap-4 bg-light-gray'>
            <div className='flex align-items-center gap-2'>
              <img src={product.store.img} alt='' />
              <h3 className='product-store-name'>{product.store.name}</h3>
            </div>
            <div className='flex flex-column align-items-center'>
              <div className='product-rating'>
                <StarReview rate={product.store.rate} />
              </div>
              <div className='review-info'>
                {product.store.rate} / {product.store.reviews} Reviews
              </div>
            </div>
          </div>
        </div>

        {activeTab === tabs[0] && <MainTab activeTab={activeTab} setActiveTab={setActiveTab} {...props} />}
        {activeTab === tabs[1] && <SecondTab activeTab={activeTab} setActiveTab={setActiveTab} {...props} />}
        {activeTab === tabs[2] && (
          <ThirdTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            {...props}
            store={product.store}
            PRODUCTS={PRODUCTS}
          />
        )}
      </div>
    </div>
  );
};

export default ModalProductDetails;

const MainTab = (props) => {
  const { product, activeTab, setActiveTab } = props;

  const [selectedImg, setSelectedImg] = useState(product.images[0]);

  return (
    <>
      <div className='product-preview p-4' aria-labelledby='product-image-gallery' role='region'>
        <img
          src={selectedImg}
          alt='Selected Product Image'
          className='selected-img'
          aria-label='Selected Product Image'
        />

        <div className='flex mx-auto' aria-label='Product Image Options'>
          {product.images.map((img, index) => (
            <button
              className={`product-img-option ${img === selectedImg ? 'active' : ''}`}
              type='button'
              onClick={() => setSelectedImg(img)}
              key={`product-img-${index}`}
              aria-label={`Select Image ${index + 1}`}
            >
              <img src={img} alt={`Product Image ${index + 1}`} />
            </button>
          ))}
        </div>
      </div>

      <div className='product-info-long' aria-labelledby='product-info'>
        <h3 id='product-info'>{product.name}</h3>
        <p>{product.description}</p>
      </div>

      <div className='features-container' aria-labelledby='product-features'>
        {features.map((feature, index) => (
          <div className='feature-box' key={`feature-${index}`} aria-label='Product Feature'>
            <img src={feature.icon} alt={`${feature.name} Icon`} />
            <h4>{feature.name}</h4>
          </div>
        ))}
      </div>

      <div className='rent-wrapper hidden top-auto bottom-4 pt-2 bg-white' aria-labelledby='rent-info' role='region'>
        <div className='price-info flex flex-row justify-content-between'>
          <span>
            <span className='price'>{numberWithCommas(product.cost_day, product.currency)}</span>/day
          </span>

          <p>{`Rented ${product.total_rent} times`}</p>
        </div>
        <button type='button' className='rent-button mt-4 mb-8' onClick={() => {}} aria-label='Rent Button'>
          <div className='rent-text'>Rent</div>
          <ArrowForwardCircleOutline width={'24px'} color={'#fff'} aria-hidden='true' />
        </button>
      </div>

      <div className='md:hidden sticky py-4 bottom-0 bg-white ' aria-labelledby='rent-info-mobile' role='region'>
        <div className='rent-wrapper '>
          <div className='price-info flex flex-row justify-content-between'>
            <span>
              <span className='price'>{numberWithCommas(product.cost_day, product.currency)}</span>/day
            </span>

            <p>{`Rented ${product.total_rent} times`}</p>
          </div>
          <button type='button' className='rent-button mt-4 ' onClick={() => {}} aria-label='Rent Button Mobile'>
            <div className='rent-text'>Rent</div>
            <ArrowForwardCircleOutline width={'24px'} color={'#fff'} aria-hidden='true' />
          </button>
        </div>
        <div className='product-details-tabs ' aria-label='Product Details Tabs Mobile'>
          {tabs.map((tab) => (
            <button
              type='button'
              key={tab}
              className={`product-details-tab ${activeTab === tab ? 'active-tab' : ''}`}
              onClick={() => setActiveTab(tab)}
              aria-selected={activeTab === tab}
              role='tab'
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

const SecondTab = (props) => {
  const { activeTab, setActiveTab } = props;
  const [district, setDistrict] = useState('');

  return (
    <>
      <div className='pickup-location-map-wrapper' aria-labelledby='pickup-location-map' role='region'>
        <img src='/assets/img/blankmap.png' alt='Pickup Location Map' id='pickup-location-map' />
      </div>
      <div className='block' aria-labelledby='search-location'>
        <SearchLocation {...props} label={'District'} selectedLocation={district} setSelectedLocation={setDistrict} />
      </div>
      <div className='md:hidden sticky py-4 bottom-0 bg-white ' aria-labelledby='mobile-actions' role='region'>
        <div className='rent-wrapper '>
          <button type='button' className='rent-button mt-4 ' onClick={() => {}}>
            <div className='rent-text' aria-label='Save and Continue'>
              Save and Continue
            </div>
            <ArrowForwardCircleOutline width={'24px'} color={'#fff'} aria-hidden='true' />
          </button>
        </div>
        <div className='product-details-tabs ' aria-label='Product Details Tabs Mobile'>
          {tabs.map((tab) => (
            <button
              type='button'
              key={tab}
              className={`product-details-tab ${activeTab === tab ? 'active-tab' : ''}`}
              onClick={() => setActiveTab(tab)}
              aria-selected={activeTab === tab}
              role='tab'
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

const ThirdTab = (props) => {
  const { store, activeTab, setActiveTab, PRODUCTS } = props;
  return (
    <>
      <div className='product-store-img' aria-labelledby='store-image' role='img'>
        <img src='/assets/img/hero-provider.jpg' alt='Store Image' id='store-image' />
      </div>

      <div className='store-info-long' aria-labelledby='store-info'>
        <h3 id='store-info'>{store.name}</h3>
        <p>{store.description}</p>
      </div>

      <div className='features-container' aria-labelledby='store-features'>
        {features.map((feature, index) => (
          <div className='feature-box' key={`feature-${index}`} aria-label='Store Feature'>
            <img src={feature.icon} alt={`${feature.name} Icon`} />
            <h4>{feature.name}</h4>
          </div>
        ))}
      </div>

      <div className='buttons-product-store-wrapper' aria-labelledby='store-actions'>
        <Link to='' className='box-stroke color-ba' aria-label='Chat with Store'>
          Chat <MarkChatUnread />
        </Link>

        {}
        <Link to='' className='box-stroke' aria-label='Read Reviews'>
          {`Read ${store.reviews || 0} Reviews`} <ThumbUp />
        </Link>
      </div>

      <div className='listing-items' aria-labelledby='product-listings'>
        {PRODUCTS.map((product) => (
          <ProductCard
            isGrid={true}
            key={product.id}
            product={{
              id: product.id,
              cost_delivery: 0,
              cost_day: 10,
              currency: 'eur',
              images: [product.first_img, product.second_img, product.third_img, product.fourth_img],
              name: product.name,
              total_rent: 10,
              description: product.about,
              store: {
                img: product.owner?.img,
                name: product.owner?.name,
                rate: product.owner?.rating,
                reviews: product.owner?.reviews,
                description: product.owner?.about,
              },
            }}

            // TODO redirect to the selected product
            // setSelectedProduct={(value) => {
            //   setSelectedProduct(value);
            //   setModalProductDetails(true);
            // }}
            // selectProvider={(value) => {
            //   setSelectedProduct(value);
            //   setActiveTab('Provider');
            //   setModalProductDetails(true);
            // }}
            // onClick={() => history.push('/newux/checkout')}
          />
        ))}
      </div>

      <div className='md:hidden sticky py-4 bottom-0 bg-white ' aria-labelledby='mobile-actions' role='region'>
        <div className='rent-wrapper '>
          <button type='button' className='rent-button mt-4 ' onClick={() => {}} aria-label='Rent Button'>
            <div className='rent-text'>Rent</div>
            <ArrowForwardCircleOutline width={'24px'} color={'#fff'} aria-hidden='true' />
          </button>
        </div>
        <div className='product-details-tabs ' aria-label='Product Details Tabs Mobile'>
          {tabs.map((tab) => (
            <button
              type='button'
              key={tab}
              className={`product-details-tab ${activeTab === tab ? 'active-tab' : ''}`}
              onClick={() => setActiveTab(tab)}
              aria-selected={activeTab === tab}
              role='tab'
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

function numberWithCommas(x, CURRENCY) {
  if (!x) {
    x = 0;
  }

  if (!CURRENCY) {
    return x.toFixed(2);
  }

  if (isNaN(x)) return x;

  return x.toLocaleString('en-US', {
    style: 'currency',
    currency: CURRENCY,
  });
}
