import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import moment from 'moment/min/moment-with-locales';

import MUIDataTable from 'mui-datatables';

import LoaderMaster from '../Loaders/Loaders';

import ReactCountryFlag from "react-country-flag";
import Countries_EN from '../Search/countries_en.json';

import { numberWithCommas } from '../../helpers/GeneralHelper'

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    LineChart
} from "recharts";

import { Tooltip as MuiTooltip } from '@mui/material';
import { Chip } from '@mui/material';
import { Avatar } from '@mui/material';
import { AnalyticsOutline, ArrowDown, ArrowUp, BarChartOutline, CalendarOutline, CloseOutline, ExpandOutline, LocationOutline, SearchOutline, StorefrontOutline } from 'react-ionicons';

import SearchCity from '../General/SearchCity';
import SearchStores from '../General/SearchStores';
import SearchDates from '../General/SearchDates';

import SunCalc from 'suncalc'
import CountdownToMidnight from './AdminCountdown';
import { ArrowDropUp } from '@mui/icons-material';

const convertObjectToArray = (obj) => {
    return Object.entries(obj).map(([key, value]) => {
        return { key, value };
    });
};

const AnalyticsCalendar = (props) => {

    const { TRANSLATE, LANGUAGE, USER, location, history } = props;

    const [isLoading, setLoading] = useState(false);

    const [CurrentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [CurrentMonth, setCurrentMonth] = useState(new Date().getMonth());

    const [CurrentOrders, setCurrentOrders] = useState([]);
    const [CurrentMonthly, setCurrentMonthly] = useState([]);
    const [CurrentHourly, setCurrentHourly] = useState([]);

    const [CurrentTopStores, setCurrentTopStores] = useState([]);
    const [CurrentTopCountries, setCurrentTopCountries] = useState([]);
    const [CurrentTopCities, setCurrentTopCities] = useState([]);
    const [CurrentTopProducts, setCurrentTopProducts] = useState([]);
    const [FullData, setFullData] = useState({});


    const [CurrentLocation, setCurrentLocation] = useState(null);
    const [CurrentStore, setCurrentStore] = useState(null);
    const [CurrentDate, setCurrentDate] = useState('current_month');

    const [SortMonthly, setSortMonthly] = useState('timeline');
    const [SortDaily, setSortDaily] = useState('timeline');
    const [SortStores, setSortStores] = useState('top_orders');
    const [SortProducts, setSortProducts] = useState('top_orders');

    const [FILTER, setFilter] = useState('received');

    const [isSearchCity, setSearchCity] = useState(false);
    const [isSearchStore, setSearchStore] = useState(false);
    const [isSeachDates, setSearchDates] = useState(false);

    const [isAllType, setAllType] = useState('bar');
    const [isTodayType, setTodayType] = useState('bar');
    const [isExpandBar, setExpandBar] = useState(false);
    const [isExpand24, setExpand24] = useState(false);
    const [isExpandLine, setExpandLine] = useState(false);
    const [isExpandOverview, setExpandOverview] = useState(true);

    useEffect(() => {

        const CurrentParams = GetRouteSearchParams(location.search) || {};

        const filter_store = CurrentParams.store || null;
        const filter_location = CurrentParams.location || null;
        const filter_start = CurrentParams.start || null;
        const filter_end = CurrentParams.end || null;

        onFetchCalendar({
            filter_start,
            filter_end,
            filter_location,
            filter_store
        });

    }, [location.search]);

    useEffect(() => {

        if (isSearchCity || isSearchStore || isSeachDates) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }

    }, [isSearchCity, isSearchStore, isSeachDates])


    const onFetchCalendar = async (search) => {

        const {
            filter_start,
            filter_end,
            filter_location,
            filter_store
        } = search;

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        try {


            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            let FORM = {
                store: filter_store,
                location: filter_location,
                start: filter_start,
                end: filter_end,
                timezone: userTimezone
            }

            setLoading(true);

            const DATA_RESPONSE = await postApiPrivate('/admin/analytics/express', {
                ...FORM,
                year: CurrentYear,
                month: CurrentMonth,
            }, JWT);

            if (DATA_RESPONSE.error) {

                setLoading(false);

                console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);

                return alert('Error Loading the dashboard');
            }

            const DASHBOARD_DATA = DATA_RESPONSE.data || [];
            const DASHBOARD_MONTHLY = DATA_RESPONSE.monthly || [];
            const DASHBOARD_HOURLY = DATA_RESPONSE.hourly || [];

            const DASHBOARD_TOP_STORES = DATA_RESPONSE.top_stores || [];
            const DASHBOARD_TOP_COUNTRIES = DATA_RESPONSE.top_countries || [];
            const DASHBOARD_TOP_PRODUCTS = DATA_RESPONSE.top_products || [];
            const DASHBOARD_TOP_CITIES = DATA_RESPONSE.top_cities || [];

            const DASHBOARD_LOCATION = DATA_RESPONSE.location || null;
            const DASHBOARD_STORE = DATA_RESPONSE.store || null;

            const TOP_STORES_SORTED = DASHBOARD_TOP_STORES.sort((a, b) => b.total - a.total);
            const TOP_COUNTRIES_SORTED = DASHBOARD_TOP_COUNTRIES.sort((a, b) => b.total - a.total);
            const TOP_CITIES_SORTED = DASHBOARD_TOP_CITIES.sort((a, b) => b.total - a.total);
            const TOP_PRODUCTS_SORTED = DASHBOARD_TOP_PRODUCTS.sort((a, b) => b.total - a.total);

            if (DASHBOARD_LOCATION) {
                setCurrentLocation(DASHBOARD_LOCATION);
            }

            if (DASHBOARD_STORE) {
                setCurrentStore(DASHBOARD_STORE);
            }

            setCurrentOrders(DASHBOARD_DATA);
            setCurrentMonthly(DASHBOARD_MONTHLY);
            setCurrentHourly(DASHBOARD_HOURLY);

            setCurrentTopStores(TOP_STORES_SORTED);
            setCurrentTopCountries(TOP_COUNTRIES_SORTED);
            setCurrentTopCities(TOP_CITIES_SORTED);
            setCurrentTopProducts(TOP_PRODUCTS_SORTED);

            setFullData(DATA_RESPONSE);

            setLoading(false);

            console.log("FETCHING DASHBOARD ===> ", DASHBOARD_DATA);

        }
        catch (e) {

            setLoading(false);
            alert('Error Loading the dashboard');
        }

    }

    const onUpdateLocation = (city) => {

        if (!city) {
            city = {
                id: 'all'
            }
        }
        const city_id = city.id;

        setCurrentLocation(city)
        setCurrentStore(null)

        const CurrentParams = GetRouteSearchParams(location.search) || {};
        const CurrentStart = CurrentParams.start;
        const CurrentEnd = CurrentParams.end;

        // alert("JSON."+JSON.stringify({
        //     CurrentParams
        // }))

        if (CurrentStart && CurrentEnd) {
            history.push('?location=' + city_id + '&start=' + CurrentStart + '&end=' + CurrentEnd);
        }
        else {
            history.push('?location=' + city_id);
        }

    }

    const onUpdateStore = (store) => {

        if (!store) {
            store = {
                id: 'all'
            }
        }

        const store_id = store.id;

        const CurrentParams = GetRouteSearchParams(location.search) || {};
        const CurrentStart = CurrentParams.start;
        const CurrentEnd = CurrentParams.end;

        setCurrentStore(store)
        setCurrentLocation(null)

        if (CurrentStart && CurrentEnd) {
            history.push('?store=' + store_id + '&start=' + CurrentStart + '&end=' + CurrentEnd);
        }
        else {
            history.push('?store=' + store_id);
        }

    }

    const onUpdateDate = (type) => {

        // alert("HERE ===> "+JSON.stringify({
        //     type
        // }))

        const CurrentParams = GetRouteSearchParams(location.search) || {};
        const CurrentLocation = CurrentParams.location;
        const CurrentStore = CurrentParams.store;

        var PART_QUERY = '';

        if (CurrentLocation) {
            PART_QUERY = '?location=' + CurrentLocation;
        }

        if (CurrentStore) {
            PART_QUERY = '?store=' + CurrentStore;
        }

        if (!CurrentStore && !CurrentLocation) {
            PART_QUERY = '?query=custom'
        }


        if (type.from && type.to) {

            setCurrentDate('custom_range');

            history.push(PART_QUERY + '&start=' + type.from + '&end=' + type.to);

            return
        }

        setCurrentDate(type)

        var REFERENCE = moment.utc(); // fixed just for testing, use moment.utc();
        var TODAY = REFERENCE.clone().startOf('day');
        var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
        var A_7DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_14DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_30DAYS_OLD = REFERENCE.clone().subtract(30, 'days').startOf('day');
        var A_60DAYS_OLD = REFERENCE.clone().subtract(60, 'days').startOf('day');
        var A_90DAYS_OLD = REFERENCE.clone().subtract(90, 'days').startOf('day');
        var A_180DAYS_OLD = REFERENCE.clone().subtract(180, 'days').startOf('day');

        var A_YEAR = REFERENCE.format('YYYY');
        var A_YEAR_FIRST = '1-1-' + A_YEAR;
        var A_YEAR_LAST = '31-12-' + A_YEAR;


        let StartDate = null;
        let EndDate = null;

        if (type === 'today') {
            StartDate = TODAY.format('DD-MM-YYYY');
            EndDate = TODAY.format('DD-MM-YYYY');
        }
        else if (type === 'yesterday') {
            StartDate = YESTERDAY.format('DD-MM-YYYY');
            EndDate = YESTERDAY.format('DD-MM-YYYY');
        }
        else if (type === 'last_7') {
            StartDate = A_7DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_14') {
            StartDate = A_14DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_30') {
            StartDate = A_30DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_60') {
            StartDate = A_60DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_90') {
            StartDate = A_90DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_180') {
            StartDate = A_180DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'current_month') {
            history.push(PART_QUERY);
            return
        }
        else if (type === 'current_year') {
            history.push(PART_QUERY + '&start=' + A_YEAR_FIRST + '&end=' + A_YEAR_LAST);
            return
        }
        else if (type === 'custom_range') {
            setSearchDates(true)
            return
        }
        else {
            StartDate = '01-01-2021';
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }

        history.push(PART_QUERY + '&start=' + StartDate + '&end=' + EndDate);


    }

    const onJumpToSection = (section) => {

        const ProviderSection = document.getElementById(section);

        if (ProviderSection) {
            var headerOffset = 100;
            var elementPosition = ProviderSection.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }

    }

    //Setup Dates
    const CalendarArray = getAllDaysInMonth(CurrentYear, CurrentMonth) || [];
    let FirstDay = moment.utc(new Date(CalendarArray[0]));
    let LastDay = moment.utc(new Date(CalendarArray[CalendarArray.length - 1]));

    const CurrentParams = GetRouteSearchParams(location.search) || {};
    const CurrentStart = CurrentParams.start;
    const CurrentEnd = CurrentParams.end;

    if (CurrentStart) {
        FirstDay = moment.utc(CurrentStart, 'DD-MM-YYYY');
    }

    if (CurrentEnd) {
        LastDay = moment.utc(CurrentEnd, 'DD-MM-YYYY');
    }

    //Setup Orders
    const DATA = CurrentOrders ? CurrentOrders.map((item) => {

        const GMV = Math.round(item.revenue || 0) || 0;

        return {
            ...item,
            GMV: GMV
        }

    }) : [];

    //Setup Monthly
    const DATA_MONTHLY = CurrentMonthly ? CurrentMonthly.map((item) => {

        const GMV = Math.round(item.revenue || 0) || 0;

        return {
            ...item,
            GMV: GMV
        }

    }) : [];

    const DATA_HOURLY = CurrentHourly ? CurrentHourly.map((item) => {
        const GMV = Math.round(item.revenue || 0) || 0;
        const TOTAL = Math.round(item.total || 0) || 0;
        return {
            ...item,
            GMV: GMV,
            TOTAL: TOTAL
        };
    }) : [];

    //Setup Performance
    const TotalStats = FullData.total_stats || {};
    const GreenStats = FullData.green_stats || {};
    const RedStats = FullData.red_stats || {};
    const YellowStats = FullData.yellow_stats || {};

    const TotalOrders = FullData.total || 0;
    const TotalPending = FullData.pending || 0;
    const TotalAccepted = FullData.accepted || 0;
    const TotalDelivering = FullData.delivering || 0;
    const TotalDelivered = FullData.delivered || 0;
    const TotalComplete = FullData.complete || 0;
    const TotalRejected = FullData.rejected || 0;
    const TotalCanceled = FullData.canceled || 0;
    const TotalRefund = FullData.refund || 0;

    const TotalGreen = FullData.green || 0;
    const TotalRed = FullData.red || 0;
    const TotalYellow = FullData.yellow || 0;

    const RateGreen = (TotalGreen / TotalOrders * 100) || 0;
    const RateRed = (TotalRed / TotalOrders * 100) || 0;
    const RateYellow = (TotalYellow / TotalOrders * 100) || 0;

    //Calculate Performance
    let MainStats = TotalStats;
    if (FILTER === 'yellow') {
        MainStats = YellowStats;
    }
    else if (FILTER === 'green') {
        MainStats = GreenStats;
    }
    else if (FILTER === 'red') {
        MainStats = RedStats
    }

    const TotalShown = TotalStats.amount_total || 0;

    const TotalTransactions = MainStats.amount_total || 0;
    const TotalProvider = MainStats.amount_provider || 0;
    const TotalFees = MainStats.amount_fees || 0;

    const TotalCustomers = MainStats.total_customers || 0;
    const TotalProviders = MainStats.total_providers || 0;
    const TotalProducts = MainStats.total_products || 0;
    const TotalDays = MainStats.total_rental_days || 0;

    const AverageOrderValue = MainStats.average_order_value || 0;
    const AverageOrderDuration = MainStats.average_order_duration || 0;
    const AverageOrderProduct = MainStats.average_order_product || 0;

    const AverageCustomersOrders = MainStats.average_customer_orders || 0;
    const AverageProvidersOrders = MainStats.average_provider_orders || 0;
    const AverageCustomersProvider = MainStats.average_provider_customers || 0;



    // if (isLoading) {
    //     return (
    //         <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
    //     )
    // }

    let FinalMonthly = SortDataArray([...CurrentMonthly], SortMonthly);
    let FinalDaily = SortDataArray([...CurrentOrders], SortDaily);

    let FinalTopStores = SortDataArray([...CurrentTopStores], SortStores);
    let FinalTopProducts = SortDataArray([...CurrentTopProducts], SortProducts);

    let FinalBestRevenue = SortDataArray([...CurrentOrders], 'best_revenue');
    let FinalTopOrders = SortDataArray([...CurrentOrders], 'top_orders');

    let FinalTodayBestRevenue = SortDataArray([...CurrentHourly], 'best_revenue');
    let FinalTodayTopOrders = SortDataArray([...CurrentHourly], 'top_orders');
    let FinalToday = [...CurrentOrders].filter((order) => moment.utc(order.date, 'YYYY-MM-DD').isSame(new Date(), 'day'));
    let FinalTodayObx = (FinalToday && FinalToday[0]) ? FinalToday[0] : {}

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {

            const newMoment = moment.utc(`${label} 2024`, 'DD MMM YYYY')
            const moonPhase = SunCalc.getMoonIllumination(newMoment).phase;
            const { title, icon } = getMoonPhaseName(moonPhase);

            return (
                <div className="bg-white rounded-lg border-2 p-4" style={{ minWidth: 300 }}>

                    <div className='w-full flex justify-center items-center mb-2'>
                        <div className='text-xs text-black font-bold'>
                            {newMoment.format('dddd, DD MMMM YYYY')}
                        </div>
                    </div>

                    <div className='w-full flex justify-between items-center mb-2'>
                        <img src={`/assets/moon/${icon}`} style={{ width: 25, height: 25, borderRadius: '50%' }} alt={title} />
                        <div className='font-bold'>{title}</div>
                    </div>


                    {payload.map((pld, index) => (
                        <div className='w-full flex justify-between items-center' key={index}>
                            <div className='text-sm font-bold'>{pld.dataKey}</div>
                            <div className='text-sm font-semibold' style={{ color: pld.fill }}>{numberWithCommas(parseFloat(pld.value), 'eur')}</div>
                        </div>
                    ))}

                </div>
            );
        }

        return null;
    };

    const CustomTooltip2 = ({ active, payload, label }) => {
        if (active && payload && payload.length) {

            const newMoment = moment.utc(`${label} 2024`, 'DD MMM YYYY')
            const moonPhase = SunCalc.getMoonIllumination(newMoment).phase;
            const { title, icon } = getMoonPhaseName(moonPhase);

            return (
                <div className="bg-white rounded-lg border-2 p-4" style={{ minWidth: 300 }}>

                    <div className='w-full flex justify-center items-center mb-2'>
                        <div className='text-xs text-black font-bold'>
                            {newMoment.format('dddd, DD MMMM YYYY')}
                        </div>
                    </div>

                    <div className='w-full flex justify-between items-center mb-2'>
                        <img src={`/assets/moon/${icon}`} style={{ width: 25, height: 25, borderRadius: '50%' }} alt={title} />
                        <div className='font-bold'>{title}</div>
                    </div>


                    {payload.map((pld, index) => (
                        <div className='w-full flex justify-between items-center' key={index}>
                            <div className='text-sm font-bold capitalize'>{pld.dataKey}</div>
                            <div className='text-sm font-semibold' style={{ color: pld.color }}>{parseInt(pld.value)}</div>
                        </div>
                    ))}

                    {/* <pre>
                        {JSON.stringify(payload, null, 2)}
                    </pre> */}

                </div>
            );
        }

        return null;
    };

    //Hourly
    const CustomHourly = ({ active, payload, label, type }) => {
        if (active && payload && payload.length) {

            const MatchedObx = DATA_HOURLY.find((item) => item.name === label);

            const newDate = MatchedObx?.date || '';
            const newMoment = moment.utc(newDate, 'YYYY-MM-DD')
            const moonPhase = SunCalc.getMoonIllumination(newMoment).phase;
            const { title, icon } = getMoonPhaseName(moonPhase);


            // return (
            //     <pre>
            //         {JSON.stringify({
            //             MatchedObx
            //         }, null, 2)}
            //     </pre>
            // )
            return (
                <div className="bg-white rounded-lg border-2 p-4" style={{ minWidth: 300 }}>

                    <div className='w-full flex justify-center items-center mb-2'>
                        <div className='text-xs text-black font-bold'>
                            {newMoment.format('dddd, DD MMMM YYYY')} - {label}
                        </div>
                    </div>

                    <div className='w-full flex justify-between items-center mb-2'>
                        <img src={`/assets/moon/${icon}`} style={{ width: 25, height: 25, borderRadius: '50%' }} alt={title} />
                        <div className='font-bold'>{title}</div>
                    </div>

                    {payload.map((pld, index) => (
                        <>
                            {type === 'line' ? (
                                <div className='w-full flex justify-between items-center' key={index}>
                                    <div className='text-sm font-bold capitalize'>{pld.dataKey}</div>
                                    <div className='text-sm font-semibold' style={{ color: pld.color }}>
                                        {pld.value}
                                    </div>
                                </div>
                            ) : (
                                <div className='w-full flex justify-between items-center' key={index}>
                                    <div className='text-md font-bold capitalize'>{pld.dataKey}</div>
                                    <div className='text-md font-bold' style={{ color: '#000' }}>
                                        {numberWithCommas(parseFloat(pld.value), 'eur')}
                                    </div>
                                </div>
                            )}
                        </>

                    ))}

                    {type !== 'line' && (
                        <>
                            <div className='my-4'></div>
                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Earnings</div>
                                <div className='text-sm font-semibold' style={{ color: 'green' }}>
                                    {MatchedObx.green.o}/{numberWithCommas(parseFloat(MatchedObx.green.a || 0), 'eur')}
                                </div>
                            </div>
                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Pending</div>
                                <div className='text-sm font-semibold' style={{ color: 'orange' }}>
                                    {MatchedObx.yellow.o}/{numberWithCommas(parseFloat(MatchedObx.yellow.a || 0), 'eur')}
                                </div>
                            </div>
                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Refunded</div>
                                <div className='text-sm font-semibold' style={{ color: 'red' }}>
                                    {MatchedObx.red.o}/{numberWithCommas(parseFloat(MatchedObx.red.a || 0), 'eur')}
                                </div>
                            </div>

                            <div className='my-4'></div>

                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Provider</div>
                                <div className='text-sm font-semibold' style={{ color: '#26BFBF' }}>
                                    {numberWithCommas(parseFloat(MatchedObx.revenue_provider || 0), 'eur')}
                                </div>
                            </div>
                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Fees</div>
                                <div className='text-sm font-semibold' style={{ color: '#fd6270' }}>
                                    {numberWithCommas(parseFloat(MatchedObx.revenue_fees || 0), 'eur')}
                                </div>
                            </div>

                        </>
                    )}

                </div>
            );
        }

        return null;
    };

    //Hourly
    const CustomDaily = ({ active, payload, label, type }) => {
        if (active && payload && payload.length) {

            const MatchedObx = DATA.find((item) => item.name === label);

            const newMoment = moment.utc(`${label} 2024`, 'DD MMM YYYY')
            const moonPhase = SunCalc.getMoonIllumination(newMoment).phase;
            const { title, icon } = getMoonPhaseName(moonPhase);

            // return (
            //     <pre>
            //         {JSON.stringify({
            //             MatchedObx
            //         }, null, 2)}
            //     </pre>
            // )
            return (
                <div className="bg-white rounded-lg border-2 p-4" style={{ minWidth: 300 }}>

                    <div className='w-full flex justify-center items-center mb-2'>
                        <div className='text-xs text-black font-bold'>
                            {newMoment.format('dddd, DD MMMM YYYY')}
                        </div>
                    </div>

                    <div className='w-full flex justify-between items-center mb-2'>
                        <img src={`/assets/moon/${icon}`} style={{ width: 25, height: 25, borderRadius: '50%' }} alt={title} />
                        <div className='font-bold'>{title}</div>
                    </div>

                    {payload.map((pld, index) => (
                        <>
                            {type === 'line' ? (
                                <div className='w-full flex justify-between items-center' key={index}>
                                    <div className='text-sm font-bold capitalize'>{pld.dataKey}</div>
                                    <div className='text-sm font-semibold' style={{ color: pld.color }}>
                                        {pld.value}
                                    </div>
                                </div>
                            ) : (
                                <div className='w-full flex justify-between items-center' key={index}>
                                    <div className='text-md font-bold capitalize'>{pld.dataKey}</div>
                                    <div className='text-md font-bold' style={{ color: '#000' }}>
                                        {numberWithCommas(parseFloat(pld.value), 'eur')}
                                    </div>
                                </div>
                            )}
                        </>

                    ))}

                    {type !== 'line' && (
                        <>
                            <div className='my-4'></div>
                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Earnings</div>
                                <div className='text-sm font-semibold' style={{ color: 'green' }}>
                                    {MatchedObx.green.o}/{numberWithCommas(parseFloat(MatchedObx.green.a || 0), 'eur')}
                                </div>
                            </div>
                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Pending</div>
                                <div className='text-sm font-semibold' style={{ color: 'orange' }}>
                                    {MatchedObx.yellow.o}/{numberWithCommas(parseFloat(MatchedObx.yellow.a || 0), 'eur')}
                                </div>
                            </div>
                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Refunded</div>
                                <div className='text-sm font-semibold' style={{ color: 'red' }}>
                                    {MatchedObx.red.o}/{numberWithCommas(parseFloat(MatchedObx.red.a || 0), 'eur')}
                                </div>
                            </div>

                            <div className='my-4'></div>

                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Provider</div>
                                <div className='text-sm font-semibold' style={{ color: '#26BFBF' }}>
                                    {numberWithCommas(parseFloat(MatchedObx.revenue_provider || 0), 'eur')}
                                </div>
                            </div>
                            <div className='w-full flex justify-between items-center'>
                                <div className='text-sm font-bold capitalize'>Fees</div>
                                <div className='text-sm font-semibold' style={{ color: '#fd6270' }}>
                                    {numberWithCommas(parseFloat(MatchedObx.revenue_fees || 0), 'eur')}
                                </div>
                            </div>

                        </>
                    )
                    }

                </div >
            );
        }

        return null;
    };

    //Monthly
    const CustomMonthly = ({ active, payload, label, type }) => {
        if (active && payload && payload.length) {

            const MatchedObx = DATA_MONTHLY.find((item) => item.name === label);

            // return (
            //     <pre>
            //         {JSON.stringify({
            //             MatchedObx
            //         }, null, 2)}
            //     </pre>
            // )
            return (
                <div className="bg-white rounded-lg border-2 p-4" style={{ minWidth: 300 }}>

                    <div className='w-full flex justify-center items-center mb-2'>
                        <div className='text-xs text-black font-bold'>
                            {label}
                        </div>
                    </div>


                    {payload.map((pld, index) => (
                        <div className='w-full flex justify-between items-center' key={index}>
                            <div className='text-sm font-bold capitalize'>{pld.dataKey}</div>
                            {type === 'line' ? (
                                <div className='text-sm font-semibold' style={{ color: pld.color }}>
                                    {pld.value}
                                </div>
                            ) : (
                                <div className='text-sm font-semibold' style={{ color: pld.color }}>
                                    {numberWithCommas(parseFloat(pld.value), 'eur')}
                                </div>
                            )}

                        </div>
                    ))}

                </div>
            );
        }

        return null;
    };

    return (
        <>
            {isSearchCity && (
                <SearchCity {...props}
                    onClose={() => setSearchCity(false)}
                    onUpdateLocation={onUpdateLocation}

                />
            )}

            {isSearchStore && (
                <SearchStores {...props}
                    onClose={() => setSearchStore(false)}
                    onUpdateStore={onUpdateStore}

                />
            )}

            {isSeachDates && (
                <SearchDates {...props}
                    onClose={() => setSearchDates(false)}
                    onUpdateDate={onUpdateDate}

                />
            )}

            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10">
                {/* INTRODUCTION */}
                {/* <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                    <div className="w-full">

                        {CurrentLocation && (
                            <div className="font-semibold" onClick={() => setSearchCity(true)}>
                                Analytics for {CurrentLocation['en']} <ReactCountryFlag countryCode={CurrentLocation.country} />
                            </div>
                        )}

                        {CurrentStore && (
                            <div className="font-semibold" onClick={() => setSearchStore(true)}>
                                Analytics for {CurrentStore.name} <ReactCountryFlag countryCode={CurrentStore.country} />
                            </div>
                        )}

                        {!CurrentLocation && !CurrentStore && (
                            <div className="font-semibold" onClick={() => setSearchDates(true)}>
                                Analytics from <span className='text-bagreen-100'>{FirstDay.format('DD/MM/YY')}</span> to  <span className='text-bagreen-100'>{LastDay.format('DD/MM/YY')}</span>
                            </div>
                        )}

                        {isLoading ? (
                            <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                <div className='w-full flex items-center justify-center'>
                                    <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                </div>
                            </div>
                        ) : (
                            <div className="bg-bagreen-100 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                <div className='flex flex-col my-auto pl-6'>
                                    <h1 className="font-semibold">
                                        {numberWithCommas(TotalShown, 'eur')}
                                    </h1>
                                    <span>{TotalOrders || 0} orders</span>
                                </div>

                                <div className="flex flex-col items-end justify-between text-xs">

                                    <select className="bg-transparent text-xs accent-white bg-blend-color-dodge p-0 pr-8 border-none text-white focus:outline-none focus:ring-0"
                                        value={CurrentDate}
                                        onChange={(e) => onUpdateDate(e.target.value)}
                                    >
                                        <option value="today">
                                            Today
                                        </option>
                                        <option value="yesterday">
                                            Yesterday
                                        </option>
                                        <option value="last_7">
                                            Last 7 days
                                        </option>
                                        <option value="last_14">
                                            Last 14 days
                                        </option>
                                        <option value="last_30">
                                            Last 30 days
                                        </option>
                                        <option value="last_60">
                                            Last 60 days
                                        </option>
                                        <option value="last_90">
                                            Last 90 days
                                        </option>
                                        <option value="last_180">
                                            Last 180 days
                                        </option>

                                        <option value="current_month">
                                            Current Month
                                        </option>
                                        <option value="current_year">
                                            Current Year
                                        </option>

                                        <option value="custom_range">
                                            Custom Range
                                        </option>

                                    </select>

                                    <div className='flex flex-col items-end'>
                                        <span className='font-semibold'>{TotalGreen || 0}/{TotalOrders} accepted</span>
                                        <span>({numberWithCommas(GreenStats.amount_total || 0, 'eur')})</span>
                                    </div>

                                    <div className="flex items-center w-full" onClick={() => setSearchDates(true)}>
                                        {FirstDay.format('DD/MM/YY')} - {LastDay.format('DD/MM/YY')}
                                        <img className="ml-2" src="/assets/img/chart.svg" />
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="w-full">
                        <div className="font-semibold">
                            Quick actions
                        </div>
                        <div className="min-h-[150px] mt-2 mb-4 flex gap-x-3">
                            <div onClick={() => setSearchCity(true)} className="w-full bg-white border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400">
                                <LocationOutline color={'#26BFBF'} width="30px" height="30px" style={{ marginBottom: 5 }} />
                                <span className="text-xs">
                                    Search by city
                                </span>
                            </div>
                            <div onClick={() => setSearchStore(true)} className="w-full bg-white border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400">
                                <StorefrontOutline color={'#26BFBF'} width="30px" height="30px" style={{ marginBottom: 5 }} />
                                <span className="text-xs">
                                    Search by store
                                </span>
                            </div>
                            <div onClick={() => onJumpToSection('section_daily_performance')} className="w-full bg-white border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400">
                                <CalendarOutline color={'#26BFBF'} width="30px" height="30px" style={{ marginBottom: 5 }} />
                                <span className="text-xs">
                                    Daily peformance
                                </span>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* CHARTS 24h */}
                <div className={`flex flex-wrap gap-3 gap-x-4 mt-8 mb-4`}>

                    <div style={{ width: '49%' }}>

                        <div className='flex items-center justify-between w-full'>
                            <div className="font-semibold">
                                Today
                            </div>

                        </div>

                        {isLoading ? (
                            <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4" >
                                <div className='w-full flex items-center justify-center' style={{ width: '100%', height: 350 }}>
                                    <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                </div>
                            </div>
                        ) : (
                            <div className="w-full bg-white rounded-lg text-black flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                <div className='flex flex-col justify-between' style={{ width: '100%', height: '100%' }}>

                                    <div className='flex flex-wrap items-center justify-between'>
                                        <div className='flex flex-col items-start'>
                                            <CountdownToMidnight timeZone={'America/Tijuana'} />
                                        </div>
                                        <div className='flex flex-col items-end'>
                                            <CountdownToMidnight isTime={true} timeZone={'America/Tijuana'} />
                                        </div>
                                    </div>

                                    <div className='w-full flex flex-wrap items-center justify-around py-10'>
                                        <div className='flex flex-col text-green-600 items-center justify-center flex-shrink-0'>
                                            <ArrowUp color={'black'} />
                                            <div className='flex flex-col items-center justify-center bg-black rounded-xl w-[60px] h-[60px] mb-2'>
                                                <span className='text-white'>{`${parseInt(FinalTodayObx.total)}`}</span>
                                                <span className='text-white text-xs'>{`Orders`}</span>
                                            </div>
                                            <span className='text-black'>+{numberWithCommas(FinalTodayObx.revenue || 0, 'eur')}</span>
                                            <span className='text-bagreen-100 text-xs'>+{numberWithCommas(FinalTodayObx.revenue_provider || 0, 'eur')}</span>
                                            <span className='text-babonbo-100 text-xs'>+{numberWithCommas(FinalTodayObx.revenue_fees || 0, 'eur')}</span>
                                        </div>
                                        <div className='flex flex-col text-green-600 items-center justify-center flex-shrink-0'>
                                            <ArrowUp color={'green'} />
                                            <div className='flex flex-col items-center justify-center bg-green-700 rounded-xl w-[60px] h-[60px] mb-2'>
                                                <span className='text-white'>{`${parseInt(FinalTodayObx?.green?.o || 0)}`}</span>
                                                <span className='text-white text-xs'>{`Earnings`}</span>
                                            </div>
                                            <span className='text-black'>+{numberWithCommas(FinalTodayObx?.green?.a || 0, 'eur')}</span>
                                            <span className='text-bagreen-100 text-xs'>+{numberWithCommas(FinalTodayObx?.green?.p || 0, 'eur')}</span>
                                            <span className='text-babonbo-100 text-xs'>+{numberWithCommas(FinalTodayObx?.green?.f || 0, 'eur')}</span>
                                        </div>
                                        <div className='flex flex-col text-orange-500 items-center justify-center flex-shrink-0'>
                                            <ArrowUp color={'orange'} />
                                            <div className='flex flex-col items-center justify-center bg-orange-500 rounded-xl w-[60px] h-[60px] mb-2'>
                                                <span className='text-white'>{`${parseInt(FinalTodayObx?.yellow?.o || 0)}`}</span>
                                                <span className='text-white text-xs'>{`Pending`}</span>
                                            </div>
                                            <span className='text-black'>+{numberWithCommas(FinalTodayObx?.yellow?.a || 0, 'eur')}</span>
                                            <span className='text-bagreen-100 text-xs'>+{numberWithCommas(FinalTodayObx?.yellow?.p || 0, 'eur')}</span>
                                            <span className='text-babonbo-100 text-xs'>+{numberWithCommas(FinalTodayObx?.yellow?.f || 0, 'eur')}</span>
                                        </div>
                                        <div className='flex flex-col text-red-600 items-center justify-center flex-shrink-0'>
                                            <ArrowDown color={'red'} />
                                            <div className='flex flex-col items-center justify-center bg-red-500 rounded-xl w-[60px] h-[60px] mb-2'>
                                                <span className='text-white'>{`${parseInt(FinalTodayObx?.red?.o || 0)}`}</span>
                                                <span className='text-white text-xs'>{`Refunded`}</span>
                                            </div>
                                            <span className='text-black'>-{numberWithCommas(FinalTodayObx?.red?.a || 0, 'eur')}</span>
                                            <span className='text-bagreen-100 text-xs'>-{numberWithCommas(FinalTodayObx?.red?.p || 0, 'eur')}</span>
                                            <span className='text-babonbo-100 text-xs'>-{numberWithCommas(FinalTodayObx?.red?.f || 0, 'eur')}</span>
                                        </div>
                                    </div>

                                    {(FinalTodayBestRevenue && FinalTodayBestRevenue[0]) && (
                                        <div className='w-full flex flex-wrap items-center justify-between'>
                                            <div className='flex flex-col items-start'>
                                                <div className='text-md font-semibold'>
                                                    Best Revenue Today
                                                </div>
                                                <div className='flex'>
                                                    <CountdownToMidnight isDate={FinalTodayBestRevenue[0].date} />
                                                    <div className='flex-col flex'>
                                                        <div className='flex text-xs md:text-xs items-center'>
                                                            {FinalTodayBestRevenue[0].formatted}
                                                        </div>
                                                        <div className='flex text-xs md:text-xs items-center'>
                                                            {FinalTodayBestRevenue[0].total} orders ({numberWithCommas(FinalTodayBestRevenue[0].revenue, 'eur')})
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='text-md font-semibold mt-4'>
                                                    Best Orders Today
                                                </div>
                                                <div className='flex'>
                                                    <CountdownToMidnight isDate={FinalTodayTopOrders[0].date} />
                                                    <div className='flex-col flex'>
                                                        <div className='flex text-xs md:text-xs items-center'>
                                                            {FinalTodayTopOrders[0].formatted}
                                                        </div>
                                                        <div className='flex text-xs md:text-xs items-center'>
                                                            {FinalTodayTopOrders[0].total} orders ({numberWithCommas(FinalTodayTopOrders[0].revenue, 'eur')})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex flex-col items-end'>
                                                {/* <div className='text-md font-semibold'>
                                                    Goals Hourly
                                                </div>
                                                <div className='flex-col flex items-end'>
                                                    <div className='flex text-xs md:text-xs items-center'>
                                                        Increase GMV {`>`} ({numberWithCommas(FinalTodayBestRevenue[0].revenue || 0, 'eur')})
                                                    </div>
                                                    <div className='flex text-xs md:text-xs items-center'>
                                                        Increase Orders {`>`} ({FinalTodayTopOrders[0].total || 0})
                                                    </div>
                                                </div> */}
                                                <div className='text-md font-semibold mt-4'>
                                                    Goals Today
                                                </div>
                                                <div className='flex-col flex items-end'>
                                                    <div className='flex text-xs md:text-sm items-center'>
                                                        Increase GMV {`>`} (<span className='font-semibold text-babonbo-100'>{numberWithCommas(FinalTodayObx.revenue || 0, 'eur')}</span>/{numberWithCommas(FinalBestRevenue[0].revenue || 0, 'eur')})
                                                    </div>
                                                    <div className='flex text-xs md:text-sm items-center'>
                                                        Increase Orders {`>`} ({FinalTodayObx.total}/{FinalTopOrders[0].total || 0})
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        )}

                    </div>

                    <div style={{ width: '49%' }}>

                        <div className='flex items-center justify-between w-full'>
                            <div className="font-semibold">
                                Overview
                            </div>

                        </div>

                        {isLoading ? (
                            <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4" >
                                <div className='w-full flex items-center justify-center' style={{ width: '100%', height: 350 }}>
                                    <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                </div>
                            </div>
                        ) : (
                            <div className="w-full bg-white rounded-lg text-black flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                <div className='flex flex-col justify-between' style={{ width: '100%', height: 'auto' }}>

                                    <div className='flex flex-wrap items-center justify-between'>
                                        <div className='flex flex-col items-start'>

                                            {CurrentLocation && (
                                                <>
                                                    <div className='text-xl font-semibold'>{`${FirstDay.format('DD/MM/YY')} - ${LastDay.format('DD/MM/YY')}`}</div>
                                                    <div className='flex text-base items-center '>
                                                        <ReactCountryFlag countryCode={CurrentLocation.country} svg className='mr-2' />
                                                        {CurrentLocation['en']}
                                                    </div>
                                                </>
                                            )}

                                            {CurrentStore && (
                                                <>
                                                    <div className='text-xl font-semibold'>{`${FirstDay.format('DD/MM/YY')} - ${LastDay.format('DD/MM/YY')}`}</div>
                                                    <div className='flex text-base items-center '>
                                                        <ReactCountryFlag countryCode={CurrentStore.country} svg className='mr-2' />
                                                        {CurrentStore.name}
                                                    </div>
                                                </>
                                            )}

                                            {!CurrentLocation && !CurrentStore && (
                                                <>
                                                    <div className='text-xl font-semibold' onClick={() => setSearchDates(true)}>
                                                        {`${FirstDay.format('DD/MM/YY')} - ${LastDay.format('DD/MM/YY')}`}
                                                    </div>
                                                    <div className='flex text-base items-center' onClick={() => setSearchCity(true)}>
                                                        <SearchOutline color={'#000'} className='mr-2' />
                                                        Search by City
                                                    </div>
                                                    <div className='flex text-base items-center' onClick={() => setSearchStore(true)}>
                                                        <SearchOutline color={'#000'} className='mr-2' />
                                                        Search by Store
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                        <div className='flex flex-col items-end'>
                                            <select className="bg-transparent text-lg accent-white bg-blend-color-dodge p-0 pr-8 border-none text-black font-semibold focus:outline-none focus:ring-0"
                                                value={CurrentDate}
                                                onChange={(e) => onUpdateDate(e.target.value)}
                                            >
                                                <option value="today">
                                                    Today
                                                </option>
                                                <option value="yesterday">
                                                    Yesterday
                                                </option>
                                                <option value="last_7">
                                                    Last 7 days
                                                </option>
                                                <option value="last_14">
                                                    Last 14 days
                                                </option>
                                                <option value="last_30">
                                                    Last 30 days
                                                </option>
                                                <option value="last_60">
                                                    Last 60 days
                                                </option>
                                                <option value="last_90">
                                                    Last 90 days
                                                </option>
                                                <option value="last_180">
                                                    Last 180 days
                                                </option>

                                                <option value="current_month">
                                                    Current Month
                                                </option>
                                                <option value="current_year">
                                                    Current Year
                                                </option>

                                                <option value="custom_range">
                                                    Custom Range
                                                </option>

                                            </select>
                                        </div>
                                    </div>

                                    <div className='w-full flex flex-wrap items-center justify-around py-10'>
                                        <div className='flex flex-col text-green-600 items-center justify-center flex-shrink-0'>
                                            <ArrowUp color={'black'} />
                                            <div className='flex flex-col items-center justify-center bg-black rounded-xl w-[60px] h-[60px] mb-2'>
                                                <span className='text-white'>{`${TotalOrders}`}</span>
                                                <span className='text-white text-xs'>{`Orders`}</span>
                                            </div>
                                            <span className='text-black'>+{numberWithCommas(TotalStats.amount_total || 0, 'eur')}</span>
                                            <span className='text-bagreen-100 text-xs'>+{numberWithCommas(TotalStats.amount_provider || 0, 'eur')}</span>
                                            <span className='text-babonbo-100 text-xs'>+{numberWithCommas(TotalStats.amount_fees || 0, 'eur')}</span>
                                        </div>
                                        <div className='flex flex-col text-green-600 items-center justify-center flex-shrink-0'>
                                            <ArrowUp color={'green'} />
                                            <div className='flex flex-col items-center justify-center bg-green-700 rounded-xl w-[60px] h-[60px] mb-2'>
                                                <span className='text-white'>{`${TotalGreen}`}</span>
                                                <span className='text-white text-xs'>{`Earnings`}</span>
                                            </div>
                                            <span className='text-black'>+{numberWithCommas(GreenStats.amount_total || 0, 'eur')}</span>
                                            <span className='text-bagreen-100 text-xs'>+{numberWithCommas(GreenStats.amount_provider || 0, 'eur')}</span>
                                            <span className='text-babonbo-100 text-xs'>+{numberWithCommas(GreenStats.amount_fees || 0, 'eur')}</span>
                                        </div>
                                        <div className='flex flex-col text-orange-500 items-center justify-center flex-shrink-0'>
                                            <ArrowUp color={'orange'} />
                                            <div className='flex flex-col items-center justify-center bg-orange-500 rounded-xl w-[60px] h-[60px] mb-2'>
                                                <span className='text-white'>{`${TotalYellow}`}</span>
                                                <span className='text-white text-xs'>{`Pending`}</span>
                                            </div>
                                            <span className='text-black'>+{numberWithCommas(YellowStats.amount_total || 0, 'eur')}</span>
                                            <span className='text-bagreen-100 text-xs'>+{numberWithCommas(YellowStats.amount_provider || 0, 'eur')}</span>
                                            <span className='text-babonbo-100 text-xs'>+{numberWithCommas(YellowStats.amount_fees || 0, 'eur')}</span>
                                        </div>
                                        <div className='flex flex-col text-red-600 items-center justify-center flex-shrink-0'>
                                            <ArrowDown color={'red'} />
                                            <div className='flex flex-col items-center justify-center bg-red-500 rounded-xl w-[60px] h-[60px] mb-2'>
                                                <span className='text-white'>{`${TotalRed}`}</span>
                                                <span className='text-white text-xs'>{`Refunded`}</span>
                                            </div>
                                            <span className='text-black'>-{numberWithCommas(RedStats.amount_total || 0, 'eur')}</span>
                                            <span className='text-bagreen-100 text-xs'>-{numberWithCommas(RedStats.amount_provider || 0, 'eur')}</span>
                                            <span className='text-babonbo-100 text-xs'>-{numberWithCommas(RedStats.amount_fees || 0, 'eur')}</span>
                                        </div>
                                    </div>

                                    {(FinalBestRevenue && FinalBestRevenue[0]) && (
                                        <div className='w-full flex flex-wrap items-center justify-between'>
                                            <div className='flex flex-col items-start'>
                                                <div className='text-md font-semibold'>
                                                    Best Revenue
                                                </div>
                                                <div className='flex'>
                                                    <CountdownToMidnight isDate={FinalBestRevenue[0].date} />
                                                    <div className='flex-col flex'>
                                                        <div className='flex text-xs md:text-xs items-center'>
                                                            {FinalBestRevenue[0].formatted}
                                                        </div>
                                                        <div className='flex text-xs md:text-xs items-center'>
                                                            {FinalBestRevenue[0].total} orders ({numberWithCommas(FinalBestRevenue[0].revenue, 'eur')})
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='text-md font-semibold mt-4'>
                                                    Best Orders
                                                </div>
                                                <div className='flex'>
                                                    <CountdownToMidnight isDate={FinalTopOrders[0].date} />
                                                    <div className='flex-col flex'>
                                                        <div className='flex text-xs md:text-xs items-center'>
                                                            {FinalTopOrders[0].formatted}
                                                        </div>
                                                        <div className='flex text-xs md:text-xs items-center'>
                                                            {FinalTopOrders[0].total} orders ({numberWithCommas(FinalTopOrders[0].revenue, 'eur')})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex flex-col items-end'>
                                                <div className='text-md font-semibold'>
                                                    Goals Today
                                                </div>
                                                <div className='flex-col flex items-end'>
                                                    <div className='flex text-xs md:text-xs items-center'>
                                                        Increase GMV {`>`} ({numberWithCommas(FinalTodayObx.revenue || 0, 'eur')}/{numberWithCommas(FinalBestRevenue[0].revenue || 0, 'eur')})
                                                    </div>
                                                    <div className='flex text-xs md:text-xs items-center'>
                                                        Increase Orders {`>`} ({FinalTodayObx.total}/{FinalTopOrders[0].total || 0})
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                </div>
                            </div>
                        )}

                    </div>


                    {isTodayType === 'bar' ? (
                        <div style={{ width: (isExpand24 ? '100%' : '49%') }}>
                            <div className='flex items-center justify-between w-full'>
                                <div className="font-semibold">
                                    GMV Performance 24h
                                </div>

                                <div className='flex items-center justify-end ml-auto'>
                                    <div className="font-semibold cursor-pointer mr-2" onClick={() => setTodayType('line')}>
                                        <AnalyticsOutline />
                                    </div>
                                    {isExpand24 ? (
                                        <div className="font-semibold cursor-pointer" onClick={() => setExpand24(false)}>
                                            <CloseOutline />
                                        </div>
                                    ) : (
                                        <div className="font-semibold cursor-pointer" onClick={() => setExpand24(true)}>
                                            <ExpandOutline />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {isLoading ? (
                                <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4" >
                                    <div className='w-full flex items-center justify-center' style={{ width: '100%', height: 350 }}>
                                        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                    </div>
                                </div>
                            ) : (
                                <div className="bg-white rounded-lg text-black flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                    <div style={{ width: '100%', height: 350 }}>
                                        <ResponsiveContainer>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={DATA_HOURLY || []}
                                                syncId="anyIdx"
                                                margin={{
                                                    top: 20,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5
                                                }}

                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis unit={'€'} name="GMV" />

                                                <Tooltip content={<CustomHourly />} cursor={{ fill: "transparent" }} />
                                                <Bar dataKey="GMV" stackId="a" fill="#26BFBF" />

                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            )}

                        </div>
                    ) : (
                        <div style={{ width: (isExpand24 ? '100%' : '49%') }}>

                            <div className='flex items-center justify-between w-full'>
                                <div className="font-semibold">
                                    Orders Performance 24h
                                </div>
                                <div className='flex items-center justify-end ml-auto'>
                                    <div className="font-semibold cursor-pointer mr-2" onClick={() => setTodayType('bar')}>
                                        <BarChartOutline />
                                    </div>
                                    {isExpand24 ? (
                                        <div className="font-semibold cursor-pointer" onClick={() => setExpand24(false)}>
                                            <CloseOutline />
                                        </div>
                                    ) : (
                                        <div className="font-semibold cursor-pointer" onClick={() => setExpand24(true)}>
                                            <ExpandOutline />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {isLoading ? (
                                <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                    <div className='w-full flex items-center justify-center' style={{ width: '100%', height: 350 }}>
                                        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                    </div>
                                </div>
                            ) : (
                                <div className="bg-white rounded-lg text-black flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                    <div style={{ width: '100%', height: 350 }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart
                                                width={500}
                                                height={300}
                                                data={DATA_HOURLY || []}
                                                syncId="anyIdx"
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />

                                                <Tooltip content={<CustomHourly type="line" />} cursor={{ fill: "transparent" }} />

                                                <Line dataKey="pending" type="monotone" stroke="#FCE205" />
                                                <Line dataKey="accepted" type="monotone" stroke="#9AD6AB" />
                                                <Line dataKey="delivering" type="monotone" stroke="#88D1F1" />
                                                <Line dataKey="delivered" type="monotone" stroke="#ff8b3d" />
                                                <Line dataKey="complete" type="monotone" stroke="green" />
                                                <Line dataKey="rejected" type="monotone" stroke="#ec1529" />
                                                <Line dataKey="canceled" type="monotone" stroke="#ec1529" />
                                                <Line dataKey="refund" type="monotone" stroke="#ec1529" />
                                                <Line dataKey="total" type="monotone" stroke="#3d3d3d" />

                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}


                    {isAllType === 'bar' ? (
                        <div style={{ width: (isExpandBar ? '100%' : '49%') }}>
                            <div className='flex items-center justify-between w-full'>
                                <div className="font-semibold">
                                    GMV Performance
                                </div>
                                <div className='flex items-center justify-end ml-auto'>
                                    <div className="font-semibold cursor-pointer mr-2" onClick={() => setAllType('line')}>
                                        <AnalyticsOutline />
                                    </div>
                                    {isExpandBar ? (
                                        <div className="font-semibold cursor-pointer" onClick={() => setExpandBar(false)}>
                                            <CloseOutline />
                                        </div>
                                    ) : (
                                        <div className="font-semibold cursor-pointer" onClick={() => setExpandBar(true)}>
                                            <ExpandOutline />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {isLoading ? (
                                <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                    <div className='w-full flex items-center justify-center' style={{ width: '100%', height: 350 }}>
                                        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                    </div>
                                </div>
                            ) : (
                                <div className="bg-white rounded-lg text-black flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                    <div style={{ width: '100%', height: 350 }}>
                                        <ResponsiveContainer>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={(DATA_MONTHLY.length > 0 && !isExpandBar) ? DATA_MONTHLY : (DATA.length > 1) ? DATA : (DATA_HOURLY.length > 0) ? DATA_HOURLY : []}
                                                syncId="anyId"
                                                margin={{
                                                    top: 20,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis unit={'€'} name="GMV" />

                                                {DATA_MONTHLY.length > 0 ? (
                                                    <Tooltip content={<CustomMonthly />} cursor={{ fill: "transparent" }} />
                                                ) : (
                                                    <Tooltip content={<CustomDaily />} cursor={{ fill: "transparent" }} />
                                                )}

                                                {/* <Legend /> */}
                                                <Bar dataKey="GMV" stackId="a" fill="#26BFBF" />

                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            )}

                        </div>
                    ) : (
                        <div style={{ width: (isExpandLine ? '100%' : '49%') }}>

                            <div className='flex items-center justify-between w-full'>
                                <div className="font-semibold">
                                    Orders Performance
                                </div>
                                <div className='flex items-center justify-end ml-auto'>
                                    <div className="font-semibold cursor-pointer mr-2" onClick={() => setAllType('bar')}>
                                        <BarChartOutline />
                                    </div>
                                    {isExpandLine ? (
                                        <div className="font-semibold cursor-pointer" onClick={() => setExpandLine(false)}>
                                            <CloseOutline />
                                        </div>
                                    ) : (
                                        <div className="font-semibold cursor-pointer" onClick={() => setExpandLine(true)}>
                                            <ExpandOutline />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {isLoading ? (
                                <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                    <div className='w-full flex items-center justify-center' style={{ width: '100%', height: 350 }}>
                                        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                    </div>
                                </div>
                            ) : (
                                <div className="bg-white rounded-lg text-black flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                    <div style={{ width: '100%', height: 350 }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart
                                                width={500}
                                                height={300}
                                                data={(DATA_MONTHLY.length > 0 && !isExpandLine) ? DATA_MONTHLY : (DATA.length > 1) ? DATA : (DATA_HOURLY.length > 0) ? DATA_HOURLY : []}
                                                syncId="anyId"
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                {DATA_MONTHLY.length > 0 ? (
                                                    <Tooltip content={<CustomMonthly type="line" />} cursor={{ fill: "transparent" }} />
                                                ) : (

                                                    <Tooltip content={<CustomDaily type="line" />} cursor={{ fill: "transparent" }} />
                                                )}
                                                {/* <Legend /> */}

                                                <Line dataKey="pending" type="monotone" stroke="#FCE205" />
                                                <Line dataKey="accepted" type="monotone" stroke="#9AD6AB" />
                                                <Line dataKey="delivering" type="monotone" stroke="#88D1F1" />
                                                <Line dataKey="delivered" type="monotone" stroke="#ff8b3d" />
                                                <Line dataKey="complete" type="monotone" stroke="green" />
                                                <Line dataKey="rejected" type="monotone" stroke="#ec1529" />
                                                <Line dataKey="canceled" type="monotone" stroke="#ec1529" />
                                                <Line dataKey="refund" type="monotone" stroke="#ec1529" />
                                                <Line dataKey="total" type="monotone" stroke="#3d3d3d" />

                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}

                </div>

                {/* CHARTS Daily*/}
                {/* <div className={`flex flex-wrap mt-8 mb-4`}>
                    <div className={`w-full ${isExpandBar ? 'md:w-full' : 'md:w-1/2 md:pr-11'} order-2 md:order-1`}>
                        <div className='flex items-center justify-between w-full'>
                            <div className="font-semibold">
                                GMV Performance
                            </div>
                            {isExpandBar ? (
                                <div className="font-semibold cursor-pointer" onClick={() => setExpandBar(false)}>
                                    <CloseOutline />
                                </div>
                            ) : (
                                <div className="font-semibold cursor-pointer" onClick={() => setExpandBar(true)}>
                                    <ExpandOutline />
                                </div>
                            )}
                        </div>

                        {isLoading ? (
                            <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                <div className='w-full flex items-center justify-center'>
                                    <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white rounded-lg text-black flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                <div style={{ width: '100%', height: 350 }}>
                                    <ResponsiveContainer>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={(DATA_MONTHLY.length > 0) ? DATA_MONTHLY : (DATA.length > 1) ? DATA : (DATA_HOURLY.length > 0) ? DATA_HOURLY : []}
                                            syncId="anyId"
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis unit={'€'} name="GMV" />

                                            {DATA_MONTHLY.length > 0 ? (
                                                <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                                            ) : (
                                                <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                                            )}

                                            <Bar dataKey="GMV" stackId="a" fill="#26BFBF" />

                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className={`w-full ${isExpandLine ? 'md:w-full' : 'md:w-1/2 md:pl-11'} order-2 md:order-1`}>

                        <div className='flex items-center justify-between w-full'>
                            <div className="font-semibold">
                                Orders Performance
                            </div>
                            {isExpandLine ? (
                                <div className="font-semibold cursor-pointer" onClick={() => setExpandLine(false)}>
                                    <CloseOutline />
                                </div>
                            ) : (
                                <div className="font-semibold cursor-pointer" onClick={() => setExpandLine(true)}>
                                    <ExpandOutline />
                                </div>
                            )}
                        </div>

                        {isLoading ? (
                            <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                <div className='w-full flex items-center justify-center'>
                                    <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white rounded-lg text-black flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                                <div style={{ width: '100%', height: 350 }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart
                                            width={500}
                                            height={300}
                                            data={(DATA_MONTHLY.length > 0) ? DATA_MONTHLY : (DATA.length > 1) ? DATA : (DATA_HOURLY.length > 0) ? DATA_HOURLY : []}
                                            syncId="anyId"
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            {DATA_MONTHLY.length > 0 ? (
                                                <Tooltip content={<CustomTooltip2 />} cursor={{ fill: "transparent" }} />
                                            ) : (

                                                <Tooltip content={<CustomTooltip2 />} cursor={{ fill: "transparent" }} />
                                            )}

                                            <Line dataKey="pending" type="monotone" stroke="#FCE205" />
                                            <Line dataKey="accepted" type="monotone" stroke="#9AD6AB" />
                                            <Line dataKey="delivering" type="monotone" stroke="#88D1F1" />
                                            <Line dataKey="delivered" type="monotone" stroke="#ff8b3d" />
                                            <Line dataKey="complete" type="monotone" stroke="green" />
                                            <Line dataKey="rejected" type="monotone" stroke="#ec1529" />
                                            <Line dataKey="canceled" type="monotone" stroke="#ec1529" />
                                            <Line dataKey="refund" type="monotone" stroke="#ec1529" />
                                            <Line dataKey="total" type="monotone" stroke="#3d3d3d" />

                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        )}

                    </div>


                </div> */}

                {/* ANALYTICS */}
                <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                    <div className="w-full">
                        <div className="font-semibold capitalize">
                            {TRANSLATE.wd_analytics}
                        </div>
                        <div className="min-h-[150px] mt-2 mb-4 grid md:grid-cols-5 grid-cols-2 gap-3">
                            <div className={"bg-white w-full rounded-lg flex flex-col items-center justify-center cursor-pointer min-h-[150px] " + (FILTER === 'received' ? 'border-2 border-bagreen-100' : 'border border-zinc-200 hover:border-zinc-400')} onClick={() => setFilter('received')}>
                                <h2 className='mb-1'>
                                    {TotalOrders}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_received}
                                </span>
                            </div>
                            <div className={"bg-white w-full rounded-lg flex flex-col items-center justify-center cursor-pointer min-h-[150px] " + (FILTER === 'yellow' ? 'border-2 border-bagreen-100' : 'border border-zinc-200 hover:border-zinc-400')} onClick={() => setFilter('yellow')}>
                                <h2 className='mb-1 text-yellow-500'>
                                    {RateYellow.toFixed(2)}%
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_pending_rate}
                                </span>
                            </div>
                            <div className={"bg-white w-full rounded-lg flex flex-col items-center justify-center cursor-pointer min-h-[150px] " + (FILTER === 'green' ? 'border-2 border-bagreen-100' : 'border border-zinc-200 hover:border-zinc-400')} onClick={() => setFilter('green')}>
                                <h2 className='mb-1 text-green-600'>
                                    {RateGreen.toFixed(2)}%
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_acceptance_rate}
                                </span>
                            </div>
                            <div className={"bg-white w-full rounded-lg flex flex-col items-center justify-center cursor-pointer min-h-[150px] " + (FILTER === 'red' ? 'border-2 border-bagreen-100' : 'border border-zinc-200 hover:border-zinc-400')} onClick={() => setFilter('red')}>
                                <h2 className='mb-1 text-red-500'>
                                    {RateRed.toFixed(2)}%
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_rejection_rate}
                                </span>
                            </div>

                            {/* <div className="bg-white w-full border border-zinc-200 rounded-lg  min-h-[150px] flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {BabonboScore.toFixed(2)}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_score}
                                </span>
                            </div> */}

                        </div>
                    </div>
                </div>

                {/* PERFORMANCE */}
                <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                    <div className="w-full">
                        <div className="font-semibold capitalize">
                            {TRANSLATE.wd_performance}
                        </div>
                        <div className=" mt-2 mb-4  grid md:grid-cols-5 grid-cols-2 gap-3">
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400 col-span-2 md:col-span-1">
                                <h2 className='mb-1'>
                                    {numberWithCommas(TotalTransactions, 'eur')}
                                </h2>
                                <span className="text-xs capitalize">
                                    Total Transactions
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1 text-bagreen-100'>
                                    {numberWithCommas(TotalProvider, 'eur')}
                                </h2>
                                <span className="text-xs capitalize">
                                    Total Providers
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1 text-babonbo-100'>
                                    {numberWithCommas(TotalFees, 'eur')}
                                </h2>
                                <span className="text-xs capitalize">
                                    Total Fees
                                </span>
                            </div>
                        </div>
                        <div className=" mt-2 mb-4  grid md:grid-cols-5 grid-cols-2 gap-3">

                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {TotalCustomers}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_total_customers}
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {AverageCustomersOrders.toFixed(1)}
                                </h2>
                                <span className="text-xs text-center capitalize">
                                    Orders Per Customer
                                </span>
                            </div>

                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {TotalDays}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_total_days}
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {numberWithCommas(AverageOrderValue, 'eur')}
                                </h2>
                                <span className="text-xs text-center capitalize">
                                    {TRANSLATE.wd_aov}
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {AverageOrderDuration.toFixed(1)}
                                </h2>
                                <span className="text-xs text-center capitalize">
                                    Average Order Duration
                                </span>
                            </div>


                        </div>
                        <div className=" mt-2 mb-4  grid md:grid-cols-5 grid-cols-2 gap-3">

                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {TotalProviders}
                                </h2>
                                <span className="text-xs capitalize">
                                    Total Providers
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {AverageProvidersOrders.toFixed(1)}
                                </h2>
                                <span className="text-xs capitalize">
                                    Orders Per Provider
                                </span>
                            </div>

                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {AverageCustomersProvider.toFixed(1)}
                                </h2>
                                <span className="text-xs text-center capitalize">
                                    Customers per Provider
                                </span>
                            </div>

                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {TotalProducts}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_total_products}
                                </span>
                            </div>

                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-not-allowed hover:border-zinc-400">
                                <h2 className='mb-1'>
                                    {numberWithCommas(AverageOrderProduct, 'eur')}
                                </h2>
                                <span className="text-xs capitalize">
                                    Average Product Value
                                </span>
                            </div>


                        </div>
                    </div>
                </div>

                {/* ORDERS */}
                <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                    <div className="w-full">
                        <div className="font-semibold capitalize">
                            {TRANSLATE.wd_orders}
                        </div>
                        <div className="min-h-[150px] mt-2 mb-4 grid md:grid-cols-7 grid-cols-3 gap-3">

                            <div className="md:col-span-1 col-span-2 bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/' + LANGUAGE + '/dashboard/orders?filter=pending')}>
                                <h2 className='mb-1'>
                                    {TotalPending}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_pending}
                                </span>
                            </div>
                            <div className="md:col-span-1 col-span-1 bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/' + LANGUAGE + '/dashboard/orders?filter=accepted')}>
                                <h2 className='mb-1'>
                                    {TotalAccepted}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_accepted}
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/' + LANGUAGE + '/dashboard/orders?filter=delivering')}>
                                <h2 className='mb-1'>
                                    {TotalDelivering}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_delivering}
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/' + LANGUAGE + '/dashboard/orders?filter=delivered')}>
                                <h2 className='mb-1'>
                                    {TotalDelivered}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_delivered}
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/' + LANGUAGE + '/dashboard/orders?filter=complete')}>
                                <h2 className='mb-1'>
                                    {TotalComplete}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_completed}
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/' + LANGUAGE + '/dashboard/orders?filter=rejected')}>
                                <h2 className='mb-1'>
                                    {TotalRejected}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_rejected}
                                </span>
                            </div>
                            <div className="bg-white min-h-[150px] md:col-span-1 col-span-2 w-full border border-zinc-200 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-zinc-400" onClick={() => history.push('/' + LANGUAGE + '/dashboard/orders?filter=canceled')}>
                                <h2 className='mb-1'>
                                    {TotalCanceled}
                                </h2>
                                <span className="text-xs capitalize">
                                    {TRANSLATE.wd_canceled}
                                </span>
                            </div>


                        </div>
                    </div>
                </div>

                {/* MONTHLY */}
                {FinalMonthly.length > 0 && (
                    <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                        <AnalyticsMonthly DATA={FinalMonthly} {...props} />
                    </div>
                )}


                {/* STORES */}
                <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                    <AnalyticsStores DATA={FinalTopStores} {...props} />
                </div>

                {/* COUNTRIES & CITIES */}
                <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                    <AnalyticsCountries DATA={CurrentTopCountries} {...props} />
                    <AnalyticsCities DATA={CurrentTopCities} {...props} />
                </div>


                {/* PRODUCTS */}
                <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                    <AnalyticsProducts DATA={FinalTopProducts} {...props} />
                </div>


                {/* DAYS */}
                <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                    <AnalyticsDays DATA={FinalDaily} STORE_ID={CurrentStore?.id} LOCATION_ID={CurrentLocation?.id} {...props} />
                </div>



            </div >
        </>
    )


}

//Stores
const AnalyticsStores = (props) => {

    const { TRANSLATE, DATA, history, match, location } = props;

    // return (
    //     <div style={{whiteSpace: 'break-spaces'}}>
    //         {JSON.stringify(DATA, null, 2)}
    //     </div>
    // )


    //Table Data
    const columns = [
        {
            name: "id",
            label: "STORE",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (id, dir) => {

                    const rowIndex = DATA.findIndex((item) => item.id === id);
                    const storeObx = rowIndex != -1 ? DATA[rowIndex] : {};

                    const store_id = storeObx.id || '';
                    const store_name = storeObx.name || '';
                    const store_img = storeObx.img || '';

                    const dataIndex = parseFloat(rowIndex) + 1;

                    return (
                        <div className='flex items-center'>
                            <div className='font-bold mr-2'>
                                {dataIndex + ')'}
                            </div>
                            <Chip
                                avatar={<Avatar alt={store_name} src={store_img} />}
                                label={store_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => history.push('/admin/stores/profile/' + store_id)}
                            />
                        </div>
                    )
                }
            },

        },
        {
            name: 'revenue',
            label: 'GMV',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label || 0).toFixed(2);
                    const final_total = numberWithCommas(parseFloat(total), 'eur');

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={final_total} size='small' onClick={() => history.push('/admin/stores/profile/' + store_id)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'earning',
            label: 'Earnings',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label).toFixed(2);
                    const final_total = numberWithCommas(parseFloat(total), 'eur');

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={final_total} size='small' onClick={() => history.push('/admin/stores/profile/' + store_id)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total',
            label: 'ORDERS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&store=' + store_id)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'pending',
            label: 'PENDING',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'warning' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=pending&store=' + store_id)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'accepted',
            label: 'ACCEPTED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'success' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=accepted&store=' + store_id)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'rejected',
            label: 'REJECTED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';
                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'error' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=rejected&store=' + store_id)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'completed',
            label: 'COMPLETED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';
                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'success' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=complete&store=' + store_id)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'canceled',
            label: 'CANCELED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'error' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=canceled&store=' + store_id)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'delivering',
            label: 'DELIVERING',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'info' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=delivering&store=' + store_id)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'delivered',
            label: 'DELIVERED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = DATA[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'warning' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=delivered&store=' + store_id)} />
                        </div>
                    )
                }
            }

        },

    ];

    const data = DATA.map((item, index) => {

        moment.locale(TRANSLATE.lang);

        let new_store = {
            id: item.id,
            name: item.name,
            img: item.img,
            total: item.total,
            revenue: item.revenue,
            earning: item.earning,
            first: item.first,
            last: item.last,
            pending: item.pending || 0,
            accepted: item.accepted || 0,
            rejected: item.rejected || 0,
            completed: item.completed || 0,
            canceled: item.canceled || 0,
            delivering: item.delivering || 0,
            delivered: item.delivered || 0
        }

        return new_store
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        count: DATA.length,
        rowsPerPage: 5,
        rowsPerPageOptions: [],
        elevation: 0,
        search: false,
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false
    };


    return (

        <div className='w-full'>
            <div className="font-semibold capitalize mb-2">
                Stores Performance
            </div>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </div>

    );

}

//Countries
const AnalyticsCountries = (props) => {

    const { TRANSLATE, DATA, history, match, location } = props;

    // return (
    //     <div style={{whiteSpace: 'break-spaces'}}>
    //         {JSON.stringify(DATA, null, 2)}
    //     </div>
    // )


    //Table Data
    const columns = [
        {
            name: "country",
            label: "COUNTRY",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const country_index = Countries_EN.findIndex((cty) => cty.value === label);
                    const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
                    const country_name = country_obx ? country_obx.label : null;

                    const rowIndex = dir.rowIndex;
                    const dataIndex = parseFloat(rowIndex) + 1;

                    return (
                        <div className='flex items-center'>
                            <div className='font-bold mr-2'>
                                {dataIndex + ')'}
                            </div>
                            <Chip
                                avatar={<ReactCountryFlag countryCode={label} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                                label={country_name?.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                            />
                        </div>
                    )
                }
            },

        },
        {
            name: 'value',
            label: 'TOTAL ORDERS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            }

        },


    ];

    const data = DATA.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_data = {
            country: item.point,
            value: item.value || 0,
        }

        return new_data
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        count: DATA.length,
        rowsPerPage: 5,
        rowsPerPageOptions: [],
        elevation: 0,
        search: false,
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false
    };


    return (

        <div className='w-full mb-2'>
            <div className="font-semibold capitalize mb-2">
                Countries Performance
            </div>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </div>

    );

}

//Cities
const AnalyticsCities = (props) => {

    const { TRANSLATE, DATA, history, match, location } = props;

    // return (
    //     <div style={{whiteSpace: 'break-spaces'}}>
    //         {JSON.stringify(DATA, null, 2)}
    //     </div>
    // )


    //Table Data
    const columns = [
        {
            name: "city",
            label: "CITY",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};

                    const country_code = dataObx.code;

                    const dataIndex = parseFloat(rowIndex) + 1;

                    return (
                        <div className='flex items-center'>
                            <div className='font-bold mr-2'>
                                {dataIndex + ')'}
                            </div>
                            <Chip
                                avatar={<ReactCountryFlag countryCode={country_code} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                                label={label.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                            />
                        </div>
                    )
                }
            },

        },
        {
            name: 'value',
            label: 'TOTAL ORDERS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            }

        },

    ];

    const data = DATA.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_store = {
            city: item.point,
            value: item.value,
            country: item.code
        }

        return new_store
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        count: DATA.length,
        rowsPerPage: 5,
        rowsPerPageOptions: [],
        elevation: 0,
        search: false,
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false
    };


    return (

        <div className='w-full mb-2'>
            <div className="font-semibold capitalize mb-2">
                Cities Performance
            </div>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </div>

    );

}

//Products
const AnalyticsProducts = (props) => {

    const { TRANSLATE, DATA, history, match, location } = props;

    // return (
    //     <div style={{whiteSpace: 'break-spaces'}}>
    //         {JSON.stringify(DATA, null, 2)}
    //     </div>
    // )


    //Table Data
    const columns = [
        {
            name: "id",
            label: "PRODUCT",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (id, dir) => {

                    const rowIndex = DATA.findIndex((item) => item.id === id);
                    const productObx = rowIndex != -1 ? DATA[rowIndex] : {};

                    const product_id = productObx.id || '';
                    const product_name = productObx.name || '';
                    const product_img = productObx.img || '';

                    const dataIndex = parseFloat(rowIndex) + 1;

                    return (
                        <div className='flex items-center'>
                            <div className='font-bold mr-2'>
                                {dataIndex + ')'}
                            </div>
                            <Chip
                                avatar={<Avatar alt={product_name} src={product_img} />}
                                label={product_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => history.push('/admin/products/profile/' + product_id)}
                            />
                        </div>
                    )
                }
            },

        },
        {
            name: 'total',
            label: 'TOTAL ORDERS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            }

        },

    ];

    const data = DATA.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_store = {
            id: item.id,
            name: item.name,
            img: item.img,
            total: item.total
        }

        return new_store
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        count: DATA.length,
        rowsPerPage: 5,
        rowsPerPageOptions: [],
        elevation: 0,
        search: false,
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false
    };


    return (

        <div className='w-full'>
            <div className="font-semibold capitalize mb-2">
                Products Performance
            </div>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </div>

    );

}

//Days
const AnalyticsDays = (props) => {

    const { TRANSLATE, DATA, history, STORE_ID, LOCATION_ID } = props;

    // return (
    //     <div style={{whiteSpace: 'break-spaces'}}>
    //         {JSON.stringify(DATA, null, 2)}
    //     </div>
    // )




    //Table Data
    const columns = [
        {
            name: "id",
            label: "DAY",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (id, dir) => {

                    const rowIndex = DATA.findIndex((item) => item.name === id);
                    const dataObx = rowIndex != -1 ? DATA[rowIndex] : {};

                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');
                    const textFormat = newMoment.format('dddd DD MMMM YYYY');

                    const total = dataObx.total || 0;
                    let color = total >= 20 ? 'green' : 'red';

                    if (STORE_ID || LOCATION_ID) {
                        color = total > 0 ? 'green' : 'red';
                    }

                    const moonPhase = SunCalc.getMoonIllumination(newMoment).phase;
                    const { title, icon } = getMoonPhaseName(moonPhase);

                    return (
                        <div>
                            <Chip
                                avatar={<MuiTooltip title={title}><img src={`/assets/moon/${icon}`} style={{ width: 25, height: 25, borderRadius: '50%' }} alt={title} /></MuiTooltip>}
                                label={textFormat}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&start=' + urlFormat + '&end=' + urlFormat)}

                            />
                        </div>
                    )
                }
            },

        },
        {
            name: 'revenue',
            label: 'GMV',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;

                    const total = parseFloat(label || 0).toFixed(2);
                    const final_total = numberWithCommas(parseFloat(total), 'eur');

                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={final_total} size='small' onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total',
            label: 'ORDERS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total_orders = parseInt(label);



                    return (
                        <>
                            {(total_orders === 0) ? (
                                <div className='flex items-center justify-center'>
                                    <Chip label={label} size='small' color='error'
                                        onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&start=' + urlFormat + '&end=' + urlFormat)} />
                                </div>
                            ) : (total_orders > 0 && total_orders < 20) ? (
                                <div className='flex items-center justify-center'>
                                    <Chip label={label} size='small' color='warning'
                                        onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&start=' + urlFormat + '&end=' + urlFormat)} />
                                </div>
                            ) : (total_orders >= 20 && total_orders < 30) ? (
                                <div className='flex items-center justify-center'>
                                    <Chip label={label} size='small' color='success'
                                        onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&start=' + urlFormat + '&end=' + urlFormat)} />
                                </div>
                            ) : (total_orders >= 30) ? (
                                <div className='flex items-center justify-center'>
                                    <Chip label={label} size='small' color={'info'}
                                        onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&start=' + urlFormat + '&end=' + urlFormat)} />
                                </div>
                            ) : null}
                        </>

                    )
                }
            }

        },
        {
            name: 'pending',
            label: 'PENDING',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {
                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'warning' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=pending&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'accepted',
            label: 'ACCEPTED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'success' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=accepted&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'rejected',
            label: 'REJECTED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'error' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=rejected&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'completed',
            label: 'COMPLETED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'success' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=complete&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'canceled',
            label: 'CANCELED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'error' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=canceled&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'delivering',
            label: 'DELIVERING',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'info' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=delivering&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'delivered',
            label: 'DELIVERED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'warning' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=delivered&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )

                }
            }

        },

    ];

    const data = DATA.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_data = {
            id: item.name,
            name: item.formatted,
            date: item.date,
            total: item.total,
            revenue: item.revenue,
            pending: item.pending || 0,
            accepted: item.accepted || 0,
            rejected: item.rejected || 0,
            completed: item.complete || 0,
            canceled: item.canceled || 0,
            delivering: item.delivering || 0,
            delivered: item.delivered || 0
        }

        return new_data
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        count: DATA.length,
        pagination: false,
        elevation: 0,
        search: false,
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false
    };


    return (

        <div className='w-full' id="section_daily_performance">
            <div className="font-semibold capitalize mb-2">
                Daily Performance
            </div>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </div>

    );

}

//Monthly
const AnalyticsMonthly = (props) => {

    const { TRANSLATE, DATA, history, match, location } = props;

    // return (
    //     <div style={{whiteSpace: 'break-spaces'}}>
    //         {JSON.stringify(DATA, null, 2)}
    //     </div>
    // )

    //Table Data
    const columns = [
        {
            name: "id",
            label: "DAY",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (id, dir) => {

                    const rowIndex = DATA.findIndex((item) => item.name === id);
                    const dataObx = rowIndex != -1 ? DATA[rowIndex] : {};

                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');
                    const textFormat = dataObx.formatted;

                    const total = dataObx.total || 0;
                    const color = total >= 300 ? 'green' : 'red';

                    return (
                        <div>
                            <Chip
                                avatar={<div style={{ width: 25, height: 25, borderRadius: '50%', background: color }} />}
                                label={textFormat}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&start=' + urlFormat + '&end=' + urlFormat)}
                            />
                        </div>
                    )
                }
            },

        },
        {
            name: 'revenue',
            label: 'GMV',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;

                    const total = parseFloat(label || 0).toFixed(2);
                    const final_total = numberWithCommas(parseFloat(total), 'eur');

                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={final_total} size='small' onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total',
            label: 'ORDERS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'pending',
            label: 'PENDING',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {
                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'warning' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=pending&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'accepted',
            label: 'ACCEPTED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'success' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=accepted&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'rejected',
            label: 'REJECTED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'error' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=rejected&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'completed',
            label: 'COMPLETED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'success' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=complete&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'canceled',
            label: 'CANCELED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'error' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=canceled&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'delivering',
            label: 'DELIVERING',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'info' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=delivering&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )
                }
            }

        },
        {
            name: 'delivered',
            label: 'DELIVERED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const dataObx = DATA[rowIndex] || {};
                    const newDate = dataObx.date || '';
                    const newMoment = moment.utc(newDate);
                    const urlFormat = newMoment.format('DD-MM-YYYY');

                    const total = parseFloat(label) || 0
                    const color = total > 0 ? 'warning' : 'default'

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' color={color} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&status=delivered&start=' + urlFormat + '&end=' + urlFormat)} />
                        </div>
                    )

                }
            }

        },

    ];

    const data = DATA.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_data = {
            id: item.name,
            name: item.formatted,
            date: item.date,
            total: item.total,
            revenue: item.revenue,
            pending: item.pending || 0,
            accepted: item.accepted || 0,
            rejected: item.rejected || 0,
            completed: item.complete || 0,
            canceled: item.canceled || 0,
            delivering: item.delivering || 0,
            delivered: item.delivered || 0
        }

        return new_data
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        count: DATA.length,
        pagination: false,
        elevation: 0,
        search: false,
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false
    };


    return (

        <div className='w-full'>
            <div className="font-semibold capitalize mb-2">
                Monthly Performance
            </div>
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
            />
        </div>

    );

}



//Helper
const getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}

const GetRouteSearchParams = (query) => {
    const routeParams = getQueryStringParams(query) || {};
    return routeParams
}

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                let [key, value] = param.split('=');
                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                return params;
            }, {}
            )
        : {}
};

const SortDataArray = (Input, Switch) => {

    let Output = Input;
    if (Switch === 'top_orders') {
        Output = Input.sort((a, b) => b.total - a.total);
    }
    else if (Switch === 'best_revenue') {
        Output = Input.sort((a, b) => b.revenue - a.revenue);
    }
    else if (Switch === 'timeline') {
        Output = Input;
    }
    else {
        Output = Input.sort((a, b) => b[Switch] - a[Switch]);
    }

    return Output
}

const getMoonPhaseName = (phase) => {
    if (phase < 0.0625) return { title: 'New Moon', icon: 'new-moon.png' };
    if (phase < 0.1875) return { title: 'Waxing Crescent', icon: 'waxing-crescent.png' };
    if (phase < 0.3125) return { title: 'First Quarter', icon: 'first-quarter.png' };
    if (phase < 0.4375) return { title: 'Waxing Gibbous', icon: 'waxing-gibbous.png' };
    if (phase < 0.5625) return { title: 'Full Moon', icon: 'full-moon.png' };
    if (phase < 0.6875) return { title: 'Waning Gibbous', icon: 'waning-gibbous.png' };
    if (phase < 0.8125) return { title: 'Last Quarter', icon: 'last-quarter.png' };
    if (phase < 0.9375) return { title: 'Waning Crescent', icon: 'waning-crescent.png' };
    return { title: 'New Moon', icon: 'new-moon.png' }; // New Moon (repeating the New Moon phase for simplicity)
};


export default AnalyticsCalendar

