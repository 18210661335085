import React, { useEffect, useState } from "react";

import Product from "../General/Product.js";

import ProductModal from "../General/ProductModal";

import {
  onFormatUrl,
  GetRouteSearchParams,
  onFormatPrices,
  numberWithCommas,
  onFetchCurrency,
} from "../../helpers/GeneralHelper";

import { postApiPrivate } from "../../api.js";

import moment from "moment";
import swal from "sweetalert";
import { deviceType } from "react-device-detect";

import Stars from "../General/Stars";

import CategoryFilter from "../General/CategoryFilter.js"
import ReviewsList from "../General/ReviewList";
import DeliveryList from "../General/DeliveryList.js";

import MissingProduct from "../General/MissingProduct.js";

import FlowMap from "../General/FlowMap";

import SearchAutoComplete from "../General/SearchAutoComplete";


import { Times_Array } from "../Constants/Constants.js";


import Countries_EN from "../Search/countries_en.json";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, { utils } from "react-modern-calendar-datepicker";

import ReactCountryFlag from "react-country-flag";
import axios from "axios";
import MapComponent from "../General/MapClean.js";
import ProviderCard from "../General/ProviderCard.js";

const AdminPlayground = (props) => {
  const {
    onClose,
    match,
    history,
    LANGUAGE,
    TRANSLATE,
    location,
  } = props;

  const [isResults, setResults] = useState(false);

  const [CHECKIN, setCheckin] = useState(moment().format('DD/MM/YYYY'));
  const [CHECKOUT, setCheckout] = useState(moment().add(3, 'days').format('DD/MM/YYYY'));

  const [LAST_SEARCH, setLastSearch] = useState(null);
  const [CITY, setCity] = useState(null);

  const [PICKUP, setPickup] = useState(null);
  const [DROPOFF, setDropoff] = useState(null);

  const [START_DATE, setStartDate] = useState(null);
  const [END_DATE, setEndDate] = useState(null);

  const [DATE_RANGE, setDateRange] = useState({
    from: null,
    to: null,
  });


  useEffect(() => {
    const lastSearchString = localStorage.getItem("lastSearch");
    if (lastSearchString) {
      const cleanedSearch = lastSearchString
        .replace("http://localhost:3000", "")
        .replace("http://www.babonbo.com", "")
        .replace("http://beta.babonbo.com", "")
        .replace("https://localhost:3000", "")
        .replace("https://www.babonbo.com", "")
        .replace("https://beta.babonbo.com", "")

      setLastSearch(cleanedSearch);
    }

    const newCheckin = moment(CHECKIN, "DD-MM-YYYY");
    const newCheckout = moment(CHECKOUT, "DD-MM-YYYY");

    const newStart = {
      day: parseInt(newCheckin.format("D")),
      month: parseInt(newCheckin.format("M")),
      year: parseInt(newCheckin.format("YYYY")),
    };

    const newEnd = {
      day: parseInt(newCheckout.format("D")),
      month: parseInt(newCheckout.format("M")),
      year: parseInt(newCheckout.format("YYYY")),
    };

    setStartDate(newStart);
    setEndDate(newEnd);
    setDateRange({
      from: newStart,
      to: newEnd,
    });

  }, []);

  useEffect(() => {
    const routeParams = GetRouteSearchParams(location.search);
    const routeId = routeParams.id || '';

    if (routeId) {
      setResults(true);
    }
    else {
      setResults(false);
    }

  }, [location.search])

  const onSelectSearch = () => {

    if (!CITY) {
      return swal(
        "Missing City",
        "Where would you like to rent your gear ?",
        "warning"
      );
    }

    if (!START_DATE) {
      return swal("Missing Checkin", "Please insert a checkin date", "warning");
    }

    if (!END_DATE) {
      return swal(
        "Missing Checkout",
        "Please insert a checkout date",
        "warning"
      );
    }

    if (!PICKUP) {
      return swal(
        "Missing Delivery Address",
        "Please insert the address for the baby gear delivery",
        "warning"
      );
    }

    if (!DROPOFF) {
      return swal(
        "Missing Return Address",
        "Please insert the address for the baby gear return",
        "warning"
      );
    }

    //Format Dates
    const FORMATTED_START = formatDateInputStart(START_DATE);
    const FORMATTED_END = formatDateInputEnd(END_DATE);

    //Format Delivery
    const FOUND_PICKUP = { city: PICKUP.address.city || PICKUP.address.county, input: PICKUP.display_name, boundingBox: JSON.stringify(PICKUP.boundingbox) };
    const FOUND_DROPOFF = { city: DROPOFF.address.city || PICKUP.address.county, input: DROPOFF.display_name, boundingBox: JSON.stringify(DROPOFF.boundingbox) };

    console.log("FOUND_PICKUP ===> ", FOUND_PICKUP);
    console.log("FOUND_DROPOFF ===> ", FOUND_DROPOFF);

    //Encode Delivery
    const ENCODED_PICKUP = new URLSearchParams(FOUND_PICKUP).toString();
    const ENCRYPTED_PICKUP = window.btoa(ENCODED_PICKUP);

    const ENCODED_DROPOFF = new URLSearchParams(FOUND_DROPOFF).toString();
    const ENCRYPTED_DROPOFF = window.btoa(ENCODED_DROPOFF);

    //Generate URL
    let fixedPath = '?id=' + CITY.id;
    let new_url = fixedPath + "&checkin=" + FORMATTED_START + "&checkout=" + FORMATTED_END;

    new_url =
      new_url +
      "&picklat=" +
      PICKUP.latitude +
      "&picklon=" +
      PICKUP.longitude +
      "&droplat=" +
      DROPOFF.latitude +
      "&droplon=" +
      DROPOFF.longitude;

    if (ENCRYPTED_PICKUP) {
      new_url = new_url + "&pickadd=" + ENCRYPTED_PICKUP;
    }

    if (ENCRYPTED_DROPOFF) {
      new_url = new_url + "&dropadd=" + ENCRYPTED_DROPOFF;
    }

    history.push(new_url);

  };

  const onSelectCity = (city) => {
    setCity(city);
  };

  const onFromDateChange = (date) => {
    setStartDate(date);
    setEndDate(null);
    setDateRange((previous) => ({
      ...previous,
      from: date,
      to: null,
    }));
  };

  const onToDateChange = (date) => {
    if (!START_DATE) {
      alert("You need to select a valid checkin date");
      // alertify
      // 	.delay(4000)
      // 	.closeLogOnClick(true)
      // 	.error('لطفا ابتدا تاریخ ورود را انتخاب کنید');
      return false;
    }

    setStartDate(date.from);
    setEndDate(date.to);
    setDateRange(date);
  };

  const formatDateInputStart = () => {
    if (!START_DATE) return "";

    const year = START_DATE.year.toString();
    const month = START_DATE.month.toString().padStart(2, 0);
    const day = START_DATE.day.toString().padStart(2, 0);
    return `${day}/${month}/${year}`;
  };

  const formatDateInputEnd = () => {
    if (!DATE_RANGE.to) return "";
    const toDate = DATE_RANGE.to;
    const year = toDate.year.toString();
    const month = toDate.month.toString().padStart(2, 0);
    const day = toDate.day.toString().padStart(2, 0);
    return `${day}/${month}/${year}`;
  };

  const search_country = CITY ? CITY.country : "";

  //Last Search
  const urlObject = LAST_SEARCH ? convertURLToObject(LAST_SEARCH) : "";

  if (isResults) {
    return (
      <PlaygroundController {...props} />
    )
  }

  return (
    <>
      <div className="fixed w-screen h-screen top-0 left-0 z-[9999] md:z-[999]">

        <div className="absolute w-full h-full top-0 left-0 z-10 md:bg-black/[.5]" />

        <div className="bg-white w-full relative z-20 fade-in md:from-top md:h-auto h-full">
          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pd:pt-10 pt-4 flex flex-col md:block md:h-auto h-full">
            <div className="flex items-center w-full justify-between mb-10">
              <div>
                <img
                  className="w-[150px] mr-4"
                  src="/assets/img/babonbo_logo.png"
                  alt=""
                  onClick={() => history.push('/')}
                />
              </div>
              <img
                className="cursor-pointer"
                src="/assets/img/close.svg"
                alt=""
                onClick={() => history.push('/')}

              />
            </div>
            <div className="overflow-auto md:overflow-visible scrollbar-hide md:p-0 p-2">
              <div className="flex md:flex-row flex-col justify-between w-full gap-4">
                <SearchPicker
                  {...props}
                  onSelectCity={onSelectCity}
                  search_country={search_country}
                  zIndex={99}
                />

                <div className="w-full md:w-1/2 flex items-end flex-wrap md:flex-nowrap">
                  <div className="input-group md:half-width w-full with-icon pr-2">
                    <label>
                      {TRANSLATE.wd_rental_period}

                    </label>
                    <img
                      className="input-icon z-[900]"
                      src="/assets/img/calendar.svg"
                      alt=""
                    />
                    <span className="inner-label z-50">
                      {TRANSLATE.wd_start}
                    </span>
                    <DatePicker
                      className={props.className}
                      minimumDate={utils().getToday()}
                      value={START_DATE}
                      onChange={onFromDateChange}
                      formatInputText={formatDateInputStart}
                      colorPrimary="#FC6170"
                      colorPrimaryLight="#FFE9EB"
                      calendarPopperPosition="bottom"
                    />
                  </div>
                  <div className="input-group md:half-width w-full">
                    <img
                      className="input-icon z-[900]"
                      src="/assets/img/calendar.svg"
                      alt=""
                    />
                    <span className="inner-label z-50">{TRANSLATE.wd_end}</span>
                    <DatePicker
                      className={props.className}
                      minimumDate={utils().getToday()}
                      value={DATE_RANGE}
                      onChange={onToDateChange}
                      formatInputText={formatDateInputEnd}
                      colorPrimary="#FC6170"
                      colorPrimaryLight="#FFE9EB"
                      calendarPopperPosition="bottom"
                    />
                  </div>
                </div>


              </div>

              <div className="h-20 block md:hidden" />
            </div>

            {search_country && (
              <>

                <div className="overflow-auto md:overflow-visible scrollbar-hide md:p-0 p-2">
                  <div className="flex md:flex-row flex-col justify-between w-full gap-4">
                    <SearchDelivery
                      {...props}
                      onSelectDelivery={(e) => setPickup(e)}
                      display_name={PICKUP?.display_name}
                      title="Delivery Address"
                      placeholder="Where do you want to be delivered ?"
                      zIndex={98}
                      search_country={search_country}
                    />

                    <SearchDelivery
                      {...props}
                      onSelectDelivery={(e) => setDropoff(e)}
                      display_name={DROPOFF?.display_name}
                      title="Pickup Address"
                      placeholder="Where do you want to return the gear ?"
                      zIndex={97}
                      search_country={search_country}
                    />


                  </div>

                  <div className="h-20 block md:hidden" />
                </div>

                <div className="flex items-center w-full bg-white z-[999] md:z-auto p-2 md:p-0 md:relative fixed md:bottom-auto md:left-auto bottom-0 left-0 flex-wrap md:flex-nowrap">
                  {/* {LAST_SEARCH && (
                <div className="w-full flex items-end flex-wrap md:flex-nowrap">
                  <div className="input-group md:half-width w-full with-icon pr-2">
                    
                    <label>
                      Last Search
                    </label>
                    <div
                      className="w-full flex items-center justify-between p-6 md:p-3 border cursor-pointer rounded-lg md:rounded-full hover:border-zinc-500"
                      onClick={() => {
                        history.push(LAST_SEARCH);
                        onClose();
                        return;
                      }}
                    >
                      <div className="w-full flex text-sm md:flex-row flex-col md:gap-x-6">
                        <div className="capitalize">
                          <span class="text-slate-700">City:</span> {urlObject.city} - {urlObject.country}{" "}
                          <ReactCountryFlag countryCode={urlObject.country} />
                        </div>
                        {urlObject.checkin && <div><span class="text-slate-700">Checkin:</span> {urlObject.checkin}</div>}
                        {urlObject.checkout && <div><span class="text-slate-700">Checkout:</span> {urlObject.checkout}</div>}
                      </div>
                      <div>
                        <div className="w-[36px] h-[36px] shrink-0 rounded-lg cursor-pointer flex items-center justify-center hover:bg-zinc-100 relative">
                          <img
                            src={"/assets/img/chevron-right.svg"}
                            alt=""
                            style={{
                              filter: "invert(0.8) sepia(0.1) saturate(0.1)",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
                  <div
                    className="btn-auto pr-8 text-lg ml-auto md:mr-6 w-full md:w-auto justify-center"
                    onClick={() => onSelectSearch()}
                  >
                    <img
                      src="/assets/img/search.svg"
                      style={{ filter: "invert(100%)" }}
                      alt=""
                    />
                    {TRANSLATE.wd_search}
                  </div>
                </div>

              </>
            )}


          </div>


        </div>

      </div>
    </>
  );
};

const SearchPicker = (props) => {
  const {
    TRANSLATE,
    LANGUAGE,
    search_country,
    zIndex,
    onSelectCity,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [SearchResults, setSearchResults] = useState([]);
  const [SearchInput, setSearchInput] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(null);


  const onUpdateSearch = async (search) => {
    console.log("CITY SELECTED ===> ", search);

    //Correct Path
    let searchPath = search[LANGUAGE + "_url"];
    let fixedPath =
      searchPath.replace("/search/", "/flow/") + "/info?id=" + search.id;

    setFocused(false);

    setSearchInput(search[LANGUAGE]);

    onSelectCity(search);
    return;

  };

  const onChangeLocation = async (city) => {
    setSearchInput(city);

    if (!city || city.length <= 3) {
      setFocused(false);
      setSearchResults([]);

      return;
    }


    clearTimeout(debouncedSearch); // Clear the previous debounce timeout
    const timeoutId = setTimeout(() => {
      // Call the Node.js server API to perform the search
      onFetchLocations(city);
    }, 500); // Adjust the delay time as needed

    setDebouncedSearch(timeoutId); // Store the new debounce timeout

  };

  // Function to fetch nearby places from OpenStreetMap API
  const onFetchLocations = async (query) => {

    //alert('calling');

    setLoading(true)

    try {

      const DATA_RESPONSE = await postApiPrivate("/locations/search", { query: query, lang: LANGUAGE });

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
        setLoading(false);
        return;
      }


      const filteredResults = DATA_RESPONSE.data || [];

      if (filteredResults.length > 0) {
        setFocused(true);
        setSearchResults(filteredResults);
      }

      setLoading(false);


    } catch (error) {
      console.error('Error fetching nearby places:', error);
      setFocused(false);
      setSearchResults([]);
      setLoading(false);

    }


  };

  const onSearchKeyUp = async (event) => {
    event.preventDefault();

    if (event.keyCode === 13) {
      event.target.blur();

      if (SearchResults.length > 0) {
        onUpdateSearch(SearchResults[0]);
      }
    }
  };

  return (
    <div className="input-group with-icon w-full md:w-1/2 md:pr-4" style={{ zIndex: zIndex }}>
      <label>
        {TRANSLATE.wd_destination}
      </label>
      <img className="input-icon" src="/assets/img/location.svg" alt="" />
      <span className="inner-label">City</span>
      <input
        type="text"
        placeholder={TRANSLATE.ask_which_city}
        onFocus={() => setFocused(true)}
        onChange={(e) => onChangeLocation(e.target.value)}
        onKeyUp={(e) => onSearchKeyUp(e)}
        value={SearchInput}
        autoComplete="new-password"
        className="pt-0"
      />

      {isLoading ? (
        <div className="w-[45px] h-[45px] rounded-full bg-babonbo-100 hover:bg-babonbo-200 cursor-pointer flex justify-center items-center absolute right-[5px] md:right-[25px] z-30" style={{ top: 40, zIndex: zIndex }}>
          <img
            className="animate-spin w-[20px] h-[20px]"
            src="/assets/img/loader-white.png"
            alt=""
          />
        </div>
      ) : (
        <>
          {search_country && (
            <div className="w-[45px] h-[45px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute right-[5px] md:right-[25px]" style={{ top: 40, zIndex: zIndex }}>
              <img
                className="w-[20px] h-[20px]"
                src="/assets/img/check.svg"
                style={{ filter: "brightness(0) invert(1)" }}
                alt=""
              />
            </div>
          )}
        </>
      )}

      {isFocused && SearchResults.length > 0 && (
        <div className="w-full bg-white rounded-lg drop-shadow-2xl text-black overflow-auto absolute top-[100%] max-h-[40vh]" style={{ zIndex: zIndex }}>
          {SearchResults.map((item, index) => {
            const country_index = Countries_EN.findIndex(
              (cty) => cty.value === item.country
            );
            const country_obx =
              country_index != -1 ? Countries_EN[country_index] : null;
            const country_name = country_obx ? country_obx.label : null;

            return (
              <div
                className="w-full p-3 flex items-center border-b border-zinc-200 cursor-pointer hover:bg-zinc-100"
                onClick={() => onUpdateSearch(item)}
                key={index}
              >
                <ReactCountryFlag svg countryCode={item.country} className="mr-2" />
                {item[LANGUAGE]} - ({country_name || item.country})
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const SearchDelivery = (props) => {

  const {
    TRANSLATE,
    LANGUAGE,
    display_name,
    onSelectDelivery,
    search_country,
    zIndex
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [SearchResults, setSearchResults] = useState([]);
  const [SearchInput, setSearchInput] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(null);


  const onUpdateSearch = async (search) => {
    console.log("DELIVERY SELECTED ===> ", search);

    setFocused(false);

    setSearchInput(search.display_name);

    onSelectDelivery(search);
    return;

  };

  const onChangeLocation = async (city) => {
    setSearchInput(city);

    if (!city || city.length <= 3) {
      setFocused(false);
      setSearchResults([]);

      return;
    }


    clearTimeout(debouncedSearch); // Clear the previous debounce timeout
    const timeoutId = setTimeout(() => {
      // Call the Node.js server API to perform the search
      onFetchLocations(city);
    }, 500); // Adjust the delay time as needed

    setDebouncedSearch(timeoutId); // Store the new debounce timeout

  };


  // Function to fetch nearby places from OpenStreetMap API
  const onFetchLocations = async (query) => {

    //alert('calling');

    setLoading(true)

    const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${query}&countrycodes=${search_country}`;
    try {
      const response = await axios.get(apiUrl);
      setLoading(false);
      //alert("response")
      const nearbyPlaces = response.data.map((place) => ({
        label: place.display_name,
        latitude: place.lat,
        longitude: place.lon,
        ...place
      }));

      const filteredResults = nearbyPlaces || [];

      if (filteredResults.length > 0) {
        setFocused(true);
        setSearchResults(filteredResults);
      }

      setLoading(false);


    } catch (error) {
      console.error('Error fetching nearby places:', error);
      setFocused(false);
      setSearchResults([]);
      setLoading(false);

    }


  };

  const onSearchKeyUp = async (event) => {
    event.preventDefault();

    if (event.keyCode === 13) {
      event.target.blur();

      if (SearchResults.length > 0) {
        onUpdateSearch(SearchResults[0]);
      }
    }
  };

  return (
    <div className="input-group with-icon w-full md:w-1/2 md:pr-4" style={{ zIndex: zIndex }}>
      {/* <pre>{JSON.stringify(SearchResults, null, 2)}</pre> */}
      <label>
        {props.title}
      </label>
      <img className="input-icon" src="/assets/img/location.svg" alt="" />
      <span className="inner-label">{props.title}</span>
      <input
        type="text"
        placeholder={props.placeholder}
        onFocus={() => setFocused(true)}
        onChange={(e) => onChangeLocation(e.target.value)}
        onKeyUp={(e) => onSearchKeyUp(e)}
        value={SearchInput}
        autoComplete="new-password"
        className="pt-0"
      />

      {isLoading ? (
        <div className="w-[45px] h-[45px] rounded-full bg-babonbo-100 hover:bg-babonbo-200 cursor-pointer flex justify-center items-center absolute right-[5px] md:right-[25px]" style={{ top: 40, zIndex: zIndex }}>
          <img
            className="animate-spin w-[20px] h-[20px]"
            src="/assets/img/loader-white.png"
            alt=""
          />
        </div>
      ) : (
        <>
          {display_name && (
            <div className="w-[45px] h-[45px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute right-[5px] md:right-[25px]" style={{ top: 40, zIndex: zIndex }}>
              <img
                className="w-[20px] h-[20px]"
                src="/assets/img/check.svg"
                style={{ filter: "brightness(0) invert(1)" }}
                alt=""
              />
            </div>
          )}
        </>
      )}

      {/* <pre>{JSON.stringify(SearchResults, null, 2)}</pre> */}

      {isFocused && SearchResults.length > 0 && (
        <div className="w-full bg-white rounded-lg drop-shadow-2xl text-black overflow-auto absolute top-[100%] max-h-[40vh]" style={{ zIndex: zIndex }}>
          {SearchResults.map((item, index) => {

            return (
              <div
                className="w-full p-3 flex items-center border-b border-zinc-200 cursor-pointer hover:bg-zinc-100"
                onClick={() => onUpdateSearch(item)}
                key={index}
              >
                <img src={"/assets/img/location.svg"} alt="" />
                {item.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};


function convertQueryStringToObject(queryString, url) {
  const urlParams = new URLSearchParams(queryString);
  const queryParams = {};

  for (const [key, value] of urlParams.entries()) {
    queryParams[key] = value;
  }

  // Extract "milan" and "IT" from the URL
  queryParams.city = url.pathname.split("/")[3];
  queryParams.country = url.pathname.split("/")[4];

  return queryParams;
}

function convertURLToObject(urlString) {
  if (!urlString) {
    return {}
  }

  if (!urlString.includes('http')) {
    urlString = 'https://www.babonbo.com' + urlString;
  }

  const url = new URL(urlString);
  const queryString = url.search.slice(1); // Remove the leading "?" character

  return convertQueryStringToObject(queryString, url);
}

const PlaygroundController = (props) => {
  const {
    match,
    LANGUAGE,
    TRANSLATE,
    location,
    USER,
    onUpdateCart,
    onFetchDates,
    history,
  } = props;

  const CART = [];

  const [CURRENCY, setCurrency] = useState('eur');

  const [isLoading, setLoading] = useState(true);
  const [CITY, setCity] = useState(null);
  const [RESULTS, setResults] = useState([]);
  const [FILTERED, setFiltered] = useState([]);
  const [PRODUCTS, setProducts] = useState([]);
  const [TOTAL, setTotal] = useState(0);

  const [isProductModal, setProductModal] = useState(false);
  const [CurrentProductModal, setCurrentProductModal] = useState(null);
  const [CurrentProviderModal, setCurrentProviderModal] = useState(null);
  const [CurrentProvider, setCurrentProvider] = useState(null);
  const [CurrentOther, setCurrentOther] = useState(null);

  const [isReviews, setReviews] = useState(false);
  const [isDelivery, setDelivery] = useState(false);
  const [isProvider, setProvider] = useState(false);

  const [PickupBackup, setPickupBackup] = useState(null);
  const [DropoffBackup, setDropoffBackup] = useState(null);

  const search_city = match.params.city;
  const search_country = match.params.country;

  const [selectedProvider, setSelectedProvider] = useState(null);


  useEffect(() => {
    if (CURRENCY) {
      onFetchProducts();
    }
  }, [location.search, CURRENCY, LANGUAGE]);

  useEffect(() => {
    if (isProductModal || isReviews || isDelivery || isProvider) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isProductModal, isReviews, isDelivery, isProvider]);

  const onFetchProducts = async () => {
    window.scrollTo(0, 0);

    const JWT =
      typeof global.window !== "undefined"
        ? localStorage.getItem("jwt_token")
        : null;

    const routeParams = GetRouteSearchParams(location.search) || {};

    const PickupString = routeParams.pickadd
      ? window.atob(routeParams.pickadd)
      : "";
    const DropoffString = routeParams.dropadd
      ? window.atob(routeParams.dropadd)
      : "";

    const PickupObx = PickupString ? GetRouteSearchParams(PickupString) : {};
    const DropoffObx = DropoffString ? GetRouteSearchParams(DropoffString) : {};

    console.log("PickupObx ==> ", PickupObx);
    console.log("DropoffObx ==> ", DropoffObx);

    setPickupBackup(PickupObx);
    setDropoffBackup(DropoffObx);

    const RequestOriginal = window.location.toString();
    const RequestUrl = RequestOriginal
      ? RequestOriginal.replace("//localhost:3000", "//www.babonbo.com")
      : "";

    const FORM = {
      id: routeParams.id,
      checkin: routeParams.checkin,
      checkout: routeParams.checkout,
      categories: routeParams.categories,
      age: routeParams.age,
      picklat: routeParams.picklat,
      picklon: routeParams.picklon,
      droplat: routeParams.droplat,
      droplon: routeParams.droplon,
      picktime: routeParams.picktime,
      droptime: routeParams.droptime,

      pickadd: PickupObx.input,
      dropadd: DropoffObx.input,

      pickcity: PickupObx.city,
      dropcity: DropoffObx.city,

      lang: LANGUAGE,
      curid: CURRENCY,

      request: RequestUrl,
      device: deviceType,
    };

    setLoading(true);

    console.log("FORM ===> Client ", FORM);

    // alert(JSON.stringify(FORM, null,2))

    const DATA_RESPONSE = await postApiPrivate("/flow/playground", FORM, JWT);

    if (DATA_RESPONSE.error) {
      console.log("ERROR FROM API LOADING PRODUCTS", DATA_RESPONSE.error);

      // alert('here');
      setLoading(false);

      return;
    }

    const DATA_CITY = DATA_RESPONSE.city;
    const DATA_RESULTS = DATA_RESPONSE.results || [];
    const DATA_FILTERED = DATA_RESPONSE.filtered || [];
    const DATA_PRODUCTS = DATA_RESPONSE.products || [];
    const DATA_TOTAL = DATA_RESPONSE.total || 0;

    //alert('RESULTS ===> '+JSON.stringify(DATA_RESPONSE, null, 2));

    setCity(DATA_CITY);
    setResults(DATA_RESULTS);
    setFiltered(DATA_FILTERED);
    setProducts(DATA_PRODUCTS);
    setTotal(DATA_TOTAL);

    setLoading(false);

    console.log("FETCHING PRODUCTS NEARBY ===> ", DATA_RESPONSE);

    window.scrollTo(0, 0);
  };

  const onCartProductAdded = (product, provider) => {
    const order_index = CART.findIndex((item) => item.store === provider.id);

    if (order_index == -1) {
      console.log("No Order Found");
      return false;
    } else {
      console.log("Found Order at Index ==> ", order_index);
      const current_order = CART[order_index] || {};
      const current_products = current_order.products || [];
      const product_index = current_products.findIndex(
        (item) => item.id === product.id
      );

      if (product_index == -1) {
        return false;
      } else {
        return true;
      }
    }
  };

  const onAddToCart = (product, provider, isOther) => {
    const ProductCategory = product.category;

    const order_index = CART.findIndex((item) => item.store === provider.id);

    if (order_index == -1) {
      console.log("No Order Found");

      if (ProductCategory === "consumable") {
        swal(
          TRANSLATE.alert_cart.consumable_t,
          TRANSLATE.alert_cart.consumable_s,
          "warning"
        );
        return;
      }

      if (CART.length != 0) {
        //There is another order from another store
        swal({
          title: TRANSLATE.alert_cart.multiple_t,
          text: TRANSLATE.alert_cart.multiple_s,
          icon: "warning",
          buttons: [TRANSLATE.btn_cancel, TRANSLATE.alert_cart.multiple_c],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            onUpdateCart([]);
            onStartCart(product, provider, true, isOther);
          }
        });
        return;
      }

      onStartCart(product, provider, false, isOther);
    } else {
      console.log("Found Order at Index ==> ", order_index);
      const current_order = CART[order_index];
      onRetrieveCart(current_order, order_index, product, isOther);
    }
  };

  const onStartCart = async (product, provider, isEmpty, isOther) => {
    let MASTER_CART = CART || [];

    if (isEmpty) {
      MASTER_CART = [];
    }

    let SEGUE_PRODUCT = product || {};

    const routeParams = GetRouteSearchParams(location.search);
    const routeCheckin = moment(routeParams.checkin, "DD-MM-YYYY");
    const routeCheckout = moment(routeParams.checkout, "DD-MM-YYYY");

    const PickupString = routeParams.pickadd
      ? window.atob(routeParams.pickadd)
      : "";
    const DropoffString = routeParams.dropadd
      ? window.atob(routeParams.dropadd)
      : "";

    const PickupObx = PickupString ? GetRouteSearchParams(PickupString) : {};
    const DropoffObx = DropoffString ? GetRouteSearchParams(DropoffString) : {};

    const routeDuration =
      routeParams.checkin && routeParams.checkout
        ? routeCheckout.diff(routeCheckin, "days") + 1
        : 1;

    const SEGUE_PROVIDER = provider || {};
    const SEGUE_CHECKIN = routeCheckin;
    const SEGUE_CHECKOUT = routeCheckout;

    let PROVIDER_DELIVERY = SEGUE_PROVIDER.delivery || [];
    let PROVIDER_PICKUP = SEGUE_PROVIDER.pickup || {};
    let PROVIDER_DROPOFF = SEGUE_PROVIDER.dropoff || {};

    let SEGUE_PICKTIME = routeParams.picktime;
    let SEGUE_DROPTIME = routeParams.droptime;

    PROVIDER_DELIVERY = PROVIDER_DELIVERY.filter((item) => item.geocode);

    PROVIDER_DELIVERY = [
      {
        name: TRANSLATE.wd_provider_place,
        type: "home",
        geocode: SEGUE_PROVIDER.geocode,
        cost: 0,
      },
      ...PROVIDER_DELIVERY,
    ];

    let SEGUE_DELIVERY =
      PROVIDER_PICKUP.name && !isOther
        ? { ...PROVIDER_PICKUP, input: PickupObx.input }
        : PROVIDER_DELIVERY[0];
    let SEGUE_RETURN =
      PROVIDER_DROPOFF.name && !isOther
        ? { ...PROVIDER_DROPOFF, input: DropoffObx.input }
        : PROVIDER_DELIVERY[0];

    if (SEGUE_CHECKOUT && SEGUE_CHECKIN) {
      var startMoment = SEGUE_CHECKIN;
      var endMoment = SEGUE_CHECKOUT;
      var daysMoment = endMoment.diff(startMoment, "days") + 1;

      console.log("START MOMENT ===> ", startMoment);
      console.log("END MOMENT ===> ", endMoment);
      console.log("DAYS MOMENT ===> ", daysMoment);

      const order_subtotal = onCalculateSingleSubtotal(
        SEGUE_PRODUCT,
        daysMoment,
        1
      );

      const order_total = onCalculateSingleTotal(
        order_subtotal,
        SEGUE_DELIVERY,
        SEGUE_RETURN
      );

      const order_obx = onCreateOrder({
        PROVIDER: SEGUE_PROVIDER,
        PRODUCT: SEGUE_PRODUCT,
        CHECKIN: SEGUE_CHECKIN,
        CHECKOUT: SEGUE_CHECKOUT,
        DELIVERY: SEGUE_DELIVERY,
        PICKTIME: SEGUE_PICKTIME,
        DROPTIME: SEGUE_DROPTIME,
        RETURN: SEGUE_RETURN,
        SUBTOTAL: order_subtotal,
        TOTAL: order_total,
        CURRENCY: CURRENCY,
        isOther,
      });

      console.log("ORDER_OBX ===> ", order_obx);

      const new_cart = [order_obx, ...MASTER_CART] || [];
      onUpdateCart(new_cart);

      swal(
        TRANSLATE.alert_cart.added_t,
        TRANSLATE.alert_cart.added_s,
        "success"
      );
    }
  };

  const onRetrieveCart = async (ORDER_OBX, ORDER_INDEX, product, isOther) => {
    console.log("1 --->");

    const routeParams = GetRouteSearchParams(location.search);
    const routeCheckin = moment(routeParams.checkin, "DD-MM-YYYY");
    const routeCheckout = moment(routeParams.checkout, "DD-MM-YYYY");

    let SEGUE_PRODUCT = product || {};

    const SEGUE_CHECKIN = routeCheckin;
    const SEGUE_CHECKOUT = routeCheckout;

    console.log("2 --->");

    if (SEGUE_CHECKOUT && SEGUE_CHECKIN) {
      console.log("5 --->");

      var startMoment = moment(SEGUE_CHECKIN);
      var endMoment = moment(SEGUE_CHECKOUT);
      var daysMoment = endMoment.diff(startMoment, "days") + 1;

      console.log("START MOMENT ===> ", startMoment);
      console.log("END MOMENT ===> ", endMoment);
      console.log("DAYS MOMENT ===> ", daysMoment);

      //Check if Product is Already in Cart
      var order_products = ORDER_OBX.products || [];
      var product_index = order_products.findIndex(
        (item) => item.id === SEGUE_PRODUCT.id
      );

      const ProductCategory = SEGUE_PRODUCT.category;

      if (product_index == -1) {
        console.log("PRODUCT NOT FOUND NEED TO ADD ");

        var new_product = {};

        if (ProductCategory === "consumable") {
          new_product = {
            id: SEGUE_PRODUCT.id,
            name: SEGUE_PRODUCT.name,
            img: SEGUE_PRODUCT.first_img,
            price_day: SEGUE_PRODUCT.price_day,
            quantity: 1,
            category: SEGUE_PRODUCT.category,
          };
        } else {
          new_product = {
            id: SEGUE_PRODUCT.id,
            name: SEGUE_PRODUCT.name,
            img: SEGUE_PRODUCT.first_img,
            price_day: SEGUE_PRODUCT.price_day,
            price_week: SEGUE_PRODUCT.price_week,
            price_month: SEGUE_PRODUCT.price_month,
            category: SEGUE_PRODUCT.category,
            quantity: 1,
          };
        }

        var new_order_products = [...order_products, new_product];

        var new_order = {
          ...ORDER_OBX,
          products: new_order_products,
          checkin: SEGUE_CHECKIN,
          checkout: SEGUE_CHECKOUT,
        };

        var new_cart = [
          ...CART.slice(0, ORDER_INDEX),
          { ...CART[ORDER_INDEX], ...new_order },
          ...CART.slice(ORDER_INDEX + 1),
        ];

        console.log(" NEW CART ===> ", new_cart);

        onUpdateCart(new_cart);

        swal(
          TRANSLATE.alert_cart.added_t,
          TRANSLATE.alert_cart.added_s,
          "success"
        );
      } else {
        console.log("PRODUCT FOUND NEED TO EDIT");

        var new_product = order_products[product_index];

        var new_order_products = [
          ...order_products.slice(0, product_index),
          { ...order_products[product_index], ...new_product },
          ...order_products.slice(product_index + 1),
        ];

        var new_order = {
          ...ORDER_OBX,
          products: new_order_products,
          checkin: SEGUE_CHECKIN,
          checkout: SEGUE_CHECKOUT,
        };

        var new_cart = [
          ...CART.slice(0, ORDER_INDEX),
          { ...CART[ORDER_INDEX], ...new_order },
          ...CART.slice(ORDER_INDEX + 1),
        ];

        console.log(" NEW CART ===> ", new_cart);

        onUpdateCart(new_cart);

        swal(
          TRANSLATE.alert_cart.added_t,
          TRANSLATE.alert_cart.added_s,
          "success"
        );
      }

      if (ProductCategory === "consumable") {
        const PriceFixed = parseFloat(SEGUE_PRODUCT.price_day);
        const order_subtotal = PriceFixed + ((PriceFixed * 15) / 100) * 1;


      } else {
        const order_subtotal = onCalculateSingleSubtotal(
          SEGUE_PRODUCT,
          daysMoment,
          1
        );
      }

      return;
    } else {
      alert("Please Select Rental Periods");
    }
  };

  const onCreateOrder = (props) => {
    const {
      PROVIDER,
      PRODUCT,
      CHECKIN,
      CHECKOUT,
      DELIVERY,
      RETURN,
      SUBTOTAL,
      TOTAL,
      PICKTIME,
      DROPTIME,
      CURRENCY,
      isOther,
    } = props;

    var order_obx = {
      store: PROVIDER.id,
      temp: PROVIDER,
      products: [
        {
          id: PRODUCT.id,
          name: PRODUCT.name,
          img: PRODUCT.first_img,
          price_day: PRODUCT.price_day,
          price_week: PRODUCT.price_week,
          price_month: PRODUCT.price_month,
          category: PRODUCT.category,
          quantity: 1,
        },
      ],
      checkin: CHECKIN,
      checkout: CHECKOUT,
      delivery: DELIVERY,
      return: RETURN,
      subtotal: SUBTOTAL,
      total: TOTAL,
      currency: CURRENCY,
      picktime: PICKTIME,
      droptime: DROPTIME,
    };

    if (!isOther) {
      order_obx = {
        ...order_obx,
        p_delivery: DELIVERY,
        p_return: RETURN,
      };
    }

    return order_obx;
  };

  //Functions
  const onCalculateDailyPrice = (PRODUCT, DURATION) => {
    var { price_day, price_month, price_week } = PRODUCT;

    if (DURATION < 7) {
      return price_day;
    } else if (DURATION >= 7 && DURATION < 30) {
      var PRICE_WEEK_DAILY = price_week / 7;
      return PRICE_WEEK_DAILY;
    } else if (DURATION >= 30) {
      var PRICE_MONTH_DAILY = price_month / 30;
      return PRICE_MONTH_DAILY;
    }
  };

  const onCalculateSingleSubtotal = (PRODUCT, DURATION, QUANTITY) => {
    var current_quantity = PRODUCT.quantity || 1;

    if (QUANTITY) {
      current_quantity = QUANTITY;
    }

    var price_day = onCalculateDailyPrice(PRODUCT, DURATION);
    var subtotal = price_day * DURATION * current_quantity;

    //alert("PRice ===> "+ JSON.stringify({current_quantity, price_day, subtotal}, null, 2));
    return subtotal;
  };

  const onCalculateSingleTotal = (SUBTOTAL, DELIVERY, RETURN) => {
    var DELIVERY_COST = parseFloat(DELIVERY.cost) || 0;
    var RETURN_COST = parseFloat(RETURN.cost) || 0;

    var TOTAL = SUBTOTAL + DELIVERY_COST + RETURN_COST;

    return TOTAL;
  };

  const onSelectStore = (provider) => {
    // setCurrentProviderModal(provider);
    // setDelivery(true);

    const ProviderSection = document.getElementById("section_" + provider.id);

    if (ProviderSection) {
      var headerOffset = 200;
      var elementPosition = ProviderSection.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const onSelectProduct = (product, provider, isOther) => {
    setCurrentProviderModal(provider);
    setCurrentProductModal(product);
    setCurrentOther(isOther);
    setProductModal(true);
  };

  const onSelectReviews = (store_id, status) => {
    setCurrentProvider(store_id);
    setReviews(status);
  };

  const onSelectProvider = (provider, status) => {
    setProvider(status);
    setCurrentProvider(provider ? provider.id : null);
    setCurrentProviderModal(provider ? provider : null);
    // alert('Provider ===> '+JSON.stringify(provider, null, 2));
  };

  const routeParams = GetRouteSearchParams(location.search);
  const routeCheckin = moment(routeParams.checkin, "DD/MM/YYYY");
  const routeCheckout = moment(routeParams.checkout, "DD/MM/YYYY");

  const routeDuration =
    routeParams.checkin && routeParams.checkout
      ? routeCheckout.diff(routeCheckin, "days") + 1
      : 1;

  var PickupString = "";
  var DropoffString = "";

  if (typeof global.window !== "undefined") {
    PickupString = routeParams.pickadd ? window.atob(routeParams.pickadd) : "";
    DropoffString = routeParams.dropadd ? window.atob(routeParams.dropadd) : "";
  }

  const PickupObx = PickupString ? GetRouteSearchParams(PickupString) : {};
  const DropoffObx = DropoffString ? GetRouteSearchParams(DropoffString) : {};

  const SORTED_RESULTS = RESULTS || [];

  const SORTED_DEL_PICK = RESULTS.map((item) => {
    const store_delivery = item.delivery || [];

    const filtred_pickup = store_delivery.filter((item) => {
      const del_type = item.type;

      const pickdist = item.pickdist || 0;

      let isPickInPoint = pickdist >= 0 && pickdist <= 1.5;

      if (del_type === "city") {
        isPickInPoint = pickdist > 0 && pickdist <= 10;
      }

      return isPickInPoint;
    });

    return filtred_pickup;
  }).flat(1);

  const SORTED_DEL_DROP = RESULTS.map((item) => {
    const store_delivery = item.delivery || [];

    const filtred_dropoff = store_delivery.filter((item) => {
      const del_type = item.type;

      const dropdist = item.dropdist || 0;

      let isDropInPoint = dropdist >= 0 && dropdist <= 1.5;

      if (del_type === "city") {
        isDropInPoint = dropdist > 0 && dropdist <= 10;
      }

      return isDropInPoint;
    });

    return filtred_dropoff;
  }).flat(1);

  const SORTED_PICKUPS = [...SORTED_DEL_PICK].sort(function (a, b) {
    return a.pickdist - b.pickdist;
  });

  const SORTED_DROPOFF = [...SORTED_DEL_DROP].sort(function (a, b) {
    return a.dropdist - b.dropdist;
  });

  const EXACT_RESULTS = RESULTS.map((item) => {
    const isPickup = SORTED_PICKUPS.findIndex(
      (pickup) => pickup.store && pickup.store.id === item.id
    );
    const isDropoff = SORTED_DROPOFF.findIndex(
      (dropoff) => dropoff.store && dropoff.store.id === item.id
    );

    if (isPickup != -1 && isDropoff != -1) {
      return {
        ...item,
        pickup: SORTED_PICKUPS[isPickup],
        dropoff: SORTED_DROPOFF[isDropoff],
      };
    }

    return null;
  }).filter((result) => result && result.id);

  const OTHER_RESULTS = RESULTS.map((item) => {
    const isPickup = SORTED_PICKUPS.findIndex(
      (pickup) => pickup.store && pickup.store.id === item.id
    );
    const isDropoff = SORTED_DROPOFF.findIndex(
      (dropoff) => dropoff.store && dropoff.store.id === item.id
    );

    if (isPickup == -1 || isDropoff == -1) {
      return {
        ...item,
        pickup: SORTED_PICKUPS[isPickup],
        dropoff: SORTED_DROPOFF[isDropoff],
      };
    }

    return null;
  }).filter((result) => result && result.id);

  // const sorted_providers = filted_providers.sort(
  //     firstBy(function (a, b) { return a.total_distance - b.total_distance })
  //         .thenBy(function (a, b) { return b.rating - a.rating })
  //         .thenBy(function (a, b) { return b.reviews - a.reviews })
  //         .thenBy(function (a, b) { return b.products - a.products })

  // );

  // return (
  //     <div style={{whiteSpace: 'break-spaces'}}>
  //         {JSON.stringify(RESULTS, null, 2)}
  //     </div>
  // )

  return (
    <>

      <CategoryFilter {...props} zIndex={9999} />

      {isLoading ? (
        <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
          <div className="flex flex-col items-center justify-center w-[100px] h-[50vh] flex-shrink-0 overflow-hidden">
            <img
              className="w-[66px] h-[66px] bounce pink-invert"
              src="/assets/img/icons/stroller.svg"
              alt=""
            />
            <div className="w-[100px] h-[3px] rounded-md bg-babonbo-100 relative mt-0">
              <div className="w-[4px] h-full bg-white absolute road-move" />
            </div>
            <span className="text-xs text-babonbo-100 mt-4 uppercase w-full text-center">
              {TRANSLATE.wd_searching_providers}
            </span>
          </div>
        </div>
      ) : (
        <>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Providers found in the city ({RESULTS.length})
              </h2>
            </div>

            <MapComponent {...props}
              stores={RESULTS || []}
              city={CITY}
              pickup={PickupBackup}
              dropoff={DropoffBackup}
              checkin={routeParams.checkin}
              checkout={routeParams.checkout}
              selectedProvider={selectedProvider} 
              setSelectedProvider={setSelectedProvider}
            />


          </div>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Providers found in the city ({RESULTS.length})
              </h2>
            </div>
            <div className="w-full flex gap-x-6 flex-nowrap overflow-auto pb-6 scrollbar-hide">
              {RESULTS.map((provider) => {
                return (
                  <div className="w-1/4 min-w-[220px] shrink-0 relative flex mb-auto md:min-h-[392px] lg:min-h-[450px] min-h-[330px]"
                       key={provider.id}
                  >
                    <ProviderCard
                      addStyles="border-zinc-200 border w-full bg-white"
                      provider={provider}
                      onClick={() => setSelectedProvider(provider)}
                      TRANSLATE={TRANSLATE}
                    />

                  </div>
                );
              })}
            </div>
            <div className="w-full h-[400px] overflow-auto">
              <pre>
                {JSON.stringify(RESULTS, null, 2)}
              </pre>
            </div>

          </div>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Providers filtred by schedules availability ({FILTERED.length})
              </h2>
            </div>
            <div className="w-full flex gap-x-6 flex-nowrap overflow-auto pb-6 scrollbar-hide">
              {FILTERED.map((provider) => {
                return (
                  <div className="w-1/4 min-w-[220px] shrink-0 relative flex mb-auto md:min-h-[392px] lg:min-h-[450px] min-h-[330px]"
                       key={provider.id}
                  >
                    <ProviderCard
                      addStyles="border-zinc-200 border w-full bg-white"
                      provider={provider}
                      onClick={() => setSelectedProvider(provider)}
                      TRANSLATE={TRANSLATE}
                    />

                  </div>
                );
              })}
            </div>
            <div className="w-full h-[400px] overflow-auto">
              <pre>
                {JSON.stringify(FILTERED, null, 2)}
              </pre>
            </div>

          </div>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Providers Delivery Coverage
              </h2>
            </div>
            <div className="w-full h-[400px] overflow-auto">
              <pre>
                {JSON.stringify(RESULTS, null, 2)}
              </pre>
            </div>

          </div>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Providers filtred by delivery match
              </h2>
            </div>
            <div className="w-full h-[400px] overflow-auto">
              <pre>
                {JSON.stringify(RESULTS, null, 2)}
              </pre>
            </div>

          </div>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Providers filtred by pickup match
              </h2>
            </div>
            <div className="w-full h-[400px] overflow-auto">
              <pre>
                {JSON.stringify(RESULTS, null, 2)}
              </pre>
            </div>

          </div>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Providers filtred by logistic match
              </h2>
            </div>
            <div className="w-full h-[400px] overflow-auto">
              <pre>
                {JSON.stringify(RESULTS, null, 2)}
              </pre>
            </div>

          </div>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Products from Providers Found
              </h2>
            </div>
            <div className="w-full h-[400px] overflow-auto">
              <pre>
                {JSON.stringify(RESULTS, null, 2)}
              </pre>
            </div>

          </div>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Products filtered by categories
              </h2>
            </div>
            <div className="w-full h-[400px] overflow-auto">
              <pre>
                {JSON.stringify(RESULTS, null, 2)}
              </pre>
            </div>

          </div>

          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="flex w-full items-center justify-between mb-6">
              <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                Products filtred by rental availability
              </h2>
            </div>
            <div className="w-full h-[400px] overflow-auto">
              <pre>
                {JSON.stringify(RESULTS, null, 2)}
              </pre>
            </div>

          </div>


          {/* <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
            <div className="w-full flex">
              <div className="w-1/2 mr-2">
                <h2 className='text-base font-bold text-xl'>
                  {SORTED_PICKUPS.length} Pickups
                </h2>
                <p className="text-xs mb-5">
                  {PickupObx.input}
                </p>
                {SORTED_PICKUPS.map((item, index) => {
                  const normal_cost = parseFloat(item.cost) || 0;
                  const final_cost = normal_cost ? normal_cost + (normal_cost * 15 / 100) : normal_cost;

                  let final_name = item.name;

                  if (item.name === 'home') {
                    final_name = TRANSLATE.wd_provider_place;
                  }

                  let delivery_type = item.type;
                  let delivery_icon = '';//onDeliveryIcon(delivery_type);

                  const pickdist = item.pickdist || 0;

                  let isPickInPoint = (pickdist >= 0 && pickdist <= 1);

                  if (delivery_type === 'city') {
                    isPickInPoint = (pickdist > 0 && pickdist <= 10);
                  }

                  const storeObx = item.store || {};

                  return (
                    <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>
                      <div className="flex">
                        <img src={delivery_icon} className="w-[20px] h-[20px] mr-1" />
                        {final_name}
                      </div>

                      <div className="flex items-center">

                        <img src={storeObx.img} className="w-[30px] h-[30px] rounded-full object-cover" />
                        <div className="ml-1 capitalize font-semibold text-xs">{storeObx.name}</div>

                        <span className={"ml-1 py-1 px-2 rounded-full text-white text-xs " + (isPickInPoint ? 'bg-green-500' : 'bg-red-500')}>
                          {pickdist.toFixed(2)} km
                        </span>

                      </div>

                    </div>
                  )
                })}
              </div>

              <div className="w-1/2 ml-2">
                <h2 className='text-base font-bold text-xl'>
                  {SORTED_DROPOFF.length} Dropoffs
                </h2>
                <p className="text-xs mb-5">
                  {DropoffObx.input}
                </p>
                {SORTED_DROPOFF.map((item, index) => {
                  const normal_cost = parseFloat(item.cost) || 0;
                  const final_cost = normal_cost ? normal_cost + (normal_cost * 15 / 100) : normal_cost;

                  let final_name = item.name;

                  if (item.name === 'home') {
                    final_name = TRANSLATE.wd_provider_place;
                  }

                  let delivery_type = item.type;
                  let delivery_icon = '';//onDeliveryIcon(delivery_type);

                  const pickdist = item.pickdist || 0;
                  const dropdist = item.dropdist || 0;

                  let isDropInPoint = (dropdist >= 0 && dropdist <= 1);

                  const storeObx = item.store || {};

                  if (delivery_type === 'city') {
                    isDropInPoint = (dropdist > 0 && dropdist <= 10)
                  }

                  return (
                    <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>
                      <div className="flex">
                        <img src={delivery_icon} className="w-[20px] h-[20px] mr-1" />
                        {final_name}
                      </div>

                      <div className="flex items-center">

                        <img src={storeObx.img} className="w-[30px] h-[30px] rounded-full object-cover" />
                        <div className="ml-1 capitalize font-semibold text-xs">{storeObx.name}</div>

                        <span className={"ml-1 py-1 px-2 rounded-full text-white text-xs " + (isDropInPoint ? 'bg-green-500' : 'bg-red-500')}>
                          {dropdist.toFixed(2)} km
                        </span>

                      </div>

                    </div>
                  )
                })}
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-1/2 ml-2">
                <h2 className='text-base font-bold text-xl'>
                  Matched {EXACT_RESULTS.length} Stores
                </h2>
                <p className="text-xs mb-5">
                  Sorted matched stores pickup/dropoff
                </p>


                {EXACT_RESULTS.map((item, index) => {

                  const storeName = item.name;
                  const storeImg = item.image;

                  const pickup = item.pickup || {};
                  const dropoff = item.dropoff || {};

                  const pickup_cost = pickup.cost || 0;
                  const dropoff_cost = dropoff.cost || 0;

                  const delivery_cost = parseFloat(pickup_cost) + parseFloat(dropoff_cost);

                  const final_pickup_cost = pickup_cost ? pickup_cost + (pickup_cost * 15 / 100) : pickup_cost;
                  const final_dropoff_cost = dropoff_cost ? dropoff_cost + (dropoff_cost * 15 / 100) : dropoff_cost;
                  const final_delivery_cost = delivery_cost ? delivery_cost + (delivery_cost * 15 / 100) : delivery_cost;


                  return (
                    <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>

                      <div>
                        <div className="flex capitalize font-semibold">
                          <img src={storeImg} className="w-[20px] h-[20px] object-cover rounded-full mr-1" />
                          {storeName}
                        </div>

                      </div>

                      <div>
                        <div className="flex capitalize font-semibold">
                          Pickup:
                          {pickup.name}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Dropoff:
                          {dropoff.name}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Pickup Cost:
                          {numberWithCommas(final_pickup_cost, CURRENCY)}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Dropoff Cost:
                          {numberWithCommas(final_dropoff_cost, CURRENCY)}
                        </div>

                        <div className="flex capitalize font-semibold">
                          Total Delivery:
                          {numberWithCommas(final_delivery_cost, CURRENCY)}
                        </div>

                      </div>


                    </div>
                  )
                })}


              </div>

              <div className="w-1/2 ml-2">

                <h2 className='text-base font-bold text-xl mt-5'>
                  Unmatched {OTHER_RESULTS.length} Stores
                </h2>
                <p className="text-xs mb-5">
                  Sorted unmatched stores pickup/dropoff
                </p>

                {OTHER_RESULTS.map((item, index) => {


                  const storeName = item.name;
                  const storeImg = item.image;

                  const pickup = item.pickup || {};
                  const dropoff = item.dropoff || {};

                  const pickup_cost = pickup.cost || 0;
                  const dropoff_cost = dropoff.cost || 0;

                  const delivery_cost = parseFloat(pickup_cost) + parseFloat(dropoff_cost);

                  const final_pickup_cost = pickup_cost ? pickup_cost + (pickup_cost * 15 / 100) : pickup_cost;
                  const final_dropoff_cost = dropoff_cost ? dropoff_cost + (dropoff_cost * 15 / 100) : dropoff_cost;
                  const final_delivery_cost = delivery_cost ? delivery_cost + (delivery_cost * 15 / 100) : delivery_cost;


                  return (
                    <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>

                      <div>
                        <div className="flex capitalize font-semibold">
                          <img src={storeImg} className="w-[20px] h-[20px] object-cover rounded-full mr-1" />
                          {storeName}
                        </div>

                      </div>

                      <div>
                        <div className="flex capitalize font-semibold">
                          Pickup:
                          {pickup.name}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Dropoff:
                          {dropoff.name}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Pickup Cost:
                          {numberWithCommas(final_pickup_cost, CURRENCY)}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Dropoff Cost:
                          {numberWithCommas(final_dropoff_cost, CURRENCY)}
                        </div>

                        <div className="flex capitalize font-semibold">
                          Total Delivery:
                          {numberWithCommas(final_delivery_cost, CURRENCY)}
                        </div>

                      </div>

                    </div>
                  )
                })}
              </div>

            </div>
          </div> */}

          {EXACT_RESULTS.length > 0 && (
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
              <div className="flex w-full items-center justify-between mb-6">
                <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                  {TRANSLATE.tool_choose_gear_from.replace(
                    "{{TOTAL}}",
                    EXACT_RESULTS.length
                  )}
                </h2>
              </div>

              {/* {JSON.stringify(RESULTS, null, 2)} */}
            </div>
          )}

          {OTHER_RESULTS.length > 0 && (
            <div
              className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap"
              style={{ marginTop: 200 }}
            >
              <div className="flex w-full items-center justify-between mb-6">
                <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                  {TRANSLATE.tool_choose_gear_other.replace(
                    "{{TOTAL}}",
                    OTHER_RESULTS.length
                  )}
                </h2>
              </div>

              {/* {JSON.stringify(RESULTS, null, 2)} */}


            </div>
          )}

        </>
      )}

    </>
  );
};

const PlaygroundController2 = (props) => {
  const {
    match,
    LANGUAGE,
    TRANSLATE,
    location,
    USER,
    onUpdateCart,
    onFetchDates,
    history,
  } = props;

  const CART = [];

  const [CURRENCY, setCurrency] = useState('eur');

  const [isLoading, setLoading] = useState(true);
  const [CITY, setCity] = useState(null);
  const [RESULTS, setResults] = useState([]);
  const [PRODUCTS, setProducts] = useState([]);
  const [TOTAL, setTotal] = useState(0);

  const [isProductModal, setProductModal] = useState(false);
  const [CurrentProductModal, setCurrentProductModal] = useState(null);
  const [CurrentProviderModal, setCurrentProviderModal] = useState(null);
  const [CurrentProvider, setCurrentProvider] = useState(null);
  const [CurrentOther, setCurrentOther] = useState(null);

  const [isReviews, setReviews] = useState(false);
  const [isDelivery, setDelivery] = useState(false);
  const [isProvider, setProvider] = useState(false);

  const [PickupBackup, setPickupBackup] = useState(null);
  const [DropoffBackup, setDropoffBackup] = useState(null);

  const search_city = match.params.city;
  const search_country = match.params.country;


  useEffect(() => {
    if (CURRENCY) {
      onFetchProducts();
    }
  }, [location.search, CURRENCY, LANGUAGE]);

  useEffect(() => {
    if (isProductModal || isReviews || isDelivery || isProvider) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isProductModal, isReviews, isDelivery, isProvider]);

  const onFetchProducts = async () => {
    window.scrollTo(0, 0);

    const JWT =
      typeof global.window !== "undefined"
        ? localStorage.getItem("jwt_token")
        : null;

    const routeParams = GetRouteSearchParams(location.search) || {};

    const PickupString = routeParams.pickadd
      ? window.atob(routeParams.pickadd)
      : "";
    const DropoffString = routeParams.dropadd
      ? window.atob(routeParams.dropadd)
      : "";

    const PickupObx = PickupString ? GetRouteSearchParams(PickupString) : {};
    const DropoffObx = DropoffString ? GetRouteSearchParams(DropoffString) : {};

    setPickupBackup(PickupObx);
    setDropoffBackup(DropoffObx);

    const RequestOriginal = window.location.toString();
    const RequestUrl = RequestOriginal
      ? RequestOriginal.replace("//localhost:3000", "//www.babonbo.com")
      : "";

    const FORM = {
      id: routeParams.id,
      checkin: routeParams.checkin,
      checkout: routeParams.checkout,
      categories: routeParams.categories,
      age: routeParams.age,
      picklat: routeParams.picklat,
      picklon: routeParams.picklon,
      droplat: routeParams.droplat,
      droplon: routeParams.droplon,
      picktime: routeParams.picktime,
      droptime: routeParams.droptime,

      pickadd: PickupObx.input,
      dropadd: DropoffObx.input,

      pickcity: PickupObx.city,
      dropcity: DropoffObx.city,

      lang: LANGUAGE,
      curid: CURRENCY,

      request: RequestUrl,
      device: deviceType,
    };

    setLoading(true);

    console.log("FORM ===> Client ", FORM);

    // alert(JSON.stringify(FORM, null,2))

    const DATA_RESPONSE = await postApiPrivate("/flow/playground", FORM, JWT);

    if (DATA_RESPONSE.error) {
      console.log("ERROR FROM API LOADING PRODUCTS", DATA_RESPONSE.error);

      // alert('here');
      setLoading(false);

      return;
    }

    const DATA_CITY = DATA_RESPONSE.city;
    const DATA_RESULTS = DATA_RESPONSE.results || [];
    const DATA_PRODUCTS = DATA_RESPONSE.products || [];
    const DATA_TOTAL = DATA_RESPONSE.total || 0;

    //alert('RESULTS ===> '+JSON.stringify(DATA_RESPONSE, null, 2));

    setCity(DATA_CITY);
    setResults(DATA_RESULTS);
    setProducts(DATA_PRODUCTS);
    setTotal(DATA_TOTAL);

    setLoading(false);

    console.log("FETCHING PRODUCTS NEARBY ===> ", DATA_RESPONSE);

    window.scrollTo(0, 0);
  };

  const onCartProductAdded = (product, provider) => {
    const order_index = CART.findIndex((item) => item.store === provider.id);

    if (order_index == -1) {
      console.log("No Order Found");
      return false;
    } else {
      console.log("Found Order at Index ==> ", order_index);
      const current_order = CART[order_index] || {};
      const current_products = current_order.products || [];
      const product_index = current_products.findIndex(
        (item) => item.id === product.id
      );

      if (product_index == -1) {
        return false;
      } else {
        return true;
      }
    }
  };

  const onAddToCart = (product, provider, isOther) => {
    const ProductCategory = product.category;

    const order_index = CART.findIndex((item) => item.store === provider.id);

    if (order_index == -1) {
      console.log("No Order Found");

      if (ProductCategory === "consumable") {
        swal(
          TRANSLATE.alert_cart.consumable_t,
          TRANSLATE.alert_cart.consumable_s,
          "warning"
        );
        return;
      }

      if (CART.length != 0) {
        //There is another order from another store
        swal({
          title: TRANSLATE.alert_cart.multiple_t,
          text: TRANSLATE.alert_cart.multiple_s,
          icon: "warning",
          buttons: [TRANSLATE.btn_cancel, TRANSLATE.alert_cart.multiple_c],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            onUpdateCart([]);
            onStartCart(product, provider, true, isOther);
          }
        });
        return;
      }

      onStartCart(product, provider, false, isOther);
    } else {
      console.log("Found Order at Index ==> ", order_index);
      const current_order = CART[order_index];
      onRetrieveCart(current_order, order_index, product, isOther);
    }
  };

  const onStartCart = async (product, provider, isEmpty, isOther) => {
    let MASTER_CART = CART || [];

    if (isEmpty) {
      MASTER_CART = [];
    }

    let SEGUE_PRODUCT = product || {};

    const routeParams = GetRouteSearchParams(location.search);
    const routeCheckin = moment(routeParams.checkin, "DD-MM-YYYY");
    const routeCheckout = moment(routeParams.checkout, "DD-MM-YYYY");

    const PickupString = routeParams.pickadd
      ? window.atob(routeParams.pickadd)
      : "";
    const DropoffString = routeParams.dropadd
      ? window.atob(routeParams.dropadd)
      : "";

    const PickupObx = PickupString ? GetRouteSearchParams(PickupString) : {};
    const DropoffObx = DropoffString ? GetRouteSearchParams(DropoffString) : {};

    const routeDuration =
      routeParams.checkin && routeParams.checkout
        ? routeCheckout.diff(routeCheckin, "days") + 1
        : 1;

    const SEGUE_PROVIDER = provider || {};
    const SEGUE_CHECKIN = routeCheckin;
    const SEGUE_CHECKOUT = routeCheckout;

    let PROVIDER_DELIVERY = SEGUE_PROVIDER.delivery || [];
    let PROVIDER_PICKUP = SEGUE_PROVIDER.pickup || {};
    let PROVIDER_DROPOFF = SEGUE_PROVIDER.dropoff || {};

    let SEGUE_PICKTIME = routeParams.picktime;
    let SEGUE_DROPTIME = routeParams.droptime;

    PROVIDER_DELIVERY = PROVIDER_DELIVERY.filter((item) => item.geocode);

    PROVIDER_DELIVERY = [
      {
        name: TRANSLATE.wd_provider_place,
        type: "home",
        geocode: SEGUE_PROVIDER.geocode,
        cost: 0,
      },
      ...PROVIDER_DELIVERY,
    ];

    let SEGUE_DELIVERY =
      PROVIDER_PICKUP.name && !isOther
        ? { ...PROVIDER_PICKUP, input: PickupObx.input }
        : PROVIDER_DELIVERY[0];
    let SEGUE_RETURN =
      PROVIDER_DROPOFF.name && !isOther
        ? { ...PROVIDER_DROPOFF, input: DropoffObx.input }
        : PROVIDER_DELIVERY[0];

    if (SEGUE_CHECKOUT && SEGUE_CHECKIN) {
      var startMoment = SEGUE_CHECKIN;
      var endMoment = SEGUE_CHECKOUT;
      var daysMoment = endMoment.diff(startMoment, "days") + 1;

      console.log("START MOMENT ===> ", startMoment);
      console.log("END MOMENT ===> ", endMoment);
      console.log("DAYS MOMENT ===> ", daysMoment);

      const order_subtotal = onCalculateSingleSubtotal(
        SEGUE_PRODUCT,
        daysMoment,
        1
      );

      const order_total = onCalculateSingleTotal(
        order_subtotal,
        SEGUE_DELIVERY,
        SEGUE_RETURN
      );

      const order_obx = onCreateOrder({
        PROVIDER: SEGUE_PROVIDER,
        PRODUCT: SEGUE_PRODUCT,
        CHECKIN: SEGUE_CHECKIN,
        CHECKOUT: SEGUE_CHECKOUT,
        DELIVERY: SEGUE_DELIVERY,
        PICKTIME: SEGUE_PICKTIME,
        DROPTIME: SEGUE_DROPTIME,
        RETURN: SEGUE_RETURN,
        SUBTOTAL: order_subtotal,
        TOTAL: order_total,
        CURRENCY: CURRENCY,
        isOther,
      });

      console.log("ORDER_OBX ===> ", order_obx);

      const new_cart = [order_obx, ...MASTER_CART] || [];
      onUpdateCart(new_cart);

      swal(
        TRANSLATE.alert_cart.added_t,
        TRANSLATE.alert_cart.added_s,
        "success"
      );
    }
  };

  const onRetrieveCart = async (ORDER_OBX, ORDER_INDEX, product, isOther) => {
    console.log("1 --->");

    const routeParams = GetRouteSearchParams(location.search);
    const routeCheckin = moment(routeParams.checkin, "DD-MM-YYYY");
    const routeCheckout = moment(routeParams.checkout, "DD-MM-YYYY");

    let SEGUE_PRODUCT = product || {};

    const SEGUE_CHECKIN = routeCheckin;
    const SEGUE_CHECKOUT = routeCheckout;

    console.log("2 --->");

    if (SEGUE_CHECKOUT && SEGUE_CHECKIN) {
      console.log("5 --->");

      var startMoment = moment(SEGUE_CHECKIN);
      var endMoment = moment(SEGUE_CHECKOUT);
      var daysMoment = endMoment.diff(startMoment, "days") + 1;

      console.log("START MOMENT ===> ", startMoment);
      console.log("END MOMENT ===> ", endMoment);
      console.log("DAYS MOMENT ===> ", daysMoment);

      //Check if Product is Already in Cart
      var order_products = ORDER_OBX.products || [];
      var product_index = order_products.findIndex(
        (item) => item.id === SEGUE_PRODUCT.id
      );

      const ProductCategory = SEGUE_PRODUCT.category;

      if (product_index == -1) {
        console.log("PRODUCT NOT FOUND NEED TO ADD ");

        var new_product = {};

        if (ProductCategory === "consumable") {
          new_product = {
            id: SEGUE_PRODUCT.id,
            name: SEGUE_PRODUCT.name,
            img: SEGUE_PRODUCT.first_img,
            price_day: SEGUE_PRODUCT.price_day,
            quantity: 1,
            category: SEGUE_PRODUCT.category,
          };
        } else {
          new_product = {
            id: SEGUE_PRODUCT.id,
            name: SEGUE_PRODUCT.name,
            img: SEGUE_PRODUCT.first_img,
            price_day: SEGUE_PRODUCT.price_day,
            price_week: SEGUE_PRODUCT.price_week,
            price_month: SEGUE_PRODUCT.price_month,
            category: SEGUE_PRODUCT.category,
            quantity: 1,
          };
        }

        var new_order_products = [...order_products, new_product];

        var new_order = {
          ...ORDER_OBX,
          products: new_order_products,
          checkin: SEGUE_CHECKIN,
          checkout: SEGUE_CHECKOUT,
        };

        var new_cart = [
          ...CART.slice(0, ORDER_INDEX),
          { ...CART[ORDER_INDEX], ...new_order },
          ...CART.slice(ORDER_INDEX + 1),
        ];

        console.log(" NEW CART ===> ", new_cart);

        onUpdateCart(new_cart);

        swal(
          TRANSLATE.alert_cart.added_t,
          TRANSLATE.alert_cart.added_s,
          "success"
        );
      } else {
        console.log("PRODUCT FOUND NEED TO EDIT");

        var new_product = order_products[product_index];

        var new_order_products = [
          ...order_products.slice(0, product_index),
          { ...order_products[product_index], ...new_product },
          ...order_products.slice(product_index + 1),
        ];

        var new_order = {
          ...ORDER_OBX,
          products: new_order_products,
          checkin: SEGUE_CHECKIN,
          checkout: SEGUE_CHECKOUT,
        };

        var new_cart = [
          ...CART.slice(0, ORDER_INDEX),
          { ...CART[ORDER_INDEX], ...new_order },
          ...CART.slice(ORDER_INDEX + 1),
        ];

        console.log(" NEW CART ===> ", new_cart);

        onUpdateCart(new_cart);

        swal(
          TRANSLATE.alert_cart.added_t,
          TRANSLATE.alert_cart.added_s,
          "success"
        );
      }

      if (ProductCategory === "consumable") {
        const PriceFixed = parseFloat(SEGUE_PRODUCT.price_day);
        const order_subtotal = PriceFixed + ((PriceFixed * 15) / 100) * 1;


      } else {
        const order_subtotal = onCalculateSingleSubtotal(
          SEGUE_PRODUCT,
          daysMoment,
          1
        );
      }

      return;
    } else {
      alert("Please Select Rental Periods");
    }
  };

  const onCreateOrder = (props) => {
    const {
      PROVIDER,
      PRODUCT,
      CHECKIN,
      CHECKOUT,
      DELIVERY,
      RETURN,
      SUBTOTAL,
      TOTAL,
      PICKTIME,
      DROPTIME,
      CURRENCY,
      isOther,
    } = props;

    var order_obx = {
      store: PROVIDER.id,
      temp: PROVIDER,
      products: [
        {
          id: PRODUCT.id,
          name: PRODUCT.name,
          img: PRODUCT.first_img,
          price_day: PRODUCT.price_day,
          price_week: PRODUCT.price_week,
          price_month: PRODUCT.price_month,
          category: PRODUCT.category,
          quantity: 1,
        },
      ],
      checkin: CHECKIN,
      checkout: CHECKOUT,
      delivery: DELIVERY,
      return: RETURN,
      subtotal: SUBTOTAL,
      total: TOTAL,
      currency: CURRENCY,
      picktime: PICKTIME,
      droptime: DROPTIME,
    };

    if (!isOther) {
      order_obx = {
        ...order_obx,
        p_delivery: DELIVERY,
        p_return: RETURN,
      };
    }

    return order_obx;
  };

  //Functions
  const onCalculateDailyPrice = (PRODUCT, DURATION) => {
    var { price_day, price_month, price_week } = PRODUCT;

    if (DURATION < 7) {
      return price_day;
    } else if (DURATION >= 7 && DURATION < 30) {
      var PRICE_WEEK_DAILY = price_week / 7;
      return PRICE_WEEK_DAILY;
    } else if (DURATION >= 30) {
      var PRICE_MONTH_DAILY = price_month / 30;
      return PRICE_MONTH_DAILY;
    }
  };

  const onCalculateSingleSubtotal = (PRODUCT, DURATION, QUANTITY) => {
    var current_quantity = PRODUCT.quantity || 1;

    if (QUANTITY) {
      current_quantity = QUANTITY;
    }

    var price_day = onCalculateDailyPrice(PRODUCT, DURATION);
    var subtotal = price_day * DURATION * current_quantity;

    //alert("PRice ===> "+ JSON.stringify({current_quantity, price_day, subtotal}, null, 2));
    return subtotal;
  };

  const onCalculateSingleTotal = (SUBTOTAL, DELIVERY, RETURN) => {
    var DELIVERY_COST = parseFloat(DELIVERY.cost) || 0;
    var RETURN_COST = parseFloat(RETURN.cost) || 0;

    var TOTAL = SUBTOTAL + DELIVERY_COST + RETURN_COST;

    return TOTAL;
  };

  const onSelectStore = (provider) => {
    // setCurrentProviderModal(provider);
    // setDelivery(true);

    const ProviderSection = document.getElementById("section_" + provider.id);

    if (ProviderSection) {
      var headerOffset = 200;
      var elementPosition = ProviderSection.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const onSelectProduct = (product, provider, isOther) => {
    setCurrentProviderModal(provider);
    setCurrentProductModal(product);
    setCurrentOther(isOther);
    setProductModal(true);
  };

  const onSelectReviews = (store_id, status) => {
    setCurrentProvider(store_id);
    setReviews(status);
  };

  const onSelectProvider = (provider, status) => {
    setProvider(status);
    setCurrentProvider(provider ? provider.id : null);
    setCurrentProviderModal(provider ? provider : null);
    // alert('Provider ===> '+JSON.stringify(provider, null, 2));
  };

  const routeParams = GetRouteSearchParams(location.search);
  const routeCheckin = moment(routeParams.checkin, "DD-MM-YYYY");
  const routeCheckout = moment(routeParams.checkout, "DD-MM-YYYY");

  const routeDuration =
    routeParams.checkin && routeParams.checkout
      ? routeCheckout.diff(routeCheckin, "days") + 1
      : 1;
  var PickupString = "";
  var DropoffString = "";

  if (typeof global.window !== "undefined") {
    PickupString = routeParams.pickadd ? window.atob(routeParams.pickadd) : "";
    DropoffString = routeParams.dropadd ? window.atob(routeParams.dropadd) : "";
  }

  const PickupObx = PickupString ? GetRouteSearchParams(PickupString) : {};
  const DropoffObx = DropoffString ? GetRouteSearchParams(DropoffString) : {};

  const SORTED_RESULTS = RESULTS || [];

  const SORTED_DEL_PICK = RESULTS.map((item) => {
    const store_delivery = item.delivery || [];

    const filtred_pickup = store_delivery.filter((item) => {
      const del_type = item.type;

      const pickdist = item.pickdist || 0;

      let isPickInPoint = pickdist >= 0 && pickdist <= 1.5;

      if (del_type === "city") {
        isPickInPoint = pickdist > 0 && pickdist <= 10;
      }

      return isPickInPoint;
    });

    return filtred_pickup;
  }).flat(1);

  const SORTED_DEL_DROP = RESULTS.map((item) => {
    const store_delivery = item.delivery || [];

    const filtred_dropoff = store_delivery.filter((item) => {
      const del_type = item.type;

      const dropdist = item.dropdist || 0;

      let isDropInPoint = dropdist >= 0 && dropdist <= 1.5;

      if (del_type === "city") {
        isDropInPoint = dropdist > 0 && dropdist <= 10;
      }

      return isDropInPoint;
    });

    return filtred_dropoff;
  }).flat(1);

  const SORTED_PICKUPS = [...SORTED_DEL_PICK].sort(function (a, b) {
    return a.pickdist - b.pickdist;
  });

  const SORTED_DROPOFF = [...SORTED_DEL_DROP].sort(function (a, b) {
    return a.dropdist - b.dropdist;
  });

  const EXACT_RESULTS = RESULTS.map((item) => {
    const isPickup = SORTED_PICKUPS.findIndex(
      (pickup) => pickup.store && pickup.store.id === item.id
    );
    const isDropoff = SORTED_DROPOFF.findIndex(
      (dropoff) => dropoff.store && dropoff.store.id === item.id
    );

    if (isPickup != -1 && isDropoff != -1) {
      return {
        ...item,
        pickup: SORTED_PICKUPS[isPickup],
        dropoff: SORTED_DROPOFF[isDropoff],
      };
    }

    return null;
  }).filter((result) => result && result.id);

  const OTHER_RESULTS = RESULTS.map((item) => {
    const isPickup = SORTED_PICKUPS.findIndex(
      (pickup) => pickup.store && pickup.store.id === item.id
    );
    const isDropoff = SORTED_DROPOFF.findIndex(
      (dropoff) => dropoff.store && dropoff.store.id === item.id
    );

    if (isPickup == -1 || isDropoff == -1) {
      return {
        ...item,
        pickup: SORTED_PICKUPS[isPickup],
        dropoff: SORTED_DROPOFF[isDropoff],
      };
    }

    return null;
  }).filter((result) => result && result.id);

  // const sorted_providers = filted_providers.sort(
  //     firstBy(function (a, b) { return a.total_distance - b.total_distance })
  //         .thenBy(function (a, b) { return b.rating - a.rating })
  //         .thenBy(function (a, b) { return b.reviews - a.reviews })
  //         .thenBy(function (a, b) { return b.products - a.products })

  // );

  // return (
  //     <div style={{whiteSpace: 'break-spaces'}}>
  //         {JSON.stringify(RESULTS, null, 2)}
  //     </div>
  // )

  return (
    <>
      {isProductModal && CurrentProductModal && CurrentProviderModal && (
        <ProductModal
          product={CurrentProductModal}
          provider={CurrentProviderModal}
          isOther={CurrentOther}
          onClose={() => {
            setProductModal(false);
            setCurrentProductModal(null);
            setCurrentProviderModal(null);
            setCurrentOther(null);
          }}
          CURRENCY={CURRENCY || "eur"}
          duration={routeDuration}
          onCalculateDailyPrice={onCalculateDailyPrice}
          onAddToCart={onAddToCart}
          USER={USER}
          TRANSLATE={TRANSLATE}
        />
      )}

      {/* {isProvider && CurrentProvider && CurrentProviderModal && (
        <ProviderPopup
          {...props}
          onClose={() => onSelectProvider(null, false)}
          providerId={CurrentProvider}
          PROVIDER={CurrentProviderModal}
        />
      )} */}

      {isReviews && (
        <ReviewsList
          {...props}
          onClose={() => onSelectReviews(null, false)}
          providerId={CurrentProvider}
        />
      )}

      {/* {(isDelivery && CurrentProviderModal) && (
                <DeliveryList {...props}
                    onClose={() => {
                        setDelivery(false);
                        setCurrentProviderModal(null);
                    }}
                    provider={CurrentProviderModal}
                    PRODUCTS={PRODUCTS}
                    onCartProductAdded={onCartProductAdded}
                    onCalculateDailyPrice={onCalculateDailyPrice}
                    routeDuration={routeDuration}
                    onFormatPrices={onFormatPrices}
                    onSelectProduct={onSelectProduct}
                    onAddToCart={onAddToCart}

                />
            )} */}

      <CategoryFilter {...props} />

      {isLoading ? (
        <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
          <div className="flex flex-col items-center justify-center w-[100px] h-[50vh] flex-shrink-0 overflow-hidden">
            <img
              className="w-[66px] h-[66px] bounce pink-invert"
              src="/assets/img/icons/stroller.svg"
              alt=""
            />
            <div className="w-[100px] h-[3px] rounded-md bg-babonbo-100 relative mt-0">
              <div className="w-[4px] h-full bg-white absolute road-move" />
            </div>
            <span className="text-xs text-babonbo-100 mt-4 uppercase w-full text-center">
              {TRANSLATE.wd_searching_providers}
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">

            <div className="w-full flex">

              <div className="w-1/3 mr-2">
                <h2 className='text-base font-bold text-xl'>
                  {SORTED_PICKUPS.length} Pickups
                </h2>
                <p className="text-xs mb-5">
                  {PickupObx.input}
                </p>
                {SORTED_PICKUPS.map((item, index) => {
                  const normal_cost = parseFloat(item.cost) || 0;
                  const final_cost = normal_cost ? normal_cost + (normal_cost * 15 / 100) : normal_cost;

                  let final_name = item.name;

                  if (item.name === 'home') {
                    final_name = TRANSLATE.wd_provider_place;
                  }

                  let delivery_type = item.type;
                  let delivery_icon = '';//onDeliveryIcon(delivery_type);

                  const pickdist = item.pickdist || 0;

                  let isPickInPoint = (pickdist >= 0 && pickdist <= 1);

                  if (delivery_type === 'city') {
                    isPickInPoint = (pickdist > 0 && pickdist <= 10);
                  }

                  const storeObx = item.store || {};

                  return (
                    <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>
                      <div className="flex">
                        <img src={delivery_icon} className="w-[20px] h-[20px] mr-1" />
                        {final_name}
                      </div>

                      <div className="flex items-center">

                        <img src={storeObx.img} className="w-[30px] h-[30px] rounded-full object-cover" />
                        <div className="ml-1 capitalize font-semibold text-xs">{storeObx.name}</div>

                        <span className={"ml-1 py-1 px-2 rounded-full text-white text-xs " + (isPickInPoint ? 'bg-green-500' : 'bg-red-500')}>
                          {pickdist.toFixed(2)} km
                        </span>

                      </div>

                    </div>
                  )
                })}
              </div>

              <div className="w-1/3 ml-2">
                <h2 className='text-base font-bold text-xl'>
                  {SORTED_DROPOFF.length} Dropoffs
                </h2>
                <p className="text-xs mb-5">
                  {DropoffObx.input}
                </p>
                {SORTED_DROPOFF.map((item, index) => {
                  const normal_cost = parseFloat(item.cost) || 0;
                  const final_cost = normal_cost ? normal_cost + (normal_cost * 15 / 100) : normal_cost;

                  let final_name = item.name;

                  if (item.name === 'home') {
                    final_name = TRANSLATE.wd_provider_place;
                  }

                  let delivery_type = item.type;
                  let delivery_icon = '';//onDeliveryIcon(delivery_type);

                  const pickdist = item.pickdist || 0;
                  const dropdist = item.dropdist || 0;

                  let isDropInPoint = (dropdist >= 0 && dropdist <= 1);

                  const storeObx = item.store || {};

                  if (delivery_type === 'city') {
                    isDropInPoint = (dropdist > 0 && dropdist <= 10)
                  }

                  return (
                    <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>
                      <div className="flex">
                        <img src={delivery_icon} className="w-[20px] h-[20px] mr-1" />
                        {final_name}
                      </div>

                      <div className="flex items-center">

                        <img src={storeObx.img} className="w-[30px] h-[30px] rounded-full object-cover" />
                        <div className="ml-1 capitalize font-semibold text-xs">{storeObx.name}</div>

                        <span className={"ml-1 py-1 px-2 rounded-full text-white text-xs " + (isDropInPoint ? 'bg-green-500' : 'bg-red-500')}>
                          {dropdist.toFixed(2)} km
                        </span>

                      </div>

                    </div>
                  )
                })}
              </div>

              <div className="w-1/3 ml-2">
                <h2 className='text-base font-bold text-xl'>
                  Matched {EXACT_RESULTS.length} Stores
                </h2>
                <p className="text-xs mb-5">
                  Sorted matched stores pickup/dropoff
                </p>

                {/* <pre className="text-xs mb-5">
                  {JSON.stringify(RESULTS, null, 2)}
                </pre> */}

                {EXACT_RESULTS.map((item, index) => {

                  const storeName = item.name;
                  const storeImg = item.image;

                  const pickup = item.pickup || {};
                  const dropoff = item.dropoff || {};

                  const pickup_cost = pickup.cost || 0;
                  const dropoff_cost = dropoff.cost || 0;

                  const delivery_cost = parseFloat(pickup_cost) + parseFloat(dropoff_cost);

                  const final_pickup_cost = pickup_cost ? pickup_cost + (pickup_cost * 15 / 100) : pickup_cost;
                  const final_dropoff_cost = dropoff_cost ? dropoff_cost + (dropoff_cost * 15 / 100) : dropoff_cost;
                  const final_delivery_cost = delivery_cost ? delivery_cost + (delivery_cost * 15 / 100) : delivery_cost;


                  return (
                    <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>

                      <div>
                        <div className="flex capitalize font-semibold">
                          <img src={storeImg} className="w-[20px] h-[20px] object-cover rounded-full mr-1" />
                          {storeName}
                        </div>

                      </div>

                      <div>
                        <div className="flex capitalize font-semibold">
                          Pickup:
                          {pickup.name}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Dropoff:
                          {dropoff.name}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Pickup Cost:
                          {numberWithCommas(final_pickup_cost, CURRENCY)}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Dropoff Cost:
                          {numberWithCommas(final_dropoff_cost, CURRENCY)}
                        </div>

                        <div className="flex capitalize font-semibold">
                          Total Delivery:
                          {numberWithCommas(final_delivery_cost, CURRENCY)}
                        </div>

                      </div>


                    </div>
                  )
                })}

                <h2 className='text-base font-bold text-xl mt-5'>
                  Unmatched {OTHER_RESULTS.length} Stores
                </h2>
                <p className="text-xs mb-5">
                  Sorted unmatched stores pickup/dropoff
                </p>

                {OTHER_RESULTS.map((item, index) => {


                  const storeName = item.name;
                  const storeImg = item.image;

                  const pickup = item.pickup || {};
                  const dropoff = item.dropoff || {};

                  const pickup_cost = pickup.cost || 0;
                  const dropoff_cost = dropoff.cost || 0;

                  const delivery_cost = parseFloat(pickup_cost) + parseFloat(dropoff_cost);

                  const final_pickup_cost = pickup_cost ? pickup_cost + (pickup_cost * 15 / 100) : pickup_cost;
                  const final_dropoff_cost = dropoff_cost ? dropoff_cost + (dropoff_cost * 15 / 100) : dropoff_cost;
                  const final_delivery_cost = delivery_cost ? delivery_cost + (delivery_cost * 15 / 100) : delivery_cost;


                  return (
                    <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>

                      <div>
                        <div className="flex capitalize font-semibold">
                          <img src={storeImg} className="w-[20px] h-[20px] object-cover rounded-full mr-1" />
                          {storeName}
                        </div>

                      </div>

                      <div>
                        <div className="flex capitalize font-semibold">
                          Pickup:
                          {pickup.name}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Dropoff:
                          {dropoff.name}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Pickup Cost:
                          {numberWithCommas(final_pickup_cost, CURRENCY)}
                        </div>
                        <div className="flex capitalize font-semibold">
                          Dropoff Cost:
                          {numberWithCommas(final_dropoff_cost, CURRENCY)}
                        </div>

                        <div className="flex capitalize font-semibold">
                          Total Delivery:
                          {numberWithCommas(final_delivery_cost, CURRENCY)}
                        </div>

                      </div>

                    </div>
                  )
                })}
              </div>

            </div>

          </div>



          <div className='w-full md:w-1/6 h-auto md:h-[400px] overflow-hidden rounded-lg mt-5 md:mt-0 ml-5 relative'>

            <div className="w-full flex flex-row md:flex-col">
              <div className="w-1/2 md:w-full">
                <div className="flex items-center justify-start mb-2 capitalize">
                  <img className="w-[20px] h-[20px] mr-2" src="/assets/img/babonbo_marker.png" />
                  {TRANSLATE.wd_available_providers}
                </div>
                <div className="flex items-center justify-start mb-2 capitalize">
                  <img className="w-[20px] h-[20px] mr-2" src="/assets/img/babonbo_marker_provider.png" />
                  {TRANSLATE.wd_other_providers}
                </div>
                <div className="flex items-center justify-start mb-2 capitalize">
                  <img className="w-[20px] h-[20px] mr-2" src="/assets/img/babonbo_marker_airport.png" />
                  {TRANSLATE.wd_airports}
                </div>
                <div className="flex items-center justify-start mb-2 capitalize">
                  <img className="w-[20px] h-[20px] mr-2" src="/assets/img/babonbo_marker_station.png" />
                  {TRANSLATE.wd_train_stations}
                </div>
                <div className="flex items-center justify-start mb-2 capitalize">
                  <img className="w-[20px] h-[20px] mr-2" src="/assets/img/babonbo_marker_port.png" />
                  {TRANSLATE.wd_ports}
                </div>

              </div>

              <div className="w-1/2 md:w-full">

                <div className="flex items-center justify-start mb-2 capitalize">
                  <img className="w-[20px] h-[20px] mr-2" src="/assets/img/babonbo_marker_pickup.png" />
                  {TRANSLATE.wd_pickup}
                </div>
                <div className="flex items-center justify-start mb-2 capitalize">
                  <img className="w-[20px] h-[20px] mr-2" src="/assets/img/babonbo_marker_dropoff.png" />
                  {TRANSLATE.wd_dropoff}
                </div>
                <div className="flex items-center justify-start mb-2 capitalize">
                  <img className="w-[20px] h-[20px] mr-2" src="/assets/img/babonbo_marker_pickdrop.png" />
                  {TRANSLATE.wd_pickup} {TRANSLATE.wd_and} {TRANSLATE.wd_dropoff}
                </div>

              </div>
            </div>

          </div>


          {EXACT_RESULTS.length > 0 && (
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap">
              <div className="flex w-full items-center justify-between mb-6">
                <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                  {TRANSLATE.tool_choose_gear_from.replace(
                    "{{TOTAL}}",
                    EXACT_RESULTS.length
                  )}
                </h2>
              </div>

              {/* {JSON.stringify(RESULTS, null, 2)} */}

              {EXACT_RESULTS.map((item, index) => {
                const store_provider = item;

                const store_distance = item.store_distance || 0;
                const store_distance_delivery = item.delivery_distance || 0;
                const store_distance_total = item.total_distance || 0;

                const store_name = onFormatUrl(item.name);
                const store_id = item.id;
                const store_rating = item.rating;
                const store_reviews = item.reviews;

                const FinalURL =
                  "/" + LANGUAGE + "/" + store_name + "/bs/" + item.id;
                const filtredProducts =
                  PRODUCTS.filter(
                    (item) => item.owner && item.owner.id === store_id
                  ) || [];

                const store_delivery = item.delivery || [];
                const store_pickup = item.pickup || {};
                const store_dropoff = item.dropoff || {};

                const pickup_cost = store_pickup.cost || 0;
                const dropoff_cost = store_dropoff.cost || 0;

                const delivery_cost =
                  parseFloat(pickup_cost) + parseFloat(dropoff_cost);
                const delivery_from = store_delivery[0]
                  ? parseFloat(store_delivery[0].cost || 0)
                  : 0;

                const final_pickup_cost = pickup_cost
                  ? pickup_cost + (pickup_cost * 15) / 100
                  : pickup_cost;
                const final_dropoff_cost = dropoff_cost
                  ? dropoff_cost + (dropoff_cost * 15) / 100
                  : dropoff_cost;

                const final_delivery_cost = delivery_cost
                  ? delivery_cost + (delivery_cost * 15) / 100
                  : delivery_cost;
                const final_delivery_from = delivery_from
                  ? delivery_from + (delivery_from * 15) / 100
                  : delivery_from;

                const PRICE_CURRENCY = CURRENCY || "eur";

                //Filter Cities
                const store_delivery_cities =
                  store_delivery.filter((item) => item.type === "city") || [];
                const store_delivery_points =
                  store_delivery.filter((item) => item.type !== "city") || [];

                return (
                  <>
                    <hr className="w-full border-4" />

                    <div
                      className="w-full flex flex-col py-6 relative"
                      id={"section_" + store_id}
                      key={index}
                    >
                      <div className="w-full flex items-center mb-6">
                        <div
                          className="w-[40px] h-[40px] md:w-[80px] md:h-[80px] mr-3 flex-shrink-0"
                          onClick={() => onSelectProvider(item, true)}
                        >
                          <img
                            className="w-full h-full object-cover rounded-full"
                            src={item.image}
                          />
                        </div>
                        <div>
                          <div className="text-lg font-bold capitalize">
                            {item.name}
                          </div>

                          {store_pickup.name && store_dropoff.name ? (
                            <div className="text-sm text-bagreen-100">
                              {TRANSLATE.wd_available_pick_for}{" "}
                              {numberWithCommas(
                                final_delivery_cost,
                                PRICE_CURRENCY
                              )}
                            </div>
                          ) : (
                            <>
                              {store_delivery.length == 0 ? (
                                <div className="text-sm text-bagreen-100">
                                  {TRANSLATE.wd_available_self}
                                </div>
                              ) : (
                                <div className="text-sm text-bagreen-100">
                                  {TRANSLATE.wd_available_pick_from}{" "}
                                  {numberWithCommas(
                                    final_delivery_from,
                                    PRICE_CURRENCY
                                  )}
                                </div>
                              )}
                            </>
                          )}

                          {/* <div className="text-sm text-bagreen-100">{filtredProducts.length}% Orders accepted</div> */}
                        </div>
                        <Stars
                          reviews={store_reviews}
                          rate={store_rating}
                          className={
                            "flex gap-x-1 ml-auto md:relative flex-shrink-0 md:top-auto top-2 flex-wrap md:w-auto w-[100px] justify-end"
                          }
                          onView={() => onSelectReviews(store_id, true)}
                        />
                      </div>

                      <div className="w-full flex gap-x-6 flex-nowrap overflow-auto pb-6 scrollbar-hide">
                        {filtredProducts.map((product, idx) => {
                          const {
                            name,
                            first_img,
                            owner,
                            distance,
                            reviews,
                            category,
                          } = product;

                          const price_day = product.price_day || 0;

                          const product_owner = owner || {};
                          const owner_name = product_owner.name;

                          const to_url =
                            "/" +
                            LANGUAGE +
                            "/" +
                            onFormatUrl(name) +
                            "/bp/" +
                            product.id;

                          //const price_final = numberWithCommas(price_day, CURRENCY || 'eur');

                          const isAdded = onCartProductAdded(product, owner);

                          const PRICE_DAILY_FINAL =
                            onCalculateDailyPrice(product, routeDuration) *
                            routeDuration;

                          const PriceObx = onFormatPrices({
                            price_day: price_day,
                            price_day_final: PRICE_DAILY_FINAL,
                            duration: routeDuration,
                            category: category,
                            quantity: 1,
                          });

                          const price_final = numberWithCommas(
                            PriceObx.PriceDayFee,
                            PRICE_CURRENCY
                          );
                          const price_discount = PriceObx.PriceDiscount > 0;

                          const total_days = numberWithCommas(
                            PriceObx.PriceNoDiscount,
                            PRICE_CURRENCY
                          );
                          const total_days_discount = numberWithCommas(
                            PriceObx.PriceDiscount,
                            PRICE_CURRENCY
                          );
                          const total_days_total = numberWithCommas(
                            PriceObx.PriceTotalFee,
                            PRICE_CURRENCY
                          );

                          if (isAdded) {
                            return (
                              <div
                                className="w-1/4 min-w-[220px] shrink-0 relative flex mb-auto min-h-[330px] lg:min-h-[450px] md:min-h-[392px]"
                                key={idx}
                              >
                                <div className="w-[44px] h-[44px] rounded-full bg-bagreen-100 flex items-center justify-center text-white absolute bottom-4 right-4 cursor-pointer hover:bg-babonbo-200">
                                  <img src="/assets/img/check-white.svg" />
                                </div>
                                {/* <div style={{whiteSpace: 'break-spaces'}}>
                                                                        {JSON.stringify({
                                                                            PriceObx,
                                                                            price_discount
                                                                        }, null, 2)}
                                                                    </div> */}
                                <Product
                                  addStyles="border-zinc-200 border w-full"
                                  name={name}
                                  price={price_final}
                                  category={category}
                                  price_final={price_final}
                                  price_discount={price_discount}
                                  total_days={total_days}
                                  total_days_discount={total_days_discount}
                                  total_days_total={total_days_total}
                                  provider={owner_name}
                                  img={first_img}
                                  url={to_url}
                                  isTotal={true}
                                  duration={routeDuration}
                                  onClick={() =>
                                    onSelectProduct(product, store_provider)
                                  }
                                  TRANSLATE={TRANSLATE}
                                />
                              </div>
                            );
                          }

                          return (
                            <div
                              className="w-1/4 min-w-[220px] shrink-0 relative flex mb-auto md:min-h-[392px] lg:min-h-[450px] min-h-[330px]"
                              key={idx}
                            >
                              {/* <div style={{whiteSpace: 'break-spaces'}}>
                                                                {JSON.stringify({
                                                                    PriceObx,
                                                                    price_discount
                                                                }, null, 2)}
                                                            </div> */}
                              <Product
                                addStyles="border-zinc-200 border w-full"
                                name={name}
                                price={price_final}
                                category={category}
                                price_final={price_final}
                                price_discount={price_discount}
                                total_days={total_days}
                                total_days_discount={total_days_discount}
                                total_days_total={total_days_total}
                                provider={owner_name}
                                img={first_img}
                                url={to_url}
                                isTotal={true}
                                duration={routeDuration}
                                onClick={() =>
                                  onSelectProduct(product, store_provider)
                                }
                                TRANSLATE={TRANSLATE}
                              />

                              <div
                                className="w-[44px] h-[44px] rounded-full bg-babonbo-100 flex items-center justify-center text-white absolute bottom-4 right-4 cursor-pointer hover:bg-babonbo-200"
                                onClick={() =>
                                  onAddToCart(product, store_provider)
                                }
                              >
                                <img
                                  style={{ filter: "invert(100%)" }}
                                  src="/assets/img/cart-plus.svg"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}

          {OTHER_RESULTS.length > 0 && (
            <div
              className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap"
              style={{ marginTop: 200 }}
            >
              <div className="flex w-full items-center justify-between mb-6">
                <h2 className="text-base font-bold mt-8 text-lg md:text-xl">
                  {TRANSLATE.tool_choose_gear_other.replace(
                    "{{TOTAL}}",
                    OTHER_RESULTS.length
                  )}
                </h2>
              </div>

              {/* {JSON.stringify(RESULTS, null, 2)} */}

              {OTHER_RESULTS.map((item, index) => {
                const store_provider = item;

                const store_distance = item.store_distance || 0;
                const store_distance_delivery = item.delivery_distance || 0;
                const store_distance_total = item.total_distance || 0;

                const store_name = onFormatUrl(item.name);
                const store_id = item.id;
                const store_rating = item.rating;
                const store_reviews = item.reviews;

                const FinalURL =
                  "/" + LANGUAGE + "/" + store_name + "/bs/" + item.id;
                const filtredProducts =
                  PRODUCTS.filter(
                    (item) => item.owner && item.owner.id === store_id
                  ) || [];
                const store_delivery = item.delivery || [];
                const store_pickup = item.pickup || {};
                const store_dropoff = item.dropoff || {};

                const pickup_cost = store_pickup.cost || 0;
                const dropoff_cost = store_dropoff.cost || 0;

                const delivery_cost =
                  parseFloat(pickup_cost) + parseFloat(dropoff_cost);
                const delivery_from = store_delivery[0]
                  ? parseFloat(store_delivery[0].cost || 0)
                  : 0;

                const final_pickup_cost = pickup_cost
                  ? pickup_cost + (pickup_cost * 15) / 100
                  : pickup_cost;
                const final_dropoff_cost = dropoff_cost
                  ? dropoff_cost + (dropoff_cost * 15) / 100
                  : dropoff_cost;

                const final_delivery_cost = delivery_cost
                  ? delivery_cost + (delivery_cost * 15) / 100
                  : delivery_cost;
                const final_delivery_from = delivery_from
                  ? delivery_from + (delivery_from * 15) / 100
                  : delivery_from;

                const PRICE_CURRENCY = CURRENCY || "eur";

                return (
                  <>
                    <hr className="w-full border-4" />

                    <div
                      className="w-full flex flex-col py-6 relative"
                      id={"section_" + store_id}
                      key={index}
                    >
                      <div className="w-full flex items-center mb-6">
                        <div
                          className="w-[40px] h-[40px] md:w-[80px] md:h-[80px] mr-3 flex-shrink-0"
                          onClick={() => onSelectProvider(item, true)}
                        >
                          <img
                            className="w-full h-full object-cover rounded-full"
                            src={item.image}
                          />
                        </div>
                        <div>
                          <div className="text-lg font-bold capitalize">
                            {item.name}
                          </div>

                          {store_pickup.name && store_dropoff.name ? (
                            <div className="text-sm text-bagreen-100">
                              {TRANSLATE.wd_available_pick_for}{" "}
                              {numberWithCommas(
                                final_delivery_cost,
                                PRICE_CURRENCY
                              )}
                            </div>
                          ) : (
                            <>
                              {store_delivery.length == 0 ? (
                                <div className="text-sm text-bagreen-100">
                                  {TRANSLATE.wd_available_self}
                                </div>
                              ) : (
                                <div className="text-sm text-bagreen-100">
                                  {TRANSLATE.wd_available_pick_from}{" "}
                                  {numberWithCommas(
                                    final_delivery_from,
                                    PRICE_CURRENCY
                                  )}
                                </div>
                              )}
                            </>
                          )}

                          {/* <div className="text-sm text-bagreen-100">{filtredProducts.length}% Orders accepted</div> */}
                        </div>
                        <Stars
                          reviews={store_reviews}
                          rate={store_rating}
                          className={
                            "flex gap-x-1 ml-auto md:relative flex-shrink-0 md:top-auto top-2 flex-wrap md:w-auto w-[100px] justify-end"
                          }
                          onView={() => onSelectReviews(store_id, true)}
                        />
                      </div>

                      <div className="w-full flex gap-x-6 flex-nowrap overflow-auto pb-6 scrollbar-hide">
                        {filtredProducts.map((product, idx) => {
                          const {
                            name,
                            first_img,
                            owner,
                            distance,
                            reviews,
                            category,
                          } = product;

                          const price_day = product.price_day || 0;

                          const product_owner = owner || {};
                          const owner_name = product_owner.name;

                          const to_url =
                            "/" +
                            LANGUAGE +
                            "/" +
                            onFormatUrl(name) +
                            "/bp/" +
                            product.id;

                          //const price_final = numberWithCommas(price_day, CURRENCY || 'eur');

                          const isAdded = onCartProductAdded(product, owner);

                          const PRICE_DAILY_FINAL =
                            onCalculateDailyPrice(product, routeDuration) *
                            routeDuration;

                          const PriceObx = onFormatPrices({
                            price_day: price_day,
                            price_day_final: PRICE_DAILY_FINAL,
                            duration: routeDuration,
                            category: category,
                            quantity: 1,
                          });

                          const price_final = numberWithCommas(
                            PriceObx.PriceDayFee,
                            PRICE_CURRENCY
                          );
                          const price_discount = PriceObx.PriceDiscount > 0;

                          const total_days = numberWithCommas(
                            PriceObx.PriceNoDiscount,
                            PRICE_CURRENCY
                          );
                          const total_days_discount = numberWithCommas(
                            PriceObx.PriceDiscount,
                            PRICE_CURRENCY
                          );
                          const total_days_total = numberWithCommas(
                            PriceObx.PriceTotalFee,
                            PRICE_CURRENCY
                          );

                          if (isAdded) {
                            return (
                              <div
                                className="w-1/4 min-w-[220px] shrink-0 relative flex mb-auto min-h-[330px] lg:min-h-[450px] md:min-h-[392px]"
                                key={idx}
                              >
                                <div className="w-[44px] h-[44px] rounded-full bg-bagreen-100 flex items-center justify-center text-white absolute bottom-4 right-4 cursor-pointer hover:bg-babonbo-200">
                                  <img src="/assets/img/check-white.svg" />
                                </div>
                                <Product
                                  addStyles="border-zinc-200 border w-full"
                                  name={name}
                                  price={price_final}
                                  category={category}
                                  price_final={price_final}
                                  price_discount={price_discount}
                                  total_days={total_days}
                                  total_days_discount={total_days_discount}
                                  total_days_total={total_days_total}
                                  provider={owner_name}
                                  img={first_img}
                                  url={to_url}
                                  isTotal={true}
                                  duration={routeDuration}
                                  onClick={() =>
                                    onSelectProduct(
                                      product,
                                      store_provider,
                                      "other"
                                    )
                                  }
                                  TRANSLATE={TRANSLATE}
                                />
                              </div>
                            );
                          }

                          return (
                            <div
                              className="w-1/4 min-w-[220px] shrink-0 relative flex mb-auto md:min-h-[392px] lg:min-h-[450px] min-h-[330px]"
                              key={idx}
                            >
                              <Product
                                addStyles="border-zinc-200 border w-full"
                                name={name}
                                price={price_final}
                                category={category}
                                price_final={price_final}
                                price_discount={price_discount}
                                total_days={total_days}
                                total_days_discount={total_days_discount}
                                total_days_total={total_days_total}
                                provider={owner_name}
                                img={first_img}
                                url={to_url}
                                isTotal={true}
                                duration={routeDuration}
                                onClick={() =>
                                  onSelectProduct(
                                    product,
                                    store_provider,
                                    "other"
                                  )
                                }
                                TRANSLATE={TRANSLATE}
                              />

                              <div
                                className="w-[44px] h-[44px] rounded-full bg-babonbo-100 flex items-center justify-center text-white absolute bottom-4 right-4 cursor-pointer hover:bg-babonbo-200"
                                onClick={() =>
                                  onAddToCart(product, store_provider, "other")
                                }
                              >
                                <img
                                  style={{ filter: "invert(100%)" }}
                                  src="/assets/img/cart-plus.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}

          {/* {EXACT_RESULTS.length == 0 && OTHER_RESULTS.length == 0 && (
            <div
              className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-6 flex flex-col justify-start items-center flex-wrap"
              style={{ marginTop: 200 }}
            >
              <div className="flex w-full items-center justify-between mb-6">
                <h2 className="text-base font-bold text-xl">
                  Unfortunately there is no provider available with these
                  filters
                </h2>

                <MissingProduct
                  {...props}
                  onClose={() => history.push("/" + LANGUAGE)}
                  city={search_city}
                  TYPE={"flow"}
                />
              </div>
            </div>
          )} */}
        </>
      )}

      {/* {CART.length > 0 && <StepSummary {...props} />} */}
    </>
  );
};


export default AdminPlayground