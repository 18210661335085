import React, { useState } from 'react';
import { ArrowForward } from 'react-ionicons';
import CustomDatePicker from '../../DatePicker/CustomDatePicker';
import Dropdown from '../../Dropdown/Dropdown';
import SearchLocation from '../../SearchLocation/SearchLocation';

const mockLocations = [
  { title: 'London', value: 'London' },
  { title: 'Paris', value: 'Paris' },
  { title: 'Istanbul', value: 'Istanbul' },
];

const ModalFilters = (props) => {
  const { product, onClose, CITY = mockLocations } = props;

  const [searchText, setSearchText] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [selectedAges, setSelectedAges] = useState([]);
  const [date, setDate] = useState({
    from: new Date(),
    to: new Date(),
  });

  const ages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => {
    return { title: item > 0 ? `${item} years old` : 'Under 1 year old', value: item };
  });

  return (
    <div
      className='fixed w-screen h-screen top-0 left-0 z-10 flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]'
      aria-modal='true'
      role='dialog'
      aria-labelledby='filter-modal-heading'
    >
      <div className='absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]' aria-label='Filter Modal' />

      <div className='slide-in-bottom flex flex-col m-0 mt-auto md:mt-0 px-4 sm:px-6 lg:px-8 pb-8 pt-8 w-full bg-white md:rounded-2xl relative z-20 overflow-auto '>
        <img className='absolute top-6 right-6 cursor-pointer' src='/assets/img/close.svg' onClick={() => onClose()} />

        <h3 className='my-4' id='filter-modal-heading'>
          Filters
        </h3>

        <div className='flex flex-col gap-8'>
          <div aria-label='City Filter'>
            <SearchLocation label={'City'} selectedLocation={city} setSelectedLocation={setCity} />
          </div>

          <div aria-label='District Filter'>
            <SearchLocation label={'District'} selectedLocation={district} setSelectedLocation={setDistrict} />
          </div>

          <div aria-label='Date Filter'>
            <CustomDatePicker label={'Date'} value={date} onChange={onClose} fullsize />
          </div>

          <div aria-label='Ages Filter'>
            <Dropdown
              multiSelect={true}
              label={'Select Ages'}
              value={selectedAges}
              options={ages}
              placeholder={'Select age...'}
              onChange={(value) => {
                if (selectedAges.includes(value)) {
                  let currentAges = selectedAges.filter((age) => age !== value);
                  let sortedAges = currentAges.sort((a, b) => a - b);
                  setSelectedAges(sortedAges);
                } else {
                  let currentAges = [...selectedAges, value];
                  let sortedAges = currentAges.sort((a, b) => a - b);
                  setSelectedAges(sortedAges);
                }
              }}
              aria-label='Select Ages'
            />
          </div>
        </div>

        <button
          type='button'
          className='flex justify-content-center gap-2 mt-auto py-2 w-full bg-black text-white rounded-lg '
          aria-label='Save Filters'
        >
          SAVE <ArrowForward color={'#fff'} aria-hidden='true' />
        </button>
      </div>
    </div>
  );
};

export default ModalFilters;
