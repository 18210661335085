// import React, { useEffect, useState } from 'react';
// import { MapContainer, TileLayer, Marker, Popup, Circle, Rectangle } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import ProviderContainer from './ProviderContainer';

// import TimeZoneComponent from './TimeZoneComponent';

// delete L.Icon.Default.prototype._getIconUrl;


// const MapComponent = (props) => {

//     const { stores, city, selectedProvider, setSelectedProvider } = props;

//     useEffect(() => {
//         if (selectedProvider) {
//             document.body.style.overflow = "hidden";
//         } else {
//             document.body.style.overflow = "auto";
//         }
//     }, [selectedProvider]);

//     const mapped_stores = stores.map((item) => {
//         return {
//             ...item,
//             latitude: item.geocode.latitude,
//             longitude: item.geocode.longitude
//         }
//     })


//     if (mapped_stores.length == 0) {
//         return null
//     }

//     const MAP_CENTER = onTrimMapCenter(mapped_stores);

//     const cityCenter = city ? [city?.latitude, city?.longitude] : [0, 0] // Center of the city
//     const circleRadius = 100000; // 100 km in meters

//     return (
//         <>
//             {/* {JSON.stringify({
//                 pickup,
//                 dropoff
//             })} */}
//             <div className="w-full flex overflow-auto">
//                 <div className={'w-1/2'}>
//                     <MapContainer center={MAP_CENTER} zoom={7} className='border border-zinc-200' style={{ height: '500px', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}>
//                         <TileLayer
//                             url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                             attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//                         />

//                         <Circle center={cityCenter} radius={circleRadius} color='#26BFBF' fillColor="#26BFBF" fillOpacity={0.1} />


//                         <Marker
//                             position={cityCenter}
//                             eventHandlers={{
//                                 click: (e) => {
//                                     console.log('marker clicked', city)
//                                 },
//                             }}
//                             icon={L.divIcon({
//                                 iconSize: [20, 20],
//                                 iconAnchor: [20 / 2, 20],
//                                 className: "mymarker",
//                                 html: '<img src="/assets/img/babonbo_marker.png" style="width: 20px; height: 20px" />',
//                             })}

//                         />

//                         {mapped_stores.map(provider => (
//                             <Marker
//                                 key={provider.id}
//                                 position={[provider.latitude, provider.longitude]}
//                                 eventHandlers={{
//                                     click: (e) => {
//                                         setSelectedProvider(provider)
//                                         console.log('marker clicked', provider)
//                                     },
//                                 }}
//                                 icon={L.divIcon({
//                                     iconSize: [30, 30],
//                                     iconAnchor: [30 / 2, 30],
//                                     className: "mymarker",
//                                     html: `<img src="${provider.image}" class="bg-white object-cover" style="width: 30px; height: 30px; border-radius: 100%; border: solid 2px #26BFBF" />`,
//                                 })}

//                             />
//                         ))}


//                     </MapContainer>
//                 </div>
//                 <div className={'w-1/2'}>
//                     <div className='flex w-full flex-wrap md:flex-nowrap mb-5 relative items-start'>
//                         <div className='w-full mb-0 border-r min-w-[300px] border-r-zinc-200 p-6 border border-zinc-200 rounded-lg md:sticky top-[120px] bg-white'>
//                             <TimeZoneComponent {...props} mappedStores={mapped_stores} />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {selectedProvider && (
//                 <div className="fixed w-screen h-screen top-0 left-0 flex-col " style={{ zIndex: 9999 }}>
//                     <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
//                     <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col slider-in">
//                         <div className="flex justify-between p-8 border-b border-zinc-200 bg-white z-20">
//                             {/* <h2>Search a City</h2> */}
//                             <div onClick={() => setSelectedProvider(null)}>
//                                 <img className='w-[150px] mr-4' src='/assets/img/babonbo_logo_provider.png' alt='' />
//                             </div>
//                             <img className="cursor-pointer" src="/assets/img/close.svg" alt='' onClick={() => setSelectedProvider(null)} />
//                         </div>
//                         <div className='w-full overflow-auto'>

//                             <ProviderContainer {...props} PROVIDER={selectedProvider} />

//                         </div>
//                     </div>
//                 </div>

//             )}
//         </>

//     );
// };

// const onTrimMapCenter = (markers) => {
//     return {
//         lat: getMiddle('latitude', markers),
//         lng: getMiddle('longitude', markers)
//     }
// }

// const getMiddle = (prop, markers) => {
//     let values = markers.map(m => m[prop]);
//     let min = Math.min(...values);
//     let max = Math.max(...values);
//     if (prop === 'latitude' && (max - min > 180)) {
//         values = values.map(val => val < max - 180 ? val + 360 : val);
//         min = Math.min(...values);
//         max = Math.max(...values);
//     }
//     let result = (min + max) / 2;
//     if (prop === 'longitude' && result > 180) {
//         result -= 360
//     }
//     return result;
// }


// export default MapComponent;

import React from "react"
export default () => {
  return (
    <></>
  )
}
