import React, {useState, useEffect} from "react";

import LoaderMaster from "../Loaders/Loaders";
import StarRating from "../General/Stars";

import {postApiPrivate} from "../../api";

import { CloseSharp, EyeSharp, SaveSharp, TrashBinSharp } from "react-ionicons";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, InputAdornment,TextareaAutosize, TextField } from "@mui/material";


import "../Profile/z_profile.css";

const AdminPartnersDetails = (props) => {

  const {TRANSLATE, history, match, location, LANGUAGE} = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [PUBLIC_PARTNER, setPartner] = useState({});
  

  useEffect(()=> {

      var partner_id = match.params.id || '';

      onFetchPublic(partner_id);

  }, []);

  const onFetchPublic = async(partner_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    
    setEdited(false);
    setLoading(true);

    try{

      const FORM = { id: partner_id};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/partners/details', FORM, JWT);
        
      if(DATA_RESPONSE.error){

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setPartner(DATA_RESPONSE || {});
      setLoading(false);

    }
    catch(e){

        setLoading(false);
    }
      
  }
  
  const onUpdatePartner = async(key, value) => {

    setEdited(true);
    setPartner({
      ...PUBLIC_PARTNER,
      [key]: value
    });

  }

  const onSavePartner = async(user_id) => {
      
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        const FORM = { id: user_id};

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/users/edit', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Update This Partner', 'error');
            onFetchPublic(user_id);

            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("SEARCH ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('Partner Updated', 'This Partner has been updated successfully !!!', 'success');
        onFetchPublic(user_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Update This Partner', 'error');
          onFetchPublic(user_id);
      }

  }

  const onDeletePartner = async(user_id) => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
      
      setSaving(true);

      try{

        const FORM = { id: user_id};

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/users/delete', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Delete This Partner', 'error');
            onFetchPublic(user_id);
            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        setSaving(false);
        swal('Partner Deleted', 'This Partner has been deleted successfully !!!', 'success');
        history.replace('/admin/users');

      }
      catch(e){

        setSaving(false);
        swal('Error', 'Cannot Delete This Partner', 'error');
        onFetchPublic(user_id);
      }
  }
  
  const onSavePassword = async(user_id, new_password) => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
    setSaving(true);

    try{

      const FORM = {id: user_id, np: new_password.trim()};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/users/password', FORM, JWT);
        
      if(DATA_RESPONSE.error){
          setSaving(false);
          swal('Error', 'Cannot Update the password for this Partner', 'error');
          onFetchPublic(user_id);

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Partner Updated', 'This Partner\'s Password has been updated successfully !!!', 'success');
      onFetchPublic(user_id);

    }
    catch(e){

        setSaving(false);
        swal('Error', 'Cannot Update the password for this Partner', 'error');
        onFetchPublic(user_id);
    }
      
  }
  
  const onSendPush = async(user_id, content) => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
    setSaving(true);

    try{

      const FORM = {id: user_id, content: content.trim()};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/users/push', FORM, JWT);
        
      if(DATA_RESPONSE.error){
          setSaving(false);
          swal('Error', 'Cannot send the push notification to this Partner', 'error');
          onFetchPublic(user_id);

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Push Sent', 'This push notification has been sent successfully to this Partner !!!', 'success');
      onFetchPublic(user_id);

    }
    catch(e){

        setSaving(false);
        swal('Error', 'Cannot Update the password for this Partner', 'error');
        onFetchPublic(user_id);
    }
      
  }

  if(isLoading){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading}/>
    )

  }

  if(isSaving){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
    )

  }


  const {
    id,
    type,
    status,
    updated,
    created,
    manager,
    product,
    store,
    review,
    order

  } = PUBLIC_PARTNER;


  return(
   
      <Container maxWidth="xl" background={'#f0f5f9'}>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={4} md={6}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                      <div className="top_store_card">
                          <div className="top_store_profile">

                             
                              <h3 className="store_title">#{id}</h3>
      
                              
                          </div>
                          
                      </div> 

                      {!isEdited && (
                          <>
                          

                            <div className="top_add_cart" 
                                 style={{backgroundColor: '#e64942'}} 
                                 onClick={()=> {
                                    swal({
                                        title: 'Confirm to Delete',
                                        text: 'Please insert CONFIRM to delete this partner',
                                        content: "input",
                                    })
                                    .then((value) => {

                                      if(value === 'CONFIRM'){

                                          onDeletePartner(id)
                                      }
                                      else{

                                          swal('Invalid Password', 'You are not authorized to delete this partner', 'error');
                                      }

                                    });
                                 }}
                            >
                              <TrashBinSharp color={'#fff'} style={{marginRight: 10}} /> Delete Partner 
                            </div>
                          </>
                      )}
                     
                      {isEdited && (
                        <> 

                          <div className="top_add_cart"  
                               style={{backgroundColor: '#59ad6a'}} 
                               onClick={()=> {
                                swal({
                                    title: 'Confirm to Save',
                                    text: 'Please insert the master password to update this user',
                                    content: "input",
                                })
                                .then((value) => {

                                  if(value === 'KingIsMe'){

                                      onSavePartner(id)
                                  }
                                  else{

                                      swal('Invalid Password', 'You are not authorized to update this user', 'error');
                                  }

                                });
                             }}
                               
                          >
                            <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Update Profile
                          </div>

                          <div className="top_add_cart"  style={{backgroundColor: '#ccc'}} onClick={()=> onFetchPublic(id)}>
                            <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Cancel Changes
                          </div>
                        
                        </>
                      )}
              
                      
                  </div>
              </Grid>
              <Grid item xs={12} lg={5} md={6}>
              
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                        <div className="top_box_magic" style={{marginBottom: 20}}>
                            <h1>
                              Status
                            </h1>

                            {status === 'pending' && (
                               <Chip label="Pending" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning"  onClick={(e)=> onUpdatePartner('status', 'approved')}/>
                            )}

                            {status === 'approved' && (
                               <Chip label="Approved" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdatePartner('status', 'rejected')}/>
                            )}

                            {status === 'rejected' && (
                               <Chip label="Rejected" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="error"  onClick={(e)=> onUpdatePartner('status', 'pending')}/>
                            )}

                        </div>   

                        <div className="top_box_magic" style={{marginBottom: 20}}>
                            <h1>
                              Type
                            </h1>

                            {status === 'add_store' && (
                               <Chip label="Store Created" style={{maxWidth: 100, marginTop: 10, color: '#fff', background: '#26BFBF'}} variant="filled"   />
                            )}

                            {status === 'edit_store' && (
                               <Chip label="Store Edited" style={{maxWidth: 100, marginTop: 10, color: '#fff', background: '#26BFBF'}} variant="filled"  />
                            )}

                            {status === 'add_product' && (
                               <Chip label="Product Created" style={{maxWidth: 100, marginTop: 10, color: '#fff', background: 'var(--orange)'}} variant="filled"  />
                            )}

                            {status === 'edit_product' && (
                               <Chip label="Product Edited" style={{maxWidth: 100, marginTop: 10, color: '#fff', background: 'var(--orange)'}} variant="filled"  />
                            )}

                            {status === 'add_profile' && (
                               <Chip label="Profile Created" style={{maxWidth: 100, marginTop: 10, color: '#fff', background: '#fd6270'}} variant="filled"  />
                            )}

                            {status === 'add_product' && (
                               <Chip label="Profile Edited" style={{maxWidth: 100, marginTop: 10, color: '#fff', background: '#fd6270'}} variant="filled"  />
                            )}

                        </div>   


                
                </div>
                

              </Grid>
              <Grid item xs={12} lg={3} md={3}>
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                    
                    <div className="top_box_magic">
                        <h1>
                          Partner Created
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <p>
                            {moment(created).format('dddd DD MMMM YYYY - HH:mm')}
                          </p> 
                        </div>
                    </div>   
                    <div className="top_box_magic">
                        <h1>
                          Partner Updated
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <p>
                            {moment(updated).format('dddd DD MMMM YYYY - HH:mm')}
                          </p> 
                        </div>
                    </div>  

                    {/* {sid && (
                      <div className="top_box_magic">
                          <h1>
                          Store
                          </h1>

                          <Chip
                              avatar={<Avatar alt={sname} src={simg} />}
                              label={sname.toLowerCase()}
                              variant="outlined"
                              size='medium'
                              style={{textTransform: 'capitalize', marginTop: 10, width: 'fit-content'}}
                              onClick={()=> history.push('/admin/stores/profile/'+sid)}
                          />
                          
                      </div>
                    )} */}


                   
                  
                </div>
              </Grid>
            </Grid>            
          
      </Container>

    
  )


}


const getSplittedNames = (full_name) => {
    
  const full_name_array = full_name ? full_name.split(' ') : [];
  const first_name = full_name_array[0];
  const last_name = full_name_array[full_name_array.length - 1];

  return {
      first_name,
      last_name
  }
}


export default AdminPartnersDetails
