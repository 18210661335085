import React, { useEffect, useState } from "react";

import { CloseOutline } from "react-ionicons";
import LoaderMaster from "../Loaders/Loaders";
import { TextareaAutosize } from "@mui/material";
import { Circle, CircleOutlined, CircleSharp } from "@mui/icons-material";
import swal from "sweetalert";
import { postApiPrivate } from "../../api";


const OrdersStatus = (props) => {


    const { onClose, ORDER, STORE_ID, history, location, match, onFetchSingle } = props;

    const [isLoading, setLoading] = useState(false);
    
    const [STATUS, setStatus] = useState(null);
    const [MESSAGE, setMessage] = useState('');

    const [SEND_EMAIL_PROVIDER, setSendEmailProvider] = useState(false);
    const [SEND_EMAIL_CUSTOMER, setSendEmailCustomer] = useState(false);
    const [SEND_PUSH_PROVIDER, setSendPushProvider] = useState(false);
    const [SEND_PUSH_CUSTOMER, setSendPushCustomer] = useState(false);
    
    const onChangeStatus = async(status) => {

        if(status === 'rejected' || status === 'canceled'){

            if(MESSAGE.length <10){

                swal('Message/Reason Missing', 'You forgot to add a reason to change this order', 'warning');
                return 
            }
        }

        let final_status = STATUS;

        if(status){
            final_status = status;
        }
       

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);
  
        try{
  
            var DATA_RESPONSE = await postApiPrivate('/admin/orders/status', {
                id: ORDER.id, 
                status: final_status, 
                message: MESSAGE,
                email_customer: SEND_EMAIL_CUSTOMER,
                email_provider: SEND_EMAIL_PROVIDER,
                push_customer: SEND_PUSH_CUSTOMER,
                push_provider: SEND_PUSH_PROVIDER
            } , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API LOADING UPDATE STATUS", DATA_RESPONSE.error);
                setLoading(false);

                alert(DATA_RESPONSE.error);
                
                return 
            }
  
            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);
  
            setLoading(false);
            swal('Status Changed', 'Your Status has been changed successfully to ('+final_status+') !!!', 'success');
            onClose();
            
            const ORDER_ID = ORDER.id;
            if(ORDER_ID){
                onFetchSingle(ORDER_ID);
            }
  
        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            setLoading(false);
        }
    }

    const onUpdateStatus = (status)=> {
        
        if(status === 'rejected' || status === 'canceled'){
            setStatus(status);
            return 
        }

        setStatus(status);
        onChangeStatus(status)
       
    }

    if(STATUS){

        return (
            <>
                <div className="slideout-wrapper active" style={{ background: '#00000040' }}>
    
                    <div className="slideout-content" style={{ color: '#000000' }}>
    
                        <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>
    
                            <div className="font-bold text-lg">
                                Order #{ORDER.id} - ({STATUS})
                            </div>
                            <CloseOutline color={"#000"} onClick={() => setStatus(null)} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />
    
                        </div>
    
                        <div className="slideout-content-scroll" style={{margin: 0}}>
    
                            {isLoading ? (
                                <LoaderMaster type={'secondary'}/>
                            ) : (
                                <>
                                    <div className="d-flex flex-column pt-8 md:pb-0 pb-32">

                                        <div className="font-bold text-lg mb-5">
                                            Message/Reason - ({STATUS}) :
                                        </div>
                                        
                                        <TextareaAutosize
                                            minRows={4}
                                            maxRows={10}
                                            placeholder=""
                                            defaultValue={MESSAGE}
                                            style={{ width: '100%', border: 'solid 1px #00000019', borderRadius: 10, padding: 10 }}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />


                                        <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'green', color: '#ffffff', width: '100%', minHeight: 60, marginTop: 10 }} onClick={() => onChangeStatus()}>
                                            Change Status
                                        </div>

                                        <div style={{height: 200}}></div>
                                    </div>
                                </>
                            )}
    
    
                        </div>
                        
    
                    </div>
    
                </div>
    
                
            </>
        )
    }

    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                         <div className="font-bold text-lg">
                            Change Order #{ORDER.id}
                        </div>
                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{margin: 0}}>

                        {isLoading ? (
                            <LoaderMaster type={'secondary'}/>
                        ) : (
                            <>
                                <div className="d-flex flex-column pt-8 md:pb-0 pb-32">

                                    <div className="font-bold text-lg mb-5">
                                        Order Status :
                                    </div>
                                    <div className="row justify-content-center mb-5">

                                            <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: '#9AD6AB', color: '#ffffff', width: '100%' }} onClick={() => onUpdateStatus('accepted')}>
                                            Accepted
                                            </div>

                                            <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: '#ec1529', color: '#ffffff', width: '100%' }} onClick={() => onUpdateStatus('rejected')}>
                                            Rejected
                                            </div>

                                            <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: '#88D1F1', color: '#ffffff', width: '100%' }} onClick={() => onUpdateStatus('delivering')}>
                                                Delivering
                                            </div>

                                            <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: '#ff8b3d', color: '#ffffff', width: '100%' }} onClick={() => onUpdateStatus('delivered')}>
                                                Delivered
                                            </div>

                                            <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'green', color: '#ffffff', width: '100%' }} onClick={() => onUpdateStatus('complete')}>
                                                Completed
                                            </div>

                                            <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: '#ec1529', color: '#ffffff', width: '100%' }} onClick={() => onUpdateStatus('canceled')}>
                                                Canceled
                                            </div>
                                    </div>

                                    <div className="font-bold text-lg mb-5">
                                        Parameters :
                                    </div>
                                    <div className="flex flex-column justify-content-center">
                                        
                                        <div>
                                           <CircleSharp color={SEND_EMAIL_PROVIDER ? 'success' : 'warning'} onClick={()=> setSendEmailProvider(!SEND_EMAIL_PROVIDER)}/> Send Email to Provider
                                        </div>

                                        <div>
                                           <CircleSharp color={SEND_EMAIL_CUSTOMER ? 'success' : 'warning'} onClick={()=> setSendEmailCustomer(!SEND_EMAIL_CUSTOMER)}/> Send Email to Customer
                                        </div>

                                        <div>
                                           <CircleSharp color={SEND_PUSH_PROVIDER ? 'success' : 'warning'} onClick={()=> setSendPushProvider(!SEND_PUSH_PROVIDER)}/> Send Push to Provider
                                        </div>

                                        <div>
                                           <CircleSharp color={SEND_PUSH_CUSTOMER ? 'success' : 'warning'} onClick={()=> setSendPushCustomer(!SEND_PUSH_CUSTOMER)}/> Send Push to Customer
                                        </div>
                                           
                                    </div>

                                    <div style={{height: 200}}></div>
                                </div>
                            </>
                        )}


                    </div>
                    

                </div>

            </div>

            
        </>
    )
}

export default OrdersStatus