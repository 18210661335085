import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { CaretDown, CheckboxOutline, SquareOutline } from 'react-ionicons';
import './dropdown.css';

const Dropdown = ({
  value,
  options,
  label,
  placeholder,
  disabled,
  onChange,
  searchText,
  setSearchText,
  icon,
  multiSelect,
  searchDisabled,
  outlined,
  isProvider,
}) => {
  const [visible, setVisible] = useState(false);
  const [onFocus, setOnFocus] = useState(false);

  useEffect(() => {
    if (searchText && options) {
      setVisible(true);
    }

    console.log('HERE', searchText, options, visible);
  }, [searchText]);

  const onSelect = (x) => {
    if (searchText) {
      setSearchText('');
    }
    onChange(x);
    setVisible(false);
    setOnFocus(false);
  };

  const onMultiSelect = (x) => {
    if (searchText) {
      setSearchText('');
    }

    onChange(x);
  };

  const onChangeSearchText = (e) => {
    e.preventDefault();

    setSearchText(e.target.value);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className='relative'
        id='dropdown-section'
        role='combobox'
        aria-expanded={visible}
        aria-haspopup='listbox'
        aria-labelledby='dropdown-label'
      >
        <div
          className={`dropdown-container ${outlined ? 'outlined' : ''} ${
            value ? (isProvider && outlined ? 'border-b-pr' : 'border-b-ba') : ''
          }`}
          aria-controls='dropdown-list'
        >
          {label && <label id='dropdown-label'>{label}</label>}

          {!multiSelect && (
            <>
              <div
                className={`search-box text-black`}
                onClick={() => {
                  setVisible(true);
                  setOnFocus(true);
                }}
                aria-label='Search Box'
              >
                {(searchText || onFocus || !value) && !searchDisabled ? (
                  <input
                    value={searchText ? searchText : value}
                    type='text'
                    placeholder='Search'
                    onChange={onChangeSearchText}
                    disabled={searchDisabled}
                    aria-label='Search Input'
                  />
                ) : value ? (
                  options
                    .filter((x) => x.value === value)
                    .map((item) => (
                      <p className='dropdown-head'>
                        {item.icon && <img src={item.icon} alt='' className='dropdown-option-img' aria-hidden='true' />}
                        {item.title}
                      </p>
                    ))
                ) : (
                  <p className='placeholder'>{value ? value : placeholder} </p>
                )}
                {icon ? (
                  icon
                ) : (
                  <CaretDown width={'20px'} height={'20px'} color={'rgba(0, 0, 0, 0.54)'} aria-hidden='true' />
                )}
              </div>

              {visible && (
                <div className='dropdown-body text-black' id='dropdown-list' role='listbox'>
                  {options.map((x, index) => (
                    <div
                      className={`dropdown-option ${x.value === value ? 'selected' : ''} ${
                        isProvider ? 'provider' : ''
                      }`}
                      onClick={() => onSelect(x.value)}
                      key={`option-${index}`}
                      role='option'
                      aria-selected={x.value === value}
                    >
                      {x.icon && <img src={x.icon} alt='' className='dropdown-option-img' aria-hidden='true' />}
                      {x.title}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {multiSelect && (
            <>
              <div
                className='search-box justify-content-between'
                onClick={() => setVisible(true)}
                aria-label='Multi-Select Search Box'
              >
                {value.length > 0 ? (
                  String(value.map((x) => x.value))
                ) : (
                  <p className='placeholder'>{value ? value : placeholder} </p>
                )}

                {icon ? (
                  icon
                ) : (
                  <CaretDown width={'20px'} height={'20px'} color={'rgba(0, 0, 0, 0.54)'} aria-hidden='true' />
                )}
              </div>

              {visible && (
                <div className='dropdown-body' id='multi-select-list' role='listbox'>
                  {options.map((x, index) => (
                    <button
                      type='button'
                      className={`dropdown-option w-full flex justify-content-between align-items-center`}
                      onClick={() => onMultiSelect(x)}
                      key={`multi-option-${index}`}
                      role='option'
                      aria-selected={value.includes(x)}
                    >
                      <div>{x.title}</div>
                      {value.includes(x) ? (
                        <CheckboxOutline width={'20px'} height={'20px'} color={'#fc6170'} aria-hidden='true' />
                      ) : (
                        <div>
                          <SquareOutline width={'20px'} height={'20px'} color={'#ddd'} aria-hidden='true' />
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
