import React, {useEffect, useState} from "react";

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import {Calendar} from 'react-modern-calendar-datepicker';

import { postApiPrivate } from "../../api";

import moment from "moment";
import swal from "sweetalert";
import { CloseOutline } from "react-ionicons";
import LoaderMaster from "../Loaders/Loaders";

const StoreAvailability = (props) => {

    const {onClose, USER, TRANSLATE, STORE_ID} = props;

    const [isLoading, setLoading] = useState(false);
    const [AVAILABILITY, setAvailability] = useState([]);

    useEffect(()=> {
        onFetchAvailability();
    }, []);

    const onFetchAvailability = async() => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;


        if(!STORE_ID){
            return alert('Please select a store');
        }

        const FORM = { 
          id: STORE_ID
        }

        setLoading(true);
        
        const DATA_RESPONSE = await postApiPrivate('/admin/stores/availability', FORM , JWT);
            
        if(DATA_RESPONSE.error){
            console.log("ERROR FROM API LOADING PRODUCTS", DATA_RESPONSE.error);

                setLoading(false);
                
            return 
        }

        
        const DATA_AVAILABILITY = DATA_RESPONSE.data || [];
        const FORMATTED_AVAILABILITY = DATA_AVAILABILITY.map((item) => {
            return onFormatDateToCalendar(item)
        });

        setAvailability(FORMATTED_AVAILABILITY);
        
        setLoading(false);
    }

    const onSaveAvailability = async() => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const store_id = STORE_ID || '';

        if(!store_id){
            return alert('Error');
        }

        const MAPPED_AVAILABILITY = AVAILABILITY.map((item) => {
            return onFormatDateToString(item)
        });

        const SORTED_AVAILABILITY = MAPPED_AVAILABILITY.sort(function (a, b) {
            const a_moment = moment(a, 'DD/MM/YYYY');
            const b_moment = moment(b, 'DD/MM/YYYY');
            return a_moment.diff(b_moment)
        });

        const FORM = { 
          id: store_id,
          ch: true,
          dt: SORTED_AVAILABILITY
        }

        setLoading(true);
        
        const DATA_RESPONSE = await postApiPrivate('/admin/stores/availability', FORM , JWT);
            
        if(DATA_RESPONSE.error){
            console.log("ERROR FROM API LOADING PRODUCTS", DATA_RESPONSE.error);

                setLoading(false);
                
            return 
        }

        setLoading(false);

        swal('Save Changes', 'Store Availability updated successfully', 'success');
        
    }

    const onFormatDateToString = (DATE) => {
		const year = DATE.year.toString();
		const month = DATE.month.toString().padStart(2, 0);
		const day = DATE.day.toString().padStart(2, 0);
		return `${day}/${month}/${year}`;
	};

    const onFormatDateToCalendar = (DATE) => {

        const newDateMoment = moment(DATE, 'DD/MM/YYYY');

        const newDateObx = {
            day: parseInt(newDateMoment.format('D')),
            month: parseInt(newDateMoment.format('M')),
            year: parseInt(newDateMoment.format('YYYY'))
        }

        return newDateObx;
       
    }

    const onClearCalendary = () => {

        swal({
            title: 'Are you sure ?',
            text: 'Once deleted the data will be lost. Would you like to proceed ?',
            icon: "warning",
            buttons: ['Cancel', 'Yes Clear'],
            dangerMode: true,
            })
            .then((willDelete) => {
            if (willDelete) {
               setAvailability([]);
               swal('Calendary Cleared','Store Availability cleared successfully. Make sure to Save changes.', 'success');
            } 
        });
    }
  

    const MAPPED_AVAILABILITY = AVAILABILITY.map((item) => {
        return onFormatDateToString(item)
    });

    const SORTED_AVAILABILITY = MAPPED_AVAILABILITY.sort(function (a, b) {
        const a_moment = moment(a, 'DD/MM/YYYY');
        const b_moment = moment(b, 'DD/MM/YYYY');
        return a_moment.diff(b_moment)
    });

    return (
        <> 
            <div className="slideout-wrapper active" style={{background: '#00000040'}}>

                <div className="slideout-content" style={{color: '#000000'}}>

                     <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Store Availability
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        {isLoading ? (
                            <LoaderMaster type={'secondary'} />
                        ) : (
                            <>
                                <div className="d-flex flex-column align-items-center justify-content-center pt-8 md:pb-0 pb-32">
                                    <Calendar
                                        value={AVAILABILITY}
                                        onChange={setAvailability}
                                        colorPrimary="#27BFBF"
                                        colorPrimaryLight="#E9F9F9"
                                        calendarClassName="provider-calendar flex-shrink-0 max-w-[320px]"
                                    />
                                    
                                    {SORTED_AVAILABILITY.length > 0 && (
                                        <div style={{width: '100%', marginTop: 30}}>

                                            <div style={{marginBottom: 20, fontWeight: 'bold'}}>
                                                Store Closed for the following dates: 
                                            </div>

                                            {SORTED_AVAILABILITY.map((item, index)=> {
                                                const formattedDate = moment(item, 'DD/MM/YYYY').format('DD MMMM YYYY');

                                                return (
                                                    <div className="p-3 border-zinc-100 bg-white border rounded-lg mb-2 text-sm flex justify-between items-center" key={index}>
                                                    • {formattedDate}
                                                    </div>
                                                )
                                                
                                            })}

                                        </div>
                                    )}

                                    <div style={{height: 200}}></div>
                                    
                                    
                                </div>
                                
                            </>
                        )}
                            
                                
                    </div>

                    {!isLoading && (
                        <div className="d-flex align-items-center justify-content-between" style={{backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>
                            <div className="top_add_cart" style={{width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5}} onClick={()=> onClearCalendary()}>
                                Clear Calendar
                            </div>
                            <div className="top_add_cart" style={{width: '50%', backgroundColor: '#26BFBF', marginLeft: 5}} onClick={()=> onSaveAvailability()}>
                                Save Changes
                            </div>
                        </div>
                    )}

                </div>

            </div>

          
        </>
    )
}

export default StoreAvailability