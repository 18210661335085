import React, { useEffect, useState } from "react";

import { postApiPrivate } from "../../api";

import swal from "sweetalert";
import { CloseOutline } from "react-ionicons";
import LoaderMaster from "../Loaders/Loaders";

import Iframe from 'react-iframe';
import SearchAutoComplete from '../General/SearchAutoComplete';
import Tooltip from '../General/Tooltip';

import ProviderMap from '../General/ProviderMap';


const StoreLocation = (props) => {


    const { onClose, USER, STORE_ID, history, location, match, DASHBOARD, LANGUAGE, TRANSLATE, onFetchStore } = props;

    const [isLoading, setLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const [isLocation, setLocation] = useState(false);

    const [LOCATION, setCurrentLocation] = useState(null);


    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/' + LANGUAGE + '/login');
        }

    }, [USER, DASHBOARD]);

    const onSelectLocation = (data) => {
        setCurrentLocation(data);

        //alert(JSON.stringify(data, null, 2))

    }

    const onSaveStore = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const store_id = STORE_ID || '';

        if (!store_id) {
            return alert('error');
        }

        const FORM = {
            id: store_id,
            location: LOCATION
        }

        console.log("FORM TO SAVE ===> ", FORM);

        setSaving(true);

        try {

            const DATA_RESPONSE = await postApiPrivate('/admin/stores/edit', FORM, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING STORE", DATA_RESPONSE.error);
                setSaving(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            swal(TRANSLATE.alert_store_updated.t, TRANSLATE.alert_store_updated.s, 'success')
            //onFireAlert(TRANSLATE.alert_store_updated, 'success');

            onFetchStore(store_id)


        }
        catch (e) {

            setSaving(false);
        }

    }


    const LocationAddress = DASHBOARD.address || '';
    const LocationCity = DASHBOARD.city || '';
    
    const isMap = (LOCATION && LOCATION.address) ? LOCATION.address : LocationAddress;


    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Store Location
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        {isLoading || isSaving ? (
                            <LoaderMaster type={'secondary'} />
                        ) : (
                            <>
                                <div className="d-flex flex-column align-items-center justify-content-center pt-8 md:pb-0 pb-32">

                                    <div className="w-full">

                                        {isLocation ? (
                                            <div className="input-group with-icon flex flex-col mb-6">
                                                <label>
                                                    {TRANSLATE.tool_store_address}
                                                    <Tooltip desc={TRANSLATE.tool_enter_store_address} />
                                                </label>

                                                <img className="input-icon" src="/assets/img/van.svg" />

                                                <SearchAutoComplete {...props}
                                                    onSelectLocation={(e) => onSelectLocation(e)}
                                                    currentLocation={LOCATION}
                                                    placeholder={TRANSLATE.wd_address_city_postal}
                                                    LANGUAGE={LANGUAGE}
                                                    theme={'provider'}
                                                />

                                                {(LOCATION) ? (
                                                    <>
                                                        {isSaving ? (
                                                            <div className="w-[50px] h-[50px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 flex justify-center items-center absolute right-[5px] z-30 cursor-not-allowed" style={{top: 40}}>
                                                                <img className='animate-spin w-[20px] h-[20px]' src="/assets/img/loader-white.png" />
                                                            </div>
                                                        ) : (
                                                            <div className="w-[50px] h-[50px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 flex justify-center items-center absolute right-[5px] z-30 cursor-pointer" style={{top: 40}} onClick={() => onSaveStore()}>
                                                                <span className='text-white' style={{ fontSize: 12 }}>
                                                                    {TRANSLATE.btn_save}
                                                                </span>
                                                            </div>
                                                        )}

                                                    </>
                                                ) : (
                                                    <div className="w-[50px] h-[50px] rounded-full flex justify-center items-center absolute right-[5px] z-30 cursor-not-allowed" style={{background: 'grey', top: 40}}>
                                                        <span className='text-white' style={{ fontSize: 12 }}>
                                                            {TRANSLATE.btn_save}
                                                        </span>
                                                    </div>
                                                )}


                                            </div>

                                        ) : (
                                            <div className='flex w-full items-center justify-between mb-6 md:flex-nowrap flex-wrap'>
                                                <div>
                                                    <label className='font-semibold'>
                                                        {TRANSLATE.tool_store_address}
                                                        <Tooltip desc={TRANSLATE.tool_enter_store_address} />
                                                    </label>
                                                    <div className="font-normal text-sm flex items-center">
                                                        {LocationAddress}
                                                        <img className="ml-4" src="/assets/img/edit.svg" onClick={() => setLocation(true)} />
                                                    </div>
                                                </div>

                                            </div>
                                        )}

                                        {isMap && (
                                            <div className="w-full mb-10">
                                               <Iframe url={"https://maps.google.com/maps?q="+isMap+"&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                                                        width="100%"
                                                        height="300"
                                                        className="map_frame"
                                                        frameborder="0" 
                                                        scrolling="no" 
                                                        marginheight="0" 
                                                        marginwidth="0"
                                                />

                                            </div>
                                        )}

                                    </div>

                                    <div style={{ height: 200 }}></div>
                                </div>
                            </>
                        )}


                    </div>


                </div>

            </div>


        </>
    )
}

const StoreMap = (props) => {

    const {store, geo, TRANSLATE, USER, LANGUAGE, type} = props;
  
    const [POINTS, setPoints] = useState([]);
  
    useEffect(()=> {
  
        const store_geocode = geo || {}
        const store_latitude = geo.latitude || 0;
        const store_longitude = geo.longitude || 0;
    
        const store_points = store.delivery || [];
        const store_filter = store_points.filter((item)=> item.geocode);
        const final_points = [...store_filter, {
          name: TRANSLATE.wd_provider_place,
          type: 'home',
          geocode: {
            latitude: store_latitude,
            longitude: store_longitude,
          },
          cost: 0
        }];

        // alert(JSON.stringify({
        //     store_geocode
        // }, null, 2))
  
        console.log("FINAL POINTS ===> ", final_points);
        setPoints(final_points);
  
    }, [store])
  
  
    return (
      
      <div style={{marginTop: 20}}>
        <ProviderMap PROVIDER={store} POINTS={POINTS} TRANSLATE={TRANSLATE} LANGUAGE={LANGUAGE} USER={USER} type={type} />
      </div>
  
    )
  
}

export default StoreLocation