import React, { useState } from 'react';
import { AddCircleOutline } from 'react-ionicons';

const AboutBabonbo = (props) => {
  const { TRANSLATE } = props;

  const [FaqIndex, setFaqIndex] = useState(null);

  return (
    <div className='layout'>
      {' '}
      <div className='quicksand w-full mx-auto justify-center max-w-8xl px-4 sm:px-6 lg:px-8 text-xs md:text-base bg-white rounded-lg'>
        <div className='relative mt-24 my-8 w-full text-center'>
          <img className='absolute top-4 right-6 cursor-pointer' src='/assets/img/close.svg' onClick={() => {}} />
          <div style={{ width: '100%', height: '4rem' }} />
          <h1 className='text-babonbo-100 text-2xl text-center mb-4'>ABOUT BABONBO</h1>
        </div>

        {/** About Section */}
        <section className='w-full ' aria-labelledby='about-babonbo-heading'>
          <article className='text-base md:text-lg font-semibold'>
            Babonbo is a baby gear rental marketplace offering a variety of baby gear such as strollers, car seats, high
            chairs, and more through locals for traveling families in more than 300+ locations. <br /> <br /> Babonbo
            was started in 2018 by Duygu Sefa, founder, customer and provider of Babonbo. Duygu, a mother of two,
            created Babonbo inspired by her search for an easier way to travel with children.
          </article>

          <img
            className='md:w-1/2 w-full h-full object-cover rounded-3xl object-right hidden md:block mt-8 mx-auto'
            src={'/assets/img/landing/hero.jpg'}
            loading='lazy'
            alt=''
            title=''
          />
        </section>
        <div className='flex w-full justify-around my-12 font-semibold text-center flex-wrap'>
          {TRANSLATE.cust_options.map((item, index) => {
            if (index === 4) {
              return (
                <div className='flex flex-col w-1/2 md:w-1/4 items-center justify-start p-6 md:p-10' key={index}>
                  <img
                    src={item.i}
                    alt=''
                    className='w-[65px] h-[65px] object-contain mb-2'
                    style={{
                      filter:
                        'brightness(0) saturate(100%) invert(52%) sepia(94%) saturate(587%) hue-rotate(312deg) brightness(92%) contrast(117%)',
                    }}
                  />
                  {item.t}
                </div>
              );
            }
            return (
              <div className='flex flex-col w-1/2 md:w-1/4 items-center justify-start p-6 md:p-10' key={index}>
                <img src={item.i} alt='' className='w-[65px] h-[65px] object-contain mb-2' />
                {item.t}
              </div>
            );
          })}
        </div>

        <h2 className='text-babonbo-100 text-lg text-center mb-4'>Join more than 10,000 happy families</h2>

        {/** Reviews Section */}
        <section className='w-full text-center' aria-labelledby='reviews-heading'>
          <h2 id='reviews-heading' className='text-babonbo-100 text-lg md:text-2xl text-center mb-4'>
            FROM CUSTOMERS
          </h2>

          <div className='w-full flex gap-8 md:flex-nowrap flex-wrap text-sm'>
            {TRANSLATE.cust_reviews.o.map((item, index) => (
              <article
                className='w-full md:w-1/3 border border-zinc-200 rounded-lg p-6 flex flex-col items-center'
                key={index}
              >
                <img className='w-[50px] h-[50px] rounded-full' src={item.i} alt={`babonbo`} />
                <h3 className='text-lg font-semibold'>{item.n}</h3>
                <div className='flex gap-x-2 mt-2' aria-label='Rating'>
                  {Array(5)
                    .fill()
                    .map((_, starIndex) => (
                      <img
                        key={starIndex}
                        className='w-[14px] h-[14px]'
                        src='/assets/img/star.svg'
                        style={{ filter: 'invert(55%) sepia(100%) saturate(3.5)' }}
                        alt={`${starIndex + 1} star`}
                      />
                    ))}
                </div>
                <blockquote className='py-3'>
                  <p>
                    <strong>{item.a}</strong> {item.b}
                  </p>
                  <p>{item.c}</p>
                </blockquote>
              </article>
            ))}
          </div>
        </section>

        {/** Press Section Icons */}
        <section className='flex flex-col mt-10 pt-10 pb-0' aria-labelledby='press-heading'>
          <h2 className='text-babonbo-100 text-lg md:text-2xl text-center '>FROM NEWS</h2>

          <div className='flex flex-wrap gap-20 md:flex-nowrap w-full items-center justify-center md:justify-between '>
            <a
              className='w-[20%] md:w-[13%]'
              href='https://www.forbes.com/sites/garydrenik/2021/10/28/how-renting-baby-equipment-is-saving-time-money-and-the-environment/'
              target='_blank'
              rel='noopener noreferrer nofollow'
              aria-label='Article on Forbes about how renting baby equipment is saving time, money, and the environment. Ahmed Allem CTO'
            >
              <img className='w-full' src='/assets/img/babonbo-forbes-usa.png' alt='Forbes USA logo' />
            </a>

            <a
              className='w-[20%] md:w-[13%]'
              href='https://milano.repubblica.it/cronaca/2020/02/19/news/passeggini_e_seggioloni_per_i_baby_turisti_una_star_up_per_trovarli_in_citta_-248996615/'
              target='_blank'
              rel='noopener noreferrer nofollow'
              aria-label='Article on La Repubblica about baby tourists. Ahmed Allem CTO'
            >
              <img className='w-full' src='/assets/img/landing/pl-2.png' alt='La Repubblica logo' />
            </a>

            <a
              className='w-[20%] md:w-[13%]'
              href='https://www.radio24.ilsole24ore.com/programmi/economia-piccole-cose/puntata/baby-on-board-bimbo-in-vacanza-quanto-mi-costi-052031-2419319446108218'
              target='_blank'
              rel='noopener noreferrer nofollow'
              aria-label='Radio 24 program about the cost of baby travel. Ahmed Allem CTO'
            >
              <img className='w-full' src='/assets/img/landing/pl-3.png' alt='Radio 24 logo' />
            </a>

            <a
              className='w-[20%] md:w-[13%]'
              href='https://www.millionaire.it/baby-ecco-tutto-quello-che-serve/'
              target='_blank'
              rel='noopener noreferrer nofollow'
              aria-label='Article on Millionaire about baby travel essentials. Ahmed Allem CTO'
            >
              <img className='w-full' src='/assets/img/landing/pl-4.jpeg' alt='Millionaire logo' />
            </a>

            <a
              className='w-[20%] md:w-[13%]'
              href='https://nuvola.corriere.it/2022/07/05/la-startup-che-facilita-le-vacanze-con-bebe-a-bordo-e-riduce-gli-sprechi/'
              target='_blank'
              rel='noopener noreferrer nofollow'
              aria-label='Article on Corriere della Sera about a startup facilitating vacations with babies and reducing waste. Ahmed Allem CTO'
            >
              <img className='w-full' src='/assets/img/landing/pl-5.jpeg' alt='Corriere della Sera logo' />
            </a>

            <a
              className='w-[20%] md:w-[13%]'
              href='https://www.105.net/audio/105-start-up/1329198/hai-perso-105-start-up-riascolta-la-storia-di-duygu-sefa-ceo-e-founder-di-babonbo.html'
              target='_blank'
              rel='noopener noreferrer nofollow'
              aria-label='Radio interview on 105 Start-Up with Duygu Sefa, CEO and founder of Babonbo. Ahmed Allem CTO'
            >
              <img className='w-full' src='/assets/img/landing/pl-6.png' alt='Radio 105 logo' />
            </a>

            <a
              className='w-[20%] md:w-[13%]'
              href='https://forbes.it/2022/09/08/babonbo-startup-facilita-viaggi-bebe-a-bordo-sostenibile/'
              target='_blank'
              rel='noopener noreferrer nofollow'
              aria-label='Article on Forbes Italy about Babonbo facilitating sustainable travel with babies. Ahmed Allem CTO'
            >
              <img className='w-full' src='/assets/img/babonbo-forbes-it.png' alt='Forbes Italy logo' />
            </a>
          </div>
        </section>

        {/** Brands Section */}
        <section className='flex flex-col mt-10 pt-10 pb-0' aria-labelledby='press-heading'>
          <h2 className='text-babonbo-100 text-lg md:text-2xl text-center mb-4'>BRANDS</h2>

          <div className='flex flex-wrap gap-20 md:flex-nowrap w-full items-center justify-center md:justify-between '>
            <div className='w-[20%] md:w-[13%]'>
              <img className='w-full' src='/assets/img/landing/logo-1.jpg' alt='' />
            </div>
            <div className='w-[20%] md:w-[13%]'>
              <img className='w-full' src='/assets/img/landing/logo-2.jpg' alt='' />
            </div>
            <div className='w-[20%] md:w-[13%]'>
              <img className='w-full' src='/assets/img/landing/logo-3.jpg' alt='' />
            </div>
            <div className='w-[20%] md:w-[13%]'>
              <img className='w-full' src='/assets/img/landing/logo-4.jpg' alt='' />
            </div>
            <div className='w-[20%] md:w-[13%]'>
              <img className='w-full' src='/assets/img/landing/logo-5.jpg' alt='' />
            </div>
            <div className='w-[20%] md:w-[13%]'>
              <img className='w-full' src='/assets/img/landing/logo-6.jpg' alt='' />
            </div>
            <div className='w-[20%] md:w-[13%]'>
              <img className='w-full' src='/assets/img/landing/logo-7.jpg' alt='' />
            </div>
          </div>
        </section>

        {/** FAQ Section */}
        <section className='flex w-full flex-wrap justify-center my-20'>
          <div className='w-full order-1 flex flex-col justify-center'>
            <h2 className='text-babonbo-100 text-lg md:text-2xl text-center mb-4'>{TRANSLATE.wd_faq}</h2>
          </div>
          <div
            className='w-full md:mt-0 mt-6 order-2 border border-zinc-200 rounded-lg flex flex-col items-start'
            role='region'
            aria-label='Frequently Asked Questions'
          >
            {TRANSLATE.cust_faq.map((item, index) => {
              return (
                <details
                  className='group flex justify-between items-center p-6 border-b cursor-pointer border-zinc-200 font-semibold w-full flex-wrap'
                  aria-label='Interest Details'
                  key={index}
                >
                  <summary className='flex' aria-label={`FAQ Question #${index}`}>
                    <AddCircleOutline className='summary-icon color-ba' aria-hidden='true' />
                    <p>{item.t}</p>
                  </summary>
                  <p aria-label={`FAQ Answer #${index}`}>{item.p}</p>
                </details>
              );
            })}
          </div>
        </section>

        {/** Download iOS/Android Section */}
        <section className='flex flex-col w-full md:flex-nowrap flex-wrap my-20 items-center'>
          <div className='m-auto'>
            <h2 className='text-babonbo-100 text-lg md:text-2xl text-center mb-4'>BABONBO APP</h2>
            <h2 className='text-base lg:text-2xl font-medium mb-6 md:mt-0 mt-10 text-center'>
              <span className='text-babonbo-100'> Download the Babonbo app</span> Download the Babonbo app for a
              smoother renting experience.
            </h2>
          </div>
          <img className='w-full md:w-1/2 rounded-lg ' src='/assets/img/app-landing.jpg' alt='' />
          <div className='flex items-center justify-start gap-x-2 mx-auto mb-8'>
            <a
              href='https://apps.apple.com/us/app/babonbo/id1572432866?ign-mpt=uo%3D2'
              target='_blank'
              rel='noopener noreferrer nofollow'
            >
              <img className='w-[125px]' src='/assets/img/app-store.png' alt='Download Babonbo AppStore' />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.babonbo.app'
              target='_blank'
              rel='noopener noreferrer nofollow'
            >
              <img className='w-[140px]' src='/assets/img/play-store.png' alt='Download Babonbo PlayStore' />
            </a>
          </div>
        </section>

        <footer className='bg-white'>
          <img src='/assets/img/babonbo_tab_on.png' className='m-auto' style={{ width: '56px' }} />
          © 2023 Babonbo Ltd <br />
          64 Southwark Bridge, Road <br />
          London, United Kingdom, SE1 0AS
        </footer>
      </div>
    </div>
  );
};

export default AboutBabonbo;
