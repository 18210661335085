import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, createMuiTheme, createTheme, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import { Add, PersonAdd } from '@mui/icons-material';


const AdminBrands = (props) => {

    const { TRANSLATE, history, match, location, USER } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [BRANDS, setBrands] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var current_page = parseInt(page_id);

            if (USER.admin) {
                onFetchBrands({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else {
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

                history.push('/logout')
            }

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchBrands = async ({ page, sort, direction }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/brands', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setBrands(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchBrands = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchBrands({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/brands/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setBrands(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setBrands([]);
            setLoading(false);
        }


    }

    const onSortBrands = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'name'
        }
        else if (sort === 'isDeleted') {
            return 'deleted'
        }
        else {
            return sort
        }
    }

    const onChangePageBrands = ({ page, sort, direction }) => {
        let new_url = '/admin/brands/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        history.push(new_url);
    }

    const onSelectBrand = (brand_id) => {
        history.push('/admin/brands/profile/' + brand_id);
    }



    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectBrand(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "img",
            label: "LOGO",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const brandObx = BRANDS[rowIndex] || {};

                    const brand_id = brandObx.id;
                    const brand_img = brandObx.img || '';

                    return (
                        <div style={{ maxWidth: 200 }} onClick={() => onSelectBrand(brand_id)}>
                            <img src={brand_img || '/assets/img/babonbo_logo_provider.png'} style={{ width: 200, height: 100, borderRadius: 20, objectFit: 'contain' }} />
                        </div>

                    )

                }
            },

        },
        {
            name: "name",
            label: "BRAND",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const brandObx = BRANDS[rowIndex] || {};

                    const brand_id = brandObx.id;
                    const brand_name = brandObx.name || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={brand_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectBrand(brand_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "url",
            label: "URL",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const brandObx = BRANDS[rowIndex] || {};

                    const brand_id = brandObx.id;
                    const brand_url = brandObx.url || '';

                    if (!brand_url) {
                        return (
                            <div style={{ maxWidth: 200 }}>
                                <Chip
                                    label={'NO'}
                                    variant="outlined"
                                    size='medium'
                                />
                            </div>

                        )
                    }


                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={brand_url.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                onClick={() => onSelectBrand(brand_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (status) => {

                    if (status === 'OFF') {
                        return (
                            <div style={{ maxWidth: 200 }}>
                                <Chip
                                    label={status}
                                    variant="filled"
                                    size='medium'
                                    color='error'
                                />
                            </div>

                        )
                    }

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={status}
                                variant="filled"
                                size='medium'
                                color='success'
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "manager",
            label: "MANAGER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const brandObx = BRANDS[rowIndex] || {};

                    const managerObx = brandObx.manager || {};
                    const manager_id = managerObx.id || '';
                    const manager_name = managerObx.name || '';
                    const manager_img = managerObx.img || '';


                    if (manager_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                                    label={manager_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => history.push('/admin/users/profile/' + manager_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },

        {
            name: 'isDeleted',
            label: 'DELETED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const brandObx = BRANDS[rowIndex] || {};

                    const isDeleted = brandObx.isDeleted || false;

                    if (isDeleted) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="Deleted" color='error' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }
                }
            }

        },

        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = BRANDS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_brand = {
            id: item.id,
            name: item.name,
            url: item.url,
            img: item.img,
            manager: item.manager || {},
            status: item.status ? 'ON' : 'OFF',
            isDeleted: item.isDeleted || false,
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY')
        };

        return new_brand
    })

    const customToolbar = () => (
        <Tooltip disableFocusListener title="Add Brand">
            <IconButton onClick={() => history.push('/admin/brands/profile/new')}>
                <Add />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageBrands({
                        page: tableState.page,
                        sort: onSortBrands(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageBrands({
                        page: tableState.page,
                        sort: onSortBrands(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchBrands(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchBrands(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Brand By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Brands with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },



    };


    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Brands →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}
export default AdminBrands

