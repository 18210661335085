import React, {useState} from "react";

import LoaderMaster from "../Loaders/Loaders";

import {postApiPrivate} from "../../api";

import {CloseSharp,  SaveSharp } from "react-ionicons";

import swal from "sweetalert";

import {Chip, Container, Grid, TextField } from "@mui/material";
import { Link } from "react-router-dom";

import "../Profile/z_profile.css";
import DateSelector from "../General/DateSelector";

const AdminCouponsAdd = (props) => {

  const {TRANSLATE, history} = props;

  const [isSaving, setSaving] = useState(false);
  const [isCalendar, setCalendar] = useState(false);

  const [PUBLIC_COUPON, setCoupon] = useState({});

  const onUpdateCoupon = async(key, value) => {

    setCoupon({
      ...PUBLIC_COUPON,
      [key]: value
    });

  }

  const onSaveCoupon = async() => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        var FORM = { 
          type: PUBLIC_COUPON.type,
          status: PUBLIC_COUPON.status,
          code: PUBLIC_COUPON.code,
          value: PUBLIC_COUPON.value,
          from: PUBLIC_COUPON.from,
          to: PUBLIC_COUPON.to,
          limit: PUBLIC_COUPON.limit
        };


        console.log("CREATR COUPON ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/coupons/create', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Create This Coupon', 'error');
      
            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("CREATED COUPON ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('Coupon Created', 'This Coupon has been created successfully !!!', 'success');


        const coupon_id = DATA_RESPONSE.id;
        history.push('/admin/coupons/profile/'+coupon_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Created This Coupon', 'error');
         
      }

  }

  if(isSaving){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
    )

  }


  const {
    type, 
    status,
    value,
    code,
    from,
    to,
    limit

  } = PUBLIC_COUPON;

  
  const CAN_SAVE = (
    type && type.length>0 &&
    value && value>0 && 
    code && code.length>0
  )

  return(
    <>
      <Container maxWidth="lg" style={{background: '#f0f5f9'}}>

            <Grid container spacing={2}>
              
              <Grid item xs={12} lg={9} md={9}>
              
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                        <div className="top_box_magic" style={{marginBottom: 20}}>
                            <h1>
                              Status
                            </h1>

                            {status ? (
                              <Chip label="Active" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateCoupon('status', false)}/>
                            ) : (
                              <Chip label="Unactive" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning" onClick={(e)=> onUpdateCoupon('status', true)} />
                            )}
                        </div>   

                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Code
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={code} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateCoupon('code', e.target.value.toUpperCase())}
                                  InputLabelProps={{shrink: false}}
                                  placeholder="Type here..."
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Value
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={value}
                                  onChange={(e)=> onUpdateCoupon('value', e.target.value)}
                                  type="number"
                              
                              />
                            </div>
                        </div>

                      
                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Type
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <select 
                                  className={'gridyx-select'}
                                  value={type} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateCoupon('type', e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  select
                              >
                                <option value={null}>
                                     ---- Select a coupon type ----
                                </option>
                                <option value="percentage">
                                    Percentage
                                </option>
                                <option value="currency">
                                    Currency
                                </option>
                                
                              </select>
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Validity Date Range
                            </h1>
                            <p>
                              If Empty, coupon has no expiration or starting validity date
                            </p>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  InputLabelProps={{shrink: false}}
                                  value={from && to ? `${from} - ${to}` : ``}
                                  onClick={()=> setCalendar(true)}
                              
                              />
                            </div> 
                        </div>
                        
                        <div className="top_box_magic">
                            <h1>
                              Limit
                            </h1>
                            <p>
                              Cart need to be at least the limit value to accept the discount from this coupon
                            </p>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={''} 
                                  InputLabelProps={{shrink: false}}
                                  value={limit}
                                  onChange={(e)=> onUpdateCoupon('limit', e.target.value)}
                                  type="number"
                              
                              />
                            </div>
                        </div>


                  </div>

              </Grid>
              <Grid item xs={12} lg={3} md={3}>
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                    

                    <div className="top_add_cart"  
                        style={{backgroundColor: '#59ad6a'}} 
                        onClick={()=> {

                            if(!CAN_SAVE){

                                return swal("Incomplete", "Please complete all the fields in order to create this coupon", 'warning')
                            }

                            swal({
                                title: 'Confirm to Save',
                                text: 'Please insert the CONFIRM to create this coupon',
                                content: "input",
                            })
                            .then((value) => {

                            if(value === 'CONFIRM'){

                                onSaveCoupon()
                            }
                            else{

                                swal('Invalid Password', 'You are not authorized to create this coupon', 'error');
                            }

                            });
                        }}
                        
                    >
                        <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Create Coupon
                    </div>

                    <Link className="top_add_cart"  style={{backgroundColor: 'var(--dark)'}} to="/admin/coupons">
                        <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Go Back
                    </Link>
                          
                

                </div>
              </Grid>

            </Grid>  
       
          
      </Container>
      {isCalendar && (
        <DateSelector {...props}
          onClose={() => setCalendar(false)}
          from={from}
          to={to}
          isRange={true}
          title={'Select Coupon Range'}
          description={'Choose the coupon validity date range from the calendar'}
          extra={'This coupon will be valid for rentals between:'}
          onSave={(item) => {

            setCoupon({
              ...PUBLIC_COUPON,
              from: item.from,
              to: item.to
            });

            setCalendar(false);
          }}
        />
      )}
    </>
    
  )


}



export default AdminCouponsAdd

