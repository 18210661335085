import React, { useState } from "react";

import { Link } from "react-router-dom";

const HeaderProvider = (props) => {

    const {TRANSLATE, LANGUAGE, history, location, match, USER, CART, CURRENCY} = props;

    const {isModalSearch, setModalSearch} = props;
    const {isModalCart, setModalCart} = props;
    const {isModalLocalisation, setModalLocalisation} = props;
    const {isModalGuide, setModalGuide} = props;
    const {isModalProvider, setModalProvider} = props;

    const onSetupProfile = () => {
        history.push('/')
    }

    const onSetupBurger = () => {
        setModalProvider(true)
    }


    return (

        <>

            <header className="py-4 md:py-6 w-full fixed md:sticky top-0 bg-white z-[999]" style={{boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px'}}>
                <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
                    <nav className="relative z-50 flex justify-between text-sm">
                        <div className='flex items-center justify-start gap-x-2 lg:gap-x-6'>
                            <Link to={'/'}>
                                <img className='w-[150px] mr-4' src='/assets/img/babonbo_logo_provider.png' alt="" />
                            </Link>
                        </div> 
                        <div className='flex items-center gap-x-4 md:gap-x-2'>

                            <div className='w-[36px] h-[36px] rounded-lg cursor-pointer md:flex items-center justify-center hover:bg-zinc-100 relative' onClick={()=> history.push('/admin/playground')}>
                                <img src='/assets/img/search.svg' alt="" />
                            </div>
                            
                            {(USER.name && USER.picture) ? (
                                <div className='bb-transition-100 w-[32px] h-[32px] rounded-full md:block  cursor-pointer hover:outline hover:outline-offset-1 hover:outline-2 hover:outline-babonbo-100'  onClick={()=> onSetupProfile()}>
                                    <img className="w-full h-full rounded-full object-cover" src={USER.picture} alt="" />
                                </div>
                            ) : (
                                <div className='bb-transition-100 w-[32px] h-[32px] rounded-full md:block  cursor-pointer hover:outline hover:outline-offset-1 hover:outline-2 hover:outline-babonbo-100'  onClick={()=> onSetupProfile()}>
                                    <img src='/assets/img/avatar.svg' alt="" />
                                </div>
                            )}

                        </div>
                    </nav>
                </div>
            </header>
        
        </>
    )
}



export default HeaderProvider