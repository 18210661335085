import {
  KeyboardDoubleArrowDownOutlined,
  KeyboardDoubleArrowRightOutlined,
  PinDropOutlined,
} from '@mui/icons-material';
import React from 'react';
import { ArrowForward } from 'react-ionicons';
import Header from '../Header';

const CustomerOrderChangeAprrove = (props) => {
  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} />

      <div
        className='relative flex flex-col mx-auto my-4 gap-4 w-full max-w-xl px-4 sm:px-6 lg:px-8'
        style={{ flexGrow: 1 }}
      >
        <div className='flex w-full align-items-center justify-content-between'>
          <h2 className='text-sm'>YOUR ORDER DETAILS - ID: #5235266</h2>
        </div>

        <div className='p-2 border-ba-dashed'>
          <div>
            <h2 className='text-sm '>New Date</h2>
            <div className='flex justify-content-between w-full'>
              <h3>
                <span className='font-semibold'>4 </span> days
              </h3>
              <h3 className='font-semibold'>27-03-2023 - 28-03-2023 </h3>
            </div>

            <KeyboardDoubleArrowDownOutlined className='flex mx-auto my-4 color-ba' />

            <div>
              <h2 className='text-sm color-ba'>New Date</h2>

              <div className='flex justify-content-between w-full'>
                <h3 className='color-ba'>
                  <strong>5 </strong> days
                </h3>
                <h3 className='font-semibold '>
                  27-03-2023 - <span className='color-ba'>28-03-2023</span>
                </h3>
              </div>
            </div>
          </div>

          <div className='mx-auto my-4 bg-light-gray' style={{ width: '90%', height: '2px' }}></div>

          <div>
            <h2 className='text-sm '>Order Total</h2>
            <div className='flex w-full justify-content-between'>
              <h3>€ 136.00</h3>
              <KeyboardDoubleArrowRightOutlined className='color-ba' />
              <h3 className='color-ba'>€ 126.00</h3>
            </div>
          </div>
        </div>

        <div className='p-2 border-ba-dashed'>
          <div>
            <div className='mt-4'>
              <h2 className='text-sm '>Delivery</h2>
              <div className='flex justify-content-between w-full'>
                <h3>Malpensa International Airport</h3>
                <PinDropOutlined />
              </div>
              <h3 className='text-sm font-semibold'>€ 25.00</h3>
            </div>

            <KeyboardDoubleArrowDownOutlined className='flex mx-auto my-4 color-ba' />

            <div className='mt-4 color-ba'>
              <h2 className='text-sm '>Delivery</h2>
              <div className='flex justify-content-between w-full'>
                <h3>Malpensa International Airport</h3>
                <PinDropOutlined />
              </div>
              <h3 className='text-sm font-semibold'>€ 25.00</h3>
            </div>
          </div>

          <div className='mx-auto my-4 bg-light-gray' style={{ width: '90%', height: '2px' }}></div>

          <div>
            <h2 className='text-sm '>Order Total</h2>
            <div className='flex w-full justify-content-between'>
              <h3>€ 136.00</h3>
              <KeyboardDoubleArrowRightOutlined className='color-ba' />
              <h3 className='color-ba'>€ 126.00</h3>
            </div>
          </div>
        </div>

        <div className='flex flex-col w-full pt-2 bg-white'>
          <button
            type='button'
            className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
            onClick={() => {}}
          >
            APPROVE AND CHECKOUT <ArrowForward style={{ color: '#fff' }} />
          </button>
          <button
            type='button'
            className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full rounded-lg'
            onClick={() => {}}
          >
            CANCEL
            <ArrowForward style={{ color: '#fff' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderChangeAprrove;
