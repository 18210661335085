import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import {CartSharp, PersonOutline, ReaderOutline, HelpBuoyOutline, SettingsOutline, LogOutOutline, WalletOutline, CallOutline, MenuSharp, LogInOutline, EyeOutline, StorefrontSharp} from 'react-ionicons'

import {Languages_Array} from '../General/Constants';


const Navbar = (props) => {

  const {TRANSLATE, history, match, setSidebar, CART, LANGUAGE, USER, onChangeLanguage, setActiveMenu} = props;

  const [isDrop, setDrop] = useState(false);

  const onSelectLanguage = (lang) => {
     onChangeLanguage(lang);
  }

  const CURRENT_URL = match.url;

  return(
    
    <div className="top_navbar" onClick={()=> (isDrop ? setDrop(false) : null)}>
                
        <div className="top_navbar_left">
            
            <Link to="/">
                  <img src="/assets/img/babonbo_logo_provider.png" className="navlogo" style={{display: 'block'}}/>
            </Link>  
           
        </div>

      
        <div className="top_navbar_right">
           
            <div className="navburger" onClick={()=> history.push('/')}>
                <MenuSharp color={'#000000'} />
            </div>

            <div className="navprofile" onClick={()=> history.push('/')}>
              {(USER.name && USER.picture) ? (
                  <img src={USER.picture} />
              ) : (
                <div>
                    <PersonOutline color={'#ffffff'}/>
                </div>
              )}
            </div>

            
        </div>

    </div>
      
  )


}


export default Navbar
