import React from 'react';
import { Analytics, AppsOutline } from 'react-ionicons';
import { Link, Route, Switch } from 'react-router-dom/cjs/react-router-dom';
import AboutBabonbo from './AboutBabonbo/AboutBabonbo.js';
import Checkout from './Checkout/Checkout.js';
import CustomerApproveDelivery from './CustomerAfterOrder/CustomerApproveDelivery.js';
import CustomerCheckout from './CustomerAfterOrder/CustomerCheckout.js';
import CustomerOrderChangeAprrove from './CustomerAfterOrder/CustomerOrderChangeAprove.js';
import CustomerOrderDetails from './CustomerAfterOrder/CustomerOrderDetails.js';
import CustomerOrderEdit from './CustomerAfterOrder/CustomerOrderEdit.js';
import CustomerOrderProblem from './CustomerAfterOrder/CustomerOrderProblem.js';
import Listing from './Listing/Listing';
import ListingGrid from './ListingGrid/ListingGrid';
import Provider from './Provider/Provider.js';
import ProviderStoreSettings from './ProviderDashboard/ProductStoreSettings.js';
import ProviderAddNewProduct from './ProviderDashboard/ProviderAddNewProduct.js';
import ProviderDashboard from './ProviderDashboard/ProviderDashboard.js';
import ProviderEarning from './ProviderDashboard/ProviderEarning.js';
import ProviderOrderChangeRequest from './ProviderDashboard/ProviderOrderChangeRequest.js';
import ProviderOrders from './ProviderDashboard/ProviderOrders.js';
import ProviderProducts from './ProviderDashboard/ProviderProducts.js';

const NewUX = (props) => {
  const { TRANSLATE, USER, LANGUAGE, setActiveMenu, onChangeLanguage, location } = props;
  const isMenu = location?.pathname === '/newux';

  const sharedPage = {
    TRANSLATE,
    USER,
    LANGUAGE,
    setActiveMenu,
    onChangeLanguage,
  };

  const items = [
    {
      path: '/admin/analytics/express',
      icon: Analytics,
      title: 'Analytics Expres',
    },
    {
      path: '/newux/listing',
      title: 'Listing',
      component: Listing,
    },
    {
      path: '/newux/listing-grid',
      title: 'Listing Grid',
      component: ListingGrid,
    },
    {
      path: '/newux/checkout',
      title: 'Checkout',
      component: Checkout,
    },
    {
      path: '/newux/provider',
      title: 'Provider',
      component: Provider,
    },
    {
      path: '/newux/dashboard',
      title: 'Dashboard',
      component: ProviderDashboard,
    },
    {
      path: '/newux/earning',
      title: 'Earning',
      component: ProviderEarning,
    },
    {
      path: '/newux/products',
      title: 'Products',
      component: ProviderProducts,
    },
    {
      path: '/newux/orders',
      title: 'Orders',
      component: ProviderOrders,
    },
    {
      path: '/newux/orders/details',
      title: 'Orders Details',
      component: CustomerOrderDetails,
    },
    {
      path: '/newux/orders/edit',
      title: 'Orders Edit',
      component: CustomerOrderEdit,
    },
    {
      path: '/newux/orders/request',
      title: 'Orders request',
      component: ProviderOrderChangeRequest,
    },
    {
      path: '/newux/products/new',
      title: ' Add New Product',
      component: ProviderAddNewProduct,
    },
    {
      path: '/newux/settings',
      title: 'Store Settings',
      component: ProviderStoreSettings,
    },
    {
      path: '/newux/customer/order/details',
      title: 'Customer Order Details',
      component: CustomerOrderDetails,
    },
    {
      path: '/newux/customer/order/edit',
      title: 'Customer Order Edit',
      component: CustomerOrderEdit,
    },
    {
      path: '/newux/customer/order/approve',
      title: 'Customer Order Approve',
      component: CustomerOrderChangeAprrove,
    },
    {
      path: '/newux/customer/checkout',
      title: 'Customer Checkout',
      component: CustomerCheckout,
    },
    {
      path: '/newux/customer/approve',
      title: 'Customer Approve Delivery',
      component: CustomerApproveDelivery,
    },
    {
      path: '/newux/customer/problem',
      title: 'Customer Report Problem',
      component: CustomerOrderProblem,
    },
    {
      path: '/newux/customer/about-babonbo',
      title: 'About Babonbo',
      component: AboutBabonbo,
    },
  ];

  return (
    <>
      {isMenu && (
        <div class='w-screen h-screen flex justify-center items-center'>
          <div class='mx-auto max-w-8xl h-full px-4 sm:px-6 lg:px-8 pb-10 pt-10 flex items-center w-full'>
            <div class='flex m-auto w-full justify-center'>
              <div
                className='grid gap-4 place-items-center'
                style={{ gridTemplateColumns: 'repeat(auto-fit, 200px)', gridTemplateRows: '110px' }}
              >
                {items.map((item) => {
                  return (
                    <Link
                      to={item.path}
                      className='flex flex-col items-center p-2 text-center bg-light rounded-lg'
                      style={{ height: '110px' }}
                      key={item.path}
                    >
                      {item.icon ? (
                        <item.icon color={'#26BFBF'} width='40px' height='40px' className='m-auto ' />
                      ) : (
                        <AppsOutline color={'#26BFBF'} width='40px' height='40px' className='m-auto ' />
                      )}
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {!isMenu && (
        <div>
          {items.map((item) => (
            <Switch>
              <Route exact path={item.path} render={(props) => <item.component {...sharedPage} {...props} />} />
            </Switch>
          ))}
        </div>
      )}
    </>
  );
};

export default NewUX;
