import React, { useCallback, useEffect, useRef, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { ChevronDown, LocationSharp, ReloadOutline } from 'react-ionicons';
import OutsideClickHandler from 'react-outside-click-handler';
import './googleautocomplete.css';

const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('PlacesDB', 1);
    request.onerror = (event) => {
      console.error('Error opening IndexedDB:', event.target.error);
      reject(event.target.error);
    };
    request.onsuccess = (event) => {
      console.log('IndexedDB opened successfully');
      resolve(event.target.result);
    };
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('myStore')) {
        db.createObjectStore('myStore', { keyPath: 'id' });
      }
    };
  });
};

const GoogleAutoComplete = ({
  placeholder = 'Where to rent',
  onConfirmAddress,
  currentAddress,
  options = {
    types: ['(cities)'],
    fields: ['address_components', 'geometry.location', 'place_id', 'formatted_address'],
  },
  children,
  label,
  deliveryCost,
}) => {
  const [isFocused, setFocused] = useState(false);

  const [value, setValue] = useState(currentAddress ? currentAddress : '');
  const [errorMessage, setErrorMessage] = useState('');
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: 'AIzaSyAVL5_XZyW9TUyWUn3Os0TccLmeVQ37PTY',
    debounce: 300,
  });

  const autocompleteRef = useRef(null);

  const handlePlaceSelected = useCallback(
    (placeId) => {
      new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
        { placeId, fields: options.fields },
        (place, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            console.error('Error fetching place details:', status);
            setErrorMessage('Failed to fetch place details. Please try again.');
            return;
          }
          if (place) {
            const address = place.formatted_address;
            const latitude = place.geometry.location.lat();
            const longitude = place.geometry.location.lng();

            setValue(address);
            // setPlaceDetails({ address, latitude, longitude });
          }
        }
      );
    },
    [options.fields]
  );

  const [searchResults, setSearchReasults] = useState([]);

  useEffect(() => {
    if (value) {
      const fetchData = async () => {
        try {
          const db = await openDatabase();
          const transaction = db.transaction(['myStore'], 'readwrite');
          const objectStore = transaction.objectStore('myStore');

          const request = objectStore.get(value);

          request.onsuccess = (event) => {
            const result = event.target.result;
            if (result && result.expiry > Date.now()) {
              setSearchReasults(result.placePredictions);
              console.log('Cached data:', result.placePredictions);
            } else {
              getPlacePredictions({ input: value, ...options });
            }
          };

          request.onerror = (e) => {
            console.log('Error fetching data:', e);
          };
        } catch (error) {
          console.error('Error opening database:', error);
        }
      };

      fetchData();
    } else {
      setSearchReasults([]);
    }
  }, [value]);

  useEffect(() => {
    const saveToDB = async () => {
      const db = await openDatabase();
      const transaction = db.transaction(['myStore'], 'readwrite');
      const objectStore = transaction.objectStore('myStore');

      const expiry = Date.now() + 24 * 60 * 60 * 1000; // 24 hours
      objectStore.put({
        id: value,
        placePredictions: placePredictions.map((x) => {
          return { description: x.description, place_id: x.place_id };
        }),
        expiry,
      });

      const request = objectStore.get(value);

      request.onsuccess = (event) => {
        const result = event.target.result;
        if (result && result.expiry > Date.now()) {
          setSearchReasults(result.placePredictions);
          console.log('Cached data:', result.placePredictions);
        } else if (placePredictions.length === 0) {
          console.log('No data found');
        } else {
          getPlacePredictions({ input: value, ...options });
        }
      };

      request.onerror = (e) => {
        console.log('Error fetching data:', e);
      };
    };

    if (deliveryCost) {
      //TODO api request to get delivery costs for founded cities. Then set the cost_delivery, total of products and currency
    }

    if (placePredictions.length > 0) {
      saveToDB();
    }
  }, [placePredictions]);

  const selectedLocation = '';
  const cost_delivery = 0;
  const total = 0;
  const CURRENCY = 'eur';

  return (
    <div className='mx-auto w-full '>
      {(isFocused || !selectedLocation) && (
        <OutsideClickHandler onOutsideClick={() => setFocused(false)}>
          <div
            className='relative inline-flex flex-column w-full my-auto justify-content-end'
            style={{ height: '64px' }}
          >
            {label && <label className={isFocused || !selectedLocation ? 'inline-label top-1' : ''}>{label}</label>}

            <div className='search-box px-2 border-light-gray'>
              <input
                type='text'
                value={value}
                placeholder={placeholder}
                onChange={(e) => setValue(e.target.value)}
                aria-label='Location search input'
                ref={autocompleteRef}
              />
              {isPlacePredictionsLoading ? (
                <ReloadOutline width='24px' height='24px' color='#fc6170' className='spin' />
              ) : (
                <LocationSharp width='24px' height='24px' color='#fc6170' />
              )}
            </div>
          </div>
        </OutsideClickHandler>
      )}

      {!isFocused && selectedLocation && (
        <div className='quicksand font-bold'>
          <SelectedLocation result={selectedLocation} setFocus={setFocused} cost_delivery={cost_delivery} />
        </div>
      )}

      {searchResults.length > 0 && (
        <ul className='overflow-auto' aria-live='polite'>
          {searchResults.map((item, index) => (
            <li
              className={`search-result-container ${total < 1 ? 'passive' : ''}`}
              key={index}
              onClick={
                () => onConfirmAddress(item)
                // handlePlaceSelected(item.place_id)
              }
              role='option'
              aria-selected='false'
            >
              {deliveryCost && cost_delivery !== null && (
                <label className={`label-box-delivery ${cost_delivery > 0 ? '' : 'free-pickup'}`}>
                  {cost_delivery > 0 ? `Delivery ${cost_delivery} ${CURRENCY}` : 'Free Self-Pickup Option'}
                </label>
              )}
              <div className='search-result'>
                <div className='district-name'>{item.description}</div>
                {children}
              </div>
            </li>
          ))}
        </ul>
      )}

      {value && searchResults.length === 0 && <div className=''>{errorMessage || 'No results found'}</div>}
    </div>
  );
};

export default GoogleAutoComplete;

const SelectedLocation = (props) => {
  const { result, setFocus, cost_delivery } = props;

  return (
    <button
      type='button'
      className={`search-result-container ${result.total < 1 ? 'passive' : ''}`}
      onClick={() => setFocus(true)}
    >
      {cost_delivery !== null && (
        <label className={`label-box-delivery ${cost_delivery > 0 ? '' : 'free-pickup'}`}>
          {cost_delivery > 0 ? `Delivery ${cost_delivery} ${result.currency}` : 'Free Pickup'}
        </label>
      )}
      <div className='search-result'>
        <div className='district-name'>{result.district}</div>
        <div className={'district-info'}>
          <ChevronDown width={'20px'} height={'20px'} color={'#fc6170'} />
        </div>
      </div>
    </button>
  );
};
