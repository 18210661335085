import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import ReactCountryFlag from 'react-country-flag';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import StarReview from '../General/Stars';
import { Add } from '@mui/icons-material';


const AdminApprovals = (props) => {

    const { TRANSLATE, history, match, location, USER } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [APPROVALS, setApprovals] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var current_page = parseInt(page_id);

            if (USER.admin) {
                onFetchApprovals({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else {
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

                history.push('/logout')
            }

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchApprovals = async ({ page, sort, direction }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/approvals', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setApprovals(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchApprovals = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchApprovals({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/approvals/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setApprovals(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setApprovals([]);
            setLoading(false);
        }


    }

    const onSortApprovals = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'search_name'
        }
        else if (sort === 'img') {
            return 'photo'
        }
        else if (sort === 'lang') {
            return 'language'
        }
        else if (sort === 'isCompleted') {
            return 'setup'
        }
        else {
            return sort
        }
    }

    const onChangePageApprovals = ({ page, sort, direction }) => {
        let new_url = '/admin/approvals/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        history.push(new_url);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/admin/users/profile/' + customer_id);
    }

 
    const onSelectProduct = (product_id) => {
        history.push('/admin/products/profile/' + product_id);
    }


    const onSelectApproval = (approval_id) => {
        history.push('/admin/approvals/profile/' + approval_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/admin/stores/profile/' + store_id);
    }


    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }

    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectApproval(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "user",
            label: "USER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const approvalObx = APPROVALS[rowIndex] || {};

                    const approval_user = approvalObx.user || {};
                    const user_id = approval_user.id;
                    const user_name = approval_user.name || '';
                    const user_img = approval_user.img || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                avatar={<Avatar alt={user_name} src={user_img} />}
                                label={user_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectCustomer(user_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "type",
            label: "TYPE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >
                            <>
                                {(label === 'edit_store' || label === 'add_store') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: '#26BFBF', color: '#fff' }} />
                                )}

                                {(label === 'edit_profile' || label === 'add_profile') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: '#fd6270', color: '#fff' }} />
                                )}

                                {(label === 'edit_product' || label === 'add_product') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: 'var(--orange)', color: '#fff' }} />
                                )}

                                {(label === 'edit_order' || label === 'add_order') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: 'var(--blue)', color: '#fff' }} />
                                )}

                            </>
                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {


                    return (
                        <CopyToClipboard text={label} onCopy={() => onSelectCopy(label)}
                        >
                            <>
                                {(label === 'pending') && (
                                    <Chip label={label} size='small' />
                                )}

                                {(label === 'approved') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: 'green', color: '#fff' }} />
                                )}

                                {(label === 'rejected') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: 'red', color: '#fff' }} />
                                )}
                            </>
                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "manager",
            label: "MANAGER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const approvalObx = APPROVALS[rowIndex] || {};

                    const managerObx = approvalObx.manager || {};
                    const manager_id = managerObx.id || '';
                    const manager_name = managerObx.name || '';
                    const manager_img = managerObx.img || '';


                    if (manager_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                                    label={manager_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => onSelectCustomer(manager_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: "about",
            label: "DESCRIPTION",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const approvalObx = APPROVALS[rowIndex] || {};

                    const approval_user = approvalObx.user || {};
                    const user_id = approval_user.id;
                    const user_name = approval_user.name || '';

                    const approval_order = approvalObx.order || {};
                    const order_id = approval_order.id;
                    const order_status = approval_order.status || '';
                    const order_total = approval_order.total || 0;
                    const order_currency = approval_order.currency || 'eur';

                    const approval_store = approvalObx.store || {};
                    const store_id = approval_store.id;
                    const store_name = approval_store.name || '';

                    const approval_product = approvalObx.product || {};
                    const product_id = approval_product.id;
                    const product_name = approval_product.name || '';

                    const approval_type = approvalObx.type || '';

                    let color = '#000';

                    if (order_status === 'pending') {
                        color = '#FCE205';
                    }
                    else if (order_status === 'accepted') {
                        color = 'green';
                    }
                    else if (order_status === 'rejected') {
                        color = '#ec1529';
                    }
                    else if (order_status === 'canceled') {
                        color = '#ec1529';
                    }
                    else if (order_status === 'delivering') {
                        color = '#88D1F1';
                    }
                    else if (order_status === 'delivered') {
                        color = '#ff8b3d';
                    }
                    else if (order_status === 'complete') {
                        color = 'green';
                    }


                    if (approval_type === 'edit_store') {
                        return (
                            <div style={{ fontWeight: 100 }}>

                                Made some changes to the store
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectStore(store_id)}>
                                    {store_name}
                                </span>

                            </div>
                        )
                    }
                    else if (approval_type === 'add_store') {
                        return (
                            <div style={{ fontWeight: 100 }}>

                                Created a store
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectStore(store_id)}>
                                    {store_name}
                                </span>

                            </div>
                        )
                    }
                    else if (approval_type === 'edit_profile') {
                        return (
                            <div style={{ fontWeight: 100 }}>

                                Made some changes to
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectCustomer(user_id)}>
                                    Profile
                                </span>

                            </div>
                        )
                    }
                    else if (approval_type === 'add_profile') {
                        return (
                            <div style={{ fontWeight: 100 }}>

                                Created a
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectCustomer(user_id)}>
                                    Profile
                                </span>
                                {' '}
                                on Babonbo

                            </div>
                        )
                    }
                    else if (approval_type === 'edit_product') {
                        return (
                            <div style={{ fontWeight: 100 }}>

                                Made some changes to a product
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectProduct(product_id)}>
                                    {product_name}
                                </span>

                            </div>
                        )
                    }
                    else if (approval_type === 'add_product') {
                        return (
                            <div style={{ fontWeight: 100 }}>

                                Added a new product
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectProduct(product_id)}>
                                    {product_name}
                                </span>

                            </div>
                        )
                    }

                    return (
                        <div>
                            NO
                        </div>

                    )

                }
            },

        },
        // {
        //     name: "store",
        //     label: "STORE",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customBodyRender: (src, dir) => {

        //             const rowIndex = dir.rowIndex;
        //             const approvalObx = APPROVALS[rowIndex] || {};

        //             const storeObx = approvalObx.store || {};
        //             const store_id = storeObx.id || '';
        //             const store_name = storeObx.name || '';
        //             const store_img = storeObx.img || '';


        //             if(store_id){
        //                 return (
        //                     <div>
        //                         <Chip
        //                             avatar={<Avatar alt={store_name} src={store_img} />}
        //                             label={store_name.toLowerCase()}
        //                             variant="outlined"
        //                             size='medium'
        //                             style={{textTransform: 'capitalize'}}
        //                             onClick={()=> onSelectStore(store_id)}
        //                         />
        //                     </div>

        //                 )
        //             }
        //             else{
        //                 return (
        //                     <div>
        //                         <Chip size='small' label="NO" />
        //                     </div>
        //                 )
        //             }
        //         }
        //     },

        // },
        // {
        //     name: "product",
        //     label: "PRODUCT",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customBodyRender: (src, dir) => {

        //             const rowIndex = dir.rowIndex;
        //             const approvalObx = APPROVALS[rowIndex] || {};

        //             const productObx = approvalObx.product || {};
        //             const product_id = productObx.id || '';
        //             const product_name = productObx.name || '';
        //             const product_img = productObx.img || '';


        //             if(product_id){
        //                 return (
        //                     <div>
        //                         <Chip
        //                             avatar={<Avatar alt={product_name} src={product_img} />}
        //                             label={product_name.toLowerCase()}
        //                             variant="outlined"
        //                             size='medium'
        //                             style={{textTransform: 'capitalize'}}
        //                             onClick={()=> onSelectProduct(product_id)}
        //                         />
        //                     </div>

        //                 )
        //             }
        //             else{
        //                 return (
        //                     <div>
        //                         <Chip size='small' label="NO" />
        //                     </div>
        //                 )
        //             }
        //         }
        //     },

        // },
        // {
        //     name: "order",
        //     label: "ORDER",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customBodyRender: (src, dir) => {

        //             const rowIndex = dir.rowIndex;
        //             const approvalObx = APPROVALS[rowIndex] || {};

        //             const orderObx = approvalObx.order || {};
        //             const order_id = orderObx.id || '';
        //             const order_status = orderObx.status || '';


        //             if(order_id){
        //                 return (
        //                     <div>
        //                         <Chip
        //                             avatar={<Avatar alt={order_id} src={''} />}
        //                             label={order_id.toLowerCase() + ' ' + order_status}
        //                             variant="outlined"
        //                             size='medium'
        //                             style={{textTransform: 'capitalize'}}
        //                             onClick={()=> onSelectStore(order_id)}
        //                         />
        //                     </div>

        //                 )
        //             }
        //             else{
        //                 return (
        //                     <div>
        //                         <Chip size='small' label="NO" />
        //                     </div>
        //                 )
        //             }
        //         }
        //     },

        // },
        // {
        //     name: "review",
        //     label: "REVIEW",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customBodyRender: (src, dir) => {

        //             const rowIndex = dir.rowIndex;
        //             const approvalObx = APPROVALS[rowIndex] || {};

        //             const storeObx = approvalObx.store || {};
        //             const store_id = storeObx.id || '';
        //             const store_name = storeObx.name || '';
        //             const store_img = storeObx.img || '';


        //             if(store_id){
        //                 return (
        //                     <div>
        //                         <Chip
        //                             avatar={<Avatar alt={store_name} src={store_img} />}
        //                             label={store_name.toLowerCase()}
        //                             variant="outlined"
        //                             size='medium'
        //                             style={{textTransform: 'capitalize'}}
        //                             onClick={()=> onSelectStore(store_id)}
        //                         />
        //                     </div>

        //                 )
        //             }
        //             else{
        //                 return (
        //                     <div>
        //                         <Chip size='small' label="NO" />
        //                     </div>
        //                 )
        //             }
        //         }
        //     },

        // },
        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = APPROVALS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_approval = {
            id: item.id,
            type: item.type,
            status: item.status,
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY'),
            user: item.user || {},
            manager: item.manager || {},
            product: item.product || {},
            store: item.store || {},
            review: item.review || {},
            order: item.order || {},
            about: 1000

        };

        return new_approval
    })

    const customToolbar = () => (
        <Tooltip disableFocusListener title="Add Product">
            <IconButton onClick={() => history.push('/admin/approvals/profile/new')}>
                <Add />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        serverSide: true,
        customToolbar: customToolbar,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageApprovals({
                        page: tableState.page,
                        sort: onSortApprovals(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageApprovals({
                        page: tableState.page,
                        sort: onSortApprovals(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchApprovals(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchApprovals(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Approval By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Approvals with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },



    };


    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Approvals →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}


export default AdminApprovals

