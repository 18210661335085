import React from 'react';


const AdminPayments = ({setActiveMenu}) => {

  return(
    <div style={{paddingTop: 50, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <h1>Admin Payments 🏗</h1>
      <p>Our CTO is Working on this feature. Stay Tuned !!! 😉</p>
      <button className='btn primary' style={{background: '#26BFBF'}} onClick={()=> setActiveMenu(true)}>
        Back To Menu
      </button>
    </div>
  )

}

export default AdminPayments
