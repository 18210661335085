import React, { useState, useEffect } from "react";

import LoaderMaster from "../Loaders/Loaders";
import StarRating from "../General/Stars";

import { postApiPrivate } from "../../api";


import { ArrowBack, CloseSharp, EyeSharp, LockOpenSharp, MailSharp, NotificationsSharp, PencilSharp, SaveSharp, TrashBinSharp } from "react-ionicons";

import ReactCountryFlag from "react-country-flag";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from "@mui/material";

import ReviewComponent from "../Reviews/ReviewComponent";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { WidgetCount } from './AdminWidgets';

import "../Profile/z_profile.css";

const AdminCustomersDetails = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [isDeleteMode, setDeleteMode] = useState(false);

  const [PUBLIC_USER, setUser] = useState({});
  const [PUBLIC_DELETE, setDelete] = useState({});


  useEffect(() => {

    var user_id = match.params.id || '';

    onFetchPublic(user_id);

  }, []);

  const onFetchPublic = async (user_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setEdited(false);
    setLoading(true);

    try {

      const FORM = { id: user_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/users/details', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setUser(DATA_RESPONSE || {});
      setLoading(false);

    }
    catch (e) {

      setLoading(false);
    }

  }

  const onFetchStats = async (user_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setEdited(false);
    setLoading(true);

    try {

      const FORM = { id: user_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/users/stats', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setDelete(DATA_RESPONSE || {});
      setLoading(false);

    }
    catch (e) {

      setLoading(false);
    }

  }

  const onUpdateUser = async (key, value) => {

    setEdited(true);
    setUser({
      ...PUBLIC_USER,
      [key]: value
    });

  }

  const onSaveUser = async (user_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: user_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/users/edit', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update This User', 'error');
        onFetchPublic(user_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('User Updated', 'This User has been updated successfully !!!', 'success');
      onFetchPublic(user_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This User', 'error');
      onFetchPublic(user_id);
    }

  }

  const onDeleteUser = async (user_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: user_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/users/delete', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Delete This User', 'error');
        onFetchPublic(user_id);
        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      setSaving(false);
      swal('User Deleted', 'This User has been deleted successfully !!!', 'success');
      history.replace('/admin/users');

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Delete This User', 'error');
      onFetchPublic(user_id);
    }
  }

  const onSavePassword = async (user_id, new_password) => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: user_id, np: new_password.trim() };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/users/password', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update the password for this User', 'error');
        onFetchPublic(user_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('User Updated', 'This User\'s Password has been updated successfully !!!', 'success');
      onFetchPublic(user_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update the password for this User', 'error');
      onFetchPublic(user_id);
    }

  }

  const onSendPush = async (user_id, content) => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: user_id, content: content.trim() };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/users/push', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot send the push notification to this User', 'error');
        onFetchPublic(user_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Push Sent', 'This push notification has been sent successfully to this User !!!', 'success');
      onFetchPublic(user_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update the password for this User', 'error');
      onFetchPublic(user_id);
    }

  }

  const onSelectCopy = (copy) => {
    swal('Copied to Clipboard !!!', copy, 'success')
  }

  if (isLoading) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading} />
    )

  }

  if (isSaving) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving} />
    )

  }


  const {
    id,
    name,
    img,
    updatedAt,
    createdAt,
    city,
    rating,
    lang,
    email,
    phone,
    about,
    currency,
    isAdmin,
    isTester,
    setup,
    store,
    shop,
    insegna,
    description,
    communication,
    use,
    punctuation,
    reviews,
    devices,
    country,
    search,
    followers,
    following
  } = PUBLIC_USER;

  const NAMES = getSplittedNames(name) || {};
  const FIRST_NAME = NAMES.first_name ? NAMES.first_name.toUpperCase() : name;
  const REFFERAL_CODE = FIRST_NAME.slice(0, 6) + id?.toUpperCase();

  const Communication = communication || 0;
  const Punctuation = punctuation || 0;
  const Use = use || 0;


  let CurrencyCode = 'eu';

  if (currency === 'usd') {
    CurrencyCode = 'us'
  }
  else if (currency === 'gbp') {
    CurrencyCode = 'gb'
  }

  if (isDeleteMode) {

    const {
      bb_approvals,
      bb_cards,

      bb_inbox_customer,
      bb_orders_customer,
      bb_reviews_customer,
      bb_messages_customer_sent,
      bb_messages_customer_received,
      bb_notifications_customer,
      bb_referrals_customer,
      bb_reports_customer,

      bb_inbox_store,
      bb_orders_store,
      bb_reviews_store,
      bb_messages_store_sent,
      bb_messages_store_received,
      bb_notifications_store,
      bb_referrals_store,
      bb_reports_store,

      bb_stores,
      bb_products,
      bb_library,

    } = PUBLIC_DELETE

    return (
      <Container maxWidth="xl" background={'#f0f5f9'}>

        <Grid container spacing={2}>

          <Grid item xs={12} lg={4} md={4}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>
              <div className="top_store_card">
                <div className="top_store_profile">

                  {img ? (
                    <img className="profile_img" src={img} />
                  ) : (
                    <div className="profile_img" src={'/assets/img/avatar.svg'} />
                  )}

                  <div className="font-bold text-lg">{name}</div>
                  <StarRating rate={rating} reviews={reviews} />

                </div>

                <div className="top_store_stats">
                  <div>
                    <div className="font-bold text-lg">{reviews || 0}</div>
                    <p>Reviews</p>
                  </div>
                  <div>
                    <div className="font-bold text-lg">{following || 0}</div>
                    <p>Following</p>
                  </div>
                  <div>
                    <div className="font-bold text-lg">{followers || 0}</div>
                    <p>Followers</p>
                  </div>
                </div>
              </div>


              <div className="top_add_cart" style={{ backgroundColor: '#3d3d3d' }} onClick={() => setDeleteMode(false)}>
                <ArrowBack color={'#fff'} style={{ marginRight: 10 }} /> Go Back
              </div>

            </div>
          </Grid>

          <Grid item xs={12} lg={4} md={4}>

            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

              <h2>CUSTOMER MODE</h2>

              <CardDelete title="Approvals" value={bb_approvals} />
              <CardDelete title="Cards" value={bb_cards} />
              <CardDelete title="Orders" value={bb_orders_customer} />
              <CardDelete title="Reviews" value={bb_reviews_customer} />
              <CardDelete title="Conversations" value={bb_inbox_customer} />
              <CardDelete title="Messages Sent" value={bb_messages_customer_sent} />
              <CardDelete title="Messages Received" value={bb_messages_customer_received} />
              <CardDelete title="Notifications" value={bb_notifications_customer} />
              <CardDelete title="Referrals" value={bb_referrals_customer} />
              <CardDelete title="Reports" value={bb_reports_customer} />
              <CardDelete title="Library" value={bb_library} />

            </div>

          </Grid>

          <Grid item xs={12} lg={4} md={4}>

            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

              <h2>STORE MODE</h2>

              <CardDelete title="Stores" value={bb_stores} />
              <CardDelete title="Products" value={bb_products} />
              <CardDelete title="Orders" value={bb_orders_store} />
              <CardDelete title="Reviews" value={bb_reviews_store} />
              <CardDelete title="Conversations" value={bb_inbox_store} />
              <CardDelete title="Messages Sent" value={bb_messages_store_sent} />
              <CardDelete title="Messages Received" value={bb_messages_store_received} />
              <CardDelete title="Notifications" value={bb_notifications_store} />
              <CardDelete title="Referrals" value={bb_referrals_store} />
              <CardDelete title="Reports" value={bb_reports_store} />


            </div>

          </Grid>


        </Grid>

      </Container>
    )

  }

  return (

    <Container maxWidth="xl" background={'#f0f5f9'}>

      <Grid container spacing={2}>

        <Grid item xs={12} lg={4} md={6}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>
            <div className="top_store_card">
              <div className="top_store_profile">

                {img ? (
                  <img className="profile_img" src={img} />
                ) : (
                  <div className="profile_img" />
                )}

                <h3 className="store_title">{name}</h3>
                <StarRating rate={rating} reviews={reviews} />

              </div>
              <div className="top_store_stats">
                <div>
                  <h3>{reviews || 0}</h3>
                  <p>{TRANSLATE.reviews}</p>
                </div>
                <div>
                  <h3>{followers || 0}</h3>
                  <p>{TRANSLATE.followers}</p>
                </div>
                <div>
                  <h3>{following || 0}</h3>
                  <p>{TRANSLATE.following}</p>
                </div>
              </div>
            </div>

            {!isEdited && (
              <>
                <div className="top_add_cart" style={{ backgroundColor: 'black' }} onClick={() => window.open('https://www.babonbo.com/profile/' + id, 'blank')}>
                  <EyeSharp color={'#fff'} style={{ marginRight: 10 }} /> Open Profile
                </div>

                <div className="top_add_cart"
                  style={{ backgroundColor: '#59ad6a' }}
                  onClick={() => {
                    swal({
                      title: 'New Push',
                      text: 'Please insert the text you would like to send to ' + name,
                      content: "input",
                    })
                      .then((value) => {

                        if (!value) {
                          swal('Invalid Content', 'The Text Content Need to be at least 6 chars long', 'warning');
                          return
                        }

                        let new_content = value.trim();

                        if (new_content.length < 6) {
                          swal('Invalid Content', 'The Text Content Need to be at least 6 chars long', 'warning');
                          return
                        }

                        swal({
                          title: 'Confirm to Send Push',
                          text: 'Please insert CONFIRM to send this push notification',
                          content: "input",
                        })
                          .then((value) => {

                            if (value === 'CONFIRM') {
                              onSendPush(id, new_content)
                            }
                            else {

                              swal('Invalid Push', 'You are not authorized to send this push notification', 'error');
                            }

                          });

                      });

                  }}
                >
                  <NotificationsSharp color={'#fff'} style={{ marginRight: 10 }} /> Send Push
                </div>

                <div className="top_add_cart" style={{ backgroundColor: '#ffbb49' }} onClick={() => window.location.replace('mailto:' + email)}>
                  <MailSharp color={'#fff'} style={{ marginRight: 10 }} /> Send Email
                </div>

                <div className="top_add_cart"
                  style={{ backgroundColor: '#ccc' }}
                  onClick={() => {
                    swal({
                      title: 'New Password',
                      text: 'What is the new password for ' + email,
                      content: "input",
                    })
                      .then((value) => {

                        if (!value) {
                          swal('Invalid Password', 'The Password Need to be at least 6 chars long', 'warning');
                          return
                        }

                        let new_password = value.trim();

                        if (new_password.length < 6) {
                          swal('Invalid Password', 'The Password Need to be at least 6 chars long', 'warning');
                          return
                        }

                        swal({
                          title: 'Confirm to Update Password',
                          text: 'Please insert CONFIRM to update this user\'s password',
                          content: "input",
                        })
                          .then((value) => {

                            if (value === 'CONFIRM') {
                              onSavePassword(id, new_password)
                            }
                            else {

                              swal('Invalid Password', 'You are not authorized to update this user\'s password', 'error');
                            }

                          });

                      });

                  }}
                >
                  <LockOpenSharp color={'#fff'} style={{ marginRight: 10 }} /> Send Password
                </div>

                <div className="top_add_cart"
                  style={{ backgroundColor: '#e64942' }}
                  onClick={() => {

                    setDeleteMode(true)
                    onFetchStats(id)
                    return

                  }}
                >
                  <TrashBinSharp color={'#fff'} style={{ marginRight: 10 }} /> Delete User
                </div>

                {/* <div className="top_add_cart" 
                                 style={{backgroundColor: '#e64942'}} 
                                 onClick={()=> {
                                    swal({
                                        title: 'Confirm to Delete',
                                        text: 'Please insert CONFIRM to delete this user',
                                        content: "input",
                                    })
                                    .then((value) => {

                                      if(value === 'CONFIRM'){

                                          onDeleteUser(id)
                                      }
                                      else{

                                          swal('Invalid Password', 'You are not authorized to delete this user', 'error');
                                      }

                                    });
                                 }}
                            >
                              <TrashBinSharp color={'#fff'} style={{marginRight: 10}} /> Delete User 
                            </div> */}
              </>
            )}

            {isEdited && (
              <>

                <div className="top_add_cart"
                  style={{ backgroundColor: '#59ad6a' }}
                  onClick={() => {
                    swal({
                      title: 'Confirm to Save',
                      text: 'Please insert CONFIRM to update this user',
                      content: "input",
                    })
                      .then((value) => {

                        if (value === 'CONFIRM') {

                          onSaveUser(id)
                        }
                        else {

                          swal('Invalid Password', 'You are not authorized to update this user', 'error');
                        }

                      });
                  }}

                >
                  <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Update Profile
                </div>

                <div className="top_add_cart" style={{ backgroundColor: '#ccc' }} onClick={() => onFetchPublic(id)}>
                  <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Cancel Changes
                </div>

              </>
            )}


          </div>
        </Grid>

        <Grid item xs={12} lg={5} md={6}>

          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

            <div className="top_box_magic" style={{ marginBottom: 20 }}>
              <h1>
                Onboarding
              </h1>

              {setup ? (
                <Chip label="Completed" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="success" onClick={(e) => onUpdateUser('setup', false)} />
              ) : (
                <Chip label="Uncomplete" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="warning" onClick={(e) => onUpdateUser('setup', true)} />
              )}
            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                {TRANSLATE.email}
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  value={email}
                  label={'.'}
                  onChange={(e) => onUpdateUser('email', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  placeholder="Type here..."

                />
              </div>
            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Phone
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  value={phone}
                  label={'.'}
                  onChange={(e) => onUpdateUser('phone', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  placeholder="Type here..."

                />
              </div>
            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Description

              </h1>
              <div style={{ margin: '10px 0px' }}>

                <TextareaAutosize
                  className={'gridyx-textarea'}
                  minRows={4}
                  maxRows={10}
                  placeholder="Type here..."
                  value={about}
                  onChange={(e) => onUpdateUser('about', e.target.value)}

                />

              </div>
            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Currency

                <div>
                  <ReactCountryFlag countryCode={CurrencyCode} svg style={{ width: 20, height: 20, marginLeft: 5, cursor: 'pointer' }} />
                </div>

              </h1>

              <div style={{ margin: '10px 0px' }}>
                <select
                  className={'gridyx-select'}
                  value={currency}
                  label={'.'}
                  onChange={(e) => onUpdateUser('currency', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  select
                >
                  <option value={null}>
                    ---- Select a currency ----
                  </option>
                  <option value="usd">
                    $ - USD
                  </option>
                  <option value="eur">
                    € - EUR
                  </option>
                  <option value="gbp">
                    £ - GBP
                  </option>

                </select>
              </div>

            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Language

                <div>
                  <ReactCountryFlag countryCode={lang === 'en' ? 'gb' : lang} svg style={{ width: 20, height: 20, marginLeft: 5, cursor: 'pointer' }} />
                </div>

              </h1>

              <div style={{ margin: '10px 0px' }}>
                <select
                  className={'gridyx-select'}
                  value={lang}
                  label={'.'}
                  onChange={(e) => onUpdateUser('lang', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  select
                >
                  <option value={null}>
                    ---- Select a language ----
                  </option>
                  <option value="en">
                    English
                  </option>
                  <option value="fr">
                    Français
                  </option>
                  <option value="it">
                    Italiano
                  </option>
                  <option value="es">
                    Espanol
                  </option>

                </select>
              </div>

            </div>




            <div className="top_box_magic">
              <h1>
                Last Search
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {search ? (
                    <>
                      <ReactCountryFlag svg countryCode={country} style={{ marginRight: 5 }} /> {city} - ({country})
                    </>
                  ) : (
                    'No Search Made'
                  )}
                </p>
              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                Devices
              </h1>
              <div style={{ margin: '10px 0px' }}>
                {devices.length > 0 ? (

                  devices.map((item, index) => {
                    return (
                      <Chip label={item} key={index} style={{ marginTop: 10 }} variant="filled" color="default" />
                    )
                  })

                ) : (
                  'No Devices Added'
                )}


              </div>
            </div>


            <div className="top_box_magic">
              <h1>
                Testers Credencials
              </h1>

              {isTester ? (
                <Chip label="True" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="success" onClick={(e) => onUpdateUser('isTester', false)} />
              ) : (
                <Chip label="False" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="error" onClick={(e) => onUpdateUser('isTester', true)} />
              )}

            </div>

            <div className="top_box_magic">
              <h1>
                Admin Credencials
              </h1>

              {isAdmin ? (
                <Chip label="True" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="success" onClick={(e) => onUpdateUser('isAdmin', false)} />
              ) : (
                <Chip label="False" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="error" onClick={(e) => onUpdateUser('isAdmin', true)} />
              )}

            </div>


          </div>

        </Grid>

        <Grid item xs={12} lg={3} md={3}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>


            <div className="top_box_magic">
              <h1>
                User Created
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                </p>
              </div>
            </div>
            <div className="top_box_magic">
              <h1>
                User Updated
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                </p>
              </div>
            </div>

            {store && (
              <div className="top_box_magic">
                <h1>
                  Store
                </h1>

                <Chip
                  avatar={<Avatar alt={shop} src={insegna} />}
                  label={shop.toLowerCase()}
                  variant="outlined"
                  size='medium'
                  style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                  onClick={() => history.push('/admin/stores/profile/' + store)}
                />

              </div>
            )}

            <div className="top_box_magic">
              <h1>
                Refferal Code
              </h1>
              <CopyToClipboard text={REFFERAL_CODE}
                onCopy={() => onSelectCopy(REFFERAL_CODE)}
              >
                <Chip label={REFFERAL_CODE}
                  style={{ marginTop: 10, width: 'fit-content' }}
                  variant="outlined" color="default"
                />

              </CopyToClipboard>

            </div>

            {id && (
              <WidgetCount title="Rented Products"
                label="Products"
                type="orders"
                onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&customer=' + id)}
                filters={{
                  customer: id,
                  query: 'products'
                }}
              />
            )}

            {id && (
              <WidgetCount title="Total Orders"
                label="Orders"
                type="orders"
                onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&customer=' + id)}
                filters={{
                  customer: id
                }}
              />
            )}


            <div className="top_box_magic">
              <h1>
                Total Reviews
              </h1>

              <Chip label={reviews + ' Reviews'}
                style={{ marginTop: 10, width: 'fit-content' }}
                variant="filled"
                color="default"
                onClick={() => history.push('/admin/reviews/0?sort=updatedAt&dir=desc&type=customer&customer=' + id)}
              />

            </div>

            <div style={{ marginTop: 40 }} />


            <ReviewComponent title="Communication"
              value={Communication}
              ReadOnly={true}

            />
            <ReviewComponent title="Punctuation"
              value={Punctuation}
              ReadOnly={true}

            />
            <ReviewComponent title="Use of Gear"
              value={Use}
              ReadOnly={true}

            />

          </div>
        </Grid>

      </Grid>

    </Container>

  )


}

const CardDelete = ({ title, value }) => {

  return (
    <div className="top_box_magic" style={{ marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      {value ? (
        <>
          <div>
            <h1>
              {title}
            </h1>
            <div style={{ color: '#green' }}>{value}</div>
          </div>
          <Chip label="Delete" variant="filled" color="error" />
        </>
      ) : (
        <>
          <div>
            <h1>
              {title}
            </h1>
            <div>{value}</div>
          </div>
        </>
      )}
    </div>
  )
}


const getSplittedNames = (full_name) => {

  const full_name_array = full_name ? full_name.split(' ') : [];
  const first_name = full_name_array[0];
  const last_name = full_name_array[full_name_array.length - 1];

  return {
    first_name,
    last_name
  }
}


export default AdminCustomersDetails
