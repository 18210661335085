import {
  AccountCircleOutlined,
  DashboardOutlined,
  Inventory2Outlined,
  LaunchOutlined,
  SmsOutlined,
} from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Cities_Array } from '../../components/Constants/Constants';

const Footer = (props) => {
  const { TRANSLATE, LANGUAGE } = props;

  return (
    <footer className='footer'>
      <div className='footer-container max-w-8xl px-4 sm:px-6 lg:px-8 '>
        <div className='w-full md:w-[30%] py-10 flex flex-col '>
          <div className='flex items-center'>
            <img className='w-[30px] h-[30px] object-contain' src='/assets/img/b-logo.png' alt='' />
          </div>
          <p className='mt-2'>
            © 2023 Babonbo Ltd
            <br />
            64 Southwark Bridge, Road <br />
            London, United Kingdom, SE1 0AS
          </p>
        </div>

        <div className='w-1/2 md:w-[20%] py-10 flex flex-col'>
          <div className='font-semibold mt-2 mb-4'>{TRANSLATE.wd_learnmore}</div>
          <Link to={'/' + LANGUAGE + '/about'} className='mb-2'>
            {TRANSLATE.wd_aboutus}
          </Link>
          <Link to={'/' + LANGUAGE + '/how-it-works'} className='mb-2'>
            {TRANSLATE.wd_howitworks}
          </Link>

          <Link to={'/' + LANGUAGE + '/become-provider'} className='mb-2'>
            {TRANSLATE.btn_become_provider}
          </Link>
          <a href={'/' + LANGUAGE + '/blog'} className='mb-2' target='_blank'>
            {TRANSLATE.wd_blog}
          </a>
          <Link to={'/' + LANGUAGE + '/trust-and-safety'} className='mb-2'>
            {TRANSLATE.wd_trust}
          </Link>
          <Link to={'/' + LANGUAGE + '/download'} className='mb-2'>
            Download
          </Link>
        </div>
        <div className='w-1/2 md:w-[15%] py-10 flex flex-col'>
          <div className='font-semibold mt-2 mb-4'>{TRANSLATE.wd_where_we_are}</div>
          {Cities_Array.map((item, index) => {
            return (
              <Link to={item[LANGUAGE + '_url']} className='mb-2' key={index}>
                {item[LANGUAGE]}
              </Link>
            );
          })}
          <Link className='mb-2' to={'/' + LANGUAGE + '/locations'}>
            {TRANSLATE.wd_other_cities}
          </Link>
        </div>
        <div className='w-1/2 md:w-[15%] py-10 flex flex-col'>
          <div className='font-semibold mt-2 mb-4'>{TRANSLATE.wd_support}</div>
          <a
            className='mb-2'
            href={'https://intercom.help/babonbo/en/'}
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            {TRANSLATE.wd_help_center}
          </a>
          <Link to={'/' + LANGUAGE + '/resources'} className='mb-2'>
            {TRANSLATE.wd_provider_resources}
          </Link>
          <Link to={'/' + LANGUAGE + '/legal/terms'} className='mb-2'>
            {TRANSLATE.wd_terms}
          </Link>
          <Link to={'/' + LANGUAGE + '/legal/privacy'} className='mb-2'>
            {TRANSLATE.wd_privacy}
          </Link>
          <Link to={'/' + LANGUAGE + '/legal/cancellation'} className='mb-2'>
            {TRANSLATE.wd_cancellation}
          </Link>
        </div>
        <div className='w-1/2 md:w-[15%] py-10 flex flex-col'>
          <div className='font-semibold mt-2 mb-4'>{TRANSLATE.wd_socialmedia}</div>
          <a
            className='mb-2'
            href='https://www.facebook.com/babonbo.baby'
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            Facebook
          </a>
          <a
            className='mb-2'
            href='https://www.instagram.com/babonbo.baby/'
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            Instagram
          </a>
          <a
            className='mb-2'
            href='https://www.linkedin.com/company/babonbo/'
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            LinkedIn
          </a>
          <div className='font-semibold mt-6 mb-4'>{TRANSLATE.download_page.t}</div>
          <a
            href='https://apps.apple.com/us/app/babonbo/id1572432866?ign-mpt=uo%3D2'
            target='_blank'
            rel='noopener noreferrer nofollow'
            className='mb-2'
          >
            <img className='w-[120px]' src='/assets/img/app-store.png' alt='Download Babonbo AppStore' />
          </a>
          <a
            href='https://play.google.com/store/apps/details?id=com.babonbo.app'
            target='_blank'
            rel='noopener noreferrer nofollow'
            className='mb-2'
          >
            <img className='w-[120px]' src='/assets/img/play-store.png' alt='Download Babonbo PlayStore' />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

export const FooterDashboard = (props) => {
  const { location } = props;

  const currentPage = location.pathname;

  return (
    <footer className='md:hidden sticky bottom-0 flex justify-content-between p-0 w-full text-xs border-none bg-white'>
      <Link
        to='/newux/dashboard'
        className={`flex flex-col align-items-center p-2 rounded-lg ${
          currentPage === '/newux/dashboard' ? 'text-white bg-pr' : 'color-pr'
        }`}
      >
        <DashboardOutlined /> Dashboard
      </Link>
      <Link
        to='/newux/products'
        className={`flex flex-col align-items-center p-2 rounded-lg ${
          currentPage === '/newux/products' ? 'text-white bg-pr' : 'color-pr'
        }`}
      >
        <Inventory2Outlined /> My Products
      </Link>
      <Link
        to='/newux/orders'
        className={`flex flex-col align-items-center p-2 rounded-lg ${
          currentPage === '/newux/orders' ? 'text-white bg-pr' : 'color-pr'
        }`}
      >
        <LaunchOutlined /> Orders
      </Link>
      <Link
        to='/chat'
        className={`flex flex-col align-items-center p-2 rounded-lg ${
          currentPage === 'chat' ? 'text-white bg-pr' : 'color-pr'
        }`}
      >
        <SmsOutlined /> Chat
      </Link>

      <Link
        to='/profile'
        className={`flex flex-col align-items-center p-2 rounded-lg ${
          currentPage === 'profile' ? 'text-white bg-pr' : 'color-pr'
        }`}
      >
        <AccountCircleOutlined /> Profile
      </Link>
    </footer>
  );
};
