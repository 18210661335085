import React, { useEffect, useState } from 'react';

import {postApiPrivate} from '../../api';

import moment from 'moment/min/moment-with-locales';

import { ArrowBack, ArrowForward, CheckmarkCircleOutline } from 'react-ionicons';

import LoaderMaster from '../Loaders/Loaders';
import { Link } from 'react-router-dom';

import ReactCountryFlag from "react-country-flag";
import Countries_EN from '../Search/countries_en.json';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    LineChart
} from "recharts";
import { TextField } from '@mui/material';
  
  

const AnalyticsCalendar = (props) => {

    const {TRANSLATE, LANGUAGE, location, history} = props;

    let MenuClass = 'col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6 mb-4';
    let MenuStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        fontSize: 18,
        fontWeight: 'bold',
        width: '100%',
        background: '#fff',
        paddingTop: 20,
        paddingBottom: 20
    }

    let MenuClassStats = 'col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-column align-items-center justify-content-center';
    let MenuStyleStats = {
        borderRadius: 20,
        fontSize: 24,
        fontWeight: 'bold',
     
        width: '100%'
    }

    const [isLoading, setLoading] = useState(false);

    const [CurrentType, setCurrentType] = useState('all');

    const [CurrentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [CurrentMonth, setCurrentMonth] = useState(new Date().getMonth());

    const [CurrentOrders, setCurrentOrders] = useState([]);
    const [FullData, setFullData] = useState({});

    const [isFocused, setFocused] = useState(false);
    const [CityResults, setCityResults] = useState([]);
    const [CityInput, setCityInput] = useState('');


    useEffect(()=> {

        onFetchCalendar()

    }, [location.search]);

    const onFetchCalendar = async() => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
  
        try{
  
            const CurrentParams = GetRouteSearchParams(location.search);

            const CurrentStore = CurrentParams.store;
            const CurrentCity = CurrentParams.city;
            const CurrentGeopoint = CurrentParams.geopoint;
            const CurrentCountry = CurrentParams.country;

            const CurrentYear = CurrentParams.year;
            const CurrentMonth = CurrentParams.month;
            const CurrentStart = CurrentParams.start;
            const CurrentEnd = CurrentParams.end;

            let FORM = {
                store: CurrentStore,
                city: CurrentCity,
                geopoint: CurrentGeopoint,
                country: CurrentCountry,
                start: CurrentStart,
                end: CurrentEnd,
                year: CurrentYear,
                month: CurrentMonth
            }

            setLoading(true);
        
            const DATA_RESPONSE = await postApiPrivate('/admin/analytics/calendar', FORM , JWT);
            
            if(DATA_RESPONSE.error){
  
                setLoading(false);

                console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
  
                return alert('Error Loading the dashboard');
            }
    
            const DASHBOARD_DATA = DATA_RESPONSE.data || [];
    
            setCurrentOrders(DASHBOARD_DATA);
            setFullData(DATA_RESPONSE);
            
            setLoading(false);

            console.log("FETCHING DASHBOARD ===> ", DASHBOARD_DATA);
  
        }
        catch(e){
  
            setLoading(false);
            alert('Error Loading the dashboard');
        }   
  
    }

    const getNextMonth = () => {
        const CurrentDate = new Date(CurrentYear, CurrentMonth, 1);

        const NextMonth = moment(CurrentDate).add(1, 'months');

        const NextCurrentYear = new Date (NextMonth).getFullYear();
        const NextCurrentMonth = new Date (NextMonth).getMonth();

        setCurrentYear(NextCurrentYear);
        setCurrentMonth(NextCurrentMonth);

        onFetchCalendar(NextCurrentYear, NextCurrentMonth);
    }

    const getPreviousMonth = () => {

        const CurrentDate = new Date(CurrentYear, CurrentMonth, 1);

        const PreviousMonth = moment(CurrentDate).subtract(1, 'months');

        const PreviousCurrentYear = new Date (PreviousMonth).getFullYear();
        const PreviousCurrentMonth = new Date (PreviousMonth).getMonth();

        setCurrentYear(PreviousCurrentYear);
        setCurrentMonth(PreviousCurrentMonth);


        onFetchCalendar(PreviousCurrentYear, PreviousCurrentMonth);
            

    }

    const onSelectCity = async(city) => {

        console.log("CITY SELECTED ===> ", city);
  
        setFocused(false);
  
        const CityId = city.id;

        setCityInput(city[LANGUAGE]);
  
        history.push('?city='+CityId);
        
    }
  
    const onSearchCity = async(query) => {
  
        setCityInput(query);
  
        if(!query){
            setFocused(false);
            setCityResults([]);
  
            return 
        }
  
        //Fetch
  
        try{
  
            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
  
            const DATA_RESPONSE = await postApiPrivate('/locations/search', {query: query, lang: LANGUAGE} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                return 
            }
  
            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);
  
            const filtredResults = DATA_RESPONSE.data || [];
  
            if(filtredResults.length > 0){
                setFocused(true);
                setCityResults(filtredResults);
            }
  
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);
  
            setFocused(false);
            setCityResults([]);
    
        }
      
            
    }
  
    const onSearchKeyUp = async(event) => {
        event.preventDefault();
  
        if (event.keyCode === 13) {
            event.target.blur();
  
            if(CityResults.length>0){
                onSelectCity(CityResults[0]);
            }
        }
    }
  

    const TotalStats = FullData.total_stats || {};
    const GreenStats = FullData.green_stats || {};
    const RedStats = FullData.red_stats || {};
    const YellowStats = FullData.yellow_stats || {};

    const CurrentStores = FullData.stores || [];

    const CalendarArray = getAllDaysInMonth(CurrentYear, CurrentMonth) || [];
    const FirstDay = moment(new Date(CalendarArray[0]));
    const LastDay = moment(new Date(CalendarArray[CalendarArray.length-1]))

    const DATA = CurrentOrders ? CurrentOrders.map((item)=> {

        const GMV = Math.round(item.revenue || 0) || 0;
     
        return {
            ...item,
            GMV: GMV
        }

    }) : [];

    
    return (
        <div className='container-fluid mb-5' style={{background: '#f0f5f9'}}>

            <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-between align-items-center'>

                <div className='button' onClick={()=> getPreviousMonth()} style={{fontWeight: 'bold', background: '#26BFBF'}}>
                    <ArrowBack color={'#fff'} /> Previous Month
                </div>
 
                <div>
                    <h1 style={{fontSize: 60, fontWeight: 100}}>
                        {FirstDay.format('DD MMM yy')} -  <span style={{fontWeight: 'bold'}}>{LastDay.format('DD MMM yyyy')}</span>
                    </h1>
                </div>
                

                <div className='button' onClick={()=> getNextMonth()} style={{fontWeight: 'bold', background: '#26BFBF'}}>
                    Next Month <ArrowForward color={'#fff'} />
                </div>
            </div>


            <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-between align-items-center'>

                <div className='col-xl-6'>

                    <div className="top_box_magic">
                        <h1>
                            Please Select a City
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                            <TextField 
                                className={'gridyx-textfield'}
                                value={CityInput} 
                                label={'.'} 
                                // onChange={(e)=> onUpdateGeopoint('marca', e.target.value)}
                                InputLabelProps={{shrink: false}}
                                onFocus={()=> setFocused(true)}  
                                onChange={(e)=> onSearchCity(e.target.value)} 
                                onKeyUp={(e)=> onSearchKeyUp(e)}
                            
                            
                            />

                            {(isFocused && CityResults.length > 0) && (
                                <div style={{...Styles.resultsContainer}}>
                                    {CityResults.map((item, index)=> {

                                        const country_index = Countries_EN.findIndex((cty)=> cty.value === item.country);
                                        const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
                                        const country_name = country_obx ? country_obx.label : null


                                        return (
                                                <div style={Styles.resultsRow} 
                                                        key={index} 
                                                        onClick={()=> onSelectCity(item)}
                                                >

                                                    <div>
                                                        <ReactCountryFlag countryCode={item.country} svg style={{marginRight: 5}}/> 
                                                        {item[LANGUAGE]} - ({country_name || item.country})
                                                    </div>
                                                    
                                                    <CheckmarkCircleOutline size={28} color="#ccc" style={{marginRight: 10}} />
                                                    
                        
                                                </div>
                                        )
                                        
                                    })}
                                </div>
                            )}
                        </div>

                    </div>
                    
                </div>

                {/* <div className='col-xl-4'>
                    <select 
                            className={'gridyx-select'}
                            value={CurrentType} 
                            label={'.'} 
                            onChange={(e)=> onUpdateStatus(e.target.value)}
                            InputLabelProps={{shrink: false}}
                            select
                        >
                        <option value={null}>
                                ---- Select a provider ({CurrentStores.length}) ----
                        </option>
                        {CurrentStores.map((item, index)=> {

                            return (
                                <option value={item.id} key={index}>
                                    {item.name}
                                </option>
                            )
                        })}
                        
                    </select>
                </div>

                <div className='col-xl-2'>
                    <select 
                            className={'gridyx-select'}
                            value={CurrentType} 
                            label={'.'} 
                            onChange={(e)=> onUpdateStatus(e.target.value)}
                            InputLabelProps={{shrink: false}}
                            select
                        >
                        <option value={null}>
                                ---- Select a type ----
                        </option>
                        <option value="all">
                            All Orders
                        </option>
                        <option value="green">
                            Green Orders
                        </option>
                        <option value="yellow">
                            Yellow Orders
                        </option>
                        <option value="red">
                            Red Orders
                        </option>
                        
                        
                    </select>
                </div> */}

            </div>

            
            {isLoading ? (
                <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
            ) : (
                <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-between w-100'>
                    
                        <div className='col-xl-6 col-md-6 col-12 p-0'>  
                            <div style={{ width: '100%', height: 350 }}>
                                <ResponsiveContainer>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={DATA || []}
                                        syncId="anyId"
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                        >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis unit={'€'} name="GMV" />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        <Bar dataKey="GMV" stackId="a" fill="#26BFBF" />
                                        
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <div style={{ width: '100%', height: 350 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={DATA || []}
                                        syncId="anyId"
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        {/* <Legend /> */}

                                        <Line dataKey="pending" type="monotone" stroke="#FCE205" />
                                        <Line dataKey="accepted" type="monotone" stroke="#9AD6AB" />
                                        <Line dataKey="delivering" type="monotone" stroke="#88D1F1" />
                                        <Line dataKey="delivered" type="monotone" stroke="#ff8b3d" />
                                        <Line dataKey="complete" type="monotone" stroke="green" />
                                        <Line dataKey="rejected" type="monotone" stroke="#ec1529" />
                                        <Line dataKey="canceled" type="monotone" stroke="#ec1529" />
                                        <Line dataKey="refund" type="monotone" stroke="#ec1529" />
                                        <Line dataKey="total" type="monotone" stroke="#3d3d3d"  />

                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        <div className='col-xl-6 col-md-6 col-12 p-0'>
                            
                            <div className='container-fluid' style={{borderRadius: 20, padding: 20}}>

                               
                                {CurrentType === 'all' && (
                                    <>
                                        <div className='row mb-5' id="all_orders" style={{textAlign: 'center'}}>

                                                    {/* First */}
                                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {numberWithCommas(TotalStats.amount_total, 'EUR')}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Total Transactions
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {FullData.total}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            All Orders
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25, color: '#26BFBF'}}>
                                                        {numberWithCommas(TotalStats.amount_provider, 'EUR')}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Provider Earnings
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/stores'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25, color: '#fd6270'}}>
                                                        {numberWithCommas(TotalStats.amount_fees, 'EUR')}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Total Fees
                                                        </p>
                                                    </Link>

                                                    {/* Second */}

                                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {numberWithCommas(TotalStats.average_order_value, 'EUR')}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Average Order Value
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {numberWithCommas(TotalStats.average_order_duration)}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Average Order Duration
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {TotalStats.total_providers}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Unique Providers
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {TotalStats.total_customers}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Unique Customers
                                                        </p>
                                                    </Link>

                                                    {/* Third */}

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {numberWithCommas(TotalStats.average_order_product, 'EUR')}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Average Product Value
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {numberWithCommas(TotalStats.average_order_quantity)}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Average Order Quantity
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {numberWithCommas(TotalStats.average_provider_orders)}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Orders per Provider
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {numberWithCommas(TotalStats.average_customer_orders)}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Orders per Customer
                                                        </p>
                                                    </Link>

                                                    {/* Fourth */}
                                                    

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {TotalStats.total_products}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Total Rented Products
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {TotalStats.total_products_quantity}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Total Products Quantity
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {numberWithCommas(TotalStats.average_provider_customers)}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                            Customers per Provider
                                                        </p>
                                                    </Link>

                                                    <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                        <h1 style={{fontSize: 25}}>
                                                        {TotalStats.total_rental_days}
                                                        </h1>
                                                        <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Total Rental Days
                                                        </p>
                                                    </Link>

                                        </div>

                                        <div className='row mb-5' id="status_orders" style={{textAlign: 'center'}}>
                                
                                               <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: '#000'}}>
                                                      {FullData.total}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(TotalStats.amount_total, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Total Orders
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: '#FCE205'}}>
                                                      {FullData.yellow}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(YellowStats.amount_total, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Yellow Orders
                                                    </p>
                                                </Link>

                                               <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: 'green'}}>
                                                      {FullData.green}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                        {numberWithCommas(GreenStats.amount_total, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Green Orders
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: 'red'}}>
                                                      {FullData.red}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                        {numberWithCommas(RedStats.amount_total, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Red Orders
                                                    </p>
                                                </Link>

                                        </div>

                                        <div className='row mb-5' id="status_orders" style={{textAlign: 'center'}}>

                                                {/* First */}
                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: '#FCE205'}}>
                                                      {FullData.pending}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(FullData.amount_pending, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Pending Orders
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: '#9AD6AB'}}>
                                                      {FullData.accepted}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(FullData.amount_accepted, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Accepted Orders
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: '#88D1F1'}}>
                                                      {FullData.delivering}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(FullData.amount_delivering, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Delivering Orders
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: '#ff8b3d'}}>
                                                      {FullData.delivered}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(FullData.amount_delivered, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Delivered Orders
                                                    </p>
                                                </Link>


                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: 'green'}}>
                                                      {FullData.complete}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(FullData.amount_complete, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Completed Orders
                                                    </p>
                                                </Link>


                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: '#ec1529'}}>
                                                      {FullData.rejected}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(FullData.amount_rejected, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Rejected Orders
                                                    </p>
                                                </Link>


                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: '#ec1529'}}>
                                                      {FullData.canceled}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(FullData.amount_canceled, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Canceled Orders
                                                    </p>
                                                </Link>


                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                                    <h1 style={{fontSize: 25, color: '#ec1529'}}>
                                                      {FullData.refund}
                                                    </h1>
                                                    <h1 style={{fontSize: 20}}>
                                                      {numberWithCommas(FullData.amount_refund, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                       Refunded Orders
                                                    </p>
                                                </Link>

                                               
                                            

                                        </div>
                                    </>
                                )}

                                {CurrentType === 'green' && (
                                    <div className='row mb-5' id="green_orders" style={{textAlign: 'center'}}>

                                                {/* First */}
                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(GreenStats.amount_total, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Transactions
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {FullData.total}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        All Orders
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25, color: '#26BFBF'}}>
                                                    {numberWithCommas(GreenStats.amount_provider, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Provider Earnings
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/stores'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25, color: '#fd6270'}}>
                                                    {numberWithCommas(GreenStats.amount_fees, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Fees
                                                    </p>
                                                </Link>

                                                {/* Second */}

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(GreenStats.average_order_value, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Order Value
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(GreenStats.average_order_duration)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Order Duration
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {GreenStats.total_providers}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Unique Providers
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {GreenStats.total_customers}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Unique Customers
                                                    </p>
                                                </Link>

                                                {/* Third */}

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(GreenStats.average_order_product, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Product Value
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(GreenStats.average_order_quantity)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Order Quantity
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(GreenStats.average_provider_orders)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Orders per Provider
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(GreenStats.average_customer_orders)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Orders per Customer
                                                    </p>
                                                </Link>

                                                {/* Fourth */}
                                                

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {GreenStats.total_products}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Total Rented Products
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {GreenStats.total_products_quantity}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Products Quantity
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(GreenStats.average_provider_customers)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Customers per Provider
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {GreenStats.total_rental_days}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Rental Days
                                                    </p>
                                                </Link>

                                    </div>
                                )}

                                {CurrentType === 'yellow' && (
                                    <div className='row mb-5' id="yellow_orders" style={{textAlign: 'center'}}>

                                                {/* First */}
                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(YellowStats.amount_total, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Transactions
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {FullData.yellow}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        All Orders
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25, color: '#26BFBF'}}>
                                                    {numberWithCommas(YellowStats.amount_provider, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Provider Earnings
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/stores'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25, color: '#fd6270'}}>
                                                    {numberWithCommas(YellowStats.amount_fees, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Fees
                                                    </p>
                                                </Link>

                                                {/* Second */}

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(YellowStats.average_order_value, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Order Value
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(YellowStats.average_order_duration)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Order Duration
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {YellowStats.total_providers}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Unique Providers
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {YellowStats.total_customers}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Unique Customers
                                                    </p>
                                                </Link>

                                                {/* Third */}

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(YellowStats.average_order_product, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Product Value
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(YellowStats.average_order_quantity)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Order Quantity
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(YellowStats.average_provider_orders)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Orders per Provider
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(YellowStats.average_customer_orders)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Orders per Customer
                                                    </p>
                                                </Link>

                                                {/* Fourth */}
                                                

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {YellowStats.total_products}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Total Rented Products
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {YellowStats.total_products_quantity}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Products Quantity
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(YellowStats.average_provider_customers)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Customers per Provider
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {YellowStats.total_rental_days}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Rental Days
                                                    </p>
                                                </Link>

                                    </div>
                                )}
                                
                                {CurrentType === 'red' && (
                                    <div className='row mb-5' id="red_orders" style={{textAlign: 'center'}}>

                                                {/* First */}
                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(RedStats.amount_total, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Transactions
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {FullData.total}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        All Orders
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25, color: '#26BFBF'}}>
                                                    {numberWithCommas(RedStats.amount_provider, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Provider Earnings
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/stores'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25, color: '#fd6270'}}>
                                                    {numberWithCommas(RedStats.amount_fees, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Fees
                                                    </p>
                                                </Link>

                                                {/* Second */}

                                                <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(RedStats.average_order_value, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Order Value
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(RedStats.average_order_duration)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Order Duration
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {RedStats.total_providers}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Unique Providers
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {RedStats.total_customers}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Unique Customers
                                                    </p>
                                                </Link>

                                                {/* Third */}

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(RedStats.average_order_product, 'EUR')}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Product Value
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(RedStats.average_order_quantity)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Average Order Quantity
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(RedStats.average_provider_orders)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Orders per Provider
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(RedStats.average_customer_orders)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Orders per Customer
                                                    </p>
                                                </Link>

                                                {/* Fourth */}
                                                

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {RedStats.total_products}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Total Rented Products
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {RedStats.total_products_quantity}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Products Quantity
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {numberWithCommas(RedStats.average_provider_customers)}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                        Customers per Provider
                                                    </p>
                                                </Link>

                                                <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                    <h1 style={{fontSize: 25}}>
                                                    {RedStats.total_rental_days}
                                                    </h1>
                                                    <p style={{fontSize: 15, fontWeight: 100}}>
                                                    Total Rental Days
                                                    </p>
                                                </Link>

                                    </div>
                                )}
                                
                            </div>

                        </div>

                </div>
            )}

    

            {isLoading ? (
                <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
            ) : (
                <div className='row m-0 p-0 px-xl-5 justify-content-center' style={{width:'100%'}}>
                    
                        {CurrentOrders.map((item, index)=> {

                            const MOMENT = moment(item.date);
                            const ISFUTURE = !moment(item.date).isSameOrBefore(moment(), "day");

                            if(ISFUTURE){

                                return null
                            }
                        
                            return (
                                <div className={MenuClass} key={index}>
                                    <Link to="/admin/dashboard" className='bg-light' style={{...MenuStyle}}>
                                            <h1 style={{fontSize: 55, color: 'var(--dark)'}}>
                                                {item.total}
                                            </h1>
                                            <p style={{fontSize: 18, fontWeight: 100}}>
                                                {numberWithCommas(item.revenue, 'EUR')}
                                            </p>
                                            <p style={{fontSize: 12, fontWeight: 'bold'}}>
                                                {MOMENT.format('dddd DD MMMM YYYY')}
                                            </p>

                                            <div className='d-flex align-items-center justify-content-center w-100' style={{paddingLeft: 10, paddingRight: 10}}>

                                                {item.pending > 0 && (
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div style={{background: '#FCE205', height: 10, width: 10, borderRadius: '50%'}}></div>
                                                        <div>{item.pending}</div>
                                                    </div>
                                                )}
                                                
                                                {item.accepted > 0 && (
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div style={{background: '#9AD6AB', height: 10, width: 10, borderRadius: '50%'}}></div>
                                                        <div>{item.accepted}</div>
                                                    </div>
                                                )}
                                                
                                                {item.delivering > 0 && (
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div style={{background: '#88D1F1', height: 10, width: 10, borderRadius: '50%'}}></div>
                                                        <div>{item.delivering}</div>
                                                    </div>
                                                )}
                                                
                                                {item.delivered > 0 && (
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div style={{background: '#ff8b3d', height: 10, width: 10, borderRadius: '50%'}}></div>
                                                        <div>{item.delivered}</div>
                                                    </div>
                                                )}
                                                
                                                {item.complete > 0 && (
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div style={{background: 'green', height: 10, width: 10, borderRadius: '50%'}}></div>
                                                        <div>{item.complete}</div>
                                                    </div>
                                                )}
                                                
                                                {item.rejected > 0 && (
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div style={{background: '#ec1529', height: 10, width: 10, borderRadius: '50%'}}></div>
                                                        <div>{item.rejected}</div>
                                                    </div>
                                                )}
                                                
                                                {item.canceled > 0 && (
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div style={{background: '#ec1529', height: 10, width: 10, borderRadius: '50%'}}></div>
                                                        <div>{item.canceled}</div>
                                                    </div>
                                                )}
                                                
                                                {item.refund > 0 && (
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div style={{background: '#ec1529', height: 10, width: 10, borderRadius: '50%'}}></div>
                                                        <div>{item.refund}</div>
                                                    </div>
                                                )}
                                                

                                            
                                            </div>
                                    </Link>
                                </div>
                            )

                        })}
                    
                </div>
            )}

        </div>
    )
}



const getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}

function numberWithCommas(x, CURRENCY) {

    if(!x){
        x=0;
    }

    if(!CURRENCY){
        return x.toFixed(2);
    }

	if(isNaN(x)) return x;

    return (x).toLocaleString('en-US', {
        style: 'currency',
        currency: CURRENCY,
    });

}

const GetRouteSearchParams = (query) => {
    const routeParams = getQueryStringParams(query) || {};
    return routeParams
}

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

const Styles = {
    resultsRow: {
        display: 'flex', 
        alignItems: 'center', 
        color: '#000', 
        height: 40, 
        minHeight: 40,
        paddingLeft: 20, 
        border: '1px solid #00000040', 
        borderRight: 'none', 
        borderTop: 'none', 
        borderLeft: 'none',
        cursor: 'pointer',
        textTransform: 'capitalize',
        justifyContent: 'space-between'
    },
    resultsContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        background: '#fff', 
        maxHeight: 280,
        position: 'relative', 
        top: 20, 
        border: '1px solid #00000040', 
        borderRadius: 10,
        overflow: 'auto',
        marginBottom: 20
    },
    right: {
        display: 'flex',
        alignItems: 'center'
    },
    searchContainer: {
        width: 300,
        height: 42,
        backgroundColor: '#F1F1F1',
        borderRadius: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 5
    },
    input: {
        fontSize: 16,
        display: 'flex',
        color: '#000',
        backgroundColor: 'transparent',
        border: 'none',
        width: '100%',
        marginLeft: 5,
        textTransform: 'capitalize'
    },
    searchOption: {
        display: 'flex', 
        width: 40, 
        height: 35, 
        alignItems: 'center', 
        borderRadius: '50%', 
        background: '#fd6270'
    },
    button: {
          display: 'flex',
          height: '50px',
          width: '200px',
          backgroundColor: '#26BFBF',
          color: '#fff',
          justifyContent: 'center',
          alignItems: 'center',  
          borderRadius: 10,
          width: '100%'
      },
      img_full: {
        display: 'flex',
        width: 200, 
        height: 200, 
        borderRadius: 6, 
        marginRight: 15, 
        marginLeft: 15
      },
      img_empty: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%', 
        height: '100%', 
        borderRadius: 6, 
        marginRight: 15, 
        borderColor: '#ccc', 
        borderWidth: 2, 
        borderStyle: "dashed", 
        justifyContent: 'center', 
        alignItems: 'center',
        marginBottom: 10,
        
      },
      clickCard: {
          backgroundColor: "#fff",
          padding: 20,
          marginTop: 10,
      },
      clickLabel: {
          color: "#697883",
          fontFamily: "SourceSansPro-Bold", 
          fontSize: 14, 
          marginBottom: 10,
      },
      wrapFlex: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      publishBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fd6270',
        padding: 10,
        width: 90,
        color: '#fff',
        borderRadius: 10,
        cursor: 'pointer'
      }
  
  }
  

export default AnalyticsCalendar

