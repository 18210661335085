import React, { useEffect, useState } from "react";

import { postApiPrivate } from "../../api";

import moment from "moment";
import swal from "sweetalert";
import { CloseOutline } from "react-ionicons";
import LoaderMaster from "../Loaders/Loaders";

import { GetRouteSearchParams, onFetchCurrency } from "../../helpers/GeneralHelper";

import Tooltip from '../General/Tooltip';

import ProviderMap from '../General/ProviderMap';

import SearchDelivery from '../General/SearchDelivery';


const StoreDelivery = (props) => {


    const { onClose, USER, STORE_ID, history, location, match, DASHBOARD, CURRENCY, LANGUAGE, TRANSLATE, onFetchStore } = props;

    const [isLoading, setLoading] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const [isMap, setMap] = useState(true);

    const [isLocation, setLocation] = useState(false);
    const [isDelivery, setDelivery] = useState(false);
    const [isChanged, setChanged] = useState(false);

    const [LOCATION, setCurrentLocation] = useState(null);
    const [DELIVERY, setCurrentDelivery] = useState([]);
    const [GEO, setCurrentGeo] = useState({});


    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/' + LANGUAGE + '/login');
        }

        setLoading(true);

        setTimeout(()=> {

            const DASHBOARD_DELIVERY = DASHBOARD.delivery || [];
            const DASHBOARD_GEO = DASHBOARD.geocode || {};
    
            setCurrentDelivery(DASHBOARD_DELIVERY)
            setCurrentGeo(DASHBOARD_GEO);

            setLoading(false);

        }, 1500)
       

    }, [USER, DASHBOARD]);

    useEffect(() => {

        if (isDelivery) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }

    }, [isDelivery])


    const onSaveStoreDelivery = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const store_id = STORE_ID || '';

        const FORM = {
            id: store_id,
            delivery: DELIVERY
        }

        console.log("FORM TO SAVE ===> ", FORM);

        setSaving(true);

        try {

            const DATA_RESPONSE = await postApiPrivate('/admin/stores/edit', FORM, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING STORE", DATA_RESPONSE.error);
                setSaving(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            swal(TRANSLATE.alert_store_updated.t, TRANSLATE.alert_store_updated.s, 'success')

            onFetchStore(store_id);

        }
        catch (e) {

            setSaving(false);
        }


    }

    const onSelectDelivery = (delivery) => {

        const SELECTED_GEO = delivery;

        var geo_index = DELIVERY.findIndex((item) => item.name === SELECTED_GEO.name);

        //Setup Price

        var newGeoObx = {
            name: SELECTED_GEO.name,
            geocode: SELECTED_GEO.geocode,
            city: SELECTED_GEO.city,
            type: SELECTED_GEO.type,
            cost: 0
        }

        var newOption = [];

        if (geo_index != -1) {

            newOption = [
                ...DELIVERY.slice(0, geo_index),
                { ...DELIVERY[geo_index], ...newGeoObx },
                ...DELIVERY.slice(geo_index + 1),
            ]

        }
        else {

            newOption = [...DELIVERY, newGeoObx];
        }


        const SortedDelivery = newOption.sort((a, b) => parseFloat(a.cost) - parseFloat(b.cost));

        setCurrentDelivery(SortedDelivery);

        setChanged(true);

        // onSaveStore(finalOption);

    }

    const onEditDelivery = (index, type, value) => {

        const oldDelivery = DELIVERY || [];

        const newDelivery = [
            ...oldDelivery.slice(0, index),
            { ...oldDelivery[index], [type]: value },
            ...oldDelivery.slice(index + 1),
        ]

        //const SortedDelivery = newDelivery.sort((a, b) => parseFloat(a.cost) - parseFloat(b.cost));

        setCurrentDelivery(newDelivery)

        setChanged(true);
    }

    const onDeleteDelivery = (index) => {

        const FilteredDelivery = DELIVERY.filter((item, idx) => idx != index);
        const SortedDelivery = FilteredDelivery.sort((a, b) => parseFloat(a.cost) - parseFloat(b.cost));


        swal({
            title: "Are you sure?",
            text: "Once deleted you won't be able to recover this delivery point",
            icon: "warning",
            buttons: ["Cancel", "Delete"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    setCurrentDelivery(SortedDelivery);

                    setChanged(true);
                }
            });

    }

    const LocationObx = DASHBOARD.location || {};
    const LocationAddress = LocationObx.address || '';
    const LocationCity = DASHBOARD.city || '';

    const shop_symbol = onFetchCurrency(CURRENCY || 'eur');


    const CAN_SAVE = isChanged;

    if(isDelivery){

        return (
            <SearchDelivery {...props} 
                                onClose={()=> setDelivery(false)}
                                onSelectDelivery={onSelectDelivery}
                                geocode={DASHBOARD.geocode}
                
                />
        )
    }

    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Store Delivery
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        {isLoading || isSaving ? (
                            <LoaderMaster type={'secondary'} />
                        ) : (
                            <>
                                <div className="d-flex flex-column align-items-center justify-content-center pt-8 md:pb-0 pb-32">

                                    <div className="w-full">
                                        
                                        <div className='flex w-full items-center justify-between mb-6 md:flex-nowrap flex-wrap'>
                                            <div>
                                                <label className='font-semibold'>{TRANSLATE.tool_delivery_locations}
                                                    <Tooltip desc={TRANSLATE.tool_enter_delivery_locations} />
                                                </label>
                                            </div>

                                            <div className="btn-provider" onClick={() => setDelivery(true)}>
                                                + {TRANSLATE.btn_add_delivery_area}
                                            </div>
                                        </div>

                                        {isMap && (
                                            <div className="w-full mb-10 mt-3">
                                                <StoreMap store={{
                                                    ...DASHBOARD,
                                                    delivery: DELIVERY
                                                }}
                                                    geo={GEO}
                                                    type="delivery"
                                                    TRANSLATE={TRANSLATE}
                                                    LANGUAGE={LANGUAGE}
                                                    USER={USER} />
                                            </div>
                                        )}

                                    </div>

                                    <div className="w-full mt-8 flex flex-col">

                                        <div className='flex w-full items-center justify-between mb-3 md:flex-nowrap flex-wrap'>
                                            <div>
                                                <label className='font-semibold'>
                                                   {DELIVERY.length} {TRANSLATE.wd_delivery_zones}
                                                </label>
                                            </div>

                                        </div>

                                        {DELIVERY.length == 0 ? (
                                            <h1>No Delivery points</h1>
                                        ) : (

                                            <>

                                                {DELIVERY.map((item, index) => {

                                                    let delivery_type = item.type;
                                                    let delivery_icon = '';

                                                    if (delivery_type === 'airport') {
                                                        delivery_icon = "/assets/img/plane.svg"
                                                    }
                                                    else if (delivery_type === 'station') {
                                                        delivery_icon = "/assets/img/train-station.svg"
                                                    }
                                                    else if (delivery_type === 'port') {
                                                        delivery_icon = "/assets/img/port-icon.svg"
                                                    }
                                                    else if (delivery_type === 'city') {
                                                        delivery_icon = "/assets/img/city-icon.svg"
                                                    }
                                                    else {
                                                        delivery_icon = "/assets/img/location.svg"
                                                    }

                                                    return (
                                                        <div className="py-4 px-6 border border-zinc-200 rounded-lg relative my-3" key={index}>
                                                            <img className="absolute top-4 right-4 z-20 cursor-pointer" src="/assets/img/delete.svg" onClick={() => onDeleteDelivery(index)} />
                                                            {/* <div className="text-lg">Delivery option {(parseInt(index)+1)}</div> */}
                                                            <div className="flex gap-x-3">
                                                                <div className="input-group w-full mt-4 with-icon">
                                                                    <label className='capitalize'>{TRANSLATE.wd_location}<span className="text-bagreen-100">*</span></label>

                                                                    <img className="input-icon" src={delivery_icon} />
                                                                    <input className="focus:outline-bagreen-100" type="text" value={item.name} />
                                                                </div>
                                                                <div className="input-group w-full mt-4 max-w-[140px] md:max-w-[200px]">
                                                                    <label className='capitalize'>{TRANSLATE.wd_price} {shop_symbol}<span className="text-bagreen-100">*</span></label>
                                                                    <input className="focus:outline-bagreen-100 text-center" type="text" placeholder="10" value={item.cost}
                                                                        onChange={(e) => onEditDelivery(index, 'cost', e.target.value)}

                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })}


                                            </>
                                        )}


                                    </div>

                                    <div style={{ height: 200 }}></div> 
                                </div>
                            </>
                        )}


                    </div>

                    {!isLoading && !isSaving &&  (
                        <div className="d-flex align-items-center justify-content-between" style={{backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>
                            <div className="top_add_cart" style={{width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5}} onClick={() => onClose()}>
                                Back
                            </div>

                            {CAN_SAVE ? (
                                <div className="top_add_cart" style={{width: '50%', backgroundColor: '#26BFBF', marginLeft: 5}} onClick={()=> onSaveStoreDelivery()}>
                                    Save Changes
                                </div>
                            ) : (
                                <div className="top_add_cart cursor-not-allowed" style={{width: '50%', backgroundColor: 'grey', marginLeft: 5}}>
                                    Save Changes
                                </div>
                            )}
                            
                        </div>
                    )}



                </div>

            </div>


        </>
    )
}

const StoreMap = (props) => {

    const {store, geo, TRANSLATE, USER, LANGUAGE, type} = props;
  
    const [POINTS, setPoints] = useState([]);
  
    useEffect(()=> {
  
        const store_geocode = geo || {}
        const store_latitude = geo.latitude || 0;
        const store_longitude = geo.longitude || 0;
    
        const store_points = store.delivery || [];
        const store_filter = store_points.filter((item)=> item.geocode);
        const final_points = [...store_filter, {
          name: TRANSLATE.wd_provider_place,
          type: 'home',
          geocode: {
            latitude: store_latitude,
            longitude: store_longitude,
          },
          cost: 0
        }];

        // alert(JSON.stringify({
        //     store_geocode
        // }, null, 2))
  
        console.log("FINAL POINTS ===> ", final_points);
        setPoints(final_points);
  
    }, [store])
  
  
    return (
      
      <div style={{marginTop: 20}}>
        <ProviderMap PROVIDER={store} POINTS={POINTS} TRANSLATE={TRANSLATE} LANGUAGE={LANGUAGE} USER={USER} type={type} />
      </div>
  
    )
  
}

export default StoreDelivery