import React from "react"
import { Link } from "react-router-dom";
import { BriefcaseOutline, CartOutline, DownloadOutline, HomeOutline, LocationOutline, LogInOutline, LogOutOutline, ReaderOutline, SettingsOutline, StorefrontOutline, WalletOutline } from 'react-ionicons'

import NavSide from './NavSide';

const Sidebar = (props) => {

      const {TRANSLATE, match, LANGUAGE, history, isSidebar, onChangeLanguage, USER, SEARCH} = props;

      const CURRENT_URL = match.url;

      const isProvider = (CURRENT_URL.includes('/onboarding/provider')) || CURRENT_URL.includes('/dashboard');

      const BrandingColor = isProvider ? '#26BFBF' : '#fd6270';

      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

      const onSelectLanguage = (lang) => {

            //Setup URL
            const currentCity = match.params.city;
            const currentCountry = match.params.country;
            const currentCategory = match.params.category;

            const SearchURL = SEARCH ? SEARCH[lang+'_url'] : null;
            
            let ChangedURL = CURRENT_URL.replace('/'+LANGUAGE+'/', '/'+lang+'/');

            if(currentCity && currentCountry && SearchURL){
                 
                  if(currentCategory){
                        ChangedURL = SearchURL+'/'+currentCategory;
                  }
                  else{
                        
                        ChangedURL = SearchURL;
                  }
                    
            }

            console.log("LANGUAGE ===> ", LANGUAGE);
            console.log("LANG ===> ", lang);
            console.log("CURRENT ===> ", CURRENT_URL);
            console.log("CHANGED ===> ", ChangedURL);
            console.log("currentCity ===> ", currentCity);
            console.log("currentCountry ===> ", currentCountry);
            console.log("currentCategory ===> ", currentCategory);
            console.log("SearchURL ===> ", SearchURL);

            if(ChangedURL === CURRENT_URL){
                  onChangeLanguage(lang);
            }
            else{
                  history.push(ChangedURL);
            }
      }

      if(!isSidebar){
            return null
      } 

      return (
            <nav className={"side-nav "+(isSidebar ? 'active' : '')}  style={{overflow: 'auto', width: '100%', borderRadius: 0, padding: 20}}>
                  
                  <NavSide onSelectLanguage={onSelectLanguage} {...props} type="SideBar"/>
                  
                  <div className="nav-links" style={{marginTop: 100}}>
                        
                        <SidebarItem Icon={HomeOutline} label={TRANSLATE.noleggia}  BrandingColor={BrandingColor} to={'/'+LANGUAGE+'/search'}/>

                        {/* <SidebarItem Icon={LocationOutline} label={TRANSLATE.cerca_vicino}  BrandingColor={BrandingColor} to={'/'+LANGUAGE+'/search/nearby'}/> */}

                        <SidebarItem Icon={ReaderOutline} label={TRANSLATE.prenotazioni}  BrandingColor={BrandingColor} to={'/orders'}/>
                        
                        <SidebarItem Icon={WalletOutline} label={TRANSLATE.portafolio}  BrandingColor={BrandingColor} to={'/wallet'}/>

                        <SidebarItem Icon={CartOutline} label={TRANSLATE.cart}  BrandingColor={BrandingColor} to={('/'+LANGUAGE+'/cart')}/>
                        
                        {USER.store ? (
                             <SidebarItem Icon={StorefrontOutline} label={TRANSLATE.area_provider}  BrandingColor={BrandingColor} to={'/dashboard'}/>
                        ) : (
                              <SidebarItem Icon={StorefrontOutline} label={TRANSLATE.diventa_provider}  BrandingColor={BrandingColor} to={TRANSLATE.url_become_provider} />
                        )}
                        
                        <SidebarItem Icon={SettingsOutline} label={TRANSLATE.impostazioni}  BrandingColor={BrandingColor} to={'/settings'}/>

                        <SidebarItem Icon={DownloadOutline} label={'Download'}  BrandingColor={BrandingColor} to={'/'+LANGUAGE+'/download'}/>

                        {JWT ? (
                              <SidebarItem Icon={LogOutOutline} label={TRANSLATE.logout}  BrandingColor={BrandingColor} to={'/logout'}/>
                        ) : (
                              <SidebarItem Icon={LogInOutline} label={TRANSLATE.login}  BrandingColor={BrandingColor} to={'/login'}/>
                        )}

                  </div>
                              
            </nav>
      )
}

const SidebarItem = ({to, Icon, label, BrandingColor, off}) => {

      if(off){
            return (
                  <a href={off} target="_blank" rel="noopener noreferrer">
                        <Icon color={BrandingColor} style={{width: 30, height: 30}}/>
                        {label}
                  </a>
            )
      }
      return (
            <Link to={to}>
                  <Icon color={BrandingColor} style={{width: 30, height: 30}}/>
                  {label}
            </Link>
      )
}


export default Sidebar