import React, { useEffect, useState } from "react";

import { postApiPrivate } from "../../api";

import { CloseOutline } from "react-ionicons";

import { GetRouteSearchParams } from "../../helpers/GeneralHelper";

import Countries_EN from '../Search/countries_en.json';

const LocationFilter = (props) => {


    const { onClose, history, location, LANGUAGE, isMap } = props;

    const [Country, setCountry] = useState('all');
    const [City, setCity] = useState(null);

    const [Status, setStatus] = useState('all');

    //Extra
    const [isLoading, setLoading] = useState(false);
    const [SearchResults, setSearchResults] = useState([]);
    const [SearchInput, setSearchInput] = useState('');


    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);

        const routeCountry = routeParams.country || null;
        const routeCity = routeParams.city || null;
        const routeName = routeParams.name || null;
        const routeRange = routeParams.range || null;

        const routeStatus = routeParams.status || null;
        const routeApproval = routeParams.approval || null;

        const routeCategories = routeParams.categories || null;

        const routeLat = routeParams.lat || null;
        const routeLon = routeParams.lon || null;

        if (routeCountry) setCountry(routeCountry);
        if (routeCity && routeName) {
            setCity({
                id: routeCity,
                name: routeName,
                lat: routeLat,
                lon: routeLon
            })
            setSearchInput(routeName)
        }

        if (routeStatus) setStatus(routeStatus);


    }, [location.search]);

    const onApplyFilter = () => {

        let newQuery = '/admin/locations/0?sort=updatedAt&dir=desc';

        if (isMap) {
            newQuery = `/admin/map?search=cities&sort=updatedAt&dir=desc`
        }

        if (Country) newQuery = newQuery + '&country=' + Country;

        if (City) newQuery = newQuery + '&city=' + City.id + '&name=' + City.name + '&lat=' + City.lat + '&lon=' + City.lon;

        if (Status) newQuery = newQuery + '&status=' + Status;


        history.push(newQuery);

        onClose();
    }

    const onSearchCities = async (city) => {

        setSearchInput(city);

        if (!city || city.length <= 3) {
            setSearchResults([]);
            return
        }

        //Fetch

        setLoading(true);

        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/locations/search', { query: city }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if (filtredResults.length > 0) {
                setSearchResults(filtredResults);
            }

            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setSearchResults([]);
            setLoading(false);
        }


    }

    const onSelectCity = (city) => {
        //alert(JSON.stringify(city))
        setCity({
            id: city.id,
            name: city['en'],
            lat: city.geocode.latitude,
            lon: city.geocode.longitude
        });
        setCountry(city.country);
        setSearchInput(city['en']);
        setSearchResults([]);
    }

    const onSelectCountry = (country) => {
        setCountry(country);
        setCity(null);
        setSearchInput('');
        setSearchResults([]);
    }



    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Location Filter
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        <>
                            <div className="d-flex flex-column align-items-center justify-content-center pt-8 md:pb-0 pb-32">

                                <div className="w-full mt-8 flex flex-col text-black">

                                    {/* Store Country */}
                                    <div className="font-semibold mb-1 mt-4">Country</div>
                                    <select value={Country} onChange={(e) => onSelectCountry(e.target.value)} className="text-black">

                                        <option value="all">
                                            All Counties
                                        </option>
                                        {Countries_EN.map((item, index) => {
                                            return (
                                                <option key={index} value={item.value}>
                                                    {item.label}
                                                </option>
                                            )
                                        })}
                                    </select>

                                    {/* Store Country */}
                                    <div className="font-semibold mb-1 mt-4">City</div>
                                    <div className="flex">
                                        <input placeholder="Search by city" style={{ border: 'solid 1px #000000', color: '#000', width: City?.id ? '90%' : '100%', marginRight: City?.id ? 8 : 0 }}
                                            onChange={(e) => onSearchCities(e.target.value)}
                                            value={SearchInput}
                                            autoComplete="new-password"
                                        />
                                        {City?.id && (
                                            <div className="flex items-center justify-center bg-green-700 rounded-full text-white" style={{ width: 40, height: 40 }}>
                                                ok
                                            </div>
                                        )}
                                    </div>


                                    {SearchResults.length > 0 && (
                                        <div className="flex flex-col w-full">
                                            {SearchResults.map((item, index) => {

                                                const country_index = Countries_EN.findIndex((cty) => cty.value === item.country);
                                                const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
                                                const country_name = country_obx ? country_obx.label : null

                                                return (

                                                    <div className="w-full p-3 my-3 flex items-center cursor-pointer hover:bg-zinc-100" onClick={() => onSelectCity(item)} key={index}>
                                                        <img className='w-[20px] h-[20px] mr-3 mb-auto mt-1' src="/assets/img/location.svg" alt="" />
                                                        {item[LANGUAGE]} - ({country_name || item.country})
                                                    </div>
                                                )

                                            })}
                                        </div>
                                    )}

                                    {/* Store Status */}
                                    <div className="font-semibold mb-1 mt-4">Status</div>
                                    <select value={Status} onChange={(e) => setStatus(e.target.value)} className="text-black">
                                        <option value="all">
                                            All Locations
                                        </option>
                                        <option value="true">
                                            Only Active
                                        </option>
                                        <option value="false">
                                            Only No Active
                                        </option>
                                    </select>



                                </div>

                                <div style={{ height: 200 }}></div>
                            </div>
                        </>

                    </div>

                    <div className="d-flex align-items-center justify-content-between" style={{ backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>
                        <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5 }} onClick={() => onClose()}>
                            Back
                        </div>


                        <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#26BFBF', marginLeft: 5 }} onClick={() => onApplyFilter()}>
                            Apply Filters
                        </div>

                    </div>


                </div>

            </div>


        </>
    )
}

export default LocationFilter