import React, {useState} from "react";

import LoaderMaster from "../Loaders/Loaders";

import {postApiPrivate} from "../../api";

import { CloseSharp, SaveSharp } from "react-ionicons";

import swal from "sweetalert";

import {Chip, Container, Grid, TextField } from "@mui/material";

import { Link } from "react-router-dom";

import "../Profile/z_profile.css";

const AdminBrandsAdd = (props) => {

    const {TRANSLATE, history, match, location, LANGUAGE} = props;

    const [isSaving, setSaving] = useState(false);

    const [PUBLIC_PRODUCT, setBrand] = useState({});  

  
    const onUpdateBrand = async(key, value) => {

        setBrand({
          ...PUBLIC_PRODUCT,
          [key]: value
        });

    }

    const onSaveBrand = async() => {
        
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
            
        setSaving(true);

        try{

            const FORM = { 
            name: PUBLIC_PRODUCT.name,
            url: PUBLIC_PRODUCT.url,
            img: PUBLIC_PRODUCT.img,
            status: PUBLIC_PRODUCT.status
            };

            console.log("SEARCH ===> ", FORM);
        
            const DATA_RESPONSE = await postApiPrivate('/admin/brands/create', FORM, JWT);
            
            if(DATA_RESPONSE.error){
                setSaving(false);
                swal('Error', 'Cannot Create This Brand', 'error');
             
                console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
                return 
            }

            console.log("SEARCH ===> ", DATA_RESPONSE);

            setSaving(false);
            swal('Brand Created', 'This Brand has been Created successfully !!!', 'success');
          
            const brand_id = DATA_RESPONSE.id;
            history.push('/admin/brands/profile/'+brand_id);
        }
        catch(e){

            setSaving(false);
            swal('Error', 'Cannot Create This Brand', 'error');
        
        }

    }

    

    if(isSaving){

        return (
        <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
        )

    }


    const {

      name,
      url,
      img,
      status,

    } = PUBLIC_PRODUCT;
    
    const CAN_SAVE = (
        name && name.length>0 &&
        url && url.length>0 &&
        img && img.length>0
    )
  
    return(
    
        <Container maxWidth="xl" style={{background: '#f0f5f9'}}>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={4} md={6}>
                    <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                        <div className="top_store_card">
                            <div className="top_store_profile">

                                {img ? (
                                  <img className="profile_img" src={img} style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}}  />
                                ) : (
                                  <div className="profile_img" style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}}  />
                                )}
                              
                                <h3 className="store_title">{name}</h3>
                                
                            </div>
                           
                        </div> 

                    </div>
                </Grid>
                <Grid item xs={12} lg={5} md={6}>
                
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                          
                          <div className="top_box_magic" style={{marginBottom: 20}}>
                              <h1>
                                Status
                              </h1>

                              {status ? (
                                <Chip label="Published" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateBrand('status', false)}/>
                              ) : (
                                <Chip label="Unpublished" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning" onClick={(e)=> onUpdateBrand('status', true)} />
                              )}
                          </div>   

                          <div className="top_box_magic">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Name
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    value={name} 
                                    label={'.'} 
                                    onChange={(e)=> onUpdateBrand('name', e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    placeholder="Type here..."
                                
                                />
                              </div>
                          </div>


                          <div className="top_box_magic">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Link
            
                              </h1>
                              <div style={{margin: '10px 0px'}}>

                                <TextField 
                                    className={'gridyx-textfield'}
                                    value={url} 
                                    label={'.'} 
                                    onChange={(e)=> onUpdateBrand('url', e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    placeholder="Type here..."
                                
                                />

                              </div>
                          </div>

                          <div className="top_box_magic">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Logo 
            
                              </h1>
                              <div style={{margin: '10px 0px'}}>

                                <TextField 
                                    className={'gridyx-textfield'}
                                    value={img} 
                                    label={'.'} 
                                    onChange={(e)=> onUpdateBrand('img', e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    placeholder="Type here..."
                                
                                />

                              </div>
                          </div>

                          

                  
                  </div>
                  

                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                  

                      <div className="top_add_cart"  
                            style={{backgroundColor: '#59ad6a'}} 
                            onClick={()=> {

                                if(!CAN_SAVE){

                                    return swal("Incomplete", "Please complete all the fields in order to create this brand", 'warning')
                                }

                                swal({
                                    title: 'Confirm to Save',
                                    text: 'Please insert the CONFIRM to create this brand',
                                    content: "input",
                                })
                                .then((value) => {

                                if(value === 'CONFIRM'){

                                    onSaveBrand()
                                }
                                else{

                                    swal('Invalid Password', 'You are not authorized to create this brand', 'error');
                                }

                                });
                            }}
                            
                        >
                            <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Create Brand
                        </div>

                        <Link className="top_add_cart"  style={{backgroundColor: 'var(--dark)'}} to="/admin/brands">
                            <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Go Back
                        </Link>
                          

                  </div>
                </Grid>
              </Grid>            
            
        </Container>

      
    )


}


const Styles = {
  resultsRow: {
      display: 'flex', 
      alignItems: 'center', 
      color: '#000', 
      height: 40, 
      minHeight: 40,
      paddingLeft: 20, 
      border: '1px solid #00000040', 
      borderRight: 'none', 
      borderTop: 'none', 
      borderLeft: 'none',
      cursor: 'pointer',
      textTransform: 'capitalize',
      justifyContent: 'space-between'
  },
  resultsContainer: {
      display: 'flex', 
      flexDirection: 'column', 
      background: '#fff', 
      maxHeight: 280,
      position: 'relative', 
      top: 20, 
      border: '1px solid #00000040', 
      borderRadius: 10,
      overflow: 'auto',
      marginBottom: 20
  },
  right: {
      display: 'flex',
      alignItems: 'center'
  },
  searchContainer: {
      width: 300,
      height: 42,
      backgroundColor: '#F1F1F1',
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 5
  },
  input: {
      fontSize: 16,
      display: 'flex',
      color: '#000',
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      marginLeft: 5,
      textTransform: 'capitalize'
  },
  searchOption: {
      display: 'flex', 
      width: 40, 
      height: 35, 
      alignItems: 'center', 
      borderRadius: '50%', 
      background: '#fd6270'
  },
  button: {
        display: 'flex',
        height: '50px',
        width: '200px',
        backgroundColor: '#26BFBF',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',  
        borderRadius: 10,
        width: '100%'
    },
    img_full: {
      display: 'flex',
      width: 200, 
      height: 200, 
      borderRadius: 6, 
      marginRight: 15, 
      marginLeft: 15
    },
    img_empty: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%', 
      height: '100%', 
      borderRadius: 6, 
      marginRight: 15, 
      borderColor: '#ccc', 
      borderWidth: 2, 
      borderStyle: "dashed", 
      justifyContent: 'center', 
      alignItems: 'center',
      marginBottom: 10,
      
    },
    clickCard: {
        backgroundColor: "#fff",
        padding: 20,
        marginTop: 10,
    },
    clickLabel: {
        color: "#697883",
        fontFamily: "SourceSansPro-Bold", 
        fontSize: 14, 
        marginBottom: 10,
    },
    wrapFlex: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    publishBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fd6270',
      padding: 10,
      width: 90,
      color: '#fff',
      borderRadius: 10,
      cursor: 'pointer'
    }

}


export default AdminBrandsAdd

