import { CircleSharp } from '@mui/icons-material';
import React, { useState } from 'react';
import { Filter_Array } from '../../Constants/Constants';
import Dropdown from '../Dropdown/Dropdown';
import { FooterDashboard } from '../Footer';
import Header from '../Header';

const mockPayouts = [
  { year: 2024, month: 'April', status: 'waiting', amount: '€2.457.85' },
  { year: 2024, month: 'March', status: 'waiting', amount: '€2.457.85' },
  { year: 2024, month: 'February', status: 'waiting', amount: '€2.457.85' },
  { year: 2024, month: 'January', status: 'waiting', amount: '€2.457.85' },
  { year: 2023, month: 'April', status: 'waiting', amount: '€2.457.85' },
];

const ProviderAddNewProduct = (props) => {
  const [category, setCategory] = useState('');

  const categories = Filter_Array.map((item) => {
    return { title: item['en'], value: item.code, icon: item.icon };
  });

  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} isProvider={true} />
      <div className='flex flex-col mx-auto my-4 w-full max-w-xl px-4 sm:px-6 lg:px-8' style={{ flexGrow: 1 }}>
        <h2 className=' mt-4 mb-2 w-full text-center font-normal'>ADD NEW PRODUCT</h2>
        <div className='mb-4'>
          <h4 className='font-bold'>Tips</h4>
          <ul>
            <li className='flex gap-1'>
              <CircleSharp style={{ marginTop: '2px', width: '12px', color: '#26bfbf' }} /> More products more customers
            </li>
            <li className='flex  gap-1'>
              <CircleSharp style={{ marginTop: '2px', width: '12px', color: '#26bfbf' }} /> Our providers are local
              parents who offer their unused
            </li>
            <li className='flex gap-1'>
              <CircleSharp style={{ marginTop: '2px', width: '12px', color: '#26bfbf' }} /> They understand the
              challenges of parenting while traveling
            </li>
          </ul>
        </div>
        <Dropdown
          placeholder='Select Category'
          value={category}
          options={categories}
          onChange={(value) => setCategory(value)}
          isProvider={true}
          outlined
        />
      </div>

      <div style={{ height: '50px' }}></div>
      <FooterDashboard {...props} />
    </div>
  );
};

export default ProviderAddNewProduct;
