import React, { useEffect, useState } from 'react';

import Header from '../Header';

import { ReceiptLongOutlined } from '@mui/icons-material';
import { ArrowForwardCircleOutline, Checkmark, CheckmarkCircleOutline, TrashOutline } from 'react-ionicons';

import '../new_ux.css';
import './checkout.css';

const CustomerCheckout = (props) => {
  const [currentStep, setCurrentStep] = useState(3);
  const [date, setDate] = useState({
    from: null,
    to: null,
  });

  return (
    <div className='layout'>
      <Header {...props} showMenu={false} animatedBackground={false}></Header>

      <main className='flex-grow-1 w-full my-2 mx-auto max-w-xl px-4 sm:px-6 lg:px-8 text-xs md:text-base'>
        <div className='stepper'>
          <div className={`step ${currentStep >= 1 ? 'checked' : ''}`} onClick={() => setCurrentStep(1)}>
            <div className={`step-num`}>{currentStep > 1 ? <Checkmark color={'#fff'} /> : 1}</div>
          </div>
          <div className='flex align-items-center justify-content-start gap-2'>
            <div className='step-title'>Date</div>
          </div>
          <div style={{ flex: '1 1 0', height: 1, background: '#BDBDBD' }} />
          <div className={`step ${currentStep >= 2 ? 'checked' : ''}`}>
            <div className={`step-num`}>2</div>
          </div>
          <div className='flex align-items-center justify-content-start gap-2'>
            <div className='step-title'>Pickup Dropoff</div>
          </div>
          <div style={{ flex: '1 1 0', height: 1, background: '#BDBDBD' }} />
          <div className={`step ${currentStep === 3 ? 'checked' : ''}`}>
            <div className={`step-num`}>3</div>
          </div>
          <div className='flex align-items-center justify-content-start gap-2'>
            <div className='step-title'>Checkout</div>
          </div>
        </div>

        {/*TODO Step 3 is only if user not logged in */}
        {currentStep === 3 && <Step3 {...props} setCurrentStep={setCurrentStep} />}
      </main>
    </div>
  );
};

export default CustomerCheckout;

const Step3 = (props) => {
  const tabs = ['With Email', 'With Phone Number'];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [countryCode, setCountryCode] = useState('');
  const [phoneNum, setPhoneNum] = useState('');

  const [isUser, setUser] = useState(false);

  const [isActivationCode, setActivationCode] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);

  const [otp, setOtp] = useState(new Array(4).fill(''));

  const [isCart, setCart] = useState(true);

  const [USER, setUSER] = useState({
    id: 1,
    email: 'test@babonbo.com',
    countryCode: '+44',
  });

  useEffect(() => {
    let interval = null;
    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft <= 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeLeft]);

  const cart = {
    duration: '10-16 January - Total: 5 Days',
    cost_delivery: 0,
    cost_pickup: 0,
    cost_lastminute: 10,
    cost_free: 10,
    cost_insurance: 20,
    cost_total: 60.55,

    currency: 'eur',

    products: [
      {
        img: '/assets/img/hero-provider.jpg',
        name: 'Chicco Polly Highchair',
        price_day: 10.55,
        currency: 'eur',
        amount: 5,
      },
    ],
  };

  return <Cart cart={cart} user={USER} />;
};

const Cart = (props) => {
  const { user, cart } = props;

  const [isCheckout, setCheckout] = useState(false);
  const [isPayment, setPayment] = useState(false);
  const [isPaymentSuccess, setPaymentSuccess] = useState(true);
  const [offers, setOffers] = useState([
    {
      img: '/assets/img/hero-provider.jpg',
      name: 'Travel Insurance',
      price_day: 10,
      currency: 'eur',
    },
  ]);

  const [cardNum, setCardNum] = useState('');
  const [expDate, setExpDate] = useState('');
  const [ccv, setCCV] = useState('');

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneNum, setPhoneNum] = useState('');

  const [couponCode, setCouponCode] = useState('');
  const [isCouponCode, setIsCouponCode] = useState(false);

  const [isDownloadApp, setDownloadApp] = useState(false);

  const onChangeCardNum = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');

    value = value.match(/.{1,4}/g)?.join(' ') || '';

    setCardNum(value);
  };

  const onChangeExpDate = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');

    if (value.length >= 2) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }

    value = value.substring(0, 5);

    setExpDate(value);
  };

  const onChangeCCV = (e) => {
    let { value } = e.target;

    value = value.replace(/\D/g, '');
    value = value.substring(0, 4);
    setCCV(value);
  };

  const onPayment = (e) => {
    e.preventDefault();
    setCheckout(false);
    setPayment(true);
    setPaymentSuccess(true);
  };

  return (
    <div className='step-1'>
      <Progressbar />

      <div className='flex flex-col gap-2 w-full'>
        <div className='my-4 quicksand text-base'>Checkout</div>
        <div className='flex px-2 py-1 justify-content-between align-items-center w-full bg-light-gray rounded'>
          <div className='quicksand text-sm font-semibold'>
            {!isCheckout && cart.duration}
            {isCheckout && !isPayment && user.email ? user.email : user.phoneNum}
          </div>
        </div>
      </div>

      <div className='flex flex-col mt-4 gap-4 w-full'>
        <div className='flex flex-col gap-2 w-full'>
          {cart.products.map((item) => (
            <div className='flex gap-1 align-items-center justify-content-between w-full quicksand text-lg'>
              <div className='flex align-items-center gap-2 font-semibold quicksand'>
                <img src={item.img} alt='' className='' style={{ width: '60px', height: '60px' }} />
                <p>{item.name}</p>
              </div>
              <div className='flex gap-1 '>
                <div>{item.amount} x</div> <strong>{numberWithCommas(item.price_day, item.currency)}</strong>
              </div>
            </div>
          ))}
        </div>

        <div className='flex flex-col gap-2 w-full quicksand font-semibold'>
          <div className='flex align-items-center justify-content-between gap-1 w-full '>
            <div className='flex gap-2'>
              <CheckmarkCircleOutline />
              Delivery Fee
            </div>
            <div>{numberWithCommas(cart.cost_delivery, cart.currency)}</div>
          </div>

          <div className='flex align-items-center justify-content-between gap-1 w-full '>
            <div className='flex gap-2'>
              <CheckmarkCircleOutline /> Pickup Fee
            </div>
            <div>{numberWithCommas(cart.cost_pickup, cart.currency)}</div>
          </div>

          <div className='flex align-items-center justify-content-between gap-1 w-full '>
            <div className='flex gap-2'>
              <CheckmarkCircleOutline /> Last Minute Fee
            </div>
            <div>{numberWithCommas(cart.cost_lastminute, cart.currency)}</div>
          </div>

          <div className='flex align-items-center justify-content-between gap-1 w-full '>
            <div className='flex gap-2 align-items-center'>
              <CheckmarkCircleOutline /> <p>Travel Insurance</p>
              <button type='button' onClick={() => {}}>
                <TrashOutline />
              </button>
            </div>
            <div>{numberWithCommas(cart.cost_insurance, cart.currency)}</div>
          </div>
        </div>

        <div className='flex align-items-center justify-content-between gap-1 w-full text-xl font-bold'>
          <div className='flex gap-2 align-items-center'>
            <ReceiptLongOutlined /> <p>Total</p>
          </div>
          <div>{numberWithCommas(cart.cost_total, cart.currency)}</div>
        </div>
      </div>

      <div className='flex my-4 w-full rounded-lg overflow-hidden'>
        <div className='flex p-2 w-full justify-content-center bg-blue text-center text-white'>
          CREDIT CARD VIA STRIPE
        </div>
        <div className='flex p-2 w-full justify-content-center text-white bg-blue opacity-25'>PAYPAL</div>
      </div>

      <div className='flex flex-col gap-2 w-full'>
        <input type='text' value={cardNum} onChange={onChangeCardNum} placeholder='Card Number' maxLength='19' />

        <div className='flex gap-3'>
          <input
            type='text'
            value={expDate}
            onChange={onChangeExpDate}
            placeholder='Expiration Date (MM/YY)'
            maxLength='5'
          />

          <input className='w-full' type='text' value={ccv} onChange={onChangeCCV} placeholder='CVV' maxLength='4' />
        </div>
        <button
          type='button'
          className='flex align-items-center justify-content-center my-4 px-3 py-2 w-full rounded gap-3 text-white bg-blue'
          onClick={onPayment}
        >
          CHECKOUT
          <ArrowForwardCircleOutline width='24px' color='#fff' />
        </button>
      </div>
    </div>
  );
};

const Progressbar = () => {
  const totalDuration = 10 * 60;
  const [timeLeft, setTimeLeft] = useState(totalDuration);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  const displayTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}.${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const calculateProgress = () => {
    return (timeLeft / totalDuration) * 100;
  };

  return (
    <div className='w-full'>
      <div className='quicksand text-center'>Reservation Time:</div>
      <div className='my-3 quicksand text-3xl text-center font-bold'>{displayTime()}</div>

      <div className='w-full rounded-lg overflow-hidden' style={{ backgroundColor: '#ddd' }}>
        <div
          style={{
            height: '20px',
            backgroundColor: '#4CAF50',
            width: `${calculateProgress()}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

function numberWithCommas(x, CURRENCY) {
  if (!x) {
    x = 0;
  }

  if (!CURRENCY) {
    return x.toFixed(2);
  }

  if (isNaN(x)) return x;

  return x.toLocaleString('en-US', {
    style: 'currency',
    currency: CURRENCY,
  });
}
