// import React, { useState, useEffect } from 'react';
// import { numberWithCommas } from '../../helpers/GeneralHelper';

// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import swal from 'sweetalert';
// delete L.Icon.Default.prototype._getIconUrl;


// const ProviderMap = (props) => {

//   const { POINTS } = props;

//   const [selectedProvider, setSelectedProvider] = useState(null);

//   const mapped_delivery = POINTS.map((item, index) => {
//     return {
//       ...item,
//       latitude: item.geocode.latitude,
//       longitude: item.geocode.longitude
//     }
//   })

//   const MAP_CENTER = onTrimMapCenter(mapped_delivery);

//   return null

//   // return (
//   //   <>
//   //     {JSON.stringify({
//   //       MAP_CENTER
//   //     }, null, 2)}
//   //   </>
//   // )

//   if (!MAP_CENTER.lat) {
//     return null
//   }
  
//   return (
//     <MapContainer center={[MAP_CENTER.lat, MAP_CENTER.lng]} zoom={10} style={{ height: '350px', width: '100%', borderRadius: 20 }}>
//       <TileLayer
//         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//       />

//       {mapped_delivery.map(provider => {

//         const { name, geocode, type } = provider;

//         const original_cost = parseFloat(provider.cost) || 0

//         let final_cost = original_cost ? original_cost + (original_cost * 15 / 100) : original_cost;

//         let geo_cost = final_cost ? numberWithCommas(final_cost, 'eur') : 'FREE';

//         geo_cost = geo_cost.toUpperCase();

//         if (type === 'airport') {
//           return (
//             <Marker
//             key={provider.id}
//             position={[provider.latitude, provider.longitude]}
//             eventHandlers={{
//               click: (e) => {
//                 console.log('marker clicked', provider)
//               },
//             }}
//             icon={L.divIcon({
//               iconSize: [20, 20],
//               iconAnchor: [20 / 2, 20],
//               className: "mymarker",
//               html: '<img src="/assets/img/babonbo_marker_airport.png" style="width: 20px; height: 20px" />',
//             })}

//           />
           
//           )
//         }
//         else if (type === 'station') {
//           return (
//             <Marker
//             key={provider.id}
//             position={[provider.latitude, provider.longitude]}
//             eventHandlers={{
//               click: (e) => {
//                 console.log('marker clicked', provider)
//               },
//             }}
//             icon={L.divIcon({
//               iconSize: [20, 20],
//               iconAnchor: [20 / 2, 20],
//               className: "mymarker",
//               html: '<img src="/assets/img/babonbo_marker_station.png" style="width: 20px; height: 20px" />',
//             })}

//           />
            
//           )
//         }
//         else if (type === 'port') {
//           return (
//             <Marker
//               key={provider.id}
//               position={[provider.latitude, provider.longitude]}
//               eventHandlers={{
//                 click: (e) => {
//                   console.log('marker clicked', provider)
//                 },
//               }}
//               icon={L.divIcon({
//                 iconSize: [20, 20],
//                 iconAnchor: [20 / 2, 20],
//                 className: "mymarker",
//                 html: '<img src="/assets/img/babonbo_marker_port.png" style="width: 20px; height: 20px" />',
//               })}

//             />
           
//           )
//         }
//         else if (type === 'city') {
//           return (
//             <Marker
//                 key={provider.id}
//                 position={[provider.latitude, provider.longitude]}
//                 eventHandlers={{
//                   click: (e) => {
//                     console.log('marker clicked', provider)
//                   },
//                 }}
//                 icon={L.divIcon({
//                   iconSize: [20, 20],
//                   iconAnchor: [20 / 2, 20],
//                   className: "mymarker",
//                   html: '<img src="/assets/img/babonbo_marker.png" style="width: 20px; height: 20px" />',
//                 })}

//               />
//           )
//         }
        
        

//         return (
//           <Marker
//             key={provider.id}
//             position={[provider.latitude, provider.longitude]}
//             eventHandlers={{
//               click: (e) => {
//                 console.log('marker clicked', provider)
//               },
//             }}
//             icon={L.divIcon({
//               iconSize: [20, 20],
//               iconAnchor: [20 / 2, 20],
//               className: "mymarker",
//               html: '<img src="/assets/img/babonbo_marker_provider.png" style="width: 20px; height: 20px" />',
//             })}

//           />
//         )
//       })}


//     </MapContainer>
//   )



// }

// const onTrimMapCenter = (markers) => {
//   return {
//     lat: getMiddle('latitude', markers),
//     lng: getMiddle('longitude', markers)
//   }
// }

// const getMiddle = (prop, markers) => {
//   let values = markers.map(m => m[prop]);
//   let min = Math.min(...values);
//   let max = Math.max(...values);
//   if (prop === 'latitude' && (max - min > 180)) {
//     values = values.map(val => val < max - 180 ? val + 360 : val);
//     min = Math.min(...values);
//     max = Math.max(...values);
//   }
//   let result = (min + max) / 2;
//   if (prop === 'longitude' && result > 180) {
//     result -= 360
//   }
//   return result;
// }



// export default ProviderMap
import React from "react"
export default () => {
  return (
    <></>
  )
}