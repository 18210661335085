import React, {useState, useEffect} from "react";

import LoaderMaster from "../Loaders/Loaders";
import StarRating from "../General/Stars";

import {postApiPrivate} from "../../api";

import { AddCircleOutline, ArrowBack, CheckmarkCircleOutline, CheckmarkCircleSharp, CloseSharp, EllipseOutline, EyeSharp, ImageSharp, LockOpenSharp, MailSharp, NotificationsSharp, SaveSharp, TrashBinSharp } from "react-ionicons";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, InputAdornment, InputLabel, Select, TextareaAutosize, TextField } from "@mui/material";

import ReviewComponent from "../Reviews/ReviewComponent";

import { onFormatUrl, onGetCurrencySymbol } from "../../helpers/GeneralHelper";

import {Categories_Array, Ages_Array} from '../General/Constants';

import ReactFileReader from 'react-file-reader';

import { ImageCompressor, getImageSize } from "compressor-img";

import "../Profile/z_profile.css";

const AdminProductsDetails = (props) => {

  const {TRANSLATE, history, match, location, LANGUAGE} = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [isFocused, setFocused] = useState(false);
  const [BrandResults, setBrandResults] = useState([]);
  const [BrandInput, setBrandInput] = useState('');

  const [PUBLIC_PRODUCT, setProduct] = useState({});

  const [LangName, setLangName] = useState('en');

  const [isImageEditor, setImageEditor] = useState(false);

  const [isLoadingPhoto, setLoadingPhoto] = useState(false);

  //Based
  const [FIRST_BASED, setFirstBased] = useState(null);
  const [SECOND_BASED, setSecondBased] = useState(null);
  const [THIRD_BASED, setThirdBased] = useState(null);
  const [FOURTH_BASED, setFourthBased] = useState(null);

  //Camera + Picker
  const [SELECTED_INDEX, setSelectedIndex] = useState(null);
  const [isReplace, setReplace] = useState(false);
  

  useEffect(()=> {

      var product_id = match.params.id || '';

      onFetchPublic(product_id);

  }, []);

  const onFetchPublic = async(product_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    
    setEdited(false);
    setLoading(true);

    try{

      const FORM = { id: product_id, partner: match.params.pid};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/products/details', FORM, JWT);
        
      if(DATA_RESPONSE.error){

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      const ProductData = DATA_RESPONSE.p || {};
      const ProductMarca = ProductData.marca || '';

      if(ProductMarca){
        setBrandInput(ProductMarca);
      }
      setProduct(DATA_RESPONSE.p || {});
      setLoading(false);

    }
    catch(e){

        setLoading(false);
    }
      
  }
  
  const onUpdateProduct = async(key, value) => {

    setEdited(true);
    setProduct({
      ...PUBLIC_PRODUCT,
      [key]: value
    });

  }

  const onSaveProduct = async(product_id) => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        const FORM = { 
          id: product_id, 
          partner: match.params.pid,
          name: PUBLIC_PRODUCT.name,
          about: PUBLIC_PRODUCT.about,
          marca: PUBLIC_PRODUCT.marca,
          brand: PUBLIC_PRODUCT.brand,
          category: PUBLIC_PRODUCT.category,
          price_day: PUBLIC_PRODUCT.price_day,
          price_week: PUBLIC_PRODUCT.price_week,
          price_month: PUBLIC_PRODUCT.price_month,
          deposit: PUBLIC_PRODUCT.deposit,
          quantity: PUBLIC_PRODUCT.quantity,
          ages: PUBLIC_PRODUCT.ages,
          en: PUBLIC_PRODUCT.en,
          it: PUBLIC_PRODUCT.it,
          es: PUBLIC_PRODUCT.es,
          fr: PUBLIC_PRODUCT.fr,
          isPublished: true

        };

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/products/edit', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Update This Product', 'error');
            onFetchPublic(product_id);

            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("SEARCH ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('Product Updated', 'This Product has been updated successfully !!!', 'success');
        onFetchPublic(product_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Update This Product', 'error');
          onFetchPublic(product_id);
      }

  }

  const onDeleteProduct = async(product_id) => {
    
      // const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
      
      // setSaving(true);

      // try{

      //   const FORM = { id: product_id};

      //   console.log("SEARCH ===> ", FORM);
    
      //   const DATA_RESPONSE = await postApiPrivate('/admin/users/delete', FORM, JWT);
          
      //   if(DATA_RESPONSE.error){
      //       setSaving(false);
      //       swal('Error', 'Cannot Delete This Product', 'error');
      //       onFetchPublic(product_id);
      //       console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
      //       return 
      //   }

      //   setSaving(false);
      //   swal('Product Deleted', 'This Product has been deleted successfully !!!', 'success');
      //   history.replace('/admin/users');

      // }
      // catch(e){

      //   setSaving(false);
      //   swal('Error', 'Cannot Delete This Product', 'error');
      //   onFetchPublic(product_id);
      // }
  }
  
  const onSelectBrand = async(brand) => {

      console.log("BRAND SELECTED ===> ", brand);

      setFocused(false);

      setEdited(true);
      setProduct({
        ...PUBLIC_PRODUCT,
        marca: brand.name,
        brand: brand.id
      });

      setBrandInput(brand.name);

      swal({
        title: 'Brand Updated',
        text: 'You selected '+brand.name,
        icon: 'success',
        timer: 2000
      })
      
  }

  const onSearchBrands = async(query) => {

      setBrandInput(query);

      if(!query){
          setFocused(false);
          setBrandResults([]);

          return 
      }

      //Fetch

      try{

          const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

          const DATA_RESPONSE = await postApiPrivate('/brands/search', {query: query, lang: LANGUAGE} , JWT);
          
          if(DATA_RESPONSE.error){
              console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
              return 
          }

          console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

          const filtredResults = DATA_RESPONSE.data || [];

          if(filtredResults.length > 0){
              setFocused(true);
              setBrandResults(filtredResults);
          }

          
      }
      catch(e){
          
          console.log("CREATING ===> ", e);

          setFocused(false);
          setBrandResults([]);
  
      }
    
          
  }

  const onSearchKeyUp = async(event) => {
      event.preventDefault();

      if (event.keyCode === 13) {
          event.target.blur();

          if(BrandResults.length>0){
              onSelectBrand(BrandResults[0]);
          }
      }
  }

  const onSelectAge = (age) => {

    const product_ages = PUBLIC_PRODUCT.ages || [];

    if(product_ages.includes(age)){

      const filtred_ages = product_ages.filter((item)=> item !== age);
      onUpdateProduct('ages', filtred_ages);

    }
    else{
      onUpdateProduct('ages', [...product_ages, age]);
    }

  }

  const onUpdateTranslations = (type, lang, value) => {

      if(lang === 'en'){
          onUpdateProduct(type, value);
      }
      else{

        const target_translation = PUBLIC_PRODUCT[lang] || {};
        const new_translation = {
          ...target_translation,
          [type]: value
        }

        onUpdateProduct(lang, new_translation);

      }

  }

   //Photos
    const onPickerReplace = (index) => {
        console.log("PICKER IMAGE REPLACE SELECTED ===> ", index);
        //Check if Index has Previews Empty

        setSelectedIndex(index);
        setReplace(true);
        
        setTimeout(()=> {
          //ACTION_SHEET_CAMERA.current.show();
        }, 100)

    }

    const onPickerAdd = (index) => {
        console.log("PICKER IMAGE ADD SELECTED ===> ", index);
        //Check if Index has Previews Empty

        var corrected_index = CorrectedPickerIndex(index);

        console.log("PICKER IMAGE ADD SELECTED ===> ", corrected_index);

        setSelectedIndex(corrected_index);
        setReplace(false);
        
        setTimeout(()=> {
          //ACTION_SHEET_CAMERA.current.show();
        }, 100)
    
    }

    const onDeleteImage = () => {

          // if(SELECTED_INDEX == 0){
          //     setFirstImage(null);
          //     setFirstBased(null);
          // }
          // else if(SELECTED_INDEX == 1){
          //     setSecondImage(null);
          //     setSecondBased('empty');
          // }
          // else if(SELECTED_INDEX == 2){
          //     setThirdImage(null);
          //     setThirdBased('empty');
          // }
          // else if(SELECTED_INDEX == 3){
          //     setFourthImage(null);
          //     setFourthBased('empty');
          // }
         
    }

    const CorrectedPickerIndex = (index) => {
      
        const {first_img, second_img, third_img, fourth_img} = PUBLIC_PRODUCT;

        if(!first_img){
           return 0;
        }

        if(!second_img){
           return 1;
        }

        if(!third_img){
           return 2;
        }

        if(!fourth_img){
           return 3;
        }


        return index;
        
    }
   const onHandleUpload = (files) => {

        console.log("Files ==> ", files);

        const OriginalFile = files.fileList[0];

        let reader = new FileReader();
        reader.onloadend = () => {
            let imageCompressor = new ImageCompressor({
                onSuccess: response => {
                    
                    const compressedImg = response.compressed;
                
                    const originalSize = getImageSize(response.original);
                    const compressedSize = getImageSize(response.compressed);
                    
                    if(originalSize > 3000){
                        
                    }
                    else if(originalSize <= 3000 && originalSize > 250){
                        
                    }
                    else if(originalSize <= 250){
                        
                    }

                    console.log("COMPRESSED ===> ", compressedImg);
                    console.log("ORIGINAL SIZE ===> ", originalSize);
                    console.log("COMPRESSED SIZE ===> ", compressedSize);

                    if(SELECTED_INDEX == 0){
                       onUpdateProduct('first_img', compressedImg);
                       setFirstBased(compressedImg);
                    }
                    else if(SELECTED_INDEX == 1){
                       onUpdateProduct('second_img', compressedImg);
                       setSecondBased(compressedImg);
                    }
                    else if(SELECTED_INDEX == 2){
                       onUpdateProduct('third_img', compressedImg);
                       setThirdBased(compressedImg);
                    }
                    else if(SELECTED_INDEX == 3){
                       onUpdateProduct('fourth_img', compressedImg);
                       setFourthBased(compressedImg);
                    }

                },
                //scale: 100,
                quality: 50,
                originalImage: reader.result
            });
        };

        reader.readAsDataURL(OriginalFile);
    }



  if(isLoading){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading}/>
    )

  }

  if(isSaving){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
    )

  }


  const {
    id,
    name,
    about,
    category,

    price_day,
    price_week,
    price_month,

    deposit,
    quantity,

    first_img,
    second_img,
    third_img,
    fourth_img,
    
    sid,
    scurrency,
    simg,
    sname,

    updatedAt,
    createdAt,

    rating,
    reviews,
    accuracy,
    condition,
    cleanliness,

    isPublished,

    ages


  } = PUBLIC_PRODUCT;


  const Accuracy = accuracy || 0;
  const Condition = condition || 0;
  const Cleanliness = cleanliness || 0;

  const CurrencyX = onGetCurrencySymbol(scurrency);
  const Categories_Final = Categories_Array.filter((item, index)=> index != 0);

  let CurrentProductName = '';
  let CurrentProductAbout = '';

  if(LangName === 'en'){
    CurrentProductName = name;
    CurrentProductAbout = about;
  }
  else{
    const CurrentLang = PUBLIC_PRODUCT[LangName] || {};
    CurrentProductName = CurrentLang.name || '';
    CurrentProductAbout = CurrentLang.about || '';
  }


  if(isImageEditor){
     return(
   
         <Container maxWidth="xl" style={{background: '#f0f5f9'}}>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={4} md={6}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                      <div className="top_store_card">
                          <div className="top_store_profile">

                              {first_img ? (
                                <img className="profile_img" src={first_img} style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}} onClick={()=> setImageEditor(true)} />
                              ) : (
                                <div className="profile_img" style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}} onClick={()=> setImageEditor(true)} />
                              )}
                            
                              <h3 className="store_title">{name}</h3>
                              <StarRating rate={rating} reviews={reviews} />
                              
                          </div>
                          <div className="top_store_stats">
                              <div>
                                <h3>{CurrencyX}{parseInt(price_day) || 0}</h3>
                                <p>{TRANSLATE.day}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price_week) || 0}</h3>
                                <p>{TRANSLATE.week}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price_month) || 0}</h3>
                                <p>{TRANSLATE.month}</p>
                              </div>
                          </div>
                      </div> 


                      <div className="top_add_cart"  style={{backgroundColor: '#3d3d3d'}} onClick={()=> setImageEditor(false)}>
                        <ArrowBack color={'#fff'} style={{marginRight: 10}} /> Go Back
                      </div>
                        
                      
                  </div>
              </Grid>
              <Grid item xs={12} lg={8} md={6}>
              
                <div className="container-fluid top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                    <div className="row">
                      
                      <div className="col-12 mb-5">
                          <h3>Product Images</h3>
                          <p>You can upload up to 4 photos of this product</p>
                      </div>

                      <div className="col-sm-6 col-12">
                          {first_img ? (
                              <ProductImageFull img={first_img} index={0} onPickerReplace={onPickerReplace} isLoadingPhoto={isLoadingPhoto} SELECTED_INDEX={SELECTED_INDEX} onHandleUpload={onHandleUpload}/>
                          ) : (
                              <ProductImageEmpty index={0} onPickerAdd={onPickerAdd} isLoadingPhoto={isLoadingPhoto} SELECTED_INDEX={SELECTED_INDEX} TRANSLATE={TRANSLATE} onHandleUpload={onHandleUpload}/>
                          )}
                      </div>

                      <div className="col-sm-6 col-12">
                          {second_img ? (
                              <ProductImageFull img={second_img} index={1} onPickerReplace={onPickerReplace} isLoadingPhoto={isLoadingPhoto} SELECTED_INDEX={SELECTED_INDEX} onHandleUpload={onHandleUpload}/>
                          ) : (
                              <ProductImageEmpty index={1} onPickerAdd={onPickerAdd} isLoadingPhoto={isLoadingPhoto} SELECTED_INDEX={SELECTED_INDEX} TRANSLATE={TRANSLATE} onHandleUpload={onHandleUpload}/>
                          )}
                      </div>

                      <div className="col-sm-6 col-12">
                          {third_img ? (
                              <ProductImageFull img={third_img} index={2} onPickerReplace={onPickerReplace} isLoadingPhoto={isLoadingPhoto} SELECTED_INDEX={SELECTED_INDEX} onHandleUpload={onHandleUpload}/>
                          ) : (
                              <ProductImageEmpty index={2} onPickerAdd={onPickerAdd} isLoadingPhoto={isLoadingPhoto} SELECTED_INDEX={SELECTED_INDEX} TRANSLATE={TRANSLATE} onHandleUpload={onHandleUpload}/>
                          )}
                      </div>

                      <div className="col-sm-6 col-12">
                            {fourth_img ? (
                                <ProductImageFull img={fourth_img} index={3} onPickerReplace={onPickerReplace} isLoadingPhoto={isLoadingPhoto} SELECTED_INDEX={SELECTED_INDEX} onHandleUpload={onHandleUpload}/>
                            ) : (
                                <ProductImageEmpty index={3} onPickerAdd={onPickerAdd} isLoadingPhoto={isLoadingPhoto} SELECTED_INDEX={SELECTED_INDEX} TRANSLATE={TRANSLATE} onHandleUpload={onHandleUpload}/>
                            )}
                      </div>
                      


                    </div>
                
                </div>
                

              </Grid>
             
            </Grid>            
          
      </Container>

      
      )

  }
 
  return(
   
      <Container maxWidth="xl" style={{background: '#f0f5f9'}}>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={4} md={6}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                      <div className="top_store_card">
                          <div className="top_store_profile">

                              {first_img ? (
                                <img className="profile_img" src={first_img} style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}} onClick={()=> setImageEditor(true)} />
                              ) : (
                                <div className="profile_img" style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}} onClick={()=> setImageEditor(true)} />
                              )}
                            
                              <h3 className="store_title">{name}</h3>
                              <StarRating rate={rating} reviews={reviews} />
                              {/* <p style={{color: '#000', margin: 0}}>{city}</p> */}
                              
                          </div>
                          <div className="top_store_stats">
                              <div>
                                <h3>{CurrencyX}{parseInt(price_day) || 0}</h3>
                                <p>{TRANSLATE.day}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price_week) || 0}</h3>
                                <p>{TRANSLATE.week}</p>
                              </div>
                              <div>
                                <h3>{CurrencyX}{parseInt(price_month) || 0}</h3>
                                <p>{TRANSLATE.month}</p>
                              </div>
                          </div>
                      </div> 

                      {!isEdited && (
                          <>
                            <a className="top_add_cart"  style={{backgroundColor: 'black'}} href={'https://www.babonbo.com/'+LANGUAGE+'/'+onFormatUrl(name)+'/bp/'+id} target="_blank">
                              <EyeSharp color={'#fff'} style={{marginRight: 10}} /> Open Product
                            </a>

                            <div className="top_add_cart"  style={{backgroundColor: 'var(--yellow)'}} onClick={()=> setImageEditor(true)}>
                              <ImageSharp color={'#fff'} style={{marginRight: 10}} /> Edit Images
                            </div>

                            <div className="top_add_cart" 
                                 style={{backgroundColor: '#e64942'}} 
                                 onClick={()=> {
                                    swal({
                                        title: 'Confirm to Delete',
                                        text: 'Please insert CONFIRM to delete this product',
                                        content: "input",
                                    })
                                    .then((value) => {

                                      if(value === 'CONFIRM'){

                                          onDeleteProduct(id)
                                      }
                                      else{

                                          swal('Invalid Password', 'You are not authorized to delete this product', 'error');
                                      }

                                    });
                                 }}
                            >
                              <TrashBinSharp color={'#fff'} style={{marginRight: 10}} /> Delete Product 
                            </div>
                          </>
                      )}
                     
                      {isEdited && (
                        <> 

                          <div className="top_add_cart"  
                               style={{backgroundColor: '#59ad6a'}} 
                               onClick={()=> {
                                swal({
                                    title: 'Confirm to Save',
                                    text: 'Please insert the CONFIRM to update this product',
                                    content: "input",
                                })
                                .then((value) => {

                                  if(value === 'CONFIRM'){

                                      onSaveProduct(id)
                                  }
                                  else{

                                      swal('Invalid Password', 'You are not authorized to update this product', 'error');
                                  }

                                });
                             }}
                               
                          >
                            <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Update Profile
                          </div>

                          <div className="top_add_cart"  style={{backgroundColor: '#ccc'}} onClick={()=> onFetchPublic(id)}>
                            <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Cancel Changes
                          </div>
                        
                        </>
                      )}
              
                      
                  </div>
              </Grid>
              <Grid item xs={12} lg={5} md={6}>
              
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                        <div className="top_box_magic" style={{marginBottom: 20}}>
                            <h1>
                              Status
                            </h1>

                            {isPublished ? (
                               <Chip label="Published" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateProduct('isPublished', false)}/>
                            ) : (
                               <Chip label="Unpublished" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning" onClick={(e)=> onUpdateProduct('isPublished', true)} />
                            )}
                        </div>   

                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Name

                              <div>
                                <img src="/assets/languages/en.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5)}} onClick={()=> setLangName('en')}/>
                                <img src="/assets/languages/it.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5)}} onClick={()=> setLangName('it')}/>
                                <img src="/assets/languages/fr.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5)}} onClick={()=> setLangName('fr')}/>
                                <img src="/assets/languages/es.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5)}} onClick={()=> setLangName('es')}/>
                              </div>

                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={CurrentProductName} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateTranslations('name', LangName, e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  placeholder="Type here..."
                              
                              />
                            </div>
                        </div>


                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Description

                              
                            </h1>
                            <div style={{margin: '10px 0px'}}>

                              <TextareaAutosize
                                className={'gridyx-textarea'}
                                minRows={4}
                                maxRows={10}
                                placeholder="Type here..."
                                value={CurrentProductAbout}
                                onChange={(e)=> onUpdateTranslations('about', LangName, e.target.value)}
                                
                              />

                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Category
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <select 
                                  className={'gridyx-select'}
                                  value={category} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateProduct('category', e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  select
                              >
                                {Categories_Final.map((item, index)=> {
                                    const Cat_Name = item[LANGUAGE];
                                    const Cat_Code = item.code;
          
                                    let NewURL = match.url;
                                    if(index != 0){
                                      NewURL = NewURL+'?filter='+Cat_Code
                                    }
                                  return (
                                    <option value={Cat_Code} key={index}>
                                        {Cat_Name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Brand
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={BrandInput} 
                                  label={'.'} 
                                  // onChange={(e)=> onUpdateProduct('marca', e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  onFocus={()=> setFocused(true)}  
                                  onChange={(e)=> onSearchBrands(e.target.value)} 
                                  onKeyUp={(e)=> onSearchKeyUp(e)}
                                 
                              
                              />

                               {(isFocused && BrandResults.length > 0) && (
                                    <div style={{...Styles.resultsContainer}}>
                                        {BrandResults.map((item, index)=> {

                                            return (
                                                <div style={Styles.resultsRow} 
                                                        key={index} 
                                                        onClick={()=> onSelectBrand(item)}
                                                >

                                                      {item.name}
                                                      
                                                      <CheckmarkCircleOutline size={28} color="#ccc" style={{marginRight: 10}} />
                                                      
                        
                                                </div>
                                            )
                                            
                                        })}
                                    </div>
                                )}
                            </div>

                           

                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Price per day
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={price_day}
                                  onChange={(e)=> onUpdateProduct('price_day', e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                  }}
                                  type="number"
                                  helperText={price_day <= 0 ? "Invalid price day. Don't use decimals" : ''}
                                  error={price_day <= 0}
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Price per week
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={price_week}
                                  onChange={(e)=> onUpdateProduct('price_week', e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                  }}
                                  type="number"
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Price per month
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={price_month}
                                  onChange={(e)=> onUpdateProduct('price_month', e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                  }}
                                  type="number"
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Quantity
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={quantity}
                                  onChange={(e)=> onUpdateProduct('quantity', e.target.value)}
                                  type="number"
                                  helperText={quantity <= 0 ? "Invalid price day. Don't use decimals" : ''}
                                  error={quantity <= 0}
                              
                              />
                            </div>
                        </div>
                        
                        <div className="top_box_magic">
                            <h1>
                              Deposit
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={deposit}
                                  onChange={(e)=> onUpdateProduct('deposit', e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                  }}
                                  type="number"
                                  helperText={deposit <= 0 ? "Invalid price day. Don't use decimals" : ''}
                                  error={deposit <= 0}
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Age
                            </h1>
                            <div className="d-flex flex-wrap" style={{margin: '10px 0px'}}>
                              
                            
                                {Ages_Array.map((item, index)=> {

                                  return (
                                    <div className="d-flex align-items-center justify-content-between" key={index} style={{borderBottom: '1px solid #00000045', padding: 20, cursor: 'pointer', width: '50%'}} onClick={()=> onSelectAge(item.code)}>
                                      
                                      <div className="d-flex flex-column" style={{fontWeight: 600}}>
                                        {item[LANGUAGE]}
                                        <div style={{fontWeight: 300}}>{item.min}-{item.max} cm</div>
                                      </div>
                                
                                      {(ages && ages.includes(item.code)) ? (
                                        <CheckmarkCircleSharp size={30} color="green" style={{marginRight: 10}}  />
                                      ) : (
                                        <CheckmarkCircleOutline size={30} color="#ccc" style={{marginRight: 10}} />
                                      )}
                                      
                                                      
                                    </div>
                                  )
                                })}
    
                            </div>
                        </div>

                
                </div>
                

              </Grid>
              <Grid item xs={12} lg={3} md={3}>
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                    
                    <div className="top_box_magic">
                        <h1>
                          Product Created
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <p>
                            {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                          </p> 
                        </div>
                    </div>   
                    <div className="top_box_magic">
                        <h1>
                          Product Updated
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <p>
                            {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                          </p> 
                        </div>
                    </div>  

                    {sid && (
                      <div className="top_box_magic">
                          <h1>
                          Store
                          </h1>

                          <Chip
                              avatar={<Avatar alt={sname} src={simg} />}
                              label={sname.toLowerCase()}
                              variant="outlined"
                              size='medium'
                              style={{textTransform: 'capitalize', marginTop: 10, width: 'fit-content'}}
                              onClick={()=> history.push('/partners/'+match.params.pid+'/stores/profile/'+sid)}
                          />
                          
                      </div>
                    )}


                    <ReviewComponent title="Accuracy" 
                                      value={Accuracy}
                                      ReadOnly={true}
                    
                    />
                    <ReviewComponent title="Condition" 
                                      value={Condition}
                                      ReadOnly={true}
                    
                    />
                    <ReviewComponent title="Cleanliness" 
                                    value={Cleanliness}
                                    ReadOnly={true}
                    
                    />
                  
                </div>
              </Grid>
            </Grid>            
          
      </Container>

    
  )


}


const ProductImageFull = ({index, onPickerReplace, img, onHandleUpload}) => {
    return (
        <ReactFileReader fileTypes={[".jpg",".jpeg",".png",".gif"]} base64={true} multipleFiles={false} handleFiles={onHandleUpload} >
            <div style={Styles.img_empty} onClick={()=>onPickerReplace(index)}>
                <img style={{objectFit: 'contain', width: '100%', height: 350, background: '#ccc'}} src={img} />
            </div>
        </ReactFileReader>
      
    )
}

const ProductImageEmpty = ({index, onPickerAdd, TRANSLATE, onHandleUpload}) => {
  return (
    <ReactFileReader fileTypes={[".jpg",".jpeg",".png",".gif"]} base64={true} multipleFiles={false} handleFiles={onHandleUpload} >
        <div style={{...Styles.img_empty, padding: 60, height: 350}} onClick={()=>onPickerAdd(index)}>
            <AddCircleOutline color={'#ccc'} style={{width: 30, height: 30}} />
            <h1 style={{color: '#ccc', fontSize: 12}}>
                {TRANSLATE.product_add_photo}
            </h1>
        </div>
    </ReactFileReader>
    
  )
}


const Styles = {
  resultsRow: {
      display: 'flex', 
      alignItems: 'center', 
      color: '#000', 
      height: 40, 
      minHeight: 40,
      paddingLeft: 20, 
      border: '1px solid #00000040', 
      borderRight: 'none', 
      borderTop: 'none', 
      borderLeft: 'none',
      cursor: 'pointer',
      textTransform: 'capitalize',
      justifyContent: 'space-between'
  },
  resultsContainer: {
      display: 'flex', 
      flexDirection: 'column', 
      background: '#fff', 
      maxHeight: 280,
      position: 'relative', 
      top: 20, 
      border: '1px solid #00000040', 
      borderRadius: 10,
      overflow: 'auto',
      marginBottom: 20
  },
  right: {
      display: 'flex',
      alignItems: 'center'
  },
  searchContainer: {
      width: 300,
      height: 42,
      backgroundColor: '#F1F1F1',
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 5
  },
  input: {
      fontSize: 16,
      display: 'flex',
      color: '#000',
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      marginLeft: 5,
      textTransform: 'capitalize'
  },
  searchOption: {
      display: 'flex', 
      width: 40, 
      height: 35, 
      alignItems: 'center', 
      borderRadius: '50%', 
      background: '#fd6270'
  },
  button: {
        display: 'flex',
        height: '50px',
        width: '200px',
        backgroundColor: '#26BFBF',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',  
        borderRadius: 10,
        width: '100%'
    },
    img_full: {
      display: 'flex',
      width: 200, 
      height: 200, 
      borderRadius: 6, 
      marginRight: 15, 
      marginLeft: 15
    },
    img_empty: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%', 
      height: '100%', 
      borderRadius: 6, 
      marginRight: 15, 
      borderColor: '#ccc', 
      borderWidth: 2, 
      borderStyle: "dashed", 
      justifyContent: 'center', 
      alignItems: 'center',
      marginBottom: 10,
      
    },
    clickCard: {
        backgroundColor: "#fff",
        padding: 20,
        marginTop: 10,
    },
    clickLabel: {
        color: "#697883",
        fontFamily: "SourceSansPro-Bold", 
        fontSize: 14, 
        marginBottom: 10,
    },
    wrapFlex: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    publishBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fd6270',
      padding: 10,
      width: 90,
      color: '#fff',
      borderRadius: 10,
      cursor: 'pointer'
    }

}


export default AdminProductsDetails

