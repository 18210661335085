import React, { useState } from 'react';
import { CaretDown } from 'react-ionicons';
import { Calendar } from 'react-modern-calendar-datepicker';
import OutsideClickHandler from 'react-outside-click-handler';
import './datepicker.css';

const CustomDatePicker = ({ value, label, disabled, onChange, icon, fullsize }) => {
  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className='relative' id='date-picker-section' role='group' aria-labelledby='date-picker-label'>
        <div
          className='date-container'
          onClick={() => setVisible(true)}
          aria-controls='calendar-popup'
          aria-expanded={visible}
        >
          {label && <label id='date-picker-label'>{label}</label>}

          <div className='date-box' aria-label='Selected Date Range'>
            <p>{value.from - value.to}</p>
            {icon ? (
              icon
            ) : (
              <CaretDown width={'20px'} height={'20px'} color={'rgba(0, 0, 0, 0.54)'} aria-hidden='true' />
            )}
          </div>
        </div>

        {visible && (
          <div
            className='absolute top-10 w-full'
            id='calendar-popup'
            style={{ minWidth: '300px' }}
            role='dialog'
            aria-modal='true'
          >
            <Calendar
              value={null}
              onChange={onChange}
              colorPrimary='#27BFBF'
              colorPrimaryLight='#E9F9F9'
              calendarClassName='provider-calendar w-full max-w-[320px]'
              aria-label='Calendar'
            />
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default CustomDatePicker;
