

import "../Admin/z_map.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { Icon, divIcon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import { StorefrontOutline } from "react-ionicons";
import { GetRouteSearchParams } from "../Admin/AdminHelper";


const SearchMap = (props) => {

    const { POINTS, mapRef, location } = props;


    let markers_providers = POINTS.map((item) => {
        return {
            ...item,
            coordinates: [item.latitude, item.longitude],
            type: 'provider'
        };
    });


    const customIcon = new Icon({
        iconUrl: "https://www.babonbo.com/assets/img/babonbo_marker.png",
        iconSize: [38, 38],
    });

    const providerIcon = new Icon({
        iconUrl: "https://www.babonbo.com/assets/img/babonbo_marker_provider.png",
        iconSize: [38, 38],
    });


    const createCustomClusterIcon = (cluster) => {
        return new divIcon({
            html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
            className: "custom-marker-cluster",
            iconSize: [33, 33],
        });
    };

    // Calculate the average coordinates to set the center of the map
    const calculateAverageCoordinates = () => {

        let markers = markers_providers;

        if (markers.length === 0) {
            return [33.33, 33.33]; // Default center if no markers
        }

        const totalLatitude = markers.reduce((acc, marker) => acc + marker.coordinates[0], 0);
        const totalLongitude = markers.reduce((acc, marker) => acc + marker.coordinates[1], 0);

        const averageLatitude = totalLatitude / markers.length;
        const averageLongitude = totalLongitude / markers.length;

        return [averageLatitude, averageLongitude];
    };

    const mapCenter = calculateAverageCoordinates();

    useEffect(() => {

        let routeParams = GetRouteSearchParams(location.search);
        let routeCountry = routeParams.country || '';
        let routeCity = routeParams.city || '';

        if ((mapRef.current && markers_providers.length > 0)) {

            if ((routeCountry && routeCountry !== 'all') || routeCity) {
                mapRef.current.setView(mapCenter, 6);
            }
            else {
                mapRef.current.setView(mapCenter, 3);
            }

        }
    }, [markers_providers, mapCenter]);


  
    return (

        <MapContainer center={mapCenter} zoom={5} ref={mapRef} className="w-full h-full rounded-lg shadow-md" scrollWheelZoom={true}>
            <TileLayer
                attribution="Google Maps"
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"

                minZoom={0}
                maxZoom={20}
            />
            <MarkerClusterGroup
                chunkedLoading
                iconCreateFunction={createCustomClusterIcon}
            >

                {markers_providers.map((marker) => {
                    let finalIcon = customIcon;

                    if (marker.type === 'provider') {
                        finalIcon = providerIcon;
                    }


                    return (
                        <Marker position={marker.coordinates} icon={finalIcon} key={marker.id}>
                            <Popup>
                                <div className="w-full flex flex-col items-center justify-center">

                                    <div className="w-fll flex flex-col items-center p-2">

                                        <div className="flex items-center gap-x-2 mb-2">
                                            <div className='flex'>
                                                <img src={marker.img} className="rounded-full shrink-0 object-cover w-[80px] h-[80px] border border-zinc-200" />
                                            </div>

                                            {marker.status ? (
                                                <div className='bg-green-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
                                            ) : (
                                                <div className='bg-red-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
                                            )}
                                        </div>


                                        <div className="text-md font-bold rounded-xl flex pb-0 uppercase text-center">
                                            {marker.name}
                                        </div>

                                        <div className="flex items-center gap-x-2 mb-4">
                                            <div className='text-xs text-black font-normal capitalize'>
                                                {marker.city}
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-2">
                                            {parseFloat(marker.rating) > 0 && (
                                                <div className="flex text-xs items-center">
                                                    {parseFloat(marker.rating).toFixed(1)}
                                                    <img src={'/assets/img/star.svg'} className="ml-1 w-[10px] h-[10px]" alt="" />
                                                </div>
                                            )}
                                            <div className="w-1 h-1 rounded-full bg-gray-500" />
                                            {parseFloat(marker.reviews) > 0 && (
                                                <div className="flex text-xs">
                                                    {parseInt(marker.reviews)} reviews
                                                </div>
                                            )}
                                        </div>

                                        <div className="flex items-center gap-x-2">
                                            <div className='text-xs text-black font-normal capitalize'>
                                                orders: {parseInt(marker.total)}
                                            </div>
                                            <div className="w-1 h-1 rounded-full bg-gray-500" />
                                            <div className='text-xs text-black font-normal capitalize'>
                                                score: {parseInt(marker.score)}
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-2 mb-4">
                                            <div className='text-xs text-black font-normal capitalize'>
                                                {marker.products_count} products
                                            </div>
                                            <div className="w-1 h-1 rounded-full bg-gray-500" />
                                            <div className='text-xs text-black font-normal capitalize'>
                                                {marker.delivery.length} delivery points
                                            </div>
                                        </div>

                                        <div className="bg-babonbo-100 text-white rounded-lg flex items-center justify-center p-1 px-2 mt-2 cursor-pointer" onClick={() => null}>
                                            <StorefrontOutline color={'#fff'} className="mr-2" /> Visit Store
                                        </div>

                                    </div>

                                </div>


                            </Popup>
                        </Marker>
                    )
                })}

            

            </MarkerClusterGroup>

        </MapContainer >

    );
}

export default SearchMap;