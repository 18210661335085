import React from "react";
import { numberWithCommas, onFormatPrices } from "../../helpers/GeneralHelper";

const ProductModal = (props) => {

    const {CURRENCY, product, provider, onAddToCart, onClose, duration, onCalculateDailyPrice, USER, TRANSLATE, isOther } = props;

    if (!product) {
        return <></>
    }
            
    const price_day = product.price_day || 0;
    const category = product.category;

    const PRICE_CURRENCY = CURRENCY || 'eur';
    const PRICE_DAILY_FINAL = onCalculateDailyPrice(product, duration)*duration;

     //Params
     const PriceObx = onFormatPrices({
        price_day: price_day,
        price_day_final: PRICE_DAILY_FINAL,
        duration: duration,
        category: category,
        quantity: 1
    });

    const price_final = numberWithCommas(PriceObx.PriceDayFee, PRICE_CURRENCY);
    const price_discount = PriceObx.PriceDiscount > 0;

    const total_days = numberWithCommas(PriceObx.PriceNoDiscount, PRICE_CURRENCY);
    const total_days_discount = numberWithCommas(PriceObx.PriceDiscount, PRICE_CURRENCY);
    const total_days_total = numberWithCommas(PriceObx.PriceTotalFee, PRICE_CURRENCY);


    const onAddProduct = () => {
        
        onClose()
        onAddToCart(product, provider, isOther)
    }


    return (
        <>
            <div className="fixed w-screen h-full md:h-screen top-0 left-0 z-[9999] flex items-end md:items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]">
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />

                <div className="mx-auto w-full max-w-[700px] overflow-auto md:max-h-[calc(100vh-100px)] max-h-[calc(100%-50px)] md:flex px-4 sm:px-6 lg:px-8 pb-0 md:pb-10 pt-10 bg-white rounded-2xl relative z-20 fade-in md:modal-in scrollbar-hide">
                    <img className="absolute top-6 right-6 cursor-pointer" src="/assets/img/close.svg" onClick={() => onClose()} />
                    <div className="flex md:flex-nowrap flex-wrap items-start">
                        <img className="w-full md:w-[300px] h-auto" src={product.first_img} />
                        <div className="w-full md:pl-10 flex flex-col h-full">
                            <div className="overflow-auto">
                                <h2>{product.name}</h2>
                                <div className="text-sm my-3">
                                    <span className="font-semibold capitalize">{TRANSLATE.wd_brand}: </span>
                                    {product.marca}
                                </div>

                                {category === 'consumable' ? (
                                    <>

                                        <h2 className="mb-10">
                                            {total_days_total}
                                            <span className='font-normal text-xs opacity-40 ml-1'> 
                                                / {TRANSLATE.wd_unit}
                                            </span>
                                        </h2>
                                        
                                    </>
                                ) : (

                                    <>

                                        {price_discount ? (
                                            <h2 className="mb-10">
                                                {total_days_total}
                                                <span className='font-normal text-xs text-red-600 opacity-70 ml-1' style={{textDecoration: 'line-through'}}> 
                                                    ({total_days})
                                                </span>
                                                <span className='font-normal text-xs opacity-40 ml-1'> 
                                                    /{duration} {TRANSLATE.wd_days}
                                                </span>
                                            </h2>
                                        ) : (
                                            <h2 className="mb-10">
                                                {total_days_total}
                                                <span className='font-normal text-xs opacity-40 ml-1'> 
                                                    /{duration} {TRANSLATE.wd_days}
                                                </span>
                                            </h2>
                                        )}
                                    
                                    
                                    </>
                                    
                                )}
                                
                                <p>{product.about}</p>
                            </div>
                            <div className="md:relative fixed left-0 bottom-0 bg-white w-full py-2 md:border-0 border-t border-t-zinc-100 px-4">
                                <button className="btn-full py-3 md:mt-6 mt-0" onClick={()=> onAddProduct()}>
                                    <img src="/assets/img/cart.svg" className="mr-2" style={{ filter: 'invert(100%)'}} />
                                    {TRANSLATE.btn_add_to_cart}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div style={{height: 200}}></div>

                </div>

            </div>


        </>
    )
}




export default ProductModal