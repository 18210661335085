import React from 'react'


const Product = (props) => {
    
    const { img, name, provider, price, addStyles, onClick, isTotal, duration, showHeart, price_discount, total_days_total, total_days, category, TRANSLATE } = props;
    
    return (
        <>
            <div className={'flex rounded-lg bb-transition-100 flex-col flex-wrap cursor-pointer items-start justify-start md:p-6 p-2 outline-zinc-200 hover:outline hover:-outline-offset-1 hover:outline-2 group relative ' +addStyles} onClick={()=> onClick()}>
                <div className='w-full relative mb-2'>
                    <div className='pt-[100%]'></div>
                    <img className='absolute w-full h-full object-cover rounded-xl top-0 left-0' src={img} />
                </div>
                <div className='text-base font-semibold mb-2'>{name}</div>
                <div className='flex flex-wrap justify-between font-semibold items-center w-full mt-auto'>
                    <div className='text-xs text-zinc-400 w-full font-normal'>{provider}</div>
                    {isTotal ? (
                        <>
                            {category === 'consumable' ? (
                                <div>
                                    {total_days_total}
                                    <span className='font-normal text-xs opacity-40 ml-1'> 
                                        /{TRANSLATE.wd_unit}
                                    </span>
                                </div>
                            ) : (
                                <>
                                    {price_discount ? (
                                        <div className='flex items-end'>
                                            <div className='flex flex-col'>
                                                <span className='font-normal mt-2 text-xs text-red-600 opacity-70' style={{textDecoration: 'line-through'}}> 
                                                    ({total_days})
                                                </span>
                                                {total_days_total}
                                            </div>
                                            <span className='font-normal text-xs opacity-40 ml-1 mb-[3px]'> 
                                                /{duration} {TRANSLATE.wd_days}
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            {total_days_total}
                                            <span className='font-normal text-xs opacity-40 ml-1'> 
                                                /{duration} {TRANSLATE.wd_days}
                                            </span>
                                        </div>
                                    )}
                                </>
                            )}
                            
                        </>
                        
                    ) : (
                        <>
                            {category === 'consumable' ? (
                                <div>{price}<span className='font-normal text-xs'>/{TRANSLATE.wd_unit}</span></div>
                            ) : (
                                <div>{price}<span className='font-normal text-xs'>/{TRANSLATE.wd_day}</span></div>
                            )}
                        </>
                        
                    )}
                    
                    {/* <span className='text-xs font-normal'>Rented 10 times</span> */}
                </div>

                {showHeart && (
                     <img className='absolute top-1 right-1 z-10 w-[22px] h-[22px] opacity-0 group-hover:opacity-100' 
                          src='/assets/img/heart.svg' 
                     />
                )}
               
            </div>
        </>
    )

}

export default Product