import React, {useState, useEffect} from "react";

import LoaderMaster from "../Loaders/Loaders";
import StarRating from "../General/Stars";

import {postApiPrivate} from "../../api";

import ReactCountryFlag from "react-country-flag";

import moment from 'moment';
import swal from "sweetalert";

import { Container, Grid } from "@mui/material";

import ReviewComponent from "../Reviews/ReviewComponent";


import "../Profile/z_profile.css";

const AdminCustomersDetails = (props) => {

  const {TRANSLATE, history, match, location} = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [PUBLIC_USER, setUser] = useState({});
  

  useEffect(()=> {

      var user_id = match.params.id || '';

      onFetchPublic(user_id);

  }, []);

  const onFetchPublic = async(user_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    
    setEdited(false);
    setLoading(true);

    try{

      const FORM = { id: user_id, partner: match.params.pid};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/users/details', FORM, JWT);
        
      if(DATA_RESPONSE.error){

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setUser(DATA_RESPONSE || {});
      setLoading(false);

    }
    catch(e){

        setLoading(false);
    }
      
  }
  
  const onUpdateUser = async(key, value) => {

    setEdited(true);
    setUser({
      ...PUBLIC_USER,
      [key]: value
    });

  }

  const onSaveUser = async(user_id) => {
      
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        const FORM = { id: user_id};

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/users/edit', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Update This User', 'error');
            onFetchPublic(user_id);

            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("SEARCH ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('User Updated', 'This User has been updated successfully !!!', 'success');
        onFetchPublic(user_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Update This User', 'error');
          onFetchPublic(user_id);
      }

  }

  const onDeleteUser = async(user_id) => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
      
      setSaving(true);

      try{

        const FORM = { id: user_id};

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/users/delete', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Delete This User', 'error');
            onFetchPublic(user_id);
            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        setSaving(false);
        swal('User Deleted', 'This User has been deleted successfully !!!', 'success');
        history.replace('/admin/users');

      }
      catch(e){

        setSaving(false);
        swal('Error', 'Cannot Delete This User', 'error');
        onFetchPublic(user_id);
      }
  }
  
  const onSavePassword = async(user_id, new_password) => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
    setSaving(true);

    try{

      const FORM = {id: user_id, np: new_password.trim()};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/users/password', FORM, JWT);
        
      if(DATA_RESPONSE.error){
          setSaving(false);
          swal('Error', 'Cannot Update the password for this User', 'error');
          onFetchPublic(user_id);

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('User Updated', 'This User\'s Password has been updated successfully !!!', 'success');
      onFetchPublic(user_id);

    }
    catch(e){

        setSaving(false);
        swal('Error', 'Cannot Update the password for this User', 'error');
        onFetchPublic(user_id);
    }
      
  }
  
  const onSendPush = async(user_id, content) => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
    setSaving(true);

    try{

      const FORM = {id: user_id, content: content.trim()};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/users/push', FORM, JWT);
        
      if(DATA_RESPONSE.error){
          setSaving(false);
          swal('Error', 'Cannot send the push notification to this User', 'error');
          onFetchPublic(user_id);

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Push Sent', 'This push notification has been sent successfully to this User !!!', 'success');
      onFetchPublic(user_id);

    }
    catch(e){

        setSaving(false);
        swal('Error', 'Cannot Update the password for this User', 'error');
        onFetchPublic(user_id);
    }
      
  }

  const onSelectCopy = (copy) => {
      swal('Copied to Clipboard !!!', copy, 'success')
  }

  if(isLoading){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading}/>
    )

  }

  if(isSaving){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
    )

  }


  const {
    id,
    name,
    img,
    updatedAt,
    createdAt,
    city,
    rating,
    lang,
    email,
    phone,
    about,
    currency,
    isAdmin,
    isTester,
    setup,
    store,
    shop,
    insegna,
    description,
    communication,
    use,
    punctuation,
    reviews,
    devices,
    country,
    search,
    followers,
    following
  } = PUBLIC_USER;

  const NAMES = getSplittedNames(name) || {};
  const FIRST_NAME = NAMES.first_name ? NAMES.first_name.toUpperCase() : name;
  const REFFERAL_CODE = FIRST_NAME.slice(0, 6) + id?.toUpperCase();

  const Communication = communication || 0;
  const Punctuation = punctuation || 0;
  const Use = use || 0;

 
  return(
   
      <Container maxWidth="lg" background={'#f0f5f9'}>

            <Grid container spacing={2}>

              <Grid item xs={12} lg={4} md={6}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                      <div className="top_store_card">
                          <div className="top_store_profile">

                              {img ? (
                                <img className="profile_img" src={img} />
                              ) : (
                                <div className="profile_img"  />
                              )}
                            
                              <h3 className="store_title">{name}</h3>
                              <StarRating rate={rating} reviews={reviews} />
                              {/* <p style={{color: '#000', margin: 0}}>{city}</p> */}
                              
                          </div>
                          {/* <div className="top_store_stats">
                              <div>
                                <h3>{reviews || 0}</h3>
                                <p>{TRANSLATE.reviews}</p>
                              </div>
                              <div>
                                <h3>{followers || 0}</h3>
                                <p>{TRANSLATE.followers}</p>
                              </div>
                              <div>
                                <h3>{following || 0}</h3>
                                <p>{TRANSLATE.following}</p>
                              </div>
                          </div> */}
                      </div> 

  
                      
                  </div>
              </Grid>

              <Grid item xs={12} lg={5} md={6}>
              
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                        <div className="top_box_magic">
                            <h1>
                              {TRANSLATE.email}
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <p>
                                {email}
                              </p> 
                            </div>
                        </div>

                        {phone && (
                          <div className="top_box_magic">
                              <h1>
                                Phone
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <p>
                                  +{phone.replace('+', '')}
                                </p> 
                              </div>
                          </div>
                        )}


                        <div className="top_box_magic">
                            <h1>
                            Currency
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <p>
                                {currency === 'usd' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'us'} style={{marginRight: 5}} /> $ - USD
                                  </>
                                )}
                                {currency === 'eur' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'eu'} style={{marginRight: 5}} /> € - EUR
                                  </>
                                )}
                                {currency === 'gbp' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'gb'} style={{marginRight: 5}} /> £ - GBP
                                  </>
                                )}
                              </p> 
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                            Language
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <p>
                                {lang === 'en' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'gb'} style={{marginRight: 5}} /> English
                                  </>
                                )}
                                {lang === 'it' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'it'} style={{marginRight: 5}} /> Italian
                                  </>
                                )}
                                {lang === 'fr' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'fr'} style={{marginRight: 5}} /> French
                                  </>
                                )}
                                {lang === 'es' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'es'} style={{marginRight: 5}} /> Spanish
                                  </>
                                )}
                              
                              </p> 
                            </div>
                        </div>

                </div>
              
              </Grid>

              <Grid item xs={12} lg={3} md={3}>
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                      
                    <div className="top_box_magic">
                        <h1>
                          Last Online
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <p>
                            {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                          </p> 
                        </div>
                    </div>  

                    <div style={{marginTop: 0}}/>


                    <ReviewComponent title="Communication" 
                                      value={Communication}
                                      ReadOnly={true}
                    
                    />
                    <ReviewComponent title="Punctuation" 
                                      value={Punctuation}
                                      ReadOnly={true}
                    
                    />
                    <ReviewComponent title="Use of Gear" 
                                    value={Use}
                                    ReadOnly={true}
                    
                    />
                  
                </div>
              </Grid>

            </Grid>            
          
      </Container>

    
  )


}


const getSplittedNames = (full_name) => {
    
  const full_name_array = full_name ? full_name.split(' ') : [];
  const first_name = full_name_array[0];
  const last_name = full_name_array[full_name_array.length - 1];

  return {
      first_name,
      last_name
  }
}


export default AdminCustomersDetails
