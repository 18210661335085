import React, { useState, useEffect } from "react";

import LoaderMaster from "../Loaders/Loaders";

import { postApiPrivate } from "../../api";

import { CloseSharp, PencilSharp, SaveSharp, TrashBinSharp } from "react-ionicons";

import moment from 'moment';
import swal from "sweetalert";

import { Chip, Grid, TextField } from "@mui/material";

import { MegadraftEditor, editorStateFromRaw, editorStateToJSON } from "megadraft"

import image from "../Plugins/mega_imagepicker/plugin"
//import video from "../Plugins/mega_videopicker/plugin";

import TagsInput from 'react-tagsinput'
import PickerImage from "../Modals/PickerImage";

import 'megadraft/dist/css/megadraft.css'
import 'react-tagsinput/react-tagsinput.css'
import "../Profile/z_profile.css";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";



const AdminPagesAdd = (props) => {

  const { TRANSLATE, history, match } = props;

  const newDate = moment().format('DD-MM-YYYY');

  const [isImage, setImage] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const [TITLE, setTitle] = useState('');
  const [CATEGORY, setCategory] = useState('module_1');
  const [BANNER, setBanner] = useState('');
  const [TAGS, setTags] = useState([]);
  const [LANGUAGE, setLanguage] = useState('en');
  const [BODY, setBody] = useState(editorStateFromRaw(null));
  const [STATUS, setStatus] = useState(false);
  const [DATE, setDate] = useState(newDate)

  useEffect(() => {

    if (isImage) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isImage])

  const keyBindings = [
    {
      name: "save",
      isKeyBound: e => e.keyCode === 83 && e.ctrlKey,
      action: () => console.log("save")
    }
  ];

  const onAction = (args) => {
    console.log("onAction fired with args:", args);
  }


  const onSavePage = async () => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    if (!TITLE) {
      return swal('Title Missing', 'You need to insert a title in order to post this page', 'warning')
    }

    if (!CATEGORY) {
      return swal('Category Missing', 'You need to insert a category in order to post this page', 'warning')
    }

    if (!BANNER) {
      return swal('Banner Missing', 'You need to insert a banner in order to post this page', 'warning')
    }

    const newMarkup = draftToHtml(convertToRaw(BODY.getCurrentContent()));
    const newJSON = editorStateToJSON(BODY);
    const newHTML = newMarkup.trim();
    const newText = newHTML.replace(/<[^>]*>?/gm, '');


    try {

      const FORM = {

        title: TITLE,
        category: CATEGORY,
        banner: BANNER,
        tags: TAGS,
        language: LANGUAGE,
        date: DATE,
        status: STATUS ? 'published' : 'unpublished',
        bodyx: newJSON,
        bodyz: newText

      };

      // alert("SEARCH ===> " + JSON.stringify(FORM, null, 2));

      // return

      setSaving(true);

      const DATA_RESPONSE = await postApiPrivate('/admin/pages/create', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update This Blog', 'error');

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Resource Updated', 'This Resource has been updated successfully !!!', 'success');

      history.push('/admin/resources/profile/' + DATA_RESPONSE.id)
    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This Blog', 'error');

    }

  }

  const ResourcesModules = [
    {
      en: 'Module 1: Welcome & Tour',
      it: 'Modulo 1: Tour di Benvenuto',
      fr: 'Module 1: Tour de Bienvenue',
      es: 'Módulo 1: Tour de Bienvenida',
      code: 'module_1',
      icon: '👋'
    },
    {
      en: 'Module 2: The Rules & Policies',
      it: 'Modulo 2: Regole e politiche',
      fr: 'Module 2: Les règles et politiques',
      es: 'Módulo 2: Las reglas y políticas',
      code: 'module_2',
      icon: '📚'
    },
    {
      en: 'Module 3: The Baby Gear',
      it: 'Modulo 3: L\'attrezzatura per bambini',
      fr: 'Module 3: L\'équipement pour bébé',
      es: 'Módulo 3: El equipo para bebés',
      code: 'module_3',
      icon: '🧸'
    },
    {
      en: 'Module 4: Managing Rentals',
      it: 'Modulo 4: Gestione di noleggi',
      fr: 'Module 4: Gestion des locations',
      es: 'Módulo 4: Gestión de alquileres',
      code: 'module_4',
      icon: ''
    },
    {
      en: 'Module 5: Consumables',
      it: 'Modulo 5: Materiali di consumo',
      fr: 'Module 5: Consommables',
      es: 'Módulo 5: Consumibles',
      code: 'module_5',
      icon: '🍼'
    },
    {
      en: 'Module 6: Delivery & Pick-up',
      it: 'Modulo 6: Consegna e ritiro',
      fr: 'Module 6: Livraison et ramassage',
      es: 'Módulo 6: Entrega y Recogida',
      code: 'module_6',
      icon: '🚚'
    },
    {
      en: 'Module 7: Cleaning',
      it: 'Modulo 7: Pulizia',
      fr: 'Module 7: Nettoyage',
      es: 'Módulo 7: Limpieza',
      code: 'module_7',
      icon: '🧼'
    },
    {
      en: 'Module 8: Talking to Customers',
      it: 'Modulo 8: Parlare con i clienti',
      fr: 'Module 8: Parler aux clients',
      es: 'Módulo 8: Hablar con los clientes',
      code: 'module_8',
      icon: '🗣️'
    }

  ]


  if (isSaving) {

    return (
      <LoaderMaster type="secondary" />
    )

  }

  //https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg

  if (isEditing) {

    return (
      <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10">

        <Grid container spacing={2}>

          <Grid item xs={12} lg={9} md={9}>
            <div className="w-full bg-white">

              <MegadraftEditor
                plugins={[image]}
                editorState={BODY}
                placeholder="Text"
                onChange={setBody}
                keyBindings={keyBindings}
                resetStyleNewLine={true}
                maxSidebarButtons={null}
                onAction={onAction}
                movableBlocks={true}

              />
            </div>

          </Grid>

          <Grid item xs={12} lg={3} md={3}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>

              <div className="top_add_cart" style={{ backgroundColor: '#59ad6a' }} onClick={() => setEditing(false)}>
                <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Save Content
              </div>

            </div>

          </Grid>

        </Grid>



      </div>
    )

  }


  return (

    <>
      {isImage && (
        <PickerImage {...props}
          onClose={() => setImage(false)}
          onSelectImage={setBanner}
          TYPE={'resources'}

        />
      )}
      <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10">

        <Grid container spacing={2}>

          <Grid item xs={12} lg={9} md={9}>

            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

              <div className="top_box_magic" style={{ marginBottom: 20 }} onClick={() => setImage(true)}>
                {BANNER ? (
                  <img className="profile_img" src={BANNER} style={{ borderRadius: 20, height: 300, width: '100%', objectFit: 'cover', margin: 0 }} />
                ) : (
                  <div className="profile_img" style={{ borderRadius: 20, height: 300, width: '100%', objectFit: 'cover', margin: 0 }} />
                )}
              </div>

              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Title
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    value={TITLE}
                    label={'.'}
                    onChange={(e) => setTitle(e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    placeholder="Type here..."

                  />
                </div>
              </div>



              <div className="top_box_magic">
                <h1>
                  Tags
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TagsInput value={TAGS || []} onChange={setTags} />
                </div>
              </div>

              {/* <div className="top_box_magic" onClick={() => setEditing(true)}>
                <h1 className="d-flex align-items-center justify-content-between">
                  Body
                </h1>
                <div style={{ margin: '10px 0px', border: 'solid 1px #00000050', whiteSpace: 'break-spaces' }}>

                  {JSON.stringify(BODY, null, 2)}


                </div>
              </div> */}

            </div>


          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>

              <div className="top_box_magic" style={{ marginBottom: 20 }}>
                <h1>
                  Status
                </h1>

                {STATUS ? (
                  <Chip label="Published" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="success" onClick={() => setStatus(false)} />
                ) : (
                  <Chip label="Unpublished" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="warning" onClick={() => setStatus(true)} />
                )}
              </div>

              <div className="top_box_magic">
                <h1>
                  Published At
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    value={DATE}
                    label={'.'}
                    onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    placeholder="DD-MM-YYYY"

                  />
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Module
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <select
                    className={'gridyx-select'}
                    value={CATEGORY}
                    label={'.'}
                    onChange={(e) => setCategory(e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    select
                  >
                    {ResourcesModules.map((item)=> {
                      return (
                        <option value={item.code}>
                          {item.en}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Language
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <select
                    className={'gridyx-select'}
                    value={LANGUAGE}
                    label={'.'}
                    onChange={(e) => setLanguage(e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    select
                  >
                    <option value={'en'}>
                      English
                    </option>
                    <option value={'fr'}>
                      French
                    </option>
                    <option value={'it'}>
                      Italian
                    </option>
                    <option value={'es'}>
                      Spanish
                    </option>


                  </select>
                </div>
              </div>



              <div className="top_add_cart"
                style={{ backgroundColor: 'grey', marginTop: 50 }}
                onClick={() => setEditing(true)}
              >
                <PencilSharp color={'#fff'} style={{ marginRight: 10 }} /> Edit Content
              </div>

              <div className="top_add_cart"
                style={{ backgroundColor: '#59ad6a', marginTop: 10 }}
                onClick={() => onSavePage()}
              >
                <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Save Page
              </div>


            </div>
          </Grid>
        </Grid>

      </div>
    </>

  )


}



export default AdminPagesAdd

