import React, { useEffect, useState } from 'react';
import { CartSharp, ChatbubbleSharp, ChevronDownOutline, MenuOutline, PersonSharp } from 'react-ionicons';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Header = (props) => {
  const {
    TRANSLATE,
    children,
    animatedBackground = true,
    showMenu = true,
    background = 'bg-light-gray',
    isProvider = false,
  } = props;

  return (
    <header className={`header-wrapper ${background}`}>
      {animatedBackground && <RotatingCircles />}

      <StaticCircles />

      {!showMenu && (
        <div className='justify-content-between w-full mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
          <div className='flex align-items-center justify-content-center m-auto py-4 md:py-8'>
            <Link to='/'>
              <img
                src={isProvider ? '/assets/img/babonbo_logo_provider.png' : '/assets/img/babonbo_logo.png'}
                className='header-logo'
              />
            </Link>
          </div>
        </div>
      )}

      {showMenu && (
        <>
          <div className='justify-content-between w-full mx-auto max-w-8xl header-menu-main  px-4 sm:px-6 lg:px-8'>
            <div className='flex align-items-center py-4 md:py-8'>
              <Link to='/' style={{ zIndex: 1 }}>
                <img
                  src={isProvider ? '/assets/img/babonbo_logo_provider.png' : '/assets/img/babonbo_logo.png'}
                  className='header-logo'
                />
              </Link>
              <menu className='menu-primary'>
                <li className='menu-primary-item'>
                  <Link to='/how-it-works'>How it works?</Link>
                </li>
                <li className='menu-primary-item'>
                  <Link to='/provider-area'>Provider Area</Link>
                </li>
                <li className='menu-primary-item'>
                  <Link to='/blog'>Blog</Link>
                </li>
              </menu>
            </div>
            <div className='flex align-items-center py-4 md:py-8'>
              <div className='menu-secondary-wrapper'>
                <menu className='menu-secondary'>
                  <li>
                    <button type='button' onClick={() => {}} className='menu-secondary-item menu-secondary-divider'>
                      English/Euro
                      <ChevronDownOutline width='12px' color='#8c8c8c' />
                    </button>
                  </li>
                  <li>
                    <Link
                      to='/chat'
                      className='menu-secondary-item menu-secondary-divider'
                      style={{ color: '#fc6170' }}
                    >
                      Chat
                      <ChatbubbleSharp width='14px' color='#fc6170' />
                    </Link>
                  </li>
                  <li>
                    <Link to='/cart' className='menu-secondary-item menu-secondary-divider'>
                      Basket
                      <CartSharp width='18px' color='#8c8c8c' />
                    </Link>
                  </li>
                  <li>
                    <Link to='/profile' className='menu-secondary-item'>
                      Profile
                      <PersonSharp width='16px' color='#8c8c8c' />
                    </Link>
                  </li>
                </menu>
              </div>
            </div>
          </div>
          <div className='justify-content-between w-full mx-auto p-6 header-menu-mobile'>
            <Link to='/'>
              <img
                src={isProvider ? '/assets/img/babonbo_logo_provider.png' : '/assets/img/babonbo_logo.png'}
                className='header-logo'
              />
            </Link>

            <Sidebar isProvider={isProvider} />
          </div>
        </>
      )}

      {children}
    </header>
  );
};

export default Header;

const Sidebar = (props) => {
  const { isProvider } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <>
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          type='button'
          class='relative flex lg:hidden items-center justify-center w-[36px] h-[36px] bg-white rounded-full cursor-pointer'
        >
          <MenuOutline width='24px' height='24px' color={isProvider ? '#26bfbf' : '#FC6170'} />
        </button>
      )}

      {isOpen && (
        <div className='fixed w-screen h-screen top-0 left-0 z-10 flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]'>
          <div className='absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]' />
          <div className='w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col slider-in overflow-auto'>
            <div className='flex justify-between p-8 border-b border-zinc-200 sticky top-0 bg-white z-20'>
              <div>
                <img
                  className='w-[150px] mr-4'
                  src={isProvider ? '/assets/img/babonbo_logo_provider.png' : '/assets/img/babonbo_logo.png'}
                  alt='Babonbo logo'
                />
              </div>
              <img
                className='cursor-pointer'
                src='/assets/img/close.svg'
                alt='Close'
                onClick={() => setIsOpen(false)}
              />
            </div>
            <div className='flex flex-col w-full px-5 py-8'>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/orders.svg' alt='My orders' />
                My orders
              </div>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/favorites.svg' alt='Favorites' />
                Favorites
              </div>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/chat.svg' alt='Chat' />
                Chat
              </div>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/cart.svg' alt='Cart' />
                Cart
              </div>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/notifications.svg' alt='Notifications' />
                Notifications
              </div>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/reviews.svg' alt='Reviews' />
                Reviews
              </div>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/wallet.svg' alt='Wallet' />
                Wallet
              </div>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/settings.svg' alt='Settings' />
                Settings
              </div>
              <div className='flex items-center md:hidden w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer'>
                <img className='mr-3 w-[28px]' src='/assets/img/sidebar/language.svg' alt='Language toggle' />
                ENG
                <div className='h-[14px] w-px mx-2 bg-zinc-200'></div>€
              </div>
              <hr className='my-6' />
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3 w-[25px] h-[25px]' src='/assets/img/sidebar/gift.png' alt='Gift' />
                <div className='flex flex-col'>
                  <div className='text-babonbo-100' style={{ fontWeight: 'bold' }}>
                    Refer a friend
                  </div>
                  <div className='text-xs text-black' style={{ fontWeight: 'bold' }}>
                    Earn 10€ for every friend you refer
                  </div>
                </div>
              </div>
              <hr className='my-6' />
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/support.svg' alt='Support' />
                Contact support
              </div>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/provider.svg' alt='Provider' />
                Become a provider
              </div>
              <div className='flex items-center w-full hover:bg-babonbo-50 px-6 py-2 mb-2 rounded-full cursor-pointer capitalize'>
                <img className='mr-3' src='/assets/img/sidebar/logout.svg' alt='Login' />
                Login
              </div>
              <div style={{ height: '200px' }}></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const RotatingCircles = () => {
  return (
    <div className='absolute top-0 w-screen h-full overflow-hidden'>
      <div className='container-rotate md:hidden'>
        <div className='circle' id='circle1'>
          <img src='/assets/img/colored-icons/baby-food.svg' alt='Rotating Image' />
          <img src='/assets/img/colored-icons/cribs.svg' alt='Rotating Image' />
          <img src='/assets/img/colored-icons/diapers.svg' alt='Rotating Image' />
        </div>
        <div className='circle' id='circle2'>
          <img src='/assets/img/colored-icons/car-seats.svg' alt='Rotating Image' />
          <img src='/assets/img/colored-icons/filters.svg' alt='Rotating Image' />
          <img src='/assets/img/colored-icons/highchairs.svg' alt='Rotating Image' />
        </div>
        <div className='circle' id='circle3'>
          <img src='/assets/img/colored-icons/carriers.svg' alt='Rotating Image' />
          <img src='/assets/img/colored-icons/outerwear.svg' alt='Rotating Image' />
          <img src='/assets/img/colored-icons/stroller.svg' alt='Rotating Image' />
          <img src='/assets/img/colored-icons/toys.svg' alt='Rotating Image' />
          <img src='/assets/img/colored-icons/wellness.svg' alt='Rotating Image' />
        </div>
      </div>
    </div>
  );
};

const StaticCircles = () => {
  return (
    <div className='hidden md:flex absolute top-0 w-screen h-full overflow-hidden' style={{ borderRadius: '50px' }}>
      <div className='container-static-circles circle-group-1'>
        <div className='static-circle' id='static-circle1'></div>
        <div className='static-circle' id='static-circle2'></div>
        <div className='static-circle' id='static-circle3'></div>
      </div>

      <div className='container-static-circles circle-group-2'>
        <div className='static-circle' id='static-circle1'></div>
        <div className='static-circle' id='static-circle2'></div>
        <div className='static-circle' id='static-circle3'></div>
      </div>
    </div>
  );
};
