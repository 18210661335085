import React, { useState, useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';

import Header from './Header';

import LoaderMaster from '../Loaders/Loaders';

import { connect } from 'react-redux';
import { onUpdateLanguage } from '../../actions';


import AdminDashboard from './AdminDashboard';
import AdminProducts from './AdminProducts';
import AdminStores from './AdminStores';
import AdminOrders from './AdminOrders';
import AdminCalendary from './AdminCalendary';
import AdminMap from './AdminMap';
import AdminInbox from './AdminInbox';
import AdminReviews from './AdminReviews';

import AdminCustomersDetails from './AdminCustomersDetails';
import AdminStoresDetails from './AdminStoresDetails';
import AdminProductsDetails from './AdminProductsDetails';
import AdminOrdersDetails from './AdminOrdersDetails';
import AdminInboxDetails from './AdminInboxDetails';
import AdminReviewsDetails from './AdminReviewsDetails';

import './z_pagination.css';
import './z_admin.css';


const AdminMaster = (props) => {

  const { USER, onChangeLanguage, LANGUAGE, TRANSLATE, match } = props;
  const [isSidebar, setSidebar] = useState(false);
  const [isActiveMenu, setActiveMenu] = useState(false);

  const [HeaderLogo, setHeaderLogo] = useState('/assets/img/babonbo_logo_provider.png');

  useEffect(() => {
    setSidebar(false);
    setActiveMenu(false);
  }, [props.match.url]);

  useEffect(() => {

    onChangeLanguage('en');

  }, []);

  const sharedProps = {
    ...props,
    isSidebar,
    setSidebar,
    isActiveMenu,
    setActiveMenu,
    isAdmin: true
  };

  const sharedPage = {
    TRANSLATE,
    USER,
    LANGUAGE,
    setActiveMenu,
    onChangeLanguage,
    HeaderLogo,
    setHeaderLogo
  }

  if (!USER.partner && !USER.admin) {
    return (
      <LoaderMaster type="secondary" />
    )
  }

  let MasterStyles = {
    width: '100%',
    height: '100vh',
    padding: 0,
    paddingTop: 50
  }

  const CURRENT_URL = match.url;
  const isMap = CURRENT_URL.includes('/map');
  const finalContainer = isMap ? 'mx-auto max-w-[100%]' : 'mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10'


  return (

    <>

      <Header {...sharedProps} HeaderLogo={HeaderLogo} />

      <div className={finalContainer} style={isMap ? {height: 'calc(100% - 85px)'} : {}}>

        <Switch>

          <Route exact path="/partners/:id" render={(props) => <AdminDashboard {...props} {...sharedPage} />} />
          <Route exact path="/partners/:id/dashboard" render={(props) => <AdminDashboard {...props} {...sharedPage} />} />

          <Route exact path="/partners/:id/products" render={(props) => <AdminProducts {...props} {...sharedPage} />} />
          <Route exact path="/partners/:id/products/:page" render={(props) => <AdminProducts {...props} {...sharedPage} />} />
          <Route path="/partners/:pid/products/profile/:id" render={(props) => <AdminProductsDetails {...props} {...sharedPage} />} />

          <Route exact path="/partners/:id/stores" render={(props) => <AdminStores {...props} {...sharedPage} />} />
          <Route exact path="/partners/:id/stores/:page" render={(props) => <AdminStores {...props} {...sharedPage} />} />
          <Route path="/partners/:pid/stores/profile/:id" render={(props) => <AdminStoresDetails {...props} {...sharedPage} />} />

          <Route exact path="/partners/:pid/customers/profile/:id" render={(props) => <AdminCustomersDetails {...props} {...sharedPage} />} />

          <Route exact path="/partners/:id/orders" render={(props) => <AdminOrders {...props} {...sharedPage} />} />
          <Route exact path="/partners/:id/orders/:page" render={(props) => <AdminOrders {...props} {...sharedPage} />} />
          <Route path="/partners/:pid/orders/profile/:id" render={(props) => <AdminOrdersDetails {...props} {...sharedPage} />} />

          <Route exact path="/partners/:id/inbox" render={(props) => <AdminInbox {...props} {...sharedPage} />} />
          <Route exact path="/partners/:id/inbox/:page" render={(props) => <AdminInbox {...props} {...sharedPage} />} />
          <Route path="/partners/:pid/inbox/profile/:conversation" render={(props) => <AdminInboxDetails {...props} {...sharedPage} />} />

          <Route exact path="/partners/:id/calendary" render={(props) => <AdminCalendary {...props} {...sharedPage} />} />
          <Route exact path="/partners/:id/map" render={(props) => <AdminMap {...props} {...sharedPage} />} />

          <Route exact path="/partners/:id/reviews" render={(props) => <AdminReviews {...props} {...sharedPage} />} />
          <Route exact path="/partners/:id/reviews/:id" render={(props) => <AdminReviews {...props} {...sharedPage} />} />
          <Route path="/partners/:pid/reviews/profile/:id" render={(props) => <AdminReviewsDetails {...props} {...sharedPage} />} />

        </Switch>
      </div>




    </>
  )


}

const mapStateToProps = state => {
  return ({
    USER: state.user,
    PROVIDER: state.provider,
    CART: state.cart,
    PRODUCT: state.product,
    PRODUCTS: state.products,
    LANGUAGE: state.language
  });
}

const mapDispatchToProps = dispatch => ({
  onChangeLanguage: (lang) => dispatch(onUpdateLanguage(lang))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMaster);

