import React, { useState, useEffect } from "react";

import LoaderMaster from "../Loaders/Loaders";
import StarRating from "../General/Stars";

import { postApiPrivate } from "../../api";

import { AnalyticsOutline, AnalyticsSharp, CalendarOutline, CalendarSharp, CashSharp, ChatbubbleEllipsesSharp, CheckmarkSharp, CloseSharp, EyeSharp, LocationSharp, LockOpenSharp, MailSharp, NotificationsSharp, ReaderSharp, SaveSharp, ShieldSharp, ShirtSharp, StarSharp, TrashBinSharp, WifiSharp } from "react-ionicons";

import ReactCountryFlag from "react-country-flag";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, TextareaAutosize, TextField } from "@mui/material";

import ReviewComponent from "../Reviews/ReviewComponent";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { onFormatUrl } from "../../helpers/GeneralHelper";

import StoreActions from '../Modals/StoreActions';
import StoreAnalytics from '../Modals/StoreAnalytics';
import StoreAvailability from '../Modals/StoreAvailability';
import StoreDelivery from '../Modals/StoreDelivery';
import StoreImage from '../Modals/StoreImage';
import StorePayout from '../Modals/StorePayout';
import StoreSchedules from '../Modals/StoreSchedules';
import StoreLocation from '../Modals/StoreLocation';

import "../Profile/z_profile.css";

const AdminApprovalsDetails = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [PUBLIC_APPROVAL, setApprovals] = useState({});
  const [PUBLIC_STORE, setStore] = useState({});

  const [isActions, setActions] = useState(false);
  const [isAnalytics, setAnalytics] = useState(false);
  const [isAvailability, setAvailability] = useState(false);
  const [isDelivery, setDelivery] = useState(false);
  const [isImage, setImage] = useState(false);
  const [isPayout, setPayout] = useState(false);
  const [isSchedules, setSchedules] = useState(false);
  const [isLocation, setLocation] = useState(false);


  useEffect(() => {

    var store_id = match.params.id || '';

    onFetchPublic(store_id);

  }, []);

  useEffect(() => {

    if (isAnalytics || isActions || isAvailability || isDelivery || isSchedules || isPayout || isImage || isLocation) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isAnalytics, isActions, isAvailability, isDelivery, isSchedules, isPayout, isImage, isLocation])

  const onFetchPublic = async (approval_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setEdited(false);
    setLoading(true);

    try {

      const FORM = { id: approval_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/approvals/details', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setLoading(false);
        history.push('/admin/approvals');
        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setApprovals(DATA_RESPONSE || {});

      const store_id = DATA_RESPONSE.store ? DATA_RESPONSE.store.id : '';

      if (store_id) {
        onFetchStore(store_id)
      }

    }
    catch (e) {

      setLoading(false);
      history.push('/admin/approvals')
    }

  }

  const onFetchStore = async (store_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setEdited(false);
    setLoading(true);

    try {

      const FORM = { id: store_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/stores/details', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setStore(DATA_RESPONSE || {});
      setLoading(false);

    }
    catch (e) {

      setLoading(false);
    }

  }


  const onUpdateStore = async (key, value) => {

    return
    setEdited(true);
    setStore({
      ...PUBLIC_STORE,
      [key]: value
    });

  }

  const onSaveApproval = async (approval_id, status) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    if (!approval_id) {
      return alert('alert no approval_id');
    }

    setSaving(true);

    try {

      const FORM = {
        id: approval_id,
        status: status
      }

      //return alert(JSON.stringify(FORM));

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/approvals/edit', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update This Approval', 'error');
        onFetchPublic(approval_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Approval Updated', 'This Approval has been updated successfully !!!', 'success');
      onFetchPublic(approval_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This Approval', 'error');
      onFetchPublic(approval_id);
    }

  }


  const onSelectCopy = (copy) => {
    swal('Copied to Clipboard !!!', copy, 'success')
  }

  if (isLoading) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading} />
    )

  }

  if (isSaving) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving} />
    )

  }


  const {
    id,
    name,
    img,
    updatedAt,
    createdAt,
    rating,
    email,
    phone,
    about,
    currency,
    status,
    address,

    alert,

    products_count,

    reviews,

    country,

    owner,
    approval

  } = PUBLIC_STORE;

  const score = PUBLIC_STORE.score ? parseFloat(PUBLIC_STORE.score.toFixed(2)) : 0;
  const total_orders = PUBLIC_STORE.total || 0;
  const total_green = PUBLIC_STORE.green || 0;
  const total_red = PUBLIC_STORE.red || 0;
  const total_yellow = PUBLIC_STORE.yellow || 0;


  const ownerObx = owner || {};
  const ownerName = ownerObx.name || '';
  const ownerImg = ownerObx.img || '';
  const ownerId = ownerObx.id || '';

  //Approval

  const approval_id = PUBLIC_APPROVAL.id;
  const approval_status = PUBLIC_APPROVAL.status;
  const approval_type = PUBLIC_APPROVAL.type;


  let CurrencyCode = 'eu';

  if (currency === 'usd') {
    CurrencyCode = 'us'
  }
  else if (currency === 'gbp') {
    CurrencyCode = 'gb'
  }

  return (

    <>

      {isActions && (
        <StoreActions {...props}
          onClose={() => setActions(false)}
          STORE_ID={id}

        />
      )}

      {isAnalytics && (
        <StoreAnalytics {...props}
          onClose={() => setAnalytics(false)}
          STORE_ID={id}
          CURRENCY={currency || 'eur'}

        />
      )}

      {isAvailability && (
        <StoreAvailability {...props}
          onClose={() => setAvailability(false)}
          STORE_ID={id}

        />
      )}

      {isDelivery && (
        <StoreDelivery {...props}
          onClose={() => setDelivery(false)}
          STORE_ID={id}
          DASHBOARD={PUBLIC_STORE || {}}
          onFetchStore={onFetchPublic}
          CURRENCY={currency || 'eur'}

        />
      )}

      {isLocation && (
        <StoreLocation {...props}
          onClose={() => setLocation(false)}
          STORE_ID={id}
          DASHBOARD={PUBLIC_STORE || {}}
          onFetchStore={onFetchPublic}
          CURRENCY={currency || 'eur'}

        />
      )}

      {isImage && (
        <StoreImage {...props}
          onClose={() => setImage(false)}
          STORE_ID={id}

        />
      )}

      {isPayout && (
        <StorePayout {...props}
          onClose={() => setPayout(false)}
          STORE_ID={id}
          CURRENCY={currency || 'eur'}

        />
      )}

      {isSchedules && (
        <StoreSchedules {...props}
          onClose={() => setSchedules(false)}
          STORE_ID={id}

        />
      )}

      <Grid container spacing={2}>

        <Grid item xs={12} lg={4} md={6}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>
            <div className="top_store_card">
              <div className="top_store_profile">

                {img ? (
                  <img className="profile_img" src={img} />
                ) : (
                  <div className="profile_img" src={'/assets/img/avatar.svg'} />
                )}

                <div className="font-bold text-lg">{name}</div>
                <StarRating rate={rating} reviews={reviews} />

              </div>

              <div className="top_store_stats">
                <div>
                  <div className="font-bold text-lg">{total_orders || 0}</div>
                  <p>Orders</p>
                </div>
                <div>
                  <div className="font-bold text-lg">{products_count || 0}</div>
                  <p>Products</p>
                </div>
                <div>
                  <div className="font-bold text-lg">{score || 0}</div>
                  <p>Score</p>
                </div>
              </div>


            </div>

            {!isEdited && (
              <>
                <div className="row justify-content-center">


                  <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/products/0?sort=updatedAt&dir=desc&store=' + id)}>
                    <ShirtSharp color={'#fff'} style={{ marginRight: 10 }} /> Products
                  </div>

                  <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setDelivery(true)}>
                    <LocationSharp color={'#fff'} style={{ marginRight: 10 }} /> Delivery
                  </div>

                  <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setSchedules(true)}>
                    <CalendarSharp color={'#fff'} style={{ marginRight: 10 }} /> Schedules
                  </div>

                  <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setAvailability(true)}>
                    <ShieldSharp color={'#fff'} style={{ marginRight: 10 }} /> Availability
                  </div>


                  {approval_status === 'pending' && (
                    <div className="top_add_cart simple col-sm-5 mr-sm-1 mt-5" style={{ backgroundColor: 'red', color: '#ffffff' }} onClick={() => {
                      swal({
                        title: 'Confirm to Reject',
                        text: 'Please insert CONFIRM to reject this approval',
                        content: "input",
                      })
                        .then((value) => {

                          if (value === 'CONFIRM') {

                            onSaveApproval(approval_id, 'rejected')
                          }
                          else {

                            swal('Invalid Password', 'You are not authorized to update this user', 'error');
                          }

                        });
                    }}>
                      <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Reject
                    </div>
                  )}

                  {(approval_status === 'rejected' || approval_status === 'approved') && (
                    <div className="top_add_cart simple col-sm-5 mr-sm-1 mt-5" style={{ backgroundColor: 'orange', color: '#ffffff' }} onClick={() => {
                      swal({
                        title: 'Confirm to make in Review',
                        text: 'Please insert CONFIRM to make this approval in Review',
                        content: "input",
                      })
                        .then((value) => {

                          if (value === 'CONFIRM') {

                            onSaveApproval(approval_id, 'pending')
                          }
                          else {

                            swal('Invalid Password', 'You are not authorized to update this approval', 'error');
                          }

                        });
                    }}>
                      <CheckmarkSharp color={'#fff'} style={{ marginRight: 10 }} /> In Review
                    </div>
                  )}

                  {approval_status === 'pending' && (
                    <div className="top_add_cart simple col-sm-5 mr-sm-1 mt-5" style={{ backgroundColor: 'green', color: '#ffffff' }} onClick={() => {
                      swal({
                        title: 'Confirm to Approve',
                        text: 'Please insert CONFIRM to accept this approval',
                        content: "input",
                      })
                        .then((value) => {

                          if (value === 'CONFIRM') {

                            onSaveApproval(approval_id, 'approved')
                          }
                          else {

                            swal('Invalid Password', 'You are not authorized to update this approval', 'error');
                          }

                        });
                    }}>
                      <CheckmarkSharp color={'#fff'} style={{ marginRight: 10 }} /> Approve
                    </div>
                  )}


                </div>

              </>
            )}


          </div>

          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>
              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between mb-3">
                   Alert
                </h1>
                {alert && (
                  <div style={{ whiteSpace: 'break-spaces' }}>
                    {alert}
                  </div>
                )}

              </div>
          </div>



        </Grid>

        <Grid item xs={12} lg={5} md={6}>

          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>


            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Store Name
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  value={name}
                  label={'.'}
                  onChange={(e) => onUpdateStore('name', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  placeholder="Type here..."
                  disabled


                />
              </div>
            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Store Email
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  value={email}
                  label={'.'}
                  onChange={(e) => onUpdateStore('email', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  placeholder="Type here..."
                  disabled

                />
              </div>
            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Store Phone
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  value={phone}
                  label={'.'}
                  onChange={(e) => onUpdateStore('phone', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  placeholder="Type here..."
                  disabled

                />
              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                Store Address
              </h1>
              <div className="flex items-center" style={{ margin: '10px 0px' }}>

                {!address ? (
                  <Chip size='medium' label="NO" />
                ) : (
                  <CopyToClipboard text={address}
                    onCopy={() => onSelectCopy(address)}
                  >
                    <Chip
                      avatar={<ReactCountryFlag countryCode={country} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                      label={address.toLowerCase()}
                      variant="outlined"
                      size='medium'
                      style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                    />

                  </CopyToClipboard>
                )}

              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                Store Description
              </h1>
              <div style={{ margin: '10px 0px' }}>

                <TextareaAutosize
                  minRows={4}
                  maxRows={10}
                  placeholder="Please insert the store's description"
                  defaultValue={about}
                  style={{ width: '100%', border: 'solid 1px #00000019', borderRadius: 10, padding: 10 }}
                  onChange={(e) => onUpdateStore('about', e.target.value)}
                  disabled
                />

              </div>
            </div>


            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Store Currency

                <div>
                  <ReactCountryFlag countryCode={CurrencyCode} svg style={{ width: 20, height: 20, marginLeft: 5, cursor: 'pointer' }} />
                </div>

              </h1>

              <div style={{ margin: '10px 0px' }}>
                <select
                  className={'gridyx-select'}
                  value={currency}
                  label={'.'}
                  onChange={(e) => onUpdateStore('currency', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  select
                  disabled
                >
                  <option value={null}>
                    ---- Select a currency ----
                  </option>
                  <option value="usd">
                    $ - USD
                  </option>
                  <option value="eur">
                    € - EUR
                  </option>
                  <option value="gbp">
                    £ - GBP
                  </option>

                </select>
              </div>

            </div>


          </div>


        </Grid>
        <Grid item xs={12} lg={3} md={3}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

            <div className="top_box_magic">
              <h1>
                Approval Status
              </h1>
              <div style={{ margin: '10px 0px' }}>
                {approval_status === 'pending' && (
                  <Chip label="Pending" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="warning" />
                )}

                {approval_status === 'approved' && (
                  <Chip label="Approved" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="success" />
                )}

                {approval_status === 'rejected' && (
                  <Chip label="Rejected" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="error" />
                )}
              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                Approval Type
              </h1>
              <div style={{ margin: '10px 0px' }}>
                {approval_type === 'add_store' && (
                  <Chip label="Store" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="info" />
                )}
              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                Store Status
              </h1>
              <div style={{ margin: '10px 0px' }}>
                {status ? (
                  <Chip label="Published" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="success" />
                ) : (
                  <Chip label="Unpublished" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="warning" />
                )}
              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                Store Approval
              </h1>
              <div style={{ margin: '10px 0px' }}>
                {approval ? (
                  <Chip label="Need Approval" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="warning" />
                ) : (
                  <Chip label="Approved" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="success" />
                )}
              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                Store Created
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                </p>
              </div>
            </div>
            <div className="top_box_magic">
              <h1>
                Store Updated
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                </p>
              </div>
            </div>

            {ownerId && (
              <div className="top_box_magic" style={{ marginBottom: 10 }}>
                <h1>
                  Store Owner
                </h1>

                <Chip
                  avatar={<Avatar alt={ownerName} src={ownerImg} />}
                  label={ownerName.toLowerCase()}
                  variant="outlined"
                  size='medium'
                  style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                  onClick={() => history.push('/admin/users/profile/' + ownerId)}
                />

              </div>
            )}


          </div>
        </Grid>
      </Grid>

    </>
  )


}


export default AdminApprovalsDetails
