import React, {useState, useEffect} from "react";

import LoaderMaster from "../Loaders/Loaders";

import {postApiPrivate} from "../../api";

import { AddCircleOutline, ArrowBack, CheckmarkCircleOutline, CheckmarkCircleSharp, CloseSharp, EllipseOutline, EyeSharp, ImageSharp, LockOpenSharp, MailSharp, NotificationsSharp, SaveSharp, TrashBinSharp } from "react-ionicons";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, InputAdornment, InputLabel, Select, TextareaAutosize, TextField } from "@mui/material";


import {onGetCurrencySymbol } from "../../helpers/GeneralHelper";

import {Categories_Array, Ages_Array} from '../General/Constants';

import ReactFileReader from 'react-file-reader';

import { ImageCompressor, getImageSize } from "compressor-img";

import "../Profile/z_profile.css";
import { Link } from "react-router-dom";

const AdminListingAdd = (props) => {

  const {TRANSLATE, history, match, LANGUAGE} = props;
  
  const [isSaving, setSaving] = useState(false);

  const [isFocused, setFocused] = useState(false);
  const [BrandResults, setBrandResults] = useState([]);
  const [BrandInput, setBrandInput] = useState('');

  const [PUBLIC_PRODUCT, setProduct] = useState({});

  const [LangName, setLangName] = useState('en');


  const onUpdateProduct = async(key, value) => {
    setProduct({
      ...PUBLIC_PRODUCT,
      [key]: value
    });

  }

  const onSaveProduct = async(product_id) => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        const FORM = { 
          name: PUBLIC_PRODUCT.name,
          about: PUBLIC_PRODUCT.about,
          marca: PUBLIC_PRODUCT.marca,
          brand: PUBLIC_PRODUCT.brand,
          category: PUBLIC_PRODUCT.category,
          price: parseInt(PUBLIC_PRODUCT.price),
          price_day: parseInt(PUBLIC_PRODUCT.price_day),
          price_week: parseInt(PUBLIC_PRODUCT.price_week),
          price_month: parseInt(PUBLIC_PRODUCT.price_month),
          deposit: parseInt(PUBLIC_PRODUCT.deposit),
          ages: PUBLIC_PRODUCT.ages,
          en: PUBLIC_PRODUCT.en,
          it: PUBLIC_PRODUCT.it,
          es: PUBLIC_PRODUCT.es,
          fr: PUBLIC_PRODUCT.fr,
          status: PUBLIC_PRODUCT.status

        };

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/listing/create', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Create This Product', 'error');

            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("SEARCH ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('Product Created', 'This Product has been created successfully !!!', 'success');
       
        const product_id = DATA_RESPONSE.id;
        history.push('/admin/listing/profile/'+product_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Update This Product', 'error');
      }

  }
  
  const onSelectBrand = async(brand) => {

      console.log("BRAND SELECTED ===> ", brand);

      setFocused(false);

      setProduct({
        ...PUBLIC_PRODUCT,
        marca: brand.name,
        brand: brand.id
      });

      setBrandInput(brand.name);

      swal({
        title: 'Brand Updated',
        text: 'You selected '+brand.name,
        icon: 'success',
        timer: 2000
      })
      
  }

  const onSearchBrands = async(query) => {

      setBrandInput(query);

      if(!query){
          setFocused(false);
          setBrandResults([]);

          return 
      }

      //Fetch

      try{

          const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

          const DATA_RESPONSE = await postApiPrivate('/brands/search', {query: query, lang: LANGUAGE} , JWT);
          
          if(DATA_RESPONSE.error){
              console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
              return 
          }

          console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

          const filtredResults = DATA_RESPONSE.data || [];

          if(filtredResults.length > 0){
              setFocused(true);
              setBrandResults(filtredResults);
          }

          
      }
      catch(e){
          
          console.log("CREATING ===> ", e);

          setFocused(false);
          setBrandResults([]);
  
      }
    
          
  }

  const onSearchKeyUp = async(event) => {
      event.preventDefault();

      if (event.keyCode === 13) {
          event.target.blur();

          if(BrandResults.length>0){
              onSelectBrand(BrandResults[0]);
          }
      }
  }

  const onSelectAge = (age) => {

    const product_ages = PUBLIC_PRODUCT.ages || [];

    if(product_ages.includes(age)){

      const filtred_ages = product_ages.filter((item)=> item !== age);
      onUpdateProduct('ages', filtred_ages);

    }
    else{
      onUpdateProduct('ages', [...product_ages, age]);
    }

  }

  const onUpdateTranslations = (type, lang, value) => {

      if(lang === 'en'){
          onUpdateProduct(type, value);
      }
      else{

        const target_translation = PUBLIC_PRODUCT[lang] || {};
        const new_translation = {
          ...target_translation,
          [type]: value
        }

        onUpdateProduct(lang, new_translation);

      }

  }



  if(isSaving){

      return (
        <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
      )

  }


  const {
  
      name,
      about,
      category,

      brand,
      marca,

      price,
      price_day,
      price_week,
      price_month,

      deposit,    

      status,

      ages


    } = PUBLIC_PRODUCT;


    const CurrencyX = onGetCurrencySymbol('eur');
    const Categories_Final = Categories_Array.filter((item, index)=> index != 0);

    let CurrentProductName = '';
    let CurrentProductAbout = '';

    if(LangName === 'en'){
      CurrentProductName = name;
      CurrentProductAbout = about;
    }
    else{
      const CurrentLang = PUBLIC_PRODUCT[LangName] || {};
      CurrentProductName = CurrentLang.name || '';
      CurrentProductAbout = CurrentLang.about || '';
    }

 
    const CAN_SAVE = (
        name && name.length>0 &&
        about && about.length>0 &&
        category && category.length>0 && 
        marca && marca.length>0 && 
        brand && brand.length>0 &&
        price && price.length>0        
    )
    
  
    return(
    
        <Container maxWidth="lg" style={{background: '#f0f5f9'}}>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={9} md={9}>
                
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                          
                          <div className="top_box_magic" style={{marginBottom: 20}}>
                              <h1>
                                Status
                              </h1>

                              {status ? (
                                <Chip label="Completed" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateProduct('status', false)}/>
                              ) : (
                                <Chip label="Uncomplete" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning" onClick={(e)=> onUpdateProduct('status', true)} />
                              )}
                          </div>   

                          <div className="top_box_magic">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Name

                                <div>
                                  <img src="/assets/languages/en.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5)}} onClick={()=> setLangName('en')}/>
                                  <img src="/assets/languages/it.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5)}} onClick={()=> setLangName('it')}/>
                                  <img src="/assets/languages/fr.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5)}} onClick={()=> setLangName('fr')}/>
                                  <img src="/assets/languages/es.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5)}} onClick={()=> setLangName('es')}/>
                                </div>

                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    value={CurrentProductName} 
                                    label={'.'} 
                                    onChange={(e)=> onUpdateTranslations('name', LangName, e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    placeholder="Type here..."
                                
                                />
                              </div>
                          </div>


                          <div className="top_box_magic">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Description

                                
                              </h1>
                              <div style={{margin: '10px 0px'}}>

                                <TextareaAutosize
                                  className={'gridyx-textarea'}
                                  minRows={4}
                                  maxRows={10}
                                  placeholder="Type here..."
                                  value={CurrentProductAbout}
                                  onChange={(e)=> onUpdateTranslations('about', LangName, e.target.value)}
                                  
                                />

                              </div>
                          </div>

                          <div className="top_box_magic">
                              <h1>
                                Category
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <select 
                                    className={'gridyx-select'}
                                    value={category} 
                                    label={'.'} 
                                    onChange={(e)=> onUpdateProduct('category', e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    select
                                >
                                  {Categories_Final.map((item, index)=> {
                                      const Cat_Name = item[LANGUAGE];
                                      const Cat_Code = item.code;
            
                                      let NewURL = match.url;
                                      if(index != 0){
                                        NewURL = NewURL+'?filter='+Cat_Code
                                      }
                                    return (
                                      <option value={Cat_Code} key={index}>
                                          {Cat_Name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                          </div>

                          <div className="top_box_magic">
                              <h1>
                                Brand
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    value={BrandInput} 
                                    label={'.'} 
                                    // onChange={(e)=> onUpdateProduct('marca', e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    onFocus={()=> setFocused(true)}  
                                    onChange={(e)=> onSearchBrands(e.target.value)} 
                                    onKeyUp={(e)=> onSearchKeyUp(e)}
                                  
                                
                                />

                                {(isFocused && BrandResults.length > 0) && (
                                      <div style={{...Styles.resultsContainer}}>
                                          {BrandResults.map((item, index)=> {

                                              return (
                                                  <div style={Styles.resultsRow} 
                                                          key={index} 
                                                          onClick={()=> onSelectBrand(item)}
                                                  >

                                                        {item.name}
                                                        
                                                        <CheckmarkCircleOutline size={28} color="#ccc" style={{marginRight: 10}} />
                                                        
                          
                                                  </div>
                                              )
                                              
                                          })}
                                      </div>
                                  )}
                              </div>

                            

                          </div>

                          <div className="top_box_magic">
                              <h1>
                                Price New
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    label={'.'} 
                                    InputLabelProps={{shrink: false}}
                                    value={price}
                                    onChange={(e)=> onUpdateProduct('price', e.target.value)}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                    }}
                                    type="number"
                                    helperText={price <= 0 ? "Invalid price day. Don't use decimals" : ''}
                                    error={price <= 0}
                                
                                />
                              </div>
                          </div>

                          <div className="top_box_magic">
                              <h1>
                                Suggested Price per day
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    label={'.'} 
                                    InputLabelProps={{shrink: false}}
                                    value={price_day}
                                    onChange={(e)=> onUpdateProduct('price_day', e.target.value)}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                    }}
                                    type="number"
                                    helperText={price_day <= 0 ? "Invalid price day. Don't use decimals" : ''}
                                    error={price_day <= 0}
                                
                                />
                              </div>
                          </div>

                          <div className="top_box_magic">
                              <h1>
                                Suggested Price per week
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    label={'.'} 
                                    InputLabelProps={{shrink: false}}
                                    value={price_week}
                                    onChange={(e)=> onUpdateProduct('price_week', e.target.value)}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                    }}
                                    type="number"
                                
                                />
                              </div>
                          </div>

                          <div className="top_box_magic">
                              <h1>
                                Suggested Price per month
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    label={'.'} 
                                    InputLabelProps={{shrink: false}}
                                    value={price_month}
                                    onChange={(e)=> onUpdateProduct('price_month', e.target.value)}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                    }}
                                    type="number"
                                
                                />
                              </div>
                          </div>

                          <div className="top_box_magic">
                              <h1>
                                Suggested Deposit
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    label={'.'} 
                                    InputLabelProps={{shrink: false}}
                                    value={deposit}
                                    onChange={(e)=> onUpdateProduct('deposit', e.target.value)}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start">{CurrencyX}</InputAdornment>,
                                    }}
                                    type="number"
                                    helperText={deposit <= 0 ? "Invalid price day. Don't use decimals" : ''}
                                    error={deposit <= 0}
                                
                                />
                              </div>
                          </div>

                          <div className="top_box_magic">
                              <h1>
                                Age
                              </h1>
                              <div className="d-flex flex-wrap" style={{margin: '10px 0px'}}>
                                
                              
                                  {Ages_Array.map((item, index)=> {

                                    return (
                                      <div className="d-flex align-items-center justify-content-between" key={index} style={{borderBottom: '1px solid #00000045', padding: 20, cursor: 'pointer', width: '50%'}} onClick={()=> onSelectAge(item.code)}>
                                        
                                        <div className="d-flex flex-column" style={{fontWeight: 600}}>
                                          {item[LANGUAGE]}
                                          <div style={{fontWeight: 300}}>{item.min}-{item.max} cm</div>
                                        </div>
                                  
                                        {(ages && ages.includes(item.code)) ? (
                                          <CheckmarkCircleSharp size={30} color="green" style={{marginRight: 10}}  />
                                        ) : (
                                          <CheckmarkCircleOutline size={30} color="#ccc" style={{marginRight: 10}} />
                                        )}
                                        
                                                        
                                      </div>
                                    )
                                  })}
      
                              </div>
                          </div>

                  
                  </div>
                  

                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                      
                  <div className="top_add_cart"  
                            style={{backgroundColor: '#59ad6a'}} 
                            onClick={()=> {

                                if(!CAN_SAVE){

                                    return swal("Incomplete", "Please complete all the fields in order to create this product", 'warning')
                                }

                                swal({
                                    title: 'Confirm to Save',
                                    text: 'Please insert the CONFIRM to create this product',
                                    content: "input",
                                })
                                .then((value) => {

                                if(value === 'CONFIRM'){

                                    onSaveProduct()
                                }
                                else{

                                    swal('Invalid Password', 'You are not authorized to create this product', 'error');
                                }

                                });
                            }}
                            
                        >
                            <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Create Product
                        </div>

                        <Link className="top_add_cart"  style={{backgroundColor: 'var(--dark)'}} to="/admin/listing">
                            <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Go Back
                        </Link>
                          
                  

                  </div>
                </Grid>
              </Grid>            
            
        </Container>

      
    )


}



const Styles = {
  resultsRow: {
      display: 'flex', 
      alignItems: 'center', 
      color: '#000', 
      height: 40, 
      minHeight: 40,
      paddingLeft: 20, 
      border: '1px solid #00000040', 
      borderRight: 'none', 
      borderTop: 'none', 
      borderLeft: 'none',
      cursor: 'pointer',
      textTransform: 'capitalize',
      justifyContent: 'space-between'
  },
  resultsContainer: {
      display: 'flex', 
      flexDirection: 'column', 
      background: '#fff', 
      maxHeight: 280,
      position: 'relative', 
      top: 20, 
      border: '1px solid #00000040', 
      borderRadius: 10,
      overflow: 'auto',
      marginBottom: 20
  },
  right: {
      display: 'flex',
      alignItems: 'center'
  },
  searchContainer: {
      width: 300,
      height: 42,
      backgroundColor: '#F1F1F1',
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 5
  },
  input: {
      fontSize: 16,
      display: 'flex',
      color: '#000',
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      marginLeft: 5,
      textTransform: 'capitalize'
  },
  searchOption: {
      display: 'flex', 
      width: 40, 
      height: 35, 
      alignItems: 'center', 
      borderRadius: '50%', 
      background: '#fd6270'
  },
  button: {
        display: 'flex',
        height: '50px',
        width: '200px',
        backgroundColor: '#26BFBF',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',  
        borderRadius: 10,
        width: '100%'
    },
    img_full: {
      display: 'flex',
      width: 200, 
      height: 200, 
      borderRadius: 6, 
      marginRight: 15, 
      marginLeft: 15
    },
    img_empty: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%', 
      height: '100%', 
      borderRadius: 6, 
      marginRight: 15, 
      borderColor: '#ccc', 
      borderWidth: 2, 
      borderStyle: "dashed", 
      justifyContent: 'center', 
      alignItems: 'center',
      marginBottom: 10,
      
    },
    clickCard: {
        backgroundColor: "#fff",
        padding: 20,
        marginTop: 10,
    },
    clickLabel: {
        color: "#697883",
        fontFamily: "SourceSansPro-Bold", 
        fontSize: 14, 
        marginBottom: 10,
    },
    wrapFlex: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    publishBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fd6270',
      padding: 10,
      width: 90,
      color: '#fff',
      borderRadius: 10,
      cursor: 'pointer'
    }

}


export default AdminListingAdd

