import {
  KeyboardDoubleArrowDownOutlined,
  KeyboardDoubleArrowRightOutlined,
  PinDropOutlined,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { ArrowForward, ChevronBack } from 'react-ionicons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { FooterDashboard } from '../Footer';
import Header from '../Header';

const ProviderOrderChangeRequest = (props) => {
  const [product, setProduct] = useState('stroller');
  const [pickupAddress, setPickupAddress] = useState('address');
  const [deliveryAddress, setDeliveryAddress] = useState('address');

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} isProvider={true} />

      <div
        className='relative flex flex-col mx-auto my-4 gap-4 w-full max-w-xl px-4 sm:px-6 lg:px-8'
        style={{ flexGrow: 1 }}
      >
        <div className='flex w-full align-items-center justify-content-between'>
          <Link to='/newux/orders' className='flex align-items-center gap-1 color-pr'>
            <ChevronBack color={'#26bfbf'} />
            Back
          </Link>

          <h2 className='text-sm'>ORDER - ID: #5235266</h2>
        </div>

        <div className='flex flex-col p-2 w-full bg-light-gray rounded-lg'>
          <div className='flex gap-2'>
            <img
              src='/assets/cities/amsterdam.png'
              className='border-mid-gray'
              style={{ width: '60px', height: '56px' }}
            />
            <div className='w-full'>
              <h2 className='text-sm '>Uppa Baby Stroller</h2>
              <h3 className='text-sm' style={{ fontStyle: 'italic' }}>
                Aishwarya Rajamohan
              </h3>

              <div className='flex mt-2 w-full justify-content-between'>
                <p className='font-semibold'>1x</p>
                <p>16$</p>
              </div>

              <div className='flex gap-8 mt-2 w-full justify-content-between '>
                <p className='font-semibold'>4days</p>
                <p>27-03-2023 - 28-03-2023</p>
              </div>
            </div>
          </div>
        </div>

        <div className='p-2 border-pr-dashed'>
          <div>
            <h2 className='text-sm '>New Date</h2>
            <div className='flex justify-content-between w-full'>
              <h3>
                <span className='font-semibold'>4 </span> days
              </h3>
              <h3 className='font-semibold'>27-03-2023 - 28-03-2023 </h3>
            </div>

            <KeyboardDoubleArrowDownOutlined className='flex mx-auto my-4 color-pr' />

            <div>
              <h2 className='text-sm color-pr'>New Date</h2>

              <div className='flex justify-content-between w-full'>
                <h3 className='color-pr'>
                  <strong>5 </strong> days
                </h3>
                <h3 className='font-semibold '>
                  27-03-2023 - <span className='color-pr'>28-03-2023</span>
                </h3>
              </div>
            </div>
          </div>

          <div className='mx-auto my-4 bg-light-gray' style={{ width: '90%', height: '2px' }}></div>

          <div>
            <h2 className='text-sm '>Order Total</h2>
            <div className='flex w-full justify-content-between'>
              <h3>€ 136.00</h3>
              <KeyboardDoubleArrowRightOutlined className='color-pr' />
              <h3 className='color-pr'>€ 126.00</h3>
            </div>
          </div>

          <div className='flex flex-col w-full pt-2 bg-white'>
            <button
              type='button'
              className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
              onClick={() => {}}
            >
              APPROVE REQUEST <ArrowForward style={{ color: '#fff' }} />
            </button>
            <button
              type='button'
              className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full rounded-lg'
              onClick={() => {}}
            >
              REJECT REQUEST
              <ArrowForward style={{ color: '#fff' }} />
            </button>
          </div>
        </div>

        <div className='p-2 border-pr-dashed'>
          <div>
            <div className='mt-4'>
              <h2 className='text-sm '>Delivery</h2>
              <div className='flex justify-content-between w-full'>
                <h3>Malpensa International Airport</h3>
                <PinDropOutlined />
              </div>
              <h3 className='text-sm font-semibold'>€ 25.00</h3>
            </div>

            <KeyboardDoubleArrowDownOutlined className='flex mx-auto my-4 color-pr' />

            <div className='mt-4 color-pr'>
              <h2 className='text-sm '>Delivery</h2>
              <div className='flex justify-content-between w-full'>
                <h3>Malpensa International Airport</h3>
                <PinDropOutlined />
              </div>
              <h3 className='text-sm font-semibold'>€ 25.00</h3>
            </div>
          </div>

          <div className='mx-auto my-4 bg-light-gray' style={{ width: '90%', height: '2px' }}></div>

          <div>
            <h2 className='text-sm '>Order Total</h2>
            <div className='flex w-full justify-content-between'>
              <h3>€ 136.00</h3>
              <KeyboardDoubleArrowRightOutlined className='color-pr' />
              <h3 className='color-pr'>€ 126.00</h3>
            </div>
          </div>

          <div className='flex flex-col w-full pt-2 bg-white'>
            <button
              type='button'
              className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
              onClick={() => {}}
            >
              APPROVE REQUEST <ArrowForward style={{ color: '#fff' }} />
            </button>
            <button
              type='button'
              className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full rounded-lg'
              onClick={() => {}}
            >
              REJECT REQUEST
              <ArrowForward style={{ color: '#fff' }} />
            </button>
          </div>
        </div>
      </div>

      <FooterDashboard {...props} />
    </div>
  );
};

export default ProviderOrderChangeRequest;
