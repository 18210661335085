import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Button, Checkbox, Chip, CircularProgress, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import ReactCountryFlag from 'react-country-flag';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';

import Countries_EN from '../Search/countries_en.json';
import { Add } from '@mui/icons-material';


const AdminGeopoints = (props) => {

    const { TRANSLATE, history, match, location } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [GEOPOINTS, setGeopoints] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var filter_country = routeParams.country || null;
            var filter_status = routeParams.status || null;
            var filter_type = routeParams.type || null;

            var current_page = parseInt(page_id);

            onFetchGeopoints({
                page: current_page,
                sort: sort_id,
                direction: direction_id,
                filter_country,
                filter_status,
                filter_type
            });
        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchGeopoints = async ({ page, sort, direction, filter_country, filter_status, filter_type }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                country: filter_country,
                status: filter_status,
                type: filter_type,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/geopoints', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setGeopoints(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchGeopoints = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchGeopoints({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/geopoints/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setGeopoints(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setGeopoints([]);
            setLoading(false);
        }


    }

    const onSortGeopoints = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else {
            return sort
        }
    }

    const onEditGeopoints = async (id, type, value) => {

        setLoading(true);

        try {

            let FORM = {
                id,
                [type]: value
            }

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/geopoints/edit', FORM, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            swal('Success', 'Your Geopoint has been updated successfully', 'success');

            if (type === 'status') {
                history.replace('/admin/geopoints/0?sort=status&dir=asc');
                history.replace('/admin/geopoints/0?sort=status&dir=desc');
            }

            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setLoading(false);

            swal('Success', 'Your Geopoint has been updated successfully', 'success');
        }


    }

    const onChangePageGeopoints = ({ page, sort, direction, filter_country, filter_status, filter_type }) => {
        let new_url = '/admin/geopoints/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }
        else {
            new_url = new_url + '?sort=updatedAt&dir=desc';
        }

        //Check filters
        if (filter_country) {
            new_url = new_url + '&country=' + filter_country;
        }

        if (filter_status) {
            new_url = new_url + '&status=' + filter_status;
        }

        if (filter_type) {
            new_url = new_url + '&type=' + filter_type;
        }

        history.push(new_url);
    }

    const onSelectGeopoint = (geopoint_id) => {
        history.push('/admin/geopoints/profile/' + geopoint_id);
    }

    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }

    //Logic
    var routeParams = GetRouteSearchParams(location.search);

    var currentSort = routeParams.sort || null;
    var currentDirection = routeParams.dir || null;

    var currentFilterCountry = routeParams.country || null;
    var currentFilterStatus = routeParams.status || null;
    var currentFilterType = routeParams.type || null;

    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectGeopoint(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "img",
            label: "THUMBNAIL",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src) => {

                    if (src) {
                        return (
                            <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={src || ''} />
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip size='small' label="NO" />
                        </div>

                    )

                }
            },
        },
        {
            name: 'status',
            label: 'STATUS',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const geopointObx = GEOPOINTS[rowIndex] || {};

                    const status = geopointObx.status || false;

                    if (status) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="ACTIVE" color='success' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="OFF" />
                            </div>

                        )
                    }
                },
                filterOptions: {
                    names: ['ON', 'OFF']
                }

            }

        },
        {
            name: "name",
            label: "NAME",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const geopointObx = GEOPOINTS[rowIndex] || {};
                    const geoType = geopointObx.type;

                    let geoTypeImg = '/assets/img/babonbo_marker.png';

                    if (geoType === 'airport') {
                        geoTypeImg = '/assets/img/babonbo_marker_airport.png';
                    }
                    else if (geoType === 'station') {
                        geoTypeImg = '/assets/img/babonbo_marker_station.png';
                    }
                    else if (geoType === 'port') {
                        geoTypeImg = '/assets/img/babonbo_marker_port.png';
                    }
                    else if (geoType === 'place') {
                        geoTypeImg = '/assets/img/babonbo_marker_place.png';
                    }
                    else if (geoType === 'park') {
                        geoTypeImg = '/assets/img/babonbo_marker_park.png';
                    }
                    else if (geoType === 'hotel') {
                        geoTypeImg = '/assets/img/babonbo_marker_hotel.png';
                    }

                    return (
                        <div>
                            <Chip
                                avatar={<img src={geoTypeImg} style={{ fontSize: 20, marginLeft: 10 }} />}
                                label={label.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}

                            />
                        </div>
                    )
                }
            },
        },
        {
            name: "type",
            label: "TYPE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >

                            <Chip label={label.toUpperCase()} size='small' />

                        </CopyToClipboard>
                    )
                },
                filterOptions: {
                    names: ['Airport', 'Station', 'Port']
                }
            },
        },

        {
            name: 'city',
            label: 'CITY',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const geopointObx = GEOPOINTS[rowIndex] || {};
                    const countryName = geopointObx.country;
                    const countryCity = geopointObx.city;

                    if (!countryName) {
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    const countryIndex = Countries_EN.findIndex((country) => country.label === countryName);
                    const countryObx = countryIndex != -1 ? Countries_EN[countryIndex] : {};
                    const countryCode = countryObx.value || 'XX';

                    return (
                        <div>
                            <Chip
                                avatar={<ReactCountryFlag countryCode={countryCode} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                                label={countryCity.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}

                            />
                        </div>
                    )
                }

            }

        },
        {
            name: 'country',
            label: 'COUNTRY',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const geopointObx = GEOPOINTS[rowIndex] || {};
                    const countryName = geopointObx.country;

                    if (!countryName) {
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    const countryIndex = Countries_EN.findIndex((country) => country.label === countryName);
                    const countryObx = countryIndex != -1 ? Countries_EN[countryIndex] : {};
                    const countryCode = countryObx.value || 'XX';

                    return (
                        <div>
                            <Chip
                                avatar={<ReactCountryFlag countryCode={countryCode} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                                label={countryName.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'uppercase' }}

                            />
                        </div>
                    )
                },
                customFilterListOptions: {
                    render: (value) => {

                        return (
                            <>
                                {value.toUpperCase()}
                            </>

                        )
                    }
                },
                filterOptions: {
                    names: Countries_EN.map((item) => {
                        return item.label
                    })
                }


            }

        },
        {
            name: "points",
            label: "POINTS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {

                    let Color = '';

                    if (value >= 5000) {
                        Color = 'success'
                    }
                    else if (value < 5000 && value >= 1000) {
                        Color = 'info'
                    }
                    else if (value < 1000 && value >= 100) {
                        Color = 'warning'
                    }
                    else if (value < 100 && value >= 0) {
                        Color = 'error'
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={value} size='small' color={Color} />
                        </div>

                    )
                }
            },
        },
        {
            name: "manager",
            label: "MANAGER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const geopointObx = GEOPOINTS[rowIndex] || {};

                    const managerObx = geopointObx.manager || {};
                    const manager_id = managerObx.id || '';
                    const manager_name = managerObx.name || '';
                    const manager_img = managerObx.img || '';


                    if (manager_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                                    label={manager_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => history.push('/admin/users/profile/' + manager_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: 'isDeleted',
            label: 'DELETED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const geopointObx = GEOPOINTS[rowIndex] || {};

                    const isDeleted = geopointObx.isDeleted || false;

                    if (isDeleted) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="Deleted" color='error' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }
                }
            }

        },

        {
            name: "updatedAt",
            label: "LAST ACTIVE",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "DATE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = GEOPOINTS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_geopoint = {

            id: item.id,
            name: item.name,
            type: item.type,
            city: item.city,
            country: item.country,
            img: item.img,
            en: item.en,
            it: item.it,
            fr: item.fr,
            es: item.es,
            tr: item.tr,
            en_url: item.en_url,
            it_url: item.it_url,
            fr_url: item.fr_url,
            es_url: item.es_url,
            tr_url: item.tr_url,
            points: item.points,
            updatedAt: moment(item.updatedAt).fromNow(),
            createdAt: moment(item.createdAt).format('DD MMMM YYYY'),
            status: item.status ? 'ON' : 'OFF',
            isDeleted: item.isDeleted || false,
            manager: item.manager || {},

        };


        return new_geopoint
    })

    const customToolbar = () => (
        <Tooltip disableFocusListener title="Add Geopoint">
            <IconButton onClick={() => history.push('/admin/geopoints/profile/new')}>
                <Add />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageGeopoints({
                        page: tableState.page,
                        sort: onSortGeopoints(sortFilter),
                        direction: sortDirection,
                        filter_status: currentFilterStatus,
                        filter_country: currentFilterCountry,
                        filter_type: currentFilterType
                    });

                    break;
                case 'sort':

                    onChangePageGeopoints({
                        page: 0,
                        sort: onSortGeopoints(sortFilter),
                        direction: sortDirection,
                        filter_status: currentFilterStatus,
                        filter_country: currentFilterCountry,
                        filter_type: currentFilterType
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchGeopoints(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchGeopoints(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Geopoint By City Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Geopoints with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        onFilterChange: (column, filterList, type) => {


            //Check if Current Filter is Active
            if (column === 'country') {
                const filterValue = filterList[6];
                currentFilterCountry = filterValue;
            }
            else if (column === 'status') {
                const filterValue = filterList[2];
                currentFilterStatus = filterValue;
            }
            else if (column === 'type') {
                const filterValue = filterList[4];
                currentFilterType = filterValue;
            }



            onChangePageGeopoints({
                page: 0,
                sort: onSortGeopoints(currentSort),
                direction: currentDirection,
                filter_status: currentFilterStatus,
                filter_country: currentFilterCountry,
                filter_type: currentFilterType
            });

        }

    };




    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Geopoints →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}


export default AdminGeopoints

