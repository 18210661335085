import React, { useEffect, useState } from "react";

import { postApiPrivate } from "../../api";

import { CloseOutline } from "react-ionicons";

import { GetRouteSearchParams } from "../../helpers/GeneralHelper";

import StarReview from "../General/Stars";
import { Filter_Array, Currencies_Array, Languages_Array } from "./Constants";
import SearchDates from "../General/SearchDates";
import moment from "moment";

import Countries_EN from '../Search/countries_en.json';

const OrderFilter = (props) => {


    const { onClose, history, location, match, DASHBOARD, CURRENCY, LANGUAGE, TRANSLATE, isMap } = props;

    const [Store, setStore] = useState(null);
    const [Status, setStatus] = useState('all');
    const [Category, setCategory] = useState('all');

    const [Insurance, setInsurance] = useState('all');
    const [Coupon, setCoupon] = useState('all');
    const [LastMinute, setLastMinute] = useState('all');
    const [Country, setCountry] = useState('all');

    const [Reviews, setReviews] = useState('all');
    const [Language, setLanguage] = useState('all');
    const [Currency, setCurrency] = useState('all');

    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);

    //Extra
    const [isLoading, setLoading] = useState(false);
    const [SearchResults, setSearchResults] = useState([]);
    const [SearchInput, setSearchInput] = useState('');

    const [isSeachDates, setSearchDates] = useState(false);


    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);

        const routeStatus = routeParams.status || null;
        const routeStore = routeParams.store || null;
        const routeStoreName = routeParams.sname || null;

        const routeCategory = routeParams.category || null;
        const routeLastminute = routeParams.lastminute || null;
        const routeCoupon = routeParams.coupon || null;
        const routeInsurance = routeParams.insurance || null;

        const routeCountry = routeParams.country || null;

        const routeStart = routeParams.start || null;
        const routeEnd = routeParams.end || null;
        const routeReviews = routeParams.reviews || null;
        const routeLanguage = routeParams.language || null;
        const routeCurrency = routeParams.currency || null;

        if (routeStatus) setStatus(routeStatus);

        if (routeCategory) setCategory(routeCategory);
        if (routeLastminute) setLastMinute(routeLastminute);
        if (routeCoupon) setCoupon(routeCoupon);
        if (routeInsurance) setInsurance(routeInsurance);

        if (routeCountry) setCountry(routeCountry);
        if (routeReviews) setReviews(routeReviews);
        if (routeLanguage) setLanguage(routeLanguage);
        if (routeCurrency) setCurrency(routeCurrency);

        if (routeStart) setStartDate(routeStart);
        if (routeEnd) setEndDate(routeEnd);

        if (routeStore) {
            setStore({
                id: routeStore,
                name: routeStoreName
            });

            setSearchInput(routeStoreName)
        }

    }, [location.search]);

    const onApplyFilter = () => {

        let newQuery = '/admin/orders/0?sort=updatedAt&dir=desc';

        if (isMap) {
            newQuery = `/admin/map?search=reviews&sort=updatedAt&dir=desc`
        }

        if (Status) newQuery = newQuery + '&status=' + Status;

        if (Store) newQuery = newQuery + '&store=' + Store.id + '&sname=' + Store.name;

        if (Insurance) newQuery = newQuery + '&insurance=' + Insurance;

        if (Coupon) newQuery = newQuery + '&coupon=' + Coupon;

        if (LastMinute) newQuery = newQuery + '&lastminute=' + LastMinute;

        if (Category) newQuery = newQuery + '&category=' + Category;

        if (Country) newQuery = newQuery + '&country=' + Country;
        if (Language) newQuery = newQuery + '&language=' + Language;
        if (Currency) newQuery = newQuery + '&currency=' + Currency;
        if (Reviews) newQuery = newQuery + '&reviews=' + Reviews;


        if (StartDate && EndDate) {
            newQuery = newQuery + '&start=' + StartDate + '&end=' + EndDate;
        }


        history.push(newQuery);

        onClose();
    }

    const onSearchStores = async (store) => {

        setSearchInput(store);

        if (!store || store.length <= 3) {
            setSearchResults([]);
            return
        }

        //Fetch

        setLoading(true);

        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/store/search', { query: store }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if (filtredResults.length > 0) {
                setSearchResults(filtredResults);
            }

            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setSearchResults([]);
            setLoading(false);
        }


    }

    const onSelectStore = (store) => {
        //alert(JSON.stringify(city))
        setStore(store);
        setSearchInput(store.name);
        setSearchResults([]);
    }

    const onUpdateDate = (type) => {

        var REFERENCE = moment(); // fixed just for testing, use moment();
        var TODAY = REFERENCE.clone().startOf('day');
        var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
        var A_7DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_14DAYS_OLD = REFERENCE.clone().subtract(14, 'days').startOf('day'); // fixed
        var A_30DAYS_OLD = REFERENCE.clone().subtract(30, 'days').startOf('day');
        var A_60DAYS_OLD = REFERENCE.clone().subtract(60, 'days').startOf('day');
        var A_90DAYS_OLD = REFERENCE.clone().subtract(90, 'days').startOf('day');
        var A_120DAYS_OLD = REFERENCE.clone().subtract(120, 'days').startOf('day');
        var A_150DAYS_OLD = REFERENCE.clone().subtract(150, 'days').startOf('day');
        var A_180DAYS_OLD = REFERENCE.clone().subtract(180, 'days').startOf('day');

        let StartDate = null;
        let EndDate = null;

        if (type === 'today') {
            StartDate = TODAY.format('DD-MM-YYYY');
            EndDate = TODAY.format('DD-MM-YYYY');
        }
        else if (type === 'yesterday') {
            StartDate = YESTERDAY.format('DD-MM-YYYY');
            EndDate = YESTERDAY.format('DD-MM-YYYY');
        }
        else if (type === 'last_7') {
            StartDate = A_7DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_14') {
            StartDate = A_14DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_30') {
            StartDate = A_30DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_60') {
            StartDate = A_60DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_90') {
            StartDate = A_90DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_120') {
            StartDate = A_120DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_150') {
            StartDate = A_150DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_180') {
            StartDate = A_180DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'this_month') {
            StartDate = REFERENCE.clone().startOf('month').format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_month') {
            StartDate = REFERENCE.clone().subtract(1, 'month').startOf('month').format('DD-MM-YYYY');
            EndDate = REFERENCE.clone().subtract(1, 'month').endOf('month').format('DD-MM-YYYY');
        }
        else {
            StartDate = '01-01-2021';
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }

        setStartDate(StartDate);
        setEndDate(EndDate);
    }

    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Orders Filter
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        <>
                            <div className="d-flex flex-column align-items-center justify-content-center pt-8 md:pb-0 pb-32">

                                <div className="w-full flex flex-col text-black">

                                    {/* Order Status */}
                                    <div className="flex items-center justify-between gap-x-2">
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Status</div>
                                            <select value={Status} onChange={(e) => setStatus(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Orders
                                                </option>
                                                <option value="pending">
                                                    Only Pending
                                                </option>
                                                <option value="accepted">
                                                    Only Accepted
                                                </option>
                                                <option value="rejected">
                                                    Only Rejected
                                                </option>
                                                <option value="delivering">
                                                    Only Delivering
                                                </option>
                                                <option value="delivered">
                                                    Only Delivered
                                                </option>
                                                <option value="complete">
                                                    Only Complete
                                                </option>
                                                <option value="canceled">
                                                    Only Canceled
                                                </option>
                                            </select>
                                        </div>
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Dates</div>
                                            {(StartDate && EndDate) ? (
                                                <div className="flex items-center justify-between gap-x-2">
                                                    <input placeholder="Order Created Between..." style={{ border: 'solid 1px #000000', color: '#000', width: Store?.id ? '90%' : '100%', marginRight: Store?.id ? 8 : 0 }}
                                                        onChange={(e) => null}
                                                        value={`${StartDate} → ${EndDate}`}
                                                        autoComplete="new-password"
                                                        onClick={() => setSearchDates(true)}
                                                    />
                                                    <div className="flex items-center justify-center bg-red-500 cursor-pointer rounded-full shrink-0" onClick={() => {
                                                        setStartDate(null);
                                                        setEndDate(null);
                                                    }}>
                                                        <CloseOutline color={'#fff'} />
                                                    </div>
                                                </div>

                                            ) : (
                                                <select value={''} onChange={(e) => onUpdateDate(e.target.value)} className="text-black">
                                                    <option value="all">
                                                        All Time
                                                    </option>
                                                    <option value="today">
                                                        Today
                                                    </option>
                                                    <option value="yesterday">
                                                        Yesterday
                                                    </option>
                                                    <option value="this_month">
                                                        This month
                                                    </option>
                                                    <option value="last_month">
                                                        Last Month
                                                    </option>
                                                    <option value="last_7">
                                                        Last 7 days
                                                    </option>
                                                    <option value="last_14">
                                                        Last 14 days
                                                    </option>
                                                    <option value="last_21">
                                                        Last 21 days
                                                    </option>
                                                    <option value="last_30">
                                                        Last 30 days
                                                    </option>
                                                    <option value="last_60">
                                                        Last 60 days
                                                    </option>
                                                    <option value="last_90">
                                                        Last 90 days
                                                    </option>
                                                    <option value="last_120">
                                                        Last 120 days
                                                    </option>
                                                    <option value="last_150">
                                                        Last 150 days
                                                    </option>
                                                    <option value="last_180">
                                                        Last 180 days
                                                    </option>
                                                    <option value="custom">
                                                        Custom Dates
                                                    </option>

                                                </select>
                                            )}

                                        </div>
                                    </div>

                                    {/* Order Status */}
                                    <div className="flex items-center justify-between gap-x-2">
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Category</div>
                                            <select value={Category} onChange={(e) => setCategory(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Categories
                                                </option>
                                                {Filter_Array.map((item, key) => {
                                                    return (
                                                        <option value={item.code} key={key}>
                                                            {item.en}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Coupon</div>
                                            <select value={Coupon} onChange={(e) => setCoupon(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Orders
                                                </option>
                                                <option value="true">
                                                    Only with Coupon
                                                </option>
                                                <option value="false">
                                                    Only without Coupon
                                                </option>

                                            </select>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between gap-x-2">
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Last Minute</div>
                                            <select value={LastMinute} onChange={(e) => setLastMinute(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Orders
                                                </option>
                                                <option value="true">
                                                    Only with Last Minute
                                                </option>
                                                <option value="false">
                                                    Only without Last Minute
                                                </option>

                                            </select>
                                        </div>
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Insurance</div>
                                            <select value={Insurance} onChange={(e) => setInsurance(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Orders
                                                </option>
                                                <option value="true">
                                                    Only with Insurance
                                                </option>
                                                <option value="false">
                                                    Only without Insurance
                                                </option>

                                            </select>
                                        </div>

                                    </div>

                                    <div className="flex items-center justify-between gap-x-2">

                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Country</div>
                                            <select value={Country} onChange={(e) => setCountry(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Orders
                                                </option>
                                                {Countries_EN.map((item, key) => {
                                                    return (
                                                        <option value={item.value} key={key}>
                                                            {item.label}
                                                        </option>

                                                    )
                                                })}

                                            </select>
                                        </div>

                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Reviews</div>
                                            <select value={Reviews} onChange={(e) => setReviews(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Orders
                                                </option>
                                                <option value="true">
                                                    Only with Reviews
                                                </option>
                                                <option value="provider">
                                                    Missing Review to Provider
                                                </option>
                                                <option value="customer">
                                                    Missing Review to Customer
                                                </option>
                                                <option value="false">
                                                    Missing Both Reviews
                                                </option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="flex items-center justify-between gap-x-2">
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Language</div>
                                            <select value={Language} onChange={(e) => setLanguage(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Orders
                                                </option>
                                                {Languages_Array.map((item) => {
                                                    return (
                                                        <option value={item.code} key={item.code}>
                                                            {item.name}
                                                        </option>

                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Currency</div>
                                            <select value={Currency} onChange={(e) => setCurrency(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Orders
                                                </option>
                                                {Currencies_Array.map((item) => {
                                                    return (
                                                        <option value={item.code} key={item.code}>
                                                            {item.name}
                                                        </option>

                                                    )
                                                })}

                                            </select>
                                        </div>

                                    </div>

                                    {/* Store */}
                                    <div className="font-semibold mb-1 mt-4">Store</div>
                                    <div className="flex">
                                        <input placeholder="Search by store" style={{ border: 'solid 1px #000000', color: '#000', width: Store?.id ? '90%' : '100%', marginRight: Store?.id ? 8 : 0 }}
                                            onChange={(e) => onSearchStores(e.target.value)}
                                            value={SearchInput}
                                            autoComplete="new-password"
                                        />
                                        {Store?.id && (
                                            <div className="flex items-center justify-center bg-green-700 rounded-full text-white" style={{ width: 40, height: 40 }}>
                                                ok
                                            </div>
                                        )}
                                    </div>

                                    {SearchResults.map((item, index) => {

                                        const store_name = item.name;
                                        const store_img = item.img;
                                        const store_score = item.score || 0;
                                        const store_reviews = item.reviews || 0;
                                        const store_rating = item.rating || 0;
                                        const store_distance = item.distance || 0;
                                        const store_status = item.status || false;
                                        const store_orders = item.orders || 0;

                                        return (

                                            <div className="w-full p-3 my-3 flex items-center justify-between cursor-pointer hover:bg-zinc-100" onClick={() => onSelectStore(item)} key={index}>
                                                <div className='flex'>
                                                    <img className='w-[40px] h-[40px] rounded-full object-cover mr-3 mb-auto mt-1' src={store_img || '/assets/img/avatar.svg'} alt='' />
                                                    <div className='flex flex-col'>
                                                        <div className='font-bold'>{store_name}</div>
                                                        <StarReview rate={store_rating} size={20} />
                                                        <div className='text-xs'>{store_reviews} reviews</div>
                                                        <div className='text-xs'>Score: {parseInt(store_score)}</div>
                                                        <div className='text-xs'>Orders: {parseInt(store_orders)}</div>
                                                        <div className='text-xs'>{store_distance.toFixed(2)} from center</div>
                                                    </div>
                                                </div>


                                                {store_status ? (
                                                    <div className='bg-green-500 text-xs text-white rounded-md p-1'>Published</div>
                                                ) : (
                                                    <div className='bg-orange-500 text-xs text-white rounded-md p-1'>Unpublished</div>
                                                )}

                                            </div>
                                        )

                                    })}




                                </div>

                                <div style={{ height: 200 }}></div>
                            </div>
                        </>

                    </div>

                    <div className="d-flex align-items-center justify-content-between" style={{ backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>
                        <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5 }} onClick={() => onClose()}>
                            Back
                        </div>


                        <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#26BFBF', marginLeft: 5 }} onClick={() => onApplyFilter()}>
                            Apply Filters
                        </div>

                    </div>


                </div>

            </div>

            {isSeachDates && (
                <SearchDates {...props}
                    onClose={() => setSearchDates(false)}
                    onUpdateDate={({ from, to }) => {
                        setStartDate(from);
                        setEndDate(to)
                    }}

                />
            )}

        </>
    )
}



export default OrderFilter