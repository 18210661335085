import React, { useState, useEffect } from "react";

import LoaderMaster from "../Loaders/Loaders";

import { postApiPrivate } from "../../api";

import { CloseSharp, SaveSharp } from "react-ionicons";

import swal from "sweetalert";

import { Container, Grid, InputAdornment, InputLabel, Select, TextareaAutosize, TextField } from "@mui/material";

import "../Profile/z_profile.css";
import { Link } from "react-router-dom";

import SearchStores from "../General/SearchStores";
import SearchCustomers from "../General/SearchCustomers";


const AdminReviewsAdd = (props) => {

  const { TRANSLATE, history, match, LANGUAGE } = props;

  const [isSaving, setSaving] = useState(false);

  const [STORE, setStore] = useState(false);
  const [CUSTOMER, setCustomer] = useState(false);
  const [PRODUCT, setProduct] = useState(false);

  const [CUSTOMER_NAME, setCustomerName] = useState('');

  const [isNew, setNew] = useState(true);

  const [isSearchStore, setSearchStore] = useState(false);
  const [isSearchCustomer, setSearchCustomer] = useState(false);
  const [isSearchProduct, setSearchProduct] = useState(false);

  useEffect(() => {

    if (isSearchCustomer || isSearchProduct || isSearchStore) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isSearchCustomer, isSearchProduct, isSearchStore])

  const [PUBLIC_REVIEW, setReview] = useState({
    type: 'provider'
  });

  const onUpdateReview = async (key, value) => {
    setReview({
      ...PUBLIC_REVIEW,
      [key]: value
    });

  }

  const onSaveReview = async (review_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      let FORM = {
        type: PUBLIC_REVIEW.type,
        store: STORE.id,
        customer: CUSTOMER.id,
        text: PUBLIC_REVIEW.text,
        rank: PUBLIC_REVIEW.rank
      };

      if (PUBLIC_REVIEW.type === 'provider') {

        if(isNew){
          FORM = {
            ...FORM,
            isNew,
            name: CUSTOMER_NAME
          }
        }

        FORM = {
          ...FORM,
          communication: PUBLIC_REVIEW.communication,
          logistic: PUBLIC_REVIEW.logistic,
          products: PUBLIC_REVIEW.products
        }
      }
      else if (PUBLIC_REVIEW.type === 'customer') {
        FORM = {
          ...FORM,
          communication: PUBLIC_REVIEW.communication,
          punctuation: PUBLIC_REVIEW.punctuation,
          use: PUBLIC_REVIEW.use
        }
      }
      else if (PUBLIC_REVIEW.type === 'product') {
        FORM = {
          ...FORM,
          product: PUBLIC_REVIEW.product.id,
          accuracy: PUBLIC_REVIEW.accuracy,
          condition: PUBLIC_REVIEW.condition,
          cleanliness: PUBLIC_REVIEW.cleanliness
        }
      }

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/reviews/create', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Create This Review', 'error');

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Review Created', 'This Review has been created successfully !!!', 'success');

      const review_id = DATA_RESPONSE.id;
      history.push('/admin/reviews/profile/' + review_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This Review', 'error');
      console.log("Review ===> ", e)
    }

  }


  if (isSaving) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving} />
    )

  }


  const {

    type,
    text,
    rank,
    communication,
    logistic,
    products,
    punctuation,
    use,
    accuracy,
    condition,
    cleanliness


  } = PUBLIC_REVIEW;



  let CAN_SAVE = false;

  if (type === 'provider') {

    if(isNew){
      CAN_SAVE = (
        CUSTOMER_NAME &&
        STORE && STORE.id &&
        text && text.length > 0 &&
        rank && parseFloat(rank) >= 1 &&
        communication && parseFloat(communication) >= 1 &&
        logistic && parseFloat(logistic) >= 1 &&
        products && parseFloat(products)
      )
    }
    else{
      CAN_SAVE = (
        CUSTOMER && CUSTOMER.id &&
        STORE && STORE.id &&
        text && text.length > 0 &&
        rank && parseFloat(rank) >= 1 &&
        communication && parseFloat(communication) >= 1 &&
        logistic && parseFloat(logistic) >= 1 &&
        products && parseFloat(products)
      )
    }
   
  }
  else if (type === 'customer') {
    CAN_SAVE = (
      CUSTOMER && CUSTOMER.id &&
      STORE && STORE.id &&
      text && text.length > 0 &&
      rank && parseFloat(rank) >= 1 &&
      communication && parseFloat(communication) >= 1 &&
      punctuation && parseFloat(punctuation) >= 1 &&
      use && parseFloat(use) >= 1
    )
  }
  else if (type === 'product') {
    CAN_SAVE = false
  }


  const storeObx = STORE || {};
  const customerObx = CUSTOMER || {};

  return (

    <>
      <Container maxWidth="lg" style={{ background: '#f0f5f9' }}>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={9} md={9}>

            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

              {/* <div className="top_box_magic">
                <h1>
                  Type
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <select
                    className={'gridyx-select'}
                    value={type}
                    label={'.'}
                    onChange={(e) => onUpdateReview('type', e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    select
                  >
                    <option value="provider">
                      Review to Provider
                    </option>
                    <option value="customer">
                      Review to Customer
                    </option>
                    <option value="product">
                      Review to Product
                    </option>
                  </select>
                </div>
              </div> */}

              <div className="flex items-center mb-6 w-full">
                <h1 className="text-2xl font-bold flex items center">
                  New Review
                </h1>
                <div className="flex items-center ml-auto">
                  <span className="text-sm mr-2">
                    New Customer
                  </span>
                  <input className="rounded-sm" type="checkbox" checked={isNew} onChange={() => setNew(!isNew)} />
                </div>
              </div>

              {isNew ? (
                <div className="input-group w-full with-icon cursor-pointer">

                  <div className='flex items-center justify-between'>
                    <label>From Customer<span className="text-bagreen-100">*</span></label>
                  </div>

                  <img className="input-icon object-cover rounded-full" src={customerObx.img || '/assets/img/avatar.svg'} alt="" />
                  <input className="focus:outline-bagreen-100 pt-0 cursor-pointer" type="text"
                    value={CUSTOMER_NAME}
                    onChange={(e)=> setCustomerName(e.target.value)}
                    placeholder={'Enter Full Name'}

                  />
                </div>
              ) : (
                <div className="input-group w-full with-icon cursor-pointer" onClick={() => setSearchCustomer(true)}>

                  <div className='flex items-center justify-between'>
                    <label>From Customer<span className="text-bagreen-100">*</span></label>
                  </div>

                  <img className="input-icon object-cover rounded-full" src={customerObx.img || '/assets/img/avatar.svg'} alt="" />
                  <input className="focus:outline-bagreen-100 pt-0 cursor-pointer" type="text" placeholder="Search Customer"
                    value={customerObx.name}
                    onClick={() => setSearchCustomer(true)}

                  />
                </div>
              )}


              <div className="input-group w-full with-icon cursor-pointer" onClick={() => setSearchStore(true)}>

                <div className='flex items-center justify-between'>
                  <label>to Store<span className="text-bagreen-100">*</span></label>
                </div>

                <img className="input-icon object-cover rounded-full" src={storeObx.img || '/assets/img/avatar.svg'} alt="" />
                <input className="focus:outline-bagreen-100 pt-0 cursor-pointer" type="text" placeholder="Search Store"
                  value={storeObx.name}
                  onClick={() => setSearchStore(true)}

                />
              </div>


              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Text
                </h1>
                <div style={{ margin: '10px 0px' }}>

                  <TextareaAutosize
                    className={'gridyx-textarea'}
                    minRows={4}
                    maxRows={10}
                    placeholder="Type here..."
                    value={text}
                    onChange={(e) => onUpdateReview('text', e.target.value)}

                  />

                </div>
              </div>

              <ReviewComponent title="Rank"
                value={rank}
                onChange={(value) => onUpdateReview('rank', value)}
                AlreadyReviewed={false}
                className={'w-full mt-7'}

              />

              <ReviewComponent title="Delivery & Pickup"
                value={logistic}
                onChange={(value) => onUpdateReview('logistic', value)}
                AlreadyReviewed={false}
                className={'w-full mt-7'}

              />

              <ReviewComponent title="Communication"
                value={communication}
                onChange={(value) => onUpdateReview('communication', value)}
                AlreadyReviewed={false}
                className={'w-full mt-7'}

              />

              <ReviewComponent title="Products"
                value={products}
                onChange={(value) => onUpdateReview('products', value)}
                AlreadyReviewed={false}
                className={'w-full mt-7'}

              />


            </div>


          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>

              <div className="top_add_cart"
                style={{ backgroundColor: '#59ad6a' }}
                onClick={() => {

                  if (!CAN_SAVE) {

                    return swal("Incomplete", "Please complete all the fields in order to create this review", 'warning')
                  }

                  swal({
                    title: 'Confirm to Save',
                    text: 'Please insert the CONFIRM to create this review',
                    content: "input",
                  })
                    .then((value) => {

                      if (value === 'CONFIRM') {

                        onSaveReview()
                      }
                      else {

                        swal('Invalid Password', 'You are not authorized to create this review', 'error');
                      }

                    });
                }}

              >
                <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Create Review
              </div>

              <Link className="top_add_cart" style={{ backgroundColor: 'var(--dark)' }} to="/admin/listing">
                <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Go Back
              </Link>

              {/* <pre>
                {JSON.stringify(
                  PUBLIC_REVIEW, null, 2
                )}
              </pre> */}


            </div>
          </Grid>
        </Grid>

      </Container>
      {isSearchStore && (
        <SearchStores {...props}
          onClose={() => setSearchStore(false)}
          onUpdateStore={setStore}

        />
      )}

      {isSearchCustomer && (
        <SearchCustomers {...props}
          onClose={() => setSearchCustomer(false)}
          onUpdateCustomer={setCustomer}

        />
      )}


    </>


  )


}



const ReviewComponent = (props) => {

  const { title, value, onChange, AlreadyReviewed, ReadOnly, className } = props;

  const ReviewImgStyle = {
    cursor: AlreadyReviewed ? 'not-allowed' : 'pointer'
  }

  const EmptyStar = 'opacity-20 hover:opacity-100';
  const FullStar = 'opacity-100 hover:opacity-100';

  if (ReadOnly) {

    return (
      <div className={className}>
        <div className="font-bold text-sm">{title}</div>
        <div className="flex gap-x-1 items-center">
          <img className={(value >= 1 ? FullStar : EmptyStar)} src="/assets/img/star.svg" style={ReviewImgStyle} />
          <img className={(value >= 2 ? FullStar : EmptyStar)} src="/assets/img/star.svg" style={ReviewImgStyle} />
          <img className={(value >= 3 ? FullStar : EmptyStar)} src="/assets/img/star.svg" style={ReviewImgStyle} />
          <img className={(value >= 4 ? FullStar : EmptyStar)} src="/assets/img/star.svg" style={ReviewImgStyle} />
          <img className={(value >= 5 ? FullStar : EmptyStar)} src="/assets/img/star.svg" style={ReviewImgStyle} />
        </div>
      </div>
    )

  }

  return (
    <div className={className}>
      <div className="font-bold text-sm">{title}</div>
      <div className="flex gap-x-1 items-center">
        <img className={(value >= 1 ? FullStar : EmptyStar)} src="/assets/img/star.svg" onClick={() => AlreadyReviewed ? null : onChange(1)} style={ReviewImgStyle} />
        <img className={(value >= 2 ? FullStar : EmptyStar)} src="/assets/img/star.svg" onClick={() => AlreadyReviewed ? null : onChange(2)} style={ReviewImgStyle} />
        <img className={(value >= 3 ? FullStar : EmptyStar)} src="/assets/img/star.svg" onClick={() => AlreadyReviewed ? null : onChange(3)} style={ReviewImgStyle} />
        <img className={(value >= 4 ? FullStar : EmptyStar)} src="/assets/img/star.svg" onClick={() => AlreadyReviewed ? null : onChange(4)} style={ReviewImgStyle} />
        <img className={(value >= 5 ? FullStar : EmptyStar)} src="/assets/img/star.svg" onClick={() => AlreadyReviewed ? null : onChange(5)} style={ReviewImgStyle} />
      </div>
    </div>
  )
}

export default AdminReviewsAdd

