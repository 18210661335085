import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import Translations from '../Docs/Metadata';

import {Helmet} from 'react-helmet-async';

import { connect } from 'react-redux';
import { onUpdateLanguage } from '../../actions';

import AuthFormLogin from './AuthFormLogin';

import Navbar from '../Admin/Navbar';
import Sidebar from '../Marketplace/Sidebar';
import Footer from '../General/Footer';
import SearchSide from '../Marketplace/SearchSide';

import MenuAreas from '../Marketplace/MenuAreas';

import './z_auth.css';

const AuthLogin = (props) => {

    const {TRANSLATE, LANGUAGE, onChangeLanguage} = props;
    const [isSidebar, setSidebar] = useState(false);
    const [isSearchbar, setSearchbar] = useState(false);
    const [isActiveMenu, setActiveMenu] = useState(false);
  
    const Landing_LOCAL = Translations(LANGUAGE) || {};
  
    const sharedProps = {
      ...props,
      isSidebar,
      setSidebar,
      isSearchbar,
      setSearchbar,
      isActiveMenu,
      setActiveMenu,
      onChangeLanguage
    };
    

    return(

        <>
            <Helmet prioritizeSeoTags>

                <html lang={LANGUAGE} />
        
                <title>{'Babonbo.com | Login'}</title>
                
            </Helmet>
          
            <SearchSide {...sharedProps} />
            <Sidebar {...sharedProps} />
            <Navbar {...sharedProps} />

            <div className="account-create-container">

                <div className="account-create-inner">
                    <div className="account-create-title">
                        <h1><span>Login</span></h1>
                        
                    </div>

                    <AuthFormLogin {...props} />
                        
                </div>
            </div> 

            <MenuAreas {...sharedProps} />
            {/* <Footer {...sharedProps} />
         */}
        </>
    )


}


const mapStateToProps = state => {
  return ({
    USER: state.user,
    PROVIDER: state.provider,
    CART: state.cart,
    PRODUCT: state.product,
    PRODUCTS: state.products,
    SEARCH: state.search
  });
}

const mapDispatchToProps = dispatch => ({
  onChangeLanguage: (lang) => dispatch(onUpdateLanguage(lang))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthLogin);

