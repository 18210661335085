import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import ReactCountryFlag from 'react-country-flag';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import StarReview from '../General/Stars';


const AdminCustomers = (props) => {

    const { TRANSLATE, history, match, location, USER } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [CUSTOMERS, setCustomers] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var current_page = parseInt(page_id);

            if (USER.admin) {
                onFetchCustomers({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else {
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

                history.push('/logout')
            }

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchCustomers = async ({ page, sort, direction }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/users', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setCustomers(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchCustomers = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchCustomers({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/users/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setCustomers(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setCustomers([]);
            setLoading(false);
        }


    }

    const onSortCustomers = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'search_name'
        }
        else if (sort === 'img') {
            return 'photo'
        }
        else if (sort === 'lang') {
            return 'language'
        }
        else if (sort === 'isCompleted') {
            return 'setup'
        }
        else {
            return sort
        }
    }

    const onChangePageCustomers = ({ page, sort, direction }) => {
        let new_url = '/admin/users/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        history.push(new_url);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/admin/users/profile/' + customer_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/admin/stores/profile/' + store_id);
    }

    const onSelectLocation = (location_id) => {
        history.push('/admin/locations/profile/' + location_id);
    }

    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }

    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectCustomer(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "img",
            label: "CUSTOMER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const customerObx = CUSTOMERS[rowIndex] || {};

                    const customer_id = customerObx.id;
                    const customer_name = customerObx.name || '';
                    const customer_img = customerObx.img || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                avatar={<Avatar alt={customer_name} src={customer_img} />}
                                label={customer_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectCustomer(customer_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >

                            <Chip label={label} size='small' />

                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "phone",
            label: "Phone",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >

                            <Chip label={label} size='small' />

                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "rating",
            label: "Rating",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "ref_code",
            label: "BABYCODE",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label) => {

                    if (!label) {
                        return (
                            <Chip size='medium' label="NO" />
                        )
                    }

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >

                            <Chip label={label} size='medium' variant='outlined' />

                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: 'city',
            label: 'LAST SEARCH',
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const customerObx = CUSTOMERS[rowIndex] || {};
                    const countryCode = customerObx.country;
                    const searchCode = customerObx.search;

                    if (countryCode === 'BB') {
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    return (
                        <div>
                            <Chip
                                avatar={<ReactCountryFlag countryCode={countryCode} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                                label={label.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => (searchCode ? onSelectLocation(searchCode) : alert('NO ID'))}
                            />

                        </div>
                    )
                }
            }

        },
        {
            name: 'currency',
            label: 'CURRENCY',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            {label === 'usd' && (
                                <>
                                    <ReactCountryFlag countryCode={'us'} style={{ marginRight: 5, fontSize: 20 }} /> USD
                                </>
                            )}
                            {label === 'eur' && (
                                <>
                                    <ReactCountryFlag countryCode={'eu'} style={{ marginRight: 5, fontSize: 20 }} /> EUR
                                </>
                            )}
                            {label === 'gbp' && (
                                <>
                                    <ReactCountryFlag countryCode={'gb'} style={{ marginRight: 5, fontSize: 20 }} /> GBP
                                </>
                            )}

                        </div>
                    )
                }
            }

        },
        {
            name: 'lang',
            label: 'LANGUAGE',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            {label === 'en' && (
                                <>
                                    <ReactCountryFlag countryCode={'gb'} style={{ marginRight: 5, fontSize: 20 }} /> English
                                </>
                            )}

                            {label === 'it' && (
                                <>
                                    <ReactCountryFlag countryCode={'it'} style={{ marginRight: 5, fontSize: 20 }} /> Italian
                                </>
                            )}

                            {label === 'fr' && (
                                <>
                                    <ReactCountryFlag countryCode={'gb'} style={{ marginRight: 5, fontSize: 20 }} /> French
                                </>
                            )}

                            {label === 'es' && (
                                <>
                                    <ReactCountryFlag countryCode={'es'} style={{ marginRight: 5, fontSize: 20 }} /> Spanish
                                </>
                            )}


                        </div>
                    )
                }
            }

        },
        {
            name: "store_id",
            label: "STORE",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const customerObx = CUSTOMERS[rowIndex] || {};

                    const store_id = customerObx.store || '';
                    const store_name = customerObx.shop || '';
                    const store_img = customerObx.insegna || '';


                    if (store_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={store_name} src={store_img} />}
                                    label={store_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => onSelectStore(store_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: 'isCompleted',
            label: 'COMPLETED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const customerObx = CUSTOMERS[rowIndex] || {};
                    const isCompleted = customerObx.setup || false;

                    if (isCompleted) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="YES" color='success' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }
                }
            }

        },
        {
            name: 'isAdmin',
            label: 'ADMIN',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const customerObx = CUSTOMERS[rowIndex] || {};
                    const isAdmin = customerObx.isAdmin;

                    if (isAdmin) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="YES" color='success' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }
                }
            }

        },
        {
            name: 'isTester',
            label: 'TESTER',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const customerObx = CUSTOMERS[rowIndex] || {};
                    const isTester = customerObx.isTester;

                    if (isTester) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="YES" color='success' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }
                }
            }

        },
        {
            name: "communication",
            label: "Communication",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "punctuation",
            label: "Punctuation",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "use",
            label: "Use of Gear",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "updatedAt",
            label: "LAST ACTIVE",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "USER CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = CUSTOMERS.map((item) => {

        moment.locale(TRANSLATE.lang);

        const NAMES = getSplittedNames(item.name) || {};
        const FIRST_NAME = NAMES.first_name ? NAMES.first_name.toUpperCase() : item.name;
        const ReferralCode = FIRST_NAME.slice(0, 6) + item?.id?.toUpperCase();

        let new_customer = {
            id: item.id,
            name: item.name,
            img: item.img,
            updatedAt: moment(item.updatedAt).fromNow(),
            createdAt: moment(item.createdAt).format('DD MMMM YYYY'),
            city: item.city,
            rating: item.rating,
            lang: item.lang || 'en',
            email: item.email,
            phone: item.phone ? '+' + item.phone : '',
            currency: item.currency || 'eur',
            isAdmin: item.isAdmin,
            isTester: item.isTester,
            isCompleted: item.setup,
            store_id: item.store,
            store_name: item.shop,
            store_img: item.insegna,
            ref_code: ReferralCode,
            communication: item.communication,
            use: item.use,
            punctuation: item.punctuation
        };

        return new_customer
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageCustomers({
                        page: tableState.page,
                        sort: onSortCustomers(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageCustomers({
                        page: tableState.page,
                        sort: onSortCustomers(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchCustomers(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchCustomers(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Customer By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Customers with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },



    };


    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Users →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}

const getSplittedNames = (full_name) => {

    const full_name_array = full_name ? full_name.split(' ') : [];
    const first_name = full_name_array[0];
    const last_name = full_name_array[full_name_array.length - 1];

    return {
        first_name,
        last_name
    }
}


export default AdminCustomers

