import React, { useState, useEffect } from "react";

import { postApiPrivate } from "../../api";

import {AnalyticsSharp, ArrowBack, CloseSharp, EyeSharp, ReaderSharp, SaveSharp, StarSharp, TrashBinSharp } from "react-ionicons";

import swal from "sweetalert";

import { Chip, Grid } from "@mui/material";

import { Ages_Array, Filter_Array } from '../Modals/Constants';

import ProductImage from '../Modals/StoreImage';

import AvailabilityProducts from '../General/AvailabilityProducts';

import { onFormatUrl } from "../../helpers/GeneralHelper";

import "../Profile/z_profile.css";

const AdminProductsDetails = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);

  const [PRODUCT, setProduct] = useState({})
  const [STORE, setStore] = useState({});

  const [isAnalytics, setAnalytics] = useState(false);

  const [isImage, setImage] = useState(false);


  useEffect(()=> {

    const product_id = match.params.id || '';
    onFetchProduct(product_id)


  }, [])
 
  useEffect(() => {

    if (isImage || isAnalytics) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isImage, isAnalytics])


  const { USER, LANGUAGE } = props;

  const [isPublished, setPublished] = useState(true);

  const [LangName, setLangName] = useState('en');
  const [EN, setEN] = useState({});
  const [IT, setIT] = useState({});
  const [FR, setFR] = useState({});
  const [ES, setES] = useState({});

  //Product
  const [ID, setID] = useState(null);
  const [NAME, setName] = useState('');
  const [ABOUT, setAbout] = useState('');
  const [MARCA, setMarca] = useState('');

  const [PRICE_DAY, setPriceDay] = useState(null);
  const [PRICE_WEEK, setPriceWeek] = useState(null);
  const [PRICE_MONTH, setPriceMonth] = useState(null);

  const [QUANTITY, setQuantity] = useState(null);

  const [CATEGORY, setCategory] = useState('passeggini');
  const [AGES, setAges] = useState('');

  //Images
  const [FIRST_IMAGE, setFirstImage] = useState(null);
  const [SECOND_IMAGE, setSecondImage] = useState(null);
  const [THIRD_IMAGE, setThirdImage] = useState(null);
  const [FOURTH_IMAGE, setFourthImage] = useState(null);

  //Based
  const [FIRST_BASED, setFirstBased] = useState(null);
  const [SECOND_BASED, setSecondBased] = useState(null);
  const [THIRD_BASED, setThirdBased] = useState(null);
  const [FOURTH_BASED, setFourthBased] = useState(null);


  //Camera + Picker
  const [SELECTED_INDEX, setSelectedIndex] = useState(null);
  const [CategoryImage, setCategoryImage] = useState(null);

  const onFetchProduct = async (id) => {

    // alert(id);

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setLoading(true);

    const FORM = { id: id };

    const DATA_RESPONSE = await postApiPrivate('/admin/products/details', FORM, JWT);

    if (DATA_RESPONSE.error) {
      console.log("ERROR FROM API LOADING SINGLE PRODUCT", DATA_RESPONSE.error);
      setLoading(false);
      return
    }

    //Setup Product
    const PRODUCT_DATA = DATA_RESPONSE || {};
    const STORE_DATA = DATA_RESPONSE.store || {};

    setProduct(PRODUCT_DATA);
    setStore(STORE_DATA)

    PRODUCT_DATA.id && setID(PRODUCT_DATA.id);
    PRODUCT_DATA.name && setName(PRODUCT_DATA.name);
    PRODUCT_DATA.about && setAbout(PRODUCT_DATA.about);
    // PRODUCT_DATA.marca && setMarca(PRODUCT_DATA.marca);
    // PRODUCT_DATA.brand && setBrand(PRODUCT_DATA.brand);

    PRODUCT_DATA.price_day && setPriceDay(PRODUCT_DATA.price_day.toString());
    PRODUCT_DATA.price_week && setPriceWeek(PRODUCT_DATA.price_week.toString());
    PRODUCT_DATA.price_month && setPriceMonth(PRODUCT_DATA.price_month.toString());

    PRODUCT_DATA.first_img && setFirstImage(PRODUCT_DATA.first_img);
    PRODUCT_DATA.second_img && setSecondImage(PRODUCT_DATA.second_img);
    PRODUCT_DATA.third_img && setThirdImage(PRODUCT_DATA.third_img);
    PRODUCT_DATA.fourth_img && setFourthImage(PRODUCT_DATA.fourth_img);

    PRODUCT_DATA.quantity && setQuantity(PRODUCT_DATA.quantity.toString());
    PRODUCT_DATA.ages && setAges(PRODUCT_DATA.ages || []);

    setPublished(PRODUCT_DATA.isPublished);

    const ProductEN = PRODUCT_DATA.en || {};
    const ProductIT = PRODUCT_DATA.it || {};
    const ProductFR = PRODUCT_DATA.fr || {};
    const ProductES = PRODUCT_DATA.es || {};

    // if(PRODUCT_DATA.marca){
    //     setBrandInput(PRODUCT_DATA.marca);
    // }

    setEN(ProductEN);
    setIT(ProductIT);
    setFR(ProductFR);
    setES(ProductES);


    //SETUP CATEGORY
    const current_category = PRODUCT_DATA.category;
    if (current_category) {
      var category_index = Filter_Array.findIndex((item) => item && (item.code === current_category));
      if (category_index != -1) {
        var category_code = Filter_Array[category_index].code;
        var category_icon = Filter_Array[category_index].icon;
        setCategory(category_code);
        setCategoryImage(category_icon);
      }
    }

    setLoading(false);
    console.log("DATA_ ==>", DATA_RESPONSE)
  }

  const onSaveProduct = async () => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    //Check Params

    if(!FIRST_IMAGE || FIRST_IMAGE.length==0){
      return swal('First Photo Missing', 'You need to add at least one picture', 'warning');
    }

    if(NAME.length==0){
      return swal('Product Name Missing', 'You need to add a title to this product', 'warning');
    }

    if(ABOUT.length==0){
      return swal('Product Description Missing', 'You need to add a description to this product', 'warning');
    }

    if(CATEGORY.length==0){
      return swal('Product Category Missing', 'You need to add a category to this product', 'warning');
    }

    if(CATEGORY === 'consumable'){
      if(parseFloat(PRICE_DAY)==0){
        return swal('Consumable Price Missing', 'You need to add a price to this consumable product', 'warning');
      }
    }
    else{

      if(!parseFloat(PRICE_DAY)){
        return swal('Daily Price Missing', 'You need to add a daily price to this product', 'warning');
      }
  
      if(!parseFloat(PRICE_WEEK)){
        return swal('Weekly Price Missing', 'You need to add a weekly price to this product', 'warning');
      }
  
      if(!parseFloat(PRICE_MONTH)){
        return swal('Monthly Price Missing', 'You need to add a monthly price to this product', 'warning');
      }
  
    }
    

    let FORM = {
      id: ID,
      store: STORE.id,
      name: NAME,
      about: ABOUT,
      marca: MARCA,
      category: CATEGORY,
      ages: AGES,
      price_day: parseFloat(PRICE_DAY),
      quantity: parseInt(QUANTITY),
      first_based: FIRST_BASED,
      second_based: SECOND_BASED,
      third_based: THIRD_BASED,
      fourth_based: FOURTH_BASED,

      // marca: MARCA,
      // brand: BRAND,
      en: EN,
      it: IT,
      fr: FR,
      es: ES,

      translate: true,

      isPublished: true
    }

    if (CATEGORY !== 'consumable') {
      FORM = {
        ...FORM,
        price_week: parseFloat(PRICE_WEEK),
        price_month: parseFloat(PRICE_MONTH),
      }
    }


    //return alert(JSON.stringify(FORM, null, 2));

    console.log("FORM TO SAVE ===> ", FORM);


    setSaving(true);

    try {

      const DATA_RESPONSE = await postApiPrivate('/admin/products/edit', FORM, JWT);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API SAVING PRODUCT", DATA_RESPONSE.error);
        setSaving(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      swal('Product Edited','Your product has been updated successfully', 'success');

      onFetchProduct(ID)

      setSaving(false)

    }
    catch (e) {

      setSaving(false);
    }



  }

  const onDeleteProduct = async () => {

    swal({
      title: TRANSLATE.ask_sure,
      text: TRANSLATE.tool_once_proceed.replace('{{TYPE}}', TRANSLATE.wd_product),
      icon: "warning",
      buttons: [TRANSLATE.btn_cancel, TRANSLATE.btn_delete],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

          onDeleteProductBackend();

        }

      });

  }

  const onDeleteProductBackend = async () => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    const product_id = match.params.id;

    setSaving(true)

    try {

      var DATA_RESPONSE = await postApiPrivate('/admin/products/delete', { id: product_id }, JWT);

      if (DATA_RESPONSE.error) {

        setSaving(false)
        console.log("ERROR FROM API DELETE PRODUCT", DATA_RESPONSE.error);
        return
      }

      history.replace('/admin/products');

      setSaving(false)

    }
    catch (e) {

      console.log("ERROR FROM API DELETE PRODUCT", e);

      setSaving(false)
    }

  }

  const onChangePrice = (type, value) => {

    var price_value = value.replace(/[^0-9.]/g, '');

    if (type === 'price_day') {
      return setPriceDay(price_value);
    }
    else if (type === 'price_week') {
      return setPriceWeek(price_value);
    }
    else if (type === 'price_month') {
      return setPriceMonth(price_value);
    }
    else if (type === 'quantity') {
      var quantity_value = value.replace(/[^0-9]/g, '');
      return setQuantity(quantity_value);
    }


  }

  const onSelectCategory = (value) => {

    if (!value) {
      return alert('Wrong Category');
    }

    const CategoryIndex = Filter_Array.findIndex((item) => item && (item.code === value));
    if (CategoryIndex != -1) {
      const CategoryObx = Filter_Array[CategoryIndex] || '';
      setCategoryImage(CategoryObx.icon);
    }

    setCategory(value);

  }

  const onSelectAge = (age) => {

    const product_ages = AGES || [];

    if (product_ages.includes(age)) {

      const filtred_ages = product_ages.filter((item) => item !== age);
      setAges(filtred_ages);

    }
    else {
      setAges([...product_ages, age]);
    }

  }

  const onUpdateTranslations = (type, lang, value) => {


    if (type === 'name') {

      if (lang === 'en') {
        setName(value)
      }
      else if (lang === 'it') {
        setIT({ ...IT, name: value });
      }
      else if (lang === 'fr') {
        setFR({ ...FR, name: value });
      }
      else if (lang === 'es') {
        setES({ ...ES, name: value });
      }

    }
    else if (type === 'about') {

      if (lang === 'en') {
        setAbout(value)
      }
      else if (lang === 'it') {
        setIT({ ...IT, about: value });
      }
      else if (lang === 'fr') {
        setFR({ ...FR, about: value });
      }
      else if (lang === 'es') {
        setES({ ...ES, about: value });
      }

    }


  }

  const onSelectImage = (image) => {

    setSelectedIndex(null);

    if(SELECTED_INDEX == 0){
      setFirstImage(image.src);
      setFirstBased(image.id);
    }
    else if(SELECTED_INDEX == 1){
      setSecondImage(image.src);
      setSecondBased(image.id);
    }
    else if(SELECTED_INDEX == 2){
      setThirdImage(image.src);
      setThirdBased(image.id);
    }
    else if(SELECTED_INDEX == 3){
      setFourthImage(image.src);
      setFourthBased(image.id);
    }

    // alert(JSON.stringify({
    //   image,
    //   SELECTED_INDEX
      
    // }, null, 2));
  }

  const onPickerAdd = (index) => {
    console.log("PICKER IMAGE ADD SELECTED ===> ", index);
    //Check if Index has Previews Empty

    var corrected_index = CorrectedPickerIndex(index);

    console.log("PICKER IMAGE ADD SELECTED ===> ", corrected_index);

    setSelectedIndex(corrected_index);
    setImage(true);

  }

  const CorrectedPickerIndex = (index) => {

    if (!FIRST_IMAGE) {
      return 0;
    }

    if (!SECOND_IMAGE) {
      return 1;
    }

    if (!THIRD_IMAGE) {
      return 2;
    }

    if (!FOURTH_IMAGE) {
      return 3;
    }


    return index;

  }


  if (isSaving) {
    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
      </div>
    );
  }

  const isMasterLoading = props.isLoading;
  if (isMasterLoading) {

    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
      </div>
    )
  }

  const ShopObx = STORE || {};
  const ShopCurrency = ShopObx.currency || 'eur';
  const ShopSymbol = USER.shop_symbol || '€';

  let CurrentProductName = onFetchTranslation('name', LangName, NAME, EN, IT, FR, ES)
  let CurrentProductAbout = onFetchTranslation('about', LangName, ABOUT, EN, IT, FR, ES);
  let CurrentProductImage = '';

  if(FOURTH_IMAGE){
    CurrentProductImage = FOURTH_IMAGE
  }
  if(THIRD_IMAGE){
    CurrentProductImage = THIRD_IMAGE
  }
  if(SECOND_IMAGE){
    CurrentProductImage = SECOND_IMAGE
  }
  if(FIRST_IMAGE){
    CurrentProductImage = FIRST_IMAGE
  }

  const RATING = parseFloat(PRODUCT.rating).toFixed(1);
  const REVIEWS = parseInt(PRODUCT.reviews);
  const isDeleted = PRODUCT.isDeleted || false;
  
  const sharedProps = {
    ...props
  }
  


  return (

    <>

      
      {isImage && (
        <ProductImage {...props}
          onClose={() => setImage(false)}
          onSelectImage={onSelectImage}
          TYPE={"products"}

        />
      )}

      {isAnalytics && (
          <AvailabilityProducts {...sharedProps}
                onClose={()=> setAnalytics(false)}  
                product={PRODUCT}  
          />
      )}


      <Grid container spacing={2}>

       

        <Grid item xs={12} lg={8} md={6}>

          <div className="w-full bg-white" style={{ padding: 20 }}>
            <div className="flex items-center mb-6 w-full">
              <h1 className="text-2xl font-bold flex items center">
                {/* <img className="mr-3 cursor-pointer" src="/assets/img/arrow-left.svg" onClick={()=> history.goBack()}/> */}
                {TRANSLATE.wd_edit_product}
              </h1>
              <div className="flex items-center ml-auto">
                <span className="text-sm mr-2">
                  {TRANSLATE.wd_published}
                </span>
                <input className="rounded-sm" type="checkbox" checked={isPublished} onChange={() => setPublished(!isPublished)} />
              </div>
            </div>

            <div className="flex md:flex-nowrap flex-wrap w-full" >

              <div className="w-full lg:pl-8">
                <div className="flex flex-col w-full pt-8">

                  <div className="flex w-full">

                    <div className="input-group w-full with-icon">
                      <div className='flex items-center justify-between'>
                        <label>Product Images<span className="text-bagreen-100">*</span></label>
                      </div>

                      <div className="flex items-center flex-wrap">

                        {FIRST_IMAGE ? (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 border-zinc-200 border relative rounded-lg overflow-hidden">
                            <div className="w-[20px] h-[20px] bg-white flex items-center rounded-full hover:border-zinc-400 justify-center cursor-pointer absolute top-[5px] left-[5px] border border-zinc-200 z-20">
                              <img src="/assets/img/close.svg" className="w-[10px] h-[10px]" onClick={() => {
                                setFirstImage(null);
                                setFirstBased('false');
                              }} />
                            </div>
                            <img className="object-contain w-full h-full absolute top-0 left-0" src={FIRST_IMAGE} />
                          </div>
                        ) : (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 cursor-pointer hover:border-zinc-400 relative rounded-lg border border-dashed bg-zinc-100 border-zinc-200 overflow-hidden flex items-center justify-center" onClick={() => onPickerAdd(0)}>
                            <div className="text-center flex flex-col items-center justify-center">
                              <img className="w-[24px] h-[24px]" src="/assets/img/image.svg" />
                              <div className="font-semibold text-xs">
                                {TRANSLATE.btn_upload_picture}
                              </div>
                            </div>
                          </div>
                        )}

                        {SECOND_IMAGE ? (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 border-zinc-200 border relative rounded-lg overflow-hidden">
                            <div className="w-[20px] h-[20px] bg-white flex items-center rounded-full hover:border-zinc-400 justify-center cursor-pointer absolute top-[5px] left-[5px] border border-zinc-200 z-20">
                              <img src="/assets/img/close.svg" className="w-[10px] h-[10px]" onClick={() => {
                                setSecondImage(null);
                                setSecondBased('false');
                              }} />
                            </div>
                            <img className="object-contain w-full h-full absolute top-0 left-0" src={SECOND_IMAGE} />
                          </div>
                        ) : (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 cursor-pointer hover:border-zinc-400 relative rounded-lg border border-dashed bg-zinc-100 border-zinc-200 overflow-hidden flex items-center justify-center" onClick={() => onPickerAdd(1)}>
                            <div className="text-center flex flex-col items-center justify-center">
                              <img className="w-[24px] h-[24px]" src="/assets/img/image.svg" />
                              <div className="font-semibold text-xs">
                                {TRANSLATE.btn_upload_picture}
                              </div>
                            </div>
                          </div>
                        )}

                        {THIRD_IMAGE ? (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 border-zinc-200 border relative rounded-lg overflow-hidden">
                            <div className="w-[20px] h-[20px] bg-white flex items-center rounded-full hover:border-zinc-400 justify-center cursor-pointer absolute top-[5px] left-[5px] border border-zinc-200 z-20">
                              <img src="/assets/img/close.svg" className="w-[10px] h-[10px]" onClick={() => {
                                setThirdImage(null);
                                setThirdBased('false');
                              }} />
                            </div>
                            <img className="object-contain w-full h-full absolute top-0 left-0" src={THIRD_IMAGE} />
                          </div>
                        ) : (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 cursor-pointer hover:border-zinc-400 relative rounded-lg border border-dashed bg-zinc-100 border-zinc-200 overflow-hidden flex items-center justify-center" onClick={() => onPickerAdd(2)}>
                            <div className="text-center flex flex-col items-center justify-center">
                              <img className="w-[24px] h-[24px]" src="/assets/img/image.svg" />
                              <div className="font-semibold text-xs">
                                {TRANSLATE.btn_upload_picture}
                              </div>
                            </div>
                          </div>
                        )}

                        {FOURTH_IMAGE ? (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 border-zinc-200 border relative rounded-lg overflow-hidden">
                            <div className="w-[20px] h-[20px] bg-white flex items-center rounded-full hover:border-zinc-400 justify-center cursor-pointer absolute top-[5px] left-[5px] border border-zinc-200 z-20">
                              <img src="/assets/img/close.svg" className="w-[10px] h-[10px]" onClick={() => {
                                setFourthImage(null);
                                setFourthBased('false');
                              }} />
                            </div>
                            <img className="object-contain w-full h-full absolute top-0 left-0" src={FOURTH_IMAGE} />
                          </div>
                        ) : (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 cursor-pointer hover:border-zinc-400 relative rounded-lg border border-dashed bg-zinc-100 border-zinc-200 overflow-hidden flex items-center justify-center" onClick={() => onPickerAdd(3)}>
                            <div className="text-center flex flex-col items-center justify-center">
                              <img className="w-[24px] h-[24px]" src="/assets/img/image.svg" />
                              <div className="font-semibold text-xs">
                                {TRANSLATE.btn_upload_picture}
                              </div>
                            </div>
                          </div>
                        )}



                      </div>

                    </div>

                  </div>

                  <div className="input-group w-full with-icon cursor-not-allowed">
                    <div className='flex items-center justify-between'>
                      <label>Product Owner<span className="text-bagreen-100">*</span></label>
                    </div>

                    <img className="input-icon object-cover rounded-full" src={ShopObx.img || '/assets/img/avatar.svg'} />
                    <input className="focus:outline-bagreen-100 pt-0 cursor-not-allowed" type="text" placeholder=""
                      onChange={(e) => onUpdateTranslations('name', LangName, e.target.value)}
                      value={ShopObx.name}
                      disabled

                    />
                  </div>

                  <div className="input-group w-full with-icon">
                    <div className='flex items-center justify-between'>
                      <label>{TRANSLATE.wd_product_name}<span className="text-bagreen-100">*</span></label>
                      <div className='flex mr-5'>
                        <img src="/assets/languages/en.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }} onClick={() => setLangName('en')} />
                        <img src="/assets/languages/it.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5) }} onClick={() => setLangName('it')} />
                        <img src="/assets/languages/fr.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5) }} onClick={() => setLangName('fr')} />
                        <img src="/assets/languages/es.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5) }} onClick={() => setLangName('es')} />
                      </div>
                    </div>

                    <img className="input-icon" src="/assets/img/search.svg" />
                    <input className="focus:outline-bagreen-100 pt-0" type="text" placeholder=""
                      onChange={(e) => onUpdateTranslations('name', LangName, e.target.value)}
                      value={CurrentProductName}

                    />
                  </div>


                  <div className="flex md:flex-nowrap flex-wrap gap-x-6">

                    {(CATEGORY !== 'consumable') ? (
                      <>
                        <div className="input-group w-full md:max-w-[300px]">
                          <label>{TRANSLATE.wd_daily_price} {ShopSymbol} <span className="text-bagreen-100">*</span></label>
                          {/*<div className="input-more">+</div>*/}

                          <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                            onChange={(e) => onChangePrice('price_day', e.target.value)} value={PRICE_DAY ? PRICE_DAY : ''}

                          />
                          {/*<div className="input-less">-</div>*/}
                        </div>
                        <div className="input-group w-full  md:max-w-[300px]">
                          <label>{TRANSLATE.wd_weekly_price} {ShopSymbol} <span className="text-bagreen-100">*</span></label>
                          {/*<div className="input-more">+</div>*/}
                          <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                            onChange={(e) => onChangePrice('price_week', e.target.value)} value={PRICE_WEEK ? PRICE_WEEK : ''}

                          />
                          {/*<div className="input-less">-</div>*/}
                        </div>
                        <div className="input-group w-full  md:max-w-[300px]">
                          <label>{TRANSLATE.wd_monthly_price} {ShopSymbol} <span className="text-bagreen-100">*</span></label>
                          {/*<div className="input-more">+</div>*/}
                          <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                            onChange={(e) => onChangePrice('price_month', e.target.value)} value={PRICE_MONTH ? PRICE_MONTH : ''}

                          />
                          {/*<div className="input-less">-</div>*/}
                        </div>
                      </>
                    ) : (
                      <div className="input-group w-full md:max-w-[300px]">
                        <label className='capitalize'>{TRANSLATE.wd_price} {ShopSymbol} <span className="text-bagreen-100">*</span></label>
                        {/*<div className="input-more">+</div>*/}

                        <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                          onChange={(e) => onChangePrice('price_day', e.target.value)} value={PRICE_DAY ? PRICE_DAY : ''}

                        />
                        {/*<div className="input-less">-</div>*/}
                      </div>
                    )}

                  </div>

                  <div className="input-group w-full with-icon">
                    <label className='capitalize'>{TRANSLATE.wd_category}<span className="text-bagreen-100">*</span></label>
                    <img className="input-icon" src={CategoryImage || "/assets/img/icons/stroller.svg"} />
                    <select className="focus:outline-bagreen-100 pt-0" value={CATEGORY} onChange={(e) => onSelectCategory(e.target.value)}>

                      {Filter_Array.map((item, index) => {

                        const Cat_Name = item[LANGUAGE];
                        const Cat_Code = item.code;

                        return (
                          <option value={Cat_Code} key={index}>
                            {Cat_Name}
                          </option>
                        )

                      })}

                    </select>

                  </div>

                  <hr className="my-6" />
                  <div className="input-group w-full">
                    <div className='flex align-items-center justify-content-between'>
                      <label>{TRANSLATE.wd_product_about}<span className="text-bagreen-100">*</span></label>
                      <div className='flex mr-5'>
                        <img src="/assets/languages/en.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }} onClick={() => setLangName('en')} />
                        <img src="/assets/languages/it.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5) }} onClick={() => setLangName('it')} />
                        <img src="/assets/languages/fr.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5) }} onClick={() => setLangName('fr')} />
                        <img src="/assets/languages/es.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5) }} onClick={() => setLangName('es')} />
                      </div>
                    </div>
                    <textarea className="focus:outline-bagreen-100 " placeholder="About the product"
                      onChange={(e) => onUpdateTranslations('about', LangName, e.target.value)}
                      value={CurrentProductAbout}
                      style={{ minHeight: 500 }}

                    />
                  </div>

                  <div className="flex md:flex-nowrap flex-wrap gap-x-6">
                    <div className="input-group w-full md:max-w-[300px]">
                      <label className='capitalize'>{TRANSLATE.wd_quantity} <span className="text-bagreen-100">*</span></label>
                      {/*<div className="input-more">+</div>*/}

                      <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                        onChange={(e) => onChangePrice('quantity', e.target.value)} value={QUANTITY ? QUANTITY : ''}

                      />
                      {/*<div className="input-less">-</div>*/}
                    </div>

                  </div>


                  <div className="flex justify-between flex-wrap gap-[10px] md:gap-[20px]">
                    <div className="w-full font-semibold capitalize">
                      {TRANSLATE.wd_age}
                    </div>
                    {Ages_Array.map((item, index) => {

                      const isAdded = AGES && AGES.includes(item.code);

                      if (isAdded) {

                        return (
                          <div className="w-[calc(50%-10px)] text-sm cursor-pointer hover:border-zinc-400 border rounded-lg flex p-4 border-bagreen-100 bg-bagreen-50" key={index} onClick={() => onSelectAge(item.code)}>
                            <img className="mr-2" src="/assets/img/check.svg" />
                            <div>
                              <div className="font-semibold">
                                {item[LANGUAGE]}
                              </div>
                              <div className="font-normal text-xs">
                                {item.min}-{item.max} cm
                              </div>
                            </div>
                          </div>
                        )
                      }
                      else {

                        return (
                          <div className="w-[calc(50%-10px)] text-sm border-zinc-200 cursor-pointer hover:border-zinc-400 border rounded-lg flex p-4" key={index} onClick={() => onSelectAge(item.code)}>
                            <img className="mr-2 grayscale group-hover:grayscale-0" src="/assets/img/check.svg" />
                            <div>
                              <div className="font-semibold">
                                {item[LANGUAGE]}
                              </div>
                              <div className="font-normal text-xs">
                                {item.min}-{item.max} cm
                              </div>
                            </div>
                          </div>
                        )
                      }

                    })}


                  </div>

                  <div style={{ height: 200 }}></div>


                </div>
              </div>

            </div>

          </div>


        </Grid>


        <Grid item xs={12} lg={4} md={6}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 110 }}>
            
          <div className="top_store_card">
              <div className="top_store_profile">

                {FIRST_IMAGE ? (
                  <img className="border cursor-pointer" src={CurrentProductImage} style={{ width: '100%', height: '100%' }}  />
                ) : (
                  <div className="border cursor-pointer" src={'/assets/img/avatar.svg'} style={{ width: '100%', height: '100%' }} />
                )}

                {/* <div className="font-bold text-lg mt-5">{CurrentProductName}</div>
                <StarRating rate={rating} reviews={reviews} /> */}

              </div>

              <div className="top_store_stats">
                <div>
                  
                  {isDeleted ? (
                     <div className="font-bold text-lg" style={{color: 'red'}}>Deleted</div>
                  ) : (
                    <>

                      {isPublished ? (
                        <div className="font-bold text-lg" style={{color: 'green'}}>Published</div>
                      ) : (
                        <div className="font-bold text-lg" style={{color: 'orange'}}>Unpublished</div>
                      )}

                    </>
                  )}
                  
                  
                  <p>Status</p>
                </div>
                <div>
                  <div className="font-bold text-lg">{RATING || 0}</div>
                  <p>Rating</p>
                </div>
                <div>
                  <div className="font-bold text-lg">{REVIEWS || 0}</div>
                  <p>Reviews</p>
                </div>
              </div>

            </div>

            <div className="row justify-content-center">
              <a className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} href={'https://www.babonbo.com/en/' + onFormatUrl(CurrentProductName) + '/bp/' + ID} target="_blank">
                <EyeSharp color={'#fff'} style={{ marginRight: 10 }} /> Live Product
              </a>

              <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setAnalytics(true)}>
                <AnalyticsSharp color={'#fff'} style={{ marginRight: 10 }} /> Analytics
              </div>

              <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&product=' + ID)}>
                <ReaderSharp color={'#fff'} style={{ marginRight: 10 }} /> Orders
              </div>

              <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/reviews/0?sort=updatedAt&dir=desc&product=' + ID)}>
                <StarSharp color={'#fff'} style={{ marginRight: 10 }} /> Reviews
              </div>

              {!isDeleted && (
                <>
                  <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'red', color: '#ffffff' }} onClick={() => onDeleteProduct()}>
                    <TrashBinSharp color={'#fff'} style={{ marginRight: 10 }} /> Delete Product
                  </div>

                  <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'green', color: '#ffffff' }} onClick={() => onSaveProduct()}>
                    <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Save Product
                  </div>
                </>
              )}

              

            </div>
            

          </div>
        </Grid>

      </Grid>

    </>
  )


}


const onFetchTranslation = (type, lang, value, en, it, fr, es, live) => {

  let ProductName = '';
  let ProductAbout = '';

  if (type === 'name') {

    if (lang === 'en') {
      ProductName = value;
    }
    else if (lang === 'it') {
      ProductName = it.name || ''
    }
    else if (lang === 'fr') {
      ProductName = fr.name || ''
    }
    else if (lang === 'es') {
      ProductName = es.name || ''
    }

    if (live) {

      if (!ProductName) {
        ProductName = value;
      }
    }


    return ProductName
  }
  else if (type === 'about') {

    if (lang === 'en') {
      ProductAbout = value;
    }
    else if (lang === 'it') {
      ProductAbout = it.about || ''
    }
    else if (lang === 'fr') {
      ProductAbout = fr.about || ''
    }
    else if (lang === 'es') {
      ProductAbout = es.about || ''
    }

    if (!ProductAbout) {
      ProductAbout = value;
    }

    return ProductAbout
  }


  return '';
}

export default AdminProductsDetails
