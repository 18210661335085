import React, { useState } from "react";

import LoaderMaster from "../Loaders/Loaders";

import { postApiPrivate } from "../../api";

import { CloseSharp, SaveSharp } from "react-ionicons";

import swal from "sweetalert";

import { Grid, TextareaAutosize, TextField } from "@mui/material";

import { Link } from "react-router-dom";

import "../Profile/z_profile.css";
import SearchStores from "../General/SearchStores";

const AdminApprovalsAdd = (props) => {

  const { TRANSLATE, history, LANGUAGE } = props;

  const [isSaving, setSaving] = useState(false);
  const [isSearchStore, setSearchStore] = useState(false);

  const [STORE, setStore] = useState(false);
  const [STATUS, setStatus] = useState('pending');
  const [MESSAGE, setMessage] = useState('');


  const onSaveApproval = async () => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = {
        store: STORE.id,
        status: STATUS,
        message: MESSAGE
      };

      console.log("SEARCH ===> ", FORM);


      const DATA_RESPONSE = await postApiPrivate('/admin/approvals/create', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Create This Approval', 'error');

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Approval Created', 'This Approval has been created successfully !!!', 'success');

      const approval_id = DATA_RESPONSE.id;
      history.push('/admin/approvals/profile/' + approval_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This Approval', 'error');
    }

  }

  const onUpdateStore = (store) => {

    if(!store){
        return null
    }
    setStore(store);
}


  if (isSaving) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving} />
    )

  }


  const CAN_SAVE = (
    STORE && STORE.id &&
    STATUS && STATUS.length > 0
  )


  return (
    <>
      {isSearchStore && (
          <SearchStores {...props} 
                      onClose={()=> setSearchStore(false)}
                      onUpdateStore={onUpdateStore}
          
          />
      )}
      <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9} md={9}>

            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>


              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Store
                </h1>
                {STORE ? (
                  <div style={{ margin: '10px 0px' }}>
                    <div className={'flex items-center cursor-pointer'} onClick={()=> setSearchStore(true)}>
                      <img className="w-[50px] h-[50px] rounded-full object-cover mr-5" src={STORE.img} />
                      {STORE.name}
                    </div>
                  </div>
                ) : (
                  <div style={{ margin: '10px 0px' }}>
                    <div className={'flex items-center cursor-pointer'} onClick={()=> setSearchStore(true)}>
                      <img className="w-[50px] h-[50px] rounded-full object-cover mr-5" src="/assets/img/avatar.svg" />
                      Click to select a provider +
                    </div>
                  </div>
                )}
                
              </div>

              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Status
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <select className='h-[60px]' value={STATUS} onChange={(e)=> setStatus(e.target.value)}>
                    <option value={'pending'}>In Review</option>
                    <option value={'approved'}>Accepted</option>
                    <option value={'rejected'}>Rejected</option>
                  </select>
                </div>
              </div>

              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Message to User
                </h1>
                <div style={{ margin: '10px 0px' }}>

                  <TextareaAutosize
                    className={'gridyx-textarea'}
                    minRows={4}
                    maxRows={10}
                    placeholder="Type here..."
                    value={MESSAGE}
                    onChange={(e) => setMessage( e.target.value)}

                  />

                </div>
              </div>

            </div>


          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>

              <div className="top_add_cart"
                style={{ backgroundColor: '#59ad6a' }}
                onClick={() => {

                  if (!CAN_SAVE) {

                    return swal("Incomplete", "Please complete all the fields in order to create this approval", 'warning')
                  }

                  swal({
                    title: 'Confirm to Save',
                    text: 'Please insert the CONFIRM to create this approval',
                    content: "input",
                  })
                    .then((value) => {

                      if (value === 'CONFIRM') {

                        onSaveApproval()
                      }
                      else {

                        swal('Invalid Password', 'You are not authorized to create this approval', 'error');
                      }

                    });
                }}

              >
                <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Create Approval
              </div>

              <Link className="top_add_cart" style={{ backgroundColor: 'var(--dark)' }} to="/admin/approvals">
                <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Go Back
              </Link>



            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )


}





export default AdminApprovalsAdd

