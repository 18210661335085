import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {API_ROOT} from '../../api';
import {DefaultAlert} from '../../alerts';

import Cookies from 'universal-cookie';

import './z_auth.css';

const AuthFormLogin = (props) => {

  const {TRANSLATE, history, onStartUser} = props;

  const [EMAIL, setEmail] = useState('');
  const [PASSWORD, setPassword] = useState('');
  const [ISVISIBLE, setVisible] = useState(false);
  const [ISLOADING, setLoading] = useState(false);

  const onKeyUp = (event) =>{

      event.preventDefault();
     

      if (event.keyCode === 13) {

          event.target.blur();
          onLogin();
          
      }
  }

  const onLogin = () => {

    if(!isValidEmail(EMAIL)){
       return DefaultAlert('Login', TRANSLATE.error_invalid_email)
    }

    var form = {
        email: EMAIL.trim(),
        password: PASSWORD.trim(),
        lang: TRANSLATE.lang
    }

    setLoading(true);

    axios.post(API_ROOT+'/users/login',form).then(response => {
        

        setLoading(false);

        localStorage.setItem('jwt_token',response.data.jwt_token);
        
        const cookies = new Cookies();
        cookies.set('jwt_token', response.data.jwt_token, { path: '/' });
     
        const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
        if(isNativeApp){
            const webData = {
                type: 'LOGIN',
                data: response.data.jwt_token
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(webData));
        }

        onStartUser();

        history.push('/');

    })
    .catch(error => {

        setLoading(false);

        if(!error || !error.response){
            return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
        }
    
        var error_data = error.response.data;
        var error_message = error_data.error;

        if(error_message === 'missing_password'){
            return DefaultAlert('Login', TRANSLATE.error_missing_password)
        }
        else if(error_message === 'missing_email'){
            return DefaultAlert('Login', TRANSLATE.error_invalid_email)
        }
       
        return DefaultAlert('Login', TRANSLATE.error_invalid_credentials)
    });
  }
  

  return(
            <>
                  <div className="account-create-form">
                
                      
                      <div className="form-group">
                          <input placeholder={'Email'} 
                                 type="text" 
                                 name="email" 
                                 value={EMAIL} 
                                 onChange={(e)=> setEmail(e.target.value)} 
                                 onKeyUp={(e)=> onKeyUp(e)}
                                 
                          />
                      </div>
                      
                      <div className="form-group">
                          <input placeholder={'Password'} 
                                 type={ISVISIBLE ? 'text' : 'password'} 
                                 name="password"  
                                 value={PASSWORD} 
                                 onChange={(e)=> setPassword(e.target.value)}
                                 onKeyUp={(e)=> onKeyUp(e)}
                                 
                          />
                          <img className="show" src={ISVISIBLE ? "/assets/img/hide.png" : "/assets/img/preview.png"} onClick={()=>setVisible(!ISVISIBLE)}/>
                      </div>
                      
                      {ISLOADING ? (

                          <div className="signup-btn btn-locked not-allowed">
                               <i className="fa fa-spinner fa-spin" style={{marginRight: '10px'}}/> 
                               Loading...
                          </div>

                      ) : (

                          <div className="signup-btn" onClick={e => onLogin(e)} style={{background: '#26BFBF'}}>
                               <strong>Login</strong>
                          </div>

                      )}
                      
                     

                  </div>
              
              </>
      )

}

const isValidEmail = (email) => {

	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
  
}


export default AuthFormLogin
