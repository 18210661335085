import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import ReactCountryFlag from 'react-country-flag';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import StarReview from '../General/Stars';

import { EyeSharp } from 'react-ionicons'


const AdminStores = (props) => {

    const { TRANSLATE, history, match, location } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [STORES, setStores] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var filter_country = routeParams.country || null;
            var filter_status = routeParams.status || null;
            var filter_store = routeParams.store || null;

            var current_page = parseInt(page_id);

            onFetchStores({
                page: current_page,
                sort: sort_id,
                direction: direction_id,
                filter_country,
                filter_status,
                filter_store
            });

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchStores = async ({ page, sort, direction, filter_store, filter_country, filter_status }) => {

        var partner_id = match.params.id;

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                status: filter_status,
                store: filter_store,
                country: filter_country,
                l: LIMIT,
                partner: partner_id
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/stores', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setStores(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchStores = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchStores({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/stores/search', {
                query: query,
                partner: match.params.id
            }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setStores(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setStores([]);
            setLoading(false);
        }


    }

    const onSortStores = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'search_name'
        }
        else if (sort === 'img') {
            return 'photo'
        }
        else if (sort === 'lang') {
            return 'language'
        }
        else {
            return sort
        }
    }

    const onChangePageStores = ({ page, sort, direction, filter_country, filter_status, filter_store }) => {

        let new_url = '/partners/' + match.params.id + '/stores/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }
        else {
            new_url = new_url + '?sort=updatedAt&dir=desc';
        }

        //Check filters
        if (filter_country) {
            new_url = new_url + '&country=' + filter_country;
        }

        if (filter_status) {
            new_url = new_url + '&status=' + filter_status;
        }

        if (filter_store) {
            new_url = new_url + '&store=' + filter_store;
        }

        history.push(new_url);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/partners/' + match.params.id + '/customers/profile/' + customer_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/partners/' + match.params.id + '/stores/profile/' + store_id);
    }

    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }

    //Table Data
    const columns = [
        {
            name: "id",
            label: "#",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <div onClick={() => onSelectStore(id)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#000', width: 40, height: 40, borderRadius: 50, marginLeft: 10, cursor: 'pointer' }}>
                            <EyeSharp color={'#fff'} />
                        </div>

                    )
                }
            },
        },
        // {
        //     name: "id",
        //     label: "ID",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (id) => {

        //             return (
        //                 <Typography variant='span' fontWeight={'bold'} onClick={()=> onSelectStore(id)} style={{cursor: 'pointer'}}>
        //                     #{id}
        //                 </Typography>
        //             )
        //         }
        //     },
        // },
        {
            name: "store_img",
            label: "STORE",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';
                    const store_name = storeObx.name || '';
                    const store_img = storeObx.img || '';

                    return (
                        <div>
                            <Chip
                                avatar={<Avatar alt={store_name} src={store_img} />}
                                label={store_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectStore(store_id)}
                            />
                        </div>
                    )
                }
            },

        },
        {
            name: "owner",
            label: "OWNER",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const ownerObx = storeObx.owner || {};
                    const customer_id = ownerObx.id || '';
                    const customer_name = ownerObx.name || '';
                    const customer_img = ownerObx.img || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                avatar={<Avatar alt={customer_name} src={customer_img} />}
                                label={customer_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectCustomer(customer_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    let color = 'default';

                    if (label) {
                        color = 'success';
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label ? 'ON' : 'OFF'} color={color} size='small' />
                        </div>
                    )

                }
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >

                            <Chip label={label} size='small' />

                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "phone",
            label: "Phone",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    if (!label) {
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >

                            <Chip label={label} size='small' />

                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "rating",
            label: "RATING",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: 'reviews',
            label: 'REVIEWS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label);

                    if (total > 0) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip label={label} size='small' color='warning'

                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small'

                            />
                        </div>
                    )

                }
            }

        },
        {
            name: 'score',
            label: 'SCORE',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';


                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small'

                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'products_count',
            label: 'PRODUCTS',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';


                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small'

                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total_orders',
            label: 'ORDERS',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label);

                    if (total > 0) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip label={label} size='small' color='info'

                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small'

                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total_green',
            label: 'GREEN',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label);

                    if (total > 0) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip label={label} size='small' color='success'

                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small'

                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total_red',
            label: 'RED',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';


                    const total = parseFloat(label);

                    if (total > 0) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip label={label} size='small' color='error'

                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small'

                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'total_yellow',
            label: 'YELLOW',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const store_id = storeObx.id || '';

                    const total = parseFloat(label);

                    if (total > 0) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip label={label} size='small' color='warning'

                                />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small'

                            />
                        </div>
                    )
                }
            }

        },
        {
            name: 'address',
            label: 'Location',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const storeObx = STORES[rowIndex] || {};

                    const countryCode = storeObx.country;
                    const storeAddress = storeObx.address;

                    if (!storeAddress) {
                        return (
                            <Chip size='small' label="NO" />
                        )
                    }

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >
                            <Chip
                                avatar={<ReactCountryFlag countryCode={countryCode} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                                label={storeAddress.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                            />

                        </CopyToClipboard>
                    )
                }
            }

        },
        {
            name: 'currency',
            label: 'CURRENCY',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            {label === 'usd' && (
                                <>
                                    <ReactCountryFlag countryCode={'us'} style={{ marginRight: 5, fontSize: 20 }} /> USD
                                </>
                            )}
                            {label === 'eur' && (
                                <>
                                    <ReactCountryFlag countryCode={'eu'} style={{ marginRight: 5, fontSize: 20 }} /> EUR
                                </>
                            )}
                            {label === 'gbp' && (
                                <>
                                    <ReactCountryFlag countryCode={'gb'} style={{ marginRight: 5, fontSize: 20 }} /> GBP
                                </>
                            )}

                        </div>
                    )
                }
            }

        },
        {
            name: "communication",
            label: "Communication",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "logistic",
            label: "Logistic",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "products",
            label: "Products",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: 'updatedAt',
            label: 'UPDATED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            }

        },
        {
            name: 'createdAt',
            label: 'CREATED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            }

        },


    ];

    const data = STORES.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_store = {

            id: item.id,

            status: item.status,

            products_count: item.products_count || 0,

            name: item.name,
            city: item.city,
            img: item.img,
            rating: item.rating,
            reviews: item.reviews,
            phone: item.phone,
            currency: item.currency,
            email: item.email,
            country: item.country,
            address: item.address,

            score: item.score ? parseFloat(item.score.toFixed(2)) : 0,
            total_orders: item.total || 0,
            total_green: item.green || 0,
            total_red: item.red || 0,
            total_yellow: item.yellow || 0,

            communication: item.communication || 0,
            logistic: item.logistic || 0,
            products: item.products || 0,

            updatedAt: moment(item.updatedAt).format('DD MMMM YYYY'),
            createdAt: moment(item.createdAt).format('DD MMMM YYYY')
        };



        return new_store
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageStores({
                        page: tableState.page,
                        sort: onSortStores(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageStores({
                        page: tableState.page,
                        sort: onSortStores(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchStores(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchStores(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Store By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Stores with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        elevation: 0,
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true
    };


    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Stores →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}

        />
    );

}


export default AdminStores

