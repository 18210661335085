import React, { useState, useEffect } from "react";

import LoaderMaster from "../Loaders/Loaders";

import { postApiPrivate } from "../../api";

import { AddCircleOutline, ArrowBack, CheckmarkCircleOutline, CloseCircleSharp, CloseSharp, ImageSharp, LeafSharp, RefreshOutline, SaveSharp, StorefrontOutline, TrashBinSharp } from "react-ionicons";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, TextField } from "@mui/material";

import ReactFileReader from 'react-file-reader';

import { ImageCompressor, getImageSize } from "compressor-img";

import ReactCountryFlag from "react-country-flag";
import Countries_EN from '../Search/countries_en.json';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import "../Profile/z_profile.css";
import { onFormatUrl } from "../../helpers/GeneralHelper";

import Iframe from "react-iframe";
import SearchStores from "../General/SearchStores";

const AdminLocationsDetails = (props) => {

  const { TRANSLATE, history, match, LANGUAGE } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);
  const [isSearching, setSearching] = useState(false);

  const [isFocused, setFocused] = useState(false);
  const [CountryResults, setCountryResults] = useState([]);
  const [CountryInput, setCountryInput] = useState('');

  const [PUBLIC_LOCATION, setLocation] = useState({});

  const [LangName, setLangName] = useState('en');

  const [isImageEditor, setImageEditor] = useState(false);
  const [CorrectedPhotos, setCorrectedPhotos] = useState([]);

  const [isSearchStore, setSearchStore] = useState(false);


  useEffect(() => {

    var location_id = match.params.id || '';

    onFetchPublic(location_id);

  }, []);

  useEffect(() => {

    if (isSearchStore) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isSearchStore])

  const onSaveAction = (id) => {
    swal({
      title: 'Confirm to Save',
      text: 'Please insert the CONFIRM to update this location',
      content: "input",
    })
      .then((value) => {

        if (value === 'CONFIRM') {

          onSaveLocation(id)
        }
        else {

          swal('Invalid Password', 'You are not authorized to update this location', 'error');
        }

      });
  }

  const onFetchPublic = async (location_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setEdited(false);
    setLoading(true);

    try {

      const FORM = { id: location_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/locations/details', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      var LocationData = DATA_RESPONSE.data || {};
      const LocationImages = LocationData.images || [];
      const LocationCountry = LocationData.country || '';

      if (LocationCountry) {
        const country_index = Countries_EN.findIndex((cty) => cty.value === LocationCountry);
        const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
        const country_name = country_obx ? country_obx.label : null;

        if (country_name) {

          setCountryInput(country_name);

          LocationData = {
            ...LocationData,
            country: {
              name: country_name,
              code: LocationCountry
            }
          }
        }
      }

      const LocationImagesCorrected = LocationImages.map((item, index) => {
        return {
          src: item,
          index: index,
          uploaded: true
        }
      })

      setCorrectedPhotos(LocationImagesCorrected)
      setLocation(LocationData);
      setLoading(false);

    }
    catch (e) {

      setLoading(false);
    }

  }

  const onUpdateLocation = async (key, value) => {

    const geocode = PUBLIC_LOCATION.geocode || {};

    setEdited(true);

    if (key === 'latitude') {
      setLocation({
        ...PUBLIC_LOCATION,
        geocode: {
          ...geocode,
          latitude: value
        }
      })

      return
    }

    if (key === 'longitude') {
      setLocation({
        ...PUBLIC_LOCATION,
        geocode: {
          ...geocode,
          longitude: value
        }
      })

      return
    }

    setLocation({
      ...PUBLIC_LOCATION,
      [key]: value
    });

  }

  const onSaveLocation = async (location_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      var FORM = {
        id: location_id,
        en: PUBLIC_LOCATION.en,
        it: PUBLIC_LOCATION.it,
        fr: PUBLIC_LOCATION.fr,
        es: PUBLIC_LOCATION.es,
        en_url: PUBLIC_LOCATION.en_url,
        it_url: PUBLIC_LOCATION.it_url,
        fr_url: PUBLIC_LOCATION.fr_url,
        es_url: PUBLIC_LOCATION.es_url,
        country: PUBLIC_LOCATION.country,
        points: PUBLIC_LOCATION.points,
        geocode: PUBLIC_LOCATION.geocode,
        img: PUBLIC_LOCATION.img,
        images: PUBLIC_LOCATION.images,
        status: PUBLIC_LOCATION.status,
      };

      const LocationImages = PUBLIC_LOCATION.images || [];

      if (LocationImages.length > 0) {
        FORM = {
          ...FORM,
          images: LocationImages
        }
      }


      if (PUBLIC_LOCATION.sponsored) {
        FORM = {
          ...FORM,
          sponsored: PUBLIC_LOCATION.sponsored.id
        }
      }

      const DATA_RESPONSE = await postApiPrivate('/admin/locations/edit', FORM, JWT);


      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update This Location', 'error');
        onFetchPublic(location_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Location Updated', 'This Location has been updated successfully !!!', 'success');
      onFetchPublic(location_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This Location', 'error');
      onFetchPublic(location_id);
    }

  }

  const onDeleteLocation = async (location_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: location_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/locations/delete', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Delete This Location', 'error');
        onFetchPublic(location_id);
        console.log("ERROR FROM API LOADING LOCATION DELETE", DATA_RESPONSE.error);
        return
      }

      setSaving(false);
      swal('Location Deleted', 'This Location has been deleted successfully !!!', 'success');
      history.replace('/admin/locations');

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Delete This Location', 'error');
      onFetchPublic(location_id);
    }
  }

  const onRecoverLocation = async (location_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: location_id, recover: true };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/locations/delete', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Recover This Location', 'error');
        onFetchPublic(location_id);
        console.log("ERROR FROM API LOADING LOCATION DELETE", DATA_RESPONSE.error);
        return
      }

      setSaving(false);
      swal('Location Recovered', 'This Location has been recovered successfully !!!', 'success');

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Delete This Location', 'error');
      onFetchPublic(location_id);
    }
  }

  const onSelectCountry = async (country) => {

    console.log("COUNTRY SELECTED ===> ", country);

    setFocused(false);

    setEdited(true);
    setLocation({
      ...PUBLIC_LOCATION,
      country: {
        name: country.label,
        code: country.value
      }
    });

    setCountryInput(country.label);

    swal({
      title: 'Country Updated',
      text: 'You selected ' + country.label,
      icon: 'success',
      timer: 2000
    })

  }

  const onSearchCountry = (query) => {

    setCountryInput(query);

    if (!query) {
      setFocused(false);
      setCountryResults([]);

      return
    }

    query = query.toLowerCase();


    const DATA_RESPONSE = Countries_EN.filter((item) => item.label.toLowerCase().startsWith(query));

    if (!DATA_RESPONSE) {
      setFocused(false);
      setCountryResults([]);

      return
    }


    const filtredResults = DATA_RESPONSE || [];

    if (filtredResults.length > 0) {
      setFocused(true);
      setCountryResults(filtredResults);
    }


  }

  const onSearchKeyUp = async (event) => {
    event.preventDefault();

    if (event.keyCode === 13) {
      event.target.blur();

      if (CountryResults.length > 0) {
        onSelectCountry(CountryResults[0]);
      }
    }
  }

  //Images
  const onAddImage = (data) => {

    const src = data.src || '';
    const isUploaded = data.uploaded || false;

    if (isUploaded) {
      const images = PUBLIC_LOCATION.images || [];
      const newImages = [...images, src]
      onUpdateLocation('images', newImages);
    }

    setCorrectedPhotos((previous) => {
      return [...previous, data]
    })

  }

  const onEditImage = (data, index) => {

    const src = data.src || '';
    const isUploaded = data.uploaded || false;

    if (isUploaded) {
      const images = PUBLIC_LOCATION.images || [];
      const newImages = [
        ...images.slice(0, index),
        src,
        ...images.slice(index + 1),
      ]
      onUpdateLocation('images', newImages);
    }

    setCorrectedPhotos((previous) => {
      return [
        ...previous.slice(0, index),
        data,
        ...previous.slice(index + 1),
      ]
    })

  }

  const onDeleteImage = (index) => {

    const images = PUBLIC_LOCATION.images || [];
    const filtred_images = images.filter((item, idx) => index !== idx)
    const filtred_corrected = CorrectedPhotos.filter((item, idx) => index !== idx);

    setCorrectedPhotos(filtred_corrected);
    onUpdateLocation('images', filtred_images);


  }

  const onSearchImage = async () => {

    const query = PUBLIC_LOCATION.en;

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSearching(true);

    try {

      const FORM = { query: query };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/locations/searchimages', FORM, JWT);

      if (DATA_RESPONSE.error) {

        setSearching(false);
        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      const LocationImages = DATA_RESPONSE.data || [];


      if (LocationImages.length > 0) {
        const images = PUBLIC_LOCATION.images || [];
        const newImages = [...LocationImages, ...images]

        const LocationImagesCorrected = LocationImages.map((item, index) => {
          return {
            src: item,
            index: index,
            uploaded: true
          }
        })

        setCorrectedPhotos((prev) => [...LocationImagesCorrected, ...prev]);
        onUpdateLocation('images', newImages);
        setSearching(false);

        swal('New Images Added', '20 new pictures have been added to this city: \n' + query, 'success');

        return

      }

      setSearching(false);

      swal('0 Images Found', '0 new pictures have been added to this city: \n' + query, 'warning');


    }
    catch (e) {

      setSearching(false);

      swal('No Images Found', 'We couldn\'t find photos for this city', 'error');
    }
  }

  const onDeleteImages = () => {

    setCorrectedPhotos([]);
    onUpdateLocation('images', []);

  }

  const onGenerateUrl = (lang, name) => {
    const newUrl = onFormatUrl(name);
    onUpdateLocation(lang + '_url', '/' + lang + '/search/location/' + newUrl);
  }

  const onSelectStore = (sponsored) => {
    setEdited(true);
    setLocation({
      ...PUBLIC_LOCATION,
      sponsored: sponsored
    })
  }


  if (isLoading) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading} />
    )

  }

  if (isSaving) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving} />
    )

  }


  const {
    id,
    name,
    country,
    points,
    status,
    updatedAt,
    createdAt,
    isDeleted

  } = PUBLIC_LOCATION;


  const geocode = PUBLIC_LOCATION.geocode || {};
  const geoLatitude = geocode.latitude || 0;
  const geoLongitude = geocode.longitude || 0;

  const managerObx = PUBLIC_LOCATION.manager || {};
  const manager_id = managerObx.id || '';
  const manager_name = managerObx.name || '';
  const manager_img = managerObx.img || '';

  const sponsoredObx = PUBLIC_LOCATION.sponsored || {};
  const sponsored_id = sponsoredObx.id || '';
  const sponsored_name = sponsoredObx.name || '';
  const sponsored_img = sponsoredObx.img || '';


  if (isImageEditor) {
    return (
      <Container maxWidth="xl" style={{ background: '#f0f5f9' }}>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} md={6}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>
              <div className="top_store_card">
                <div className="top_store_profile">

                  {CorrectedPhotos.length > 0 ? (
                    <img className="profile_img" src={CorrectedPhotos[0].src} style={{ borderRadius: 20, height: 250, width: 250, objectFit: 'contain' }} onClick={() => setImageEditor(true)} />
                  ) : (
                    <div className="profile_img" style={{ borderRadius: 20, height: 250, width: 250, objectFit: 'contain' }} onClick={() => setImageEditor(true)} />
                  )}

                  <h3 className="store_title">{name}</h3>

                </div>

              </div>


              {isEdited && (
                <>

                  <div className="top_add_cart"
                    style={{ backgroundColor: '#59ad6a' }}
                    onClick={() => onSaveAction(id)}
                  >
                    <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Update Location
                  </div>

                </>
              )}

              <div className="top_add_cart" style={{ backgroundColor: '#3d3d3d' }} onClick={() => setImageEditor(false)}>
                <ArrowBack color={'#fff'} style={{ marginRight: 10 }} /> Go Back
              </div>


            </div>
          </Grid>
          <Grid item xs={12} lg={8} md={6}>

            <div className="container-fluid top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

              <div className="row">

                <div className="col-12 mb-5 d-flex align-items-center justify-content-between">

                  <div>

                    <h3>Location Images</h3>
                    <p>You can upload up to 10 photos of this location</p>

                  </div>

                  <div>

                    <div className="gridyx-textbutton" onClick={() => onDeleteImages()} style={{ cursor: 'pointer', background: '#fff', color: '#000' }}>
                      Reset Images
                    </div>

                    {isSearching ? (
                      <div className="gridyx-textbutton" style={{ background: '#ccc', cursor: 'not-allowed' }}>
                        <RefreshOutline rotate={true} color={'#fff'} /> Searching Images...
                      </div>
                    ) : (
                      <div className="gridyx-textbutton" onClick={() => onSearchImage()} style={{ cursor: 'pointer' }}>
                        Search More Images
                      </div>
                    )}

                  </div>


                </div>

                {CorrectedPhotos.map((item, index) => {

                  return (
                    <div className="col-sm-6 col-12" key={index}>
                      <LocationImageFull item={item}
                        index={index}
                        onDeleteImage={onDeleteImage}
                        onEditImage={onEditImage}
                        onSaveAction={onSaveAction}
                        location_id={id}
                        setImageEditor={setImageEditor}


                      />
                    </div>
                  )

                })}

                {Array.from({ length: (4 - CorrectedPhotos.length) }).map((item, index) => {

                  return (
                    <div className="col-sm-6 col-12" key={index}>
                      <LocationImageEmpty
                        TRANSLATE={TRANSLATE}
                        onAddImage={onAddImage}
                      />

                    </div>
                  )

                })}



              </div>

            </div>


          </Grid>

        </Grid>

      </Container>
    )

  }

  const LocationName = PUBLIC_LOCATION[LangName] || '';
  const LocationURL = PUBLIC_LOCATION[LangName + '_url'] || '';

  return (
    <>
      <Container maxWidth="xl" style={{ background: '#f0f5f9' }}>

        <Grid container spacing={2}>

          <Grid item xs={12} lg={4} md={6}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>
              <div className="top_store_card">
                <div className="top_store_profile">

                  {CorrectedPhotos.length > 0 ? (
                    <img className="profile_img" src={CorrectedPhotos[0].src} style={{ borderRadius: 20, height: 250, width: 250, objectFit: 'contain' }} onClick={() => setImageEditor(true)} />
                  ) : (
                    <div className="profile_img" style={{ borderRadius: 20, height: 250, width: 250, objectFit: 'contain' }} onClick={() => setImageEditor(true)} />
                  )}

                  <h3 className="store_title">{name}</h3>

                </div>

              </div>

              {!isEdited && (
                <>

                  <div className="top_add_cart" style={{ backgroundColor: 'var(--yellow)' }} onClick={() => setImageEditor(true)}>
                    <ImageSharp color={'#fff'} style={{ marginRight: 10 }} /> Edit Images
                  </div>

                  {isDeleted ? (
                    <div className="top_add_cart"
                      style={{ backgroundColor: 'var(--success)' }}
                      onClick={() => {
                        swal({
                          title: 'Confirm to Recover',
                          text: 'Please insert CONFIRM to recover this location',
                          content: "input",
                        })
                          .then((value) => {

                            if (value === 'CONFIRM') {

                              onRecoverLocation(id)
                            }
                            else {

                              swal('Invalid Password', 'You are not authorized to recover this location', 'error');
                            }

                          });
                      }}
                    >
                      <LeafSharp color={'#fff'} style={{ marginRight: 10 }} /> Recover Location
                    </div>
                  ) : (
                    <div className="top_add_cart"
                      style={{ backgroundColor: '#e64942' }}
                      onClick={() => {
                        swal({
                          title: 'Confirm to Delete',
                          text: 'Please insert CONFIRM to delete this location',
                          content: "input",
                        })
                          .then((value) => {

                            if (value === 'CONFIRM') {

                              onDeleteLocation(id)
                            }
                            else {

                              swal('Invalid Password', 'You are not authorized to delete this location', 'error');
                            }

                          });
                      }}
                    >
                      <TrashBinSharp color={'#fff'} style={{ marginRight: 10 }} /> Delete Location
                    </div>
                  )}

                </>
              )}

              {isEdited && (
                <>

                  <div className="top_add_cart"
                    style={{ backgroundColor: '#59ad6a' }}
                    onClick={() => onSaveAction(id)}

                  >
                    <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Update Location
                  </div>

                  <div className="top_add_cart" style={{ backgroundColor: '#ccc' }} onClick={() => onFetchPublic(id)}>
                    <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Cancel Changes
                  </div>

                </>
              )}


            </div>
          </Grid>
          <Grid item xs={12} lg={5} md={6}>

            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

              <div className="top_box_magic" style={{ marginBottom: 20 }}>
                <h1>
                  Status
                </h1>

                {status ? (
                  <Chip label="Active" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="success" onClick={(e) => onUpdateLocation('status', false)} />
                ) : (
                  <Chip label="Unactive" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="warning" onClick={(e) => onUpdateLocation('status', true)} />
                )}
              </div>

              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Name

                  <div>
                    <img src="/assets/languages/en.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }} onClick={() => setLangName('en')} />
                    <img src="/assets/languages/it.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5) }} onClick={() => setLangName('it')} />
                    <img src="/assets/languages/fr.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5) }} onClick={() => setLangName('fr')} />
                    <img src="/assets/languages/es.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5) }} onClick={() => setLangName('es')} />
                  </div>

                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    value={LocationName}
                    label={'.'}
                    onChange={(e) => onUpdateLocation(LangName, e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    placeholder="Type here..."

                  />
                </div>
              </div>

              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Url

                  {/* <div>
                                <div style={{fontSize: 14, color: '#40a4e9', cursor: 'pointer'}} onClick={()=> onGenerateUrl(LangName, PUBLIC_LOCATION[LangName])}>
                                  Auto-generate
                                </div>
                              </div> */}

                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    value={LocationURL}
                    label={'.'}
                    onChange={(e) => onUpdateLocation(LangName + '_url', e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    placeholder="Type here..."

                  />
                </div>
              </div>

              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Country

                  <div>
                    <ReactCountryFlag countryCode={country?.code} svg style={{ width: 20, height: 20, marginLeft: 5, cursor: 'pointer' }} />
                  </div>
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    value={CountryInput}
                    label={'.'}
                    InputLabelProps={{ shrink: false }}
                    onFocus={() => setFocused(true)}
                    onChange={(e) => onSearchCountry(e.target.value)}
                    onKeyUp={(e) => onSearchKeyUp(e)}


                  />

                  {(isFocused && CountryResults.length > 0) && (
                    <div style={{ ...Styles.resultsContainer }}>
                      {CountryResults.map((item, index) => {

                        return (
                          <div style={Styles.resultsRow}
                            key={index}
                            onClick={() => onSelectCountry(item)}
                          >

                            <div>
                              <ReactCountryFlag countryCode={item.value} svg style={{ marginRight: 5 }} />
                              {item.label}
                            </div>

                            <CheckmarkCircleOutline size={28} color="#ccc" style={{ marginRight: 10 }} />


                          </div>
                        )

                      })}
                    </div>
                  )}
                </div>

              </div>


              <div className="top_box_magic">
                <h1>
                  Latitude
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    label={'.'}
                    InputLabelProps={{ shrink: false }}
                    value={geoLatitude}
                    onChange={(e) => onUpdateLocation('latitude', e.target.value)}
                    type="number"

                  />
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Longitude
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    label={'.'}
                    InputLabelProps={{ shrink: false }}
                    value={geoLongitude}
                    onChange={(e) => onUpdateLocation('longitude', e.target.value)}
                    type="number"

                  />
                </div>
              </div>

              <Iframe url={"https://maps.google.com/maps?q=" + geocode.latitude + "%2C" + geocode.longitude + "&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                width="100%"
                height="300"
                className="map_frame"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              />
            </div>


          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 80 }}>

              <div className="top_box_magic">
                <h1>
                  Location Created
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <p>
                    {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                  </p>
                </div>
              </div>
              <div className="top_box_magic">
                <h1>
                  Location Updated
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <p>
                    {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                  </p>
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Points
                </h1>
                <div style={{ margin: '10px 0px' }}>
                  <TextField
                    className={'gridyx-textfield'}
                    label={'.'}
                    InputLabelProps={{ shrink: false }}
                    value={points}
                    onChange={(e) => onUpdateLocation('points', e.target.value)}
                    type="number"

                  />
                </div>
              </div>

              {manager_id && (
                <div className="top_box_magic">
                  <h1>
                    Manager
                  </h1>
                  <Chip
                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                    label={manager_name.toLowerCase()}
                    variant="outlined"
                    size='medium'
                    style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                    onClick={() => history.push('/admin/users/profile/' + manager_id)}
                  />
                </div>
              )}

              {sponsored_id ? (
                <div className="top_box_magic">
                  <h1>
                    Sponsored
                  </h1>
                  <div className="flex items-center w-full">
                    <Chip
                      avatar={<Avatar alt={sponsored_name} src={sponsored_img} />}
                      label={sponsored_name.toLowerCase()}
                      variant="outlined"
                      size='medium'
                      style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                      onClick={() => setSearchStore(true)}
                    />
                    <CloseCircleSharp className="cursor-pointer" onClick={()=> onSelectStore()} />
                  </div>

                </div>
              ) : (
                <div className="top_box_magic">
                  <h1>
                    Sponsored
                  </h1>
                  <Chip
                    avatar={<StorefrontOutline />}
                    label={'Select Store'}
                    variant="outlined"
                    size='medium'
                    style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                    onClick={() => setSearchStore(true)}
                  />

                </div>
              )}



            </div>
          </Grid>
        </Grid>


      </Container>
      {isSearchStore && (
        <SearchStores {...props}
          onClose={() => setSearchStore(false)}
          onUpdateStore={onSelectStore}
          Geocode={PUBLIC_LOCATION.geocode}

        />
      )}
    </>

  )


}


const LocationImageFull = ({ item, onDeleteImage, index, onEditImage, onSaveAction, location_id, setImageEditor }) => {

  const { src, size, compressed, uploaded } = item;

  const [CurrentUrl, setUrl] = useState('');

  const [isChanged, setChanged] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [isCropped, setCropped] = useState(false);

  const [cropper, setCropper] = useState();


  useEffect(() => {

    setUrl(item.src);

  }, []);

  const onUploadImage = async (item) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setUploading(true);

    try {

      var FORM = {
        ...item,
        type: 'locations'
      };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/library/upload', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setUploading(false);
        swal('Error', 'Cannot Update This Location', 'error');

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setUploading(false);
      swal('Photo Updated', 'This photo has been uploaded successfully !!!', 'success');

      const new_src = DATA_RESPONSE.url;
      onEditImage({
        src: new_src,
        uploaded: true
      }, index)

      setUrl(new_src);
      setChanged(false);

      // setImageEditor(false)
      // setTimeout(()=> {
      //   onSaveAction(location_id)
      // }, 3000)


    }
    catch (e) {

      setUploading(false);
      swal('Error', 'Cannot Upload This Photo', 'error');

    }
  }

  const onCropImage = (item, index) => {

    if (typeof cropper !== "undefined") {

      const newImage = cropper.getCroppedCanvas().toDataURL();
      const newSize = getImageSize(newImage);

      let imageCompressor = new ImageCompressor({
        onSuccess: response => {

          const compressedImg = response.compressed;

          const originalSize = getImageSize(response.original);
          const compressedSize = getImageSize(response.compressed);

          if (originalSize > 3000) {

          }
          else if (originalSize <= 3000 && originalSize > 250) {

          }
          else if (originalSize <= 250) {

          }

          console.log("COMPRESSED ===> ", compressedImg);
          console.log("ORIGINAL SIZE ===> ", originalSize);
          console.log("COMPRESSED SIZE ===> ", compressedSize);

          onEditImage({
            src: compressedImg,
            uploaded: false,
            size: originalSize,
            compressed: compressedSize
          }, index)

          setChanged(true);
          setCropped(true);


        },
        //scale: 100,
        quality: 50,
        originalImage: newImage
      });

    }

  }

  const onEditUrl = (url) => {
    setUrl(url);
    setChanged(true);
  }

  const onSaveUrl = () => {
    onEditImage({
      src: CurrentUrl,
      uploaded: true
    }, index)

    setChanged(false)
  }




  const CAN_SAVE = isChanged && CurrentUrl.length > 0;


  return (
    <>

      <div className="product-images-full">

        <div className="d-flex align-items-center justify-content-center" style={{ background: 'var(--danger)', position: 'absolute', top: 10, right: 25, borderRadius: '50%', width: 30, height: 30, cursor: 'pointer', zIndex: 2 }} onClick={() => onDeleteImage(index)}>
          <CloseSharp color={'#ffffff'} style={{ height: 16, width: 16 }} />
        </div>

        {!uploaded ? (
          <>
            {isCropped ? (
              <>
                <img style={{ objectFit: 'contain', width: '100%', height: 350, background: '#ccc' }} src={src} />
                <div className="top_box_magic w-100 p-3">
                  <h1 className="d-flex align-items-center justify-content-between">
                    Paste url of image

                    <div className="d-flex align-items-center justify-content-center" style={{ background: '#000', borderRadius: 20, height: 25, color: '#fff', padding: '0px 10px', fontSize: 14 }}>
                      {size} kb to {compressed} kb
                    </div>
                  </h1>
                  <div className="d-flex align-items-center justify-content-between" style={{ margin: '10px 0px' }}>
                    <TextField
                      className={'gridyx-textfield'}
                      value={CurrentUrl}
                      label={'.'}
                      onChange={(e) => onEditUrl(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                      placeholder="Type here..."
                      disabled={true}

                    />

                    {isUploading ? (
                      <div className="gridyx-textbutton" style={{ cursor: 'not-allowed', background: '#ccc' }}>
                        <RefreshOutline color={'#fff'} rotate={true} style={{ marginBottom: 5 }} />
                      </div>
                    ) : (
                      <>

                        {CAN_SAVE ? (
                          <div className="gridyx-textbutton" onClick={() => {
                            swal({
                              title: 'Upload Photo',
                              text: 'Please add a name to the photo to easily find it later',
                              icon: 'info',
                              content: "input",
                            })
                              .then((value) => {

                                if (value && value.length > 0) {
                                  onUploadImage({
                                    ...item,
                                    name: value
                                  })
                                }
                                else {
                                  swal('min 4 chars', 'The photo name should be at least 4 chars long.', 'warning');
                                }

                              });
                          }}>
                            Upload
                          </div>
                        ) : (
                          <div className="gridyx-textbutton" style={{ cursor: 'not-allowed', background: '#ccc' }}>
                            Upload
                          </div>
                        )}

                      </>
                    )}


                  </div>
                </div>
              </>
            ) : (
              <>

                <Cropper
                  style={{ height: 350, width: "100%" }}
                  initialAspectRatio={1}
                  src={src}
                  viewMode={1}
                  cropBoxResizable={false}
                  dragMode={'move'}
                  background={true}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
                <div className="top_box_magic w-100 p-3">
                  <h1 className="d-flex align-items-center justify-content-between">
                    Adjust Image

                    <div className="d-flex align-items-center justify-content-center" style={{ background: '#000', borderRadius: 20, height: 25, color: '#fff', padding: '0px 10px', fontSize: 14 }}>
                      {size} kb
                    </div>
                  </h1>
                  <div className="d-flex align-items-center justify-content-between" style={{ margin: '10px 0px' }}>

                    <p style={{ marginRight: 10 }}>
                      Make sure the location image is square with white background
                    </p>

                    <div className="gridyx-textbutton" style={{ width: 140 }} onClick={() => onCropImage(item, index)}>
                      Crop Image
                    </div>

                  </div>
                </div>
              </>
            )}


          </>
        ) : (
          <>
            <img style={{ objectFit: 'contain', width: '100%', height: 350, background: '#ccc' }} src={src} />
            <div className="top_box_magic w-100 p-3">
              <h1 className="d-flex align-items-center justify-content-between">
                Paste url of image
              </h1>
              <div className="d-flex align-items-center justify-content-between" style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  value={CurrentUrl}
                  label={'.'}
                  onChange={(e) => onEditUrl(e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  placeholder="Type here..."

                />

                {CAN_SAVE ? (
                  <div className="gridyx-textbutton" onClick={() => onSaveUrl()}>
                    Save
                  </div>
                ) : (
                  <div className="gridyx-textbutton" style={{ cursor: 'not-allowed', background: '#ccc' }}>
                    Save
                  </div>
                )}

              </div>
            </div>
          </>
        )}

      </div>

    </>

  )
}

const LocationImageEmpty = ({ TRANSLATE, onAddImage }) => {

  const [CurrentUrl, setUrl] = useState('');

  const onSaveUrl = () => {
    onAddImage({
      src: CurrentUrl,
      uploaded: true
    })

    setUrl('');
  }

  const onHandleUpload = (files) => {

    console.log("Files ==> ", files);
    const OriginalFile = files.fileList[0];

    let reader = new FileReader();
    reader.onloadend = () => {

      const newSize = getImageSize(reader.result);

      onAddImage({
        src: reader.result,
        size: newSize
      })

    };

    reader.readAsDataURL(OriginalFile);
  }



  const CAN_SAVE = CurrentUrl.length > 0;

  return (

    <div className="product-images-full">

      <ReactFileReader fileTypes={[".jpg", ".jpeg", ".png", ".gif"]} base64={true} multipleFiles={false} handleFiles={onHandleUpload} >
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ padding: 60, height: 350 }}>
          <AddCircleOutline color={'#ccc'} style={{ width: 30, height: 30 }} />
          <h1 style={{ color: '#ccc', fontSize: 12 }}>
            {TRANSLATE.product_add_photo}
          </h1>
        </div>
      </ReactFileReader>

      <div className="top_box_magic w-100 p-3">
        <h1 className="d-flex align-items-center justify-content-between">
          Paste url of image
        </h1>
        <div className="d-flex align-items-center justify-content-between" style={{ margin: '10px 0px' }}>
          <TextField
            className={'gridyx-textfield'}
            value={CurrentUrl}
            label={'.'}
            onChange={(e) => setUrl(e.target.value)}
            InputLabelProps={{ shrink: false }}
            placeholder="Type here..."

          />

          {CAN_SAVE ? (
            <div className="gridyx-textbutton" onClick={() => onSaveUrl()}>
              Save
            </div>
          ) : (
            <div className="gridyx-textbutton" style={{ cursor: 'not-allowed', background: '#ccc' }}>
              Save
            </div>
          )}

        </div>
      </div>

    </div>

  )
}



const Styles = {
  resultsRow: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    height: 40,
    minHeight: 40,
    paddingLeft: 20,
    border: '1px solid #00000040',
    borderRight: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    cursor: 'pointer',
    textTransform: 'capitalize',
    justifyContent: 'space-between'
  },
  resultsContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    maxHeight: 280,
    position: 'relative',
    top: 20,
    border: '1px solid #00000040',
    borderRadius: 10,
    overflow: 'auto',
    marginBottom: 20
  },
  right: {
    display: 'flex',
    alignItems: 'center'
  },
  searchContainer: {
    width: 300,
    height: 42,
    backgroundColor: '#F1F1F1',
    borderRadius: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5
  },
  input: {
    fontSize: 16,
    display: 'flex',
    color: '#000',
    backgroundColor: 'transparent',
    border: 'none',
    width: '100%',
    marginLeft: 5,
    textTransform: 'capitalize'
  },
  searchOption: {
    display: 'flex',
    width: 40,
    height: 35,
    alignItems: 'center',
    borderRadius: '50%',
    background: '#fd6270'
  },
  button: {
    display: 'flex',
    height: '50px',
    width: '200px',
    backgroundColor: '#26BFBF',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    width: '100%'
  },
  img_full: {
    display: 'flex',
    width: 200,
    height: 200,
    borderRadius: 6,
    marginRight: 15,
    marginLeft: 15
  },
  img_empty: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    borderRadius: 6,
    marginRight: 15,
    borderColor: '#ccc',
    borderWidth: 2,
    borderStyle: "dashed",
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,

  },
  clickCard: {
    backgroundColor: "#fff",
    padding: 20,
    marginTop: 10,
  },
  clickLabel: {
    color: "#697883",
    fontFamily: "SourceSansPro-Bold",
    fontSize: 14,
    marginBottom: 10,
  },
  wrapFlex: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  publishBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fd6270',
    padding: 10,
    width: 90,
    color: '#fff',
    borderRadius: 10,
    cursor: 'pointer'
  }

}


export default AdminLocationsDetails

