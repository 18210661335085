import React, { useState } from "react";

import moment from "moment";
import swal from "sweetalert";
import { numberWithCommas } from "../../helpers/GeneralHelper";

import { postApiPrivate } from "../../api";
 
const DeclineModal = (props) => {

    const {onClose, ORDER, TRANSLATE, USER, history, match, onFetchSingle} = props;

    const [isCanceling, setCanceling] = useState(false);
    const [REASON, setReason] = useState('');

    const onCancelOrder = async() => {

        if(REASON.length <10){

            swal(TRANSLATE.ask_why_reject, TRANSLATE.tool_tell_reason_reject, 'warning');
            return 
        }

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const store_id = USER.store || '';

        if(!store_id){
            return alert('Error');
        }


        setCanceling(true);
  
        try{
  
            var DATA_RESPONSE = await postApiPrivate('/orders/status', {id: order_id ,sid: store_id, st: 'rejected', reason: REASON} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API LOADING UPDATE STATUS", DATA_RESPONSE.error);
                setCanceling(false);

                alert(DATA_RESPONSE.error);
                
                return 
            }
  
            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);
  
            setCanceling(false);
            swal(TRANSLATE.alert_order.rejected_t, TRANSLATE.alert_order.rejected_s, 'success');
            onClose();
            
            const ORDER_ID = match.params.id;
            if(ORDER_ID){
                onFetchSingle(ORDER_ID);
            }
  
        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            setCanceling(false);
        }
    }


    moment.locale(TRANSLATE.lang);
 
    const order_id = ORDER.id;
    const order_status = ORDER.status;
    const order_products = ORDER.products || []
    const order_delivery = ORDER.delivery || {};
    const order_return = ORDER.return || {};

    const order_created = moment(ORDER.created).format('dddd DD MMMM YYYY - HH:mm');
    const order_updated = moment(ORDER.updated).format('dddd DD MMMM YYYY - HH:mm');

    const order_checkin = moment(ORDER.checkin).format('DD MMMM YYYY');
    const order_checkout = moment(ORDER.checkout).format('DD MMMM YYYY');


    const order_customer = ORDER.customer || {}
    const customer_id = order_customer.id;
    const customer_name = order_customer.name;
    const customer_img = order_customer.img;

    const order_store = ORDER.store || {}
    const store_id = order_store.id;
    const store_name = order_store.name;
    const store_img = order_store.img;
    
    const order_message = ORDER.message || '';

    const order_currency = ORDER.currency || 'eur';
    const order_symbol = ORDER.symbol || '€';

    const order_date = moment(ORDER.created).fromNow();

    const order_checkin_ex = ORDER.checkin_ex;
    const order_checkout_ex = ORDER.checkout_ex;
    const order_duration_ex = ORDER.checkdur_ex;

    let orderPeriod = '';
    let orderDuration = 0;
    let orderExact = false;

    if(order_checkin_ex && order_checkout_ex){

        orderPeriod = order_checkin_ex + ' - ' + order_checkout_ex;
        orderDuration = order_duration_ex;
        orderExact = true;
    }
    else{

        const startMoment = moment(order_checkin);
        const endMoment = moment(order_checkout);

        orderPeriod = startMoment.format('DD/MM/YYYY') + ' - ' + endMoment.format('DD/MM/YYYY')
        orderDuration =  moment(endMoment).diff(startMoment, 'days')+1;
    }


    //Calculate Costs

    const cost_subtotal = ORDER.subtotal || 0;
    const cost_total = ORDER.total || 0;
    const cost_delivery = ORDER.d_cost || 0;
    const cost_return = ORDER.r_cost || 0;
    const cost_fee = ORDER.f_cost || 0;
    const cost_discount = ORDER.c_cost || 0;

    if(isCanceling){

        return (
            <div className="fixed w-screen h-screen top-0 left-0 z-[500] flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]">
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
                <div className="mx-auto w-full max-w-[700px] px-4 sm:px-6 lg:px-8 pb-10 pt-10 bg-white rounded-2xl relative z-20">
                    <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
                        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 z-[500] flex items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]">
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />

                <div className="mx-auto w-full max-w-[700px] px-4 sm:px-6 lg:px-8 pb-10 pt-10 bg-white rounded-2xl relative z-20">
                    <img className="absolute top-6 right-6" src="/assets/img/close.svg" onClick={()=> onClose()} />
                    <div className="flex flex-col">
                        <h3 className="text-lg font-semibold mb-6">
                            {TRANSLATE.ask_sure_reject}
                        </h3>
                        
                        <div className="flex w-full border border-zinc-200 p-4 rounded-lg items-start mt-3 hover:border-zinc-400 cursor-pointer">
                            <img src={customer_img || "/assets/img/avatar.svg"} className="w-[50px] h-[50px] rounded-full mr-3 object-cover" />
                            <div className="mt-1">
                                <div className="font-bold">
                                    {customer_name}
                                </div>
                                <div className="text-xs opacity-50">
                                    {order_date}
                                </div>
                                <div className=" border-b-zinc-200 flex flex-col sm:flex-row gap-x-4 mt-4 sm:items-center">
                                    <div className="font-semibold text-sm">
                                        {numberWithCommas(cost_total, order_currency)}
                                    </div>
                                    <div className="w-[4px] h-[4px] rounded-full bg-black hidden sm:block" />
                                    <div className="text-sm">
                                        {orderDuration == 1 ? '1 '+TRANSLATE.wd_day : orderDuration+' '+TRANSLATE.wd_days}
                                    </div>
                                    <div className="w-[4px] h-[4px] rounded-full bg-black hidden sm:block" />
                                    <div className="text-sm">
                                        {orderPeriod}
                                    </div>
                                </div>
                            </div>
                            <div className="ml-auto flex gap-x-3">
                                {order_products.map((item, index)=> {
                                  return (
                                    <img className="w-[70px] shrink-0 h-[70px] border border-zinc-200 rounded-lg object-contain" 
                                         src={item.img} 
                                         key={index}
                                    />
                                  )
                                })}
                               
                            </div>
                        </div>

                        <div className="input-group mt-6">
                            <label>
                                {TRANSLATE.ask_why_reject}
                            </label>
                            <textarea placeholder={TRANSLATE.tool_tell_reason_reject}
                                      value={REASON}
                                      onChange={(e)=> setReason(e.target.value)}
                            
                            >

                            </textarea>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="cursor-pointer" onClick={()=> onClose()}>
                                {TRANSLATE.btn_back}
                            </div>
                            <div className="btn-provider bg-red-600" onClick={()=> onCancelOrder()}>
                                {TRANSLATE.yes_reject}
                            </div>
                        </div>
                    </div>

                </div>

            </div>


        </>
    )
}

export default DeclineModal