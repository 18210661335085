import React from 'react';

import { Link } from 'react-router-dom';
import { Cities_Array } from '../General/Constants';

import ReactCountryFlag from 'react-country-flag';
import Countries_EN from '../Search/countries_en.json';


const CitiesCards = (props) => {
  
    let {LANGUAGE, className, TRANSLATE} = props;
  
    if(!className){
      className = 'row';
    }
  
    return (
      <div className={className}>
            
          {Cities_Array.map((item, index) => {
  
              const City_Name = item[LANGUAGE];
              const City_URL = item[LANGUAGE+'_url'];
              const City_IMG = item['en'].toLowerCase();
              const City_Alt = item[LANGUAGE].toLowerCase();
  
              const City_Country = item.country;
  
              const country_index = Countries_EN.findIndex((cty)=> cty.value === City_Country);
              const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
          
              const City_Country_Name = country_obx ? country_obx.label : null;
  
              return (
                <Link className="col-6 col-lg-3" to={City_URL} key={index}>
                  <div className="service-item" style={{transform: 'translate(0px, 0px)', cursor: 'pointer', opacity: 1}}>
                    <img src={'/assets/cities/'+City_IMG+'.png'} alt={City_Alt} />
                    <h2 style={{marginBottom: 10, fontSize: 30}}>{City_Name}</h2>
                    {City_Country_Name} <ReactCountryFlag countryCode={City_Country} svg/>
                  </div>
                </Link>
              )
          })}


          <div className='d-flex align-items-center justify-content-center' style={{width: '100%', height: 100}}>
            <Link to={'/'+LANGUAGE+'/locations'} className="section_button mb-0">
              {TRANSLATE.other_cities}
            </Link>
          </div>
          
  
      </div>
    )
}

export default CitiesCards