import React from 'react';

const Order_Status = ({status, type, TRANSLATE}) => {

    var STATUS_STYLE = {}

    if(type === 'list'){
        STATUS_STYLE = {...Styles.badge};
    }
    else if(type === 'details'){
        STATUS_STYLE = {...Styles.badge};
    }
    else if(type === 'orders'){
        STATUS_STYLE = {...Styles.badge, padding: '10px 20px', display: 'inline-block'};
    }

    if(status === 'pending'){
        return <div style={{...STATUS_STYLE, backgroundColor: '#FCE205', color: '#aa9908'}}>
            Pending
        </div>
    }
    else if(status === 'accepted'){
        return <div style={{...STATUS_STYLE, backgroundColor: "#9AD6AB",color: "#fff"}}>
            Accepted
        </div>
    }
    else if(status === 'rejected'){
        return <div style={{...STATUS_STYLE, backgroundColor: "#ec1529",color: "#fff"}}>
            Rejected
        </div>
    }
    else if(status === 'delivering'){
        return <div style={{...STATUS_STYLE, backgroundColor: '#88D1F1', color: '#fff'}}>
            Delivering
        </div>
    }
    else if(status === 'canceled'){
        return <div style={{...STATUS_STYLE, backgroundColor: '#ec1529', color: '#fff'}}>
            Canceled
        </div>
    }
    else if(status === 'delivered'){
        return <div style={{...STATUS_STYLE, backgroundColor: '#ff8b3d', color: '#fff'}}>
            Delivered
        </div>
    }
    else if(status === 'complete'){
        return <div style={{...STATUS_STYLE, backgroundColor: "#9AD6AB",color: "#fff"}}>
            Completed
        </div>
    }
    else{
        return <div style={{...STATUS_STYLE, backgroundColor: '#000', color: 'white'}}>....</div>
    }
}


const Styles = {
    badge: {
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 8,
        paddingRight: 8,
        borderRadius:6,
        backgroundColor: "#9AD6AB",
        color: "#40764F",
        fontSize: 12,
    }
}

export default Order_Status