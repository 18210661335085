import React, { useEffect, useState } from "react";

import { postApiPrivate } from "../../api";

import moment from "moment";
import { CloseOutline } from "react-ionicons";

import { GetRouteSearchParams } from "../../helpers/GeneralHelper";

import Countries_EN from '../Search/countries_en.json';
import { Currencies_Array, Filter_Array, Languages_Array } from "./Constants";
import SearchDates from "../General/SearchDates";

const StoreFilter = (props) => {


    const { onClose, USER, history, location, match, DASHBOARD, CURRENCY, LANGUAGE, TRANSLATE, isMap } = props;

    const [Country, setCountry] = useState('all');
    const [City, setCity] = useState(null);
    const [Range, setRange] = useState(100);

    const [Status, setStatus] = useState('all');
    const [Approval, setApproval] = useState('all');
    const [Push, setPush] = useState('all');

    const [Categories, setCategories] = useState([]);

    const [Reviews, setReviews] = useState('all');
    const [Language, setLanguage] = useState('all');
    const [Currency, setCurrency] = useState('all');

    const [Products, setProducts] = useState('all');
    const [Orders, setOrders] = useState('all');
    const [Delivery, setDelivery] = useState('all');

    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);

    //Extra
    const [isLoading, setLoading] = useState(false);
    const [SearchResults, setSearchResults] = useState([]);
    const [SearchInput, setSearchInput] = useState('');

    const [isSeachDates, setSearchDates] = useState(false);


    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);

        const routeCountry = routeParams.country || null;
        const routeCity = routeParams.city || null;
        const routeName = routeParams.name || null;
        const routeRange = routeParams.range || null;

        const routeStatus = routeParams.status || null;
        const routeApproval = routeParams.approval || null;
        const routePush = routeParams.push || null;

        const routeOrders = routeParams.orders || null;
        const routeProducts = routeParams.products || null;
        const routeDelivery = routeParams.delivery || null;

        const routeCategories = routeParams.categories || null;

        const routeLat = routeParams.lat || null;
        const routeLon = routeParams.lon || null;

        const routeStart = routeParams.start || null;
        const routeEnd = routeParams.end || null;
        const routeReviews = routeParams.reviews || null;
        const routeLanguage = routeParams.language || null;
        const routeCurrency = routeParams.currency || null;

        if (routeCountry) setCountry(routeCountry);
        if (routeCity && routeName) {
            setCity({
                id: routeCity,
                name: routeName,
                lat: routeLat,
                lon: routeLon
            })
            setSearchInput(routeName)
        }
        if (routeRange) setRange(routeRange);
        if (routeStatus) setStatus(routeStatus);
        if (routeApproval) setApproval(routeApproval);

        if (routeReviews) setReviews(routeReviews);
        if (routeLanguage) setLanguage(routeLanguage);
        if (routeCurrency) setCurrency(routeCurrency);

        if (routeStart) setStartDate(routeStart);
        if (routeEnd) setEndDate(routeEnd);

        if (routePush) setPush(routePush);
        if (routeOrders) setOrders(routeOrders);
        if (routeProducts) setProducts(routeProducts);
        if (routeDelivery) setDelivery(routeDelivery);


        if (routeCategories) {
            const newCategories = routeCategories.split('-') || [];
            setCategories(newCategories)
        }


    }, [location.search]);

    const onApplyFilter = () => {

        let newQuery = '/admin/stores/0?sort=updatedAt&dir=desc';

        if (isMap) {
            newQuery = `/admin/map?search=stores&sort=updatedAt&dir=desc`
        }

        if (Country) newQuery = newQuery + '&country=' + Country;

        if (City) newQuery = newQuery + '&city=' + City.id + '&name=' + City.name + '&lat=' + City.lat + '&lon=' + City.lon;

        if (Range) newQuery = newQuery + '&range=' + Range;

        if (Status) newQuery = newQuery + '&status=' + Status;

        if (Push) newQuery = newQuery + '&push=' + Push;

        if (Approval) newQuery = newQuery + '&approval=' + Approval;

        if (Categories.length > 0) {
            const newCategories = Categories.join('-');
            newQuery = newQuery + '&categories=' + newCategories;
        }

        if (Language) newQuery = newQuery + '&language=' + Language;
        if (Currency) newQuery = newQuery + '&currency=' + Currency;
        if (Reviews) newQuery = newQuery + '&reviews=' + Reviews;


        if (StartDate && EndDate) {
            newQuery = newQuery + '&start=' + StartDate + '&end=' + EndDate;
        }


        if (Orders) newQuery = newQuery + '&orders=' + Orders;
        if (Products) newQuery = newQuery + '&products=' + Products;
        if (Delivery) newQuery = newQuery + '&delivery=' + Delivery;

        history.push(newQuery);

        onClose();
    }

    const onSearchCities = async (city) => {

        setSearchInput(city);

        if (!city || city.length <= 3) {
            setSearchResults([]);
            return
        }

        //Fetch

        setLoading(true);

        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/locations/search', { query: city }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if (filtredResults.length > 0) {
                setSearchResults(filtredResults);
            }

            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setSearchResults([]);
            setLoading(false);
        }


    }

    const onSelectCity = (city) => {
        //alert(JSON.stringify(city))
        setCity({
            id: city.id,
            name: city['en'],
            lat: city.geocode.latitude,
            lon: city.geocode.longitude
        });
        setCountry(city.country);
        setSearchInput(city['en']);
        setSearchResults([]);
    }

    const onSelectCountry = (country) => {
        setCountry(country);
        setCity(null);
        setSearchInput('');
        setSearchResults([]);
    }

    const onSelectCategory = (category) => {

        if (Categories.includes(category)) {
            setCategories(prev => prev.filter((item) => item !== category));
        }
        else {
            setCategories(prev => [...prev, category])
        }
    }

    const onUpdateDate = (type) => {

        var REFERENCE = moment(); // fixed just for testing, use moment();
        var TODAY = REFERENCE.clone().startOf('day');
        var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
        var A_7DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_14DAYS_OLD = REFERENCE.clone().subtract(14, 'days').startOf('day'); // fixed
        var A_30DAYS_OLD = REFERENCE.clone().subtract(30, 'days').startOf('day');
        var A_60DAYS_OLD = REFERENCE.clone().subtract(60, 'days').startOf('day');
        var A_90DAYS_OLD = REFERENCE.clone().subtract(90, 'days').startOf('day');
        var A_120DAYS_OLD = REFERENCE.clone().subtract(120, 'days').startOf('day');
        var A_150DAYS_OLD = REFERENCE.clone().subtract(150, 'days').startOf('day');
        var A_180DAYS_OLD = REFERENCE.clone().subtract(180, 'days').startOf('day');

        let StartDate = null;
        let EndDate = null;

        if (type === 'today') {
            StartDate = TODAY.format('DD-MM-YYYY');
            EndDate = TODAY.format('DD-MM-YYYY');
        }
        else if (type === 'yesterday') {
            StartDate = YESTERDAY.format('DD-MM-YYYY');
            EndDate = YESTERDAY.format('DD-MM-YYYY');
        }
        else if (type === 'last_7') {
            StartDate = A_7DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_14') {
            StartDate = A_14DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_30') {
            StartDate = A_30DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_60') {
            StartDate = A_60DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_90') {
            StartDate = A_90DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_120') {
            StartDate = A_120DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_150') {
            StartDate = A_150DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_180') {
            StartDate = A_180DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'this_month') {
            StartDate = REFERENCE.clone().startOf('month').format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_month') {
            StartDate = REFERENCE.clone().subtract(1, 'month').startOf('month').format('DD-MM-YYYY');
            EndDate = REFERENCE.clone().subtract(1, 'month').endOf('month').format('DD-MM-YYYY');
        }
        else {
            StartDate = '01-01-2021';
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }

        setStartDate(StartDate);
        setEndDate(EndDate);
    }


    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Store Filter
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        <>
                            <div className="d-flex flex-column align-items-center justify-content-center md:pb-0 pb-32">

                                <div className="w-full mt-8 flex flex-col text-black">

                                    <div className="flex items-center justify-between gap-x-2">

                                        <div className="w-full">
                                            {/* Store Status */}
                                            <div className="font-semibold mb-1 mt-4">Status</div>
                                            <select value={Status} onChange={(e) => setStatus(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Stores
                                                </option>
                                                <option value="true">
                                                    Only Published
                                                </option>
                                                <option value="false">
                                                    Only Unpublished
                                                </option>
                                            </select>
                                        </div>

                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Dates</div>
                                            {(StartDate && EndDate) ? (
                                                <div className="flex items-center justify-between gap-x-2">
                                                    <input placeholder="Order Created Between..." style={{ border: 'solid 1px #000000', color: '#000', width: '100%', marginRight: 0 }}
                                                        onChange={(e) => null}
                                                        value={`${StartDate} → ${EndDate}`}
                                                        autoComplete="new-password"
                                                        onClick={() => setSearchDates(true)}
                                                    />
                                                    <div className="flex items-center justify-center bg-red-500 cursor-pointer rounded-full shrink-0" onClick={() => {
                                                        setStartDate(null);
                                                        setEndDate(null);
                                                    }}>
                                                        <CloseOutline color={'#fff'} />
                                                    </div>
                                                </div>

                                            ) : (
                                                <select value={''} onChange={(e) => onUpdateDate(e.target.value)} className="text-black">
                                                    <option value="all">
                                                        All Time
                                                    </option>
                                                    <option value="today">
                                                        Today
                                                    </option>
                                                    <option value="yesterday">
                                                        Yesterday
                                                    </option>
                                                    <option value="this_month">
                                                        This month
                                                    </option>
                                                    <option value="last_month">
                                                        Last Month
                                                    </option>
                                                    <option value="last_7">
                                                        Last 7 days
                                                    </option>
                                                    <option value="last_14">
                                                        Last 14 days
                                                    </option>
                                                    <option value="last_21">
                                                        Last 21 days
                                                    </option>
                                                    <option value="last_30">
                                                        Last 30 days
                                                    </option>
                                                    <option value="last_60">
                                                        Last 60 days
                                                    </option>
                                                    <option value="last_90">
                                                        Last 90 days
                                                    </option>
                                                    <option value="last_120">
                                                        Last 120 days
                                                    </option>
                                                    <option value="last_150">
                                                        Last 150 days
                                                    </option>
                                                    <option value="last_180">
                                                        Last 180 days
                                                    </option>
                                                    <option value="custom">
                                                        Custom Dates
                                                    </option>

                                                </select>
                                            )}

                                        </div>


                                    </div>

                                    <div className="flex items-center justify-between gap-x-2">


                                        <div className="w-full">
                                            {/* Need Approval */}
                                            <div className="font-semibold mb-1 mt-4">Approval</div>
                                            <select value={Approval} onChange={(e) => setApproval(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Stores
                                                </option>
                                                <option value="false">
                                                    Only Approved
                                                </option>
                                                <option value="true">
                                                    Only Unapproved
                                                </option>
                                            </select>
                                        </div>

                                        <div className="w-full">
                                            {/* Need Approval */}
                                            <div className="font-semibold mb-1 mt-4">Notifications</div>
                                            <select value={Push} onChange={(e) => setPush(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Stores
                                                </option>
                                                <option value="true">
                                                    Only Push Active
                                                </option>
                                                <option value="false">
                                                    Only Push Deactivated
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between gap-x-2">
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Language</div>
                                            <select value={Language} onChange={(e) => setLanguage(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Stores
                                                </option>
                                                {Languages_Array.map((item) => {
                                                    return (
                                                        <option value={item.code} key={item.code}>
                                                            {item.name}
                                                        </option>

                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Currency</div>
                                            <select value={Currency} onChange={(e) => setCurrency(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Stores
                                                </option>
                                                {Currencies_Array.map((item) => {
                                                    return (
                                                        <option value={item.code} key={item.code}>
                                                            {item.name}
                                                        </option>

                                                    )
                                                })}

                                            </select>
                                        </div>

                                    </div>

                                    <div className="flex items-center justify-between gap-x-2">

                                        <div className="w-full">
                                            {/* Need Approval */}
                                            <div className="font-semibold mb-1 mt-4">Products</div>
                                            <select value={Products} onChange={(e) => setProducts(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Stores
                                                </option>
                                                <option value="true">
                                                    Only with Products
                                                </option>
                                                <option value="false">
                                                    Only without Products
                                                </option>
                                            </select>
                                        </div>

                                        <div className="w-full">
                                            {/* Need Approval */}
                                            <div className="font-semibold mb-1 mt-4">Delivery</div>
                                            <select value={Delivery} onChange={(e) => setDelivery(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Stores
                                                </option>
                                                <option value="free">
                                                    Only with Free Delivery
                                                </option>
                                                <option value="true">
                                                    Only with Delivery
                                                </option>
                                                <option value="false">
                                                    Only without Delivery
                                                </option>
                                                <option value="airport">
                                                    Only with Airports
                                                </option>
                                                <option value="station">
                                                    Only with Train Stations
                                                </option>
                                                <option value="port">
                                                    Only with Ports
                                                </option>
                                                <option value="place">
                                                    Only with Places
                                                </option>
                                                <option value="park">
                                                    Only with Parks
                                                </option>
                                                <option value="city">
                                                    Only with City
                                                </option>
                                                <option value="home">
                                                    Only with Home
                                                </option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="flex items-center justify-between gap-x-2">

                                        <div className="w-full">
                                            {/* Need Approval */}
                                            <div className="font-semibold mb-1 mt-4">Orders</div>
                                            <select value={Orders} onChange={(e) => setOrders(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Stores
                                                </option>
                                                <option value="true">
                                                    Only with Orders
                                                </option>
                                                <option value="false">
                                                    Only without Orders
                                                </option>
                                            </select>
                                        </div>

                                        <div className="w-full">
                                            <div className="font-semibold mb-1 mt-4">Reviews</div>
                                            <select value={Reviews} onChange={(e) => setReviews(e.target.value)} className="text-black">
                                                <option value="all">
                                                    All Stores
                                                </option>
                                                <option value="true">
                                                    Only with Reviews
                                                </option>
                                                <option value="false">
                                                    Only without Reviews
                                                </option>
                                                <option value="1">
                                                    1 Star
                                                </option>
                                                <option value="2">
                                                    2 Stars
                                                </option>
                                                <option value="3">
                                                    3 Stars
                                                </option>
                                                <option value="4">
                                                    4 Stars
                                                </option>
                                                <option value="5">
                                                    5 Stars
                                                </option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="flex items-center justify-between gap-x-2">

                                        <div className="w-full">
                                            {/* Store Country */}
                                            <div className="font-semibold mb-1 mt-4">Country</div>
                                            <select value={Country} onChange={(e) => onSelectCountry(e.target.value)} className="text-black">

                                                <option value="all">
                                                    All Counties
                                                </option>
                                                {Countries_EN.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.value}>
                                                            {item.label}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                        <div className="w-full">
                                            {/* Store Country */}
                                            <div className="font-semibold mb-1 mt-4">City</div>
                                            <div className="flex">
                                                <input placeholder="Search by city" style={{ border: 'solid 1px #000000', color: '#000', width: City?.id ? '90%' : '100%', marginRight: City?.id ? 8 : 0 }}
                                                    onChange={(e) => onSearchCities(e.target.value)}
                                                    value={SearchInput}
                                                    autoComplete="new-password"
                                                />
                                                {City?.id && (
                                                    <div className="flex items-center justify-center bg-green-700 rounded-full text-white" style={{ width: 40, height: 40 }}>
                                                        ok
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                    </div>

                                    {City?.id && (
                                        <>
                                            <div className="font-semibold mb-1 mt-4">Range</div>
                                            <div className="flex">
                                                <input placeholder="100 km" style={{ border: 'solid 1px #000000', color: '#000', width: City?.id ? '90%' : '100%', marginRight: City?.id ? 8 : 0 }}
                                                    onChange={(e) => setRange(parseInt(e.target.value))}
                                                    value={Range}
                                                    autoComplete="new-password"
                                                    type="number"
                                                />
                                                {Range > 0 && (
                                                    <div className="flex items-center justify-center rounded-full text-white" style={{ width: 40, height: 40 }}>
                                                        Km
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {SearchResults.length > 0 && (
                                        <div className="flex flex-col w-full">
                                            {SearchResults.map((item, index) => {

                                                const country_index = Countries_EN.findIndex((cty) => cty.value === item.country);
                                                const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
                                                const country_name = country_obx ? country_obx.label : null

                                                return (

                                                    <div className="w-full p-3 my-3 flex items-center cursor-pointer hover:bg-zinc-100" onClick={() => onSelectCity(item)} key={index}>
                                                        <img className='w-[20px] h-[20px] mr-3 mb-auto mt-1' src="/assets/img/location.svg" alt="" />
                                                        {item[LANGUAGE]} - ({country_name || item.country})
                                                    </div>
                                                )

                                            })}
                                        </div>
                                    )}



                                    {/* Categories */}
                                    <div className="font-semibold mb-1 mt-4">Categories</div>
                                    <div className="w-full flex flex-wrap gap-2">

                                        {Filter_Array.map((item, index) => {

                                            const isSelected = Categories.includes(item.code);

                                            return (
                                                <div key={index} style={{ border: 'solid 1px #000' }} className={"rounded-xl p-2 cursor-pointer " + (isSelected ? 'bg-bagreen-100 text-white' : '')} onClick={() => onSelectCategory(item.code)}>
                                                    <img src={item.icon} className="w-[20px] h-[20px] mr-1" style={{ filter: (isSelected ? 'brightness(0) saturate(100%) invert(99%) sepia(11%) saturate(2%) hue-rotate(269deg) brightness(115%) contrast(100%)' : '') }} />  {item['en']}
                                                </div>
                                            )

                                        })}

                                    </div>


                                </div>

                                <div style={{ height: 200 }}></div>
                            </div>
                        </>

                    </div>

                    <div className="d-flex align-items-center justify-content-between" style={{ backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>
                        <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5 }} onClick={() => onClose()}>
                            Back
                        </div>


                        <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#26BFBF', marginLeft: 5 }} onClick={() => onApplyFilter()}>
                            Apply Filters
                        </div>

                    </div>


                </div>

            </div>

            {isSeachDates && (
                <SearchDates {...props}
                    onClose={() => setSearchDates(false)}
                    onUpdateDate={({ from, to }) => {
                        setStartDate(from);
                        setEndDate(to)
                    }}

                />
            )}

        </>
    )
}


export default StoreFilter