import React, { useEffect, useState } from 'react';

import {postApiPrivate} from '../../api';

import {Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';
import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import { Add } from '@mui/icons-material';
import { numberWithCommas } from '../../helpers/GeneralHelper';

const AdminGiftCards = (props) => {

    const {TRANSLATE, history, match, location, USER} = props;

    const [isLoading, setLoading] = useState(true);
     
    const [searchText, setSearchText] = useState('');
 
    const [PAGE, setPage] = useState(0);

    const [GIFTCARDS, setGiftCards] = useState([]); 
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT*(PAGE+1);

    if(TOTAL < CURRENT){
        CURRENT = TOTAL;
    }

    useEffect(()=> {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if(!JWT){
            return history.replace('/login');
        }
        else{

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;
        
            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;
           
            var current_page = parseInt(page_id);

            if(USER.admin){
                onFetchGiftCards({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else{
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')
            
                history.push('/logout')
            }

        }

        window.scrollTo(0,0);

    }, [match.params.page, location.search]);

    const onFetchGiftCards = async ({page, sort, direction}) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
        setLoading(true);

        try{

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/giftcards', FORM , JWT);

            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setGiftCards(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0,0)
            

        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchGiftCards = async(query) => {

        setSearchText(query);

        if(!query || query.length == 0){

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchGiftCards({
                page: current_page
            });

            return 
        }

        if(query.length <= 3){

            return 
        }


        try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/giftcards/search', {query: query} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setGiftCards(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);

            setTotal(0);
            setGiftCards([]);
            setLoading(false);
        }
      
           
    }

    const onSortGiftCards = (sort) => {

        if(sort === 'id'){
            return 'objectId'
        }
        else if(sort === 'name'){
            return 'search_name'
        }
        else if(sort === 'img'){
            return 'photo'
        }
        else if(sort === 'lang'){
            return 'language'
        }
        else if(sort === 'isCompleted'){
            return 'setup'
        }
        else{
            return sort
        }
    }

    const onChangePageGiftCards = ({page, sort, direction}) => {
        let new_url = '/admin/giftcards/'+page;

        if(sort){
            new_url = new_url+'?sort='+sort+'&dir='+direction;
        }

        history.push(new_url);
    }

    const onSelectGiftCard = (giftcard_id) => {
        history.push('/admin/giftcards/profile/'+giftcard_id);
    }


    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={()=> onSelectGiftCard(id)} style={{cursor: 'pointer'}}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "sender",
            label: "SENDER",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const giftcardObx = GIFTCARDS[rowIndex] || {};

                    const giftcard_id = giftcardObx.id;
                    const fullname = giftcardObx.fullname || '';

                    return (
                        <div style={{maxWidth: 200}}>
                            <Chip
                                label={fullname.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{textTransform: 'uppercase'}}
                                onClick={()=> onSelectGiftCard(giftcard_id)}
                            />
                        </div>
                        
                    )
                
                }
            },
            
        },
        {
            name: "recipient",
            label: "RECIPIENT",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const giftcardObx = GIFTCARDS[rowIndex] || {};

                    const giftcard_id = giftcardObx.id;
                    const recipient_fullname = giftcardObx.recipient_fullname || '';

                    return (
                        <div style={{maxWidth: 200}}>
                            <Chip
                                label={recipient_fullname.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{textTransform: 'uppercase'}}
                                onClick={()=> onSelectGiftCard(giftcard_id)}
                            />
                        </div>
                        
                    )
                
                }
            },
            
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const giftcardObx = GIFTCARDS[rowIndex] || {};

                    const status = giftcardObx.status || '';
                    
                    if(status === 'pending'){
                        return (
                            <Chip label={status} size='small' className='capitalize' color='warning' />
                        )
                    }
                    else if (status === 'paid'){
                        return (
                            <Chip label={status} size='small' className='capitalize' color='info' />
                        )
                    }
                    else if (status === 'canceled'){
                        return (
                            <Chip label={status} size='small' className='capitalize' color='error' />
                        )
                    }
                    else if (status === 'redeemed'){
                        return (
                            <Chip label={status} size='small' className='capitalize' color='success' />
                        )
                    }
                    
                }
            },
        },
        {
            name: "amount",
            label: "AMOUNT",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const giftcardObx = GIFTCARDS[rowIndex] || {};

                    const giftcard_amount = giftcardObx.amount || 0;
                    const giftcard_currency = giftcardObx.currency || 'usd';

                    return (
                        
                        <Chip label={numberWithCommas(giftcard_amount, giftcard_currency)} size='medium' />

                    )
                }
            },
        },
        {
            name: "type",
            label: "TYPE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const giftcardObx = GIFTCARDS[rowIndex] || {};

                    const giftcard_type = giftcardObx.type || '';

                    return (
                        
                        <Chip label={giftcard_type} size='small' className='uppercase' />

                    )
                }
            },
        },
        {
            name: "delivery",
            label: "DELIVERY",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const giftcardObx = GIFTCARDS[rowIndex] || {};

                    const giftcard_used = giftcardObx.delivery || '';

                    return (
                        
                        <Chip label={giftcard_used} size='small' />

                    )
                }
            },
        },
        {
            name: "message",
            label: "MESSAGE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    if(label === 'ON'){
                        return (
                            <Chip label={'ON'} size='small' color='success' />
                        )
                    }

                    return (
                        <Chip label={'OFF'} size='small' color='error'/>
                    )
                  
                }
            },
        },
        {
            name: "owner",
            label: "REDEEMED FROM",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const giftcardObx = GIFTCARDS[rowIndex] || {};

                    const ownerObx = giftcardObx.owner || {};
                    const owner_id = ownerObx.id || '';
                    const owner_name = ownerObx.name || '';
                    const owner_img = ownerObx.img || '';

                    
                    if(owner_id){
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={owner_name} src={owner_img} />}
                                    label={owner_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{textTransform: 'capitalize'}}
                                    onClick={()=> history.push('/admin/users/profile/'+owner_id)}
                                />
                            </div>
                            
                        )
                    }
                    else{
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },
            
        },
        {
            name: "redeemed",
            label: "REDEEMED AT",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
       
        {
            name: "manager",
            label: "MANAGER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const giftcardObx = GIFTCARDS[rowIndex] || {};

                    const managerObx = giftcardObx.manager || {};
                    const manager_id = managerObx.id || '';
                    const manager_name = managerObx.name || '';
                    const manager_img = managerObx.img || '';

                    
                    if(manager_id){
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                                    label={manager_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{textTransform: 'capitalize'}}
                                    onClick={()=> history.push('/admin/users/profile/'+manager_id)}
                                />
                            </div>
                            
                        )
                    }
                    else{
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },
            
        },
        {
            name: 'isDeleted',
            label: 'DELETED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const giftcardObx = GIFTCARDS[rowIndex] || {};

                    const isDeleted = giftcardObx.isDeleted || false;

                    if(isDeleted){
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                 <Chip size='small' label="Deleted" color='error' />
                            </div>
                           
                        )
                    }
                    else{
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                 <Chip size='small' label="NO" />
                            </div>
                           
                        )
                    }
                }
            }

        },
        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" }}),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        
    ];
  
    const data = GIFTCARDS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_giftcard = {
            id: item.id,
            sender: item.fullname,
            recipient: item.recipient_fullname,
            amount: item.amount,
            delivery: item.delivery,
            message: item.message ? 'ON' : 'OFF',
            status: item.status,
            isDeleted: item.isDeleted || false,
            manager: item.manager || {},
            owner: item.owner || {},
            redeemed: item.redeemed ? moment(item.redeemed).format('DD MMMM YYYY') : '',
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY')
        };

        return new_giftcard
    })

    const customToolbar = () => (
        <Tooltip disableFocusListener title="Add GiftCard">
          <IconButton onClick={() =>history.push('/admin/giftcards/profile/new')}>
            <Add />
          </IconButton>
        </Tooltip>
    );

    const options = {
        filter: false,
        print: false,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageGiftCards({
                        page: tableState.page,
                        sort: onSortGiftCards(sortFilter),
                        direction: sortDirection
                    });
                    
                    break;
                case 'sort':
                    
                    onChangePageGiftCards({
                        page: tableState.page,
                        sort: onSortGiftCards(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchGiftCards(e.target.value);
            },
            onKeyUp:(e) => {
                if(e.keyCode === 13){
                    onSearchGiftCards(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search GiftCard By Name',
        textLabels: {
            body: {
                noMatch: isLoading ? 
                    'Searching GiftCards with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        
    
    
    };
 

    return (
        <div className="top_container small_padding" style={{background: '#f0f5f9'}}>
            <MUIDataTable
                title={
                    <div className='font-light md:text-5xl text-xl text-lg py-8'>

                        {'GiftCards Found → '}
                        
                        {isLoading ? (

                            <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />
                        
                            ) : (

                            <>
                               {' '}<span style={{color: '#26BFBF'}}>{CURRENT}</span>{'/'+TOTAL}
                            </>

                        )} 
                         
                    </div>
                } 
                data={data}
                columns={columns}
                options={options}
            />
        </div>
    );
    
}  



export default AdminGiftCards

