import React, { useEffect, useState } from "react";

import { CalendarOutline, CloseOutline, SettingsSharp } from "react-ionicons";
import { GetRouteSearchParams, numberWithCommas } from "../../helpers/GeneralHelper";
import { postApiPrivate } from "../../api";
import moment from "moment";

import Countries_EN from '../Search/countries_en.json';
import SearchDates from '../General/SearchDates';

const StorePayout = (props) => {


    const { onClose, history, location, STORE_ID } = props;

    const [isBank, setBank] = useState(false);
    const [isSeachDates, setSearchDates] = useState(false);

    const [isLoading, setLoading] = useState(true);
    const [ANALYTICS, setAnalytics] = useState({});
    const [ORDERS, setOrders] = useState([]);
    const [TOTAL, setTotal] = useState(0);
    const [RANGE, setRange] = useState('current_month');
    const [TYPE, setType] = useState('payout');

    useEffect(() => {

        const CurrentParams = GetRouteSearchParams(location.search) || {};
        const filter_range = CurrentParams.range || 'current_month';
        const filter_start = CurrentParams.start || null;
        const filter_end = CurrentParams.end || null;

        setRange(filter_range);

        onFetchAnalytics({
            type: TYPE,
            filter_range,
            filter_start,
            filter_end
        });

    }, [location.search, TYPE]);

    const onFetchAnalytics = async ({ filter_range, filter_start, filter_end, type }) => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const store_id = STORE_ID || '';

        if (!store_id) {
            return alert('Error');
        }

        let FORM = {
            id: store_id,
            type
        };


        if (filter_range === 'custom_range') {

            FORM = {
                ...FORM,
                start: filter_start,
                end: filter_end
            }
        }
        else {

            const TimesObx = onFetchTimes(filter_range);

            FORM = {
                ...FORM,
                range: filter_range,
                start: TimesObx.start,
                end: TimesObx.end
            }
        }


        setLoading(true);

        const DATA_RESPONSE = await postApiPrivate('/admin/payouts/analytics', FORM, JWT);

        if (DATA_RESPONSE.error) {
            console.log("ERROR FROM API LOADING PRODUCTS", DATA_RESPONSE.error);

            setLoading(false);

            return
        }


        const DATA_ANALYTICS = DATA_RESPONSE.a || {};
        const DATA_ORDERS = DATA_RESPONSE.o || [];
        const DATA_TOTAL = DATA_RESPONSE.t || 0;

        setAnalytics(DATA_ANALYTICS);
        setOrders(DATA_ORDERS);
        setTotal(DATA_TOTAL)

        setLoading(false);
    }

    const onFetchTimes = (type) => {

        var REFERENCE = moment(); // fixed just for testing, use moment();
        var TODAY = REFERENCE.clone().startOf('day');
        var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
        var A_7DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_14DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_30DAYS_OLD = REFERENCE.clone().subtract(30, 'days').startOf('day');
        var A_60DAYS_OLD = REFERENCE.clone().subtract(60, 'days').startOf('day');
        var A_90DAYS_OLD = REFERENCE.clone().subtract(90, 'days').startOf('day');
        var A_180DAYS_OLD = REFERENCE.clone().subtract(180, 'days').startOf('day');

        const CurrentYear = new Date().getFullYear();
        const CurrentMonth = new Date().getMonth();
        const CalendarArray = getAllDaysInMonth(CurrentYear, CurrentMonth) || [];

        var A_START_MONTH = moment(new Date(CalendarArray[0])).startOf('day');
        var A_END_MONTH = moment(new Date(CalendarArray[CalendarArray.length - 1])).startOf('day');


        let StartDate = null;
        let EndDate = null;

        if (type === 'today') {
            StartDate = TODAY.format('DD-MM-YYYY');
            EndDate = TODAY.format('DD-MM-YYYY');
        }
        else if (type === 'yesterday') {
            StartDate = YESTERDAY.format('DD-MM-YYYY');
            EndDate = YESTERDAY.format('DD-MM-YYYY');
        }
        else if (type === 'last_7') {
            StartDate = A_7DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_14') {
            StartDate = A_14DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_30') {
            StartDate = A_30DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_60') {
            StartDate = A_60DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_90') {
            StartDate = A_90DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_180') {
            StartDate = A_180DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'current_month') {
            StartDate = A_START_MONTH.format('DD-MM-YYYY');
            EndDate = A_END_MONTH.format('DD-MM-YYYY');
        }
        else {
            StartDate = '01-01-2021';
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }

        return {
            start: StartDate,
            end: EndDate
        }

    }

    const onUpdateDate = (type) => {

        if (type.from && type.to) {
            history.push('?range=custom_range' + '&start=' + type.from + '&end=' + type.to);
            return
        }

        var REFERENCE = moment(); // fixed just for testing, use moment();
        var TODAY = REFERENCE.clone().startOf('day');
        var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
        var A_7DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_14DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_30DAYS_OLD = REFERENCE.clone().subtract(30, 'days').startOf('day');
        var A_60DAYS_OLD = REFERENCE.clone().subtract(60, 'days').startOf('day');
        var A_90DAYS_OLD = REFERENCE.clone().subtract(90, 'days').startOf('day');
        var A_180DAYS_OLD = REFERENCE.clone().subtract(180, 'days').startOf('day');

        const CurrentYear = new Date().getFullYear();
        const CurrentMonth = new Date().getMonth();
        const CalendarArray = getAllDaysInMonth(CurrentYear, CurrentMonth) || [];

        var A_START_MONTH = moment(new Date(CalendarArray[0])).startOf('day');
        var A_END_MONTH = moment(new Date(CalendarArray[CalendarArray.length - 1])).startOf('day');


        let StartDate = null;
        let EndDate = null;

        if (type === 'today') {
            StartDate = TODAY.format('DD-MM-YYYY');
            EndDate = TODAY.format('DD-MM-YYYY');
        }
        else if (type === 'yesterday') {
            StartDate = YESTERDAY.format('DD-MM-YYYY');
            EndDate = YESTERDAY.format('DD-MM-YYYY');
        }
        else if (type === 'last_7') {
            StartDate = A_7DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_14') {
            StartDate = A_14DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_30') {
            StartDate = A_30DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_60') {
            StartDate = A_60DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_90') {
            StartDate = A_90DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'last_180') {
            StartDate = A_180DAYS_OLD.format('DD-MM-YYYY');
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }
        else if (type === 'current_month') {
            StartDate = A_START_MONTH.format('DD-MM-YYYY');
            EndDate = A_END_MONTH.format('DD-MM-YYYY');
        }
        else if (type === 'custom_range') {
            setSearchDates(true)
            return
        }
        else {
            StartDate = '01-01-2021';
            EndDate = REFERENCE.format('DD-MM-YYYY');
        }

        history.push('?range=' + type + '&start=' + StartDate + '&end=' + EndDate);


    }


    if (isSeachDates) {

        return (
            <>
                <SearchDates {...props}
                    onClose={() => setSearchDates(false)}
                    onUpdateDate={onUpdateDate}

                />
            </>
        )
    }

    if (isBank) {

        return (
            <>
                <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                    <div className="slideout-content" style={{ color: '#000000' }}>

                        <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                            <div className="font-bold text-lg">
                                Bank Information
                            </div>

                            <CloseOutline color={"#000"} onClick={() => setBank(false)} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                        </div>

                        <div className="slideout-content-scroll" style={{ margin: 0 }}>

                            <div className="d-flex flex-column pt-4 md:pb-0 pb-32">

                                <WalletBank {...props} />

                                <div style={{ height: 200 }}></div>
                            </div>

                        </div>


                    </div>

                </div>


            </>
        )
    }

    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Store Payout
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        <div className="d-flex flex-column pt-4 md:pb-0 pb-32">

                            {/* <div style={{whiteSpace: 'break-spaces'}}>
                                {JSON.stringify(DASHBOARD, null, 2)}
                            </div> */}

                            <WalletAnalytics {...props}
                                setBank={setBank}
                                setSearchDates={setSearchDates}
                                onUpdateType={setType}
                                ANALYTICS={ANALYTICS}
                                TYPE={TYPE}
                                isLoading={isLoading}
                            />

                            <WalletTransactions {...props}
                                onUpdateDate={onUpdateDate}
                                ORDERS={ORDERS}
                                TOTAL={TOTAL}
                                RANGE={RANGE}
                                isLoading={isLoading}

                            />

                            <div style={{ height: 200 }}></div>
                        </div>

                    </div>


                </div>

            </div>


        </>
    )
}

const WalletBank = (props) => {


    const { location, STORE_ID, TRANSLATE, LANGUAGE, DASHBOARD } = props;

    const [isDrop, setDrop] = useState(false);

    const [FIRST_NAME, setFirstName] = useState('');
    const [LAST_NAME, setLastName] = useState('');

    const [BANK_COUNTRY, setBankCountry] = useState('it');
    const [BANK_TYPE, setBankType] = useState('iban');

    const [BANK_NAME, setBankName] = useState('');
    const [BANK_IBAN, setBankIban] = useState('');
    const [SOCIAL_SECURITY, setSocialSecurity] = useState('');

    const [BANK_ROUTING, setBankRouting] = useState('');
    const [BANK_ACCOUNT, setBankAccount] = useState('');

    const [TEMP_IBAN, setTempIban] = useState('');

    const [isConnected, setConnected] = useState(false);

    const [isLoading, setLoading] = useState(true);
    const [isSaving, setSaving] = useState(false);

    // return (
    //     <div style={{whiteSpace: 'break-spaces'}}>
    //         {JSON.stringify(DASHBOARD, null, 2)}
    //     </div>
    // )


    useEffect(() => {

        if (DASHBOARD.name) {
            setLoading(false);

            if (DASHBOARD.bank) {

                // alert(JSON.stringify(DASHBOARD.bank))

                setSocialSecurity(DASHBOARD.fiscal);
                setTempIban(DASHBOARD.temp_iban);
                setFirstName(DASHBOARD.bank.fn);
                setLastName(DASHBOARD.bank.ln);
                setBankName(DASHBOARD.bank.bn);
                setBankCountry(DASHBOARD.bank.ct);
                setBankIban(DASHBOARD.bank.ib);
                setBankRouting(DASHBOARD.bank.rt);
                setBankAccount(DASHBOARD.bank.ac);

                setConnected(true);


            }
        }

    }, [DASHBOARD]);


    if (isLoading || isSaving) {
        return (
            <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
                <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
            </div>
        )
    }


    var CAN_SAVE = false;

    if (BANK_TYPE === 'iban') {

        CAN_SAVE = (FIRST_NAME && LAST_NAME && BANK_NAME && BANK_IBAN && BANK_COUNTRY && SOCIAL_SECURITY);

    }
    else {

        CAN_SAVE = (FIRST_NAME && LAST_NAME && BANK_NAME && BANK_ACCOUNT && BANK_ROUTING && SOCIAL_SECURITY);
    }

    const BANK_COUNTRY_INDEX = Countries_EN.findIndex((item) => item.value.toLowerCase() === BANK_COUNTRY);
    const BANK_COUNTRY_NAME = BANK_COUNTRY_INDEX != -1 ? Countries_EN[BANK_COUNTRY_INDEX].label : '';


    // if(isConnected){
    //     return(
    //         <>
    //             <div className="w-full mt-8">
    //                 <div className='flex w-full items-center justify-between mb-4'>

    //                     <div>
    //                         <label className='font-semibold'>
    //                             {TRANSLATE.wd_list_payout.t}
    //                             <Tooltip desc={TRANSLATE.wd_list_payout.s} />
    //                         </label>
    //                     </div>

    //                 </div>

    //                 <div className="w-full border border-zinc-200 rounded-lg mt-2 flex-wrap p-4">
    //                     <div className="w-full flex items-center">
    //                         <img className="mr-3" src="/assets/img/package.svg" />
    //                         <div className="font-medium">{TRANSLATE.wd_list_payout.e} <span className='text-bagreen-100'>({TRANSLATE.wd_list_payout.connected})</span></div>
    //                         <img className="rotate-90 ml-auto" src="/assets/img/chevron-right.svg" />

    //                     </div>
    //                     <div className="w-full flex flex-wrap gap-x-3 md:pl-9 mt-10 justify-between">
    //                         <div className="input-group half-width">
    //                             <label>{TRANSLATE.wd_first_name}</label>
    //                             <input type="text" placeholder="John" value={FIRST_NAME} disabled/>
    //                         </div>
    //                         <div className="input-group half-width">
    //                             <label>{TRANSLATE.wd_last_name}</label>
    //                             <input type="text" placeholder="Doe" value={LAST_NAME} disabled/>
    //                         </div>
    //                         <div className="input-group half-width">
    //                             <label>{TRANSLATE.wd_list_payout.c}</label>
    //                             <input type="text" placeholder="Unicredit bank" value={BANK_COUNTRY_NAME} disabled />
    //                         </div>
    //                         <div className="input-group half-width">
    //                             <label>{TRANSLATE.wd_list_payout.a}</label>
    //                             <input type="text" placeholder="Unicredit bank" value={BANK_NAME} disabled />
    //                         </div>

    //                         {/* <div className="flex w-full mb-4 items-center justify-between mt-4">
    //                             <div className="cursor-pointer" onClick={()=> history.goBack()}>
    //                                 {TRANSLATE.btn_back}
    //                             </div>
    //                             <div className='btn-provider bg-red-500' onClick={()=> onDisconnect()}>
    //                                 {TRANSLATE.wd_list_payout.disconnect}
    //                             </div>
    //                         </div> */}
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // }

    return (
        <>

            <div className="w-full mt-8">

                {TEMP_IBAN && (
                    <div className="w-full border border-zinc-200 rounded-lg mt-2 flex-wrap p-4">
                        <div className="w-full flex items-center">
                            <img className="mr-3" src="/assets/img/package.svg" />
                            <div className="font-medium">Legacy IBAN</div>
                            <img className="rotate-90 ml-auto" src="/assets/img/chevron-right.svg" />
                        </div>
                        <div>
                            <div className="input-group">
                                <label>IBAN</label>
                                <input type="text" className="pt-0" placeholder="Ex. IT222244444777788884444" onChange={(e) => null} value={TEMP_IBAN} />
                            </div>
                        </div>
                    </div>
                )}

                <div className="w-full border border-zinc-200 rounded-lg mt-2 flex-wrap p-4">

                    <div className="w-full flex items-center">
                        <img className="mr-3" src="/assets/img/package.svg" />
                        <div className="font-medium">{TRANSLATE.wd_list_payout.e}</div>
                        <img className="rotate-90 ml-auto" src="/assets/img/chevron-right.svg" />
                    </div>
                    <div className="w-full flex flex-wrap gap-x-3 md:pl-9 mt-10 justify-between">
                        <div className="input-group half-width">
                            <label>{TRANSLATE.wd_first_name}</label>
                            <input type="text" className="pt-0" placeholder="John" onChange={(e) => setFirstName(e.target.value)} value={FIRST_NAME} />
                        </div>
                        <div className="input-group half-width">
                            <label>{TRANSLATE.wd_last_name}</label>
                            <input type="text" className="pt-0" placeholder="Doe" onChange={(e) => setLastName(e.target.value)} value={LAST_NAME} />
                        </div>
                        <div className="input-group half-width">
                            <label>{TRANSLATE.wd_list_payout.c}</label>
                            <select value={BANK_COUNTRY} onChange={(e) => setBankCountry(e.target.value)} style={{ background: '#f4f4f5', color: '#000000' }}>
                                <option value={null}>Select Country</option>
                                {Countries_EN.map((item, index) => {
                                    const country_code = item.value.toLowerCase();
                                    return (
                                        <option key={index} value={country_code}>
                                            {item.label}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="input-group half-width">
                            <label>{TRANSLATE.wd_list_payout.d}</label>
                            <select value={BANK_TYPE} onChange={(e) => setBankType(e.target.value)} style={{ background: '#f4f4f5', color: '#000000' }}>
                                <option value={'iban'}>IBAN</option>
                                <option value={'account'}>{TRANSLATE.wd_list_payout.f}</option>
                            </select>
                        </div>

                        {BANK_TYPE === 'iban' ? (
                            <>
                                <div className="input-group half-width">
                                    <label>{TRANSLATE.wd_list_payout.a}</label>
                                    <input type="text" className="pt-0" placeholder="Unicredit bank" onChange={(e) => setBankName(e.target.value)} value={BANK_NAME} />
                                </div>
                                <div className="input-group half-width">
                                    <label>{TRANSLATE.wd_list_payout.b}</label>
                                    <input type="text" className="pt-0" placeholder="Ex. LLMSJDS28KLLMLLSLS" onChange={(e) => setSocialSecurity(e.target.value)} value={SOCIAL_SECURITY} />
                                </div>
                                <div className="input-group">
                                    <label>IBAN</label>
                                    <input type="text" className="pt-0" placeholder="Ex. IT222244444777788884444" onChange={(e) => setBankIban(e.target.value)} value={BANK_IBAN} />
                                </div>

                            </>

                        ) : (

                            <>

                                <div className="input-group half-width">
                                    <label>{TRANSLATE.wd_list_payout.a}</label>
                                    <input type="text" className="pt-0" placeholder="Unicredit bank" onChange={(e) => setBankName(e.target.value)} value={BANK_NAME} />
                                </div>
                                <div className="input-group half-width">
                                    <label>{TRANSLATE.wd_list_payout.b}</label>
                                    <input type="text" className="pt-0" placeholder="Ex. LLMSJDS28KLLMLLSLS" onChange={(e) => setSocialSecurity(e.target.value)} value={SOCIAL_SECURITY} />
                                </div>
                                <div className="input-group half-width">
                                    <label>{TRANSLATE.wd_list_payout.f}</label>
                                    <input type="text" className="pt-0" placeholder="Ex. 0123456789" onChange={(e) => setBankAccount(e.target.value)} value={BANK_ACCOUNT} />
                                </div>
                                <div className="input-group half-width">
                                    <label>{TRANSLATE.wd_list_payout.g}</label>
                                    <input type="text" className="pt-0" placeholder="Ex. 0123456789" onChange={(e) => setBankRouting(e.target.value)} value={BANK_ROUTING} />
                                </div>



                            </>

                        )}

                        {/* <div className="flex w-full mb-4 items-center justify-between mt-4">
                            <div className="cursor-pointer" onClick={()=> history.goBack()}>
                                {TRANSLATE.btn_back}
                            </div>
                            {CAN_SAVE ? (
                                <button className="btn-provider" onClick={()=> onNextStep()}>
                                    {TRANSLATE.btn_save_changes}
                                </button>
                            ) : (
                                <button className="btn-provider cursor-not-allowed">
                                    {TRANSLATE.btn_save_changes}
                                </button>
                            )}
                        </div> */}
                    </div>
                </div>

            </div>

        </>
    );

}

const WalletAnalytics = (props) => {

    const { isLoading, ANALYTICS, TYPE, location, CURRENCY, onUpdateType, setBank, setSearchDates } = props;


    const CurrentYear = new Date().getFullYear();
    const CurrentMonth = new Date().getMonth();


    //Setup Dates
    const CalendarArray = getAllDaysInMonth(CurrentYear, CurrentMonth) || [];
    let FirstDay = moment(new Date(CalendarArray[0]));
    let LastDay = moment(new Date(CalendarArray[CalendarArray.length - 1]));

    const CurrentParams = GetRouteSearchParams(location.search) || {};
    const CurrentStart = CurrentParams.start;
    const CurrentEnd = CurrentParams.end;

    if (CurrentStart) {
        FirstDay = moment(CurrentStart, 'DD-MM-YYYY');
    }

    if (CurrentEnd) {
        LastDay = moment(CurrentEnd, 'DD-MM-YYYY');
    }

    const GreenStats = ANALYTICS.green_stats || {};
    const TotalShown = GreenStats.amount_provider || 0;

    return (
        <>
            {/* <div style={{whiteSpace: 'break-spaces'}}>
                {JSON.stringify(ANALYTICS, null, 2)}
            </div> */}

            <div className="flex gap-x-8 md:flex-nowrap flex-wrap mt-8 mb-4">
                <div className="w-full">

                    <div className="font-semibold w-full flex space-between items-center">
                        <div>
                            Provider Earnings
                        </div>
                        <div className="text-bagreen-100 font-semibold ml-auto cursor-pointer" onClick={() => setSearchDates(true)}>
                            {FirstDay.format('DD/MM/YY')} - {LastDay.format('DD/MM/YY')}
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="bg-white border border-zinc-200 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                            <div className='w-full flex items-center justify-center'>
                                <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                            </div>
                        </div>
                    ) : (
                        <div className="bg-bagreen-100 rounded-lg text-white flex p-4 min-h-[150px] justify-between mt-2 mb-4">
                            <h1 className="my-auto font-semibold pl-6">
                                {numberWithCommas(TotalShown, CURRENCY)}
                            </h1>
                            <div className="flex flex-col items-end justify-between text-xs">
                                <select style={{ textTransform: 'uppercase' }} value={TYPE} onChange={(e) => onUpdateType(e.target.value)} className="bg-transparent text-xs accent-white bg-blend-color-dodge p-0 pr-8 border-none text-white focus:outline-none focus:ring-0">
                                    <option value="all">
                                        All Orders
                                    </option>
                                    <option value="payout">
                                        Payout
                                    </option>
                                    <option value="green">
                                        Green
                                    </option>
                                    <option value="red">
                                        Red
                                    </option>
                                    <option value="yellow">
                                        Yellow
                                    </option>

                                </select>
                                <div className="flex items-center w-full uppercase cursor-pointer" onClick={() => setBank(true)}>
                                    Bank Information <SettingsSharp color={'#fff'} style={{ marginLeft: 5 }} />
                                </div>
                            </div>
                        </div>
                    )}

                </div>


            </div>

        </>
    )
}

const WalletTransactions = (props) => {

    const { USER, TRANSLATE, history, isLoading, onClose, RANGE, ORDERS, TOTAL, onUpdateDate } = props;

    const onSelectOrder = (order_id) => {
        onClose();
        setTimeout(() => {
            history.push('/admin/orders/profile/' + order_id)
        }, 300)
    }

    const onSelectAll = (store_id) => {
        onClose();
        setTimeout(() => {
            history.push('/admin/orders/0?sort=updatedAt&dir=desc&store=' + store_id)
        }, 300)
    }


    return (
        <div className="w-full mt-8">
            <div className="font-semibold w-full flex space-between items-center justify-between">
                <div>
                    Orders Found ({ORDERS.length})
                </div>

                <select style={{ width: 'auto' }} value={RANGE} onChange={(e) => onUpdateDate(e.target.value)} className="bg-transparent text-bagreen-100 font-semibold accent-white bg-blend-color-dodge p-0 pr-8 border-none focus:outline-none focus:ring-0">
                    <option value="all">
                        All Times
                    </option>
                    <option value="today">
                        Today
                    </option>
                    <option value="yesterday">
                        Yesterday
                    </option>
                    <option value="last_7">
                        Last 7 days
                    </option>
                    <option value="last_14">
                        Last 14 days
                    </option>
                    <option value="last_30">
                        Last 30 days
                    </option>
                    <option value="last_60">
                        Last 60 days
                    </option>
                    <option value="last_90">
                        Last 90 days
                    </option>
                    <option value="last_180">
                        Last 180 days
                    </option>
                    <option value="last_180">
                        Last 180 days
                    </option>
                    <option value="last_180">
                        Last 180 days
                    </option>

                    <option value="current_month">
                        Current Month
                    </option>

                    <option value="custom_range">
                        Custom Range
                    </option>
                </select>

                {/* <div className="text-bagreen-100 font-normal ml-auto cursor-pointer" onClick={()=> onSelectAll(STORE_ID)}>
                    {TRANSLATE.btn_view_all}
                </div> */}
            </div>
            <div className="w-full border border-zinc-200 rounded-lg mt-2">
                {isLoading ? (
                    <div className='w-full h-[200px] flex items-center justify-center'>
                        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                    </div>
                ) : (
                    <>

                        {ORDERS.map((item, index) => {

                            const order_id = item.id;
                            const order_total = item.total;
                            const order_provider = item.p_total;
                            const order_fee_service = item.f_cost;
                            const order_fee_provider = item.f_p_cost;
                            const order_fees = order_fee_service + order_fee_provider;


                            const order_status = item.status;
                            const order_symbol = item.symbol || '€';

                            const order_checkin = moment(item.checkin).format('DD MMMM YYYY');
                            const order_checkout = moment(item.checkout).format('DD MMMM YYYY');
                            const order_checkin_ex = item.checkin_ex;
                            const order_checkout_ex = item.checkout_ex;
                            const order_duration_ex = item.checkdur_ex;

                            let orderPeriod = '';
                            let orderDuration = 0;
                            let orderExact = false;
                            let orderStart = '';
                            let orderEnd = '';

                            if (order_checkin_ex && order_checkout_ex) {

                                orderPeriod = order_checkin_ex + ' - ' + order_checkout_ex;
                                orderStart = order_checkin_ex;
                                orderEnd = order_checkout_ex;
                                orderDuration = order_duration_ex;
                                orderExact = true;
                            }
                            else {

                                const startMoment = moment(order_checkin);
                                const endMoment = moment(order_checkout);

                                orderPeriod = startMoment.format('DD/MM/YYYY') + ' - ' + endMoment.format('DD/MM/YYYY');
                                orderStart = startMoment.format('DD/MM/YYYY');
                                orderEnd = endMoment.format('DD/MM/YYYY');
                                orderDuration = moment(endMoment).diff(startMoment, 'days') + 1;
                            }



                            return (
                                <>
                                    <div className="flex flex-column w-full p-4 cursor-pointer" onClick={() => onSelectOrder(order_id)} key={index}>
                                        <div className="flex w-full p-4 cursor-pointer">
                                            <img className="mr-2" src="/assets/img/package.svg" />
                                            <span className="font-semibold mr-2">#{order_id}</span>
                                            {order_status === 'accepted' && (
                                                <span className="font-semibold ml-auto" style={{ background: '#9AD6AB', color: '#fff', borderRadius: 10, padding: 5, fontSize: 12 }}>{' '}{order_status}{' '}</span>
                                            )}
                                            {order_status === 'rejected' && (
                                                <span className="font-semibold ml-auto" style={{ background: '#ec1529', color: '#fff', borderRadius: 10, padding: 5, fontSize: 12 }}>{' '}{order_status}{' '}</span>
                                            )}
                                            {order_status === 'pending' && (
                                                <span className="font-semibold ml-auto" style={{ background: '#FCE205', color: '#fff', borderRadius: 10, padding: 5, fontSize: 12 }}>{' '}{order_status}{' '}</span>
                                            )}
                                            {order_status === 'delivering' && (
                                                <span className="font-semibold ml-auto" style={{ background: '#88D1F1', color: '#fff', borderRadius: 10, padding: 5, fontSize: 12 }}>{' '}{order_status}{' '}</span>
                                            )}
                                            {order_status === 'delivered' && (
                                                <span className="font-semibold ml-auto" style={{ background: '#ff8b3d', color: '#fff', borderRadius: 10, padding: 5, fontSize: 12 }}>{' '}{order_status}{' '}</span>
                                            )}
                                            {order_status === 'complete' && (
                                                <span className="font-semibold ml-auto" style={{ background: 'green', color: '#fff', borderRadius: 10, padding: 5, fontSize: 12 }}>{' '}{order_status}{' '}</span>
                                            )}
                                            {order_status === 'canceled' && (
                                                <span className="font-semibold ml-auto" style={{ background: '#ec1529', color: '#fff', borderRadius: 10, padding: 5, fontSize: 12 }}>{' '}{order_status}{' '}</span>
                                            )}

                                        </div>
                                        <div className="flex w-full py-2 px-4 cursor-pointer items-center">
                                            <CalendarOutline color={'#000'} style={{marginRight: 10}}/>
                                            <span className="text-sm mr-2">{orderPeriod}</span>
                                            
                                            <div className="font-semibold ml-auto text-bagreen-100">
                                            {(order_provider ? order_symbol + order_provider.toFixed(2) : order_symbol + '0.00')}
                                            </div>
                                        </div>
                                        <div className="flex w-full py-2 px-4 cursor-pointer">
                                            <CalendarOutline color={'#000'} style={{marginRight: 10}}/>
                                            <span className="text-sm mr-2">{orderDuration == 1 ? orderDuration+' day' : orderDuration+' days'}</span>
                                            
                                            <div className="font-semibold ml-auto text-babonbo-100">
                                                {(order_fees ? order_symbol + order_fees.toFixed(2) : order_symbol + '0.00')}
                                            </div>
                                        </div>
                                        <div className="flex w-full py-2 px-4 cursor-pointer">
                                            <div className="font-semibold ml-auto">
                                                {(order_total ? order_symbol + order_total.toFixed(2) : order_symbol + '0.00')}
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ margin: '5px 0px' }} />
                                </>
                            )
                        })}

                    </>
                )}

            </div>
        </div>

    )
}

const getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}



export default StorePayout