import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import swal from 'sweetalert';


import { GetRouteSearchParams } from './AdminHelper';

import { Add, FilterList } from '@mui/icons-material';

import PartnerFilter from '../Modals/PartnerFilter';

const AdminPartners = (props) => {

    const { TRANSLATE, history, match, location } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [PARTNERS, setPartners] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const [isFilter, setFilter] = useState(false);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var filter_country = routeParams.country || null;
            var filter_status = routeParams.status || null;
            var filter_store = routeParams.store || null;

            var current_page = parseInt(page_id);

            onFetchPartners({
                page: current_page,
                sort: sort_id,
                direction: direction_id,
                filter_country,
                filter_status,
                filter_store
            });
        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchPartners = async ({ page, sort, direction, filter_status, filter_store, filter_country }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                status: filter_status,
                store: filter_store,
                country: filter_country,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/partners', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setPartners(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchPartners = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchPartners({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/partners/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setPartners(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setPartners([]);
            setLoading(false);
        }


    }

    const onSortPartners = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'isPublished') {
            return 'isBusy'
        }
        else if (sort === 'isDeleted') {
            return 'deleted'
        }
        else {
            return sort
        }
    }

    const onChangePagePartners = ({ page, sort, direction, filter_status, filter_store, filter_country }) => {

        let new_url = '/admin/partners/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }
        else {
            new_url = new_url + '?sort=updatedAt&dir=desc';
        }

        //Check filters
        if (filter_country) {
            new_url = new_url + '&country=' + filter_country;
        }

        if (filter_status) {
            new_url = new_url + '&status=' + filter_status;
        }

        if (filter_store) {
            new_url = new_url + '&store=' + filter_store;
        }

        history.push(new_url);

    }

    const onSelectPartner = (partner_id) => {
        history.push('/partners/' + partner_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/admin/stores/profile/' + store_id);
    }

    const onSelectLocation = (location_id) => {
        history.push('/admin/locations/profile/' + location_id);
    }

    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }

    //Logic
    var routeParams = GetRouteSearchParams(location.search);

    var currentSort = routeParams.sort || null;
    var currentDirection = routeParams.dir || null;

    var currentFilterCountry = routeParams.country || null;
    var currentFilterStatus = routeParams.status || null;
    var currentFilterStore = routeParams.store || null;

    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectPartner(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "name",
            label: "PARTNER",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const partnerObx = PARTNERS[rowIndex] || {};

                    const partner_id = partnerObx.id;
                    const partner_name = partnerObx.name || '';
                    const partner_img = partnerObx.icon || '';

                    return (
                        <div>
                            <Chip
                                avatar={<Avatar alt={partner_name} src={partner_img} />}
                                label={partner_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectPartner(partner_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {

                    if(value === 'provider'){
                        return (
                            <div>
                                <Chip
                                    label={value}
                                    size='small'
                                    color='secondary'
                                    style={{ textTransform: 'capitalize', backgroundColor: '#26BFBF', color: '#fff' }}
                                />
                            </div>
    
                        )
                    }
                    else if(value === 'customer'){
                        return (
                            <div>
                               <Chip
                                    label={value}
                                    size='small'
                                    color='primary'
                                    style={{ textTransform: 'capitalize', backgroundColor: '#fd6270', color: '#fff' }}
                                />
                            </div>
    
                        )
                    }
                    else{
                        return (
                            <div>
                               <Chip
                                    label={value}
                                    variant="outlined"
                                    size='small'
                                    color='info'
                                    style={{ textTransform: 'capitalize' }}
                                />
                            </div>
    
                        )
                    }

                    
                }
            },
        },
        {
            name: "logo",
            label: "Logo",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (logo) => {

                    return (
                        <div className='justify-center items-center flex'>
                            <img src={logo} className='h-[80px] object-contain' />
                        </div>
                    )
                }
            },
        },
        {
            name: "stores",
            label: "Stores",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (stores) => {

                    return (
                        <div className='justify-center items-center flex'>
                            <Chip label={stores.length} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "customers",
            label: "Customers",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (customers) => {

                    return (
                        <div className='justify-center items-center flex'>
                            <Chip label={customers.length} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const partnerObx = PARTNERS[rowIndex] || {};

                    const partner_id = partnerObx.id;
                    const partner_linkname = partnerObx.linkname || '';

                    return (
                        <div className='flex gap-x-4'>
                            <div className='flex items-center justify-center border-1 rounded-full underline cursor-pointer font-bold' onClick={()=> history.push(`/admin/partners/profile/${partner_id}`)}>
                                Edit
                            </div>
                            <div className='flex items-center justify-center border-1 rounded-full underline cursor-pointer font-bold' onClick={()=> onSelectPartner(partner_id)}>
                                Manage
                            </div>
                            <div className='flex items-center justify-center border-1 rounded-full underline cursor-pointer font-bold' onClick={()=> window.open(`https://www.babonbo.com/en/partners/${partner_linkname}`)}>
                                Public
                            </div>
                        </div>
                    )
                }
            },
        },
        {
            name: "updatedAt",
            label: "LAST UPDATED",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div className='justify-center items-center flex'>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "PARTNER CREATED",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div className='justify-center items-center flex'>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
       
    ];

    const data = PARTNERS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_partner = {
            id: item.id,
            name: item.name,
            logo: item.logo,
            icon: item.icon,
            type: item.type,
            stores: item.stores || [],
            customers: item.customers || [],
            updatedAt: moment(item.updatedAt).fromNow(),
            createdAt: moment(item.createdAt).format('DD MMMM YYYY'),
        };


        return new_partner
    })

    const customToolbar = () => (
        <>
            <Tooltip disableFocusListener title="Add Partner">
                <IconButton onClick={() => history.push('/admin/partners/profile/new')}>
                    <Add />
                </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Filter Partners">
                <IconButton onClick={() => setFilter(true)}>
                    <FilterList />
                </IconButton>
            </Tooltip>
        </>
    );

    const options = {
        filter: false,
        print: false,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,

        filterType: 'dropdown',
        responsive: 'standard',
        //customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePagePartners({
                        page: tableState.page,
                        sort: onSortPartners(sortFilter),
                        direction: sortDirection,
                        filter_country: currentFilterCountry,
                        filter_status: currentFilterStatus,
                        filter_store: currentFilterStore
                    });

                    break;
                case 'sort':

                    onChangePagePartners({
                        page: tableState.page,
                        sort: onSortPartners(sortFilter),
                        direction: sortDirection,
                        filter_country: currentFilterCountry,
                        filter_status: currentFilterStatus,
                        filter_store: currentFilterStore
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchPartners(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchPartners(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Partner By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Partners with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        onFilterChange: (column, filterList, type) => {


            //Check if Current Filter is Active
            if (column === 'country') {
                const filterValue = filterList[6];
                currentFilterCountry = filterValue;
            }
            else if (column === 'status') {
                const filterValue = filterList[2];
                currentFilterStatus = filterValue;
            }

            onChangePagePartners({
                page: 0,
                sort: onSortPartners(currentSort),
                direction: currentDirection,
                filter_status: currentFilterStatus,
                filter_country: currentFilterCountry,
                filter_store: currentFilterStore
            });

        }

    };


    return (
        <>
            <MUIDataTable
                title={
                    <div className='font-light md:text-5xl text-xl text-lg py-8'>

                        {'Partners Found → '}

                        {isLoading ? (

                            <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                        ) : (

                            <>
                                {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                            </>

                        )}

                    </div>
                }
                data={data}
                columns={columns}
                options={options}
            />

            {/* <pre>
                {JSON.stringify(PARTNERS, null, 2)}
            </pre> */}
            {isFilter && (
                <PartnerFilter {...props}
                    onClose={() => setFilter(false)}

                />
            )}

        </>
    );

}


export default AdminPartners

