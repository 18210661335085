import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import { GetRouteSearchParams } from './AdminHelper';

import swal from 'sweetalert';
import { EyeSharp } from 'react-ionicons';


const AdminInbox = (props) => {

    const { TRANSLATE, history, match, location } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [CHATS, setChats] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var current_page = parseInt(page_id);

            onFetchChats({
                page: current_page,
                sort: sort_id,
                direction: direction_id
            });

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchChats = async ({ page, sort, direction }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT,
                partner: match.params.id
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/messages', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setChats(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchChats = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchChats({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/messages/search', { query: query, partner: match.params.id }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setChats(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setChats([]);
            setLoading(false);
        }


    }

    const onSortChats = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'search_name'
        }
        else if (sort === 'img') {
            return 'photo'
        }
        else if (sort === 'lang') {
            return 'language'
        }
        else if (sort === 'isCompleted') {
            return 'setup'
        }
        else {
            return sort
        }
    }

    const onChangePageChats = ({ page, sort, direction }) => {
        let new_url = '/partners/' + match.params.id + '/inbox/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        history.push(new_url);
    }

    const onSelectChat = (chat_id) => {
        history.push('/partners/' + match.params.id + '/inbox/profile/' + chat_id);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/partners/' + match.params.id + '/customers/profile/' + customer_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/partners/' + match.params.id + '/stores/profile/' + store_id);
    }


    //Table Data
    const columns = [
        // {
        //     name: "id",
        //     label: "ID",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (id) => {

        //             return (
        //                 <Typography variant='span' fontWeight={'bold'} onClick={()=> onSelectChat(id)} style={{cursor: 'pointer'}}>
        //                     #{id}
        //                 </Typography>
        //             )
        //         }
        //     },
        // },
        {
            name: "id",
            label: "#",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <div onClick={() => onSelectChat(id)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#000', width: 40, height: 40, borderRadius: 50, marginLeft: 10, cursor: 'pointer' }}>
                            <EyeSharp color={'#fff'} />
                        </div>

                    )
                }
            },
        },
        {
            name: "store",
            label: "STORE",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const chatObx = CHATS[rowIndex] || {};

                    const storeObx = chatObx.s || {};

                    const store_id = storeObx.id || '';
                    const store_name = storeObx.name || '';
                    const store_img = storeObx.img || '';


                    if (store_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={store_name} src={store_img} />}
                                    label={store_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => onSelectStore(store_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: "customer",
            label: "CUSTOMER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const chatObx = CHATS[rowIndex] || {};

                    const customerObx = chatObx.c || {};
                    const customer_id = customerObx.id;
                    const customer_name = customerObx.name || '';
                    const customer_img = customerObx.img || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                avatar={<Avatar alt={customer_name} src={customer_img} />}
                                label={customer_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectCustomer(customer_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "message",
            label: "LAST MESSAGE",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "300px", maxWidth: "300px" } }),
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const chatObx = CHATS[rowIndex] || {};

                    const chat_id = chatObx.id || '';

                    const customerObx = chatObx.c || {};
                    const storeObx = chatObx.s || {};
                    const messageObx = chatObx.m || {};

                    const customer_id = customerObx.id || '';
                    const customer_img = customerObx.img || '';

                    const store_id = storeObx.id || '';
                    const store_img = storeObx.img || '';

                    const message_text = messageObx.text || '';
                    const message_from = messageObx.from || '';

                    if (message_from === customer_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar src={customer_img} />}
                                    label={message_text}
                                    variant="outlined"
                                    size='medium'
                                    onClick={() => onSelectChat(chat_id)}
                                />
                            </div>

                        )
                    }
                    else if (message_from === store_id) {

                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar src={store_img} />}
                                    label={message_text}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => onSelectChat(chat_id)}
                                />
                            </div>

                        )
                    }


                    return (
                        <div>
                            <Chip size='small' label="NO MESSAGE YET" />
                        </div>
                    )

                }
            },

        },
        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = CHATS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_chat = {
            id: item.id,
            customer: item.c,
            store: item.s,
            message: item.m,
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY')
        };

        return new_chat
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageChats({
                        page: tableState.page,
                        sort: onSortChats(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageChats({
                        page: tableState.page,
                        sort: onSortChats(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchChats(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchChats(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Chat By Store Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Chats with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        elevation: 0,
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true

    };




    return (

        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Chats →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}


export default AdminInbox

