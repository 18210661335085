import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-modern-calendar-datepicker';
import Header from '../Header';
import ProductCard from '../ListingGrid/ProductCard/ProductCard';
import SearchLocation from '../SearchLocation/SearchLocation';

import {
  CheckBoxOutlineBlank,
  CheckCircleOutline,
  LoopOutlined,
  PushPinTwoTone,
  ReceiptLongOutlined,
  Warning,
} from '@mui/icons-material';
import {
  AddCircleOutline,
  ArrowForwardCircleOutline,
  CheckboxOutline,
  Checkmark,
  CheckmarkCircleOutline,
  CloseCircleOutline,
  Link,
  LocationSharp,
  TrashOutline,
  WarningSharp,
} from 'react-ionicons';

import '../Listing/listing.css';
import '../new_ux.css';
import './checkout.css';

const Checkout = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [date, setDate] = useState({
    from: null,
    to: null,
  });

  const cart = {
    duration: '10-16 January - Total: 5 Days',
    cost_delivery: 0,
    cost_pickup: 0,
    cost_lastminute: 10,
    cost_free: 10,
    cost_insurance: 20,
    cost_total: 60.55,

    currency: 'eur',

    products: [
      {
        img: '/assets/img/hero-provider.jpg',
        name: 'Chicco Polly Highchair',
        price_day: 10.55,
        currency: 'eur',
        amount: 5,
      },
    ],
  };

  const [USER, setUSER] = useState({
    id: 1,
    email: 'test@babonbo.com',
    countryCode: '+44',
  });

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

  return (
    <div className='layout'>
      <Header {...props} showMenu={false} animatedBackground={false}></Header>

      <main
        className='flex flex-col flex-grow-1 w-full mx-auto justify-center max-w-xl px-4 sm:px-6 lg:px-8 text-xs md:text-base'
        id='checkout-process'
        role='main'
        aria-labelledby='checkout-heading'
      >
        {!isPaymentSuccess && (
          <div className='stepper md:gap-2'>
            <div className='flex flex-col md:flex-row items-center justify-center gap-2'>
              <div className={`step ${currentStep >= 1 ? 'checked' : ''}`} onClick={() => setCurrentStep(1)}>
                <div className={`step-num`}>{currentStep > 1 ? <Checkmark color={'#fff'} /> : 1}</div>
              </div>
              <div>Rental Dates</div>
            </div>
            <div className='mb-4 md:mb-0' style={{ flex: '1 1 0', height: 1, background: '#BDBDBD' }} />
            <div className='flex flex-col md:flex-row items-center justify-center gap-2'>
              <div className={`step ${currentStep >= 2 ? 'checked' : ''}`} onClick={() => setCurrentStep(2.1)}>
                <div className={`step-num`}>{currentStep === 3 ? <Checkmark color={'#fff'} /> : 2}</div>
              </div>
              <div>Delivery & Return</div>
            </div>
            <div className='mb-4 md:mb-0' style={{ flex: '1 1 0', height: 1, background: '#BDBDBD' }} />
            <div className='flex flex-col md:flex-row items-center justify-center gap-2'>
              <div className={`step ${currentStep === 3 ? 'checked' : ''}`} onClick={() => setCurrentStep(3)}>
                <div className={`step-num`}>3</div>
              </div>
              <div>Checkout</div>
            </div>
          </div>
        )}

        {currentStep === 1 && <Step1 {...props} setCurrentStep={setCurrentStep} />}
        {currentStep === 2.1 && <Step2_1 {...props} setCurrentStep={setCurrentStep} />}
        {currentStep === 2.2 && <Step2_2 {...props} setCurrentStep={setCurrentStep} />}
        {currentStep === 2.3 && <Step2_3 {...props} setCurrentStep={setCurrentStep} />}

        {/*TODO Step 3 is only if user not logged in */}
        {currentStep === 3 &&
          (USER.id ? <Cart cart={cart} user={USER} /> : <Step3 {...props} setCurrentStep={setCurrentStep} />)}

        {isPaymentSuccess && <></>}
      </main>
    </div>
  );
};

export default Checkout;

const Step1 = (props) => {
  const { setCurrentStep } = props;
  return (
    <div className='flex flex-col items-center justify-center m-auto md:my-8 ' aria-label='Step 1: Rental Dates'>
      <Calendar aria-label='Rental Dates Calendar' />
      <button type='button' className='rent-button mt-8' onClick={() => setCurrentStep(2.1)}>
        <div className='rent-text' onClick={() => setCurrentStep(2.1)}>
          Save and Continue
        </div>
        <ArrowForwardCircleOutline width='24px' color='#fff' aria-hidden='true' />
      </button>
    </div>
  );
};

const Step2_1 = (props) => {
  const { setCurrentStep } = props;

  const [activeTab, setActiveTab] = useState('delivery');

  const [isDeliverAddress, setDeliverAddress] = useState(false);
  const [pickupLocation, setPickupLocation] = useState(null);
  const [dropoffLocation, setDropoffLocation] = useState(null);

  const CAN_DELIVER = true;

  return (
    <div className='flex flex-col items-center justify-center my-4' aria-labelledby='delivery-options'>
      <h3 id='delivery-options' className='mb-4 text-center text-base md:text-lg'>
        Choose Between Delivery or Free Self-Pickup
      </h3>
      <div className='flex w-full border-light-gray rounded-lg overflow-hidden' aria-label='Delivery or Pickup Options'>
        <div
          className={`w-full py-2 text-center ${activeTab === 'delivery' ? 'bg-ba text-white ' : 'color-gray'}`}
          onClick={() => setActiveTab('delivery')}
          role='button'
          aria-pressed={activeTab === 'delivery'}
          aria-label='Choose Delivery Option'
        >
          Delivery
        </div>

        {/* TODO Conditional, appears only if provider has free pickup service */}
        <div
          className={`w-full py-2  text-center ${activeTab !== 'delivery' ? 'bg-ba text-white ' : ' color-gray'}`}
          onClick={() => setActiveTab('freePickup')}
          role='button'
          aria-pressed={activeTab !== 'delivery'}
          aria-label='Choose Self-Pickup Option'
        >
          Self-Pickup Location
        </div>
      </div>

      <h3 className='mt-4 w-full text-lg font-semibold text-left'>
        {activeTab === 'delivery' ? 'Delivery Location' : 'Pickup Location'}
      </h3>

      {activeTab === 'delivery' && <SelectLocation location={pickupLocation} setLocation={setPickupLocation} />}

      {activeTab === 'freePickup' && (
        <div className='w-full' aria-labelledby='pickup-location'>
          <div className='flex align-items-center justify-content-center my-2 p-2 w-full gap-2 text-red border-red'>
            You need to go to provider's place <WarningSharp color={'#FF0000'} aria-hidden='true' />
          </div>

          <div className='flex w-full gap-2'>
            <p className='flex align-items-center w-4 h-4 aria-hidden="true" '>
              <PushPinTwoTone />
            </p>

            <p className='flex my-4 gap-2 align-items-center'>Oxford Street, Kenginston, London</p>
          </div>

          <img
            src='/assets/img/map.png'
            id='pickup-location'
            alt='Map showing pickup location'
            className='my-4 w-full h-24'
          />

          <a href='#' className='flex aling-items-center justify-content-center gap-2 underline'>
            View Prodiver’s Pickup Street on Google map{' '}
            <LocationSharp width='24px' height='24px' color='#fc6170' aria-hidden='true' />
          </a>
        </div>
      )}

      {!CAN_DELIVER && <AlternativeProviders />}

      <button
        type='button'
        className={`flex justify-content-center mt-8 px-3 py-2 w-full rounded gap-3 ${
          dropoffLocation ? 'bg-ba text-white' : 'bg-light-gray color-gray'
        }`}
        onClick={() => setCurrentStep(2.2)}
        aria-label='Continue to Step 2.2'
        // disabled={!dropoffLocation}
      >
        <div>Continue</div>
        <ArrowForwardCircleOutline width={'24px'} color={dropoffLocation ? '#fff' : '#8c8c8c'} aria-hidden='true' />
      </button>
    </div>
  );
};

const Step2_2 = (props) => {
  const { setCurrentStep } = props;

  const [activeTab, setActiveTab] = useState('returnAddress');
  const [returnLocation, setReturnLocation] = useState(null);

  const [isDifferentAddress, setDifferentAddress] = useState(false);

  return (
    <div className='flex flex-col items-center justify-center my-4' aria-labelledby='return-options'>
      <h3 className='mt-4 w-full text-lg font-semibold text-left' id='return-options'>
        {activeTab === 'returnAddress' ? 'Return Location' : 'Select Different Location'}
      </h3>

      <h4 className='text-left m-0'>15 July 2024 Return Date</h4>

      <div className='w-full' aria-labelledby='return-location-info'>
        {!isDifferentAddress && (
          <>
            <div className='flex align-items-center justify-content-center my-2 p-2 w-full gap-2 text-red border-red'>
              You need to go pickup location <WarningSharp color={'#FF0000'} aria-hidden='true' />
            </div>
            <div className='flex w-full gap-2'>
              <p className='flex align-items-center w-4 h-4 '>
                <PushPinTwoTone aria-hidden='true' />
              </p>

              <p className='flex my-4 gap-2 align-items-center'>Oxford Street, Kenginston, London</p>
            </div>

            <a href='#' className='flex aling-items-center justify-content-center gap-2 underline'>
              View Prodiver’s Pickup Street on Google map{' '}
              <LocationSharp width='24px' height='24px' color='#fc6170' aria-hidden='true' />
            </a>
          </>
        )}

        <CheckboxReturnDifferentAddress
          isDifferentAddress={isDifferentAddress}
          setDifferentAddress={setDifferentAddress}
        />

        {isDifferentAddress && (
          <>
            <div
              className='flex mt-4 w-full border-light-gray rounded-lg overflow-hidden'
              aria-labelledby='return-address-options'
            >
              <div
                className={`w-full py-2 text-center ${
                  activeTab === 'returnAddress' ? 'bg-ba text-white ' : 'color-gray'
                }`}
                onClick={() => setActiveTab('returnAddress')}
                role='button'
                aria-pressed={activeTab === 'returnAddress'}
                aria-label='Return to Specified Address'
              >
                Return Address
              </div>
              {/* Conditional, appears only if user has free return service */}
              <div
                className={`w-full py-2  text-center ${
                  activeTab !== 'returnAddress' ? 'bg-ba text-white ' : ' color-gray'
                }`}
                onClick={() => setActiveTab('freePickup')}
                role='button'
                aria-pressed={activeTab === 'freePickup'}
                aria-label='Free Return to Pickup Location'
              >
                Free Return
              </div>
            </div>
            {activeTab !== 'freePickup' && (
              <textarea
                value={returnLocation}
                onChange={(e) => setReturnLocation(e.target.value)}
                type='text'
                placeholder='Full address'
                className='w-full text-sm rounded-lg border-gray'
                aria-label='Return Address'
              />
            )}

            {activeTab === 'freePickup' && (
              <>
                <div className='flex align-items-center justify-content-center my-2 p-2 w-full gap-2 text-red border-red'>
                  You need to go pickup location <WarningSharp color={'#FF0000'} />
                </div>
                <div className='flex w-full gap-2'>
                  <p className='flex align-items-center w-4 h-4 '>
                    <PushPinTwoTone aria-hidden='true' />
                  </p>

                  <p className='flex my-4 gap-2 align-items-center' aria-labelledby='free-pickup-info'>
                    Oxford Street, Kenginston, London
                  </p>
                </div>
                <img
                  src='/assets/img/map.png'
                  id='free-pickup-info'
                  alt='Map showing pickup location'
                  className='my-4 w-full h-24'
                />
                <a href='#' className='flex aling-items-center justify-content-center gap-2 underline'>
                  View Prodiver’s Pickup Street on Google map{' '}
                  <LocationSharp width='24px' height='24px' color='#fc6170' aria-hidden='true' />
                </a>
              </>
            )}
          </>
        )}
      </div>

      <button
        type='button'
        className={`flex justify-content-center mt-8 px-3 py-2 w-full rounded gap-3 ${
          returnLocation ? 'bg-ba text-white' : 'bg-light-gray color-gray'
        }`}
        onClick={() => setCurrentStep(2.3)}
        aria-label='Continue to Step 2.3'
        // disabled={!returnLocation}
      >
        <div>Continue</div>
        <ArrowForwardCircleOutline width={'24px'} color={returnLocation ? '#fff' : '#8c8c8c'} aria-hidden='true' />
      </button>
    </div>
  );
};

const Step2_3 = (props) => {
  const { setCurrentStep } = props;

  const [activeTab, setActiveTab] = useState('returnAddress');
  const [returnLocation, setReturnLocation] = useState(null);

  return (
    <div className='flex flex-col items-center justify-center my-4' aria-labelledby='location-summary'>
      <div className='flex flex-col w-full' aria-labelledby='delivery-location'>
        <div className='flex align-items-center justify-content-between'>
          <h3 className='text-lg'>Delivery Location</h3>
          <button
            type='button'
            className='px-4 py-2 bg-light-gray rounded-lg font-bold'
            onClick={() => {}}
            aria-label='Change Delivery Location'
          >
            CHANGE
          </button>
        </div>
        <div className='flex w-full gap-2 rounded-full'>
          <p className='flex align-items-center w-4 h-4 '>
            <PushPinTwoTone aria-hidden='true' />
          </p>

          <p className='flex my-4 gap-2 align-items-center' id='delivery-address'>
            Oxford Street, Kenginston, London
          </p>
        </div>
      </div>

      <div className='flex flex-col w-full' aria-labelledby='return-location'>
        <div className='flex align-items-center justify-content-between'>
          <h3 className='text-lg' id='return-location'>
            Return Location
          </h3>
          <button
            type='button'
            className='px-4 py-2 bg-light-gray rounded-lg font-bold'
            onClick={() => {}}
            aria-label='Change Return Location'
          >
            CHANGE
          </button>
        </div>
        <div className='flex w-full gap-2' aria-labelledby='return-address'>
          <p className='flex align-items-center w-4 h-4 '>
            <PushPinTwoTone aria-hidden='true' />
          </p>

          <p className='flex my-4 gap-2 align-items-center' id='return-address'>
            Oxford Street, Kenginston, London
          </p>
        </div>
      </div>

      <button
        type='button'
        className={`flex justify-content-center mt-8 px-3 py-2 w-full rounded gap-3 ${
          returnLocation ? 'bg-ba text-white' : 'bg-light-gray color-gray'
        }`}
        onClick={() => setCurrentStep(3)}
        aria-label='Continue to Step 3'
        // disabled={!returnLocation}
      >
        <div>Continue</div>
        <ArrowForwardCircleOutline width={'24px'} color={returnLocation ? '#fff' : '#8c8c8c'} aria-hidden='true' />
      </button>
    </div>
  );
};

const Step3 = (props) => {
  const tabs = ['via Email', 'via Phone Number'];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [countryCode, setCountryCode] = useState('');
  const [phoneNum, setPhoneNum] = useState('');

  const [isUser, setUser] = useState(true);

  const [isActivationCode, setActivationCode] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);

  const [otp, setOtp] = useState(new Array(4).fill(''));

  const [isCart, setCart] = useState(true);

  useEffect(() => {
    let interval = null;
    if (timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft <= 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeLeft]);

  const displayTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}.${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
      {!isUser && (
        <>
          {!isActivationCode && (
            <>
              {isCart && (
                <div className='flex flex-col items-center justify-center my-4'>
                  <h2>SIGN UP</h2>
                  <div className='flex w-full border-light-gray '>
                    {tabs.map((tab) => (
                      <button
                        type='button'
                        className={`w-full p-2 ${activeTab === tab ? 'bg-ba text-white' : 'color-gray'}`}
                        onClick={() => {
                          setActiveTab(tab);
                        }}
                      >
                        {tab}
                      </button>
                    ))}
                  </div>
                  {activeTab === tabs[0] && (
                    <div className='w-full'>
                      <p className='mt-4'>Enter your email.</p>
                      <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                        <label className='inline-label'>Email</label>

                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type='text'
                          placeholder='Email'
                        />
                      </div>
                    </div>
                  )}
                  {activeTab === tabs[1] && (
                    <div className='w-full'>
                      <p className='mt-4'>Enter Your Phone Number.</p>
                      <div className='flex gap-1'>
                        <div className='relative inline-flex flex-column mt-5 mb-3 w-3 '>
                          <label className='inline-label'>Country Code</label>
                          <div className='flex' style={{ maxWidth: '120px' }}>
                            <input
                              value={countryCode}
                              onChange={(e) => setCountryCode(e.target.value)}
                              className='w-3'
                              type='text'
                              placeholder='+44'
                            />
                          </div>
                        </div>
                        <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                          <label className='inline-label'>Phone Number</label>
                          <input
                            value={phoneNum}
                            onChange={(e) => setPhoneNum(e.target.value)}
                            type='text'
                            placeholder=''
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <button
                    type='button'
                    className={`flex align-items-center justify-content-center px-3 py-2 w-full rounded gap-3 text-white bg-ba`}
                    onClick={() => setActivationCode(true)}
                  >
                    <div>Continue</div>
                    <ArrowForwardCircleOutline width='24px' color='#fff' aria-hidden='true' />
                  </button>
                  <p className='my-3'>OR</p>
                  <button
                    type='button'
                    className={`flex justify-content-center px-3 py-2 w-full rounded gap-3 border-ba color-ba `}
                    onClick={() => setUser(true)}
                  >
                    I have Membership - Login
                    <ArrowForwardCircleOutline width={'24px'} color={'#FC6170'} />
                  </button>
                </div>
              )}
            </>
          )}

          {/* Condition; appears if user submit email or phone numer */}
          {isActivationCode && (
            <div className='flex flex-col items-center justify-center my-4'>
              <h2>SIGN UP</h2>

              <div className='mt-4'>{`Enter your ${activeTab === tabs[0] ? 'Email' : 'SMS'} Password`}</div>

              <div className='flex mt-2 mb-4'>
                <button
                  className='mr-1 border-b font-bold'
                  style={{ borderColor: '#000' }}
                  type='button'
                  onClick={() => {}}
                >
                  resend
                </button>
                in {displayTime()} seconds
              </div>

              <OTPInput otp={otp} setOtp={setOtp} />
              <button
                type='button'
                className={`flex justify-content-center mt-4 px-3 py-2 w-full rounded gap-3  ${
                  otp.every((element) => element !== '') ? 'bg-ba text-white' : 'bg-light-gray color-gray'
                }`}
                onClick={() => {
                  console.log('here');
                  setCart(true);
                  setActivationCode(false);
                }}
              >
                <div>Continue</div>
                <ArrowForwardCircleOutline
                  width={'24px'}
                  color={`${otp.every((element) => element !== '') ? '#fff' : ''}`}
                />
              </button>
            </div>
          )}
        </>
      )}

      {isUser && (
        <div className='flex flex-col items-center justify-center my-4'>
          <h2>SIGN IN</h2>
          <div className='flex w-full border-light-gray '>
            {tabs.map((tab) => (
              <button
                type='button'
                className={`w-full p-2 ${activeTab === tab ? 'bg-ba text-white' : 'color-gray'}`}
                onClick={() => {
                  setActiveTab(tab);
                }}
              >
                {tab}
              </button>
            ))}
          </div>
          {activeTab === tabs[0] && (
            <div className='w-full'>
              <p className='mt-4'>Enter your email.</p>
              <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                <label className='inline-label'>Email</label>

                <input value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='Email' />
              </div>

              <p>Password</p>

              <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                <label className='inline-label'>Password</label>

                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type='password'
                  placeholder='***'
                />
              </div>
            </div>
          )}
          {activeTab === tabs[1] && (
            <div className='w-full'>
              <p className='mt-4'>Enter Your Phone Number.</p>
              <div className='flex gap-1'>
                <div className='relative inline-flex flex-column mt-5 mb-3 w-3 '>
                  <label className='inline-label'>Country Code</label>
                  <div className='flex' style={{ maxWidth: '120px' }}>
                    <input
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      className='w-3'
                      type='text'
                      placeholder='+44'
                    />
                  </div>
                </div>
                <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                  <label className='inline-label'>Phone Number</label>
                  <input value={phoneNum} onChange={(e) => setPhoneNum(e.target.value)} type='text' placeholder='' />
                </div>
              </div>

              <p>Password</p>

              <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                <label className='inline-label'>Password</label>

                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type='password'
                  placeholder='***'
                />
              </div>
            </div>
          )}
          <button
            type='button'
            className={`flex align-items-center justify-content-center px-3 py-2 w-full rounded gap-3 text-white bg-ba`}
            onClick={() => {}}
          >
            <div>Continue</div>
            <ArrowForwardCircleOutline width='24px' color='#fff' aria-hidden='true' />
          </button>

          <button type='button' onClick={() => {}} className='mt-2 ml-0 mr-auto'>
            Forgot my Password
          </button>

          <p className='my-3'>OR</p>
          <button
            type='button'
            className={`flex justify-content-center px-3 py-2 w-full rounded gap-3 border-ba color-ba `}
            onClick={() => setUser(false)}
          >
            Signup
            <ArrowForwardCircleOutline width={'24px'} color={'#FC6170'} />
          </button>
        </div>
      )}
    </>
  );
};

const SelectLocation = (props) => {
  const { type, location, setLocation } = props;
  const label = `${type} Location`;
  return (
    <div className='w-full quicksand'>
      <SearchLocation label={type ? label : ''} selectedLocation={location} setSelectedLocation={setLocation} />
      {location && (
        <div className='flex flex-column mx-auto max-w-min align-items-center gap-1 '>
          <div className='flex py-1 gap-1 nunito semibold text-[14px] '>
            <h4 className='border-b' style={{ borderColor: '#000' }}>
              {`View Prodiver’s ${type} Place on map`}
            </h4>
            <LocationSharp color='#FC6170' />
          </div>

          <p className='nunito text-[12px]'>Tube Train Station</p>

          <p className='flex gap-3 px-2 py-1 quicksand text-[12px] color-ba border-ba rounded'>
            You need to go dropoff location <Warning />
          </p>
        </div>
      )}
    </div>
  );
};

const CheckboxReturnDifferentAddress = (props) => {
  const { isDifferentAddress, setDifferentAddress } = props;

  console.log('isDifferentAddress', isDifferentAddress);
  return (
    <button
      type='button'
      onClick={() => setDifferentAddress(!isDifferentAddress)}
      className='flex items-center mt-4 px-2 py-1 w-full gap-2 h-6 rounded-lg transition-all duration-300 ease-in bg-light-gray'
    >
      {!isDifferentAddress && <CheckBoxOutlineBlank />}
      {isDifferentAddress && <CheckboxOutline color={'#fc6170'} />}I want to return different address
    </button>
  );
};

const AlternativeProviders = () => {
  const mockItems = [
    {
      id: 1,
      cost_delivery: 0,
      cost_day: 10,
      currency: 'eur',
      images: ['/assets/img/landing/hero-provider.jpg', '/assets/img/landing/hero-prov.jpg'],
      name: 'Chicco Polly Highchair',
      total_rent: 10,
      description:
        'Elegance can be seen from the details in the Book stroller approved from birth. Reversible seat and lots of driving comfort. With its 51cm width and agility, it passes every test!',
      store: {
        img: '/assets/img/landing/hero-provider.jpg',
        name: "Gina's Milano Babonbo store",
        rate: 4.8,
        reviews: 56,
        description:
          'Pedivan is a sustainable cargo-bike delivery service. We provide also rental service for premium baby equipment. We deliver them to your hotel or apartment emission-free. Please contact us first before booking same day delivery requests. Thanks.',
      },
    },
    {
      id: 2,
      cost_delivery: null,
      cost_day: 10,
      currency: 'eur',
      images: ['/assets/img/landing/hero-provider.jpg', '/assets/img/landing/hero-prov.jpg'],
      name: 'Chicco Polly Highchair',
      total_rent: 10,
      description:
        'Elegance can be seen from the details in the Book stroller approved from birth. Reversible seat and lots of driving comfort. With its 51cm width and agility, it passes every test!',
      store: {
        img: '/assets/img/landing/hero-provider.jpg',
        name: "Gina's Milano Babonbo store",
        rate: 4.8,
        reviews: 56,
        description:
          'Pedivan is a sustainable cargo-bike delivery service. We provide also rental service for premium baby equipment. We deliver them to your hotel or apartment emission-free. Please contact us first before booking same day delivery requests. Thanks.',
      },
    },
  ];

  const products = mockItems;

  const [selectedProvider, setSelectedProvider] = useState(null);

  return (
    <>
      <p className='mt-8 mb-4 quicksand text-lg'>Sorry. This provider can’t deliver this area.</p>
      <div
        className={`flex flex-wrap gap-2 mb-8 justify-center md:justify-between grid list-grid`}
        aria-label='Product Listings'
      >
        {products.map((product) => (
          <ProductCard
            isGrid={true}
            key={`${product.name}-${product.id}`}
            product={{
              id: product.id,
              cost_delivery: 0,
              cost_day: 10,
              currency: 'eur',
              images: [product.first_img, product.second_img, product.third_img, product.fourth_img],
              name: product.name,
              total_rent: 10,
              description: product.about,
              store: {
                img: product.owner?.img || '',
                name: product.owner?.name,
                rate: product.owner?.rating,
                reviews: product.owner?.reviews,
                description: product.owner?.about,
              },
            }}
            setSelectedProduct={(value) => {}}
            selectProvider={(value) => {}}
            onClick={() => {}}
            aria-label={`Product Card for ${product.name}`}
          />
        ))}
      </div>
    </>
  );
};

const OTPInput = (props) => {
  const { otp, setOtp } = props;

  const handleChange = (element, index) => {
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
      {otp.map((data, index) => (
        <input
          key={index}
          style={{
            width: '44px',
            height: '44px',
            textAlign: 'center',
            borderRadius: '4px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
          }}
          type='text'
          maxLength='1'
          value={data}
          onChange={(e) => handleChange(e.target, index)}
        />
      ))}
    </div>
  );
};

const Cart = (props) => {
  const { user, cart } = props;

  const [isCheckout, setCheckout] = useState(false);
  const [isPayment, setPayment] = useState(false);
  const [isPaymentSuccess, setPaymentSuccess] = useState(true);
  const [offers, setOffers] = useState([
    {
      img: '/assets/img/hero-provider.jpg',
      name: 'Baby Gear Protection Insurance',
      price_day: 10,
      currency: 'eur',
    },
  ]);

  const [cardNum, setCardNum] = useState('');
  const [expDate, setExpDate] = useState('');
  const [ccv, setCCV] = useState('');

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneNum, setPhoneNum] = useState('');

  const [couponCode, setCouponCode] = useState('');
  const [isCouponCode, setIsCouponCode] = useState(false);

  const [isDownloadApp, setDownloadApp] = useState(false);

  const onChangeCardNum = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');

    value = value.match(/.{1,4}/g)?.join(' ') || '';

    setCardNum(value);
  };

  const onChangeExpDate = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, '');

    if (value.length >= 2) {
      value = value.substring(0, 2) + '/' + value.substring(2);
    }

    value = value.substring(0, 5);

    setExpDate(value);
  };

  const onChangeCCV = (e) => {
    let { value } = e.target;

    value = value.replace(/\D/g, '');
    value = value.substring(0, 4);
    setCCV(value);
  };

  const onPayment = (e) => {
    e.preventDefault();
    setCheckout(false);
    setPayment(true);
    setPaymentSuccess(true);
  };

  return (
    <div className='flex flex-col items-center justify-center my-4'>
      {!isDownloadApp && <Progressbar />}

      {!isPayment && (
        <div className='flex flex-col gap-2 w-full'>
          <div className='my-4 quicksand text-base'>Payment</div>
          <div className='flex px-2 py-1 justify-content-between align-items-center w-full bg-light-gray rounded'>
            <div className='quicksand text-sm font-semibold'>
              {!isCheckout && cart.duration}
              {isCheckout && !isPayment && user.email ? user.email : user.phoneNum}
            </div>
          </div>
        </div>
      )}

      {!isCheckout && (
        <>
          {!isPayment && cart && (
            <div className='flex flex-col mt-4 gap-4 w-full'>
              <div className='flex flex-col gap-2 w-full'>
                {cart.products.map((item) => (
                  <div className='flex gap-1 align-items-center justify-content-between w-full quicksand text-lg'>
                    <div className='flex align-items-center gap-2 font-semibold quicksand'>
                      <img src={item.img} alt='' className='' style={{ width: '60px', height: '60px' }} />
                      <p>{item.name}</p>
                    </div>
                    <div className='flex gap-1 '>
                      <div>{item.amount} x</div> <strong>{numberWithCommas(item.price_day, item.currency)}</strong>
                    </div>
                  </div>
                ))}
              </div>

              <div className='flex flex-col gap-2 w-full quicksand font-semibold'>
                <div className='flex align-items-center justify-content-between gap-1 w-full '>
                  <div className='flex gap-2'>
                    <CheckmarkCircleOutline />
                    Return Fee
                  </div>
                  <div>{numberWithCommas(cart.cost_delivery, cart.currency)}</div>
                </div>

                <div className='flex align-items-center justify-content-between gap-1 w-full '>
                  <div className='flex gap-2'>
                    <CheckmarkCircleOutline /> Pickup Fee
                  </div>
                  <div>{numberWithCommas(cart.cost_pickup, cart.currency)}</div>
                </div>

                <div className='flex align-items-center justify-content-between gap-1 w-full '>
                  <div className='flex gap-2'>
                    <CheckmarkCircleOutline /> Last Minute Fee
                  </div>
                  <div>{numberWithCommas(cart.cost_lastminute, cart.currency)}</div>
                </div>

                <div className='flex align-items-center justify-content-between gap-1 w-full '>
                  <div className='flex gap-2 align-items-center'>
                    <CheckmarkCircleOutline /> <p>Baby Gear Protection Insurance</p>
                    <button type='button' onClick={() => {}}>
                      <TrashOutline />
                    </button>
                  </div>
                  <div>{numberWithCommas(cart.cost_insurance, cart.currency)}</div>
                </div>
              </div>

              <div className='flex align-items-center justify-content-between gap-1 w-full text-xl font-bold'>
                <div className='flex gap-2 align-items-center'>
                  <ReceiptLongOutlined /> <p>Total</p>
                </div>
                <div>{numberWithCommas(cart.cost_total, cart.currency)}</div>
              </div>

              {offers && (
                <div className='w-full quicksand'>
                  <div className='font-bold'>Other products you may need</div>

                  <div className='flex flex-col my-2 gap-2 w-full font-semibold'>
                    {offers.map((item) => (
                      <div className='flex justify-content-between'>
                        <div className='flex align-items-center gap-2 '>
                          <img src={item.img} alt='' style={{ width: '60px', height: '60px' }} /> <p>{item.name}</p>
                        </div>

                        <div className='flex align-items-center gap-2'>
                          <p>{numberWithCommas(item.price_day, item.currency)}</p>
                          <button
                            type='button'
                            onClick={() => {}}
                            className='px-3 h-full rounded'
                            style={{ background: '#000' }}
                          >
                            <AddCircleOutline color='#fff' />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <button type='button' onClick={() => setIsCouponCode(true)} className='quicksand underline font-fold'>
                Do you have coupon/referral code?
              </button>

              {isCouponCode && (
                <div className='flex flex-col gap-4'>
                  <div className='flex align-items-center justify-content-between gap-4'>
                    <input
                      type='text'
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      className='w-full rounded-lg bg-light-gray'
                    />

                    <button
                      type='button'
                      className='flex px-4 py-2 h-full bg-gray rounded-lg font-bold text-lg'
                      onClick={() => {}}
                    >
                      Apply
                    </button>
                  </div>

                  <div className='flex flex-col align-items-center m-2'>
                    <p className='text-lg'>50 Euro discount applied</p>
                    <button type='button' className='font-bold' onClick={() => {}}>
                      Remove
                    </button>
                  </div>
                </div>
              )}

              <button
                type='button'
                className={`flex align-items-center justify-content-center px-3 py-2 w-full rounded gap-3 text-white bg-ba`}
                onClick={() => setCheckout(true)}
              >
                <div>Continue</div>
                <ArrowForwardCircleOutline width='24px' color='#fff' aria-hidden='true' />
              </button>
            </div>
          )}
        </>
      )}

      {isCheckout && (
        <div className='flex flex-col gap-2 w-full quicksand'>
          <div className='flex items-center justify-between  my-4gap-1 w-full text-lg font-bold'>
            <div className='flex gap-2 align-items-center'>
              <ReceiptLongOutlined /> <p>Total</p>
            </div>
            <div>{numberWithCommas(cart.cost_total, cart.currency)}</div>
          </div>
          <input type='text' value={cardNum} onChange={onChangeCardNum} placeholder='Card Number' maxLength='19' />

          <div className='flex gap-3'>
            <input
              type='text'
              value={expDate}
              onChange={onChangeExpDate}
              placeholder='Expiration Date (MM/YY)'
              maxLength='5'
            />

            <input className='w-full' type='text' value={ccv} onChange={onChangeCCV} placeholder='CVV' maxLength='4' />
          </div>
          <button
            type='button'
            className='flex justify-content-center my-4 px-3 py-2 w-full rounded gap-3 text-white bg-ba' // Adjusted the className for background color
            onClick={onPayment}
          >
            <div>Pay</div>
            <ArrowForwardCircleOutline width='24px' color='#fff' />
          </button>
        </div>
      )}

      {isPayment && (
        <>
          {isPaymentSuccess && !isDownloadApp && (
            <div className='flex flex-col p-4 w-full rounded' style={{ border: '1px solid #4CAF50' }}>
              <div className='flex flex-col align-items-center m-auto gap-2' style={{ color: '#4CAF50' }}>
                <CheckmarkCircleOutline height='80px' width='80px' color={'#4CAF50'} /> Successfull
              </div>

              <div className='my-4 w-full'>
                {!user.email && (
                  <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                    <label className='inline-label'>Email</label>

                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type='text'
                      placeholder='example@example.com'
                    />
                  </div>
                )}

                {!user.phoneNum && (
                  <div className='flex gap-1'>
                    <div className='relative inline-flex flex-column mt-5 mb-3 w-[120px]'>
                      <label className='inline-label'>Country Code</label>
                      <div className='flex' style={{ maxWidth: '120px' }}>
                        <input
                          value={countryCode}
                          onChange={(e) => setCountryCode(e.target.value)}
                          className=''
                          type='text'
                          placeholder='+44'
                        />
                      </div>
                    </div>
                    <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                      <label className='inline-label'>Phone Number</label>
                      <input
                        value={phoneNum}
                        onChange={(e) => setPhoneNum(e.target.value)}
                        type='text'
                        placeholder=''
                      />
                    </div>
                  </div>
                )}

                {!user.name && (
                  <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                    <label className='inline-label'>Name</label>

                    <input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='John' />
                  </div>
                )}
                {!user.surname && (
                  <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                    <label className='inline-label'>Surname</label>

                    <input value={surname} onChange={(e) => setSurname(e.target.value)} type='text' placeholder='Doe' />
                  </div>
                )}
              </div>

              <button
                type='button'
                onClick={() => setDownloadApp(true)}
                className={`flex align-items-center justify-content-center mt-4 px-3 py-2 w-full rounded gap-3 text-white bg-ba`}
              >
                Save And Chat with Provider
                <ArrowForwardCircleOutline color={'#fff'} />
              </button>
            </div>
          )}

          {!isPaymentSuccess && (
            <div className='flex flex-col p-4 w-full rounded' style={{ border: '1px solid red' }}>
              <div className='flex flex-col align-items-center m-auto gap-2' style={{ color: 'red' }}>
                <CloseCircleOutline height='80px' width='80px' color={'red'} /> Something went wrong!
              </div>
              <button
                type='button'
                onClick={() => {}}
                className={`flex align-items-center justify-content-center mt-4 px-3 py-2 w-full rounded gap-3 text-white bg-ba`}
              >
                Try Again
                <LoopOutlined />
              </button>
            </div>
          )}
        </>
      )}

      {isDownloadApp && (
        <div className='flex flex-col'>
          <h3 className='my-4 color-ba text-center text-lg'>Download Babonbo App</h3>

          <div className='flex flex-col w-full gap-4'>
            <div className='flex align-items-center justify-content-between'>
              <img src='/assets/img/mobile_mockup.png' className='w-1/2' />
              <ul className='flex flex-col gap-2 w-1/2'>
                <li className='flex align-items-center gap-2' style={{ color: '#27AE60' }}>
                  <CheckCircleOutline />
                  <span className='text-black'>Track your order easy</span>
                </li>
                <li className='flex align-items-center gap-2' style={{ color: '#27AE60' }}>
                  <CheckCircleOutline />
                  <span className='text-black'>Chat with Provider</span>
                </li>
                <li className='flex align-items-center gap-2' style={{ color: '#27AE60' }}>
                  <CheckCircleOutline />
                  <span className='text-black'>Catch Discounts</span>
                </li>
                <li className='flex align-items-center gap-2' style={{ color: '#27AE60' }}>
                  <CheckCircleOutline />
                  <span className='text-black'>Promotions and more</span>
                </li>
              </ul>
            </div>
            <div className='flex w-full justify-content-between gap-4 mt-4'>
              <img src='/assets/img/app-store.png' style={{ width: '150px', height: '50px' }} />
              <img src='/assets/img/play-store.png' style={{ width: '150px', height: '50px' }} />
            </div>
          </div>

          <Link to='/newux/'>Discover More</Link>
        </div>
      )}
    </div>
  );
};

const Progressbar = () => {
  const totalDuration = 10 * 60;
  const [timeLeft, setTimeLeft] = useState(totalDuration);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  const displayTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}.${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const calculateProgress = () => {
    return (timeLeft / totalDuration) * 100;
  };

  return (
    <div className='w-full'>
      <div className='quicksand text-center'>Reservation Time:</div>
      <div className='my-3 quicksand text-3xl text-center font-bold'>{displayTime()}</div>

      <div className='w-full rounded-lg overflow-hidden' style={{ backgroundColor: '#ddd' }}>
        <div
          style={{
            height: '20px',
            backgroundColor: '#4CAF50',
            width: `${calculateProgress()}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

function numberWithCommas(x, CURRENCY) {
  if (!x) {
    x = 0;
  }

  if (!CURRENCY) {
    return x.toFixed(2);
  }

  if (isNaN(x)) return x;

  return x.toLocaleString('en-US', {
    style: 'currency',
    currency: CURRENCY,
  });
}
