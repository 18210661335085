import React, { useState, useEffect, useRef } from 'react';
import LoaderMaster from '../Loaders/Loaders';

import moment from 'moment';

import { postApiPrivate } from '../../api';

import { Link } from 'react-router-dom';
import { BodySharp, ChatbubbleEllipsesSharp, CloseSharp, NotificationsSharp, PersonOutline, ReaderSharp, SaveSharp, ShirtSharp, StarSharp, StorefrontSharp, WifiSharp } from 'react-ionicons';

import { Avatar, Chip, Container, Grid, TextareaAutosize, TextField } from "@mui/material";
import ReviewComponent from "../Reviews/ReviewComponent";
import swal from 'sweetalert';

import { numberWithCommas, onFetchTime, onFormatUrl } from '../../helpers/GeneralHelper';

import ConfirmModal from "../General/ConfirmModal";
import DeclineModal from "../General/DeclineModal";
import StarReview from '../General/Stars';
import OrdersStatus from '../Modals/OrdersStatus';

const AdminOrdersDetails = (props) => {

    const { TRANSLATE, match, history, USER, LANGUAGE, } = props;

    const [isUpdating, setUpdating] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isRefreshing, setRefreshing] = useState(false);

    const [isEdited, setEdited] = useState(false);
    const [isStatus, setStatus] = useState(false);
    const [isActions, setActions] = useState(false);

    const [isAccepting, setAccepting] = useState(false);
    const [isRejecting, setRejecting] = useState(false);


    const [ORDER, setOrder] = useState({});

    useEffect(() => {

        const ORDER_ID = match.params.id;

        if (ORDER_ID) {
            onFetchSingle(ORDER_ID);
        }

        console.log("ORDER_ID ===> ", ORDER_ID);


    }, []);

    const onFetchSingle = async (order_id, type) => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        if (type === 'refresh') {
            setRefreshing(true);
        }
        else {
            setLoading(true);
        }

        try {

            const DATA_RESPONSE = await postApiPrivate('/admin/orders/details', { id: order_id }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API LOADING SINGLE ORDER", DATA_RESPONSE.error);
                setLoading(false);
                setRefreshing(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setOrder(DATA_RESPONSE);
            setLoading(false);
            setRefreshing(false);


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
            setRefreshing(false);
        }

    }

    const onCalculateDailyPrice = (PRODUCT, DURATION) => {

        var { price_day, price_month, price_week } = PRODUCT;

        if (DURATION < 7) {
            return price_day
        }
        else if (DURATION >= 7 && DURATION < 30) {
            var PRICE_WEEK_DAILY = price_week / 7;
            return PRICE_WEEK_DAILY
        }
        else if (DURATION >= 30) {
            var PRICE_MONTH_DAILY = price_month / 30;
            return PRICE_MONTH_DAILY
        }

    }

    const onCalculateSingleSubtotal = (PRODUCT, DURATION) => {

        var { price_day, price_month, price_week, category } = PRODUCT;

        let quantity = parseFloat(PRODUCT.quantity) || 1;

        let subtotal = 0;

        if (category === 'consumable') {
            subtotal = price_day * quantity;
        }
        else {

            if (DURATION < 7) {
                subtotal = price_day * DURATION * quantity;
            }
            else if (DURATION >= 7 && DURATION < 30) {
                var PRICE_WEEK_DAILY = price_week / 7;
                subtotal = PRICE_WEEK_DAILY * DURATION;
            }
            else if (DURATION >= 30) {
                var PRICE_MONTH_DAILY = price_month / 30;
                subtotal = PRICE_MONTH_DAILY * DURATION;

            }

        }

        return subtotal

    }


    moment.locale(TRANSLATE.lang);

    const order_id = ORDER.id;
    const order_status = ORDER.status;
    const order_products = ORDER.products || []
    const order_delivery = ORDER.delivery || {};
    const order_return = ORDER.return || {};

    const order_created = moment(ORDER.created).format('dddd DD MMMM YYYY');
    const order_updated = moment(ORDER.updated).format('dddd DD MMMM YYYY');
    const order_created_time = moment(ORDER.created).format('HH:mm');
    const order_updated_time = moment(ORDER.updated).format('HH:mm');

    const order_checkin = moment(ORDER.checkin).format('DD MMMM YYYY');
    const order_checkout = moment(ORDER.checkout).format('DD MMMM YYYY');


    const order_customer = ORDER.customer || {}
    const customer_id = order_customer.id;
    const customer_name = order_customer.name;
    const customer_img = order_customer.img;
    const customer_phone = order_customer.phone;

    const order_store = ORDER.store || {}
    const store_id = order_store.id;
    const store_name = order_store.name;
    const store_img = order_store.img;
    const store_address = order_store.address;

    const order_message = ORDER.message || '';
    const order_reason = ORDER.reason || '';

    const order_currency = ORDER.currency || 'eur';

    const order_checkin_ex = ORDER.checkin_ex;
    const order_checkout_ex = ORDER.checkout_ex;
    const order_duration_ex = ORDER.checkdur_ex;

    const order_picktime = ORDER.picktime || '';
    const order_droptime = ORDER.droptime || '';

    const order_coupon = ORDER.coupon || '';
    const order_captured = ORDER.captured || false;
    const order_refunded = ORDER.refunded || false;
    const order_charge = ORDER.charge || '';
    const order_refund = ORDER.refund || '';
    const order_payint = ORDER.payint || '';

    let orderPeriod = '';
    let orderDuration = 0;
    let orderExact = false;
    let orderStart = '';
    let orderEnd = '';

    if (order_checkin_ex && order_checkout_ex) {

        orderPeriod = order_checkin_ex + ' - ' + order_checkout_ex;
        orderStart = order_checkin_ex;
        orderEnd = order_checkout_ex;
        orderDuration = order_duration_ex;
        orderExact = true;
    }
    else {

        const startMoment = moment(order_checkin);
        const endMoment = moment(order_checkout);

        orderPeriod = startMoment.format('DD/MM/YYYY') + ' - ' + endMoment.format('DD/MM/YYYY');
        orderStart = startMoment.format('DD/MM/YYYY');
        orderEnd = endMoment.format('DD/MM/YYYY');
        orderDuration = moment(endMoment).diff(startMoment, 'days') + 1;
    }


    //Calculate Costs

    let cost_total = ORDER.total || 0;
    let cost_subtotal = ORDER.subtotal || 0;
    let cost_delivery = ORDER.d_cost || 0;
    let cost_return = ORDER.r_cost || 0;
    let cost_service_fee = ORDER.f_cost || 0;
    let cost_provider_fee = ORDER.f_p_cost || 0;
    let cost_total_fees = parseFloat(cost_service_fee) + parseFloat(cost_provider_fee);
    let cost_provider = ORDER.p_total || 0;
    let cost_discount = ORDER.dis_cost || 0;
    let cost_insurance = ORDER.i_cost || 0;
    let cost_lastminute = ORDER.lm_cost || 0;

    let cost_credits = ORDER.credits || 0;
    let cost_used = ORDER.used || 0;
    let cost_remaining = ORDER.remaining || 0;
    let cost_total_to_pay = ORDER.total_to_pay || 0;

    let hasInsurance = ORDER.hasInsurance || false;
    let hasLastminute = ORDER.hasLastminute || false;

    if (hasLastminute) {
        cost_provider = cost_provider + 8;
        cost_total_fees = cost_total_fees + 3.5;
    }

    let CostsObx = {
        cost_total: numberWithCommas(parseFloat(cost_total.toFixed(2)), order_currency),
        cost_subtotal: numberWithCommas(parseFloat(cost_subtotal.toFixed(2)), order_currency),
        cost_delivery: numberWithCommas(parseFloat(cost_delivery.toFixed(2)), order_currency),
        cost_return: numberWithCommas(parseFloat(cost_return.toFixed(2)), order_currency),
        cost_service_fee: numberWithCommas(parseFloat(cost_service_fee.toFixed(2)), order_currency),
        cost_provider_fee: numberWithCommas(parseFloat(cost_provider_fee.toFixed(2)), order_currency),
        cost_total_fees: numberWithCommas(parseFloat(cost_total_fees.toFixed(2)), order_currency),
        cost_provider: numberWithCommas(parseFloat(cost_provider.toFixed(2)), order_currency),
        cost_discount: numberWithCommas(parseFloat(cost_discount.toFixed(2)), order_currency),
        cost_insurance: numberWithCommas(parseFloat(cost_insurance.toFixed(2)), order_currency),
        cost_lastminute: numberWithCommas(cost_lastminute.toFixed(2), order_currency),
        cost_credits: numberWithCommas(cost_credits, order_currency),
        cost_used: numberWithCommas(cost_used, order_currency),
        cost_remaining: numberWithCommas(cost_remaining, order_currency),
        cost_total_to_pay: numberWithCommas(cost_total_to_pay, order_currency),
    }

    //Reviews
    const order_review_cus = ORDER.review_cus || {};
    const order_review_pro = ORDER.review_pro || {};


    if (isLoading) {
        return (
            <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
        )
    }

    if (isRefreshing) {
        return (
            <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
        )
    }

    if (isUpdating) {
        return (
            <LoaderMaster title={TRANSLATE.loader_processing_request} type="secondary" />
        )
    }


    moment.locale(TRANSLATE.lang);

    // return (
    //     <div style={{whiteSpace: 'break-spaces'}}>
    //         {JSON.stringify(ORDER, null, 2)}
    //     </div>
    // )

    return (
        <>

            {isRejecting && (
                <DeclineModal
                    onClose={() => setRejecting(false)}
                    ORDER={ORDER}
                    TRANSLATE={TRANSLATE}
                    LANGUAGE={LANGUAGE}
                    history={history}
                    match={match}
                    onFetchSingle={onFetchSingle}
                    USER={USER}

                />
            )}

            {isAccepting && (
                <ConfirmModal
                    onClose={() => setAccepting(false)}
                    ORDER={ORDER}
                    TRANSLATE={TRANSLATE}
                    LANGUAGE={LANGUAGE}
                    history={history}
                    match={match}
                    onFetchSingle={onFetchSingle}
                    USER={USER}

                />
            )}

            {isStatus && (
                <OrdersStatus
                    onClose={() => setStatus(false)}
                    ORDER={ORDER}
                    onFetchSingle={onFetchSingle}
                    {...props}

                />
            )}

            <Grid container spacing={2}>

                <Grid item xs={12} lg={4} md={5}>
                    <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>
                        <div className="top_store_card">
                            <div className="top_store_profile">

                                <div className="font-100 text-xl">Order <span className='font-bold'>#{order_id}</span></div>

                            </div>

                            <div className="top_store_stats items-center">
                                <div>
                                    <OrderStatus status={order_status} type={'center'} TRANSLATE={TRANSLATE} />
                                </div>
                                <div>
                                    <PaymentStatus status={order_captured} TRANSLATE={TRANSLATE} />
                                </div>
                                <div>
                                    <RefundStatus
                                        created={ORDER.created}
                                        updated={ORDER.updated}
                                        checkin={order_checkin_ex}
                                        status={order_status}
                                        refunded={order_refunded}
                                        captured={order_captured}

                                    />
                                </div>
                            </div>


                            <div className="top_store_stats">
                                <div>
                                    <div className="font-bold text-lg">{CostsObx.cost_total}</div>
                                    <p>Total</p>
                                </div>
                                <div>
                                    <div className="font-bold text-lg text-bagreen-100">{CostsObx.cost_provider}</div>
                                    <p>Provider</p>
                                </div>
                                <div>
                                    <div className="font-bold text-lg text-babonbo-100">{CostsObx.cost_total_fees}</div>
                                    <p>Fees</p>
                                </div>
                            </div>

                            <div className="top_store_stats">
                                <div>
                                    <div className="font-bold text-lg">{orderStart}</div>
                                    <p>Checkin</p>
                                </div>
                                <div>
                                    <div className="font-bold text-lg">{orderEnd}</div>
                                    <p>Checkout</p>
                                </div>
                            </div>

                            <div className="top_store_stats">
                                <div>
                                    <div className="font-bold text-lg">{orderDuration}</div>
                                    <p>Days</p>
                                </div>
                            </div>

                            {hasInsurance && (
                                <div className="top_store_stats">
                                    <div className='btn-auto' style={{ background: 'green' }}>
                                        INSURANCE PURCHASED
                                    </div>
                                </div>
                            )}
                            {hasLastminute && (
                                <div className="top_store_stats">
                                    <div className='btn-auto bg-blue-500'>
                                        LAST MINUTE FEE PAID
                                    </div>
                                </div>
                            )}


                            <div className="top_store_stats">
                                <div>
                                    <StarReview rate={order_review_pro.rank} size={20} />
                                    <p>To Provider</p>
                                </div>
                                <div>
                                    <StarReview rate={order_review_cus.rank} size={20} />
                                    <p>To Customer</p>
                                </div>
                            </div>

                        </div>

                        {!isEdited && (
                            <div className="row justify-content-center">
                                <a className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/stores/profile/' + store_id)}>
                                    <StorefrontSharp color={'#fff'} style={{ marginRight: 10 }} /> Store
                                </a>

                                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/users/profile/' + customer_id)}>
                                    <BodySharp color={'#fff'} style={{ marginRight: 10 }} /> Customer
                                </div>

                                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&store=' + store_id + '&customer=' + customer_id)}>
                                    <ReaderSharp color={'#fff'} style={{ marginRight: 10 }} /> Orders
                                </div>

                                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/products/0?sort=updatedAt&dir=desc&order=' + order_id)}>
                                    <ShirtSharp color={'#fff'} style={{ marginRight: 10 }} /> Products
                                </div>

                                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/inbox/0?sort=updatedAt&dir=desc&store=' + store_id + '&customer=' + customer_id)}>
                                    <ChatbubbleEllipsesSharp color={'#fff'} style={{ marginRight: 10 }} /> Chat
                                </div>

                                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/reviews/0?sort=updatedAt&dir=desc&store=' + store_id + '&customer=' + customer_id)}>
                                    <StarSharp color={'#fff'} style={{ marginRight: 10 }} /> Reviews
                                </div>

                                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setStatus(true)}>
                                    <NotificationsSharp color={'#fff'} style={{ marginRight: 10 }} /> Change Status
                                </div>

                                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setActions(true)}>
                                    <WifiSharp color={'#fff'} style={{ marginRight: 10 }} /> Actions
                                </div>


                            </div>
                        )}

                        {isEdited && (
                            <>

                                <div className="top_add_cart"
                                    style={{ backgroundColor: '#59ad6a' }}
                                    onClick={() => {
                                        swal({
                                            title: 'Confirm to Save',
                                            text: 'Please insert CONFIRM to save',
                                            content: "input",
                                        })
                                            .then((value) => {

                                                if (value === 'CONFIRM') {


                                                }
                                                else {

                                                    swal('Invalid Password', 'You are not authorized to update this user', 'error');
                                                }

                                            });
                                    }}

                                >
                                    <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Update Order
                                </div>

                                <div className="top_add_cart" style={{ backgroundColor: '#ccc' }} onClick={() => onFetchSingle(order_id)}>
                                    <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Cancel Changes
                                </div>

                            </>
                        )}


                    </div>
                </Grid>

                <Grid item xs={12} lg={8} md={7}>

                    <div className="w-full lg:pl-14 py-2">

                        <div className="w-full flex-shrink-0 border border-zinc-200 rounded-lg ml-0 mb-10 order-1 md:order-0 bg-white">
                            <div className='w-full flex items-center p-4 pb-0'>
                                <Link className="w-[40px] h-[40px] mr-2" to={'/admin/users/profile/' + customer_id}>
                                    <img className="w-full h-full object-cover rounded-full" src={customer_img || "/assets/img/avatar.svg"} alt='' />
                                </Link>
                                <div className="text-sm">
                                    <div className="font-bold capitalize">{customer_name}</div>
                                    <div className="text-zinc-400">Customer</div>
                                </div>

                                <OrderStatus status={order_status} type={'list'} TRANSLATE={TRANSLATE} />

                            </div>

                            <div className='w-full flex items-center p-4 pb-0'>
                                <Link className="w-[40px] h-[40px] mr-2" to={'/admin/stores/profile/' + store_id}>
                                    <img className="w-full h-full object-cover rounded-full" src={store_img || "/assets/img/avatar.svg"} alt='' />
                                </Link>
                                <div className="text-sm">
                                    <div className="font-bold capitalize">{store_name}</div>
                                    <div className="text-zinc-400">Store</div>
                                </div>

                            </div>

                            <div className="flex px-4 py-6 my-6 border-t border-b border-zinc-200 items-center justify-between sm:flex-row flex-col">
                                <div className="text-sm w-full sm:w-1/2 mb-6 sm:mb-0">
                                    <div className="font-bold">
                                        Order Created
                                    </div>
                                    <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                        {order_created} {/* {'(' + moment(ORDER.created).fromNow() + ')'} */}
                                    </div>
                                    <div className="font-bold">
                                        {order_created_time}
                                    </div>
                                </div>
                                <div className="text-sm w-full sm:w-1/2">
                                    <div className="font-bold">
                                        Order Updated
                                    </div>
                                    <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                        {order_updated} {/* {'(' + moment(ORDER.updated).fromNow() + ')'} */}
                                    </div>
                                    <div className="font-bold">
                                        {order_updated_time}
                                    </div>
                                </div>
                            </div>


                            {order_products.map((item, index) => {


                                return (
                                    <ProductCard product={item}
                                        rentalperiod={orderPeriod}
                                        duration={orderDuration}
                                        onCalculateDailyPrice={onCalculateDailyPrice}
                                        onCalculateSingleSubtotal={onCalculateSingleSubtotal}
                                        key={index}
                                        TRANSLATE={TRANSLATE}
                                        LANGUAGE={LANGUAGE}
                                        history={history}
                                        currency={order_currency}
                                    />
                                )

                            })}



                            <div className="flex px-4 py-6 my-6 border-t border-zinc-200 items-center justify-between sm:flex-row flex-col">
                                <div className="text-sm w-full sm:w-1/2 mb-6 sm:mb-0">
                                    <div className="font-bold">
                                        {TRANSLATE.wd_pickup}
                                    </div>
                                    <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                        <DeliveryName delivery={order_delivery}
                                            status={order_status}
                                            address={store_address}
                                            TRANSLATE={TRANSLATE}

                                        />
                                        {order_picktime && (
                                            <div className="mt-2 mb-2">
                                                {'(' + onFetchTime(order_picktime, LANGUAGE) + ')'}
                                            </div>
                                        )}
                                        {/* {JSON.stringify(order_delivery, null, 2)} */}
                                    </div>
                                    <div className="font-bold">
                                        {numberWithCommas(cost_delivery, order_currency)}
                                    </div>
                                </div>
                                <div className="text-sm w-full sm:w-1/2">
                                    <div className="font-bold">
                                        {TRANSLATE.wd_dropoff}
                                    </div>
                                    <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                        <DeliveryName delivery={order_return}
                                            status={order_status}
                                            address={store_address}
                                            TRANSLATE={TRANSLATE}

                                        />
                                        {order_droptime && (
                                            <div className="mt-2 mb-2">
                                                {'(' + onFetchTime(order_droptime, LANGUAGE) + ')'}
                                            </div>
                                        )}
                                        {/* {JSON.stringify(order_return, null, 2)} */}
                                    </div>
                                    <div className="font-bold">
                                        {numberWithCommas(cost_return, order_currency)}
                                    </div>
                                </div>
                            </div>

                            {(customer_phone || order_coupon) && (
                                <div className="flex px-4 py-6 my-6 border-t border-zinc-200 items-center justify-between sm:flex-row flex-col">
                                    {customer_phone && (
                                        <div className="text-sm w-full sm:w-1/2 mb-6 sm:mb-0">
                                            <div className="font-bold capitalize">{TRANSLATE.wd_phone_number}</div>
                                            <a href={'tel:+' + customer_phone.replace('+', '')} className="py-1">
                                                +{customer_phone.replace('+', '')}
                                            </a>
                                        </div>
                                    )}
                                    {order_coupon && (
                                        <div className="text-sm w-full sm:w-1/2">
                                            <div className="font-bold">
                                                Coupon
                                            </div>
                                            <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                                with discount code: {order_coupon}
                                            </div>
                                            <div className="font-bold">
                                                {'(' + CostsObx.cost_discount + ')'}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {(hasInsurance || hasLastminute) && (
                                <div className="flex px-4 py-6 my-6 border-t border-zinc-200 items-center justify-between sm:flex-row flex-col">
                                    {hasInsurance && (
                                        <div className="text-sm w-full sm:w-1/2">
                                            <div className="font-bold">
                                                Insurance
                                            </div>
                                            <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                                with insurance purchased:
                                            </div>
                                            <div className="font-bold">
                                                {'(' + CostsObx.cost_insurance + ')'}
                                            </div>
                                        </div>
                                    )}
                                    {hasLastminute && (
                                        <div className="text-sm w-full sm:w-1/2">
                                            <div className="font-bold">
                                                Last minute
                                            </div>
                                            <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                                with fee paid:
                                            </div>
                                            <div className="font-bold text-blue-500">
                                                {'(' + CostsObx.cost_lastminute + ')'}
                                            </div>
                                        </div>
                                    )}

                                </div>
                            )}

                            {order_message && (
                                <div className="flex px-4 py-6 my-6 border-t border-zinc-200 items-center justify-between sm:flex-row flex-col">
                                    <div className="text-sm w-full sm:w-1/2 mb-6 sm:mb-0">
                                        <div className="font-bold capitalize">{TRANSLATE.wd_additional_info}</div>
                                        <div className="py-1">
                                            {order_message}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {order_reason && (
                                <div className="flex px-4 py-6 my-6 border-t border-zinc-200 items-center justify-between sm:flex-row flex-col">
                                    <div className="text-sm w-full sm:w-1/2 mb-6 sm:mb-0">
                                        <div className="font-bold capitalize">Reason: {order_status}</div>
                                        <div className="py-1">
                                            {order_reason}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="flex px-4 py-6 my-6 border-t border-zinc-200 items-center justify-between sm:flex-row flex-col">
                                <div className="text-sm w-full sm:w-1/2 mb-6 sm:mb-0">
                                    <div className="font-bold">
                                        Payment
                                    </div>
                                    {order_payint && (
                                        <div className="py-1 capitalize" style={{ marginRight: 50, color: 'orange' }}>
                                            {order_payint}
                                        </div>
                                    )}
                                    {order_charge && (
                                        <div className="py-1 capitalize" style={{ marginRight: 50, color: 'green' }}>
                                            {order_charge}
                                        </div>
                                    )}
                                    {order_refund && (
                                        <div className="py-1 capitalize" style={{ marginRight: 50, color: 'red' }}>
                                            {order_refund}
                                        </div>
                                    )}

                                    <div className="font-bold">
                                        {'(' + CostsObx.cost_total + ')'}
                                    </div>


                                </div>
                                <div className="text-sm w-full sm:w-1/2">
                                    <div className="font-bold">
                                        Pay Status
                                    </div>

                                    {order_captured ? (
                                        <>
                                            <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                                Order accepted by the provider
                                            </div>
                                            <div className="font-bold" style={{ color: 'green' }}>
                                                Captured
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                                Order still not accepted by the provider
                                            </div>
                                            <div className="font-bold" style={{ color: 'orange' }}>
                                                Uncaptured
                                            </div>
                                        </>
                                    )}

                                    {order_refunded && (
                                        <div className="font-bold" style={{ color: 'red' }}>
                                            Refunded
                                        </div>
                                    )}

                                </div>

                            </div>

                            {cost_credits > 0 && (
                                <div className="flex px-4 py-6 my-6 border-t border-zinc-200 items-center justify-between sm:flex-row flex-col">
                                    <div className="text-sm w-full sm:w-1/2 mb-6 sm:mb-0">
                                        <div className="font-bold">
                                            Babonbo Credits
                                        </div>
                                        <div className="py-1 capitalize" style={{ marginRight: 50, color: 'black' }}>
                                            Before order <span className='font-bold'>{'(' + CostsObx.cost_credits + ')'}</span>
                                        </div>

                                        <div className="py-1 capitalize" style={{ marginRight: 50, color: 'black' }}>
                                            After order <span className='font-bold'>{'(' + CostsObx.cost_remaining + ')'}</span>
                                        </div>

                                    </div>
                                    <div className="text-sm w-full sm:w-1/2">
                                        <div className="font-bold">
                                            Credits used
                                        </div>
                                        <div className="font-bold" style={{ color: '#26BFBF' }}>
                                            {'(' + CostsObx.cost_used + ')'}
                                        </div>
                                        <div className="font-bold mt-4">
                                            Total Paid with Card
                                        </div>
                                        <div className="font-bold" style={{ color: '#fd6270' }}>
                                            {'(' + CostsObx.cost_total_to_pay + ')'}
                                        </div>
                                    </div>
                                </div>
                            )}


                            <div className="flex px-4 py-6 my-6 border-t border-zinc-200 items-center justify-between sm:flex-row flex-col">
                                <div className="text-sm w-full sm:w-1/2 mb-6 sm:mb-0">
                                    <div className="font-bold">
                                        Total Fees
                                    </div>
                                    <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                        with service fee: {CostsObx.cost_service_fee}
                                    </div>
                                    <div className="font-bold" style={{ color: '#fd6270' }}>
                                        {'(' + CostsObx.cost_total_fees + ')'}
                                    </div>
                                </div>

                                <div className="text-sm w-full sm:w-1/2">
                                    <div className="font-bold">
                                        Payout
                                    </div>
                                    <div className="py-1 capitalize" style={{ marginRight: 50 }}>
                                        with provider fee: {CostsObx.cost_provider_fee}
                                    </div>
                                    <div className="font-bold" style={{ color: '#26BFBF' }}>
                                        {'(' + CostsObx.cost_provider + ')'}
                                    </div>

                                </div>

                            </div>

                            <div className="flex px-4 py-6 my-6 border-t border-zinc-200 items-center justify-between">
                                <div className="text-sm w-full sm:w-1/2">
                                    <div className="font-bold capitalize">
                                        {TRANSLATE.wd_order_total}
                                    </div>
                                </div>
                                <div className="text-lg  w-full sm:w-1/2 text-right">
                                    <div className="font-bold">
                                        {CostsObx.cost_total}
                                    </div>
                                </div>
                            </div>



                            {/* <ProviderActions order={order_id}
                                status={order_status}
                                store={store_id}
                                customer={customer_id}
                                onAcceptOrder={() => setAccepting(true)}
                                onRejectOrder={() => setRejecting(true)}
                                onUpdateStatus={onUpdateStatus}
                                history={history}
                                LANGUAGE={LANGUAGE}
                                TRANSLATE={TRANSLATE}

                            /> */}

                        </div>



                    </div>

                </Grid>

            </Grid>
        </>
    )


};


// const StoreBackup = () => {

//     return (
//         <>
//             <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>
//                 <h1 style={{fontSize: 60, fontWeight: 100}}>{TRANSLATE.noleggio}  <span style={{fontWeight: 'bold'}}>#{order_id ? order_id.toUpperCase() : ''}</span></h1>
//             </div>

//             {/* <div style={{whiteSpace: 'break-spaces'}}>
//                  {JSON.stringify(ORDER, null, 2)}
//             </div> */}


//             <div className='row'>

//                 <div className='col-xl-4'>
//                     <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>

//                         <div className="top_checkout_card">

//                                 <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
//                                     STATUS
//                                 </div>
//                                 <div style={{display: 'flex', flexDirection: 'column', marginBottom: 20, alignItems: 'flex-start'}}>
//                                     <Order_Status status={order_status} type={'orders'} TRANSLATE={TRANSLATE} />
//                                 </div>

//                                 <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
//                                     {TRANSLATE.order_created}
//                                 </div>
//                                 <div style={{fontSize: 14, marginBottom: 20}}>
//                                     {order_created}
//                                 </div>

//                                 {(order_updated !== order_created) && (
//                                     <>
//                                     <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
//                                         {TRANSLATE.order_updated}
//                                     </div>
//                                     <div style={{fontSize: 14, marginBottom: 20}}>
//                                         {order_updated}
//                                     </div>
//                                     </>
//                                 )}


//                         </div>

//                         <div className="top_checkout_card">

//                                 <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
//                                 {TRANSLATE.provider_name}
//                                 </div>

//                                 <div style={{display: 'flex', alignItems: 'center', fontSize: 14, marginBottom: 20, cursor: 'pointer'}} onClick={()=> history.push('/admin/stores/profile/'+store_id)}>
//                                     <img src={store_img} style={{width: 35, height: 35, borderRadius: 50, border: 'solid 1px #00000019',  marginRight: 5, objectFit: 'cover'}} />
//                                     {store_name}
//                                 </div>

//                                 <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5, marginTop: 20}}>
//                                 {TRANSLATE.customer_name}
//                                 </div>

//                                 <div style={{display: 'flex', alignItems: 'center', fontSize: 14, cursor: 'pointer'}} onClick={()=> history.push('/admin/users/profile/'+customer_id)}>
//                                     {customer_img ? (
//                                         <img src={customer_img} style={{width: 35, height: 35, borderRadius: 50, border: 'solid 1px #00000019', marginRight: 5, objectFit: 'cover'}} />
//                                     ) : (
//                                         <div className="d-flex align-items-center justify-content-center"style={{background: '#ccc', width: 35, height: 35, borderRadius: 50, border: 'solid 1px #00000019', marginRight: 5, objectFit: 'cover'}}>
//                                          <PersonOutline color={'#ffffff'}  />
//                                         </div>
//                                     )}

//                                     {customer_name}
//                                 </div>

//                         </div>


//                     </div>
//                 </div>

//                 <div className='col-xl-4'>
//                     <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>
//                         <div className="top_checkout_card">
//                             <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
//                                 {TRANSLATE.order_products}
//                             </div>

//                             {order_products.map((item, index)=> {

//                                 return (
//                                     <Product_Card product={item} 
//                                                     duration={order_duration} 
//                                                     onCalculateDailyPrice={onCalculateDailyPrice} 
//                                                     onCalculateSingleSubtotal={onCalculateSingleSubtotal} 
//                                                     key={index}
//                                                     TRANSLATE={TRANSLATE}
//                                                     currency={order_currency}
//                                     />
//                                 )

//                             })}

//                         </div>
//                     </div>

//                     {(order_phone || order_coupon || order_message) && (
//                         <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>

//                             {order_phone && (
//                                 <div className="top_checkout_card">
//                                     <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10 }}>
//                                         CUSTOMER PHONE 
//                                     </div>

//                                     <Chip label={'+'+order_phone.replace('+', '')} />
//                                 </div>
//                             )}

//                             {order_coupon && (
//                                 <div className="top_checkout_card" style={{marginTop: 20}}>
//                                     <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10 }}>
//                                         COUPON 
//                                     </div>

//                                     <Chip label={order_coupon} />
//                                 </div>
//                             )}

//                             {order_message && (
//                                 <div className="top_checkout_card" style={{marginTop: 20}}>
//                                     <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10 }}>
//                                         {TRANSLATE.additional_info}
//                                     </div>

//                                     <p>{order_message}</p>
//                                 </div>
//                             )}

//                             {order_reason && (
//                                 <div className="top_checkout_card" style={{marginTop: 20}}>
//                                     <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10 }}>
//                                         Reason of {order_status}
//                                     </div>

//                                     <p>{order_reason}</p>
//                                 </div>
//                             )}

//                         </div>
//                     )}

//                     <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>

//                         <div className="top_checkout_card">
//                             <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' }}>
//                             {TRANSLATE.rental_delivery}
//                             </div>

//                             {(order_delivery && order_store) && (
//                                 <Delivery_Card order_delivery={order_delivery} order_store={order_store} TRANSLATE={TRANSLATE} />
//                             )}
//                         </div>

//                     </div>
//                     <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>


//                         <div className="top_checkout_card">
//                             <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' }}>
//                             {TRANSLATE.rental_return}
//                             </div>

//                             {(order_return && order_store) && (
//                                 <Return_Card order_return={order_return} order_store={order_store} TRANSLATE={TRANSLATE} />
//                             )}
//                         </div>


//                     </div>
//                 </div>

//                 <div className='col-xl-4'>
//                     <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>

//                         <div className="top_checkout_card">
//                             <div style={{ fontSize: 16, fontWeight: 'bold' }}>
//                             {TRANSLATE.order_period}
//                             </div>
//                             <div style={{ fontSize: 15, marginTop: 10 }}>
//                             {moment(order_checkin).format('DD MMMM YYYY')} → {moment(order_checkout).format('DD MMMM YYYY')}
//                             </div>

//                         </div>

//                         <div className="top_checkout_card" style={{marginTop: 20}}>
//                             <div style={{ fontSize: 16, fontWeight: 'bold' }}>
//                             EXACT DATES
//                             </div>
//                             <div style={{ fontSize: 15, marginTop: 10 }}>
//                                 {orderPeriod}
//                             </div>

//                         </div>

//                         <div className="top_checkout_card" style={{marginTop: 20}}>
//                             <div style={{ fontSize: 16, fontWeight: 'bold'}}>
//                             {TRANSLATE.order_duration}
//                             </div>

//                             <div style={{ fontSize: 15 , marginTop: 10}}>
//                                 {orderDuration} {TRANSLATE.days}
//                             </div> 
//                         </div>

//                         <div className="top_checkout_card" style={{marginTop: 20}}>
//                             <div style={{ fontSize: 16, fontWeight: 'bold'}}>
//                             To Provider
//                             </div>

//                             <StarReview rate={order_review_pro.rank} size={20} onClick={()=> onSelectReview(order_review_pro.id)} />
//                         </div>

//                         <div className="top_checkout_card" style={{marginTop: 20}}>
//                             <div style={{ fontSize: 16, fontWeight: 'bold'}}>
//                             To Customer
//                             </div>

//                             <StarReview rate={order_review_cus.rank} size={20} onClick={()=> onSelectReview(order_review_cus.id)} />
//                         </div>



//                     </div>
//                     <div className="top_checkout_right" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}> 
//                         <div className="top_checkout_card">

//                             <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase'}}>
//                                 {TRANSLATE.rental_cost_details}
//                             </div>

//                             <Costs_Card SUBTOTAL={cost_subtotal} 
//                                         DELIVERY={cost_delivery} 
//                                         RETURN={cost_return}
//                                         FEE={cost_fee}
//                                         DISCOUNT={cost_discount}
//                                         TOTAL={cost_total}
//                                         TOTAL_PROVIDER={cost_total_provider}
//                                         TRANSLATE={TRANSLATE}
//                                         currency={order_currency}

//                             />

//                             {/* <Costs_Card SUBTOTAL={cost_subtotal} 
//                                         DELIVERY={cost_delivery} 
//                                         RETURN={cost_return}
//                                         FEE={cost_fee}
//                                         P_FEE={cost_fee_provider}
//                                         TOTAL={cost_total}
//                                         P_TOTAL={cost_total_provider}
//                                         TRANSLATE={TRANSLATE}

//                             /> */}

//                             {/* <Actions_Card status={order_status} 
//                                         order_id={order_id} 
//                                         onUpdateStatus={onUpdateStatus} 
//                                         onRankProvider={onRankProvider}
//                                         history={history} 
//                                         customer_id={customer_id}
//                                         TRANSLATE={TRANSLATE}

//                             /> */}

//                         </div>
//                     </div>
//                 </div>

//             </div>
//         </> 
//     )
// }



const ProductCard = (props) => {

    const { product, duration, onCalculateSingleSubtotal, LANGUAGE, TRANSLATE, history, rentalperiod, currency } = props;

    var { name, img, id } = product;
    var { quantity, category } = product;

    var total_price = onCalculateSingleSubtotal(product, duration);

    const onSelectProduct = () => {
        history.push('/' + LANGUAGE + '/' + onFormatUrl(name) + '/bp/' + id);
    }

    return (
        <div className="flex px-4 pt-4 items-center" onClick={() => onSelectProduct()}>
            <img className="w-[50px] h-[50px] sm:w-[100px] shrink-0 mr-6 sm:h-[100px] border border-zinc-200 rounded-lg object-contain" src={img} alt='' />
            <div className="w-full">
                <div className="font-bold mb-1">
                    {name}
                </div>

                {category === 'consumable' ? (
                    <div className=" border-b-zinc-200 flex flex-col sm:flex-row gap-x-4 sm:items-center">
                        <div className="font-semibold text-sm">
                            {numberWithCommas(total_price, currency)}
                        </div>
                        <div className="w-[4px] h-[4px] rounded-full bg-black hidden sm:block" />
                        <div className="text-sm capitalize">
                            {TRANSLATE.wd_quantity}: {quantity}
                        </div>
                    </div>
                ) : (
                    <div className=" border-b-zinc-200 flex flex-col sm:flex-row gap-x-4 sm:items-center">
                        <div className="font-semibold text-sm">
                            {numberWithCommas(total_price, currency)}
                        </div>
                        <div className="w-[4px] h-[4px] rounded-full bg-black hidden sm:block" />
                        <div className="text-sm capitalize">
                            {TRANSLATE.wd_quantity}: {quantity}
                        </div>
                        <div className="w-[4px] h-[4px] rounded-full bg-black hidden sm:block" />
                        <div className="text-sm">
                            {duration == 1 ? '1 ' + TRANSLATE.wd_day : duration + ' ' + TRANSLATE.wd_days}
                        </div>
                        <div className="w-[4px] h-[4px] rounded-full bg-black hidden sm:block" />
                        <div className="text-sm">
                            {rentalperiod}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )

}

// const ProviderActions = (props) => {

//     const { status, store, customer, order, LANGUAGE, TRANSLATE, history, onAcceptOrder, onRejectOrder, onUpdateStatus } = props;


//     if (status === 'pending') {

//         return (
//             <div className="p-4 border-t border-b-zinc-200 flex justify-between text-sm bg-zinc-100 md:flex-nowrap flex-wrap">
//                 <div className="flex items-center gap-x-6 md:order-1 order-2 justify-center w-full md:w-auto">
//                     <a className='md:w-auto w-full text-center block' onClick={() => history.goBack()}>
//                         {TRANSLATE.btn_back}
//                     </a>
//                     <a className="text-red-500 md:w-auto w-full text-center block capitalize cursor-pointer" onClick={() => onRejectOrder()}>
//                         {TRANSLATE.btn_decline}
//                     </a>
//                 </div>
//                 <div className="flex items-center gap-x-6 md:w-auto w-full md:flex-nowrap flex-wrap justify-center md:order-2 order-1">
//                     <a className='order-2 md:order-2 md:my-0 my-4' onClick={() => history.push('/' + LANGUAGE + '/dashboard/inbox/' + store + '/' + customer)}>
//                         {TRANSLATE.btn_contact_customer}
//                     </a>
//                     <button className="btn-provider md:w-auto w-full md:order-2 order-1 text-center justify-center" onClick={() => onAcceptOrder()}>
//                         {TRANSLATE.btn_accepted_order}
//                     </button>
//                 </div>
//             </div>
//         )

//     }

//     if (status === 'accepted') {
//         return (
//             <div className="p-4 border-t border-b-zinc-200 flex justify-between text-sm bg-zinc-100 md:flex-nowrap flex-wrap">
//                 <div className="flex items-center gap-x-6 md:order-1 order-2 justify-center w-full md:w-auto">
//                     <a className='md:w-auto w-full text-center block' onClick={() => history.goBack()}>
//                         {TRANSLATE.btn_back}
//                     </a>
//                 </div>
//                 <div className="flex items-center gap-x-6 md:w-auto w-full md:flex-nowrap flex-wrap justify-center md:order-2 order-1">
//                     <Link className='order-2 md:order-2 md:my-0 my-4' to={'/' + LANGUAGE + '/dashboard/inbox/' + store + '/' + customer}>
//                         {TRANSLATE.btn_contact_customer}
//                     </Link>
//                     <button className="btn-auto bg-blue-500 md:w-auto w-full md:order-2 order-1 text-center justify-center" onClick={() => onUpdateStatus('delivering')}>
//                         {TRANSLATE.btn_delivering_order}
//                     </button>
//                 </div>
//             </div>
//         )
//     }

//     if (status === 'delivering') {
//         return (
//             <div className="p-4 border-t border-b-zinc-200 flex justify-between text-sm bg-zinc-100 md:flex-nowrap flex-wrap">
//                 <div className="flex items-center gap-x-6 md:order-1 order-2 justify-center w-full md:w-auto">
//                     <a className='md:w-auto w-full text-center block' onClick={() => history.goBack()}>
//                         {TRANSLATE.btn_back}
//                     </a>
//                 </div>
//                 <div className="flex items-center gap-x-6 md:w-auto w-full md:flex-nowrap flex-wrap justify-center md:order-2 order-1">
//                     <Link className='order-2 md:order-2 md:my-0 my-4' to={'/' + LANGUAGE + '/dashboard/inbox/' + store + '/' + customer}>
//                         {TRANSLATE.btn_contact_customer}
//                     </Link>
//                     <button className="btn-auto bg-orange-500 md:w-auto w-full md:order-2 order-1 text-center justify-center" onClick={() => onUpdateStatus('delivered')}>
//                         {TRANSLATE.btn_delivered_order}
//                     </button>
//                 </div>
//             </div>
//         )
//     }

//     if (status === 'delivered') {
//         return (
//             <div className="p-4 border-t border-b-zinc-200 flex justify-between text-sm bg-zinc-100 md:flex-nowrap flex-wrap">
//                 <div className="flex items-center gap-x-6 md:order-1 order-2 justify-center w-full md:w-auto">
//                     <a className='md:w-auto w-full text-center block' onClick={() => history.goBack()}>
//                         {TRANSLATE.btn_back}
//                     </a>
//                 </div>
//                 <div className="flex items-center gap-x-6 md:w-auto w-full md:flex-nowrap flex-wrap justify-center md:order-2 order-1">
//                     <Link className='order-2 md:order-2 md:my-0 my-4' to={'/' + LANGUAGE + '/dashboard/inbox/' + store + '/' + customer}>
//                         {TRANSLATE.btn_contact_customer}
//                     </Link>
//                     <button className="btn-auto bg-green-700 md:w-auto w-full md:order-2 order-1 text-center justify-center" onClick={() => onUpdateStatus('complete')}>
//                         {TRANSLATE.btn_completed_order}
//                     </button>
//                 </div>
//             </div>
//         )
//     }

//     if (status === 'complete') {
//         return (
//             <div className="p-4 border-t border-b-zinc-200 flex justify-between text-sm bg-zinc-100 md:flex-nowrap flex-wrap">
//                 <div className="flex items-center gap-x-6 md:order-1 order-2 justify-center w-full md:w-auto">
//                     <a className='md:w-auto w-full text-center block' onClick={() => history.goBack()}>
//                         {TRANSLATE.btn_back}
//                     </a>
//                 </div>
//                 <div className="flex items-center gap-x-6 md:w-auto w-full md:flex-nowrap flex-wrap justify-center md:order-2 order-1">
//                     <Link className='order-2 md:order-2 md:my-0 my-4' to={'/' + LANGUAGE + '/dashboard/inbox/' + store + '/' + customer}>
//                         {TRANSLATE.btn_contact_customer}
//                     </Link>
//                     <button className="btn-auto bg-bagreen-100 md:w-auto w-full md:order-2 order-1 text-center justify-center" onClick={() => history.push('/' + LANGUAGE + '/dashboard/reviews/' + order)}>
//                         {TRANSLATE.btn_leave_review}
//                     </button>
//                 </div>
//             </div>
//         )
//     }

//     if (status === 'rejected' || status === 'canceled' || status === 'refund') {
//         return (
//             <div className="p-4 border-t border-b-zinc-200 flex justify-between text-sm bg-zinc-100">
//                 <div className="flex items-center gap-x-6">
//                     <a onClick={() => history.goBack()}>
//                         {TRANSLATE.btn_back}
//                     </a>
//                 </div>
//                 <div className="flex items-center gap-x-6">
//                     <button className="btn-auto bg-bagreen-100" onClick={() => history.push('/' + LANGUAGE + '/dashboard/inbox/' + store + '/' + customer)}>
//                         {TRANSLATE.btn_contact_customer}
//                     </button>
//                 </div>
//             </div>
//         )
//     }

// }

const OrderStatus = ({ status, TRANSLATE, type }) => {

    //let BasicClass = 'px-4 py-2 ml-auto rounded-full text-white text-xs absolute md:relative md:top-0 md:right-0 top-3 right-3 capitalize'

    let BasicClass = 'px-4 py-2 ml-auto rounded-full text-white text-xs capitalize';

    if (type === 'center') {
        BasicClass = 'px-4 py-2 my-6 rounded-full text-white text-xs capitalize';
    }

    if (status === 'pending') {
        return (
            <div className={"bg-yellow-500 " + BasicClass}>
                {TRANSLATE.wd_pending}
            </div>
        )
    }
    else if (status === 'accepted') {
        return (
            <div className={"bg-green-500 " + BasicClass}>
                {TRANSLATE.wd_accepted}
            </div>
        )
    }
    else if (status === 'rejected') {
        return (
            <div className={"bg-red-500 " + BasicClass}>
                {TRANSLATE.wd_rejected}
            </div>
        )
    }
    else if (status === 'delivering') {
        return (
            <div className={"bg-blue-500 " + BasicClass}>
                {TRANSLATE.wd_delivering}
            </div>
        )
    }
    else if (status === 'canceled') {
        return (
            <div className={"bg-red-500 " + BasicClass}>
                {TRANSLATE.wd_canceled}
            </div>
        )
    }
    else if (status === 'delivered') {
        return (
            <div className={"bg-orange-500 " + BasicClass}>
                {TRANSLATE.wd_delivered}
            </div>
        )
    }
    else if (status === 'complete') {
        return (
            <div className={"bg-green-700 " + BasicClass}>
                {TRANSLATE.wd_completed}
            </div>
        )
    }
    else {
        return (
            <div className={"bg-black-500 " + BasicClass}>
                {TRANSLATE.wd_completed}
            </div>
        )
    }
}

const PaymentStatus = ({ status }) => {

    //let BasicClass = 'px-4 py-2 ml-auto rounded-full text-white text-xs absolute md:relative md:top-0 md:right-0 top-3 right-3 capitalize'

    let BasicClass = 'px-4 py-2 ml-auto rounded-full text-white text-xs capitalize';


    if (status) {
        return (
            <div className={"bg-green-700 " + BasicClass}>
                Captured
            </div>
        )

    }
    else {
        return (
            <div className={"bg-yellow-500 " + BasicClass}>
                Uncaptured
            </div>
        )

    }


}

const RefundStatus = (props) => {

    const {
        created,
        updated,
        checkin,
        status,
        refunded,
        captured
    } = props;


    let BasicClass = 'px-4 py-2 ml-auto rounded-full text-white text-xs capitalize';

    let FinalStatus = '';

    let Updated = moment(updated);
    let Created = moment(created);
    let Checkin = moment(checkin, 'DD-MM-YYYY');

    let FromCreationToCancellation = Updated.diff(Created, 'hours');
    let FromCreationToCheckin = Checkin.diff(Created, 'hours');

    let FromCheckinToCancellation = Checkin.diff(Updated, 'hours');

    // return(
    //     <div style={{whiteSpace: 'break-spaces'}}>
    //         {JSON.stringify({
    //             CR_TO_CANC: FromCreationToCancellation,
    //             CR_TO_CHECK: FromCreationToCheckin,
    //             CH_TO_CANC: FromCheckinToCancellation,
    //             LAST_MINUTE: (FromCreationToCheckin <= 48),
    //             LATE_CANC: (FromCheckinToCancellation <= 48)

    //         }, null, 2)}
    //     </div>
    // )

    //LOGIC
    if (refunded) {
        FinalStatus = 'refunded'
    }
    else {

        if (status === 'canceled') {

            FinalStatus = 'need_full_refund';

            if (captured) {
                FinalStatus = 'need_partial_refund'
                //Check if order is not last minute
                if (FromCreationToCheckin <= 48) {
                    //THIS IS LAST MINUTE
                    FinalStatus = 'no_need_refund'
                }
                else {
                    //Check if Cancellation has been made 48h before checkin starts
                    if (FromCheckinToCancellation <= 48) {
                        //THIS IS CANCELED LATE
                        FinalStatus = 'no_need_refund'
                    }
                    else {
                        //ONLY BABONBO FEE
                        FinalStatus = 'need_partial_refund'
                    }

                }
            }
            else {
                FinalStatus = 'need_full_refund'
            }
        }

    }



    //RENDER
    if (FinalStatus === 'need_partial_refund') {
        return (
            <div className={"bg-yellow-500 " + BasicClass}>
                Only babonbo fee
            </div>
        )
    }
    else if (FinalStatus === 'need_full_refund') {
        return (
            <div className={"bg-yellow-500 " + BasicClass}>
                Full Refund
            </div>
        )
    }
    else if (FinalStatus === 'refunded') {
        return (
            <div className={"bg-red-500 " + BasicClass}>
                Refunded
            </div>
        )
    }
    else if (FinalStatus === 'no_need_refund') {
        return (
            <div className={"bg-green-700 " + BasicClass}>
                No need refund
            </div>
        )
    }
    else {
        return (
            <div className={"bg-black " + BasicClass}>
                No refund
            </div>
        )
    }


}


const DeliveryName = (props) => {

    const { delivery, status, address, TRANSLATE } = props;

    if (delivery.type === 'home') {

        if (status === 'accepted' || status === 'delivering' || status === 'delivered' || status === 'complete') {
            return address
        }

        return TRANSLATE.wd_provider_place || '';
    }

    if (delivery.input) {
        return delivery.input
    }

    return delivery.name || ''
}



export default AdminOrdersDetails
