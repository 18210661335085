import React, { useEffect, useState } from 'react';

import ReactCountryFlag from 'react-country-flag';
import {
  AirplaneOutline,
  AnalyticsOutline,
  AppsOutline,
  BarcodeOutline,
  BodyOutline,
  CalendarOutline,
  CashOutline,
  ChatbubbleEllipsesOutline,
  CheckmarkCircleSharp,
  CopyOutline,
  GiftOutline,
  GlobeOutline,
  HandLeftOutline,
  LocateOutline,
  LocationOutline,
  LogInOutline,
  LogOutOutline,
  LogoAmazon,
  MapOutline,
  NotificationsOutline,
  PencilOutline,
  ReaderOutline,
  SchoolOutline,
  ShieldCheckmarkOutline,
  ShirtOutline,
  StarOutline,
  StorefrontOutline,
  UmbrellaOutline,
  WalletOutline,
  WarningSharp,
} from 'react-ionicons';

import { Link } from 'react-router-dom';

import swal from 'sweetalert';

const AdminRoot = (props) => {
  const { USER, TRANSLATE, history } = props;

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    console.log('USER ===> ', USER);

    setLoading(false);

    if (USER.admin) {
    } else {
      if (USER.partner) {
        history.push('/partners/' + USER.partner);
      } else {
        setLoading(false);
        swal("You're not Authorized", 'You are not authorized to be here', 'error');

        history.push('/logout');
      }
    }
  }, []);

  if (isLoading) {
    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
      </div>
    );
  }

  return (
    <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10'>
      {USER.admin ? (
        <>
          <div className='hidden md:block flex px-xl-5 px-2 mx-2 mb-8 items-center justify-center'>
            <div style={{ fontSize: 60, fontWeight: 100, width: '100%', textAlign: 'center' }}>
              Welcome Back <span style={{ fontWeight: 'bold' }}>{USER.name}</span>
            </div>
          </div>
          <div className='block md:hidden flex px-xl-5 px-2 mx-2 mb-8 items-center justify-center'>
            <div style={{ fontSize: 20, fontWeight: 100, width: '100%', textAlign: 'center' }}>
              Welcome Back<br></br>
              <span style={{ fontWeight: 'bold', fontSize: 30 }}>{USER.name}</span>
            </div>
          </div>
          <MenuAdmin {...props} />
        </>
      ) : (
        <>
          {USER.partner ? (
            <>
              <div className='row px-xl-5 px-2 mx-2 mb-8 justify-content-center'>
                <h1 style={{ fontSize: 60, fontWeight: 100 }}>
                  Welcome Back <span style={{ fontWeight: 'bold' }}>{USER.name}</span>
                </h1>
              </div>
              <MenuPartner {...props} />
            </>
          ) : (
            <>{/* <h1>Not Authorized</h1> */}</>
          )}
        </>
      )}
    </div>
  );
};

const MenuAdmin = (props) => {
  const { TRANSLATE, USER } = props;

  let MenuClass = 'col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6 mb-4';
  let MenuStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    fontSize: 18,
    fontWeight: 'bold',
    width: '100%',
    background: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
  };

  return (
    <div className='row m-0 p-0 px-xl-5 justify-content-center' style={{ width: '100%' }}>
      {/* <div className={MenuClass}>
                <Link to="/admin/dashboard" className='bg-light' style={MenuStyle}>
                    <EyeOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
                    Dashboard
                </Link>
            </div> */}

      <div className={MenuClass}>
        <Link to='/admin/users' className='bg-light' style={MenuStyle}>
          <BodyOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Users
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/stores' className='bg-light' style={MenuStyle}>
          <StorefrontOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Stores
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/orders' className='bg-light' style={MenuStyle}>
          <ReaderOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Orders
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/products' className='bg-light' style={MenuStyle}>
          <ShirtOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Products
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/reviews' className='bg-light' style={MenuStyle}>
          <StarOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Reviews
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/analytics/general' className='bg-light' style={MenuStyle}>
          <AnalyticsOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Analytics
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/partners' className='bg-light' style={MenuStyle}>
          <HandLeftOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Partners
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/locations' className='bg-light' style={MenuStyle}>
          <LocationOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Locations
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/geopoints' className='bg-light' style={MenuStyle}>
          <GlobeOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Geopoints
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/geopoints/0?type=airport' className='bg-light' style={MenuStyle}>
          <AirplaneOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Airports
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/map' className='bg-light' style={MenuStyle}>
          <MapOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Map
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/inbox' className='bg-light' style={MenuStyle}>
          <ChatbubbleEllipsesOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Messages
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/approvals' className='bg-light' style={MenuStyle}>
          <ShieldCheckmarkOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Approvals
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/calendary' className='bg-light' style={MenuStyle}>
          <CalendarOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Calendary
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/bank' className='bg-light' style={MenuStyle}>
          <WalletOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Bank
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/coupons' className='bg-light' style={MenuStyle}>
          <BarcodeOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Coupons
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/giftcards' className='bg-light' style={MenuStyle}>
          <GiftOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Gift Cards
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/blog' className='bg-light' style={MenuStyle}>
          <PencilOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Blog
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/resources' className='bg-light' style={MenuStyle}>
          <SchoolOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Resources
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/listing' className='bg-light' style={MenuStyle}>
          <UmbrellaOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Listing
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/library' className='bg-light' style={MenuStyle}>
          <CopyOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Library
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/brands' className='bg-light' style={MenuStyle}>
          <LogoAmazon color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Brands
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/legal' className='bg-light' style={MenuStyle}>
          <CopyOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Legal
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/payments' className='bg-light' style={MenuStyle}>
          <CashOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Payouts
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/notifications' className='bg-light' style={MenuStyle}>
          <NotificationsOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Notifications
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/admin/sitemap' className='bg-light' style={MenuStyle}>
          <LocateOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          Sitemap
        </Link>
      </div>

      <div className={MenuClass}>
        <Link to='/newux' className='bg-light' style={MenuStyle}>
          <AppsOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
          New UX
        </Link>
      </div>

      <div className={MenuClass}>
        {USER.name && (
          <Link to='/logout' className='bg-light' style={MenuStyle}>
            <LogOutOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
            Logout
          </Link>
        )}
      </div>
    </div>
  );
};

const MenuPartner = (props) => {
  const { TRANSLATE, USER } = props;

  let MenuClass =
    'col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
  let MenuStyle = {
    borderRadius: 20,
    fontSize: 24,
    fontWeight: 'bold',
  };

  let partner_id = USER.partner;

  return (
    <>
      {partner_id && (
        <div className='row px-xl-5 px-2 mb-5 justify-content-center'>
          <Link to={'/partners/' + partner_id + '/stores'} className={MenuClass} style={MenuStyle}>
            <StorefrontOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
            Stores
          </Link>

          <Link to={'/partners/' + partner_id + '/products'} className={MenuClass} style={MenuStyle}>
            <ShirtOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
            Products
          </Link>

          <Link to={'/partners/' + partner_id + '/orders'} className={MenuClass} style={MenuStyle}>
            <ReaderOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
            Orders
          </Link>

          <Link to={'/partners/' + partner_id + '/inbox'} className={MenuClass} style={MenuStyle}>
            <ChatbubbleEllipsesOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
            Messages
          </Link>

          <Link to={'/partners/' + partner_id + '/reviews'} className={MenuClass} style={MenuStyle}>
            <StarOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
            Reviews
          </Link>

          {USER.name ? (
            <Link to='/logout' className={MenuClass} style={MenuStyle}>
              <LogOutOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
              Logout
            </Link>
          ) : (
            <Link to='/login' className={MenuClass} style={MenuStyle}>
              <LogInOutline color={'#26BFBF'} width='40px' height='40px' style={{ marginBottom: 5 }} />
              Login
            </Link>
          )}
        </div>
      )}
    </>
  );
};

const Check_Item = ({ Icon, title, status, size, index, onSelectCheckItem, currency }) => {
  if (!size) {
    size = 30;
  }

  return (
    <div className='product_container' onClick={() => onSelectCheckItem(index)}>
      <div className='product_card' style={{ height: 200 }}>
        <div className='product_photo' style={{ height: 80 }}>
          {index == 10 ? (
            <>
              {currency === 'eur' && (
                <h4>
                  € EUR <ReactCountryFlag countryCode={'eu'} />
                </h4>
              )}

              {currency === 'gbp' && (
                <h4>
                  £ GBP <ReactCountryFlag countryCode={'gb'} />
                </h4>
              )}

              {currency === 'usd' && (
                <h4>
                  $ USD <ReactCountryFlag countryCode={'us'} />
                </h4>
              )}
            </>
          ) : (
            <Icon color={'#26BFBF'} style={{ height: 40, width: 40 }} />
          )}
        </div>

        <div className='product_info'>
          <h1>{title}</h1>

          <div className='product_price'>
            {status ? (
              <CheckmarkCircleSharp size={26} color='#228c22' style={{ marginLeft: 'auto' }} />
            ) : (
              <WarningSharp size={26} color='#eed202' style={{ marginLeft: 'auto' }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //     <div style={Styles.listMenuItem} onClick={()=> onSelectCheckItem(index)}>

  //         <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
  //             <Icon color={'#26BFBF'}/>
  //             <h4 style={{marginLeft: 20, fontWeight: '400', fontSize: '15px', color: (status ? '#000' : '#ccc')}}>
  //               {title}
  //             </h4>
  //         </div>

  //         {status ? (
  //             <CheckmarkCircleSharp size={26} color="#228c22" style={{marginLeft: "auto"}} />
  //         ) : (
  //             <WarningSharp size={26} color="#eed202" style={{marginLeft: "auto"}} />
  //         )}

  //     </div>
  // )
};

export default AdminRoot;
