import React, {useEffect, useState} from "react";

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import {Calendar} from 'react-modern-calendar-datepicker';

import { postApiPrivate } from "../../api";

import moment from "moment";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { numberWithCommas } from "../../helpers/GeneralHelper";

const Availability = (props) => {

    const {onClose, USER, product, history, LANGUAGE, TRANSLATE} = props;

    const [isLoading, setLoading] = useState(true);
    const [AVAILABILITY, setAvailability] = useState([]);
    const [RENTALS, setRentals] = useState([]);
    const [TOTAL, setTotal] = useState(0);
    const [FEES, setFees] = useState(0);
    const [EARNED, setEarned] = useState(0);

    useEffect(()=> {
        onFetchAvailability();
    }, []);

    const onFetchAvailability = async() => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const store_id = USER.store || '';
        const product_id = product.id || '';

        if(!product_id){
            return alert('Error Product');
        }

        if(!store_id){
            return alert('Error Store');
        }

        const FORM = { 
          id: store_id,
          pid: product_id
        }

        setLoading(true);
        
        const DATA_RESPONSE = await postApiPrivate('/products/availability', FORM , JWT);
            
        if(DATA_RESPONSE.error){
            console.log("ERROR FROM API LOADING PRODUCTS", DATA_RESPONSE.error);

                setLoading(false);
                
            return 
        }

        const DATA_AVAILABILITY = DATA_RESPONSE.data || [];
        const DATA_RENTALS = DATA_RESPONSE.rentals || [];
        const DATA_TOTAL = DATA_RESPONSE.total || 0;
        const DATA_EARNED = DATA_RESPONSE.earned || 0;
        const DATA_FEES = DATA_RESPONSE.fees || 0;

        const FORMATTED_AVAILABILITY = DATA_AVAILABILITY.map((item) => {
            return onFormatDateToCalendar(item)
        });

        setAvailability(FORMATTED_AVAILABILITY);
        setRentals(DATA_RENTALS);

        setTotal(DATA_TOTAL);
        setEarned(DATA_EARNED);
        setFees(DATA_FEES);
        
        setLoading(false);
    }


    const onFormatDateToString = (DATE) => {
		const year = DATE.year.toString();
		const month = DATE.month.toString().padStart(2, 0);
		const day = DATE.day.toString().padStart(2, 0);
		return `${day}/${month}/${year}`;
	};

    const onFormatDateToCalendar = (DATE) => {

        const newDateMoment = moment(DATE, 'DD/MM/YYYY');

        const newDateObx = {
            day: parseInt(newDateMoment.format('D')),
            month: parseInt(newDateMoment.format('M')),
            year: parseInt(newDateMoment.format('YYYY'))
        }

        return newDateObx;
       
    }

    const onSelectRental = (id) => {
        onClose();
        history.push('/'+LANGUAGE+'/dashboard/orders/'+id);
    }
    

    const MAPPED_AVAILABILITY = AVAILABILITY.map((item) => {
        return onFormatDateToString(item)
    });

    const SORTED_AVAILABILITY = MAPPED_AVAILABILITY.sort(function (a, b) {
        const a_moment = moment(a, 'DD/MM/YYYY');
        const b_moment = moment(b, 'DD/MM/YYYY');
        return a_moment.diff(b_moment)
    });

    const product_name = product.name || '';
    const product_img = product.first_img || '';

    const StoreCurrency = USER.shop_currency || 'eur';

    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 flex-col " style={{zIndex: 1000}}>
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
                <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col fade-in md:slider-in">
                    <div className="flex justify-between p-8 border-b border-zinc-200">
                        <div>
                            <h2 className="capitalize">{TRANSLATE.wd_rental_dates}</h2>
                            <p className="text-sm">{TRANSLATE.tool_product_availability}</p>
                        </div>
                        <img className="mb-auto cursor-pointer" src="/assets/img/close.svg" onClick={()=> onClose()}/>
                    </div>

                    {isLoading ? (
                        <div className="overflow-auto h-full pt-8">
                            <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
                                <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="overflow-auto h-full pt-8">
                                <div className="flex-shrink-0 border border-zinc-200 rounded-lg order-1 md:order-0 drop-shadow-lg bg-white" style={{margin: 30}}>
                                    <div className="flex p-4">
                                        <img className="md:w-[100px] shrink-0 mr-4 md:h-[100px] w-[50px] h-[50px] border border-zinc-200 rounded-lg object-contain bg-white" src={product_img} />
                                        <div className="w-full">
                                            <div className="font-bold mb-1">
                                                {product_name}
                                            </div>
                                            <div className="flex justify-between text-sm mb-2">
                                                {SORTED_AVAILABILITY.length == 1 ? '1 '+TRANSLATE.wd_day : SORTED_AVAILABILITY.length+' '+TRANSLATE.wd_days}
                                                <span className="capitalize">
                                                    {TRANSLATE.wd_rentals}: {RENTALS.length}
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* {USER.admin && (
                                        <>
                                            <h3 className="font-semibold text-md p-4 border-t border-b-zinc-200 flex justify-between">
                                                TOTAL FULL
                                                <span>
                                                    {numberWithCommas(TOTAL, StoreCurrency)}
                                                </span>
                                            </h3>
                                            <h3 className="font-semibold text-md p-4 border-t border-b-zinc-200 flex justify-between">
                                                TOTAL FEES
                                                <span>
                                                    {numberWithCommas(FEES, StoreCurrency)}
                                                </span>
                                            </h3>
                                        </>
                                    )} */}
                                    
                                    <h3 className="font-semibold text-md p-4 border-t border-b-zinc-200 flex justify-between capitalize">
                                        {TRANSLATE.wd_total_earned}
                                        <span>
                                            {numberWithCommas(EARNED, StoreCurrency)}
                                        </span>
                                    </h3>
                                </div>
                                <Calendar
                                    value={AVAILABILITY}
                                    onChange={()=> null}
                                    colorPrimary="#27BFBF"
                                    colorPrimaryLight="#E9F9F9"
                                    calendarClassName="provider-calendar max-w-[320px]"
                                    
                                />
                                
                                {RENTALS.length > 0 && (
                                    <div className="p-10">

                                        <div className="font-semibold mb-3">{TRANSLATE.wd_product_rentals_count.replace('{{TOTAL}}', RENTALS.length)} :</div>

                                        {RENTALS.map((item, index)=> {
                                            const formattedStart = item.start;
                                            const formattedEnd = item.end;
                                            const formattedDuration = item.duration
                                            const formattedId = item.id

                                            return (
                                                <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer bg-white" key={index} onClick={()=> onSelectRental(formattedId)}>
                                                    {formattedStart} - {formattedEnd} <span className="bg-bagreen-100 py-1 px-2 rounded-full text-white text-xs">{formattedDuration} {TRANSLATE.wd_days}</span> 
                                                </div>
                                            )
                                            
                                        })}

                                    </div>
                                )}

                                <div style={{height: 200}}></div>

                                
                            </div>

                            <div className="z-[9999] flex justify-between md:p-8 p-4 border-t border-zinc-200 items-center mt-auto md:relative fixed bottom-0 left-0 w-full bg-white">
                                <div className="cursor-pointer" onClick={()=> onClose()}>
                                    {TRANSLATE.btn_back}
                                </div>
                                <div className="btn-provider" onClick={()=> onFetchAvailability()}>
                                    {TRANSLATE.btn_refresh}
                                </div>
                            </div>

                        </>
                    )}
                    
                    
                    
                </div>
            </div>
        </>
    )
}

export default Availability