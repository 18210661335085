import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { onUpdateCart } from '../../actions';
import { numberWithCommas, onFormatUrl } from '../../helpers/GeneralHelper';
import Stars from './Stars';

import Product from '../General/Product.js';


const DeliveryList = (props) => {

  const { onClose } = props;

  const { TRANSLATE, provider, onAddToCart, PRODUCTS, LANGUAGE, CURRENCY } = props;
  const { onCartProductAdded, onCalculateDailyPrice, routeDuration, onFormatPrices, onSelectProduct} = props;


  //Filter Cities
  const CurrentProvider = provider || {};
  const CurrentDelivery = CurrentProvider.delivery || [];
  const store_delivery_cities = CurrentDelivery.filter((item) => item.type === 'city') || [];
  const store_delivery_points = CurrentDelivery.filter((item) => item.type !== 'city') || [];

  const store_distance = CurrentProvider.store_distance || 0;
  const store_distance_delivery = CurrentProvider.delivery_distance || 0;
  const store_distance_total = CurrentProvider.total_distance || 0;

  const store_name = onFormatUrl(CurrentProvider.name);
  const store_id = CurrentProvider.id;
  const store_rating = CurrentProvider.rating;
  const store_reviews = CurrentProvider.reviews;

  const FinalURL = '/' + LANGUAGE + '/' + store_name + '/bs/' + CurrentProvider.id;

  const store_delivery = CurrentProvider.delivery || [];
  const store_pickup = CurrentProvider.pickup || {};
  const store_dropoff = CurrentProvider.dropoff || {};

  const pickup_cost = store_pickup.cost || 0;
  const dropoff_cost = store_dropoff.cost || 0;

  const delivery_cost = parseFloat(pickup_cost) + parseFloat(dropoff_cost);
  const delivery_from = store_delivery[0] ? parseFloat(store_delivery[0].cost || 0) : 0;

  const final_pickup_cost = pickup_cost ? pickup_cost + (pickup_cost * 15 / 100) : pickup_cost;
  const final_dropoff_cost = dropoff_cost ? dropoff_cost + (dropoff_cost * 15 / 100) : dropoff_cost;

  const final_delivery_cost = delivery_cost ? delivery_cost + (delivery_cost * 15 / 100) : delivery_cost;
  const final_delivery_from = delivery_from ? delivery_from + (delivery_from * 15 / 100) : delivery_from;

  const PRICE_CURRENCY = CURRENCY || 'eur';

  //PRODUCTS
  const filtredProducts = PRODUCTS.filter((item)=> item.owner.id === store_id) || [];


  return (
    <>
      <div className="fixed w-screen h-screen top-0 left-0 z-[500] flex-col ">
        <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
        <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col slider-in">
          <div className="flex justify-between p-8 border-b border-zinc-200 bg-white z-20">
            {/* <h2>Search a City</h2> */}
            <div onClick={() => onClose()}>
              <img className='w-[150px] mr-4' src='/assets/img/babonbo_logo.png' />
            </div>
            <img className="cursor-pointer" src="/assets/img/close.svg" onClick={() => onClose()} />
          </div>

          <div className="w-full pb-20 bg-white rounded-lg text-black z-30 overflow-auto p-8">

            <div className='w-full flex items-center mb-6'>
              <Link className="w-[40px] h-[40px] md:w-[80px] md:h-[80px] mr-3 flex-shrink-0" to={FinalURL}>
                <img className="w-full h-full object-cover rounded-full" src={CurrentProvider.image} />
              </Link>
              <div>
                <div className="text-lg font-bold capitalize">
                  {CurrentProvider.name}
                </div>

                <Stars
                  reviews={store_reviews}
                  rate={store_rating}
                  className={'flex gap-x-1 ml-auto md:relative flex-shrink-0 md:top-auto top-2 flex-wrap md:w-auto w-[100px]'}
                  onView={() => null}
                />
              </div>

            </div>

            <div className="w-full flex gap-x-6 flex-nowrap overflow-auto pb-6 scrollbar-hide">
              {filtredProducts.map((product, idx) => {


                const { name, first_img, owner, distance, reviews, category } = product;

                const price_day = product.price_day || 0;

                const product_owner = owner || {};
                const owner_name = product_owner.name;

                const to_url = '/' + LANGUAGE + '/' + onFormatUrl(name) + '/bp/' + product.id;

                //const price_final = numberWithCommas(price_day, CURRENCY || 'eur');

                const isAdded = onCartProductAdded(product, owner);

                const PRICE_DAILY_FINAL = onCalculateDailyPrice(product, routeDuration) * routeDuration;

                const PriceObx = onFormatPrices({
                  price_day: price_day,
                  price_day_final: PRICE_DAILY_FINAL,
                  duration: routeDuration,
                  category: category,
                  quantity: 1
                });

                const price_final = numberWithCommas(PriceObx.PriceDayFee, PRICE_CURRENCY);
                const price_discount = PriceObx.PriceDiscount > 0;

                const total_days = numberWithCommas(PriceObx.PriceNoDiscount, PRICE_CURRENCY);
                const total_days_discount = numberWithCommas(PriceObx.PriceDiscount, PRICE_CURRENCY);
                const total_days_total = numberWithCommas(PriceObx.PriceTotalFee, PRICE_CURRENCY);


                if (isAdded) {
                  return (
                    <div className="w-1/4 min-w-[220px] shrink-0 relative flex mb-auto min-h-[330px] lg:min-h-[450px] md:min-h-[392px]" key={idx}>
                      <div className="w-[44px] h-[44px] rounded-full bg-bagreen-100 flex items-center justify-center text-white absolute bottom-4 right-4 cursor-pointer hover:bg-babonbo-200">
                        <img src="/assets/img/check-white.svg" /></div>
                      <Product addStyles="border-zinc-200 border w-full"
                        name={name}
                        price={price_final}
                        category={category}

                        price_final={price_final}
                        price_discount={price_discount}
                        total_days={total_days}
                        total_days_discount={total_days_discount}
                        total_days_total={total_days_total}


                        provider={owner_name}
                        img={first_img}
                        url={to_url}
                        isTotal={true}
                        duration={routeDuration}
                        onClick={() => onSelectProduct(product, CurrentProvider)}

                        TRANSLATE={TRANSLATE}

                      />
                    </div>
                  )
                }

                return (
                  <div className="w-1/4 min-w-[220px] shrink-0 relative flex mb-auto md:min-h-[392px] lg:min-h-[450px] min-h-[330px]" key={idx}>

                    <Product addStyles="border-zinc-200 border w-full"
                      name={name}
                      price={price_final}
                      category={category}

                      price_final={price_final}
                      price_discount={price_discount}
                      total_days={total_days}
                      total_days_discount={total_days_discount}
                      total_days_total={total_days_total}

                      provider={owner_name}
                      img={first_img}
                      url={to_url}
                      isTotal={true}
                      duration={routeDuration}
                      onClick={() => onSelectProduct(product, CurrentProvider)}

                      TRANSLATE={TRANSLATE}
                    />

                    <div className="w-[44px] h-[44px] rounded-full bg-babonbo-100 flex items-center justify-center text-white absolute bottom-4 right-4 cursor-pointer hover:bg-babonbo-200" onClick={() => onAddToCart(product, CurrentProvider)}>
                      <img style={{ filter: 'invert(100%)' }} src="/assets/img/cart-plus.svg" />
                    </div>
                  </div>
                )

              })}
            </div>

            {/* <h2>Delivers in cities: </h2>
            <div className='whitespace-breaks'>
              {store_delivery_cities.map((item, index) => {
                const normal_cost = parseFloat(item.cost) || 0;
                const final_cost = normal_cost ? normal_cost + (normal_cost * 15 / 100) : normal_cost;

                let final_name = item.name;

                if (item.name === 'home') {
                  final_name = TRANSLATE.wd_provider_place;
                }

                let delivery_type = item.type;
                let delivery_icon = onDeliveryIcon(delivery_type);

                const pickdist = item.pickdist || 0;
                const dropdist = item.dropdist || 0;

                const isPickInCity = (pickdist > 0 && pickdist <= 10);
                const isDropInCity = (dropdist > 0 && dropdist <= 10);

                return (
                  <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>
                    <div className="flex">
                      <img src={delivery_icon} />
                      {final_name}
                    </div>

                    <div className="flex">
                      <span className={"py-1 px-2 rounded-full text-white text-xs " + (isPickInCity ? 'bg-green-500' : 'bg-red-500')}>
                        {pickdist.toFixed(2)} km
                      </span>
                      <span className={"py-1 px-2 rounded-full text-white text-xs " + (isDropInCity ? 'bg-green-500' : 'bg-red-500')}>
                        {dropdist.toFixed(2)} km
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>

            <h2>Delivers at points: </h2>
            <div className='whitespace-breaks'>
              {store_delivery_points.map((item, index) => {
                const normal_cost = parseFloat(item.cost) || 0;
                const final_cost = normal_cost ? normal_cost + (normal_cost * 15 / 100) : normal_cost;

                let final_name = item.name;

                if (item.name === 'home') {
                  final_name = TRANSLATE.wd_provider_place;
                }

                let delivery_type = item.type;
                let delivery_icon = onDeliveryIcon(delivery_type);

                const pickdist = item.pickdist || 0;
                const dropdist = item.dropdist || 0;

                const isPickInPoint = (pickdist > 0 && pickdist <= 10);
                const isDropInPoint = (dropdist > 0 && dropdist <= 10);

                return (
                  <div className="p-3 border-zinc-100 border rounded-lg mb-2 text-sm flex justify-between items-center cursor-pointer" key={index}>
                    <div className="flex">
                      <img src={delivery_icon} />
                      {final_name}
                    </div>

                    <div className="flex">
                      <span className={"py-1 px-2 rounded-full text-white text-xs " + (isPickInPoint ? 'bg-green-500' : 'bg-red-500')}>
                        {pickdist.toFixed(2)} km
                      </span>
                      <span className={"py-1 px-2 rounded-full text-white text-xs " + (isDropInPoint ? 'bg-green-500' : 'bg-red-500')}>
                        {dropdist.toFixed(2)} km
                      </span>
                    </div>

                  </div>
                )
              })}
            </div> */}


          </div>

        </div>

      </div>
    </>
  )
}

const onDeliveryIcon = (delivery_type) => {

  let delivery_icon = '';

  if (delivery_type === 'airport') {
    delivery_icon = "/assets/img/plane.svg"
  }
  else if (delivery_type === 'station') {
    delivery_icon = "/assets/img/train-station.svg"
  }
  else if (delivery_type === 'port') {
    delivery_icon = "/assets/img/port-icon.svg"
  }
  else if (delivery_type === 'city') {
    delivery_icon = "/assets/img/city-icon.svg"
  }
  else {
    delivery_icon = "/assets/img/location.svg"
  }

  return delivery_icon
}



const mapStateToProps = state => {
  return ({
    USER: state.user,
    CART: state.cart
  });
}

const mapDispatchToProps = dispatch => ({
  onUpdateCart: (cart) => dispatch(onUpdateCart(cart))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryList);
