import React, { useEffect, useState } from "react";

import LoaderMaster from "../Loaders/Loaders";

import { postApiPrivate } from "../../api";

import { CloseSharp, SaveSharp } from "react-ionicons";

import swal from "sweetalert";

import { Chip, Container, Grid, TextField } from "@mui/material";
import { Link } from "react-router-dom";

import "../Profile/z_profile.css";
import moment from "moment";
import DateSelector from "../General/DateSelector";

const AdminGiftCardsAdd = (props) => {

  const { TRANSLATE, history, LANGUAGE } = props;

  const [isSaving, setSaving] = useState(false);
  const [isCalendar, setCalendar] = useState(false);
  const [PUBLIC_GIFTCARD, setGiftCard] = useState({
    currency: 'usd'
  });


  const cardList = [
    {
      type: 'merry-christmas',
      img: 'https://beta.babonbo.com/assets/gift/1.png'
    },
    {
      type: 'happy-easter',
      img: 'https://beta.babonbo.com/assets/gift/2.png'
    },
    {
      type: 'happy-monthers-day',
      img: 'https://beta.babonbo.com/assets/gift/3.png'
    },
    {
      type: 'enjoy-your-travel',
      img: 'https://beta.babonbo.com/assets/gift/4.png'
    },
    {
      type: 'fathers-day',
      img: 'https://beta.babonbo.com/assets/gift/5.png'
    },
    {
      type: 'happy-birthday',
      img: 'https://beta.babonbo.com/assets/gift/6.png'
    },
    {
      type: 'happy-halloween',
      img: 'https://beta.babonbo.com/assets/gift/7.png'
    }

  ]

  const amountList = [25, 50, 75, 100, 150, 200, 250];


  useEffect(() => {

    if (isCalendar) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isCalendar])

  const onUpdateGiftCard = async (key, value) => {

    if(key === 'design'){
      
      let GiftFound = cardList.find((item)=> item.type === value);
      if(GiftFound){
       // alert('here = '+GiftFound.img)
        setGiftCard({
          ...PUBLIC_GIFTCARD,
          type: value,
          design: GiftFound.img 
        });
        return
      }
    }
    setGiftCard({
      ...PUBLIC_GIFTCARD,
      [key]: value
    });

  }

  const onSaveGiftCard = async () => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      var FORM = {
        ...PUBLIC_GIFTCARD
      };

     // alert(JSON.stringify(FORM, null, 2))

      console.log("CREATR GIFTCARD ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/giftcards/create', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Create This GiftCard', 'error');

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("CREATED GIFTCARD ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('GiftCard Created', 'This GiftCard has been created successfully !!!', 'success');


      const giftcard_id = DATA_RESPONSE.id;
      history.push('/admin/giftcards/profile/' + giftcard_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Created This GiftCard', 'error');

    }

  }

  if (isSaving) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving} />
    )

  }



  const {
    fullname,
    email,
    recipient_fullname,
    recipient_email,
    design,
    amount,
    date,
    type,
    message
  } = PUBLIC_GIFTCARD

  const CAN_SAVE = (
    fullname && fullname.length > 0 &&
    email && email.length > 0 &&
    recipient_fullname && recipient_fullname.length > 0 &&
    recipient_email && recipient_email.length > 0 &&
    design && design.length > 0 &&
    amount && amount > 0 &&
    date && date.length > 0
  )

  return (
    <>
      <Container maxWidth="lg" style={{ background: '#f0f5f9' }}>

        <Grid container spacing={2}>

          <Grid item xs={12} lg={9} md={9}>

            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

              <div className="top_box_magic" style={{ marginBottom: 20 }}>
                <h1>
                  Status
                </h1>

                <Chip label="Pending" style={{ maxWidth: 100, marginTop: 10 }} variant="filled" color="warning" />
              </div>

              <div className="top_box_magic">
                <h1 className="d-flex align-items-center justify-content-between">
                  Choose a Design
                </h1>
                <div className="w-full flex flex-wrap justify-center gap-2">
                  {cardList.map((item, index) => {
                    return (
                      <img src={item.img}
                        key={index}
                        onClick={() => onUpdateGiftCard('design', item.type)}
                        className={"cursor-pointer h-[100px] border-1 rounded-lg " + (type === item.type ? 'border-babonbo-100 border-2' : '')}
                        alt={item.type}
                      />
                    )
                  })}
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Choose an E-Gift Card Amount
                </h1>
                <div className='w-full flex flex-wrap justify-end gap-2'>
                  {amountList.map((item, index) => (
                    <button
                      key={index}
                      name='amount'
                      type='button'
                      className={`text-black rounded-lg max-w-56 min-w-fit py-2 px-4 bg-zinc-100 text-center ${item === amount ? 'border-2 border-babonbo-100 drop-shadow-sm' : 'hover:drop-shadow-lg cursor-pointer'
                        }`}
                      onClick={() => onUpdateGiftCard('amount', item)}
                    >
                      ${item}
                    </button>
                  ))}
                </div>
                <div className="flex items-center justify-between" style={{ margin: '10px 0px' }}>
                  <div className="mr-2">$</div>
                  <TextField
                    className={'gridyx-textfield'}

                    InputLabelProps={{ shrink: false }}
                    value={amount}
                    onChange={(e) => onUpdateGiftCard('amount', e.target.value)}
                    type="number"

                  />
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Who's the sender ?
                </h1>

                <div className="flex items-center justify-between gap-x-2" style={{ margin: '10px 0px' }}>
                  <div className="w-full">
                    <div className="mr-2">Name</div>
                    <TextField
                      className={'gridyx-textfield'}
                      InputLabelProps={{ shrink: false }}
                      value={fullname}
                      onChange={(e) => onUpdateGiftCard('fullname', e.target.value)}
                      type="text"

                    />
                  </div>
                  <div className="w-full">
                    <div className="mr-2">Email</div>
                    <TextField
                      className={'gridyx-textfield'}
                      InputLabelProps={{ shrink: false }}
                      value={email}
                      onChange={(e) => onUpdateGiftCard('email', e.target.value)}
                      type="email"

                    />
                  </div>
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Who's the recipient ?
                </h1>

                <div className="flex items-center justify-between gap-x-2" style={{ margin: '10px 0px' }}>
                  <div className="w-full">
                    <div className="mr-2">Name</div>
                    <TextField
                      className={'gridyx-textfield'}
                      InputLabelProps={{ shrink: false }}
                      value={recipient_fullname}
                      onChange={(e) => onUpdateGiftCard('recipient_fullname', e.target.value)}
                      type="text"

                    />
                  </div>
                  <div className="w-full">
                    <div className="mr-2">Email</div>
                    <TextField
                      className={'gridyx-textfield'}
                      InputLabelProps={{ shrink: false }}
                      value={recipient_email}
                      onChange={(e) => onUpdateGiftCard('recipient_email', e.target.value)}
                      type="email"

                    />
                  </div>
                </div>
              </div>

              <div className="top_box_magic">
                <h1>
                  Customize Delivery
                </h1>

                <div className="flex justify-between gap-x-2" style={{ margin: '10px 0px' }}>

                  <div className="w-full">
                    <div className="mr-2">Delivery Message</div>
                    <textarea
                      className={'gridyx-textfield rounded-md'}
                      InputLabelProps={{ shrink: false }}
                      value={message}
                      onChange={(e) => onUpdateGiftCard('message', e.target.value)}
                      style={{ minHeight: 300, border: 'solid 1px #00000040' }}

                    />
                  </div>

                  <div className="w-full">
                    <div className="mr-2">Delivery Date</div>
                    <TextField
                      className={'gridyx-textfield'}
                      InputLabelProps={{ shrink: false }}
                      value={date}
                      onClick={()=> setCalendar(true)}
                      type="text"
                      

                    />

                    <div className="flex flex-wrap gap-2 mt-4">
                      <button
                        name='amount'
                        type='button'
                        className={`text-black rounded-lg max-w-56 min-w-fit py-2 px-4 bg-zinc-100 text-center ${date === moment().format('DD/MM/YYYY') ? 'border-2 border-babonbo-100 drop-shadow-sm' : 'hover:drop-shadow-lg cursor-pointer'
                          }`}
                        onClick={() => onUpdateGiftCard('date', moment().format('DD/MM/YYYY'))}
                      >
                        Today
                      </button>
                      <button
                        name='amount'
                        type='button'
                        className={`text-black rounded-lg max-w-56 min-w-fit py-2 px-4 bg-zinc-100 text-center ${date === moment().add(1, 'days').format('DD/MM/YYYY') ? 'border-2 border-babonbo-100 drop-shadow-sm' : 'hover:drop-shadow-lg cursor-pointer'
                          }`}
                        onClick={() => onUpdateGiftCard('date', moment().add(1, 'days').format('DD/MM/YYYY'))}
                      >
                        Tomorrow
                      </button>
                      <button
                        name='amount'
                        type='button'
                        className={'text-black rounded-lg max-w-56 min-w-fit py-2 px-4 bg-zinc-100 text-center hover:drop-shadow-lg cursor-pointer'}
                        onClick={() => setCalendar(true)}
                      >
                        Custom
                      </button>
                    </div>

                  </div>
                </div>
              </div>



            </div>

          </Grid>
          <Grid item xs={12} lg={3} md={3}>
            <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 100 }}>


              <div className="top_add_cart"
                style={{ backgroundColor: '#59ad6a' }}
                onClick={() => {

                  if (!CAN_SAVE) {

                    return swal("Incomplete", "Please complete all the fields in order to create this giftcard", 'warning')
                  }

                  swal({
                    title: 'Confirm to Save',
                    text: 'Please insert the CONFIRM to create this giftcard',
                    content: "input",
                  })
                    .then((value) => {

                      if (value === 'CONFIRM') {

                        onSaveGiftCard()
                      }
                      else {

                        swal('Invalid Password', 'You are not authorized to create this giftcard', 'error');
                      }

                    });
                }}

              >
                <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Create GiftCard
              </div>

              <Link className="top_add_cart" style={{ backgroundColor: 'var(--dark)' }} to="/admin/giftcards">
                <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Go Back
              </Link>

              {/* <div className="mt-10">
                <pre>
                  {JSON.stringify(PUBLIC_GIFTCARD, null, 2)}
                </pre>
              </div> */}

            </div>


          </Grid>

        </Grid>


      </Container>

      {isCalendar && (
        <DateSelector {...props}
          onClose={() => setCalendar(false)}
          date={date}
          title={'Select Delivery Date'}
          description={'Choose the delivery date for your gift card from the calendar'}
          extra={'Your Gift card will be delivered on:'}
          onSave={(item) => {
            onUpdateGiftCard('date', item)
            setCalendar(false);
          }}
        />
      )}
    </>


  )


}



export default AdminGiftCardsAdd

