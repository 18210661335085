

//New Ones
export const onUpdateUser = user => ({
  type: 'UPDATE_USER',
  user
});

export const onUpdateSearch = search => ({
  type: 'UPDATE_SEARCH',
  search
});

export const onUpdateProduct = product => ({
  type: 'UPDATE_PRODUCT',
  product
});

export const onUpdateProducts = products => ({
  type: 'UPDATE_PRODUCTS',
  products
});

export const onUpdateCart = cart => ({
  type: 'UPDATE_CART',
  cart
});

export const onUpdateProvider = provider => ({
  type: 'UPDATE_PROVIDER',
  provider
});

export const onUpdateProviders = providers => ({
  type: 'UPDATE_PROVIDERS',
  providers
});


export const onUpdateLanguage = language => ({
  type: 'UPDATE_LANGUAGE',
  language
});

export const onUpdateCurrency = currency => ({
  type: 'UPDATE_CURRENCY',
  currency
});

export const onUpdatePoints = points => ({
  type: 'UPDATE_POINTS',
  points
});

export const onUpdateCount = count => ({
  type: 'UPDATE_COUNT',
  count
});