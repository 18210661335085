import React, { useState } from 'react';
import { Marker, Map, GoogleApiWrapper, InfoWindow, Circle } from 'google-maps-react';

import { getDistanceBetweenCoordinates, numberWithCommas, onFormatUrl } from '../../helpers/GeneralHelper';

import ReactDOM from "react-dom";
import swal from 'sweetalert';


const FlowMap = (props) => {

  const mapStyle = [

    {
      elementType: "labels.text",
      stylers: [
        {
          color: "#938d8d"
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#938d8d"
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "administrative.neighborhood",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "landscape.natural",
      stylers: [
        {
          "visibility": "off"
        }
      ]
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "simplified"
        }
      ]
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.highway",
      stylers: [
        {
          color: "#ffffff"
        },
        {
          weight: 0.5
        }
      ]
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#afd5df"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off"
        }
      ]
    }

  ]

  const { history, loaded, ROUTE, google, TRANSLATE, LANGUAGE, EXACT_RESULTS, OTHER_RESULTS, SearchURL, onSelectStore } = props;

  const [MAP_BOUNDS, setMapBounds] = useState(null);
  const [MAP_MARKER, setMapMarker] = useState({});
  const [MAP_PLACE, setMapPlace] = useState({});
  const [MAP_INFO, setMapInfo] = useState(false);

  //Setup Center
  const CenterCoodinates = { lat: parseFloat(ROUTE.citylat), lng: parseFloat(ROUTE.citylon) };

  //Setup Pickup
  const PickupCoodinates = { lat: parseFloat(ROUTE.picklat), lng: parseFloat(ROUTE.picklon) };

  //Setup Dropoff
  const DropoffCoodinates = { lat: parseFloat(ROUTE.droplat), lng: parseFloat(ROUTE.droplon) };

  //Setup Stores
  const ExactPoints = EXACT_RESULTS.map((item) => {
    return {
      ...item,
      type: 'exact'
    }
  });

  const OtherPoints = OTHER_RESULTS.map((item) => {
    return {
      ...item,
      type: 'other'
    }
  });


  //Setup Cities

  //Setup Points

  if (!loaded) {

    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader.png' />
      </div>
    )

  }


  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
  }

  const onMapBounds = () => {

    var points = [...ExactPoints, ...OtherPoints].map((item) => {
      const geocode = item.geocode || {}
      const lat = geocode.latitude || 0;
      const lng = geocode.longitude || 0;
      return {
        lat,
        lng
      }
    })

    var mapped_points = [...points];

    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < mapped_points.length; i++) {
      bounds.extend(mapped_points[i]);
    }

    if (mapped_points.length > 1) {
      setMapBounds(bounds);
    }
  }

  const onReady = (mapProps, map) => {
    onMapBounds();
    map.setOptions({
      styles: mapStyle
    })
  }

  const onMarkerClick = (props, marker, place) => {
    setMapMarker(marker);
    setMapPlace(place);
    setMapInfo(true);
  }

  const onInfoWindowClose = () => {
    setMapMarker(null);
    setMapInfo(false);
  }

  const onMapClicked = () => {
    if (MAP_INFO) {
      setMapMarker(null);
      setMapInfo(false);
    }
  };

  const onInfoWindowOpen = (props, e) => {

    let ProviderCard = (
      <MarkerItem
        marker={MAP_PLACE}
        pickup={PickupCoodinates}
        dropoff={DropoffCoodinates}
        history={history}
        TRANSLATE={TRANSLATE}
        onCardClicked={onCardClicked}

      />
    )

    let DeliveryCard = (
      <MarkerDelivery
        marker={MAP_PLACE}
        history={history}
        TRANSLATE={TRANSLATE}
        onCardClicked={onCardClicked}

      />
    )


    let FinalCard = (MAP_PLACE.type === 'pickup' || MAP_PLACE.type === 'dropoff' || MAP_PLACE.type === 'pickdrop') ? DeliveryCard : ProviderCard;


    ReactDOM.render(
      React.Children.only(FinalCard),
      document.getElementById("iwc")
    );
  }


  const onCardClicked = (marker) => {

    if (marker.type == 'exact') {
      onSelectStore(marker)
    }
    else if (marker.type == 'other') {
      onSelectStore(marker)
    }
    else if (marker.type === 'station' || marker.type === 'airport') {
      //history.push(SearchURL+'?point='+marker.name)
    }
    else {

      // const store_name = marker.name ? onFormatUrl(marker.name) : '';
      // history.push('/'+LANGUAGE+'/'+store_name+'/bs/'+marker.id);
    }

  }

  const centerMoved = (mapProps, map) => {
    // ...
    // alert("MAP PROPS ===> "+mapProps);
    // swal('Map Props', ''+mapProps, 'success')
  }

  let MARKERS = [
    ...ExactPoints,
    ...OtherPoints
  ]



  return (
    <Map google={google}
      containerStyle={containerStyle}
      initialCenter={CenterCoodinates}
      zoom={12}
      bounds={MAP_BOUNDS}
      onMapClicked={onMapClicked}
      onReady={(mapProps, map) => onReady(mapProps, map)}
      className="nearby_map absolute left-0 top-0 border-0 focus:ring-2 focus:ring-babonbo-100 outline-0"
      onDragend={centerMoved}
      style={{ borderRadius: 20 }}
      

    >

      <Circle
        radius={10 * 1000}
        center={CenterCoodinates}
        onMouseover={() => console.log('mouseover')}
        onMouseout={() => console.log('mouseout')}
        onClick={() => swal({
          title: '10 km radius from ' + ROUTE.cityname + ' center',
          text: 'Available providers offer a delivery service 10 Km radius from the center of ' + ROUTE.cityname + '.',
          buttons: false,
          timer: 5000
        })}
        strokeColor='transparent'
        strokeOpacity={0}
        strokeWeight={5}
        fillColor='#FF0000'
        fillOpacity={0.2}
      />

      {((PickupCoodinates.lat === DropoffCoodinates.lat) && (PickupCoodinates.lng === DropoffCoodinates.lng)) && (
        <Marker
          name={'Pickup & Dropoff Same'}
          position={DropoffCoodinates}
          onClick={(pr, mr) => onMarkerClick(pr, mr, {
            name: ROUTE.pickadd,
            type: 'pickdrop'
          })}
          icon={{
            url: "/assets/img/babonbo_marker_pickdrop.png",
            anchor: new window.google.maps.Point(16, 16),
            scaledSize: new window.google.maps.Size(25, 25)
          }}
        />
      )}

      {!((PickupCoodinates.lat === DropoffCoodinates.lat) && (PickupCoodinates.lng === DropoffCoodinates.lng)) && (
        <Marker
          name={'Pickup'}
          position={PickupCoodinates}
          onClick={(pr, mr) => onMarkerClick(pr, mr, {
            name: ROUTE.pickadd,
            type: 'pickup'
          })}
          icon={{
            url: "/assets/img/babonbo_marker_pickup.png",
            anchor: new window.google.maps.Point(16, 16),
            scaledSize: new window.google.maps.Size(25, 25)
          }}
        />
      )}

      {!((PickupCoodinates.lat === DropoffCoodinates.lat) && (PickupCoodinates.lng === DropoffCoodinates.lng)) && (
        <Marker
          name={'Dropoff'}
          position={DropoffCoodinates}
          onClick={(pr, mr) => onMarkerClick(pr, mr, {
            name: ROUTE.dropadd,
            type: 'dropoff'
          })}
          icon={{
            url: "/assets/img/babonbo_marker_dropoff.png",
            anchor: new window.google.maps.Point(16, 16),
            scaledSize: new window.google.maps.Size(25, 25)
          }}
        />
      )}




      {/* <Marker
                name={'Query City'}
                position={CenterCoodinates}
                onClick={(pr, mr)=> onMarkerSimpleClick(pr, mr)}
                icon={{
                    url: "/assets/img/babonbo_current.png",
                    anchor: new window.google.maps.Point(16,16),
                    scaledSize: new window.google.maps.Size(32,32)
                }}
            /> */}

      {MARKERS.map((marker, index) => {

        const { name, geocode, type } = marker;

        const original_cost = parseFloat(marker.cost) || 0
        const final_cost = original_cost ? original_cost + (original_cost * 15 / 100) : original_cost;

        let geo_cost = final_cost ? numberWithCommas(final_cost, 'eur') : 'FREE';

        geo_cost = geo_cost.toUpperCase();

        if (type === 'airport') {
          return (
            <Marker
              name={name}
              onClick={(pr, mr) => onMarkerClick(pr, mr, marker)}
              position={{ lat: geocode.latitude, lng: geocode.longitude }}
              key={index}
              icon={{
                url: "/assets/img/babonbo_marker_airport.png",
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(20, 20)
              }}
            />
          )
        }
        else if (type === 'station') {
          return (
            <Marker
              name={name}
              onClick={(pr, mr) => onMarkerClick(pr, mr, marker)}
              position={{ lat: geocode.latitude, lng: geocode.longitude }}
              key={index}
              icon={{
                url: "/assets/img/babonbo_marker_station.png",
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(20, 20)
              }}
            />
          )
        }
        else if (type === 'port') {
          return (
            <Marker
              name={name}
              onClick={(pr, mr) => onMarkerClick(pr, mr, marker)}
              position={{ lat: geocode.latitude, lng: geocode.longitude }}
              key={index}
              icon={{
                url: "/assets/img/babonbo_marker_port.png",
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(20, 20)
              }}
            />
          )
        }
        else if (type === 'city') {
          return (
            <Marker
              name={name}
              onClick={(pr, mr) => onMarkerClick(pr, mr, marker)}
              position={{ lat: geocode.latitude, lng: geocode.longitude }}
              key={index}
              icon={{
                url: "/assets/img/babonbo_marker_provider.png",
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(20, 20)
              }}
            />
          )
        }
        else if (type === 'home') {
          return (
            <Marker
              name={name}
              onClick={(pr, mr) => onMarkerClick(pr, mr, marker)}
              position={{ lat: geocode.latitude, lng: geocode.longitude }}
              key={index}
              icon={{
                url: "/assets/img/babonbo_marker.png",
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(20, 20)
              }}
            />
          )
        }
        else if (type === 'provider') {
          return (
            <Marker
              name={name}
              onClick={(pr, mr) => onMarkerClick(pr, mr, marker)}
              position={{ lat: geocode.latitude, lng: geocode.longitude }}
              key={index}
              icon={{
                url: "/assets/img/babonbo_marker_provider.png",
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(20, 20)
              }}
            />
          )
        }
        else if (type === 'exact') {
          return (
            <Marker
              name={name}
              onClick={(pr, mr) => onMarkerClick(pr, mr, marker)}
              position={{ lat: geocode.latitude, lng: geocode.longitude }}
              key={index}
              icon={{
                url: "/assets/img/babonbo_marker.png",
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(20, 20)
              }}
            />
          )
        }
        else if (type === 'other') {
          return (
            <Marker
              name={name}
              onClick={(pr, mr) => onMarkerClick(pr, mr, marker)}
              position={{ lat: geocode.latitude, lng: geocode.longitude }}
              key={index}
              icon={{
                url: "/assets/img/babonbo_marker_provider.png",
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(20, 20)
              }}
            />
          )
        }

      })}


      <InfoWindow
        marker={MAP_MARKER}
        onClose={onInfoWindowClose}
        visible={MAP_INFO}
        onOpen={e => {
          onInfoWindowOpen(props, e);
        }}

      >
        <div id="iwc" />

      </InfoWindow>

    </Map>
  );

}

const MarkerItem = (props) => {

  const { marker, pickup, dropoff, TRANSLATE, onCardClicked } = props;

  const MarkerTitle = marker.name;
  const MarkerDelivery = marker.delivery || [];
  const MarkerSubtitle = MarkerDelivery.length == 0 ? 'Free Self Pickup' : MarkerDelivery.length + ' ' + TRANSLATE.wd_delivery_zones;
  const MarkerImage = marker.image;

  const MarkerLocation = marker.geocode || {};
  const MarkerLatitude = MarkerLocation.latitude || 0;
  const MarkerLongitude = MarkerLocation.longitude || 0;

  const MarkerPickDist = getDistanceBetweenCoordinates(pickup.lat, pickup.lng, MarkerLatitude, MarkerLongitude);
  const MarkerDropDist = getDistanceBetweenCoordinates(dropoff.lat, dropoff.lng, MarkerLatitude, MarkerLongitude);
  const MarkerProducts = marker.products || 0;
  const MarkerButton = MarkerProducts == 1 ? MarkerProducts + ' ' + TRANSLATE.wd_product : MarkerProducts + ' ' + TRANSLATE.wd_products;

  return (
    <div className='w-[180px] h-auto flex flex-col justify-center items-center' onClick={() => onCardClicked(marker)}>


      <img src={MarkerImage} className='w-[50px] h-[50px] rounded-full mx-auto object-cover mt-2 mb-2' />

      <div className='w-full text-center'>

        <h1 className='text-sm leading-tight capitalize mb-3'>
          {MarkerTitle}
        </h1>

        <p className='text-xs leading-tight capitalize mb-5'>
          {MarkerSubtitle}
        </p>

        <div className='flex items-center justify-between w-full'>
          <div className='flex flex-col items-center justify-center w-1/2'>
            <h1 className='text-xs'>{TRANSLATE.wd_pickup}</h1>
            <p className='text-xs'>{MarkerPickDist.toFixed(2)} km</p>
          </div>
          <div className='flex flex-col items-center justify-center w-1/2'>
            <h1 className='text-xs'>{TRANSLATE.wd_dropoff}</h1>
            <p className='text-xs'>{MarkerDropDist.toFixed(2)} km</p>
          </div>
        </div>

      </div>

      <div className='w-full bg-babonbo-100 rounded-full text-white text-center py-2 mt-4 cursor-pointer'>
        {MarkerButton}
      </div>

    </div>

  )

}

const MarkerDelivery = (props) => {

  const { marker, TRANSLATE, onCardClicked } = props;

  let MarkerType = marker.type;
  let MarkerTitle = '';

  if (MarkerType === 'pickup') {
    MarkerTitle = TRANSLATE.wd_pickup;
  }
  else if (MarkerType === 'dropoff') {
    MarkerTitle = TRANSLATE.wd_dropoff;
  }
  else if (MarkerType === 'pickdrop') {
    MarkerTitle = TRANSLATE.wd_pickup + ' ' + TRANSLATE.wd_and + ' ' + TRANSLATE.wd_dropoff;
  }


  return (
    <div className='w-[180px] h-auto flex flex-col justify-center items-center' onClick={() => onCardClicked(marker)}>

      <div className='w-full text-center'>

        <h1 className='text-sm leading-tight'>
          {MarkerTitle}
        </h1>

        <p className='text-xs normal'>
          {marker.name}
        </p>

      </div>

    </div>

  )

}





export default GoogleApiWrapper(
  (props) => ({
    apiKey: 'AIzaSyA-flHydkKCfUwJT5aT2ieRNZCGxNnPKEg',
    language: props.LANGUAGE,
    LoadingContainer: (props) => (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader.png' />
      </div>
    )
  }
  ))(FlowMap)