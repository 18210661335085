import { ChevronRightOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import Dropdown from '../Dropdown/Dropdown';
import { FooterDashboard } from '../Footer';
import Header from '../Header';

const mockPayouts = [
  { year: 2024, month: 'April', status: 'waiting', amount: '€2.457.85' },
  { year: 2024, month: 'March', status: 'waiting', amount: '€2.457.85' },
  { year: 2024, month: 'February', status: 'waiting', amount: '€2.457.85' },
  { year: 2024, month: 'January', status: 'waiting', amount: '€2.457.85' },
  { year: 2023, month: 'April', status: 'waiting', amount: '€2.457.85' },
];

const ProviderEarning = (props) => {
  const [activeTab, setActiveTab] = useState('payouts');
  const [filter, setFilter] = useState('all');

  const groupedPayouts = mockPayouts.reduce((acc, payout) => {
    if (!acc[payout.year]) {
      acc[payout.year] = [];
    }
    acc[payout.year].push(payout);
    return acc;
  }, {});

  const sortedYears = Object.keys(groupedPayouts).sort((a, b) => b - a);
  const currentYear = new Date().getFullYear();

  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} isProvider={true} />

      <div className='flex flex-col align-items-center justify-content-center mx-auto my-4 max-w-xl px-4 sm:px-6 lg:px-8'>
        <div className='flex w-full border-light-gray rounded-lg overflow-hidden'>
          <button
            type='button'
            onClick={() => setActiveTab('earning')}
            className={`p-1 w-full text-center ${activeTab === 'earning' ? 'text-white bg-pr' : ' color-gray'}`}
          >
            Earning
          </button>
          <button
            type='button'
            onClick={() => setActiveTab('earning')}
            className={`p-1 w-full text-center ${activeTab === 'payouts' ? 'text-white bg-pr' : 'color-gray'}`}
          >
            Payouts
          </button>
        </div>

        <div className='my-4 w-full'>
          <Dropdown
            value={filter}
            options={[{ title: 'All Time', value: 'all' }]}
            onChange={(value) => setFilter(value)}
            searchDisabled={true}
            isProvider={true}
            outlined
          />
        </div>

        <div className='flex p-2 w-full align-items-center justify-content-between text-white bg-pr rounded-lg'>
          <p>Total:</p>
          <h2>€9,874.73</h2>
        </div>

        <h3 className='mt-4 mb-2 w-full font-semibold'>PAYOUTS</h3>

        <div>
          {sortedYears.map((year) => (
            <div key={year} className='mb-8'>
              {parseInt(year) !== currentYear && <h3 className='font-semibold mb-2'>{year}</h3>}
              <div className='flex flex-col gap-2'>
                {groupedPayouts[year].map((payout, index) => (
                  <div key={index} className='grid grid-cols-3 items-center rounded-lg'>
                    <span className='text-left uppercase'>
                      {payout.month} {payout.year}
                    </span>
                    <span className='flex justify-content-center'>
                      <span
                        className={`py-1 px-2 text-xs font-semibold text-white text-center uppercase rounded-full ${
                          payout.status === 'waiting' ? 'bg-gray' : ''
                        }`}
                      >
                        {payout.status}
                      </span>
                    </span>
                    <span className='text-right font-bold'>
                      {payout.amount} <ChevronRightOutlined />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <FooterDashboard {...props} />
    </div>
  );
};

export default ProviderEarning;
