import React from 'react';

import './z_empty.css';

const EmptyContainer = ({title, sub, img, EmptyButton, noShaddow}) => {

    return (
        <div className="empty_container" style={(noShaddow ? {boxShadow: 'none'} : {})}>
            <div className="empty_box" style={{textAlign: 'center'}}>

                {img && (
                     <img src={img} />
                )}
                
                <h4 style={(img ? {} : {paddingTop: 60})}>
                    {title}
                </h4>
                <p style={(noShaddow ? {marginBottom: 0} : {})}> 
                   {sub}
                </p>

                {EmptyButton && (
                    <EmptyButton />
                )}
                
            </div>
        </div>
    )
}

export default EmptyContainer