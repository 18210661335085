import React from 'react';

const ReviewComponent = (props) => {

    const {title, value, onChange, AlreadyReviewed, ReadOnly} = props;
  
    const ReviewImgStyle = {
      width: 25,
      height: 25,
      objectFit: 'cover',
      cursor: AlreadyReviewed ? 'not-allowed' : 'pointer'
    }
  
    const EmptyStar = '/assets/img/babonbo_star_empty.png';
    const FullStar = '/assets/img/babonbo_star_full.png';

    if(ReadOnly){
      return (
        <div className="top_box_magic">
            <h1>
              {title}
            </h1>
            <div className='flex' style={{margin: '10px 0px'}}>
              <img src={(value >= 1 ? FullStar : EmptyStar)} style={ReviewImgStyle} />
              <img src={(value >= 2 ? FullStar : EmptyStar)} style={ReviewImgStyle} />
              <img src={(value >= 3 ? FullStar : EmptyStar)} style={ReviewImgStyle} />
              <img src={(value >= 4 ? FullStar : EmptyStar)} style={ReviewImgStyle} />
              <img src={(value >= 5 ? FullStar : EmptyStar)} style={ReviewImgStyle} />
            </div>
        </div>
      )
    }
  
    return (
      <div style={{margin: 20}}>
        <div style={{marginBottom: 10, fontSize: 25}}>
          {title}
        </div>
        <img src={(value >= 1 ? FullStar : EmptyStar)} onClick={() => AlreadyReviewed ? null : onChange(1)} style={ReviewImgStyle} />
        <img src={(value >= 2 ? FullStar : EmptyStar)} onClick={() => AlreadyReviewed ? null : onChange(2)} style={ReviewImgStyle} />
        <img src={(value >= 3 ? FullStar : EmptyStar)} onClick={() => AlreadyReviewed ? null : onChange(3)} style={ReviewImgStyle} />
        <img src={(value >= 4 ? FullStar : EmptyStar)} onClick={() => AlreadyReviewed ? null : onChange(4)} style={ReviewImgStyle} />
        <img src={(value >= 5 ? FullStar : EmptyStar)} onClick={() => AlreadyReviewed ? null : onChange(5)} style={ReviewImgStyle} />
      </div>
    )
}

export default ReviewComponent