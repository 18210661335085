const Languages_Array = [
    {
        name: 'ITALIANO',
        code: 'it',
        img: '/assets/languages/it.png'
    },
    {
        name: 'ENGLISH',
        code: 'en',
        img: '/assets/languages/en.png'
    },
    {
        name: 'FRANÇAIS',
        code: 'fr',
        img: '/assets/languages/fr.png'
    },
    {
        name: 'ESPAÑOL',
        code: 'es',
        img: '/assets/languages/es.png'
    },
    // {
    //     name: 'TÜRKÇE',
    //     code: 'tr',
    //     img: '/assets/languages/tr.png'
    // }
]

const Currencies_Array = [
    {   
        nav: '€ EUR',
        name: 'EURO - €',
        code: 'eur',
        img: '/assets/languages/eu.png'
    },
    {
        nav: '£ GBP',
        name: 'GBP - £',
        code: 'gbp',
        img: '/assets/languages/en.png'
    },
    {
        nav: '$ USD',
        name: 'US DOLLAR - $',
        code: 'usd',
        img: '/assets/languages/us.png'
    }
]

const Cities_Array = [
  {
    en: 'Milan',
    it: 'Milano',
    fr: 'Milan',
    es: 'Milán',
    tr: 'Milano',
    en_url: '/en/search/milan/IT',
    it_url: '/it/search/milano/IT',
    fr_url: '/fr/search/milan/IT',
    es_url: '/es/search/milan/IT',
    tr_url: '/tr/search/milano/IT',
    country: 'IT',
    geocode: { latitude: 45.46427, longitude: 9.18951 },
    img: ''
  },
  {
    en: 'Paris',
    it: 'Parigi',
    fr: 'Paris',
    es: 'París',
    tr: 'Paris',
    en_url: '/en/search/paris/FR',
    it_url: '/it/search/parigi/FR',
    fr_url: '/fr/search/paris/FR',
    es_url: '/es/search/paris/FR',
    tr_url: '/tr/search/paris/FR',
    country: 'FR',
    geocode: { latitude: 48.85341, longitude: 2.3488 },
    img: 'https://mybabonbo.s3.amazonaws.com/dff8b5c5c6f9689c22c8c665e013f726_paris-preview.jpg'
  },
  {
    en: 'Barcelona',
    it: 'Barcellona',
    fr: 'Barcelone',
    es: 'Barcelona',
    tr: 'Barselona',
    en_url: '/en/search/barcelona/ES',
    it_url: '/it/search/barcellona/ES',
    fr_url: '/fr/search/barcelone/ES',
    es_url: '/es/search/barcelona/ES',
    tr_url: '/tr/search/barselona/ES',
    country: 'ES',
    geocode: { latitude: 41.38879, longitude: 2.15899 },
    img: ''
  },
  {
    en: 'London',
    it: 'Londra',
    fr: 'Londres',
    es: 'Londres',
    tr: 'Londra',
    en_url: '/en/search/london/GB',
    it_url: '/it/search/londra/GB',
    fr_url: '/fr/search/londres/GB',
    es_url: '/es/search/londres/GB',
    tr_url: '/tr/search/londra/GB',
    country: 'GB',
    geocode: { latitude: 51.50853, longitude: -0.12574 },
    img: ''
  },
  {
    en: 'Istanbul',
    it: 'Istanbul',
    fr: 'Istanbul',
    es: 'Estanbul',
    tr: 'İstanbul',
    en_url: '/en/search/istanbul/TR',
    it_url: '/it/search/istanbul/TR',
    fr_url: '/fr/search/istanbul/TR',
    es_url: '/es/search/estanbul/TR',
    tr_url: '/tr/search/istanbul/TR',
    country: 'TR',
    geocode: { latitude: 41.01384, longitude: 28.94966 },
    img: ''
  },
  {
    en: 'Rome',
    it: 'Roma',
    fr: 'Rome',
    es: 'Roma',
    tr: 'Roma',
    en_url: '/en/search/rome/IT',
    it_url: '/it/search/roma/IT',
    fr_url: '/fr/search/rome/IT',
    es_url: '/es/search/roma/IT',
    tr_url: '/tr/search/roma/IT',
    country: 'IT',
    geocode: { latitude: 41.89193, longitude: 12.51133 },
    img: ''
  },
//   {
//     en: 'Berlin',
//     it: 'Berlino',
//     fr: 'Berlin',
//     es: 'Berlina',
//     tr: 'Berlin',
//     en_url: '/en/search/berlin/DE',
//     it_url: '/it/search/berlino/DE',
//     fr_url: '/fr/search/berlin/DE',
//     es_url: '/es/search/berlina/DE',
//     tr_url: '/tr/search/berlin/DE',
//     country: 'DE',
//     geocode: { latitude: 52.52437, longitude: 13.41053 },
//     img: ''
//   },
  {
    en: 'Pisa',
    it: 'Pisa',
    fr: 'Pise',
    es: 'Pisa',
    tr: 'Pisa',
    en_url: '/en/search/pisa/IT',
    it_url: '/it/search/pisa/IT',
    fr_url: '/fr/search/pise/IT',
    es_url: '/es/search/pisa/IT',
    tr_url: '/tr/search/pisa/IT',
    country: 'IT',
    geocode: { latitude: 43.70853, longitude: 10.4036 },
    img: ''
  },
  {
    en: 'Florence',
    it: 'Firenze',
    fr: 'Florence',
    es: 'florencia',
    tr: 'Floransa',
    en_url: '/en/search/florence/IT',
    it_url: '/it/search/firenze/IT',
    fr_url: '/fr/search/florence/IT',
    es_url: '/es/search/florencia/IT',
    tr_url: '/tr/search/floransa/IT',
    country: 'IT',
    geocode: { latitude: 43.77925, longitude: 11.24626 },
    img: ''
  },
//   {
//     en: 'Venice',
//     it: 'Venezia',
//     fr: 'Venise',
//     es: 'Venecia',
//     tr: 'Venedik',
//     en_url: '/en/search/venice/IT',
//     it_url: '/it/search/venezia/IT',
//     fr_url: '/fr/search/venise/IT',
//     es_url: '/es/search/venecia/IT',
//     tr_url: '/tr/search/venedik/IT',
//     country: 'IT',
//     geocode: { latitude: 45.43713, longitude: 12.33265 },
//     img: ''
//   },
  
]

const Categories_Array = [
  {
      it: 'Tutti Prodotti',
      en: 'All Products',
      fr: 'Tous les produits',
      es: 'Todos los productos',
      tr: 'Hepsi',
      code: 'all',
      keywords: ['all']
  }, {
      it: 'Sistemi Modulari',
      en: 'Modular Systems',
      fr: 'Systèmes Modulaires',
      es: 'Sistemas Modulares',
      tr: 'Modüler Sistem Bebek Arabaları',
      code: 'sistemi_modulari',
      keywords: [
          'sistemi_modulari',
          'sistemi-modulari', 'sistemi modulari', 'sistema modulare', 'trio',
          'sistemas-modulares', 'sistema modulare',
          'modular-systems', 'modular system',
          'systemes-modulaires', 'systemes-modulaires', 'systemes modulaires',
          'moduler-sistem-bebek-arabalar'
      ]
  }, {
      it: 'Passeggini',
      en: 'Strollers',
      fr: 'Poussettes',
      es: 'Cochecitos',
      tr: 'Bebek Arabaları',
      code: 'passeggini',
      keywords: [
          'passeggini', 
          'passeggino', 'passeggio',
          'cochecitos', 'cochecito',
          'strollers', 'stroller',
          'poussettes', 'poussette',
          'bebek-arabalar'
      ] 
  }, {
      it: 'Seggiolini Auto',
      en: 'Car Seats',
      fr: 'Sièges Auto',
      es: 'Asientos de Coche',
      tr: 'Oto Koltukları',
      code: 'seggiolini',
      keywords: [
          'seggiolini', 
          'seggiolini-auto', 'seggiolino auto', 'seggiolino', 'ovetto', 'seggiolini-auto', 
          'asientos-de-coche', 'asiento de coche',
          'car-seats', 'car-seat', 'car', 'auto seat', 'seggiolino-auto', 'pram-infant-car-seat',
          'sieges-auto', 'sieges auto', 'sieges-auto',
          'oto-koltuklar'
      ]
  }, {
      it: 'Culle e Lettini',
      en: 'Cribs & Cots',
      fr: 'Lits et Berceaux',
      es: 'Cunas y Camas',
      tr: 'Beşikler ve Park Yataklar',
      code: 'culle_lettini',
      keywords: [
          'culle_lettini', 
          'culle-e-lettini', 'culle e lettini', 'culle', 'culle e lettini', 'lettino', 'lettini',
          'cunas-y-camas', 'cunas', 'camas', 
          'cribs-and-cots', 'cribs', 'cots', 'co-sleeper-bassinet', 'light-portable-cot',
          'lits-et-berceaux',
          'besikler-ve-park-yataklar'
          
      ]
  }, {
      it: 'Sdraiette',
      en: 'Bouncers',
      fr: 'Videurs',
      es: 'Gorilas',
      tr: 'Ana Kucakları',
      code: 'sdraiette',
      keywords: [
          'sdraiette', 
          'sdraietta',
          'gorilas',
          'bouncers',
          'videurs',
          'ana-kucaklar'
      ]
  }, {
      it: 'Marsupi e Zaini',
      en: 'Bum Bags & Backpacks',
      fr: 'Sacs banane et sacs à dos',
      es: 'Riñoneras y Mochilas',
      tr: 'Kanguru ve Sırt Çantaları',
      code: 'marsupi_zaini',
      keywords: [
          'marsupi_zaini', 
          'marsupi-e-zaini', 'marsupi e zaini', 'marsupi', 'zaini', 'marsupio', 'zaino',
          'rinoneras-y-mochilas', 'riñoneras', 'mochilas', 'mochila', 'riñonera',
          'bum-bags-and-backpacks',
          'sacs banane et sacs à dos', 'sacs-banane-et-sacs-a-dos',
          'kanguru-ve-srt-cantalar'
      ]
  }, {
      it: 'Seggioloni',
      en: 'Highchairs',
      fr: 'Chaises hautes',
      es: 'Tronas',
      tr: 'Mama Sandalyeleri',
      code: 'seggioloni',
      keywords: [
          'seggioloni', 
          'seggiolone',
          'tronas',
          'highchairs',
          'chaises hautes', 'chaises-hautes',
          'mama-sandalyeleri'
      ]
  }, {
      it: 'Box Gioco e Recinto',
      en: 'Play Box & Fence',
      fr: 'Boîte de jeu et Clôture',
      es: 'Cajas de Juego',
      tr: 'Oyun Alanı ve Oyun Çiti',
      code: 'box_recinto',
      keywords: [
          'box_recinto', 
          'box-gioco-e-recinto', 'box gioco e recinto', 'box gioco', 'recinto',
          'cajas-de-juego',
          'play-box-and-fence',
          'boite-de-jeu-et-cloture', 'boîte de jeu et clôture', 'boîte-de-jeu-et-clôture',
          'oyun-alan-ve-oyun-citi'
      ]
  }, {
      it: 'Giocatoli e Libri',
      en: 'Toys & Books',
      fr: 'Jouets et livres',
      es: 'Juguetes y libros',
      tr: 'Oyuncaklar ve Kitaplar',
      code: 'giocatoli_libri',
      keywords: [
          'giocatoli_libri', 
          'giocatoli-e-libri', 'giocatoli e libri', 'giocatoli', 'libri', 'giocatoli e libri',
          'juguetes-y-libros', 'juguetes', 'libros',
          'toys-and-books',
          'jouets et livres', 'jouets-et-livres',
          'oyuncaklar-ve-kitaplar'
      ]
  }, {
      it: 'Vestiti di Carnevale',
      en: 'Carnival Clothes',
      fr: 'Vêtements de carnaval',
      es: 'Ropa de Carnaval',
      tr: 'Festival Kıyafetleri',
      code: 'vestiti_carnevale',
      keywords: [
          'vestiti_carnevale', 
          'vestiti-di-carnevale', 'vestiti di carnevale', 'vestito carnevale', 'vestito carnevale',
          'ropa-de-carnaval', 'carnevale',
          'carnival-clothes',
          'vetements-de-carnaval','vêtements-de-carnaval',
          'festival-kyafetleri'

      ]
  }, {
      it: 'Allattamento',
      en: 'Breastfeeding',
      fr: 'Allaiter',
      es: 'Lactancia',
      tr: 'Emzirme Ürünleri',
      code: 'allattamento',
      keywords: [
          'allattamento', 
          'allattamenti',
          'lactancia',
          'breastfeeding',
          'allaiter',
          'emzirme-urunleri'
      ]
  }, {
      it: 'Benessere',
      en: 'Wellness',
      fr: 'Bien-être',
      es: 'Bienestar',
      tr: 'Bebek Sağlık Ürünleri',
      code: 'benessere',
      keywords: [
          'benessere', 
          'benesseri',
          'bienestar',
          'wellness',
          'bien-etre', 'bien-être',
          'bebek-saglk-urunleri'
      ]
  },{
      it: 'E-Bikes',
      en: 'E-Bikes',
      fr: 'E-Bikes',
      es: 'E-Bikes',
      tr: 'E-Bikes',
      code: 'ebikes',
      keywords: ['e-bikes', 'ebikes', 'bici elettronica']
  }, {
      it: 'Articoli Stagionali',
      en: 'Outdoor & Seasonal',
      fr: 'Articles Saisonniers',
      es: 'Artículos de Temporada',
      tr: 'Açık Hava ve Sezonluk Ürünler',
      code: 'outdoor_seasonal',
      keywords: [
          'outdoor_seasonal', 
          'articoli-stagionali', 'articoli stagionali', 'articolo stagionale', 'stagionale',
          'articulos-de-temporada',
          'outdoor-and-seasonal',
          'articles-saisonniers',
          'ack-hava-ve-sezonluk-urunler'
      ]
  }, {
      it: 'Pacchetti',
      en: 'Packages',
      fr: 'Paquets',
      es: 'Paquetes',
      tr: 'Setler',
      code: 'packages',
      keywords: [
          'pacchetti', 
          'pacchetto',
          'paquetes',
          'packages',
          'paquets',
          'setler'
      ]
  }, {
      it: 'Altro',
      en: 'Other',
      fr: 'Autre',
      es: 'Otro',
      tr: 'Diğer Ürünler',
      code: 'other',
      keywords: [
          'other', 
          'altri prodotti', 'altri',
          'otro',
          'other',
          'diğer-ürünler'
      ]
  }
];

const Ages_Array = [
    {
        it: 'Prematuro',
        en: 'Premature',
        fr: 'Premature',
        es: 'Prematuro',
        code: 'premature',
        min: 0,
        max: 45
    },
    {
        it: 'Nascita',
        en: 'Birth',
        fr: 'Naissance',
        es: 'Nacimiento',
        code: 'birth',
        min: 45,
        max: 55
    },
    {
        it: '1 mese',
        en: '1 month',
        fr: '1 mois',
        es: '1 mes',
        code: '1_m',
        min: 50,
        max: 55
    },
    {
        it: '3 mesi',
        en: '3 months',
        fr: '3 mois',
        es: '3 meses',
        code: '3_m',
        min: 54,
        max: 60
    },
    {
        it: '6 mesi',
        en: '6 month',
        fr: '6 mois',
        es: '6 meses',
        code: '6_m',
        min: 60,
        max: 66
    },
    {
        it: '9 mesi',
        en: '9 month',
        fr: '9 mois',
        es: '9 meses',
        code: '9_m',
        min: 67,
        max: 74
    },
    {
        it: '12 mesi',
        en: '12month',
        fr: '12 mois',
        es: '12 meses',
        code: '12_m',
        min: 74,
        max: 80
    },
    {
        it: '18 mesi',
        en: '18 month',
        fr: '18 mois',
        es: '18 meses',
        code: '18_m',
        min: 80,
        max: 86
    },
    {
        it: '24 mesi',
        en: '24 month',
        fr: '24 mois',
        es: '24 meses',
        code: '24_m',
        min: 86,
        max: 94
    },
    {
        it: '3 anni',
        en: '3 years',
        fr: '3 ans',
        es: '3 años',
        code: '3_y',
        min: 94,
        max: 102
    },
    {
        it: '4 anni',
        en: '4 years',
        fr: '4 ans',
        es: '4 años',
        code: '4_y',
        min: 102,
        max: 108
    },
    {
        it: '5 anni',
        en: '5 years',
        fr: '5 ans',
        es: '5 años',
        code: '5_y',
        min: 108,
        max: 116
    },
    {
        it: '6 anni',
        en: '6 years',
        fr: '6 ans',
        es: '6 años',
        code: '6_y',
        min: 116,
        max: 123
    },
    {
        it: '8 anni',
        en: '8 years',
        fr: '8 ans',
        es: '8 años',
        code: '8_y',
        min: 123,
        max: 128
    },
    {
        it: '10 anni',
        en: '10 years',
        fr: '10 ans',
        es: '10 años',
        code: '10_y',
        min: 128,
        max: 140
    },
    {
        it: '12 anni',
        en: '12 years',
        fr: '12 ans',
        es: '12 años',
        code: '12_y',
        min: 140,
        max: 152
    },
    {
        it: '14 anni',
        en: '14 years',
        fr: '14 ans',
        es: '14 años',
        code: '14_y',
        min: 152,
        max: 164
    },
    {
        it: '16 anni',
        en: '16 years',
        fr: '16 ans',
        es: '16 años',
        code: '16_y',
        min: 164,
        max: 176
    }

];

const onCheckCityRequest = (req_body) => {

  var loc_city = req_body.city || '';
  var loc_country = req_body.country || '';
  var loc_lang = req_body.lang || '';
  var loc_category = req_body.category || 'all';

  var it = '';
  var en = '';
  var fr = '';
  var es = '';
  var tr = '';

  //Category Check Middleware
  if(loc_country){
      let CAT_INDEX = Categories_Array.findIndex((item)=> item.keywords.includes(loc_country.toLowerCase()));

      if(CAT_INDEX != -1){
          let CAT_FOUND = Categories_Array[CAT_INDEX] || {};

          it = CAT_FOUND.it;
          fr = CAT_FOUND.fr;
          en = CAT_FOUND.en;
          es = CAT_FOUND.es;
          tr = CAT_FOUND.tr;

          loc_category =  CAT_FOUND.code;
          loc_country = null;

          
      }
      else{

          let CAT_INDEX = Categories_Array.findIndex((item)=> item.keywords.includes(loc_category.toLowerCase()));
   
          if(CAT_INDEX == -1){
               loc_category = 'all';
          }
      
          let CAT_FOUND = Categories_Array[CAT_INDEX] || {};
      
          it = CAT_FOUND.it;
          fr = CAT_FOUND.fr;
          en = CAT_FOUND.en;
          es = CAT_FOUND.es;
          tr = CAT_FOUND.tr;

          loc_category =  CAT_FOUND.code;

      }
  }
  else{


        let CAT_INDEX = Categories_Array.findIndex((item)=> item.keywords.includes(loc_category.toLowerCase()));
    
        if(CAT_INDEX == -1){
            loc_category = 'all';
        }
    
        let CAT_FOUND = Categories_Array[CAT_INDEX] || {};
  
        it = CAT_FOUND.it;
        fr = CAT_FOUND.fr;
        en = CAT_FOUND.en;
        es = CAT_FOUND.es;
        tr = CAT_FOUND.tr;
  
        loc_category =  CAT_FOUND.code;
      

  }


  return {
      city: loc_city,
      country: loc_country,
      category: loc_category,
      lang: loc_lang,
      it: it,
      en: en,
      es: es,
      fr: fr,
      tr: tr
  }

}

export {
    Currencies_Array,
    Languages_Array,
    Cities_Array,
    Categories_Array,
    Ages_Array,
    onCheckCityRequest
}