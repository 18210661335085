import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import ReactGA from 'react-ga';
import swal from 'sweetalert';
import GAListener from './helpers/GAnalytics';

import { connect } from 'react-redux';
import { onUpdateCart, onUpdateLanguage, onUpdateUser } from './actions';
import { postApiPrivate } from './api';

import { onGetCurrencySymbol } from './helpers/GeneralHelper';

import LANG_EN from './translations/en';
import LANG_ES from './translations/es';
import LANG_FR from './translations/fr';
import LANG_IT from './translations/it';

import './components/Marketplace/z_marketplace.css';
import './components/Root/z_styles.css';
import './components/Search/z_search.css';
import './components/Store/z_store.css';

import AuthLogin from './components/Auth/AuthLogin';
import AuthLogout from './components/Auth/AuthLogout';

import Admin from './components/Admin/Admin';
import NewUX from './components/NewUX';
import Partners from './components/Partners/Admin';

const NoMatch = () => (
  <div>
    <h1>404</h1>
    React Page Not Found
  </div>
);

const AppRoutes = (props) => {
  const { onUpdateCart, onUpdateUser, CART, LANGUAGE, USER } = props;

  useEffect(() => {
    onStartUser();
    onStartFacebookPixel();
  }, []);

  useEffect(() => {
    localStorage.setItem('language', LANGUAGE);
  }, [LANGUAGE]);

  const onStartUser = async (isReset) => {
    if (isReset) {
      onUpdateUser({});
      return;
    }

    const JWT = typeof global.window !== 'undefined' ? localStorage.getItem('jwt_token') : null;

    if (JWT) {
      onFetchUser(JWT);
    }
  };

  const onFetchUser = async (jwt, payload) => {
    if (payload) {
      onUpdateUser(payload);
      return;
    }

    if (!jwt) {
      console.log('ERROR NO JWT FOUND ---> On Fetch User');

      alert('Check your internet connection and try again. 3');

      return;
    }

    try {
      const DATA_RESPONSE = await postApiPrivate('/users/profile', {}, jwt);

      if (DATA_RESPONSE.error) {
        console.log('ERROR FROM API USER INFO', DATA_RESPONSE.error);

        swal('Warning', 'You have been disconnected', 'warning');

        localStorage.removeItem('jwt_token');

        onStartUser();

        window.location.replace('/login');

        return;
      }

      const DATA_ID = DATA_RESPONSE.profile_id || '';
      const DATA_NAME = DATA_RESPONSE.profile_name || '';
      const DATA_EMAIL = DATA_RESPONSE.profile_email || '';
      const DATA_PHONE = DATA_RESPONSE.profile_phone || '';
      const DATA_ABOUT = DATA_RESPONSE.profile_about || '';
      const DATA_PICTURE = DATA_RESPONSE.profile_picture || '';
      const DATA_SETUP = DATA_RESPONSE.profile_setup || false;

      const DATA_CITY = DATA_RESPONSE.profile_city || '';
      const DATA_COUNTRY = DATA_RESPONSE.profile_country || '';
      const DATA_GEOCODE = DATA_RESPONSE.profile_geocode || {};
      const DATA_SEARCH = DATA_RESPONSE.profile_search || null;

      const DATA_REVIEWS = DATA_RESPONSE.profile_reviews || 0;
      const DATA_RATING = DATA_RESPONSE.profile_rating || 0;

      const DATA_FOLLOWING = DATA_RESPONSE.profile_following || 0;
      const DATA_FOLLOWERS = DATA_RESPONSE.profile_followers || 0;

      const DATA_STORE = DATA_RESPONSE.profile_store || '';
      const DATA_INSEGNA = DATA_RESPONSE.profile_insegna || '';
      const DATA_MARKETO = DATA_RESPONSE.profile_marketo || false;

      const DATA_SHOP = DATA_RESPONSE.profile_shop || '';
      const DATA_DESCRIPTION = DATA_RESPONSE.profile_description || '';

      const DATA_CURRENCY = DATA_RESPONSE.profile_currency || '';
      const DATA_SHOP_CURRENCY = DATA_RESPONSE.profile_shop_currency || '';

      const DATA_CURRENCY_SYMBOL = onGetCurrencySymbol(DATA_CURRENCY);
      const DATA_SHOP_CURRENCY_SYMBOL = onGetCurrencySymbol(DATA_SHOP_CURRENCY);

      const DATA_ADMIN = DATA_RESPONSE.profile_admin || false;

      const DATA_PARTNER = DATA_RESPONSE.profile_partner || '';
      const DATA_LOGO = DATA_RESPONSE.profile_logo || '';

      const DATA_USER = {
        id: DATA_ID,
        email: DATA_EMAIL,
        phone: DATA_PHONE,
        name: DATA_NAME,
        city: DATA_CITY,
        rating: DATA_RATING,
        reviews: DATA_REVIEWS,
        about: DATA_ABOUT,
        setup: DATA_SETUP,
        picture: DATA_PICTURE,
        geocode: DATA_GEOCODE,
        store: DATA_STORE,
        insegna: DATA_INSEGNA,
        marketo: DATA_MARKETO,
        description: DATA_DESCRIPTION,
        shop: DATA_SHOP,
        country: DATA_COUNTRY,
        admin: DATA_ADMIN,
        search: DATA_SEARCH,
        following: DATA_FOLLOWING,
        followers: DATA_FOLLOWERS,
        currency: DATA_CURRENCY,
        symbol: DATA_CURRENCY_SYMBOL,
        shop_currency: DATA_SHOP_CURRENCY,
        shop_symbol: DATA_SHOP_CURRENCY_SYMBOL,
        partner: DATA_PARTNER,
        logo: DATA_LOGO,
      };

      console.log('CHECKING USER PROFILE === ', DATA_USER);

      onUpdateUser(DATA_USER);
    } catch (e) {
      console.log('FETCH USER ERROR ===> ', e);

      return alert('Check your internet connection and try again. 5');
    }
  };

  const onFetchAnalytics = ({ location }) => {
    console.log('ANALYTICS ===> ');

    ReactGA.addTrackers(
      [
        {
          trackingId: 'UA-138082419-1',
          gaOptions: {
            name: 'tracker2',
          },
        },
      ],
      { debug: true, alwaysSendToDefaultTracker: false }
    );

    ReactGA.set({ page: location.pathname }, ['tracker2']);
    ReactGA.pageview(location.pathname, ['tracker2']);
  };

  const onStartFacebookPixel = () => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('968310484061433');
      });
  };

  const onGoogleTracking = (event) => {
    ReactGA.event({
      category: event,
      action: event,
    });
  };

  const onFacebookTracking = (event, data) => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        if (event === 'pageView') {
          ReactPixel.pageView(); // For tracking page view
        } else {
          //alert("Updating Search "+event+"===> "+JSON.stringify(data, null, 2));
          ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
          onGoogleTracking(event, data);
        }
      });
  };

  const onKlaviyoTracking = (event, item) => {
    var _learnq = window._learnq || [];

    if (!USER.email) {
      return;
      alert('EVENT ==> ITEM ===> ' + JSON.stringify({ event, item }, null, 2));
    }

    // alert("EVENT ==> ITEM ===> EMAIL ===> "+USER.email+'/n'+JSON.stringify({event, item}, null, 2));

    _learnq.push([
      'identify',
      {
        $email: USER.email,
      },
    ]);

    if (event === 'Viewed Product') {
      _learnq.push(['track', 'Viewed Product', item]);
      _learnq.push([
        'trackViewedItem',
        {
          Title: item.Name,
          ItemId: item.ProductID,
          Categories: item.Categories,
          ImageUrl: item.ImageURL,
          Url: item.URL,
          Metadata: {
            Brand: item.Brand,
            Price: item.Price,
          },
        },
      ]);
    } else if (event === 'Added to Cart') {
      _learnq.push(['track', 'Added to Cart', item]);
    } else if (event === 'Started Checkout') {
      _learnq.push(['track', 'Started Checkout', item]);
    }
  };

  let TRANSLATE = {};

  if (LANGUAGE === 'it') {
    TRANSLATE = LANG_IT;
  } else if (LANGUAGE === 'en') {
    TRANSLATE = LANG_EN;
  } else if (LANGUAGE === 'fr') {
    TRANSLATE = LANG_FR;
  } else if (LANGUAGE === 'es') {
    TRANSLATE = LANG_ES;
  }

  const sharedProps = {
    ...props,
    LANGUAGE,
    TRANSLATE,
    onFetchUser,
    onStartUser,
    onFetchAnalytics,
    onFacebookTracking,
    onKlaviyoTracking,
    onUpdateLanguage,
  };

  return (
    <GAListener trackingId={'UA-138082419-1'} defaultId='UA-138082419-1'>
      <Switch>
        <Route exact path='/' render={(props) => <Admin {...sharedProps} {...props} />} />

        <Route exact path='/login' render={(props) => <AuthLogin {...sharedProps} {...props} />} />
        <Route exact path='/logout' render={(props) => <AuthLogout {...sharedProps} {...props} />} />

        <Route exact path='/admin' render={(props) => <Admin {...sharedProps} {...props} />} />
        <Route path='/admin/*' render={(props) => <Admin {...sharedProps} {...props} />} />

        <Route exact path='/partners' render={(props) => <Partners {...sharedProps} {...props} />} />
        <Route path='/partners/*' render={(props) => <Partners {...sharedProps} {...props} />} />

        <Route path='/newux' render={(props) => <NewUX {...sharedProps} {...props} />} />
        <Route path='/newux/*' render={(props) => <NewUX {...sharedProps} {...props} />} />

        <Route render={NoMatch} />
      </Switch>
    </GAListener>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.user,
    PROVIDER: state.provider,
    CART: state.cart,
    PRODUCT: state.product,
    PRODUCTS: state.products,
    LANGUAGE: state.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateCart: (cart) => dispatch(onUpdateCart(cart)),
  onUpdateUser: (user) => dispatch(onUpdateUser(user)),
  onUpdateLanguage: (language) => dispatch(onUpdateLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
