import { ChatBubbleOutline, EventAvailable, Settings } from '@mui/icons-material';
import React, { useState } from 'react';
import { AddCircleOutline, ArrowForward } from 'react-ionicons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Header from '../Header';

const CustomerOrderDetails = (props) => {
  const [offers, setOffers] = useState([
    {
      img: '/assets/img/hero-provider.jpg',
      name: 'Travel Insurance',
      price_day: 10,
      currency: 'eur',
    },
  ]);

  const status = true;
  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} />
      <div
        className='relative flex flex-col mx-auto my-4 gap-4 w-full max-w-xl px-4 sm:px-6 lg:px-8'
        style={{ flexGrow: 1 }}
      >
        <div className='flex w-full align-items-center justify-content-between'>
          <h2 className='text-sm'>YOUR ORDER DETAILS - ID: #5235266</h2>
        </div>
        <div className='flex flex-col p-2 w-full bg-light-gray rounded-lg'>
          <label className='mb-2 ml-auto px-4 py-1 text-white rounded-full' style={{ background: '#8F00FF' }}>
            New
          </label>
          <div className='flex gap-2'>
            <img
              src='/assets/cities/amsterdam.png'
              className='border-mid-gray'
              style={{ width: '60px', height: '56px' }}
            />
            <div className='w-full'>
              <h2 className='text-sm text-ellipsis'>Uppa Baby Stroller</h2>
              <h3 className='text-sm' style={{ fontStyle: 'italic' }}>
                Aishwarya Rajamohan
              </h3>

              <div className='flex mt-2 w-full justify-content-between'>
                <p className='font-semibold'>1x</p>
                <p>16$</p>
              </div>

              <div className='flex gap-8 mt-2 w-full justify-content-between '>
                <p className='font-semibold'>4days</p>
                <p>27-03-2023 - 28-03-2023</p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-2 p-2 w-full bg-light-gray rounded-lg'>
          <div>
            <h2 className='text-sm text-ellipsis'>Customer / Delivery Details</h2>
            <h3 className='text-sm' style={{ fontStyle: 'italic' }}>
              Aishwarya Rajamohan
              <br />
              +90555 555 55 55
              <br />
              aishwarya@gmail.com
            </h3>
          </div>
          <div>
            <h2 className='text-sm text-ellipsis'>Delivery</h2>
            <h3 className='text-sm' style={{ fontStyle: 'italic' }}>
              Malpensa International Airport
            </h3>
            <h3 className='text-sm font-bold'>€ 25.00</h3>
          </div>

          <div>
            <h2 className='text-sm text-ellipsis'>Pickup</h2>
            <h3 className='text-sm' style={{ fontStyle: 'italic' }}>
              Malpensa International Airport
            </h3>
            <h3 className='text-sm font-bold'>€ 25.00</h3>
          </div>
        </div>

        <div className='flex flex-col gap-2 p-2 w-full bg-light-gray rounded-lg'>
          <h2 className='text-sm text-ellipsis'>Additional Information</h2>
          <h3 className='text-sm' style={{ fontStyle: 'italic' }}>
            Arrival Air Emirates Dec 28 EK206 11:55am Depart Air Emirates Jan 7, 2023J EK205 3:40pm (15:40pm)
          </h3>
        </div>

        <div className='flex flex-col gap-2 p-2 w-full bg-light-gray rounded-lg'>
          <h2 className='text-sm text-ellipsis'>Order Total</h2>
          <h3 className='text-sm font-bold'>€ 136.00 </h3>
        </div>

        <div className='w-full quicksand'>
          <div className='font-bold'>Add another products/services etc.</div>

          <div className='flex flex-col my-2 gap-2 w-full font-semibold'>
            {offers.map((item) => (
              <div className='flex justify-content-between'>
                <div className='flex align-items-center gap-2 '>
                  <img src={item.img} alt='' style={{ width: '60px', height: '60px' }} /> <p>{item.name}</p>
                </div>

                <div className='flex align-items-center gap-2'>
                  <p>{numberWithCommas(item.price_day, item.currency)}</p>
                  <button type='button' onClick={() => {}} className='px-3 h-full bg-gray rounded'>
                    <AddCircleOutline color='#fff' />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
          type='button'
          className='quicksand flex items-center justify-center p-2 gap-2 font-bold w-full bg-blue text-white rounded-lg'
          onClick={() => {}}
        >
          CONTINUE <ArrowForward style={{ color: '#fff' }} />
        </button>

        <div className='flex flex-col gap-2 p-2 w-full bg-light-gray rounded-lg color-ba'>
          <h3 className='text-sm '>
            <EventAvailable /> Add Calendar
          </h3>
          <h3 className='text-sm '>
            <ChatBubbleOutline /> Contact Customer
          </h3>
          <Link to='/newux/orders/edit' className='text-sm '>
            <Settings /> Edit
          </Link>
        </div>

        <div className='sticky top-auto flex flex-col w-full pt-2 bg-white' style={{ bottom: '0px' }}>
          <button
            type='button'
            className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
            onClick={() => {}}
          >
            APPROVE MY ORDER <ArrowForward style={{ color: '#fff' }} />
          </button>
          <button
            type='button'
            className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full rounded-lg'
            onClick={() => {}}
          >
            I HAVE A PROBLEM <ArrowForward style={{ color: '#fff' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderDetails;

function numberWithCommas(x, CURRENCY) {
  if (!x) {
    x = 0;
  }

  if (!CURRENCY) {
    return x.toFixed(2);
  }

  if (isNaN(x)) return x;

  return x.toLocaleString('en-US', {
    style: 'currency',
    currency: CURRENCY,
  });
}
