import "./z_map.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { Icon, divIcon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";

import Countries_EN from '../Search/countries_en.json'
import ReactCountryFlag from "react-country-flag";
import { Search } from "@mui/icons-material";
import { numberWithCommas, onFormatUrl } from "../../helpers/GeneralHelper";
import { StorefrontOutline } from "react-ionicons";
import { GetRouteSearchParams } from "./AdminHelper";

const AdminMapCluster = (props) => {

    const { STATUS, Providers, Cities, Geopoints, PROVIDER, history, setProvider, mapRef, location } = props;


    let markers_providers = Providers.map((item) => {
        return {
            ...item,
            coordinates: [item.latitude, item.longitude],
            type: 'provider'
        };
    });

    let markers_cities = Cities.map((item) => {
        return {
            ...item,
            coordinates: [item.latitude, item.longitude],
            type: 'city'
        };
    });

    let markers_geopoints = Geopoints.map((item) => {
        return {
            ...item,
            coordinates: [item.latitude, item.longitude]
        };
    });

    if (STATUS === 'stores') {
        markers_cities = [];
        markers_geopoints = [];
    }
    else if (STATUS === 'cities') {
        markers_providers = [];
        markers_geopoints = [];
    }
    else if (STATUS === 'airports') {
        markers_providers = [];
        markers_cities = [];
    }
    else if (STATUS === 'stations') {
        markers_providers = [];
        markers_cities = [];
    }
    else if (STATUS === 'ports') {
        markers_providers = [];
        markers_cities = [];
    }
    else if (STATUS === 'places') {
        markers_providers = [];
        markers_cities = [];
    }
    else if (STATUS === 'parks') {
        markers_providers = [];
        markers_cities = [];
    }
    else if (STATUS === 'geopoints') {
        markers_providers = [];
        markers_cities = [];
    }
    else {
        markers_cities = [];
        markers_providers = [];
        markers_geopoints = [];
    }

    let markers_delivery = []

    // if (PROVIDER) {
    //     let store_delivery = PROVIDER.delivery || [];
    //     markers_delivery = store_delivery.map((item) => {
    //         const GEOCODE = item.geocode;
    //         const GEOLATITUDE = GEOCODE ? GEOCODE.latitude : 0;
    //         const GEOLONGITUDE = GEOCODE ? GEOCODE.longitude : 0;

    //         return {
    //             ...item,
    //             coordinates: [GEOLATITUDE, GEOLONGITUDE],
    //         };
    //     });

    //     markers_cities = [];
    //     markers_geopoints = [];
    //     markers_providers = [PROVIDER].map((item) => {
    //         const GEOCODE = item.geocode;
    //         const GEOLATITUDE = GEOCODE ? GEOCODE.latitude : 0;
    //         const GEOLONGITUDE = GEOCODE ? GEOCODE.longitude : 0;

    //         return {
    //             ...item,
    //             coordinates: [GEOLATITUDE, GEOLONGITUDE],
    //             type: 'provider'
    //         };
    //     });
    // }


    const customIcon = new Icon({
        iconUrl: "https://www.babonbo.com/assets/img/babonbo_marker.png",
        iconSize: [38, 38],
    });

    const providerIcon = new Icon({
        iconUrl: "https://www.babonbo.com/assets/img/babonbo_marker_provider.png",
        iconSize: [38, 38],
    });

    const airportIcon = new Icon({
        iconUrl: "https://www.babonbo.com/assets/img/babonbo_marker_airport.png",
        iconSize: [38, 38],
    });

    const stationIcon = new Icon({
        iconUrl: "https://www.babonbo.com/assets/img/babonbo_marker_station.png",
        iconSize: [38, 38],
    });

    const portIcon = new Icon({
        iconUrl: "https://www.babonbo.com/assets/img/babonbo_marker_port.png",
        iconSize: [38, 38],
    });

    const createCustomClusterIcon = (cluster) => {
        return new divIcon({
            html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
            className: "custom-marker-cluster",
            iconSize: [33, 33],
        });
    };

    // Calculate the average coordinates to set the center of the map
    const calculateAverageCoordinates = () => {

        let markers = [];

        if (STATUS === 'stores') {
            markers = markers_providers;
        }
        else if (STATUS === 'cities') {
            markers = markers_cities;
        }
        else if (STATUS === 'airports' || STATUS === 'stations' || STATUS === 'ports' || STATUS === 'parks' || STATUS === 'places') {
            markers = markers_geopoints;
        }

        // if (PROVIDER) {
        //     markers = markers_delivery;
        // }

        if (markers.length === 0) {
            return [33.33, 33.33]; // Default center if no markers
        }

        const totalLatitude = markers.reduce((acc, marker) => acc + marker.coordinates[0], 0);
        const totalLongitude = markers.reduce((acc, marker) => acc + marker.coordinates[1], 0);

        const averageLatitude = totalLatitude / markers.length;
        const averageLongitude = totalLongitude / markers.length;

        return [averageLatitude, averageLongitude];
    };

    const mapCenter = calculateAverageCoordinates();

    useEffect(() => {

        let routeParams = GetRouteSearchParams(location.search);
        let routeCountry = routeParams.country || '';
        let routeCity = routeParams.city || '';

        if ((mapRef.current && markers_providers.length > 0) || (mapRef.current && markers_cities.length > 0) || (mapRef.current && markers_geopoints.length > 0)) {

            if ((routeCountry && routeCountry !== 'all') || routeCity) {
                mapRef.current.setView(mapCenter, 6);
            }
            else {
                mapRef.current.setView(mapCenter, 3);
            }

        }
    }, [markers_providers, markers_cities, markers_geopoints, mapCenter]);


    // var Stadia_AlidadeSmooth = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}', {
    //     minZoom: 0,
    //     maxZoom: 20,
    //     attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    //     ext: 'png'
    // });

    // const onEachCountry = (country, layer) => {
    //     const countryName = country.properties.ADMIN;
    //     console.log(countryName);
    //     layer.bindPopup(countryName);

    //     layer.options.fillOpacity = Math.random(); //0-1 (0.1, 0.2, 0.3)
    //     // const colorIndex = Math.floor(Math.random() * this.colors.length);
    //     // layer.options.fillColor = this.colors[colorIndex]; //0

    //     // layer.on({
    //     //     click: alert('Country Clicked ===> '+ countryName),
    //     // });
    // };

    // const countryStyle = {
    //     color: "black",
    //     weight: 0.3,
    //     opacity: 1,
    // };

    return (

        <MapContainer center={mapCenter} zoom={5} ref={mapRef} className="w-full h-full" scrollWheelZoom={true}>
            <TileLayer
                attribution="Google Maps"
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"

                minZoom={0}
                maxZoom={20}
            />
            <MarkerClusterGroup
                chunkedLoading
                iconCreateFunction={createCustomClusterIcon}
            >

                {markers_providers.map((marker) => {
                    let finalIcon = customIcon;

                    if (marker.type === 'provider') {
                        finalIcon = providerIcon;
                    }


                    return (
                        <Marker position={marker.coordinates} icon={finalIcon} key={marker.id}>
                            <Popup>
                                <div className="w-full flex flex-col items-center justify-center">

                                    <div className="w-fll flex flex-col items-center p-2">

                                        <div className="flex items-center gap-x-2 mb-2">
                                            <div className='flex'>
                                                <img src={marker.img} className="rounded-full shrink-0 object-cover w-[80px] h-[80px] border border-zinc-200" />
                                            </div>

                                            {marker.status ? (
                                                <div className='bg-green-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
                                            ) : (
                                                <div className='bg-red-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
                                            )}
                                        </div>


                                        <div className="text-md font-bold rounded-xl flex pb-0 uppercase text-center">
                                            {marker.name}
                                        </div>

                                        <div className="flex items-center gap-x-2 mb-4">
                                            <div className='text-xs text-black font-normal capitalize'>
                                                {marker.city}
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-2">
                                            {parseFloat(marker.rating) > 0 && (
                                                <div className="flex text-xs items-center">
                                                    {parseFloat(marker.rating).toFixed(1)}
                                                    <img src={'/assets/img/star.svg'} className="ml-1 w-[10px] h-[10px]" alt="" />
                                                </div>
                                            )}
                                            <div className="w-1 h-1 rounded-full bg-gray-500" />
                                            {parseFloat(marker.reviews) > 0 && (
                                                <div className="flex text-xs">
                                                    {parseInt(marker.reviews)} reviews
                                                </div>
                                            )}
                                        </div>

                                        <div className="flex items-center gap-x-2">
                                            <div className='text-xs text-black font-normal capitalize'>
                                                orders: {parseInt(marker.total)}
                                            </div>
                                            <div className="w-1 h-1 rounded-full bg-gray-500" />
                                            <div className='text-xs text-black font-normal capitalize'>
                                                score: {parseInt(marker.score)}
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-2 mb-4">
                                            <div className='text-xs text-black font-normal capitalize'>
                                                {marker.products_count} products
                                            </div>
                                            <div className="w-1 h-1 rounded-full bg-gray-500" />
                                            <div className='text-xs text-black font-normal capitalize'>
                                                {marker.delivery.length} delivery points
                                            </div>
                                        </div>

                                        <div className="bg-babonbo-100 text-white rounded-lg flex items-center justify-center p-1 px-2 mt-2 cursor-pointer" onClick={() => setProvider((marker))}>
                                            <StorefrontOutline color={'#fff'} className="mr-2" /> Visit Store
                                        </div>

                                    </div>

                                </div>


                            </Popup>
                        </Marker>
                    )
                })}

                {markers_cities.map((marker) => {

                    const CountryFound = Countries_EN.find((item) => item.value === marker.country);
                    const CountryName = CountryFound ? CountryFound.label : '';

                    return (
                        <Marker position={marker.coordinates} icon={customIcon} key={marker.id}>
                            <Popup>
                                <div className="w-full flex flex-col items-center justify-center">

                                    <div className="flex justify-start">
                                        <ReactCountryFlag svg countryCode={marker.country} style={{ width: 40, height: 40 }} className="rounded-full bg-zinc-300 mr-1 object-cover mb-1" />
                                        {/* {marker.status ? (
                                            <div className='bg-green-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
                                        ) : (
                                            <div className='bg-red-500 w-[10px] h-[10px] shrink-0 rounded-full'></div>
                                        )} */}
                                    </div>

                                    <div className="w-fll flex flex-col items-center p-2">

                                        <div className="text-md font-bold rounded-xl flex pb-0 uppercase">
                                            {marker.en}
                                        </div>

                                        <div className="flex items-center gap-x-2">
                                            <div className='text-xs font-normal'>
                                                {CountryName}
                                            </div>
                                        </div>

                                        <div className="bg-babonbo-100 text-white rounded-lg flex items-center justify-center p-1 px-2 mt-2 cursor-pointer" onClick={() => {
                                            history.push(`?search=stores&sort=updatedAt&dir=desc&country=${marker.country}&city=${marker.id}&name=${marker.en}&lat=${marker.latitude}&lon=${marker.longitude}&range=100&status=true&approval=false`);
                                        }}>
                                            <Search /> Search in City
                                        </div>

                                    </div>

                                </div>


                            </Popup>
                        </Marker>
                    )
                })}

                {markers_delivery.map((marker, index) => {

                    const store_currency = PROVIDER?.currency || 'eur';

                    let finalIcon = customIcon;

                    if (marker.type === 'airport') {
                        finalIcon = airportIcon;
                    }
                    else if (marker.type === 'station') {
                        finalIcon = stationIcon;
                    }
                    else if (marker.type === 'port') {
                        finalIcon = portIcon;
                    }

                    return (
                        <Marker position={marker.coordinates} icon={finalIcon} key={onFormatUrl(marker.name + '_' + index)}>
                            <Popup>
                                <div className="w-full flex flex-col items-center justify-center">

                                    {/* <div className="flex justify-start">
                                        <ReactCountryFlag svg countryCode={marker.country} style={{ width: 40, height: 40 }} className="rounded-full bg-zinc-300 mr-1 object-cover mb-1" />
                                    </div> */}

                                    <div className="w-fll flex flex-col items-center p-2">

                                        <div className="text-md font-bold rounded-xl flex pb-0 uppercase text-center">
                                            {marker.name}
                                        </div>

                                        <div className="flex items-center gap-x-2">
                                            <div className='text-xs font-normal'>
                                                {numberWithCommas(parseFloat(marker.cost), store_currency)}
                                            </div>
                                        </div>

                                        {/* <div className="bg-babonbo-100 text-white rounded-lg flex items-center justify-center p-1 px-2 mt-2 cursor-pointer" onClick={() => {
                                            history.push(`?search=stores&sort=updatedAt&dir=desc&country=${marker.country}&city=${marker.id}&name=${marker.en}&lat=${marker.latitude}&lon=${marker.longitude}&range=100&status=true&approval=false`);
                                        }}>
                                            <Search /> Search in City
                                        </div> */}

                                    </div>

                                </div>


                            </Popup>
                        </Marker>
                    )
                })}

                {markers_geopoints.map((marker, index) => {

                    let finalIcon = customIcon;

                    if (marker.type === 'airport') {
                        finalIcon = airportIcon;
                    }
                    else if (marker.type === 'station') {
                        finalIcon = stationIcon;
                    }
                    else if (marker.type === 'port') {
                        finalIcon = portIcon;
                    }

                    let costs = marker.costs || [];
                    let firstCost = costs[0] ? parseFloat(costs[0]) : 0;
                    let secondCost = costs[costs.length - 1] ? parseFloat(costs[costs.length - 1]) : 0;

                    return (
                        <Marker position={marker.coordinates} icon={finalIcon} key={onFormatUrl(marker.name + '_' + index)}>
                            <Popup>
                                <div className="w-full flex flex-col items-center justify-center">

                                    <div className="w-fll flex flex-col items-center p-2">

                                        <div className="flex items-center gap-x-2 mb-2">
                                            <div className='flex'>
                                                {marker.stores.slice(0, 3).map((item, index) => {
                                                    return (
                                                        <img key={index} src={item.image} className="rounded-full shrink-0 object-cover w-[40px] h-[40px] border border-zinc-200" />
                                                    );
                                                })}
                                            </div>

                                            {marker.stores.length > 2 && (
                                                <p className="text-xs text-gray-600">
                                                    +{marker.stores.length - 2} more
                                                </p>
                                            )}
                                        </div>


                                        <div className="text-md font-bold rounded-xl flex pb-0 uppercase text-center">
                                            {marker.name}
                                        </div>

                                        <div className="flex items-center gap-x-2 mb-4">
                                            <div className='text-xs text-black font-normal capitalize'>
                                                {marker.city}
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-2">
                                            <div className='text-xs text-black font-normal capitalize'>
                                                Stores: {marker.stores.length}
                                            </div>
                                            <div className="w-1 h-1 rounded-full bg-gray-500" />
                                            <div className='text-xs text-black font-normal capitalize'>
                                                Products: {parseInt(marker.products)}
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-x-2 mb-5">
                                            <div className='text-xs text-black font-normal capitalize'>
                                                Delivery: {(parseFloat(firstCost) !== parseFloat(secondCost)) ? (
                                                    <>
                                                        {numberWithCommas(parseFloat(firstCost), 'eur')} - {numberWithCommas(parseFloat(secondCost), 'eur')}
                                                    </>
                                                ) : (
                                                    <>
                                                        {numberWithCommas(parseFloat(firstCost), 'eur')}
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="bg-babonbo-100 text-white rounded-lg flex items-center justify-center p-1 px-2 mt-2 cursor-pointer" onClick={() => {
                                            history.push(`?search=stores&sort=updatedAt&dir=desc&country=${marker.country}&city=${marker.id}&name=${marker.en}&lat=${marker.latitude}&lon=${marker.longitude}&range=100&status=true&approval=false`);
                                        }}>
                                            <Search /> Search in Geopoint
                                        </div>

                                    </div>

                                </div>


                            </Popup>
                        </Marker>
                    )
                })}


            </MarkerClusterGroup>

            {/* <GeoJSON
                style={countryStyle}
                data={mapData.features}
                onEachFeature={onEachCountry}
            /> */}
        </MapContainer >

    );
}

export default AdminMapCluster