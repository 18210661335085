import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import { onGetCurrencySymbol } from '../../helpers/GeneralHelper';

import { GetRouteSearchParams } from './AdminHelper';

import swal from 'sweetalert';
import StarReview from '../General/Stars';
import { EyeSharp } from 'react-ionicons';


const AdminOrders = (props) => {

    const { TRANSLATE, history, match, location } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [ORDERS, setOrders] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var filter_status = routeParams.status || null;
            var filter_store = routeParams.store || null;
            var filter_customer = routeParams.customer || null;

            var current_page = parseInt(page_id);

            onFetchOrders({
                page: current_page,
                sort: sort_id,
                direction: direction_id,
                filter_status,
                filter_store,
                filter_customer
            });

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchOrders = async ({ page, sort, direction, filter_status, filter_store, filter_customer }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                status: filter_status,
                store: filter_store,
                customer: filter_customer,
                l: LIMIT,
                partner: match.params.id
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/orders', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setOrders(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchOrders = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchOrders({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/orders/search', {
                query: query,
                partner: match.params.id
            }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setOrders(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setOrders([]);
            setLoading(false);
        }


    }

    const onSortOrders = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'date') {
            return 'createdAt'
        }
        else if (sort === 'total') {
            return 'cost_total'
        }
        else if (sort === 'subtotal') {
            return 'cost_subtotal'
        }
        else if (sort === 'd_cost') {
            return 'cost_delivery'
        }
        else if (sort === 'f_cost') {
            return 'cost_service_fee'
        }
        else if (sort === 'p_cost') {
            return 'cost_provider'
        }
        else {
            return sort
        }
    }

    const onChangePageOrders = ({ page, sort, direction, filter_status, filter_store, filter_customer }) => {

        let new_url = '/partners/' + match.params.id + '/orders/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }
        else {
            new_url = new_url + '?sort=updatedAt&dir=desc';
        }

        if (filter_status) {
            new_url = new_url + '&status=' + filter_status;
        }

        if (filter_store) {
            new_url = new_url + '&store=' + filter_store;
        }

        if (filter_customer) {
            new_url = new_url + '&customer=' + filter_customer;
        }

        history.push(new_url);
    }

    const onSelectOrder = (order_id) => {
        history.push('/partners/' + match.params.id + '/orders/profile/' + order_id);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/partners/' + match.params.id + '/customers/profile/' + customer_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/partners/' + match.params.id + '/stores/profile/' + store_id);
    }

    const onSelectReview = (review_id) => {
        if (review_id) {
            history.push('/partners/' + match.params.id + '/reviews/profile/' + review_id);
        }
    }

    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }


    //Logic
    var routeParams = GetRouteSearchParams(location.search);

    var currentSort = routeParams.sort || null;
    var currentDirection = routeParams.dir || null;

    var currentFilterStatus = routeParams.status || null;
    var currentFilterStore = routeParams.store || null;
    var currentFilterCustomer = routeParams.customer || null;

    //Table Data
    const columns = [
        // {
        //     name: "id",
        //     label: "ID",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         customBodyRender: (id) => {

        //             return (
        //                 <Typography variant='span' fontWeight={'bold'} onClick={()=> onSelectOrder(id)} style={{cursor: 'pointer'}}>
        //                     #{id}
        //                 </Typography>
        //             )
        //         }
        //     },
        // },
        {
            name: "id",
            label: "#",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <div onClick={() => onSelectOrder(id)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#000', width: 40, height: 40, borderRadius: 50, marginLeft: 10, cursor: 'pointer' }}>
                            <EyeSharp color={'#fff'} />
                        </div>

                    )
                }
            },
        },
        {
            name: "store_img",
            label: "STORE",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const orderObx = ORDERS[rowIndex] || {};
                    const storeObx = orderObx.store || {};

                    const store_id = storeObx.id || '';
                    const store_name = storeObx.name || '';
                    const store_img = storeObx.img || '';

                    return (
                        <Chip
                            avatar={<Avatar alt={store_name} src={store_img} />}
                            label={store_name.toLowerCase()}
                            variant="outlined"
                            size='medium'
                            style={{ textTransform: 'capitalize' }}
                            onClick={() => onSelectStore(store_id)}

                        />
                    )
                }
            },

        },
        {
            name: "customer_img",
            label: "CUSTOMER",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const orderObx = ORDERS[rowIndex] || {};
                    const customerObx = orderObx.customer || {};

                    const customer_id = customerObx.id || '';
                    const customer_name = customerObx.name || '';
                    const customer_img = customerObx.img || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                avatar={<Avatar alt={customer_name} src={customer_img} />}
                                label={customer_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectCustomer(customer_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    let color = 'default';

                    if (label === 'pending') {
                        color = 'warning';
                    }
                    else if (label === 'accepted') {
                        color = 'success';
                    }
                    else if (label === 'rejected') {
                        color = 'error';
                    }
                    else if (label === 'canceled') {
                        color = 'error';
                    }
                    else if (label === 'delivering') {
                        color = 'info';
                    }
                    else if (label === 'delivered') {
                        color = 'default';
                    }
                    else if (label === 'complete') {
                        color = 'success';
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} color={color} size='small' />
                        </div>
                    )

                },
                filterOptions: {
                    names: ['pending', 'accepted', 'delivering', 'delivered', 'complete', 'rejected', 'canceled']
                }
            },
        },
        {
            name: "checkin",
            label: "CHECKIN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "checkout",
            label: "CHECKOUT",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }

            },
        },
        {
            name: 'products',
            label: 'PRODUCTS',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            }

        },
        {
            name: "p_cost",
            label: "TOTAL",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "review_pro",
            label: "TO PROVIDER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (review) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={review.rank} size={20} onClick={() => onSelectReview(review.id)} />
                        </div>
                    )
                }

            },
        },
        {
            name: "review_cus",
            label: "TO CUSTOMER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (review) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={review.rank} size={20} onClick={() => onSelectReview(review.id)} />
                        </div>
                    )
                }

            },
        },
        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        }


    ];

    const data = ORDERS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let currency = item.scurrency || 'eur';
        let symbol = onGetCurrencySymbol(currency);

        let store_obx = item.store || {};
        let customer_obx = item.customer || {};

        let total = item.total || 0;
        let subtotal = item.subtotal || 0;
        let d_cost = item.d_cost || 0;
        let r_cost = item.r_cost || 0;
        let p_cost = item.p_total || 0;
        let f_cost = item.f_cost * 2 || 0;

        let new_order = {

            id: item.id,
            store_id: store_obx.id,
            store_name: store_obx.name,
            store_img: store_obx.img,

            customer_id: customer_obx.customer_id,
            customer_name: customer_obx.customer,
            customer_img: customer_obx.customer_img,

            status: item.status,

            date: moment(item.updated).format('DD MMMM YYYY | HH:mm'),

            products: item.products.length || 0,

            total: symbol + '' + total.toFixed(2),
            subtotal: symbol + '' + subtotal.toFixed(2),
            d_cost: symbol + '' + d_cost.toFixed(2),
            r_cost: symbol + '' + r_cost.toFixed(2),
            f_cost: symbol + '' + f_cost.toFixed(2),
            p_cost: symbol + '' + p_cost.toFixed(2),

            review_pro: item.review_pro,
            review_cus: item.review_cus,

            checkin: moment(item.checkin).format('DD MMMM YYYY'),
            checkout: moment(item.checkout).format('DD MMMM YYYY'),

            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY'),

        };



        return new_order
    })

    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageOrders({
                        page: tableState.page,
                        sort: onSortOrders(sortFilter),
                        direction: sortDirection,
                        filter_status: currentFilterStatus,
                        filter_store: currentFilterStore,
                        filter_customer: currentFilterCustomer
                    });

                    break;
                case 'sort':

                    onChangePageOrders({
                        page: tableState.page,
                        sort: onSortOrders(sortFilter),
                        direction: sortDirection,
                        filter_status: currentFilterStatus,
                        filter_store: currentFilterStore,
                        filter_customer: currentFilterCustomer
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchOrders(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchOrders(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Order By ID',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Orders with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        onFilterChange: (column, filterList, type) => {

            //Check if Current Filter is Active
            if (column === 'status') {
                const filterValue = filterList[3];
                currentFilterStatus = filterValue;
            }

            onChangePageOrders({
                page: 0,
                sort: onSortOrders(currentSort),
                direction: currentDirection,
                filter_status: currentFilterStatus,
                filter_store: currentFilterStore,
                filter_customer: currentFilterCustomer
            });

        },
        elevation: 0,
        download: false,
        print: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true

    };




    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Orders →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}



export default AdminOrders


