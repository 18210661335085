import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import {postApiPrivate} from '../../api';

import LoaderMaster from '../Loaders/Loaders';

import {ShirtOutline, ReaderOutline, ChatbubbleEllipsesOutline, StarOutline, LogOutOutline, LogInOutline, StorefrontOutline, CalendarOutline, ShieldCheckmarkOutline, MapOutline, LocationOutline, GlobeOutline, CashOutline, SchoolOutline, CopyOutline, MegaphoneOutline, HandLeftOutline, BodyOutline, LogoAmazon, NotificationsOutline, UmbrellaOutline} from 'react-ionicons'
import SearchMap from '../Search/SearchMap';
import moment from 'moment/moment';


const AdminDashboard = (props) => {

  const {TRANSLATE, USER, history, match} = props;
  
  const [isLoading, setLoading] = useState(true);
  const [isLoadingStores, setLoadingStores] = useState(true);

  const [DASHBOARD, setDashboard] = useState({});
  const [STORES, setStores] = useState([]);

  useEffect(()=>{

    if(USER.admin){
      onFetchDashboard();
    }
    else{
      setLoading(false)
      swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

      history.push('/logout')
    }
    
  }, []);

  const onFetchDashboard = async() => {

      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

      try{

          setLoading(true);
      
          const DATA_RESPONSE = await postApiPrivate('/admin/query/delivery', {} , JWT);
          
          if(DATA_RESPONSE.error){

            setLoading(false);

              console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);

              return alert('Error Loading the dashboard');
          }
  
          const DASHBOARD_DATA = DATA_RESPONSE || {};
  
          setDashboard(DASHBOARD_DATA);
          setLoading(false);

         
  
          console.log("FETCHING DASHBOARD ===> ", DASHBOARD_DATA);

      }
      catch(e){

          setLoading(false);

          alert('Error Loading the dashboard');
      }

      

  }


 

  if(isLoading){
    return(
        <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
    );
  }

  //Dashboard
  const {
       airports,
       stations,
       ports,
       cities
  } = DASHBOARD

  let MenuClass = 'col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
  let MenuStyle = {
      borderRadius: 20,
      fontSize: 24,
      fontWeight: 'bold'
  }


  let MasterAirports = ConvertObjectToArray(DASHBOARD.master_airports || {}) || [];
  let MasterStations = ConvertObjectToArray(DASHBOARD.master_stations || {}) || [];
  let MasterPorts = ConvertObjectToArray(DASHBOARD.master_ports || {}) || [];
  let MasterCities = ConvertObjectToArray(DASHBOARD.master_cities || {}) || [];

  let CountAirports = MasterAirports.map((item)=> item.value).reduce((partialSum, a) => partialSum + a, 0);
  let CountStations = MasterStations.map((item)=> item.value).reduce((partialSum, a) => partialSum + a, 0);
  let CountPorts = MasterPorts.map((item)=> item.value).reduce((partialSum, a) => partialSum + a, 0);
  let CountCities = MasterCities.map((item)=> item.value).reduce((partialSum, a) => partialSum + a, 0);
   


  return(
    <>
       <div className='container-fluid mb-5' style={{background: '#f0f5f9'}}> 
            
            {USER.admin ? (
                <>
                    <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>
                        <h1 style={{fontSize: 60, fontWeight: 100}}>Delivery Locations <span style={{fontWeight: 'bold'}}>in Italy</span></h1>
                    </div>

                    <div className='row px-xl-5 px-2 mx-2 mb-2 justify-content-center'>

                        <Link to={'/admin/analytics'} className={MenuClass} style={MenuStyle}>
                            <h1 style={{fontSize: 55}}>
                              {MasterCities.length}
                            </h1>
                            <p style={{fontSize: 18, fontWeight: 100}}>
                              Total Cities
                            </p>
                        </Link>

                        <Link to={'/admin/analytics'} className={MenuClass} style={MenuStyle}>
                            <h1 style={{fontSize: 55}}>
                              {MasterAirports.length}
                            </h1>
                            <p style={{fontSize: 18, fontWeight: 100}}>
                              Total Airports
                            </p>
                        </Link>

                        <Link to={'/admin/analytics'} className={MenuClass} style={MenuStyle}>
                            <h1 style={{fontSize: 55}}>
                              {MasterStations.length}
                            </h1>
                            <p style={{fontSize: 18, fontWeight: 100}}>
                              Total Stations
                            </p>
                        </Link>

                        <Link to={'/admin/analytics'} className={MenuClass} style={MenuStyle}>
                            <h1 style={{fontSize: 55}}>
                              {MasterPorts.length}
                            </h1>
                            <p style={{fontSize: 18, fontWeight: 100}}>
                              Total Ports
                            </p>
                        </Link>

                    </div>


                    <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>
                        <Link to={'/admin/analytics'} className={MenuClass} style={MenuStyle}>
                            <h1 style={{fontSize: 55}}>
                              {CountCities}
                            </h1>
                            <p style={{fontSize: 18, fontWeight: 100}}>
                              Providers in Cities
                            </p>
                        </Link>
                        
                        <Link to={'/admin/analytics'} className={MenuClass} style={MenuStyle}>
                            <h1 style={{fontSize: 55}}>
                              {CountAirports}
                            </h1>
                            <p style={{fontSize: 18, fontWeight: 100}}>
                              Providers to Airports
                            </p>
                        </Link>

                        <Link to={'/admin/analytics'} className={MenuClass} style={MenuStyle}>
                            <h1 style={{fontSize: 55}}>
                              {CountStations}
                            </h1>
                            <p style={{fontSize: 18, fontWeight: 100}}>
                             Providers in Stations
                            </p>
                        </Link>

                        <Link to={'/admin/analytics'} className={MenuClass} style={MenuStyle}>
                            <h1 style={{fontSize: 55}}>
                              {CountPorts}
                            </h1>
                            <p style={{fontSize: 18, fontWeight: 100}}>
                              Providers in Ports
                            </p>
                        </Link>

                        


                    </div>

                    
                    <div className='row px-xl-5 px-2 mb-5 justify-content-center'>
                      
                        <div className='col-xl-4 col-md-6 col-12'>
                            <div className='bg-light mb-5' style={{borderRadius: 20, padding: 20}}>
                            
                                {MasterAirports.map((item)=> {

                                    return (
                                        <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=pending'}>
                                            <h4 style={{fontWeight: 100, fontSize: 'x-large'}}>{item.point}</h4>
                                            <h4 style={{color: '#000', fontSize: 'x-large'}}>{item.value}</h4>
                                        </Link>
                                    )
                                })}
                            
                                <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc'}>
                                    <h4 style={{fontWeight: 100, fontSize: 'x-large'}}>Total Airports</h4>
                                    <h4 style={{color: '#000', fontSize: 'x-large'}}>{airports ? parseInt(airports) : 0}</h4>
                                </Link>

                            </div>
                        </div>

                        <div className='col-xl-4 col-md-6 col-12'>
                            <div className='bg-light mb-5' style={{borderRadius: 20, padding: 20}}>
                            
                                {MasterStations.map((item)=> {

                                    return (
                                        <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=pending'}>
                                            <h4 style={{fontWeight: 100, fontSize: 'x-large'}}>{item.point}</h4>
                                            <h4 style={{color: '#000', fontSize: 'x-large'}}>{item.value}</h4>
                                        </Link>
                                    )
                                })}
                            
                                <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc'}>
                                    <h4 style={{fontWeight: 100, fontSize: 'x-large'}}>Total Stations</h4>
                                    <h4 style={{color: '#000', fontSize: 'x-large'}}>{stations ? parseInt(stations) : 0}</h4>
                                </Link>

                            </div>
                        </div>

                        <div className='col-xl-4 col-md-6 col-12'>
                            <div className='bg-light mb-5' style={{borderRadius: 20, padding: 20}}>
                            
                                {MasterPorts.map((item)=> {

                                    return (
                                        <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=pending'}>
                                            <h4 style={{fontWeight: 100, fontSize: 'x-large'}}>{item.point}</h4>
                                            <h4 style={{color: '#000', fontSize: 'x-large'}}>{item.value}</h4>
                                        </Link>
                                    )
                                })}
                            
                                <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc'}>
                                    <h4 style={{fontWeight: 100, fontSize: 'x-large'}}>Total Ports</h4>
                                    <h4 style={{color: '#000', fontSize: 'x-large'}}>{ports ? parseInt(ports) : 0}</h4>
                                </Link>

                            </div>
                        </div>

                        <div className='col-xl-4 col-md-6 col-12'>
                            <div className='bg-light mb-5' style={{borderRadius: 20, padding: 20}}>
                            
                                {MasterCities.map((item)=> {

                                    return (
                                        <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=pending'}>
                                            <h4 style={{fontWeight: 100, fontSize: 'x-large'}}>{item.point}</h4>
                                            <h4 style={{color: '#000', fontSize: 'x-large'}}>{item.value}</h4>
                                        </Link>
                                    )
                                })}
                            
                                <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc'}>
                                    <h4 style={{fontWeight: 100, fontSize: 'x-large'}}>Total Cities</h4>
                                    <h4 style={{color: '#000', fontSize: 'x-large'}}>{cities ? parseInt(cities) : 0}</h4>
                                </Link>

                            </div>
                        </div>

                          
                    </div>

                    <div style={{width: '100%', height: 200}}></div>

                </>
            ) : (
                <>
                    {/* <h1>Not Authorized</h1> */}
                </>
            )}
               
      </div>
    </>
      
  )


}


const ConvertObjectToArray = (object) => {

    const groupArrays = Object.keys(object).map((point) => {
        return {
          point,
          value: parseInt(object[point])
        };
    });

    //Reorder
    const finalArray = groupArrays.sort((a, b) => b.value - a.value);

    return finalArray

}
  
function numberWithCommas(x, CURRENCY) {
	if(isNaN(x)) return x;

  return (x).toLocaleString('en-US', {
    style: 'currency',
    currency: CURRENCY,
  });

}

const MenuAdminLeft = (props) => {

  const {TRANSLATE, USER, match} = props;

  let MenuClass = 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
  let MenuStyle = {
      borderRadius: 20,
      fontSize: 18,
      fontWeight: 'bold'
  }

  return (
      <>
        <div className='row mb-5 justify-content-center p-0'>
              
                <Link to={'/admin/stores'} className={MenuClass} style={MenuStyle}>
                    <StorefrontOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    {TRANSLATE.menu_stores}
                </Link>

                <Link to={'/admin/users'} className={MenuClass} style={MenuStyle}>
                    <BodyOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    Users
                </Link>

                <Link to="/admin/partners" className={MenuClass} style={MenuStyle}>
                    <HandLeftOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    Partners
                </Link>
                
                <Link to={'/admin/orders'} className={MenuClass} style={MenuStyle}>
                    <ReaderOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    {TRANSLATE.menu_orders}
                </Link>

                <Link to={'/admin/products'} className={MenuClass} style={MenuStyle}>
                    <ShirtOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    {TRANSLATE.menu_products}
                </Link>

                <Link to="/admin/approvals" className={MenuClass} style={MenuStyle}>
                      <ShieldCheckmarkOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                      {TRANSLATE.menu_approvals}
                </Link>
                
                <Link to={'/admin/inbox'} className={MenuClass} style={MenuStyle}>
                    <ChatbubbleEllipsesOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    {TRANSLATE.menu_messages}
                </Link>

                <Link to={'/admin/calendary'} className={MenuClass} style={MenuStyle}>
                    <CalendarOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    Calendary
                </Link>

                <Link to={'/admin/reviews'} className={MenuClass} style={MenuStyle}>
                    <StarOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    {TRANSLATE.reviews}
                </Link>

               
        </div>
      </>
  )
}

const MenuAdminRight = (props) => {

  const {TRANSLATE, USER, match} = props;

  let MenuClass = 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
  let MenuStyle = {
      borderRadius: 20,
      fontSize: 18,
      fontWeight: 'bold'
  }

  return (
      <>
        <div className='row mb-5 justify-content-center p-0'>

            <Link to="/admin/map" className={MenuClass} style={MenuStyle}>
                <MapOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                {TRANSLATE.menu_map}
            </Link>
        
            <Link to="/admin/locations" className={MenuClass} style={MenuStyle}>
                <LocationOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                {TRANSLATE.menu_locations}
            </Link>
        
            <Link to="/admin/geopoints" className={MenuClass} style={MenuStyle}>
                <GlobeOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                {TRANSLATE.menu_geopoints}
            </Link>

            <Link to="/admin/payments" className={MenuClass} style={MenuStyle}>
                <CashOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                {TRANSLATE.menu_payments}
            </Link>
            
            <Link to="/admin/marketing" className={MenuClass} style={MenuStyle}>
                <MegaphoneOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                {TRANSLATE.menu_marketing}
            </Link>
            
            <Link to="/admin/notifications" className={MenuClass} style={MenuStyle}>
                <NotificationsOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                Notifications
            </Link>
    
            <Link to="/admin/brands"  className={MenuClass} style={MenuStyle}>
                <LogoAmazon color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                Brands
            </Link>

            <Link to="/admin/listing" className={MenuClass} style={MenuStyle}>
                <UmbrellaOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                Listing
            </Link>

        
            {USER.name ? (
                <Link to="/logout" className={MenuClass} style={MenuStyle}>
                    <LogOutOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    {TRANSLATE.logout}
                </Link>
            ) : (
                <Link to="/login" className={MenuClass} style={MenuStyle}>
                    <LogInOutline color={'#26BFBF'} width="40px" height="40px" style={{marginBottom: 5}}/>
                    {TRANSLATE.login}
                </Link>
            )}

        </div>
      </>
  )
}

export default AdminDashboard
