import React from 'react'


const LoaderMaster = ({title, type}) => {

    if(type !== 'secondary'){
        return (

             <div className="flex-container" id="loadingFlex" style={{flexDirection: 'column'}}>    
                <img src="/assets/favicon/apple-192x192.png" style={{width: 100, height: 100, borderRadius: '50%', animation: 'animbase 2s ease infinite forwards'}} />
            </div>
        )
    }
    return (
        <div className="flex-container" id="loadingFlex" style={{flexDirection: 'column'}}>
            
                <div className="material-spinner" style={{borderTop: (type === "secondary" ? "5px #26BFBF solid" : "5px #fd6270 solid")}}></div>
                {title && (
                    <h2 style={{fontSize: 14, fontWeight: 400, marginTop: 10}}>{title}</h2>
                )}
           
        </div>
    )
    
    return (
        <div className="flex-container" id="loadingFlex" style={{flexDirection: 'column'}}>
           
            <div className="lds-circle">
                <div style={{background: (type === 'secondary' ? '#26BFBF' : '#fd6270')}}></div>
            </div>
            
            {title && (
                <h2 style={{fontSize: 14, fontWeight: 400}}>{title}</h2>
            )}
            
        </div>
    )
}

export default LoaderMaster