import React, { useState } from 'react';

import TimezoneSelect from 'react-timezone-select'


const TimezoneSelector = ({ selectedTimezone, onSelectTimezone }) => {


  const handleChange = (selected) => {
    onSelectTimezone(selected); // Pass the selected timezone value to the parent component
  };


  return (
      <TimezoneSelect
          value={selectedTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
          onChange={handleChange}
        />
  );
};

export default TimezoneSelector;