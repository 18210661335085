import React, { useState } from 'react';
import './range_slider.css';

const RangeSlider = () => {
  const [values, setValues] = useState([4, 6]);

  const handleChange = (event, index) => {
    const newValue = parseInt(event.target.value);
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = newValue;
      return newValues;
    });
  };

  const getLeftValue = () => (values[0] / 11) * 100;
  const getRightValue = () => 100 - (values[1] / 11) * 100;

  return (
    <div className='slider-container'>
      <div className='slider'>
        <input
          type='range'
          min='0'
          max='11'
          value={values[0]}
          onChange={(e) => handleChange(e, 0)}
          className='thumb thumb--left'
        />
        <input
          type='range'
          min='0'
          max='11'
          value={values[1]}
          onChange={(e) => handleChange(e, 1)}
          className='thumb thumb--right'
        />
        <div className='slider__track'></div>
        <div
          className='slider__range'
          style={{
            left: `${getLeftValue()}%`,
            right: `${getRightValue()}%`,
          }}
        ></div>
        <div className='slider__values'>
          <div className='slider__value' style={{ left: `${getLeftValue()}%` }}>
            {values[0]}
          </div>
          <div className='slider__value' style={{ left: `${100 - getRightValue()}%` }}>
            {values[1]}
          </div>
        </div>
      </div>
      <div className='slider__labels'>
        <span>0</span>
        <span>11</span>
      </div>
    </div>
  );
};

export default RangeSlider;
