import React, {useState, useEffect, useRef} from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { onUpdateCart } from '../../actions';

import { postApiPrivate } from '../../api';

import Countries_EN from '../../components/Search/countries_en.json';


const SearchCity = (props) => {

    const { onClose } = props; 

    const {TRANSLATE, placeholder, history, onFetchUser, LANGUAGE, onUpdateLocation} = props;

    const inputRef = useRef();

    const [isLoading, setLoading] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [SearchResults, setSearchResults] = useState([]);
    const [SearchInput, setSearchInput] = useState('');

    useEffect(()=> {

        inputRef.current.focus();

    }, [])
 
    const onUpdateSearch = async(search) => {

        setFocused(false);

        setSearchInput(search[LANGUAGE]);

        console.log("CITY ==> ", search);
            
        onClose();

        onUpdateLocation(search)
        
    }

    const onSearchCities = async(city) => {

        setSearchInput(city);

        if(!city || city.length <= 3){
            setFocused(false);
            setSearchResults([]);

            return 
        }

        //Fetch

        setLoading(true);
        
        try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/locations/search', {query: city, lang: LANGUAGE} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if(filtredResults.length > 0){
                setFocused(true);
                setSearchResults(filtredResults);
            }

            setLoading(false);
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);

            setFocused(false);
            setSearchResults([]);
            setLoading(false);
        }
      
           
    }

    const onSearchKeyUp = async(event) => {
        event.preventDefault();

        if (event.keyCode === 13) {
            event.target.blur();

            if(SearchResults.length>0){
                onUpdateSearch(SearchResults[0]);
            }
        }
    }


    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 flex-col " style={{zIndex: 1000}}>
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
                <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col slider-in">
                    <div className="flex justify-between p-8 border-b border-zinc-200 bg-white z-20">
                        {/* <h2>Search a City</h2> */}
                        <div onClick={()=> onClose()}>
                            <img className='w-[150px] mr-4' src='/assets/img/babonbo_logo_provider.png' />
                        </div>
                        <img className="cursor-pointer" src="/assets/img/close.svg" onClick={() => onClose()} />
                    </div>
                    
                    <div className='p-3 flex flex-col'>
                        <div className="input-group mt-3">
                
                            <input className="bg-white py-3 ring-2 ring-bagreen-100" type="text" placeholder="Search by city name..."
                                    onFocus={()=> setFocused(true)}  
                                    onChange={(e)=> onSearchCities(e.target.value)} 
                                    onKeyUp={(e)=> onSearchKeyUp(e)}
                                    value={SearchInput}
                                    autoComplete="new-password"
                                    ref={inputRef}
                        
                            />

                            {isLoading ? (
                                <div className="w-[54px] h-[54px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute top-[3px] right-[3px] z-30">
                                    <img className='animate-spin w-[20px] h-[20px]' src="/assets/img/loader-white.png" />
                                </div>        
                            ) : (
                                <div className="w-[54px] h-[54px] rounded-full bg-bagreen-100 hover:bg-bagreen-200 cursor-pointer flex justify-center items-center absolute top-[3px] right-[3px] z-30">
                                    <img src="/assets/img/search.svg" style={{filter: 'invert(100%)'}} />
                                </div>
                            )}
                            
                
                        

                        </div>

                        <div className="w-full bg-white rounded-lg text-black z-30 overflow-auto" style={{height: '100vh'}}>
                                
                                {SearchResults.map((item, index)=> {

                                    const country_index = Countries_EN.findIndex((cty)=> cty.value === item.country);
                                    const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
                                    const country_name = country_obx ? country_obx.label : null

                                    return (

                                        <div className="w-full p-3 my-3 flex items-center cursor-pointer hover:bg-zinc-100" onClick={()=> onUpdateSearch(item)} key={index}>
                                            <img className='w-[20px] h-[20px] mr-3 mb-auto mt-1' src="/assets/img/location.svg" />
                                                {item[LANGUAGE]} - ({country_name || item.country})
                                        </div>
                                    )
                                    
                                })}

                                <div style={{height: 200}}></div>
                                
                        </div>
                        
                    </div>
                    
                </div>    

            </div>
        </>
    )
}



const mapStateToProps = state => {
  return ({
    USER: state.user,
    PROVIDER: state.provider,
    CART: state.cart,
    PRODUCT: state.product,
    PRODUCTS: state.products
  });
}

const mapDispatchToProps = dispatch => ({
  onUpdateCart: (cart) => dispatch(onUpdateCart(cart))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchCity);
