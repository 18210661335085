import React, { useEffect, useState } from 'react';

import {postApiPrivate} from '../../api';

import {Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';
import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import { Add, CardGiftcard } from '@mui/icons-material';
import { numberWithCommas } from '../../helpers/GeneralHelper';
import { GiftOutline, ReaderOutline } from 'react-ionicons';

const AdminTransactions = (props) => {

    const {TRANSLATE, history, match, location, USER} = props;

    const [isLoading, setLoading] = useState(true);
     
    const [searchText, setSearchText] = useState('');
 
    const [PAGE, setPage] = useState(0);

    const [BANK, setTransactions] = useState([]); 
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT*(PAGE+1);

    if(TOTAL < CURRENT){
        CURRENT = TOTAL;
    }

    useEffect(()=> {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if(!JWT){
            return history.replace('/login');
        }
        else{

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;
        
            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;
           
            var current_page = parseInt(page_id);

            if(USER.admin){
                onFetchTransactions({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else{
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')
            
                history.push('/logout')
            }

        }

        window.scrollTo(0,0);

    }, [match.params.page, location.search]);

    const onFetchTransactions = async ({page, sort, direction}) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
        setLoading(true);

        try{

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/bank', FORM , JWT);

            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setTransactions(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0,0)
            

        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchTransactions = async(query) => {

        setSearchText(query);

        if(!query || query.length == 0){

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchTransactions({
                page: current_page
            });

            return 
        }

        if(query.length <= 3){

            return 
        }


        try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/bank/search', {query: query} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setTransactions(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);
            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);

            setTotal(0);
            setTransactions([]);
            setLoading(false);
        }
      
           
    }

    const onSortTransactions = (sort) => {

        if(sort === 'id'){
            return 'objectId'
        }
        else if(sort === 'name'){
            return 'search_name'
        }
        else if(sort === 'img'){
            return 'photo'
        }
        else if(sort === 'lang'){
            return 'language'
        }
        else if(sort === 'isCompleted'){
            return 'setup'
        }
        else{
            return sort
        }
    }

    const onChangePageTransactions = ({page, sort, direction}) => {
        let new_url = '/admin/bank/'+page;

        if(sort){
            new_url = new_url+'?sort='+sort+'&dir='+direction;
        }

        history.push(new_url);
    }

    const onSelectTransaction = (transaction_id) => {
        history.push('/admin/bank/profile/'+transaction_id);
    }


    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={()=> onSelectTransaction(id)} style={{cursor: 'pointer'}}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "manager",
            label: "MANAGER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const transactionObx = BANK[rowIndex] || {};

                    const ownerObx = transactionObx.owner || {};
                    const owner_id = ownerObx.id || '';
                    const owner_name = ownerObx.name || '';
                    const owner_img = ownerObx.img || '';

                    
                    if(owner_id){
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={owner_name} src={owner_img} />}
                                    label={owner_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{textTransform: 'capitalize'}}
                                    onClick={()=> history.push('/admin/users/profile/'+owner_id)}
                                />
                            </div>
                            
                        )
                    }
                    else{
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },
            
        },

        {
            name: "type",
            label: "TYPE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const transactionObx = BANK[rowIndex] || {};

                    const transaction_type = transactionObx.type || '';

                    if(transaction_type === 'debit'){
                        return (
                            <Chip label={transaction_type} size='small' color='error' className='uppercase' />
                        )
                    }
                    else if(transaction_type === 'credit'){
                        return (
                            <Chip label={transaction_type} size='small' color='success' className='uppercase' />
                        )
                    }

                    return (
                        <Chip label={transaction_type} size='small'  className='uppercase' />
                    )
                }
            },
        },


        {
            name: "amount",
            label: "AMOUNT",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const transactionObx = BANK[rowIndex] || {};

                    const transaction_amount = transactionObx.amount || 0;
                    const transaction_currency = transactionObx.currency || 'usd';

                    return (
                        
                        <Chip label={numberWithCommas(transaction_amount, transaction_currency)} size='medium' />

                    )
                }
            },
        },
      
        {
            name: "order",
            label: "ORDER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const transactionObx = BANK[rowIndex] || {};

                    const order_id = transactionObx.order || '';
                    
                    if(order_id){
                        return (
                            <div>
                                <Chip
                                    avatar={<ReaderOutline color={'#26BFBF'} />}
                                    label={order_id.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{textTransform: 'capitalize'}}
                                    onClick={()=> history.push('/admin/orders/profile/'+order_id)}
                                />
                            </div>
                            
                        )
                    }
                    else{
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },
            
        },

        {
            name: "giftcard",
            label: "GIFT CARD",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const transactionObx = BANK[rowIndex] || {};

                    const giftcard_id = transactionObx.giftcard || '';
                    
                    if(giftcard_id){
                        return (
                            <div>
                                <Chip
                                    avatar={<GiftOutline color={'#fd6270'} />}
                                    label={giftcard_id.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{textTransform: 'capitalize'}}
                                    onClick={()=> history.push('/admin/giftcards/profile/'+giftcard_id)}
                                />
                            </div>
                            
                        )
                    }
                    else{
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },
            
        },
       
        {
            name: "manager",
            label: "MANAGER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const transactionObx = BANK[rowIndex] || {};

                    const managerObx = transactionObx.manager || {};
                    const manager_id = managerObx.id || '';
                    const manager_name = managerObx.name || '';
                    const manager_img = managerObx.img || '';

                    
                    if(manager_id){
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                                    label={manager_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{textTransform: 'capitalize'}}
                                    onClick={()=> history.push('/admin/users/profile/'+manager_id)}
                                />
                            </div>
                            
                        )
                    }
                    else{
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },
            
        },
        {
            name: 'isDeleted',
            label: 'DELETED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const transactionObx = BANK[rowIndex] || {};

                    const isDeleted = transactionObx.isDeleted || false;

                    if(isDeleted){
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                 <Chip size='small' label="Deleted" color='error' />
                            </div>
                           
                        )
                    }
                    else{
                        return (
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                 <Chip size='small' label="NO" />
                            </div>
                           
                        )
                    }
                }
            }

        },
        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" }}),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        
    ];
  
    const data = BANK.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_transaction = {
            id: item.id,
            owner: item.owner,
            type: item.type,
            amount: item.amount,
            order: item.order,
            giftcard: item.giftcard,
            isDeleted: item.isDeleted || false,
            manager: item.manager || {},
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY')
        };

        return new_transaction
    })

    const customToolbar = () => (
        <Tooltip disableFocusListener title="Add Transaction">
          <IconButton onClick={() =>history.push('/admin/bank/profile/new')}>
            <Add />
          </IconButton>
        </Tooltip>
    );

    const options = {
        filter: false,
        print: false,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        filterType: 'dropdown',
        responsive: 'standard',
       // customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageTransactions({
                        page: tableState.page,
                        sort: onSortTransactions(sortFilter),
                        direction: sortDirection
                    });
                    
                    break;
                case 'sort':
                    
                    onChangePageTransactions({
                        page: tableState.page,
                        sort: onSortTransactions(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchTransactions(e.target.value);
            },
            onKeyUp:(e) => {
                if(e.keyCode === 13){
                    onSearchTransactions(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Transaction By Name',
        textLabels: {
            body: {
                noMatch: isLoading ? 
                    'Searching Transactions with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        
    
    
    };
 

    return (
        <div className="top_container small_padding" style={{background: '#f0f5f9'}}>
            <MUIDataTable
                title={
                    <div className='font-light md:text-5xl text-xl text-lg py-8'>

                        {'Transactions Found → '}
                        
                        {isLoading ? (

                            <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />
                        
                            ) : (

                            <>
                               {' '}<span style={{color: '#26BFBF'}}>{CURRENT}</span>{'/'+TOTAL}
                            </>

                        )} 
                         
                    </div>
                } 
                data={data}
                columns={columns}
                options={options}
            />
        </div>
    );
    
}  



export default AdminTransactions

