import React, {useState, useEffect} from "react";

import LoaderMaster from "../Loaders/Loaders";
import StarRating from "../General/Stars";

import {postApiPrivate, postApiPublic} from "../../api";

import EmptyContainer from "../General/Empty";

import ReviewsCard from "../Profile/ReviewCard";

import { CloseSharp, EyedropSharp, EyeSharp, LockOpenSharp, MailSharp, NotificationsSharp, PencilSharp, SaveSharp, TrashBinSharp } from "react-ionicons";

import ReactCountryFlag from "react-country-flag";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize } from "@mui/material";

import ReviewComponent from "../Reviews/ReviewComponent";

import {CopyToClipboard} from 'react-copy-to-clipboard';

import {WidgetCount} from './AdminWidgets';

import "../Profile/z_profile.css";

const AdminCustomersDetails = (props) => {

  const {TRANSLATE, history, match, location} = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [PUBLIC_USER, setUser] = useState({});
  

  useEffect(()=> {

      var user_id = match.params.id || '';

      onFetchPublic(user_id);

  }, []);

  const onFetchPublic = async(user_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    
    setEdited(false);
    setLoading(true);

    try{

      const FORM = { id: user_id};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/users/details', FORM, JWT);
        
      if(DATA_RESPONSE.error){

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setUser(DATA_RESPONSE || {});
      setLoading(false);

    }
    catch(e){

        setLoading(false);
    }
      
  }
  
  const onUpdateUser = async(key, value) => {

    setEdited(true);
    setUser({
      ...PUBLIC_USER,
      [key]: value
    });

  }

  const onSaveUser = async(user_id) => {
      
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        const FORM = { id: user_id};

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/users/edit', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Update This User', 'error');
            onFetchPublic(user_id);

            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("SEARCH ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('User Updated', 'This User has been updated successfully !!!', 'success');
        onFetchPublic(user_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Update This User', 'error');
          onFetchPublic(user_id);
      }

  }

  const onDeleteUser = async(user_id) => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
      
      setSaving(true);

      try{

        const FORM = { id: user_id};

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/users/delete', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Delete This User', 'error');
            onFetchPublic(user_id);
            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        setSaving(false);
        swal('User Deleted', 'This User has been deleted successfully !!!', 'success');
        history.replace('/admin/users');

      }
      catch(e){

        setSaving(false);
        swal('Error', 'Cannot Delete This User', 'error');
        onFetchPublic(user_id);
      }
  }
  
  const onSavePassword = async(user_id, new_password) => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
    setSaving(true);

    try{

      const FORM = {id: user_id, np: new_password.trim()};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/users/password', FORM, JWT);
        
      if(DATA_RESPONSE.error){
          setSaving(false);
          swal('Error', 'Cannot Update the password for this User', 'error');
          onFetchPublic(user_id);

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('User Updated', 'This User\'s Password has been updated successfully !!!', 'success');
      onFetchPublic(user_id);

    }
    catch(e){

        setSaving(false);
        swal('Error', 'Cannot Update the password for this User', 'error');
        onFetchPublic(user_id);
    }
      
  }
  
  const onSendPush = async(user_id, content) => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
    setSaving(true);

    try{

      const FORM = {id: user_id, content: content.trim()};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/users/push', FORM, JWT);
        
      if(DATA_RESPONSE.error){
          setSaving(false);
          swal('Error', 'Cannot send the push notification to this User', 'error');
          onFetchPublic(user_id);

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Push Sent', 'This push notification has been sent successfully to this User !!!', 'success');
      onFetchPublic(user_id);

    }
    catch(e){

        setSaving(false);
        swal('Error', 'Cannot Update the password for this User', 'error');
        onFetchPublic(user_id);
    }
      
  }

  const onSelectCopy = (copy) => {
      swal('Copied to Clipboard !!!', copy, 'success')
  }

  if(isLoading){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading}/>
    )

  }

  if(isSaving){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
    )

  }


  const {
    id,
    name,
    img,
    updatedAt,
    createdAt,
    city,
    rating,
    lang,
    email,
    phone,
    about,
    currency,
    isAdmin,
    isTester,
    setup,
    store,
    shop,
    insegna,
    description,
    communication,
    use,
    punctuation,
    reviews,
    devices,
    country,
    search,
    followers,
    following
  } = PUBLIC_USER;

  const NAMES = getSplittedNames(name) || {};
  const FIRST_NAME = NAMES.first_name ? NAMES.first_name.toUpperCase() : name;
  const REFFERAL_CODE = FIRST_NAME.slice(0, 6) + id?.toUpperCase();

  const Communication = communication || 0;
  const Punctuation = punctuation || 0;
  const Use = use || 0;

 
  return(
   
      <Container maxWidth="lg" background={'#f0f5f9'}>

            <Grid container spacing={2}>

              <Grid item xs={12} lg={4} md={6}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                      <div className="top_store_card">
                          <div className="top_store_profile">

                              {img ? (
                                <img className="profile_img" src={img} />
                              ) : (
                                <div className="profile_img"  />
                              )}
                            
                              <h3 className="store_title">{name}</h3>
                              <StarRating rate={rating} reviews={reviews} />
                              {/* <p style={{color: '#000', margin: 0}}>{city}</p> */}
                              
                          </div>
                          <div className="top_store_stats">
                              <div>
                                <h3>{reviews || 0}</h3>
                                <p>{TRANSLATE.reviews}</p>
                              </div>
                              <div>
                                <h3>{followers || 0}</h3>
                                <p>{TRANSLATE.followers}</p>
                              </div>
                              <div>
                                <h3>{following || 0}</h3>
                                <p>{TRANSLATE.following}</p>
                              </div>
                          </div>
                      </div> 

                      {!isEdited && (
                          <>
                            <div className="top_add_cart"  style={{backgroundColor: 'black'}} onClick={()=> history.push('/profile/'+id)}>
                              <EyeSharp color={'#fff'} style={{marginRight: 10}} /> Open Profile
                            </div>

                            <div className="top_add_cart"  
                                 style={{backgroundColor: '#59ad6a'}} 
                                 onClick={()=> {
                                  swal({
                                      title: 'New Push',
                                      text: 'Please insert the text you would like to send to '+name,
                                      content: "input",
                                  })
                                  .then((value) => {

                                        if(!value){
                                          swal('Invalid Content', 'The Text Content Need to be at least 6 chars long', 'warning');
                                          return
                                        }

                                        let new_content = value.trim();

                                        if(new_content.length < 6){
                                          swal('Invalid Content', 'The Text Content Need to be at least 6 chars long', 'warning');
                                          return
                                        }
                              
                                        swal({
                                            title: 'Confirm to Send Push',
                                            text: 'Please insert the master password to send this push notification',
                                            content: "input",
                                        })
                                        .then((value) => {
    
                                          if(value === 'KingIsMe'){
                                              onSendPush(id, new_content)
                                          }
                                          else{
    
                                              swal('Invalid Push', 'You are not authorized to send this push notification', 'error');
                                          }
    
                                        });
                                   
                                  });

                               }}
                            >
                              <NotificationsSharp color={'#fff'} style={{marginRight: 10}} /> Send Push
                            </div>

                            <div className="top_add_cart"  style={{backgroundColor: '#ffbb49'}} onClick={()=> window.location.replace('mailto:'+email)}>
                              <MailSharp color={'#fff'} style={{marginRight: 10}} /> Send Email
                            </div>

                            <div className="top_add_cart"  
                                 style={{backgroundColor: '#ccc'}} 
                                 onClick={()=> {
                                  swal({
                                      title: 'New Password',
                                      text: 'What is the new password for '+email,
                                      content: "input",
                                  })
                                  .then((value) => {

                                        if(!value){
                                          swal('Invalid Password', 'The Password Need to be at least 6 chars long', 'warning');
                                          return
                                        }

                                        let new_password = value.trim();

                                        if(new_password.length < 6){
                                          swal('Invalid Password', 'The Password Need to be at least 6 chars long', 'warning');
                                          return
                                        }
                              
                                        swal({
                                            title: 'Confirm to Update Password',
                                            text: 'Please insert the master password to update this user\'s password',
                                            content: "input",
                                        })
                                        .then((value) => {
    
                                          if(value === 'KingIsMe'){
                                              onSavePassword(id, new_password)
                                          }
                                          else{
    
                                              swal('Invalid Password', 'You are not authorized to update this user\'s password', 'error');
                                          }
    
                                        });
                                   
                                  });

                               }}
                            >
                              <LockOpenSharp color={'#fff'} style={{marginRight: 10}} /> Send Password
                            </div>

                            <div className="top_add_cart" 
                                 style={{backgroundColor: '#e64942'}} 
                                 onClick={()=> {
                                    swal({
                                        title: 'Confirm to Delete',
                                        text: 'Please insert the master password to delete this user',
                                        content: "input",
                                    })
                                    .then((value) => {

                                      if(value === 'KingIsMe'){

                                          onDeleteUser(id)
                                      }
                                      else{

                                          swal('Invalid Password', 'You are not authorized to delete this user', 'error');
                                      }

                                    });
                                 }}
                            >
                              <TrashBinSharp color={'#fff'} style={{marginRight: 10}} /> Delete User 
                            </div>
                          </>
                      )}
                     
                      {isEdited && (
                        <> 

                          <div className="top_add_cart"  
                               style={{backgroundColor: '#59ad6a'}} 
                               onClick={()=> {
                                swal({
                                    title: 'Confirm to Save',
                                    text: 'Please insert the master password to update this user',
                                    content: "input",
                                })
                                .then((value) => {

                                  if(value === 'KingIsMe'){

                                      onSaveUser(id)
                                  }
                                  else{

                                      swal('Invalid Password', 'You are not authorized to update this user', 'error');
                                  }

                                });
                             }}
                               
                          >
                            <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Update Profile
                          </div>

                          <div className="top_add_cart"  style={{backgroundColor: '#ccc'}} onClick={()=> onFetchPublic(id)}>
                            <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Cancel Changes
                          </div>
                        
                        </>
                      )}
              
                      
                  </div>
              </Grid>

              <Grid item xs={12} lg={5} md={6}>
              
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                        <div className="top_box_magic" style={{marginBottom: 20}}>
                            <h1>
                              Onboarding
                            </h1>

                            {setup ? (
                               <Chip label="Completed" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateUser('setup', false)}/>
                            ) : (
                               <Chip label="Uncomplete" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning" onClick={(e)=> onUpdateUser('setup', true)} />
                            )}
                        </div>   

                        <div className="top_box_magic">
                            <h1>
                              {TRANSLATE.email}
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <p>
                                {email}
                              </p> 
                            </div>
                        </div>

                        {phone && (
                          <div className="top_box_magic">
                              <h1>
                                Phone
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <p>
                                  +{phone.replace('+', '')}
                                </p> 
                              </div>
                          </div>
                        )}

                        <div className="top_box_magic">
                            <h1>
                              {TRANSLATE.about_me}
                            </h1>
                            <div  style={{margin: '10px 0px'}}>

                              <TextareaAutosize
                                minRows={4}
                                maxRows={10}
                                placeholder="Please insert the customer's description"
                                defaultValue={about}
                                style={{ width: '100%', border: 'solid 1px #00000019', borderRadius: 10, padding: 10}}
                                onChange={(e)=> onUpdateUser('about', e.target.value)}
                              />

                            </div>
                        </div>


                        <div className="top_box_magic">
                            <h1>
                            Currency
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <p>
                                {currency === 'usd' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'us'} style={{marginRight: 5}} /> $ - USD
                                  </>
                                )}
                                {currency === 'eur' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'eu'} style={{marginRight: 5}} /> € - EUR
                                  </>
                                )}
                                {currency === 'gbp' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'gb'} style={{marginRight: 5}} /> £ - GBP
                                  </>
                                )}
                              </p> 
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                            Language
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <p>
                                {lang === 'en' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'gb'} style={{marginRight: 5}} /> English
                                  </>
                                )}
                                {lang === 'it' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'it'} style={{marginRight: 5}} /> Italian
                                  </>
                                )}
                                {lang === 'fr' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'fr'} style={{marginRight: 5}} /> French
                                  </>
                                )}
                                {lang === 'es' && (
                                  <>
                                  <ReactCountryFlag svg countryCode={'es'} style={{marginRight: 5}} /> Spanish
                                  </>
                                )}
                               
                              </p> 
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Last Search
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <p>
                                {search ? (
                                  <>
                                  <ReactCountryFlag svg countryCode={country} style={{marginRight: 5}}/> {city} - ({country}) 
                                  </>
                                ) : (
                                  'No Search Made'
                                )}
                              
                              </p> 
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                            Devices
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              {devices.map((item, index)=> {
                                return (
                                  <Chip label={item} key={index} style={{marginTop: 10}} variant="filled" color="default" />
                                )
                              })}

                            </div>
                        </div>

                        
                        <div className="top_box_magic">
                            <h1>
                            Testers Credencials
                            </h1>

                            {isTester ? (
                               <Chip label="True" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateUser('isTester', false)}/>
                            ) : (
                               <Chip label="False" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="error" onClick={(e)=> onUpdateUser('isTester', true)} />
                            )}

                        </div>

                        <div className="top_box_magic">
                            <h1>
                            Admin Credencials
                            </h1>

                            {isAdmin ? (
                               <Chip label="True" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateUser('isAdmin', false)}/>
                            ) : (
                               <Chip label="False" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="error" onClick={(e)=> onUpdateUser('isAdmin', true)} />
                            )}

                        </div>
                        
                
                </div>
              
              </Grid>

              <Grid item xs={12} lg={3} md={3}>
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                    
                    
                    <div className="top_box_magic">
                        <h1>
                          User Created
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <p>
                            {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                          </p> 
                        </div>
                    </div>   
                    <div className="top_box_magic">
                        <h1>
                          User Updated
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <p>
                            {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                          </p> 
                        </div>
                    </div>  

                    {store && (
                      <div className="top_box_magic">
                          <h1>
                          Store
                          </h1>

                          <Chip
                              avatar={<Avatar alt={shop} src={insegna} />}
                              label={shop.toLowerCase()}
                              variant="outlined"
                              size='medium'
                              style={{textTransform: 'capitalize', marginTop: 10, width: 'fit-content'}}
                              onClick={()=> history.push('/admin/stores/profile/'+store)}
                          />
                          
                      </div>
                    )}

                    <div className="top_box_magic">
                        <h1>
                        Refferal Code
                        </h1>
                        <CopyToClipboard text={REFFERAL_CODE}   
                                         onCopy={() => onSelectCopy(REFFERAL_CODE)}
                                  >
                            <Chip label={REFFERAL_CODE} 
                                  style={{marginTop: 10, width: 'fit-content'}} 
                                  variant="outlined" color="default" 
                                  />
                        
                        </CopyToClipboard>
                        
                    </div>

                    {id && (
                      <WidgetCount title="Rented Products" 
                                   label="Products"
                                   type="orders"
                                   onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&customer='+id)} 
                                   filters={{
                                      customer: id,
                                      query: 'products'
                                   }} 
                      />
                    )}
                    
                    {id && (
                      <WidgetCount title="Total Orders" 
                                    label="Orders"
                                    type="orders"
                                    onClick={()=> history.push('/admin/orders/0?sort=updatedAt&dir=desc&customer='+id)} 
                                    filters={{
                                      customer: id
                                    }} 
                      />
                    )}


                    <div className="top_box_magic">
                        <h1>
                          Total Reviews
                        </h1>

                        <Chip label={reviews+' Reviews'} 
                              style={{marginTop: 10, width: 'fit-content'}} 
                              variant="filled" 
                              color="default" 
                              onClick={()=> history.push('/admin/reviews/0?sort=updatedAt&dir=desc&type=customer&customer='+id)}
                        />
                        
                    </div>

                    <div style={{marginTop: 40}}/>


                    <ReviewComponent title="Communication" 
                                      value={Communication}
                                      ReadOnly={true}
                    
                    />
                    <ReviewComponent title="Punctuation" 
                                      value={Punctuation}
                                      ReadOnly={true}
                    
                    />
                    <ReviewComponent title="Use of Gear" 
                                    value={Use}
                                    ReadOnly={true}
                    
                    />
                  
                </div>
              </Grid>

            </Grid>            
          
      </Container>

    
  )


}


const getSplittedNames = (full_name) => {
    
  const full_name_array = full_name ? full_name.split(' ') : [];
  const first_name = full_name_array[0];
  const last_name = full_name_array[full_name_array.length - 1];

  return {
      first_name,
      last_name
  }
}


export default AdminCustomersDetails
