import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import swal from 'sweetalert';

import { onGetCurrencySymbol } from '../../helpers/GeneralHelper';

import { GetRouteSearchParams } from './AdminHelper';

import StarReview from '../General/Stars';
import { Add, FilterList } from '@mui/icons-material';

import ProductFilter from '../Modals/ProductFilter';
import { Filter_Array } from '../Modals/Constants';

const AdminProducts = (props) => {

    const { TRANSLATE, history, match, location } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [PRODUCTS, setProducts] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const [isFilter, setFilter] = useState(false);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var filter_country = routeParams.country || null;
            var filter_status = routeParams.status || null;
            var filter_store = routeParams.store || null;

            var current_page = parseInt(page_id);

            onFetchProducts({
                page: current_page,
                sort: sort_id,
                direction: direction_id,
                filter_country,
                filter_status,
                filter_store
            });
        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchProducts = async ({ page, sort, direction, filter_status, filter_store, filter_country }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                status: filter_status,
                store: filter_store,
                country: filter_country,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/products', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setProducts(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchProducts = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchProducts({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/products/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setProducts(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setProducts([]);
            setLoading(false);
        }


    }

    const onSortProducts = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'isPublished') {
            return 'isBusy'
        }
        else if (sort === 'isDeleted') {
            return 'deleted'
        }
        else {
            return sort
        }
    }

    const onChangePageProducts = ({ page, sort, direction, filter_status, filter_store, filter_country }) => {

        let new_url = '/admin/products/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }
        else {
            new_url = new_url + '?sort=updatedAt&dir=desc';
        }

        //Check filters
        if (filter_country) {
            new_url = new_url + '&country=' + filter_country;
        }

        if (filter_status) {
            new_url = new_url + '&status=' + filter_status;
        }

        if (filter_store) {
            new_url = new_url + '&store=' + filter_store;
        }

        history.push(new_url);

    }

    const onSelectProduct = (product_id) => {
        history.push('/admin/products/profile/' + product_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/admin/stores/profile/' + store_id);
    }

    const onSelectLocation = (location_id) => {
        history.push('/admin/locations/profile/' + location_id);
    }

    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }

    //Logic
    var routeParams = GetRouteSearchParams(location.search);

    var currentSort = routeParams.sort || null;
    var currentDirection = routeParams.dir || null;

    var currentFilterCountry = routeParams.country || null;
    var currentFilterStatus = routeParams.status || null;
    var currentFilterStore = routeParams.store || null;

    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectProduct(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "sid",
            label: "STORE",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const productObx = PRODUCTS[rowIndex] || {};

                    const store_id = productObx.sid || '';
                    const store_name = productObx.sname || '';
                    const store_img = productObx.simg || '';

                    return (
                        <div>
                            <Chip
                                avatar={<Avatar alt={store_name} src={store_img} />}
                                label={store_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectStore(store_id)}
                            />
                        </div>
                    )
                }
            },

        },
        {
            name: "name",
            label: "PRODUCT",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const productObx = PRODUCTS[rowIndex] || {};

                    const product_id = productObx.id;
                    const product_name = productObx.name || '';
                    const product_img = productObx.first_img || '';

                    return (
                        <div>
                            <Chip
                                avatar={<Avatar alt={product_name} src={product_img} />}
                                label={product_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectProduct(product_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "category",
            label: "Category",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {

                    const CatFound = Filter_Array.find((item)=> item.code === value);
                    const CatName = CatFound ? CatFound.en : value;
                    const CatIcon = CatFound ? CatFound.icon : '';

                    return (
                        <div>
                            <Chip
                                avatar={<Avatar alt={CatName} src={CatIcon} />}
                                label={CatName}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                            />
                        </div>

                    )
                }
            },
        },
        {
            name: "price_day",
            label: "Daily",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "price_week",
            label: "Weekly",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "price_month",
            label: "Monthly",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "rating",
            label: "Rating",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "isPublished",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    if (label) {
                        return (
                            <div>
                                <Chip label="published" size="small" color='success' />
                            </div>
                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip label="unpublished" size="small" color='warning' />
                            </div>
                        )
                    }

                }
            },
        },
        {
            name: 'isDeleted',
            label: 'DELETED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const productObx = PRODUCTS[rowIndex] || {};

                    const isDeleted = productObx.isDeleted || false;

                    if (isDeleted) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="Deleted" color='error' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }
                }
            }

        },
        
        {
            name: "accuracy",
            label: "Accuracy",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "cleanliness",
            label: "Cleanliness",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "condition",
            label: "Condition",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={label} size={20} />
                        </div>
                    )
                }

            },
        },
        {
            name: "updatedAt",
            label: "LAST UPDATED",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "PRODUCT CREATED",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        // {
        //     name: "about",
        //     label: "About",
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (label) => {

        //             return (
        //                 <CopyToClipboard text={label}
        //                     onCopy={() => onSelectCopy(label)}
        //                 >

        //                     <Chip label={label} size='small' />

        //                 </CopyToClipboard>
        //             )
        //         }
        //     },
        // },

    ];

    const data = PRODUCTS.map((item) => {

        moment.locale(TRANSLATE.lang);

        //SETUP CATEGORY
        const CATEGORIES = TRANSLATE.list_categories || [];
        let ProductCategory = item.category;

        if (ProductCategory) {

            const category_index = CATEGORIES.findIndex((item) => item.code === ProductCategory);

            if (category_index != -1) {
                var category_name = CATEGORIES[category_index].name;
                ProductCategory = category_name;
            }
        }

        let store_currency = item.scurrency || 'eur';
        let store_symbol = onGetCurrencySymbol(store_currency);

        let new_product = {
            id: item.id,
            name: item.name,
            about: item.about,
            img: item.first_img,
            updatedAt: moment(item.updatedAt).fromNow(),
            createdAt: moment(item.createdAt).format('DD MMMM YYYY'),
            price_day: store_symbol + '' + (item.price_day.toFixed(2)),
            price_week: store_symbol + '' + (item.price_week.toFixed(2)),
            price_month: store_symbol + '' + (item.price_month.toFixed(2)),
            isPublished: item.isPublished || false,
            isDeleted: item.isDeleted || false,
            country: item.sland || 'XX',
            store: item.sid,
            category: ProductCategory,
            brand: item.marca,
            quantity: item.quantity,
            currency: item.scurrency,

            rating: item.rating,
            accuracy: item.accuracy,
            cleanliness: item.cleanliness,
            condition: item.condition

        };


        return new_product
    })

    const customToolbar = () => (
        <>
            <Tooltip disableFocusListener title="Add Product">
                <IconButton onClick={() => history.push('/admin/products/profile/new')}>
                    <Add />
                </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Filter Products">
                <IconButton onClick={() => setFilter(true)}>
                    <FilterList />
                </IconButton>
            </Tooltip>
        </>
    );

    const options = {
        filter: false,
        print: false,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,

        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageProducts({
                        page: tableState.page,
                        sort: onSortProducts(sortFilter),
                        direction: sortDirection,
                        filter_country: currentFilterCountry,
                        filter_status: currentFilterStatus,
                        filter_store: currentFilterStore
                    });

                    break;
                case 'sort':

                    onChangePageProducts({
                        page: tableState.page,
                        sort: onSortProducts(sortFilter),
                        direction: sortDirection,
                        filter_country: currentFilterCountry,
                        filter_status: currentFilterStatus,
                        filter_store: currentFilterStore
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchProducts(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchProducts(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Product By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Products with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        onFilterChange: (column, filterList, type) => {


            //Check if Current Filter is Active
            if (column === 'country') {
                const filterValue = filterList[6];
                currentFilterCountry = filterValue;
            }
            else if (column === 'status') {
                const filterValue = filterList[2];
                currentFilterStatus = filterValue;
            }

            onChangePageProducts({
                page: 0,
                sort: onSortProducts(currentSort),
                direction: currentDirection,
                filter_status: currentFilterStatus,
                filter_country: currentFilterCountry,
                filter_store: currentFilterStore
            });

        }

    };


    return (
        <>
            <MUIDataTable
                title={
                    <div className='font-light md:text-5xl text-xl text-lg py-8'>

                        {'Products Found → '}

                        {isLoading ? (

                            <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                        ) : (

                            <>
                                {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                            </>

                        )}

                    </div>
                }
                data={data}
                columns={columns}
                options={options}
            />

            {isFilter && (
                <ProductFilter {...props}
                    onClose={() => setFilter(false)}

                />
            )}

        </>
    );

}


export default AdminProducts

