import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

import { postApiPrivate } from '../../api';

import LoaderMaster from '../Loaders/Loaders';

import { ShirtOutline, ReaderOutline, ChatbubbleEllipsesOutline, StarOutline, LogOutOutline, LogInOutline, StorefrontOutline, CalendarOutline, ShieldCheckmarkOutline, MapOutline, LocationOutline, GlobeOutline, CashOutline, SchoolOutline, CopyOutline, MegaphoneOutline, HandLeftOutline, BodyOutline, LogoAmazon, NotificationsOutline, UmbrellaOutline } from 'react-ionicons'
import SearchMap from '../Search/SearchMap';
import moment from 'moment/moment';
import { numberWithCommas } from '../../helpers/GeneralHelper';


const AdminDashboard = (props) => {

  const { TRANSLATE, USER, history, match } = props;

  const [isLoading, setLoading] = useState(true);
  const [isLoadingStores, setLoadingStores] = useState(true);

  const [DASHBOARD, setDashboard] = useState({});
  const [STORES, setStores] = useState([]);

  useEffect(() => {

    if (USER.admin) {
      onFetchDashboard();
    }
    else {
      setLoading(false)
      swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

      history.push('/logout')
    }

  }, []);

  const onFetchDashboard = async () => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    try {

      setLoading(true);

      const DATA_RESPONSE = await postApiPrivate('/admin/dashboard', {}, JWT);

      if (DATA_RESPONSE.error) {

        setLoading(false);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);

        return alert('Error Loading the dashboard');
      }

      const DASHBOARD_DATA = DATA_RESPONSE.data;

      setDashboard(DASHBOARD_DATA);
      setLoading(false);

      //onFetchStores();


      console.log("FETCHING DASHBOARD ===> ", DASHBOARD_DATA);

    }
    catch (e) {

      setLoading(false);

      alert('Error Loading the dashboard');
    }



  }

  const onFetchStores = async () => {

    console.log("SEARCH STORES ON ====> ", USER.city);

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setLoadingStores(true)

    try {
      const DATA_RESPONSE = await postApiPrivate('/admin/map', {}, JWT);

      setLoadingStores(false);


      if (DATA_RESPONSE.error) {
        console.log("ERROR HERE ===> ", DATA_RESPONSE.error);
        return
      }

      setStores(DATA_RESPONSE.data);

      console.log("DATA X ===> ", DATA_RESPONSE.data);

    }
    catch (e) {
      setLoadingStores(false);
    }


  }


  if (isLoading) {
    return (
      <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
    );
  }

  //Dashboard
  const {
    users,
    customers,
    providers,
    stores,
    payouts,
    chats,
    messages,
    orders,
    pending,
    accepted,
    rejected,
    delivering,
    delivered,
    complete,
    canceled,

    total_rentals,
    total_provider,
    total_fees,

    total_reports,
    total_reviews,

    total_delivery,
    total_return,
    total_transactions,

    new_today,
    new_yesterday,
    new_2days,
    new_3days,
    new_4days,
    new_5days,
    new_6days,
    new_7days,
    new_30days,
    new_60days,
    new_90days


  } = DASHBOARD

  let MenuClass = 'col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
  let MenuStyle = {
    borderRadius: 20,
    fontSize: 24,
    fontWeight: 'bold'
  }


  let POINTS = STORES.map((item) => {
    return {
      ...item,
      type: 'provider'
    }
  })


  var REFERENCE = moment(); // fixed just for testing, use moment();
  var TODAY = REFERENCE.clone().startOf('day');
  var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
  var A_2DAYS_OLD = REFERENCE.clone().subtract(2, 'days').startOf('day');
  var A_3DAYS_OLD = REFERENCE.clone().subtract(3, 'days').startOf('day');
  var A_4DAYS_OLD = REFERENCE.clone().subtract(4, 'days').startOf('day');
  var A_5DAYS_OLD = REFERENCE.clone().subtract(5, 'days').startOf('day');
  var A_6DAYS_OLD = REFERENCE.clone().subtract(6, 'days').startOf('day');
  var A_7DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
  var A_30DAYS_OLD = REFERENCE.clone().subtract(30, 'days').startOf('day');
  var A_60DAYS_OLD = REFERENCE.clone().subtract(60, 'days').startOf('day');
  var A_90DAYS_OLD = REFERENCE.clone().subtract(90, 'days').startOf('day');

  return (
    <>
     <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10">

        {USER.admin ? (
          <>
            <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>
              <div className='row px-xl-5 px-2 mx-2 mb-8 justify-content-center'>
                  <div style={{ fontSize: 60, fontWeight: 100 }}>Welcome Back <span style={{ fontWeight: 'bold' }}>{USER.name}</span></div>
              </div>
            </div>

            <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>

              <Link to={'/admin/orders'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10}}>
                  {numberWithCommas(total_rentals, 'EUR')}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Total Rentals
                </p>
              </Link>


              <Link to={'/admin/orders'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: '#26BFBF' }}>
                  {numberWithCommas(total_provider, 'EUR')}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Provider Earnings
                </p>
              </Link>

              <Link to={'/admin/stores'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: '#fd6270' }}>
                  {numberWithCommas(total_fees, 'EUR')}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Total Fees
                </p>
              </Link>

              <Link to={'/admin/orders'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10}}>
                  {numberWithCommas(total_transactions, 'EUR')}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Total Transactions
                </p>
              </Link>

              <Link to={'/admin/orders'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10}}>
                  {numberWithCommas(total_delivery + total_return, 'EUR')}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Total Logistics
                </p>
              </Link>

            </div>



            <div className='row px-xl-5 px-2 mb-5 justify-content-center'>

              <div className='col-xl-4 col-md-6 col-12 p-0'>
                <MenuAdminLeft {...props} DASHBOARD={DASHBOARD} />
              </div>

              <div className='col-xl-4 col-md-6 col-12'>
                <div className='bg-light mb-5' style={{ borderRadius: 20, padding: 20 }}>

                  <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=pending'}>
                    <h4 style={{ fontSize: 20 }}>Pending</h4>
                    <h4 style={{ color: '#FCE205', fontSize: 'x-large' }}>{pending}</h4>
                  </Link>
                  <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=accepted'}>
                    <h4 style={{ fontSize: 20 }}>Accepted</h4>
                    <h4 style={{ color: '#9AD6AB', fontSize: 'x-large' }}>{accepted}</h4>
                  </Link>
                  <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=delivering'}>
                    <h4 style={{ fontSize: 20 }}>Delivering</h4>
                    <h4 style={{ color: '#88D1F1', fontSize: 'x-large' }}>{delivering}</h4>
                  </Link>
                  <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=delivered'}>
                    <h4 style={{ fontSize: 20 }}>Delivered</h4>
                    <h4 style={{ color: '#ff8b3d', fontSize: 'x-large' }}>{delivered}</h4>
                  </Link>
                  <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=complete'}>
                    <h4 style={{ fontSize: 20 }}>Complete</h4>
                    <h4 style={{ color: '#9AD6AB', fontSize: 'x-large' }}>{complete}</h4>
                  </Link>
                  <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=rejected'}>
                    <h4 style={{ fontSize: 20 }}>Rejected</h4>
                    <h4 style={{ color: '#ec1529', fontSize: 'x-large' }}>{rejected}</h4>
                  </Link>
                  <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc&status=canceled'}>
                    <h4 style={{ fontSize: 20 }}>Canceled</h4>
                    <h4 style={{ color: '#ec1529', fontSize: 'x-large' }}>{canceled}</h4>
                  </Link>

                  <Link className='d-flex align-items-center justify-content-between px-3 py-1' to={'/admin/orders/0?sort=updatedAt&dir=desc'}>
                    <h4 style={{ fontSize: 20 }}>Total Orders</h4>
                    <h4 style={{ color: '#000', fontSize: 'x-large' }}>{orders}</h4>
                  </Link>

                </div>
              </div>

              <div className='col-xl-4 col-md-6 col-12 p-0'>
                <MenuAdminRight {...props} DASHBOARD={DASHBOARD} />
              </div>

            </div>

            <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>
              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_today}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Today
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {TODAY.format('dddd DD MMMM YYYY')}
                </p>
              </Link>
              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_yesterday}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Yesterday
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {YESTERDAY.format('dddd DD MMMM YYYY')}
                </p>
              </Link>
              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_2days}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  2 days ago
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {A_2DAYS_OLD.format('dddd DD MMMM YYYY')}
                </p>

              </Link>
              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_3days}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  3 days ago
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {A_3DAYS_OLD.format('dddd DD MMMM YYYY')}
                </p>
              </Link>
              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_4days}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  4 days ago
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {A_4DAYS_OLD.format('dddd DD MMMM YYYY')}
                </p>
              </Link>
              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_5days}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  5 days ago
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {A_5DAYS_OLD.format('dddd DD MMMM YYYY')}
                </p>
              </Link>
              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_6days}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  6 days ago
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {A_6DAYS_OLD.format('dddd DD MMMM YYYY')}
                </p>
              </Link>
              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_7days}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Last 7 Days
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {A_7DAYS_OLD.format('DD MMMM YYYY')} - {TODAY.format('DD MMMM YYYY')}
                </p>
              </Link>

              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_30days}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Last 30 Days
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {A_30DAYS_OLD.format('DD MMMM YYYY')} - {TODAY.format('DD MMMM YYYY')}
                </p>
              </Link>

              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_60days}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Last 60 Days
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {A_60DAYS_OLD.format('DD MMMM YYYY')} - {TODAY.format('DD MMMM YYYY')}
                </p>
              </Link>

              <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClass} style={MenuStyle}>
                <h1 style={{ padding: 10, color: 'var(--dark)' }}>
                  {new_90days}
                </h1>
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  Last 90 Days
                </p>
                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                  {A_90DAYS_OLD.format('DD MMMM YYYY')} - {TODAY.format('DD MMMM YYYY')}
                </p>
              </Link>

            </div>

            {/* <div className='row px-xl-5 px-2 mb-5 justify-content-center'>
                      
                          <div className='col-xl-12 col-12 mb-5'>
                            {!isLoadingStores && (
                              <SearchMap STORE={{}} 
                                          POINTS={POINTS} 
                                          GEOS={[]}
                                          TRANSLATE={TRANSLATE} 
                                          LANGUAGE={TRANSLATE.locale} 
                                          history={history} 
                                          SearchURL={''}
                                          Zoom={2}
                              />
                            )}
                          </div>

                         
                    </div>
                     */}
            <div style={{ width: '100%', height: 200 }}></div>

          </>
        ) : (
          <>
            {/* <h1>Not Authorized</h1> */}
          </>
        )}

      </div>
    </>

  )


}





const MenuAdminLeft = (props) => {

  const { TRANSLATE, USER, match } = props;

  let MenuClass = 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
  let MenuStyle = {
    borderRadius: 20,
    fontSize: 13,
    fontWeight: 'bold'
  }

  return (
    <>
      <div className='row mb-5 justify-content-center p-0'>

        <Link to={'/admin/stores'} className={MenuClass} style={MenuStyle}>
          <StorefrontOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Stores
        </Link>

        <Link to={'/admin/users'} className={MenuClass} style={MenuStyle}>
          <BodyOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Users
        </Link>

        <Link to="/admin/partners" className={MenuClass} style={MenuStyle}>
          <HandLeftOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Partners
        </Link>

        <Link to={'/admin/orders'} className={MenuClass} style={MenuStyle}>
          <ReaderOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Orders
        </Link>

        <Link to={'/admin/products'} className={MenuClass} style={MenuStyle}>
          <ShirtOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Products
        </Link>

        <Link to="/admin/approvals" className={MenuClass} style={MenuStyle}>
          <ShieldCheckmarkOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Approvals
        </Link>

        <Link to={'/admin/inbox'} className={MenuClass} style={MenuStyle}>
          <ChatbubbleEllipsesOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Messages
        </Link>

        <Link to={'/admin/calendary'} className={MenuClass} style={MenuStyle}>
          <CalendarOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Calendary
        </Link>

        <Link to={'/admin/reviews'} className={MenuClass} style={MenuStyle}>
          <StarOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Reviews
        </Link>


      </div>
    </>
  )
}

const MenuAdminRight = (props) => {

  const { TRANSLATE, USER, match } = props;

  let MenuClass = 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
  let MenuStyle = {
    borderRadius: 20,
    fontSize: 13,
    fontWeight: 'bold'
  }

  return (
    <>
      <div className='row mb-5 justify-content-center p-0'>

        <Link to="/admin/map" className={MenuClass} style={MenuStyle}>
          <MapOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Map
        </Link>

        <Link to="/admin/locations" className={MenuClass} style={MenuStyle}>
          <LocationOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Locations
        </Link>

        <Link to="/admin/geopoints" className={MenuClass} style={MenuStyle}>
          <GlobeOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
         Geopoints
        </Link>

        <Link to="/admin/payments" className={MenuClass} style={MenuStyle}>
          <CashOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Payments
        </Link>

        <Link to="/admin/marketing" className={MenuClass} style={MenuStyle}>
          <MegaphoneOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Marketing
        </Link>

        <Link to="/admin/notifications" className={MenuClass} style={MenuStyle}>
          <NotificationsOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Notifications
        </Link>

        <Link to="/admin/brands" className={MenuClass} style={MenuStyle}>
          <LogoAmazon color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Brands
        </Link>

        <Link to="/admin/listing" className={MenuClass} style={MenuStyle}>
          <UmbrellaOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Listing
        </Link>


        {USER.name ? (
          <Link to="/logout" className={MenuClass} style={MenuStyle}>
            <LogOutOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
            Logout
          </Link>
        ) : (
          <Link to="/login" className={MenuClass} style={MenuStyle}>
            <LogInOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
            Login
          </Link>
        )}

      </div>
    </>
  )
}

export default AdminDashboard
