import React, { useEffect, useState } from 'react';
import './SplashScreen.css'; 

const SplashLoadingScreen = ({title,  TRANSLATE }) => {
  const [animationComplete, setAnimationComplete] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) =>
        prevPercentage < 95 ? prevPercentage + 5 : 95
      );
    }, 500); // Adjust the interval as needed

    const timeout = setTimeout(() => {
      setAnimationComplete(true);
      clearInterval(interval);
    }, 10000); // Adjust the total loading time as needed

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={`loading-screen ${animationComplete ? 'animation-complete' : ''}`}>
      <div className="stroller">
        <img
          className="w-[100px] h-[100px] pink-invert mb-4"
          src="/assets/img/icons/stroller.svg"
          alt="Loading Icon"
        />

      </div>
      <div className="text-container-splash flex flex-col justify-center items-center">
        <span className="text-3xl font-bold text-black-100 mb-2 text-center">
          {title || TRANSLATE.wd_searching_providers}
        </span>

      </div>
      <div className="text-center-splash mt-6">
        
        <p className="text-gray-500 text-xl">
          Loading: {loadingPercentage}%
        </p>
      </div>
    </div>
  );
};

export default SplashLoadingScreen;
