import React from 'react';
import { ArrowForwardCircleOutline } from 'react-ionicons';
import StarReview from '../../../General/Stars';

import { AddShoppingCartOutlined } from '@mui/icons-material';
import '../../new_ux.css';
import './productcard.css';

const ProductCard = (props) => {
  const {
    product,
    total_rent,
    completed_orders,
    buttonText = 'Rent',
    setSelectedProduct,
    selectProvider,
    isGrid,
    onClick = () => {},
    addCart = () => {},
  } = props;

  return (
    <>
      <div className={`card ${isGrid ? 'grid w-full' : ''}`} aria-labelledby='product-card'>
        <div className='card-top' aria-labelledby='card-banner'>
          <div className='card-banner' id='card-banner'>
            {product.cost_delivery === null && <div className={`banner-text`}></div>}
            {product.cost_delivery !== null && (
              <div className={`banner-text ${product.cost_delivery > 0 ? 'banner-gray' : 'banner-green'}`}>
                {product.cost_delivery > 0 ? `Delivery ${product.cost_delivery} ${product.currency}` : 'Free Pickup'}
              </div>
            )}
          </div>
          <div
            className='card-image'
            onClick={() => setSelectedProduct(product)}
            aria-labelledby='product-image'
            role='img'
          >
            <img src={product.images[0]} alt='Product Image' className='object-contain' id='product-image' />
          </div>
        </div>
        <div className='card-title' id='product-name'>
          {product.name}
        </div>

        <div aria-labelledby='product-info'>
          <div className={`flex justify-content-between mb-2 text-black ${isGrid ? 'flex-col' : 'align-items-end'}`}>
            <p className={'flex align-items-center justify-content-between'}>
              <span className='quicksand text-sm'>
                <b className='text-base '>{numberWithCommas(product.cost_day, product.currency)}</b>
                /day
              </span>
            </p>

            <p className='text-xs'>Rented {total_rent} times</p>
          </div>

          <div className='flex gap-1' aria-labelledby='product-actions'>
            {buttonText === 'Rent' && (
              <button
                type='button'
                onClick={addCart}
                className='px-2 py-1 border rounded-full'
                aria-label='Add to Cart'
              >
                <AddShoppingCartOutlined style={{ width: '16px' }} />
              </button>
            )}

            <button
              type='button'
              className='flex items-center justify-center px-2 lg:px-4 lg:py-1 gap-1 w-full bg-ba text-white text-sm md:text-base rounded-lg'
              onClick={onClick}
              aria-label={buttonText}
            >
              <div className='rent-text'>{buttonText}</div>
              <ArrowForwardCircleOutline width={'24px'} color={'#fff'} aria-hidden='true' />
            </button>
          </div>
        </div>
        <div className='card-footer' onClick={() => selectProvider(product)} aria-labelledby='store-info'>
          <div className='store-info' id='store-info'>
            <img src={product.store.img} alt='Store Logo' />
            <div className='flex flex-col quicksand'>
              <h3 className='product-store-name text-base font-semibold '>{product.store.name}</h3>
              <p className='text-[10px]'>{completed_orders} orders completed</p>
            </div>
          </div>
          <div
            className='flex flex-wrap items-center justify-center md:justify-between mt-3'
            aria-labelledby='store-rating'
          >
            <div className='product-rating'>
              <StarReview rate={product.store.rate} size={20} aria-label={`Rating: ${product.store.rate}`} />
            </div>
            <div className='review-info '>
              {product.store.rate} / {product.store.reviews} Reviews
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;

function numberWithCommas(x, CURRENCY) {
  if (!x) {
    x = 0;
  }

  if (!CURRENCY) {
    return x.toFixed(2);
  }

  if (isNaN(x)) return x;

  return x.toLocaleString('en-US', {
    style: 'currency',
    currency: CURRENCY,
  });
}
