import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { postApiPrivate } from '../../../api.js';

import { Filter_Array } from '../../Constants/Constants';
import Footer from '../Footer';
import Header from '../Header';
import ListProducts from './ListProducts';
import PickupLocation from './PickupLocation/PickupLocation';
import SplashLoadingScreen from './SplashScreen.js';

import {
  AddCircleOutline,
  KeyboardDoubleArrowDownOutlined,
  KeyboardDoubleArrowRightOutlined,
  UnfoldMore,
} from '@mui/icons-material';
import '../new_ux.css';
import './listing.css';

const Listing = (props) => {
  const { history, match, LANGUAGE } = props;

  const [isLoading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [CITY, setCity] = useState(null);
  const [DATA, setData] = useState(null);

  const [isModalLocation, setModalLocation] = useState(false);

  const toggleIsModalLocation = () => setModalLocation(!isModalLocation);

  useEffect(() => {
    if (isModalLocation) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalLocation]);

  useEffect(() => {
    const onFetchCity = async () => {
      const JWT = typeof global.window !== 'undefined' ? localStorage.getItem('jwt_token') : null;
      const currentCity = match.params.city;
      const currentCountry = match.params.country;
      const FORM = {
        city: 'london',
        country: 'GB',
        lang: 'en',
      };
      // alert(JSON.stringify(FORM));
      setLoading(true);
      console.log('FORM ===> Client ', FORM);
      const DATA_RESPONSE = await postApiPrivate('/products/nearby/city', FORM, JWT);
      if (DATA_RESPONSE.error) {
        console.log('ERROR FROM API LOADING CITY', DATA_RESPONSE.error);
        setLoading(false);
        return;
      }
      const DATA_SEARCH = DATA_RESPONSE || {};
      setCity(DATA_SEARCH);
      const DATA_RESPONSE_CATEGORIES = await postApiPrivate(
        '/products/nearby/categories',
        { city: DATA_SEARCH.id },
        JWT
      );
      if (DATA_RESPONSE_CATEGORIES.error) {
        console.log('ERROR FROM API LOADING PRODUCTS', DATA_RESPONSE_CATEGORIES.error);
        setLoading(false);
        return;
      }
      setData(DATA_RESPONSE_CATEGORIES);
      setLoading(false);
      console.log('FETCHING PRODUCTS NEARBY ===> ', DATA_RESPONSE);
      //window.scrollTo(0, 0)
    };
    onFetchCity();
  }, [match.params.city, match.params.country, match.params.lang]);

  const onSelectCategory = (category) => {
    const matched_url = CITY[LANGUAGE + '_url'];
    const original_url = matched_url.replace('/search/', '/flow/');

    let next_url = original_url + '/info?id=' + CITY.id;

    if (category) {
      next_url = next_url + '&categories=' + category;
    }

    history.push(next_url);
  };

  if (isLoading || !CITY) {
    return (
      <div className='layout'>
        <ListingHeader />

        <SplashLoadingScreen {...props} title={''} />

        <div style={{ height: 200 }}></div>
      </div>
    );
  }

  const currentCity = 'london';

  return (
    <div className='layout'>
      {isModalLocation && (
        <PickupLocation
          selectedLocation={selectedLocation}
          setSelectedLocation={(value) => {
            setSelectedLocation(value);
            setModalLocation(false);
            setTimeout(() => {
              history.push('/newux/listing-grid');
            }, 100);
          }}
          onClose={toggleIsModalLocation}
          CITY={CITY}
          {...props}
        />
      )}

      <ListingHeader currentCity={currentCity} />

      <main className='flex-grow-1 my-12 mx-auto w-fulln justify-center'>
        <div className='flex flex-col gap-8'>
          <div className='max-w-8xl px-4 sm:px-6 lg:px-8'>
            <div className='mb-12'>
              <h2
                className='mb-4 quicksand text-lg leading-4 text-center uppercase'
                style={{ letterSpacing: '0.15rem' }}
                id='safe-clean-products'
                aria-label='Safe and Clean Pre-Loved Products'
              >
                Safe & Clean, Pre-Loved Products
              </h2>
              <div className='flex flex-wrap gap-8 md:gap-2 justify-center md:justify-between '>
                {Filter_Array.map((item) => {
                  const products = DATA && DATA[item.sub] ? DATA[item.sub].p : [];
                  if (products.length == 0) {
                    return <></>;
                  }

                  return (
                    <ListProducts
                      products={{
                        title: item[LANGUAGE],
                        products: products,
                        url: '/',
                        total: DATA && DATA[item.sub] ? DATA[item.sub].c : 0,
                        type: item[LANGUAGE],
                      }}
                      {...props}
                      onClick={toggleIsModalLocation}
                    />
                  );
                })}
              </div>
            </div>

            <h2
              class='quicksand text-base text-center'
              id='travel-hassle-free'
              aria-label='Travel Hassle-Free with Our Baby Gear Rentals'
            >
              <strong>Travel hassle-free with our top-rated baby gear rentals in {currentCity.toUpperCase()}.</strong>{' '}
              <br />
              Choose from a wide range of strollers, car seats, cribs, and more, all delivered and picked up at your
              convenience. <br />
              Enjoy a stress-free holiday with our reliable service.
              <br /> <br />
              <strong>Book now to ensure your baby gear is ready for your arrival!</strong>
            </h2>
          </div>

          <div className='flex flex-col md:gap-8 max-w-8xl px-0 sm:px-6 lg:px-8'>
            <div
              className='flex flex-wrap justify-around mt-0 lg:mt-12 gap-8 bg-light-gray p-2 lg:p-4 items-stretch'
              aria-label='Explore Baby Gears and More'
            >
              <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 bg-light-gray p-4 w-full'>
                {/* First Box: AIRPORTS */}
                <div
                  className='flex flex-col rounded-lg p-4 bg-white shadow-md w-full'
                  id='airports'
                  aria-labelledby='airports-heading'
                >
                  <h3 className='font-bold quicksand' id='airports-heading'>
                    AIRPORTS
                  </h3>
                  <ul className='mt-2 flex-grow'>
                    {[...Array(5)].map((_, index) => (
                      // TODO dynamic with related airport
                      <li key={`airport-${index}`} className='flex gap-2 mb-2 items-center'>
                        <img
                          src='/assets/cities/dubai.png'
                          style={{ width: '36px', height: '36px', objectFit: 'contain' }}
                          alt='Airport Image' // TODO dynamic with related airport
                          aria-label='Airport Image' // TODO dynamic with related airport
                        />
                        <div className='flex flex-col quicksand'>
                          <h4 className='font-bold quicksand'>Doha Hamad</h4>
                          <a
                            href='#'
                            className='text-sm font-semibold quicksand color-ba'
                            aria-label='View Airport Details' // TODO dynamic with related airport
                          >
                            View
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <a
                    href='#'
                    className='flex items-center mt-4 gap-2 text-sm font-semibold quicksand color-ba'
                    aria-label='View All Airports'
                  >
                    All Airports <KeyboardDoubleArrowRightOutlined />
                  </a>
                </div>

                {/* Second Box: BLOG */}
                <div
                  className='flex flex-col rounded-lg p-4 bg-white shadow-md w-full'
                  id='blog'
                  aria-labelledby='blog-heading'
                >
                  <h3 className='font-bold quicksand' id='blog-heading'>
                    BLOG
                  </h3>
                  <ul className='mt-2 flex-grow '>
                    {[...Array(5)].map((_, index) => (
                      // TODO dynamic with related blog title
                      <li key={`blog-${index}`} className='flex gap-2 mb-2 items-center' aria-label='View Blog Details'>
                        <img
                          src='/assets/cities/dubai.png'
                          style={{ width: '36px', height: '36px', objectFit: 'contain' }}
                          alt='Blog Image' // TODO dynamic with related blog title
                          aria-label='Blog Image' // TODO dynamic with related blog title
                        />
                        <div className='flex flex-col quicksand'>
                          <h4 className='font-bold quicksand'>Doha Hamad</h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <a
                    href='#'
                    className='flex items-center mt-4 gap-2 text-sm font-semibold quicksand color-ba'
                    aria-label='View All Blogs'
                  >
                    All Blogs <KeyboardDoubleArrowRightOutlined />
                  </a>
                </div>

                {/* Third Box: CITIES */}
                <div
                  className='flex flex-col rounded-lg p-4 bg-white shadow-md w-full'
                  aria-labelledby='cities-heading'
                >
                  <h3 className='font-bold quicksand' id='cities-heading'>
                    CITIES
                  </h3>
                  <ul className='flex flex-wrap mt-2 flex-grow'>
                    {[...Array(9)].map((_, index) => (
                      // TODO dynamic with related city
                      <li key={`city-${index}`} className='flex flex-col gap-2 mb-2 w-1/3 items-center'>
                        <img
                          src='/assets/cities/dubai.png'
                          style={{ width: '36px', height: '36px', objectFit: 'contain' }}
                          alt='City Image'
                          aria-label='City Image'
                        />
                        <div className='font-bold quicksand'>Dubai</div>
                      </li>
                    ))}
                  </ul>
                  <a
                    href='#'
                    className='flex items-center mt-4 gap-2 text-sm font-semibold quicksand color-ba'
                    aria-label='View All Cities'
                  >
                    All Cities <KeyboardDoubleArrowRightOutlined />
                  </a>
                </div>
              </div>
            </div>

            <div className='flex flex-wrap justify-content-around mt-0 lg:mt-12 gap-8 bg-light-gray p-2 lg:p-4 items-stretch'>
              <div
                className='grid grid-cols-1 lg:grid-cols-3 gap-4 bg-light-gray p-4'
                id='explore-interest'
                aria-labelledby='explore-interest-heading'
              >
                {/* First Box */}
                <div className='flex flex-col rounded-lg p-4 bg-white shadow-md w-full'>
                  <h3 className='font-bold quicksand text-lg' id='explore-interest-heading'>
                    Explore by Interest
                  </h3>
                  <ul className='flex flex-wrap mt-2 flex-grow'>
                    {[...Array(5)].map((_, index) => (
                      // TODO dynamic with related interest title
                      <li key={`explore-${index}`} className='flex gap-2 mb-2 items-center'>
                        <details className='quicksand' aria-label='Interest Details'>
                          <summary className='flex'>
                            <AddCircleOutline className='summary-icon color-ba' aria-hidden='true' />
                            <p>Et donec congue proin facilisi tellus etiam molestie orci, diam eu tincidunt .</p>
                          </summary>
                          <p>
                            We offer a 30-day return policy for all our products. Please ensure that the items are in
                            their original condition and packaging.
                          </p>
                        </details>
                      </li>
                    ))}
                  </ul>
                  <a
                    href='#'
                    className='flex items-center mt-4 gap-2 text-sm font-semibold quicksand color-ba'
                    aria-label='View All Interests'
                  >
                    All <KeyboardDoubleArrowRightOutlined />
                  </a>
                </div>

                {/* Second Box */}
                <div
                  className='flex flex-col rounded-lg p-4 bg-white shadow-md w-full'
                  id='city-faq'
                  aria-labelledby='city-faq-heading'
                >
                  {/* // TODO dynamic with related city */}
                  <h3 className='font-bold quicksand text-lg' id='city-faq-heading'>
                    {currentCity} FAQ
                  </h3>
                  <ul className='mt-2 flex-grow'>
                    {[...Array(5)].map((_, index) => (
                      // TODO dynamic with related FAQ title
                      <li key={`${currentCity}-faq-${index}`} className='flex gap-2 mb-2 items-center'>
                        <details className='quicksand'>
                          <summary className='flex'>
                            <AddCircleOutline className='summary-icon color-ba' />
                            <p>Et donec congue proin facilisi tellus etiam molestie orci, diam eu tincidunt .</p>
                          </summary>
                          <p>
                            We offer a 30-day return policy for all our products. Please ensure that the items are in
                            their original condition and packaging.
                          </p>
                        </details>
                      </li>
                    ))}
                  </ul>
                  <a
                    href='#'
                    className='flex items-center mt-4 gap-2 text-sm font-semibold quicksand color-ba'
                    aria-label='View all FAQs'
                  >
                    All <KeyboardDoubleArrowRightOutlined />
                  </a>
                </div>

                {/* Third Box */}
                <div
                  className='flex flex-col rounded-lg p-4 bg-white shadow-md w-full'
                  id='cities'
                  aria-labelledby='cities-heading'
                >
                  <h3 className='font-bold quicksand text-lg' id='cities-heading'>
                    Cities
                  </h3>
                  <ul className='flex flex-wrap mt-2 flex-grow'>
                    {[...Array(5)].map((_, index) => (
                      // TODO dynamic with related city

                      <li key={`city-${index}`} className='flex gap-2 mb-2 items-center'>
                        <details className='quicksand'>
                          <summary className='flex'>
                            <AddCircleOutline className='summary-icon color-ba' aria-hidden='true' />
                            <p>Et donec congue proin facilisi tellus etiam molestie orci, diam eu tincidunt .</p>
                          </summary>
                          <p>
                            We offer a 30-day return policy for all our products. Please ensure that the items are in
                            their original condition and packaging.
                          </p>
                        </details>
                      </li>
                    ))}
                  </ul>
                  <a
                    href='#'
                    className='flex items-center mt-4 gap-2 text-sm font-semibold quicksand color-ba'
                    aria-label='View all cities'
                  >
                    All <KeyboardDoubleArrowRightOutlined />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer {...props} />
    </div>
  );
};

export default Listing;

const ListingHeader = ({ currentCity }) => {
  return (
    <Header animatedBackground={true}>
      <div
        className='flex flex-col md:flex-row mb-4 md:mb-24 gap-8 w-full mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'
        id='hero-section'
        role='region'
        aria-labelledby='hero-heading'
      >
        <div className='flex flex-col my-auto mx-0 gap-4 ' style={{ zIndex: 2 }}>
          <h1
            className='w-full quicksand text-base md:text-2xl uppercase text-center md:text-left color-ba'
            id='hero-heading'
            aria-label='Rent Baby Gear from Local Parents & Services'
          >
            Rent baby gear <br /> from local parents & services
          </h1>

          <div className='flex m-auto md:m-0 gap-4'>
            <Link
              to='/newux/about-babonbo'
              className='flex px-3 py-2 gap-1 align-items-center text-sm md:text-lg color-ba bg-white rounded-full hover:shadow-md'
              aria-label='Learn More'
            >
              <UnfoldMore className=' hidden xl:block color-ba' width='24px' style={{ transform: 'rotate(-45deg)' }} />
              Learn More
            </Link>
            <Link
              to='/newux/listing-grid'
              className=' hidden md:flex px-3 py-2 text-base md:text-lg text-white bg-ba rounded-full hover:shadow-md'
              aria-label='View Products'
            >
              <KeyboardDoubleArrowDownOutlined className='hidden xl:block text-white' width='24px' />
              View Products
            </Link>
          </div>
        </div>

        <div
          className='relative flex flex-col w-full md:w-[70%]  mx-auto md:ml-auto md:mr-0 md:mb-32'
          style={{ maxWidth: '800px', zIndex: 2 }}
          aria-labelledby='hero-subheading'
        >
          <h2
            className='md:absolute mt-25vw md:mt-0 md:m-auto px-4 py-1 bottom-12 text-base md:text-lg text-center md:text-left uppercase md:bg-white color-ba rounded-lg'
            id='hero-subheading'
          >
            Light, Safe, Comfy & Same-Day Delivery in {currentCity}
          </h2>

          <img
            src='/assets/img/landing/hero.jpg'
            className='flex w-full object-fit  '
            style={{ borderRadius: '40px' }}
            alt='Baby gear'
            aria-label='Baby gear'
          />
        </div>
      </div>
    </Header>
  );
};
