import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { AirplaneOutline, BodyOutline, BookOutline, CartOutline, CashOutline, ChatbubbleEllipsesOutline, CloseOutline, CopyOutline, DocumentTextOutline, EarthOutline, FingerPrintOutline, GlobeOutline, LocationOutline, LogoAndroid, LogoAppleAppstore, CalendarOutline, LogoFacebook, LogoInstagram, LogoWhatsapp, MapOutline, MegaphoneOutline, SchoolOutline, SearchOutline, ShieldCheckmarkOutline, ShirtOutline, StarOutline, TvOutline, ChevronDownOutline, ChevronUpOutline } from 'react-ionicons';
import {PersonOutline, ReaderOutline, HelpBuoyOutline, SettingsOutline, LogOutOutline, WalletOutline, LogInOutline, EyeOutline, StorefrontOutline} from 'react-ionicons'

import ReactCountryFlag from 'react-country-flag';

const MenuAreas = (props) => {

    const {isActiveMenu, setActiveMenu, USER, history, LANGUAGE, isAdmin, isProvider, isProviderURL, onChangeLanguage, match, isPartner} = props;

    const onCloseMenu = () => {
   
        setActiveMenu(false);
       
    }

    const sharedProps = {
        ...props
    }

    return (
       <div className={"slideout-wrapper "+(isActiveMenu ? 'active' : '')} style={{background: '#00000040'}}>

            <div className="slideout-content">

                <div className='d-flex align-items-center justify-content-between' style={{padding: 25}}>

                    <img src='/assets/img/babonbo_logo_provider.png' style={{height: 45}} />
                    
                    <CloseOutline color={"#000"} 
                                    onClick={()=> onCloseMenu()} 
                                    style={{
                                        height: 30, 
                                        width: 30, 
                                        zIndex: 222, 
                                        cursor: 'pointer'
                                    }} 
                    />
                    
                </div>
                
                <div className="slideout-content-scroll">
                 
                            <MenuAdmin {...sharedProps} />
                            <MenuPartner {...sharedProps} />
                            
                </div>

            </div>

        </div>
    )
}


const MenuAdmin = (props) => {

    const {TRANSLATE, USER} = props;

    return (
        <>
           {USER.admin && (
                <>
                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 10}}>
                        <h4 style={{...Styles.menuTitle, marginTop: 18}}>
                            {TRANSLATE.menu_admin}
                        </h4>
                        <ChevronUpOutline color='#ccc' />
                    </div>
                    <div style={Styles.menuCategory}>
                        <Link to="/admin/dashboard" style={Styles.menuItem}>
                            <EyeOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_dashboard}
                        </Link>
                        <Link to="/admin/users" style={Styles.menuItem}>
                            <BodyOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_customers}
                        </Link>

                        <Link to="/admin/stores" style={Styles.menuItem}>
                            <StorefrontOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_stores}
                        </Link>

                        <Link to="/admin/orders" style={Styles.menuItem}>
                            <ReaderOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_orders}
                        </Link>

                        <Link to="/admin/products" style={Styles.menuItem}>
                            <ShirtOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_products}
                        </Link>
                        
                        <Link to="/admin/inbox" style={Styles.menuItem}>
                            <ChatbubbleEllipsesOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_messages}
                        </Link>
                        

                        <Link to="/admin/reviews" style={Styles.menuItem}>
                            <StarOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.reviews}
                        </Link>

                        <Link to="/admin/calendary" style={Styles.menuItem}>
                            <CalendarOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            Calendary
                        </Link>

                        <Link to="/admin/map" style={Styles.menuItem}>
                            <MapOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_map}
                        </Link>

                        <Link to="/admin/locations" style={Styles.menuItem}>
                            <LocationOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_locations}
                        </Link>

                        <Link to="/admin/geopoints" style={Styles.menuItem}>
                            <GlobeOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_geopoints}
                        </Link>

                        <Link to="/admin/approvals" style={Styles.menuItem}>
                            <ShieldCheckmarkOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_approvals}
                        </Link>

                        <Link to="/admin/payments" style={Styles.menuItem}>
                            <CashOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_payments}
                        </Link>

                        <Link to="/admin/legal" style={Styles.menuItem}>
                            <SchoolOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            Legal
                        </Link>

                        <Link to="/admin/pages" style={Styles.menuItem}>
                            <CopyOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            Pages
                        </Link>

                        <Link to="/admin/marketing" style={Styles.menuItem}>
                            <MegaphoneOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_marketing}
                        </Link>

                        {USER.name ? (
                            <Link to="/logout" style={Styles.menuItem}>
                                <LogOutOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                                {TRANSLATE.logout}
                            </Link>
                        ) : (
                            <Link to="/login" style={Styles.menuItem}>
                                <LogInOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                                {TRANSLATE.login}
                            </Link>
                        )}
                        
                    </div>
                </>
            )}

        </>
    )
}

const MenuPartner = (props) => {

    const {TRANSLATE, USER} = props;

    let partner_id = '/EGQjv4kEbe';

    return (
        <>
           {USER.admin && (
                <>
                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 10}}>
                        <h4 style={{...Styles.menuTitle, marginTop: 18}}>
                            Partner Menu
                        </h4>
                        <ChevronUpOutline color='#ccc' />
                    </div>
                    <div style={Styles.menuCategory}>
                
                        <Link to={'/partners'+partner_id+'/stores'} style={Styles.menuItem}>
                            <StorefrontOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_stores}
                        </Link>

                        <Link to={'/partners'+partner_id+'/products'} style={Styles.menuItem}>
                            <ShirtOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_products}
                        </Link>

                        <Link to={'/partners'+partner_id+'/orders'} style={Styles.menuItem}>
                            <ReaderOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_orders}
                        </Link>

                        <Link to={'/partners'+partner_id+'/inbox'} style={Styles.menuItem}>
                            <ChatbubbleEllipsesOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.menu_messages}
                        </Link>

                        <Link to={'/partners'+partner_id+'/reviews'} style={Styles.menuItem}>
                            <StarOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                            {TRANSLATE.reviews}
                        </Link>

                        {USER.name ? (
                            <Link to="/logout" style={Styles.menuItem}>
                                <LogOutOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                                {TRANSLATE.logout}
                            </Link>
                        ) : (
                            <Link to="/login" style={Styles.menuItem}>
                                <LogInOutline color={'#26BFBF'} style={{marginRight: 5}}/>
                                {TRANSLATE.login}
                            </Link>
                        )}

                    </div>

                </>
            )}

        </>
    )
}


const MenuTemplateBackup = () => {

    const isActive = true;

    return (
       <div className={"slideout-wrapper "+(isActive ? 'active' : '')} >
                <div className="close-modal-hotspot"  id="close_modal" />
                <div className="slideout-content" style={{background: '#fff', opacity: 1}}>
                    <div className="slideout-content-scroll">
                            
                    <div className="input-group" style={{marginTop: '0px'}}>
                        <label>Button Text</label>
                        <input placeholder="Explore" />
                    </div>
                </div>

                <div className="slideout-actions">
                    <button className="empty"  id="close_modal">Cancel</button>
                    <button>Save</button>
                </div>
            </div>
        </div>
    )
}

const Styles = {
    menuTitle: {
        fontSize: 25,
        color: '#000',
        marginTop: 20
    },
    menuCategory: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start'
    },
    menuItem: {
        display: 'flex', 
        alignItems: 'center',
        fontSize: 18,
        padding: '10px 20px',
        // 
        width: '100%',
        height: 60
    }
}

export default MenuAreas