import React, {useState} from "react";

import LoaderMaster from "../Loaders/Loaders";

import {postApiPrivate} from "../../api";

import { CheckmarkCircleOutline, CloseSharp,  SaveSharp } from "react-ionicons";

import swal from "sweetalert";

import {Chip, Container, Grid, TextField } from "@mui/material";

import ReactCountryFlag from "react-country-flag";
import Countries_EN from '../Search/countries_en.json';

import "../Profile/z_profile.css";

import Iframe from "react-iframe";
import { Link } from "react-router-dom";

import { onFormatUrl } from "../../helpers/GeneralHelper";

const AdminLocationsAdd = (props) => {

  const {TRANSLATE, history, LANGUAGE} = props;

  const [isSaving, setSaving] = useState(false);

  const [isFocused, setFocused] = useState(false);
  const [CountryResults, setCountryResults] = useState([]);
  const [CountryInput, setCountryInput] = useState('');

  const [PUBLIC_LOCATION, setLocation] = useState({});

  const [LangName, setLangName] = useState('en');

  
  const onUpdateLocation = async(key, value) => {

    const geocode = PUBLIC_LOCATION.geocode || {};

    if(key === 'latitude'){
        setLocation({
          ...PUBLIC_LOCATION,
          geocode: {
            ...geocode,
            latitude: value
          }
        })

        return
    }

    if(key === 'longitude'){
        setLocation({
          ...PUBLIC_LOCATION,
          geocode: {
            ...geocode,
            longitude: value
          }
        })

        return
    }

    setLocation({
      ...PUBLIC_LOCATION,
      [key]: value
    });

  }

  const onSaveLocation = async() => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        var FORM = { 
          en: PUBLIC_LOCATION.en,
          it: PUBLIC_LOCATION.it,
          fr: PUBLIC_LOCATION.fr,
          es: PUBLIC_LOCATION.es,
          en_url: PUBLIC_LOCATION.en_url,
          it_url: PUBLIC_LOCATION.it_url,
          fr_url: PUBLIC_LOCATION.fr_url,
          es_url: PUBLIC_LOCATION.es_url,
          country: PUBLIC_LOCATION.country,
          points: PUBLIC_LOCATION.points,
          geocode: PUBLIC_LOCATION.geocode,
          status: PUBLIC_LOCATION.status,

        };


        console.log("SEARCH ===> ", FORM);

    
        const DATA_RESPONSE = await postApiPrivate('/admin/locations/create', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Create This Location', 'error');
      
            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("SEARCH ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('Location Created', 'This Location has been created successfully !!!', 'success');


        const location_id = DATA_RESPONSE.id;
        history.push('/admin/locations/profile/'+location_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Created This Location', 'error');
         
      }

  }

  const onSelectCountry = async(country) => {

      console.log("COUNTRY SELECTED ===> ", country);

      setFocused(false);

      setLocation({
        ...PUBLIC_LOCATION,
        country: {
          name: country.label,
          code: country.value
        }
      });

      setCountryInput(country.label);

      swal({
        title: 'Country Updated',
        text: 'You selected '+country.label,
        icon: 'success',
        timer: 2000
      })
      
  }

  const onSearchCountry = (query) => {

      setCountryInput(query);

      if(!query){
          setFocused(false);
          setCountryResults([]);

          return 
      }

      query = query.toLowerCase();
 

      const DATA_RESPONSE = Countries_EN.filter((item)=> item.label.toLowerCase().startsWith(query));

      if(!DATA_RESPONSE){
          setFocused(false);
          setCountryResults([]);

          return 
      }


      const filtredResults = DATA_RESPONSE || [];

      if(filtredResults.length > 0){
          setFocused(true);
          setCountryResults(filtredResults);
      }
    
          
  }

  const onSearchKeyUp = async(event) => {
      event.preventDefault();

      if (event.keyCode === 13) {
          event.target.blur();

          if(CountryResults.length>0){
              onSelectCountry(CountryResults[0]);
          }
      }
  }
 

  const onGenerateUrl = (lang, name) => {
    const newUrl = onFormatUrl(name);
    onUpdateLocation(lang+'_url', '/'+lang+'/search/location/'+newUrl);
  }



  if(isSaving){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
    )

  }


  const {
    country,
    points,
    status,
    en,
    it,
    fr,
    es,
    en_url,
    it_url,
    fr_url,
    es_url,


  } = PUBLIC_LOCATION;

  const geocode = PUBLIC_LOCATION.geocode || {};
  const geoLatitude = geocode.latitude || 0;
  const geoLongitude = geocode.longitude || 0;

  const CAN_SAVE = (
        en && en.length>0 &&
        it && it.length>0 &&
        fr && fr.length>0 &&
        es && es.length>0 &&
        en_url && en_url.length>0 &&
        it_url && it_url.length>0 &&
        fr_url && fr_url.length>0 &&
        es_url && es_url.length>0 &&
        geoLatitude !== 0 && geoLongitude !== 0 &&
        country && country.name
  )

  const LocationName = PUBLIC_LOCATION[LangName] || '';
  const LocationURL = PUBLIC_LOCATION[LangName+'_url'] || '';


  return(
  
      <Container maxWidth="lg" style={{background: '#f0f5f9'}}>

            <Grid container spacing={2}>
              
              <Grid item xs={12} lg={9} md={9}>
              
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                        
                        <div className="top_box_magic" style={{marginBottom: 20}}>
                            <h1>
                              Status
                            </h1>

                            {status ? (
                              <Chip label="Active" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateLocation('status', false)}/>
                            ) : (
                              <Chip label="Unactive" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning" onClick={(e)=> onUpdateLocation('status', true)} />
                            )}
                        </div>   

                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Name

                              <div>
                                <img src="/assets/languages/en.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5)}} onClick={()=> setLangName('en')}/>
                                <img src="/assets/languages/it.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5)}} onClick={()=> setLangName('it')}/>
                                <img src="/assets/languages/fr.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5)}} onClick={()=> setLangName('fr')}/>
                                <img src="/assets/languages/es.png" style={{width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5)}} onClick={()=> setLangName('es')}/>
                              </div>

                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={LocationName} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateLocation(LangName, e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  placeholder="Type here..."
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                           <h1 className="d-flex align-items-center justify-content-between">
                              Url

                              <div>
                                <div style={{fontSize: 14, color: '#40a4e9', cursor: 'pointer'}} onClick={()=> onGenerateUrl(LangName, PUBLIC_LOCATION[LangName])}>
                                  Auto-generate
                                </div>
                              </div>

                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={LocationURL} 
                                  label={'.'} 
                                  onChange={(e)=> onUpdateLocation(LangName+'_url', e.target.value)}
                                  InputLabelProps={{shrink: false}}
                                  placeholder="Type here..."
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1 className="d-flex align-items-center justify-content-between">
                              Country

                              <div>
                                <ReactCountryFlag countryCode={country?.code} svg style={{width: 20, height: 20, marginLeft: 5, cursor: 'pointer'}} /> 
                              </div>
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  value={CountryInput} 
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  onFocus={()=> setFocused(true)}  
                                  onChange={(e)=> onSearchCountry(e.target.value)} 
                                  onKeyUp={(e)=> onSearchKeyUp(e)}
                                
                              
                              />

                              {(isFocused && CountryResults.length > 0) && (
                                    <div style={{...Styles.resultsContainer}}>
                                        {CountryResults.map((item, index)=> {

                                            return (
                                                  <div style={Styles.resultsRow} 
                                                          key={index} 
                                                          onClick={()=> onSelectCountry(item)}
                                                  >

                                                        <div>
                                                          <ReactCountryFlag countryCode={item.value} svg style={{marginRight: 5}}/> 
                                                          {item.label}
                                                        </div>
                                                      
                                                        <CheckmarkCircleOutline size={28} color="#ccc" style={{marginRight: 10}} />
                                                        
                          
                                                  </div>
                                            )
                                            
                                        })}
                                    </div>
                                )}
                            </div>

                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Latitude
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={geoLatitude}
                                  onChange={(e)=> onUpdateLocation('latitude', e.target.value)}
                                  type="number"
                              
                              />
                            </div>
                        </div>

                        <div className="top_box_magic">
                            <h1>
                              Longitude
                            </h1>
                            <div  style={{margin: '10px 0px'}}>
                              <TextField 
                                  className={'gridyx-textfield'}
                                  label={'.'} 
                                  InputLabelProps={{shrink: false}}
                                  value={geoLongitude}
                                  onChange={(e)=> onUpdateLocation('longitude', e.target.value)}
                                  type="number"
                              
                              />
                            </div>
                        </div>
                        
                        <Iframe url={"https://maps.google.com/maps?q="+geocode.latitude+"%2C"+geocode.longitude+"&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                                width="100%"
                                height="300"
                                className="map_frame"
                                frameborder="0" 
                                scrolling="no" 
                                marginheight="0" 
                                marginwidth="0"
                        />
                </div>
                

              </Grid>
              <Grid item xs={12} lg={3} md={3}>
                <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                    

                    <div className="top_box_magic">
                        <h1>
                          Points
                        </h1>
                        <div  style={{margin: '10px 0px'}}>
                          <TextField 
                              className={'gridyx-textfield'}
                              label={'.'} 
                              InputLabelProps={{shrink: false}}
                              value={points}
                              onChange={(e)=> onUpdateLocation('points', e.target.value)}
                              type="number"
                          
                          />
                        </div>
                    </div>

                    <div className="top_add_cart"  
                        style={{backgroundColor: '#59ad6a'}} 
                        onClick={()=> {

                            if(!CAN_SAVE){

                                return swal("Incomplete", "Please complete all the fields in order to create this location", 'warning')
                            }

                            swal({
                                title: 'Confirm to Save',
                                text: 'Please insert the CONFIRM to create this location',
                                content: "input",
                            })
                            .then((value) => {

                            if(value === 'CONFIRM'){

                                onSaveLocation()
                            }
                            else{

                                swal('Invalid Password', 'You are not authorized to create this location', 'error');
                            }

                            });
                        }}
                        
                    >
                        <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Create Location
                    </div>

                    <Link className="top_add_cart"  style={{backgroundColor: 'var(--dark)'}} to="/admin/locations">
                        <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Go Back
                    </Link>
                          
                

                </div>
              </Grid>

            </Grid>  
       
          
      </Container>

    
  )


}



const Styles = {
  resultsRow: {
      display: 'flex', 
      alignItems: 'center', 
      color: '#000', 
      height: 40, 
      minHeight: 40,
      paddingLeft: 20, 
      border: '1px solid #00000040', 
      borderRight: 'none', 
      borderTop: 'none', 
      borderLeft: 'none',
      cursor: 'pointer',
      textTransform: 'capitalize',
      justifyContent: 'space-between'
  },
  resultsContainer: {
      display: 'flex', 
      flexDirection: 'column', 
      background: '#fff', 
      maxHeight: 280,
      position: 'relative', 
      top: 20, 
      border: '1px solid #00000040', 
      borderRadius: 10,
      overflow: 'auto',
      marginBottom: 20
  },
  right: {
      display: 'flex',
      alignItems: 'center'
  },
  searchContainer: {
      width: 300,
      height: 42,
      backgroundColor: '#F1F1F1',
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 5
  },
  input: {
      fontSize: 16,
      display: 'flex',
      color: '#000',
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      marginLeft: 5,
      textTransform: 'capitalize'
  },
  searchOption: {
      display: 'flex', 
      width: 40, 
      height: 35, 
      alignItems: 'center', 
      borderRadius: '50%', 
      background: '#fd6270'
  },
  button: {
        display: 'flex',
        height: '50px',
        width: '200px',
        backgroundColor: '#26BFBF',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',  
        borderRadius: 10,
        width: '100%'
    },
    img_full: {
      display: 'flex',
      width: 200, 
      height: 200, 
      borderRadius: 6, 
      marginRight: 15, 
      marginLeft: 15
    },
    img_empty: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%', 
      height: '100%', 
      borderRadius: 6, 
      marginRight: 15, 
      borderColor: '#ccc', 
      borderWidth: 2, 
      borderStyle: "dashed", 
      justifyContent: 'center', 
      alignItems: 'center',
      marginBottom: 10,
      
    },
    clickCard: {
        backgroundColor: "#fff",
        padding: 20,
        marginTop: 10,
    },
    clickLabel: {
        color: "#697883",
        fontFamily: "SourceSansPro-Bold", 
        fontSize: 14, 
        marginBottom: 10,
    },
    wrapFlex: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    publishBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fd6270',
      padding: 10,
      width: 90,
      color: '#fff',
      borderRadius: 10,
      cursor: 'pointer'
    }

}


export default AdminLocationsAdd

