import React, { useEffect, useState } from "react";

import { postApiPrivate } from "../../api";

import moment from "moment";
import swal from "sweetalert";
import { CloseOutline } from "react-ionicons";

import { GetRouteSearchParams, onFetchCurrency } from "../../helpers/GeneralHelper";

import ProviderMap from '../General/ProviderMap';

import Countries_EN from '../Search/countries_en.json';
import { Filter_Array } from "./Constants";
import StarReview from "../General/Stars";

const ReviewFilter = (props) => {


    const { onClose, history, location, match, DASHBOARD, CURRENCY, LANGUAGE, TRANSLATE, isMap } = props;

    const [Store, setStore] = useState(null);
    const [Type, setType] = useState('all');

    //Extra
    const [isLoading, setLoading] = useState(false);
    const [SearchResults, setSearchResults] = useState([]);
    const [SearchInput, setSearchInput] = useState('');


    useEffect(() => {

        const routeParams = GetRouteSearchParams(location.search);

        const routeType = routeParams.type || null;
        const routeStore = routeParams.store || null;
        const routeStoreName = routeParams.sname || null;

        if (routeType) setType(routeType);

        if (routeStore) {
            setStore({
                id: routeStore,
                name: routeStoreName
            });

            setSearchInput(routeStoreName)
        }

    }, [location.search]);

    const onApplyFilter = () => {

        let newQuery = '/admin/reviews/0?sort=updatedAt&dir=desc';

        if (isMap) {
            newQuery = `/admin/map?search=reviews&sort=updatedAt&dir=desc`
        }

        if (Type) newQuery = newQuery + '&type=' + Type;

        if (Store) newQuery = newQuery + '&store=' + Store.id + '&sname=' + Store.name;

        history.push(newQuery);

        onClose();
    }

    const onSearchStores = async (store) => {

        setSearchInput(store);

        if (!store || store.length <= 3) {
            setSearchResults([]);
            return
        }

        //Fetch

        setLoading(true);

        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/store/search', { query: store }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if (filtredResults.length > 0) {
                setSearchResults(filtredResults);
            }

            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setSearchResults([]);
            setLoading(false);
        }


    }

    const onSelectStore = (store) => {
        //alert(JSON.stringify(city))
        setStore(store);
        setSearchInput(store.name);
        setSearchResults([]);
    }

    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        <div className="font-bold text-lg">
                            Reviews Filter
                        </div>

                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>

                        <>
                            <div className="d-flex flex-column align-items-center justify-content-center pt-8 md:pb-0 pb-32">

                                <div className="w-full mt-8 flex flex-col text-black">

                                    {/* Store Status */}
                                    <div className="font-semibold mb-1 mt-4">Type</div>
                                    <select value={Type} onChange={(e) => setType(e.target.value)} className="text-black">
                                        <option value="all">
                                            All Reviews
                                        </option>
                                        <option value="provider">
                                            Only Reviews to Providers
                                        </option>
                                        <option value="customer">
                                            Only Reviews to Customers
                                        </option>
                                        <option value="product">
                                            Only Reviews to Products
                                        </option>
                                    </select>


                                    {/* Store */}
                                    <div className="font-semibold mb-1 mt-4">Store</div>
                                    <div className="flex">
                                        <input placeholder="Search by store" style={{ border: 'solid 1px #000000', color: '#000', width: Store?.id ? '90%' : '100%', marginRight: Store?.id ? 8 : 0 }}
                                            onChange={(e) => onSearchStores(e.target.value)}
                                            value={SearchInput}
                                            autoComplete="new-password"
                                        />
                                        {Store?.id && (
                                            <div className="flex items-center justify-center bg-green-700 rounded-full text-white" style={{ width: 40, height: 40 }}>
                                                ok
                                            </div>
                                        )}
                                    </div>

                                    {SearchResults.map((item, index) => {

                                        const store_name = item.name;
                                        const store_img = item.img;
                                        const store_score = item.score || 0;
                                        const store_reviews = item.reviews || 0;
                                        const store_rating = item.rating || 0;
                                        const store_distance = item.distance || 0;
                                        const store_status = item.status || false;
                                        const store_orders = item.orders || 0;

                                        return (

                                            <div className="w-full p-3 my-3 flex items-center justify-between cursor-pointer hover:bg-zinc-100" onClick={() => onSelectStore(item)} key={index}>
                                                <div className='flex'>
                                                    <img className='w-[40px] h-[40px] rounded-full object-cover mr-3 mb-auto mt-1' src={store_img || '/assets/img/avatar.svg'} alt='' />
                                                    <div className='flex flex-col'>
                                                        <div className='font-bold'>{store_name}</div>
                                                        <StarReview rate={store_rating} size={20} />
                                                        <div className='text-xs'>{store_reviews} reviews</div>
                                                        <div className='text-xs'>Score: {parseInt(store_score)}</div>
                                                        <div className='text-xs'>Orders: {parseInt(store_orders)}</div>
                                                        <div className='text-xs'>{store_distance.toFixed(2)} from center</div>
                                                    </div>
                                                </div>


                                                {store_status ? (
                                                    <div className='bg-green-500 text-xs text-white rounded-md p-1'>Published</div>
                                                ) : (
                                                    <div className='bg-orange-500 text-xs text-white rounded-md p-1'>Unpublished</div>
                                                )}

                                            </div>
                                        )

                                    })}




                                </div>

                                <div style={{ height: 200 }}></div>
                            </div>
                        </>

                    </div>

                    <div className="d-flex align-items-center justify-content-between" style={{ backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>
                        <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5 }} onClick={() => onClose()}>
                            Back
                        </div>


                        <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#26BFBF', marginLeft: 5 }} onClick={() => onApplyFilter()}>
                            Apply Filters
                        </div>

                    </div>


                </div>

            </div>


        </>
    )
}



export default ReviewFilter