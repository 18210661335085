import React, { useEffect, useState } from 'react';
import { postApiPrivate } from '../../../../api';

import { firstBy } from 'thenby';
import GoogleAutoComplete from '../../GoogleAutoComplete/GoogleAutoComplete';

const PickupLocation = (props) => {
  const { label, selectedLocation, setSelectedLocation, CITY, onClose, LANGUAGE } = props;
  const tabs = [`${CITY[LANGUAGE]} Districts`, 'Airports', 'Hotels'];

  console.log(CITY);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [POINT, setPoint] = useState();
  const [RESULTS, setResults] = useState([]);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    onFetchDelivery();
  }, []);

  const onFetchDelivery = async () => {
    window.scrollTo(0, 0);

    const JWT = typeof global.window !== 'undefined' ? localStorage.getItem('jwt_token') : null;

    setLoading(true);

    try {
      var DATA_RESPONSE = await postApiPrivate('/geo/delivery', { id: CITY.id }, JWT);

      if (DATA_RESPONSE.error) {
        console.log('ERROR FROM API FETCHING CARDS', DATA_RESPONSE.error);
        setLoading(false);
        return;
      }

      console.log('DATA_RESPONSE ====> ', DATA_RESPONSE);

      const Point = DATA_RESPONSE.p;
      const Results = DATA_RESPONSE.d || [];

      setLoading(false);
      setResults(Results);
      setPoint(Point);

      window.scrollTo(0, 0);

      //  alert(JSON.stringify(DATA_RESPONSE))
    } catch (e) {
      console.log('LOADING CARDS ===> ', e);
      setLoading(false);
    }
  };

  const onFormatResult = (FoundResults) => {
    const FormattedResults = FoundResults.sort(
      firstBy(function (a, b) {
        return parseFloat(a.distance) - parseFloat(b.distance);
      })
        .thenBy(function (a, b) {
          return parseFloat(b.products) - parseFloat(a.products);
        })
        .thenBy(function (a, b) {
          return parseFloat(b.stores.length) - parseFloat(a.stores.length);
        })
    );

    return FormattedResults;
  };

  //Filtred
  const FoundResults = RESULTS.filter((item) => parseFloat(item.distance) <= 100) || [];
  const FoundCities = onFormatResult(
    RESULTS.filter((item) => item.type === 'city' && parseFloat(item.distance) <= 100) || []
  );
  const FoundAirports = onFormatResult(
    RESULTS.filter((item) => item.type === 'airport' && parseFloat(item.distance) <= 100) || []
  );
  const FoundStations = onFormatResult(
    RESULTS.filter((item) => item.type === 'station' && parseFloat(item.distance) <= 100) || []
  );
  const FoundPorts = onFormatResult(
    RESULTS.filter((item) => item.type === 'port' && parseFloat(item.distance) <= 100) || []
  );
  const FoundOthers = onFormatResult(
    RESULTS.filter(
      (item) =>
        item.type !== 'city' &&
        item.type !== 'airport' &&
        item.type !== 'station' &&
        item.type !== 'port' &&
        parseFloat(item.distance) <= 100
    ) || []
  );

  const sharedProps = {
    ...props,
    RESULTS: FoundResults,
    POINT,
    FoundCities,
    FoundAirports,
    FoundStations,
    FoundPorts,
    FoundOthers,
    isLoading,
  };

  return (
    <div className='fixed w-screen h-screen top-0 left-0 z-10 flex md:items-center md:justify-center md:drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]'>
      <div className='absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]' />
      <div className='slide-in-bottom flex flex-col m-0 mt-auto md:mt-0 max-h-[600px] w-full md:w-2/3 md:max-w-[600px] px-4 sm:px-6 lg:px-8 pb-8 pt-8 bg-white md:rounded-2xl relative z-20 '>
        <img className='absolute top-6 right-6 cursor-pointer' src='/assets/img/close.svg' onClick={() => onClose()} />
        <h4 className='mt-4 mb-4 w-full'>Where should we deliver the baby gear?</h4>

        <div className='flex justify-between min-h-max w-full bg-light-gray rounded-lg'>
          <div className='flex overflow-hidden w-full rounded-lg'>
            {tabs.map((tab) => (
              <div
                key={tab}
                className={`p-3 w-full text-center cursor-pointer ${activeTab === tab ? 'bg-ba text-white' : ''}`}
                style={{ minWidth: 'max-content' }}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
        </div>

        <GoogleAutoComplete currentAddress={'London'} onConfirmAddress={(value) => setSelectedLocation(value)} />
      </div>
    </div>
  );
};

export default PickupLocation;
