import { Times_Array } from '../components/Constants/Constants';

import momentTZ from 'moment-timezone';
import tzlookup from 'tz-lookup';

const getDistanceBetweenCoordinates = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // km
  var dLat = getRadiant(lat2 - lat1);
  var dLon = getRadiant(lon2 - lon1);

  lat1 = getRadiant(lat1);
  lat2 = getRadiant(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

const getRadiant = (Value) => {
  return (Value * Math.PI) / 180;
};

const onReverseUrl = (url) => {
  if (!url) {
    return url;
  }

  return url
    .split('-') // Split the string by hyphens
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join the words with spaces
};

const onFormatUrl = (url) => {
  if (!url) {
    return url;
  }
  return url
    .toString() // Convert to string
    .normalize('NFD') // Change diacritics
    .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
    .replace(/\s+/g, '-') // Change whitespace to dashes
    .toLowerCase() // Change to lowercase
    .replace(/&/g, '-and-') // Replace ampersand
    .replace(/[^a-z0-9\-]/g, '') // Remove anything that is not a letter, number or dash
    .replace(/-+/g, '-') // Remove duplicate dashes
    .replace(/^-*/, '') // Remove starting dashes
    .replace(/-*$/, ''); // Remove trailing dashes
};

const translations = {
  online: {
    en: 'online',
    es: 'en línea',
    it: 'online',
    fr: 'en ligne',
  },
  'last seen a few seconds ago': {
    en: 'last seen a few seconds ago',
    es: 'visto hace unos segundos',
    'it-IT': 'visto qualche secondo fa',
    fr: 'vu il y a quelques secondes',
  },
  'last seen {0} minutes ago': {
    en: 'last seen {0} minutes ago',
    es: 'visto hace {0} minutos',
    it: 'visto {0} minuti fa',
    fr: 'vu il y a {0} minutes',
  },
  'last seen {0} hours ago': {
    en: 'last seen {0} hours ago',
    es: 'visto hace {0} horas',
    it: 'visto {0} ore fa',
    fr: 'vu il y a {0} heures',
  },
  'last seen yesterday at {0}': {
    en: 'last seen yesterday at {0}',
    es: 'visto ayer a las {0}',
    it: 'visto ieri alle {0}',
    fr: 'vu hier à {0}',
  },
  'last seen {0} days ago at {1}': {
    en: 'last seen {0} days ago at {1}',
    es: 'visto hace {0} días a las {1}',
    it: 'visto {0} giorni fa alle {1}',
    fr: 'vu il y a {0} jours à {1}',
  },
  'last seen on {0}': {
    en: 'last seen on {0}',
    es: 'visto el {0}',
    it: 'visto il {0}',
    fr: 'vu le {0}',
  },
  'last seen last week': {
    en: 'last seen last week',
    es: 'visto la semana pasada',
    it: 'visto la settimana scorsa',
    fr: 'vu la semaine dernière',
  },
};

const translate = (message, language) => translations[message]?.[language] || message;

const getOnlineStatus = (lastSeenDateString, language = 'en') => {
  // Convert the date string to a Date object
  const lastSeenDate = new Date(lastSeenDateString);

  // Check if the Date object is valid
  if (isNaN(lastSeenDate.getTime())) {
    return ''; //translate('Invalid date format', language);
  }

  // Get the current date and time
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - lastSeenDate;

  // Convert the time difference to seconds, minutes, hours, and days
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);

  // Determine the appropriate time unit to display
  if (secondsDifference < 5) {
    return translate('online', language);
  } else if (minutesDifference < 1) {
    return translate('last seen a few seconds ago', language);
  } else if (minutesDifference < 60) {
    return translate('last seen {0} minutes ago', language).replace('{0}', minutesDifference);
  } else if (hoursDifference < 24) {
    return translate('last seen {0} hours ago', language).replace('{0}', hoursDifference);
  } else if (daysDifference === 1) {
    const options = { hour: 'numeric', minute: 'numeric' };
    const formattedLastSeen = new Intl.DateTimeFormat(language, options).format(lastSeenDate);
    return translate('last seen yesterday at {0}', language).replace('{0}', formattedLastSeen);
  } else if (daysDifference < 7) {
    const options = { hour: 'numeric', minute: 'numeric' };
    const formattedLastSeen = new Intl.DateTimeFormat(language, options).format(lastSeenDate);
    return translate('last seen {0} days ago at {1}', language)
      .replace('{0}', daysDifference)
      .replace('{1}', formattedLastSeen);
  } else {
    // Format the last seen date for display
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedLastSeen = new Intl.DateTimeFormat(language, options).format(lastSeenDate);
    return translate('last seen last week', language);
    //return translate('last seen on {0}', language).replace('{0}', formattedLastSeen);
  }
};

const onBrowswerDeviceData = (navigator, window) => {
  var module = {
    options: [],
    header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
    dataos: [
      { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
      { name: 'Windows', value: 'Win', version: 'NT' },
      { name: 'iPhone', value: 'iPhone', version: 'OS' },
      { name: 'iPad', value: 'iPad', version: 'OS' },
      { name: 'Kindle', value: 'Silk', version: 'Silk' },
      { name: 'Android', value: 'Android', version: 'Android' },
      { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
      { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
      { name: 'Macintosh', value: 'Mac', version: 'OS X' },
      { name: 'Linux', value: 'Linux', version: 'rv' },
      { name: 'Palm', value: 'Palm', version: 'PalmOS' },
    ],
    databrowser: [
      { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
      { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
      { name: 'Safari', value: 'Safari', version: 'Version' },
      { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
      { name: 'Opera', value: 'Opera', version: 'Opera' },
      { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
      { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
    ],
    init: function () {
      var agent = this.header.join(' '),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);

      return { os: os, browser: browser };
    },
    matchItem: function (string, data) {
      var i = 0,
        j = 0,
        html = '',
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          };
        }
      }
      return { name: 'unknown', version: 0 };
    },
  };

  var e = module.init();

  return {
    os: e.os.name,
    browser: e.browser.name,
  };
};

const onGetCurrencySymbol = (currency, type) => {
  if (type === 'full') {
    if (currency === 'usd') {
      return '$ - USD - 🇺🇸';
    } else if (currency === 'gbp') {
      return '£ - GBP - 🇬🇧';
    } else {
      return '€ - EUR - 🇪🇺';
    }
  } else {
    if (currency === 'usd') {
      return '$';
    } else if (currency === 'gbp') {
      return '£';
    } else {
      return '€';
    }
  }
};

const onFormatImgUrl = (url) => {
  if (!url) {
    return '';
  }

  if (url.includes('mybabonbo.s3.amazonaws.com/')) {
    let newUrl = url.replace('mybabonbo.s3.amazonaws.com/', 'mybabonbo.s3.eu-central-1.amazonaws.com/');
    return newUrl;
  }

  return url;
};

const GetRouteSearchParams = (query) => {
  const routeParams = getQueryStringParams(query) || {};
  return routeParams;
};

const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
};

const onFormatPrices = ({ price_day, price_day_final, price_shown, duration, category, quantity }) => {
  let PriceTotalFee = parseFloat((price_day_final + (price_day_final * 15) / 100).toFixed(2));
  let PriceDayFee = parseFloat((price_day + (price_day * 15) / 100).toFixed(2));

  let PriceNoDiscount = parseFloat((PriceDayFee * duration).toFixed(2));
  let PriceDiscount = parseFloat((PriceNoDiscount - PriceTotalFee).toFixed(2));

  if (category === 'consumable') {
    PriceTotalFee = parseFloat(((price_day + (price_day * 15) / 100) * (quantity || 1)).toFixed(2));
    PriceDayFee = parseFloat((price_day + (price_day * 15) / 100).toFixed(2));
    PriceNoDiscount = parseFloat((PriceDayFee * (quantity || 1)).toFixed(2));
    PriceDiscount = 0;
  }

  let PriceDayFeeShown = 0;

  if (price_shown) {
    PriceDayFeeShown = parseFloat((price_shown + (price_shown * 15) / 100).toFixed(2));
  }

  return {
    Duration: duration,
    PriceTotalFee,
    PriceDayFee,
    PriceDayFeeShown,
    PriceNoDiscount,
    PriceDiscount,
  };
};

//Calculate Prices
const onCalculateDailyPrice = (PRODUCT, DURATION) => {
  var { price_day, price_month, price_week } = PRODUCT;

  if (DURATION < 7) {
    return price_day;
  } else if (DURATION >= 7 && DURATION < 30) {
    var PRICE_WEEK_DAILY = price_week / 7;
    return PRICE_WEEK_DAILY;
  } else if (DURATION >= 30) {
    var PRICE_MONTH_DAILY = price_month / 30;
    return PRICE_MONTH_DAILY;
  }
};

const numberWithCommas = (x, CURRENCY) => {
  if (isNaN(x)) {
    x = 0;
  }

  if (!CURRENCY) {
    return formatFloatWithDecimals(x);
  }

  if (CURRENCY === 'coin') {
    return x.toFixed(2);
  }

  return x.toLocaleString('en-US', {
    style: 'currency',
    currency: CURRENCY,
  });
};

function formatFloatWithDecimals(num) {
  const roundedNum = Math.round(num * 10) / 10; // Round to one decimal place
  const numString = num.toString();
  const decimalIndex = numString.indexOf('.');

  if (decimalIndex !== -1 && numString.substring(decimalIndex + 1).length > 2) {
    return roundedNum.toLocaleString(); // Return formatted number with commas
  } else {
    return num.toLocaleString(); // Return original number if it has 2 or fewer decimal places
  }
}

const onFetchLanguage = (lang) => {
  if (lang === 'en') {
    return 'ENG';
  } else if (lang === 'it') {
    return 'IT';
  } else if (lang === 'fr') {
    return 'FR';
  } else if (lang === 'es') {
    return 'ES';
  } else if (lang === 'de') {
    return 'DE';
  } else if (lang === 'ar') {
    return 'AR';
  } else if (lang === 'ja') {
    return 'JA';
  } else if (lang === 'zh') {
    return 'ZH';
  }
};

const onFetchCurrency = (curr) => {
  if (curr === 'eur') {
    return '€';
  } else if (curr === 'usd') {
    return '$';
  } else if (curr === 'gbp') {
    return '£';
  }
};

const onFetchTime = (time, lang) => {
  if (!time) {
    return '';
  }

  if (!lang) {
    lang = 'en';
  }

  const FoundIndex = Times_Array.findIndex((item) => item.code === time);
  if (FoundIndex == -1) {
    return '';
  }

  const FoundTime = Times_Array[FoundIndex] || {};
  const FoundText = FoundTime[lang];

  return FoundText;
};

function convertQueryStringToObject(queryString, url) {
  const urlParams = new URLSearchParams(queryString);
  const queryParams = {};

  for (const [key, value] of urlParams.entries()) {
    queryParams[key] = value;
  }

  const PickupString = queryParams.pickadd ? window.atob(queryParams.pickadd) : '';
  const PickupObx = PickupString ? GetRouteSearchParams(PickupString) : {};

  // Extract "milan" and "IT" from the URL
  queryParams.city = url.pathname.split('/')[3];
  queryParams.country = url.pathname.split('/')[4];

  queryParams.state = url.pathname.split('/')[4];
  queryParams.country_2 = url.pathname.split('/')[5];

  queryParams.address = PickupObx.input;
  queryParams.string = urlParams.pickadd;

  return queryParams;
}

function convertURLToObject(urlString) {
  if (!urlString) {
    return {};
  }

  if (!urlString.includes('http')) {
    urlString = 'https://www.babonbo.com' + urlString;
  }

  const url = new URL(urlString);
  const queryString = url.search; // Remove the leading "?" character

  return convertQueryStringToObject(queryString, url);
}

function getTimezoneFromCoordinates(latitude, longitude) {
  // Use tz-lookup to get the timezone based on coordinates
  const timezone = tzlookup(latitude, longitude);

  return timezone;
}

// Function to calculate last-minute fee
const onBrainLastMinute = (checkin, checkout, latitude, longitude) => {
  const storeTimezone = getTimezoneFromCoordinates(latitude, longitude);

  // Define the threshold for last-minute booking (in hours)
  const lastMinuteThresholdHours = 24 * 60 * 60;

  // Convert the check-in and check-out times to the store's timezone (Europe/Rome)
  const checkinStoreTime = momentTZ.tz(checkin, storeTimezone).startOf('day');
  const checkoutStoreTime = momentTZ.tz(checkout, storeTimezone).startOf('day');

  // Convert the current time to the customer's timezone (Europe/Amsterdam)
  const currentTime = momentTZ.tz();

  const isValid = currentTime.isSameOrBefore(checkinStoreTime);

  // Calculate the duration between check-in and check-out in hours
  const hoursRemaining = checkoutStoreTime.diff(currentTime, 'hours');

  // Calculate the remaining minutes to reach checkinStoreTime
  const secondsRemaining = checkinStoreTime.diff(currentTime, 'seconds');

  const secondsRemainingThreshold = secondsRemaining - lastMinuteThresholdHours;

  function formatSeconds(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  }

  // Check if the booking is considered last-minute
  if (secondsRemaining <= lastMinuteThresholdHours) {
    return {
      storeTimezone,
      isValid,
      countdownLastminute: formatSeconds(secondsRemainingThreshold),
      countdownCheckin: formatSeconds(secondsRemaining),
      secondsRemainingLastminute: secondsRemainingThreshold,
      secondsRemainingCheckin: secondsRemaining,
      lastMinuteThresholdHours,
      rentalCheckin: checkinStoreTime,
      rentalCheckout: checkoutStoreTime,
      currentTime,
      message: 'Last-minute fee is applicable.',
      isLastminute: true,
    };
  } else {
    return {
      storeTimezone,
      isValid,
      countdownLastminute: formatSeconds(secondsRemainingThreshold),
      countdownCheckin: formatSeconds(secondsRemaining),
      secondsRemainingLastminute: secondsRemainingThreshold,
      secondsRemainingCheckin: secondsRemaining,
      lastMinuteThresholdHours,
      rentalCheckin: checkinStoreTime,
      rentalCheckout: checkoutStoreTime,
      currentTime,
      message: 'Last-minute fee is not applicable.',
      isLastminute: false,
    };
  }
};

const onCalculateLastMinute = (ORDER) => {
  const Store = ORDER?.temp || {};
  const Geocode = Store?.geocode;

  if (Geocode) {
    const Latitude = parseFloat(Geocode.latitude);
    const Longitude = parseFloat(Geocode.longitude);
    const Checkin = ORDER?.checkin;
    const Checkout = ORDER?.checkout;

    const LastMinute = onBrainLastMinute(Checkin, Checkout, Latitude, Longitude);

    // swal('Order', JSON.stringify({
    //     Checkin,
    //     Checkout,
    //     LastMinute
    // }, null, 2), 'success');

    return LastMinute.isLastminute;
  }
};

const onMaskPhoneNumber = (inputString) => {
  // Define a regular expression to match phone numbers with various formats
  const phoneRegex = /(\d{3}[-.\s]?\d{3}[-.\s]?\d{4}|\(\d{3}\)[-.\s]?\d{3}[-.\s]?\d{4}|\d{10})/g;

  // Replace phone numbers with hashtags
  const stringWithHashtags = inputString.replace(phoneRegex, function (match) {
    // Check if the matched text is exactly 10 digits (to prevent partial matches)
    if (/^\d{10}$/.test(match)) {
      return '##########';
    }

    // Handle various formats of phone numbers
    const sanitizedNumber = match.replace(/[^\d]/g, ''); // Remove non-digit characters
    return '##########'.slice(0, sanitizedNumber.length); // Use hashtags with the same length as the digits
  });

  return stringWithHashtags;
};

const onFormatAddress = (location) => {
  const dataAddress = location?.address || {};

  const administrative = dataAddress.administrative;
  const boundary = dataAddress.boundary;
  const village = dataAddress.village;
  const county = dataAddress.county;
  const city = dataAddress.city;
  const state = dataAddress.state;
  const country = dataAddress.country;
  const suburb = dataAddress.suburb;

  const dataType = location.addresstype || '';
  const dataDisplay = location.display_name || '';

  let final_location = state && state !== city ? `${city}, ${state}, ${country}` : `${city}, ${country}`;

  if (dataType === 'village') {
    final_location = `${village}, ${county}, ${country}`;
  } else if (dataType === 'island') {
    final_location = `${dataDisplay}`;
  } else if (dataType === 'suburb') {
    final_location = `${suburb}, ${state}, ${country}`;
  } else {
    if (!city) {
      if (boundary) {
        final_location = state && state !== boundary ? `${boundary}, ${state}, ${country}` : `${boundary}, ${country}`;
      }
      if (administrative) {
        final_location =
          state && state !== administrative
            ? `${administrative}, ${state}, ${country}`
            : `${administrative}, ${country}`;
      }
    }
  }

  return final_location;
};

// Function to calculate the distance between two coordinates
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance.toFixed(2); // Round to 2 decimal places
};

export {
  GetRouteSearchParams,
  calculateDistance,
  convertQueryStringToObject,
  convertURLToObject,
  getDistanceBetweenCoordinates,
  getOnlineStatus,
  getRadiant,
  numberWithCommas,
  onBrowswerDeviceData,
  onCalculateDailyPrice,
  onCalculateLastMinute,
  onFetchCurrency,
  onFetchLanguage,
  onFetchTime,
  onFormatAddress,
  onFormatImgUrl,
  onFormatPrices,
  onFormatUrl,
  onGetCurrencySymbol,
  onMaskPhoneNumber,
  onReverseUrl,
};
