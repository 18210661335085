import React, { useState, useEffect } from "react";

import LoaderMaster from "../Loaders/Loaders";
import StarRating from "../General/Stars";

import { postApiPrivate } from "../../api";

import { AnalyticsOutline, AnalyticsSharp, CalendarOutline, CalendarSharp, CashSharp, ChatbubbleEllipsesSharp, CloseSharp, EyeSharp, LocationSharp, LockOpenSharp, MailSharp, NotificationsSharp, ReaderSharp, SaveSharp, ShieldSharp, ShirtSharp, StarSharp, TrashBinSharp, WifiSharp } from "react-ionicons";

import ReactCountryFlag from "react-country-flag";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, TextareaAutosize, TextField } from "@mui/material";

import ReviewComponent from "../Reviews/ReviewComponent";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { onFormatUrl } from "../../helpers/GeneralHelper";

import StoreActions from '../Modals/StoreActions';
import StoreAnalytics from '../Modals/StoreAnalytics';
import StoreAvailability from '../Modals/StoreAvailability';
import StoreDelivery from '../Modals/StoreDelivery';
import StoreImage from '../Modals/StoreImage';
import StorePayout from '../Modals/StorePayout';
import StoreSchedules from '../Modals/StoreSchedules';
import StoreLocation from '../Modals/StoreLocation';

import TimezoneSelector from '../General/TimezoneSelector'

import "../Profile/z_profile.css";

const AdminStoresDetails = (props) => {

  const { TRANSLATE, history, match, location } = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [PUBLIC_STORE, setStore] = useState({});

  const [isActions, setActions] = useState(false);
  const [isAnalytics, setAnalytics] = useState(false);
  const [isAvailability, setAvailability] = useState(false);
  const [isDelivery, setDelivery] = useState(false);
  const [isImage, setImage] = useState(false);
  const [isPayout, setPayout] = useState(false);
  const [isSchedules, setSchedules] = useState(false);
  const [isLocation, setLocation] = useState(false);


  useEffect(() => {

    var store_id = match.params.id || '';

    onFetchPublic(store_id);

  }, []);

  useEffect(() => {

    if (isAnalytics || isActions || isAvailability || isDelivery || isSchedules || isPayout || isImage || isLocation) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isAnalytics, isActions, isAvailability, isDelivery, isSchedules, isPayout, isImage, isLocation])

  const onFetchPublic = async (store_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setEdited(false);
    setLoading(true);

    try {

      const FORM = { id: store_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/stores/details', FORM, JWT);

      if (DATA_RESPONSE.error) {

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      setStore(DATA_RESPONSE || {});
      setLoading(false);

    }
    catch (e) {

      setLoading(false);
    }

  }

  const onUpdateStore = async (key, value) => {

    setEdited(true);
    setStore({
      ...PUBLIC_STORE,
      [key]: value
    });

  }

  const onSaveStore = async (store_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    if (!store_id) {
      return alert('alert no store_id');
    }

    setSaving(true);

    try {

      const FORM = {
        id: store_id,
        about: PUBLIC_STORE.about,
        name: PUBLIC_STORE.name,
        phone: PUBLIC_STORE.phone,
        currency: PUBLIC_STORE.currency,
        status: PUBLIC_STORE.status ? 'published' : 'unpublished',
        img_id: PUBLIC_STORE.img_id,
        timezone: PUBLIC_STORE?.timezone?.value,
        start: PUBLIC_STORE?.start,
      }

      //return alert(JSON.stringify(FORM));

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/stores/edit', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update This Store', 'error');
        onFetchPublic(store_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Store Updated', 'This Store has been updated successfully !!!', 'success');
      onFetchPublic(store_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update This Store', 'error');
      onFetchPublic(store_id);
    }

  }

  const onSelectImage = (image) => {
    // alert('IMAGE ===> '+JSON.stringify(image, null, 2))
    setEdited(true);
    setStore({
      ...PUBLIC_STORE,
      img: image.src,
      img_id: image.id
    });
  }

  const onDeleteStore = async (store_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: store_id };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/stores/delete', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Delete This Store', 'error');
        onFetchPublic(store_id);
        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      setSaving(false);
      swal('Store Deleted', 'This Store has been deleted successfully !!!', 'success');
      history.replace('/admin/stores');

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Delete This Store', 'error');
      onFetchPublic(store_id);
    }
  }

  const onSavePassword = async (store_id, new_password) => {
    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: store_id, np: new_password.trim() };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/stores/password', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot Update the password for this Store', 'error');
        onFetchPublic(store_id);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Store Updated', 'This Store\'s Password has been updated successfully !!!', 'success');
      onFetchPublic(store_id);

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update the password for this Store', 'error');
      onFetchPublic(store_id);
    }

  }

  const onSendPush = async (user_id, content) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    setSaving(true);

    try {

      const FORM = { id: user_id, content: content.trim() };

      console.log("SEARCH ===> ", FORM);

      const DATA_RESPONSE = await postApiPrivate('/admin/users/push', FORM, JWT);

      if (DATA_RESPONSE.error) {
        setSaving(false);
        swal('Error', 'Cannot send the push notification to this Store', 'error');

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
        return
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      setSaving(false);
      swal('Push Sent', 'This push notification has been sent successfully to this Store !!!', 'success');

    }
    catch (e) {

      setSaving(false);
      swal('Error', 'Cannot Update the password for this Store', 'error');

    }

  }

  const onSelectCopy = (copy) => {
    swal('Copied to Clipboard !!!', copy, 'success')
  }

  if (isLoading) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading} />
    )

  }

  if (isSaving) {

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving} />
    )

  }


  const {
    id,
    name,
    img,
    updatedAt,
    createdAt,
    rating,
    email,
    phone,
    about,
    currency,
    status,
    address,

    communication,
    logistic,
    products,

    products_count,

    reviews,

    country,

    timezone,

    start,
    total,

    owner,
    approval

  } = PUBLIC_STORE;

  const score = PUBLIC_STORE.score ? parseFloat(PUBLIC_STORE.score.toFixed(2)) : 0;
  const total_orders = PUBLIC_STORE.total || 0;
  const total_green = PUBLIC_STORE.green || 0;
  const total_red = PUBLIC_STORE.red || 0;
  const total_yellow = PUBLIC_STORE.yellow || 0;


  const ownerObx = owner || {};
  const ownerName = ownerObx.name || '';
  const ownerImg = ownerObx.img || '';
  const ownerId = ownerObx.id || '';


  let CurrencyCode = 'eu';

  if (currency === 'usd') {
    CurrencyCode = 'us'
  }
  else if (currency === 'gbp') {
    CurrencyCode = 'gb'
  }

  return (

    <>

      {isActions && (
        <StoreActions {...props}
          onClose={() => setActions(false)}
          STORE_ID={id}

        />
      )}

      {isAnalytics && (
        <StoreAnalytics {...props}
          onClose={() => setAnalytics(false)}
          STORE_ID={id}
          CURRENCY={currency || 'eur'}

        />
      )}

      {isAvailability && (
        <StoreAvailability {...props}
          onClose={() => setAvailability(false)}
          STORE_ID={id}

        />
      )}

      {isDelivery && (
        <StoreDelivery {...props}
          onClose={() => setDelivery(false)}
          STORE_ID={id}
          DASHBOARD={PUBLIC_STORE || {}}
          onFetchStore={onFetchPublic}
          CURRENCY={currency || 'eur'}

        />
      )}

      {isLocation && (
        <StoreLocation {...props}
          onClose={() => setLocation(false)}
          STORE_ID={id}
          DASHBOARD={PUBLIC_STORE || {}}
          onFetchStore={onFetchPublic}
          CURRENCY={currency || 'eur'}

        />
      )}

      {isImage && (
        <StoreImage {...props}
          onClose={() => setImage(false)}
          STORE_ID={id}
          onSelectImage={onSelectImage}
          TYPE={"stores"}

        />
      )}

      {isPayout && (
        <StorePayout {...props}
          onClose={() => setPayout(false)}
          STORE_ID={id}
          DASHBOARD={PUBLIC_STORE}
          CURRENCY={currency || 'eur'}

        />
      )}

      {isSchedules && (
        <StoreSchedules {...props}
          onClose={() => setSchedules(false)}
          STORE_ID={id}

        />
      )}

      <Grid container spacing={2}>

        <Grid item xs={12} lg={4} md={6}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>
            <div className="top_store_card">
              <div className="top_store_profile">

                {img ? (
                  <img className="profile_img cursor-pointer" src={img} onClick={() => setImage(true)} />
                ) : (
                  <div className="profile_img cursor-pointer" src={'/assets/img/avatar.svg'} onClick={() => setImage(true)} />
                )}

                <div className="font-bold text-lg">{name}</div>
                <StarRating rate={rating} reviews={reviews} />

              </div>

              <div className="top_store_stats">
                <div>
                  <div className="font-bold text-lg">{total_orders || 0}</div>
                  <p>Orders</p>
                </div>
                <div>
                  <div className="font-bold text-lg">{products_count || 0}</div>
                  <p>Products</p>
                </div>
                <div>
                  <div className="font-bold text-lg">{score || 0}</div>
                  <p>Score</p>
                </div>
              </div>

              {/* <div className="top_store_stats">
                  <div>
                    <div className="font-bold text-lg">{total_green || 0}</div>
                    <p>Green orders</p>
                  </div>
                  <div>
                    <div className="font-bold text-lg">{total_red || 0}</div>
                    <p>Red orders</p>
                  </div>
                  <div>
                    <div className="font-bold text-lg">{total_yellow || 0}</div>
                    <p>Yellow orders</p>
                  </div>
              </div> */}

            </div>

            {!isEdited && (
              <div className="row justify-content-center">
                <a className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} href={'https://www.babonbo.com/en/' + onFormatUrl(name) + '/bs/' + id} target="_blank">
                  <EyeSharp color={'#fff'} style={{ marginRight: 10 }} /> Live Store
                </a>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setAnalytics(true)}>
                  <AnalyticsSharp color={'#fff'} style={{ marginRight: 10 }} /> Analytics
                </div>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/orders/0?sort=updatedAt&dir=desc&store=' + id)}>
                  <ReaderSharp color={'#fff'} style={{ marginRight: 10 }} /> Orders
                </div>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/products/0?sort=updatedAt&dir=desc&store=' + id)}>
                  <ShirtSharp color={'#fff'} style={{ marginRight: 10 }} /> Products
                </div>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/inbox/0?sort=updatedAt&dir=desc&store=' + id)}>
                  <ChatbubbleEllipsesSharp color={'#fff'} style={{ marginRight: 10 }} /> Chat
                </div>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/reviews/0?sort=updatedAt&dir=desc&store=' + id)}>
                  <StarSharp color={'#fff'} style={{ marginRight: 10 }} /> Reviews
                </div>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/notifications/0?sort=updatedAt&dir=desc&owner=' + ownerId)}>
                  <NotificationsSharp color={'#fff'} style={{ marginRight: 10 }} /> Notifications
                </div>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setDelivery(true)}>
                  <LocationSharp color={'#fff'} style={{ marginRight: 10 }} /> Delivery
                </div>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setSchedules(true)}>
                  <CalendarSharp color={'#fff'} style={{ marginRight: 10 }} /> Schedules
                </div>



                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setAvailability(true)}>
                  <ShieldSharp color={'#fff'} style={{ marginRight: 10 }} /> Availability
                </div>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setPayout(true)}>
                  <CashSharp color={'#fff'} style={{ marginRight: 10 }} /> Payout
                </div>

                <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => setActions(true)}>
                  <WifiSharp color={'#fff'} style={{ marginRight: 10 }} /> Actions
                </div>

                {/* <div className="top_add_cart"  
                                style={{backgroundColor: '#59ad6a'}} 
                                onClick={()=> {
                                swal({
                                    title: 'New Push',
                                    text: 'Please insert the text you would like to send to '+name,
                                    content: "input",
                                })
                                .then((value) => {

                                      if(!value){
                                        swal('Invalid Content', 'The Text Content Need to be at least 6 chars long', 'warning');
                                        return
                                      }

                                      let new_content = value.trim();

                                      if(new_content.length < 6){
                                        swal('Invalid Content', 'The Text Content Need to be at least 6 chars long', 'warning');
                                        return
                                      }
                            
                                      swal({
                                          title: 'Confirm to Send Push',
                                          text: 'Please insert the master password to send this push notification',
                                          content: "input"
                                      })
                                      .then((value) => {
  
                                        if(value === 'KingIsMe'){
                                            onSendPush(ownerId, new_content)
                                        }
                                        else{
  
                                            swal('Invalid Push', 'You are not authorized to send this push notification', 'error');
                                        }
  
                                      });
                                  
                                });

                              }}
                          >
                            <NotificationsSharp color={'#fff'} style={{marginRight: 10}} /> Send Push
                          </div>

                          <div className="top_add_cart"  style={{backgroundColor: '#ffbb49'}} onClick={()=> window.location.replace('mailto:'+email)}>
                            <MailSharp color={'#fff'} style={{marginRight: 10}} /> Send Email
                          </div>

                          <div className="top_add_cart"  
                                style={{backgroundColor: '#ccc'}} 
                                onClick={()=> {
                                swal({
                                    title: 'New Password',
                                    text: 'What is the new password for '+email,
                                    content: "input",
                                })
                                .then((value) => {

                                      if(!value){
                                        swal('Invalid Password', 'The Password Need to be at least 6 chars long', 'warning');
                                        return
                                      }

                                      let new_password = value.trim();

                                      if(new_password.length < 6){
                                        swal('Invalid Password', 'The Password Need to be at least 6 chars long', 'warning');
                                        return
                                      }
                            
                                      swal({
                                          title: 'Confirm to Update Password',
                                          text: 'Please insert the master password to update this user\'s password',
                                          content: "input",
                                      })
                                      .then((value) => {
  
                                        if(value === 'KingIsMe'){
                                            onSavePassword(ownerId, new_password)
                                        }
                                        else{
  
                                            swal('Invalid Password', 'You are not authorized to update this user\'s password', 'error');
                                        }
  
                                      });
                                  
                                });

                              }}
                          >
                            <LockOpenSharp color={'#fff'} style={{marginRight: 10}} /> Send Password
                          </div>

                          <div className="top_add_cart" 
                                style={{backgroundColor: '#e64942'}} 
                                onClick={()=> {
                                  swal({
                                      title: 'Confirm to Delete',
                                      text: 'Please insert the master password to delete this user',
                                      content: "input",
                                  })
                                  .then((value) => {

                                    if(value === 'KingIsMe'){

                                        onDeleteStore(id)
                                    }
                                    else{

                                        swal('Invalid Password', 'You are not authorized to delete this user', 'error');
                                    }

                                  });
                                }}
                          >
                            <TrashBinSharp color={'#fff'} style={{marginRight: 10}} /> Delete Store 
                          </div> */}


              </div>
            )}

            {isEdited && (
              <>

                <div className="top_add_cart"
                  style={{ backgroundColor: '#59ad6a' }}
                  onClick={() => {
                    swal({
                      title: 'Confirm to Save',
                      text: 'Please insert CONFIRM to save',
                      content: "input",
                    })
                      .then((value) => {

                        if (value === 'CONFIRM') {

                          onSaveStore(id)
                        }
                        else {

                          swal('Invalid Password', 'You are not authorized to update this user', 'error');
                        }

                      });
                  }}

                >
                  <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Update Store
                </div>

                <div className="top_add_cart" style={{ backgroundColor: '#ccc' }} onClick={() => onFetchPublic(id)}>
                  <CloseSharp color={'#fff'} style={{ marginRight: 10 }} /> Cancel Changes
                </div>

              </>
            )}


          </div>
        </Grid>

        <Grid item xs={12} lg={5} md={6}>

          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

            <div className="top_box_magic flex flex-row items-center justify-between" style={{ marginBottom: 20 }}>
              <div className="top_box_magic" style={{ marginBottom: 20 }}>
                <h1>
                  Status
                </h1>

                {status ? (
                  <Chip label="Published" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="success" onClick={(e) => onUpdateStore('status', false)} />
                ) : (
                  <Chip label="Unpublished" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="warning" onClick={(e) => onUpdateStore('status', true)} />
                )}
              </div>
              <div className="top_box_magic" style={{ marginBottom: 20 }}>
                <h1>
                  Approval
                </h1>

                {approval ? (
                  <Chip label="Need Approval" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="warning" />
                ) : (
                  <Chip label="Approved" style={{ marginTop: 10, width: 'fit-content' }} variant="filled" color="success" />
                )}
              </div>
            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Store Name
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  value={name}
                  label={'.'}
                  onChange={(e) => onUpdateStore('name', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  placeholder="Type here..."


                />
              </div>
            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Store Email
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  value={email}
                  label={'.'}
                  onChange={(e) => onUpdateStore('email', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  placeholder="Type here..."
                  disabled

                />
              </div>
            </div>

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Store Phone
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  value={phone}
                  label={'.'}
                  onChange={(e) => onUpdateStore('phone', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  placeholder="Type here..."

                />
              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                Store Address
              </h1>
              <div className="flex items-center" style={{ margin: '10px 0px' }}>

                {!address ? (
                  <Chip size='medium' label="NO" />
                ) : (
                  <CopyToClipboard text={address}
                    onCopy={() => onSelectCopy(address)}
                  >
                    <Chip
                      avatar={<ReactCountryFlag countryCode={country} style={{ fontSize: 20, marginLeft: 10 }} svg />}
                      label={address.toLowerCase()}
                      variant="outlined"
                      size='medium'
                      style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                    />

                  </CopyToClipboard>
                )}

                <img className="ml-4 cursor-pointer" src="/assets/img/edit.svg" onClick={() => setLocation(true)} alt="" />

              </div>
            </div>

            <div className="top_box_magic">
              <h1>
                Store Description
              </h1>
              <div style={{ margin: '10px 0px' }}>

                <TextareaAutosize
                  minRows={4}
                  maxRows={10}
                  placeholder="Please insert the store's description"
                  defaultValue={about}
                  style={{ width: '100%', border: 'solid 1px #00000019', borderRadius: 10, padding: 10 }}
                  onChange={(e) => onUpdateStore('about', e.target.value)}
                />

              </div>
            </div>


            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Store Currency

                <div>
                  <ReactCountryFlag countryCode={CurrencyCode} svg style={{ width: 20, height: 20, marginLeft: 5, cursor: 'pointer' }} />
                </div>

              </h1>

              <div style={{ margin: '10px 0px' }}>
                <select
                  className={'gridyx-select'}
                  value={currency}
                  label={'.'}
                  onChange={(e) => onUpdateStore('currency', e.target.value)}
                  InputLabelProps={{ shrink: false }}
                  select
                >
                  <option value={null}>
                    ---- Select a currency ----
                  </option>
                  <option value="usd">
                    $ - USD
                  </option>
                  <option value="eur">
                    € - EUR
                  </option>
                  <option value="gbp">
                    £ - GBP
                  </option>

                </select>
              </div>

            </div>





          </div>

        </Grid>
        <Grid item xs={12} lg={3} md={3}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20 }}>

            <div className="top_box_magic">
              <h1>
                Store Created
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                </p>
              </div>
            </div>
            <div className="top_box_magic">
              <h1>
                Store Updated
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <p>
                  {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                </p>
              </div>
            </div>

            {ownerId && (
              <div className="top_box_magic" style={{ marginBottom: 10 }}>
                <h1>
                  Store Owner
                </h1>

                <Chip
                  avatar={<Avatar alt={ownerName} src={ownerImg} />}
                  label={ownerName.toLowerCase()}
                  variant="outlined"
                  size='medium'
                  style={{ textTransform: 'capitalize', marginTop: 10, width: 'fit-content' }}
                  onClick={() => history.push('/admin/users/profile/' + ownerId)}
                />

              </div>
            )}

            <div className="top_box_magic">
              <h1 className="d-flex align-items-center justify-content-between">
                Store Timezone

              </h1>

              <div style={{ margin: '10px 0px' }}>
                <TimezoneSelector
                  selectedTimezone={timezone}
                  onSelectTimezone={(tz) => onUpdateStore('timezone', tz)}
                />
              </div>

            </div>

            <div className="top_box_magic">
              <h1>
                Start Orders
              </h1>
              <div style={{ margin: '10px 0px' }}>
                <TextField
                  className={'gridyx-textfield'}
                  label={'.'}
                  InputLabelProps={{ shrink: false }}
                  value={start}
                  onChange={(e) => onUpdateStore('start', e.target.value)}
                  type="number"

                />
              </div>
              <div>Total orders: {parseInt(total) + parseInt(start)}</div>
            </div>

            <ReviewComponent title="Communication"
              value={communication || 0}
              ReadOnly={true}

            />
            <ReviewComponent title="Logistics"
              value={logistic || 0}
              ReadOnly={true}

            />
            <ReviewComponent title="Products"
              value={products || 0}
              ReadOnly={true}

            />

          </div>
        </Grid>
      </Grid>

    </>
  )


}


export default AdminStoresDetails
