import React from 'react'
import { Link } from 'react-router-dom';

import { Filter_Array } from '../Constants/Constants';
import { onFormatUrl, GetRouteSearchParams } from '../../helpers/GeneralHelper';

const CategoryFilter = (props) => {

    const {LANGUAGE, TRANSLATE, location, history, zIndex} = props;
    
    const routeParams = GetRouteSearchParams(location.search);
    const routeCategories = routeParams.categories;

    const finalCategories = routeCategories ? routeCategories.split('-') : [];
    const matchedCategories = onMatchedCategories(finalCategories) || [];

    const onSelectCategory = (category) => {

        let newCategories = [];

        if(!matchedCategories.includes(category)){
            newCategories = [...matchedCategories, category];
        }
        else{
            newCategories = matchedCategories.filter((item)=> item !== category);
        }

        onGenerateNewResultURL(newCategories);
            
    }

    const onGenerateNewResultURL = (newCategories) => {

        const newCatString = '&categories='+newCategories.join('-');

        const original_url = 'https://www.babonbo.com'+ window.location.pathname + window.location.search;

        const filtred_url = removeParamFromURL(original_url, 'categories');

        const new_url = filtred_url.replace('https://www.babonbo.com', '') + newCatString

        history.push(new_url);
        
    }

    


    return (
        <>
             <div className="mx-auto max-w-8xl px-0 mb:py-0 py-4 sm:px-6 lg:px-4 md:mb-8 flex items-center sticky top-[69px] md:top-[100px] bg-white" style={{zIndex: zIndex || 40}} id="cat-bar">
                <div className='w-full overflow-auto scrollbar-hide px-4 md:px-0 flex'>
                    <div className='md:bg-white flex-shrink-0 bg-zinc-100 rounded-full flex flex-nowrap justify-between items-center px-4 md:px-0 md:w-full gap-x-2 md:gap-x-4 text-[10px] md:text-xs'>
                         
                            {/* {matchedCategories} */}

                            {Filter_Array.map((item, index) => {

                                const Cat_Name = item[LANGUAGE];
                                const Cat_Code = item.sub; 
                                const isActive = matchedCategories.includes(Cat_Code);
                                
                                return (
                                    <div className={'h-[72px] w-[72px] md:h-[90px] md:w-[90px] shrink-0 rounded-2xl flex-col flex justify-center align-center items-center cursor-pointer md:hover:bg-zinc-100 '+(isActive ? 'md:bg-zinc-100 bg-white border-4 md:border-2 border-zinc-100 md:border-babonbo-100' : '')} key={index} onClick={()=> onSelectCategory(Cat_Code)}>
                                        <img className='w-[26px] h-[26px] md:w-auto md:h-auto' src={item.icon} />
                                        <span className='whitespace-nowrap'>{Cat_Name}</span>
                                    </div>
                                )

                            })}
                    
                    </div>
                </div>
            </div>
        </>
    )

}

const onMatchedCategories = (categories) => {

    if(!categories || categories.length == 0){
        return []
    }

    var matched = [];

    for(var i=0; i<categories.length; i++){
        const cat = categories[i];

        let CAT_INDEX = Filter_Array.findIndex((item)=> item && item.keywords.includes(cat.toLowerCase()));

        if(CAT_INDEX != -1){
            let CAT_FOUND = Filter_Array[CAT_INDEX] || {}
            let CAT_CODE =  CAT_FOUND.sub;

            if(CAT_CODE){
                matched.push(CAT_CODE)
            }
        }

        if(i== categories.length-1){
            return matched
        }
    }

}

function removeParamFromURL(url, param) {
    const [path, searchParams] = url.split('?');
    const newSearchParams = searchParams
      ?.split('&')
      .filter((p) => !(p === param || p.startsWith(`${param}=`)))
      .join('&');
    return newSearchParams ? `${path}?${newSearchParams}` : path;
}

export default CategoryFilter