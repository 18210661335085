import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import ReactCountryFlag from 'react-country-flag';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import StarReview from '../General/Stars';


const AdminReferrals = (props) => {

    const { TRANSLATE, history, match, location, USER } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [REFERRALS, setReferrals] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var current_page = parseInt(page_id);

            if (USER.admin) {
                onFetchReferrals({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else {
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

                history.push('/logout')
            }

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchReferrals = async ({ page, sort, direction }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/referrals', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setReferrals(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchReferrals = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchReferrals({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/referrals/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setReferrals(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setReferrals([]);
            setLoading(false);
        }


    }

    const onSortReferrals = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'name') {
            return 'search_name'
        }
        else if (sort === 'img') {
            return 'photo'
        }
        else if (sort === 'lang') {
            return 'language'
        }
        else if (sort === 'isCompleted') {
            return 'setup'
        }
        else {
            return sort
        }
    }

    const onChangePageReferrals = ({ page, sort, direction }) => {
        let new_url = '/admin/referrals/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        history.push(new_url);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/admin/users/profile/' + customer_id);
    }

    const onSelectOrder = (order_id) => {
        history.push('/admin/orders/profile/' + order_id);
    }

    const onSelectProduct = (product_id) => {
        history.push('/admin/products/profile/' + product_id);
    }

    const onSelectReview = (review_id) => {
        history.push('/admin/reviews/profile/' + review_id);
    }

    const onSelectReferral = (referral_id) => {
        history.push('/admin/referrals/profile/' + referral_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/admin/stores/profile/' + store_id);
    }

    const onSelectLocation = (location_id) => {
        history.push('/admin/locations/profile/' + location_id);
    }

    const onSelectCopy = (copy) => {
        swal('Copied to Clipboard !!!', copy, 'success')
    }

    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectReferral(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {


                    return (
                        <CopyToClipboard text={label} onCopy={() => onSelectCopy(label)}
                        >
                            <>
                                {(label === 'pending') && (
                                    <Chip label={label} size='small' />
                                )}

                                {(label === 'approved') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: 'green', color: '#fff' }} />
                                )}
                            </>
                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "type",
            label: "TYPE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <CopyToClipboard text={label}
                            onCopy={() => onSelectCopy(label)}
                        >
                            <>
                                {(label === 'store') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: '#26BFBF', color: '#fff' }} />
                                )}

                                {(label === 'signup' || label === 'checkout') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: '#fd6270', color: '#fff' }} />
                                )}

                                {(label === 'review') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: 'var(--yellow)', color: '#fff' }} />
                                )}

                                {(label === 'order') && (
                                    <Chip label={label} size='small' style={{ backgroundColor: 'var(--blue)', color: '#fff' }} />
                                )}

                            </>
                        </CopyToClipboard>
                    )
                }
            },
        },
        {
            name: "owner",
            label: "OWNER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const referralObx = REFERRALS[rowIndex] || {};

                    const ownerObx = referralObx.owner || {};
                    const owner_id = ownerObx.id || '';
                    const owner_name = ownerObx.name || '';
                    const owner_img = ownerObx.img || '';


                    if (owner_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={owner_name} src={owner_img} />}
                                    label={owner_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => onSelectCustomer(owner_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: "value",
            label: "CREDIT",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    const credit = parseInt(label);

                    if (credit <= 0) {
                        return (
                            <div className='d-flex align-items-center justify-content-center'>
                                <Chip label={label} size='medium' color='error' />
                            </div>

                        )
                    }

                    return (
                        <div className='d-flex align-items-center justify-content-center'>
                            <Chip label={'+' + label} size='medium' color='success' />
                        </div>
                    )
                }
            },
        },
        {
            name: "about",
            label: "DESCRIPTION",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const referralObx = REFERRALS[rowIndex] || {};

                    const referral_user = referralObx.user || {};
                    const user_id = referral_user.id;
                    const user_name = referral_user.name || '';

                    const referral_order = referralObx.order || {};
                    const order_id = referral_order.id;
                    const order_status = referral_order.status || '';
                    const order_total = referral_order.total || 0;
                    const order_currency = referral_order.currency || 'eur';

                    const referral_owner = referralObx.owner || {};
                    const owner_id = referral_owner.id;

                    const referral_store = referralObx.store || {};
                    const store_id = referral_store.id;
                    const store_name = referral_store.name || '';

                    const referral_review = referralObx.review || {};
                    const review_id = referral_review.id;
                    const review_rank = referral_review.rank || 0;
                    const review_type = referral_review.type || '';
                    const review_store = referral_review.store || '';
                    const review_customer = referral_review.customer || '';

                    const referral_type = referralObx.type || '';
                    const referral_code = referralObx.code || '';

                    let color = '#000';

                    if (order_status === 'pending') {
                        color = '#FCE205';
                    }
                    else if (order_status === 'accepted') {
                        color = 'green';
                    }
                    else if (order_status === 'rejected') {
                        color = '#ec1529';
                    }
                    else if (order_status === 'canceled') {
                        color = '#ec1529';
                    }
                    else if (order_status === 'delivering') {
                        color = '#88D1F1';
                    }
                    else if (order_status === 'delivered') {
                        color = '#ff8b3d';
                    }
                    else if (order_status === 'complete') {
                        color = 'green';
                    }

                    if (referral_type === 'signup') {
                        return (
                            <div style={{ fontWeight: 100 }}>
                                Invited to signup
                                {' '}
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectCustomer(user_id)}>
                                    {user_name.toLowerCase()}
                                </span>
                                {' '}
                                with code
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectCustomer(owner_id)}>
                                    {referral_code}
                                </span>
                            </div>
                        )
                    }
                    else if (referral_type === 'checkout') {
                        return (
                            <div style={{ fontWeight: 100 }}>
                                Invited to checkout
                                {' '}
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectCustomer(user_id)}>
                                    {user_name.toLowerCase()}
                                </span>
                                {' '}
                                for order
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectOrder(order_id)}>
                                    #{order_id}
                                </span>
                                {' '}
                                with status
                                {' '}
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', color: color, cursor: 'pointer' }} onClick={() => onSelectOrder(order_id)}>
                                    {order_status}
                                </span>
                                {' '}
                                and a total of
                                {' '}
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectOrder(order_id)}>
                                    {numberWithCommas(order_total, order_currency)}
                                </span>
                            </div>
                        )
                    }
                    else if (referral_type === 'store') {
                        return (
                            <div style={{ fontWeight: 100 }}>
                                Invited
                                {' '}
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectCustomer(user_id)}>
                                    {user_name.toLowerCase()}
                                </span>
                                {' '}
                                to create the store
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectStore(store_id)}>
                                    {store_name}
                                </span>

                            </div>
                        )
                    }
                    else if (referral_type === 'review') {
                        return (
                            <div style={{ fontWeight: 100 }}>
                                Sent a review of
                                {' '}
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectReview(review_id)}>
                                    {review_rank.toFixed(1)} ⭐️{/* <StarReview rate={review_rank} /> */}
                                </span>
                                {' '}
                                to a
                                {' '}
                                {review_type === 'customer' ? (
                                    <span style={{ textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectCustomer(review_customer)}>
                                        a Customer
                                    </span>
                                ) : (
                                    <span style={{ textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectStore(review_store)}>
                                        {review_type}
                                    </span>
                                )}


                            </div>
                        )
                    }
                    else if (referral_type === 'order') {
                        return (
                            <div style={{ fontWeight: 100 }}>
                                Spent for order
                                {' '}
                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectOrder(order_id)}>
                                    #{order_id}
                                </span>
                                {' '}
                                with status
                                {' '}
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', color: color, cursor: 'pointer' }} onClick={() => onSelectOrder(order_id)}>
                                    {order_status}
                                </span>
                                {' '}
                                and a total of
                                {' '}
                                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => onSelectOrder(order_id)}>
                                    {numberWithCommas(order_total, order_currency)}
                                </span>
                            </div>
                        )
                    }

                    return (
                        <div>
                            NO
                        </div>

                    )

                }
            },

        },

        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = REFERRALS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_referral = {
            id: item.id,
            type: item.type,
            status: item.status,
            code: item.code,
            value: item.value,
            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY'),
            user: item.user || {},
            owner: item.owner || {},
            product: item.product || {},
            store: item.store || {},
            review: item.review || {},
            order: item.order || {},
            about: 1000
        };

        return new_referral
    })

    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageReferrals({
                        page: tableState.page,
                        sort: onSortReferrals(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageReferrals({
                        page: tableState.page,
                        sort: onSortReferrals(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchReferrals(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchReferrals(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Referral By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Referrals with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },



    };


    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Referrals →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}


function numberWithCommas(x, CURRENCY) {
    if (isNaN(x)) return x;

    return (x).toLocaleString('en-US', {
        style: 'currency',
        currency: CURRENCY,
    });

}

export default AdminReferrals

