import React, { useState, useEffect, useRef } from 'react';

import { postApiPrivate } from '../../api';

import LoaderMaster from '../Loaders/Loaders';

import { Link } from 'react-router-dom';
import { ShirtOutline, ReaderOutline, ChatbubbleEllipsesOutline, StarOutline, LogOutOutline, LogInOutline, StorefrontOutline, CalendarOutline, SearchOutline } from 'react-ionicons'
import SearchMap from '../Search/SearchMap';

const AdminDashboard = (props) => {

  const mapRef = useRef();

  const { TRANSLATE, match, USER, SEARCH, history, HeaderLogo, setHeaderLogo } = props;

  const [isLoading, setLoading] = useState(true);
  const [DASHBOARD, setDashboard] = useState({});

  useEffect(() => {

    onFetchDashboard();

  }, []);

  const onFetchDashboard = async () => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    try {

      setLoading(true);

      const DATA_RESPONSE = await postApiPrivate('/admin/dashboard/partners', { id: match.params.id }, JWT);

      if (DATA_RESPONSE.error) {

        setLoading(false);

        console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);

        return alert('Error Loading the dashboard');
      }

      const DASHBOARD_DATA = DATA_RESPONSE.data;

      setDashboard(DASHBOARD_DATA);
      setLoading(false);

      const DATA_LOGO = DASHBOARD_DATA && DASHBOARD_DATA.partner ? DASHBOARD_DATA.partner.logo : '';

      if (DATA_LOGO) {
        //alert(DATA_LOGO)
        setHeaderLogo(DATA_LOGO);
      }

      console.log("FETCHING DASHBOARD ===> ", DASHBOARD_DATA);

    }
    catch (e) {

      setLoading(false);

      alert('Error Loading the dashboard');
    }



  }

  if (isLoading) {
    return (
      <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
    );
  }

  //Dashboard
  const {
    shops,
    agencies
  } = DASHBOARD

  let MenuClass = 'col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
  let MenuStyle = {
    borderRadius: 20,
    fontSize: 24,
    fontWeight: 'bold'
  }

  let partner_id = USER.partner || match.params.id;

  let STORES = shops || [];

  const POINTS = STORES.map((item) => {

    const GEOCODE = item.geocode;

    return {
      ...item,
      latitude: GEOCODE ? GEOCODE.latitude : 0,
      longitude: GEOCODE ? GEOCODE.longitude : 0,
      type: 'provider'
    }
  })

  //Elements

  const ORDERS = DASHBOARD.orders || {};
  const PENDING = DASHBOARD.pending || {};
  const ACCEPTED = DASHBOARD.accepted || {};
  const REJECTED = DASHBOARD.rejected || {};
  const DELIVERING = DASHBOARD.delivering || {};
  const DELIVERED = DASHBOARD.delivered || {};
  const COMPLETE = DASHBOARD.complete || {};
  const CANCELED = DASHBOARD.canceled || {};

  const PARTNER = DASHBOARD.partner || {};
  const PARTNER_ID = PARTNER.id || '';
  const PARTNER_LOGO = PARTNER.logo || '';
  const PARTNER_NAME = PARTNER.name || '';
  const PARTNER_TYPE = PARTNER.type || '';

  const CUSTOMERS = agencies || [];

  return (
    <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10">

      {(USER.partner || USER.admin) ? (
        <>
          <div className='hidden md:block flex px-xl-5 px-2 mx-2 mb-8 items-center justify-center'>
            <div style={{ fontSize: 60, fontWeight: 100, width: '100%', textAlign: 'center' }}>
              Welcome Back <span style={{ fontWeight: 'bold' }}>{USER.name}</span>
            </div>
          </div>
          <div className='block md:hidden flex px-xl-5 px-2 mx-2 mb-8 items-center justify-center'>
            <div style={{ fontSize: 20, fontWeight: 100, width: '100%', textAlign: 'center' }}>
              Welcome Back<br></br>
              <span style={{ fontWeight: 'bold', fontSize: 30 }}>
                {USER.name}
              </span>
            </div>
          </div>

          {PARTNER_TYPE === 'provider' ? (
            <>
              <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>

                {/* <Link to={'/partners/' + partner_id + '/orders'} className={MenuClass} style={MenuStyle}>
                  <img src={PARTNER_LOGO} className='rounded-lg object-contain' />
                  <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                    {PARTNER_NAME}
                  </p>
                </Link> */}

                <Link to={'/partners/' + partner_id + '/orders'} className={MenuClass} style={MenuStyle}>
                  <h1 style={{ padding: 10 }}>
                    {numberWithCommas(parseFloat(ORDERS.provider || 0), 'EUR')}
                  </h1>
                  <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                    Total Rentals
                  </p>
                </Link>

                <Link to={'/partners/' + partner_id + '/orders'} className={MenuClass} style={MenuStyle}>
                  <h1 style={{ padding: 10 }}>
                    {parseInt(ORDERS.count)}
                  </h1>
                  <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                    Total Orders
                  </p>
                </Link>

                <Link to={'/partners/' + partner_id + '/stores'} className={MenuClass} style={MenuStyle}>
                  <h1 style={{ padding: 10 }}>
                    {parseInt(shops?.length)}
                  </h1>
                  <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                    Total Stores
                  </p>
                </Link>

              </div>

              <div className='row px-xl-5 px-2 mb-5 justify-content-center'>

                <div className='col-xl-4 col-md-6 col-12'>
                  {/* <MenuPartner {...props} DASHBOARD={DASHBOARD} /> */}
                  <div className='text-xl font-bold mb-2'>
                    Stores
                  </div>
                  <div className='flex flex-col w-full bg-white p-4 rounded-lg'>
                    {shops.map((item) => {
                      return (
                        <div className='flex w-full items-center justify-start border-b py-4 cursor-pointer' key={item.id} onClick={() => history.push(`/partners/${PARTNER_ID}/stores/profile/${item.id}`)}>
                          <img src={item.img} className='w-[30px] h-[30px] rounded-lg object-cover mr-1' />
                          <div>
                            {item.name}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>


                <div className='col-xl-4 col-md-6 col-12'>
                  <div className='text-xl font-bold mb-2'>
                    Orders
                  </div>
                  <div className='bg-light mb-5' style={{ borderRadius: 20, padding: 20 }}>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=pending'}>
                      <div className="text-base font-bold">Pending</div>
                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#FCE205', fontSize: 'x-large' }}>{PENDING.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(PENDING.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=accepted'}>
                      <div className="text-base font-bold">Accepted</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#9AD6AB', fontSize: 'x-large' }}>{ACCEPTED.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(ACCEPTED.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=delivering'}>
                      <div className="text-base font-bold">Delivering</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#88D1F1', fontSize: 'x-large' }}>{DELIVERING.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(DELIVERING.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=delivered'}>
                      <div className="text-base font-bold">Delivered</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#ff8b3d', fontSize: 'x-large' }}>{DELIVERED.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(DELIVERED.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=complete'}>
                      <div className="text-base font-bold">Complete</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#9AD6AB', fontSize: 'x-large' }}>{COMPLETE.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(COMPLETE.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=rejected'}>
                      <div className="text-base font-bold">Rejected</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#ec1529', fontSize: 'x-large' }}>{REJECTED.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(REJECTED.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b pb-4' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=canceled'}>
                      <div className="text-base font-bold">Canceled</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#ec1529', fontSize: 'x-large' }}>{CANCELED.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(CANCELED.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=all'}>
                      <h4 style={{ fontSize: 20, fontWeight: 'bold' }}>TOTAL</h4>
                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#000', fontSize: 'x-large' }}>{ORDERS.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(ORDERS.provider, 'eur')}</h4>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className='col-xl-4 col-12' style={{ maxHeight: 400 }}>
                  <div className='text-xl font-bold mb-2'>
                    Map
                  </div>
                  <SearchMap
                    {...props}
                    POINTS={POINTS}
                    TRANSLATE={TRANSLATE}
                    LANGUAGE={TRANSLATE.locale}
                    history={history}
                    mapRef={mapRef}

                  />

                </div>


              </div>
            </>
          ) : (PARTNER_TYPE === 'customer') ? (
            <>
              <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>

                {/* <Link to={'/partners/' + partner_id + '/orders'} className={MenuClass} style={MenuStyle}>
                <img src={PARTNER_LOGO} className='rounded-lg object-contain' />
                <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                  {PARTNER_NAME}
                </p>
              </Link> */}

                <Link to={'/partners/' + partner_id + '/orders'} className={MenuClass} style={MenuStyle}>
                  <h1 style={{ padding: 10 }}>
                    {numberWithCommas(parseFloat(ORDERS.provider || 0), 'EUR')}
                  </h1>
                  <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                    Total Rentals
                  </p>
                </Link>

                <Link to={'/partners/' + partner_id + '/orders'} className={MenuClass} style={MenuStyle}>
                  <h1 style={{ padding: 10 }}>
                    {parseInt(ORDERS.count)}
                  </h1>
                  <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                    Total Orders
                  </p>
                </Link>

                <Link to={'/partners/' + partner_id + '/stores'} className={MenuClass} style={MenuStyle}>
                  <h1 style={{ padding: 10 }}>
                    {parseInt(agencies.length || 0)}
                  </h1>
                  <p style={{ fontSize: 15, marginBottom: 3, fontWeight: 100 }}>
                    Total Agencies
                  </p>
                </Link>

              </div>

              <div className='row px-xl-5 px-2 mb-5 justify-content-center'>

                <div className='col-xl-4 col-md-6 col-12'>
                  {/* <MenuPartner {...props} DASHBOARD={DASHBOARD} /> */}
                  <div className='text-xl font-bold mb-2'>
                    Agencies
                  </div>
                  <div className='flex flex-col w-full bg-white p-4 rounded-lg'>
                    {agencies.map((item) => {
                      return (
                        <div className='flex w-full items-center justify-start border-b py-4 cursor-pointer' key={item.id} onClick={() => history.push(`/partners/${PARTNER_ID}/customers/profile/${item.id}`)}>
                          <img src={item.img} className='w-[30px] h-[30px] rounded-lg object-cover mr-1' />
                          <div className='capitalize'>
                            {item.name}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>


                <div className='col-xl-4 col-md-6 col-12'>
                  <div className='text-xl font-bold mb-2'>
                    Orders
                  </div>
                  <div className='bg-light mb-5' style={{ borderRadius: 20, padding: 20 }}>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=pending'}>
                      <div className="text-base font-bold">Pending</div>
                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#FCE205', fontSize: 'x-large' }}>{PENDING.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(PENDING.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=accepted'}>
                      <div className="text-base font-bold">Accepted</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#9AD6AB', fontSize: 'x-large' }}>{ACCEPTED.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(ACCEPTED.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=delivering'}>
                      <div className="text-base font-bold">Delivering</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#88D1F1', fontSize: 'x-large' }}>{DELIVERING.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(DELIVERING.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=delivered'}>
                      <div className="text-base font-bold">Delivered</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#ff8b3d', fontSize: 'x-large' }}>{DELIVERED.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(DELIVERED.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=complete'}>
                      <div className="text-base font-bold">Complete</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#9AD6AB', fontSize: 'x-large' }}>{COMPLETE.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(COMPLETE.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=rejected'}>
                      <div className="text-base font-bold">Rejected</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#ec1529', fontSize: 'x-large' }}>{REJECTED.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(REJECTED.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1 border-b pb-4' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=canceled'}>
                      <div className="text-base font-bold">Canceled</div>

                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#ec1529', fontSize: 'x-large' }}>{CANCELED.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(CANCELED.provider, 'eur')}</h4>
                      </div>
                    </Link>
                    <Link className='w-full flex items-center justify-between px-3 py-1' to={'/partners/' + partner_id + '/orders/0?sort=updatedAt&dir=desc&status=all'}>
                      <h4 style={{ fontSize: 20, fontWeight: 'bold' }}>TOTAL</h4>
                      <div className='flex flex-col items-end'>
                        <h4 style={{ color: '#000', fontSize: 'x-large' }}>{ORDERS.count}</h4>
                        <h4 className='text-base'>{numberWithCommas(ORDERS.provider, 'eur')}</h4>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className='col-xl-4 col-12' style={{ maxHeight: 400 }}>
                  <div className='text-xl font-bold mb-2'>
                    Map
                  </div>
                  <SearchMap
                    {...props}
                    POINTS={POINTS}
                    TRANSLATE={TRANSLATE}
                    LANGUAGE={TRANSLATE.locale}
                    history={history}
                    mapRef={mapRef}

                  />

                  <div className='w-full bg-bagreen-100 text-white flex items-center justify-center p-3 mt-4 rounded-lg cursor-pointer' onClick={() => history.push(`/partners/${match.params.id}/map?search=stores&sort=updatedAt&dir=desc&country=all&range=100&status=true&approval=false`)}>
                   <SearchOutline color={'#ffffff'} className="mr-" />  Explore Babonbo Locations
                  </div>

                </div>


              </div>
            </>
          ) : (
            <></>
          )}

          <div style={{ width: '100%', height: 200 }}></div>

        </>
      ) : (
        <>
          {/* <h1>Not Authorized</h1> */}
        </>
      )}

      {/* <pre>
        {JSON.stringify(DASHBOARD, null, 2)}
      </pre> */}

    </div>
  );


}

const MenuPartner = (props) => {

  const { TRANSLATE, USER, match } = props;

  let MenuClass = 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex flex-column bg-light w-100 p-4 m-2 align-items-center justify-content-center';
  let MenuStyle = {
    borderRadius: 20,
    fontSize: 13,
    fontWeight: 'bold'
  }

  let partner_id = USER.partner || match.params.id;

  return (
    <>
      <div className='row mb-5 justify-content-center p-0'>


        <Link to={'/partners/' + partner_id + '/stores'} className={MenuClass} style={MenuStyle}>
          <StorefrontOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Stores
        </Link>

        <Link to={'/partners/' + partner_id + '/products'} className={MenuClass} style={MenuStyle}>
          <ShirtOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Products
        </Link>

        <Link to={'/partners/' + partner_id + '/orders'} className={MenuClass} style={MenuStyle}>
          <ReaderOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Orders
        </Link>

        <Link to={'/partners/' + partner_id + '/inbox'} className={MenuClass} style={MenuStyle}>
          <ChatbubbleEllipsesOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Messages
        </Link>
        <Link to={'/partners/' + partner_id + '/calendary'} className={MenuClass} style={MenuStyle}>
          <CalendarOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Calendary
        </Link>

        <Link to={'/partners/' + partner_id + '/reviews'} className={MenuClass} style={MenuStyle}>
          <StarOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
          Reviews
        </Link>

        {USER.name ? (
          <Link to="/logout" className={MenuClass} style={MenuStyle}>
            <LogOutOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
            Logout
          </Link>
        ) : (
          <Link to="/login" className={MenuClass} style={MenuStyle}>
            <LogInOutline color={'#26BFBF'} width="40px" height="40px" style={{ marginBottom: 5 }} />
            Login
          </Link>
        )}

      </div>
    </>
  )
}


function numberWithCommas(x, CURRENCY) {
  if (isNaN(x)) return x;

  return (x).toLocaleString('en-US', {
    style: 'currency',
    currency: CURRENCY,
  });

}

export default AdminDashboard
