import React, { useState } from 'react';

const ToggleButton = (props) => {
  const { onToggle, description = true } = props;
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    onToggle(!isToggled);
  };

  return (
    <div className='flex items-center justify-content-between' style={{ width: description ? '90px' : '40px' }}>
      <button
        onClick={handleToggle}
        className={`flex items-center rounded-full cursor-pointer ${isToggled ? 'bg-pr' : 'bg-light-pr'}`}
        style={{ width: '35px', height: '12px' }}
      >
        <div
          className={`bg-pr rounded-full shadow-md`}
          style={{
            width: '16px',
            height: '16px',
            transform: isToggled ? 'translateX(19px)' : 'translateX(0)',
            transition: 'transform 0.3s ease-in-out',
          }}
        ></div>
      </button>
      {description && <span className='font-semibold text-sm'>{isToggled ? 'Active' : 'Passive'}</span>}
    </div>
  );
};

export default ToggleButton;
