import React, { useEffect, useState } from "react";

import { postApiPrivate } from "../../api";

import { CloseOutline } from "react-ionicons";
import LoaderMaster from "../Loaders/Loaders";
import swal from "sweetalert";

import ReactFileReader from 'react-file-reader';

import { ImageCompressor, getImageSize } from "compressor-img";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";


const StoreImage = (props) => {


    const { onClose, onSelectImage, TYPE } = props;

    const [isCropping, setCropping] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const [IMAGE, setImage] = useState(null);
    const [COMPRESSED, setCompressed] = useState(null);
    const [LIBRARY, setLibrary] = useState([]);

    const [cropper, setCropper] = useState();
    const [CATEGORY, setCategory] = useState(TYPE || 'all');

    useEffect(() => {

        onFetchLibrary();

    }, [CATEGORY])


    const onFetchLibrary = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const FORM = {
            p: 0,
            s: 'updatedAt',
            d: 'desc',
            l: 200,
            c: CATEGORY
        };

        setLoading(true);

        const DATA_RESPONSE = await postApiPrivate('/admin/library', FORM, JWT);

        if (DATA_RESPONSE.error) {
            console.log("ERROR FROM API LOADING PRODUCTS", DATA_RESPONSE.error);

            setLoading(false);

            return
        }


        setLibrary(DATA_RESPONSE.data || [])

        setLoading(false);
    }


    const onUploadImage = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                name: TYPE?.toUpperCase(),
                ...COMPRESSED,
                type: TYPE
            };

            console.log("SEARCH ===> ", FORM);

            const DATA_RESPONSE = await postApiPrivate('/admin/library/upload', FORM, JWT);

            if (DATA_RESPONSE.error) {
                setLoading(false);
                swal('Error', 'Cannot Update This Image', 'error');

                console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
                return
            }

            console.log("SEARCH ===> ", DATA_RESPONSE);

            setLoading(false);
            swal('Photo Uploaded', 'This photo has been uploaded successfully !!!', 'success');

            setUploading(false);
            onFetchLibrary();

        }
        catch (e) {

            setLoading(false);
            swal('Error', 'Cannot Upload This Photo', 'error');

        }
    }

    const onCropImage = () => {

        if (typeof cropper !== "undefined") {

            const newImage = cropper.getCroppedCanvas().toDataURL();
            const newSize = getImageSize(newImage);

            let imageCompressor = new ImageCompressor({
                onSuccess: response => {

                    const compressedImg = response.compressed;

                    const originalSize = getImageSize(response.original);
                    const compressedSize = getImageSize(response.compressed);

                    if (originalSize > 3000) {

                    }
                    else if (originalSize <= 3000 && originalSize > 250) {

                    }
                    else if (originalSize <= 250) {

                    }

                    console.log("COMPRESSED ===> ", compressedImg);
                    console.log("ORIGINAL SIZE ===> ", originalSize);
                    console.log("COMPRESSED SIZE ===> ", compressedSize);
                    setCompressed({
                        src: compressedImg,
                        uploaded: false,
                        size: originalSize,
                        compressed: compressedSize
                    })

                    setCropping(false);
                },
                //scale: 100,
                quality: 50,
                originalImage: newImage
            });

        }

    }

    const onHandleUpload = (files) => {

        console.log("Files ==> ", files);
        const OriginalFile = files.fileList[0];

        let reader = new FileReader();
        reader.onloadend = () => {

            const newImage = reader.result;
            const newSize = getImageSize(newImage);

            setImage({
                src: newImage,
                size: newSize
            })

            let imageCompressor = new ImageCompressor({
                onSuccess: response => {
  
                    const compressedImg = response.compressed;
  
                    const originalSize = getImageSize(response.original);
                    const compressedSize = getImageSize(response.compressed);
  
                    if(originalSize > 3000){
  
                    }
                    else if(originalSize <= 3000 && originalSize > 250){
  
                    }
                    else if(originalSize <= 250){
  
                    }
  
                    console.log("COMPRESSED ===> ", compressedImg);
                    console.log("ORIGINAL SIZE ===> ", originalSize);
                    console.log("COMPRESSED SIZE ===> ", compressedSize);
  
                    setCompressed({
                      src: compressedImg,
                      uploaded: false,
                      size: originalSize,
                      compressed: compressedSize
                    })
  
                },
                //scale: 100,
                quality: 50,
                originalImage: newImage
            });

            setUploading(true);
        };

        reader.readAsDataURL(OriginalFile);
    }

    if (isCropping) {

        return (
            <>
                <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                    <div className="slideout-content" style={{ color: '#000000' }}>

                        <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                            <div className="font-bold text-lg">
                                Crop the image
                            </div>

                            <CloseOutline color={"#000"} onClick={() => setCropping(false)} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                        </div>

                        <div className="slideout-content-scroll" style={{ margin: 0 }}>

                            {isLoading ? (
                                <LoaderMaster type={'secondary'} />
                            ) : (
                                <>
                                    <div className="d-flex flex-column align-items-center justify-content-center pt-8 md:pb-0 pb-32">

                                        <div className="flex flex-wrap" style={{ whiteSpace: 'break-spaces' }}>

                                            <Cropper
                                                style={{ height: 350, width: "100%" }}
                                                initialAspectRatio={1}
                                                src={IMAGE?.src}
                                                viewMode={1}
                                                cropBoxResizable={false}
                                                dragMode={'move'}
                                                background={true}
                                                responsive={true}
                                                autoCropArea={1}
                                                checkOrientation={false}
                                                onInitialized={(instance) => {
                                                    setCropper(instance);
                                                }}
                                                guides={true}
                                            />
                                            <div className="top_box_magic w-100 p-3">
                                                <div className="top_box_magic w-100 p-3">
                                                    <h1 className="d-flex align-items-center justify-content-between">
                                                        Original Image

                                                        <div className="d-flex align-items-center justify-content-center" style={{ background: '#000', borderRadius: 20, height: 25, color: '#fff', padding: '0px 10px', fontSize: 14 }}>
                                                            {IMAGE?.size} kb
                                                        </div>
                                                    </h1>
                                                </div>
                                            </div>


                                        </div>



                                        <div style={{ height: 200 }}></div>
                                    </div>
                                </>
                            )}
                        </div>

                        {!isLoading && (
                            <div className="d-flex align-items-center justify-content-between" style={{ backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>

                                <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5 }} onClick={() => setCropping(false)}>
                                    Back
                                </div>

                                <div className="top_add_cart" style={{ width: '50%', backgroundColor: 'green', marginLeft: 5 }} onClick={() => onCropImage()}>
                                    Crop image
                                </div>

                            </div>
                        )}

                    </div>

                </div>


            </>
        )
    }

    if (isUploading) {

        return (
            <>
                <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                    <div className="slideout-content" style={{ color: '#000000' }}>

                        <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                            <div className="font-bold text-lg">
                                Upload an Image
                            </div>

                            <CloseOutline color={"#000"} onClick={() => setUploading(false)} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                        </div>

                        <div className="slideout-content-scroll" style={{ margin: 0 }}>

                            {isLoading ? (
                                <LoaderMaster type={'secondary'} />
                            ) : (
                                <>
                                    <div className="d-flex flex-column align-items-center justify-content-center pt-8 md:pb-0 pb-32">

                                        <div className="flex flex-wrap" style={{ whiteSpace: 'break-spaces' }}>

                                            <img className="w-full border object-cover" src={COMPRESSED?.src} />


                                            <div className="top_box_magic w-100 p-3">
                                                <h1 className="d-flex align-items-center justify-content-between">
                                                    Original Image

                                                    <div className="d-flex align-items-center justify-content-center" style={{ background: '#000', borderRadius: 20, height: 25, color: '#fff', padding: '0px 10px', fontSize: 14 }}>
                                                        {COMPRESSED?.size} kb
                                                    </div>
                                                </h1>
                                                <h1 className="d-flex align-items-center justify-content-between mt-2">
                                                    Compressed Image

                                                    <div className="d-flex align-items-center justify-content-center bg-green-500" style={{ background: '#000', borderRadius: 20, height: 25, color: '#fff', padding: '0px 10px', fontSize: 14 }}>
                                                        {COMPRESSED?.compressed} kb
                                                    </div>
                                                </h1>
                                            </div>


                                        </div>

                                        <div style={{ height: 200 }}></div>
                                    </div>
                                </>
                            )}
                        </div>

                        {!isLoading && (
                            <div className="d-flex align-items-center justify-content-between" style={{ backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>

                                <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5 }} onClick={() => setCropping(true)}>
                                    Crop the image
                                </div>

                                <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#26BFBF', marginLeft: 5 }} onClick={() => onUploadImage()}>
                                    Confirm Upload
                                </div>

                            </div>
                        )}

                    </div>

                </div>


            </>
        )
    }

    return (
        <>
            <div className="slideout-wrapper active" style={{ background: '#00000040' }}>

                <div className="slideout-content" style={{ color: '#000000' }}>

                    <div className='d-flex align-items-center justify-content-between' style={{ padding: 25 }}>

                        
                        <div className="font-bold text-lg">
                            Filter by category: 
                            <select
                                className={'gridyx-select'}
                                value={CATEGORY}
                                label={'.'}
                                style={{color: '#000000'}}
                                onChange={(e) => setCategory(e.target.value)}
                                InputLabelProps={{ shrink: false }}
                                select
                            >
                                <option value={'all'}>
                                    All Categories
                                </option>
                                <option value={'users'}>
                                    Users
                                </option>
                                <option value={'stores'}>
                                    Stores 
                                </option>
                                <option value={'products'}>
                                    Products 
                                </option>
                                <option value={'blog'}>
                                    Blog 
                                </option>
                                <option value={'resources'}>
                                    Resources 
                                </option>


                            </select>
                        </div>
                        <CloseOutline color={"#000"} onClick={() => onClose()} style={{ height: 30, width: 30, zIndex: 222, cursor: 'pointer' }} />

                    </div>
                    

                    <div className="slideout-content-scroll" style={{ margin: 0 }}>
                        
                    
                        {isLoading ? (
                            <LoaderMaster type={'secondary'} />
                        ) : (
                            <>
                                <div className="d-flex flex-column align-items-center justify-content-center pt-8 md:pb-0 pb-32">
                                    
                                    
                                    <div className="flex flex-wrap" style={{ whiteSpace: 'break-spaces' }}>

                                        {/* {JSON.stringify(LIBRARY, null, 2)} */}
                                        {LIBRARY.map((item, index) => {

                                            return (
                                                <img className="w-1/2 border object-cover cursor-pointer" src={item.src} key={index} onClick={() => { onSelectImage(item); onClose() }} />
                                            )
                                        })}

                                    </div>

                                    <div style={{ height: 200 }}></div>
                                </div>
                            </>
                        )}




                    </div>

                    {!isLoading && (
                        <div className="d-flex align-items-center justify-content-between" style={{ backgroundColor: '#ffffff', borderTop: 'solid 1px #00000019', position: 'fixed', width: '100%', height: 80, maxWidth: 500, paddingLeft: 10, paddingRight: 10, bottom: 0 }}>

                            <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#ffffff', color: '#000000', border: 'solid 1px #000000', marginRight: 5 }} onClick={()=> onClose()}>
                                Back
                            </div>

                           

                            <div className="top_add_cart" style={{ width: '50%', backgroundColor: '#26BFBF', marginLeft: 5 }}>
                                <ReactFileReader fileTypes={[".jpg", ".jpeg", ".png", ".gif"]} base64={true} multipleFiles={false} handleFiles={onHandleUpload} >
                                    <div>
                                    Upload Image
                                    </div>
                                </ReactFileReader>
                            </div>

                           
                    </div>
                    )}

                </div>

            </div>


        </>
    )
}

export default StoreImage