import React from 'react'
import { Link } from 'react-router-dom'
import {LogoFacebook, LogoInstagram, LogoLinkedin, LogoWhatsapp} from 'react-ionicons';

import { Cities_Array } from '../General/Constants';

const Footer = (props) => {

    const {TRANSLATE, LANGUAGE, match} = props;

    const CURRENT_URL = match.url;
    const isProvider = CURRENT_URL.includes('/dashboard') || CURRENT_URL === '/onboarding/provider';
    const isOnboarding = CURRENT_URL.includes('/onboarding')

    const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;

    if(isNativeApp){
        return null
    }

    if(isOnboarding){
        return null
    }

    return (
        <div className="landing-page-footer-wrapper">
                
            <footer className="landing-footer">
                <div className="footer-section first">
                    {isProvider ? (
                        <Link className="click-logo" to="/">
                            <img src="/assets/img/babonbo_logo_provider.png" />
                        </Link>
                    ) : (
                        <Link className="click-logo" to="/">
                            <img src="/assets/img/babonbo_logo.png" />
                        </Link>
                    )}
                    
                    {/* <p>{TRANSLATE.footer_info}</p> */}
                    <p>
                        Copyright © Babonbo <br/> All Rights Reserved
                    </p>
                    
                    <div className="footer-social">
                        <a href="https://www.facebook.com/babonbo.baby" target='_blank'>
                            <LogoFacebook color={'#3b5998'} style={{marginRight: 10}}/>
                        </a>
                        <a href="https://www.instagram.com/babonbo.baby/" target='_blank'>
                            <LogoInstagram color={'#fd6270'} style={{marginRight: 10}}/>
                        </a>
                        <a href="https://wa.me/447427401350" target='_blank'>
                            <LogoWhatsapp color={'#25d366'} style={{marginRight: 10}}/>
                        </a>
                        <a href="https://www.linkedin.com/company/babonbo/" target='_blank'>
                            <LogoLinkedin color={'#0e76a8'}/>
                        </a>
                    </div>
                </div>
                <div className="footer-section">
                    <h4>{TRANSLATE.learn_more}</h4>
                    <Link to={TRANSLATE.url_about}>{TRANSLATE.about_us}</Link>
                    <Link to={TRANSLATE.url_tutorial_customer}>{TRANSLATE.how_it_works}</Link>
                    <Link to={'/'+LANGUAGE+'/search'}>{TRANSLATE.rent_now}</Link>
                    {/* <Link to={'/'+LANGUAGE+'/search/nearby'}>{TRANSLATE.cerca_vicino}</Link> */}
                    <Link to={TRANSLATE.url_become_provider}>{TRANSLATE.diventa_provider}</Link>
                    <a href={TRANSLATE.url_blog} target="_blank">{TRANSLATE.blog}</a>
                    <Link to={TRANSLATE.url_faq_customer}>{TRANSLATE.faq_customer}</Link>
                    <Link to={TRANSLATE.url_faq_provider}>{TRANSLATE.faq_provider}</Link>
                    <Link to={'/'+LANGUAGE+'/trust-and-safety'}>Trust & Safety</Link>
                    <Link to={'/'+LANGUAGE+'/download'}>Download</Link>
                    <Link to={'/'+LANGUAGE+'/sitemap'}>Sitemap</Link>
                    
                   
                </div>
                <div className="footer-section">
                    <h4>{TRANSLATE.where}</h4>
                    {Cities_Array.map((item, index) => {
                        return (
                            <Link to={item[LANGUAGE+'_url']} key={index}>
                                {item[LANGUAGE]}
                            </Link>
                        )
                    })}

                    <Link to={'/'+LANGUAGE+'/locations'}>{TRANSLATE.other_cities}</Link>
                </div>
                <div className="footer-section">
                    <h4>{TRANSLATE.support}</h4>

                    <Link to={TRANSLATE.url_tutorial_customer}>{TRANSLATE.guideline_customer}</Link>
                    <Link to={TRANSLATE.url_tutorial_provider}>{TRANSLATE.guideline_provider}</Link>
                    <Link to={TRANSLATE.url_terms}>{TRANSLATE.termini}</Link>
                    <Link to={TRANSLATE.url_privacy}>{TRANSLATE.policy}</Link>
                    <Link to={TRANSLATE.url_cancellation}>{TRANSLATE.cancellation}</Link>
                    
                </div>

                {/* <div className="footer-section">
                    <h4>{TRANSLATE.support}</h4>
                    <a href={TRANSLATE.url_terms}>{TRANSLATE.termini}</a>
                    <a href={TRANSLATE.url_privacy}>{TRANSLATE.policy}</a>
                    <a href={TRANSLATE.url_faq}>{TRANSLATE.faq}</a>
                </div> */}

                
            </footer>

        </div>
    )
}

export default Footer