import React, { useEffect, useState } from "react";

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';

import { postApiPrivate } from "../../api";

import moment from "moment";
import swal from "sweetalert";

const DateSelector = (props) => {

    const { onClose, USER, TRANSLATE, title, description, extra, onSave, date, from, to, isRange } = props;

    const [isLoading, setLoading] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);

    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
    });


    useEffect(() => {
        if (date) {
            let newDate = onFormatDateToCalendar(date);
            setSelectedDay(newDate);
        }

    }, [date]);


    useEffect(() => {
        if (from && to) {
            let fromDate = onFormatDateToCalendar(from);
            let toDate = onFormatDateToCalendar(to)
            setSelectedDayRange({
                from: fromDate,
                to: toDate
            });
        }

    }, []);

    const onFormatDateToString = (DATE) => {

        if (!DATE) {
            return null
        }
        const year = DATE.year.toString();
        const month = DATE.month.toString().padStart(2, 0);
        const day = DATE.day.toString().padStart(2, 0);
        return `${day}/${month}/${year}`;
    };

    const onFormatDateToCalendar = (DATE) => {

        const newDateMoment = moment(DATE, 'DD/MM/YYYY');

        const newDateObx = {
            day: parseInt(newDateMoment.format('D')),
            month: parseInt(newDateMoment.format('M')),
            year: parseInt(newDateMoment.format('YYYY'))
        }

        return newDateObx;

    }


    const FORMATED_DATE = onFormatDateToString(selectedDay);

    const FORMATED_FROM = (selectedDayRange && selectedDayRange.from) ? onFormatDateToString(selectedDayRange.from) : null;
    const FORMATED_TO = (selectedDayRange && selectedDayRange.to) ? onFormatDateToString(selectedDayRange.to) : null;

    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 z-[10000] flex-col " style={{ zIndex: 10001 }}>
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />
                <div className="w-full max-w-[500px] bg-white h-full z-20 relative ml-auto flex flex-col fade-in md:slider-in" style={{ zIndex: 10001 }}>
                    <div className="flex justify-between p-8 border-b border-zinc-200">
                        <div>
                            <h2 className="capitalize">{title}</h2>
                            <p className="text-sm">{description}</p>
                        </div>
                        <img className="mb-auto cursor-pointer" src="/assets/img/close.svg" onClick={() => onClose()} alt="" />
                    </div>

                    {isLoading ? (
                        <div className="overflow-auto h-full pt-8">
                            <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
                                <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' alt="close" />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="overflow-auto h-full pt-8 md:pb-0 pb-32">

                                {isRange ? (
                                    <>
                                        <Calendar
                                            value={selectedDayRange}
                                            onChange={setSelectedDayRange}
                                            colorPrimary="#27BFBF"
                                            colorPrimaryLight="#E9F9F9"
                                            calendarClassName="provider-calendar flex-shrink-0 max-w-[320px]"
                                        />

                                        {FORMATED_FROM && FORMATED_TO && (
                                            <div className="p-10">

                                                <div className="font-semibold mb-3">{extra}</div>

                                                <div className="p-3 border-zinc-100 bg-white border rounded-lg mb-2 text-sm flex justify-between items-center">
                                                    • {FORMATED_FROM} ---- {FORMATED_TO}
                                                </div>

                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Calendar
                                            value={selectedDay}
                                            onChange={setSelectedDay}
                                            colorPrimary="#27BFBF"
                                            colorPrimaryLight="#E9F9F9"
                                            calendarClassName="provider-calendar flex-shrink-0 max-w-[320px]"
                                        />

                                        {FORMATED_DATE && (
                                            <div className="p-10">

                                                <div className="font-semibold mb-3">{extra}</div>

                                                <div className="p-3 border-zinc-100 bg-white border rounded-lg mb-2 text-sm flex justify-between items-center">
                                                    • {FORMATED_DATE}
                                                </div>

                                            </div>
                                        )}
                                    </>
                                )}






                            </div>

                            <div className="flex justify-between md:p-8 p-4 border-t border-zinc-200 items-center mt-auto md:relative fixed bottom-0 left-0 w-full bg-white">
                                {isRange ? (
                                    <div className="w-full h-full bg-bagreen-100 rounded-md text-center text-white p-4 cursor-pointer" onClick={() => onSave({from: FORMATED_FROM, to: FORMATED_TO})}>
                                        {TRANSLATE.btn_save_changes}
                                    </div>
                                ) : (
                                    <div className="w-full h-full bg-bagreen-100 rounded-md text-center text-white p-4 cursor-pointer" onClick={() => onSave(FORMATED_DATE)}>
                                        {TRANSLATE.btn_save_changes}
                                    </div>
                                )}

                            </div>

                        </>
                    )}



                </div>
            </div>
        </>
    )
}

export default DateSelector