import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import { GetRouteSearchParams } from './AdminHelper';

import StarReview from '../General/Stars';
import { FilterList } from '@mui/icons-material';

import OrderFilter from '../Modals/OrderFilter';

const AdminOrders = (props) => {

    const { TRANSLATE, history, match, location } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [ORDERS, setOrders] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const [isFilter, setFilter] = useState(false);


    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var filter_status = routeParams.status || null;
            var filter_store = routeParams.store || null;
            var filter_customer = routeParams.customer || null;
            var filter_start = routeParams.start || null;
            var filter_end = routeParams.end || null;

            var filter_country = routeParams.country || null;
            var filter_reviews = routeParams.reviews || null;
            var filter_currency = routeParams.currency || null;
            var filter_language = routeParams.language || null;

            var filter_coupon = routeParams.coupon || null;
            var filter_insurance = routeParams.insurance || null;
            var filter_lastminute = routeParams.lastminute || null;
            var filter_category = routeParams.category || null;

            var current_page = parseInt(page_id);

            onFetchOrders({
                page: current_page,
                sort: sort_id,
                direction: direction_id,
                filter_status,
                filter_store,
                filter_customer,
                filter_start,
                filter_end,
                filter_coupon,
                filter_insurance,
                filter_category,
                filter_lastminute,
                filter_country,
                filter_reviews,
                filter_language,
                filter_currency
            });

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchOrders = async (props) => {

        const {
            page, sort, direction,
            filter_status,
            filter_store,
            filter_customer,
            filter_start,
            filter_end,
            filter_coupon,
            filter_insurance,
            filter_category,
            filter_lastminute,
            filter_country,
            filter_reviews,
            filter_language,
            filter_currency
        } = props;

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                status: filter_status,
                store: filter_store,
                customer: filter_customer,
                start: filter_start,
                end: filter_end,
                coupon: filter_coupon,
                insurance: filter_insurance,
                category: filter_category,
                lastminute: filter_lastminute,
                currency: filter_currency,
                language: filter_language,
                country: filter_country,
                reviews: filter_reviews,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/orders', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setOrders(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchOrders = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchOrders({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/orders/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setOrders(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setOrders([]);
            setLoading(false);
        }


    }

    const onSortOrders = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'date') {
            return 'createdAt'
        }
        else if (sort === 'total') {
            return 'cost_total'
        }
        else if (sort === 'subtotal') {
            return 'cost_subtotal'
        }
        else if (sort === 'd_cost') {
            return 'cost_delivery'
        }
        else if (sort === 'f_cost') {
            return 'cost_service_fee'
        }
        else if (sort === 'f_cost') {
            return 'cost_service_fee'
        }
        else if (sort === 'f_cost') {
            return 'cost_service_fee'
        }
        else if (sort === 'f_cost') {
            return 'cost_service_fee'
        }
        else {
            return sort
        }
    }

    const onChangePageOrders = (props) => {

        const {
            page, sort, direction,
            filter_status,
            filter_store,
            filter_customer,
            filter_start,
            filter_end,
            filter_coupon,
            filter_insurance,
            filter_category,
            filter_lastminute,
            filter_sname,
            filter_cname,
            filter_country,
            filter_reviews,
            filter_language,
            filter_currency

        } = props;


        let new_url = '/admin/orders/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }
        else {
            new_url = new_url + '?sort=updatedAt&dir=desc';
        }

        if (filter_status) {
            new_url = new_url + '&status=' + filter_status;
        }

        if (filter_store) {
            new_url = new_url + '&store=' + filter_store;
        }

        if (filter_customer) {
            new_url = new_url + '&customer=' + filter_customer;
        }

        if (filter_sname) {
            new_url = new_url + '&sname=' + filter_sname;
        }

        if (filter_cname) {
            new_url = new_url + '&cname=' + filter_cname;
        }

        if (filter_start) {
            new_url = new_url + '&start=' + filter_start;
        }

        if (filter_end) {
            new_url = new_url + '&end=' + filter_end;
        }

        if (filter_insurance) new_url = new_url + '&insurance=' + filter_insurance;

        if (filter_coupon) new_url = new_url + '&coupon=' + filter_coupon;

        if (filter_lastminute) new_url = new_url + '&lastminute=' + filter_lastminute;

        if (filter_category) new_url = new_url + '&category=' + filter_category;

        if (filter_country) new_url = new_url + '&category=' + filter_country;
        if (filter_language) new_url = new_url + '&language=' + filter_language;
        if (filter_currency) new_url = new_url + '&currency=' + filter_currency;
        if (filter_reviews) new_url = new_url + '&reviews=' + filter_reviews;

        history.push(new_url);
    }

    const onSelectOrder = (order_id) => {
        history.push('/admin/orders/profile/' + order_id);
    }

    const onSelectCustomer = (customer_id) => {
        history.push('/admin/users/profile/' + customer_id);
    }

    const onSelectStore = (store_id) => {
        history.push('/admin/stores/profile/' + store_id);
    }

    const onSelectReview = (review_id) => {
        if (review_id) {
            history.push('/admin/reviews/profile/' + review_id);
        }
    }



    //Logic
    var routeParams = GetRouteSearchParams(location.search);

    var currentSort = routeParams.sort || null;
    var currentDirection = routeParams.dir || null;

    var currentFilterStatus = routeParams.status || null;
    var currentFilterStore = routeParams.store || null;
    var currentFilterCustomer = routeParams.customer || null;
    var currentFilterStoreName = routeParams.sname || null;
    var currentFilterCustomerName = routeParams.cname || null;
    var currentFilterStart = routeParams.start || null;
    var currentFilterEnd = routeParams.end || null;

    var currentFilterCountry = routeParams.country || null;
    var currentFilterReviews = routeParams.reviews || null;
    var currentFilterCurrency = routeParams.currency || null;
    var currentFilterLanguage = routeParams.language || null;

    var currentFilterCoupon = routeParams.coupon || null;
    var currentFilterLastminute = routeParams.lastminute || null;
    var currentFilterCategory = routeParams.category || null;
    var currentFilterInsurance = routeParams.insurance || null;

    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectOrder(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },
        {
            name: "store_img",
            label: "STORE",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const orderObx = ORDERS[rowIndex] || {};
                    const storeObx = orderObx.store || {};

                    const store_id = storeObx.id || '';
                    const store_name = storeObx.name || '';
                    const store_img = storeObx.img || '';

                    return (
                        <Chip
                            avatar={<Avatar alt={store_name} src={store_img} />}
                            label={store_name.toLowerCase()}
                            variant="outlined"
                            size='medium'
                            style={{ textTransform: 'capitalize' }}
                            onClick={() => onSelectStore(store_id)}

                        />
                    )
                }
            },

        },
        {
            name: "customer_img",
            label: "CUSTOMER",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const orderObx = ORDERS[rowIndex] || {};
                    const customerObx = orderObx.customer || {};

                    const customer_id = customerObx.id || '';
                    const customer_name = customerObx.name || '';
                    const customer_img = customerObx.img || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                avatar={<Avatar alt={customer_name} src={customer_img} />}
                                label={customer_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectCustomer(customer_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label) => {

                    let color = '#000';

                    if (label === 'pending') {
                        color = '#FCE205';
                    }
                    else if (label === 'accepted') {
                        color = '#9AD6AB';
                    }
                    else if (label === 'rejected') {
                        color = '#ec1529';
                    }
                    else if (label === 'canceled') {
                        color = '#ec1529';
                    }
                    else if (label === 'delivering') {
                        color = '#88D1F1';
                    }
                    else if (label === 'delivered') {
                        color = '#ff8b3d';
                    }
                    else if (label === 'complete') {
                        color = 'green';
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' style={{ background: color, color: '#fff' }} />
                        </div>
                    )

                },
                filterOptions: {
                    names: ['pending', 'accepted', 'delivering', 'delivered', 'complete', 'rejected', 'canceled']
                }
            },
        },
        {
            name: "captured",
            label: "CAPTURED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label === 'true') {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="YES" color='success' />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip size='small' label="NO" />
                        </div>
                    )
                }
            }
        },
        {
            name: "refunded",
            label: "REFUNDED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label === 'true') {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="YES" color='error' />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip size='small' label="NO" />
                        </div>
                    )
                }
            }
        },
        {
            name: "insurance",
            label: "INSURANCE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label === 'true') {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="YES" color='success' />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip size='small' label="NO" />
                        </div>
                    )
                }
            }
        },
        {
            name: "lastminute",
            label: "LAST MINUTE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label === 'true') {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="YES" color='info' />
                            </div>
                        )
                    }

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip size='small' label="NO" />
                        </div>
                    )
                }
            }
        },
        {
            name: "checkin",
            label: "CHECKIN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "checkout",
            label: "CHECKOUT",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }

            },
        },
        {
            name: 'products',
            label: 'PRODUCTS',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (label) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            }

        },
        {
            name: "cost_total",
            label: "TOTAL",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "cost_provider",
            label: "PROVIDER",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'} color={'#26BFBF'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "cost_total_fees",
            label: "FEES",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'} color={'#fd6270'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "cost_subtotal",
            label: "SUBTOTAL",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "cost_delivery",
            label: "PICKUP",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "cost_return",
            label: "DROPOFF",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "cost_discount",
            label: "DISCOUNT",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "cost_provider_fee",
            label: "PROVIDER FEE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'} color={'#26BFBF'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "cost_service_fee",
            label: "CUSTOMER FEE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'} color={'#fd6270'}>
                                {value}
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "cost_insurance",
            label: "INSURANCE FEE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    if (value) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant='span' fontWeight={'bold'} color={'#fd6270'}>
                                    {value}
                                </Typography>
                            </div>

                        )
                    }
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                ----
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "credits",
            label: "CREDITS",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    if (value) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant='span' fontWeight={'bold'} color={'##00000'}>
                                    {value}
                                </Typography>
                            </div>

                        )
                    }
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                ----
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "used",
            label: "CREDITS USED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    if (value) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant='span' fontWeight={'bold'} color={'##00000'}>
                                    {value}
                                </Typography>
                            </div>

                        )
                    }
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                ----
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "remaining",
            label: "REMAINING",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    if (value) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant='span' fontWeight={'bold'} color={'##00000'}>
                                    {value}
                                </Typography>
                            </div>

                        )
                    }
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                ----
                            </Typography>
                        </div>

                    )
                }
            },
        },
        {
            name: "total_to_pay",
            label: "TOTAL TO PAY",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {

                    if (value) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant='span' fontWeight={'bold'} color={'##00000'}>
                                    {value}
                                </Typography>
                            </div>

                        )
                    }
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='span' fontWeight={'bold'}>
                                ----
                            </Typography>
                        </div>

                    )
                }
            },
        },

        {
            name: "review_pro",
            label: "TO PROVIDER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (review) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={review.rank} size={20} onClick={() => onSelectReview(review.id)} />
                        </div>
                    )
                }

            },
        },
        {
            name: "review_cus",
            label: "TO CUSTOMER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (review) => {

                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StarReview rate={review.rank} size={20} onClick={() => onSelectReview(review.id)} />
                        </div>
                    )
                }

            },
        },
        {
            name: "phone",
            label: "PHONE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <Chip label={label || 'NO'} size="medium" />
                    )
                }

            },
        },
        {
            name: "coupon",
            label: "COUPON",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <Chip label={label || 'NO'} size="medium" />
                    )
                }

            },
        },
        {
            name: "message",
            label: "MESSAGE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label) {
                        return (
                            <Tooltip title={label}>
                                <Chip label={'YES'}
                                    size='medium'
                                    color='success'
                                />
                            </Tooltip>

                        )
                    }
                    return (

                        <Chip label={'NO'} size="medium" />

                    )
                }

            },
        },
        {
            name: "reason",
            label: "REASON",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label) {
                        return (
                            <Tooltip title={label}>
                                <Chip label={'YES'}
                                    size='medium'
                                    color='success'
                                />
                            </Tooltip>

                        )
                    }
                    return (

                        <Chip label={'NO'} size="medium" />

                    )
                }

            },
        },
        {
            name: "payment_intent",
            label: "PAY",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label) {
                        return (
                            <Tooltip title={label}>
                                <Chip label={'YES'}
                                    size='medium'
                                    color='success'
                                />
                            </Tooltip>

                        )
                    }
                    return (

                        <Chip label={'NO'} size="medium" />

                    )
                }

            },
        },
        {
            name: "charge",
            label: "CHARGE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label) {
                        return (
                            <Tooltip title={label}>
                                <Chip label={'YES'}
                                    size='medium'
                                    color='success'
                                />
                            </Tooltip>

                        )
                    }
                    return (

                        <Chip label={'NO'} size="medium" />

                    )
                }

            },
        },
        {
            name: "refund",
            label: "REFUND",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    if (label) {
                        return (
                            <Tooltip title={label}>
                                <Chip label={'YES'}
                                    size='medium'
                                    color='success'
                                />
                            </Tooltip>

                        )
                    }
                    return (

                        <Chip label={'NO'} size="medium" />

                    )
                }

            },
        },
        {
            name: "updatedAt",
            label: "LAST UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "DATE CREATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        }


    ];

    const data = ORDERS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let currency = item.currency || 'eur';

        let store_obx = item.store || {};
        let customer_obx = item.customer || {};

        //Costs
        let cost_total = item.total || 0;
        let cost_subtotal = item.subtotal || 0;
        let cost_delivery = item.d_cost || 0;
        let cost_return = item.r_cost || 0;
        let cost_service_fee = item.f_cost || 0;
        let cost_provider_fee = item.f_p_cost || 0;
        let cost_total_fees = parseFloat(cost_service_fee) + parseFloat(cost_provider_fee);
        let cost_provider = item.p_total || 0;
        let cost_discount = item.dis_cost || 0;
        let cost_insurance = item.i_cost || 0;

        let cost_credits = item.credits || 0;
        let cost_used = item.used || 0;
        let cost_remaining = item.remaining || 0;
        let cost_total_to_pay = item.total_to_pay || 0;

        const order_checkin_ex = item.checkin_ex;
        const order_checkout_ex = item.checkout_ex;
        const order_duration_ex = item.checkdur_ex;

        let hasLastminute = item.hasLastminute || false;

        if (hasLastminute) {
            cost_provider = cost_provider + 8;
            cost_total_fees = cost_total_fees + 3.5;
        }


        let new_order = {

            id: item.id,
            store_id: store_obx.id,
            store_name: store_obx.name,
            store_img: store_obx.img,

            customer_id: customer_obx.customer_id,
            customer_name: customer_obx.customer,
            customer_img: customer_obx.customer_img,

            status: item.status,
            captured: item.captured ? 'true' : 'false',
            refunded: item.refunded ? 'true' : 'false',

            insurance: item.hasInsurance ? 'true' : 'false',
            lastminute: hasLastminute ? 'true' : 'false',

            date: moment(item.updated).format('DD MMMM YYYY | HH:mm'),

            products: item.products.length || 0,

            cost_total: numberWithCommas(parseFloat(cost_total.toFixed(2)), currency),
            cost_subtotal: numberWithCommas(parseFloat(cost_subtotal.toFixed(2)), currency),
            cost_delivery: numberWithCommas(parseFloat(cost_delivery.toFixed(2)), currency),
            cost_return: numberWithCommas(parseFloat(cost_return.toFixed(2)), currency),
            cost_service_fee: numberWithCommas(parseFloat(cost_service_fee.toFixed(2)), currency),
            cost_provider_fee: numberWithCommas(parseFloat(cost_provider_fee.toFixed(2)), currency),
            cost_total_fees: numberWithCommas(parseFloat(cost_total_fees.toFixed(2)), currency),
            cost_provider: numberWithCommas(parseFloat(cost_provider.toFixed(2)), currency),
            cost_discount: numberWithCommas(parseFloat(cost_discount.toFixed(2)), currency),
            cost_insurance: numberWithCommas(parseFloat(cost_insurance.toFixed(2)), currency),

            credits: numberWithCommas(parseFloat(cost_credits.toFixed(2)), currency),
            used: numberWithCommas(parseFloat(cost_used.toFixed(2)), currency),
            remaining: numberWithCommas(parseFloat(cost_remaining.toFixed(2)), currency),
            total_to_pay: numberWithCommas(parseFloat(cost_total_to_pay.toFixed(2)), currency),

            coupon: item.coupon,
            message: item.message,
            reason: item.reason,

            review_pro: item.review_pro,
            review_cus: item.review_cus,

            checkin: order_checkin_ex ? order_checkin_ex : moment(item.checkin).format('DD MMMM YYYY'),
            checkout: order_checkout_ex ? order_checkout_ex : moment(item.checkout).format('DD MMMM YYYY'),

            updatedAt: moment(item.updated).fromNow(),
            createdAt: moment(item.created).format('DD MMMM YYYY'),

            phone: item.phone,
            charge: item.charge,
            refund: item.refund,
            payment_intent: item.payint

        };



        return new_order
    })


    const customToolbar = () => (
        <Tooltip disableFocusListener title="Filter Orders">
            <IconButton onClick={() => setFilter(true)}>
                <FilterList />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filter: false,
        print: false,
        download: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,

        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageOrders({
                        page: tableState.page,
                        sort: onSortOrders(sortFilter),
                        direction: sortDirection,
                        filter_status: currentFilterStatus,
                        filter_store: currentFilterStore,
                        filter_sname: currentFilterStoreName,
                        filter_customer: currentFilterCustomer,
                        filter_cname: currentFilterCustomerName,
                        filter_start: currentFilterStart,
                        filter_end: currentFilterEnd,
                        filter_coupon: currentFilterCoupon,
                        filter_lastminute: currentFilterLastminute,
                        filter_category: currentFilterCategory,
                        filter_insurance: currentFilterInsurance,
                        filter_country: currentFilterCountry,
                        filter_reviews: currentFilterReviews,
                        filter_language: currentFilterLanguage,
                        filter_currency: currentFilterCurrency

                    });

                    break;
                case 'sort':

                    onChangePageOrders({
                        page: tableState.page,
                        sort: onSortOrders(sortFilter),
                        direction: sortDirection,
                        filter_status: currentFilterStatus,
                        filter_store: currentFilterStore,
                        filter_sname: currentFilterStoreName,
                        filter_customer: currentFilterCustomer,
                        filter_cname: currentFilterCustomerName,
                        filter_start: currentFilterStart,
                        filter_end: currentFilterEnd,
                        filter_coupon: currentFilterCoupon,
                        filter_lastminute: currentFilterLastminute,
                        filter_category: currentFilterCategory,
                        filter_insurance: currentFilterInsurance,
                        filter_country: currentFilterCountry,
                        filter_reviews: currentFilterReviews,
                        filter_language: currentFilterLanguage,
                        filter_currency: currentFilterCurrency
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchOrders(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchOrders(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Order By ID',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Orders with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },
        onFilterChange: (column, filterList, type) => {

            //Check if Current Filter is Active
            if (column === 'status') {
                const filterValue = filterList[3];
                currentFilterStatus = filterValue;
            }

            onChangePageOrders({
                page: 0,
                sort: onSortOrders(currentSort),
                direction: currentDirection,
                filter_status: currentFilterStatus,
                filter_store: currentFilterStore,
                filter_sname: currentFilterStoreName,
                filter_customer: currentFilterCustomer,
                filter_cname: currentFilterCustomerName,
                filter_start: currentFilterStart,
                filter_end: currentFilterEnd,
                filter_coupon: currentFilterCoupon,
                filter_lastminute: currentFilterLastminute,
                filter_category: currentFilterCategory,
                filter_insurance: currentFilterInsurance,
                filter_country: currentFilterCountry,
                filter_reviews: currentFilterReviews,
                filter_language: currentFilterLanguage,
                filter_currency: currentFilterCurrency
            });

        }

    };



    return (
        <>

            {isFilter && (
                <OrderFilter {...props}
                    onClose={() => setFilter(false)}

                />
            )}
            <MUIDataTable
                title={
                    <div className='font-light md:text-5xl text-xl text-lg py-8'>

                        {'Orders Found → '}

                        {isLoading ? (

                            <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                        ) : (

                            <>
                                {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                            </>

                        )}

                    </div>
                }
                data={data}
                columns={columns}
                options={options}
            />

        </>
    )

}


function numberWithCommas(x, CURRENCY) {
    if (isNaN(x)) return x;

    return (x).toLocaleString('en-US', {
        style: 'currency',
        currency: CURRENCY,
    });

}


export default AdminOrders


