import React from 'react'
import { numberWithCommas } from '../../helpers/GeneralHelper';


const ProviderCard = (props) => {
    
    const { provider, onClick, addStyles } = props;

    const {image, name, orders, score, reviews, rating, products, delivery, timezone, total_distance, pickup, dropoff} = provider;
    
    return (
        <>
            <div className={'flex rounded-lg bb-transition-100 flex-col flex-wrap cursor-pointer items-start justify-start md:p-6 p-2 outline-zinc-200 hover:outline hover:-outline-offset-1 hover:outline-2 group relative ' +addStyles} onClick={()=> onClick()}>
                <div className='w-full relative mb-2'>
                    <div className='pt-[100%]'></div>
                    <img className='absolute w-full h-full object-cover rounded-xl top-0 left-0' src={image} alt={name} />
                </div>
                <div className='text-base font-semibold mb-4'>{name}</div>

                <div className='flex flex-wrap justify-between font-semibold items-center w-full mt-auto'>
                    
                    <div>{parseFloat(total_distance).toFixed(2)}<span className='font-normal text-xs'>/km</span></div>
                     
                    <span className='text-xs font-normal'></span>
                    
                    
                </div>
                
                <div className='flex flex-wrap justify-between font-semibold items-center w-full mt-auto'>
                   
                    <div>{orders}<span className='font-normal text-xs'>/orders</span></div>
                     
                    <span className='text-xs font-normal'>Score: {parseInt(score)}</span>
                    
                </div>

                <div className='flex flex-wrap justify-between font-semibold items-center w-full mt-auto'>
                    
                    <div>{products}<span className='font-normal text-xs'>/products</span></div>
                     
                    <span className='text-xs font-normal'>{timezone}</span>
                    
                    
                </div>
                
                
                
                <div className='flex flex-wrap justify-between font-semibold items-center w-full mt-auto'>
                    
                    <div>{delivery.length}<span className='font-normal text-xs'>/delivery</span></div>
                     
                    <span className='text-xs font-normal'>{numberWithCommas(parseFloat(pickup.cost || 0)+parseFloat(dropoff.cost || 0), 'eur')}</span>
                    
                </div>

                <div className='flex flex-wrap justify-between font-semibold items-center w-full mt-auto'>
                    
                    <div>{parseInt(rating)}<span className='font-normal text-xs'>/5</span></div>
                     
                    <span className='text-xs font-normal'>{reviews} reviews</span>
                    
                </div>

                {/* {showHeart && (
                     <img className='absolute top-1 right-1 z-10 w-[22px] h-[22px] opacity-0 group-hover:opacity-100' 
                          src='/assets/img/heart.svg' 
                     />
                )} */}
               
            </div>
        </>
    )

}

export default ProviderCard