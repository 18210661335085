import React, {useState, useEffect} from "react";

import LoaderMaster from "../Loaders/Loaders";

import {postApiPrivate} from "../../api";

import { CloseSharp, SaveSharp, TrashBinSharp } from "react-ionicons";

import moment from 'moment';
import swal from "sweetalert";

import { Avatar, Chip, Container, Grid, TextField } from "@mui/material";

import "../Profile/z_profile.css";

const AdminBrandsDetails = (props) => {

  const {TRANSLATE, history, match} = props;

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [isEdited, setEdited] = useState(false);

  const [PUBLIC_PRODUCT, setBrand] = useState({});  

  useEffect(()=> {

      var brand_id = match.params.id || '';

      onFetchPublic(brand_id);

  }, []);

  const onFetchPublic = async(brand_id) => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
    
    setEdited(false);
    setLoading(true);

    try{

      const FORM = { id: brand_id};

      console.log("SEARCH ===> ", FORM);
  
      const DATA_RESPONSE = await postApiPrivate('/admin/brands/details', FORM, JWT);
        
      if(DATA_RESPONSE.error){

          console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
          return 
      }

      console.log("SEARCH ===> ", DATA_RESPONSE);

      //alert("DARA ===> "+JSON.stringify(DATA_RESPONSE, null, 3));

      const BrandData = DATA_RESPONSE.data || {};
     
      setBrand(BrandData);
      setLoading(false);

    }
    catch(e){

        setLoading(false);
    }
      
  }
  
  const onUpdateBrand = async(key, value) => {

    setEdited(true);
    setBrand({
      ...PUBLIC_PRODUCT,
      [key]: value
    });

  }

  const onSaveBrand = async(brand_id) => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        
      setSaving(true);

      try{

        const FORM = { 
          id: brand_id, 
          name: PUBLIC_PRODUCT.name,
          url: PUBLIC_PRODUCT.url,
          img: PUBLIC_PRODUCT.img,
          status: PUBLIC_PRODUCT.status

        };

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/brands/edit', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Update This Brand', 'error');
            onFetchPublic(brand_id);

            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        console.log("SEARCH ===> ", DATA_RESPONSE);

        setSaving(false);
        swal('Brand Updated', 'This Brand has been updated successfully !!!', 'success');
        onFetchPublic(brand_id);

      }
      catch(e){

          setSaving(false);
          swal('Error', 'Cannot Update This Brand', 'error');
          onFetchPublic(brand_id);
      }

  }

  const onDeleteBrand = async(brand_id) => {
    
      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
      
      setSaving(true);

      try{

        const FORM = { id: brand_id};

        console.log("SEARCH ===> ", FORM);
    
        const DATA_RESPONSE = await postApiPrivate('/admin/brands/delete', FORM, JWT);
          
        if(DATA_RESPONSE.error){
            setSaving(false);
            swal('Error', 'Cannot Delete This Brand', 'error');
            onFetchPublic(brand_id);
            console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
            return 
        }

        setSaving(false);
        swal('Brand Deleted', 'This Brand has been deleted successfully !!!', 'success');
        history.replace('/admin/brands');

      }
      catch(e){

        setSaving(false);
        swal('Error', 'Cannot Delete This Brand', 'error');
        onFetchPublic(brand_id);
      }
  }
  

  if(isLoading){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_loading}/>
    )

  }

  if(isSaving){

    return (
      <LoaderMaster type="secondary" title={TRANSLATE.loader_saving}/>
    )

  }


    const {
      id,
      name,
      url,
      img,
      status,

      createdAt,
      updatedAt
    } = PUBLIC_PRODUCT;


    const managerObx = PUBLIC_PRODUCT.manager || {};
    const manager_id = managerObx.id || '';
    const manager_name = managerObx.name || '';
    const manager_img = managerObx.img || '';
    
  
    return(
     
        <Container maxWidth="xl" style={{background: '#f0f5f9'}}>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={4} md={6}>
                    <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                        <div className="top_store_card">
                            <div className="top_store_profile">

                                {img ? (
                                  <img className="profile_img" src={img} style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}}  />
                                ) : (
                                  <div className="profile_img" style={{borderRadius: 20, height: 250, width: 250, objectFit: 'contain'}}  />
                                )}
                              
                                <h3 className="store_title">{name}</h3>
                                
                            </div>
                           
                        </div> 

                        {!isEdited && (
                            <>
                              <div className="top_add_cart" 
                                  style={{backgroundColor: '#e64942'}} 
                                  onClick={()=> {
                                      swal({
                                          title: 'Confirm to Delete',
                                          text: 'Please insert CONFIRM to delete this brand',
                                          content: "input",
                                      })
                                      .then((value) => {

                                        if(value === 'CONFIRM'){

                                            onDeleteBrand(id)
                                        }
                                        else{

                                            swal('Invalid Password', 'You are not authorized to delete this brand', 'error');
                                        }

                                      });
                                  }}
                              >
                                <TrashBinSharp color={'#fff'} style={{marginRight: 10}} /> Delete Brand 
                              </div>
                            </>
                        )}
                      
                        {isEdited && (
                          <> 

                            <div className="top_add_cart"  
                                style={{backgroundColor: '#59ad6a'}} 
                                onClick={()=> {
                                  swal({
                                      title: 'Confirm to Save',
                                      text: 'Please insert the CONFIRM to update this brand',
                                      content: "input",
                                  })
                                  .then((value) => {

                                    if(value === 'CONFIRM'){

                                        onSaveBrand(id)
                                    }
                                    else{

                                        swal('Invalid Password', 'You are not authorized to update this brand', 'error');
                                    }

                                  });
                              }}
                                
                            >
                              <SaveSharp color={'#fff'} style={{marginRight: 10}} /> Update Profile
                            </div>

                            <div className="top_add_cart"  style={{backgroundColor: '#ccc'}} onClick={()=> onFetchPublic(id)}>
                              <CloseSharp color={'#fff'} style={{marginRight: 10}} /> Cancel Changes
                            </div>
                          
                          </>
                        )}
                
                        
                    </div>
                </Grid>
                <Grid item xs={12} lg={5} md={6}>
                
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20}}>
                          
                          <div className="top_box_magic" style={{marginBottom: 20}}>
                              <h1>
                                Status
                              </h1>

                              {status ? (
                                <Chip label="Published" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="success"  onClick={(e)=> onUpdateBrand('status', false)}/>
                              ) : (
                                <Chip label="Unpublished" style={{maxWidth: 100, marginTop: 10}} variant="filled" color="warning" onClick={(e)=> onUpdateBrand('status', true)} />
                              )}
                          </div>   

                          <div className="top_box_magic">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Name
                              </h1>
                              <div  style={{margin: '10px 0px'}}>
                                <TextField 
                                    className={'gridyx-textfield'}
                                    value={name} 
                                    label={'.'} 
                                    onChange={(e)=> onUpdateBrand('name', e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    placeholder="Type here..."
                                
                                />
                              </div>
                          </div>


                          <div className="top_box_magic">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Link
            
                              </h1>
                              <div style={{margin: '10px 0px'}}>

                                <TextField 
                                    className={'gridyx-textfield'}
                                    value={url} 
                                    label={'.'} 
                                    onChange={(e)=> onUpdateBrand('url', e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    placeholder="Type here..."
                                
                                />

                              </div>
                          </div>

                          <div className="top_box_magic">
                              <h1 className="d-flex align-items-center justify-content-between">
                                Logo 
            
                              </h1>
                              <div style={{margin: '10px 0px'}}>

                                <TextField 
                                    className={'gridyx-textfield'}
                                    value={img} 
                                    label={'.'} 
                                    onChange={(e)=> onUpdateBrand('img', e.target.value)}
                                    InputLabelProps={{shrink: false}}
                                    placeholder="Type here..."
                                
                                />

                              </div>
                          </div>

                          

                  
                  </div>
                  

                </Grid>
                <Grid item xs={12} lg={3} md={3}>
                  <div className="top_right_container" style={{height: 'auto', marginBottom: 20, position: 'sticky', top: 80}}>
                      
                      <div className="top_box_magic">
                          <h1>
                            Brand Created
                          </h1>
                          <div  style={{margin: '10px 0px'}}>
                            <p>
                              {moment(createdAt).format('dddd DD MMMM YYYY - HH:mm')}
                            </p> 
                          </div>
                      </div>   
                      <div className="top_box_magic">
                          <h1>
                            Brand Updated
                          </h1>
                          <div  style={{margin: '10px 0px'}}>
                            <p>
                              {moment(updatedAt).format('dddd DD MMMM YYYY - HH:mm')}
                            </p> 
                          </div>
                      </div>  

                      {manager_id && (
                        <div className="top_box_magic">
                            <h1>
                              Manager
                            </h1>
                            <Chip
                                avatar={<Avatar alt={manager_name} src={manager_img} />}
                                label={manager_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{textTransform: 'capitalize', marginTop: 10, width: 'fit-content'}}
                                onClick={()=> history.push('/admin/users/profile/'+manager_id)}
                            />
                        </div>
                      )}


                  

                  </div>
                </Grid>
              </Grid>            
            
        </Container>

      
    )


}


const Styles = {
  resultsRow: {
      display: 'flex', 
      alignItems: 'center', 
      color: '#000', 
      height: 40, 
      minHeight: 40,
      paddingLeft: 20, 
      border: '1px solid #00000040', 
      borderRight: 'none', 
      borderTop: 'none', 
      borderLeft: 'none',
      cursor: 'pointer',
      textTransform: 'capitalize',
      justifyContent: 'space-between'
  },
  resultsContainer: {
      display: 'flex', 
      flexDirection: 'column', 
      background: '#fff', 
      maxHeight: 280,
      position: 'relative', 
      top: 20, 
      border: '1px solid #00000040', 
      borderRadius: 10,
      overflow: 'auto',
      marginBottom: 20
  },
  right: {
      display: 'flex',
      alignItems: 'center'
  },
  searchContainer: {
      width: 300,
      height: 42,
      backgroundColor: '#F1F1F1',
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 5
  },
  input: {
      fontSize: 16,
      display: 'flex',
      color: '#000',
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
      marginLeft: 5,
      textTransform: 'capitalize'
  },
  searchOption: {
      display: 'flex', 
      width: 40, 
      height: 35, 
      alignItems: 'center', 
      borderRadius: '50%', 
      background: '#fd6270'
  },
  button: {
        display: 'flex',
        height: '50px',
        width: '200px',
        backgroundColor: '#26BFBF',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',  
        borderRadius: 10,
        width: '100%'
    },
    img_full: {
      display: 'flex',
      width: 200, 
      height: 200, 
      borderRadius: 6, 
      marginRight: 15, 
      marginLeft: 15
    },
    img_empty: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%', 
      height: '100%', 
      borderRadius: 6, 
      marginRight: 15, 
      borderColor: '#ccc', 
      borderWidth: 2, 
      borderStyle: "dashed", 
      justifyContent: 'center', 
      alignItems: 'center',
      marginBottom: 10,
      
    },
    clickCard: {
        backgroundColor: "#fff",
        padding: 20,
        marginTop: 10,
    },
    clickLabel: {
        color: "#697883",
        fontFamily: "SourceSansPro-Bold", 
        fontSize: 14, 
        marginBottom: 10,
    },
    wrapFlex: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    publishBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fd6270',
      padding: 10,
      width: 90,
      color: '#fff',
      borderRadius: 10,
      cursor: 'pointer'
    }

}


export default AdminBrandsDetails

