import { KeyboardDoubleArrowRightOutlined, UnfoldMore } from '@mui/icons-material';
import React, { useState } from 'react';
import { ChevronForward } from 'react-ionicons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Slider from 'react-slick';
import { FooterDashboard } from '../Footer';
import Header from '../Header';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './provider_dashboard.css';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
};

const ProviderDashboard = (props) => {
  const [currentStep, setCurrentStep] = useState(2);
  const [PROVIDER, setProvider] = useState({
    name: '',
    surname: '',
    storeName: '',
    about: '',
    countryCode: '',
    phoneNum: '',
    email: '',
  });

  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} isProvider={true}>
        {currentStep === 0 && (
          <div
            className='hero relative flex flex-col md:flex-row mb-4 md:mb-12 gap-8 w-full mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 color-pr'
            style={{ zIndex: '40' }}
          >
            <div className='header-title'>
              <h1 className='w-full quicksand text-[16px] md:text-[18px] color-pr'>
                EARN MONEY UP TO 5000 EURO <br /> IN A YEAR
              </h1>

              <div className='flex flex-col md:flex-row gap-4 md:mt-4 gap-4'>
                <Link
                  to='/'
                  className='flex align-items-center justify-content-center px-2 py-1 text-xs md:text-sm bg-white rounded-full'
                >
                  <UnfoldMore color='' className='icon-link-passive color-pr' />
                  Learn More
                </Link>
                <button
                  type='button'
                  className='flex align-items-center justify-content-center text-xs md:text-sm px-2 py-1 bg-pr text-white rounded-full'
                  onClick={() => setCurrentStep(1)}
                >
                  <KeyboardDoubleArrowRightOutlined className='' />
                  BECOME A PROVIDER
                </button>
              </div>
            </div>

            <div className='hero-img-container provider ml-auto mr-0'>
              <h2 className='quicksand color-pr' style={{ lineHeight: '1.5rem', textTransform: 'uppercase' }}>
                Europe’s biggest leader baby equipment rental portal
              </h2>
              <img src='/assets/img/landing/hero.jpg' className='hero-img mt-4' />
            </div>
          </div>
        )}
      </Header>

      <div className='flex flex-col align-items-center justify-content-center mx-auto my-4 max-w-xl px-4 sm:px-6 lg:px-8'>
        {/* If user not verified yet */}

        <p className='text-center p-2 bg-light-gray rounded-lg'>
          Your profile is being verified. <br />
          We will email you within 24 hours
        </p>

        <div className='flex flex-col mt-8 w-full gap-4'>
          <OrderCard />
          <div className='flex align-items-center justify-content-between p-2 w-full bg-light-gray rounded-lg'>
            <h3>You have active orders</h3>
            <button type='button' className='color-pr'>
              2 <KeyboardDoubleArrowRightOutlined />
            </button>
          </div>
          <div className='flex align-items-center justify-content-between p-2 w-full bg-light-gray rounded-lg'>
            <h3>12 Product</h3>
            <button type='button' className='color-pr'>
              2 <KeyboardDoubleArrowRightOutlined />
            </button>
          </div>
          <div className='p-2 text-white bg-pr rounded-lg'>
            <div className='flex w-full justify-content-between '>
              <div>Earning: </div>
              <button type='button' className='flex align-items-center'>
                Detail <KeyboardDoubleArrowRightOutlined />
              </button>
            </div>

            <div className='flex w-full justify-content-between '>
              <div>This Month </div>
              <div className='font-bold text-lg'>€1,587.73</div>
            </div>

            <div className='flex w-full justify-content-between '>
              <div>Payout</div>
              <div className='font-bold text-lg'>€1,587.73</div>
            </div>
          </div>
          <div className='carousel-container'>
            <Slider {...settings}>
              <div className='flex flex-col gap-2 p-2 w-full rounded-lg bg-light-gray'>
                <h3 className='font-bold'>Black Friday Campaign</h3>
                <p>You can increase sales up to %200</p>
                <button type='button' className='mr-auto bg-white font-bold px-2 py-1 rounded-lg'>
                  JOIN
                </button>
              </div>
              <div className='flex flex-col gap-2 p-2 w-full rounded-lg bg-light-gray'>
                <h3 className='font-bold'>Black Friday Campaign2</h3>
                <p>You can increase sales up to %200</p>
                <button type='button' className='mr-auto bg-white font-bold px-2 py-1 rounded-lg'>
                  JOIN
                </button>
              </div>
              {/* Add more campaign items here */}
            </Slider>
          </div>
          <div />
        </div>
      </div>

      <FooterDashboard {...props} />
    </div>
  );
};

export default ProviderDashboard;

const OrderCard = () => {
  return (
    <div className='flex flex-col p-2 w-full bg-light-gray rounded-lg'>
      <label className='mb-2 ml-auto px-4 py-1 text-white rounded-full' style={{ background: '#8F00FF' }}>
        New
      </label>
      <div className='flex gap-2'>
        <img src='/assets/cities/amsterdam.png' className='border-mid-gray' style={{ width: '60px', height: '56px' }} />
        <div className=''>
          <h2 className='text-sm text-ellipsis'>Uppa Baby Stroller</h2>
          <h3 className='text-sm' style={{ fontStyle: 'italic' }}>
            Aishwarya Rajamohan
          </h3>

          <div className='flex mt-2 w-full justify-content-between'>
            <p className='font-semibold'>1x</p>
            <p>16$</p>
          </div>

          <div className='flex gap-8 mt-2 w-full justify-content-between '>
            <p className='font-semibold'>4days</p>
            <p>27-03-2023 - 28-03-2023</p>
          </div>

          <div className='flex justify-content-between mt-2 w-full'>
            <p>ID: #5235266</p>
            <Link to='/earning' type='button' className='flex gap-1 color-pr'>
              Details <ChevronForward color={'#26bfbf'} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
