import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import { Avatar, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import MUIDataTable from 'mui-datatables';

import moment from 'moment/min/moment-with-locales';

import swal from 'sweetalert';

import { GetRouteSearchParams } from './AdminHelper';
import { Add } from '@mui/icons-material';


const AdminListings = (props) => {

    const { TRANSLATE, history, match, location, USER } = props;

    const [isLoading, setLoading] = useState(true);

    const [searchText, setSearchText] = useState('');

    const [PAGE, setPage] = useState(0);

    const [LISTINGS, setListings] = useState([]);
    const [TOTAL, setTotal] = useState(null);

    const LIMIT = 20;
    let CURRENT = LIMIT * (PAGE + 1);

    if (TOTAL < CURRENT) {
        CURRENT = TOTAL;
    }

    useEffect(() => {
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        if (!JWT) {
            return history.replace('/login');
        }
        else {

            var routeParams = GetRouteSearchParams(location.search);

            var page_id = match.params.page || 0;

            var sort_id = routeParams.sort || null;
            var direction_id = routeParams.dir || null;

            var current_page = parseInt(page_id);

            if (USER.admin) {
                onFetchListings({
                    page: current_page,
                    sort: sort_id,
                    direction: direction_id
                });
            }
            else {
                setLoading(false)
                swal('You\'re not Authorized', 'You are not authorized to be here', 'error')

                history.push('/logout')
            }

        }

        window.scrollTo(0, 0);

    }, [match.params.page, location.search]);

    const onFetchListings = async ({ page, sort, direction }) => {

        setPage(page);

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                p: page,
                s: sort,
                d: direction,
                l: LIMIT
            }

            var DATA_RESPONSE = await postApiPrivate('/admin/listing', FORM, JWT);


            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API SAVING CARD", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setListings(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

            window.scrollTo(0, 0)


        }
        catch (e) {

            console.log("CREATING ===> ", e);
            setLoading(false);
        }

    }

    const onSearchListings = async (query) => {

        setSearchText(query);

        if (!query || query.length == 0) {

            var page_number = match.params.page || 0;
            var current_page = parseInt(page_number);
            onFetchListings({
                page: current_page
            });

            return
        }

        if (query.length <= 3) {

            return
        }


        try {

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/admin/listing/search', { query: query }, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            setListings(DATA_RESPONSE.data);
            setTotal(DATA_RESPONSE.count);
            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setTotal(0);
            setListings([]);
            setLoading(false);
        }


    }

    const onSortListings = (sort) => {

        if (sort === 'id') {
            return 'objectId'
        }
        else if (sort === 'isDeleted') {
            return 'deleted'
        }
        else {
            return sort
        }
    }

    const onChangePageListings = ({ page, sort, direction }) => {
        let new_url = '/admin/listing/' + page;

        if (sort) {
            new_url = new_url + '?sort=' + sort + '&dir=' + direction;
        }

        history.push(new_url);
    }

    const onSelectListing = (listing_id) => {
        history.push('/admin/listing/profile/' + listing_id);
    }


    //Table Data
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (id) => {

                    return (
                        <Typography variant='span' fontWeight={'bold'} onClick={() => onSelectListing(id)} style={{ cursor: 'pointer' }}>
                            #{id}
                        </Typography>
                    )
                }
            },
        },

        {
            name: "images",
            label: "IMAGES",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const listingObx = LISTINGS[rowIndex] || {};

                    const listing_id = listingObx.id;
                    const listing_images = listingObx.images || [];

                    return (
                        <div style={{ maxWidth: 200 }}>
                            {[listing_images[0]].map((item, index) => {
                                return (
                                    <img src={item || '/assets/img/babonbo_logo_provider.png'}
                                        key={index}
                                        style={{
                                            width: 200, height: 100, borderRadius: 20, objectFit: 'contain'
                                        }}
                                    />
                                )
                            })}
                        </div>

                    )

                }
            },

        },
        {
            name: "name",
            label: "LISTING",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const listingObx = LISTINGS[rowIndex] || {};

                    const listing_id = listingObx.id;
                    const listing_name = listingObx.name || '';

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={listing_name.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                style={{ textTransform: 'capitalize' }}
                                onClick={() => onSelectListing(listing_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "price",
            label: "PRICE",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const listingObx = LISTINGS[rowIndex] || {};

                    const listing_id = listingObx.id;
                    const listing_price = listingObx.price || 0;

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={numberWithCommas(listing_price, 'eur')}
                                variant="outlined"
                                size='medium'
                                onClick={() => onSelectListing(listing_id)}
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "category",
            label: "CATEGORY",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (category) => {

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={category}
                                variant="filled"
                                size='small'
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "marca",
            label: "BRAND",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (marca) => {

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={marca}
                                variant="filled"
                                size='small'
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (status) => {

                    if (status === 'OFF') {
                        return (
                            <div style={{ maxWidth: 200 }}>
                                <Chip
                                    label={status}
                                    variant="filled"
                                    size='medium'
                                    color='error'
                                />
                            </div>

                        )
                    }

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={status}
                                variant="filled"
                                size='medium'
                                color='success'
                            />
                        </div>

                    )

                }
            },

        },
        {
            name: "price_day",
            label: "Daily",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const listingObx = LISTINGS[rowIndex] || {};

                    const listing_id = listingObx.id;
                    const listing_price = listingObx.price_day || 0;

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={numberWithCommas(listing_price, 'eur')}
                                variant="outlined"
                                size='medium'
                                onClick={() => onSelectListing(listing_id)}
                            />
                        </div>

                    )
                }
            },
        },
        {
            name: "price_week",
            label: "Weekly",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const listingObx = LISTINGS[rowIndex] || {};

                    const listing_id = listingObx.id;
                    const listing_price = listingObx.price_week || 0;

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={numberWithCommas(listing_price, 'eur')}
                                variant="outlined"
                                size='medium'
                                onClick={() => onSelectListing(listing_id)}
                            />
                        </div>

                    )
                }
            },
        },
        {
            name: "price_month",
            label: "Monthly",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const listingObx = LISTINGS[rowIndex] || {};

                    const listing_id = listingObx.id;
                    const listing_price = listingObx.price_month || 0;

                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={numberWithCommas(listing_price, 'eur')}
                                variant="outlined"
                                size='medium'
                                onClick={() => onSelectListing(listing_id)}
                            />
                        </div>

                    )
                }
            },
        },

        {
            name: "manager",
            label: "MANAGER",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const listingObx = LISTINGS[rowIndex] || {};

                    const managerObx = listingObx.manager || {};
                    const manager_id = managerObx.id || '';
                    const manager_name = managerObx.name || '';
                    const manager_img = managerObx.img || '';


                    if (manager_id) {
                        return (
                            <div>
                                <Chip
                                    avatar={<Avatar alt={manager_name} src={manager_img} />}
                                    label={manager_name.toLowerCase()}
                                    variant="outlined"
                                    size='medium'
                                    style={{ textTransform: 'capitalize' }}
                                    onClick={() => history.push('/admin/users/profile/' + manager_id)}
                                />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div>
                                <Chip size='small' label="NO" />
                            </div>
                        )
                    }
                }
            },

        },
        {
            name: 'isDeleted',
            label: 'DELETED',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (label, dir) => {

                    const rowIndex = dir.rowIndex;
                    const listingObx = LISTINGS[rowIndex] || {};

                    const isDeleted = listingObx.isDeleted || false;

                    if (isDeleted) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="Deleted" color='error' />
                            </div>

                        )
                    }
                    else {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Chip size='small' label="NO" />
                            </div>

                        )
                    }
                }
            }

        },

        {
            name: "url",
            label: "URL",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (src, dir) => {

                    const rowIndex = dir.rowIndex;
                    const listingObx = LISTINGS[rowIndex] || {};

                    const listing_id = listingObx.id;
                    const listing_url = listingObx.url || '';

                    if (!listing_url) {
                        return (
                            <div style={{ maxWidth: 200 }}>
                                <Chip
                                    label={'NO'}
                                    variant="outlined"
                                    size='medium'
                                />
                            </div>

                        )
                    }


                    return (
                        <div style={{ maxWidth: 200 }}>
                            <Chip
                                label={listing_url.toLowerCase()}
                                variant="outlined"
                                size='medium'
                                onClick={() => onSelectListing(listing_id)}
                            />
                        </div>

                    )

                }
            },

        },

        {
            name: "updatedAt",
            label: "UPDATED",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" } }),
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },
        {
            name: "createdAt",
            label: "CREATED",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (label) => {

                    return (
                        <div>
                            <Chip label={label} size='small' />
                        </div>
                    )
                }
            },
        },

    ];

    const data = LISTINGS.map((item) => {

        moment.locale(TRANSLATE.lang);

        let new_listing = {
            id: item.id,
            name: item.name,
            about: item.about,
            url: item.url,
            status: item.status ? 'ON' : 'OFF',
            images: item.images,
            price: item.price,
            price_day: item.price_day,
            price_week: item.price_week,
            price_month: item.price_month,
            marca: item.marca,
            category: item.category,
            currency: item.currency || 'EUR',
            isDeleted: item.isDeleted || false,
            manager: item.manager || {},
            updatedAt: moment(item.updatedAt).fromNow(),
            createdAt: moment(item.createdAt).format('DD MMMM YYYY')
        };

        return new_listing
    })

    const customToolbar = () => (
        <Tooltip disableFocusListener title="Add Product">
            <IconButton onClick={() => history.push('/admin/listing/profile/new')}>
                <Add />
            </IconButton>
        </Tooltip>
    );
 
    const options = {
        filter: false,
        filterType: 'dropdown',
        responsive: 'standard',
        customToolbar: customToolbar,
        serverSide: true,
        count: TOTAL,
        page: PAGE,
        rowsPerPage: LIMIT,
        rowsPerPageOptions: [],
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            const sortOrder = tableState.sortOrder || {};
            const sortFilter = sortOrder.name || '';
            const sortDirection = sortOrder.direction || '';


            switch (action) {

                case 'changePage':

                    onChangePageListings({
                        page: tableState.page,
                        sort: onSortListings(sortFilter),
                        direction: sortDirection
                    });

                    break;
                case 'sort':

                    onChangePageListings({
                        page: tableState.page,
                        sort: onSortListings(sortFilter),
                        direction: sortDirection
                    });

                    break;
                default:
                    console.log('action not handled.');
            }
        },
        searchText: searchText,
        searchProps: {
            onChange: (e) => {
                onSearchListings(e.target.value);
            },
            onKeyUp: (e) => {
                if (e.keyCode === 13) {
                    onSearchListings(e.target.value);
                }
            }
        },
        searchPlaceholder: 'Search Listing By Name',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Searching Listings with the applied filters...' :
                    'Sorry, there is no matching data to display',
            },
        },



    };


    return (
        <MUIDataTable
            title={
                <Typography variant="h2" style={{ margin: '80px 20px' }}>

                    All Listings →

                    {isLoading ? (

                        <CircularProgress size={40} style={{ marginLeft: 15, position: 'relative', top: 4, color: '#26BFBF' }} />

                    ) : (

                        <>
                            {' '}<span style={{ color: '#26BFBF' }}>{CURRENT}</span>{'/' + TOTAL}
                        </>

                    )}

                </Typography>
            }
            data={data}
            columns={columns}
            options={options}
        />
    );

}


const numberWithCommas = (x, CURRENCY) => {
    if (isNaN(x)) return x;

    return (x).toLocaleString('en-US', {
        style: 'currency',
        currency: CURRENCY,
    });

}

export default AdminListings

