import React, {useState} from 'react';

import {CloseOutline, LocationOutline} from 'react-ionicons';

import {postApiPrivate} from '../../api';

import ReactCountryFlag from "react-country-flag";
import Countries_EN from './countries_en.json';
 

const SearchCitiesComplete = (props) => {

    const {TRANSLATE, placeholder, history, onFetchUser, LANGUAGE, type, onSelectCity, style, styleParent, styleChild, styleIcon, inputRef, onFacebookTracking} = props;
 
    const [isFocused, setFocused] = useState(false);
    const [SearchResults, setSearchResults] = useState([]);
    const [SearchInput, setSearchInput] = useState('');
    

    const onUpdateSearch = async(search) => {

        console.log("CITY SELECTED ===> ", search);

        setFocused(false);

        setSearchInput(search[LANGUAGE]);

        onFacebookTracking('Search', {
            city: search[LANGUAGE],
            country: search.country,
            search_string: search[LANGUAGE],
        });

        console.log("CITY ==> ", search);

        if(type === 'onboarding_customer'){
            onSelectCity(search);
            return
        }
        
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        if(!JWT){
            
            onFetchUser(null, {search: search});
            history.push(search[LANGUAGE+'_url']);
         
            return 
        }

        const FORM = {
            lang: LANGUAGE,
            search: search
        }

        console.log("FORM TO UPLOAD ===> ", FORM);

        const DATA_RESPONSE = await postApiPrivate('/users/search', FORM , JWT);
        
        if(DATA_RESPONSE.error){
            console.log("ERROR FROM API SAVING USER", DATA_RESPONSE.error);
            return 
        }
        
        onFetchUser(JWT);

        history.push(search[LANGUAGE+'_url']);
    
        
    }

    const onSearchCities = async(city) => {

        setSearchInput(city);

        if(!city || city.length <= 3){
            setFocused(false);
            setSearchResults([]);

            return 
        }

        //Fetch

        try{

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/locations/search', {query: city, lang: LANGUAGE} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                return 
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if(filtredResults.length > 0){
                setFocused(true);
                setSearchResults(filtredResults);
            }

            
        }
        catch(e){
            
            console.log("CREATING ===> ", e);

            setFocused(false);
            setSearchResults([]);
    
        }
      
           
    }

    const onSearchKeyUp = async(event) => {
        event.preventDefault();

        if (event.keyCode === 13) {
            event.target.blur();

            if(SearchResults.length>0){
                onUpdateSearch(SearchResults[0]);
            }
        }
    }


    return (
        <div style={{display: 'flex', ...styleParent}}>
        
            <div className="search_container" style={{...style}}>
                
                <input placeholder={placeholder ? placeholder : TRANSLATE.where_to_rent} 
                        onFocus={()=> setFocused(true)}  
                        onChange={(e)=> onSearchCities(e.target.value)} 
                        onKeyUp={(e)=> onSearchKeyUp(e)}
                        value={SearchInput}
                        ref={inputRef}
                        autoComplete="new-password"
                        type="text"
                        
                />
                
                {SearchInput.length > 0 ? (
                    
                    <div style={{background: '#fd6270', ...styleIcon}}  onClick={()=> onSearchCities('')}>
                        <CloseOutline  color='#ffffff' />
                    </div>
                    
                ) : (   
                    <div style={{background: '#fd6270', ...styleIcon}}>
                        <LocationOutline  color='#ffffff' />
                    </div>
                    
                )}

            </div>

            {(isFocused && SearchResults.length > 0) && (
                <div style={{...Styles.resultsContainer, ...styleChild}}>
                    {SearchResults.map((item, index)=> {

                        const country_index = Countries_EN.findIndex((cty)=> cty.value === item.country);
                        const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
                        const country_name = country_obx ? country_obx.label : null

                        return (
                            <div style={Styles.resultsRow} 
                                    key={index} 
                                    onClick={()=> onUpdateSearch(item)}
                            >
                                <ReactCountryFlag countryCode={item.country} svg style={{marginRight: 5}}/> 
                                    {item[LANGUAGE]} - ({country_name || item.country})
                            </div>
                        )
                        
                    })}
                </div>
            )}
        </div>
    
    );

}


const Styles = {
    resultsRow: {
        display: 'flex', 
        alignItems: 'center', 
        color: '#000', 
        height: 40, 
        minHeight: 40,
        paddingLeft: 20, 
        border: 'solid 1px #00000019', 
        borderRight: 'none', 
        borderTop: 'none', 
        borderLeft: 'none',
        cursor: 'pointer',
        textTransform: 'capitalize'
    },
    resultsContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        background: '#fff', 
        width: 260, 
        maxHeight: 280,
        position: 'absolute', 
        top: 60, 
        border: 'solid 1px #00000019', 
        borderRadius: 10,
        overflow: 'auto'
    },
    right: {
        display: 'flex',
        alignItems: 'center'
    },
    searchContainer: {
        width: 300,
        height: 42,
        backgroundColor: '#F1F1F1',
        borderRadius: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 5
    },
    input: {
        fontSize: 16,
        display: 'flex',
        color: '#000',
        backgroundColor: 'transparent',
        border: 'none',
        width: '100%',
        marginLeft: 5,
        textTransform: 'capitalize'
    },
    searchOption: {
        display: 'flex', 
        width: 40, 
        height: 35, 
        alignItems: 'center', 
        borderRadius: '50%', 
        background: '#fd6270'
    }
  
}


export default SearchCitiesComplete