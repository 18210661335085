import React, { useState, useEffect } from "react";

import { postApiPrivate } from "../../api";

import {ArrowBack, CloseSharp, SaveSharp, TrashBinSharp } from "react-ionicons";

import swal from "sweetalert";

import { Grid } from "@mui/material";

import { Ages_Array, Filter_Array } from '../Modals/Constants';

import SearchStores from "../General/SearchStores";
import ProductImage from '../Modals/StoreImage';

import "../Profile/z_profile.css";

const AdminProductsAdd = (props) => {

  const { TRANSLATE, history, match, location } = props;
 
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);

  const [STORE, setStore] = useState(false);
  const [isSearchStore, setSearchStore] = useState(false);

  const [isImage, setImage] = useState(false);

  const product_id = match.params.id || '';


  useEffect(()=> {

    setLoading(true);

    setTimeout(()=> {
        setLoading(false);
    }, 1400)

  }, [])

  useEffect(() => {

    if (isImage || isSearchStore) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [isImage, isSearchStore])


  const { USER, LANGUAGE } = props;

  const [isPublished, setPublished] = useState(true);

  const [isFocused, setFocused] = useState(false);
  const [BrandResults, setBrandResults] = useState([]);
  const [BrandInput, setBrandInput] = useState('');

  const [LangName, setLangName] = useState('en');
  const [EN, setEN] = useState({});
  const [IT, setIT] = useState({});
  const [FR, setFR] = useState({});
  const [ES, setES] = useState({});

  //Product
  const [ID, setID] = useState(null);
  const [NAME, setName] = useState('');
  const [ABOUT, setAbout] = useState('');
  // const [MARCA, setMarca] = useState('');
  // const [BRAND, setBrand] = useState('');
  const [PRICE_DAY, setPriceDay] = useState(null);
  const [PRICE_WEEK, setPriceWeek] = useState(null);
  const [PRICE_MONTH, setPriceMonth] = useState(null);

  const [QUANTITY, setQuantity] = useState(null);

  const [CATEGORY, setCategory] = useState('passeggini');
  const [AGES, setAges] = useState('');

  //Images
  const [FIRST_IMAGE, setFirstImage] = useState(null);
  const [SECOND_IMAGE, setSecondImage] = useState(null);
  const [THIRD_IMAGE, setThirdImage] = useState(null);
  const [FOURTH_IMAGE, setFourthImage] = useState(null);

  //Based
  const [FIRST_BASED, setFirstBased] = useState(null);
  const [SECOND_BASED, setSecondBased] = useState(null);
  const [THIRD_BASED, setThirdBased] = useState(null);
  const [FOURTH_BASED, setFourthBased] = useState(null);


  //Camera + Picker
  const [SELECTED_INDEX, setSelectedIndex] = useState(null);
  const [CategoryImage, setCategoryImage] = useState(null);

  const onSaveProduct = async () => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

 
    //Check Params
    if(!STORE.id){
      return swal('Store Missing', 'You need to select a store owner', 'warning');
    }

    if(!FIRST_IMAGE || FIRST_IMAGE.length==0){
      return swal('First Photo Missing', 'You need to add at least one picture', 'warning');
    }

    if(NAME.length==0){
      return swal('Product Name Missing', 'You need to add a title to this product', 'warning');
    }

    if(ABOUT.length==0){
      return swal('Product Description Missing', 'You need to add a description to this product', 'warning');
    }

    if(CATEGORY.length==0){
      return swal('Product Category Missing', 'You need to add a category to this product', 'warning');
    }

    if(CATEGORY === 'consumable'){
      if(parseFloat(PRICE_DAY)==0){
        return swal('Consumable Price Missing', 'You need to add a price to this consumable product', 'warning');
      }
    }
    else{

      if(!parseFloat(PRICE_DAY)){
        return swal('Daily Price Missing', 'You need to add a daily price to this product', 'warning');
      }
  
      if(!parseFloat(PRICE_WEEK)){
        return swal('Weekly Price Missing', 'You need to add a weekly price to this product', 'warning');
      }
  
      if(!parseFloat(PRICE_MONTH)){
        return swal('Monthly Price Missing', 'You need to add a monthly price to this product', 'warning');
      }
  
    }
    

    let FORM = {
      store: STORE.id,
      name: NAME,
      about: ABOUT,
      category: CATEGORY,
      ages: AGES,
      price_day: parseFloat(PRICE_DAY),
      quantity: parseInt(QUANTITY),
      first_based: FIRST_BASED,
      second_based: SECOND_BASED,
      third_based: THIRD_BASED,
      fourth_based: FOURTH_BASED,

      // marca: MARCA,
      // brand: BRAND,
      en: EN,
      it: IT,
      fr: FR,
      es: ES,

      translate: true,

      isPublished: true
    }

    if (CATEGORY !== 'consumable') {
      FORM = {
        ...FORM,
        price_week: parseFloat(PRICE_WEEK),
        price_month: parseFloat(PRICE_MONTH),
      }
    }


    //return alert(JSON.stringify(FORM, null, 2));

    console.log("FORM TO SAVE ===> ", FORM);


    setSaving(true);

    try {

      const DATA_RESPONSE = await postApiPrivate('/admin/products/create', FORM, JWT);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API SAVING PRODUCT", DATA_RESPONSE.error);
        setSaving(false);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      swal('Product Created','Your product has been created successfully', 'success');

      history.push('/admin/products/profile/'+DATA_RESPONSE.id);

    }
    catch (e) {

      setSaving(false);
    }



  }

  const onChangePrice = (type, value) => {

    var price_value = value.replace(/[^0-9.]/g, '');

    if (type === 'price_day') {
      return setPriceDay(price_value);
    }
    else if (type === 'price_week') {
      return setPriceWeek(price_value);
    }
    else if (type === 'price_month') {
      return setPriceMonth(price_value);
    }
    else if (type === 'quantity') {
      var quantity_value = value.replace(/[^0-9]/g, '');
      return setQuantity(quantity_value);
    }


  }

  const onSelectCategory = (value) => {

    if (!value) {
      return alert('Wrong Category');
    }

    const CategoryIndex = Filter_Array.findIndex((item) => item && (item.code === value));
    if (CategoryIndex != -1) {
      const CategoryObx = Filter_Array[CategoryIndex] || '';
      setCategoryImage(CategoryObx.icon);
    }

    setCategory(value);

  }

  const onSelectAge = (age) => {

    const product_ages = AGES || [];

    if (product_ages.includes(age)) {

      const filtred_ages = product_ages.filter((item) => item !== age);
      setAges(filtred_ages);

    }
    else {
      setAges([...product_ages, age]);
    }

  }

  const onSelectBrand = async (brand) => {

    console.log("BRAND SELECTED ===> ", brand);

    // setFocused(false);
    // setBrandInput(brand.name);
    // setMarca(brand.name);
    // setBrand(brand.id);

  }

  const onSearchBrands = async (query) => {

    setBrandInput(query);

    if (!query) {
      setFocused(false);
      setBrandResults([]);

      return
    }

    //Fetch

    try {

      const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

      const DATA_RESPONSE = await postApiPrivate('/brands/search', { query: query, lang: LANGUAGE }, JWT);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
        return
      }

      console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

      const filtredResults = DATA_RESPONSE.data || [];

      if (filtredResults.length > 0) {
        setFocused(true);
        setBrandResults(filtredResults);
      }


    }
    catch (e) {

      console.log("CREATING ===> ", e);

      setFocused(false);
      setBrandResults([]);

    }


  }

  const onUpdateTranslations = (type, lang, value) => {


    if (type === 'name') {

      if (lang === 'en') {
        setName(value)
      }
      else if (lang === 'it') {
        setIT({ ...IT, name: value });
      }
      else if (lang === 'fr') {
        setFR({ ...FR, name: value });
      }
      else if (lang === 'es') {
        setES({ ...ES, name: value });
      }

    }
    else if (type === 'about') {

      if (lang === 'en') {
        setAbout(value)
      }
      else if (lang === 'it') {
        setIT({ ...IT, about: value });
      }
      else if (lang === 'fr') {
        setFR({ ...FR, about: value });
      }
      else if (lang === 'es') {
        setES({ ...ES, about: value });
      }

    }


  }

  const onSelectImage = (image) => {

    setSelectedIndex(null);

    if(SELECTED_INDEX == 0){
      setFirstImage(image.src);
      setFirstBased(image.id);
    }
    else if(SELECTED_INDEX == 1){
      setSecondImage(image.src);
      setSecondBased(image.id);
    }
    else if(SELECTED_INDEX == 2){
      setThirdImage(image.src);
      setThirdBased(image.id);
    }
    else if(SELECTED_INDEX == 3){
      setFourthImage(image.src);
      setFourthBased(image.id);
    }

    // alert(JSON.stringify({
    //   image,
    //   SELECTED_INDEX
      
    // }, null, 2));
  }

  const onPickerAdd = (index) => {
    console.log("PICKER IMAGE ADD SELECTED ===> ", index);
    //Check if Index has Previews Empty

    var corrected_index = CorrectedPickerIndex(index);

    console.log("PICKER IMAGE ADD SELECTED ===> ", corrected_index);

    setSelectedIndex(corrected_index);
    setImage(true);

  }

  const CorrectedPickerIndex = (index) => {

    if (!FIRST_IMAGE) {
      return 0;
    }

    if (!SECOND_IMAGE) {
      return 1;
    }

    if (!THIRD_IMAGE) {
      return 2;
    }

    if (!FOURTH_IMAGE) {
      return 3;
    }


    return index;

  }

 

  if (isSaving) {
    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
      </div>
    );
  }

  const isMasterLoading = props.isLoading;
  if (isMasterLoading) {

    return (
      <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader-provider.png' />
      </div>
    )
  }

  const ShopObx = STORE || {};
  const ShopCurrency = ShopObx.currency || 'eur';
  const ShopSymbol = USER.shop_symbol || '€';

  let CurrentProductName = onFetchTranslation('name', LangName, NAME, EN, IT, FR, ES);
  let CurrentProductAbout = onFetchTranslation('about', LangName, ABOUT, EN, IT, FR, ES);
  
  const sharedProps = {
    ...props
  }


  return (

    <>

      {isSearchStore && (
        <SearchStores {...props}
          onClose={() => setSearchStore(false)}
          onUpdateStore={setStore}

        />
      )}

      {isImage && (
        <ProductImage {...props}
          onClose={() => setImage(false)}
          onSelectImage={onSelectImage}
          TYPE={"products"}

        />
      )}


      <Grid container spacing={2}>

       

        <Grid item xs={12} lg={8} md={6}>

          <div className="w-full bg-white" style={{ padding: 20 }}>
            <div className="flex items-center mb-6 w-full">
              <h1 className="text-2xl font-bold flex items center">
                {/* <img className="mr-3 cursor-pointer" src="/assets/img/arrow-left.svg" onClick={()=> history.goBack()}/> */}
                {TRANSLATE.wd_add_product}
              </h1>
              <div className="flex items-center ml-auto">
                <span className="text-sm mr-2">
                  {TRANSLATE.wd_published}
                </span>
                <input className="rounded-sm" type="checkbox" checked={isPublished} onChange={() => setPublished(!isPublished)} />
              </div>
            </div>

            <div className="flex md:flex-nowrap flex-wrap w-full" >

              <div className="w-full lg:pl-8">
                <div className="flex flex-col w-full pt-8">

                  <div className="flex w-full">

                    <div className="input-group w-full with-icon">
                      <div className='flex items-center justify-between'>
                        <label>Product Images<span className="text-bagreen-100">*</span></label>
                      </div>

                      <div className="flex items-center flex-wrap">

                        {FIRST_IMAGE ? (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 border-zinc-200 border relative rounded-lg overflow-hidden">
                            <div className="w-[20px] h-[20px] bg-white flex items-center rounded-full hover:border-zinc-400 justify-center cursor-pointer absolute top-[5px] left-[5px] border border-zinc-200 z-20">
                              <img src="/assets/img/close.svg" className="w-[10px] h-[10px]" onClick={() => {
                                setFirstImage(null);
                                setFirstBased(null);
                              }} />
                            </div>
                            <img className="object-contain w-full h-full absolute top-0 left-0" src={FIRST_IMAGE} />
                          </div>
                        ) : (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 cursor-pointer hover:border-zinc-400 relative rounded-lg border border-dashed bg-zinc-100 border-zinc-200 overflow-hidden flex items-center justify-center" onClick={() => onPickerAdd(0)}>
                            <div className="text-center flex flex-col items-center justify-center">
                              <img className="w-[24px] h-[24px]" src="/assets/img/image.svg" />
                              <div className="font-semibold text-xs">
                                {TRANSLATE.btn_upload_picture}
                              </div>
                            </div>
                          </div>
                        )}

                        {SECOND_IMAGE ? (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 border-zinc-200 border relative rounded-lg overflow-hidden">
                            <div className="w-[20px] h-[20px] bg-white flex items-center rounded-full hover:border-zinc-400 justify-center cursor-pointer absolute top-[5px] left-[5px] border border-zinc-200 z-20">
                              <img src="/assets/img/close.svg" className="w-[10px] h-[10px]" onClick={() => {
                                setSecondImage(null);
                                setSecondBased(null);
                              }} />
                            </div>
                            <img className="object-contain w-full h-full absolute top-0 left-0" src={SECOND_IMAGE} />
                          </div>
                        ) : (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 cursor-pointer hover:border-zinc-400 relative rounded-lg border border-dashed bg-zinc-100 border-zinc-200 overflow-hidden flex items-center justify-center" onClick={() => onPickerAdd(1)}>
                            <div className="text-center flex flex-col items-center justify-center">
                              <img className="w-[24px] h-[24px]" src="/assets/img/image.svg" />
                              <div className="font-semibold text-xs">
                                {TRANSLATE.btn_upload_picture}
                              </div>
                            </div>
                          </div>
                        )}

                        {THIRD_IMAGE ? (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 border-zinc-200 border relative rounded-lg overflow-hidden">
                            <div className="w-[20px] h-[20px] bg-white flex items-center rounded-full hover:border-zinc-400 justify-center cursor-pointer absolute top-[5px] left-[5px] border border-zinc-200 z-20">
                              <img src="/assets/img/close.svg" className="w-[10px] h-[10px]" onClick={() => {
                                setThirdImage(null);
                                setThirdBased(null);
                              }} />
                            </div>
                            <img className="object-contain w-full h-full absolute top-0 left-0" src={THIRD_IMAGE} />
                          </div>
                        ) : (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 cursor-pointer hover:border-zinc-400 relative rounded-lg border border-dashed bg-zinc-100 border-zinc-200 overflow-hidden flex items-center justify-center" onClick={() => onPickerAdd(2)}>
                            <div className="text-center flex flex-col items-center justify-center">
                              <img className="w-[24px] h-[24px]" src="/assets/img/image.svg" />
                              <div className="font-semibold text-xs">
                                {TRANSLATE.btn_upload_picture}
                              </div>
                            </div>
                          </div>
                        )}

                        {FOURTH_IMAGE ? (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 border-zinc-200 border relative rounded-lg overflow-hidden">
                            <div className="w-[20px] h-[20px] bg-white flex items-center rounded-full hover:border-zinc-400 justify-center cursor-pointer absolute top-[5px] left-[5px] border border-zinc-200 z-20">
                              <img src="/assets/img/close.svg" className="w-[10px] h-[10px]" onClick={() => {
                                setFourthImage(null);
                                setFourthBased(null);
                              }} />
                            </div>
                            <img className="object-contain w-full h-full absolute top-0 left-0" src={FOURTH_IMAGE} />
                          </div>
                        ) : (
                          <div className="w-[120px] h-[120px] mt-4 mr-4 cursor-pointer hover:border-zinc-400 relative rounded-lg border border-dashed bg-zinc-100 border-zinc-200 overflow-hidden flex items-center justify-center" onClick={() => onPickerAdd(3)}>
                            <div className="text-center flex flex-col items-center justify-center">
                              <img className="w-[24px] h-[24px]" src="/assets/img/image.svg" />
                              <div className="font-semibold text-xs">
                                {TRANSLATE.btn_upload_picture}
                              </div>
                            </div>
                          </div>
                        )}



                      </div>

                    </div>

                  </div>

                  <div className="input-group w-full with-icon cursor-pointer" onClick={() => setSearchStore(true)}>

                    <div className='flex items-center justify-between'>
                      <label>Product Owner<span className="text-bagreen-100">*</span></label>
                    </div>

                    <img className="input-icon object-cover rounded-full" src={ShopObx.img || '/assets/img/avatar.svg'} alt="" />
                    <input className="focus:outline-bagreen-100 pt-0 cursor-pointer" type="text" placeholder=""
                      value={ShopObx.name}
                      onClick={() => setSearchStore(true)}

                    />
                  </div>

                  <div className="input-group w-full with-icon">
                    <div className='flex items-center justify-between'>
                      <label>{TRANSLATE.wd_product_name}<span className="text-bagreen-100">*</span></label>
                      <div className='flex mr-5'>
                        <img src="/assets/languages/en.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }} onClick={() => setLangName('en')} />
                        <img src="/assets/languages/it.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5) }} onClick={() => setLangName('it')} />
                        <img src="/assets/languages/fr.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5) }} onClick={() => setLangName('fr')} />
                        <img src="/assets/languages/es.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5) }} onClick={() => setLangName('es')} />
                      </div>
                    </div>

                    <img className="input-icon" src="/assets/img/search.svg" />
                    <input className="focus:outline-bagreen-100 pt-0" type="text" placeholder=""
                      onChange={(e) => onUpdateTranslations('name', LangName, e.target.value)}
                      value={CurrentProductName}

                    />
                  </div>


                  <div className="flex md:flex-nowrap flex-wrap gap-x-6">

                    {(CATEGORY !== 'consumable') ? (
                      <>
                        <div className="input-group w-full md:max-w-[300px]">
                          <label>{TRANSLATE.wd_daily_price} {ShopSymbol} <span className="text-bagreen-100">*</span></label>
                          {/*<div className="input-more">+</div>*/}

                          <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                            onChange={(e) => onChangePrice('price_day', e.target.value)} value={PRICE_DAY ? PRICE_DAY : ''}

                          />
                          {/*<div className="input-less">-</div>*/}
                        </div>
                        <div className="input-group w-full  md:max-w-[300px]">
                          <label>{TRANSLATE.wd_weekly_price} {ShopSymbol} <span className="text-bagreen-100">*</span></label>
                          {/*<div className="input-more">+</div>*/}
                          <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                            onChange={(e) => onChangePrice('price_week', e.target.value)} value={PRICE_WEEK ? PRICE_WEEK : ''}

                          />
                          {/*<div className="input-less">-</div>*/}
                        </div>
                        <div className="input-group w-full  md:max-w-[300px]">
                          <label>{TRANSLATE.wd_monthly_price} {ShopSymbol} <span className="text-bagreen-100">*</span></label>
                          {/*<div className="input-more">+</div>*/}
                          <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                            onChange={(e) => onChangePrice('price_month', e.target.value)} value={PRICE_MONTH ? PRICE_MONTH : ''}

                          />
                          {/*<div className="input-less">-</div>*/}
                        </div>
                      </>
                    ) : (
                      <div className="input-group w-full md:max-w-[300px]">
                        <label className='capitalize'>{TRANSLATE.wd_price} {ShopSymbol} <span className="text-bagreen-100">*</span></label>
                        {/*<div className="input-more">+</div>*/}

                        <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                          onChange={(e) => onChangePrice('price_day', e.target.value)} value={PRICE_DAY ? PRICE_DAY : ''}

                        />
                        {/*<div className="input-less">-</div>*/}
                      </div>
                    )}

                  </div>

                  <div className="input-group w-full with-icon">
                    <label className='capitalize'>{TRANSLATE.wd_category}<span className="text-bagreen-100">*</span></label>
                    <img className="input-icon" src={CategoryImage || "/assets/img/icons/stroller.svg"} />
                    <select className="focus:outline-bagreen-100 pt-0" value={CATEGORY} onChange={(e) => onSelectCategory(e.target.value)}>

                      {Filter_Array.map((item, index) => {

                        const Cat_Name = item[LANGUAGE];
                        const Cat_Code = item.code;

                        return (
                          <option value={Cat_Code} key={index}>
                            {Cat_Name}
                          </option>
                        )

                      })}

                    </select>

                  </div>

                  <hr className="my-6" />
                  <div className="input-group w-full">
                    <div className='flex align-items-center justify-content-between'>
                      <label>{TRANSLATE.wd_product_about}<span className="text-bagreen-100">*</span></label>
                      <div className='flex mr-5'>
                        <img src="/assets/languages/en.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }} onClick={() => setLangName('en')} />
                        <img src="/assets/languages/it.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'it' ? 1 : 0.5) }} onClick={() => setLangName('it')} />
                        <img src="/assets/languages/fr.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'fr' ? 1 : 0.5) }} onClick={() => setLangName('fr')} />
                        <img src="/assets/languages/es.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'es' ? 1 : 0.5) }} onClick={() => setLangName('es')} />
                      </div>
                    </div>
                    <textarea className="focus:outline-bagreen-100 " placeholder="About the product"
                      onChange={(e) => onUpdateTranslations('about', LangName, e.target.value)}
                      value={CurrentProductAbout}
                      style={{ minHeight: 500 }}

                    />
                  </div>

                  <div className="flex md:flex-nowrap flex-wrap gap-x-6">
                    <div className="input-group w-full md:max-w-[300px]">
                      <label className='capitalize'>{TRANSLATE.wd_quantity} <span className="text-bagreen-100">*</span></label>
                      {/*<div className="input-more">+</div>*/}

                      <input className="focus:outline-bagreen-100 pt-0 text-center" type="text" placeholder="0.00"
                        onChange={(e) => onChangePrice('quantity', e.target.value)} value={QUANTITY ? QUANTITY : ''}

                      />
                      {/*<div className="input-less">-</div>*/}
                    </div>

                  </div>


                  <div className="flex justify-between flex-wrap gap-[10px] md:gap-[20px]">
                    <div className="w-full font-semibold capitalize">
                      {TRANSLATE.wd_age}
                    </div>
                    {Ages_Array.map((item, index) => {

                      const isAdded = AGES && AGES.includes(item.code);

                      if (isAdded) {

                        return (
                          <div className="w-[calc(50%-10px)] text-sm cursor-pointer hover:border-zinc-400 border rounded-lg flex p-4 border-bagreen-100 bg-bagreen-50" key={index} onClick={() => onSelectAge(item.code)}>
                            <img className="mr-2" src="/assets/img/check.svg" />
                            <div>
                              <div className="font-semibold">
                                {item[LANGUAGE]}
                              </div>
                              <div className="font-normal text-xs">
                                {item.min}-{item.max} cm
                              </div>
                            </div>
                          </div>
                        )
                      }
                      else {

                        return (
                          <div className="w-[calc(50%-10px)] text-sm border-zinc-200 cursor-pointer hover:border-zinc-400 border rounded-lg flex p-4" key={index} onClick={() => onSelectAge(item.code)}>
                            <img className="mr-2 grayscale group-hover:grayscale-0" src="/assets/img/check.svg" />
                            <div>
                              <div className="font-semibold">
                                {item[LANGUAGE]}
                              </div>
                              <div className="font-normal text-xs">
                                {item.min}-{item.max} cm
                              </div>
                            </div>
                          </div>
                        )
                      }

                    })}


                  </div>

                  <div style={{ height: 200 }}></div>


                  {/* <div className="w-full flex md:flex-nowrap flex-wrap items-center justify-between bg-white md:sticky bottom-0 py-3 border-t border-zinc-200 z-50 mt-4 fixed left-0 md:px-0 px-4">
                      <div className="flex items-center btn-blank cursor-pointer md:w-auto w-1/2 justify-center md:order-1 order-2 md:my-0 my-3" onClick={() => history.goBack()}>Cancel</div>
                      <div className="flex items-center cursor-pointer text-red-600 md:ml-10 md:w-auto w-1/2 justify-center md:order-2 order-3" onClick={() => onDeleteProduct()}>
                        {TRANSLATE.btn_delete_product}
                      </div>
                      {CAN_SAVE ? (
                        <button className="btn-provider ml-auto px-8 py-4 md:w-auto w-full justify-center order-1 md:order-2" onClick={() => onUpdateProduct()}>
                          {TRANSLATE.btn_save_changes}
                        </button>
                      ) : (
                        <button className="btn-locked ml-auto px-8 py-4 cursor-not-allowed md:w-auto w-full justify-center order-1 md:order-2">
                          {TRANSLATE.btn_save_changes}
                        </button>
                      )}

                    </div> */}

                </div>
              </div>

            </div>

          </div>


        </Grid>


        <Grid item xs={12} lg={4} md={6}>
          <div className="top_right_container" style={{ height: 'auto', marginBottom: 20, position: 'sticky', top: 110 }}>
            
            <div className="row justify-content-center">
              
              <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'black', color: '#ffffff' }} onClick={() => history.push('/admin/products')}>
                <ArrowBack color={'#fff'} style={{ marginRight: 10 }} /> Back
              </div>

              <div className="top_add_cart simple col-sm-5 mr-sm-1" style={{ backgroundColor: 'green', color: '#ffffff' }} onClick={() => onSaveProduct()}>
                <SaveSharp color={'#fff'} style={{ marginRight: 10 }} /> Save Product
              </div>

            </div>

          </div>
        </Grid>

      </Grid>

    </>
  )


}


const onFetchTranslation = (type, lang, value, en, it, fr, es, live) => {

  let ProductName = '';
  let ProductAbout = '';

  if (type === 'name') {

    if (lang === 'en') {
      ProductName = value;
    }
    else if (lang === 'it') {
      ProductName = it.name || ''
    }
    else if (lang === 'fr') {
      ProductName = fr.name || ''
    }
    else if (lang === 'es') {
      ProductName = es.name || ''
    }

    if (live) {

      if (!ProductName) {
        ProductName = value;
      }
    }


    return ProductName
  }
  else if (type === 'about') {

    if (lang === 'en') {
      ProductAbout = value;
    }
    else if (lang === 'it') {
      ProductAbout = it.about || ''
    }
    else if (lang === 'fr') {
      ProductAbout = fr.about || ''
    }
    else if (lang === 'es') {
      ProductAbout = es.about || ''
    }

    if (!ProductAbout) {
      ProductAbout = value;
    }

    return ProductAbout
  }


  return '';
}

export default AdminProductsAdd
