const language = (state = 'en', action) => {

  console.log("onUpdateLanguage ===> ", state);
  
  switch (action.type) {
    case 'UPDATE_LANGUAGE':
      return action.language;
    default:
      return state;
  }
};
export default language;
