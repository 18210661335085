import React, {useEffect, useState} from "react";

import {postApiPrivate} from '../../api';

import swal from 'sweetalert';
import PhoneInput from 'react-phone-input-2';

import { deviceType } from "react-device-detect";
import { GetRouteSearchParams } from "../../helpers/GeneralHelper";
 

const MissingProduct = (props) => {

    const {USER, TRANSLATE, LANGUAGE, CURRENCY, onClose, TYPE, location} = props;

    const [FIRST_NAME, setFirstName] = useState('');
    const [LAST_NAME, setLastName] = useState('');
    const [EMAIL, setEmail] = useState('');
    const [PHONE, setPhone] = useState('');

    const [isSaving, setSaving] = useState(false);

    useEffect(()=>{
  
        if(USER.name){
          
            var name = USER.name;
            var email = USER.email;
            var phone = USER.phone;

            var names = getSplittedNames(name);
            var first_name = names.first_name;
            var last_name = names.last_name;

            setFirstName(first_name);
            setLastName(last_name);
            setEmail(email);
            setPhone(phone);

        }
  
    }, [USER])

   
    const onSendRentalRequest = async() => {
       
        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const RequestOriginal = window.location.toString();
        const RequestUrl = RequestOriginal ? RequestOriginal.replace('//localhost:3000', '//www.babonbo.com') : '';
 
        let FORM = {
            fn: FIRST_NAME.trim(),
            ln: LAST_NAME.trim(),
            email: EMAIL.trim(),
            phone: PHONE,
            type: TYPE,
            url: RequestUrl
        }

        if(TYPE === 'flow'){

            const routeParams = GetRouteSearchParams(location.search) || {};

            const PickupString = routeParams.pickadd ? window.atob(routeParams.pickadd) : '';
            const DropoffString = routeParams.dropadd ? window.atob(routeParams.dropadd) : '';

            const PickupObx = PickupString ? GetRouteSearchParams(PickupString) : {};
            const DropoffObx = DropoffString ? GetRouteSearchParams(DropoffString) : {};
        
            const REQUEST = { 
                id: routeParams.id, 
                checkin: routeParams.checkin,
                checkout: routeParams.checkout,
                categories: routeParams.categories,
                age: routeParams.age,
                picklat: routeParams.picklat,
                picklon: routeParams.picklon,
                droplat: routeParams.droplat,
                droplon: routeParams.droplon,
                picktime: routeParams.picktime,
                droptime: routeParams.droptime,

                pickadd: PickupObx.input,
                dropadd: DropoffObx.input,

                pickcity: PickupObx.city,
                dropcity: DropoffObx.city,

                lang: LANGUAGE,
                curid: CURRENCY,
                device: deviceType
            };

            FORM = {
                ...FORM,
                request: REQUEST
            }
        }
 
        console.log("FORM TO UPLOAD ===> ", FORM);

        try{
    
            setSaving(true);
    
            var DATA_RESPONSE = await postApiPrivate('/flow/requests', FORM , JWT);
             
            if(DATA_RESPONSE.error){

                setSaving(false);

                console.log("ERROR FROM API SAVING USER", DATA_RESPONSE.error);

                alert("Cannot send request");
                return 
            }
            
            swal('Request Send', 'Congratulations your request has been updated successfully', 'success');
            
            onClose();

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

        }
        catch(e){

            setSaving(false);

            console.log("ERROR WHILE SAVING profile ===> ", e);

            alert("Cannot send request");

        }

        
    }

 
    

    const CAN_SAVE = FIRST_NAME.trim().length >= 3 && LAST_NAME.trim().length >= 3 && isValidEmail(EMAIL) && PHONE.trim().length > 3;


    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 z-[9999] flex items-end md:items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]">
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />

                {isSaving ? (
                    <div className="mx-auto w-full max-w-[400px] md:h-auto flex flex-col h-[calc(100vh-100px)] overflow-auto px-4 sm:px-6 lg:px-8 pb-10 pt-10 bg-white md:rounded-2xl rounded-tl-2xl rounded-tr-2xl relative z-20">
                        <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
                            <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader.png' />
                        </div>
                    </div>
                ) : (
                    <div className="mx-auto w-full max-w-[400px] md:h-auto flex flex-col h-[calc(100vh-100px)] overflow-auto px-4 sm:px-6 lg:px-8 pb-10 pt-10 bg-white md:rounded-2xl rounded-tl-2xl rounded-tr-2xl relative z-20">
                        <img className="absolute top-6 right-6 cursor-pointer" src="/assets/img/close.svg" onClick={()=> onClose()}/>
                        <div className="flex flex-col">
                            <h1 className="text-xl">Sorry we were’t able to find the gear you are looking for.</h1>
                            <p className="mb-8 text-sm mt-2">Please fill in the form below and we will try to get the gear for you.</p>
                            <div className="input-group w-full mt-3">
                                <label>Your Name <span className="text-babonbo-100">*</span></label>
                                <input className="mb-3" type="text" placeholder="First Name"
                                        onChange={(e)=> setFirstName(e.target.value)} 
                                        value={FIRST_NAME} 
                                
                                />
                                <input type="text" placeholder="Last Name" 
                                        onChange={(e)=> setLastName(e.target.value)} 
                                        value={LAST_NAME} 
                                
                                />
                            </div>
                            <div className="input-group w-full mt-3">
                                <label>Contact Details<span classNme="text-babonbo-100">*</span></label>
                                
                                <PhoneInput
                                    country={LANGUAGE === 'en' ? 'gb' : LANGUAGE}
                                    value={PHONE}
                                    onChange={phone => setPhone(phone)}
                                    containerClass="phone-input mb-3"
                                    buttonStyle={{background: 'transparent', border: 'none'}}
                                    placeholder={TRANSLATE.wd_phone_number}
                                    inputClass="bg-zinc-100 rounded-full border-0 h-[60px] w-full pl-14"
                                    buttonClass="transparent hover:!bg-transparent !bg-transparent"
                                    
                                />

                                <input type="text" placeholder="Email address" 
                                        onChange={(e)=> setEmail(e.target.value)} 
                                        value={EMAIL}
                                
                                />
                            </div>
                        </div>

                        {CAN_SAVE ? (
                            <button className="btn-full ml-auto px-8 py-4 md:mt-0 mt-auto" onClick={()=> onSendRentalRequest()}>
                                Send Request
                            </button>
                        ) : (
                            <button className="btn-full ml-auto px-8 py-4 md:mt-0 mt-auto cursor-not-allowed">
                                Send Request
                            </button>
                        )}
                        
                    </div>
                )}
                

            </div>


        </>
    )
}


const getSplittedNames = (full_name) => {
    
    const full_name_array = full_name ? full_name.split(' ') : [];
    const first_name = full_name_array[0];
    const last_name = full_name_array[full_name_array.length - 1];

    return {
        first_name,
        last_name
    }
}

const isValidEmail = (email) => {

	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
  
}

export default MissingProduct