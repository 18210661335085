import React, { useEffect, useState } from "react";

import { postApiPublic } from "../../api";

import Stars from "./Stars";

const ReviewsList = (props) => {


    const {onClose, providerId, LANGUAGE, TRANSLATE} = props;

    const [isLoading, setLoading] = useState(false);
    const [REVIEWS, setReviews] = useState([]);


    useEffect(()=> {

        if(providerId){
           onFetchReviews(providerId)
        }

    }, [])

    const onFetchReviews = async(store_id) => {

        setLoading(true);

        try{
    
            const FORM = { id: store_id, provider: true};
      
            console.log("REVIEWS ===> ", FORM);
        
            const DATA_RESPONSE = await postApiPublic('/users/reviews', FORM);
              
            if(DATA_RESPONSE.error){
      
                console.log("ERROR FROM API LOADING REVIEWS", DATA_RESPONSE.error);
                return 
            }
      
            console.log("REVIEWS ===> ", DATA_RESPONSE);
      
            setReviews(DATA_RESPONSE.data || []);
      
            setLoading(false)
      
      
        }
        catch(e){
      
            setLoading(false);
        }
    }



    return (

        <div className="fixed w-screen h-screen top-0 left-0 z-[500] flex items-end md:items-center justify-center drop-shadow-[0_0px_20px_rgba(0,0,0,0.25)]">
            <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" />

            {isLoading ? (
                <div className="mx-auto w-full max-w-[700px] overflow-auto md:max-h-[calc(100vh-100px)] max-h-[calc(100vh-50px)] md:flex px-4 sm:px-6 lg:px-8 pb-4 md:pb-10 pt-10 bg-white rounded-2xl relative z-20 modal-in">
                    <img className="absolute top-4 right-4 cursor-pointer" src="/assets/img/close.svg" onClick={()=> onClose()}/>
                    <div className='w-full h-[calc(100vh-200px)] flex items-center justify-center'>
                        <img className='animate-spin w-[24px] h-[24px]' src='/assets/img/loader.png' />
                    </div>
                </div>
            ) : (
                
                <div className="mx-auto w-full max-w-[700px] overflow-auto md:max-h-[calc(100vh-100px)] max-h-[calc(100vh-50px)] md:flex px-4 sm:px-6 lg:px-8 pb-4 md:pb-10 pt-10 bg-white rounded-2xl relative z-20 modal-in">
                    <img className="absolute top-4 right-4 cursor-pointer" src="/assets/img/close.svg" onClick={()=> onClose()}/>
                    <div className="flex flex-col items-start mb-8 gap-4">

                        {REVIEWS.length == 0 ? (
                            <div className="font-semibold capitalize">
                                0 {TRANSLATE.wd_reviews} 
                            </div>
                        ) : (
                            <>

                                <div className="font-semibold capitalize">
                                    {REVIEWS.length} {TRANSLATE.wd_reviews} 
                                </div>

                                {REVIEWS.map((item, index)=> {

                                    const customer = item.customer || {};

                                    return (
                                        <Reviews name={customer.name} 
                                                subtitle={item.text}
                                                date={item.date} 
                                                image={customer.img}
                                                rank={item.rank}
                                                key={index}
                                                TRANSLATE={TRANSLATE}
                                        />
                                    )

                                })}

                                <div className="w-full rounded-lg p-6 flex items-start">
                                </div>

                            </>
                        )}
                        
                        

                        
                    </div>

                </div>
            )}


        </div>

    )
}


const Reviews = (props) => {

    const {name, subtitle, image, rank} = props;

    return (
        <div className="w-full border border-zinc-200 rounded-lg p-6 flex items-start">
            <img className="w-[50px] h-[50px] rounded-full mr-4 object-cover" src={image || '/assets/img/avatar.svg'} />
            <div>
                <div className="font-semibold">
                    {name}
                </div>

                <Stars rate={rank} className={"flex gap-x-2 mt-3"}/>
                
                <p className="py-3">
                    {subtitle}
                </p>
            </div>
        </div>
    )
}
export default ReviewsList