
import swal from 'sweetalert';

const DefaultAlert = (title, description, type) => {

  if(!type){
    type = 'warning';
  }
  return swal(title, description, type);
};

export { DefaultAlert };