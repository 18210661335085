import React, { useState } from 'react';
import { ArrowForward } from 'react-ionicons';
import Dropdown from '../Dropdown/Dropdown';
import Header from '../Header';

const CustomerOrderEdit = (props) => {
  const [product, setProduct] = useState('stroller');
  const [pickupAddress, setPickupAddress] = useState('address');
  const [deliveryAddress, setDeliveryAddress] = useState('address');
  const [about, setAbout] = useState(
    'Arrival Air Emirates Dec 28 EK206 11:55am Depart Air Emirates Jan 7, 2023J EK205 3:40pm (15:40pm)'
  );
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} />

      <div
        className='relative flex flex-col mx-auto my-4 gap-4 w-full max-w-xl px-4 sm:px-6 lg:px-8'
        style={{ flexGrow: 1 }}
      >
        <div className='flex w-full align-items-center justify-content-between'>
          <h2 className='text-sm'>YOUR ORDER DETAILS - ID: #5235266</h2>
        </div>

        <div className='flex flex-col gap-4 p-2 w-full bg-white border-gray-dashed rounded-lg'>
          <div>
            <h2 className='text-sm text-ellipsis'>Change Product</h2>
            <Dropdown
              value={product}
              options={[{ title: 'Uppa Baby Stroller', value: 'stroller' }]}
              onChange={(value) => setProduct(value)}
              searchDisabled={true}
              outlined
            />
          </div>
          <div className='mt-4'>
            <h2 className='text-sm text-ellipsis'>Dates</h2>
            <div className='w-full'>
              <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                <label className='inline-label bg-light-purple'>Start Date</label>

                <input value={startDate} onChange={(e) => setStartDate(e.target.value)} type='text' placeholder='' />
              </div>
            </div>
            <div className='w-full'>
              <div className='relative inline-flex flex-column mt-5 mb-3 w-full '>
                <label className='inline-label bg-light-purple'>EndDate</label>

                <input value={endDate} onChange={(e) => setEndDate(e.target.value)} type='text' placeholder='' />
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-4 p-2 w-full bg-white border-gray-dashed rounded-lg'>
          <h2 className='text-sm text-ellipsis'>Change Delivery Address</h2>
          <div>
            <h2 className='text-sm text-ellipsis'>Delivery</h2>
            <Dropdown
              value={deliveryAddress}
              options={[{ title: 'Malpensa International Airport', value: 'address' }]}
              onChange={(value) => setDeliveryAddress(value)}
              searchDisabled={true}
              outlined
            />
          </div>

          <div>
            <h2 className='text-sm text-ellipsis'>Pickup</h2>
            <Dropdown
              value={pickupAddress}
              options={[{ title: 'Malpensa International Airport', value: 'address' }]}
              onChange={(value) => setPickupAddress(value)}
              searchDisabled={true}
              outlined
            />
          </div>
        </div>

        <div className='flex flex-col gap-4 p-2 w-full bg-white border-gray-dashed rounded-lg'>
          <h2 className='text-sm text-ellipsis'>Additional Information</h2>

          <div className='relative inline-flex flex-column mb-3 w-full '>
            <label className='inline-label bg-light-gray '>Additional Information</label>
            <textarea
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              type='text'
              placeholder='ABOUT'
              className='w-full text-sm rounded-lg border-gray'
            />
          </div>
        </div>

        <div className='sticky top-auto flex flex-col w-full pt-2 bg-white' style={{ bottom: '0px' }}>
          <button
            type='button'
            className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
            onClick={() => {}}
          >
            REVIEW CHANGES <ArrowForward style={{ color: '#fff' }} />
          </button>
          <button
            type='button'
            className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full color-red rounded-lg'
            onClick={() => {}}
          >
            CANCEL MY ORDER
          </button>
          <button
            type='button'
            className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full rounded-lg'
            onClick={() => {}}
          >
            CANCEL
            <ArrowForward style={{ color: '#fff' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderEdit;
