import React, { useEffect, useState } from 'react';

import { postApiPrivate } from '../../api';

import moment from 'moment/min/moment-with-locales';

import MUIDataTable from 'mui-datatables';

import LoaderMaster from '../Loaders/Loaders';
import { Link } from 'react-router-dom';

import ReactCountryFlag from "react-country-flag";
import Countries_EN from '../Search/countries_en.json';

import StarReview from '../General/Stars';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    LineChart
} from "recharts";
import { Box, Button, Modal } from '@mui/material';



const AnalyticsCalendar = (props) => {

    const { TRANSLATE, LANGUAGE, location, history } = props;

    let MenuClass = 'col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6 mb-4';
    let MenuStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        fontSize: 18,
        fontWeight: 'bold',
        width: '100%',
        background: '#fff',
        paddingTop: 20,
        paddingBottom: 20
    }

    let MenuClassStats = 'col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 d-flex flex-column align-items-center justify-content-center';
    let MenuStyleStats = {
        borderRadius: 20,
        fontSize: 24,
        fontWeight: 'bold',

        width: '100%'
    }

    const [isOpenCity, setOpenCity] = useState(false);
    const [isOpenProvider, setOpenProvider] = useState(false);
    const [isOpenDates, setOpenDates] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [isServing, setServing] = useState(false);

    const [CurrentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [CurrentMonth, setCurrentMonth] = useState(new Date().getMonth());

    const [CurrentOrders, setCurrentOrders] = useState([]);
    const [CurrentMonthly, setCurrentMonthly] = useState([]);
    const [CurrentTopStores, setCurrentTopStores] = useState([]);
    const [CurrentTopCountries, setCurrentTopCountries] = useState([]);
    const [CurrentTopCities, setCurrentTopCities] = useState([]);
    const [CurrentTopProducts, setCurrentTopProducts] = useState([]);
    const [FullData, setFullData] = useState({});

    const [Locations, setLocations] = useState([]);
    const [Stores, setStores] = useState([]);

    const [CurrentLocation, setCurrentLocation] = useState('all');
    const [CurrentStore, setCurrentStore] = useState('all');
    const [CurrentType, setCurrentType] = useState('all');
    const [CurrentDate, setCurrentDate] = useState('current_month');

    const [CurrentFirst, setCurrentFirst] = useState(null);
    const [CurrentLast, setCurrentLast] = useState(null);

    const [SortMonthly, setSortMonthly] = useState('timeline');
    const [SortDaily, setSortDaily] = useState('timeline');
    const [SortStores, setSortStores] = useState('top_orders');
    const [SortProducts, setSortProducts] = useState('top_orders');

    const [isShownStores, setShownStores] = useState(false);
    const [isShownProducts, setShownProducts] = useState(false);

    const [RANGE, setRange] = useState(false);
    const [FILTER, setFilter] = useState('received');


    // useEffect(() => {

    //     onFetchLocations();
    //     onFetchStores();


    // }, []);

    useEffect(() => {

        onFetchCalendar();

    }, [location.search]);

    const onFetchCalendar = async (year, month) => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        try {

            const CurrentParams = GetRouteSearchParams(location.search) || {};

            const CurrentStoreX = CurrentParams.store || CurrentStore;
            const CurrentLocationX = CurrentParams.location || CurrentLocation;

            const CurrentDateX = CurrentParams.date || CurrentDate;
            const CurrentStartX = CurrentParams.start || CurrentStart;
            const CurrentEndX = CurrentParams.end || CurrentEnd;

            if (CurrentParams.store) {
                setCurrentStore(CurrentParams.store)
            }

            if (CurrentParams.location) {
                setCurrentLocation(CurrentParams.location)
            }

            if (CurrentParams.date) {
                setCurrentDate(CurrentParams.date)
            }


            let FORM = {
                store: CurrentStoreX,
                location: CurrentLocationX,
                start: CurrentStartX,
                end: CurrentEndX,
                date: CurrentDateX
            }

            setLoading(true);

            const DATA_RESPONSE = await postApiPrivate('/admin/analytics/calendar', {
                ...FORM,
                year: year || CurrentYear,
                month: month || CurrentMonth
            }, JWT);

            if (DATA_RESPONSE.error) {

                setLoading(false);

                console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);

                return alert('Error Loading the dashboard');
            }

            const DASHBOARD_DATA = DATA_RESPONSE.data || [];
            const DASHBOARD_MONTHLY = DATA_RESPONSE.monthly || [];
            const DASHBOARD_TOP_STORES = DATA_RESPONSE.top_stores || [];
            const DASHBOARD_TOP_COUNTRIES = DATA_RESPONSE.top_countries || [];
            const DASHBOARD_TOP_PRODUCTS = DATA_RESPONSE.top_products || [];
            const DASHBOARD_TOP_CITIES = DATA_RESPONSE.top_cities || [];

            const TOP_STORES_SORTED = DASHBOARD_TOP_STORES.sort((a, b) => b.total - a.total);
            const TOP_COUNTRIES_SORTED = DASHBOARD_TOP_COUNTRIES.sort((a, b) => b.total - a.total);
            const TOP_CITIES_SORTED = DASHBOARD_TOP_CITIES.sort((a, b) => b.total - a.total);
            const TOP_PRODUCTS_SORTED = DASHBOARD_TOP_PRODUCTS.sort((a, b) => b.total - a.total);

            setCurrentOrders(DASHBOARD_DATA);
            setCurrentMonthly(DASHBOARD_MONTHLY);
            setCurrentTopStores(TOP_STORES_SORTED);
            setCurrentTopCountries(TOP_COUNTRIES_SORTED);
            setCurrentTopCities(TOP_CITIES_SORTED);
            setCurrentTopProducts(TOP_PRODUCTS_SORTED);

            setFullData(DATA_RESPONSE);

            setLoading(false);

            console.log("FETCHING DASHBOARD ===> ", DASHBOARD_DATA);

        }
        catch (e) {

            setLoading(false);
            alert('Error Loading the dashboard');
        }

    }

    const onFetchLocations = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        try {

            setLoading(true);

            const DATA_RESPONSE = await postApiPrivate('/admin/analytics/locations', {}, JWT);

            if (DATA_RESPONSE.error) {

                setLoading(false);

                console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);

                return alert('Error Loading the dashboard');
            }

            const DASHBOARD_DATA = DATA_RESPONSE.data || [];

            setLocations(DASHBOARD_DATA);

            setLoading(false);

            console.log("FETCHING DASHBOARD ===> ", DASHBOARD_DATA);

        }
        catch (e) {

            setLoading(false);
            alert('Error Loading the dashboard');
        }

    }

    const onFetchStores = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        try {

            setLoading(true);

            const DATA_RESPONSE = await postApiPrivate('/admin/analytics/providers', {}, JWT);

            if (DATA_RESPONSE.error) {

                setLoading(false);

                console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);

                return alert('Error Loading the dashboard');
            }

            const DASHBOARD_DATA = DATA_RESPONSE.data || [];

            setStores(DASHBOARD_DATA);

            setLoading(false);

            //alert("DATA ===> "+JSON.stringify(DASHBOARD_DATA, null, 2))

            console.log("FETCHING DASHBOARD ===> ", DASHBOARD_DATA);

        }
        catch (e) {

            setLoading(false);
            alert('Error Loading the dashboard');
        }

    }

    const onUpdateStatus = (type) => {
        setCurrentType(type)
    }

    const onUpdateLocation = (type) => {

        if (type === 'no_value') { return }
        if (type === 'search') {
            setOpenCity(true);
            return
        }

        setCurrentLocation(type)
        setCurrentStore('no_value')

        const CurrentParams = GetRouteSearchParams(location.search) || {};
        const CurrentStart = CurrentParams.start;
        const CurrentEnd = CurrentParams.end;

        if (CurrentStart && CurrentEnd) {
            history.push('?location=' + type + '&start=' + CurrentStart + '&end=' + CurrentEnd);
        }
        else {
            history.push('?location=' + type);
        }

    }

    const onUpdateStore = (type) => {

        if (type === 'no_value') { return }
        if (type === 'search') {
            setOpenProvider(true);
            return
        }


        const CurrentParams = GetRouteSearchParams(location.search) || {};
        const CurrentStart = CurrentParams.start;
        const CurrentEnd = CurrentParams.end;

        setCurrentStore(type)
        setCurrentLocation('no_value')

        if (CurrentStart && CurrentEnd) {
            history.push('?store=' + type + '&start=' + CurrentStart + '&end=' + CurrentEnd);
        }
        else {
            history.push('?store=' + type);
        }

    }

    const onUpdateDate = (type) => {
        if (type === 'custom_range') {
            setOpenDates(true);
            return
        }

        const CurrentParams = GetRouteSearchParams(location.search) || {};
        const CurrentLocation = CurrentParams.location;
        const CurrentStore = CurrentParams.store;
        const CurrentStart = CurrentParams.start;
        const CurrentEnd = CurrentParams.end;

        var REFERENCE = moment(); // fixed just for testing, use moment();
        var TODAY = REFERENCE.clone().startOf('day');
        var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
        var A_7DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_14DAYS_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
        var A_30DAYS_OLD = REFERENCE.clone().subtract(30, 'days').startOf('day');
        var A_60DAYS_OLD = REFERENCE.clone().subtract(60, 'days').startOf('day');
        var A_90DAYS_OLD = REFERENCE.clone().subtract(90, 'days').startOf('day');
        var A_180DAYS_OLD = REFERENCE.clone().subtract(180, 'days').startOf('day');

        var A_YEAR = REFERENCE.format('YYYY');
        var A_YEAR_FIRST = '1-1-' + A_YEAR;
        var A_YEAR_LAST = '31-12-' + A_YEAR;

        // alert("A_YEAR_FIRST ===> "+JSON.stringify({
        //     A_YEAR,
        //     A_YEAR_FIRST,
        //     A_YEAR_LAST
        // }, null, 2))

        var PART_QUERY = '';

        if (CurrentLocation) {
            PART_QUERY = '?location=' + CurrentLocation;
        }

        if (CurrentStore) {
            PART_QUERY = '?store=' + CurrentStore;
        }

        if (!CurrentLocation && !CurrentStore) {
            PART_QUERY = '?location=all'
        }

        if (type === 'today') {
            history.push(PART_QUERY + '&date=' + type + '&start=' + TODAY.format('DD-MM-YYYY'));
        }
        else if (type === 'yesterday') {
            history.push(PART_QUERY + '&date=' + type + '&start=' + YESTERDAY.format('DD-MM-YYYY'));
        }
        else if (type === 'last_7') {
            history.push(PART_QUERY + '&date=' + type + '&start=' + A_7DAYS_OLD.format('DD-MM-YYYY') + '&end=' + REFERENCE.format('DD-MM-YYYY'));
        }
        else if (type === 'last_14') {
            history.push(PART_QUERY + '&date=' + type + '&start=' + A_14DAYS_OLD.format('DD-MM-YYYY') + '&end=' + REFERENCE.format('DD-MM-YYYY'));
        }
        else if (type === 'last_30') {
            history.push(PART_QUERY + '&date=' + type + '&start=' + A_30DAYS_OLD.format('DD-MM-YYYY') + '&end=' + REFERENCE.format('DD-MM-YYYY'));
        }
        else if (type === 'last_60') {
            history.push(PART_QUERY + '&date=' + type + '&start=' + A_60DAYS_OLD.format('DD-MM-YYYY') + '&end=' + REFERENCE.format('DD-MM-YYYY'));
        }
        else if (type === 'last_90') {
            history.push(PART_QUERY + '&date=' + type + '&start=' + A_90DAYS_OLD.format('DD-MM-YYYY') + '&end=' + REFERENCE.format('DD-MM-YYYY'));
        }
        else if (type === 'last_180') {
            history.push(PART_QUERY + '&date=' + type + '&start=' + A_180DAYS_OLD.format('DD-MM-YYYY') + '&end=' + REFERENCE.format('DD-MM-YYYY'));
        }
        else if (type === 'current_month') {
            history.push(PART_QUERY + '&date=' + type);
        }
        else if (type === 'current_year') {
            history.push(PART_QUERY + '&date=' + type + '&start=' + A_YEAR_FIRST + '&end=' + A_YEAR_LAST);
        }


        setCurrentDate(type)
    }

    const onSelectDate = (date, type) => {

        // alert("HELO => "+JSON.stringify({
        //    date, 
        //    type,
        //    value: moment(date).format('DD-MM-YYYY')
        // }, null, 2))

        if (type === 'start') {
            setCurrentFirst(moment(date).format('DD-MM-YYYY'));
        }
        else if (type === 'end') {
            setCurrentLast(moment(date).format('DD-MM-YYYY'));
        }
        return
    }

    const onApplyDate = () => {

        const CurrentParams = GetRouteSearchParams(location.search) || {};
        const CurrentLocation = CurrentParams.location;
        const CurrentStore = CurrentParams.store;

        var PART_QUERY = '';

        if (CurrentLocation) {
            PART_QUERY = '?location=' + CurrentLocation;
        }

        if (CurrentStore) {
            PART_QUERY = '?store=' + CurrentStore;
        }

        if (!CurrentLocation && !CurrentStore) {
            PART_QUERY = '?location=all'
        }


        history.push(PART_QUERY + '&date=custom_range&start=' + CurrentFirst + '&end=' + CurrentLast);

        setOpenDates(false);

        return
    }

    const getNextMonth = () => {
        const CurrentDate = new Date(CurrentYear, CurrentMonth, 1);

        const NextMonth = moment(CurrentDate).add(1, 'months');

        const NextCurrentYear = new Date(NextMonth).getFullYear();
        const NextCurrentMonth = new Date(NextMonth).getMonth();

        setCurrentYear(NextCurrentYear);
        setCurrentMonth(NextCurrentMonth);

        onFetchCalendar(NextCurrentYear, NextCurrentMonth);
    }

    const getPreviousMonth = () => {

        const CurrentDate = new Date(CurrentYear, CurrentMonth, 1);

        const PreviousMonth = moment(CurrentDate).subtract(1, 'months');

        const PreviousCurrentYear = new Date(PreviousMonth).getFullYear();
        const PreviousCurrentMonth = new Date(PreviousMonth).getMonth();

        setCurrentYear(PreviousCurrentYear);
        setCurrentMonth(PreviousCurrentMonth);


        onFetchCalendar(PreviousCurrentYear, PreviousCurrentMonth);


    }


    //Setup Dates
    const CalendarArray = getAllDaysInMonth(CurrentYear, CurrentMonth) || [];
    let FirstDay = moment(new Date(CalendarArray[0]));
    let LastDay = moment(new Date(CalendarArray[CalendarArray.length - 1]));

    const CurrentParams = GetRouteSearchParams(location.search) || {};
    const CurrentStart = CurrentParams.start;
    const CurrentEnd = CurrentParams.end;

    if (CurrentStart) {
        FirstDay = moment(CurrentStart, 'DD-MM-YYYY');
    }

    if (CurrentEnd) {
        LastDay = moment(CurrentEnd, 'DD-MM-YYYY');
    }

    //Setup Orders
    const DATA = CurrentOrders ? CurrentOrders.map((item) => {

        const GMV = Math.round(item.revenue || 0) || 0;

        return {
            ...item,
            GMV: GMV
        }

    }) : [];

    //Setup Monthly
    const DATA_MONTHLY = CurrentMonthly ? CurrentMonthly.map((item) => {

        const GMV = Math.round(item.revenue || 0) || 0;

        return {
            ...item,
            GMV: GMV
        }

    }) : [];

    //Setup Performance
    const TotalStats = FullData.total_stats || {};
    const GreenStats = FullData.green_stats || {};
    const RedStats = FullData.red_stats || {};
    const YellowStats = FullData.yellow_stats || {};

    const TotalOrders = FullData.total || 0;
    const TotalPending = FullData.pending || 0;
    const TotalAccepted = FullData.accepted || 0;
    const TotalDelivering = FullData.delivering || 0;
    const TotalDelivered = FullData.delivered || 0;
    const TotalComplete = FullData.complete || 0;
    const TotalRejected = FullData.rejected || 0;
    const TotalCanceled = FullData.canceled || 0;
    const TotalRefund = FullData.refund || 0;

    const TotalGreen = FullData.green || 0;
    const TotalRed = FullData.red || 0;
    const TotalYellow = FullData.yellow || 0;

    const RateGreen = (TotalGreen / TotalOrders * 100) || 0;
    const RateRed = (TotalRed / TotalOrders * 100) || 0;
    const RateYellow = (TotalYellow / TotalOrders * 100) || 0;

    const BabonboScore = FullData.score || 0;

    //Calculate Performance
    let MainStats = TotalStats;
    if (FILTER === 'yellow') {
        MainStats = YellowStats;
    }
    else if (FILTER === 'green') {
        MainStats = GreenStats;
    }
    else if (FILTER === 'red') {
        MainStats = RedStats
    }

    const TotalShown = TotalStats.amount_total || 0;

    const TotalTransactions = MainStats.amount_total || 0;
    const TotalProvider = MainStats.amount_provider || 0;
    const TotalFees = MainStats.amount_fees || 0;

    const TotalCustomers = MainStats.total_customers || 0;
    const TotalProviders = MainStats.total_providers || 0;
    const TotalProducts = MainStats.total_products || 0;
    const TotalDays = MainStats.total_rental_days || 0;

    const AverageOrderValue = MainStats.average_order_value || 0;
    const AverageOrderDuration = MainStats.average_order_duration || 0;
    const AverageOrderProduct = MainStats.average_order_product || 0;
    
    const AverageCustomersOrders = MainStats.average_customer_orders || 0;
    const AverageProvidersOrders = MainStats.average_provider_orders || 0;
    const AverageCustomersProvider = MainStats.average_provider_customers || 0;
    


    if (isLoading) {
        return (
            <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
        )
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };


    let FinalMonthly = SortDataArray(CurrentMonthly, SortMonthly);
    let FinalDaily = SortDataArray(CurrentOrders, SortDaily);

    let FinalTopStores = SortDataArray(CurrentTopStores, SortStores);
    let FinalTopProducts = SortDataArray(CurrentTopProducts, SortProducts);

    

    return (

        <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 pb-10 pt-10">
            <Modal
                open={isOpenCity}
                onClose={() => setOpenCity(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400, maxHeight: 400, overflow: 'auto' }}>
                    <h4 id="parent-modal-title">Search City</h4>

                    {Locations.map((item, index) => {
                        return (
                            <div value={item.id} key={index} onClick={() => {
                                onUpdateLocation(item.id)
                                setOpenCity(false)
                            }}>
                                {item.name}
                            </div>
                        )
                    })}

                </Box>
            </Modal>

            <Modal
                open={isOpenProvider}
                onClose={() => setOpenProvider(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400, maxHeight: 400, overflow: 'auto' }}>
                    <h4 id="parent-modal-title">Search Provider</h4>

                    {Stores.map((item, index) => {
                        return (
                            <div value={item.id} key={index} onClick={() => {
                                onUpdateStore(item.id)
                                setOpenProvider(false)
                            }}>
                                {item.name}
                            </div>
                        )
                    })}


                </Box>
            </Modal>

            <Modal
                open={isOpenDates}
                onClose={() => setOpenDates(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <h4 id="parent-modal-title">Search Dates</h4>


                    <div className='d-flex flex-row justify-content-between'>

                        <div className="form-group">
                            <span className="form-label">
                                {TRANSLATE.start_date}
                            </span>
                            <input className="form-control" type="date" required
                                onChange={(e) => onSelectDate(e.target.value, 'start')}
                                lang="fr-CA"
                            />

                        </div>

                        <div className="form-group">
                            <span className="form-label">
                                {TRANSLATE.end_date}
                            </span>
                            <input className="form-control" type="date" required
                                onChange={(e) => onSelectDate(e.target.value, 'end')}
                                lang="fr-CA"

                            />

                        </div>

                    </div>

                    {(CurrentFirst && CurrentLast) && (
                        <div className='button-primary' onClick={() => onApplyDate()}>
                            Apply Range
                        </div>
                    )}



                </Box>
            </Modal>


            <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-between align-items-center'>

                <div className='col-md-6 col-12 order-2 md:order-1'>

                    <div className="row">

                        <div className='col-xl-4'>
                            <select
                                className={'gridyx-select'}
                                value={CurrentLocation}
                                label={'.'}
                                onChange={(e) => onUpdateLocation(e.target.value)}
                                InputLabelProps={{ shrink: false }}
                                select
                            >
                                <option value={'no_value'}>
                                    ---- Select a Location ({Locations.length}) ----
                                </option>
                                <option value="search">
                                    ---- Search a Location ----
                                </option>
                                <option value="all">
                                    All the World
                                </option>

                                {Locations.map((item, index) => {
                                    return (
                                        <option value={item.id} key={index}>
                                            {item.name}
                                        </option>
                                    )
                                })}


                            </select>
                        </div>

                        <div className='col-xl-4'>
                            <select
                                className={'gridyx-select'}
                                value={CurrentStore}
                                label={'.'}
                                onChange={(e) => onUpdateStore(e.target.value)}
                                InputLabelProps={{ shrink: false }}
                                select
                            >
                                <option value={'no_value'}>
                                    ---- Select a Store ({Stores.length}) ----
                                </option>
                                <option value="search">
                                    ---- Search a Store ----
                                </option>
                                <option value="all">
                                    All Stores
                                </option>

                                {Stores.map((item, index) => {
                                    return (
                                        <option value={item.id} key={index}>
                                            {item.name}
                                        </option>
                                    )
                                })}




                            </select>
                        </div>

                        <div className='col-xl-4'>
                            <select
                                className={'gridyx-select'}
                                value={CurrentDate}
                                label={'.'}
                                onChange={(e) => onUpdateDate(e.target.value)}
                                InputLabelProps={{ shrink: false }}
                                select
                            >
                                <option value={null}>
                                    ---- Select a Date Range ----
                                </option>
                                <option value="today">
                                    Today
                                </option>
                                <option value="yesterday">
                                    Yesterday
                                </option>
                                <option value="last_7">
                                    Last 7 days
                                </option>
                                <option value="last_14">
                                    Last 14 days
                                </option>
                                <option value="last_30">
                                    Last 30 days
                                </option>
                                <option value="last_60">
                                    Last 60 days
                                </option>
                                <option value="last_90">
                                    Last 90 days
                                </option>
                                <option value="last_180">
                                    Last 180 days
                                </option>

                                <option value="current_month">
                                    Current Month
                                </option>
                                <option value="current_year">
                                    Current Year
                                </option>

                                <option value="custom_range">
                                    Custom Range
                                </option>



                            </select>
                        </div>

                    </div>

                </div>

                <div className='col-md-6 col-12 order-1 md:order-2 mb-2'>
                    <h1 style={{ fontWeight: 100, textAlign: 'end' }} onClick={() => setOpenDates(true)}>
                        {(CurrentDate === 'today' || CurrentDate === 'yesterday') ? (
                            <>{CurrentDate.toUpperCase()} - <span style={{ fontWeight: 'bold' }}>{FirstDay.format('DD MMM yyyy')}</span></>
                        ) : (
                            <>{FirstDay.format('DD MMM yy')} -  <span style={{ fontWeight: 'bold' }}>{LastDay.format('DD MMM yyyy')}</span></>
                        )}

                    </h1>
                </div>

            </div>


            {isServing ? (
                <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
            ) : (

                <>

                    <div className='row px-xl-5 mb-5 justify-content-between w-100'>
                        <div className='col-xl-12 col-md-6 col-12 p-0 mb-5 d-flex flex-md-row flex-column'>
                            <div style={{ width: '100%', height: 350 }}>
                                <ResponsiveContainer>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={DATA_MONTHLY.length > 0 ? DATA_MONTHLY : DATA || []}
                                        syncId="anyId"
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis unit={'€'} name="GMV" />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        <Bar dataKey="GMV" stackId="a" fill="#26BFBF" />

                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <div style={{ width: '100%', height: 350 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={DATA_MONTHLY.length > 0 ? DATA_MONTHLY : DATA || []}
                                        syncId="anyId"
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        {/* <Legend /> */}

                                        <Line dataKey="pending" type="monotone" stroke="#FCE205" />
                                        <Line dataKey="accepted" type="monotone" stroke="#9AD6AB" />
                                        <Line dataKey="delivering" type="monotone" stroke="#88D1F1" />
                                        <Line dataKey="delivered" type="monotone" stroke="#ff8b3d" />
                                        <Line dataKey="complete" type="monotone" stroke="green" />
                                        <Line dataKey="rejected" type="monotone" stroke="#ec1529" />
                                        <Line dataKey="canceled" type="monotone" stroke="#ec1529" />
                                        <Line dataKey="refund" type="monotone" stroke="#ec1529" />
                                        <Line dataKey="total" type="monotone" stroke="#3d3d3d" />

                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>


                    <div className='row px-xl-5 px-2 mx-2 mt-4 mb-4 justify-content-between align-items-center'>

                        <div className='row px-xl-5 px-2 mx-2 mb-8 justify-content-center'>
                            <h1 style={{ fontSize: 60, fontWeight: 100 }}>Health <span style={{ fontWeight: 'bold' }}>Summary</span></h1>
                        </div>

                    </div>

                    <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-between w-100'>

                        <div className='col-xl-6 col-md-6 col-12 p-0'>


                            <div className='container-fluid' style={{ borderRadius: 20, padding: 20 }}>

                                {CurrentType === 'all' && (
                                    <>
                                        <div className='row mb-5' id="all_orders" style={{ textAlign: 'center' }}>

                                            {/* First */}
                                            <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {numberWithCommas(TotalStats.amount_total, 'EUR')}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Total Transactions
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {FullData.total}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    All Orders
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25, color: '#26BFBF' }}>
                                                    {numberWithCommas(TotalStats.amount_provider, 'EUR')}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Provider Earnings
                                                </p>
                                            </Link>

                                            <Link to={'/admin/stores'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25, color: '#fd6270' }}>
                                                    {numberWithCommas(TotalStats.amount_fees, 'EUR')}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Total Fees
                                                </p>
                                            </Link>

                                            {/* Second */}

                                            <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {numberWithCommas(TotalStats.average_order_value, 'EUR')}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Average Order Value
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {numberWithCommas(TotalStats.average_order_duration)}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Average Order Duration
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {TotalStats.total_providers}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Unique Providers
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {TotalStats.total_customers}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Unique Customers
                                                </p>
                                            </Link>

                                            {/* Third */}

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {numberWithCommas(TotalStats.average_order_product, 'EUR')}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Average Product Value
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {numberWithCommas(TotalStats.average_order_quantity)}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Average Order Quantity
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {numberWithCommas(TotalStats.average_provider_orders)}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Orders per Provider
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {numberWithCommas(TotalStats.average_customer_orders)}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Orders per Customer
                                                </p>
                                            </Link>

                                            {/* Fourth */}


                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {TotalStats.total_products}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Total Rented Products
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {TotalStats.total_products_quantity}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Total Products Quantity
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {numberWithCommas(TotalStats.average_provider_customers)}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Customers per Provider
                                                </p>
                                            </Link>

                                            <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                                <h1 style={{ fontSize: 25 }}>
                                                    {TotalStats.total_rental_days}
                                                </h1>
                                                <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                    Total Rental Days
                                                </p>
                                            </Link>

                                        </div>
                                    </>
                                )}

                                {CurrentType === 'green' && (
                                    <div className='row mb-5' id="green_orders" style={{ textAlign: 'center' }}>

                                        {/* First */}
                                        <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(GreenStats.amount_total, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Transactions
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {FullData.green}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                All Orders
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25, color: '#26BFBF' }}>
                                                {numberWithCommas(GreenStats.amount_provider, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Provider Earnings
                                            </p>
                                        </Link>

                                        <Link to={'/admin/stores'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25, color: '#fd6270' }}>
                                                {numberWithCommas(GreenStats.amount_fees, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Fees
                                            </p>
                                        </Link>

                                        {/* Second */}

                                        <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(GreenStats.average_order_value, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Order Value
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(GreenStats.average_order_duration)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Order Duration
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {GreenStats.total_providers}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Unique Providers
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {GreenStats.total_customers}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Unique Customers
                                            </p>
                                        </Link>

                                        {/* Third */}

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(GreenStats.average_order_product, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Product Value
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(GreenStats.average_order_quantity)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Order Quantity
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(GreenStats.average_provider_orders)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Orders per Provider
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(GreenStats.average_customer_orders)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Orders per Customer
                                            </p>
                                        </Link>

                                        {/* Fourth */}


                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {GreenStats.total_products}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Rented Products
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {GreenStats.total_products_quantity}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Products Quantity
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(GreenStats.average_provider_customers)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Customers per Provider
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {GreenStats.total_rental_days}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Rental Days
                                            </p>
                                        </Link>

                                    </div>
                                )}

                                {CurrentType === 'yellow' && (
                                    <div className='row mb-5' id="yellow_orders" style={{ textAlign: 'center' }}>

                                        {/* First */}
                                        <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(YellowStats.amount_total, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Transactions
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {FullData.yellow}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                All Orders
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25, color: '#26BFBF' }}>
                                                {numberWithCommas(YellowStats.amount_provider, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Provider Earnings
                                            </p>
                                        </Link>

                                        <Link to={'/admin/stores'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25, color: '#fd6270' }}>
                                                {numberWithCommas(YellowStats.amount_fees, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Fees
                                            </p>
                                        </Link>

                                        {/* Second */}

                                        <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(YellowStats.average_order_value, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Order Value
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(YellowStats.average_order_duration)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Order Duration
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {YellowStats.total_providers}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Unique Providers
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {YellowStats.total_customers}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Unique Customers
                                            </p>
                                        </Link>

                                        {/* Third */}

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(YellowStats.average_order_product, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Product Value
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(YellowStats.average_order_quantity)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Order Quantity
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(YellowStats.average_provider_orders)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Orders per Provider
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(YellowStats.average_customer_orders)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Orders per Customer
                                            </p>
                                        </Link>

                                        {/* Fourth */}


                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {YellowStats.total_products}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Rented Products
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {YellowStats.total_products_quantity}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Products Quantity
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(YellowStats.average_provider_customers)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Customers per Provider
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {YellowStats.total_rental_days}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Rental Days
                                            </p>
                                        </Link>

                                    </div>
                                )}

                                {CurrentType === 'red' && (
                                    <div className='row mb-5' id="red_orders" style={{ textAlign: 'center' }}>

                                        {/* First */}
                                        <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(RedStats.amount_total, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Transactions
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {FullData.red}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                All Orders
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25, color: '#26BFBF' }}>
                                                {numberWithCommas(RedStats.amount_provider, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Provider Earnings
                                            </p>
                                        </Link>

                                        <Link to={'/admin/stores'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25, color: '#fd6270' }}>
                                                {numberWithCommas(RedStats.amount_fees, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Fees
                                            </p>
                                        </Link>

                                        {/* Second */}

                                        <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(RedStats.average_order_value, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Order Value
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(RedStats.average_order_duration)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Order Duration
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {RedStats.total_providers}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Unique Providers
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {RedStats.total_customers}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Unique Customers
                                            </p>
                                        </Link>

                                        {/* Third */}

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(RedStats.average_order_product, 'EUR')}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Product Value
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(RedStats.average_order_quantity)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Average Order Quantity
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(RedStats.average_provider_orders)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Orders per Provider
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(RedStats.average_customer_orders)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Orders per Customer
                                            </p>
                                        </Link>

                                        {/* Fourth */}


                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {RedStats.total_products}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Rented Products
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {RedStats.total_products_quantity}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Products Quantity
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {numberWithCommas(RedStats.average_provider_customers)}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Customers per Provider
                                            </p>
                                        </Link>

                                        <Link to={'/admin/orders/0?sort=createdAt&dir=desc'} className={MenuClassStats} style={MenuStyleStats}>
                                            <h1 style={{ fontSize: 25 }}>
                                                {RedStats.total_rental_days}
                                            </h1>
                                            <p style={{ fontSize: 15, fontWeight: 100 }}>
                                                Total Rental Days
                                            </p>
                                        </Link>

                                    </div>
                                )}

                                <div className='row mb-5' id="status_orders" style={{ textAlign: 'center' }}>

                                    <div onClick={() => onUpdateStatus('all')} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: '#000' }}>
                                            {FullData.total}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(TotalStats.amount_total, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Total Orders
                                        </p>
                                    </div>

                                    <div onClick={() => onUpdateStatus('yellow')} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: '#FCE205' }}>
                                            {FullData.yellow}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(YellowStats.amount_total, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Yellow Orders
                                        </p>
                                    </div>

                                    <div onClick={() => onUpdateStatus('green')} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: 'green' }}>
                                            {FullData.green}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(GreenStats.amount_total, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Green Orders
                                        </p>
                                    </div>

                                    <div onClick={() => onUpdateStatus('red')} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: 'red' }}>
                                            {FullData.red}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(RedStats.amount_total, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Red Orders
                                        </p>
                                    </div>

                                </div>

                                <div className='row mb-5' id="status_orders" style={{ textAlign: 'center' }}>

                                    {/* First */}
                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: '#FCE205' }}>
                                            {FullData.pending}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(FullData.amount_pending, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Pending Orders
                                        </p>
                                    </Link>

                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: '#9AD6AB' }}>
                                            {FullData.accepted}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(FullData.amount_accepted, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Accepted Orders
                                        </p>
                                    </Link>

                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: '#88D1F1' }}>
                                            {FullData.delivering}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(FullData.amount_delivering, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Delivering Orders
                                        </p>
                                    </Link>

                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: '#ff8b3d' }}>
                                            {FullData.delivered}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(FullData.amount_delivered, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Delivered Orders
                                        </p>
                                    </Link>


                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: 'green' }}>
                                            {FullData.complete}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(FullData.amount_complete, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Completed Orders
                                        </p>
                                    </Link>


                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: '#ec1529' }}>
                                            {FullData.rejected}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(FullData.amount_rejected, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Rejected Orders
                                        </p>
                                    </Link>


                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: '#ec1529' }}>
                                            {FullData.canceled}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(FullData.amount_canceled, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Canceled Orders
                                        </p>
                                    </Link>


                                    <Link to={'/admin/orders'} className={MenuClassStats} style={MenuStyleStats}>

                                        <h1 style={{ fontSize: 25, color: '#ec1529' }}>
                                            {FullData.refund}
                                        </h1>
                                        <h1 style={{ fontSize: 20 }}>
                                            {numberWithCommas(FullData.amount_refund, 'EUR')}
                                        </h1>
                                        <p style={{ fontSize: 15, fontWeight: 100 }}>
                                            Refunded Orders
                                        </p>
                                    </Link>


                                </div>

                            </div>

                        </div>

                        <div className='col-xl-5 col-md-6 col-12'>

                            <div className='row mb-5 justify-content-between w-100'>
                                <div className='col-xl-6 bg-light mb-5' style={{ borderRadius: 20, padding: 20 }}>

                                    {(CurrentTopCountries.length >= 15 ? CurrentTopCountries.slice(0, 15) : CurrentTopCountries).map((item, index) => {

                                        const country_index = Countries_EN.findIndex((cty) => cty.value === item.point);
                                        const country_obx = country_index != -1 ? Countries_EN[country_index] : null;
                                        const country_name = country_obx ? country_obx.label : null;

                                        return (
                                            <div className='d-flex align-items-center justify-content-between px-3 py-1 mr-1' key={index}>
                                                <div className='d-flex align-items-center'>
                                                    <ReactCountryFlag countryCode={item.point} style={{ fontSize: 20, marginRight: 10 }} svg />
                                                    <h4 style={{ fontWeight: 100, fontSize: 'x-large' }}>{country_name}</h4>
                                                </div>
                                                <h4 style={{ color: '#000', fontSize: 'x-large' }}>{item.value}</h4>
                                            </div>
                                        )
                                    })}

                                </div>

                                <div className='col-xl-6 bg-light mb-5' style={{ borderRadius: 20, padding: 20 }}>

                                    {(CurrentTopCities.length >= 15 ? CurrentTopCities.slice(0, 15) : CurrentTopCities).map((item, index) => {
                                        return (
                                            <div className='d-flex align-items-center justify-content-between px-3 py-1 ml-1' key={index}>
                                                <div className='d-flex align-items-center'>
                                                    <ReactCountryFlag countryCode={item.code} style={{ fontSize: 20, marginRight: 10 }} svg />
                                                    <h4 style={{ fontWeight: 100, fontSize: 'x-large' }}>{item.point}</h4>
                                                </div>
                                                <h4 style={{ color: '#000', fontSize: 'x-large' }}>{item.value}</h4>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>

                        </div>

                    </div>

                </>
            )}



            {isServing ? (
                <LoaderMaster title={TRANSLATE.loader_loading} type="secondary" />
            ) : (

                <>

                    {CurrentMonthly.length > 0 && (
                        <>
                            <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-between align-items-center'>

                                <div className='col-xl-8'>
                                    <div>
                                        <h1 style={{ fontSize: 60, fontWeight: 100 }}>
                                            Monthly <span style={{ fontWeight: 'bold' }}>Summary</span>
                                        </h1>
                                    </div>
                                </div>

                                <div className='col-xl-4'>
                                    <select
                                        className={'gridyx-select'}
                                        value={SortMonthly}
                                        label={'.'}
                                        onChange={(e) => setSortMonthly(e.target.value)}
                                        InputLabelProps={{ shrink: false }}
                                        select
                                    >
                                        <option value={null}>
                                            ---- Sort by ----
                                        </option>
                                        <option value="timeline">
                                            Timeline
                                        </option>
                                        <option value="top_orders">
                                            Top Orders
                                        </option>
                                        <option value="best_revenue">
                                            Best Revenue
                                        </option>
                                        <option value="pending">
                                            Most Pending Orders
                                        </option>
                                        <option value="accpeted">
                                            Most Accepted Orders
                                        </option>
                                        <option value="delivering">
                                            Most Delivering Orders
                                        </option>
                                        <option value="delivered">
                                            Most Delivered Orders
                                        </option>
                                        <option value="complete">
                                            Most Completed Orders
                                        </option>
                                        <option value="canceled">
                                            Most Canceled Orders
                                        </option>
                                        <option value="rejected">
                                            Most Rejected Orders
                                        </option>
                                        <option value="refund">
                                            Most Refund Orders
                                        </option>



                                    </select>
                                </div>

                            </div>
                            <div className='row m-0 p-0 px-xl-5 justify-content-center pb-4' style={{ width: '100%' }}>

                                {FinalMonthly.map((item, index) => {

                                    const MOMENT = moment(item.name, 'MMM YYYY');
                                    const VALUE = item.total;

                                    let FinalStyle = { ...MenuStyle };

                                    if (VALUE == 0) {
                                        FinalStyle = {
                                            ...FinalStyle,
                                            background: '#ccc'
                                        }
                                    }
                                    else if (VALUE > 0 && VALUE < 200) {
                                        FinalStyle = {
                                            ...FinalStyle,
                                            background: '#fff'
                                        }
                                    }
                                    else {
                                        FinalStyle = {
                                            ...FinalStyle,
                                            background: '#99e4ae'
                                        }
                                    }


                                    return (
                                        <div className={MenuClass} key={index}>
                                            <Link to="/admin/dashboard" className='' style={{ ...FinalStyle }}>
                                                <h1 style={{ fontSize: 55, color: 'var(--dark)' }}>
                                                    {item.total}
                                                </h1>
                                                <p style={{ fontSize: 18, fontWeight: 100 }}>
                                                    {numberWithCommas(item.revenue, 'EUR')}
                                                </p>
                                                <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                                                    {MOMENT.format('MMMM YYYY')}
                                                </p>

                                                <div className='d-flex align-items-center justify-content-center w-100' style={{ paddingLeft: 10, paddingRight: 10 }}>

                                                    {item.pending > 0 && (
                                                        <div className='d-flex flex-column mx-2'>
                                                            <div style={{ background: '#FCE205', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                            <div>{item.pending}</div>
                                                        </div>
                                                    )}

                                                    {item.accepted > 0 && (
                                                        <div className='d-flex flex-column mx-2'>
                                                            <div style={{ background: '#9AD6AB', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                            <div>{item.accepted}</div>
                                                        </div>
                                                    )}

                                                    {item.delivering > 0 && (
                                                        <div className='d-flex flex-column mx-2'>
                                                            <div style={{ background: '#88D1F1', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                            <div>{item.delivering}</div>
                                                        </div>
                                                    )}

                                                    {item.delivered > 0 && (
                                                        <div className='d-flex flex-column mx-2'>
                                                            <div style={{ background: '#ff8b3d', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                            <div>{item.delivered}</div>
                                                        </div>
                                                    )}

                                                    {item.complete > 0 && (
                                                        <div className='d-flex flex-column mx-2'>
                                                            <div style={{ background: 'green', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                            <div>{item.complete}</div>
                                                        </div>
                                                    )}

                                                    {item.rejected > 0 && (
                                                        <div className='d-flex flex-column mx-2'>
                                                            <div style={{ background: '#ec1529', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                            <div>{item.rejected}</div>
                                                        </div>
                                                    )}

                                                    {item.canceled > 0 && (
                                                        <div className='d-flex flex-column mx-2'>
                                                            <div style={{ background: '#ec1529', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                            <div>{item.canceled}</div>
                                                        </div>
                                                    )}

                                                    {item.refund > 0 && (
                                                        <div className='d-flex flex-column mx-2'>
                                                            <div style={{ background: '#ec1529', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                            <div>{item.refund}</div>
                                                        </div>
                                                    )}



                                                </div>
                                            </Link>
                                        </div>
                                    )

                                })}

                            </div>
                        </>
                    )}

                    <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-between align-items-center' style={{ marginTop: 20 }}>

                        <div className='col-xl-8'>
                            <div>
                                <h1 style={{ fontSize: 60, fontWeight: 100 }}>
                                    Stores <span style={{ fontWeight: 'bold' }}>Summary</span>
                                </h1>
                            </div>
                        </div>

                        <div className='col-xl-4'>
                            <select
                                className={'gridyx-select'}
                                value={SortStores}
                                label={'.'}
                                onChange={(e) => setSortStores(e.target.value)}
                                InputLabelProps={{ shrink: false }}
                                select
                            >
                                <option value={null}>
                                    ---- Sort by ----
                                </option>
                                <option value="timeline">
                                    Timeline
                                </option>
                                <option value="top_orders">
                                    Top Orders
                                </option>
                                <option value="best_revenue">
                                    Best Revenue
                                </option>
                                <option value="rating">
                                    Best Rating
                                </option>
                                <option value="pending">
                                    Most Pending Orders
                                </option>
                                <option value="accpeted">
                                    Most Accepted Orders
                                </option>
                                <option value="delivering">
                                    Most Delivering Orders
                                </option>
                                <option value="delivered">
                                    Most Delivered Orders
                                </option>
                                <option value="complete">
                                    Most Completed Orders
                                </option>
                                <option value="canceled">
                                    Most Canceled Orders
                                </option>
                                <option value="rejected">
                                    Most Rejected Orders
                                </option>
                                <option value="refund">
                                    Most Refund Orders
                                </option>



                            </select>
                        </div>

                    </div>

                    <div className='row m-0 p-0 px-xl-5 justify-content-center pb-4' style={{ width: '100%' }}>

                        {(isShownStores ? FinalTopStores : FinalTopStores.slice(0, 12)).map((item, index) => {

                            let FinalStyle = { ...MenuStyle };

                            const FirstDate = moment(item.first, 'DD MMM YYYY');
                            const LastDate = moment(item.last, 'DD MMM YYYY');
                            const TimeDuration = LastDate.diff(FirstDate, 'days') + 1

                            return (
                                <div className={MenuClass} key={index}>
                                    <Link to={"/admin/stores/profile/" + item.id} className='' style={{ ...FinalStyle }}>
                                        <h1 style={{ fontSize: 55, color: 'var(--dark)' }}>
                                            {item.total}
                                        </h1>
                                        <p style={{ fontSize: 18, fontWeight: 100 }}>
                                            {numberWithCommas(item.revenue, 'EUR')} <div style={{ color: '#26BFBF' }}>{numberWithCommas(item.earning, 'EUR')}</div>
                                        </p>
                                        <p style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>
                                            {item.name}
                                        </p>

                                        <div className='d-flex align-items-center justify-content-center w-100' style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <img src={item.img} style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: '50%', border: 'solid 1px #000' }} />
                                        </div>


                                        <p style={{ fontSize: 16, fontWeight: 100, textAlign: 'center', marginTop: 10 }}>
                                            {item.first} - {item.last}
                                        </p>

                                        <p style={{ fontSize: 16, fontWeight: 100, textAlign: 'center', marginTop: 10 }}>
                                            {TimeDuration} days
                                        </p>

                                        <p style={{ fontSize: 16, fontWeight: 100, textAlign: 'center' }}>
                                            <StarReview rate={item.rating} size={20} />
                                        </p>

                                        <div className='d-flex align-items-center justify-content-center w-100' style={{ paddingLeft: 10, paddingRight: 10 }}>

                                            {item.pending > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#FCE205', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.pending}</div>
                                                </div>
                                            )}

                                            {item.accepted > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#9AD6AB', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.accepted}</div>
                                                </div>
                                            )}

                                            {item.delivering > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#88D1F1', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.delivering}</div>
                                                </div>
                                            )}

                                            {item.delivered > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#ff8b3d', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.delivered}</div>
                                                </div>
                                            )}

                                            {item.complete > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: 'green', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.complete}</div>
                                                </div>
                                            )}

                                            {item.rejected > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#ec1529', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.rejected}</div>
                                                </div>
                                            )}

                                            {item.canceled > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#ec1529', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.canceled}</div>
                                                </div>
                                            )}

                                            {item.refund > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#ec1529', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.refund}</div>
                                                </div>
                                            )}



                                        </div>
                                    </Link>
                                </div>
                            )

                        })}

                        {isShownStores ? (
                            <button className='btn primary' onClick={() => setShownStores(false)}>
                                Show Top Stores
                            </button>
                        ) : (
                            <button className='btn primary' onClick={() => setShownStores(true)}>
                                Expand all Stores
                            </button>
                        )}

                    </div>

                    <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-between align-items-center'>

                        <div className='col-xl-8'>
                            <div>
                                <h1 style={{ fontSize: 60, fontWeight: 100 }}>
                                    Products <span style={{ fontWeight: 'bold' }}>Summary</span>
                                </h1>
                            </div>
                        </div>

                        <div className='col-xl-4'>
                            <select
                                className={'gridyx-select'}
                                value={SortProducts}
                                label={'.'}
                                onChange={(e) => setSortProducts(e.target.value)}
                                InputLabelProps={{ shrink: false }}
                                select
                            >
                                <option value={null}>
                                    ---- Sort by ----
                                </option>
                                <option value="timeline">
                                    Timeline
                                </option>
                                <option value="top_orders">
                                    Top Orders
                                </option>
                                <option value="best_revenue">
                                    Best Revenue
                                </option>
                                <option value="rating">
                                    Best Rating
                                </option>
                                <option value="pending">
                                    Most Pending Orders
                                </option>
                                <option value="accpeted">
                                    Most Accepted Orders
                                </option>
                                <option value="delivering">
                                    Most Delivering Orders
                                </option>
                                <option value="delivered">
                                    Most Delivered Orders
                                </option>
                                <option value="complete">
                                    Most Completed Orders
                                </option>
                                <option value="canceled">
                                    Most Canceled Orders
                                </option>
                                <option value="rejected">
                                    Most Rejected Orders
                                </option>
                                <option value="refund">
                                    Most Refund Orders
                                </option>



                            </select>
                        </div>

                    </div>

                    <div className='row m-0 p-0 px-xl-5 justify-content-center pb-4' style={{ width: '100%' }}>

                        {(isShownProducts ? FinalTopProducts : FinalTopProducts.slice(0, 12)).map((item, index) => {

                            let FinalStyle = { ...MenuStyle }

                            return (
                                <div className={MenuClass} key={index}>
                                    <Link to={"/admin/products/profile/" + item.id} className='' style={{ ...FinalStyle }}>
                                        <h1 style={{ fontSize: 55, color: 'var(--dark)' }}>
                                            {item.total}
                                        </h1>

                                        <p style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>
                                            {item.name}
                                        </p>

                                        <div className='d-flex align-items-center justify-content-center w-100' style={{ paddingLeft: 10, paddingRight: 10 }}>

                                            <img src={item.img} style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: '50%', border: 'solid 1px #000' }} />

                                        </div>

                                    </Link>
                                </div>
                            )

                        })}

                        {isShownProducts ? (
                            <button className='btn primary' onClick={() => setShownProducts(false)}>
                                Show Top Products
                            </button>
                        ) : (
                            <button className='btn primary' onClick={() => setShownProducts(true)}>
                                Expand all Products
                            </button>
                        )}


                    </div>



                    <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-between align-items-center'>

                        <div className='col-xl-8'>
                            <div>
                                <h1 style={{ fontSize: 60, fontWeight: 100 }}>
                                    All Days <span style={{ fontWeight: 'bold' }}>Summary</span>
                                </h1>
                            </div>
                        </div>

                        <div className='col-xl-4'>
                            <select
                                className={'gridyx-select'}
                                value={SortDaily}
                                label={'.'}
                                onChange={(e) => setSortDaily(e.target.value)}
                                InputLabelProps={{ shrink: false }}
                                select
                            >
                                <option value={null}>
                                    ---- Sort by ----
                                </option>
                                <option value="timeline">
                                    Timeline
                                </option>
                                <option value="top_orders">
                                    Top Orders
                                </option>
                                <option value="best_revenue">
                                    Best Revenue
                                </option>
                                <option value="pending">
                                    Most Pending Orders
                                </option>
                                <option value="accpeted">
                                    Most Accepted Orders
                                </option>
                                <option value="delivering">
                                    Most Delivering Orders
                                </option>
                                <option value="delivered">
                                    Most Delivered Orders
                                </option>
                                <option value="complete">
                                    Most Completed Orders
                                </option>
                                <option value="canceled">
                                    Most Canceled Orders
                                </option>
                                <option value="rejected">
                                    Most Rejected Orders
                                </option>
                                <option value="refund">
                                    Most Refund Orders
                                </option>



                            </select>
                        </div>

                    </div>
                    <div className='row m-0 p-0 px-xl-5 justify-content-center pb-4' style={{ width: '100%' }}>

                        {FinalDaily.map((item, index) => {

                            const MOMENT = moment(item.date);
                            const ISFUTURE = !moment(item.date).isSameOrBefore(moment(), "day");

                            const VALUE = item.total;

                            let FinalStyle = { ...MenuStyle };

                            if (VALUE == 0) {
                                FinalStyle = {
                                    ...FinalStyle,
                                    background: '#ccc'
                                }
                            }
                            else if (VALUE > 0 && VALUE < 10) {
                                FinalStyle = {
                                    ...FinalStyle,
                                    background: '#fff'
                                }
                            }
                            else {
                                FinalStyle = {
                                    ...FinalStyle,
                                    background: '#99e4ae'
                                }
                            }

                            if (ISFUTURE) {

                                return null
                            }

                            return (
                                <div className={MenuClass} key={index}>
                                    <Link to="/admin/dashboard" className='' style={{ ...FinalStyle }}>
                                        <h1 style={{ fontSize: 55, color: 'var(--dark)' }}>
                                            {item.total}
                                        </h1>
                                        <p style={{ fontSize: 18, fontWeight: 100 }}>
                                            {numberWithCommas(item.revenue, 'EUR')}
                                        </p>
                                        <p style={{ fontSize: 12, fontWeight: 'bold' }}>
                                            {MOMENT.format('dddd DD MMMM YYYY')}
                                        </p>

                                        <div className='d-flex align-items-center justify-content-center w-100' style={{ paddingLeft: 10, paddingRight: 10 }}>

                                            {item.pending > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#FCE205', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.pending}</div>
                                                </div>
                                            )}

                                            {item.accepted > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#9AD6AB', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.accepted}</div>
                                                </div>
                                            )}

                                            {item.delivering > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#88D1F1', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.delivering}</div>
                                                </div>
                                            )}

                                            {item.delivered > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#ff8b3d', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.delivered}</div>
                                                </div>
                                            )}

                                            {item.complete > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: 'green', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.complete}</div>
                                                </div>
                                            )}

                                            {item.rejected > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#ec1529', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.rejected}</div>
                                                </div>
                                            )}

                                            {item.canceled > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#ec1529', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.canceled}</div>
                                                </div>
                                            )}

                                            {item.refund > 0 && (
                                                <div className='d-flex flex-column mx-2'>
                                                    <div style={{ background: '#ec1529', height: 10, width: 10, borderRadius: '50%' }}></div>
                                                    <div>{item.refund}</div>
                                                </div>
                                            )}



                                        </div>
                                    </Link>
                                </div>
                            )

                        })}

                    </div>

                </>
            )}

        </div>
    )
}



const getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}

function numberWithCommas(x, CURRENCY) {

    if (!x) {
        x = 0;
    }

    if (!CURRENCY) {
        return x.toFixed(2);
    }

    if (isNaN(x)) return x;

    return (x).toLocaleString('en-US', {
        style: 'currency',
        currency: CURRENCY,
    });

}

const GetRouteSearchParams = (query) => {
    const routeParams = getQueryStringParams(query) || {};
    return routeParams
}

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                let [key, value] = param.split('=');
                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                return params;
            }, {}
            )
        : {}
};

const SortDataArray = (Input, Switch) => {

    let Output = Input;
    if (Switch === 'top_orders') {
        Output = Input.sort((a, b) => b.total - a.total);
    }
    else if (Switch === 'best_revenue') {
        Output = Input.sort((a, b) => b.revenue - a.revenue);
    }
    else if (Switch === 'best_revenue') {
        Output = Input.sort((a, b) => b.revenue - a.revenue);
    }
    else if (Switch === 'timeline') {
        Output = Input;
    }
    else {
        Output = Input.sort((a, b) => b[Switch] - a[Switch]);
    }

    return Output
}

export default AnalyticsCalendar

