import { CameraAltOutlined, WarningAmberOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { ArrowForward, CloseCircleOutline } from 'react-ionicons';
import Dropdown from '../Dropdown/Dropdown';
import Header from '../Header';

const CustomerOrderProblem = (props) => {
  const [status, setStatus] = useState('received');
  const [reason, setReason] = useState('all');

  return (
    <div className='layout relative quicksand'>
      <Header {...props} animatedBackground={false} />

      <div
        className='relative flex flex-col mx-auto my-4 gap-4 w-full max-w-xl px-4 sm:px-6 lg:px-8'
        style={{ flexGrow: 1 }}
      >
        <div className='flex w-full align-items-center justify-content-between'>
          <h2 className='text-sm'>YOUR ORDER DETAILS - ID: #5235266</h2>
        </div>

        <div className='flex flex-col p-2 w-full bg-light-gray rounded-lg'>
          <div className='flex gap-2'>
            <img
              src='/assets/cities/amsterdam.png'
              className='border-mid-gray'
              style={{ width: '60px', height: '56px' }}
            />
            <div className='w-full'>
              <h2 className='text-sm text-ellipsis'>Uppa Baby Stroller</h2>
              <h3 className='text-sm' style={{ fontStyle: 'italic' }}>
                Aishwarya Rajamohan
              </h3>

              <div className='flex mt-2 w-full justify-content-between'>
                <p className='font-semibold'>1x</p>
                <p>16$</p>
              </div>

              <div className='flex gap-8 mt-2 w-full justify-content-between '>
                <p className='font-semibold'>4days</p>
                <p>27-03-2023 - 28-03-2023</p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-content-between p-2 w-full bg-light-gray rounded-lg'>
          <div className='flex align-items-center gap-1'>
            <input
              type='radio'
              id='status'
              name='status'
              value='status'
              checked={status !== 'received'}
              onClick={() => setStatus('notreceived')}
            />
              <p>I didn’t receive order</p>
          </div>
          <div className='flex align-items-center gap-1'>
            <input
              type='radio'
              id='status'
              name='status'
              value='status'
              checked={status === 'received'}
              onClick={() => setStatus('received')}
            />
              <p>Yes, I received</p>
          </div>
        </div>

        <div className='w-full'>
          <h3 className='font-bold'>What’s your problem?</h3>
          <div className='w-full'>
            <Dropdown
              value={reason}
              placeholder={'Please Select Reason'}
              options={[{ title: 'Broken/Dirty Product', value: 'all' }]}
              onChange={(value) => setReason(value)}
              searchDisabled={true}
              outlined
            />
          </div>
        </div>
        <div>
          <h3 className='font-bold'>Please Add Photo</h3>

          <div>
            <button
              type='button'
              className='flex align-items-center pl-1 pr-2 py-1 gap-1 font-semibold text-sm border-purple-gray rounded-lg'
            >
              <CameraAltOutlined /> TAKE A PHOTO
            </button>
          </div>
        </div>

        <div className='flex my-4 gap-2'>
          <div className='relative border-light-gray rounded-lg' style={{ width: '96px', height: '88px' }}>
            <CloseCircleOutline
              className='absolute top-0 right-0'
              style={{ color: 'red', width: '20px', height: '20px' }}
            />
            <div className='flex items-center justify-center h-full'>
              <img src='/assets/cities/amsterdam.png' />
            </div>
          </div>
          <div className='relative border-light-gray rounded-lg' style={{ width: '96px', height: '88px' }}>
            <div>
              <CloseCircleOutline
                className='absolute top-0 right-0'
                style={{ color: 'red', width: '20px', height: '20px' }}
              />
            </div>
            <div className='flex items-center justify-center h-full'>
              <img src='/assets/cities/dubai.png' />
            </div>
          </div>
        </div>

        <div className='font-semibold'>
          <WarningAmberOutlined /> Warning - You need to take photos
        </div>

        <p>If you don’t take photos after delivered you will be charge for broken/dirty product</p>

        <div className='sticky top-auto flex flex-col w-full pt-2 bg-white' style={{ bottom: '0px' }}>
          <button
            type='button'
            className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full bg-black text-white rounded-lg'
            onClick={() => {}}
          >
            SEND <ArrowForward style={{ color: '#fff' }} />
          </button>
          <button
            type='button'
            className='quicksand flex items-center justify-center mt-4 p-2 gap-2 font-bold w-full rounded-lg'
            onClick={() => {}}
          >
            CANCEL <ArrowForward style={{ color: '#fff' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderProblem;
