const currency = (state = 'eur', action) => {
  
  switch (action.type) {
    case 'UPDATE_CURRENCY':
      return action.currency;
    default:
      return state;
  }

};
export default currency;
