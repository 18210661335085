import React, {useState, useEffect, useRef} from 'react';
import LoaderMaster from '../Loaders/Loaders';

import moment from 'moment';

import {postApiPrivate} from '../../api';

import Order_Status from '../Orders/OrdersStatus';

import Iframe from 'react-iframe';
import { Link } from 'react-router-dom';
import StarReview from '../General/Stars';
import { PersonOutline } from 'react-ionicons';
import { Chip } from '@mui/material';

const AdminOrdersDetails = (props) => {

    const {TRANSLATE, match, history} = props;

    const [isUpdating, setUpdating] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isRefreshing, setRefreshing] = useState(false);
    const [ORDER, setOrder] = useState({});

    useEffect(()=> {

        const ORDER_ID = match.params.id;

        if(ORDER_ID){
            onFetchSingle(ORDER_ID);
        }

        console.log("ORDER_ID ===> ", ORDER_ID);


    }, []);

    const onFetchSingle = async (order_id, type) => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        if(type === 'refresh'){
            setRefreshing(true);
        }
        else{
            setLoading(true);
        }
        
        try{
  
            const DATA_RESPONSE = await postApiPrivate('/admin/orders/details', {id: order_id, partner: match.params.pid} , JWT);
            
            if(DATA_RESPONSE.error){
                console.log("ERROR FROM API LOADING SINGLE ORDER", DATA_RESPONSE.error);
                setLoading(false);
                setRefreshing(false);
                return 
            }
  
            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);
  
            setOrder(DATA_RESPONSE);
            setLoading(false);
            setRefreshing(false);
           
  
        }
        catch(e){
            
            console.log("CREATING ===> ", e);
            setLoading(false);
            setRefreshing(false);
        }
  
    }

    const onCalculateDailyPrice = (PRODUCT, DURATION) => {
       
        var {price_day, price_month, price_week} = PRODUCT;

        if(DURATION < 7){
            return price_day
        }
        else if(DURATION >= 7 && DURATION < 30){
            var PRICE_WEEK_DAILY = price_week/7;
            return PRICE_WEEK_DAILY
        }
        else if(DURATION >= 30){
            var PRICE_MONTH_DAILY = price_month/30;
            return PRICE_MONTH_DAILY
        }

    }

    const onCalculateSingleSubtotal = (PRODUCT, DURATION) => {
       
        var {price_day, price_month, price_week} = PRODUCT;

        if(DURATION < 7){
            var subtotal = price_day * DURATION;
            return subtotal
        }
        else if(DURATION >= 7 && DURATION < 30){
            var PRICE_WEEK_DAILY = price_week/7;
            var subtotal = PRICE_WEEK_DAILY * DURATION;
            return subtotal
        }
        else if(DURATION >= 30){
            var PRICE_MONTH_DAILY = price_month/30;
            var subtotal = PRICE_MONTH_DAILY * DURATION;
            return subtotal
        }

    }

    const onUpdateStatus = async(status, data) => {

        // const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        // const store_id = USER.store || '';

        // if(!store_id){
        //     return alert('Error');
        // }

        // setUpdating(true);
  
        // try{
  
        //     var DATA_RESPONSE = await postApiPrivate('/admin/orders/status', {id: order_id, sid: store_id, st: status, qr: data} , JWT);
            
        //     if(DATA_RESPONSE.error){
        //         console.log("ERROR FROM API LOADING UPDATE STATUS", DATA_RESPONSE.error);
        //         setUpdating(false);

        //         alert(DATA_RESPONSE.error);
                
        //         return 
        //     }
  
        //     console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);
  
        //     setUpdating(false);   

        //     onFetchSingle(ORDER.id)
  
        // }
        // catch(e){
            
        //     console.log("CREATING ===> ", e);
        //     setUpdating(false);
        // }
    }

    const onSelectReview = (review_id) => {
        history.push('/partners/'+match.params.pid+'/reviews/profil/'+review_id);
    }
    
    moment.locale(TRANSLATE.lang);
 
    const order_id = ORDER.id;
    const order_status = ORDER.status;
    const order_products = ORDER.products || []
    const order_delivery = ORDER.delivery || {};
    const order_return = ORDER.return || {};

    const order_currency =  ORDER.currency || 'eur';

    const order_created = moment(ORDER.created).format('dddd DD MMMM YYYY - HH:mm');
    const order_updated = moment(ORDER.updated).format('dddd DD MMMM YYYY - HH:mm');

    const order_checkin = moment(ORDER.checkin).format('DD MMMM YYYY');
    const order_checkout = moment(ORDER.checkout).format('DD MMMM YYYY');
    const order_duration =  moment(ORDER.checkout).diff(moment(ORDER.checkin), 'days')+1;

    const cost_subtotal = ORDER.subtotal || 0;
    const cost_total = ORDER.total || 0;
    const cost_delivery = ORDER.d_cost || 0;
    const cost_return = ORDER.r_cost || 0;
    const cost_fee = ORDER.f_cost || 0;
    const cost_fee_provider = ORDER.f_p_cost || 0;
    const cost_total_provider = ORDER.p_total || 0;
    const cost_discount = ORDER.dis_cost || 0;

    const order_hash = ORDER.qr || {};
    const order_hash_iv = order_hash.iv;
    const order_hash_content = order_hash.content;
    const order_qr_code = order_hash_iv + '---' + order_hash_content; 

    const order_store = ORDER.store || {}
    const store_id = order_store.id;
    const store_name = order_store.name;
    const store_img = order_store.img;
    const store_marketo = order_store.marketo;

    const order_customer = ORDER.customer || {}
    const customer_id = order_customer.id;
    const customer_name = order_customer.name;
    const customer_img = order_customer.img;

    const order_review_pro = ORDER.review_pro || {};
    const order_review_cus = ORDER.review_cus || {};

    const order_coupon = ORDER.coupon || '';
    const order_message = ORDER.message || '';
    const order_phone = ORDER.phone || '';


    if(isLoading){
        return (
            <LoaderMaster title={'loading'} type="secondary" />
        )
    }

    if(isRefreshing){
        return (
            <LoaderMaster title={'loading'} type="secondary" />
        )
    }

    if(isUpdating){
        return (
            <LoaderMaster title={'loading'} type="secondary" />
        )
    }


    moment.locale(TRANSLATE.lang);
    
    return ( 
        <>

            <div className='row px-xl-5 px-2 mx-2 mb-5 justify-content-center'>
                <h1 style={{fontSize: 60, fontWeight: 100}}>Order  <span style={{fontWeight: 'bold'}}>#{order_id ? order_id.toUpperCase() : ''}</span></h1>
            </div>

            <div className='row'>

                <div className='col-xl-4'>
                    <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>

                        <div className="top_checkout_card">

                                <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
                                    STATUS
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', marginBottom: 20, alignItems: 'flex-start'}}>
                                    <Order_Status status={order_status} type={'orders'} TRANSLATE={TRANSLATE} />
                                </div>

                                <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
                                    Order Created
                                </div>
                                <div style={{fontSize: 14, marginBottom: 20}}>
                                    {order_created}
                                </div>
                                
                                {(order_updated !== order_created) && (
                                    <>
                                    <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
                                        Order Updated
                                    </div>
                                    <div style={{fontSize: 14, marginBottom: 20}}>
                                        {order_updated}
                                    </div>
                                    </>
                                )}

                               
                        </div>
                    
                        <div className="top_checkout_card">

                                <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
                                    Store
                                </div>
                            
                                <div style={{display: 'flex', alignItems: 'center', fontSize: 14, marginBottom: 20, cursor: 'pointer'}} onClick={()=> history.push('/partners/'+match.params.pid+'/stores/profile/'+store_id)}>
                                    <img src={store_img} style={{width: 35, height: 35, borderRadius: 50, border: 'solid 1px #00000019',  marginRight: 5, objectFit: 'cover'}} />
                                    {store_name}
                                </div>
                            
                                <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5, marginTop: 20}}>
                                Customer
                                </div>

                                <div style={{display: 'flex', alignItems: 'center', fontSize: 14, cursor: 'pointer'}} onClick={()=> history.push('/partners/'+match.params.pid+'/customers/profile/'+customer_id)}>
                                    {customer_img ? (
                                        <img src={customer_img} style={{width: 35, height: 35, borderRadius: 50, border: 'solid 1px #00000019', marginRight: 5, objectFit: 'cover'}} />
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-center"style={{background: '#ccc', width: 35, height: 35, borderRadius: 50, border: 'solid 1px #00000019', marginRight: 5, objectFit: 'cover'}}>
                                         <PersonOutline color={'#ffffff'}  />
                                        </div>
                                    )}
                                    
                                    {customer_name}
                                </div>

                        </div>

                       
                    </div>
                </div>

                <div className='col-xl-4'>
                    <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>
                        <div className="top_checkout_card">
                            <div style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
                                Products
                            </div>
                            
                            {order_products.map((item, index)=> {

                                return (
                                    <Product_Card product={item} 
                                                    duration={order_duration} 
                                                    onCalculateDailyPrice={onCalculateDailyPrice} 
                                                    onCalculateSingleSubtotal={onCalculateSingleSubtotal} 
                                                    key={index}
                                                    TRANSLATE={TRANSLATE}
                                                    currency={order_currency}
                                                    match={match}
                                    />
                                )

                            })}
                            
                        </div>
                    </div>

                    {(order_phone || order_coupon || order_message) && (
                        <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>

                            {order_phone && (
                                <div className="top_checkout_card">
                                    <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10 }}>
                                        CUSTOMER PHONE 
                                    </div>
                                    
                                    <Chip label={'+'+order_phone.replace('+', '')} />
                                </div>
                            )}
                            
                            {order_coupon && (
                                <div className="top_checkout_card" style={{marginTop: 20}}>
                                    <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10 }}>
                                        COUPON 
                                    </div>
                                    
                                    <Chip label={order_coupon} />
                                </div>
                            )}
                            
                            {order_message && (
                                <div className="top_checkout_card" style={{marginTop: 20}}>
                                    <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10 }}>
                                        Additional Information
                                    </div>
                
                                    <p>{order_message}</p>
                                </div>
                            )}
                        
                        </div>
                    )}
                    
                    <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>
                      
                        <div className="top_checkout_card">
                            <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' }}>
                            Pickup
                            </div>
                            
                            {(order_delivery && order_store) && (
                                <Delivery_Card order_delivery={order_delivery} order_store={order_store} TRANSLATE={TRANSLATE} />
                            )}
                        </div>

                    </div>
                    <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>


                        <div className="top_checkout_card">
                            <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' }}>
                            Dropoff
                            </div>
                            
                            {(order_return && order_store) && (
                                <Return_Card order_return={order_return} order_store={order_store} TRANSLATE={TRANSLATE} />
                            )}
                        </div>
                    

                    </div>
                </div>

                <div className='col-xl-4'>
                    <div className="top_checkout_left" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}>

                        <div className="top_checkout_card">
                            <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                             Rental Period
                            </div>
                            <div style={{ fontSize: 15, marginTop: 10 }}>
                            {moment(order_checkin).format('DD MMMM YYYY')} → {moment(order_checkout).format('DD MMMM YYYY')}
                            </div>
            
                        </div>

                        <div className="top_checkout_card" style={{marginTop: 20}}>
                            <div style={{ fontSize: 16, fontWeight: 'bold'}}>
                            Rental Duration
                            </div>
                            
                            <div style={{ fontSize: 15 , marginTop: 10}}>
                                {order_duration} {TRANSLATE.days}
                            </div> 
                        </div>

                        <div className="top_checkout_card" style={{marginTop: 20}}>
                            <div style={{ fontSize: 16, fontWeight: 'bold'}}>
                            To Provider
                            </div>
                            
                            <StarReview rate={order_review_pro.rank} size={20} onClick={()=> onSelectReview(order_review_pro.id)} />
                        </div>

                        <div className="top_checkout_card" style={{marginTop: 20}}>
                            <div style={{ fontSize: 16, fontWeight: 'bold'}}>
                            To Customer
                            </div>
                            
                            <StarReview rate={order_review_cus.rank} size={20} onClick={()=> onSelectReview(order_review_cus.id)} />
                        </div>

                        
                    
                    </div>
                    <div className="top_checkout_right" style={{background: '#fff', borderRadius: 20, padding: 20, marginBottom: 20}}> 
                        <div className="top_checkout_card">

                            <div style={{ fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase'}}>
                                Rental Costs
                            </div>

                            <Costs_Card SUBTOTAL={cost_subtotal} 
                                        DELIVERY={cost_delivery} 
                                        RETURN={cost_return}
                                        FEE={cost_fee}
                                        DISCOUNT={cost_discount}
                                        TOTAL={cost_total}
                                        TOTAL_PROVIDER={cost_total_provider}
                                        TRANSLATE={TRANSLATE}
                                        currency={order_currency}
                            
                            />
                            
                            {/* <Costs_Card SUBTOTAL={cost_subtotal} 
                                        DELIVERY={cost_delivery} 
                                        RETURN={cost_return}
                                        FEE={cost_fee}
                                        P_FEE={cost_fee_provider}
                                        TOTAL={cost_total}
                                        P_TOTAL={cost_total_provider}
                                        TRANSLATE={TRANSLATE}
                            
                            /> */}

                            {/* <Actions_Card status={order_status} 
                                        order_id={order_id} 
                                        onUpdateStatus={onUpdateStatus} 
                                        onRankProvider={onRankProvider}
                                        history={history} 
                                        customer_id={customer_id}
                                        TRANSLATE={TRANSLATE}

                            /> */}

                        </div>
                    </div>
                </div>

            </div>
            
                  
        </>
    )

  
};


const Delivery_Card = ({order_delivery, order_store}) => {

    
    var delivery_type = order_delivery.type;

    var provider_address = order_store.address;
    var provider_map = provider_address;

    if(delivery_type !== 'home'){
        provider_address = order_delivery.name;
        var geocode = order_delivery.geocode || {}
        provider_map = geocode.latitude+','+geocode.longitude; 
    }

    return (

        <>
            <div style={{...Styles.row, flexDirection: 'column', justifyContent: 'space-between'}}>

                <Iframe url={"https://maps.google.com/maps?q="+provider_map+"&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                        width="100%"
                        height="300"
                        className="map_frame"
                        frameborder="0" 
                        scrolling="no" 
                        marginheight="0" 
                        marginwidth="0"
                />
                        
                <div style={{ fontSize: 14 , marginTop: 20}}>
                    {provider_address}
                </div>

            </div>

        </>
    )
}

const Return_Card = ({order_return, order_store}) => {

    var return_type = order_return.type;

    var provider_address = order_store.address;
    var provider_map = provider_address;

    if(return_type !== 'home'){
        provider_address = order_return.name;
        var geocode = order_return.geocode || {}
        provider_map = geocode.latitude+','+geocode.longitude; 
    }

    return (

        <>
            <div style={{...Styles.row, flexDirection: 'column', justifyContent: 'space-between'}}>

                <Iframe url={"https://maps.google.com/maps?q="+provider_map+"&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                        width="100%"
                        height="300"
                        className="map_frame"
                        frameborder="0" 
                        scrolling="no" 
                        marginheight="0" 
                        marginwidth="0"
                />
                        
                <div style={{ fontSize: 14 , marginTop: 20}}>
                    {provider_address}
                </div>

            </div>

        </>
    )
}

const Costs_Card = (props) => {

    const {SUBTOTAL, DELIVERY, RETURN, FEE, DISCOUNT, TOTAL_PROVIDER, TOTAL, TRANSLATE, currency} = props;
  
    
  
    return (
        <div className="top_costs_container">
  
          <div style={{height: 1, backgroundColor: '#ccc', marginTop: 20, marginBottom: 30}} />
        
          <div className="d-flex align-items-center justify-content-between px-2">
              <div>
                Subtotal
              </div>
              <span style={{fontWeight: 'bold'}}>{numberWithCommas(SUBTOTAL, currency)}</span>
          </div>
  
          <div className="d-flex align-items-center justify-content-between px-2">
              <div>
                Pickup
              </div>
              <span style={{fontWeight: 'bold'}}>{numberWithCommas(DELIVERY, currency)}</span>
          </div>
          <div className="d-flex align-items-center justify-content-between px-2">
              <div>
                Dropoff
              </div>
              <span style={{fontWeight: 'bold'}}>{numberWithCommas(RETURN, currency)}</span>
          </div>
          
          <div className="d-flex align-items-center justify-content-between px-2">
              <div>
                Service Fee
              </div>
              <span style={{fontWeight: 'bold', color: 'var(--danger)'}}>-{numberWithCommas(FEE, currency)}</span>
          </div>

  
          <div style={{height: 1, backgroundColor: '#ccc', marginTop: 20, marginBottom: 30}} />
  
          <div className="d-flex align-items-center justify-content-between px-2">
              <h4 style={{fontSize: 22, fontWeight: 'bold', margin: 0}}>
                Total Rental
              </h4>
              <span style={{color: '#26BFBF', fontSize: 22, fontWeight: 'bold'}}>
                {numberWithCommas(TOTAL_PROVIDER, currency)} {currency.toUpperCase()}
              </span>
          </div>
  
         
  
      </div>
    )
  
};

const Product_Card = ({ product, duration, onCalculateDailyPrice, onCalculateSingleSubtotal, TRANSLATE, currency, match }) => {

    const {name, img, id} = product;
    const {quantity} = product;

    const final_price = onCalculateDailyPrice(product, duration);
    const total_price = onCalculateSingleSubtotal(product, duration);


    return (
        <Link to={'/partners/'+match.params.pid+'/products/profile/'+id} style={{display: 'flex', backgroundColor: '#fff', flexDirection: 'row', marginTop: 20}}>
          
            <img src={img} 
                style={Styles.avatar} 
            />

            <div style={{display: 'flex', flexDirection: 'column', margin: 0, width: '100%'}}>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <h5 style={{margin: 0}}>
                    {name}
                  </h5>
                </div>
                
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 5}}>
              
                  <div style={{fontSize: 18, fontWeight: 'bold', color: '#26BFBF'}}>
                     {numberWithCommas(final_price, currency)}
                  </div>
                  <div style={{fontSize: 12, fontWeight: 'bold', color: '#ccc', marginLeft: 5}}>
                    /day
                  </div>
                
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
              
                  <div style={{fontSize: 12, color: '#000'}}>
                    QTY: {quantity}
                  </div>

                  <div style={{fontSize: 15, fontWeight: 'bold', color: '#000'}}>
                    {numberWithCommas(total_price, currency)}
                  </div>
                
                </div>

            </div>	

        
        </Link>
    )
}

function numberWithCommas(x, CURRENCY) {
	if(isNaN(x)) return x;

  return (x).toLocaleString('en-US', {
    style: 'currency',
    currency: CURRENCY,
  });

}


const Styles = {
    buyBtn: {
        display: 'flex',
        height: 50,
        backgroundColor: 'green',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70%', 
        borderRadius: 10, 
        height: 40,
        cursor: 'pointer'
    },
    avatar : {
        width: 100, 
        height: 100, 
        marginRight: 15, 
        borderRadius: 15,
        border: 'solid 1px #00000019',
        objectFit: 'cover'
    },
    add_btn: {
        display: 'flex',
        height: 60,
        backgroundColor: '#26BFBF',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
        width: '100%',
        padding: 20,
        marginTop: 50
    }, 
    row: {
        display: 'flex',
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: 20
    },
    checkout_card: { 
        marginTop: 20, 
        padding: 20, 
        border: 'solid 1px #00000010', 
        backgroundColor: '#ffffff' 
    },
    buttonContainer: {
        zIndex: 222, 
        width: '100%', 
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 10, 
        flexDirection: 'row', 
        justifyContent: 'space-evenly', 
        backgroundColor: '#fff', 
        borderTopWidth: 0.5, 
        borderColor: '#ccc'
    },
    action_text: {
      color: '#ffffff', 
      fontSize: 15, 
      fontWeight: 'bold', 
      textTransform:'uppercase'
    },
    action_button:{
        padding: 10,
        width: '45%', 
        borderRadius: 10, 
        marginHorizontal: 10, 
        marginTop: 10,
        textAlign: 'center',
        cursor: 'pointer'
    },
    action_container: {
        width: '100%', 
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 10, 
        flexDirection: 'row', 
        justifyContent: 'space-evenly', 
        backgroundColor: '#fff', 
        borderTopWidth: 0.5, 
        borderColor: '#ccc',
        zIndex: 222
    }
};

export default AdminOrdersDetails
